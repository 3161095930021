import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: '88d417d8-95eb-427b-8828-c46c9129de97',
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
  redirectUri: window.location.origin,
  logoutUri: `${ window.location.origin  }/logout`,
  postLogoutRedirectUri: window.location.origin,
  endpoints: {
    api: `https://itella.onmicrosoft.com/${ process.env.REACT_APP_AZURE_CLIENT_ID }`,
  },
  cacheLocation: 'localStorage',
  expireOffsetSeconds: 300,
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const ensureToken = () => {
  authContext.acquireToken(adalConfig.clientId, (message, token) => {
    if (token === null || token === undefined) {
      // console.log("Token renewal failure due to message =", message, " and msg = ", msg);
    }
  });
};
