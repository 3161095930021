import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../locales/translation.js';
import './NewTeam.css';
import TeamDesc from './TeamDesc/TeamDesc';
import { getJsonBody } from '../../utility/commonUtility.js';
import { CommonService } from '../../service/CommonService.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';

const translator = new LocalizedStrings(translation);
let availablePeopleList = [];
class NewTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: [],
      imageBase64: '',
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    this.getPeopleScreenData();
  };

  getPeopleScreenData = () => {
    const { browserLanguage , selectedCostCenter }= this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;

    this.commonService
      .postMethod(apiUrl.getPeopleScreenData, jsonBody)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          availablePeopleList = res.data.filter((value) => value.groupId === null);
          this.setState({});
        }
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  };

  render() {
    const { browserLanguage,OnHide,TeamDetails  }= this.props;
    const { imageFile , imageBase64 }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <Dialog
        id = "new-team"
        visible = { Dialog }
        style = { { width: '80vw', height: '80vh' } }
        onHide = { () => {
          OnHide();
        } }
      >
        <div className = "newTeamContainer">
          {/* Left section */}
          <div className = "newTeamLeftSection">
            <div className = "newTeamHeaderLeftContainer">
              <div className = "team-heading">{translator.NewTeamScreen.newTeam}</div>

              {imageFile && imageFile.length !== 0 ? (
                <img alt = "" id = "newteam-avatar" src = { imageBase64 } />
              ) : (
                <div id = "newteam-avatar" />
              )}
            </div>
            <div className = "how-to-heading">{translator.NewTeamScreen.howToCreate}</div>
            <div className = "how-to-desc">
              {translator.NewTeamScreen.welcometo}
              <br />
                1. {translator.NewTeamScreen.step1}
              <br />
                2. {translator.NewTeamScreen.step2} <br />3. {translator.NewTeamScreen.step3}
              <br />
              {translator.NewTeamScreen.youmay}
              <br />
              {translator.NewTeamScreen.nextFunc}
              <br />
              {translator.NewTeamScreen.finalText}
              <br />
              {translator.NewTeamScreen.policy}
            </div>
            <div id = "leftside-border" />
          </div>
          {/* Right section */}
          <div className = "newTeamRightSection">
            <TeamDesc
              availablePeopleList = { availablePeopleList }
              // eslint-disable-next-line no-shadow
              selectedFiles = { (fileArray, imageBase64) => {
                this.setState({
                  imageFile: fileArray,
                  imageBase64,
                });
              } }
              OnHide = { (e) => OnHide(e) }
              TeamDetails = { (e) => {
                TeamDetails(e);
              } }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewTeam);
