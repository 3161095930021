import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getNameInitials } from '../../utility/commonUtility';
import './Avatar.css';

class Avatar extends Component {
  render() {
    const { size , avatarColor , imageSource ,name ,fontSize } = this.props;
    return (
      <div
        style = { {
          backgroundColor: avatarColor,
          width: `${ size }px`,
        } }
        className = "avatarOuterDiv"
      >
        <div className = "avatarInnerContainer">
          {imageSource !== null ? (
            <img
              style = { {
                width: `${ size - 10 }px`,
                aspectRatio: '1',
                borderRadius: '100px',
              } }
              alt = "error"
              src = { imageSource }
            />
          ) : (
            <div
              className = "nameInitialsInAvatar"
              style = { {
                width: `${ size - 10 }px`,
                backgroundColor: avatarColor,
                fontSize,
              } }
            >
              <div> {getNameInitials(name)}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);
