import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import './LogFilterCss.css';
import { Search, Checkbox, Button } from '@postidigital/posti-components';
import { DateInput } from '@postidigital/posti-components/build/datepicker';
import moment from 'moment';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody, isRolePEC } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';
import Avatar from '../../components/Avatar/Avatar.js';
import CustomApiErrorDialog from '../Configuration/CustomApiErrorDialog.js';

const translator = new LocalizedStrings(translation);
class LogFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiflag: false,
      responseJson: null,
      // for starting date
      date1: '',
      //   for ending date
      date2: '',
      // for searchBar
      searchBarText: null,
      jsonCopy: null,
      // Result will be stored in it when search for people is done
      // searchPeopleResult: [],
      checkedEmployeeID: [],
      // Filter by type
      filterByType: null,

      resetButtonFlag: false,
      applyButtonFlag: false,

      filterByTypeClicked: false,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getLogFilterData();
    }, 2500);
  };

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.getLogFilterData();
    }
  }

  // For type column in Search people datatable
  // avatarBodyTemplate = (rowData) => (
  //   <Avatar
  //     imageSource = { rowData.Badge }
  //     className = "peopleBadgeAvatar"
  //     avatarColor = "#FFA894"
  //     size = { 66 }
  //     fontSize = "24px"
  //   />
  // );

  // For type column in Search people datatable
  // nameBodyTemplate = (rowData) => (
  //   <div className = "nameBodyTemplate">{rowData.Name}</div>
  // );

  // When Enter is pressed on people search Bar
  searchPeopleOnBaseOfInput = (e) => {
    const { responseJson } = this.state
    if (e) {
      const filtered = responseJson.filter((value) => value.firstName.toLowerCase().includes(e.toLowerCase()));
      this.setState({ jsonCopy: filtered });
    } else {
      this.setState({
        jsonCopy: responseJson,
      });
    }
  };

  // When checkBox on clicked
  onCheckBoxChange = (e, item) => {
    const { checkedEmployeeID, date1, date2, filterByType } = this.state
    const copyCheckedID = [ ...checkedEmployeeID ];

    if (e.target.checked) {
      copyCheckedID.push(item.userSourceSystemId);
    } else {
      copyCheckedID.splice(copyCheckedID.indexOf(item.userSourceSystemId), 1);
    }
    this.setState({
      checkedEmployeeID: copyCheckedID,

      resetButtonFlag: true,
      applyButtonFlag: true,
    });
    if (
      copyCheckedID.length === 0 &&
      date1 === '' &&
      date2 === '' &&
      filterByType == null
    ) {
      this.setState({
        resetButtonFlag: false,
        applyButtonFlag: false,
      });
    }
  };

  // When apply button is clicked
  applyButtonClicked = () => {
    const { parentCallback } = this.props
    const { checkedEmployeeID, filterByType, date1, date2 } = this.state
    parentCallback(
      checkedEmployeeID,
      filterByType,
      date1,
      date2
    );
    this.setState({
      applyButtonFlag: false,
    });
    if (filterByType !== null) {
      this.setState({
        filterByTypeClicked: true,
      });
    }
  };

  // When reset button is clicked
  resetButtonClicked = () => {
    const { responseJson } = this.state
    const { resetCallback } =this.props
    this.setState({
      checkedEmployeeID: [],
      filterByType: null,
      jsonCopy: responseJson,

      resetButtonFlag: false,
      applyButtonFlag: false,
      date1: '',
      date2: '',
      searchBarText: null,

      filterByTypeClicked: false,
    });

    if (isRolePEC() === false) {
      this.cssBeforeButtonClicked('teamButton');
    }
    this.cssBeforeButtonClicked('rewardsButton');

    this.cssBeforeButtonClicked('weatherButton');
    resetCallback();
  };

  getLogFilterData = () => {
    const { browserLanguage, selectedCostCenter } = this.props
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;

    this.commonService
      .postMethod(apiUrl.getPeopleScreenData, jsonBody)
      .then((res) => {
        // if (res.data.length <= 0) Toastr.showErrorMessage('No people found');
        this.setState({
          responseJson: res.data,
          jsonCopy: res.data,
        });
      })
      .catch(() => {
        // Toastr.showErrorMessage(translator.ToastMessages.fetchingLogScreen);
        this.setState({
          apiflag: true,
        });
      });
  };

  // Filter by type button initial css
  cssBeforeButtonClicked = (className) => {
    document.getElementsByClassName(className)[ 0 ].style.backgroundColor =
      '#F6F7F9';
    document.getElementsByClassName(className)[ 0 ].style.color = '#3B4A57';
  };

  // Filter by type button after clicked css
  cssAfterButtonClicked = (className) => {
    document.getElementsByClassName(className)[ 0 ].style.backgroundColor =
      '#29545C';
    document.getElementsByClassName(className)[ 0 ].style.color = '#FFFFFF';
  };

  // When Filter by type is clicked
  typeFilterClicked = (arg) => {
    const { filterByTypeClicked } =this.state
    if (arg === 'Team' && filterByTypeClicked === false) {
      if (isRolePEC() === false) {
        this.cssAfterButtonClicked('teamButton');
      }
      this.cssBeforeButtonClicked('rewardsButton');

      this.cssBeforeButtonClicked('weatherButton');
    } else if (arg === 'Rewards' && filterByTypeClicked === false) {
      this.cssAfterButtonClicked('rewardsButton');
      if (isRolePEC() === false) {
        this.cssBeforeButtonClicked('teamButton');
      }
      this.cssBeforeButtonClicked('weatherButton');
    } else if (arg === 'Weather' && filterByTypeClicked === false) {
      this.cssAfterButtonClicked('weatherButton');
      if (isRolePEC() === false) {
        this.cssBeforeButtonClicked('teamButton');
      }
      this.cssBeforeButtonClicked('rewardsButton');
    }

    if (filterByTypeClicked === false) {
      this.setState({
        filterByType: arg,
      });
    }
    if (filterByTypeClicked) {
      this.setState({
        resetButtonFlag: true,
      });
    } else {
      this.setState({
        resetButtonFlag: true,
        applyButtonFlag: true,
      });
    }
  };

  testFunction = (start, end) => {
    // if (start.length == undefined && end.length == undefined) {
    if (start._d && end._d) {
      if (end._d < start._d) {
        Toastr.showErrorMessage(translator.ToastMessages.dateCheck);
        this.setState({
          date2: '',

          resetButtonFlag: false,
          applyButtonFlag: false,
        });
      }
    }
  };

  render() {
    const { browserLanguage } = this.props
    const { apiflag, date1, date2,searchBarText, jsonCopy, checkedEmployeeID, applyButtonFlag, resetButtonFlag } = this.state
    translator.setLanguage(browserLanguage);
    return (
      <>
        {apiflag === true ? <CustomApiErrorDialog /> : null}
        <h3 className = "logFilterSubHeading">
          {translator.LogFilter.filterByType}
        </h3>
        <div
          className = {
            isRolePEC()
              ? 'filterUpperButtonsWrapperPEC'
              : 'filterUpperButtonsWrapper'
          }
        >
          {isRolePEC() === true ? null : (
            <button
              type = "button"
              className = "teamButton"
              onClick = { () => {
                this.typeFilterClicked('Team');
              } }
            >
              {translator.LogFilter.teamText}
            </button>
          )}

          <button
            type = "button"
            className = "rewardsButton"
            onClick = { () => {
              this.typeFilterClicked('Rewards');
            } }
          >
            {translator.LogFilter.rewardsText}
          </button>
          <button
            type = "button"
            className = "weatherButton"
            onClick = { () => {
              this.typeFilterClicked('Weather');
            } }
          >
            {translator.LogFilter.weatherText}
          </button>
        </div>
        <div className = "calenderContainer">
          <div className = "calenderContainerHeading">
            {translator.LogFilter.filterByDate}
          </div>
          <div className = "calendersWrapper">
            <DateInput
              id = "date1"
              isOutsideRange = { () => false }
              locale = { browserLanguage }
              firstDayOfWeek = { 0 }
              className = "startingCalender"
              label = { translator.LogFilter.startingDate }
              message = ""
              date = {
                date1 === ''
                  ? ''
                  : moment(date1).locale(browserLanguage)
              }
              nextMonthButtonAriaLabel = "Move to next month"
              prevMonthButtonAriaLabel = "Move to previous month"
              daySize = { 46.5 }
              onDateChange = { (data) => {
                if (data !== null && data !== '') {
                  this.setState({ date1: data });
                } else {
                  this.setState({});
                }
              } }
            />

            <DateInput
              id = "date2"
              isOutsideRange = { () => false }
              firstDayOfWeek = { 0 }
              className = "endingCalender"
              label = { translator.LogFilter.endingDate }
              locale = { browserLanguage }
              message = ""
              date = {
                date2 === ''
                  ? ''
                  : moment(date2).locale(browserLanguage)
              }
              nextMonthButtonAriaLabel = "Move to next month"
              prevMonthButtonAriaLabel = "Move to previous month"
              daySize = { 46.5 }
              onDateChange = { (data) => {
                if (data !== null && data !== '') {
                  this.setState({
                    date2: data,

                    resetButtonFlag: true,
                    applyButtonFlag: true,
                  });
                } else {
                  this.setState({});
                }
              } }
            />
            {this.testFunction(date1, date2)}
          </div>
        </div>
        <div className = "peopleSearchContainer">
          <div className = "peopleSearchContainerHeading">
            {translator.LogFilter.filterByPeople}
          </div>
          <div className = "searchBarPeopleContainer">
            <Search
              className = "searchBarPeople"
              placeholder = { translator.RewardsScreen.searchText1 }
              size = "sm"
              value = { searchBarText }
              onChange = { (e) => {
                this.searchPeopleOnBaseOfInput(e.target.value);
                this.setState({
                  searchBarText: e.target.value,
                });
              } }
              onEnter = { this.searchPeopleOnBaseOfInput }
            />
            <div className = "serachByPeopleContainer">
              <table>
                <tbody>
                  {jsonCopy != null
                    ? jsonCopy.map((item) => (
                      <tr key = { item.i } className = "peopleSearchResultWrapper">
                        <Checkbox
                          className = "peopleSearchResultCheckbox"
                          onChange = { (e) => {
                            this.onCheckBoxChange(e, item);
                          } }
                          checked = { checkedEmployeeID.includes(
                            item.userSourceSystemId
                          ) }
                        />
                        <td className = "peopleSearchResultAvatar">
                          <Avatar
                            imageSource = { item.userImagePath }
                            className = "peopleBadgeAvatar"
                            name = { item.firstName }
                            avatarColor = "#FFA894"
                            size = { 55 }
                            fontSize = "24px"
                          />
                        </td>
                        <td className = "peopleSearchResultName">
                          {item.firstName}
                        </td>
                      </tr>
                    ))
                    : null}
                </tbody>
              </table>

              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
        <div className = "weatherScreenRightSectionFooter">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div
            style = { {
              height: '8vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            } }
          >
            <button
              type = "button"
              className = {
                resetButtonFlag
                  ? translator.LogScreen.resetText === 'Reset' ? 'rightSideLogFooterResetButton' : 'rightSideLogFooterResetButton1' 
                  : translator.LogScreen.resetText === 'Reset' ? 'rightSideLogFooterResetButtonDisable' : 'rightSideLogFooterResetButtonDisable1'
              }
              onClick = { this.resetButtonClicked }
            >
              {translator.LogScreen.resetText}
            </button>
            <Button
              id = "applyButton"
              onClick = { this.applyButtonClicked }
              disabled = { !applyButtonFlag }
              style = { {
                marginRight: '2vh',
              } }
            >
              <div className = "applyTextLogFilterCss">
                {translator.LogFilter.applyText}
              </div>
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LogFilter);
