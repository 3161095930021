import React, { Component } from 'react';
import './AddToTeam.css';
import LocalizedStrings from 'react-localization';
import { Button, Dropdown, DropdownMenuItem } from '@postidigital/posti-components';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../../locales/translation.js';
import { CommonService } from '../../../service/CommonService.js';
import apiUrl from '../../../urlConstants.js';
import Toastr from '../../../shared/Toastr';
import { getJsonBody } from '../../../utility/commonUtility';
import Avatar from '../../../components/Avatar/Avatar';

const translator = new LocalizedStrings(translation);
class AddToTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      teamsList: [],
      selectedTeam: null,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    this.getTeamsList();
  };

  // Api call to get team list
  getTeamsList = () => {
    const jsonBody = getJsonBody();
    const { browserLanguage , selectedCostCenter } = this.props;
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.getTeamsInACostCenter, jsonBody)
      .then((res) => {
        this.setState({ teamsList: res.data });
      })
      .catch(() => {
        Toastr.showErrorMessage('Fetch Team API Error');
      });
  };

  // API to add members to a team
  addPeopleToTeam = () => {
    const jsonBody = getJsonBody();
    const { selectedCostCenter , peopleEmployeeIdList,onConfirm } = this.props;
    const { selectedTeam }= this.state;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.userSourceSystemIds = peopleEmployeeIdList;
    jsonBody.teamName = selectedTeam.teamName;
    this.commonService
      .postMethod(apiUrl.addToTeam, jsonBody)
      .then(() => {
        Toastr.showSuccessMessage(translator.ToastMessages.addedToTeamSuccesfully);
      })
      .catch(() => {
        Toastr.showErrorMessage('Add to a Team API Error');
      });
    onConfirm();
  };

  render() {
    const { browserLanguage ,peopleList,onCancel } = this.props;
    translator.setLanguage(browserLanguage);
    const { isOpen , selectedTeam , teamsList } = this.state;
    return (
      <Dialog
        showHeader = { false }
        visible 
        style = { { width: '40vw', height: '80vh' } }
        id = "addToTeamDialog"
      >
        <div className = "addToTeamHeader">Add to a team?</div>
        <div
          style = { {
            paddingBottom: '17px',
            height: 'calc(72vh - 74px)',
            overflowY: 'auto',
          } }
        >
          <div className = "addToTeamBody">Add selected employees to:</div>

          <div
            style = { {
              padding: '0 0 0 32px',
              minWidth: '200px',
              width: '60%',
            } }
          >
            <Dropdown
              id = "addToTeamDropdown"
              iconColor = "#73808C"
              isOpen = { isOpen }
              menuAriaLabel = "Example "
              title = {
                selectedTeam
                  ? selectedTeam.teamName
                  : translator.PeopleScreen.selectTeam
              }
              onSelect = { (e) => {
                this.setState({ selectedTeam: teamsList[ e ] });
              } }
              onToggle = { () => this.setState({ isOpen: !isOpen }) }
              overflowMenuContainerDirection = "right"
            >
              {teamsList.map((item, index) => (
                <DropdownMenuItem
                  key = { item.index }
                  allowWrap
                  aria-label = "Menu item"
                  tabIndex = { -1 }
                  value = { index }
                  selected = {
                    selectedTeam && selectedTeam.teamId === item.teamId
                  }
                >
                  {item.teamName}
                </DropdownMenuItem>
              ))}
            </Dropdown>
          </div>
          {peopleList &&
              peopleList.map((members) => (
                <div className = "addToTeamPeopleContainer" key = { members.index }>
                  <Avatar
                    imageSource = { members.userImagePath }
                    name = { members.firstName }
                    avatarColor = "#FFA894"
                    size = { 44 }
                    fontSize = "15px"
                  />
                  <div className = "addToTeamPeopleName">{members.firstName}</div>
                </div>
              ))}
        </div>
        {/* Footer */}
        <div style = { { position: 'absolute', bottom: '0', width: '40vw' } }>
          <div style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } } />
          <div className = "addToTeamFooter">
            <div aria-hidden = "true" className = "addToTeamFooterText" onClick = { () => onCancel() }>
              {translator.WeatherScreen.cancel}
            </div>
            <Button
              size = "sm"
              id = "addToTeamButton"
              disabled = { !selectedTeam }
              onClick = { () => {
                this.addPeopleToTeam();
              } }
            >
              {translator.WeatherScreen.confirm}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddToTeam);
