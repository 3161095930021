/* eslint-disable no-unused-expressions */
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import {
  Button,
  Dialog,
  Body,
  Tab,
  TabContext,
  TabPanel,
  Tabs,
  Radio,
  Search,
  Checkbox,
  Loading,
  Input,
  Textarea 
} from '@postidigital/posti-components';
import moment from 'moment';
import { translation } from '../../locales/translation';
import './Rewards.css';
import { CommonService } from '../../service/CommonService';
import Avatar from '../../components/Avatar/Avatar';
import AwardBadgeScreen from './AwardBadge';
import { BadgeDetails, BadgeDetailsEMD } from '../../utility/BadgesInfo';
import apiUrl from '../../urlConstants';
import 'moment/locale/fi';
import Toastr from '../../shared/Toastr';
import { getAppId, getJsonBody, isRolePEC } from '../../utility/commonUtility';

const translator = new LocalizedStrings(translation);

export class Rewards extends Component {
  constructor(props) {
    super(props);
    const { fromPeoplescreen,checkboxSelectedValues,checkboxSelectedData } = this.props;
    this.state = {
      inputText:null,
      labelFocus:true,
      labelFocus1:true,
      inputTextArea:"",
      currentTab: 'tab1',
      badgeValue: '',
      selectedImg: '',
      searchValue: '',
      checkboxSelectedValues: fromPeoplescreen
        ? checkboxSelectedValues
        : [],
      originalData: [],
      resetData: [],
      saveCard: false,
      checkboxSelectedData: fromPeoplescreen
        ? checkboxSelectedData
        : [],
      isLoading: true,
      editFlow: false,
      badgeDataPEC: null,
      badgeTitleAfterEdit: null,
      badgeDescAfterEdit: ""
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    const { selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.CostCenterId = selectedCostCenter.costCenterId;
    this.getEmployeeDetails(jsonBody);
    if(jsonBody.applicationId === 4 || getAppId() === 2)
    {
      this.getBadgeDetailsPEC();
    }
  };

  // Method to get employee details
  getEmployeeDetails = (body) => {
    const { closeRewardsOverlay } = this.props;
    this.commonService
      .postMethod(apiUrl.getPeopleScreenData, body)
      .then((res) => {
        res.data.sort((a, b) =>
          a.firstName > b.firstName ? 1 : b.firstName > a.firstName ? -1 : 0
        );
        this.setState({
          originalData: res.data,
          resetData: res.data,
          isLoading: false,
        });
      })
      .catch(() => {
        closeRewardsOverlay();
        Toastr.showErrorMessage('Error while Fetching Data');
      });
  };

  // To get exception badge details for REC
  getBadgeDetailsPEC = () => {
    const { selectedCostCenter,closeRewardsOverlay } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.getBadgeDataPEC, jsonBody)
      .then((res) => {
        // console.log(res.data,"dddddddddddd");
        let tempRes = res.data;
        // tempRes=tempRes.filter((ele)=>ele.badgeName !== "Safety")
        tempRes=tempRes.filter((ele)=>ele.badgeName !== "Kudos")
        this.setState({
          badgeDataPEC: tempRes,
        });
      })
      .catch(() => {
        closeRewardsOverlay();
        Toastr.showErrorMessage('Error while Fetching Data');
      });
  };

  // method showing final recipients to whom badge will be awarded
  finalRecipients = () => {
    const { checkboxSelectedData } = this.state;
    return(
      checkboxSelectedData.map((data) => (
        <div
          className = "flexBox"
          key = { data.userSourceSystemId }
          style = { { alignItems: 'center' } }
        >
          <div className = "avatarContainer">
            <Avatar
              imageSource = { data.userImagePath }
              name = { data.firstName }
              avatarColor = "#FFA894"
              size = { 46 }
              fontSize = "15px"
            />
          </div>
          <div className = "userNameText">{data.firstName}</div>
        </div>
      )))}

  // left section of the overlay when edit flow starts
  leftSectionOnEdit = () => {
    const { badgeValue,selectedImg,selectedBadgeName,selectedBadgePoints,selectedBadgeMsg,badgeDescAfterEdit,badgeTitleAfterEdit } = this.state;
    const { browserLanguage } = this.props;
    return (
      <div
        style = { {
          width: '56.7%',
          backgroundColor: '#FFFFFF',
        } }
      >
        <div className = "newRewardBanner">
          <div className = "newRewardText">
            {translator.RewardsScreen.newReward}
          </div>
          {badgeValue === '' ? (
            <div
              className = "badgeOnBanner"
              style = { {
                borderRadius: '100px',
                backgroundColor: '#E6EBF1',
              } }
            />
          ) : (
            <div className = "badgeOnBanner">
              <img
                alt = ""
                src = {
                  (isRolePEC() || getAppId()=== 2)
                    ? selectedImg
                    : require(`../../images/${ selectedImg }`)
                }
                style = { { height: '88px', width: '88px' } }
              />
            </div>
          )}
        </div>
        <div
          className = "tabInnerContainer"
          style = { {
            direction: 'rtl',
          } }
        >
          <div style = { { direction: 'ltr', marginLeft: '32px' } }>
            <div className = "rewards-Text">
              {translator.RewardsScreen.rewardText}
            </div>
            <div
              className = "flexBox"
              style = { { color: '#3B4A57', alignItems: 'center' } }
            >
              <div className = "typePointsText">
                {translator.RewardsScreen.typeText}
              </div>
              <div className = "badgeNamePointsText">
                {(isRolePEC() || getAppId()=== 2)
                  ? selectedBadgeName
                  : translator.BadgesText[ selectedBadgeName ]}
              </div>
            </div>
            <div
              className = "flexBox"
              style = { { color: '#3B4A57', alignItems: 'center' } }
            >
              <div className = "typePointsText">
                {translator.RewardsScreen.pointsText}
              </div>
              <div className = "badgeNamePointsText">
                {selectedBadgePoints}
              </div>
            </div>
          </div>
          <div className = "recipientsText">
            {translator.RewardsScreen.recipientsText}
          </div>
          <div style = { { direction: 'ltr', marginLeft: '32px' } }>
            {this.finalRecipients()}
          </div>
          <div style = { { direction: 'ltr', marginLeft: '32px',marginTop:'34.59px',fontFamily:'Montserrat',fontWeight:'600',fontSize:'16px',lineHeight:'125%',marginBottom:'18px' } }>
            {translator.RewardsScreen.Preview}
          </div>
          <div style = { { direction: 'ltr', marginLeft: '32px' } }>
            <div className = "messageBox">
              <div className = "momentText">
                {moment().locale(browserLanguage).format('llll')}
              </div>
              <div>
                <img
                  alt = ""
                  src = {
                    (isRolePEC() || getAppId()=== 2)
                      ? selectedImg
                      : require(`../../images/${ selectedImg }`)
                  }
                  style = { {
                    width: '40px',
                    aspectRatio: 1,
                    marginTop: '8px',
                  } }
                />
              </div>
              <div className = "badgeTitleText">
                {(isRolePEC() || getAppId() === 2 || getAppId() === 5)
                  ? (badgeTitleAfterEdit === null || badgeTitleAfterEdit === "") ? selectedBadgeName :badgeTitleAfterEdit
                  : translator.BadgesText[ selectedBadgeName ]}
              </div>
              <div className = "final-BadgeAwardMessage">
                {(isRolePEC() || getAppId() === 2 || getAppId() === 5)
                  ? badgeDescAfterEdit === "" ? selectedBadgeMsg : badgeDescAfterEdit
                  : translator.BadgesText[ selectedBadgeMsg ]}
              </div>
            </div>
          </div>
        </div>
        <div className = "dividerLine" style = { { marginLeft: '32px' } } />
        <div className = "buttonRow">
          <button
            type = "button"
            disabled
            className = "cancelButton"
            style = { { color: '#A7A7A7' } }
          >
            {translator.RewardsScreen.cancelText}
          </button>
          <Button id = "reward-nextButton" size = "sm" disabled>
            {translator.RewardsScreen.reward}
          </Button>
        </div>
      </div>
    )}

  // left section of the overlay before edit Flow
  leftSectionOfOverLay = () => {
    const { browserLanguage } = this.props;
    const { selectedImg,badgeValue } = this.state;

    translator.setLanguage(browserLanguage);
    return (
      <div
        style = { {
          width: '56.7%',
          backgroundColor: '#FFFFFF',
        } }
      >
        <div className = "newRewardBanner">
          <div className = "newRewardText">
            {translator.RewardsScreen.newReward}
          </div>
          {badgeValue === '' ? (
            <div
              className = "badgeOnBanner"
              style = { {
                backgroundColor: '#E6EBF1',
              } }
            />
          ) : (
            <div className = "badgeOnBanner">
              <img
                alt = ""
                src = {
                  (isRolePEC() || getAppId()=== 2)
                    ? selectedImg
                    : require(`../../images/${ selectedImg }`)
                }
                style = { { height: '88px', width: '88px' } }
              />
            </div>
          )}
        </div>
        <div className = "leftSectionTextContainer">
          <div style = { { paddingTop: '33.3px', lineHeight: '20.2px' } }>
            <p
              className = "selectRewardText"
              style = { {
                marginTop: '0px',
                marginBottom: '18px',
              } }
            >
              {translator.RewardsScreen.howTo}
            </p>
            <p style = { { marginBottom: 0, marginTop: 0 } }>
              {translator.RewardsScreen.welcomeToText}
            </p>
            <p style = { { marginBottom: 0, marginTop: 0 } }>
              {translator.RewardsScreen.threeSteps}{' '}
            </p>
            <ol
              style = { {
                marginBottom: 0,
                marginTop: 0,
                paddingLeft: '18px',
              } }
            >
              <li>{translator.RewardsScreen.listItemText1}</li>
              <li>{translator.RewardsScreen.listItemText2}</li>
              <li>{translator.RewardsScreen.listItemText3}</li>
              <li>{translator.RewardsScreen.listItemText4}</li>
            </ol>
            <p style = { { marginBottom: 0, marginTop: 0 } }>
              {translator.RewardsScreen.youMayProceedText}
            </p>
          </div>
        </div>
        <div className = "dividerLine" style = { { marginLeft: '32px' } } />
      </div>
    );
  };

  // Tab Section
  createTab = () => {
    const { browserLanguage } = this.props;
    const { currentTab,badgeValue,checkboxSelectedValues } = this.state;

    translator.setLanguage(browserLanguage);
    return (
      <div>
        <TabContext>
          <Tabs
            id = "tabStyle"
            selectedTab = { currentTab }
            onChange = { (e) => {
              this.setState({ currentTab: e });
            } }
          >
            <Tab
              value = "tab1"
              panelId = "id-tab1"
              className = "editTeam-tabStyle"
              id = {
                currentTab === 'tab1'
                  ? 'selectBadgeTabActive'
                  : 'selectBadgeTabInActive'
              }
            >
              {`1. ${ translator.RewardsScreen.typeText }`}
            </Tab>
            <Tab
              className = "editTeam-tabStyle"
              id = {
                currentTab === 'tab2'
                  ? 'selectRecipientsTabActive'
                  : 'selectRecipientsTabInActive'
              }
              value = "tab2"
              panelId = "id-tab2"
              disabled = { badgeValue === '' }
            >
              {`2. ${ translator.RewardsScreen.recipientsText }`}
            </Tab>
            <Tab
              className = "editTeam-tabStyle"
              id = {
                currentTab === 'tab3'
                  ? 'rewardMsgTabActive'
                  : 'rewardMsgTabInActive'
              }
              value = "tab3"
              panelId = "id-tab3"
              disabled = {
                checkboxSelectedValues.length === 0
              }
            >
              {`3. ${ translator.RewardsScreen.MessageText }`}
            </Tab>
          </Tabs>
          <TabPanel id = "badgesTab" value = "tab1">
            {this.badgesTab()}
          </TabPanel>
          <TabPanel id = "recipientsTab" value = "tab2">
            {this.recipientsTab()}
          </TabPanel>
          <TabPanel id = "messageContainer" value = "tab3">
            {this.messageTab()}
          </TabPanel>
        </TabContext>
      </div>
    );
  };

  // Badges Tab
  badgesTab = () => {
    const { browserLanguage,closeRewardsOverlay } = this.props;
    const { badgeDataPEC,badgeValue,editFlow } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <div>
        <div
          className = "tabInnerContainer"
          style = { {
            marginLeft: '32px',
          } }
        >
          <p className = "selectRewardText">
            {translator.RewardsScreen.selectRewardType}
          </p>
          <div>
            {(isRolePEC() || getAppId() === 2)
              ? badgeDataPEC
                ? this.selectBadge()
                : null
              : this.selectBadge()}
          </div>
        </div>
        <div className = "dividerLine" style = { { marginLeft: '32px' } } />
        <div className = "buttonRow" style = { { marginBottom: '10px' } }>
          <button
            type = "button"
            className = "cancelButton"
            onClick = { closeRewardsOverlay }
          >
            {translator.RewardsScreen.cancelText}
          </button>
          <Button
            id = "reward-nextButton"
            size = "sm"
            disabled = { badgeValue === '' }
            onClick = { () => {
              this.setState({ currentTab: 'tab2' });
            } }
          >
            {editFlow
              ? translator.NewTeamScreen.nextText
              : translator.NewTeamScreen.nextText}
          </Button>
        </div>
      </div>
    );
  };

  // Recipients Tab
  recipientsTab = () => {
    const { searchValue,checkboxSelectedValues,editFlow } = this.state;
    return (
      <div>
        <div className = "tabInnerContainer">
          <p className = "selectRewardText" style = { { marginTop: '33.3px' } }>
            {translator.RewardsScreen.selectRecipients}
          </p>
          <div id = "searchContainer">
            <Search
              id = "searchBox"
              aria-label = { translator.RewardsScreen.searchText1 }
              clearButtonProps = { {
                'aria-label': 'Clear',
              } }
              label = { translator.RewardsScreen.searchText1 }
              message = ""
              onChange = { (e) => {
                this.globalSearch(e.target.value);
              } }
              size = "sm"
              value = { searchValue }
            />
          </div>
          {this.selectRecipients()}
        </div>
        <div className = "dividerLine" style = { { marginLeft: '0px' } } />
        <div className = "buttonRow" style = { { marginBottom: '10px' } }>
          <button
            type = "button"
            className = "cancelButton"
            onClick = { () => {
              this.setState({ currentTab: 'tab1' });
            } }
          >
            {translator.NewTeamScreen.backText}
          </button>
          {/* )} */}
          <Button
            id = "reward-nextButton"
            size = "sm"
            disabled = {
              checkboxSelectedValues.length === 0
            }
            onClick = { () => {
              this.setState({ currentTab: 'tab3' });
            } }
          >
            {editFlow
              ? translator.NewTeamScreen.nextText
              : translator.NewTeamScreen.nextText}
          </Button>
        </div>
      </div>
    )}

  // Message Tab
  messageTab = () => {
    const { browserLanguage } = this.props;
    const { selectedBadgeName,selectedBadgeMsg, labelFocus, inputText, inputTextArea, labelFocus1,labelFocus2,labelFocus3 } = this.state;

    translator.setLanguage(browserLanguage);
    return (
      <div>
        <div className = "tabInnerContainer">
          <p className = "MessageTitleText">
            {translator.RewardsScreen.msgTitle}
          </p>
          {(isRolePEC() || getAppId() === 2 || getAppId() === 5)
            ? 
            <Input  
              className = "messageTitle"
              id = "textarea-id1"
              label = { labelFocus === true ? getAppId() === 5 ? translator.BadgesText[ selectedBadgeName ] : selectedBadgeName : "" } 
              value = { inputText }
              onFocus = { ()=>{
                // if(inputText === selectedBadgeName || inputText === null)
                // {
                //   this.setState({inputText:""});
                // }
                this.setState({
                  labelFocus:false,
                  labelFocus3: true,
                // inputText: ""
                }) 
              } }
              onMouseLeave = { ()=>{inputText === null && !labelFocus3 && this.setState({
                labelFocus:true
              })
              } }
              onBlur = { ()=>{
              // console.log("Blurrrr",inputText)
                (inputText=== null || inputText === '') &&   this.setState({ labelFocus:true })
              } }
              onChange = { (e)=>this.setState({
                inputText:e.target.value
              }) }
            />
            : <span className = "messageTitleStyle">{translator.BadgesText[ selectedBadgeName ]}</span>}
          <div style = { { display:'flex' } }>
            <div style = { { width:'50%' } }>
              <p
                style = { {
                  color: '#3B4A57',
                  fontSize: '16px',
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  marginTop: 30,
                } }
              >
                {translator.RewardsScreen.msgContent}
              </p>
            </div>
            {(isRolePEC() || getAppId() === 2 || getAppId() === 5) && <div style = { { width:'50%',paddingTop:'33px' } } className = "inputlength">{`${ inputTextArea.length === 0 ? labelFocus2 === true ? 0 : selectedBadgeMsg && selectedBadgeMsg.length : inputTextArea.length }/200`}</div>} 
          </div>
          <div >
            {(isRolePEC() || getAppId() === 2 || getAppId() === 5)
              ? 
              <Textarea
                style = { {
                  color: '#3B4A57',
                  fontSize: '16px',
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                } }
                className = "messageTitle"
                label = { labelFocus1 === true ? getAppId() === 5 ? translator.BadgesText[ selectedBadgeMsg ] :selectedBadgeMsg : "" }
                value = { inputTextArea } 
                onFocus = { ()=>{this.setState({
                  labelFocus1:false,
                  labelFocus2 : true
                })
                } }
                onMouseLeave = { ()=>{inputTextArea === "" && !labelFocus2 && this.setState({
                  labelFocus1:true,
                })
                } }
                onBlur = { ()=>{
                  // console.log("Blurrrr")
                  inputTextArea === "" && this.setState({ labelFocus1:true , labelFocus2 : false })
                } }  
                onChange = { (e) => this.setState({ inputTextArea: e.target.value }) } 
                id = "textarea-id"  
                rows = { 8 } 
                cols = { 60 } 
                maxlength = { 200 }
                
              />
              : <div className = "messageBodyStyle">{translator.BadgesText[ selectedBadgeMsg ]}</div>}
          </div>
        </div>
        <div className = "dividerLineRewards" />
        <div className = "buttonRow" style = { { marginBottom: '10px' } }>
          <button
            type = "button"
            className = "cancelButton"
            onClick = { () => {
              this.setState({ currentTab: 'tab2' });
            } }
          >
            {translator.NewTeamScreen.backText}
          </button>
          <Button
            id = "reward-nextButton"
            size = "sm"
            disabled = { false }
            onClick = { () => {
              this.setState({ saveCard: true });
            } }
          >
            {translator.NewTeamScreen.saveText}
          </Button>
        </div>
      </div>
    );
  };

  // selecting Badge which has to be awarded in Tab1(Type Tab)
  selectBadge = () => {
    const { browserLanguage } = this.props;
    const { badgeDataPEC } = this.state;
    translator.setLanguage(browserLanguage);
    let finalData;
    if ((isRolePEC() === true || getAppId() === 2)) {
      finalData = badgeDataPEC;
    } else {
      finalData = BadgeDetails;
    }
    const appid = getAppId();
    if( appid === 5)
    {
      finalData = BadgeDetailsEMD;
    }

    return finalData.map((data) => (
      <div
        className = "flexBox"
        style = { {
          alignItems: 'center',
          marginTop: '-25px',
          marginRight: getAppId() === 2 ?'15px' :'32px',
        } }
        key = {
          (isRolePEC() || getAppId()=== 2) ? data.badgeAchievementCode : data.BadgeAcheivmentCode
        }
      >
        <Radio
          size = "small"
          value = {
            (isRolePEC() || getAppId()=== 2) ? data.badgeAchievementCode : data.BadgeAcheivmentCode
          }
          onChange = { (e) => {
            this.setState({
              badgeValue: e.target.value,
              selectedImg: (isRolePEC() || getAppId()=== 2) ? data.badgeImage : data.BadgeImage,
              selectedBadgeName: (isRolePEC() || getAppId()=== 2)
                ? browserLanguage === 'en'
                  ? data.messageTitle.slice(0, -1)
                  : data.messageTitleFi.slice(0, -1)
                : data.BadgeName,
              selectedBadgeMsg: (isRolePEC() || getAppId()=== 2)
                ? browserLanguage === 'en'
                  ? data.badgeMessage
                  : data.badgeMessageFi
                : data.BadgeMessage,
              selectedBadgePoints: (isRolePEC() || getAppId()=== 2)
                ? data.badgePoints
                : data.BadgePoints,
              selectedBadgeAchievementCode: (isRolePEC() || getAppId()=== 2)
                ? data.badgeAchievementCode
                : data.BadgeAcheivmentCode,
            });
          } }
        />
        <img
          alt = ""
          src = {
            (isRolePEC() || getAppId() === 2)
              ? data.badgeImage
              : require(`../../images/${ data.BadgeImage }`)
          }
          style = { {
            width: '66px',
            aspectRatio: 1,
            margin: '20px',
          } }
        />
        <div>
          <p className = "badgeName">
            {(isRolePEC() || getAppId()=== 2)
              ? browserLanguage === 'en'
                ? data.messageTitle.slice(0, -1)
                : data.messageTitleFi.slice(0, -1)
              : translator.BadgesText[ data.BadgeName ]}
          </p>
          <p className = "badgeDescription">
            {(isRolePEC() || getAppId()=== 2)
              ? browserLanguage === 'en'
                ? data.bagdeDescription
                : data.bagdeDescriptionFi
              : translator.BadgesText[ data.BadgeDescription ]}
          </p>
        </div>
      </div>
    ));
  };

  // Search Functionality in Tab2(Recipients Tab)
  globalSearch = (search) => {
    const { resetData } = this.state
    this.setState({ searchValue: search });
    if (search) {
      const filtered = resetData.filter((value) => value.firstName.toLowerCase().includes(search.toLowerCase()));
      this.setState({ originalData: filtered });
    } else {
      this.setState({ originalData: resetData });
    }
  };

  // handle change function for checkbox in Tab2(Recipients Tab)
  handleChange = (e, membersData) => {
    const { checkboxSelectedValues, checkboxSelectedData } = this.state
    const blurcheck = e.target.value;
    document.getElementById(blurcheck).blur();
    const selectedMembers = [ ...checkboxSelectedValues ];
    const selectedMemberData = [ ...checkboxSelectedData ];
    if (e.target.checked) {
      selectedMembers.push(e.target.value);
      selectedMemberData.push(membersData);
    } else {
      selectedMembers.splice(selectedMembers.indexOf(e.target.value), 1);
      selectedMemberData.splice(selectedMemberData.indexOf(membersData), 1);
    }
    this.setState({
      checkboxSelectedValues: selectedMembers,
      checkboxSelectedData: selectedMemberData,
    });
  };

  // select Recipients to award Badge in Tab2(Recipients Tab)
  selectRecipients = () => {
    const { originalData,checkboxSelectedValues } = this.state;
    return(originalData.map((data) => (
      <div
        className = "flexBox"
        style = { { alignItems: 'center' } }
        key = { data.userSourceSystemId }
      >
        <div style = { { marginTop: '20px' } }>
          <Checkbox
            id = { data.userSourceSystemId }
            onChange = { (e) => {
              this.handleChange(e, data);
            } }
            checked = { checkboxSelectedValues.includes(
              data.userSourceSystemId
            ) }
            value = { data.userSourceSystemId }
          />
        </div>
        <div className = "avatarContainer">
          <Avatar
            imageSource = { data.userImagePath }
            name = { data.firstName }
            avatarColor = "#FFA894"
            size = { 46 }
            fontSize = "15px"
          />
        </div>
        <div className = "userNameText">{data.firstName}</div>
      </div>
    )))}

  render() {
    const { browserLanguage,closeRewardsOverlay } = this.props;
    const { inputText, inputTextArea, saveCard,editFlow,isLoading,badgeValue,selectedBadgeAchievementCode,selectedImg,selectedBadgePoints,checkboxSelectedValues,checkboxSelectedData,selectedBadgeName,selectedBadgeMsg } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        <Dialog
          id = { saveCard ? 'saveCardOverLay' : 'rewards_overlay' }
          disableOutsideClick
          fullSizeMainContent
          maxWidth = "95rem"
          disableButtons = { false }
          onClose = { closeRewardsOverlay }
          noCloseButton
          style = {
            isRolePEC()
              ? saveCard === false
                ? { width: '1005.3px' }
                : { width: '562.82px' }
              : saveCard === false ? { width: '1005.3px' } : { width: "562.82px" }
          }
        >
          <Body size = "One" as = "div">
            <div className = { saveCard ? 'flexBoxNone' : 'flexBox' }>
              {editFlow
                ? this.leftSectionOnEdit()
                : this.leftSectionOfOverLay()}

              <div
                style = { {
                  width: '1.3%',
                  backgroundColor: '#F6F7F9',
                  height: 'auto',
                } }
              />
              <div
                style = { {
                  width: '42%',
                  backgroundColor: '#FFFFFF',
                } }
              >
                {this.createTab()}
              </div>
            </div>
            {saveCard && (
              <AwardBadgeScreen
                inputText = { inputText }
                inputTextArea = { inputTextArea }
                selectedBadgeAchievementCode = {
                  selectedBadgeAchievementCode
                }
                selectedImg = { selectedImg }
                checkboxSelectedData = { checkboxSelectedData }
                closeRewardsOverlay = { closeRewardsOverlay }
                selectedBadgeName = { selectedBadgeName }
                selectedBadgeMsg = { selectedBadgeMsg }
                selectedBadgePoints = { selectedBadgePoints }
                checkboxSelectedValues = { checkboxSelectedValues }
                badgeValue = { badgeValue }
                closeAwardBadge = { (inputText1,inputTextArea1) => {
                  this.setState({
                    saveCard: false,
                    currentTab: 'tab1',
                    editFlow: true,
                    badgeTitleAfterEdit : inputText1,
                    badgeDescAfterEdit: inputTextArea1
                  });
                } }
              />
            )}
          </Body>
        </Dialog>
        {isLoading && (
          <Dialog
            id = "spinner"
            onClose = { closeRewardsOverlay }
            noCloseButton
          >
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
