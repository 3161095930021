/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */
export const MapRegions= {
  regions:[
    {
      id: 1,
      regionName: 'eCom TP  Espoo',
      coordinates: [
        { lng:24.5043407	, lat:60.2554357 },
        { lng:24.504347	, lat:60.2565002 },
        { lng:24.5044502	, lat:60.2740712 },
        { lng:24.5108282	, lat:60.2782171 },
        { lng:24.5155572	, lat:60.2812908 },
        { lng:24.5154875	, lat:60.2814954 },
        { lng:24.5151791	, lat:60.2824006 },
        { lng:24.5147983	, lat:60.2835122 },
        { lng:24.5142959	, lat:60.2849957 },
        { lng:24.5105861	, lat:60.28564 },
        { lng:24.508885	, lat:60.285934 },
        { lng:24.5077908	, lat:60.2861231 },
        { lng:24.4992904	, lat:60.2875918 },
        { lng:24.4994561	, lat:60.2875964 },
        { lng:24.5008161	, lat:60.288088 },
        { lng:24.5050268	, lat:60.289458 },
        { lng:24.5051132	, lat:60.2894858 },
        { lng:24.5071151	, lat:60.2901336 },
        { lng:24.5084691	, lat:60.2905752 },
        { lng:24.5107359	, lat:60.2913134 },
        { lng:24.5110283	, lat:60.2914087 },
        { lng:24.5114932	, lat:60.2915619 },
        { lng:24.5117287	, lat:60.2916394 },
        { lng:24.5120248	, lat:60.2917332 },
        { lng:24.5120232	, lat:60.2917369 },
        { lng:24.5117126	, lat:60.2925992 },
        { lng:24.5113319	, lat:60.2937229 },
        { lng:24.5106875	, lat:60.2955881 },
        { lng:24.5085303	, lat:60.3019424 },
        { lng:24.5052391	, lat:60.3116338 },
        { lng:24.5052264	, lat:60.3116701 },
        { lng:24.5052119	, lat:60.3117127 },
        { lng:24.5050488	, lat:60.3121659 },
        { lng:24.5047469	, lat:60.3130082 },
        { lng:24.5045889	, lat:60.3134963 },
        { lng:24.5023151	, lat:60.3201071 },
        { lng:24.5020428	, lat:60.3208514 },
        { lng:24.5020366	, lat:60.3208684 },
        { lng:24.5019234	, lat:60.3212426 },
        { lng:24.5001716	, lat:60.3262898 },
        { lng:24.5008382	, lat:60.3261526 },
        { lng:24.5065789	, lat:60.3250354 },
        { lng:24.5077833	, lat:60.3248061 },
        { lng:24.5075539	, lat:60.3250916 },
        { lng:24.5102698	, lat:60.3252102 },
        { lng:24.5103019	, lat:60.3251922 },
        { lng:24.5102754	, lat:60.3249887 },
        { lng:24.5104915	, lat:60.3247106 },
        { lng:24.5106436	, lat:60.3243131 },
        { lng:24.5418782	, lat:60.3182022 },
        { lng:24.5434017	, lat:60.3178954 },
        { lng:24.5441316	, lat:60.3177485 },
        { lng:24.5453961	, lat:60.3174935 },
        { lng:24.5495396	, lat:60.316687 },
        { lng:24.5523862	, lat:60.3161233 },
        { lng:24.5529404	, lat:60.3160137 },
        { lng:24.5543522	, lat:60.3157363 },
        { lng:24.5548088	, lat:60.3156419 },
        { lng:24.5563691	, lat:60.3153326 },
        { lng:24.5624759	, lat:60.3141609 },
        { lng:24.5662721	, lat:60.3152984 },
        { lng:24.581552	, lat:60.3200059 },
        { lng:24.582892	, lat:60.320405 },
        { lng:24.5837071	, lat:60.3206479 },
        { lng:24.5845122	, lat:60.3208876 },
        { lng:24.5845746	, lat:60.3209061 },
        { lng:24.5886731	, lat:60.3221214 },
        { lng:24.5956633	, lat:60.3241941 },
        { lng:24.5991688	, lat:60.3274124 },
        { lng:24.5992008	, lat:60.3274416 },
        { lng:24.6009114	, lat:60.3290078 },
        { lng:24.6050631	, lat:60.3327988 },
        { lng:24.6058415	, lat:60.3334957 },
        { lng:24.6059664	, lat:60.333628 },
        { lng:24.6059966	, lat:60.33366 },
        { lng:24.6060483	, lat:60.3336968 },
        { lng:24.6066556	, lat:60.3341837 },
        { lng:24.6078909	, lat:60.3352342 },
        { lng:24.6139169	, lat:60.344105 },
        { lng:24.6154973	, lat:60.3461721 },
        { lng:24.6165847	, lat:60.3480458 },
        { lng:24.6181754	, lat:60.3503982 },
        { lng:24.62678	, lat:60.3582017 },
        { lng:24.6278118	, lat:60.3591311 },
        { lng:24.6359411	, lat:60.3583084 },
        { lng:24.6486193	, lat:60.3601954 },
        { lng:24.6515314	, lat:60.3606623 },
        { lng:24.6647004	, lat:60.3627734 },
        { lng:24.6661055	, lat:60.3639149 },
        { lng:24.6662068	, lat:60.3637338 },
        { lng:24.6665279	, lat:60.3635222 },
        { lng:24.6670293	, lat:60.363369 },
        { lng:24.667294	, lat:60.3633355 },
        { lng:24.6676544	, lat:60.3633383 },
        { lng:24.6681478	, lat:60.3633053 },
        { lng:24.6686759	, lat:60.3632543 },
        { lng:24.668995	, lat:60.363213 },
        { lng:24.6696935	, lat:60.3629261 },
        { lng:24.6700877	, lat:60.3626253 },
        { lng:24.6706285	, lat:60.362149 },
        { lng:24.6711636	, lat:60.361773 },
        { lng:24.6715015	, lat:60.3614666 },
        { lng:24.6715859	, lat:60.3613106 },
        { lng:24.6715804	, lat:60.3610682 },
        { lng:24.6720479	, lat:60.3608259 },
        { lng:24.6722675	, lat:60.3607201 },
        { lng:24.6726055	, lat:60.3605446 },
        { lng:24.6729209	, lat:60.3604109 },
        { lng:24.6731012	, lat:60.3602967 },
        { lng:24.6732532	, lat:60.360149 },
        { lng:24.6733715	, lat:60.3599875 },
        { lng:24.6734448	, lat:60.3598259 },
        { lng:24.673518	, lat:60.3596253 },
        { lng:24.6735968	, lat:60.3595055 },
        { lng:24.6737095	, lat:60.3594554 },
        { lng:24.6738744	, lat:60.3594333 },
        { lng:24.6740305	, lat:60.3593997 },
        { lng:24.6743967	, lat:60.3594053 },
        { lng:24.6747346	, lat:60.3594471 },
        { lng:24.6749824	, lat:60.3595055 },
        { lng:24.6751852	, lat:60.359578 },
        { lng:24.6753711	, lat:60.3596086 },
        { lng:24.6755119	, lat:60.3595808 },
        { lng:24.6755739	, lat:60.3595 },
        { lng:24.6755795	, lat:60.3594136 },
        { lng:24.6755739	, lat:60.3592855 },
        { lng:24.6755908	, lat:60.3591685 },
        { lng:24.6756302	, lat:60.3590877 },
        { lng:24.6757034	, lat:60.3590125 },
        { lng:24.6758724	, lat:60.3589568 },
        { lng:24.6760639	, lat:60.3589373 },
        { lng:24.6765314	, lat:60.3589512 },
        { lng:24.6770158	, lat:60.3589623 },
        { lng:24.6772805	, lat:60.3589484 },
        { lng:24.6774551	, lat:60.3588816 },
        { lng:24.6775959	, lat:60.3588203 },
        { lng:24.6776523	, lat:60.3587562 },
        { lng:24.6777353	, lat:60.3587167 },
        { lng:24.6777757	, lat:60.3586906 },
        { lng:24.6778719	, lat:60.3586336 },
        { lng:24.6780128	, lat:60.3585891 },
        { lng:24.6781367	, lat:60.3585612 },
        { lng:24.6783282	, lat:60.3585166 },
        { lng:24.6785634	, lat:60.3584752 },
        { lng:24.6789196	, lat:60.3583941 },
        { lng:24.679342	, lat:60.35833 },
        { lng:24.6798095	, lat:60.3582882 },
        { lng:24.6800743	, lat:60.3582938 },
        { lng:24.6802433	, lat:60.3583161 },
        { lng:24.6804066	, lat:60.3583579 },
        { lng:24.6811614	, lat:60.3585556 },
        { lng:24.6815444	, lat:60.3586225 },
        { lng:24.6819781	, lat:60.3586587 },
        { lng:24.6822991	, lat:60.3586615 },
        { lng:24.6826089	, lat:60.358642 },
        { lng:24.6828568	, lat:60.3586058 },
        { lng:24.6830764	, lat:60.3585306 },
        { lng:24.6832679	, lat:60.3583996 },
        { lng:24.6834932	, lat:60.3582743 },
        { lng:24.6837242	, lat:60.3582297 },
        { lng:24.6842367	, lat:60.3581963 },
        { lng:24.6847324	, lat:60.3580821 },
        { lng:24.6850591	, lat:60.358018 },
        { lng:24.6856449	, lat:60.3579567 },
        { lng:24.6858702	, lat:60.3579511 },
        { lng:24.6860617	, lat:60.3580319 },
        { lng:24.6865517	, lat:60.3582743 },
        { lng:24.6867996	, lat:60.3583411 },
        { lng:24.6869516	, lat:60.3583495 },
        { lng:24.68716	, lat:60.3583244 },
        { lng:24.6878641	, lat:60.358018 },
        { lng:24.6887315	, lat:60.3577617 },
        { lng:24.6895482	, lat:60.3575416 },
        { lng:24.6901228	, lat:60.3574441 },
        { lng:24.6905565	, lat:60.3574079 },
        { lng:24.6913225	, lat:60.3574163 },
        { lng:24.6920547	, lat:60.3574386 },
        { lng:24.6924377	, lat:60.3574358 },
        { lng:24.6926405	, lat:60.3573606 },
        { lng:24.6928208	, lat:60.3572798 },
        { lng:24.6933559	, lat:60.3568006 },
        { lng:24.6938628	, lat:60.3565443 },
        { lng:24.6946739	, lat:60.3561682 },
        { lng:24.6951583	, lat:60.3560428 },
        { lng:24.6960201	, lat:60.3559398 },
        { lng:24.6962679	, lat:60.3558729 },
        { lng:24.6964425	, lat:60.3557531 },
        { lng:24.6965101	, lat:60.3555859 },
        { lng:24.6969213	, lat:60.3550204 },
        { lng:24.6973719	, lat:60.3545663 },
        { lng:24.6978225	, lat:60.3542487 },
        { lng:24.7007289	, lat:60.3529085 },
        { lng:24.7008922	, lat:60.3528695 },
        { lng:24.7011119	, lat:60.3528584 },
        { lng:24.7023961	, lat:60.3528695 },
        { lng:24.7033255	, lat:60.3527553 },
        { lng:24.7036916	, lat:60.3527469 },
        { lng:24.7039563	, lat:60.3527748 },
        { lng:24.7113784	, lat:60.3509661 },
        { lng:24.7199726	, lat:60.3488725 },
        { lng:24.732227	, lat:60.3459003 },
        { lng:24.7423598	, lat:60.3434585 },
        { lng:24.7434356	, lat:60.3431992 },
        { lng:24.7492994	, lat:60.341786 },
        { lng:24.7491415	, lat:60.3408554 },
        { lng:24.7482672	, lat:60.335389 },
        { lng:24.7479832	, lat:60.3336126 },
        { lng:24.7474355	, lat:60.3292812 },
        { lng:24.7470279	, lat:60.3268642 },
        { lng:24.7469459	, lat:60.3264666 },
        { lng:24.7468225	, lat:60.325519 },
        { lng:24.7468054	, lat:60.325388 },
        { lng:24.7464627	, lat:60.3234487 },
        { lng:24.7462404	, lat:60.3222773 },
        { lng:24.7609248	, lat:60.3253743 },
        { lng:24.7619421	, lat:60.3229304 },
        { lng:24.7621789	, lat:60.3222169 },
        { lng:24.7629191	, lat:60.3204728 },
        { lng:24.7635009	, lat:60.3190132 },
        { lng:24.7635229	, lat:60.3189583 },
        { lng:24.7648679	, lat:60.315584 },
        { lng:24.7653381	, lat:60.314399 },
        { lng:24.7666117	, lat:60.3114211 },
        { lng:24.7678155	, lat:60.3086056 },
        { lng:24.7684251	, lat:60.3071258 },
        { lng:24.769196	, lat:60.3053199 },
        { lng:24.7694499	, lat:60.3047067 },
        { lng:24.7695067	, lat:60.3045698 },
        { lng:24.7705547	, lat:60.3020397 },
        { lng:24.7706884	, lat:60.3017168 },
        { lng:24.7708113	, lat:60.3014207 },
        { lng:24.7708776	, lat:60.3012608 },
        { lng:24.7709543	, lat:60.3010762 },
        { lng:24.7710356	, lat:60.3008794 },
        { lng:24.7711352	, lat:60.3006315 },
        { lng:24.771801	, lat:60.2992165 },
        { lng:24.7717229	, lat:60.2988709 },
        { lng:24.7711941	, lat:60.2968204 },
        { lng:24.7711158	, lat:60.296501 },
        { lng:24.7710619	, lat:60.2962754 },
        { lng:24.7710074	, lat:60.2960584 },
        { lng:24.7687715	, lat:60.2869257 },
        { lng:24.7672625	, lat:60.2807609 },
        { lng:24.7656884	, lat:60.274307 },
        { lng:24.7650684	, lat:60.2717917 },
        { lng:24.7649364	, lat:60.2712464 },
        { lng:24.7654861	, lat:60.270065 },
        { lng:24.765652	, lat:60.2701495 },
        { lng:24.7667272	, lat:60.269714 },
        { lng:24.7679559	, lat:60.2692189 },
        { lng:24.7693353	, lat:60.2686632 },
        { lng:24.7699768	, lat:60.2683345 },
        { lng:24.7685378	, lat:60.2669064 },
        { lng:24.7671623	, lat:60.2663036 },
        { lng:24.7676557	, lat:60.2651893 },
        { lng:24.7679621	, lat:60.2643796 },
        { lng:24.7679684	, lat:60.2638154 },
        { lng:24.7677635	, lat:60.2636786 },
        { lng:24.7686989	, lat:60.2626407 },
        { lng:24.7690343	, lat:60.2622554 },
        { lng:24.7691072	, lat:60.2621681 },
        { lng:24.7691363	, lat:60.2621332 },
        { lng:24.7691652	, lat:60.262102 },
        { lng:24.7692115	, lat:60.2620519 },
        { lng:24.7695585	, lat:60.2620766 },
        { lng:24.7698836	, lat:60.261901 },
        { lng:24.7699684	, lat:60.2617613 },
        { lng:24.7699299	, lat:60.2614233 },
        { lng:24.769911	, lat:60.2612571 },
        { lng:24.7703573	, lat:60.2607933 },
        { lng:24.7705244	, lat:60.2607819 },
        { lng:24.7709521	, lat:60.2603756 },
        { lng:24.7722935	, lat:60.2591009 },
        { lng:24.7724305	, lat:60.2589623 },
        { lng:24.7728555	, lat:60.2585148 },
        { lng:24.7730307	, lat:60.2583598 },
        { lng:24.7731025	, lat:60.2582931 },
        { lng:24.7731611	, lat:60.2582387 },
        { lng:24.7738902	, lat:60.2575609 },
        { lng:24.7740363	, lat:60.257415 },
        { lng:24.7740514	, lat:60.2573942 },
        { lng:24.7740745	, lat:60.2573625 },
        { lng:24.7740942	, lat:60.2573334 },
        { lng:24.7744031	, lat:60.256878 },
        { lng:24.775269	, lat:60.2555692 },
        { lng:24.7753063	, lat:60.2555128 },
        { lng:24.775432	, lat:60.2553227 },
        { lng:24.775819	, lat:60.2547333 },
        { lng:24.7761539	, lat:60.2541915 },
        { lng:24.7761996	, lat:60.2541251 },
        { lng:24.7762427	, lat:60.2540624 },
        { lng:24.7763119	, lat:60.2539618 },
        { lng:24.7763512	, lat:60.2539046 },
        { lng:24.7770683	, lat:60.2529115 },
        { lng:24.7773111	, lat:60.2525492 },
        { lng:24.7774543	, lat:60.2523263 },
        { lng:24.7774766	, lat:60.2522915 },
        { lng:24.7775155	, lat:60.252234 },
        { lng:24.7796296	, lat:60.2490962 },
        { lng:24.779708	, lat:60.2489798 },
        { lng:24.7802506	, lat:60.2481624 },
        { lng:24.7810992	, lat:60.2470717 },
        { lng:24.7835043	, lat:60.2433489 },
        { lng:24.783641	, lat:60.2431437 },
        { lng:24.7838861	, lat:60.2427759 },
        { lng:24.784756	, lat:60.2415058 },
        { lng:24.7850613	, lat:60.2415895 },
        { lng:24.7860569	, lat:60.2418639 },
        { lng:24.7873929	, lat:60.2422199 },
        { lng:24.7877029	, lat:60.2423007 },
        { lng:24.7881196	, lat:60.2424095 },
        { lng:24.7894979	, lat:60.2427938 },
        { lng:24.7896835	, lat:60.2428456 },
        { lng:24.7936592	, lat:60.2439544 },
        { lng:24.7946459	, lat:60.2442109 },
        { lng:24.7947436	, lat:60.2442363 },
        { lng:24.7965204	, lat:60.2446984 },
        { lng:24.7966337	, lat:60.2447333 },
        { lng:24.7969576	, lat:60.2448121 },
        { lng:24.79764	, lat:60.2449895 },
        { lng:24.7981083	, lat:60.2451083 },
        { lng:24.7982895	, lat:60.2451582 },
        { lng:24.8004301	, lat:60.2457497 },
        { lng:24.8010874	, lat:60.2459293 },
        { lng:24.8012795	, lat:60.2459811 },
        { lng:24.8013891	, lat:60.246011 },
        { lng:24.8018961	, lat:60.246149 },
        { lng:24.8020339	, lat:60.2461866 },
        { lng:24.8026767	, lat:60.2463617 },
        { lng:24.8030796	, lat:60.2464752 },
        { lng:24.8039359	, lat:60.2467047 },
        { lng:24.8041153	, lat:60.2467536 },
        { lng:24.8044553	, lat:60.2468461 },
        { lng:24.8045231	, lat:60.2468647 },
        { lng:24.8046338	, lat:60.2468924 },
        { lng:24.8061284	, lat:60.2473079 },
        { lng:24.8062773	, lat:60.2473482 },
        { lng:24.8078776	, lat:60.247781 },
        { lng:24.8081431	, lat:60.2478527 },
        { lng:24.8083359	, lat:60.2479045 },
        { lng:24.8098269	, lat:60.2483042 },
        { lng:24.8110427	, lat:60.2486266 },
        { lng:24.8120074	, lat:60.2488825 },
        { lng:24.813201	, lat:60.249199 },
        { lng:24.8153201	, lat:60.2497609 },
        { lng:24.8168178	, lat:60.2501713 },
        { lng:24.8180775	, lat:60.2504938 },
        { lng:24.8206987	, lat:60.251187 },
        { lng:24.8224167	, lat:60.2516427 },
        { lng:24.8226527	, lat:60.2517053 },
        { lng:24.8229433	, lat:60.2517832 },
        { lng:24.8274664	, lat:60.2529976 },
        { lng:24.8282926	, lat:60.2532182 },
        { lng:24.8314026	, lat:60.254055 },
        { lng:24.8335636	, lat:60.2473147 },
        { lng:24.8336092	, lat:60.2471746 },
        { lng:24.8336486	, lat:60.2470531 },
        { lng:24.8341736	, lat:60.2453656 },
        { lng:24.8343272	, lat:60.244941 },
        { lng:24.8345858	, lat:60.2441331 },
        { lng:24.8347517	, lat:60.2435828 },
        { lng:24.8348028	, lat:60.2434163 },
        { lng:24.8349045	, lat:60.2430586 },
        { lng:24.8349149	, lat:60.2430238 },
        { lng:24.8349418	, lat:60.2429337 },
        { lng:24.8351164	, lat:60.2423929 },
        { lng:24.8354172	, lat:60.2415378 },
        { lng:24.8356389	, lat:60.2408728 },
        { lng:24.8358196	, lat:60.2402692 },
        { lng:24.8358271	, lat:60.2402504 },
        { lng:24.8358638	, lat:60.2401589 },
        { lng:24.8358669	, lat:60.2401326 },
        { lng:24.8358684	, lat:60.2401092 },
        { lng:24.8361846	, lat:60.239132 },
        { lng:24.8361949	, lat:60.2390986 },
        { lng:24.8362854	, lat:60.2388124 },
        { lng:24.836627	, lat:60.237747 },
        { lng:24.8367431	, lat:60.2373897 },
        { lng:24.8369365	, lat:60.2367604 },
        { lng:24.8359611	, lat:60.2366856 },
        { lng:24.8359766	, lat:60.2366273 },
        { lng:24.836	, lat:60.2365448 },
        { lng:24.8361293	, lat:60.2360897 },
        { lng:24.8361669	, lat:60.2359601 },
        { lng:24.8362807	, lat:60.2355665 },
        { lng:24.8363621	, lat:60.2352844 },
        { lng:24.836674	, lat:60.234199 },
        { lng:24.8369152	, lat:60.2332932 },
        { lng:24.8369731	, lat:60.2330927 },
        { lng:24.8369897	, lat:60.233035 },
        { lng:24.8370137	, lat:60.2329626 },
        { lng:24.8370394	, lat:60.2328845 },
        { lng:24.8370448	, lat:60.2328683 },
        { lng:24.8371839	, lat:60.232373 },
        { lng:24.8372058	, lat:60.2322952 },
        { lng:24.837239	, lat:60.2321771 },
        { lng:24.8378388	, lat:60.2300417 },
        { lng:24.837967	, lat:60.2295614 },
        { lng:24.8379909	, lat:60.2294751 },
        { lng:24.8381158	, lat:60.2290273 },
        { lng:24.8393258	, lat:60.2290986 },
        { lng:24.8399692	, lat:60.2291364 },
        { lng:24.8400782	, lat:60.228682 },
        { lng:24.840167	, lat:60.2283354 },
        { lng:24.8401856	, lat:60.2282633 },
        { lng:24.8401943	, lat:60.2282288 },
        { lng:24.8403418	, lat:60.2276388 },
        { lng:24.8403869	, lat:60.2274882 },
        { lng:24.8404375	, lat:60.2272629 },
        { lng:24.8405098	, lat:60.226941 },
        { lng:24.8405189	, lat:60.2269064 },
        { lng:24.8405309	, lat:60.22686 },
        { lng:24.8407162	, lat:60.226105 },
        { lng:24.8407447	, lat:60.22596 },
        { lng:24.8408356	, lat:60.2256332 },
        { lng:24.8408451	, lat:60.2255952 },
        { lng:24.8408608	, lat:60.225531 },
        { lng:24.8408731	, lat:60.2254699 },
        { lng:24.8411347	, lat:60.2244493 },
        { lng:24.8411575	, lat:60.2243553 },
        { lng:24.8411742	, lat:60.2242861 },
        { lng:24.8415245	, lat:60.222839 },
        { lng:24.8416284	, lat:60.2224108 },
        { lng:24.8416762	, lat:60.2222341 },
        { lng:24.8416945	, lat:60.2221486 },
        { lng:24.8418357	, lat:60.2214834 },
        { lng:24.8418757	, lat:60.221319 },
        { lng:24.8419097	, lat:60.2212042 },
        { lng:24.8431684	, lat:60.2216057 },
        { lng:24.8439422	, lat:60.2220277 },
        { lng:24.8451636	, lat:60.2227398 },
        { lng:24.8462638	, lat:60.2232636 },
        { lng:24.8470982	, lat:60.2238626 },
        { lng:24.8483652	, lat:60.2243486 },
        { lng:24.8490783	, lat:60.2246613 },
        { lng:24.8496517	, lat:60.2248281 },
        { lng:24.8502425	, lat:60.2249204 },
        { lng:24.8508525	, lat:60.2249653 },
        { lng:24.8513692	, lat:60.2249752 },
        { lng:24.8512016	, lat:60.2248264 },
        { lng:24.8485046	, lat:60.2222423 },
        { lng:24.8478581	, lat:60.2216022 },
        { lng:24.8478072	, lat:60.2210134 },
        { lng:24.84761	, lat:60.2204705 },
        { lng:24.8477026	, lat:60.2201615 },
        { lng:24.8478058	, lat:60.2198284 },
        { lng:24.848024	, lat:60.219386 },
        { lng:24.8480591	, lat:60.2187536 },
        { lng:24.8480907	, lat:60.2185885 },
        { lng:24.8473438	, lat:60.2173892 },
        { lng:24.8473184	, lat:60.2173902 },
        { lng:24.8460879	, lat:60.215386 },
        { lng:24.8459431	, lat:60.2133234 },
        { lng:24.8460383	, lat:60.2123543 },
        { lng:24.84633	, lat:60.2093806 },
        { lng:24.8463132	, lat:60.2093799 },
        { lng:24.846648	, lat:60.2060312 },
        { lng:24.8466873	, lat:60.2056164 },
        { lng:24.8468461	, lat:60.2040096 },
        { lng:24.8468739	, lat:60.2037279 },
        { lng:24.8429711	, lat:60.1961161 },
        { lng:24.8427859	, lat:60.1957592 },
        { lng:24.8428235	, lat:60.1951312 },
        { lng:24.8433886	, lat:60.1852542 },
        { lng:24.8436954	, lat:60.1806714 },
        { lng:24.8441176	, lat:60.1735661 },
        { lng:24.8444078	, lat:60.1659976 },
        { lng:24.844408	, lat:60.165948 },
        { lng:24.8444085	, lat:60.1658361 },
        { lng:24.8444094	, lat:60.1655923 },
        { lng:24.8443053	, lat:60.1653351 },
        { lng:24.8442992	, lat:60.1653164 },
        { lng:24.8442819	, lat:60.165263 },
        { lng:24.8442602	, lat:60.1652082 },
        { lng:24.8442397	, lat:60.1651576 },
        { lng:24.8415786	, lat:60.1585957 },
        { lng:24.8409357	, lat:60.1570101 },
        { lng:24.8408579	, lat:60.1510697 },
        { lng:24.8394338	, lat:60.1502629 },
        { lng:24.8380241	, lat:60.1428409 },
        { lng:24.8371597	, lat:60.1382951 },
        { lng:24.8356729	, lat:60.1304131 },
        { lng:24.7828025	, lat:60.0999603 },
        { lng:24.8169729	, lat:60.0620898 },
        { lng:24.8511334	, lat:60.0241753 },
        { lng:24.8815238	, lat:59.9903175 },
        { lng:24.9119149	, lat:59.9564191 },
        { lng:24.9423061	, lat:59.922486 },
        { lng:24.9299761	, lat:59.9213477 },
        { lng:24.8579327	, lat:59.9064917 },
        { lng:24.831625	, lat:59.9010905 },
        { lng:24.8028821	, lat:59.9367706 },
        { lng:24.7980613	, lat:59.9427513 },
        { lng:24.7741393	, lat:59.9724122 },
        { lng:24.7453966	, lat:60.0080156 },
        { lng:24.7135835	, lat:60.0299101 },
        { lng:24.6817704	, lat:60.0517902 },
        { lng:24.6632108	, lat:60.07197 },
        { lng:24.6446512	, lat:60.0921374 },
        { lng:24.6530846	, lat:60.1007713 },
        { lng:24.6547217	, lat:60.1061525 },
        { lng:24.6495356	, lat:60.114786 },
        { lng:24.6478467	, lat:60.1175971 },
        { lng:24.6410047	, lat:60.1304707 },
        { lng:24.6382023	, lat:60.1327113 },
        { lng:24.6397594	, lat:60.1337289 },
        { lng:24.6407152	, lat:60.1343534 },
        { lng:24.6410609	, lat:60.1345792 },
        { lng:24.6404284	, lat:60.1362869 },
        { lng:24.6347122	, lat:60.1376983 },
        { lng:24.6331286	, lat:60.1400213 },
        { lng:24.6291134	, lat:60.1459102 },
        { lng:24.6267308	, lat:60.149404 },
        { lng:24.6133925	, lat:60.1558104 },
        { lng:24.6108069	, lat:60.1565381 },
        { lng:24.6102327	, lat:60.1566661 },
        { lng:24.6100414	, lat:60.1567211 },
        { lng:24.6099196	, lat:60.1567631 },
        { lng:24.6094999	, lat:60.1569081 },
        { lng:24.6092939	, lat:60.1569562 },
        { lng:24.604847	, lat:60.1582156 },
        { lng:24.5994132	, lat:60.1598796 },
        { lng:24.5925407	, lat:60.1598724 },
        { lng:24.5889586	, lat:60.163957 },
        { lng:24.5877762	, lat:60.165305 },
        { lng:24.5770122	, lat:60.1637712 },
        { lng:24.5673796	, lat:60.1679277 },
        { lng:24.5722732	, lat:60.1726354 },
        { lng:24.5697143	, lat:60.174334 },
        { lng:24.568343	, lat:60.1752388 },
        { lng:24.5680152	, lat:60.175756 },
        { lng:24.5679809	, lat:60.1759694 },
        { lng:24.5679232	, lat:60.176292 },
        { lng:24.5678536	, lat:60.176553 },
        { lng:24.567784	, lat:60.1767535 },
        { lng:24.5677234	, lat:60.1769341 },
        { lng:24.567668	, lat:60.1771515 },
        { lng:24.5676216	, lat:60.1774846 },
        { lng:24.5677724	, lat:60.1777066 },
        { lng:24.5680334	, lat:60.1780354 },
        { lng:24.5682914	, lat:60.1782935 },
        { lng:24.5688394	, lat:60.1786915 },
        { lng:24.5689989	, lat:60.1788602 },
        { lng:24.5690714	, lat:60.1793101 },
        { lng:24.5636951	, lat:60.1808916 },
        { lng:24.5605578	, lat:60.1816626 },
        { lng:24.5603563	, lat:60.1817023 },
        { lng:24.55999	, lat:60.1817743 },
        { lng:24.558847	, lat:60.1820826 },
        { lng:24.5575883	, lat:60.1820726 },
        { lng:24.5570783	, lat:60.1822862 },
        { lng:24.5563347	, lat:60.1825261 },
        { lng:24.556807	, lat:60.1827734 },
        { lng:24.5565382	, lat:60.1828359 },
        { lng:24.554782	, lat:60.1828709 },
        { lng:24.5533801	, lat:60.1832507 },
        { lng:24.5538449	, lat:60.1840451 },
        { lng:24.5470069	, lat:60.1871263 },
        { lng:24.5400422	, lat:60.1902489 },
        { lng:24.53891	, lat:60.1907741 },
        { lng:24.5336472	, lat:60.1931421 },
        { lng:24.5286637	, lat:60.1953842 },
        { lng:24.5287455	, lat:60.195612 },
        { lng:24.5280839	, lat:60.1960359 },
        { lng:24.5266961	, lat:60.1953929 },
        { lng:24.516388	, lat:60.1955189 },
        { lng:24.5275935	, lat:60.1960526 },
        { lng:24.5327107	, lat:60.1962991 },
        { lng:24.5357792	, lat:60.196447 },
        { lng:24.5362154	, lat:60.1964679 },
        { lng:24.5363163	, lat:60.1974657 },
        { lng:24.536368	, lat:60.197977 },
        { lng:24.5363929	, lat:60.1982779 },
        { lng:24.5364459	, lat:60.1989159 },
        { lng:24.5364901	, lat:60.2001676 },
        { lng:24.536468	, lat:60.201957 },
        { lng:24.5364195	, lat:60.2045644 },
        { lng:24.5355626	, lat:60.2048302 },
        { lng:24.5352461	, lat:60.2049369 },
        { lng:24.5348309	, lat:60.205317 },
        { lng:24.5347078	, lat:60.2055829 },
        { lng:24.5345744	, lat:60.2056069 },
        { lng:24.5338084	, lat:60.2055841 },
        { lng:24.5339478	, lat:60.2060064 },
        { lng:24.5336796	, lat:60.2060291 },
        { lng:24.5281849	, lat:60.206344 },
        { lng:24.52813	, lat:60.2061866 },
        { lng:24.5277063	, lat:60.2062232 },
        { lng:24.5247591	, lat:60.2053489 },
        { lng:24.5032091	, lat:60.2128645 },
        { lng:24.5025913	, lat:60.2153655 },
        { lng:24.5065913	, lat:60.2164122 },
        { lng:24.5102257	, lat:60.219869 },
        { lng:24.5106234	, lat:60.2199129 },
        { lng:24.5115788	, lat:60.2209016 },
        { lng:24.5115403	, lat:60.2211255 },
        { lng:24.5170955	, lat:60.2265029 },
        { lng:24.519729	, lat:60.2279374 },
        { lng:24.5218039	, lat:60.2290673 },
        { lng:24.5247655	, lat:60.230666 },
        { lng:24.5254123	, lat:60.231015 },
        { lng:24.5251848	, lat:60.2401218 },
        { lng:24.5251645	, lat:60.2409335 },
        { lng:24.5250966	, lat:60.2436493 },
        { lng:24.5250815	, lat:60.2442561 },
        { lng:24.5250687	, lat:60.244767 },
        { lng:24.5250233	, lat:60.2465833 },
        { lng:24.5043407	, lat:60.2554357 },
      ],
      fillColor: '#F3D4D4',
      fillColor1: 'red',
    },
    {
      id: 2,
      regionName: 'eCom TP  Forssa',
      fillColor: '#FAD519',
      fillColor1: 'red',
      coordinates: [
        { lng:23.4678834	, lat:60.9844488 },
        { lng:23.4680572	, lat:60.9843512 },
        { lng:23.4682539	, lat:60.9842042 },
        { lng:23.4683586	, lat:60.9841256 },
        { lng:23.4686604	, lat:60.9837267 },
        { lng:23.4663685	, lat:60.9829547 },
        { lng:23.4590729	, lat:60.9837249 },
        { lng:23.4587285	, lat:60.9838691 },
        { lng:23.4542663	, lat:60.9832462 },
        { lng:23.4531066	, lat:60.9816 },
        { lng:23.4634921	, lat:60.9784772 },
        { lng:23.4638998	, lat:60.9773113 },
        { lng:23.4635146	, lat:60.9767403 },
        { lng:23.4617755	, lat:60.9770718 },
        { lng:23.4614321	, lat:60.9766867 },
        { lng:23.4604032	, lat:60.9769485 },
        { lng:23.4589826	, lat:60.9772681 },
        { lng:23.4577854	, lat:60.9760532 },
        { lng:23.4639856	, lat:60.9746253 },
        { lng:23.4614547	, lat:60.9692646 },
        { lng:23.4660241	, lat:60.9682218 },
        { lng:23.4655102	, lat:60.96712 },
        { lng:23.4715172	, lat:60.9657746 },
        { lng:23.4721395	, lat:60.9655996 },
        { lng:23.4752511	, lat:60.964881 },
        { lng:23.473917	, lat:60.9635451 },
        { lng:23.4740173	, lat:60.9635063 },
        { lng:23.4744894	, lat:60.9635844 },
        { lng:23.4749078	, lat:60.9635896 },
        { lng:23.4753369	, lat:60.9635531 },
        { lng:23.4758305	, lat:60.9635115 },
        { lng:23.4761738	, lat:60.9635427 },
        { lng:23.4767424	, lat:60.9636 },
        { lng:23.4772467	, lat:60.9635948 },
        { lng:23.4777831	, lat:60.9635323 },
        { lng:23.4782014	, lat:60.9634688 },
        { lng:23.4786307	, lat:60.9633969 },
        { lng:23.4794783	, lat:60.9632355 },
        { lng:23.479886	, lat:60.9631053 },
        { lng:23.4800254	, lat:60.9629959 },
        { lng:23.4796998	, lat:60.9629564 },
        { lng:23.4794783	, lat:60.9629543 },
        { lng:23.479371	, lat:60.9630428 },
        { lng:23.4789595	, lat:60.9631907 },
        { lng:23.4783517	, lat:60.9633188 },
        { lng:23.4776292	, lat:60.9634617 },
        { lng:23.4771356	, lat:60.9635136 },
        { lng:23.4765278	, lat:60.9634906 },
        { lng:23.4760772	, lat:60.9634438 },
        { lng:23.475787	, lat:60.9634374 },
        { lng:23.4752688	, lat:60.9634563 },
        { lng:23.4747898	, lat:60.9635063 },
        { lng:23.4744963	, lat:60.9635083 },
        { lng:23.474146	, lat:60.9634542 },
        { lng:23.4740602	, lat:60.9634073 },
        { lng:23.4740709	, lat:60.9632146 },
        { lng:23.4743392	, lat:60.9630011 },
        { lng:23.501324	, lat:60.9475798 },
        { lng:23.5015957	, lat:60.9469198 },
        { lng:23.548256	, lat:60.9248248 },
        { lng:23.5544023	, lat:60.9059897 },
        { lng:23.5571308	, lat:60.8936995 },
        { lng:23.5524372	, lat:60.8795076 },
        { lng:23.550001	, lat:60.877666 },
        { lng:23.5511379	, lat:60.8765594 },
        { lng:23.5513364	, lat:60.8759901 },
        { lng:23.5506981	, lat:60.8753847 },
        { lng:23.5492214	, lat:60.8760061 },
        { lng:23.5486333	, lat:60.8753256 },
        { lng:23.5478371	, lat:60.8749692 },
        { lng:23.5480683	, lat:60.8744647 },
        { lng:23.5460186	, lat:60.8743004 },
        { lng:23.5473009	, lat:60.8733834 },
        { lng:23.546428	, lat:60.8730023 },
        { lng:23.5445603	, lat:60.8731999 },
        { lng:23.5490671	, lat:60.854894 },
        { lng:23.5638619	, lat:60.83311 },
        { lng:23.5639925	, lat:60.8329176 },
        { lng:23.5566098	, lat:60.831837 },
        { lng:23.5545571	, lat:60.8316584 },
        { lng:23.5550118	, lat:60.8291745 },
        { lng:23.5546551	, lat:60.8280735 },
        { lng:23.5528717	, lat:60.827436 },
        { lng:23.5534689	, lat:60.8211546 },
        { lng:23.5535982	, lat:60.819795 },
        { lng:23.553704	, lat:60.8186839 },
        { lng:23.5587755	, lat:60.8023486 },
        { lng:23.5588047	, lat:60.8022544 },
        { lng:23.5574381	, lat:60.8022027 },
        { lng:23.5574104	, lat:60.8020606 },
        { lng:23.5568206	, lat:60.7990208 },
        { lng:23.5582945	, lat:60.7983453 },
        { lng:23.550211	, lat:60.7888657 },
        { lng:23.6345552	, lat:60.7795213 },
        { lng:23.6366602	, lat:60.7802028 },
        { lng:23.6463014	, lat:60.7832196 },
        { lng:23.6498661	, lat:60.7843236 },
        { lng:23.6686541	, lat:60.7976717 },
        { lng:23.6709034	, lat:60.7992693 },
        { lng:23.6729024	, lat:60.8007057 },
        { lng:23.6704951	, lat:60.8017134 },
        { lng:23.6719667	, lat:60.8027185 },
        { lng:23.6723841	, lat:60.8030037 },
        { lng:23.675886	, lat:60.8053955 },
        { lng:23.6764525	, lat:60.8051279 },
        { lng:23.6768763	, lat:60.8048821 },
        { lng:23.6772532	, lat:60.8046046 },
        { lng:23.6775037	, lat:60.804423 },
        { lng:23.6777363	, lat:60.8042901 },
        { lng:23.6779777	, lat:60.8041521 },
        { lng:23.6787815	, lat:60.8037624 },
        { lng:23.679139	, lat:60.8036317 },
        { lng:23.6794958	, lat:60.8035377 },
        { lng:23.6798248	, lat:60.8034656 },
        { lng:23.679868	, lat:60.8034561 },
        { lng:23.6802824	, lat:60.803381 },
        { lng:23.680758	, lat:60.8033405 },
        { lng:23.681143	, lat:60.803325 },
        { lng:23.6814978	, lat:60.8033352 },
        { lng:23.6816726	, lat:60.8033407 },
        { lng:23.6817821	, lat:60.8033682 },
        { lng:23.6820186	, lat:60.8033857 },
        { lng:23.6826968	, lat:60.803518 },
        { lng:23.6832239	, lat:60.8036421 },
        { lng:23.6838393	, lat:60.8037259 },
        { lng:23.6841869	, lat:60.8037585 },
        { lng:23.6846062	, lat:60.8037629 },
        { lng:23.6849691	, lat:60.8037362 },
        { lng:23.6852909	, lat:60.8036832 },
        { lng:23.6859065	, lat:60.8035685 },
        { lng:23.6862263	, lat:60.8034778 },
        { lng:23.6869665	, lat:60.8032818 },
        { lng:23.6899835	, lat:60.8064441 },
        { lng:23.6917729	, lat:60.8074376 },
        { lng:23.6935074	, lat:60.807042 },
        { lng:23.698238	, lat:60.8059163 },
        { lng:23.6997138	, lat:60.8056388 },
        { lng:23.6999273	, lat:60.8056035 },
        { lng:23.7001488	, lat:60.8055627 },
        { lng:23.7020229	, lat:60.8052691 },
        { lng:23.7020819	, lat:60.8057927 },
        { lng:23.702143	, lat:60.8063711 },
        { lng:23.7043792	, lat:60.8189409 },
        { lng:23.6727056	, lat:60.8222164 },
        { lng:23.6748476	, lat:60.8247987 },
        { lng:23.6754425	, lat:60.8254643 },
        { lng:23.6740204	, lat:60.8254493 },
        { lng:23.6743541	, lat:60.8275337 },
        { lng:23.6745083	, lat:60.828395 },
        { lng:23.6756464	, lat:60.8282143 },
        { lng:23.6752464	, lat:60.829684 },
        { lng:23.6730819	, lat:60.830256 },
        { lng:23.6727616	, lat:60.8308538 },
        { lng:23.6745619	, lat:60.8320386 },
        { lng:23.6737862	, lat:60.8325361 },
        { lng:23.6740759	, lat:60.8330543 },
        { lng:23.6744367	, lat:60.8330549 },
        { lng:23.6741974	, lat:60.8339124 },
        { lng:23.6713199	, lat:60.8341817 },
        { lng:23.6707792	, lat:60.8354839 },
        { lng:23.6736599	, lat:60.836011 },
        { lng:23.6728493	, lat:60.8392282 },
        { lng:23.673757	, lat:60.8392039 },
        { lng:23.6740885	, lat:60.839391 },
        { lng:23.6727308	, lat:60.8397188 },
        { lng:23.6681061	, lat:60.8582461 },
        { lng:23.6646171	, lat:60.8595188 },
        { lng:23.6651031	, lat:60.85985 },
        { lng:23.6662919	, lat:60.8593176 },
        { lng:23.6667221	, lat:60.8592032 },
        { lng:23.6678197	, lat:60.858896 },
        { lng:23.6653311	, lat:60.8687469 },
        { lng:23.6641981	, lat:60.8690261 },
        { lng:23.6637937	, lat:60.8693883 },
        { lng:23.6629836	, lat:60.869528 },
        { lng:23.6628876	, lat:60.8697445 },
        { lng:23.662687	, lat:60.8700427 },
        { lng:23.6623072	, lat:60.8699453 },
        { lng:23.6615884	, lat:60.8701498 },
        { lng:23.6604237	, lat:60.8708723 },
        { lng:23.6605648	, lat:60.8710443 },
        { lng:23.6598213	, lat:60.8711495 },
        { lng:23.6598514	, lat:60.8714182 },
        { lng:23.6593278	, lat:60.8715587 },
        { lng:23.6579953	, lat:60.8725974 },
        { lng:23.6559815	, lat:60.8731088 },
        { lng:23.6545084	, lat:60.8738754 },
        { lng:23.6526019	, lat:60.8743052 },
        { lng:23.6518326	, lat:60.8747089 },
        { lng:23.6510344	, lat:60.874841 },
        { lng:23.6507404	, lat:60.8751726 },
        { lng:23.6498907	, lat:60.8755705 },
        { lng:23.6490742	, lat:60.8756363 },
        { lng:23.6489889	, lat:60.8756799 },
        { lng:23.6487942	, lat:60.8757798 },
        { lng:23.6488296	, lat:60.8759035 },
        { lng:23.6492159	, lat:60.8760785 },
        { lng:23.6492674	, lat:60.8763026 },
        { lng:23.649115	, lat:60.8764065 },
        { lng:23.6488339	, lat:60.8764472 },
        { lng:23.6481752	, lat:60.8763093 },
        { lng:23.6476849	, lat:60.8763306 },
        { lng:23.6471731	, lat:60.8766601 },
        { lng:23.6474617	, lat:60.8770538 },
        { lng:23.6492105	, lat:60.8769259 },
        { lng:23.6768319	, lat:60.882345 },
        { lng:23.7012057	, lat:60.880017 },
        { lng:23.72003	, lat:60.891125 },
        { lng:23.7201301	, lat:60.8912861 },
        { lng:23.7191053	, lat:60.8913178 },
        { lng:23.7189885	, lat:60.8915509 },
        { lng:23.7187204	, lat:60.8920067 },
        { lng:23.719349	, lat:60.8921807 },
        { lng:23.7187576	, lat:60.8924245 },
        { lng:23.7186349	, lat:60.8930358 },
        { lng:23.7182362	, lat:60.8931435 },
        { lng:23.717744	, lat:60.8937338 },
        { lng:23.7157454	, lat:60.8946354 },
        { lng:23.7139052	, lat:60.8951772 },
        { lng:23.714049	, lat:60.8956967 },
        { lng:23.7146758	, lat:60.8963085 },
        { lng:23.7137487	, lat:60.8967043 },
        { lng:23.7117865	, lat:60.8981576 },
        { lng:23.7102234	, lat:60.8988864 },
        { lng:23.7097301	, lat:60.8997495 },
        { lng:23.7095887	, lat:60.9004604 },
        { lng:23.7101686	, lat:60.9007075 },
        { lng:23.7103279	, lat:60.901101 },
        { lng:23.7109175	, lat:60.9018875 },
        { lng:23.7552813	, lat:60.9378214 },
        { lng:23.7648424	, lat:60.9352985 },
        { lng:23.8209101	, lat:60.9311696 },
        { lng:23.8393352	, lat:60.9397267 },
        { lng:23.8296031	, lat:60.9504643 },
        { lng:23.8255701	, lat:60.9549671 },
        { lng:23.8122009	, lat:60.9697442 },
        { lng:23.8072871	, lat:60.9752 },
        { lng:23.7973119	, lat:60.9863718 },
        { lng:23.7949071	, lat:60.9890561 },
        { lng:23.7850366	, lat:60.9888376 },
        { lng:23.7839852	, lat:60.9913663 },
        { lng:23.7806807	, lat:60.9919802 },
        { lng:23.7786841	, lat:60.9924147 },
        { lng:23.7796507	, lat:60.9931664 },
        { lng:23.7767733	, lat:60.995714 },
        { lng:23.7758742	, lat:60.9959237 },
        { lng:23.7677997	, lat:60.9959419 },
        { lng:23.7665401	, lat:60.9970785 },
        { lng:23.7532782	, lat:60.9948806 },
        { lng:23.7549304	, lat:60.9941627 },
        { lng:23.754866	, lat:60.9934552 },
        { lng:23.7546944	, lat:60.9930286 },
        { lng:23.7534284	, lat:60.9921961 },
        { lng:23.7492881	, lat:60.993822 },
        { lng:23.7457476	, lat:60.9932601 },
        { lng:23.7454901	, lat:60.9936763 },
        { lng:23.7399315	, lat:60.9927684 },
        { lng:23.738688	, lat:60.9925213 },
        { lng:23.7358545	, lat:60.9921441 },
        { lng:23.7368641	, lat:60.9980669 },
        { lng:23.7375937	, lat:61.0037155 },
        { lng:23.7123166	, lat:61.0090303 },
        { lng:23.6966954	, lat:61.006035 },
        { lng:23.694806	, lat:61.0058348 },
        { lng:23.6949348	, lat:61.0055955 },
        { lng:23.6951923	, lat:61.0053875 },
        { lng:23.6949358	, lat:61.0047452 },
        { lng:23.687961	, lat:61.0052731 },
        { lng:23.6871885	, lat:61.0051171 },
        { lng:23.6851072	, lat:61.002964 },
        { lng:23.6792707	, lat:61.0041497 },
        { lng:23.6756673	, lat:61.0043311 },
        { lng:23.6753439	, lat:61.0043474 },
        { lng:23.6757945	, lat:61.0059492 },
        { lng:23.6645732	, lat:61.0057229 },
        { lng:23.6607538	, lat:61.0018015 },
        { lng:23.6517619	, lat:61.00312 },
        { lng:23.6498951	, lat:61.0011644 },
        { lng:23.6443805	, lat:61.0023918 },
        { lng:23.639618	, lat:61.003445 },
        { lng:23.6383949	, lat:61.002228 },
        { lng:23.6306701	, lat:61.0039651 },
        { lng:23.6272573	, lat:61.0003634 },
        { lng:23.620777	, lat:61.0017677 },
        { lng:23.6185251	, lat:61.0025193 },
        { lng:23.6146187	, lat:60.9981891 },
        { lng:23.6081181	, lat:60.9913559 },
        { lng:23.6058854	, lat:60.9919048 },
        { lng:23.6053275	, lat:60.9904376 },
        { lng:23.6030745	, lat:60.9906769 },
        { lng:23.6000919	, lat:60.990063 },
        { lng:23.5987411	, lat:60.9890666 },
        { lng:23.5951126	, lat:60.9898603 },
        { lng:23.5903592	, lat:60.9866496 },
        { lng:23.5868266	, lat:60.9842616 },
        { lng:23.5863482	, lat:60.984126 },
        { lng:23.5860371	, lat:60.9840791 },
        { lng:23.5857377	, lat:60.9840763 },
        { lng:23.5854792	, lat:60.9841156 },
        { lng:23.584696	, lat:60.9843705 },
        { lng:23.5844278	, lat:60.984433 },
        { lng:23.5837636	, lat:60.9846175 },
        { lng:23.5833881	, lat:60.9847944 },
        { lng:23.582679	, lat:60.9851668 },
        { lng:23.5819183	, lat:60.9856166 },
        { lng:23.5812209	, lat:60.9860694 },
        { lng:23.5810844	, lat:60.9862053 },
        { lng:23.579471	, lat:60.9878365 },
        { lng:23.57267	, lat:60.9905755 },
        { lng:23.5756516	, lat:60.9934968 },
        { lng:23.5829268	, lat:60.9903881 },
        { lng:23.5875541	, lat:60.9957359 },
        { lng:23.5821758	, lat:60.9968288 },
        { lng:23.5815964	, lat:60.9969121 },
        { lng:23.5783134	, lat:60.9975675 },
        { lng:23.5726475	, lat:60.9986573 },
        { lng:23.5701799	, lat:60.9995415 },
        { lng:23.5663175	, lat:61.0010603 },
        { lng:23.5660814	, lat:60.9999993 },
        { lng:23.5660332	, lat:60.9991462 },
        { lng:23.5658883	, lat:60.9989278 },
        { lng:23.5649656	, lat:60.9959939 },
        { lng:23.5643015	, lat:60.9961838 },
        { lng:23.5546874	, lat:60.9992607 },
        { lng:23.5479293	, lat:60.9965583 },
        { lng:23.5413193	, lat:60.9994895 },
        { lng:23.5410182	, lat:60.9985976 },
        { lng:23.5403537	, lat:60.9966286 },
        { lng:23.5441957	, lat:60.9950913 },
        { lng:23.5433937	, lat:60.9946239 },
        { lng:23.5380373	, lat:60.9915016 },
        { lng:23.5367499	, lat:60.9908148 },
        { lng:23.535677	, lat:60.9901384 },
        { lng:23.5341953	, lat:60.9892929 },
        { lng:23.5309563	, lat:60.9873598 },
        { lng:23.5260843	, lat:60.9888667 },
        { lng:23.5262131	, lat:60.9923626 },
        { lng:23.521472	, lat:60.9928959 },
        { lng:23.5203991	, lat:60.9912518 },
        { lng:23.5162363	, lat:60.9924381 },
        { lng:23.5163855	, lat:60.9922794 },
        { lng:23.516389	, lat:60.9921425 },
        { lng:23.5161709	, lat:60.9918424 },
        { lng:23.5107861	, lat:60.993541 },
        { lng:23.5101628	, lat:60.9933927 },
        { lng:23.5093699	, lat:60.9931664 },
        { lng:23.5066018	, lat:60.9929687 },
        { lng:23.4996066	, lat:60.9907524 },
        { lng:23.4926114	, lat:60.9904922 },
        { lng:23.4910665	, lat:60.9906067 },
        { lng:23.4908938	, lat:60.9906249 },
        { lng:23.4884487	, lat:60.9905026 },
        { lng:23.4865389	, lat:60.9904714 },
        { lng:23.4790717	, lat:60.9901176 },
        { lng:23.4789215	, lat:60.9902321 },
        { lng:23.4785771	, lat:60.9902815 },
        { lng:23.4743295	, lat:60.9877345 },
        { lng:23.4707675	, lat:60.9866834 },
        { lng:23.4706173	, lat:60.9867562 },
        { lng:23.4702086	, lat:60.9867958 },
        { lng:23.469758	, lat:60.9868999 },
        { lng:23.4693503	, lat:60.9873578 },
        { lng:23.4691153	, lat:60.9874639 },
        { lng:23.4674416	, lat:60.9878178 },
        { lng:23.4678495	, lat:60.9846422 },
        { lng:23.4678834	, lat:60.9844488 },
      ],
    },
    {
      id: 3,
      regionName: 'eCom TP  Helsinki Herttoniemi',
      fillColor: '#F3D4D4',
      fillColor1: 'pink',
      coordinates: [
        { lng:25.0069442	, lat:60.204776 },
        { lng:25.0191986	, lat:60.2139658 },
        { lng:25.0215669	, lat:60.2132555 },
        { lng:25.027082	, lat:60.2115952 },
        { lng:25.0279274	, lat:60.2113471 },
        { lng:25.0286141	, lat:60.211207 },
        { lng:25.0288468	, lat:60.2110348 },
        { lng:25.0297996	, lat:60.2115923 },
        { lng:25.0300732	, lat:60.2117523 },
        { lng:25.0395184	, lat:60.2173064 },
        { lng:25.0401271	, lat:60.2176509 },
        { lng:25.0410625	, lat:60.2181794 },
        { lng:25.0414596	, lat:60.2184037 },
        { lng:25.042508	, lat:60.2189958 },
        { lng:25.0430792	, lat:60.2193242 },
        { lng:25.0433181	, lat:60.2191599 },
        { lng:25.0436122	, lat:60.2186661 },
        { lng:25.0439427	, lat:60.2172089 },
        { lng:25.045018	, lat:60.2146563 },
        { lng:25.0450958	, lat:60.2141826 },
        { lng:25.0452033	, lat:60.2133938 },
        { lng:25.0452735	, lat:60.212469 },
        { lng:25.0452358	, lat:60.211442 },
        { lng:25.0452542	, lat:60.2109876 },
        { lng:25.045299	, lat:60.2106276 },
        { lng:25.0461607	, lat:60.2096624 },
        { lng:25.0470808	, lat:60.2091272 },
        { lng:25.0478719	, lat:60.2087946 },
        { lng:25.0487041	, lat:60.2085388 },
        { lng:25.049699	, lat:60.2082931 },
        { lng:25.050682	, lat:60.2081043 },
        { lng:25.0515382	, lat:60.2079755 },
        { lng:25.0523161	, lat:60.2078976 },
        { lng:25.0536705	, lat:60.2078035 },
        { lng:25.0546759	, lat:60.2076056 },
        { lng:25.0579627	, lat:60.207547 },
        { lng:25.0635177	, lat:60.2077054 },
        { lng:25.0644396	, lat:60.2077356 },
        { lng:25.0644696	, lat:60.2037647 },
        { lng:25.064479	, lat:60.2025235 },
        { lng:25.0661138	, lat:60.2025896 },
        { lng:25.0669171	, lat:60.2025778 },
        { lng:25.0698215	, lat:60.2027618 },
        { lng:25.0702078	, lat:60.200571 },
        { lng:25.0726353	, lat:60.1974849 },
        { lng:25.0743084	, lat:60.1976859 },
        { lng:25.0764561	, lat:60.1941589 },
        { lng:25.0761223	, lat:60.1934916 },
        { lng:25.0666892	, lat:60.1968344 },
        { lng:25.0641797	, lat:60.1955283 },
        { lng:25.0624141	, lat:60.1937006 },
        { lng:25.0608779	, lat:60.1931529 },
        { lng:25.0487494	, lat:60.1917595 },
        { lng:25.0480908	, lat:60.1912637 },
        { lng:25.0477137	, lat:60.1906745 },
        { lng:25.0482229	, lat:60.1898512 },
        { lng:25.0487407	, lat:60.1890218 },
        { lng:25.0556452	, lat:60.1887151 },
        { lng:25.0406024	, lat:60.1850327 },
        { lng:25.0220109	, lat:60.1830772 },
        { lng:25.0228946	, lat:60.188585 },
        { lng:25.0187539	, lat:60.1896053 },
        { lng:25.0178327	, lat:60.1899882 },
        { lng:25.0175697	, lat:60.1900977 },
        { lng:25.0142144	, lat:60.1910773 },
        { lng:25.021893	, lat:60.1975638 },
        { lng:25.0197818	, lat:60.200688 },
        { lng:25.0069442	, lat:60.204776 },
      ],
    },
    {
      id: 4,
      regionName: 'eCom TP  Helsinki POK',
      fillColor: '#F3D4D4',
      fillColor1: 'pink',
      coordinates: [
        { lng:24.7828025	, lat:60.0999603 },
        { lng:24.8356729	, lat:60.1304131 },
        { lng:24.8371597	, lat:60.1382951 },
        { lng:24.8380241	, lat:60.1428409 },
        { lng:24.8394338	, lat:60.1502629 },
        { lng:24.8408579	, lat:60.1510697 },
        { lng:24.8409357	, lat:60.1570101 },
        { lng:24.8415786	, lat:60.1585957 },
        { lng:24.8442397	, lat:60.1651576 },
        { lng:24.8442602	, lat:60.1652082 },
        { lng:24.8442819	, lat:60.165263 },
        { lng:24.8442992	, lat:60.1653164 },
        { lng:24.8443053	, lat:60.1653351 },
        { lng:24.8444094	, lat:60.1655923 },
        { lng:24.8444085	, lat:60.1658361 },
        { lng:24.844408	, lat:60.165948 },
        { lng:24.8444078	, lat:60.1659976 },
        { lng:24.8441176	, lat:60.1735661 },
        { lng:24.8436954	, lat:60.1806714 },
        { lng:24.8433886	, lat:60.1852542 },
        { lng:24.8428235	, lat:60.1951312 },
        { lng:24.8427859	, lat:60.1957592 },
        { lng:24.8429711	, lat:60.1961161 },
        { lng:24.8468739	, lat:60.2037279 },
        { lng:24.8468461	, lat:60.2040096 },
        { lng:24.8466873	, lat:60.2056164 },
        { lng:24.846648	, lat:60.2060312 },
        { lng:24.8463132	, lat:60.2093799 },
        { lng:24.84633	, lat:60.2093806 },
        { lng:24.8460383	, lat:60.2123543 },
        { lng:24.8459431	, lat:60.2133234 },
        { lng:24.8460879	, lat:60.215386 },
        { lng:24.8473184	, lat:60.2173902 },
        { lng:24.8473438	, lat:60.2173892 },
        { lng:24.8480907	, lat:60.2185885 },
        { lng:24.8480591	, lat:60.2187536 },
        { lng:24.848024	, lat:60.219386 },
        { lng:24.8478058	, lat:60.2198284 },
        { lng:24.8477026	, lat:60.2201615 },
        { lng:24.84761	, lat:60.2204705 },
        { lng:24.8478072	, lat:60.2210134 },
        { lng:24.8478581	, lat:60.2216022 },
        { lng:24.8485046	, lat:60.2222423 },
        { lng:24.8512016	, lat:60.2248264 },
        { lng:24.8513692	, lat:60.2249752 },
        { lng:24.8508525	, lat:60.2249653 },
        { lng:24.8502425	, lat:60.2249204 },
        { lng:24.8496517	, lat:60.2248281 },
        { lng:24.8490783	, lat:60.2246613 },
        { lng:24.8483652	, lat:60.2243486 },
        { lng:24.8470982	, lat:60.2238626 },
        { lng:24.8462638	, lat:60.2232636 },
        { lng:24.8451636	, lat:60.2227398 },
        { lng:24.8439422	, lat:60.2220277 },
        { lng:24.8431684	, lat:60.2216057 },
        { lng:24.8419097	, lat:60.2212042 },
        { lng:24.8418757	, lat:60.221319 },
        { lng:24.8418357	, lat:60.2214834 },
        { lng:24.8416945	, lat:60.2221486 },
        { lng:24.8416762	, lat:60.2222341 },
        { lng:24.8416284	, lat:60.2224108 },
        { lng:24.8415245	, lat:60.222839 },
        { lng:24.8411742	, lat:60.2242861 },
        { lng:24.8411575	, lat:60.2243553 },
        { lng:24.8411347	, lat:60.2244493 },
        { lng:24.8408731	, lat:60.2254699 },
        { lng:24.8408608	, lat:60.225531 },
        { lng:24.8408451	, lat:60.2255952 },
        { lng:24.8408356	, lat:60.2256332 },
        { lng:24.8407447	, lat:60.22596 },
        { lng:24.8407162	, lat:60.226105 },
        { lng:24.8405309	, lat:60.22686 },
        { lng:24.8405189	, lat:60.2269064 },
        { lng:24.8405098	, lat:60.226941 },
        { lng:24.8404375	, lat:60.2272629 },
        { lng:24.8403869	, lat:60.2274882 },
        { lng:24.8403418	, lat:60.2276388 },
        { lng:24.8401943	, lat:60.2282288 },
        { lng:24.8401856	, lat:60.2282633 },
        { lng:24.840167	, lat:60.2283354 },
        { lng:24.8400782	, lat:60.228682 },
        { lng:24.8399692	, lat:60.2291364 },
        { lng:24.8393258	, lat:60.2290986 },
        { lng:24.8381158	, lat:60.2290273 },
        { lng:24.8379909	, lat:60.2294751 },
        { lng:24.837967	, lat:60.2295614 },
        { lng:24.8378388	, lat:60.2300417 },
        { lng:24.837239	, lat:60.2321771 },
        { lng:24.8372058	, lat:60.2322952 },
        { lng:24.8371839	, lat:60.232373 },
        { lng:24.8370448	, lat:60.2328683 },
        { lng:24.8370394	, lat:60.2328845 },
        { lng:24.8370137	, lat:60.2329626 },
        { lng:24.8369897	, lat:60.233035 },
        { lng:24.8369731	, lat:60.2330927 },
        { lng:24.8369152	, lat:60.2332932 },
        { lng:24.836674	, lat:60.234199 },
        { lng:24.8363621	, lat:60.2352844 },
        { lng:24.8362807	, lat:60.2355665 },
        { lng:24.8361669	, lat:60.2359601 },
        { lng:24.8361293	, lat:60.2360897 },
        { lng:24.836	, lat:60.2365448 },
        { lng:24.8359766	, lat:60.2366273 },
        { lng:24.8359611	, lat:60.2366856 },
        { lng:24.8369365	, lat:60.2367604 },
        { lng:24.8367431	, lat:60.2373897 },
        { lng:24.836627	, lat:60.237747 },
        { lng:24.8362854	, lat:60.2388124 },
        { lng:24.8361949	, lat:60.2390986 },
        { lng:24.8361846	, lat:60.239132 },
        { lng:24.8358684	, lat:60.2401092 },
        { lng:24.8358669	, lat:60.2401326 },
        { lng:24.8358638	, lat:60.2401589 },
        { lng:24.8358271	, lat:60.2402504 },
        { lng:24.8358196	, lat:60.2402692 },
        { lng:24.8356389	, lat:60.2408728 },
        { lng:24.8354172	, lat:60.2415378 },
        { lng:24.8351164	, lat:60.2423929 },
        { lng:24.8349418	, lat:60.2429337 },
        { lng:24.8349149	, lat:60.2430238 },
        { lng:24.8349045	, lat:60.2430586 },
        { lng:24.8348028	, lat:60.2434163 },
        { lng:24.8347517	, lat:60.2435828 },
        { lng:24.8345858	, lat:60.2441331 },
        { lng:24.8343272	, lat:60.244941 },
        { lng:24.8341736	, lat:60.2453656 },
        { lng:24.8336486	, lat:60.2470531 },
        { lng:24.8336092	, lat:60.2471746 },
        { lng:24.8335636	, lat:60.2473147 },
        { lng:24.8314026	, lat:60.254055 },
        { lng:24.8335667	, lat:60.2546418 },
        { lng:24.8336844	, lat:60.2546666 },
        { lng:24.8341945	, lat:60.2565675 },
        { lng:24.8346828	, lat:60.2582944 },
        { lng:24.8347319	, lat:60.2584995 },
        { lng:24.8354657	, lat:60.2587737 },
        { lng:24.8368517	, lat:60.2590293 },
        { lng:24.837406	, lat:60.2590842 },
        { lng:24.8377056	, lat:60.2591009 },
        { lng:24.8386407	, lat:60.2590969 },
        { lng:24.8387479	, lat:60.259067 },
        { lng:24.8390847	, lat:60.2590705 },
        { lng:24.8401411	, lat:60.2590816 },
        { lng:24.8409102	, lat:60.2584648 },
        { lng:24.8423513	, lat:60.2580356 },
        { lng:24.8424347	, lat:60.2580115 },
        { lng:24.8425132	, lat:60.2579924 },
        { lng:24.8449232	, lat:60.2572984 },
        { lng:24.8451675	, lat:60.2572287 },
        { lng:24.8456011	, lat:60.2571046 },
        { lng:24.8456573	, lat:60.2570881 },
        { lng:24.8457658	, lat:60.2570586 },
        { lng:24.8460145	, lat:60.2569864 },
        { lng:24.8466351	, lat:60.2568079 },
        { lng:24.8468314	, lat:60.2567509 },
        { lng:24.8468647	, lat:60.2567426 },
        { lng:24.8470024	, lat:60.256702 },
        { lng:24.8481256	, lat:60.2563859 },
        { lng:24.8487094	, lat:60.256214 },
        { lng:24.8497979	, lat:60.2559033 },
        { lng:24.8498471	, lat:60.2558887 },
        { lng:24.8498979	, lat:60.2558741 },
        { lng:24.8499678	, lat:60.2558548 },
        { lng:24.8500173	, lat:60.255841 },
        { lng:24.8520081	, lat:60.2552704 },
        { lng:24.8525281	, lat:60.255122 },
        { lng:24.8540772	, lat:60.2546787 },
        { lng:24.8541794	, lat:60.2546498 },
        { lng:24.854991	, lat:60.2544203 },
        { lng:24.8551253	, lat:60.2543815 },
        { lng:24.8564324	, lat:60.2540038 },
        { lng:24.856661	, lat:60.2539378 },
        { lng:24.8570813	, lat:60.2538179 },
        { lng:24.8578163	, lat:60.2536079 },
        { lng:24.8624912	, lat:60.2531432 },
        { lng:24.8626826	, lat:60.253226 },
        { lng:24.8629895	, lat:60.2533548 },
        { lng:24.8635991	, lat:60.2536149 },
        { lng:24.8640596	, lat:60.2538088 },
        { lng:24.8643083	, lat:60.2539151 },
        { lng:24.8645215	, lat:60.2537479 },
        { lng:24.8646325	, lat:60.2536937 },
        { lng:24.8648705	, lat:60.2536048 },
        { lng:24.8650692	, lat:60.2535488 },
        { lng:24.8652043	, lat:60.2534961 },
        { lng:24.8654198	, lat:60.2533752 },
        { lng:24.8655258	, lat:60.2533249 },
        { lng:24.8656288	, lat:60.2533016 },
        { lng:24.8657557	, lat:60.2532871 },
        { lng:24.8659188	, lat:60.2532781 },
        { lng:24.8660905	, lat:60.2532838 },
        { lng:24.8664724	, lat:60.2532869 },
        { lng:24.8666245	, lat:60.2532817 },
        { lng:24.8668533	, lat:60.2532309 },
        { lng:24.8670655	, lat:60.2531813 },
        { lng:24.8671406	, lat:60.2531453 },
        { lng:24.8671966	, lat:60.253092 },
        { lng:24.8674744	, lat:60.2528227 },
        { lng:24.86759	, lat:60.252669 },
        { lng:24.8676063	, lat:60.2526172 },
        { lng:24.8676068	, lat:60.252539 },
        { lng:24.8676297	, lat:60.2525045 },
        { lng:24.8676957	, lat:60.2524556 },
        { lng:24.8677319	, lat:60.2524405 },
        { lng:24.8678739	, lat:60.2523805 },
        { lng:24.8687634	, lat:60.2532664 },
        { lng:24.8689712	, lat:60.2533162 },
        { lng:24.8690876	, lat:60.2533446 },
        { lng:24.8713695	, lat:60.2538922 },
        { lng:24.8719335	, lat:60.2540313 },
        { lng:24.8724329	, lat:60.2541514 },
        { lng:24.8727701	, lat:60.2545758 },
        { lng:24.8740573	, lat:60.2562387 },
        { lng:24.8747097	, lat:60.2570861 },
        { lng:24.875069	, lat:60.2575465 },
        { lng:24.8755432	, lat:60.2581681 },
        { lng:24.8760797	, lat:60.2588492 },
        { lng:24.8768598	, lat:60.2598241 },
        { lng:24.876914	, lat:60.2598886 },
        { lng:24.8772516	, lat:60.2603098 },
        { lng:24.8776355	, lat:60.2604742 },
        { lng:24.8779537	, lat:60.2606092 },
        { lng:24.8785208	, lat:60.2608514 },
        { lng:24.8788967	, lat:60.261011 },
        { lng:24.8795718	, lat:60.2612997 },
        { lng:24.8801794	, lat:60.2615605 },
        { lng:24.8847165	, lat:60.2635126 },
        { lng:24.8848996	, lat:60.2635914 },
        { lng:24.8849821	, lat:60.2636285 },
        { lng:24.8852107	, lat:60.2637253 },
        { lng:24.890791	, lat:60.2661138 },
        { lng:24.8914713	, lat:60.2664204 },
        { lng:24.8919896	, lat:60.2667981 },
        { lng:24.8924568	, lat:60.2671387 },
        { lng:24.8925609	, lat:60.2675183 },
        { lng:24.8926795	, lat:60.26797 },
        { lng:24.8928066	, lat:60.2684385 },
        { lng:24.8928328	, lat:60.2685352 },
        { lng:24.8938924	, lat:60.2685103 },
        { lng:24.894365	, lat:60.2684972 },
        { lng:24.8951969	, lat:60.2685231 },
        { lng:24.8961578	, lat:60.2685649 },
        { lng:24.8975398	, lat:60.2686477 },
        { lng:24.8977868	, lat:60.2686776 },
        { lng:24.8981452	, lat:60.2687312 },
        { lng:24.8985458	, lat:60.2687963 },
        { lng:24.8989443	, lat:60.2688738 },
        { lng:24.8992717	, lat:60.2689375 },
        { lng:24.8994524	, lat:60.268975 },
        { lng:24.8996381	, lat:60.2690251 },
        { lng:24.8997875	, lat:60.2690731 },
        { lng:24.9000116	, lat:60.2691492 },
        { lng:24.900161	, lat:60.2691923 },
        { lng:24.9002962	, lat:60.2692253 },
        { lng:24.9013258	, lat:60.2694015 },
        { lng:24.9016408	, lat:60.2694416 },
        { lng:24.904029	, lat:60.2698801 },
        { lng:24.9054099	, lat:60.2701894 },
        { lng:24.9058095	, lat:60.2702965 },
        { lng:24.9060922	, lat:60.2703807 },
        { lng:24.9069511	, lat:60.270675 },
        { lng:24.907246	, lat:60.2707686 },
        { lng:24.907358	, lat:60.2707946 },
        { lng:24.9074902	, lat:60.2708181 },
        { lng:24.9077042	, lat:60.2708437 },
        { lng:24.9079131	, lat:60.2708532 },
        { lng:24.9083673	, lat:60.2708482 },
        { lng:24.9085814	, lat:60.2708342 },
        { lng:24.9087993	, lat:60.2708222 },
        { lng:24.9092315	, lat:60.2708071 },
        { lng:24.9094736	, lat:60.2708031 },
        { lng:24.9096877	, lat:60.2708101 },
        { lng:24.9098734	, lat:60.2708352 },
        { lng:24.9100248	, lat:60.2708672 },
        { lng:24.9101692	, lat:60.2709128 },
        { lng:24.9102903	, lat:60.2709578 },
        { lng:24.9104428	, lat:60.2710334 },
        { lng:24.9105719	, lat:60.2711185 },
        { lng:24.9106568	, lat:60.2712086 },
        { lng:24.9107203	, lat:60.2713027 },
        { lng:24.9108091	, lat:60.2713988 },
        { lng:24.9108909	, lat:60.2714544 },
        { lng:24.9111019	, lat:60.271559 },
        { lng:24.9112584	, lat:60.2716306 },
        { lng:24.9114239	, lat:60.2716996 },
        { lng:24.9115864	, lat:60.2717612 },
        { lng:24.9117671	, lat:60.2718218 },
        { lng:24.9119416	, lat:60.2718743 },
        { lng:24.9121163	, lat:60.2719264 },
        { lng:24.9123233	, lat:60.2719759 },
        { lng:24.9125191	, lat:60.2720185 },
        { lng:24.9127089	, lat:60.2720555 },
        { lng:24.9129279	, lat:60.2720911 },
        { lng:24.9135133	, lat:60.2721766 },
        { lng:24.9136103	, lat:60.2721922 },
        { lng:24.9139181	, lat:60.2722442 },
        { lng:24.9144158	, lat:60.2723383 },
        { lng:24.9150183	, lat:60.2724369 },
        { lng:24.9152344	, lat:60.2724665 },
        { lng:24.9162488	, lat:60.2725746 },
        { lng:24.916905	, lat:60.2726286 },
        { lng:24.91734	, lat:60.2726617 },
        { lng:24.9177457	, lat:60.2726971 },
        { lng:24.9179669	, lat:60.2727187 },
        { lng:24.9180961	, lat:60.2727388 },
        { lng:24.9183635	, lat:60.2727883 },
        { lng:24.9186522	, lat:60.2728459 },
        { lng:24.9187905	, lat:60.2728734 },
        { lng:24.9194557	, lat:60.272977 },
        { lng:24.9196465	, lat:60.273007 },
        { lng:24.9199825	, lat:60.2730525 },
        { lng:24.9202673	, lat:60.2730851 },
        { lng:24.9205297	, lat:60.2731107 },
        { lng:24.9205852	, lat:60.2731317 },
        { lng:24.9206125	, lat:60.2731432 },
        { lng:24.9206962	, lat:60.2731981 },
        { lng:24.9208093	, lat:60.2733084 },
        { lng:24.920985	, lat:60.273451 },
        { lng:24.921202	, lat:60.2735726 },
        { lng:24.9212979	, lat:60.2736232 },
        { lng:24.9214633	, lat:60.2736907 },
        { lng:24.921636	, lat:60.2737503 },
        { lng:24.9218066	, lat:60.2738039 },
        { lng:24.9219802	, lat:60.2738534 },
        { lng:24.9227735	, lat:60.274051 },
        { lng:24.9228756	, lat:60.2740802 },
        { lng:24.9231239	, lat:60.2741683 },
        { lng:24.9233036	, lat:60.2742353 },
        { lng:24.9235034	, lat:60.2743189 },
        { lng:24.9236225	, lat:60.2743785 },
        { lng:24.9238991	, lat:60.2745441 },
        { lng:24.9241362	, lat:60.2746662 },
        { lng:24.9242776	, lat:60.2747248 },
        { lng:24.9244259	, lat:60.2747778 },
        { lng:24.9245784	, lat:60.2748149 },
        { lng:24.9247087	, lat:60.2748324 },
        { lng:24.9248803	, lat:60.2748494 },
        { lng:24.9250104	, lat:60.274857 },
        { lng:24.9254465	, lat:60.274865 },
        { lng:24.9257534	, lat:60.274879 },
        { lng:24.9268456	, lat:60.274923 },
        { lng:24.9271272	, lat:60.274944 },
        { lng:24.9274058	, lat:60.2749731 },
        { lng:24.9281487	, lat:60.2750587 },
        { lng:24.9283505	, lat:60.2750731 },
        { lng:24.9285787	, lat:60.2750762 },
        { lng:24.9290732	, lat:60.2750582 },
        { lng:24.9296254	, lat:60.274998 },
        { lng:24.9298556	, lat:60.2749851 },
        { lng:24.930538	, lat:60.2749631 },
        { lng:24.9309458	, lat:60.2749706 },
        { lng:24.9312688	, lat:60.2749896 },
        { lng:24.9315009	, lat:60.2750081 },
        { lng:24.931721	, lat:60.2750296 },
        { lng:24.9319641	, lat:60.2750621 },
        { lng:24.9323922	, lat:60.2751452 },
        { lng:24.932815	, lat:60.2752453 },
        { lng:24.9337691	, lat:60.2754696 },
        { lng:24.9340184	, lat:60.2755126 },
        { lng:24.9342909	, lat:60.2755461 },
        { lng:24.9349208	, lat:60.2756177 },
        { lng:24.9351367	, lat:60.2756552 },
        { lng:24.9358847	, lat:60.2758488 },
        { lng:24.9368457	, lat:60.2761232 },
        { lng:24.9369708	, lat:60.2761461 },
        { lng:24.9386239	, lat:60.2764567 },
        { lng:24.939047	, lat:60.2765407 },
        { lng:24.9392848	, lat:60.2765828 },
        { lng:24.9397932	, lat:60.2766767 },
        { lng:24.9399492	, lat:60.2767056 },
        { lng:24.9401025	, lat:60.276739 },
        { lng:24.940241	, lat:60.276773 },
        { lng:24.9403643	, lat:60.2768065 },
        { lng:24.9414371	, lat:60.2771938 },
        { lng:24.9419799	, lat:60.2773842 },
        { lng:24.9423079	, lat:60.2774777 },
        { lng:24.9426555	, lat:60.2775717 },
        { lng:24.9429684	, lat:60.2776468 },
        { lng:24.9432741	, lat:60.2777146 },
        { lng:24.9435049	, lat:60.2777689 },
        { lng:24.9437502	, lat:60.277839 },
        { lng:24.9443015	, lat:60.2780143 },
        { lng:24.9446129	, lat:60.2781214 },
        { lng:24.9447667	, lat:60.2781769 },
        { lng:24.9450882	, lat:60.2782778 },
        { lng:24.9453467	, lat:60.2783523 },
        { lng:24.9455349	, lat:60.2783908 },
        { lng:24.94564	, lat:60.2784028 },
        { lng:24.9457407	, lat:60.2784082 },
        { lng:24.9459603	, lat:60.2784049 },
        { lng:24.9461746	, lat:60.2783906 },
        { lng:24.9462931	, lat:60.2783893 },
        { lng:24.9463973	, lat:60.2783948 },
        { lng:24.9466155	, lat:60.2784151 },
        { lng:24.9472477	, lat:60.2784911 },
        { lng:24.9474615	, lat:60.2785081 },
        { lng:24.9476443	, lat:60.2785102 },
        { lng:24.9477729	, lat:60.2785 },
        { lng:24.9479262	, lat:60.278475 },
        { lng:24.9480932	, lat:60.2784354 },
        { lng:24.9482608	, lat:60.2783853 },
        { lng:24.9484724	, lat:60.2783216 },
        { lng:24.9486613	, lat:60.278268 },
        { lng:24.9489299	, lat:60.2781984 },
        { lng:24.9490508	, lat:60.2781762 },
        { lng:24.9491115	, lat:60.278165 },
        { lng:24.9492891	, lat:60.2781425 },
        { lng:24.9494139	, lat:60.2781332 },
        { lng:24.9495671	, lat:60.2781273 },
        { lng:24.9497286	, lat:60.2781296 },
        { lng:24.9499529	, lat:60.2781441 },
        { lng:24.9501723	, lat:60.2781615 },
        { lng:24.9505928	, lat:60.2782066 },
        { lng:24.9510822	, lat:60.2782658 },
        { lng:24.9512328	, lat:60.2782795 },
        { lng:24.9515274	, lat:60.2782971 },
        { lng:24.9516549	, lat:60.2782961 },
        { lng:24.9518686	, lat:60.2782885 },
        { lng:24.9520636	, lat:60.2782693 },
        { lng:24.9522603	, lat:60.2782363 },
        { lng:24.9525046	, lat:60.278191 },
        { lng:24.9526347	, lat:60.278158 },
        { lng:24.9527407	, lat:60.2781171 },
        { lng:24.9528235	, lat:60.278069 },
        { lng:24.9528871	, lat:60.2780115 },
        { lng:24.9529416	, lat:60.2779314 },
        { lng:24.9529729	, lat:60.2778538 },
        { lng:24.952984	, lat:60.2777702 },
        { lng:24.952987	, lat:60.2775499 },
        { lng:24.9530113	, lat:60.2774384 },
        { lng:24.9530537	, lat:60.2773362 },
        { lng:24.9531212	, lat:60.2772413 },
        { lng:24.9531779	, lat:60.2771781 },
        { lng:24.9532876	, lat:60.2770955 },
        { lng:24.9535465	, lat:60.27695 },
        { lng:24.9536162	, lat:60.2769169 },
        { lng:24.9536705	, lat:60.2768914 },
        { lng:24.9538006	, lat:60.2768519 },
        { lng:24.9539864	, lat:60.2768048 },
        { lng:24.9541035	, lat:60.2767827 },
        { lng:24.9544305	, lat:60.2767458 },
        { lng:24.954697	, lat:60.2767217 },
        { lng:24.9550957	, lat:60.2766957 },
        { lng:24.9553106	, lat:60.276674 },
        { lng:24.9554561	, lat:60.2766429 },
        { lng:24.9557106	, lat:60.2765766 },
        { lng:24.9560211	, lat:60.2764546 },
        { lng:24.9562293	, lat:60.2763619 },
        { lng:24.9563403	, lat:60.2762969 },
        { lng:24.9564553	, lat:60.2762248 },
        { lng:24.9565261	, lat:60.2761527 },
        { lng:24.9566128	, lat:60.2760536 },
        { lng:24.9566775	, lat:60.2759495 },
        { lng:24.956736	, lat:60.2758383 },
        { lng:24.9567602	, lat:60.2757393 },
        { lng:24.9567702	, lat:60.2756311 },
        { lng:24.9567643	, lat:60.2755576 },
        { lng:24.956737	, lat:60.2754781 },
        { lng:24.9566824	, lat:60.2753734 },
        { lng:24.9565786	, lat:60.2752118 },
        { lng:24.9565028	, lat:60.2750667 },
        { lng:24.9564564	, lat:60.2749601 },
        { lng:24.9562525	, lat:60.274396 },
        { lng:24.9562192	, lat:60.2742378 },
        { lng:24.9561829	, lat:60.2741287 },
        { lng:24.9560102	, lat:60.2737848 },
        { lng:24.9558932	, lat:60.2735791 },
        { lng:24.9558558	, lat:60.2735036 },
        { lng:24.9558245	, lat:60.2733918 },
        { lng:24.9558043	, lat:60.2732413 },
        { lng:24.9557992	, lat:60.2731441 },
        { lng:24.9558225	, lat:60.2729814 },
        { lng:24.9558578	, lat:60.2728729 },
        { lng:24.9558952	, lat:60.2727928 },
        { lng:24.9559516	, lat:60.2726901 },
        { lng:24.9560527	, lat:60.2725405 },
        { lng:24.9561354	, lat:60.2724289 },
        { lng:24.9565976	, lat:60.2718927 },
        { lng:24.9567804	, lat:60.2717172 },
        { lng:24.9572034	, lat:60.2713347 },
        { lng:24.9572851	, lat:60.2712652 },
        { lng:24.9574576	, lat:60.2711294 },
        { lng:24.9577262	, lat:60.2709453 },
        { lng:24.9578454	, lat:60.2708782 },
        { lng:24.9580281	, lat:60.2707746 },
        { lng:24.9582784	, lat:60.2706425 },
        { lng:24.9584923	, lat:60.2705307 },
        { lng:24.9594229	, lat:60.269936 },
        { lng:24.9596764	, lat:60.2697433 },
        { lng:24.9598127	, lat:60.2696202 },
        { lng:24.9599015	, lat:60.2695227 },
        { lng:24.959959	, lat:60.2694391 },
        { lng:24.9599944	, lat:60.269354 },
        { lng:24.9600156	, lat:60.2692878 },
        { lng:24.9600257	, lat:60.2691773 },
        { lng:24.9600196	, lat:60.2690551 },
        { lng:24.9599923	, lat:60.2688773 },
        { lng:24.9599924	, lat:60.2687908 },
        { lng:24.9600045	, lat:60.2687232 },
        { lng:24.9600478	, lat:60.2686295 },
        { lng:24.9601155	, lat:60.268515 },
        { lng:24.9601689	, lat:60.2684448 },
        { lng:24.9602225	, lat:60.2683857 },
        { lng:24.9602891	, lat:60.2683266 },
        { lng:24.9603739	, lat:60.2682801 },
        { lng:24.9604738	, lat:60.268225 },
        { lng:24.9606555	, lat:60.2681394 },
        { lng:24.9607816	, lat:60.2680974 },
        { lng:24.961031	, lat:60.2680333 },
        { lng:24.9612247	, lat:60.2679887 },
        { lng:24.9614388	, lat:60.2679372 },
        { lng:24.961566	, lat:60.2678936 },
        { lng:24.9620646	, lat:60.2676673 },
        { lng:24.9625949	, lat:60.2674122 },
        { lng:24.962743	, lat:60.2673379 },
        { lng:24.9631023	, lat:60.2671893 },
        { lng:24.9633274	, lat:60.2671107 },
        { lng:24.9634536	, lat:60.2670642 },
        { lng:24.9636747	, lat:60.266994 },
        { lng:24.9644519	, lat:60.2667693 },
        { lng:24.9647334	, lat:60.2666836 },
        { lng:24.9651494	, lat:60.266555 },
        { lng:24.9654219	, lat:60.2664533 },
        { lng:24.9658711	, lat:60.2663078 },
        { lng:24.9661608	, lat:60.2662331 },
        { lng:24.9663203	, lat:60.2661945 },
        { lng:24.9664424	, lat:60.2661695 },
        { lng:24.9665827	, lat:60.2661475 },
        { lng:24.9668775	, lat:60.2661055 },
        { lng:24.9671107	, lat:60.266073 },
        { lng:24.9673166	, lat:60.2660333 },
        { lng:24.9674206	, lat:60.2660048 },
        { lng:24.9681756	, lat:60.2658086 },
        { lng:24.9684017	, lat:60.26575 },
        { lng:24.9686076	, lat:60.2656964 },
        { lng:24.9688499	, lat:60.2656188 },
        { lng:24.9689928	, lat:60.2655629 },
        { lng:24.9690316	, lat:60.2655432 },
        { lng:24.9692425	, lat:60.2654385 },
        { lng:24.9693583	, lat:60.2653718 },
        { lng:24.9699402	, lat:60.2650433 },
        { lng:24.9702016	, lat:60.2649117 },
        { lng:24.9704512	, lat:60.2648141 },
        { lng:24.970691	, lat:60.2647284 },
        { lng:24.9708365	, lat:60.2646914 },
        { lng:24.9710016	, lat:60.2646563 },
        { lng:24.9714242	, lat:60.2645802 },
        { lng:24.971774	, lat:60.2645471 },
        { lng:24.9732006	, lat:60.2644383 },
        { lng:24.9732526	, lat:60.2644551 },
        { lng:24.9733918	, lat:60.2644995 },
        { lng:24.973661	, lat:60.2645674 },
        { lng:24.9743214	, lat:60.2646914 },
        { lng:24.9749347	, lat:60.2647732 },
        { lng:24.9752963	, lat:60.2648385 },
        { lng:24.9754339	, lat:60.2648737 },
        { lng:24.9755106	, lat:60.2649029 },
        { lng:24.9756325	, lat:60.264978 },
        { lng:24.9757268	, lat:60.2650666 },
        { lng:24.9758035	, lat:60.2651564 },
        { lng:24.9758119	, lat:60.265272 },
        { lng:24.9756962	, lat:60.2654715 },
        { lng:24.9755897	, lat:60.2657107 },
        { lng:24.9755251	, lat:60.2659177 },
        { lng:24.9755429	, lat:60.2661448 },
        { lng:24.9756144	, lat:60.2663142 },
        { lng:24.9757516	, lat:60.2665155 },
        { lng:24.9760455	, lat:60.2667788 },
        { lng:24.9763636	, lat:60.2669726 },
        { lng:24.9767719	, lat:60.2671524 },
        { lng:24.9772716	, lat:60.267321 },
        { lng:24.9777079	, lat:60.2675132 },
        { lng:24.9780276	, lat:60.2676752 },
        { lng:24.9780855	, lat:60.2677773 },
        { lng:24.9780801	, lat:60.2678834 },
        { lng:24.9780498	, lat:60.2679706 },
        { lng:24.9779621	, lat:60.2680672 },
        { lng:24.9778304	, lat:60.2681964 },
        { lng:24.977578	, lat:60.268357 },
        { lng:24.9772625	, lat:60.2685843 },
        { lng:24.976903	, lat:60.2687175 },
        { lng:24.976508	, lat:60.2688374 },
        { lng:24.9761541	, lat:60.2689815 },
        { lng:24.9758632	, lat:60.2692102 },
        { lng:24.9756629	, lat:60.2694211 },
        { lng:24.9755724	, lat:60.2695802 },
        { lng:24.9755367	, lat:60.2697244 },
        { lng:24.9755562	, lat:60.2699226 },
        { lng:24.9755945	, lat:60.2699905 },
        { lng:24.9756597	, lat:60.270048 },
        { lng:24.975803	, lat:60.2701497 },
        { lng:24.9758964	, lat:60.2702573 },
        { lng:24.9760225	, lat:60.2705021 },
        { lng:24.9761634	, lat:60.2707313 },
        { lng:24.9763639	, lat:60.2709646 },
        { lng:24.9766115	, lat:60.2711531 },
        { lng:24.9769115	, lat:60.2713847 },
        { lng:24.9771629	, lat:60.271734 },
        { lng:24.9772018	, lat:60.2722255 },
        { lng:24.9771674	, lat:60.2731201 },
        { lng:24.9773133	, lat:60.2734122 },
        { lng:24.9774633	, lat:60.2738576 },
        { lng:24.9776625	, lat:60.2740843 },
        { lng:24.9778459	, lat:60.2742157 },
        { lng:24.978588	, lat:60.2745081 },
        { lng:24.9792983	, lat:60.2749592 },
        { lng:24.9794815	, lat:60.2751492 },
        { lng:24.97975	, lat:60.2752628 },
        { lng:24.9801069	, lat:60.2753569 },
        { lng:24.9803231	, lat:60.2754641 },
        { lng:24.9804605	, lat:60.2756605 },
        { lng:24.980729	, lat:60.2758926 },
        { lng:24.9807682	, lat:60.2759527 },
        { lng:24.9807696	, lat:60.2760437 },
        { lng:24.9807453	, lat:60.2762027 },
        { lng:24.9808356	, lat:60.2764498 },
        { lng:24.9808397	, lat:60.276941 },
        { lng:24.9807714	, lat:60.2772462 },
        { lng:24.9808049	, lat:60.2773645 },
        { lng:24.9809114	, lat:60.2775023 },
        { lng:24.9812248	, lat:60.2776704 },
        { lng:24.98156	, lat:60.2778581 },
        { lng:24.9819342	, lat:60.2780047 },
        { lng:24.9819904	, lat:60.2780559 },
        { lng:24.9820209	, lat:60.2781122 },
        { lng:24.9820074	, lat:60.2784811 },
        { lng:24.9820799	, lat:60.2786823 },
        { lng:24.9822576	, lat:60.2788883 },
        { lng:24.9825067	, lat:60.279005 },
        { lng:24.9823979	, lat:60.2790305 },
        { lng:24.9824941	, lat:60.2791278 },
        { lng:24.9825651	, lat:60.2791865 },
        { lng:24.9827405	, lat:60.2792529 },
        { lng:24.9832	, lat:60.2793927 },
        { lng:24.9833853	, lat:60.2794377 },
        { lng:24.9834681	, lat:60.2794719 },
        { lng:24.9834637	, lat:60.2795508 },
        { lng:24.9835094	, lat:60.2796449 },
        { lng:24.9835884	, lat:60.279677 },
        { lng:24.9837224	, lat:60.2796938 },
        { lng:24.9842331	, lat:60.2797181 },
        { lng:24.9845189	, lat:60.2796829 },
        { lng:24.9848324	, lat:60.2796643 },
        { lng:24.9852049	, lat:60.279684 },
        { lng:24.9856484	, lat:60.2796889 },
        { lng:24.9859758	, lat:60.2797441 },
        { lng:24.9868471	, lat:60.2797895 },
        { lng:24.9876793	, lat:60.2797102 },
        { lng:24.9893747	, lat:60.27973 },
        { lng:24.9897567	, lat:60.2797842 },
        { lng:24.9904839	, lat:60.2799613 },
        { lng:24.9911207	, lat:60.280199 },
        { lng:24.9921206	, lat:60.2806386 },
        { lng:24.9930856	, lat:60.2811291 },
        { lng:24.9933558	, lat:60.2812274 },
        { lng:24.9936675	, lat:60.2812981 },
        { lng:24.9941196	, lat:60.281341 },
        { lng:24.9949914	, lat:60.2813368 },
        { lng:24.9953657	, lat:60.2813505 },
        { lng:24.9956342	, lat:60.2814349 },
        { lng:24.9960256	, lat:60.2816743 },
        { lng:24.9971807	, lat:60.2825541 },
        { lng:24.9983529	, lat:60.2836171 },
        { lng:24.9992254	, lat:60.2843465 },
        { lng:24.9995961	, lat:60.2846813 },
        { lng:24.9996702	, lat:60.2848181 },
        { lng:24.9997485	, lat:60.2849774 },
        { lng:24.9999133	, lat:60.2857492 },
        { lng:25.0000121	, lat:60.2861289 },
        { lng:25.000148	, lat:60.2863208 },
        { lng:25.000424	, lat:60.2864658 },
        { lng:25.0007041	, lat:60.2865556 },
        { lng:25.0009801	, lat:60.2866067 },
        { lng:25.001256	, lat:60.286623 },
        { lng:25.0015526	, lat:60.286623 },
        { lng:25.0020538	, lat:60.286584 },
        { lng:25.0032357	, lat:60.2864971 },
        { lng:25.0040634	, lat:60.2864303 },
        { lng:25.0045768	, lat:60.2863576 },
        { lng:25.0052505	, lat:60.2861716 },
        { lng:25.0054657	, lat:60.2861205 },
        { lng:25.0056306	, lat:60.2861004 },
        { lng:25.0059725	, lat:60.2861347 },
        { lng:25.0063084	, lat:60.2862102 },
        { lng:25.0069531	, lat:60.2864638 },
        { lng:25.0070511	, lat:60.286503 },
        { lng:25.0075968	, lat:60.2867503 },
        { lng:25.007904	, lat:60.2870279 },
        { lng:25.008114	, lat:60.2872086 },
        { lng:25.0083273	, lat:60.2874884 },
        { lng:25.0085144	, lat:60.2876514 },
        { lng:25.0087393	, lat:60.2877522 },
        { lng:25.0092436	, lat:60.2878237 },
        { lng:25.0100274	, lat:60.2878143 },
        { lng:25.010495	, lat:60.2878017 },
        { lng:25.0107642	, lat:60.2877657 },
        { lng:25.0110052	, lat:60.2876902 },
        { lng:25.0111838	, lat:60.2875954 },
        { lng:25.0114677	, lat:60.2874355 },
        { lng:25.0116449	, lat:60.2873543 },
        { lng:25.0118051	, lat:60.2873353 },
        { lng:25.0120814	, lat:60.2873455 },
        { lng:25.0123676	, lat:60.2873891 },
        { lng:25.0126465	, lat:60.2875002 },
        { lng:25.0129423	, lat:60.2876544 },
        { lng:25.0133955	, lat:60.2878231 },
        { lng:25.0136231	, lat:60.2879139 },
        { lng:25.0138201	, lat:60.288027 },
        { lng:25.0140321	, lat:60.2882113 },
        { lng:25.0143718	, lat:60.2884252 },
        { lng:25.0146113	, lat:60.2885474 },
        { lng:25.0154217	, lat:60.288806 },
        { lng:25.015933	, lat:60.2889319 },
        { lng:25.0164852	, lat:60.288978 },
        { lng:25.0180848	, lat:60.2888791 },
        { lng:25.0183514	, lat:60.2888727 },
        { lng:25.0185491	, lat:60.2888934 },
        { lng:25.0187403	, lat:60.2889389 },
        { lng:25.0196673	, lat:60.2892159 },
        { lng:25.019929	, lat:60.2892676 },
        { lng:25.0201348	, lat:60.289294 },
        { lng:25.0205797	, lat:60.2893154 },
        { lng:25.0209862	, lat:60.2893226 },
        { lng:25.0212914	, lat:60.2892995 },
        { lng:25.0215855	, lat:60.2892406 },
        { lng:25.0219196	, lat:60.2891467 },
        { lng:25.0224811	, lat:60.2889295 },
        { lng:25.0229371	, lat:60.2886973 },
        { lng:25.0233662	, lat:60.2883825 },
        { lng:25.0238983	, lat:60.288062 },
        { lng:25.0242992	, lat:60.2879308 },
        { lng:25.025306	, lat:60.2877104 },
        { lng:25.0264475	, lat:60.287519 },
        { lng:25.0267068	, lat:60.2874363 },
        { lng:25.0268853	, lat:60.2873275 },
        { lng:25.0270569	, lat:60.2870978 },
        { lng:25.0270999	, lat:60.2868 },
        { lng:25.0271573	, lat:60.2866079 },
        { lng:25.0272723	, lat:60.28646 },
        { lng:25.0275719	, lat:60.2862937 },
        { lng:25.0287306	, lat:60.28583 },
        { lng:25.0291619	, lat:60.2856918 },
        { lng:25.0294415	, lat:60.285617 },
        { lng:25.029943	, lat:60.2855147 },
        { lng:25.0308248	, lat:60.285433 },
        { lng:25.031368	, lat:60.2853876 },
        { lng:25.0317195	, lat:60.2852974 },
        { lng:25.0322926	, lat:60.2850812 },
        { lng:25.0326664	, lat:60.2849785 },
        { lng:25.0331277	, lat:60.2849411 },
        { lng:25.0335168	, lat:60.2849551 },
        { lng:25.0338588	, lat:60.2850018 },
        { lng:25.0341978	, lat:60.2851309 },
        { lng:25.0343798	, lat:60.285232 },
        { lng:25.0345241	, lat:60.2853673 },
        { lng:25.0345837	, lat:60.2855664 },
        { lng:25.0344519	, lat:60.2859677 },
        { lng:25.0344739	, lat:60.2863659 },
        { lng:25.0346057	, lat:60.2865572 },
        { lng:25.0349258	, lat:60.2868621 },
        { lng:25.0351737	, lat:60.287139 },
        { lng:25.0354624	, lat:60.2873116 },
        { lng:25.0357699	, lat:60.2873676 },
        { lng:25.0361277	, lat:60.2873832 },
        { lng:25.036606	, lat:60.2873498 },
        { lng:25.0368821	, lat:60.2873436 },
        { lng:25.0371049	, lat:60.2873808 },
        { lng:25.0372901	, lat:60.2874338 },
        { lng:25.0375066	, lat:60.2875146 },
        { lng:25.0376792	, lat:60.2876033 },
        { lng:25.0378204	, lat:60.2877184 },
        { lng:25.0379378	, lat:60.2878716 },
        { lng:25.0380316	, lat:60.2877762 },
        { lng:25.0383633	, lat:60.2874389 },
        { lng:25.0384689	, lat:60.2873315 },
        { lng:25.0393059	, lat:60.2864803 },
        { lng:25.0393305	, lat:60.286455 },
        { lng:25.0405491	, lat:60.2850881 },
        { lng:25.0408664	, lat:60.2847361 },
        { lng:25.0406458	, lat:60.2841367 },
        { lng:25.040735	, lat:60.2840536 },
        { lng:25.0411984	, lat:60.2836211 },
        { lng:25.0412372	, lat:60.2835855 },
        { lng:25.0415565	, lat:60.2832852 },
        { lng:25.0422522	, lat:60.2831572 },
        { lng:25.0422231	, lat:60.2830669 },
        { lng:25.0423616	, lat:60.2829463 },
        { lng:25.0425181	, lat:60.2828099 },
        { lng:25.0425594	, lat:60.2827754 },
        { lng:25.0430634	, lat:60.2823532 },
        { lng:25.0431516	, lat:60.2822227 },
        { lng:25.04318	, lat:60.2821804 },
        { lng:25.0433794	, lat:60.2819394 },
        { lng:25.0434079	, lat:60.2819048 },
        { lng:25.0424695	, lat:60.2815822 },
        { lng:25.042263	, lat:60.281511 },
        { lng:25.0424889	, lat:60.2811015 },
        { lng:25.0426599	, lat:60.2811513 },
        { lng:25.0428392	, lat:60.2809366 },
        { lng:25.0430694	, lat:60.280662 },
        { lng:25.0435	, lat:60.2801762 },
        { lng:25.0437325	, lat:60.2798772 },
        { lng:25.0441799	, lat:60.2800146 },
        { lng:25.0443114	, lat:60.2799142 },
        { lng:25.0446331	, lat:60.2796534 },
        { lng:25.0448673	, lat:60.2794613 },
        { lng:25.0450481	, lat:60.279338 },
        { lng:25.0451371	, lat:60.279226 },
        { lng:25.0451502	, lat:60.2788767 },
        { lng:25.0451575	, lat:60.2785443 },
        { lng:25.0457345	, lat:60.2784179 },
        { lng:25.046043	, lat:60.2788728 },
        { lng:25.0466546	, lat:60.2781798 },
        { lng:25.0466925	, lat:60.2781363 },
        { lng:25.047036	, lat:60.2777421 },
        { lng:25.0470785	, lat:60.2777036 },
        { lng:25.0475222	, lat:60.2777901 },
        { lng:25.0475942	, lat:60.2778041 },
        { lng:25.0481159	, lat:60.2779057 },
        { lng:25.048632	, lat:60.2780122 },
        { lng:25.0491601	, lat:60.2781085 },
        { lng:25.0496921	, lat:60.2781983 },
        { lng:25.0506533	, lat:60.2783206 },
        { lng:25.0510953	, lat:60.2779477 },
        { lng:25.0516583	, lat:60.277474 },
        { lng:25.0517392	, lat:60.2774059 },
        { lng:25.0518203	, lat:60.2773359 },
        { lng:25.0533908	, lat:60.2760155 },
        { lng:25.0566778	, lat:60.2758125 },
        { lng:25.0594281	, lat:60.2761413 },
        { lng:25.0615549	, lat:60.2763742 },
        { lng:25.0618047	, lat:60.2760909 },
        { lng:25.0623771	, lat:60.2754352 },
        { lng:25.0634713	, lat:60.2753612 },
        { lng:25.0635942	, lat:60.275353 },
        { lng:25.0640752	, lat:60.2753241 },
        { lng:25.0647295	, lat:60.27528 },
        { lng:25.0650897	, lat:60.2752607 },
        { lng:25.0653989	, lat:60.2752348 },
        { lng:25.0659209	, lat:60.2752066 },
        { lng:25.0664237	, lat:60.2751704 },
        { lng:25.0670496	, lat:60.2751292 },
        { lng:25.0677343	, lat:60.2750855 },
        { lng:25.0683143	, lat:60.2750471 },
        { lng:25.0684843	, lat:60.2750316 },
        { lng:25.0689491	, lat:60.2750639 },
        { lng:25.0694651	, lat:60.2750922 },
        { lng:25.0700231	, lat:60.2751279 },
        { lng:25.0705592	, lat:60.2751579 },
        { lng:25.0710982	, lat:60.2751943 },
        { lng:25.0717309	, lat:60.2752309 },
        { lng:25.0724551	, lat:60.2752736 },
        { lng:25.0731382	, lat:60.2753176 },
        { lng:25.0735094	, lat:60.275344 },
        { lng:25.073589	, lat:60.2753469 },
        { lng:25.0762216	, lat:60.2755146 },
        { lng:25.0763891	, lat:60.2755253 },
        { lng:25.0765549	, lat:60.2755358 },
        { lng:25.0795054	, lat:60.2744748 },
        { lng:25.084769	, lat:60.272456 },
        { lng:25.0856519	, lat:60.2721394 },
        { lng:25.0860494	, lat:60.2719886 },
        { lng:25.0879554	, lat:60.2712506 },
        { lng:25.0887222	, lat:60.2688561 },
        { lng:25.0890904	, lat:60.2677976 },
        { lng:25.0890655	, lat:60.2677044 },
        { lng:25.0886948	, lat:60.266797 },
        { lng:25.0884031	, lat:60.2660296 },
        { lng:25.0880398	, lat:60.2650738 },
        { lng:25.0878302	, lat:60.2645225 },
        { lng:25.08741	, lat:60.263417 },
        { lng:25.0863495	, lat:60.2606268 },
        { lng:25.0875307	, lat:60.2592976 },
        { lng:25.0866058	, lat:60.2575783 },
        { lng:25.0847949	, lat:60.2541885 },
        { lng:25.0847689	, lat:60.254139 },
        { lng:25.0824407	, lat:60.2496979 },
        { lng:25.0859911	, lat:60.247897 },
        { lng:25.0913755	, lat:60.2451832 },
        { lng:25.0919556	, lat:60.2450797 },
        { lng:25.0925588	, lat:60.2449721 },
        { lng:25.0927691	, lat:60.2449347 },
        { lng:25.0954338	, lat:60.2444592 },
        { lng:25.0955542	, lat:60.2444377 },
        { lng:25.0956933	, lat:60.2444145 },
        { lng:25.0982033	, lat:60.2439663 },
        { lng:25.0983433	, lat:60.2439413 },
        { lng:25.1013731	, lat:60.2433976 },
        { lng:25.1015289	, lat:60.2433689 },
        { lng:25.1059923	, lat:60.2425568 },
        { lng:25.1068138	, lat:60.2424044 },
        { lng:25.1086945	, lat:60.2420659 },
        { lng:25.1088051	, lat:60.2420456 },
        { lng:25.1089812	, lat:60.2420146 },
        { lng:25.109115	, lat:60.2419901 },
        { lng:25.1126595	, lat:60.2413377 },
        { lng:25.1134592	, lat:60.2411905 },
        { lng:25.1295994	, lat:60.2382195 },
        { lng:25.1296068	, lat:60.2379612 },
        { lng:25.1350875	, lat:60.2372173 },
        { lng:25.1354537	, lat:60.2372467 },
        { lng:25.135846	, lat:60.2372286 },
        { lng:25.1359002	, lat:60.237361 },
        { lng:25.1363065	, lat:60.2376622 },
        { lng:25.1366332	, lat:60.2380906 },
        { lng:25.1367221	, lat:60.2382032 },
        { lng:25.136825	, lat:60.2384066 },
        { lng:25.1368949	, lat:60.2385298 },
        { lng:25.1370971	, lat:60.2387954 },
        { lng:25.137176	, lat:60.2389411 },
        { lng:25.1373315	, lat:60.239296 },
        { lng:25.1380492	, lat:60.2401554 },
        { lng:25.1387102	, lat:60.2407112 },
        { lng:25.1390147	, lat:60.2409352 },
        { lng:25.1397071	, lat:60.2415342 },
        { lng:25.140739	, lat:60.242702 },
        { lng:25.1418668	, lat:60.2435835 },
        { lng:25.141708	, lat:60.2436316 },
        { lng:25.1416813	, lat:60.2438832 },
        { lng:25.142244	, lat:60.2441494 },
        { lng:25.1431759	, lat:60.2447263 },
        { lng:25.1446726	, lat:60.2450895 },
        { lng:25.1451879	, lat:60.2452911 },
        { lng:25.1470578	, lat:60.2454752 },
        { lng:25.147606	, lat:60.2454692 },
        { lng:25.1483483	, lat:60.2455336 },
        { lng:25.1501088	, lat:60.2454282 },
        { lng:25.1507582	, lat:60.2455295 },
        { lng:25.1519642	, lat:60.2461403 },
        { lng:25.1522817	, lat:60.2458957 },
        { lng:25.1537886	, lat:60.2463092 },
        { lng:25.1541064	, lat:60.246435 },
        { lng:25.1552835	, lat:60.2467511 },
        { lng:25.1574404	, lat:60.2480769 },
        { lng:25.1583228	, lat:60.2484553 },
        { lng:25.1588278	, lat:60.2485946 },
        { lng:25.1598757	, lat:60.2488132 },
        { lng:25.1595849	, lat:60.2492184 },
        { lng:25.1598502	, lat:60.2497345 },
        { lng:25.1591182	, lat:60.2507513 },
        { lng:25.1589863	, lat:60.2507247 },
        { lng:25.1586152	, lat:60.250964 },
        { lng:25.1573866	, lat:60.2510766 },
        { lng:25.1573689	, lat:60.2512447 },
        { lng:25.157307	, lat:60.251706 },
        { lng:25.1571026	, lat:60.2536152 },
        { lng:25.157688	, lat:60.2538842 },
        { lng:25.157486	, lat:60.2539967 },
        { lng:25.157319	, lat:60.2540468 },
        { lng:25.1571706	, lat:60.2541256 },
        { lng:25.1570159	, lat:60.2542375 },
        { lng:25.156957	, lat:60.2543044 },
        { lng:25.1569259	, lat:60.2544081 },
        { lng:25.1569233	, lat:60.2544453 },
        { lng:25.1568791	, lat:60.2544881 },
        { lng:25.1567929	, lat:60.2545174 },
        { lng:25.1565782	, lat:60.254509 },
        { lng:25.1560668	, lat:60.2546614 },
        { lng:25.1559679	, lat:60.2547157 },
        { lng:25.1558101	, lat:60.2548849 },
        { lng:25.1557406	, lat:60.2549319 },
        { lng:25.1556544	, lat:60.2549548 },
        { lng:25.1555576	, lat:60.2549569 },
        { lng:25.1553808	, lat:60.2549277 },
        { lng:25.1552314	, lat:60.2548818 },
        { lng:25.1551304	, lat:60.2548703 },
        { lng:25.1550378	, lat:60.2548807 },
        { lng:25.1549494	, lat:60.2549079 },
        { lng:25.1548421	, lat:60.2550519 },
        { lng:25.1546779	, lat:60.2550655 },
        { lng:25.1545853	, lat:60.2551188 },
        { lng:25.1544275	, lat:60.2553171 },
        { lng:25.1544191	, lat:60.255408 },
        { lng:25.1544696	, lat:60.2555092 },
        { lng:25.1547032	, lat:60.2556732 },
        { lng:25.1547053	, lat:60.2557369 },
        { lng:25.1545306	, lat:60.2559467 },
        { lng:25.1544443	, lat:60.2561023 },
        { lng:25.1542928	, lat:60.2562432 },
        { lng:25.1542528	, lat:60.2563633 },
        { lng:25.1540255	, lat:60.2565397 },
        { lng:25.1539813	, lat:60.2566462 },
        { lng:25.1539077	, lat:60.2566807 },
        { lng:25.153834	, lat:60.2566755 },
        { lng:25.153571	, lat:60.2566243 },
        { lng:25.1534552	, lat:60.2566452 },
        { lng:25.1530954	, lat:60.2568394 },
        { lng:25.1530112	, lat:60.2568394 },
        { lng:25.1528071	, lat:60.256806 },
        { lng:25.1527944	, lat:60.2569908 },
        { lng:25.1525314	, lat:60.2570148 },
        { lng:25.1524662	, lat:60.2570148 },
        { lng:25.1523799	, lat:60.2570231 },
        { lng:25.1522894	, lat:60.2570618 },
        { lng:25.1522746	, lat:60.2570931 },
        { lng:25.1522641	, lat:60.2572006 },
        { lng:25.1522557	, lat:60.2572779 },
        { lng:25.1522199	, lat:60.2573499 },
        { lng:25.15214	, lat:60.2574429 },
        { lng:25.1520632	, lat:60.2574951 },
        { lng:25.1519622	, lat:60.2575504 },
        { lng:25.1518328	, lat:60.2575901 },
        { lng:25.1517244	, lat:60.2576146 },
        { lng:25.1516181	, lat:60.2576199 },
        { lng:25.1515013	, lat:60.2576219 },
        { lng:25.1513877	, lat:60.2576423 },
        { lng:25.1512919	, lat:60.2576757 },
        { lng:25.1510994	, lat:60.2577754 },
        { lng:25.1510215	, lat:60.2577817 },
        { lng:25.1509216	, lat:60.257753 },
        { lng:25.1508353	, lat:60.2577478 },
        { lng:25.1506932	, lat:60.2577556 },
        { lng:25.1505301	, lat:60.2577932 },
        { lng:25.1504944	, lat:60.2578125 },
        { lng:25.1505038	, lat:60.2578704 },
        { lng:25.1505901	, lat:60.2579122 },
        { lng:25.1507648	, lat:60.257956 },
        { lng:25.1507869	, lat:60.2579884 },
        { lng:25.1506396	, lat:60.258086 },
        { lng:25.1505396	, lat:60.2581779 },
        { lng:25.1504712	, lat:60.2581988 },
        { lng:25.1498625	, lat:60.258233 },
        { lng:25.1490961	, lat:60.2581968 },
        { lng:25.1490039	, lat:60.2583171 },
        { lng:25.1488878	, lat:60.2583747 },
        { lng:25.1487643	, lat:60.2583666 },
        { lng:25.148614	, lat:60.2583046 },
        { lng:25.1485292	, lat:60.2581968 },
        { lng:25.1484131	, lat:60.2581149 },
        { lng:25.1483045	, lat:60.2580152 },
        { lng:25.1482122	, lat:60.2579997 },
        { lng:25.1480173	, lat:60.2580374 },
        { lng:25.1477137	, lat:60.2580721 },
        { lng:25.1474027	, lat:60.2581968 },
        { lng:25.1468179	, lat:60.2582928 },
        { lng:25.1467182	, lat:60.2583474 },
        { lng:25.1466468	, lat:60.2584079 },
        { lng:25.1465203	, lat:60.2584877 },
        { lng:25.1447412	, lat:60.2591685 },
        { lng:25.1446423	, lat:60.2592227 },
        { lng:25.1445918	, lat:60.2592822 },
        { lng:25.1440157	, lat:60.2602906 },
        { lng:25.1443193	, lat:60.2607571 },
        { lng:25.1441313	, lat:60.2613029 },
        { lng:25.142674	, lat:60.2629311 },
        { lng:25.1426412	, lat:60.2630108 },
        { lng:25.1425162	, lat:60.2630891 },
        { lng:25.1423883	, lat:60.2632869 },
        { lng:25.1417446	, lat:60.2648484 },
        { lng:25.1419314	, lat:60.2655804 },
        { lng:25.1431149	, lat:60.2658136 },
        { lng:25.1427957	, lat:60.2660912 },
        { lng:25.1424085	, lat:60.2668559 },
        { lng:25.1423712	, lat:60.2669295 },
        { lng:25.1423387	, lat:60.267167 },
        { lng:25.1424294	, lat:60.2674255 },
        { lng:25.1422537	, lat:60.267775 },
        { lng:25.142089	, lat:60.268027 },
        { lng:25.141904	, lat:60.2682925 },
        { lng:25.1420013	, lat:60.2687461 },
        { lng:25.1420137	, lat:60.269097 },
        { lng:25.1425241	, lat:60.2697779 },
        { lng:25.1723848	, lat:60.2764326 },
        { lng:25.1737822	, lat:60.2782564 },
        { lng:25.1748285	, lat:60.2783329 },
        { lng:25.1768698	, lat:60.2779906 },
        { lng:25.177796	, lat:60.2778992 },
        { lng:25.1782335	, lat:60.277829 },
        { lng:25.1787618	, lat:60.2778176 },
        { lng:25.1876581	, lat:60.2798927 },
        { lng:25.1877452	, lat:60.2800381 },
        { lng:25.1878028	, lat:60.2802283 },
        { lng:25.1878344	, lat:60.2802972 },
        { lng:25.1894229	, lat:60.2837721 },
        { lng:25.1895732	, lat:60.284101 },
        { lng:25.190239	, lat:60.2856802 },
        { lng:25.1902616	, lat:60.2856662 },
        { lng:25.1918717	, lat:60.284676 },
        { lng:25.1921912	, lat:60.2844804 },
        { lng:25.1949732	, lat:60.2827603 },
        { lng:25.1962808	, lat:60.281952 },
        { lng:25.1964092	, lat:60.282446 },
        { lng:25.1966755	, lat:60.2828764 },
        { lng:25.1967346	, lat:60.2831551 },
        { lng:25.1993672	, lat:60.284206 },
        { lng:25.2020579	, lat:60.2852783 },
        { lng:25.204841	, lat:60.2863873 },
        { lng:25.2045692	, lat:60.28652 },
        { lng:25.2015313	, lat:60.2880017 },
        { lng:25.2055741	, lat:60.2887124 },
        { lng:25.2072384	, lat:60.2890229 },
        { lng:25.2128752	, lat:60.2900254 },
        { lng:25.2135695	, lat:60.2901508 },
        { lng:25.2206412	, lat:60.2914287 },
        { lng:25.2225607	, lat:60.2920388 },
        { lng:25.224733	, lat:60.2937017 },
        { lng:25.2254885	, lat:60.293903 },
        { lng:25.2251923	, lat:60.2941815 },
        { lng:25.2290601	, lat:60.2960479 },
        { lng:25.2270377	, lat:60.2966159 },
        { lng:25.2295277	, lat:60.2978497 },
        { lng:25.2328585	, lat:60.2969833 },
        { lng:25.2328166	, lat:60.2968313 },
        { lng:25.238188	, lat:60.2953311 },
        { lng:25.243392	, lat:60.2961745 },
        { lng:25.2471366	, lat:60.2966784 },
        { lng:25.2528931	, lat:60.2974534 },
        { lng:25.2545115	, lat:60.2952274 },
        { lng:25.2528146	, lat:60.2949069 },
        { lng:25.2455406	, lat:60.2935335 },
        { lng:25.2515677	, lat:60.2901317 },
        { lng:25.2513305	, lat:60.2896408 },
        { lng:25.2539255	, lat:60.2896477 },
        { lng:25.2438803	, lat:60.2848555 },
        { lng:25.2473543	, lat:60.2829813 },
        { lng:25.2470058	, lat:60.2816965 },
        { lng:25.2470752	, lat:60.2785201 },
        { lng:25.244829	, lat:60.2789628 },
        { lng:25.2441593	, lat:60.2783195 },
        { lng:25.2461126	, lat:60.2776001 },
        { lng:25.2466846	, lat:60.2777315 },
        { lng:25.2522654	, lat:60.2757532 },
        { lng:25.2519927	, lat:60.2752988 },
        { lng:25.2509121	, lat:60.2734979 },
        { lng:25.2483792	, lat:60.2731169 },
        { lng:25.2464102	, lat:60.2728207 },
        { lng:25.2430014	, lat:60.272308 },
        { lng:25.2432386	, lat:60.2718237 },
        { lng:25.2348537	, lat:60.271969 },
        { lng:25.231854	, lat:60.2671811 },
        { lng:25.2320773	, lat:60.2656934 },
        { lng:25.231868	, lat:60.2652851 },
        { lng:25.2325549	, lat:60.262551 },
        { lng:25.231586	, lat:60.2625212 },
        { lng:25.2307014	, lat:60.2621592 },
        { lng:25.2306103	, lat:60.2614191 },
        { lng:25.230244	, lat:60.2614316 },
        { lng:25.2299882	, lat:60.2614172 },
        { lng:25.2282055	, lat:60.2613854 },
        { lng:25.2276337	, lat:60.2612345 },
        { lng:25.2273451	, lat:60.2611057 },
        { lng:25.2288569	, lat:60.2599229 },
        { lng:25.2289258	, lat:60.2599453 },
        { lng:25.2290982	, lat:60.260001 },
        { lng:25.2335309	, lat:60.2565508 },
        { lng:25.2374554	, lat:60.2562705 },
        { lng:25.2408283	, lat:60.2505491 },
        { lng:25.2407725	, lat:60.2491645 },
        { lng:25.240926	, lat:60.246305 },
        { lng:25.2271138	, lat:60.2451349 },
        { lng:25.2231515	, lat:60.2428222 },
        { lng:25.2266255	, lat:60.2393735 },
        { lng:25.2043882	, lat:60.2298966 },
        { lng:25.2014098	, lat:60.2181613 },
        { lng:25.2204028	, lat:60.1997372 },
        { lng:25.219541	, lat:60.1979766 },
        { lng:25.2106288	, lat:60.1799703 },
        { lng:25.2061424	, lat:60.1708883 },
        { lng:25.1942182	, lat:60.1466566 },
        { lng:25.1767848	, lat:60.0934943 },
        { lng:25.1760705	, lat:60.0875916 },
        { lng:25.1716702	, lat:60.05131 },
        { lng:25.1664908	, lat:60.0082681 },
        { lng:25.1693516	, lat:59.9434385 },
        { lng:25.0895597	, lat:59.9360766 },
        { lng:25.0097678	, lat:59.928713 },
        { lng:24.9423061	, lat:59.922486 },
        { lng:24.9119149	, lat:59.9564191 },
        { lng:24.8815238	, lat:59.9903175 },
        { lng:24.8511334	, lat:60.0241753 },
        { lng:24.8169729	, lat:60.0620898 },
        { lng:24.7828025	, lat:60.0999603 },

      ],
    },
    {
      id: 5,
      regionName: 'eCom TP  Hyvinkää',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:24.5772407	, lat:60.585803 },
        { lng:24.5961668	, lat:60.5567498 },
        { lng:24.6494948	, lat:60.5170579 },
        { lng:24.6634022	, lat:60.5081313 },
        { lng:24.6852453	, lat:60.5136178 },
        { lng:24.6957676	, lat:60.5162467 },
        { lng:24.6979464	, lat:60.5168057 },
        { lng:24.7041123	, lat:60.5183388 },
        { lng:24.7059069	, lat:60.518768 },
        { lng:24.7159647	, lat:60.5344948 },
        { lng:24.7169883	, lat:60.5361604 },
        { lng:24.7183178	, lat:60.5383241 },
        { lng:24.7184363	, lat:60.5385232 },
        { lng:24.7188195	, lat:60.5391666 },
        { lng:24.7190934	, lat:60.5396182 },
        { lng:24.7196395	, lat:60.5405187 },
        { lng:24.7489498	, lat:60.5888151 },
        { lng:24.7759459	, lat:60.5803777 },
        { lng:24.7810505	, lat:60.578782 },
        { lng:24.7753857	, lat:60.5652029 },
        { lng:24.7982167	, lat:60.5579472 },
        { lng:24.7987316	, lat:60.5569346 },
        { lng:24.7987316	, lat:60.5564283 },
        { lng:24.7989033	, lat:60.5555845 },
        { lng:24.799075	, lat:60.5551625 },
        { lng:24.79856	, lat:60.554825 },
        { lng:24.7977017	, lat:60.5546562 },
        { lng:24.7963284	, lat:60.5542343 },
        { lng:24.7949551	, lat:60.5538123 },
        { lng:24.7934101	, lat:60.5538123 },
        { lng:24.7920368	, lat:60.5533904 },
        { lng:24.7904919	, lat:60.5533904 },
        { lng:24.7882603	, lat:60.550099 },
        { lng:24.8018215	, lat:60.5453722 },
        { lng:24.8013066	, lat:60.5444437 },
        { lng:24.8026799	, lat:60.5439372 },
        { lng:24.8040531	, lat:60.5442749 },
        { lng:24.8105763	, lat:60.54208 },
        { lng:24.8104046	, lat:60.5414891 },
        { lng:24.7983883	, lat:60.5287389 },
        { lng:24.8122929	, lat:60.5286544 },
        { lng:24.8138378	, lat:60.5310191 },
        { lng:24.8248242	, lat:60.5310191 },
        { lng:24.8246525	, lat:60.5295834 },
        { lng:24.8282574	, lat:60.5294989 },
        { lng:24.8308323	, lat:60.5356634 },
        { lng:24.8353446	, lat:60.550835 },
        { lng:24.8354742	, lat:60.5512968 },
        { lng:24.8382138	, lat:60.5604784 },
        { lng:24.8802613	, lat:60.571802 },
        { lng:24.8837103	, lat:60.572705 },
        { lng:24.8834271	, lat:60.5725324 },
        { lng:24.8832071	, lat:60.572369 },
        { lng:24.8831106	, lat:60.572253 },
        { lng:24.8830945	, lat:60.5721054 },
        { lng:24.8831452	, lat:60.5720386 },
        { lng:24.8833412	, lat:60.5719077 },
        { lng:24.8836119	, lat:60.5718725 },
        { lng:24.8837353	, lat:60.5717855 },
        { lng:24.8839177	, lat:60.5717539 },
        { lng:24.8840142	, lat:60.5716827 },
        { lng:24.8841459	, lat:60.5716125 },
        { lng:24.8844946	, lat:60.5714414 },
        { lng:24.8847223	, lat:60.5713296 },
        { lng:24.8852535	, lat:60.5710686 },
        { lng:24.8856211	, lat:60.5708903 },
        { lng:24.8860581	, lat:60.5707207 },
        { lng:24.8864741	, lat:60.5706557 },
        { lng:24.8866535	, lat:60.5705863 },
        { lng:24.8868171	, lat:60.5705477 },
        { lng:24.8869996	, lat:60.570458 },
        { lng:24.8870962	, lat:60.5704343 },
        { lng:24.887434	, lat:60.5702445 },
        { lng:24.8874877	, lat:60.5701523 },
        { lng:24.8875306	, lat:60.5700811 },
        { lng:24.8875655	, lat:60.5699247 },
        { lng:24.8875574	, lat:60.5697964 },
        { lng:24.8874394	, lat:60.569488 },
        { lng:24.8873804	, lat:60.5690874 },
        { lng:24.8874261	, lat:60.5686357 },
        { lng:24.887461	, lat:60.5685313 },
        { lng:24.8874609	, lat:60.5684074 },
        { lng:24.8873884	, lat:60.5682429 },
        { lng:24.8873161	, lat:60.5681966 },
        { lng:24.8872677	, lat:60.5681307 },
        { lng:24.8869807	, lat:60.5679714 },
        { lng:24.8869378	, lat:60.5678871 },
        { lng:24.8868734	, lat:60.5678317 },
        { lng:24.886852	, lat:60.5677368 },
        { lng:24.886911	, lat:60.5676446 },
        { lng:24.8871551	, lat:60.5674927 },
        { lng:24.8873536	, lat:60.5674321 },
        { lng:24.8876137	, lat:60.5673361 },
        { lng:24.8879437	, lat:60.5672528 },
        { lng:24.8881582	, lat:60.5671553 },
        { lng:24.8883701	, lat:60.5669961 },
        { lng:24.8885042	, lat:60.5668722 },
        { lng:24.8885606	, lat:60.566723 },
        { lng:24.8887272	, lat:60.5662161 },
        { lng:24.8889177	, lat:60.5660977 },
        { lng:24.8889393	, lat:60.5660321 },
        { lng:24.8889667	, lat:60.5659721 },
        { lng:24.8891159	, lat:60.5653687 },
        { lng:24.8892736	, lat:60.5651831 },
        { lng:24.8895681	, lat:60.5641905 },
        { lng:24.8896758	, lat:60.5640817 },
        { lng:24.8899031	, lat:60.5640029 },
        { lng:24.890517	, lat:60.5638761 },
        { lng:24.8906889	, lat:60.5638368 },
        { lng:24.8911462	, lat:60.5636755 },
        { lng:24.891511	, lat:60.5635068 },
        { lng:24.8916828	, lat:60.5634461 },
        { lng:24.8918543	, lat:60.5634092 },
        { lng:24.892026	, lat:60.5634092 },
        { lng:24.8922674	, lat:60.5634118 },
        { lng:24.8924229	, lat:60.5633908 },
        { lng:24.8925892	, lat:60.5632906 },
        { lng:24.8927287	, lat:60.5632405 },
        { lng:24.8928789	, lat:60.5632484 },
        { lng:24.8930667	, lat:60.5632642 },
        { lng:24.8931874	, lat:60.5633106 },
        { lng:24.8935281	, lat:60.5634988 },
        { lng:24.8938473	, lat:60.5637667 },
        { lng:24.8939759	, lat:60.5638168 },
        { lng:24.8940779	, lat:60.5638194 },
        { lng:24.8942066	, lat:60.5637904 },
        { lng:24.8947055	, lat:60.5636692 },
        { lng:24.8948825	, lat:60.5635848 },
        { lng:24.8950247	, lat:60.5634619 },
        { lng:24.8951963	, lat:60.563425 },
        { lng:24.8953465	, lat:60.5634356 },
        { lng:24.8955343	, lat:60.5634303 },
        { lng:24.8958647	, lat:60.5633962 },
        { lng:24.8999616	, lat:60.5645832 },
        { lng:24.9002807	, lat:60.5646755 },
        { lng:24.9061917	, lat:60.561322 },
        { lng:24.9127148	, lat:60.5567657 },
        { lng:24.9127148	, lat:60.5555 },
        { lng:24.9442134	, lat:60.5646209 },
        { lng:24.964812	, lat:60.5706042 },
        { lng:24.9666033	, lat:60.5676078 },
        { lng:24.9676518	, lat:60.5676319 },
        { lng:24.9680315	, lat:60.5673011 },
        { lng:24.9705154	, lat:60.5673342 },
        { lng:24.9721127	, lat:60.5678882 },
        { lng:24.972842	, lat:60.5681412 },
        { lng:24.9733231	, lat:60.5696057 },
        { lng:24.9765867	, lat:60.5691005 },
        { lng:24.9752084	, lat:60.5682471 },
        { lng:24.9868777	, lat:60.5672039 },
        { lng:24.9889752	, lat:60.5670151 },
        { lng:24.9897644	, lat:60.5654906 },
        { lng:25.0247266	, lat:60.5691719 },
        { lng:25.0257252	, lat:60.5779806 },
        { lng:25.0306072	, lat:60.5788496 },
        { lng:25.0307385	, lat:60.5787934 },
        { lng:25.0311066	, lat:60.5788469 },
        { lng:25.0314335	, lat:60.5790163 },
        { lng:25.0316569	, lat:60.5790268 },
        { lng:25.0317706	, lat:60.5789624 },
        { lng:25.0318458	, lat:60.5788583 },
        { lng:25.0318214	, lat:60.5786859 },
        { lng:25.0316265	, lat:60.5783308 },
        { lng:25.0317204	, lat:60.5783087 },
        { lng:25.0387242	, lat:60.5806498 },
        { lng:25.0453339	, lat:60.5768636 },
        { lng:25.0499043	, lat:60.579526 },
        { lng:25.0541958	, lat:60.582026 },
        { lng:25.0549596	, lat:60.5824711 },
        { lng:25.0659963	, lat:60.5889029 },
        { lng:25.0735522	, lat:60.5932846 },
        { lng:25.0565767	, lat:60.6025919 },
        { lng:25.0596067	, lat:60.6042509 },
        { lng:25.0584736	, lat:60.6049411 },
        { lng:25.0565988	, lat:60.6058382 },
        { lng:25.0534002	, lat:60.6044426 },
        { lng:25.0530581	, lat:60.6045928 },
        { lng:25.0531537	, lat:60.6046545 },
        { lng:25.0566319	, lat:60.6068968 },
        { lng:25.0615366	, lat:60.610041 },
        { lng:25.0668183	, lat:60.6138307 },
        { lng:25.0721853	, lat:60.6176769 },
        { lng:25.0806544	, lat:60.6237411 },
        { lng:25.0838946	, lat:60.6261063 },
        { lng:25.0841064	, lat:60.626261 },
        { lng:25.0854141	, lat:60.6278946 },
        { lng:25.0974015	, lat:60.6429167 },
        { lng:25.1124255	, lat:60.661559 },
        { lng:25.0633687	, lat:60.6678161 },
        { lng:25.0551289	, lat:60.6784099 },
        { lng:25.0437993	, lat:60.6800912 },
        { lng:25.0437993	, lat:60.6816042 },
        { lng:25.0352162	, lat:60.6846301 },
        { lng:25.0293797	, lat:60.6831172 },
        { lng:25.0290364	, lat:60.6816042 },
        { lng:25.0173634	, lat:60.6821086 },
        { lng:25.0023451	, lat:60.6817393 },
        { lng:24.9990121	, lat:60.6815166 },
        { lng:24.9974007	, lat:60.6814087 },
        { lng:24.9883524	, lat:60.680629 },
        { lng:24.9386566	, lat:60.6732039 },
        { lng:24.931876	, lat:60.6721886 },
        { lng:24.9346226	, lat:60.6689934 },
        { lng:24.9298161	, lat:60.6678161 },
        { lng:24.9287861	, lat:60.6688252 },
        { lng:24.9205464	, lat:60.6661342 },
        { lng:24.9202031	, lat:60.6632748 },
        { lng:24.8992397	, lat:60.6638787 },
        { lng:24.896499	, lat:60.6639999 },
        { lng:24.8950623	, lat:60.664069 },
        { lng:24.8949478	, lat:60.6640696 },
        { lng:24.8949429	, lat:60.6630727 },
        { lng:24.8949363	, lat:60.6618902 },
        { lng:24.894519	, lat:60.6607775 },
        { lng:24.8944638	, lat:60.6606579 },
        { lng:24.8944107	, lat:60.6605879 },
        { lng:24.8943803	, lat:60.6605474 },
        { lng:24.8943285	, lat:60.6604784 },
        { lng:24.8941788	, lat:60.6600993 },
        { lng:24.8939572	, lat:60.6601101 },
        { lng:24.8938705	, lat:60.6601149 },
        { lng:24.891015	, lat:60.6600376 },
        { lng:24.8811556	, lat:60.6597704 },
        { lng:24.8657372	, lat:60.659616 },
        { lng:24.8509477	, lat:60.6594679 },
        { lng:24.8355689	, lat:60.6593137 },
        { lng:24.8350991	, lat:60.6662604 },
        { lng:24.8237963	, lat:60.6677492 },
        { lng:24.8235737	, lat:60.6677786 },
        { lng:24.818316	, lat:60.668471 },
        { lng:24.8105329	, lat:60.6722726 },
        { lng:24.8094534	, lat:60.6728329 },
        { lng:24.8036103	, lat:60.6691365 },
        { lng:24.7991359	, lat:60.6663194 },
        { lng:24.7955424	, lat:60.6640921 },
        { lng:24.7936485	, lat:60.6629153 },
        { lng:24.7899327	, lat:60.6605898 },
        { lng:24.7870367	, lat:60.6587493 },
        { lng:24.7846853	, lat:60.6572494 },
        { lng:24.7834693	, lat:60.6565024 },
        { lng:24.7822287	, lat:60.655852 },
        { lng:24.7815711	, lat:60.6555033 },
        { lng:24.7806671	, lat:60.654901 },
        { lng:24.7792715	, lat:60.6538954 },
        { lng:24.7769455	, lat:60.6548163 },
        { lng:24.7744777	, lat:60.6558146 },
        { lng:24.7702748	, lat:60.6575026 },
        { lng:24.7641233	, lat:60.6599113 },
        { lng:24.7615105	, lat:60.6571516 },
        { lng:24.7604122	, lat:60.6560093 },
        { lng:24.7572576	, lat:60.6543664 },
        { lng:24.7517532	, lat:60.6548414 },
        { lng:24.750577	, lat:60.6522776 },
        { lng:24.7493101	, lat:60.6495801 },
        { lng:24.7460694	, lat:60.6492537 },
        { lng:24.7417778	, lat:60.6502634 },
        { lng:24.7398895	, lat:60.6477392 },
        { lng:24.7124237	, lat:60.6501793 },
        { lng:24.6914425	, lat:60.6493973 },
        { lng:24.6516401	, lat:60.6602154 },
        { lng:24.6390828	, lat:60.658768 },
        { lng:24.6167233	, lat:60.6670342 },
        { lng:24.6078342	, lat:60.6641708 },
        { lng:24.6056332	, lat:60.6609474 },
        { lng:24.5645445	, lat:60.6360426 },
        { lng:24.5654212	, lat:60.6260847 },
        { lng:24.5631104	, lat:60.6043768 },
        { lng:24.5630538	, lat:60.6035994 },
        { lng:24.5629319	, lat:60.6024612 },
        { lng:24.5636431	, lat:60.6016635 },
        { lng:24.5642273	, lat:60.6018487 },
        { lng:24.5646307	, lat:60.6018865 },
        { lng:24.5655897	, lat:60.6018541 },
        { lng:24.5667183	, lat:60.6022832 },
        { lng:24.5669938	, lat:60.6019106 },
        { lng:24.5661604	, lat:60.6015627 },
        { lng:24.5647332	, lat:60.6012015 },
        { lng:24.5643753	, lat:60.6010336 },
        { lng:24.5642478	, lat:60.6009218 },
        { lng:24.5772407	, lat:60.585803 },
      ],
    },
    {
      id: 6,
      regionName: 'eCom TP  Joensuu kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [       
        { lng:29.6372066	, lat:62.6545282 },
        { lng:29.613095	, lat:62.6501829 },
        { lng:29.6135884	, lat:62.6492003 },
        { lng:29.620003	, lat:62.6478744 },
        { lng:29.6214116	, lat:62.6475211 },
        { lng:29.62258	, lat:62.6472129 },
        { lng:29.6233894	, lat:62.6469819 },
        { lng:29.6242883	, lat:62.6467138 },
        { lng:29.6260179	, lat:62.6468795 },
        { lng:29.6272474	, lat:62.6463877 },
        { lng:29.6282242	, lat:62.6465749 },
        { lng:29.6295879	, lat:62.646825 },
        { lng:29.6303373	, lat:62.6467044 },
        { lng:29.630581	, lat:62.6465696 },
        { lng:29.6308943	, lat:62.6461793 },
        { lng:29.6310388	, lat:62.6460117 },
        { lng:29.6317808	, lat:62.6453699 },
        { lng:29.6330691	, lat:62.6442284 },
        { lng:29.6338211	, lat:62.6440381 },
        { lng:29.6345636	, lat:62.6438512 },
        { lng:29.6354335	, lat:62.6438533 },
        { lng:29.6362199	, lat:62.6437864 },
        { lng:29.6367858	, lat:62.6434422 },
        { lng:29.637076	, lat:62.6428599 },
        { lng:29.6382708	, lat:62.642159 },
        { lng:29.6385073	, lat:62.6416007 },
        { lng:29.6382534	, lat:62.6408827 },
        { lng:29.6378785	, lat:62.6402776 },
        { lng:29.6379313	, lat:62.6397925 },
        { lng:29.6373908	, lat:62.6395076 },
        { lng:29.6375895	, lat:62.6392312 },
        { lng:29.6376338	, lat:62.638311 },
        { lng:29.6379343	, lat:62.6378828 },
        { lng:29.6380013	, lat:62.6378063 },
        { lng:29.6382846	, lat:62.6366059 },
        { lng:29.6377111	, lat:62.635499 },
        { lng:29.6378616	, lat:62.6351271 },
        { lng:29.6377846	, lat:62.6349354 },
        { lng:29.637541	, lat:62.6345922 },
        { lng:29.6361661	, lat:62.6344924 },
        { lng:29.6362207	, lat:62.6335822 },
        { lng:29.636104	, lat:62.6333742 },
        { lng:29.6356514	, lat:62.632568 },
        { lng:29.6358023	, lat:62.6322842 },
        { lng:29.6351769	, lat:62.6316226 },
        { lng:29.63549	, lat:62.6312718 },
        { lng:29.6353177	, lat:62.6309142 },
        { lng:29.6356199	, lat:62.628839 },
        { lng:29.6383222	, lat:62.6111552 },
        { lng:29.7015999	, lat:62.5632136 },
        { lng:29.7179917	, lat:62.5507292 },
        { lng:29.7430051	, lat:62.5285673 },
        { lng:29.7510542	, lat:62.521363 },
        { lng:29.7611282	, lat:62.4669892 },
        { lng:29.7629082	, lat:62.45897 },
        { lng:29.7632206	, lat:62.4074388 },
        { lng:29.8306858	, lat:62.4042442 },
        { lng:29.8357357	, lat:62.3995096 },
        { lng:29.8448499	, lat:62.3965351 },
        { lng:29.8455962	, lat:62.3929524 },
        { lng:29.8460686	, lat:62.3918436 },
        { lng:29.8517179	, lat:62.3875229 },
        { lng:29.8565091	, lat:62.3830477 },
        { lng:29.8827616	, lat:62.3758706 },
        { lng:29.8830849	, lat:62.3745457 },
        { lng:29.945134	, lat:62.3676495 },
        { lng:29.9496635	, lat:62.3702151 },
        { lng:29.9525145	, lat:62.3720043 },
        { lng:29.9696054	, lat:62.3751455 },
        { lng:29.9742258	, lat:62.3803936 },
        { lng:30.0384838	, lat:62.3881646 },
        { lng:30.0711485	, lat:62.3998894 },
        { lng:30.0875673	, lat:62.3934349 },
        { lng:30.1060947	, lat:62.3908399 },
        { lng:30.1309821	, lat:62.3895424 },
        { lng:30.1453961	, lat:62.3914166 },
        { lng:30.1614347	, lat:62.3961418 },
        { lng:30.1781991	, lat:62.4015388 },
        { lng:30.192302	, lat:62.3918651 },
        { lng:30.2104837	, lat:62.4116576 },
        { lng:30.2127305	, lat:62.412474 },
        { lng:30.2410745	, lat:62.4162675 },
        { lng:30.2443928	, lat:62.4151471 },
        { lng:30.2519282	, lat:62.4021793 },
        { lng:30.2533454	, lat:62.397375 },
        { lng:30.2772304	, lat:62.3510059 },
        { lng:30.2797537	, lat:62.3501237 },
        { lng:30.3167047	, lat:62.3579987 },
        { lng:30.3377553	, lat:62.3622161 },
        { lng:30.3768839	, lat:62.3561705 },
        { lng:30.4071982	, lat:62.3373845 },
        { lng:30.4759378	, lat:62.3489387 },
        { lng:30.4931636	, lat:62.3500916 },
        { lng:30.5133155	, lat:62.3541336 },
        { lng:30.5557279	, lat:62.3569082 },
        { lng:30.5699938	, lat:62.3725592 },
        { lng:30.6123123	, lat:62.3591854 },
        { lng:30.6134184	, lat:62.356363 },
        { lng:30.6140751	, lat:62.3539733 },
        { lng:30.6147664	, lat:62.353043 },
        { lng:30.6204698	, lat:62.3488243 },
        { lng:30.6660968	, lat:62.354695 },
        { lng:30.686387	, lat:62.327963 },
        { lng:30.6887029	, lat:62.3241743 },
        { lng:30.7229577	, lat:62.3294719 },
        { lng:30.7617752	, lat:62.2835122 },
        { lng:30.7619135	, lat:62.2813418 },
        { lng:30.7656811	, lat:62.2784638 },
        { lng:30.7778483	, lat:62.2646325 },
        { lng:30.7827913	, lat:62.2586477 },
        { lng:30.7860117	, lat:62.2550086 },
        { lng:30.7894291	, lat:62.2509636 },
        { lng:30.8086955	, lat:62.2610155 },
        { lng:30.8093379	, lat:62.2695726 },
        { lng:30.8108298	, lat:62.2706682 },
        { lng:30.8341471	, lat:62.2754925 },
        { lng:30.840439	, lat:62.2786442 },
        { lng:30.8472037	, lat:62.282009 },
        { lng:30.860231	, lat:62.2885747 },
        { lng:30.8866252	, lat:62.3017272 },
        { lng:30.9043505	, lat:62.310261 },
        { lng:30.9207199	, lat:62.3088002 },
        { lng:30.9307385	, lat:62.3067193 },
        { lng:30.9432722	, lat:62.3059687 },
        { lng:30.9551691	, lat:62.3122651 },
        { lng:30.96225	, lat:62.32725 },
        { lng:30.9619598	, lat:62.3275431 },
        { lng:30.9619993	, lat:62.3277474 },
        { lng:30.9612132	, lat:62.3281862 },
        { lng:30.9599971	, lat:62.3282107 },
        { lng:30.9584637	, lat:62.328358 },
        { lng:30.9576177	, lat:62.3287264 },
        { lng:30.9568774	, lat:62.329021 },
        { lng:30.9556613	, lat:62.329021 },
        { lng:30.9555027	, lat:62.3295367 },
        { lng:30.9550268	, lat:62.3297577 },
        { lng:30.9539164	, lat:62.3299541 },
        { lng:30.953652	, lat:62.330347 },
        { lng:30.9529647	, lat:62.3306416 },
        { lng:30.9514842	, lat:62.3307644 },
        { lng:30.9506382	, lat:62.3310099 },
        { lng:30.9511044	, lat:62.3313353 },
        { lng:30.9509554	, lat:62.3315992 },
        { lng:30.9499372	, lat:62.3317537 },
        { lng:30.9498078	, lat:62.3320458 },
        { lng:30.948746	, lat:62.3322439 },
        { lng:30.9479609	, lat:62.3322565 },
        { lng:30.9473409	, lat:62.3325662 },
        { lng:30.9471002	, lat:62.33246 },
        { lng:30.9471484	, lat:62.3321884 },
        { lng:30.9465139	, lat:62.3321639 },
        { lng:30.9457208	, lat:62.3321639 },
        { lng:30.9452449	, lat:62.3325579 },
        { lng:30.9449429	, lat:62.3325186 },
        { lng:30.944683	, lat:62.332299 },
        { lng:30.9440288	, lat:62.3323849 },
        { lng:30.9436058	, lat:62.3326058 },
        { lng:30.9424425	, lat:62.3329987 },
        { lng:30.9430154	, lat:62.3333692 },
        { lng:31.0714363	, lat:62.4051671 },
        { lng:31.0773055	, lat:62.4073713 },
        { lng:31.1015095	, lat:62.4184849 },
        { lng:31.0874668	, lat:62.4163296 },
        { lng:30.9548189	, lat:62.3862444 },
        { lng:30.9357731	, lat:62.4141891 },
        { lng:30.9373631	, lat:62.4465849 },
        { lng:30.9432047	, lat:62.4474803 },
        { lng:30.9555448	, lat:62.4501824 },
        { lng:31.0001002	, lat:62.4553138 },
        { lng:31.0001693	, lat:62.4607322 },
        { lng:30.9374287	, lat:62.4881888 },
        { lng:30.9277582	, lat:62.4859915 },
        { lng:30.9271145	, lat:62.4856545 },
        { lng:30.9270286	, lat:62.4841973 },
        { lng:30.9203556	, lat:62.4781084 },
        { lng:30.911579	, lat:62.4847723 },
        { lng:30.9041118	, lat:62.4920278 },
        { lng:30.8937906	, lat:62.4983204 },
        { lng:30.8875036	, lat:62.5032247 },
        { lng:30.8875035	, lat:62.5045721 },
        { lng:30.8755517	, lat:62.5188142 },
        { lng:30.8743286	, lat:62.519844 },
        { lng:30.8691573	, lat:62.5237944 },
        { lng:30.866239	, lat:62.5255566 },
        { lng:30.8661103	, lat:62.5273385 },
        { lng:30.8694791	, lat:62.5286748 },
        { lng:30.8649516	, lat:62.5355238 },
        { lng:30.8663893	, lat:62.5356326 },
        { lng:30.8666467	, lat:62.5362759 },
        { lng:30.8655739	, lat:62.5378988 },
        { lng:30.8633208	, lat:62.5389576 },
        { lng:30.8620548	, lat:62.5400856 },
        { lng:30.8597159	, lat:62.5407584 },
        { lng:30.8582568	, lat:62.5408277 },
        { lng:30.8579349	, lat:62.5410751 },
        { lng:30.858171	, lat:62.5417825 },
        { lng:30.8598769	, lat:62.5428461 },
        { lng:30.8602524	, lat:62.5433705 },
        { lng:30.8619904	, lat:62.5435585 },
        { lng:30.8635139	, lat:62.5441422 },
        { lng:30.8500814	, lat:62.5483664 },
        { lng:30.847485	, lat:62.5509085 },
        { lng:30.8410532	, lat:62.5545539 },
        { lng:30.8104182	, lat:62.5470963 },
        { lng:30.8099449	, lat:62.5474414 },
        { lng:30.8071875	, lat:62.5481388 },
        { lng:30.8070695	, lat:62.5483169 },
        { lng:30.7999456	, lat:62.5497215 },
        { lng:30.7974458	, lat:62.5534306 },
        { lng:30.7929826	, lat:62.5557646 },
        { lng:30.7901931	, lat:62.557159 },
        { lng:30.7794428	, lat:62.5565854 },
        { lng:30.7735419	, lat:62.5545976 },
        { lng:30.7716751	, lat:62.5564371 },
        { lng:30.7690143	, lat:62.5570552 },
        { lng:30.7667291	, lat:62.5579304 },
        { lng:30.7637357	, lat:62.5579897 },
        { lng:30.7612145	, lat:62.5590379 },
        { lng:30.759927	, lat:62.5599822 },
        { lng:30.7572019	, lat:62.5601899 },
        { lng:30.7557535	, lat:62.560882 },
        { lng:30.7515263	, lat:62.5599723 },
        { lng:30.7489353	, lat:62.5614506 },
        { lng:30.7519041	, lat:62.5667682 },
        { lng:30.735476	, lat:62.5750429 },
        { lng:30.7200694	, lat:62.5828101 },
        { lng:30.7158208	, lat:62.5838277 },
        { lng:30.711379	, lat:62.5867818 },
        { lng:30.7066584	, lat:62.589617 },
        { lng:30.7022649	, lat:62.5921667 },
        { lng:30.7013368	, lat:62.5928766 },
        { lng:30.7015192	, lat:62.5943903 },
        { lng:30.7007736	, lat:62.594889 },
        { lng:30.7006341	, lat:62.5952174 },
        { lng:30.7003498	, lat:62.595568 },
        { lng:30.6997959	, lat:62.5956859 },
        { lng:30.6994325	, lat:62.5957285 },
        { lng:30.6992447	, lat:62.5958495 },
        { lng:30.6991267	, lat:62.5960557 },
        { lng:30.6987512	, lat:62.5963828 },
        { lng:30.6983757	, lat:62.5969433 },
        { lng:30.6980002	, lat:62.5976395 },
        { lng:30.6977668	, lat:62.597895 },
        { lng:30.697614	, lat:62.598 },
        { lng:30.6973726	, lat:62.5980814 },
        { lng:30.6969058	, lat:62.5981604 },
        { lng:30.6963908	, lat:62.5980691 },
        { lng:30.6956823	, lat:62.5999408 },
        { lng:30.6984937	, lat:62.6056204 },
        { lng:30.7007789	, lat:62.6056105 },
        { lng:30.7074362	, lat:62.611699 },
        { lng:30.7109016	, lat:62.6148502 },
        { lng:30.7140832	, lat:62.6178025 },
        { lng:30.7085144	, lat:62.6196465 },
        { lng:30.7007682	, lat:62.6241313 },
        { lng:30.6928825	, lat:62.6290421 },
        { lng:30.6701588	, lat:62.6321322 },
        { lng:30.669574	, lat:62.6397702 },
        { lng:30.6352798	, lat:62.6483617 },
        { lng:30.5931155	, lat:62.6635682 },
        { lng:30.5938522	, lat:62.6660022 },
        { lng:30.5824657	, lat:62.6868964 },
        { lng:30.549506	, lat:62.6865501 },
        { lng:30.518142	, lat:62.6913211 },
        { lng:30.5151616	, lat:62.6966395 },
        { lng:30.5135339	, lat:62.7019619 },
        { lng:30.5131853	, lat:62.7031083 },
        { lng:30.5125482	, lat:62.7046667 },
        { lng:30.511149	, lat:62.7075315 },
        { lng:30.5415855	, lat:62.729372 },
        { lng:30.5456507	, lat:62.7343355 },
        { lng:30.5486226	, lat:62.738572 },
        { lng:30.5512458	, lat:62.7422157 },
        { lng:30.5533433	, lat:62.7447462 },
        { lng:30.5538613	, lat:62.7459016 },
        { lng:30.548585	, lat:62.7619788 },
        { lng:30.5592719	, lat:62.7699022 },
        { lng:30.5567217	, lat:62.7745605 },
        { lng:30.5502641	, lat:62.7863499 },
        { lng:30.5459615	, lat:62.7939084 },
        { lng:30.5227017	, lat:62.8111904 },
        { lng:30.5239552	, lat:62.8116093 },
        { lng:30.522573	, lat:62.8142051 },
        { lng:30.5274438	, lat:62.816156 },
        { lng:30.5271649	, lat:62.8226545 },
        { lng:30.5271864	, lat:62.8246685 },
        { lng:30.5269933	, lat:62.8276525 },
        { lng:30.5270932	, lat:62.8289648 },
        { lng:30.5228989	, lat:62.8268371 },
        { lng:30.5216503	, lat:62.8274271 },
        { lng:30.5198907	, lat:62.8275251 },
        { lng:30.5071556	, lat:62.8311358 },
        { lng:30.5044452	, lat:62.8318295 },
        { lng:30.5008403	, lat:62.8318344 },
        { lng:30.488175	, lat:62.8451072 },
        { lng:30.4867977	, lat:62.8448791 },
        { lng:30.4862612	, lat:62.8448129 },
        { lng:30.4856497	, lat:62.844764 },
        { lng:30.4853197	, lat:62.8447754 },
        { lng:30.4850221	, lat:62.8448301 },
        { lng:30.4847189	, lat:62.8449174 },
        { lng:30.4845446	, lat:62.8449819 },
        { lng:30.4843622	, lat:62.8451129 },
        { lng:30.4840457	, lat:62.8453859 },
        { lng:30.4839438	, lat:62.8455181 },
        { lng:30.4837829	, lat:62.8456307 },
        { lng:30.4836126	, lat:62.8456937 },
        { lng:30.4834422	, lat:62.8457278 },
        { lng:30.482887	, lat:62.8457604 },
        { lng:30.4819509	, lat:62.8457522 },
        { lng:30.4816371	, lat:62.8458119 },
        { lng:30.4814333	, lat:62.8459122 },
        { lng:30.4812509	, lat:62.8460322 },
        { lng:30.4811972	, lat:62.8461644 },
        { lng:30.4812427	, lat:62.8462786 },
        { lng:30.4803604	, lat:62.8460909 },
        { lng:30.4790944	, lat:62.8458828 },
        { lng:30.4788543	, lat:62.8458624 },
        { lng:30.4786706	, lat:62.8458559 },
        { lng:30.478397	, lat:62.8458975 },
        { lng:30.4778874	, lat:62.8460395 },
        { lng:30.4771632	, lat:62.8461766 },
        { lng:30.4761922	, lat:62.8462004 },
        { lng:30.4763263	, lat:62.8464019 },
        { lng:30.4762888	, lat:62.8465096 },
        { lng:30.4761118	, lat:62.8466198 },
        { lng:30.4759562	, lat:62.8466516 },
        { lng:30.4755766	, lat:62.8466643 },
        { lng:30.4755485	, lat:62.8469258 },
        { lng:30.475193	, lat:62.8483321 },
        { lng:30.4747009	, lat:62.8502992 },
        { lng:30.4740375	, lat:62.8530616 },
        { lng:30.4688859	, lat:62.8740731 },
        { lng:30.4662251	, lat:62.8846448 },
        { lng:30.4648948	, lat:62.8898986 },
        { lng:30.4630919	, lat:62.8974146 },
        { lng:30.4650017	, lat:62.9001082 },
        { lng:30.4542943	, lat:62.9003711 },
        { lng:30.4552599	, lat:62.901905 },
        { lng:30.4489191	, lat:62.9033494 },
        { lng:30.4362162	, lat:62.9175467 },
        { lng:30.4349395	, lat:62.9181425 },
        { lng:30.4361626	, lat:62.9184463 },
        { lng:30.436345	, lat:62.9188577 },
        { lng:30.4333516	, lat:62.9453358 },
        { lng:30.4288026	, lat:62.9483476 },
        { lng:30.4179772	, lat:62.9523182 },
        { lng:30.4134389	, lat:62.9550551 },
        { lng:30.4115078	, lat:62.9581419 },
        { lng:30.410467	, lat:62.9583346 },
        { lng:30.3908762	, lat:62.9690051 },
        { lng:30.3876579	, lat:62.9707074 },
        { lng:30.3766604	, lat:62.9778393 },
        { lng:30.3772221	, lat:62.9785614 },
        { lng:30.3722187	, lat:62.9811588 },
        { lng:30.3588828	, lat:62.9977462 },
        { lng:30.3613933	, lat:62.9984022 },
        { lng:30.351834	, lat:63.0034462 },
        { lng:30.3266093	, lat:63.0167505 },
        { lng:30.3041218	, lat:63.0216546 },
        { lng:30.2859207	, lat:63.0257208 },
        { lng:30.2660466	, lat:63.0393436 },
        { lng:30.2433279	, lat:63.041109 },
        { lng:30.2410704	, lat:63.0410973 },
        { lng:30.2394402	, lat:63.0424329 },
        { lng:30.2040909	, lat:63.0433801 },
        { lng:30.186589	, lat:63.0439839 },
        { lng:30.1849335	, lat:63.0444112 },
        { lng:30.1826144	, lat:63.0443849 },
        { lng:30.1823607	, lat:63.044097 },
        { lng:30.1703185	, lat:63.040828 },
        { lng:30.1615772	, lat:63.0383813 },
        { lng:30.1410094	, lat:63.0325606 },
        { lng:30.1402666	, lat:63.0323534 },
        { lng:30.1192239	, lat:63.0369457 },
        { lng:30.1184553	, lat:63.0367347 },
        { lng:30.117479	, lat:63.0371482 },
        { lng:30.1151723	, lat:63.037462 },
        { lng:30.1061708	, lat:63.0386319 },
        { lng:30.1023352	, lat:63.0386878 },
        { lng:30.1003718	, lat:63.0393858 },
        { lng:30.0944683	, lat:63.04013 },
        { lng:30.0880181	, lat:63.0409261 },
        { lng:30.085051	, lat:63.0409253 },
        { lng:30.0789571	, lat:63.0422288 },
        { lng:30.0673113	, lat:63.0438004 },
        { lng:30.0683463	, lat:63.0428392 },
        { lng:30.0666189	, lat:63.0423772 },
        { lng:30.0634853	, lat:63.0443336 },
        { lng:30.0413528	, lat:63.0474181 },
        { lng:30.0341865	, lat:63.0475887 },
        { lng:30.0266713	, lat:63.0466433 },
        { lng:30.0250181	, lat:63.0401772 },
        { lng:30.0202064	, lat:63.0347805 },
        { lng:30.0032548	, lat:63.0352318 },
        { lng:29.9833185	, lat:63.0457416 },
        { lng:29.9578753	, lat:63.0347865 },
        { lng:29.95814	, lat:63.0329211 },
        { lng:29.9585535	, lat:63.0300061 },
        { lng:29.9599859	, lat:63.0195471 },
        { lng:29.9601821	, lat:63.0185229 },
        { lng:29.9618367	, lat:63.0055952 },
        { lng:29.9593198	, lat:63.0046297 },
        { lng:29.9621569	, lat:63.0032588 },
        { lng:29.9633221	, lat:62.9947541 },
        { lng:29.9627852	, lat:62.9932974 },
        { lng:29.9636972	, lat:62.9919138 },
        { lng:29.9648757	, lat:62.9826658 },
        { lng:29.9659717	, lat:62.9816025 },
        { lng:29.965049	, lat:62.9814904 },
        { lng:29.9676561	, lat:62.9577568 },
        { lng:29.9689972	, lat:62.9576962 },
        { lng:29.9685573	, lat:62.9565011 },
        { lng:29.9664009	, lat:62.9555548 },
        { lng:29.9683052	, lat:62.9517837 },
        { lng:29.9666749	, lat:62.9440883 },
        { lng:29.964695	, lat:62.9451575 },
        { lng:29.9620664	, lat:62.9442693 },
        { lng:29.9657571	, lat:62.9398867 },
        { lng:29.961473	, lat:62.9205691 },
        { lng:29.9594593	, lat:62.9106018 },
        { lng:29.9540263	, lat:62.8856481 },
        { lng:29.9480731	, lat:62.8839851 },
        { lng:29.9530296	, lat:62.881223 },
        { lng:29.9663675	, lat:62.8695768 },
        { lng:29.9666196	, lat:62.8693566 },
        { lng:29.9666798	, lat:62.8693041 },
        { lng:29.967742	, lat:62.8683764 },
        { lng:29.9690536	, lat:62.8672309 },
        { lng:29.9711848	, lat:62.8653694 },
        { lng:29.9715142	, lat:62.8650817 },
        { lng:29.9717359	, lat:62.8648881 },
        { lng:29.9720037	, lat:62.8646542 },
        { lng:29.9786552	, lat:62.8588436 },
        { lng:29.9880063	, lat:62.8505833 },
        { lng:30.0037138	, lat:62.8361344 },
        { lng:30.0045097	, lat:62.8339771 },
        { lng:30.0056304	, lat:62.8297753 },
        { lng:30.023495	, lat:62.8147736 },
        { lng:30.0280219	, lat:62.8037625 },
        { lng:30.0313103	, lat:62.7958521 },
        { lng:30.0381264	, lat:62.7797889 },
        { lng:30.0864379	, lat:62.7514534 },
        { lng:30.0898651	, lat:62.7492879 },
        { lng:30.1166796	, lat:62.7425818 },
        { lng:30.1389098	, lat:62.7352202 },
        { lng:30.1417637	, lat:62.7359672 },
        { lng:30.1411843	, lat:62.73811 },
        { lng:30.1421499	, lat:62.7390142 },
        { lng:30.1427078	, lat:62.7388078 },
        { lng:30.1435232	, lat:62.7360459 },
        { lng:30.143137	, lat:62.7340406 },
        { lng:30.1451969	, lat:62.7337063 },
        { lng:30.1456904	, lat:62.7338046 },
        { lng:30.149188	, lat:62.7332541 },
        { lng:30.1490899	, lat:62.7329951 },
        { lng:30.1576155	, lat:62.7315116 },
        { lng:30.1828122	, lat:62.7251429 },
        { lng:30.1930408	, lat:62.7259478 },
        { lng:30.1962769	, lat:62.7217896 },
        { lng:30.1963842	, lat:62.7218142 },
        { lng:30.2013181	, lat:62.7156736 },
        { lng:30.204907	, lat:62.7095092 },
        { lng:30.2105543	, lat:62.6997499 },
        { lng:30.284061	, lat:62.6590463 },
        { lng:30.2800798	, lat:62.641053 },
        { lng:30.2827835	, lat:62.6390018 },
        { lng:30.2850366	, lat:62.6361121 },
        { lng:30.2864742	, lat:62.6356042 },
        { lng:30.2858519	, lat:62.6341296 },
        { lng:30.2879977	, lat:62.6325514 },
        { lng:30.2849615	, lat:62.6315404 },
        { lng:30.2848327	, lat:62.6313653 },
        { lng:30.2850044	, lat:62.6309608 },
        { lng:30.2804918	, lat:62.6293268 },
        { lng:30.2617657	, lat:62.6373943 },
        { lng:30.2606499	, lat:62.6387947 },
        { lng:30.2583218	, lat:62.641053 },
        { lng:30.2574256	, lat:62.6410017 },
        { lng:30.2334358	, lat:62.6252703 },
        { lng:30.2103623	, lat:62.6213816 },
        { lng:30.2027948	, lat:62.6173054 },
        { lng:30.1990129	, lat:62.6130752 },
        { lng:30.2105925	, lat:62.6068894 },
        { lng:30.2036905	, lat:62.5972815 },
        { lng:30.2015984	, lat:62.5969556 },
        { lng:30.2019417	, lat:62.5960816 },
        { lng:30.2012551	, lat:62.5954594 },
        { lng:30.2018666	, lat:62.5947532 },
        { lng:30.2011429	, lat:62.5937331 },
        { lng:30.1869708	, lat:62.5947951 },
        { lng:30.1764159	, lat:62.5972014 },
        { lng:30.1700616	, lat:62.598637 },
        { lng:30.1647018	, lat:62.5998297 },
        { lng:30.1395393	, lat:62.6053056 },
        { lng:30.134999	, lat:62.6058467 },
        { lng:30.1322329	, lat:62.6061876 },
        { lng:30.1262068	, lat:62.6068792 },
        { lng:30.1109323	, lat:62.6086626 },
        { lng:30.1039747	, lat:62.60634 },
        { lng:30.0928586	, lat:62.6036222 },
        { lng:30.0925019	, lat:62.6035351 },
        { lng:30.0923195	, lat:62.6034905 },
        { lng:30.0391083	, lat:62.590645 },
        { lng:30.0404835	, lat:62.5901306 },
        { lng:30.0409877	, lat:62.5896466 },
        { lng:30.0416314	, lat:62.5891626 },
        { lng:30.0444424	, lat:62.5884958 },
        { lng:30.0461698	, lat:62.5886341 },
        { lng:30.0462878	, lat:62.5889551 },
        { lng:30.0486696	, lat:62.5891626 },
        { lng:30.0489914	, lat:62.5874437 },
        { lng:30.0460732	, lat:62.5875178 },
        { lng:30.042994	, lat:62.5866929 },
        { lng:30.0408912	, lat:62.5863471 },
        { lng:30.039078	, lat:62.5861939 },
        { lng:30.0392067	, lat:62.58652 },
        { lng:30.0405264	, lat:62.5874239 },
        { lng:30.0417173	, lat:62.5881599 },
        { lng:30.0422752	, lat:62.5878882 },
        { lng:30.0428867	, lat:62.5878981 },
        { lng:30.0431442	, lat:62.5880166 },
        { lng:30.0419962	, lat:62.588392 },
        { lng:30.0408268	, lat:62.589128 },
        { lng:30.0393355	, lat:62.590022 },
        { lng:30.0385737	, lat:62.5902837 },
        { lng:30.0337136	, lat:62.5869546 },
        { lng:30.031203	, lat:62.5873646 },
        { lng:30.0313103	, lat:62.5877746 },
        { lng:30.0316536	, lat:62.5878388 },
        { lng:30.0317073	, lat:62.587987 },
        { lng:30.0314927	, lat:62.588066 },
        { lng:30.0317609	, lat:62.5886143 },
        { lng:30.0322652	, lat:62.5890934 },
        { lng:30.0321686	, lat:62.5898787 },
        { lng:30.0323081	, lat:62.5902541 },
        { lng:30.0331879	, lat:62.5905603 },
        { lng:30.0342286	, lat:62.5907628 },
        { lng:30.0337994	, lat:62.5918049 },
        { lng:30.030688	, lat:62.5908863 },
        { lng:30.0276625	, lat:62.5893058 },
        { lng:30.0265359	, lat:62.5875721 },
        { lng:29.9993545	, lat:62.5807524 },
        { lng:30.0043273	, lat:62.5768268 },
        { lng:29.9985337	, lat:62.5753839 },
        { lng:29.9960982	, lat:62.5769454 },
        { lng:29.9609157	, lat:62.5678879 },
        { lng:29.9569421	, lat:62.5696671 },
        { lng:29.9433043	, lat:62.5663158 },
        { lng:29.9431346	, lat:62.5662741 },
        { lng:29.944396	, lat:62.5683612 },
        { lng:29.9399113	, lat:62.5717715 },
        { lng:29.9411773	, lat:62.5752801 },
        { lng:29.9330234	, lat:62.5751714 },
        { lng:29.9311091	, lat:62.5772392 },
        { lng:29.9170058	, lat:62.5753933 },
        { lng:29.9117803	, lat:62.5738767 },
        { lng:29.9105251	, lat:62.5731107 },
        { lng:29.9119627	, lat:62.571895 },
        { lng:29.9117911	, lat:62.5717813 },
        { lng:29.9108148	, lat:62.5719593 },
        { lng:29.9101146	, lat:62.5722932 },
        { lng:29.9089908	, lat:62.5732639 },
        { lng:29.9077999	, lat:62.5738767 },
        { lng:29.906137	, lat:62.5743314 },
        { lng:29.9052679	, lat:62.5747366 },
        { lng:29.9050105	, lat:62.5751714 },
        { lng:29.9056542	, lat:62.5760115 },
        { lng:29.9054825	, lat:62.5765797 },
        { lng:29.9032402	, lat:62.5785216 },
        { lng:29.9024093	, lat:62.5788905 },
        { lng:29.9050748	, lat:62.579327 },
        { lng:29.9049568	, lat:62.5800631 },
        { lng:29.9032264	, lat:62.5813651 },
        { lng:29.9097311	, lat:62.585122 },
        { lng:29.9136042	, lat:62.5870436 },
        { lng:29.9255098	, lat:62.5929144 },
        { lng:29.922141	, lat:62.6003985 },
        { lng:29.9277998	, lat:62.6011102 },
        { lng:29.9254068	, lat:62.6046012 },
        { lng:29.9134251	, lat:62.6161442 },
        { lng:29.9085398	, lat:62.6182077 },
        { lng:29.9095804	, lat:62.6188161 },
        { lng:29.9100917	, lat:62.619115 },
        { lng:29.9101485	, lat:62.6191482 },
        { lng:29.903444	, lat:62.6255373 },
        { lng:29.9006446	, lat:62.6281479 },
        { lng:29.8893678	, lat:62.6285042 },
        { lng:29.8607178	, lat:62.6291118 },
        { lng:29.8207569	, lat:62.6299867 },
        { lng:29.7971268	, lat:62.6304394 },
        { lng:29.7974217	, lat:62.6292172 },
        { lng:29.7945839	, lat:62.6293874 },
        { lng:29.7943103	, lat:62.6297845 },
        { lng:29.7922826	, lat:62.6304898 },
        { lng:29.7909951	, lat:62.6301692 },
        { lng:29.7882056	, lat:62.6305288 },
        { lng:29.7484943	, lat:62.6319516 },
        { lng:29.7448516	, lat:62.6314579 },
        { lng:29.7421771	, lat:62.6327508 },
        { lng:29.739506	, lat:62.6330175 },
        { lng:29.7388456	, lat:62.6330833 },
        { lng:29.7074128	, lat:62.6367655 },
        { lng:29.7064297	, lat:62.6350417 },
        { lng:29.7032364	, lat:62.6354139 },
        { lng:29.7018622	, lat:62.6358052 },
        { lng:29.7018704	, lat:62.6357522 },
        { lng:29.7002805	, lat:62.6362076 },
        { lng:29.7003673	, lat:62.6362668 },
        { lng:29.6944617	, lat:62.6379609 },
        { lng:29.6917338	, lat:62.6387458 },
        { lng:29.691454	, lat:62.6387303 },
        { lng:29.6910759	, lat:62.6386317 },
        { lng:29.6886547	, lat:62.6388749 },
        { lng:29.6864312	, lat:62.6391335 },
        { lng:29.6850129	, lat:62.6393246 },
        { lng:29.6837637	, lat:62.6395101 },
        { lng:29.6724371	, lat:62.6408451 },
        { lng:29.6724685	, lat:62.6411538 },
        { lng:29.6692532	, lat:62.6413498 },
        { lng:29.6692251	, lat:62.6412243 },
        { lng:29.6660264	, lat:62.6415819 },
        { lng:29.6646786	, lat:62.6421243 },
        { lng:29.6619323	, lat:62.6431957 },
        { lng:29.6594166	, lat:62.6441525 },
        { lng:29.6495628	, lat:62.6480191 },
        { lng:29.643518	, lat:62.6550766 },
        { lng:29.6431546	, lat:62.6555867 },
        { lng:29.6416507	, lat:62.6553447 },
        { lng:29.6372066	, lat:62.6545282 },
      ],
    },
    {
      id: 7,
      regionName: 'eCom TP  Jyväskylä kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:25.2852095	, lat:62.1523536 },
        { lng:25.3182337	, lat:62.1745506 },
        { lng:25.3303358	, lat:62.1821618 },
        { lng:25.3457854	, lat:62.1972984 },
        { lng:25.3471587	, lat:62.1976988 },
        { lng:25.335314	, lat:62.2329876 },
        { lng:25.3847525	, lat:62.243822 },
        { lng:25.4234076	, lat:62.2448889 },
        { lng:25.4132259	, lat:62.2664163 },
        { lng:25.4555711	, lat:62.3068046 },
        { lng:25.4581921	, lat:62.3094177 },
        { lng:25.4635147	, lat:62.315541 },
        { lng:25.4852536	, lat:62.342457 },
        { lng:25.4857212	, lat:62.3430481 },
        { lng:25.4886441	, lat:62.3462975 },
        { lng:25.4445089	, lat:62.3451776 },
        { lng:25.4425376	, lat:62.357138 },
        { lng:25.37908	, lat:62.3720703 },
        { lng:25.3747399	, lat:62.3741507 },
        { lng:25.3695949	, lat:62.3774662 },
        { lng:25.3024519	, lat:62.365395 },
        { lng:25.2854161	, lat:62.3891839 },
        { lng:25.312289	, lat:62.3922966 },
        { lng:25.3245586	, lat:62.3770553 },
        { lng:25.3411024	, lat:62.3796637 },
        { lng:25.3666118	, lat:62.3838351 },
        { lng:25.3614412	, lat:62.3956417 },
        { lng:25.3859244	, lat:62.3978794 },
        { lng:25.3866649	, lat:62.3968008 },
        { lng:25.4051159	, lat:62.39841 },
        { lng:25.4078279	, lat:62.3900775 },
        { lng:25.4394925	, lat:62.3924041 },
        { lng:25.4395923	, lat:62.3924243 },
        { lng:25.4482032	, lat:62.3941702 },
        { lng:25.4898572	, lat:62.3976047 },
        { lng:25.4935937	, lat:62.3878008 },
        { lng:25.4936132	, lat:62.3877498 },
        { lng:25.493715	, lat:62.3874826 },
        { lng:25.4939529	, lat:62.3868582 },
        { lng:25.4982039	, lat:62.3871924 },
        { lng:25.4983637	, lat:62.3869051 },
        { lng:25.4982798	, lat:62.3863376 },
        { lng:25.4983888	, lat:62.3860849 },
        { lng:25.4987662	, lat:62.3854902 },
        { lng:25.4987494	, lat:62.3851132 },
        { lng:25.4990062	, lat:62.384758 },
        { lng:25.4957362	, lat:62.383591 },
        { lng:25.5050741	, lat:62.3781047 },
        { lng:25.5059015	, lat:62.3785868 },
        { lng:25.5280349	, lat:62.3914782 },
        { lng:25.5450855	, lat:62.4014054 },
        { lng:25.5482535	, lat:62.4032494 },
        { lng:25.5497742	, lat:62.403018 },
        { lng:25.5561991	, lat:62.4086507 },
        { lng:25.5607798	, lat:62.4126659 },
        { lng:25.5609164	, lat:62.4127856 },
        { lng:25.5614927	, lat:62.4132908 },
        { lng:25.5622687	, lat:62.4139709 },
        { lng:25.5623305	, lat:62.414025 },
        { lng:25.5634454	, lat:62.4150022 },
        { lng:25.5638071	, lat:62.4153191 },
        { lng:25.5643481	, lat:62.4157933 },
        { lng:25.5643871	, lat:62.4158275 },
        { lng:25.579132	, lat:62.4287469 },
        { lng:25.5880317	, lat:62.4282629 },
        { lng:25.5908544	, lat:62.4280715 },
        { lng:25.5925297	, lat:62.4279997 },
        { lng:25.5975718	, lat:62.4277156 },
        { lng:25.6170291	, lat:62.426619 },
        { lng:25.6300347	, lat:62.4217811 },
        { lng:25.6463331	, lat:62.4203821 },
        { lng:25.6479625	, lat:62.4192715 },
        { lng:25.6487334	, lat:62.4191947 },
        { lng:25.6490298	, lat:62.4185742 },
        { lng:25.6556934	, lat:62.4141795 },
        { lng:25.6583332	, lat:62.4124384 },
        { lng:25.6583118	, lat:62.4123367 },
        { lng:25.6673447	, lat:62.4113155 },
        { lng:25.6672557	, lat:62.4104909 },
        { lng:25.66809	, lat:62.4105314 },
        { lng:25.6687211	, lat:62.4102137 },
        { lng:25.6715236	, lat:62.410669 },
        { lng:25.6755103	, lat:62.4102013 },
        { lng:25.6758761	, lat:62.4100537 },
        { lng:25.6826289	, lat:62.4073406 },
        { lng:25.6832979	, lat:62.4070702 },
        { lng:25.6853422	, lat:62.4062438 },
        { lng:25.6856176	, lat:62.4059719 },
        { lng:25.6863157	, lat:62.4054118 },
        { lng:25.6863604	, lat:62.4052961 },
        { lng:25.6866854	, lat:62.4028976 },
        { lng:25.6869665	, lat:62.4031393 },
        { lng:25.6873875	, lat:62.4037134 },
        { lng:25.6878356	, lat:62.4039483 },
        { lng:25.6955636	, lat:62.4010879 },
        { lng:25.6958628	, lat:62.3974737 },
        { lng:25.6972152	, lat:62.3969744 },
        { lng:25.7040834	, lat:62.3944716 },
        { lng:25.7048175	, lat:62.3941881 },
        { lng:25.7281598	, lat:62.3855352 },
        { lng:25.7295637	, lat:62.3863124 },
        { lng:25.7335463	, lat:62.3847025 },
        { lng:25.7334773	, lat:62.3846662 },
        { lng:25.7331364	, lat:62.3846753 },
        { lng:25.7325972	, lat:62.384521 },
        { lng:25.7323673	, lat:62.3844511 },
        { lng:25.7319946	, lat:62.3844217 },
        { lng:25.7315697	, lat:62.3842108 },
        { lng:25.7440871	, lat:62.3796096 },
        { lng:25.7595627	, lat:62.3834078 },
        { lng:25.7862049	, lat:62.3899456 },
        { lng:25.8185328	, lat:62.3813299 },
        { lng:25.8423122	, lat:62.3684808 },
        { lng:25.8462486	, lat:62.3494647 },
        { lng:25.8433199	, lat:62.3468227 },
        { lng:25.8402531	, lat:62.344094 },
        { lng:25.8382952	, lat:62.3420513 },
        { lng:25.8334987	, lat:62.3381025 },
        { lng:25.8289339	, lat:62.3340534 },
        { lng:25.826504	, lat:62.3283684 },
        { lng:25.8256745	, lat:62.3264649 },
        { lng:25.8256435	, lat:62.3263927 },
        { lng:25.824388	, lat:62.323481 },
        { lng:25.8238632	, lat:62.3222528 },
        { lng:25.8233853	, lat:62.3211344 },
        { lng:25.8230068	, lat:62.3202527 },
        { lng:25.8227754	, lat:62.3197328 },
        { lng:25.8196396	, lat:62.31241 },
        { lng:25.8184286	, lat:62.3095888 },
        { lng:25.8183413	, lat:62.3093855 },
        { lng:25.8178063	, lat:62.3081393 },
        { lng:25.8172976	, lat:62.3069538 },
        { lng:25.8168117	, lat:62.3058218 },
        { lng:25.8167447	, lat:62.3056657 },
        { lng:25.8167178	, lat:62.3056027 },
        { lng:25.8556664	, lat:62.2958925 },
        { lng:25.8499796	, lat:62.2874752 },
        { lng:25.8524646	, lat:62.2861252 },
        { lng:25.8530075	, lat:62.286137 },
        { lng:25.8533635	, lat:62.2862092 },
        { lng:25.8536557	, lat:62.2863153 },
        { lng:25.8539462	, lat:62.2862949 },
        { lng:25.8540848	, lat:62.2864554 },
        { lng:25.854094	, lat:62.2865658 },
        { lng:25.8542035	, lat:62.2867272 },
        { lng:25.8544683	, lat:62.2867569 },
        { lng:25.8544866	, lat:62.28688 },
        { lng:25.8546235	, lat:62.2869777 },
        { lng:25.8552432	, lat:62.2876936 },
        { lng:25.8548314	, lat:62.2898334 },
        { lng:25.8553224	, lat:62.2898445 },
        { lng:25.8553406	, lat:62.2903115 },
        { lng:25.8554593	, lat:62.2906893 },
        { lng:25.8554045	, lat:62.2912157 },
        { lng:25.8554684	, lat:62.2913558 },
        { lng:25.8552676	, lat:62.2916063 },
        { lng:25.8552493	, lat:62.2919204 },
        { lng:25.8557953	, lat:62.2919729 },
        { lng:25.8567665	, lat:62.2882052 },
        { lng:25.8629101	, lat:62.2851162 },
        { lng:25.8682175	, lat:62.2916339 },
        { lng:25.8811286	, lat:62.292538 },
        { lng:25.878648	, lat:62.2726378 },
        { lng:25.8842957	, lat:62.2766897 },
        { lng:25.8927831	, lat:62.2827777 },
        { lng:25.8933466	, lat:62.2832275 },
        { lng:25.893718	, lat:62.2835079 },
        { lng:25.8942183	, lat:62.283919 },
        { lng:25.8933905	, lat:62.281038 },
        { lng:25.9010949	, lat:62.2807367 },
        { lng:25.9217474	, lat:62.2798049 },
        { lng:25.9259347	, lat:62.2712324 },
        { lng:25.9368723	, lat:62.271401 },
        { lng:25.9620505	, lat:62.2986245 },
        { lng:25.9964327	, lat:62.2795698 },
        { lng:26.0510008	, lat:62.2676018 },
        { lng:26.047917	, lat:62.2645179 },
        { lng:26.0445311	, lat:62.264352 },
        { lng:26.0452106	, lat:62.2610885 },
        { lng:26.0454587	, lat:62.2610888 },
        { lng:26.051348	, lat:62.2370739 },
        { lng:26.0501227	, lat:62.2365512 },
        { lng:26.0456981	, lat:62.2358165 },
        { lng:26.045179	, lat:62.2352336 },
        { lng:26.0421662	, lat:62.2243332 },
        { lng:26.0427434	, lat:62.2235569 },
        { lng:26.0393167	, lat:62.2233232 },
        { lng:26.0382964	, lat:62.2258839 },
        { lng:26.0379306	, lat:62.2268019 },
        { lng:26.0390957	, lat:62.2302613 },
        { lng:26.0349737	, lat:62.2331694 },
        { lng:26.0281179	, lat:62.2320795 },
        { lng:26.0233114	, lat:62.2265607 },
        { lng:26.0193975	, lat:62.2236289 },
        { lng:26.0403402	, lat:62.1914606 },
        { lng:26.0423534	, lat:62.1918129 },
        { lng:26.0465377	, lat:62.1837035 },
        { lng:26.0502279	, lat:62.1806728 },
        { lng:26.0353677	, lat:62.1858499 },
        { lng:26.0175407	, lat:62.2033823 },
        { lng:26.0132921	, lat:62.2023216 },
        { lng:26.0145624	, lat:62.2002641 },
        { lng:26.0115068	, lat:62.1947395 },
        { lng:26.004812	, lat:62.1922571 },
        { lng:26.011156	, lat:62.1859199 },
        { lng:26.0122812	, lat:62.1849827 },
        { lng:26.0145362	, lat:62.1851011 },
        { lng:26.0154416	, lat:62.1849989 },
        { lng:26.0163182	, lat:62.1845091 },
        { lng:26.0165777	, lat:62.1837771 },
        { lng:26.0164998	, lat:62.1828433 },
        { lng:26.021338	, lat:62.1807613 },
        { lng:26.0266817	, lat:62.1841037 },
        { lng:26.0348527	, lat:62.1840076 },
        { lng:26.0377367	, lat:62.1796697 },
        { lng:26.0485797	, lat:62.1796849 },
        { lng:26.0490518	, lat:62.1707308 },
        { lng:25.9777936	, lat:62.1696721 },
        { lng:25.9408244	, lat:62.1369879 },
        { lng:25.9426719	, lat:62.1282139 },
        { lng:25.9465699	, lat:62.1082387 },
        { lng:25.9608998	, lat:62.0746492 },
        { lng:25.9498903	, lat:62.0779909 },
        { lng:25.9127527	, lat:62.0899639 },
        { lng:25.8733328	, lat:62.1238022 },
        { lng:25.8724952	, lat:62.1234985 },
        { lng:25.8723507	, lat:62.1223488 },
        { lng:25.8707565	, lat:62.1222295 },
        { lng:25.8701194	, lat:62.123089 },
        { lng:25.8686424	, lat:62.1232489 },
        { lng:25.8689646	, lat:62.1238805 },
        { lng:25.870416	, lat:62.1238235 },
        { lng:25.871562	, lat:62.123856 },
        { lng:25.8724181	, lat:62.1240241 },
        { lng:25.8728662	, lat:62.1241782 },
        { lng:25.8629115	, lat:62.1327778 },
        { lng:25.8635356	, lat:62.1335385 },
        { lng:25.8635219	, lat:62.1341229 },
        { lng:25.8630595	, lat:62.1343161 },
        { lng:25.8620188	, lat:62.133388 },
        { lng:25.8523622	, lat:62.1348798 },
        { lng:25.8408952	, lat:62.1313774 },
        { lng:25.83602	, lat:62.1310364 },
        { lng:25.8310933	, lat:62.1308558 },
        { lng:25.8288555	, lat:62.1295723 },
        { lng:25.8203625	, lat:62.1274578 },
        { lng:25.8040246	, lat:62.1282403 },
        { lng:25.7957397	, lat:62.1144054 },
        { lng:25.7951216	, lat:62.0944486 },
        { lng:25.775392	, lat:62.0969635 },
        { lng:25.7698574	, lat:62.0977108 },
        { lng:25.7615516	, lat:62.0988105 },
        { lng:25.7618181	, lat:62.1004374 },
        { lng:25.7622371	, lat:62.1027288 },
        { lng:25.7565051	, lat:62.1036899 },
        { lng:25.7328144	, lat:62.107662 },
        { lng:25.7291998	, lat:62.1331715 },
        { lng:25.7108685	, lat:62.1392328 },
        { lng:25.7101198	, lat:62.1395179 },
        { lng:25.710323	, lat:62.1396265 },
        { lng:25.7111536	, lat:62.1393546 },
        { lng:25.7113667	, lat:62.1394529 },
        { lng:25.7111614	, lat:62.1395104 },
        { lng:25.7104733	, lat:62.1397031 },
        { lng:25.710861	, lat:62.1398992 },
        { lng:25.7047141	, lat:62.141952 },
        { lng:25.7066437	, lat:62.1428412 },
        { lng:25.7052571	, lat:62.1438259 },
        { lng:25.7052941	, lat:62.1438974 },
        { lng:25.7063869	, lat:62.1446548 },
        { lng:25.7099611	, lat:62.147277 },
        { lng:25.7124247	, lat:62.1465989 },
        { lng:25.7133642	, lat:62.147305 },
        { lng:25.7198756	, lat:62.1520338 },
        { lng:25.7242219	, lat:62.1511159 },
        { lng:25.7285783	, lat:62.1500816 },
        { lng:25.7416119	, lat:62.146687 },
        { lng:25.7419468	, lat:62.1465997 },
        { lng:25.7447695	, lat:62.1458645 },
        { lng:25.7472837	, lat:62.1451335 },
        { lng:25.7482282	, lat:62.1448632 },
        { lng:25.750228	, lat:62.1442869 },
        { lng:25.7634588	, lat:62.151985 },
        { lng:25.7732239	, lat:62.152117 },
        { lng:25.7939842	, lat:62.1523976 },
        { lng:25.7674813	, lat:62.1753539 },
        { lng:25.7621492	, lat:62.1798424 },
        { lng:25.7615827	, lat:62.1800617 },
        { lng:25.7560087	, lat:62.1806899 },
        { lng:25.7476382	, lat:62.1816176 },
        { lng:25.7475276	, lat:62.1816303 },
        { lng:25.7445603	, lat:62.1777152 },
        { lng:25.7423335	, lat:62.174742 },
        { lng:25.7417339	, lat:62.1739511 },
        { lng:25.7391338	, lat:62.1712094 },
        { lng:25.736981	, lat:62.1689389 },
        { lng:25.7354412	, lat:62.169169 },
        { lng:25.7209743	, lat:62.1714073 },
        { lng:25.7129773	, lat:62.1726741 },
        { lng:25.7107924	, lat:62.1730202 },
        { lng:25.7094934	, lat:62.1732172 },
        { lng:25.7059694	, lat:62.1737513 },
        { lng:25.7047583	, lat:62.173935 },
        { lng:25.6994703	, lat:62.1747366 },
        { lng:25.692751	, lat:62.175777 },
        { lng:25.6889439	, lat:62.1783999 },
        { lng:25.6837483	, lat:62.1819793 },
        { lng:25.6836342	, lat:62.1820544 },
        { lng:25.6834337	, lat:62.1821961 },
        { lng:25.6752842	, lat:62.1879827 },
        { lng:25.669787	, lat:62.191809 },
        { lng:25.6664809	, lat:62.201877 },
        { lng:25.666044	, lat:62.203067 },
        { lng:25.6574339	, lat:62.200856 },
        { lng:25.6122369	, lat:62.189251 },
        { lng:25.610398	, lat:62.1887763 },
        { lng:25.600407	, lat:62.186197 },
        { lng:25.5997388	, lat:62.1863335 },
        { lng:25.590398	, lat:62.188241 },
        { lng:25.5895471	, lat:62.1900933 },
        { lng:25.584998	, lat:62.200189 },
        { lng:25.5854658	, lat:62.212036 },
        { lng:25.5857249	, lat:62.21835 },
        { lng:25.5857558	, lat:62.2188778 },
        { lng:25.5857665	, lat:62.2190593 },
        { lng:25.5857789	, lat:62.21927 },
        { lng:25.5858011	, lat:62.2196494 },
        { lng:25.5858234	, lat:62.2200299 },
        { lng:25.5858454	, lat:62.2204049 },
        { lng:25.5858729	, lat:62.2208745 },
        { lng:25.5858896	, lat:62.2211595 },
        { lng:25.5859169	, lat:62.2216248 },
        { lng:25.5859363	, lat:62.2219555 },
        { lng:25.5859612	, lat:62.2223798 },
        { lng:25.5859826	, lat:62.2227453 },
        { lng:25.5860163	, lat:62.2233203 },
        { lng:25.586024	, lat:62.223452 },
        { lng:25.5796359	, lat:62.231674 },
        { lng:25.5789276	, lat:62.2326468 },
        { lng:25.5768537	, lat:62.2352804 },
        { lng:25.573962	, lat:62.2390647 },
        { lng:25.5734857	, lat:62.239688 },
        { lng:25.570777	, lat:62.242832 },
        { lng:25.5692888	, lat:62.2424047 },
        { lng:25.5465643	, lat:62.2358786 },
        { lng:25.5375272	, lat:62.233184 },
        { lng:25.5368047	, lat:62.2329686 },
        { lng:25.5338961	, lat:62.2321013 },
        { lng:25.5325485	, lat:62.2316995 },
        { lng:25.5309475	, lat:62.2312221 },
        { lng:25.5304051	, lat:62.2310603 },
        { lng:25.529193	, lat:62.230699 },
        { lng:25.5171129	, lat:62.23102 },
        { lng:25.5053649	, lat:62.231217 },
        { lng:25.503517	, lat:62.231242 },
        { lng:25.499585	, lat:62.230598 },
        { lng:25.499251	, lat:62.230352 },
        { lng:25.4990846	, lat:62.2304536 },
        { lng:25.4988175	, lat:62.2305161 },
        { lng:25.4985447	, lat:62.2305283 },
        { lng:25.4982837	, lat:62.2305444 },
        { lng:25.4980423	, lat:62.2306019 },
        { lng:25.4978219	, lat:62.230734 },
        { lng:25.4977363	, lat:62.2308837 },
        { lng:25.4977717	, lat:62.2310409 },
        { lng:25.4977816	, lat:62.2311382 },
        { lng:25.4977301	, lat:62.2312217 },
        { lng:25.4973372	, lat:62.2314705 },
        { lng:25.4971392	, lat:62.2316105 },
        { lng:25.4968099	, lat:62.2316926 },
        { lng:25.4961635	, lat:62.2317935 },
        { lng:25.4957842	, lat:62.2319137 },
        { lng:25.4955833	, lat:62.2320178 },
        { lng:25.4954055	, lat:62.2321654 },
        { lng:25.4953116	, lat:62.2322761 },
        { lng:25.4951254	, lat:62.2323727 },
        { lng:25.4948851	, lat:62.2324455 },
        { lng:25.4946941	, lat:62.2324722 },
        { lng:25.4945525	, lat:62.2325139 },
        { lng:25.4944753	, lat:62.2326231 },
        { lng:25.4944796	, lat:62.232775 },
        { lng:25.4944077	, lat:62.2328764 },
        { lng:25.4941654	, lat:62.2329739 },
        { lng:25.4938839	, lat:62.233032 },
        { lng:25.4934608	, lat:62.2325555 },
        { lng:25.4926111	, lat:62.2314656 },
        { lng:25.4920245	, lat:62.230779 },
        { lng:25.4919736	, lat:62.2307195 },
        { lng:25.4914327	, lat:62.2301161 },
        { lng:25.4911826	, lat:62.2299363 },
        { lng:25.4909313	, lat:62.2297592 },
        { lng:25.4906174	, lat:62.2295239 },
        { lng:25.4902037	, lat:62.2289335 },
        { lng:25.4899474	, lat:62.2285651 },
        { lng:25.4897992	, lat:62.2282462 },
        { lng:25.4897137	, lat:62.2280472 },
        { lng:25.4896005	, lat:62.2276492 },
        { lng:25.4894863	, lat:62.227464 },
        { lng:25.4893642	, lat:62.2272795 },
        { lng:25.4891769	, lat:62.2270922 },
        { lng:25.4887031	, lat:62.2266816 },
        { lng:25.4874399	, lat:62.22568 },
        { lng:25.48763	, lat:62.225658 },
        { lng:25.4883808	, lat:62.2255712 },
        { lng:25.4895679	, lat:62.225434 },
        { lng:25.4895569	, lat:62.2252729 },
        { lng:25.4893008	, lat:62.2250247 },
        { lng:25.4891349	, lat:62.2248639 },
        { lng:25.4884469	, lat:62.2236179 },
        { lng:25.488687	, lat:62.223055 },
        { lng:25.477678	, lat:62.210176 },
        { lng:25.473955	, lat:62.208896 },
        { lng:25.471763	, lat:62.205461 },
        { lng:25.4730319	, lat:62.20508 },
        { lng:25.470566	, lat:62.203284 },
        { lng:25.473575	, lat:62.201952 },
        { lng:25.473627	, lat:62.200157 },
        { lng:25.476128	, lat:62.198973 },
        { lng:25.4754676	, lat:62.1979104 },
        { lng:25.4755574	, lat:62.1978872 },
        { lng:25.4755467	, lat:62.1977733 },
        { lng:25.47547	, lat:62.197641 },
        { lng:25.475298	, lat:62.1975155 },
        { lng:25.4751154	, lat:62.1974054 },
        { lng:25.4749738	, lat:62.1973043 },
        { lng:25.4749067	, lat:62.1971693 },
        { lng:25.4749469	, lat:62.1970167 },
        { lng:25.474985	, lat:62.1968997 },
        { lng:25.4750749	, lat:62.1967839 },
        { lng:25.4752216	, lat:62.1966625 },
        { lng:25.4753815	, lat:62.1965692 },
        { lng:25.4757554	, lat:62.1964551 },
        { lng:25.4758044	, lat:62.1964351 },
        { lng:25.4759404	, lat:62.1963822 },
        { lng:25.4760021	, lat:62.1963484 },
        { lng:25.4764291	, lat:62.1961472 },
        { lng:25.4769216	, lat:62.1958074 },
        { lng:25.4771952	, lat:62.1956158 },
        { lng:25.4775887	, lat:62.1953298 },
        { lng:25.4777364	, lat:62.1951502 },
        { lng:25.4779451	, lat:62.1950044 },
        { lng:25.478083	, lat:62.1948301 },
        { lng:25.4781433	, lat:62.1947255 },
        { lng:25.4782809	, lat:62.1945669 },
        { lng:25.4783445	, lat:62.1945202 },
        { lng:25.4783566	, lat:62.1944404 },
        { lng:25.4783145	, lat:62.194401 },
        { lng:25.4782748	, lat:62.1943049 },
        { lng:25.4783308	, lat:62.1941555 },
        { lng:25.478297	, lat:62.1940337 },
        { lng:25.4782195	, lat:62.1938686 },
        { lng:25.4781345	, lat:62.1937472 },
        { lng:25.4781972	, lat:62.1936367 },
        { lng:25.4783041	, lat:62.1935475 },
        { lng:25.4784164	, lat:62.1934591 },
        { lng:25.4785256	, lat:62.1933333 },
        { lng:25.478832	, lat:62.193355 },
        { lng:25.4788989	, lat:62.1932581 },
        { lng:25.479227	, lat:62.193144 },
        { lng:25.4794979	, lat:62.193102 },
        { lng:25.4798879	, lat:62.192998 },
        { lng:25.4801739	, lat:62.193047 },
        { lng:25.481902	, lat:62.189241 },
        { lng:25.479004	, lat:62.189927 },
        { lng:25.4786197	, lat:62.1896939 },
        { lng:25.4783579	, lat:62.1895033 },
        { lng:25.4781541	, lat:62.1893432 },
        { lng:25.4779341	, lat:62.1891229 },
        { lng:25.4778268	, lat:62.1889427 },
        { lng:25.4777786	, lat:62.1888251 },
        { lng:25.4777839	, lat:62.1886899 },
        { lng:25.4778	, lat:62.1885823 },
        { lng:25.477859	, lat:62.1884897 },
        { lng:25.4779663	, lat:62.1884271 },
        { lng:25.4780736	, lat:62.1883746 },
        { lng:25.4781487	, lat:62.1882945 },
        { lng:25.4783311	, lat:62.1880867 },
        { lng:25.4785618	, lat:62.187884 },
        { lng:25.4788085	, lat:62.1876512 },
        { lng:25.4789909	, lat:62.1874735 },
        { lng:25.4791358	, lat:62.1872708 },
        { lng:25.4792109	, lat:62.1870906 },
        { lng:25.479254	, lat:62.1869905 },
        { lng:25.4793682	, lat:62.1867077 },
        { lng:25.479379	, lat:62.1866809 },
        { lng:25.479358	, lat:62.184428 },
        { lng:25.493005	, lat:62.17734 },
        { lng:25.501117	, lat:62.17645 },
        { lng:25.501461	, lat:62.166012 },
        { lng:25.509579	, lat:62.15828 },
        { lng:25.526231	, lat:62.157686 },
        { lng:25.541823	, lat:62.15996 },
        { lng:25.536721	, lat:62.140792 },
        { lng:25.5369571	, lat:62.1408225 },
        { lng:25.5371792	, lat:62.1407595 },
        { lng:25.5373055	, lat:62.140658 },
        { lng:25.5373943	, lat:62.1405406 },
        { lng:25.5372701	, lat:62.1403725 },
        { lng:25.5369652	, lat:62.1402108 },
        { lng:25.5368287	, lat:62.1401144 },
        { lng:25.536837	, lat:62.1399308 },
        { lng:25.5368321	, lat:62.1394545 },
        { lng:25.5368223	, lat:62.1392968 },
        { lng:25.5368372	, lat:62.1391518 },
        { lng:25.5367426	, lat:62.1390826 },
        { lng:25.5367436	, lat:62.1389268 },
        { lng:25.536523	, lat:62.1388132 },
        { lng:25.5362984	, lat:62.1387896 },
        { lng:25.5363198	, lat:62.1387301 },
        { lng:25.5361715	, lat:62.1386886 },
        { lng:25.5359328	, lat:62.1387013 },
        { lng:25.5357705	, lat:62.138789 },
        { lng:25.5354806	, lat:62.1388939 },
        { lng:25.5352366	, lat:62.1389027 },
        { lng:25.5351257	, lat:62.1389906 },
        { lng:25.5351587	, lat:62.1390328 },
        { lng:25.5354226	, lat:62.1390334 },
        { lng:25.5356552	, lat:62.139106 },
        { lng:25.5356994	, lat:62.1392552 },
        { lng:25.5356557	, lat:62.139429 },
        { lng:25.5355396	, lat:62.1395377 },
        { lng:25.5353194	, lat:62.1396209 },
        { lng:25.5349728	, lat:62.1396556 },
        { lng:25.5347444	, lat:62.1397338 },
        { lng:25.5344415	, lat:62.1398108 },
        { lng:25.5342645	, lat:62.139924 },
        { lng:25.534228	, lat:62.1400527 },
        { lng:25.5341894	, lat:62.1401677 },
        { lng:25.5340182	, lat:62.1402646 },
        { lng:25.5339713	, lat:62.1403721 },
        { lng:25.5339047	, lat:62.140546 },
        { lng:25.53387	, lat:62.140637 },
        { lng:25.526681	, lat:62.139357 },
        { lng:25.520456	, lat:62.127745 },
        { lng:25.508571	, lat:62.121414 },
        { lng:25.50615	, lat:62.121136 },
        { lng:25.497286	, lat:62.1092609 },
        { lng:25.480964	, lat:62.0876779 },
        { lng:25.4797015	, lat:62.0861091 },
        { lng:25.5071039	, lat:62.084982 },
        { lng:25.5252779	, lat:62.0842156 },
        { lng:25.5336419	, lat:62.0838479 },
        { lng:25.5315266	, lat:62.0736452 },
        { lng:25.531347	, lat:62.0726989 },
        { lng:25.5326706	, lat:62.0725491 },
        { lng:25.53949	, lat:62.0717349 },
        { lng:25.5710745	, lat:62.0702847 },
        { lng:25.5752782	, lat:62.0700916 },
        { lng:25.580804	, lat:62.0699029 },
        { lng:25.582744	, lat:62.0697699 },
        { lng:25.59093	, lat:62.0703029 },
        { lng:25.6043106	, lat:62.071153 },
        { lng:25.604517	, lat:62.0711679 },
        { lng:25.608201	, lat:62.0714029 },
        { lng:25.6212052	, lat:62.069 },
        { lng:25.63408	, lat:62.0666699 },
        { lng:25.633576	, lat:62.0689539 },
        { lng:25.647054	, lat:62.0714297 },
        { lng:25.664312	, lat:62.0746139 },
        { lng:25.664535	, lat:62.0747779 },
        { lng:25.664696	, lat:62.0750219 },
        { lng:25.665166	, lat:62.0752329 },
        { lng:25.666647	, lat:62.0755069 },
        { lng:25.6672559	, lat:62.075576 },
        { lng:25.668405	, lat:62.0755779 },
        { lng:25.668616	, lat:62.0755619 },
        { lng:25.669041	, lat:62.0754759 },
        { lng:25.669824	, lat:62.0755199 },
        { lng:25.670589	, lat:62.0755359 },
        { lng:25.671319	, lat:62.0754799 },
        { lng:25.6716883	, lat:62.0754799 },
        { lng:25.672691	, lat:62.0756189 },
        { lng:25.673379	, lat:62.0756349 },
        { lng:25.6927479	, lat:62.075712 },
        { lng:25.7679729	, lat:62.063555 },
        { lng:25.7703549	, lat:62.049114 },
        { lng:25.8359419	, lat:62.044466 },
        { lng:25.9514129	, lat:61.997469 },
        { lng:25.9624898	, lat:61.9855602 },
        { lng:25.9627505	, lat:61.9854644 },
        { lng:25.9629291	, lat:61.9853066 },
        { lng:25.9630423	, lat:61.9851713 },
        { lng:25.9630468	, lat:61.9850343 },
        { lng:25.9629986	, lat:61.9849412 },
        { lng:25.9628658	, lat:61.9848173 },
        { lng:25.9627931	, lat:61.9847354 },
        { lng:25.9627615	, lat:61.984661 },
        { lng:25.9627883	, lat:61.9845524 },
        { lng:25.9629055	, lat:61.9843783 },
        { lng:25.9630042	, lat:61.9842365 },
        { lng:25.9631407	, lat:61.9841047 },
        { lng:25.9632724	, lat:61.9840124 },
        { lng:25.963439	, lat:61.9839192 },
        { lng:25.9636857	, lat:61.9837956 },
        { lng:25.9638217	, lat:61.9837193 },
        { lng:25.9638542	, lat:61.9836439 },
        { lng:25.9638112	, lat:61.9835864 },
        { lng:25.9636045	, lat:61.9835091 },
        { lng:25.9635136	, lat:61.9834584 },
        { lng:25.9634839	, lat:61.9833883 },
        { lng:25.9635739	, lat:61.9833114 },
        { lng:25.9637174	, lat:61.9831841 },
        { lng:25.9638054	, lat:61.9831625 },
        { lng:25.9640006	, lat:61.9832113 },
        { lng:25.9641482	, lat:61.983249 },
        { lng:25.9642825	, lat:61.983242 },
        { lng:25.9644979	, lat:61.9831689 },
        { lng:25.964716	, lat:61.982916 },
        { lng:25.9647417	, lat:61.9828167 },
        { lng:25.9648332	, lat:61.9827437 },
        { lng:25.9648603	, lat:61.9826629 },
        { lng:25.9649201	, lat:61.9825505 },
        { lng:25.9650239	, lat:61.9824546 },
        { lng:25.9649944	, lat:61.9819144 },
        { lng:25.9647522	, lat:61.9819229 },
        { lng:25.9644732	, lat:61.9818555 },
        { lng:25.9643306	, lat:61.9818126 },
        { lng:25.9641777	, lat:61.9817509 },
        { lng:25.9640945	, lat:61.9816908 },
        { lng:25.9638926	, lat:61.9815217 },
        { lng:25.963868	, lat:61.9814241 },
        { lng:25.9637999	, lat:61.9813243 },
        { lng:25.9636537	, lat:61.9811842 },
        { lng:25.9636402	, lat:61.9811083 },
        { lng:25.9637082	, lat:61.9810164 },
        { lng:25.9639019	, lat:61.9809459 },
        { lng:25.9640334	, lat:61.9808537 },
        { lng:25.964201	, lat:61.9807712 },
        { lng:25.964128	, lat:61.9807206 },
        { lng:25.9640194	, lat:61.980672 },
        { lng:25.9641114	, lat:61.9805843 },
        { lng:25.9641342	, lat:61.9804766 },
        { lng:25.9641462	, lat:61.9803649 },
        { lng:25.9641967	, lat:61.9802723 },
        { lng:25.9642597	, lat:61.9802014 },
        { lng:25.9652401	, lat:61.979817 },
        { lng:25.9667267	, lat:61.9796968 },
        { lng:25.9670612	, lat:61.9798968 },
        { lng:25.96808	, lat:61.9796607 },
        { lng:25.9681041	, lat:61.9794747 },
        { lng:25.9688945	, lat:61.9791367 },
        { lng:25.9695508	, lat:61.9791975 },
        { lng:25.9700322	, lat:61.979084 },
        { lng:25.9704285	, lat:61.9788904 },
        { lng:25.9711444	, lat:61.9788313 },
        { lng:25.9718604	, lat:61.9785482 },
        { lng:25.9721468	, lat:61.9783595 },
        { lng:25.9723887	, lat:61.9783345 },
        { lng:25.9727605	, lat:61.978384 },
        { lng:25.973077	, lat:61.978384 },
        { lng:25.9733385	, lat:61.9783066 },
        { lng:25.9734741	, lat:61.978171 },
        { lng:25.9735268	, lat:61.9781168 },
        { lng:25.9736638	, lat:61.9780405 },
        { lng:25.9736966	, lat:61.9779589 },
        { lng:25.9738693	, lat:61.9779263 },
        { lng:25.9740292	, lat:61.9779098 },
        { lng:25.9741594	, lat:61.977871 },
        { lng:25.9743145	, lat:61.9778431 },
        { lng:25.9744272	, lat:61.9776807 },
        { lng:25.9745296	, lat:61.9775706 },
        { lng:25.9745476	, lat:61.9774616 },
        { lng:25.9745168	, lat:61.977343 },
        { lng:25.9745175	, lat:61.9772272 },
        { lng:25.9746553	, lat:61.9770543 },
        { lng:25.9749285	, lat:61.9768705 },
        { lng:25.9751129	, lat:61.9768003 },
        { lng:25.9753729	, lat:61.9767178 },
        { lng:25.9756739	, lat:61.976699 },
        { lng:25.9761156	, lat:61.9767203 },
        { lng:25.9763892	, lat:61.9767191 },
        { lng:25.9766609	, lat:61.9766099 },
        { lng:25.9768417	, lat:61.9764622 },
        { lng:25.9768989	, lat:61.9763285 },
        { lng:25.9770719	, lat:61.9761462 },
        { lng:25.9771872	, lat:61.9760145 },
        { lng:25.9773943	, lat:61.9759089 },
        { lng:25.978075	, lat:61.9756553 },
        { lng:25.9784373	, lat:61.9755074 },
        { lng:25.978557	, lat:61.975391 },
        { lng:25.978758	, lat:61.975078 },
        { lng:25.978867	, lat:61.974666 },
        { lng:25.979067	, lat:61.974398 },
        { lng:25.97909	, lat:61.974273 },
        { lng:25.9789459	, lat:61.9740019 },
        { lng:25.9826959	, lat:61.9702599 },
        { lng:25.9848669	, lat:61.9657689 },
        { lng:25.9304189	, lat:61.9356869 },
        { lng:25.929228	, lat:61.935408 },
        { lng:25.8888269	, lat:61.907284 },
        { lng:25.8501916	, lat:61.8906326 },
        { lng:25.7939469	, lat:61.8923189 },
        { lng:25.778296	, lat:61.876371 },
        { lng:25.7667208	, lat:61.8647654 },
        { lng:25.7629663	, lat:61.8608982 },
        { lng:25.7516511	, lat:61.8495334 },
        { lng:25.6981729	, lat:61.8552011 },
        { lng:25.6684906	, lat:61.8583401 },
        { lng:25.6626291	, lat:61.8589422 },
        { lng:25.660457	, lat:61.859033 },
        { lng:25.6221119	, lat:61.859193 },
        { lng:25.618812	, lat:61.857759 },
        { lng:25.6063319	, lat:61.854257 },
        { lng:25.5962252	, lat:61.8392056 },
        { lng:25.4465858	, lat:61.9169329 },
        { lng:25.4426022	, lat:61.9314477 },
        { lng:25.4294976	, lat:61.9285359 },
        { lng:25.4247323	, lat:61.9310804 },
        { lng:25.4121318	, lat:61.9302593 },
        { lng:25.4083607	, lat:61.9297453 },
        { lng:25.4077946	, lat:61.9308085 },
        { lng:25.4069455	, lat:61.9331774 },
        { lng:25.4089796	, lat:61.9340125 },
        { lng:25.4093213	, lat:61.9340771 },
        { lng:25.4112718	, lat:61.9347611 },
        { lng:25.4119154	, lat:61.9350229 },
        { lng:25.4122885	, lat:61.9351745 },
        { lng:25.4111872	, lat:61.9363806 },
        { lng:25.4064295	, lat:61.9363806 },
        { lng:25.3661642	, lat:61.9557164 },
        { lng:25.3512173	, lat:61.9462775 },
        { lng:25.3356787	, lat:61.9507817 },
        { lng:25.3408919	, lat:61.963429 },
        { lng:25.3335973	, lat:61.9642137 },
        { lng:25.3375268	, lat:61.9667236 },
        { lng:25.3417763	, lat:61.969427 },
        { lng:25.3434469	, lat:61.9692578 },
        { lng:25.3453431	, lat:61.9735608 },
        { lng:25.3447976	, lat:61.9735638 },
        { lng:25.3456791	, lat:61.974717 },
        { lng:25.3425028	, lat:61.9747198 },
        { lng:25.3399391	, lat:61.9764997 },
        { lng:25.3398227	, lat:61.9779928 },
        { lng:25.3384977	, lat:61.9796631 },
        { lng:25.3302623	, lat:61.9780996 },
        { lng:25.3240744	, lat:61.9877229 },
        { lng:25.3277184	, lat:61.9890512 },
        { lng:25.330724	, lat:61.9902569 },
        { lng:25.350633	, lat:61.9979205 },
        { lng:25.3679215	, lat:62.0046934 },
        { lng:25.371182	, lat:62.0059823 },
        { lng:25.3432737	, lat:62.016429 },
        { lng:25.3432774	, lat:62.0214917 },
        { lng:25.3388872	, lat:62.0287393 },
        { lng:25.3366325	, lat:62.0419123 },
        { lng:25.3017594	, lat:62.0447715 },
        { lng:25.295468	, lat:62.0452621 },
        { lng:25.2825913	, lat:62.046413 },
        { lng:25.2807239	, lat:62.0465511 },
        { lng:25.2607866	, lat:62.0483389 },
        { lng:25.2825773	, lat:62.0707341 },
        { lng:25.3167369	, lat:62.0820043 },
        { lng:25.3125843	, lat:62.0882569 },
        { lng:25.3438601	, lat:62.1074388 },
        { lng:25.3427411	, lat:62.1077078 },
        { lng:25.3429034	, lat:62.1088748 },
        { lng:25.3452208	, lat:62.1083227 },
        { lng:25.347697	, lat:62.1097325 },
        { lng:25.3475206	, lat:62.1098036 },
        { lng:25.3371444	, lat:62.1139786 },
        { lng:25.3146157	, lat:62.1230413 },
        { lng:25.2852095	, lat:62.1523536 },
      ],
    },
    {
      id: 8,
      regionName: 'eCom TP  Kajaani',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:26.767441	, lat:64.265168 },
        { lng:26.7674012	, lat:64.2680012 },
        { lng:26.7483822	, lat:64.2785576 },
        { lng:26.7861645	, lat:64.2920711 },
        { lng:26.8138425	, lat:64.3061635 },
        { lng:26.8244359	, lat:64.3063446 },
        { lng:26.8389172	, lat:64.3064358 },
        { lng:26.8374772	, lat:64.3043385 },
        { lng:26.8448061	, lat:64.302328 },
        { lng:26.8453688	, lat:64.3019355 },
        { lng:26.862191	, lat:64.305873 },
        { lng:26.8652301	, lat:64.3060265 },
        { lng:26.8656265	, lat:64.3047563 },
        { lng:26.87675	, lat:64.304716 },
        { lng:26.8775436	, lat:64.3028129 },
        { lng:26.8812202	, lat:64.3015001 },
        { lng:26.8883248	, lat:64.3015221 },
        { lng:26.8902721	, lat:64.3031256 },
        { lng:26.8913396	, lat:64.3048962 },
        { lng:26.8930659	, lat:64.305874 },
        { lng:26.893891	, lat:64.311966 },
        { lng:26.8948351	, lat:64.3118493 },
        { lng:26.8953039	, lat:64.3123795 },
        { lng:26.9058686	, lat:64.3110225 },
        { lng:26.9260721	, lat:64.311882 },
        { lng:26.9217183	, lat:64.3216805 },
        { lng:26.9416665	, lat:64.3270691 },
        { lng:26.9642356	, lat:64.2956981 },
        { lng:26.9994058	, lat:64.2904801 },
        { lng:27.0423459	, lat:64.2845095 },
        { lng:27.1877795	, lat:64.3103244 },
        { lng:27.3343107	, lat:64.300049 },
        { lng:27.4270057	, lat:64.2928775 },
        { lng:27.4835889	, lat:64.3217407 },
        { lng:27.5725617	, lat:64.3079753 },
        { lng:27.6517122	, lat:64.3496515 },
        { lng:27.765676	, lat:64.3491926 },
        { lng:27.7929273	, lat:64.365181 },
        { lng:27.8272338	, lat:64.3604084 },
        { lng:27.8434778	, lat:64.358232 },
        { lng:27.9127678	, lat:64.3488704 },
        { lng:27.9198932	, lat:64.3479173 },
        { lng:27.9253034	, lat:64.3471728 },
        { lng:27.9274197	, lat:64.3467937 },
        { lng:27.9338838	, lat:64.3454749 },
        { lng:27.9349819	, lat:64.3461076 },
        { lng:27.9383223	, lat:64.3452153 },
        { lng:27.9371808	, lat:64.3445061 },
        { lng:27.9681335	, lat:64.3317687 },
        { lng:27.9830385	, lat:64.3256625 },
        { lng:27.9944105	, lat:64.3298087 },
        { lng:28.0039973	, lat:64.3257085 },
        { lng:28.0307553	, lat:64.3208255 },
        { lng:28.0439472	, lat:64.3184226 },
        { lng:28.044605	, lat:64.3183064 },
        { lng:28.1116869	, lat:64.3060739 },
        { lng:28.1329504	, lat:64.3171002 },
        { lng:28.1580346	, lat:64.3080294 },
        { lng:28.1092695	, lat:64.297993 },
        { lng:28.1106451	, lat:64.2904162 },
        { lng:28.0938592	, lat:64.2967902 },
        { lng:28.0396776	, lat:64.2685955 },
        { lng:28.0293848	, lat:64.2631408 },
        { lng:27.9801432	, lat:64.2470161 },
        { lng:27.9805929	, lat:64.2464936 },
        { lng:27.980948	, lat:64.2463102 },
        { lng:27.9824282	, lat:64.2458297 },
        { lng:27.9839415	, lat:64.2456512 },
        { lng:27.9848812	, lat:64.2454631 },
        { lng:27.98607	, lat:64.2448016 },
        { lng:27.9875014	, lat:64.2441401 },
        { lng:27.9887972	, lat:64.2430756 },
        { lng:27.9886814	, lat:64.2429016 },
        { lng:27.990569	, lat:64.2417672 },
        { lng:27.9917895	, lat:64.2398578 },
        { lng:27.9922668	, lat:64.2395746 },
        { lng:27.9930349	, lat:64.2392843 },
        { lng:27.9939484	, lat:64.2390433 },
        { lng:27.9948795	, lat:64.2388989 },
        { lng:27.9960091	, lat:64.2387544 },
        { lng:27.9982653	, lat:64.2379483 },
        { lng:27.9959953	, lat:64.2361713 },
        { lng:27.9946711	, lat:64.23508 },
        { lng:27.9599768	, lat:64.2435541 },
        { lng:27.9470177	, lat:64.2306931 },
        { lng:27.9339766	, lat:64.2342095 },
        { lng:27.9227568	, lat:64.2301817 },
        { lng:27.8978794	, lat:64.220968 },
        { lng:27.885191	, lat:64.2077546 },
        { lng:27.8635822	, lat:64.18516 },
        { lng:27.8436207	, lat:64.1643594 },
        { lng:27.8407096	, lat:64.159173 },
        { lng:27.8383529	, lat:64.1596642 },
        { lng:27.8370995	, lat:64.1597418 },
        { lng:27.8358663	, lat:64.1598181 },
        { lng:27.8320876	, lat:64.1605211 },
        { lng:27.8320318	, lat:64.1600592 },
        { lng:27.7968674	, lat:64.162371 },
        { lng:27.7966212	, lat:64.1616816 },
        { lng:27.8085119	, lat:64.1551323 },
        { lng:27.8383157	, lat:64.1548241 },
        { lng:27.8524309	, lat:64.1533803 },
        { lng:27.8522312	, lat:64.1532274 },
        { lng:27.8518408	, lat:64.1530856 },
        { lng:27.8514968	, lat:64.1529032 },
        { lng:27.8511715	, lat:64.1525993 },
        { lng:27.8509484	, lat:64.1522791 },
        { lng:27.8509391	, lat:64.151886 },
        { lng:27.8509298	, lat:64.1513915 },
        { lng:27.8509298	, lat:64.1509295 },
        { lng:27.8508168	, lat:64.1507909 },
        { lng:27.85081	, lat:64.1452935 },
        { lng:27.8436888	, lat:64.1389333 },
        { lng:27.8263433	, lat:64.1332891 },
        { lng:27.8338141	, lat:64.1253798 },
        { lng:27.831158	, lat:64.120861 },
        { lng:27.8336121	, lat:64.1045053 },
        { lng:27.839112	, lat:64.1008109 },
        { lng:27.8366031	, lat:64.0819973 },
        { lng:27.8509038	, lat:64.0665823 },
        { lng:27.8443578	, lat:64.0635781 },
        { lng:27.8500932	, lat:64.0626674 },
        { lng:27.84702	, lat:64.0484856 },
        { lng:27.8314508	, lat:64.0442799 },
        { lng:27.8410421	, lat:64.0377504 },
        { lng:27.843484	, lat:64.0371226 },
        { lng:27.8568535	, lat:64.0317784 },
        { lng:27.8670992	, lat:64.0364548 },
        { lng:27.8985511	, lat:64.017517 },
        { lng:27.89751	, lat:64.0170853 },
        { lng:27.8979469	, lat:64.0126908 },
        { lng:27.9071402	, lat:64.0096562 },
        { lng:27.9106253	, lat:64.0106709 },
        { lng:27.9770105	, lat:63.981494 },
        { lng:27.9779169	, lat:63.9816469 },
        { lng:27.9784096	, lat:63.9818834 },
        { lng:27.9787442	, lat:63.9822402 },
        { lng:27.9792787	, lat:63.9821607 },
        { lng:27.9786792	, lat:63.9816571 },
        { lng:27.9779448	, lat:63.9814145 },
        { lng:27.9773824	, lat:63.9813452 },
        { lng:28.0021701	, lat:63.9704387 },
        { lng:28.0060082	, lat:63.9664028 },
        { lng:28.0078115	, lat:63.9664436 },
        { lng:28.0117157	, lat:63.9618085 },
        { lng:28.020751	, lat:63.9587725 },
        { lng:28.0316826	, lat:63.9590989 },
        { lng:28.0361166	, lat:63.9569972 },
        { lng:28.0218292	, lat:63.949646 },
        { lng:28.0655045	, lat:63.9035268 },
        { lng:28.033838	, lat:63.8722806 },
        { lng:28.0064617	, lat:63.8621194 },
        { lng:27.9845183	, lat:63.8578562 },
        { lng:27.9741243	, lat:63.8616906 },
        { lng:27.9704804	, lat:63.859856 },
        { lng:27.975766	, lat:63.8562807 },
        { lng:27.9395193	, lat:63.8497695 },
        { lng:27.9259179	, lat:63.8447118 },
        { lng:27.9098887	, lat:63.8521182 },
        { lng:27.8928104	, lat:63.8491032 },
        { lng:27.8911624	, lat:63.8509944 },
        { lng:27.8159915	, lat:63.8787787 },
        { lng:27.7404336	, lat:63.9092236 },
        { lng:27.6638598	, lat:63.9230122 },
        { lng:27.6502902	, lat:63.9187415 },
        { lng:27.6180931	, lat:63.9298806 },
        { lng:27.6012904	, lat:63.9354769 },
        { lng:27.5800547	, lat:63.9428188 },
        { lng:27.5757211	, lat:63.9443979 },
        { lng:27.5717085	, lat:63.9457038 },
        { lng:27.5404207	, lat:63.9565025 },
        { lng:27.4955401	, lat:63.959949 },
        { lng:27.4777655	, lat:63.9614706 },
        { lng:27.4970053	, lat:63.949704 },
        { lng:27.4492452	, lat:63.9443534 },
        { lng:27.4285718	, lat:63.9478656 },
        { lng:27.4283738	, lat:63.9486057 },
        { lng:27.4282143	, lat:63.9488398 },
        { lng:27.4289097	, lat:63.9497224 },
        { lng:27.4286236	, lat:63.9504842 },
        { lng:27.4285382	, lat:63.951076 },
        { lng:27.427516	, lat:63.9514815 },
        { lng:27.4274925	, lat:63.9516677 },
        { lng:27.4226449	, lat:63.9562782 },
        { lng:27.4159039	, lat:63.9557518 },
        { lng:27.4174541	, lat:63.956747 },
        { lng:27.4233747	, lat:63.9590367 },
        { lng:27.422895	, lat:63.9619149 },
        { lng:27.4239623	, lat:63.9623268 },
        { lng:27.4224946	, lat:63.9647619 },
        { lng:27.4189547	, lat:63.9652776 },
        { lng:27.4186433	, lat:63.966454 },
        { lng:27.3864122	, lat:63.9691611 },
        { lng:27.3815317	, lat:63.9703297 },
        { lng:27.3794284	, lat:63.9707044 },
        { lng:27.3771834	, lat:63.9710581 },
        { lng:27.261143	, lat:63.989333 },
        { lng:27.25045	, lat:63.9900199 },
        { lng:27.1312381	, lat:63.997633 },
        { lng:27.1219614	, lat:63.9918307 },
        { lng:27.1193114	, lat:63.9907806 },
        { lng:27.1119224	, lat:63.9944213 },
        { lng:27.1102036	, lat:63.9937858 },
        { lng:27.0185654	, lat:63.9985828 },
        { lng:27.0126024	, lat:63.9989408 },
        { lng:26.8725059	, lat:64.0351291 },
        { lng:26.8703917	, lat:64.0369559 },
        { lng:26.7811235	, lat:64.0570861 },
        { lng:26.7768683	, lat:64.1215497 },
        { lng:26.7746871	, lat:64.1210122 },
        { lng:26.7685041	, lat:64.116839 },
        { lng:26.7655355	, lat:64.1191713 },
        { lng:26.77676	, lat:64.1236443 },
        { lng:26.7747204	, lat:64.1568179 },
        { lng:26.7733246	, lat:64.1567169 },
        { lng:26.7711692	, lat:64.1577846 },
        { lng:26.7716048	, lat:64.160207 },
        { lng:26.7727388	, lat:64.1609435 },
        { lng:26.7741293	, lat:64.1629668 },
        { lng:26.7708537	, lat:64.2160224 },
        { lng:26.767441	, lat:64.265168 },
      ],
    },
    {
      id: 9,
      regionName: 'eCom TP  Kemi',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:24.1330196	, lat:65.5126873 },
        { lng:24.1357244	, lat:65.4850671 },
        { lng:24.1388482	, lat:65.4531325 },
        { lng:24.1419851	, lat:65.4210249 },
        { lng:24.1444599	, lat:65.3956666 },
        { lng:24.1451295	, lat:65.3888008 },
        { lng:24.1482945	, lat:65.3563257 },
        { lng:24.1501263	, lat:65.337276 },
        { lng:24.4681057	, lat:65.4587081 },
        { lng:24.5311243	, lat:65.5247984 },
        { lng:24.6001372	, lat:65.5968485 },
        { lng:24.6576991	, lat:65.6567365 },
        { lng:24.6786429	, lat:65.6588008 },
        { lng:24.6824055	, lat:65.6609861 },
        { lng:24.6853934	, lat:65.6601473 },
        { lng:24.7023612	, lat:65.6698696 },
        { lng:24.7272231	, lat:65.6841368 },
        { lng:24.7474104	, lat:65.6950375 },
        { lng:24.7313548	, lat:65.6983192 },
        { lng:24.7341872	, lat:65.7080047 },
        { lng:24.7207	, lat:65.708789 },
        { lng:24.7199232	, lat:65.7177567 },
        { lng:24.7341829	, lat:65.7154293 },
        { lng:24.7426511	, lat:65.7201824 },
        { lng:24.7058168	, lat:65.7207634 },
        { lng:24.6947169	, lat:65.7192594 },
        { lng:24.6935871	, lat:65.7205772 },
        { lng:24.7000416	, lat:65.7219906 },
        { lng:24.6975407	, lat:65.7259565 },
        { lng:24.7023204	, lat:65.7262375 },
        { lng:24.7022163	, lat:65.7269855 },
        { lng:24.6970118	, lat:65.7267941 },
        { lng:24.6908427	, lat:65.7366047 },
        { lng:24.6908352	, lat:65.7386155 },
        { lng:24.6886004	, lat:65.7418665 },
        { lng:24.6796139	, lat:65.7456315 },
        { lng:24.6794691	, lat:65.748549 },
        { lng:24.6833304	, lat:65.7497163 },
        { lng:24.7009911	, lat:65.7473543 },
        { lng:24.7034695	, lat:65.7499882 },
        { lng:24.6941365	, lat:65.7518314 },
        { lng:24.6824062	, lat:65.7541913 },
        { lng:24.6772258	, lat:65.7552334 },
        { lng:24.6764403	, lat:65.7553914 },
        { lng:24.6632117	, lat:65.7556187 },
        { lng:24.665631	, lat:65.7601684 },
        { lng:24.6634273	, lat:65.7600895 },
        { lng:24.6635366	, lat:65.7651004 },
        { lng:24.5951565	, lat:65.7822705 },
        { lng:24.5955438	, lat:65.7800875 },
        { lng:24.5941362	, lat:65.7787002 },
        { lng:24.5883083	, lat:65.7791844 },
        { lng:24.5801061	, lat:65.7826257 },
        { lng:24.565733	, lat:65.7906491 },
        { lng:24.5643132	, lat:65.7914415 },
        { lng:24.5599799	, lat:65.7940349 },
        { lng:24.5541143	, lat:65.7952916 },
        { lng:24.5519998	, lat:65.7928999 },
        { lng:24.5464744	, lat:65.7922937 },
        { lng:24.5398054	, lat:65.7902576 },
        { lng:24.5361608	, lat:65.7888382 },
        { lng:24.5277622	, lat:65.7861889 },
        { lng:24.5131313	, lat:65.7827987 },
        { lng:24.5089481	, lat:65.7799885 },
        { lng:24.4361928	, lat:65.7671606 },
        { lng:24.4327866	, lat:65.7653181 },
        { lng:24.4325125	, lat:65.7651697 },
        { lng:24.4021583	, lat:65.7487347 },
        { lng:24.4017941	, lat:65.7485321 },
        { lng:24.3536506	, lat:65.7215723 },
        { lng:24.3403154	, lat:65.7140922 },
        { lng:24.3067203	, lat:65.6952235 },
        { lng:24.2948308	, lat:65.6885374 },
        { lng:24.2904904	, lat:65.6860955 },
        { lng:24.2860861	, lat:65.6836349 },
        { lng:24.2794432	, lat:65.6799223 },
        { lng:24.1843603	, lat:65.626636 },
        { lng:24.1596949	, lat:65.6127677 },
        { lng:24.1515445	, lat:65.5841904 },
        { lng:24.1484654	, lat:65.5733201 },
        { lng:24.1413308	, lat:65.5483767 },
        { lng:24.1361315	, lat:65.5303135 },
        { lng:24.1319	, lat:65.5153845 },
        { lng:24.1330196	, lat:65.5126873 },
      ],
    },
    {
      id: 10,
      regionName: 'eCom TP  Kemi-Tornio kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:23.8918627	, lat:66.1676674 },
        { lng:23.8918848	, lat:66.1677192 },
        { lng:23.8918912	, lat:66.167737 },
        { lng:23.8918994	, lat:66.1677546 },
        { lng:23.8919064	, lat:66.1677661 },
        { lng:23.8919192	, lat:66.1677833 },
        { lng:23.8919336	, lat:66.1678002 },
        { lng:23.8920293	, lat:66.1679006 },
        { lng:23.8920421	, lat:66.1679178 },
        { lng:23.8920492	, lat:66.1679295 },
        { lng:23.8920577	, lat:66.1679471 },
        { lng:23.8920644	, lat:66.1679648 },
        { lng:23.8920696	, lat:66.1679826 },
        { lng:23.8920873	, lat:66.168072 },
        { lng:23.8920897	, lat:66.1680814 },
        { lng:23.8921016	, lat:66.1681169 },
        { lng:23.8921171	, lat:66.1681522 },
        { lng:23.8921468	, lat:66.1682047 },
        { lng:23.8922081	, lat:66.1682908 },
        { lng:23.8922199	, lat:66.1683055 },
        { lng:23.8923331	, lat:66.1684414 },
        { lng:23.892342	, lat:66.168453 },
        { lng:23.892485	, lat:66.1686602 },
        { lng:23.8924945	, lat:66.1686741 },
        { lng:23.8926636	, lat:66.1688969 },
        { lng:23.8926771	, lat:66.1689129 },
        { lng:23.8928421	, lat:66.1691175 },
        { lng:23.8928455	, lat:66.1691224 },
        { lng:23.8928568	, lat:66.1691397 },
        { lng:23.8930704	, lat:66.1695246 },
        { lng:23.89308	, lat:66.1695379 },
        { lng:23.8931063	, lat:66.1695721 },
        { lng:23.893166	, lat:66.1696397 },
        { lng:23.8932355	, lat:66.1697056 },
        { lng:23.8932438	, lat:66.1697129 },
        { lng:23.8933602	, lat:66.1698096 },
        { lng:23.8933746	, lat:66.1698223 },
        { lng:23.8935206	, lat:66.169953 },
        { lng:23.8935365	, lat:66.1699656 },
        { lng:23.8935793	, lat:66.169997 },
        { lng:23.8936257	, lat:66.1700276 },
        { lng:23.8936764	, lat:66.170057 },
        { lng:23.8936925	, lat:66.1700656 },
        { lng:23.8937201	, lat:66.1700796 },
        { lng:23.8937489	, lat:66.1700933 },
        { lng:23.8937789	, lat:66.1701065 },
        { lng:23.8938101	, lat:66.1701192 },
        { lng:23.8938426	, lat:66.1701314 },
        { lng:23.8938764	, lat:66.170143 },
        { lng:23.8938834	, lat:66.1701453 },
        { lng:23.8939188	, lat:66.1701561 },
        { lng:23.8939552	, lat:66.1701663 },
        { lng:23.8939928	, lat:66.1701758 },
        { lng:23.8940313	, lat:66.1701847 },
        { lng:23.8940707	, lat:66.1701929 },
        { lng:23.8941109	, lat:66.1702005 },
        { lng:23.8941518	, lat:66.1702074 },
        { lng:23.8941915	, lat:66.1702134 },
        { lng:23.8942336	, lat:66.1702192 },
        { lng:23.894276	, lat:66.1702243 },
        { lng:23.8944048	, lat:66.1702377 },
        { lng:23.8945838	, lat:66.1702525 },
        { lng:23.8946715	, lat:66.1702577 },
        { lng:23.8947598	, lat:66.1702608 },
        { lng:23.8947955	, lat:66.1702615 },
        { lng:23.8948398	, lat:66.1702618 },
        { lng:23.8950615	, lat:66.1702606 },
        { lng:23.8952393	, lat:66.1702603 },
        { lng:23.8953279	, lat:66.1702585 },
        { lng:23.8953719	, lat:66.1702568 },
        { lng:23.8953838	, lat:66.1702562 },
        { lng:23.8956028	, lat:66.1702425 },
        { lng:23.895647	, lat:66.1702409 },
        { lng:23.8956913	, lat:66.1702404 },
        { lng:23.895703	, lat:66.1702406 },
        { lng:23.8957472	, lat:66.1702418 },
        { lng:23.8957911	, lat:66.1702441 },
        { lng:23.8958347	, lat:66.1702474 },
        { lng:23.8960064	, lat:66.1702654 },
        { lng:23.8960193	, lat:66.1702667 },
        { lng:23.8961046	, lat:66.1702765 },
        { lng:23.8961458	, lat:66.1702831 },
        { lng:23.8961839	, lat:66.1702921 },
        { lng:23.8962088	, lat:66.1703002 },
        { lng:23.8962397	, lat:66.170313 },
        { lng:23.8962669	, lat:66.1703272 },
        { lng:23.8963174	, lat:66.1703566 },
        { lng:23.8963418	, lat:66.17037 },
        { lng:23.8964517	, lat:66.1704263 },
        { lng:23.8965042	, lat:66.1704552 },
        { lng:23.8965201	, lat:66.1704649 },
        { lng:23.8965434	, lat:66.1704802 },
        { lng:23.8966288	, lat:66.170543 },
        { lng:23.8966952	, lat:66.1705925 },
        { lng:23.8967148	, lat:66.1706086 },
        { lng:23.8967328	, lat:66.1706249 },
        { lng:23.8967491	, lat:66.1706416 },
        { lng:23.896764	, lat:66.1706594 },
        { lng:23.8967759	, lat:66.1706767 },
        { lng:23.8967857	, lat:66.1706942 },
        { lng:23.8967934	, lat:66.1707119 },
        { lng:23.8967993	, lat:66.1707296 },
        { lng:23.8968036	, lat:66.1707475 },
        { lng:23.8968063	, lat:66.1707654 },
        { lng:23.8968075	, lat:66.1707833 },
        { lng:23.8968075	, lat:66.1708 },
        { lng:23.8967985	, lat:66.1708716 },
        { lng:23.8967983	, lat:66.1708735 },
        { lng:23.8967976	, lat:66.1708925 },
        { lng:23.8967987	, lat:66.1709104 },
        { lng:23.8968017	, lat:66.1709283 },
        { lng:23.8968221	, lat:66.1709996 },
        { lng:23.8968691	, lat:66.1710993 },
        { lng:23.8968755	, lat:66.1711189 },
        { lng:23.8968787	, lat:66.1711368 },
        { lng:23.8968799	, lat:66.1711494 },
        { lng:23.89687	, lat:66.171239 },
        { lng:23.8968657	, lat:66.1712594 },
        { lng:23.8968367	, lat:66.1713483 },
        { lng:23.8968347	, lat:66.1713536 },
        { lng:23.8968089	, lat:66.1714246 },
        { lng:23.896804	, lat:66.1714388 },
        { lng:23.8967906	, lat:66.1714742 },
        { lng:23.8967822	, lat:66.1714919 },
        { lng:23.8967704	, lat:66.1715091 },
        { lng:23.896754	, lat:66.1715258 },
        { lng:23.896751	, lat:66.1715283 },
        { lng:23.8967296	, lat:66.171544 },
        { lng:23.8967036	, lat:66.1715585 },
        { lng:23.8966736	, lat:66.1715717 },
        { lng:23.8966396	, lat:66.1715832 },
        { lng:23.8966215	, lat:66.1715883 },
        { lng:23.8965827	, lat:66.1715969 },
        { lng:23.8965415	, lat:66.1716035 },
        { lng:23.8964989	, lat:66.1716085 },
        { lng:23.8964556	, lat:66.1716124 },
        { lng:23.896412	, lat:66.1716157 },
        { lng:23.8964043	, lat:66.1716162 },
        { lng:23.8960521	, lat:66.1716331 },
        { lng:23.8960211	, lat:66.1716342 },
        { lng:23.8957567	, lat:66.171646 },
        { lng:23.8957157	, lat:66.1716482 },
        { lng:23.8955844	, lat:66.1716569 },
        { lng:23.8954933	, lat:66.1716649 },
        { lng:23.8954505	, lat:66.1716696 },
        { lng:23.8954081	, lat:66.1716749 },
        { lng:23.8953663	, lat:66.1716807 },
        { lng:23.8953251	, lat:66.1716874 },
        { lng:23.8952848	, lat:66.1716949 },
        { lng:23.8952425	, lat:66.1717039 },
        { lng:23.8952047	, lat:66.1717133 },
        { lng:23.8951683	, lat:66.1717236 },
        { lng:23.8951335	, lat:66.1717346 },
        { lng:23.8951002	, lat:66.1717465 },
        { lng:23.8950687	, lat:66.1717591 },
        { lng:23.895039	, lat:66.1717724 },
        { lng:23.8950112	, lat:66.1717864 },
        { lng:23.8949855	, lat:66.171801 },
        { lng:23.8949621	, lat:66.1718162 },
        { lng:23.8949414	, lat:66.1718321 },
        { lng:23.8949236	, lat:66.1718485 },
        { lng:23.8949156	, lat:66.1718571 },
        { lng:23.8949025	, lat:66.1718742 },
        { lng:23.8948923	, lat:66.1718916 },
        { lng:23.894885	, lat:66.1719093 },
        { lng:23.8948804	, lat:66.1719272 },
        { lng:23.8948777	, lat:66.1719451 },
        { lng:23.8948767	, lat:66.171963 },
        { lng:23.8948771	, lat:66.1719809 },
        { lng:23.8948778	, lat:66.1719894 },
        { lng:23.8948831	, lat:66.1720252 },
        { lng:23.8949173	, lat:66.1721319 },
        { lng:23.8949223	, lat:66.1721446 },
        { lng:23.8949839	, lat:66.1722865 },
        { lng:23.8950454	, lat:66.1724095 },
        { lng:23.8950513	, lat:66.1724217 },
        { lng:23.8951188	, lat:66.1725625 },
        { lng:23.8951238	, lat:66.1725726 },
        { lng:23.8951872	, lat:66.1726954 },
        { lng:23.8951952	, lat:66.172711 },
        { lng:23.8952465	, lat:66.1728166 },
        { lng:23.8952534	, lat:66.1728316 },
        { lng:23.8954124	, lat:66.1732208 },
        { lng:23.895417	, lat:66.1732324 },
        { lng:23.8957722	, lat:66.1740264 },
        { lng:23.8957764	, lat:66.1740354 },
        { lng:23.895897	, lat:66.1743228 },
        { lng:23.8959053	, lat:66.1743448 },
        { lng:23.8960063	, lat:66.1745561 },
        { lng:23.8960173	, lat:66.1745917 },
        { lng:23.8960209	, lat:66.1746095 },
        { lng:23.8960231	, lat:66.1746275 },
        { lng:23.8960237	, lat:66.1746454 },
        { lng:23.8960227	, lat:66.1746633 },
        { lng:23.89602	, lat:66.1746812 },
        { lng:23.8960154	, lat:66.174699 },
        { lng:23.8960087	, lat:66.1747168 },
        { lng:23.8959999	, lat:66.1747343 },
        { lng:23.8959888	, lat:66.1747517 },
        { lng:23.8959854	, lat:66.1747564 },
        { lng:23.8959713	, lat:66.1747734 },
        { lng:23.8959552	, lat:66.1747901 },
        { lng:23.8959192	, lat:66.1748229 },
        { lng:23.8959108	, lat:66.1748301 },
        { lng:23.8957892	, lat:66.1749258 },
        { lng:23.8956769	, lat:66.1750031 },
        { lng:23.8955545	, lat:66.1750778 },
        { lng:23.8954219	, lat:66.1751496 },
        { lng:23.8953078	, lat:66.1752045 },
        { lng:23.8951868	, lat:66.175257 },
        { lng:23.8950589	, lat:66.1753067 },
        { lng:23.895026	, lat:66.1753186 },
        { lng:23.895012	, lat:66.1753236 },
        { lng:23.8948779	, lat:66.1753706 },
        { lng:23.8948455	, lat:66.1753828 },
        { lng:23.8948142	, lat:66.1753955 },
        { lng:23.8947843	, lat:66.1754087 },
        { lng:23.8947591	, lat:66.175421 },
        { lng:23.8947327	, lat:66.1754354 },
        { lng:23.8947081	, lat:66.1754503 },
        { lng:23.8946625	, lat:66.1754811 },
        { lng:23.8945718	, lat:66.1755457 },
        { lng:23.8943961	, lat:66.1756492 },
        { lng:23.8943794	, lat:66.1756592 },
        { lng:23.8943097	, lat:66.175705 },
        { lng:23.8942261	, lat:66.1757683 },
        { lng:23.8941155	, lat:66.1758661 },
        { lng:23.8938767	, lat:66.1760978 },
        { lng:23.8938705	, lat:66.1761033 },
        { lng:23.893758	, lat:66.1762008 },
        { lng:23.8937406	, lat:66.1762173 },
        { lng:23.8937274	, lat:66.1762305 },
        { lng:23.8936154	, lat:66.1763666 },
        { lng:23.8936059	, lat:66.1763781 },
        { lng:23.8934844	, lat:66.1765129 },
        { lng:23.8934709	, lat:66.1765299 },
        { lng:23.8934674	, lat:66.1765345 },
        { lng:23.8934444	, lat:66.1765692 },
        { lng:23.893426	, lat:66.1766042 },
        { lng:23.8934059	, lat:66.1766574 },
        { lng:23.8933934	, lat:66.176711 },
        { lng:23.8933866	, lat:66.1767826 },
        { lng:23.8933909	, lat:66.1768722 },
        { lng:23.8934154	, lat:66.1769974 },
        { lng:23.8934936	, lat:66.1773185 },
        { lng:23.8935026	, lat:66.1773902 },
        { lng:23.8935034	, lat:66.1774081 },
        { lng:23.8935034	, lat:66.1774153 },
        { lng:23.8934958	, lat:66.1775049 },
        { lng:23.8934746	, lat:66.1775941 },
        { lng:23.8934511	, lat:66.1776087 },
        { lng:23.893381	, lat:66.1776544 },
        { lng:23.8933597	, lat:66.1776701 },
        { lng:23.8933207	, lat:66.1777027 },
        { lng:23.8932516	, lat:66.1777687 },
        { lng:23.8932384	, lat:66.177781 },
        { lng:23.8930811	, lat:66.1779293 },
        { lng:23.8930713	, lat:66.1779405 },
        { lng:23.8930004	, lat:66.1780254 },
        { lng:23.8929685	, lat:66.1780589 },
        { lng:23.8929507	, lat:66.1780753 },
        { lng:23.8929313	, lat:66.1780914 },
        { lng:23.8929144	, lat:66.178104 },
        { lng:23.8928916	, lat:66.1781193 },
        { lng:23.8928674	, lat:66.1781343 },
        { lng:23.8928417	, lat:66.178149 },
        { lng:23.8928149	, lat:66.1781633 },
        { lng:23.8928054	, lat:66.1781681 },
        { lng:23.8927773	, lat:66.178182 },
        { lng:23.8925991	, lat:66.1782618 },
        { lng:23.8925842	, lat:66.1782685 },
        { lng:23.892555	, lat:66.178282 },
        { lng:23.8924991	, lat:66.1783099 },
        { lng:23.8924733	, lat:66.1783244 },
        { lng:23.8924497	, lat:66.1783396 },
        { lng:23.8924288	, lat:66.1783554 },
        { lng:23.8924143	, lat:66.1783683 },
        { lng:23.892399	, lat:66.1783851 },
        { lng:23.8923871	, lat:66.1784024 },
        { lng:23.8923789	, lat:66.17842 },
        { lng:23.8923747	, lat:66.1784379 },
        { lng:23.8923745	, lat:66.1784558 },
        { lng:23.8923763	, lat:66.1784655 },
        { lng:23.8923855	, lat:66.1785011 },
        { lng:23.8923878	, lat:66.178519 },
        { lng:23.892384	, lat:66.1785368 },
        { lng:23.892371	, lat:66.1785539 },
        { lng:23.8923659	, lat:66.178558 },
        { lng:23.8923406	, lat:66.1785727 },
        { lng:23.8923118	, lat:66.1785863 },
        { lng:23.8922827	, lat:66.1785998 },
        { lng:23.8919697	, lat:66.1787511 },
        { lng:23.8918812	, lat:66.1787913 },
        { lng:23.8915723	, lat:66.17892 },
        { lng:23.89156	, lat:66.1789249 },
        { lng:23.8910535	, lat:66.1791258 },
        { lng:23.8910257	, lat:66.1791375 },
        { lng:23.8904611	, lat:66.1793903 },
        { lng:23.8904399	, lat:66.1793992 },
        { lng:23.8904086	, lat:66.1794119 },
        { lng:23.8902467	, lat:66.1794731 },
        { lng:23.8901129	, lat:66.1795203 },
        { lng:23.8896977	, lat:66.1796548 },
        { lng:23.8896623	, lat:66.1796656 },
        { lng:23.8888541	, lat:66.1799172 },
        { lng:23.8885851	, lat:66.1800107 },
        { lng:23.8885526	, lat:66.1800229 },
        { lng:23.888521	, lat:66.1800351 },
        { lng:23.8884892	, lat:66.1800476 },
        { lng:23.8883657	, lat:66.1800991 },
        { lng:23.8882776	, lat:66.1801394 },
        { lng:23.8882733	, lat:66.1801415 },
        { lng:23.8881634	, lat:66.1801978 },
        { lng:23.8881374	, lat:66.1802123 },
        { lng:23.8880869	, lat:66.1802418 },
        { lng:23.8880626	, lat:66.1802568 },
        { lng:23.8879931	, lat:66.1803027 },
        { lng:23.8879711	, lat:66.1803183 },
        { lng:23.8879088	, lat:66.1803658 },
        { lng:23.8878521	, lat:66.1804144 },
        { lng:23.8878015	, lat:66.1804642 },
        { lng:23.8877861	, lat:66.180481 },
        { lng:23.8877575	, lat:66.180515 },
        { lng:23.8877444	, lat:66.1805321 },
        { lng:23.8877204	, lat:66.1805666 },
        { lng:23.8876904	, lat:66.180619 },
        { lng:23.887682	, lat:66.1806366 },
        { lng:23.887679	, lat:66.1806435 },
        { lng:23.8876718	, lat:66.1806612 },
        { lng:23.8876143	, lat:66.180839 },
        { lng:23.8876108	, lat:66.1808475 },
        { lng:23.8876031	, lat:66.1808652 },
        { lng:23.8875268	, lat:66.1810235 },
        { lng:23.8875079	, lat:66.1810768 },
        { lng:23.8875036	, lat:66.1810946 },
        { lng:23.8875019	, lat:66.1811075 },
        { lng:23.8875013	, lat:66.1811254 },
        { lng:23.8875045	, lat:66.1811971 },
        { lng:23.887504	, lat:66.1812086 },
        { lng:23.8875017	, lat:66.1812265 },
        { lng:23.8874978	, lat:66.1812443 },
        { lng:23.8874923	, lat:66.1812621 },
        { lng:23.8874856	, lat:66.1812798 },
        { lng:23.8874696	, lat:66.1813151 },
        { lng:23.8874668	, lat:66.1813206 },
        { lng:23.8874366	, lat:66.181373 },
        { lng:23.8874251	, lat:66.1813904 },
        { lng:23.8874126	, lat:66.1814076 },
        { lng:23.8873992	, lat:66.1814246 },
        { lng:23.8873907	, lat:66.1814348 },
        { lng:23.8873757	, lat:66.1814517 },
        { lng:23.8873433	, lat:66.181485 },
        { lng:23.887253	, lat:66.1815669 },
        { lng:23.8872358	, lat:66.1815821 },
        { lng:23.8870778	, lat:66.1817303 },
        { lng:23.8870695	, lat:66.1817384 },
        { lng:23.8869104	, lat:66.1818864 },
        { lng:23.8868913	, lat:66.1819026 },
        { lng:23.8868713	, lat:66.1819186 },
        { lng:23.8868503	, lat:66.1819344 },
        { lng:23.8868281	, lat:66.1819499 },
        { lng:23.8867806	, lat:66.1819802 },
        { lng:23.8867272	, lat:66.1820105 },
        { lng:23.8866999	, lat:66.1820246 },
        { lng:23.8865591	, lat:66.1820939 },
        { lng:23.8865321	, lat:66.1821081 },
        { lng:23.886524	, lat:66.1821127 },
        { lng:23.8864988	, lat:66.1821274 },
        { lng:23.8864751	, lat:66.1821426 },
        { lng:23.8864527	, lat:66.1821581 },
        { lng:23.8864317	, lat:66.1821738 },
        { lng:23.886412	, lat:66.1821899 },
        { lng:23.8863764	, lat:66.1822227 },
        { lng:23.8863644	, lat:66.1822353 },
        { lng:23.8863353	, lat:66.1822691 },
        { lng:23.8862861	, lat:66.1823381 },
        { lng:23.886283	, lat:66.1823426 },
        { lng:23.8862034	, lat:66.1824639 },
        { lng:23.8862009	, lat:66.182468 },
        { lng:23.8861352	, lat:66.1825906 },
        { lng:23.8861054	, lat:66.1826628 },
        { lng:23.8860992	, lat:66.1826806 },
        { lng:23.8860385	, lat:66.1828944 },
        { lng:23.886036	, lat:66.1829028 },
        { lng:23.8859776	, lat:66.1830805 },
        { lng:23.8859739	, lat:66.1830933 },
        { lng:23.8859645	, lat:66.183129 },
        { lng:23.8859341	, lat:66.1833079 },
        { lng:23.8859176	, lat:66.1834691 },
        { lng:23.8859158	, lat:66.1834861 },
        { lng:23.8858877	, lat:66.183701 },
        { lng:23.8858855	, lat:66.1837173 },
        { lng:23.8858605	, lat:66.1838784 },
        { lng:23.8858561	, lat:66.1838962 },
        { lng:23.8858396	, lat:66.1839496 },
        { lng:23.8858384	, lat:66.1839529 },
        { lng:23.8858079	, lat:66.1840235 },
        { lng:23.8857987	, lat:66.1840414 },
        { lng:23.8857257	, lat:66.1841634 },
        { lng:23.8857146	, lat:66.1841808 },
        { lng:23.8856409	, lat:66.1843027 },
        { lng:23.8856318	, lat:66.1843202 },
        { lng:23.8856254	, lat:66.1843335 },
        { lng:23.8856178	, lat:66.1843512 },
        { lng:23.8855935	, lat:66.1844223 },
        { lng:23.8855702	, lat:66.1845114 },
        { lng:23.8855692	, lat:66.1845152 },
        { lng:23.885535	, lat:66.1846219 },
        { lng:23.8855276	, lat:66.1846395 },
        { lng:23.8855105	, lat:66.1846747 },
        { lng:23.8855021	, lat:66.18469 },
        { lng:23.8854682	, lat:66.184742 },
        { lng:23.8854554	, lat:66.1847592 },
        { lng:23.8854127	, lat:66.1848101 },
        { lng:23.8853973	, lat:66.1848269 },
        { lng:23.8853487	, lat:66.184877 },
        { lng:23.8853456	, lat:66.18488 },
        { lng:23.8852021	, lat:66.185014 },
        { lng:23.8851844	, lat:66.1850304 },
        { lng:23.8851297	, lat:66.1850794 },
        { lng:23.8851101	, lat:66.1850955 },
        { lng:23.8851044	, lat:66.1850999 },
        { lng:23.8850829	, lat:66.1851156 },
        { lng:23.8850602	, lat:66.185131 },
        { lng:23.8850434	, lat:66.1851423 },
        { lng:23.8850216	, lat:66.1851584 },
        { lng:23.8850024	, lat:66.1851745 },
        { lng:23.884985	, lat:66.185191 },
        { lng:23.8849692	, lat:66.1852078 },
        { lng:23.8849136	, lat:66.1852759 },
        { lng:23.8848992	, lat:66.1852929 },
        { lng:23.8848713	, lat:66.1853269 },
        { lng:23.8848586	, lat:66.1853441 },
        { lng:23.8848362	, lat:66.1853788 },
        { lng:23.8848313	, lat:66.1853871 },
        { lng:23.8848216	, lat:66.1854046 },
        { lng:23.8848129	, lat:66.1854221 },
        { lng:23.8847922	, lat:66.1854753 },
        { lng:23.884787	, lat:66.1854931 },
        { lng:23.8847791	, lat:66.1855288 },
        { lng:23.8847727	, lat:66.1855825 },
        { lng:23.884772	, lat:66.1856186 },
        { lng:23.8847726	, lat:66.1856365 },
        { lng:23.8847805	, lat:66.1857082 },
        { lng:23.8847837	, lat:66.1857261 },
        { lng:23.8848269	, lat:66.1858865 },
        { lng:23.884829	, lat:66.1858934 },
        { lng:23.8848344	, lat:66.1859112 },
        { lng:23.884892	, lat:66.1861251 },
        { lng:23.8849051	, lat:66.1862349 },
        { lng:23.8849048	, lat:66.1864501 },
        { lng:23.8849062	, lat:66.186468 },
        { lng:23.8849109	, lat:66.1865038 },
        { lng:23.8849135	, lat:66.1865175 },
        { lng:23.8849283	, lat:66.186571 },
        { lng:23.8849348	, lat:66.1865887 },
        { lng:23.8849496	, lat:66.1866241 },
        { lng:23.8850037	, lat:66.1867302 },
        { lng:23.8850359	, lat:66.1867802 },
        { lng:23.8851944	, lat:66.1870252 },
        { lng:23.8851983	, lat:66.1870311 },
        { lng:23.8852513	, lat:66.1871181 },
        { lng:23.8852595	, lat:66.1871358 },
        { lng:23.8852661	, lat:66.1871535 },
        { lng:23.885271	, lat:66.1871708 },
        { lng:23.8852764	, lat:66.1872066 },
        { lng:23.8852808	, lat:66.1872962 },
        { lng:23.8852827	, lat:66.1873124 },
        { lng:23.8852912	, lat:66.1873481 },
        { lng:23.8852985	, lat:66.1873683 },
        { lng:23.8853387	, lat:66.1874565 },
        { lng:23.8853765	, lat:66.1875265 },
        { lng:23.8853889	, lat:66.1875437 },
        { lng:23.8854034	, lat:66.1875607 },
        { lng:23.8854071	, lat:66.1875647 },
        { lng:23.8854244	, lat:66.1875812 },
        { lng:23.8854434	, lat:66.1875974 },
        { lng:23.8854636	, lat:66.1876133 },
        { lng:23.8854849	, lat:66.1876291 },
        { lng:23.8855678	, lat:66.1876925 },
        { lng:23.8855744	, lat:66.1876982 },
        { lng:23.885592	, lat:66.1877146 },
        { lng:23.8856709	, lat:66.1877984 },
        { lng:23.8856869	, lat:66.1878134 },
        { lng:23.885706	, lat:66.1878295 },
        { lng:23.8857268	, lat:66.1878454 },
        { lng:23.8857515	, lat:66.1878629 },
        { lng:23.8858222	, lat:66.1879085 },
        { lng:23.8859425	, lat:66.1879838 },
        { lng:23.8859525	, lat:66.1879901 },
        { lng:23.8861596	, lat:66.1881281 },
        { lng:23.8862634	, lat:66.1882073 },
        { lng:23.886272	, lat:66.1882143 },
        { lng:23.8863276	, lat:66.1882632 },
        { lng:23.8863601	, lat:66.1882966 },
        { lng:23.8863743	, lat:66.1883135 },
        { lng:23.8863846	, lat:66.1883273 },
        { lng:23.886396	, lat:66.1883446 },
        { lng:23.8864058	, lat:66.1883621 },
        { lng:23.8864141	, lat:66.1883797 },
        { lng:23.8864209	, lat:66.1883974 },
        { lng:23.8864264	, lat:66.1884152 },
        { lng:23.8864308	, lat:66.1884331 },
        { lng:23.8864341	, lat:66.188451 },
        { lng:23.8864354	, lat:66.1884601 },
        { lng:23.8864439	, lat:66.1885856 },
        { lng:23.886447	, lat:66.1886039 },
        { lng:23.8864919	, lat:66.1887479 },
        { lng:23.886497	, lat:66.1887838 },
        { lng:23.8864979	, lat:66.1888017 },
        { lng:23.8864973	, lat:66.1888375 },
        { lng:23.8864931	, lat:66.1888913 },
        { lng:23.8864919	, lat:66.1889075 },
        { lng:23.8864843	, lat:66.188997 },
        { lng:23.8864807	, lat:66.1890149 },
        { lng:23.8864765	, lat:66.1890309 },
        { lng:23.8864635	, lat:66.1890664 },
        { lng:23.8864551	, lat:66.189084 },
        { lng:23.8864456	, lat:66.1891015 },
        { lng:23.8864352	, lat:66.1891189 },
        { lng:23.8863875	, lat:66.189188 },
        { lng:23.8863762	, lat:66.1892033 },
        { lng:23.8862388	, lat:66.1893738 },
        { lng:23.8862235	, lat:66.1893906 },
        { lng:23.8861586	, lat:66.1894574 },
        { lng:23.886122	, lat:66.1894916 },
        { lng:23.8861037	, lat:66.1895079 },
        { lng:23.8859861	, lat:66.1896044 },
        { lng:23.8859677	, lat:66.1896188 },
        { lng:23.8859471	, lat:66.1896346 },
        { lng:23.8858183	, lat:66.1897288 },
        { lng:23.8857969	, lat:66.1897435 },
        { lng:23.8854748	, lat:66.1899582 },
        { lng:23.8854333	, lat:66.1899899 },
        { lng:23.8854141	, lat:66.1900061 },
        { lng:23.8853963	, lat:66.1900225 },
        { lng:23.8853798	, lat:66.1900391 },
        { lng:23.8853648	, lat:66.190056 },
        { lng:23.8853513	, lat:66.1900731 },
        { lng:23.8853396	, lat:66.1900904 },
        { lng:23.8853296	, lat:66.1901078 },
        { lng:23.8853215	, lat:66.1901255 },
        { lng:23.8853156	, lat:66.1901432 },
        { lng:23.8853118	, lat:66.1901611 },
        { lng:23.8853103	, lat:66.190179 },
        { lng:23.8853111	, lat:66.1901969 },
        { lng:23.8853143	, lat:66.1902148 },
        { lng:23.88532	, lat:66.1902326 },
        { lng:23.8853282	, lat:66.1902502 },
        { lng:23.8853297	, lat:66.190253 },
        { lng:23.8853406	, lat:66.1902704 },
        { lng:23.8853538	, lat:66.1902875 },
        { lng:23.8853693	, lat:66.1903043 },
        { lng:23.8853868	, lat:66.1903208 },
        { lng:23.8854258	, lat:66.190353 },
        { lng:23.8855125	, lat:66.1904156 },
        { lng:23.8855289	, lat:66.1904271 },
        { lng:23.8856616	, lat:66.1905204 },
        { lng:23.8856805	, lat:66.1905336 },
        { lng:23.8857031	, lat:66.190549 },
        { lng:23.8859963	, lat:66.1907497 },
        { lng:23.8861187	, lat:66.1908453 },
        { lng:23.8861375	, lat:66.1908615 },
        { lng:23.8861406	, lat:66.1908642 },
        { lng:23.8861768	, lat:66.1908969 },
        { lng:23.8862928	, lat:66.1910134 },
        { lng:23.8863015	, lat:66.1910223 },
        { lng:23.8864116	, lat:66.1911397 },
        { lng:23.8864379	, lat:66.1911739 },
        { lng:23.8864596	, lat:66.1912087 },
        { lng:23.8864614	, lat:66.1912119 },
        { lng:23.8864782	, lat:66.1912471 },
        { lng:23.886485	, lat:66.1912649 },
        { lng:23.8864908	, lat:66.1912826 },
        { lng:23.8865028	, lat:66.1913362 },
        { lng:23.8865142	, lat:66.1914257 },
        { lng:23.8865148	, lat:66.1914301 },
        { lng:23.8865172	, lat:66.191448 },
        { lng:23.8865471	, lat:66.191591 },
        { lng:23.8865831	, lat:66.1916976 },
        { lng:23.8865907	, lat:66.1917152 },
        { lng:23.8866169	, lat:66.191768 },
        { lng:23.8866599	, lat:66.1918376 },
        { lng:23.8866721	, lat:66.1918548 },
        { lng:23.8866849	, lat:66.191872 },
        { lng:23.8866932	, lat:66.1918827 },
        { lng:23.88687	, lat:66.1920856 },
        { lng:23.8868828	, lat:66.1921028 },
        { lng:23.8868948	, lat:66.19212 },
        { lng:23.8869159	, lat:66.1921549 },
        { lng:23.8869325	, lat:66.1921901 },
        { lng:23.8869388	, lat:66.1922079 },
        { lng:23.8869436	, lat:66.1922257 },
        { lng:23.8869468	, lat:66.1922458 },
        { lng:23.8869478	, lat:66.1922637 },
        { lng:23.8869389	, lat:66.1923892 },
        { lng:23.88694	, lat:66.1924071 },
        { lng:23.8869428	, lat:66.192425 },
        { lng:23.8869457	, lat:66.1924367 },
        { lng:23.8869839	, lat:66.192525 },
        { lng:23.8869885	, lat:66.1925428 },
        { lng:23.88699	, lat:66.1925607 },
        { lng:23.8869873	, lat:66.1925786 },
        { lng:23.8869849	, lat:66.1925852 },
        { lng:23.8869744	, lat:66.1926026 },
        { lng:23.8869594	, lat:66.1926195 },
        { lng:23.8869411	, lat:66.1926358 },
        { lng:23.8869201	, lat:66.1926516 },
        { lng:23.8868979	, lat:66.1926671 },
        { lng:23.8868527	, lat:66.192698 },
        { lng:23.886842	, lat:66.1927057 },
        { lng:23.8868215	, lat:66.1927216 },
        { lng:23.8867843	, lat:66.1927541 },
        { lng:23.8867671	, lat:66.1927707 },
        { lng:23.8867196	, lat:66.1928209 },
        { lng:23.8867048	, lat:66.1928378 },
        { lng:23.8866131	, lat:66.1929388 },
        { lng:23.8865961	, lat:66.1929553 },
        { lng:23.8865832	, lat:66.192967 },
        { lng:23.8865434	, lat:66.192999 },
        { lng:23.886522	, lat:66.1930147 },
        { lng:23.8864998	, lat:66.1930303 },
        { lng:23.8864768	, lat:66.1930456 },
        { lng:23.8864286	, lat:66.1930757 },
        { lng:23.8861919	, lat:66.1932073 },
        { lng:23.8860409	, lat:66.1932959 },
        { lng:23.886017	, lat:66.193311 },
        { lng:23.8859478	, lat:66.193357 },
        { lng:23.8859258	, lat:66.1933726 },
        { lng:23.8859082	, lat:66.1933854 },
        { lng:23.8858872	, lat:66.1934012 },
        { lng:23.8857887	, lat:66.1934815 },
        { lng:23.8857702	, lat:66.1934978 },
        { lng:23.8856427	, lat:66.1936123 },
        { lng:23.8856394	, lat:66.1936152 },
        { lng:23.8856209	, lat:66.1936315 },
        { lng:23.885464	, lat:66.1937602 },
        { lng:23.8854002	, lat:66.1938074 },
        { lng:23.8853882	, lat:66.1938159 },
        { lng:23.885366	, lat:66.1938315 },
        { lng:23.8852554	, lat:66.1939092 },
        { lng:23.8852145	, lat:66.193941 },
        { lng:23.8851954	, lat:66.1939572 },
        { lng:23.8851888	, lat:66.193963 },
        { lng:23.8851712	, lat:66.1939795 },
        { lng:23.8851203	, lat:66.1940292 },
        { lng:23.8851083	, lat:66.1940404 },
        { lng:23.8850898	, lat:66.1940567 },
        { lng:23.8850702	, lat:66.1940727 },
        { lng:23.8850079	, lat:66.1941203 },
        { lng:23.8850026	, lat:66.1941245 },
        { lng:23.8849652	, lat:66.194157 },
        { lng:23.8849154	, lat:66.1942069 },
        { lng:23.8849	, lat:66.1942237 },
        { lng:23.8848853	, lat:66.1942406 },
        { lng:23.8848781	, lat:66.1942496 },
        { lng:23.8848652	, lat:66.1942668 },
        { lng:23.8848535	, lat:66.1942841 },
        { lng:23.8848333	, lat:66.194319 },
        { lng:23.8848246	, lat:66.1943366 },
        { lng:23.8848034	, lat:66.1943897 },
        { lng:23.8847974	, lat:66.1944075 },
        { lng:23.8847919	, lat:66.1944252 },
        { lng:23.8847907	, lat:66.1944295 },
        { lng:23.8847858	, lat:66.1944473 },
        { lng:23.8847474	, lat:66.1946439 },
        { lng:23.8847464	, lat:66.1946503 },
        { lng:23.8847322	, lat:66.1947756 },
        { lng:23.8847348	, lat:66.1948317 },
        { lng:23.8847375	, lat:66.1948496 },
        { lng:23.8847792	, lat:66.1950281 },
        { lng:23.8847816	, lat:66.195046 },
        { lng:23.8847824	, lat:66.1950569 },
        { lng:23.884783	, lat:66.1951466 },
        { lng:23.8847854	, lat:66.1951645 },
        { lng:23.8847898	, lat:66.1951823 },
        { lng:23.8847968	, lat:66.1952 },
        { lng:23.8848066	, lat:66.1952175 },
        { lng:23.8848141	, lat:66.1952277 },
        { lng:23.8848294	, lat:66.1952445 },
        { lng:23.8848479	, lat:66.1952608 },
        { lng:23.8848694	, lat:66.1952765 },
        { lng:23.8848934	, lat:66.1952916 },
        { lng:23.8849184	, lat:66.1953064 },
        { lng:23.884971	, lat:66.1953353 },
        { lng:23.8849968	, lat:66.195349 },
        { lng:23.8850207	, lat:66.1953651 },
        { lng:23.885039	, lat:66.1953814 },
        { lng:23.8850538	, lat:66.1953983 },
        { lng:23.885097	, lat:66.1954678 },
        { lng:23.8851093	, lat:66.195485 },
        { lng:23.8851218	, lat:66.1955023 },
        { lng:23.8851424	, lat:66.1955371 },
        { lng:23.8851511	, lat:66.1955547 },
        { lng:23.8851588	, lat:66.1955724 },
        { lng:23.8851601	, lat:66.1955757 },
        { lng:23.885177	, lat:66.1956291 },
        { lng:23.8851972	, lat:66.1957543 },
        { lng:23.8851981	, lat:66.1957601 },
        { lng:23.8852059	, lat:66.1957958 },
        { lng:23.8852235	, lat:66.1958492 },
        { lng:23.885238	, lat:66.1958855 },
        { lng:23.8853158	, lat:66.1960438 },
        { lng:23.8853254	, lat:66.1960613 },
        { lng:23.8854703	, lat:66.1963238 },
        { lng:23.8854724	, lat:66.1963278 },
        { lng:23.8854813	, lat:66.1963453 },
        { lng:23.8856029	, lat:66.1966097 },
        { lng:23.8856065	, lat:66.1966183 },
        { lng:23.885739	, lat:66.196956 },
        { lng:23.8857455	, lat:66.1969737 },
        { lng:23.8858235	, lat:66.1971684 },
        { lng:23.8858407	, lat:66.1972036 },
        { lng:23.8858465	, lat:66.1972149 },
        { lng:23.8859617	, lat:66.1974066 },
        { lng:23.8859709	, lat:66.1974207 },
        { lng:23.8859804	, lat:66.1974383 },
        { lng:23.8860086	, lat:66.1975091 },
        { lng:23.8860129	, lat:66.1975213 },
        { lng:23.8860238	, lat:66.1975569 },
        { lng:23.8860446	, lat:66.1976641 },
        { lng:23.8860453	, lat:66.1976688 },
        { lng:23.886048	, lat:66.1976867 },
        { lng:23.8861192	, lat:66.1981161 },
        { lng:23.8861724	, lat:66.1982941 },
        { lng:23.8861743	, lat:66.1982993 },
        { lng:23.8862356	, lat:66.1984588 },
        { lng:23.8862485	, lat:66.1985123 },
        { lng:23.886251	, lat:66.1985302 },
        { lng:23.8862535	, lat:66.1985661 },
        { lng:23.8862506	, lat:66.1986198 },
        { lng:23.8862479	, lat:66.1986377 },
        { lng:23.8862457	, lat:66.1986486 },
        { lng:23.8862857	, lat:66.1987682 },
        { lng:23.9011815	, lat:66.2011504 },
        { lng:23.9233574	, lat:66.2047362 },
        { lng:23.9280177	, lat:66.2054648 },
        { lng:23.960046	, lat:66.2104777 },
        { lng:23.9602902	, lat:66.2105159 },
        { lng:23.9908076	, lat:66.2152648 },
        { lng:23.9909219	, lat:66.2152826 },
        { lng:23.9959638	, lat:66.2160597 },
        { lng:24.0168468	, lat:66.2192761 },
        { lng:24.0182237	, lat:66.2194869 },
        { lng:24.0249715	, lat:66.2205199 },
        { lng:24.0326454	, lat:66.2216942 },
        { lng:24.0332251	, lat:66.2217829 },
        { lng:24.0498254	, lat:66.2243213 },
        { lng:24.0602532	, lat:66.2259147 },
        { lng:24.0735514	, lat:66.2279454 },
        { lng:24.0738647	, lat:66.2279932 },
        { lng:24.0856855	, lat:66.2297971 },
        { lng:24.0890063	, lat:66.2303036 },
        { lng:24.0929636	, lat:66.2309071 },
        { lng:24.1030687	, lat:66.2324476 },
        { lng:24.1070979	, lat:66.2330616 },
        { lng:24.1102677	, lat:66.2335356 },
        { lng:24.14416	, lat:66.2386452 },
        { lng:24.1462279	, lat:66.2389566 },
        { lng:24.1524765	, lat:66.2398975 },
        { lng:24.1557248	, lat:66.2403865 },
        { lng:24.1682257	, lat:66.2422104 },
        { lng:24.1698627	, lat:66.2424491 },
        { lng:24.1836086	, lat:66.2444947 },
        { lng:24.1845288	, lat:66.2446316 },
        { lng:24.1850713	, lat:66.2447123 },
        { lng:24.1934433	, lat:66.2459573 },
        { lng:24.1969491	, lat:66.2464785 },
        { lng:24.1971585	, lat:66.2465096 },
        { lng:24.2053304	, lat:66.2477241 },
        { lng:24.1925627	, lat:66.2539428 },
        { lng:24.1911597	, lat:66.2570852 },
        { lng:24.1932171	, lat:66.2575587 },
        { lng:24.1933089	, lat:66.2575799 },
        { lng:24.1941592	, lat:66.2578349 },
        { lng:24.2034022	, lat:66.260607 },
        { lng:24.2033808	, lat:66.260618 },
        { lng:24.2032879	, lat:66.2606659 },
        { lng:24.2010988	, lat:66.2617952 },
        { lng:24.1976889	, lat:66.2607269 },
        { lng:24.1887301	, lat:66.2656111 },
        { lng:24.188671	, lat:66.2656434 },
        { lng:24.1932777	, lat:66.2669354 },
        { lng:24.2029709	, lat:66.2696976 },
        { lng:24.2031001	, lat:66.2696323 },
        { lng:24.2113645	, lat:66.2654554 },
        { lng:24.2114726	, lat:66.2654008 },
        { lng:24.218482	, lat:66.2618559 },
        { lng:24.2230684	, lat:66.2595356 },
        { lng:24.2312723	, lat:66.261896 },
        { lng:24.2317106	, lat:66.262022 },
        { lng:24.2336311	, lat:66.2625745 },
        { lng:24.2364085	, lat:66.2633733 },
        { lng:24.236425	, lat:66.2633546 },
        { lng:24.2415885	, lat:66.2573994 },
        { lng:24.2340305	, lat:66.2538995 },
        { lng:24.2372723	, lat:66.2524699 },
        { lng:24.2451572	, lat:66.2536029 },
        { lng:24.2626701	, lat:66.2561583 },
        { lng:24.27378	, lat:66.2577793 },
        { lng:24.2740229	, lat:66.257815 },
        { lng:24.2742646	, lat:66.2578504 },
        { lng:24.2854976	, lat:66.2594855 },
        { lng:24.2982043	, lat:66.2613356 },
        { lng:24.3297982	, lat:66.2659062 },
        { lng:24.3369708	, lat:66.2669427 },
        { lng:24.3389925	, lat:66.2672348 },
        { lng:24.3567744	, lat:66.2698124 },
        { lng:24.3677048	, lat:66.2713956 },
        { lng:24.3775048	, lat:66.2728141 },
        { lng:24.3865665	, lat:66.2741252 },
        { lng:24.3960462	, lat:66.275496 },
        { lng:24.4116228	, lat:66.277773 },
        { lng:24.4189061	, lat:66.2788385 },
        { lng:24.4413631	, lat:66.2820577 },
        { lng:24.4480488	, lat:66.2830271 },
        { lng:24.4485254	, lat:66.2830956 },
        { lng:24.4493221	, lat:66.2832101 },
        { lng:24.4540916	, lat:66.2838957 },
        { lng:24.4551001	, lat:66.2840406 },
        { lng:24.4561819	, lat:66.284196 },
        { lng:24.4643957	, lat:66.2853761 },
        { lng:24.4684142	, lat:66.2859532 },
        { lng:24.4716899	, lat:66.2864245 },
        { lng:24.4722466	, lat:66.2865046 },
        { lng:24.476883	, lat:66.2871577 },
        { lng:24.4799195	, lat:66.2875854 },
        { lng:24.4803477	, lat:66.2876738 },
        { lng:24.4810193	, lat:66.2877712 },
        { lng:24.495538	, lat:66.2898762 },
        { lng:24.5028969	, lat:66.2909425 },
        { lng:24.5152091	, lat:66.2927255 },
        { lng:24.5235925	, lat:66.2939389 },
        { lng:24.5259457	, lat:66.2942794 },
        { lng:24.5273113	, lat:66.2944769 },
        { lng:24.5276456	, lat:66.2945253 },
        { lng:24.5278005	, lat:66.2945477 },
        { lng:24.5331577	, lat:66.2953226 },
        { lng:24.5379411	, lat:66.2960143 },
        { lng:24.5453136	, lat:66.29708 },
        { lng:24.5527976	, lat:66.2981614 },
        { lng:24.5530719	, lat:66.2981983 },
        { lng:24.5530721	, lat:66.2981984 },
        { lng:24.5532279	, lat:66.2982193 },
        { lng:24.5576379	, lat:66.2988127 },
        { lng:24.5612913	, lat:66.2993342 },
        { lng:24.5622689	, lat:66.2994738 },
        { lng:24.5638654	, lat:66.2997017 },
        { lng:24.568072	, lat:66.300302 },
        { lng:24.5716253	, lat:66.300809 },
        { lng:24.5778043	, lat:66.3016904 },
        { lng:24.7918234	, lat:66.332226 },
        { lng:24.7288658	, lat:66.2987277 },
        { lng:24.7243653	, lat:66.29639 },
        { lng:24.7210569	, lat:66.2946472 },
        { lng:24.7184451	, lat:66.2933063 },
        { lng:24.7174264	, lat:66.2927356 },
        { lng:24.7142081	, lat:66.291111 },
        { lng:24.7123319	, lat:66.290135 },
        { lng:24.7116019	, lat:66.2897623 },
        { lng:24.709233	, lat:66.2885527 },
        { lng:24.7086159	, lat:66.2882376 },
        { lng:24.7084933	, lat:66.288175 },
        { lng:24.7070741	, lat:66.2874503 },
        { lng:24.7065249	, lat:66.2871729 },
        { lng:24.7033094	, lat:66.2855491 },
        { lng:24.7001245	, lat:66.2839403 },
        { lng:24.6990977	, lat:66.2834261 },
        { lng:24.6989502	, lat:66.2833523 },
        { lng:24.697319	, lat:66.2825353 },
        { lng:24.6933008	, lat:66.2805226 },
        { lng:24.6928682	, lat:66.2803059 },
        { lng:24.6905231	, lat:66.279131 },
        { lng:24.6859252	, lat:66.276827 },
        { lng:24.6841584	, lat:66.2759415 },
        { lng:24.6821383	, lat:66.274929 },
        { lng:24.6806121	, lat:66.274164 },
        { lng:24.6772473	, lat:66.2724771 },
        { lng:24.676183	, lat:66.2719434 },
        { lng:24.6730838	, lat:66.2703894 },
        { lng:24.668837	, lat:66.2682594 },
        { lng:24.6665852	, lat:66.2671298 },
        { lng:24.6662164	, lat:66.2669448 },
        { lng:24.6639136	, lat:66.2657895 },
        { lng:24.6636422	, lat:66.265658 },
        { lng:24.663642	, lat:66.2656579 },
        { lng:24.6600813	, lat:66.2639327 },
        { lng:24.6572761	, lat:66.2625585 },
        { lng:24.6505068	, lat:66.2592416 },
        { lng:24.6485182	, lat:66.258267 },
        { lng:24.6459775	, lat:66.2570217 },
        { lng:24.6419438	, lat:66.2550442 },
        { lng:24.6410761	, lat:66.2546187 },
        { lng:24.6391258	, lat:66.2536625 },
        { lng:24.6381331	, lat:66.2531757 },
        { lng:24.6378075	, lat:66.253016 },
        { lng:24.6348292	, lat:66.2515553 },
        { lng:24.6312464	, lat:66.2497979 },
        { lng:24.6260062	, lat:66.2472269 },
        { lng:24.6162809	, lat:66.2424535 },
        { lng:24.615426	, lat:66.2421204 },
        { lng:24.6148655	, lat:66.2418494 },
        { lng:24.6111623	, lat:66.240059 },
        { lng:24.6050872	, lat:66.2372103 },
        { lng:24.6023793	, lat:66.2359403 },
        { lng:24.6000238	, lat:66.2348241 },
        { lng:24.6000159	, lat:66.2348226 },
        { lng:24.5998497	, lat:66.2347472 },
        { lng:24.5996537	, lat:66.2346583 },
        { lng:24.5960838	, lat:66.2329796 },
        { lng:24.593976	, lat:66.2320008 },
        { lng:24.5920001	, lat:66.231083 },
        { lng:24.5899694	, lat:66.2301398 },
        { lng:24.5818679	, lat:66.2263757 },
        { lng:24.5780558	, lat:66.224604 },
        { lng:24.5774827	, lat:66.2243376 },
        { lng:24.570267	, lat:66.220983 },
        { lng:24.5683723	, lat:66.220102 },
        { lng:24.5664263	, lat:66.2191969 },
        { lng:24.5641569	, lat:66.2181414 },
        { lng:24.5619456	, lat:66.2171129 },
        { lng:24.5597837	, lat:66.2161071 },
        { lng:24.5578656	, lat:66.2152147 },
        { lng:24.5563586	, lat:66.2145135 },
        { lng:24.5548545	, lat:66.2138136 },
        { lng:24.5532756	, lat:66.2130789 },
        { lng:24.553137	, lat:66.2130143 },
        { lng:24.5508446	, lat:66.21204 },
        { lng:24.5486566	, lat:66.2110257 },
        { lng:24.5459761	, lat:66.2097452 },
        { lng:24.5356144	, lat:66.2049452 },
        { lng:24.5353534	, lat:66.2048243 },
        { lng:24.5262292	, lat:66.2007569 },
        { lng:24.5210941	, lat:66.1982587 },
        { lng:24.5176621	, lat:66.1965983 },
        { lng:24.516825	, lat:66.1962043 },
        { lng:24.511324	, lat:66.1937203 },
        { lng:24.5066393	, lat:66.1915345 },
        { lng:24.5030677	, lat:66.1899274 },
        { lng:24.5001095	, lat:66.188577 },
        { lng:24.4940781	, lat:66.1857612 },
        { lng:24.4948702	, lat:66.1826361 },
        { lng:24.4959636	, lat:66.17844 },
        { lng:24.4992771	, lat:66.1657142 },
        { lng:24.5012811	, lat:66.1580115 },
        { lng:24.4989489	, lat:66.1576544 },
        { lng:24.4969458	, lat:66.1573476 },
        { lng:24.4971566	, lat:66.1550837 },
        { lng:24.4972543	, lat:66.1540341 },
        { lng:24.4975322	, lat:66.151824 },
        { lng:24.4976717	, lat:66.1507147 },
        { lng:24.4976833	, lat:66.1505955 },
        { lng:24.4978441	, lat:66.148936 },
        { lng:24.4980163	, lat:66.147159 },
        { lng:24.4985038	, lat:66.1437564 },
        { lng:24.4985079	, lat:66.1433775 },
        { lng:24.4986781	, lat:66.1421659 },
        { lng:24.4987835	, lat:66.1414899 },
        { lng:24.4991142	, lat:66.1383021 },
        { lng:24.4992391	, lat:66.1371956 },
        { lng:24.4992959	, lat:66.13671 },
        { lng:24.4994719	, lat:66.1352051 },
        { lng:24.4996866	, lat:66.1333687 },
        { lng:24.4997777	, lat:66.1325894 },
        { lng:24.4998761	, lat:66.1317474 },
        { lng:24.5000312	, lat:66.1303286 },
        { lng:24.5002354	, lat:66.1284598 },
        { lng:24.5003119	, lat:66.1282287 },
        { lng:24.5010867	, lat:66.120805 },
        { lng:24.5011282	, lat:66.120494 },
        { lng:24.5011861	, lat:66.1199962 },
        { lng:24.5016403	, lat:66.1160925 },
        { lng:24.501774	, lat:66.1150265 },
        { lng:24.5017975	, lat:66.1148393 },
        { lng:24.5018059	, lat:66.1147722 },
        { lng:24.5021848	, lat:66.1106653 },
        { lng:24.5025154	, lat:66.1073678 },
        { lng:24.5025567	, lat:66.1069673 },
        { lng:24.5027822	, lat:66.104778 },
        { lng:24.5028284	, lat:66.1041127 },
        { lng:24.5029507	, lat:66.1027596 },
        { lng:24.502959	, lat:66.1026683 },
        { lng:24.5031555	, lat:66.1004951 },
        { lng:24.5031733	, lat:66.100298 },
        { lng:24.5032983	, lat:66.0989545 },
        { lng:24.503382	, lat:66.0980359 },
        { lng:24.5034628	, lat:66.0971487 },
        { lng:24.503504	, lat:66.0966961 },
        { lng:24.5036517	, lat:66.0950741 },
        { lng:24.503666	, lat:66.094917 },
        { lng:24.5037922	, lat:66.0935308 },
        { lng:24.5039553	, lat:66.091883 },
        { lng:24.5040298	, lat:66.0910196 },
        { lng:24.504084	, lat:66.0903923 },
        { lng:24.504147	, lat:66.0897597 },
        { lng:24.5042094	, lat:66.0891324 },
        { lng:24.5042208	, lat:66.0888201 },
        { lng:24.5042536	, lat:66.0885106 },
        { lng:24.5042763	, lat:66.0882082 },
        { lng:24.5043165	, lat:66.0878997 },
        { lng:24.504335	, lat:66.0876051 },
        { lng:24.5043533	, lat:66.0874024 },
        { lng:24.5044608	, lat:66.0860206 },
        { lng:24.5046282	, lat:66.0841596 },
        { lng:24.5047675	, lat:66.0825755 },
        { lng:24.5048836	, lat:66.0814351 },
        { lng:24.5050879	, lat:66.079428 },
        { lng:24.5054384	, lat:66.0756377 },
        { lng:24.5056118	, lat:66.073594 },
        { lng:24.5057629	, lat:66.0715382 },
        { lng:24.5058121	, lat:66.0711415 },
        { lng:24.5059021	, lat:66.0702288 },
        { lng:24.5060079	, lat:66.0690637 },
        { lng:24.5060772	, lat:66.0689276 },
        { lng:24.5062796	, lat:66.0663278 },
        { lng:24.5064038	, lat:66.0650446 },
        { lng:24.5067427	, lat:66.0621129 },
        { lng:24.5067604	, lat:66.0619596 },
        { lng:24.5069365	, lat:66.060026 },
        { lng:24.5072952	, lat:66.0558384 },
        { lng:24.5073321	, lat:66.0556799 },
        { lng:24.5073101	, lat:66.0556795 },
        { lng:24.5073252	, lat:66.0554241 },
        { lng:24.5076669	, lat:66.051717 },
        { lng:24.5077181	, lat:66.0503116 },
        { lng:24.5079533	, lat:66.048208 },
        { lng:24.5079579	, lat:66.0481273 },
        { lng:24.5080315	, lat:66.0472759 },
        { lng:24.5080616	, lat:66.0469174 },
        { lng:24.5080917	, lat:66.0465589 },
        { lng:24.5081218	, lat:66.0462004 },
        { lng:24.5081883	, lat:66.0456414 },
        { lng:24.5082045	, lat:66.0455214 },
        { lng:24.5082649	, lat:66.0446443 },
        { lng:24.5083041	, lat:66.0441327 },
        { lng:24.50836	, lat:66.0435936 },
        { lng:24.5085338	, lat:66.0418025 },
        { lng:24.5085724	, lat:66.0411085 },
        { lng:24.5086422	, lat:66.0403442 },
        { lng:24.5088415	, lat:66.0380922 },
        { lng:24.5090869	, lat:66.0356512 },
        { lng:24.5092224	, lat:66.0341312 },
        { lng:24.5092832	, lat:66.0334055 },
        { lng:24.5094548	, lat:66.0318533 },
        { lng:24.5095563	, lat:66.0304404 },
        { lng:24.5095752	, lat:66.0303457 },
        { lng:24.5096536	, lat:66.0295971 },
        { lng:24.5096982	, lat:66.0291503 },
        { lng:24.5098183	, lat:66.0279978 },
        { lng:24.5098914	, lat:66.0272385 },
        { lng:24.5099574	, lat:66.0265518 },
        { lng:24.506099	, lat:66.0263054 },
        { lng:24.5061155	, lat:66.0261686 },
        { lng:24.5064489	, lat:66.0258105 },
        { lng:24.5068189	, lat:66.0254132 },
        { lng:24.5070518	, lat:66.025163 },
        { lng:24.5073835	, lat:66.0248067 },
        { lng:24.5101041	, lat:66.0247885 },
        { lng:24.5101693	, lat:66.0242079 },
        { lng:24.5102086	, lat:66.0239604 },
        { lng:24.5102463	, lat:66.0236313 },
        { lng:24.5104412	, lat:66.0212829 },
        { lng:24.5105338	, lat:66.0204941 },
        { lng:24.5108539	, lat:66.0172583 },
        { lng:24.5108629	, lat:66.0171668 },
        { lng:24.5103626	, lat:66.0171684 },
        { lng:24.5100447	, lat:66.0168923 },
        { lng:24.5091459	, lat:66.0161118 },
        { lng:24.509342	, lat:66.0158287 },
        { lng:24.5110235	, lat:66.0154346 },
        { lng:24.5111717	, lat:66.0140275 },
        { lng:24.5112443	, lat:66.0133376 },
        { lng:24.511316	, lat:66.0126567 },
        { lng:24.5113812	, lat:66.0119711 },
        { lng:24.5114392	, lat:66.0113618 },
        { lng:24.5114664	, lat:66.011075 },
        { lng:24.5116056	, lat:66.0096114 },
        { lng:24.5124116	, lat:66.0015407 },
        { lng:24.5128992	, lat:65.9966555 },
        { lng:24.5134488	, lat:65.9910889 },
        { lng:24.5136629	, lat:65.9887366 },
        { lng:24.5138733	, lat:65.9864245 },
        { lng:24.5139671	, lat:65.9853938 },
        { lng:24.5140924	, lat:65.9841394 },
        { lng:24.514266	, lat:65.9824012 },
        { lng:24.5145041	, lat:65.9800175 },
        { lng:24.5145328	, lat:65.9792289 },
        { lng:24.5146549	, lat:65.9777769 },
        { lng:24.5147227	, lat:65.9769703 },
        { lng:24.5148927	, lat:65.9712861 },
        { lng:24.5153384	, lat:65.9663333 },
        { lng:24.5153529	, lat:65.9661723 },
        { lng:24.5154019	, lat:65.9656277 },
        { lng:24.5154224	, lat:65.9653994 },
        { lng:24.5154619	, lat:65.96496 },
        { lng:24.5155753	, lat:65.9636998 },
        { lng:24.5157032	, lat:65.9622775 },
        { lng:24.5158437	, lat:65.9607151 },
        { lng:24.516256	, lat:65.9561299 },
        { lng:24.5162748	, lat:65.9559093 },
        { lng:24.5162972	, lat:65.955577 },
        { lng:24.5163864	, lat:65.9545795 },
        { lng:24.5164845	, lat:65.9534818 },
        { lng:24.516547	, lat:65.9527825 },
        { lng:24.5166165	, lat:65.9520051 },
        { lng:24.5167036	, lat:65.9510302 },
        { lng:24.517276	, lat:65.9446223 },
        { lng:24.517292	, lat:65.9443747 },
        { lng:24.5173246	, lat:65.9440789 },
        { lng:24.5173786	, lat:65.9434738 },
        { lng:24.5174207	, lat:65.9430028 },
        { lng:24.5175036	, lat:65.9420739 },
        { lng:24.5176466	, lat:65.9404732 },
        { lng:24.5176851	, lat:65.9400415 },
        { lng:24.5176886	, lat:65.9400025 },
        { lng:24.517768	, lat:65.939113 },
        { lng:24.5178082	, lat:65.938662 },
        { lng:24.5179099	, lat:65.9375234 },
        { lng:24.5180049	, lat:65.9364585 },
        { lng:24.5180434	, lat:65.9360273 },
        { lng:24.5180793	, lat:65.9356257 },
        { lng:24.5181616	, lat:65.9347035 },
        { lng:24.5182297	, lat:65.9339397 },
        { lng:24.5182875	, lat:65.9332921 },
        { lng:24.5182942	, lat:65.9332169 },
        { lng:24.5183473	, lat:65.9326227 },
        { lng:24.5184019	, lat:65.9320107 },
        { lng:24.5185387	, lat:65.9304771 },
        { lng:24.5186155	, lat:65.9296161 },
        { lng:24.5186345	, lat:65.9294039 },
        { lng:24.5187638	, lat:65.9279539 },
        { lng:24.5187844	, lat:65.9277234 },
        { lng:24.5188	, lat:65.9275488 },
        { lng:24.5189237	, lat:65.9261693 },
        { lng:24.5189335	, lat:65.9260517 },
        { lng:24.5190573	, lat:65.9251665 },
        { lng:24.5194441	, lat:65.9224015 },
        { lng:24.5195212	, lat:65.9218503 },
        { lng:24.5196749	, lat:65.9194862 },
        { lng:24.5196835	, lat:65.9193537 },
        { lng:24.5203673	, lat:65.9088305 },
        { lng:24.5203906	, lat:65.9084714 },
        { lng:24.5204986	, lat:65.9071924 },
        { lng:24.52057	, lat:65.9063472 },
        { lng:24.5206734	, lat:65.9051215 },
        { lng:24.5207924	, lat:65.9037122 },
        { lng:24.5209074	, lat:65.9023678 },
        { lng:24.5210279	, lat:65.9009297 },
        { lng:24.5211189	, lat:65.8998423 },
        { lng:24.5211723	, lat:65.8992094 },
        { lng:24.5212089	, lat:65.8987762 },
        { lng:24.5212612	, lat:65.8981562 },
        { lng:24.5214452	, lat:65.8959747 },
        { lng:24.5215798	, lat:65.8943793 },
        { lng:24.5216084	, lat:65.8940406 },
        { lng:24.5216392	, lat:65.8936754 },
        { lng:24.5216538	, lat:65.8936551 },
        { lng:24.5217355	, lat:65.8925999 },
        { lng:24.5217443	, lat:65.892593 },
        { lng:24.5219008	, lat:65.8907557 },
        { lng:24.5222393	, lat:65.8865572 },
        { lng:24.5222735	, lat:65.8862421 },
        { lng:24.522323	, lat:65.8857869 },
        { lng:24.5226075	, lat:65.8831677 },
        { lng:24.522611	, lat:65.8831359 },
        { lng:24.522659	, lat:65.8826938 },
        { lng:24.5227604	, lat:65.8817605 },
        { lng:24.5227981	, lat:65.8808111 },
        { lng:24.522855	, lat:65.8793768 },
        { lng:24.5229113	, lat:65.8779566 },
        { lng:24.5229134	, lat:65.8779032 },
        { lng:24.5229181	, lat:65.8777854 },
        { lng:24.5229325	, lat:65.8774226 },
        { lng:24.5229747	, lat:65.8763579 },
        { lng:24.5229952	, lat:65.8761878 },
        { lng:24.5230318	, lat:65.8749449 },
        { lng:24.523035	, lat:65.8748359 },
        { lng:24.523112	, lat:65.8736197 },
        { lng:24.5154686	, lat:65.873887 },
        { lng:24.5154712	, lat:65.8737205 },
        { lng:24.5154853	, lat:65.8728407 },
        { lng:24.5155023	, lat:65.8728404 },
        { lng:24.5155085	, lat:65.8726484 },
        { lng:24.5155442	, lat:65.8715416 },
        { lng:24.5155467	, lat:65.8714632 },
        { lng:24.5155778	, lat:65.8704996 },
        { lng:24.5155946	, lat:65.8699811 },
        { lng:24.5156088	, lat:65.8695389 },
        { lng:24.5156416	, lat:65.8685246 },
        { lng:24.5156488	, lat:65.8683014 },
        { lng:24.5159125	, lat:65.8676239 },
        { lng:24.5187497	, lat:65.8676182 },
        { lng:24.5204314	, lat:65.8676148 },
        { lng:24.5212296	, lat:65.8676132 },
        { lng:24.523053	, lat:65.8676077 },
        { lng:24.5244672	, lat:65.867659 },
        { lng:24.5239549	, lat:65.8668171 },
        { lng:24.5239097	, lat:65.8667232 },
        { lng:24.523877	, lat:65.8666108 },
        { lng:24.5239064	, lat:65.8664693 },
        { lng:24.5239829	, lat:65.8663512 },
        { lng:24.5240674	, lat:65.8662218 },
        { lng:24.5241845	, lat:65.86608 },
        { lng:24.5243489	, lat:65.8659694 },
        { lng:24.5245599	, lat:65.8658575 },
        { lng:24.5247075	, lat:65.8657312 },
        { lng:24.5248142	, lat:65.8656141 },
        { lng:24.524897	, lat:65.8655296 },
        { lng:24.524909	, lat:65.8654578 },
        { lng:24.5248762	, lat:65.8653491 },
        { lng:24.5248463	, lat:65.8652482 },
        { lng:24.5247588	, lat:65.8651431 },
        { lng:24.5246709	, lat:65.8650189 },
        { lng:24.5245777	, lat:65.8649037 },
        { lng:24.524381	, lat:65.8647037 },
        { lng:24.5241167	, lat:65.8645353 },
        { lng:24.5239328	, lat:65.8644104 },
        { lng:24.5238058	, lat:65.8642572 },
        { lng:24.5238047	, lat:65.8641012 },
        { lng:24.5238575	, lat:65.8639058 },
        { lng:24.5239779	, lat:65.8636753 },
        { lng:24.5240764	, lat:65.8634483 },
        { lng:24.5242292	, lat:65.8631986 },
        { lng:24.524373	, lat:65.8630286 },
        { lng:24.5244967	, lat:65.8628251 },
        { lng:24.5245919	, lat:65.8626822 },
        { lng:24.5246704	, lat:65.8625315 },
        { lng:24.5248439	, lat:65.862348 },
        { lng:24.5249434	, lat:65.862158 },
        { lng:24.5250285	, lat:65.8619411 },
        { lng:24.5250794	, lat:65.8617838 },
        { lng:24.5251392	, lat:65.8616523 },
        { lng:24.5251582	, lat:65.8615322 },
        { lng:24.5251467	, lat:65.8614009 },
        { lng:24.5250215	, lat:65.8612096 },
        { lng:24.5248322	, lat:65.8609736 },
        { lng:24.5245906	, lat:65.8607244 },
        { lng:24.5242563	, lat:65.8604935 },
        { lng:24.5240721	, lat:65.8603517 },
        { lng:24.5240455	, lat:65.860268 },
        { lng:24.5241298	, lat:65.8602109 },
        { lng:24.5241557	, lat:65.8601615 },
        { lng:24.5241481	, lat:65.8600954 },
        { lng:24.5240847	, lat:65.8599981 },
        { lng:24.5239605	, lat:65.8598855 },
        { lng:24.5237757	, lat:65.8597598 },
        { lng:24.5236275	, lat:65.8596674 },
        { lng:24.5235036	, lat:65.8595627 },
        { lng:24.5233094	, lat:65.8594011 },
        { lng:24.5232347	, lat:65.8592905 },
        { lng:24.5230482	, lat:65.8591995 },
        { lng:24.5228894	, lat:65.8591173 },
        { lng:24.5227669	, lat:65.8590552 },
        { lng:24.5226879	, lat:65.8589827 },
        { lng:24.5226353	, lat:65.858882 },
        { lng:24.5225002	, lat:65.8587684 },
        { lng:24.5223943	, lat:65.8587106 },
        { lng:24.5222057	, lat:65.8586398 },
        { lng:24.522047	, lat:65.8585588 },
        { lng:24.5219069	, lat:65.8584608 },
        { lng:24.5217412	, lat:65.858335 },
        { lng:24.5216384	, lat:65.8582032 },
        { lng:24.5214926	, lat:65.8580974 },
        { lng:24.5213751	, lat:65.8580218 },
        { lng:24.5213376	, lat:65.8579625 },
        { lng:24.5213111	, lat:65.8579055 },
        { lng:24.5211997	, lat:65.8578489 },
        { lng:24.5210107	, lat:65.8577613 },
        { lng:24.5207553	, lat:65.8576561 },
        { lng:24.5206102	, lat:65.8575761 },
        { lng:24.5202446	, lat:65.8574513 },
        { lng:24.5198291	, lat:65.8573065 },
        { lng:24.5195662	, lat:65.8572249 },
        { lng:24.5191888	, lat:65.8571585 },
        { lng:24.5188645	, lat:65.8571243 },
        { lng:24.5185542	, lat:65.8571035 },
        { lng:24.5183757	, lat:65.8570909 },
        { lng:24.5182276	, lat:65.8570838 },
        { lng:24.5180441	, lat:65.8570859 },
        { lng:24.5177774	, lat:65.8570581 },
        { lng:24.5175597	, lat:65.8570178 },
        { lng:24.5173342	, lat:65.8569898 },
        { lng:24.5171579	, lat:65.8569604 },
        { lng:24.5170446	, lat:65.8569296 },
        { lng:24.5169918	, lat:65.8569041 },
        { lng:24.5167994	, lat:65.8568849 },
        { lng:24.5166043	, lat:65.8568669 },
        { lng:24.5164085	, lat:65.8568252 },
        { lng:24.5162864	, lat:65.8567776 },
        { lng:24.5162079	, lat:65.8567198 },
        { lng:24.516175	, lat:65.8566313 },
        { lng:24.5162062	, lat:65.8564932 },
        { lng:24.5162821	, lat:65.8563785 },
        { lng:24.5163456	, lat:65.8562212 },
        { lng:24.5164443	, lat:65.8560502 },
        { lng:24.516586	, lat:65.8558521 },
        { lng:24.5167258	, lat:65.8556809 },
        { lng:24.5169616	, lat:65.8555149 },
        { lng:24.5171493	, lat:65.8553872 },
        { lng:24.5172177	, lat:65.855296 },
        { lng:24.517277	, lat:65.8550938 },
        { lng:24.5173514	, lat:65.8549342 },
        { lng:24.5173391	, lat:65.8547179 },
        { lng:24.5173104	, lat:65.8545038 },
        { lng:24.5172507	, lat:65.8543483 },
        { lng:24.5171803	, lat:65.8541995 },
        { lng:24.5170879	, lat:65.8540497 },
        { lng:24.5169786	, lat:65.8538843 },
        { lng:24.516833	, lat:65.8537864 },
        { lng:24.5166393	, lat:65.8537223 },
        { lng:24.5163426	, lat:65.8536084 },
        { lng:24.5159879	, lat:65.8534812 },
        { lng:24.5156942	, lat:65.8533751 },
        { lng:24.5154759	, lat:65.8533134 },
        { lng:24.5152485	, lat:65.8532271 },
        { lng:24.5150775	, lat:65.8531024 },
        { lng:24.5149378	, lat:65.8530146 },
        { lng:24.5147897	, lat:65.8529211 },
        { lng:24.5146996	, lat:65.8528454 },
        { lng:24.5146388	, lat:65.8527403 },
        { lng:24.5145982	, lat:65.8526698 },
        { lng:24.5145897	, lat:65.8525723 },
        { lng:24.5145975	, lat:65.8524725 },
        { lng:24.5145719	, lat:65.8523571 },
        { lng:24.514546	, lat:65.8522294 },
        { lng:24.5145683	, lat:65.8521541 },
        { lng:24.514596	, lat:65.8520789 },
        { lng:24.5147673	, lat:65.8519513 },
        { lng:24.5149538	, lat:65.8517843 },
        { lng:24.5151038	, lat:65.8515907 },
        { lng:24.5152143	, lat:65.8514466 },
        { lng:24.5152796	, lat:65.8513431 },
        { lng:24.5153785	, lat:65.8511811 },
        { lng:24.5154881	, lat:65.8510067 },
        { lng:24.5156212	, lat:65.8508008 },
        { lng:24.5156986	, lat:65.8506468 },
        { lng:24.5157597	, lat:65.8504154 },
        { lng:24.5158422	, lat:65.8501627 },
        { lng:24.5157939	, lat:65.8499331 },
        { lng:24.5157211	, lat:65.8497103 },
        { lng:24.5156589	, lat:65.8494751 },
        { lng:24.5156532	, lat:65.8492935 },
        { lng:24.5156992	, lat:65.8491038 },
        { lng:24.515782	, lat:65.8488634 },
        { lng:24.5158093	, lat:65.8486872 },
        { lng:24.5159026	, lat:65.8485173 },
        { lng:24.5159524	, lat:65.8483612 },
        { lng:24.5160024	, lat:65.8482073 },
        { lng:24.5161078	, lat:65.8480778 },
        { lng:24.5162226	, lat:65.8478956 },
        { lng:24.5163734	, lat:65.8477277 },
        { lng:24.5166104	, lat:65.8475157 },
        { lng:24.5169232	, lat:65.8472741 },
        { lng:24.5171782	, lat:65.8471101 },
        { lng:24.5171967	, lat:65.8470013 },
        { lng:24.5171861	, lat:65.8469262 },
        { lng:24.5171443	, lat:65.8468177 },
        { lng:24.5171339	, lat:65.8467854 },
        { lng:24.5170439	, lat:65.8466466 },
        { lng:24.5168869	, lat:65.8465358 },
        { lng:24.5166718	, lat:65.8464294 },
        { lng:24.5164282	, lat:65.8463466 },
        { lng:24.5162331	, lat:65.846268 },
        { lng:24.516077	, lat:65.8462087 },
        { lng:24.5159392	, lat:65.8461378 },
        { lng:24.515841	, lat:65.8460664 },
        { lng:24.5157431	, lat:65.8460112 },
        { lng:24.5156555	, lat:65.8459838 },
        { lng:24.5156162	, lat:65.8459457 },
        { lng:24.5155265	, lat:65.8458454 },
        { lng:24.5154272	, lat:65.8457657 },
        { lng:24.5154195	, lat:65.8457595 },
        { lng:24.5153097	, lat:65.8456314 },
        { lng:24.5152699	, lat:65.8455488 },
        { lng:24.5152577	, lat:65.8455221 },
        { lng:24.5152173	, lat:65.8454562 },
        { lng:24.5151726	, lat:65.845342 },
        { lng:24.5151477	, lat:65.8452468 },
        { lng:24.5150806	, lat:65.8451149 },
        { lng:24.5150093	, lat:65.8449381 },
        { lng:24.5149232	, lat:65.8448151 },
        { lng:24.5148314	, lat:65.8446811 },
        { lng:24.5147584	, lat:65.8445356 },
        { lng:24.5146603	, lat:65.8443758 },
        { lng:24.5145278	, lat:65.8442554 },
        { lng:24.5145128	, lat:65.8442457 },
        { lng:24.5143373	, lat:65.8441874 },
        { lng:24.5139379	, lat:65.8440914 },
        { lng:24.5134717	, lat:65.8440105 },
        { lng:24.5130477	, lat:65.843928 },
        { lng:24.5127072	, lat:65.8438757 },
        { lng:24.5124456	, lat:65.8438472 },
        { lng:24.5119287	, lat:65.843782 },
        { lng:24.511522	, lat:65.8436818 },
        { lng:24.5110308	, lat:65.8435952 },
        { lng:24.5105921	, lat:65.8435218 },
        { lng:24.510231	, lat:65.843416 },
        { lng:24.5100027	, lat:65.8433519 },
        { lng:24.5099867	, lat:65.8433514 },
        { lng:24.5098611	, lat:65.843361 },
        { lng:24.5097027	, lat:65.8433752 },
        { lng:24.5094186	, lat:65.8433969 },
        { lng:24.5092186	, lat:65.8433934 },
        { lng:24.5090271	, lat:65.8433989 },
        { lng:24.5089092	, lat:65.8433917 },
        { lng:24.5087824	, lat:65.8433654 },
        { lng:24.5086035	, lat:65.8433383 },
        { lng:24.5084498	, lat:65.8433256 },
        { lng:24.5081724	, lat:65.8432979 },
        { lng:24.5079119	, lat:65.8432846 },
        { lng:24.5076947	, lat:65.8432588 },
        { lng:24.5074938	, lat:65.8432273 },
        { lng:24.5073218	, lat:65.8431542 },
        { lng:24.5072214	, lat:65.8430941 },
        { lng:24.5070132	, lat:65.8430044 },
        { lng:24.5068855	, lat:65.842949 },
        { lng:24.5067408	, lat:65.8428746 },
        { lng:24.5065632	, lat:65.8427992 },
        { lng:24.5063976	, lat:65.8426678 },
        { lng:24.5062996	, lat:65.8425987 },
        { lng:24.5061726	, lat:65.842476 },
        { lng:24.5060441	, lat:65.8423937 },
        { lng:24.5058986	, lat:65.8422958 },
        { lng:24.5057513	, lat:65.8422259 },
        { lng:24.5055291	, lat:65.8421238 },
        { lng:24.5053569	, lat:65.8420451 },
        { lng:24.5051101	, lat:65.8419443 },
        { lng:24.5048512	, lat:65.8418088 },
        { lng:24.5045706	, lat:65.8416779 },
        { lng:24.5041943	, lat:65.8415531 },
        { lng:24.5037991	, lat:65.841434 },
        { lng:24.5034106	, lat:65.841353 },
        { lng:24.5032261	, lat:65.8413232 },
        { lng:24.502973	, lat:65.8412824 },
        { lng:24.5025306	, lat:65.8412308 },
        { lng:24.5021134	, lat:65.8411981 },
        { lng:24.5017351	, lat:65.8411821 },
        { lng:24.5013551	, lat:65.841202 },
        { lng:24.5010266	, lat:65.8412025 },
        { lng:24.5006867	, lat:65.8411908 },
        { lng:24.5001529	, lat:65.8411935 },
        { lng:24.4998139	, lat:65.8412098 },
        { lng:24.4991965	, lat:65.8412533 },
        { lng:24.4987922	, lat:65.8412856 },
        { lng:24.4983169	, lat:65.8413206 },
        { lng:24.4977287	, lat:65.841337 },
        { lng:24.4973929	, lat:65.8413656 },
        { lng:24.496989	, lat:65.8414114 },
        { lng:24.4968844	, lat:65.8414301 },
        { lng:24.4966923	, lat:65.8414678 },
        { lng:24.4963297	, lat:65.8415156 },
        { lng:24.495994	, lat:65.8415521 },
        { lng:24.4958441	, lat:65.841558 },
        { lng:24.4958217	, lat:65.8415028 },
        { lng:24.4957813	, lat:65.8414029 },
        { lng:24.4954273	, lat:65.8406539 },
        { lng:24.495378	, lat:65.8405497 },
        { lng:24.494932	, lat:65.8396061 },
        { lng:24.4932316	, lat:65.8379492 },
        { lng:24.4928896	, lat:65.8376158 },
        { lng:24.4924346	, lat:65.8371794 },
        { lng:24.4928404	, lat:65.836438 },
        { lng:24.4929655	, lat:65.8361161 },
        { lng:24.493132	, lat:65.8356754 },
        { lng:24.4943932	, lat:65.8348014 },
        { lng:24.4949583	, lat:65.8343791 },
        { lng:24.4960782	, lat:65.8343442 },
        { lng:24.4973318	, lat:65.8331213 },
        { lng:24.4974439	, lat:65.8330119 },
        { lng:24.4971602	, lat:65.8323827 },
        { lng:24.4953088	, lat:65.8321563 },
        { lng:24.4951384	, lat:65.8314979 },
        { lng:24.4951821	, lat:65.830901 },
        { lng:24.495453	, lat:65.8302389 },
        { lng:24.4956886	, lat:65.8297101 },
        { lng:24.4958923	, lat:65.8293557 },
        { lng:24.4959926	, lat:65.8291811 },
        { lng:24.4963881	, lat:65.828632 },
        { lng:24.4968717	, lat:65.8277998 },
        { lng:24.496707	, lat:65.8276396 },
        { lng:24.4958743	, lat:65.8268746 },
        { lng:24.4957862	, lat:65.8267928 },
        { lng:24.4957849	, lat:65.8267916 },
        { lng:24.4947974	, lat:65.8258755 },
        { lng:24.4934027	, lat:65.8245814 },
        { lng:24.4924988	, lat:65.8237426 },
        { lng:24.4920304	, lat:65.823308 },
        { lng:24.4898037	, lat:65.8213484 },
        { lng:24.4877852	, lat:65.8194492 },
        { lng:24.4870853	, lat:65.8197107 },
        { lng:24.4861998	, lat:65.8203595 },
        { lng:24.484455	, lat:65.8216991 },
        { lng:24.4829905	, lat:65.8217671 },
        { lng:24.4806514	, lat:65.8218874 },
        { lng:24.480362	, lat:65.8208106 },
        { lng:24.4802723	, lat:65.8204909 },
        { lng:24.4799013	, lat:65.8202343 },
        { lng:24.4788734	, lat:65.8195235 },
        { lng:24.4790099	, lat:65.8193763 },
        { lng:24.4790679	, lat:65.8192257 },
        { lng:24.4791311	, lat:65.8191181 },
        { lng:24.4792196	, lat:65.8189639 },
        { lng:24.4792779	, lat:65.8188313 },
        { lng:24.4793582	, lat:65.8187039 },
        { lng:24.4795566	, lat:65.8183864 },
        { lng:24.479866	, lat:65.8180574 },
        { lng:24.4802607	, lat:65.8172447 },
        { lng:24.48041	, lat:65.8169371 },
        { lng:24.4806812	, lat:65.8161457 },
        { lng:24.480692	, lat:65.8161143 },
        { lng:24.4808794	, lat:65.8155674 },
        { lng:24.4808976	, lat:65.8155143 },
        { lng:24.4815359	, lat:65.8152353 },
        { lng:24.4826182	, lat:65.8147623 },
        { lng:24.4831472	, lat:65.8145311 },
        { lng:24.4818238	, lat:65.8127217 },
        { lng:24.4807334	, lat:65.8112306 },
        { lng:24.479049	, lat:65.8106492 },
        { lng:24.4776196	, lat:65.810476 },
        { lng:24.4745166	, lat:65.809636 },
        { lng:24.4730271	, lat:65.8099637 },
        { lng:24.4711319	, lat:65.8103805 },
        { lng:24.4709726	, lat:65.8104156 },
        { lng:24.4698483	, lat:65.8102466 },
        { lng:24.4683734	, lat:65.8100248 },
        { lng:24.4672321	, lat:65.8098533 },
        { lng:24.4650893	, lat:65.809531 },
        { lng:24.4647834	, lat:65.808845 },
        { lng:24.4647474	, lat:65.8087649 },
        { lng:24.4647276	, lat:65.8085605 },
        { lng:24.4642877	, lat:65.8081244 },
        { lng:24.4644604	, lat:65.8080852 },
        { lng:24.464429	, lat:65.8079829 },
        { lng:24.464259	, lat:65.8074302 },
        { lng:24.4613219	, lat:65.8075525 },
        { lng:24.4607817	, lat:65.8075785 },
        { lng:24.4606087	, lat:65.8075855 },
        { lng:24.4599202	, lat:65.80761 },
        { lng:24.459456	, lat:65.8073909 },
        { lng:24.4593639	, lat:65.8073441 },
        { lng:24.4592992	, lat:65.8073137 },
        { lng:24.4559633	, lat:65.8056947 },
        { lng:24.4554325	, lat:65.8054384 },
        { lng:24.4548998	, lat:65.8051812 },
        { lng:24.4546836	, lat:65.8050935 },
        { lng:24.4516936	, lat:65.8036361 },
        { lng:24.4501437	, lat:65.8028836 },
        { lng:24.4498233	, lat:65.8027277 },
        { lng:24.4498955	, lat:65.8026674 },
        { lng:24.4500209	, lat:65.8025495 },
        { lng:24.4501384	, lat:65.8024483 },
        { lng:24.4503942	, lat:65.8022074 },
        { lng:24.4504377	, lat:65.8021674 },
        { lng:24.4508261	, lat:65.8018422 },
        { lng:24.4510047	, lat:65.8017467 },
        { lng:24.451272	, lat:65.8016645 },
        { lng:24.4515034	, lat:65.8015876 },
        { lng:24.4517487	, lat:65.8015229 },
        { lng:24.4519097	, lat:65.8014589 },
        { lng:24.4521398	, lat:65.8013802 },
        { lng:24.4523385	, lat:65.8012862 },
        { lng:24.4525477	, lat:65.8012057 },
        { lng:24.4527201	, lat:65.8011234 },
        { lng:24.4531949	, lat:65.8008754 },
        { lng:24.4535408	, lat:65.8006766 },
        { lng:24.453677	, lat:65.8005897 },
        { lng:24.4539915	, lat:65.8004381 },
        { lng:24.4542841	, lat:65.8003149 },
        { lng:24.4543662	, lat:65.8002905 },
        { lng:24.4566834	, lat:65.8003568 },
        { lng:24.4576014	, lat:65.8007215 },
        { lng:24.4586671	, lat:65.8011068 },
        { lng:24.4589679	, lat:65.8012467 },
        { lng:24.4600702	, lat:65.8016642 },
        { lng:24.4604062	, lat:65.8017663 },
        { lng:24.4608798	, lat:65.8018957 },
        { lng:24.4617539	, lat:65.8021736 },
        { lng:24.4618708	, lat:65.8022097 },
        { lng:24.4620432	, lat:65.8022592 },
        { lng:24.4620564	, lat:65.802263 },
        { lng:24.4622183	, lat:65.8023221 },
        { lng:24.4624189	, lat:65.8024035 },
        { lng:24.4632122	, lat:65.8026544 },
        { lng:24.4637934	, lat:65.8028563 },
        { lng:24.4642728	, lat:65.8029883 },
        { lng:24.4646404	, lat:65.8030791 },
        { lng:24.465046	, lat:65.8031663 },
        { lng:24.4655284	, lat:65.8032612 },
        { lng:24.4659533	, lat:65.8033133 },
        { lng:24.4668491	, lat:65.8034061 },
        { lng:24.4674823	, lat:65.8034667 },
        { lng:24.4679579	, lat:65.8034943 },
        { lng:24.4685493	, lat:65.8035185 },
        { lng:24.468799	, lat:65.8035177 },
        { lng:24.4690064	, lat:65.8035171 },
        { lng:24.4695289	, lat:65.8034968 },
        { lng:24.4694418	, lat:65.8033288 },
        { lng:24.469428	, lat:65.8033023 },
        { lng:24.46899	, lat:65.8024579 },
        { lng:24.4684286	, lat:65.8014116 },
        { lng:24.4680346	, lat:65.8003233 },
        { lng:24.4678706	, lat:65.7999108 },
        { lng:24.4658411	, lat:65.7996311 },
        { lng:24.4656923	, lat:65.7993688 },
        { lng:24.4646309	, lat:65.7992995 },
        { lng:24.4643259	, lat:65.7992775 },
        { lng:24.4641306	, lat:65.7992633 },
        { lng:24.464046	, lat:65.7992572 },
        { lng:24.4634966	, lat:65.7991964 },
        { lng:24.4629908	, lat:65.7991268 },
        { lng:24.4624519	, lat:65.7990262 },
        { lng:24.4620835	, lat:65.7989268 },
        { lng:24.4617268	, lat:65.7987872 },
        { lng:24.4614792	, lat:65.7986606 },
        { lng:24.4614129	, lat:65.7986004 },
        { lng:24.4611923	, lat:65.7984552 },
        { lng:24.4610212	, lat:65.7983424 },
        { lng:24.4608578	, lat:65.7981892 },
        { lng:24.460647	, lat:65.7979767 },
        { lng:24.4603566	, lat:65.7977364 },
        { lng:24.4601463	, lat:65.797553 },
        { lng:24.459957	, lat:65.7973214 },
        { lng:24.4598345	, lat:65.7971659 },
        { lng:24.4597457	, lat:65.7970208 },
        { lng:24.4597003	, lat:65.7969644 },
        { lng:24.4593825	, lat:65.7967118 },
        { lng:24.4592028	, lat:65.7965687 },
        { lng:24.4588932	, lat:65.7963206 },
        { lng:24.4585289	, lat:65.7960626 },
        { lng:24.4581478	, lat:65.795781 },
        { lng:24.4578765	, lat:65.7955316 },
        { lng:24.4576431	, lat:65.7952687 },
        { lng:24.457542	, lat:65.7950862 },
        { lng:24.4574785	, lat:65.7948666 },
        { lng:24.457338	, lat:65.794609 },
        { lng:24.4572532	, lat:65.7944209 },
        { lng:24.4571955	, lat:65.7942226 },
        { lng:24.4570719	, lat:65.7939941 },
        { lng:24.4569144	, lat:65.7938342 },
        { lng:24.4568474	, lat:65.7937761 },
        { lng:24.4565243	, lat:65.7935313 },
        { lng:24.4560965	, lat:65.7933138 },
        { lng:24.4559141	, lat:65.7932226 },
        { lng:24.4556826	, lat:65.7931642 },
        { lng:24.4552904	, lat:65.7929922 },
        { lng:24.4550003	, lat:65.7928531 },
        { lng:24.4548976	, lat:65.7928159 },
        { lng:24.4545738	, lat:65.7927128 },
        { lng:24.4542289	, lat:65.7925674 },
        { lng:24.4538351	, lat:65.7924269 },
        { lng:24.4534963	, lat:65.7922949 },
        { lng:24.453156	, lat:65.7921315 },
        { lng:24.4525518	, lat:65.7919231 },
        { lng:24.452236	, lat:65.7916989 },
        { lng:24.4520014	, lat:65.7914606 },
        { lng:24.4518939	, lat:65.7912572 },
        { lng:24.4518802	, lat:65.7910777 },
        { lng:24.4519728	, lat:65.7908369 },
        { lng:24.4520469	, lat:65.790549 },
        { lng:24.4521979	, lat:65.790274 },
        { lng:24.4523473	, lat:65.7899631 },
        { lng:24.4525017	, lat:65.7896432 },
        { lng:24.4518306	, lat:65.7894016 },
        { lng:24.4522913	, lat:65.7877911 },
        { lng:24.4520533	, lat:65.7870029 },
        { lng:24.4525901	, lat:65.7862199 },
        { lng:24.4532366	, lat:65.7861474 },
        { lng:24.4522053	, lat:65.7844608 },
        { lng:24.4521223	, lat:65.784325 },
        { lng:24.4513838	, lat:65.7831705 },
        { lng:24.4494934	, lat:65.7823841 },
        { lng:24.4503651	, lat:65.7816243 },
        { lng:24.449193	, lat:65.7797968 },
        { lng:24.4490143	, lat:65.7796219 },
        { lng:24.4489526	, lat:65.7795615 },
        { lng:24.4471847	, lat:65.7778791 },
        { lng:24.4450287	, lat:65.7757066 },
        { lng:24.444645	, lat:65.7753401 },
        { lng:24.4444182	, lat:65.7751204 },
        { lng:24.4423987	, lat:65.7732201 },
        { lng:24.4423035	, lat:65.7731285 },
        { lng:24.4399691	, lat:65.7708482 },
        { lng:24.4361928	, lat:65.7671606 },
        { lng:24.4327866	, lat:65.7653181 },
        { lng:24.4325125	, lat:65.7651697 },
        { lng:24.4021583	, lat:65.7487347 },
        { lng:24.4017941	, lat:65.7485321 },
        { lng:24.3536506	, lat:65.7215723 },
        { lng:24.3403154	, lat:65.7140922 },
        { lng:24.3067203	, lat:65.6952235 },
        { lng:24.2948308	, lat:65.6885374 },
        { lng:24.2904904	, lat:65.6860955 },
        { lng:24.2860861	, lat:65.6836349 },
        { lng:24.2794432	, lat:65.6799223 },
        { lng:24.1843603	, lat:65.626636 },
        { lng:24.1596949	, lat:65.6127677 },
        { lng:24.1616052	, lat:65.6192806 },
        { lng:24.1776819	, lat:65.6603563 },
        { lng:24.1648788	, lat:65.6954892 },
        { lng:24.1657752	, lat:65.6990923 },
        { lng:24.1659578	, lat:65.699864 },
        { lng:24.1660922	, lat:65.7004088 },
        { lng:24.16635	, lat:65.7015682 },
        { lng:24.1664583	, lat:65.7020373 },
        { lng:24.1692184	, lat:65.7132571 },
        { lng:24.1721721	, lat:65.7252822 },
        { lng:24.1539276	, lat:65.7462361 },
        { lng:24.1430066	, lat:65.7587705 },
        { lng:24.1318042	, lat:65.7716088 },
        { lng:24.1275482	, lat:65.7783754 },
        { lng:24.1280223	, lat:65.7846053 },
        { lng:24.1297554	, lat:65.7872508 },
        { lng:24.1311632	, lat:65.7893997 },
        { lng:24.1337567	, lat:65.7966734 },
        { lng:24.1362347	, lat:65.7981824 },
        { lng:24.143478	, lat:65.8025918 },
        { lng:24.1495139	, lat:65.8091707 },
        { lng:24.1541661	, lat:65.8142388 },
        { lng:24.1553226	, lat:65.8154981 },
        { lng:24.1507874	, lat:65.8277011 },
        { lng:24.1507301	, lat:65.827853 },
        { lng:24.1506682	, lat:65.8280216 },
        { lng:24.1490117	, lat:65.8324759 },
        { lng:24.1462059	, lat:65.8379212 },
        { lng:24.1455812	, lat:65.8391332 },
        { lng:24.145421	, lat:65.839444 },
        { lng:24.1453437	, lat:65.8395903 },
        { lng:24.1450791	, lat:65.8400905 },
        { lng:24.1446794	, lat:65.8408464 },
        { lng:24.144611	, lat:65.8409757 },
        { lng:24.1443491	, lat:65.8411755 },
        { lng:24.1441515	, lat:65.841329 },
        { lng:24.14339	, lat:65.8419071 },
        { lng:24.1433521	, lat:65.841936 },
        { lng:24.1425235	, lat:65.842568 },
        { lng:24.1413476	, lat:65.8434649 },
        { lng:24.1411823	, lat:65.8435909 },
        { lng:24.1402894	, lat:65.844272 },
        { lng:24.1400688	, lat:65.8444402 },
        { lng:24.1385314	, lat:65.8456125 },
        { lng:24.1354157	, lat:65.8467156 },
        { lng:24.1279259	, lat:65.8493668 },
        { lng:24.1255915	, lat:65.8511723 },
        { lng:24.1251937	, lat:65.8514798 },
        { lng:24.1255865	, lat:65.8574495 },
        { lng:24.1255545	, lat:65.8577731 },
        { lng:24.1263449	, lat:65.8586442 },
        { lng:24.1265069	, lat:65.8589692 },
        { lng:24.1265539	, lat:65.8590639 },
        { lng:24.1265862	, lat:65.8591284 },
        { lng:24.1265876	, lat:65.8591316 },
        { lng:24.1266738	, lat:65.8593041 },
        { lng:24.1282817	, lat:65.8601382 },
        { lng:24.1295345	, lat:65.8609865 },
        { lng:24.1308773	, lat:65.8622821 },
        { lng:24.1311059	, lat:65.8628568 },
        { lng:24.1316265	, lat:65.8631371 },
        { lng:24.1334953	, lat:65.8631686 },
        { lng:24.1349843	, lat:65.8630373 },
        { lng:24.1363202	, lat:65.8629482 },
        { lng:24.1401152	, lat:65.8626982 },
        { lng:24.1440687	, lat:65.8621932 },
        { lng:24.1449287	, lat:65.8622294 },
        { lng:24.152978	, lat:65.8625719 },
        { lng:24.1526453	, lat:65.8629122 },
        { lng:24.1515971	, lat:65.8638121 },
        { lng:24.1497946	, lat:65.8660315 },
        { lng:24.1487432	, lat:65.8671544 },
        { lng:24.1478635	, lat:65.8681896 },
        { lng:24.1458035	, lat:65.870102 },
        { lng:24.1453314	, lat:65.8704529 },
        { lng:24.1418338	, lat:65.8726809 },
        { lng:24.1410614	, lat:65.8734089 },
        { lng:24.1405464	, lat:65.8741719 },
        { lng:24.1385294	, lat:65.8767153 },
        { lng:24.1381002	, lat:65.8773642 },
        { lng:24.1377354	, lat:65.8780833 },
        { lng:24.1376067	, lat:65.8786358 },
        { lng:24.1372419	, lat:65.8798634 },
        { lng:24.1365767	, lat:65.8811962 },
        { lng:24.1360832	, lat:65.8820467 },
        { lng:24.1351391	, lat:65.8831515 },
        { lng:24.1342807	, lat:65.8845981 },
        { lng:24.1341091	, lat:65.8855186 },
        { lng:24.1339374	, lat:65.8861498 },
        { lng:24.1333366	, lat:65.8873157 },
        { lng:24.1328002	, lat:65.8879995 },
        { lng:24.1316415	, lat:65.8890163 },
        { lng:24.1305589	, lat:65.8897469 },
        { lng:24.1294742	, lat:65.8903487 },
        { lng:24.1283155	, lat:65.8908833 },
        { lng:24.1245604	, lat:65.8921542 },
        { lng:24.1232944	, lat:65.8925223 },
        { lng:24.1202045	, lat:65.8936879 },
        { lng:24.1184021	, lat:65.894503 },
        { lng:24.1180158	, lat:65.8948711 },
        { lng:24.1175652	, lat:65.8954144 },
        { lng:24.1168571	, lat:65.8967113 },
        { lng:24.1155697	, lat:65.8986654 },
        { lng:24.115162	, lat:65.899489 },
        { lng:24.1150761	, lat:65.8998833 },
        { lng:24.1150879	, lat:65.9009264 },
        { lng:24.1153551	, lat:65.9018109 },
        { lng:24.1153765	, lat:65.9023278 },
        { lng:24.1151405	, lat:65.9035718 },
        { lng:24.1148401	, lat:65.9043077 },
        { lng:24.11453	, lat:65.9049765 },
        { lng:24.1137361	, lat:65.9060978 },
        { lng:24.1128563	, lat:65.9072365 },
        { lng:24.1124701	, lat:65.907622 },
        { lng:24.111247	, lat:65.9089008 },
        { lng:24.1109681	, lat:65.9094088 },
        { lng:24.1106891	, lat:65.9097416 },
        { lng:24.1100239	, lat:65.9114845 },
        { lng:24.1099166	, lat:65.9133498 },
        { lng:24.1105389	, lat:65.914497 },
        { lng:24.1113543	, lat:65.9152589 },
        { lng:24.1124057	, lat:65.9159856 },
        { lng:24.1144013	, lat:65.9170014 },
        { lng:24.1154527	, lat:65.9177719 },
        { lng:24.1159248	, lat:65.9184285 },
        { lng:24.1161823	, lat:65.919199 },
        { lng:24.1164612	, lat:65.9196105 },
        { lng:24.1166973	, lat:65.9206436 },
        { lng:24.1167402	, lat:65.9217729 },
        { lng:24.1171264	, lat:65.9239177 },
        { lng:24.1174697	, lat:65.9248718 },
        { lng:24.1181778	, lat:65.9256858 },
        { lng:24.1183066	, lat:65.9260622 },
        { lng:24.1181993	, lat:65.9264473 },
        { lng:24.1179203	, lat:65.9267887 },
        { lng:24.1169548	, lat:65.9275152 },
        { lng:24.1152596	, lat:65.9283291 },
        { lng:24.1142296	, lat:65.9287405 },
        { lng:24.1132855	, lat:65.9293444 },
        { lng:24.1125345	, lat:65.9299395 },
        { lng:24.111998	, lat:65.930412 },
        { lng:24.1111612	, lat:65.9308671 },
        { lng:24.1100883	, lat:65.9312434 },
        { lng:24.1085219	, lat:65.9316022 },
        { lng:24.1078352	, lat:65.9316547 },
        { lng:24.1070413	, lat:65.9315935 },
        { lng:24.1063117	, lat:65.9314534 },
        { lng:24.1055178	, lat:65.9312084 },
        { lng:24.1041445	, lat:65.9306658 },
        { lng:24.1026639	, lat:65.9300707 },
        { lng:24.1021919	, lat:65.929922 },
        { lng:24.1009902	, lat:65.9296857 },
        { lng:24.1001963	, lat:65.9295456 },
        { lng:24.099338	, lat:65.9295106 },
        { lng:24.0983939	, lat:65.9295456 },
        { lng:24.096055	, lat:65.9298082 },
        { lng:24.0926647	, lat:65.9302808 },
        { lng:24.0894031	, lat:65.9309546 },
        { lng:24.0885019	, lat:65.9312084 },
        { lng:24.0868496	, lat:65.9320398 },
        { lng:24.0857124	, lat:65.9326698 },
        { lng:24.0809273	, lat:65.9363536 },
        { lng:24.0803694	, lat:65.9368698 },
        { lng:24.0798544	, lat:65.9374647 },
        { lng:24.0774297	, lat:65.9408416 },
        { lng:24.0753054	, lat:65.9435096 },
        { lng:24.0743184	, lat:65.9444192 },
        { lng:24.0734815	, lat:65.9448566 },
        { lng:24.0724945	, lat:65.9452764 },
        { lng:24.0705847	, lat:65.9458187 },
        { lng:24.0692543	, lat:65.946116 },
        { lng:24.0682244	, lat:65.9462822 },
        { lng:24.0666151	, lat:65.9464659 },
        { lng:24.0658426	, lat:65.9465446 },
        { lng:24.0646839	, lat:65.9467807 },
        { lng:24.0639972	, lat:65.9471743 },
        { lng:24.0635681	, lat:65.9475591 },
        { lng:24.0626454	, lat:65.9485473 },
        { lng:24.0616154	, lat:65.9494568 },
        { lng:24.061079	, lat:65.9497979 },
        { lng:24.0600705	, lat:65.9501739 },
        { lng:24.0553283	, lat:65.9509696 },
        { lng:24.0544486	, lat:65.951197 },
        { lng:24.0536761	, lat:65.9515293 },
        { lng:24.0533113	, lat:65.951984 },
        { lng:24.0527319	, lat:65.9533917 },
        { lng:24.0512084	, lat:65.954712 },
        { lng:24.0508437	, lat:65.9554813 },
        { lng:24.0502643	, lat:65.9570113 },
        { lng:24.0488266	, lat:65.9582881 },
        { lng:24.0455007	, lat:65.9604381 },
        { lng:24.0433979	, lat:65.9620114 },
        { lng:24.042561	, lat:65.9628767 },
        { lng:24.0421962	, lat:65.9633924 },
        { lng:24.0420675	, lat:65.9639518 },
        { lng:24.0422821	, lat:65.9647209 },
        { lng:24.0430545	, lat:65.9660056 },
        { lng:24.0430974	, lat:65.966425 },
        { lng:24.0424752	, lat:65.9669494 },
        { lng:24.0407371	, lat:65.9679631 },
        { lng:24.0399217	, lat:65.9685123 },
        { lng:24.0397286	, lat:65.9689156 },
        { lng:24.0397715	, lat:65.9693612 },
        { lng:24.0399432	, lat:65.9696758 },
        { lng:24.0404581	, lat:65.9701214 },
        { lng:24.0413165	, lat:65.970567 },
        { lng:24.0454363	, lat:65.971834 },
        { lng:24.0477538	, lat:65.9722971 },
        { lng:24.0501999	, lat:65.9727164 },
        { lng:24.0508651	, lat:65.9729261 },
        { lng:24.0515732	, lat:65.9731707 },
        { lng:24.0522813	, lat:65.9735901 },
        { lng:24.0529036	, lat:65.97449 },
        { lng:24.0528607	, lat:65.9747695 },
        { lng:24.0527534	, lat:65.9750928 },
        { lng:24.0514659	, lat:65.9764468 },
        { lng:24.050157	, lat:65.9782026 },
        { lng:24.0496206	, lat:65.9793294 },
        { lng:24.0494275	, lat:65.9802902 },
        { lng:24.0497922	, lat:65.9840108 },
        { lng:24.0495562	, lat:65.9906384 },
        { lng:24.04917	, lat:65.9950296 },
        { lng:24.048655	, lat:65.9967405 },
        { lng:24.0474319	, lat:65.9991582 },
        { lng:24.0459728	, lat:66.0013139 },
        { lng:24.0450715	, lat:66.0022215 },
        { lng:24.0401792	, lat:66.0064624 },
        { lng:24.0392136	, lat:66.0074135 },
        { lng:24.0374326	, lat:66.0090363 },
        { lng:24.0353298	, lat:66.0105718 },
        { lng:24.0306091	, lat:66.0135029 },
        { lng:24.0236783	, lat:66.0173669 },
        { lng:24.0189361	, lat:66.0200443 },
        { lng:24.0169835	, lat:66.0210995 },
        { lng:24.0135288	, lat:66.0231836 },
        { lng:24.012134	, lat:66.0241776 },
        { lng:24.0097093	, lat:66.0262353 },
        { lng:24.0074992	, lat:66.0278307 },
        { lng:24.0035939	, lat:66.0304896 },
        { lng:23.9998817	, lat:66.0326775 },
        { lng:23.9964699	, lat:66.0351442 },
        { lng:23.9947533	, lat:66.0365996 },
        { lng:23.9935088	, lat:66.0379243 },
        { lng:23.9920711	, lat:66.039397 },
        { lng:23.991127	, lat:66.0402946 },
        { lng:23.9890456	, lat:66.0426385 },
        { lng:23.9872646	, lat:66.0444594 },
        { lng:23.9863204	, lat:66.0455572 },
        { lng:23.9853119	, lat:66.04662 },
        { lng:23.9850158	, lat:66.0475334 },
        { lng:23.9845609	, lat:66.0497299 },
        { lng:23.9840674	, lat:66.050418 },
        { lng:23.9831876	, lat:66.0512368 },
        { lng:23.982737	, lat:66.0516549 },
        { lng:23.9819431	, lat:66.0522733 },
        { lng:23.9794754	, lat:66.054468 },
        { lng:23.9769649	, lat:66.0564362 },
        { lng:23.9756345	, lat:66.057516 },
        { lng:23.973639	, lat:66.0588396 },
        { lng:23.9722228	, lat:66.0595971 },
        { lng:23.9671158	, lat:66.0616693 },
        { lng:23.9652919	, lat:66.0626706 },
        { lng:23.9641547	, lat:66.0633584 },
        { lng:23.9587473	, lat:66.0671452 },
        { lng:23.9578032	, lat:66.0680504 },
        { lng:23.957138	, lat:66.0688425 },
        { lng:23.9563226	, lat:66.0695475 },
        { lng:23.9549279	, lat:66.070357 },
        { lng:23.9529538	, lat:66.0710184 },
        { lng:23.9497351	, lat:66.0715145 },
        { lng:23.9466667	, lat:66.0723152 },
        { lng:23.9448642	, lat:66.0731419 },
        { lng:23.9423108	, lat:66.0741601 },
        { lng:23.9408946	, lat:66.0748041 },
        { lng:23.9394783	, lat:66.075683 },
        { lng:23.9383625	, lat:66.0767185 },
        { lng:23.9371395	, lat:66.078711 },
        { lng:23.9367489	, lat:66.0794758 },
        { lng:23.9365816	, lat:66.0803032 },
        { lng:23.9367532	, lat:66.0813037 },
        { lng:23.9375042	, lat:66.0834959 },
        { lng:23.9375472	, lat:66.0854879 },
        { lng:23.9370965	, lat:66.0870623 },
        { lng:23.9369463	, lat:66.0880016 },
        { lng:23.9364099	, lat:66.0899324 },
        { lng:23.9366674	, lat:66.0912108 },
        { lng:23.9370751	, lat:66.0922978 },
        { lng:23.9382123	, lat:66.0939935 },
        { lng:23.93862	, lat:66.0949848 },
        { lng:23.9387702	, lat:66.0958543 },
        { lng:23.9387702	, lat:66.0974106 },
        { lng:23.9391994	, lat:66.0986364 },
        { lng:23.93965	, lat:66.0993754 },
        { lng:23.9408087	, lat:66.1004795 },
        { lng:23.9441132	, lat:66.1019834 },
        { lng:23.9446067	, lat:66.1022876 },
        { lng:23.9451646	, lat:66.1031308 },
        { lng:23.945465	, lat:66.1045128 },
        { lng:23.9453363	, lat:66.1064945 },
        { lng:23.9451432	, lat:66.1073375 },
        { lng:23.9447569	, lat:66.1079632 },
        { lng:23.9441132	, lat:66.1085455 },
        { lng:23.9432334	, lat:66.109093 },
        { lng:23.9418172	, lat:66.1097969 },
        { lng:23.9407443	, lat:66.1102053 },
        { lng:23.9399719	, lat:66.1104313 },
        { lng:23.938148	, lat:66.1108484 },
        { lng:23.9370965	, lat:66.1111699 },
        { lng:23.9365172	, lat:66.1114827 },
        { lng:23.9360666	, lat:66.1118824 },
        { lng:23.9357662	, lat:66.1124125 },
        { lng:23.9357018	, lat:66.1128382 },
        { lng:23.935852	, lat:66.1133335 },
        { lng:23.9364099	, lat:66.1144196 },
        { lng:23.9376974	, lat:66.1155143 },
        { lng:23.9391565	, lat:66.1166351 },
        { lng:23.939929	, lat:66.1173996 },
        { lng:23.9410448	, lat:66.1187374 },
        { lng:23.9413022	, lat:66.1192412 },
        { lng:23.9415383	, lat:66.1198927 },
        { lng:23.9412164	, lat:66.1204139 },
        { lng:23.9406156	, lat:66.1209612 },
        { lng:23.9378046	, lat:66.1227331 },
        { lng:23.9363884	, lat:66.1238535 },
        { lng:23.9336633	, lat:66.1258858 },
        { lng:23.9269041	, lat:66.1299758 },
        { lng:23.9247798	, lat:66.1310265 },
        { lng:23.9230203	, lat:66.1317732 },
        { lng:23.9188146	, lat:66.1334575 },
        { lng:23.9179563	, lat:66.1337788 },
        { lng:23.9159393	, lat:66.134673 },
        { lng:23.9147591	, lat:66.1353067 },
        { lng:23.91433	, lat:66.1356193 },
        { lng:23.914051	, lat:66.1361228 },
        { lng:23.9139866	, lat:66.136661 },
        { lng:23.9140081	, lat:66.1378242 },
        { lng:23.9142227	, lat:66.1386401 },
        { lng:23.9143514	, lat:66.1389873 },
        { lng:23.9148235	, lat:66.1394821 },
        { lng:23.9155316	, lat:66.1399421 },
        { lng:23.9161109	, lat:66.1402112 },
        { lng:23.9177632	, lat:66.1408274 },
        { lng:23.9194798	, lat:66.1413395 },
        { lng:23.9209175	, lat:66.1417735 },
        { lng:23.9218831	, lat:66.1421814 },
        { lng:23.9235814	, lat:66.1431083 },
        { lng:23.9256381	, lat:66.1439605 },
        { lng:23.9265179	, lat:66.1444205 },
        { lng:23.9271831	, lat:66.1449932 },
        { lng:23.9275908	, lat:66.1454792 },
        { lng:23.9279556	, lat:66.1463209 },
        { lng:23.9280414	, lat:66.1471019 },
        { lng:23.9276981	, lat:66.1475965 },
        { lng:23.9275908	, lat:66.1483687 },
        { lng:23.9277625	, lat:66.148577 },
        { lng:23.9283847	, lat:66.1488286 },
        { lng:23.9295434	, lat:66.1493232 },
        { lng:23.9299082	, lat:66.1495487 },
        { lng:23.9300155	, lat:66.1497036 },
        { lng:23.9298803	, lat:66.1499852 },
        { lng:23.9295864	, lat:66.1503383 },
        { lng:23.9288568	, lat:66.1507721 },
        { lng:23.9280629	, lat:66.151605 },
        { lng:23.9258956	, lat:66.1543897 },
        { lng:23.9215397	, lat:66.1590737 },
        { lng:23.9208102	, lat:66.1597849 },
        { lng:23.9192867	, lat:66.1608863 },
        { lng:23.9181494	, lat:66.1614587 },
        { lng:23.9170551	, lat:66.1618663 },
        { lng:23.9159178	, lat:66.1621611 },
        { lng:23.9085793	, lat:66.1632798 },
        { lng:23.9072489	, lat:66.1633925 },
        { lng:23.9063477	, lat:66.1634272 },
        { lng:23.9055752	, lat:66.1634186 },
        { lng:23.9045023	, lat:66.1635486 },
        { lng:23.903644	, lat:66.1637741 },
        { lng:23.9016914	, lat:66.1643811 },
        { lng:23.9000606	, lat:66.1646499 },
        { lng:23.8987731	, lat:66.16491 },
        { lng:23.8977217	, lat:66.1650835 },
        { lng:23.8964557	, lat:66.1655083 },
        { lng:23.8959836	, lat:66.1657771 },
        { lng:23.894782	, lat:66.1667569 },
        { lng:23.8934516	, lat:66.1672338 },
        { lng:23.8928616	, lat:66.1674371 },
        { lng:23.8918627	, lat:66.1676674 },
      ],
    },
    {
      id: 11,
      regionName: 'eCom TP  Kokkola kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:22.287872	, lat:64.0093865 },
        { lng:22.3648102	, lat:63.9991894 },
        { lng:22.4498642	, lat:63.987901 },
        { lng:22.5284035	, lat:63.9774402 },
        { lng:22.6269286	, lat:63.9642368 },
        { lng:22.7490957	, lat:63.9476101 },
        { lng:22.7707529	, lat:63.9284332 },
        { lng:22.7754736	, lat:63.9245287 },
        { lng:22.7797222	, lat:63.9204727 },
        { lng:22.7834988	, lat:63.9171897 },
        { lng:22.7974463	, lat:63.9050546 },
        { lng:22.8149064	, lat:63.8896999 },
        { lng:22.8162528	, lat:63.8885298 },
        { lng:22.8160482	, lat:63.8884593 },
        { lng:22.8158891	, lat:63.8884046 },
        { lng:22.8155565	, lat:63.8883536 },
        { lng:22.8153656	, lat:63.8884514 },
        { lng:22.8150565	, lat:63.8885474 },
        { lng:22.8147841	, lat:63.8885236 },
        { lng:22.814548	, lat:63.8884481 },
        { lng:22.8143978	, lat:63.8883348 },
        { lng:22.8142262	, lat:63.888297 },
        { lng:22.814076	, lat:63.8884198 },
        { lng:22.8140971	, lat:63.8885614 },
        { lng:22.8138012	, lat:63.8891577 },
        { lng:22.8133173	, lat:63.889322 },
        { lng:22.8128463	, lat:63.8898825 },
        { lng:22.8124881	, lat:63.8904312 },
        { lng:22.8125525	, lat:63.8905729 },
        { lng:22.8126704	, lat:63.8906469 },
        { lng:22.8123379	, lat:63.8908519 },
        { lng:22.8118496	, lat:63.890993 },
        { lng:22.8114367	, lat:63.890979 },
        { lng:22.8105784	, lat:63.8910545 },
        { lng:22.8102994	, lat:63.8909695 },
        { lng:22.8099561	, lat:63.8907523 },
        { lng:22.8098273	, lat:63.8905162 },
        { lng:22.8099775	, lat:63.890384 },
        { lng:22.8098853	, lat:63.8901909 },
        { lng:22.8097844	, lat:63.8898835 },
        { lng:22.8101492	, lat:63.8894397 },
        { lng:22.8101063	, lat:63.8892603 },
        { lng:22.8104432	, lat:63.8890199 },
        { lng:22.8106213	, lat:63.8888258 },
        { lng:22.8107071	, lat:63.8886181 },
        { lng:22.8108358	, lat:63.8884575 },
        { lng:22.8112006	, lat:63.8883064 },
        { lng:22.8113723	, lat:63.887872 },
        { lng:22.811265	, lat:63.8877492 },
        { lng:22.8114581	, lat:63.8875037 },
        { lng:22.8115654	, lat:63.8874565 },
        { lng:22.8114152	, lat:63.8873809 },
        { lng:22.8109861	, lat:63.8872865 },
        { lng:22.8108358	, lat:63.8873998 },
        { lng:22.8102565	, lat:63.8874565 },
        { lng:22.810235	, lat:63.8873053 },
        { lng:22.8101213	, lat:63.8869517 },
        { lng:22.8102607	, lat:63.8867565 },
        { lng:22.8106824	, lat:63.8867519 },
        { lng:22.8111996	, lat:63.8868374 },
        { lng:22.8111298	, lat:63.8870367 },
        { lng:22.8114581	, lat:63.8870881 },
        { lng:22.8115869	, lat:63.8869842 },
        { lng:22.812016	, lat:63.8868709 },
        { lng:22.8118873	, lat:63.8867953 },
        { lng:22.8114108	, lat:63.8867173 },
        { lng:22.8118873	, lat:63.8866065 },
        { lng:22.8121018	, lat:63.8865309 },
        { lng:22.8123593	, lat:63.8864553 },
        { lng:22.8125739	, lat:63.8865215 },
        { lng:22.8124452	, lat:63.8867009 },
        { lng:22.8125739	, lat:63.8867481 },
        { lng:22.8128529	, lat:63.8866348 },
        { lng:22.813046	, lat:63.8866537 },
        { lng:22.8133035	, lat:63.8867103 },
        { lng:22.8134108	, lat:63.8865403 },
        { lng:22.8127885	, lat:63.8864365 },
        { lng:22.8127241	, lat:63.8863609 },
        { lng:22.8131104	, lat:63.8862476 },
        { lng:22.8134108	, lat:63.8861059 },
        { lng:22.8137755	, lat:63.8860303 },
        { lng:22.8141618	, lat:63.8859831 },
        { lng:22.8145212	, lat:63.8859028 },
        { lng:22.8149493	, lat:63.8860544 },
        { lng:22.8152132	, lat:63.8862098 },
        { lng:22.8154278	, lat:63.8861626 },
        { lng:22.8155136	, lat:63.8860209 },
        { lng:22.8156209	, lat:63.8859548 },
        { lng:22.8158934	, lat:63.88596 },
        { lng:22.8160071	, lat:63.8858981 },
        { lng:22.816093	, lat:63.8857092 },
        { lng:22.8163719	, lat:63.8855486 },
        { lng:22.8163505	, lat:63.8853975 },
        { lng:22.8164792	, lat:63.8852936 },
        { lng:22.8167582	, lat:63.8851708 },
        { lng:22.8171444	, lat:63.8851425 },
        { lng:22.8175242	, lat:63.8852705 },
        { lng:22.8178032	, lat:63.8853838 },
        { lng:22.8179813	, lat:63.8852936 },
        { lng:22.8179534	, lat:63.8851666 },
        { lng:22.8179813	, lat:63.8849631 },
        { lng:22.8181529	, lat:63.884878 },
        { lng:22.8184533	, lat:63.8848875 },
        { lng:22.8187537	, lat:63.8849631 },
        { lng:22.8190971	, lat:63.8849819 },
        { lng:22.8190112	, lat:63.8850764 },
        { lng:22.8188396	, lat:63.8851897 },
        { lng:22.8189898	, lat:63.8852747 },
        { lng:22.8189898	, lat:63.8853786 },
        { lng:22.8192902	, lat:63.8853786 },
        { lng:22.8193533	, lat:63.8853116 },
        { lng:22.8255699	, lat:63.8787251 },
        { lng:22.8256202	, lat:63.8786719 },
        { lng:22.8300405	, lat:63.8781995 },
        { lng:22.8317164	, lat:63.8778937 },
        { lng:22.8320375	, lat:63.8756009 },
        { lng:22.8325178	, lat:63.8722702 },
        { lng:22.8327012	, lat:63.8712359 },
        { lng:22.8330134	, lat:63.8709048 },
        { lng:22.8346361	, lat:63.8691848 },
        { lng:22.8350519	, lat:63.8692211 },
        { lng:22.8355336	, lat:63.8690341 },
        { lng:22.8359842	, lat:63.8689017 },
        { lng:22.836113	, lat:63.8687978 },
        { lng:22.836407	, lat:63.8687746 },
        { lng:22.8366065	, lat:63.86876 },
        { lng:22.8367567	, lat:63.8686466 },
        { lng:22.8367782	, lat:63.8684954 },
        { lng:22.8369069	, lat:63.8682969 },
        { lng:22.8370571	, lat:63.8681362 },
        { lng:22.837143	, lat:63.8678716 },
        { lng:22.8373146	, lat:63.8674936 },
        { lng:22.8372717	, lat:63.8672479 },
        { lng:22.8374434	, lat:63.8670683 },
        { lng:22.8375077	, lat:63.8668604 },
        { lng:22.8376794	, lat:63.8667375 },
        { lng:22.8380442	, lat:63.8667091 },
        { lng:22.8382373	, lat:63.8666146 },
        { lng:22.8389894	, lat:63.8665149 },
        { lng:22.8392673	, lat:63.8663783 },
        { lng:22.8394389	, lat:63.8662271 },
        { lng:22.8396685	, lat:63.8662985 },
        { lng:22.8397973	, lat:63.8663741 },
        { lng:22.8399754	, lat:63.86635 },
        { lng:22.8401041	, lat:63.8661704 },
        { lng:22.8402972	, lat:63.8660381 },
        { lng:22.8402758	, lat:63.8659152 },
        { lng:22.8399754	, lat:63.8659625 },
        { lng:22.8398252	, lat:63.8658207 },
        { lng:22.8401256	, lat:63.8657545 },
        { lng:22.8401685	, lat:63.8655939 },
        { lng:22.8403616	, lat:63.8655372 },
        { lng:22.8403831	, lat:63.8654615 },
        { lng:22.8402329	, lat:63.8653859 },
        { lng:22.8400612	, lat:63.8652158 },
        { lng:22.8393746	, lat:63.8651307 },
        { lng:22.8392887	, lat:63.8650457 },
        { lng:22.8390956	, lat:63.8650173 },
        { lng:22.8388596	, lat:63.8650929 },
        { lng:22.8387104	, lat:63.8651114 },
        { lng:22.838848	, lat:63.8649724 },
        { lng:22.8431586	, lat:63.8606195 },
        { lng:22.8483009	, lat:63.8554315 },
        { lng:22.8617334	, lat:63.8423711 },
        { lng:22.8660018	, lat:63.8382131 },
        { lng:22.8671622	, lat:63.8370827 },
        { lng:22.8704959	, lat:63.8345777 },
        { lng:22.8707886	, lat:63.8343578 },
        { lng:22.8712556	, lat:63.834053 },
        { lng:22.871325	, lat:63.8340077 },
        { lng:22.8712392	, lat:63.83379 },
        { lng:22.871325	, lat:63.8336292 },
        { lng:22.8711748	, lat:63.8335724 },
        { lng:22.8713036	, lat:63.8334115 },
        { lng:22.8716469	, lat:63.8333169 },
        { lng:22.8720267	, lat:63.8331896 },
        { lng:22.8721404	, lat:63.833052 },
        { lng:22.8722692	, lat:63.8324842 },
        { lng:22.8721404	, lat:63.8320678 },
        { lng:22.8719902	, lat:63.8319448 },
        { lng:22.8716662	, lat:63.8318071 },
        { lng:22.8714752	, lat:63.8316988 },
        { lng:22.8712392	, lat:63.831642 },
        { lng:22.8710032	, lat:63.8312351 },
        { lng:22.8710675	, lat:63.8305821 },
        { lng:22.8714538	, lat:63.8302793 },
        { lng:22.8717477	, lat:63.8300763 },
        { lng:22.8719044	, lat:63.8299291 },
        { lng:22.8719044	, lat:63.8297777 },
        { lng:22.8718186	, lat:63.8295884 },
        { lng:22.8717113	, lat:63.8294276 },
        { lng:22.8715396	, lat:63.8293613 },
        { lng:22.8714538	, lat:63.8292572 },
        { lng:22.8708959	, lat:63.8291436 },
        { lng:22.8712562	, lat:63.828733 },
        { lng:22.8802697	, lat:63.8194741 },
        { lng:22.8798426	, lat:63.8193326 },
        { lng:22.8806162	, lat:63.8186178 },
        { lng:22.8809102	, lat:63.8188218 },
        { lng:22.8811312	, lat:63.8186084 },
        { lng:22.8810453	, lat:63.8185137 },
        { lng:22.8808308	, lat:63.8184474 },
        { lng:22.8816247	, lat:63.8180687 },
        { lng:22.8867992	, lat:63.8125481 },
        { lng:22.8840097	, lat:63.8107564 },
        { lng:22.8837779	, lat:63.810618 },
        { lng:22.8804875	, lat:63.8085143 },
        { lng:22.8788073	, lat:63.807525 },
        { lng:22.8778052	, lat:63.8043373 },
        { lng:22.8719688	, lat:63.8024996 },
        { lng:22.8639007	, lat:63.7907888 },
        { lng:22.8598881	, lat:63.7847135 },
        { lng:22.8830195	, lat:63.7823247 },
        { lng:22.8833199	, lat:63.7820972 },
        { lng:22.8832984	, lat:63.7820024 },
        { lng:22.8830409	, lat:63.7819929 },
        { lng:22.8831053	, lat:63.7818697 },
        { lng:22.8833413	, lat:63.7818697 },
        { lng:22.8834057	, lat:63.7816517 },
        { lng:22.8833842	, lat:63.781519 },
        { lng:22.8835344	, lat:63.7814052 },
        { lng:22.8840065	, lat:63.7813294 },
        { lng:22.884264	, lat:63.7812156 },
        { lng:22.8843284	, lat:63.7811587 },
        { lng:22.884543	, lat:63.7811777 },
        { lng:22.8849013	, lat:63.7814009 },
        { lng:22.8848798	, lat:63.7815336 },
        { lng:22.8851223	, lat:63.7815284 },
        { lng:22.885309	, lat:63.7814199 },
        { lng:22.8856373	, lat:63.7813104 },
        { lng:22.8859377	, lat:63.7812535 },
        { lng:22.8860664	, lat:63.7811682 },
        { lng:22.8862166	, lat:63.7811872 },
        { lng:22.8862531	, lat:63.7812682 },
        { lng:22.8864312	, lat:63.7812914 },
        { lng:22.8863454	, lat:63.781519 },
        { lng:22.8865964	, lat:63.7816569 },
        { lng:22.8867037	, lat:63.7817706 },
        { lng:22.886796	, lat:63.7819455 },
        { lng:22.9121375	, lat:63.7794238 },
        { lng:22.9190254	, lat:63.7787412 },
        { lng:22.9192615	, lat:63.778618 },
        { lng:22.9195554	, lat:63.7785094 },
        { lng:22.9198408	, lat:63.7782766 },
        { lng:22.9201198	, lat:63.7781913 },
        { lng:22.9203279	, lat:63.778225 },
        { lng:22.9204137	, lat:63.7783008 },
        { lng:22.9206133	, lat:63.7782766 },
        { lng:22.9239618	, lat:63.7789258 },
        { lng:22.9241045	, lat:63.778955 },
        { lng:22.9273403	, lat:63.77721 },
        { lng:22.9271901	, lat:63.7771479 },
        { lng:22.9255051	, lat:63.7765688 },
        { lng:22.9215378	, lat:63.7751097 },
        { lng:22.9221674	, lat:63.7747636 },
        { lng:22.9236987	, lat:63.7739514 },
        { lng:22.9279685	, lat:63.772781 },
        { lng:22.9260844	, lat:63.7714087 },
        { lng:22.9268986	, lat:63.7703095 },
        { lng:22.9272504	, lat:63.768786 },
        { lng:22.9304537	, lat:63.7673295 },
        { lng:22.9358959	, lat:63.771162 },
        { lng:22.9345762	, lat:63.7723998 },
        { lng:22.9328221	, lat:63.7734791 },
        { lng:22.9313562	, lat:63.7738677 },
        { lng:22.9313686	, lat:63.7743835 },
        { lng:22.9336162	, lat:63.7749474 },
        { lng:22.9333103	, lat:63.7753147 },
        { lng:22.9321806	, lat:63.7755396 },
        { lng:22.9313636	, lat:63.7754816 },
        { lng:22.9311655	, lat:63.7760552 },
        { lng:22.9305584	, lat:63.77672 },
        { lng:22.9315232	, lat:63.7771048 },
        { lng:22.9320134	, lat:63.7782972 },
        { lng:22.9335299	, lat:63.7785561 },
        { lng:22.9386011	, lat:63.779422 },
        { lng:22.94134	, lat:63.7798897 },
        { lng:22.9487589	, lat:63.7811564 },
        { lng:22.950529	, lat:63.7814586 },
        { lng:22.9539765	, lat:63.7820472 },
        { lng:22.9629223	, lat:63.7835745 },
        { lng:22.9662527	, lat:63.779694 },
        { lng:22.9829933	, lat:63.7825088 },
        { lng:22.9894197	, lat:63.7835919 },
        { lng:22.9904403	, lat:63.7847754 },
        { lng:22.9868364	, lat:63.785502 },
        { lng:22.9868472	, lat:63.7856537 },
        { lng:22.9886188	, lat:63.7879502 },
        { lng:23.0037978	, lat:63.7904327 },
        { lng:23.004692	, lat:63.7905789 },
        { lng:23.0050584	, lat:63.7906417 },
        { lng:23.0112948	, lat:63.7917102 },
        { lng:23.0297604	, lat:63.7883089 },
        { lng:23.0382596	, lat:63.7856485 },
        { lng:23.0386679	, lat:63.7855208 },
        { lng:23.0468756	, lat:63.7829513 },
        { lng:23.0537882	, lat:63.7807872 },
        { lng:23.055187	, lat:63.7803501 },
        { lng:23.0573415	, lat:63.7796766 },
        { lng:23.0614549	, lat:63.778388 },
        { lng:23.0737483	, lat:63.7738613 },
        { lng:23.0744151	, lat:63.7737263 },
        { lng:23.0860348	, lat:63.7694551 },
        { lng:23.0902782	, lat:63.7678617 },
        { lng:23.0901486	, lat:63.7677936 },
        { lng:23.1204887	, lat:63.7566375 },
        { lng:23.1247546	, lat:63.7550684 },
        { lng:23.1580335	, lat:63.7437239 },
        { lng:23.1663052	, lat:63.7409283 },
        { lng:23.1698911	, lat:63.7431139 },
        { lng:23.1740052	, lat:63.7425519 },
        { lng:23.1811283	, lat:63.7440699 },
        { lng:23.1809891	, lat:63.7443664 },
        { lng:23.1831309	, lat:63.7446443 },
        { lng:23.1864084	, lat:63.7404905 },
        { lng:23.189095	, lat:63.738745 },
        { lng:23.1806678	, lat:63.7380313 },
        { lng:23.1760941	, lat:63.7406827 },
        { lng:23.1704915	, lat:63.7418074 },
        { lng:23.1690938	, lat:63.7399602 },
        { lng:23.1738716	, lat:63.7383361 },
        { lng:23.1861576	, lat:63.7325962 },
        { lng:23.1904478	, lat:63.732867 },
        { lng:23.1950298	, lat:63.7284322 },
        { lng:23.1998956	, lat:63.7293017 },
        { lng:23.2014433	, lat:63.7284715 },
        { lng:23.2057394	, lat:63.7267835 },
        { lng:23.2134817	, lat:63.7221098 },
        { lng:23.2149029	, lat:63.7230362 },
        { lng:23.2241087	, lat:63.7187646 },
        { lng:23.2300789	, lat:63.7186549 },
        { lng:23.2355873	, lat:63.7170998 },
        { lng:23.2344155	, lat:63.7166055 },
        { lng:23.2371617	, lat:63.7154001 },
        { lng:23.2357725	, lat:63.7140211 },
        { lng:23.2399769	, lat:63.7135804 },
        { lng:23.2409871	, lat:63.7154195 },
        { lng:23.2375794	, lat:63.7155925 },
        { lng:23.2390097	, lat:63.716046 },
        { lng:23.2418164	, lat:63.7167848 },
        { lng:23.2483967	, lat:63.7169215 },
        { lng:23.2482998	, lat:63.7185134 },
        { lng:23.2472888	, lat:63.7198538 },
        { lng:23.2457093	, lat:63.7203551 },
        { lng:23.2456453	, lat:63.7204768 },
        { lng:23.2489941	, lat:63.721857 },
        { lng:23.2499258	, lat:63.7214456 },
        { lng:23.2599698	, lat:63.7302427 },
        { lng:23.2609481	, lat:63.7300683 },
        { lng:23.2620871	, lat:63.7318764 },
        { lng:23.2633192	, lat:63.7324515 },
        { lng:23.262993	, lat:63.7329262 },
        { lng:23.2650192	, lat:63.7369126 },
        { lng:23.265585	, lat:63.7414782 },
        { lng:23.2751541	, lat:63.7459584 },
        { lng:23.2783094	, lat:63.7442993 },
        { lng:23.2808502	, lat:63.7444179 },
        { lng:23.2838188	, lat:63.7461148 },
        { lng:23.281916	, lat:63.7491026 },
        { lng:23.2854039	, lat:63.7507132 },
        { lng:23.2917805	, lat:63.7508627 },
        { lng:23.2934966	, lat:63.7509576 },
        { lng:23.29444	, lat:63.7512547 },
        { lng:23.2944154	, lat:63.7512748 },
        { lng:23.2944002	, lat:63.751287 },
        { lng:23.2943622	, lat:63.7513183 },
        { lng:23.292656	, lat:63.7527121 },
        { lng:23.2916184	, lat:63.7536109 },
        { lng:23.297486	, lat:63.754563 },
        { lng:23.3000254	, lat:63.7545653 },
        { lng:23.301508	, lat:63.7539248 },
        { lng:23.3034099	, lat:63.7544373 },
        { lng:23.3036279	, lat:63.7549667 },
        { lng:23.3029415	, lat:63.7556067 },
        { lng:23.3182966	, lat:63.7582297 },
        { lng:23.3234635	, lat:63.7622085 },
        { lng:23.3263061	, lat:63.7622379 },
        { lng:23.3276583	, lat:63.7598292 },
        { lng:23.3363559	, lat:63.7612771 },
        { lng:23.336934	, lat:63.7612531 },
        { lng:23.3366316	, lat:63.7599599 },
        { lng:23.3369702	, lat:63.759962 },
        { lng:23.3377902	, lat:63.7599147 },
        { lng:23.347424	, lat:63.7587065 },
        { lng:23.3515691	, lat:63.7581901 },
        { lng:23.3610319	, lat:63.7570441 },
        { lng:23.3732147	, lat:63.7581075 },
        { lng:23.3725424	, lat:63.7596431 },
        { lng:23.364912	, lat:63.7589727 },
        { lng:23.3636073	, lat:63.7612142 },
        { lng:23.3689128	, lat:63.7623831 },
        { lng:23.374465	, lat:63.7600273 },
        { lng:23.3770058	, lat:63.7605909 },
        { lng:23.3803092	, lat:63.7587239 },
        { lng:23.3918587	, lat:63.7593006 },
        { lng:23.404825	, lat:63.7503613 },
        { lng:23.4211533	, lat:63.753259 },
        { lng:23.4382079	, lat:63.7415001 },
        { lng:23.4270649	, lat:63.7379683 },
        { lng:23.430054	, lat:63.7361312 },
        { lng:23.4393555	, lat:63.7407133 },
        { lng:23.4443406	, lat:63.7372216 },
        { lng:23.4481867	, lat:63.7375878 },
        { lng:23.452938	, lat:63.7399276 },
        { lng:23.4578612	, lat:63.7416011 },
        { lng:23.4578607	, lat:63.7417901 },
        { lng:23.4578088	, lat:63.7422301 },
        { lng:23.4557583	, lat:63.7433189 },
        { lng:23.4563685	, lat:63.7435362 },
        { lng:23.4577303	, lat:63.7428024 },
        { lng:23.4560374	, lat:63.7559633 },
        { lng:23.4587797	, lat:63.7565451 },
        { lng:23.455681	, lat:63.7586873 },
        { lng:23.4553326	, lat:63.7614141 },
        { lng:23.4776343	, lat:63.7791326 },
        { lng:23.4842819	, lat:63.7768225 },
        { lng:23.4875072	, lat:63.7755608 },
        { lng:23.5209563	, lat:63.7618158 },
        { lng:23.5310226	, lat:63.7577357 },
        { lng:23.5494606	, lat:63.7505048 },
        { lng:23.5663233	, lat:63.7442275 },
        { lng:23.5803573	, lat:63.7390821 },
        { lng:23.5978326	, lat:63.7296157 },
        { lng:23.6011892	, lat:63.7282847 },
        { lng:23.6186818	, lat:63.7214775 },
        { lng:23.6225572	, lat:63.7202443 },
        { lng:23.6778509	, lat:63.7021061 },
        { lng:23.6830443	, lat:63.7004427 },
        { lng:23.6863432	, lat:63.6992613 },
        { lng:23.7440812	, lat:63.6754369 },
        { lng:23.752742	, lat:63.6718843 },
        { lng:23.7659149	, lat:63.6675353 },
        { lng:23.7826808	, lat:63.6619228 },
        { lng:23.7840692	, lat:63.6614863 },
        { lng:23.8098388	, lat:63.6579394 },
        { lng:23.8247518	, lat:63.6558446 },
        { lng:23.8295369	, lat:63.6551971 },
        { lng:23.840653	, lat:63.6536216 },
        { lng:23.8586775	, lat:63.6497741 },
        { lng:23.8703923	, lat:63.6472734 },
        { lng:23.8750497	, lat:63.6463071 },
        { lng:23.878719	, lat:63.6454879 },
        { lng:23.886507	, lat:63.6438442 },
        { lng:23.8900904	, lat:63.6430631 },
        { lng:23.8938241	, lat:63.6422628 },
        { lng:23.8942532	, lat:63.6421676 },
        { lng:23.8973442	, lat:63.6415059 },
        { lng:23.9172709	, lat:63.6372395 },
        { lng:23.921527	, lat:63.6363323 },
        { lng:23.9229421	, lat:63.6360412 },
        { lng:23.9351816	, lat:63.6339186 },
        { lng:23.9361386	, lat:63.6335922 },
        { lng:23.9492289	, lat:63.6290325 },
        { lng:23.959653	, lat:63.6254242 },
        { lng:23.9484993	, lat:63.6235039 },
        { lng:23.9579611	, lat:63.6158331 },
        { lng:23.9616958	, lat:63.6128326 },
        { lng:23.9631098	, lat:63.6117821 },
        { lng:23.9696083	, lat:63.6069065 },
        { lng:23.9787965	, lat:63.6000093 },
        { lng:23.9898214	, lat:63.5916899 },
        { lng:23.9936248	, lat:63.5926862 },
        { lng:24.0098672	, lat:63.5876798 },
        { lng:24.0355949	, lat:63.5797182 },
        { lng:24.0380851	, lat:63.5805923 },
        { lng:24.0588336	, lat:63.5694145 },
        { lng:24.0599719	, lat:63.5698113 },
        { lng:24.058899	, lat:63.57451 },
        { lng:24.0620748	, lat:63.5764389 },
        { lng:24.068275	, lat:63.5754692 },
        { lng:24.0713016	, lat:63.5742426 },
        { lng:24.072589	, lat:63.5746819 },
        { lng:24.0731673	, lat:63.5744379 },
        { lng:24.0683608	, lat:63.5727476 },
        { lng:24.0710001	, lat:63.5710477 },
        { lng:24.073339	, lat:63.5707516 },
        { lng:24.0738969	, lat:63.5713724 },
        { lng:24.0742971	, lat:63.572431 },
        { lng:24.0837041	, lat:63.5754936 },
        { lng:24.0807	, lat:63.5771169 },
        { lng:24.0896264	, lat:63.5802486 },
        { lng:24.0989766	, lat:63.5770313 },
        { lng:24.1123265	, lat:63.5723895 },
        { lng:24.1299239	, lat:63.5662772 },
        { lng:24.1440184	, lat:63.561496 },
        { lng:24.1527227	, lat:63.5584682 },
        { lng:24.1583339	, lat:63.5565133 },
        { lng:24.1739969	, lat:63.5510609 },
        { lng:24.1912499	, lat:63.5449489 },
        { lng:24.2239933	, lat:63.5333841 },
        { lng:24.2251735	, lat:63.5345316 },
        { lng:24.2291646	, lat:63.5356983 },
        { lng:24.2321687	, lat:63.5352488 },
        { lng:24.2350869	, lat:63.5343222 },
        { lng:24.2364173	, lat:63.5350786 },
        { lng:24.237297	, lat:63.53719 },
        { lng:24.2443781	, lat:63.540632 },
        { lng:24.2426615	, lat:63.545173 },
        { lng:24.2435198	, lat:63.5501529 },
        { lng:24.24264	, lat:63.5513476 },
        { lng:24.246438	, lat:63.550325 },
        { lng:24.2493348	, lat:63.5482892 },
        { lng:24.2519526	, lat:63.5469701 },
        { lng:24.2522745	, lat:63.5460142 },
        { lng:24.2512231	, lat:63.5447715 },
        { lng:24.2550854	, lat:63.5428022 },
        { lng:24.2543988	, lat:63.542439 },
        { lng:24.2552357	, lat:63.5419036 },
        { lng:24.2508797	, lat:63.5413013 },
        { lng:24.2507725	, lat:63.5408806 },
        { lng:24.2500214	, lat:63.5407563 },
        { lng:24.2492704	, lat:63.5403548 },
        { lng:24.2490344	, lat:63.5400297 },
        { lng:24.2487769	, lat:63.5400106 },
        { lng:24.2485623	, lat:63.5400584 },
        { lng:24.2481975	, lat:63.5399819 },
        { lng:24.2473821	, lat:63.5399341 },
        { lng:24.2470603	, lat:63.5400106 },
        { lng:24.246717	, lat:63.5401444 },
        { lng:24.2463093	, lat:63.5402018 },
        { lng:24.2459016	, lat:63.5401827 },
        { lng:24.2456226	, lat:63.5401062 },
        { lng:24.2454724	, lat:63.540001 },
        { lng:24.2454295	, lat:63.5398576 },
        { lng:24.2455797	, lat:63.5396377 },
        { lng:24.245687	, lat:63.5393509 },
        { lng:24.2456655	, lat:63.539217 },
        { lng:24.245451	, lat:63.5390449 },
        { lng:24.2449574	, lat:63.5388154 },
        { lng:24.2443781	, lat:63.5386146 },
        { lng:24.2438845	, lat:63.5383947 },
        { lng:24.24367	, lat:63.5382704 },
        { lng:24.2434125	, lat:63.5379836 },
        { lng:24.2428546	, lat:63.5378593 },
        { lng:24.2424469	, lat:63.5376489 },
        { lng:24.2422752	, lat:63.5375055 },
        { lng:24.2421894	, lat:63.5373525 },
        { lng:24.2409663	, lat:63.5372282 },
        { lng:24.2402367	, lat:63.5372091 },
        { lng:24.2399149	, lat:63.5371804 },
        { lng:24.2396145	, lat:63.5371039 },
        { lng:24.2393999	, lat:63.5369413 },
        { lng:24.2393355	, lat:63.5367405 },
        { lng:24.2394213	, lat:63.536578 },
        { lng:24.2397432	, lat:63.5363102 },
        { lng:24.2400007	, lat:63.5360999 },
        { lng:24.2400007	, lat:63.5359564 },
        { lng:24.2398505	, lat:63.5357748 },
        { lng:24.2398505	, lat:63.5356122 },
        { lng:24.2399149	, lat:63.5354401 },
        { lng:24.2403226	, lat:63.5350767 },
        { lng:24.2405157	, lat:63.5348759 },
        { lng:24.2407946	, lat:63.5343595 },
        { lng:24.2409448	, lat:63.5341874 },
        { lng:24.2413096	, lat:63.5338813 },
        { lng:24.2415242	, lat:63.5337283 },
        { lng:24.2415671	, lat:63.533604 },
        { lng:24.2415242	, lat:63.5334701 },
        { lng:24.2411594	, lat:63.5332693 },
        { lng:24.2410521	, lat:63.5331641 },
        { lng:24.2410307	, lat:63.532992 },
        { lng:24.2408805	, lat:63.5327911 },
        { lng:24.2408161	, lat:63.5326094 },
        { lng:24.2407732	, lat:63.5324947 },
        { lng:24.2406874	, lat:63.5323704 },
        { lng:24.2403869	, lat:63.5323077 },
        { lng:24.2400651	, lat:63.5322843 },
        { lng:24.2392926	, lat:63.532313 },
        { lng:24.238563	, lat:63.5323608 },
        { lng:24.2378979	, lat:63.5323512 },
        { lng:24.2375331	, lat:63.5321791 },
        { lng:24.2381124	, lat:63.5319687 },
        { lng:24.2354946	, lat:63.5315096 },
        { lng:24.2331772	, lat:63.5325425 },
        { lng:24.2338853	, lat:63.5328868 },
        { lng:24.2334132	, lat:63.5328772 },
        { lng:24.2330913	, lat:63.5329155 },
        { lng:24.2327695	, lat:63.5330302 },
        { lng:24.2323618	, lat:63.5334128 },
        { lng:24.2320828	, lat:63.5335466 },
        { lng:24.2316108	, lat:63.5336901 },
        { lng:24.2312889	, lat:63.5337857 },
        { lng:24.230731	, lat:63.5342447 },
        { lng:24.2305164	, lat:63.5341969 },
        { lng:24.230216	, lat:63.533977 },
        { lng:24.2277055	, lat:63.533682 },
        { lng:24.2275982	, lat:63.5332607 },
        { lng:24.2273836	, lat:63.533078 },
        { lng:24.227684	, lat:63.5329824 },
        { lng:24.2285638	, lat:63.5322269 },
        { lng:24.2286925	, lat:63.5320261 },
        { lng:24.2289285	, lat:63.5320739 },
        { lng:24.2293362	, lat:63.5320261 },
        { lng:24.2325334	, lat:63.5305819 },
        { lng:24.2324476	, lat:63.5304098 },
        { lng:24.2442493	, lat:63.526249 },
        { lng:24.2669945	, lat:63.5182511 },
        { lng:24.275084	, lat:63.5153901 },
        { lng:24.2906419	, lat:63.5099212 },
        { lng:24.3100171	, lat:63.5030623 },
        { lng:24.3211547	, lat:63.4991135 },
        { lng:24.335424	, lat:63.5056331 },
        { lng:24.3411532	, lat:63.5031633 },
        { lng:24.3466893	, lat:63.5058245 },
        { lng:24.3578259	, lat:63.5111367 },
        { lng:24.3541781	, lat:63.5114238 },
        { lng:24.3501655	, lat:63.5108974 },
        { lng:24.3475047	, lat:63.5111463 },
        { lng:24.341003	, lat:63.5139025 },
        { lng:24.3400589	, lat:63.5139121 },
        { lng:24.339737	, lat:63.5138546 },
        { lng:24.339265	, lat:63.5137494 },
        { lng:24.3389216	, lat:63.5137302 },
        { lng:24.3385569	, lat:63.5138355 },
        { lng:24.3381706	, lat:63.5137972 },
        { lng:24.3379561	, lat:63.5138355 },
        { lng:24.3379346	, lat:63.5139503 },
        { lng:24.3337503	, lat:63.5117109 },
        { lng:24.327827	, lat:63.5122225 },
        { lng:24.3251029	, lat:63.514247 },
        { lng:24.3225494	, lat:63.5145054 },
        { lng:24.3217126	, lat:63.5141226 },
        { lng:24.3231073	, lat:63.5112611 },
        { lng:24.3208103	, lat:63.5112272 },
        { lng:24.3200174	, lat:63.5133379 },
        { lng:24.3196527	, lat:63.5133092 },
        { lng:24.319245	, lat:63.5133092 },
        { lng:24.318966	, lat:63.5131465 },
        { lng:24.3189231	, lat:63.5130029 },
        { lng:24.3187729	, lat:63.5128689 },
        { lng:24.3185143	, lat:63.5128254 },
        { lng:24.3183652	, lat:63.5126393 },
        { lng:24.3184296	, lat:63.5124479 },
        { lng:24.3187289	, lat:63.512366 },
        { lng:24.3188791	, lat:63.5121459 },
        { lng:24.3174844	, lat:63.5126149 },
        { lng:24.3173556	, lat:63.5130072 },
        { lng:24.314373	, lat:63.5141843 },
        { lng:24.3155757	, lat:63.5154432 },
        { lng:24.3146959	, lat:63.5158451 },
        { lng:24.3138591	, lat:63.5167637 },
        { lng:24.3158117	, lat:63.5179311 },
        { lng:24.3206612	, lat:63.515558 },
        { lng:24.3322483	, lat:63.5164001 },
        { lng:24.3360452	, lat:63.5153135 },
        { lng:24.3382554	, lat:63.5141269 },
        { lng:24.3409816	, lat:63.5139886 },
        { lng:24.3442206	, lat:63.5138015 },
        { lng:24.350895	, lat:63.5119215 },
        { lng:24.3501226	, lat:63.511577 },
        { lng:24.3498221	, lat:63.5114047 },
        { lng:24.3497782	, lat:63.5113229 },
        { lng:24.3500142	, lat:63.5112463 },
        { lng:24.3503586	, lat:63.511242 },
        { lng:24.350615	, lat:63.5111984 },
        { lng:24.3509798	, lat:63.5112654 },
        { lng:24.3510667	, lat:63.5114047 },
        { lng:24.3517104	, lat:63.5114717 },
        { lng:24.351925	, lat:63.5114334 },
        { lng:24.3520752	, lat:63.5114143 },
        { lng:24.3522683	, lat:63.511577 },
        { lng:24.3522683	, lat:63.5116444 },
        { lng:24.3525891	, lat:63.5117248 },
        { lng:24.3527833	, lat:63.5118066 },
        { lng:24.3532983	, lat:63.5117875 },
        { lng:24.3537704	, lat:63.5118545 },
        { lng:24.3538776	, lat:63.5120076 },
        { lng:24.3575029	, lat:63.5121172 },
        { lng:24.3586627	, lat:63.5115482 },
        { lng:24.366366	, lat:63.5152326 },
        { lng:24.3744985	, lat:63.5191366 },
        { lng:24.3876949	, lat:63.5254221 },
        { lng:24.3983165	, lat:63.5305202 },
        { lng:24.4044105	, lat:63.5334085 },
        { lng:24.4183365	, lat:63.5272778 },
        { lng:24.4300524	, lat:63.5221217 },
        { lng:24.4467454	, lat:63.5147681 },
        { lng:24.4583111	, lat:63.5208536 },
        { lng:24.4668941	, lat:63.5254105 },
        { lng:24.4927302	, lat:63.5389554 },
        { lng:24.4382695	, lat:63.5599415 },
        { lng:24.4300577	, lat:63.5630693 },
        { lng:24.4230786	, lat:63.5593634 },
        { lng:24.4146017	, lat:63.5632175 },
        { lng:24.4085421	, lat:63.5659968 },
        { lng:24.3893769	, lat:63.5746858 },
        { lng:24.3634357	, lat:63.5863665 },
        { lng:24.3527304	, lat:63.5912052 },
        { lng:24.339726	, lat:63.5970659 },
        { lng:24.3308168	, lat:63.6010927 },
        { lng:24.3175302	, lat:63.5952529 },
        { lng:24.3110929	, lat:63.598125 },
        { lng:24.3015614	, lat:63.5939179 },
        { lng:24.2990004	, lat:63.5950401 },
        { lng:24.2881858	, lat:63.5902789 },
        { lng:24.2800651	, lat:63.5939551 },
        { lng:24.2728124	, lat:63.5907485 },
        { lng:24.2615257	, lat:63.5958827 },
        { lng:24.2601953	, lat:63.5946135 },
        { lng:24.2569767	, lat:63.5946803 },
        { lng:24.2565218	, lat:63.5951584 },
        { lng:24.258925	, lat:63.5960363 },
        { lng:24.2563115	, lat:63.5970563 },
        { lng:24.255303	, lat:63.5967796 },
        { lng:24.2559038	, lat:63.5962834 },
        { lng:24.2562471	, lat:63.594938 },
        { lng:24.2544661	, lat:63.5948712 },
        { lng:24.2541442	, lat:63.594604 },
        { lng:24.2541228	, lat:63.59406 },
        { lng:24.2504964	, lat:63.5942032 },
        { lng:24.2495523	, lat:63.5923995 },
        { lng:24.2470632	, lat:63.5917983 },
        { lng:24.2466341	, lat:63.5920655 },
        { lng:24.2465868	, lat:63.5941469 },
        { lng:24.2487584	, lat:63.5947757 },
        { lng:24.2495196	, lat:63.5945515 },
        { lng:24.2498629	, lat:63.5944036 },
        { lng:24.2504063	, lat:63.5942328 },
        { lng:24.2497878	, lat:63.5944847 },
        { lng:24.2498307	, lat:63.5946899 },
        { lng:24.2497948	, lat:63.5948674 },
        { lng:24.2497556	, lat:63.5950143 },
        { lng:24.2498913	, lat:63.5951393 },
        { lng:24.2501204	, lat:63.5952195 },
        { lng:24.2502384	, lat:63.5953626 },
        { lng:24.2504423	, lat:63.5954246 },
        { lng:24.2505174	, lat:63.5955153 },
        { lng:24.2504744	, lat:63.5956059 },
        { lng:24.2503956	, lat:63.5957023 },
        { lng:24.2505066	, lat:63.5957872 },
        { lng:24.2506853	, lat:63.5957977 },
        { lng:24.2508462	, lat:63.5958741 },
        { lng:24.2509465	, lat:63.5959256 },
        { lng:24.2511074	, lat:63.5959447 },
        { lng:24.2512539	, lat:63.5959266 },
        { lng:24.2513826	, lat:63.5959361 },
        { lng:24.2515044	, lat:63.5960163 },
        { lng:24.2516938	, lat:63.5960363 },
        { lng:24.2518155	, lat:63.5960163 },
        { lng:24.2519405	, lat:63.5959361 },
        { lng:24.2523697	, lat:63.5959695 },
        { lng:24.2526095	, lat:63.5959542 },
        { lng:24.2525022	, lat:63.5953578 },
        { lng:24.2530815	, lat:63.5949618 },
        { lng:24.2535751	, lat:63.5949093 },
        { lng:24.2547944	, lat:63.595397 },
        { lng:24.2547016	, lat:63.5963502 },
        { lng:24.2545621	, lat:63.5967081 },
        { lng:24.2545299	, lat:63.5968893 },
        { lng:24.2547086	, lat:63.5970477 },
        { lng:24.2542295	, lat:63.5969895 },
        { lng:24.2535858	, lat:63.5969323 },
        { lng:24.2503886	, lat:63.5958493 },
        { lng:24.2499058	, lat:63.5963836 },
        { lng:24.2502169	, lat:63.5975239 },
        { lng:24.251329	, lat:63.598021 },
        { lng:24.2503033	, lat:63.5982872 },
        { lng:24.2512862	, lat:63.5987165 },
        { lng:24.2640234	, lat:63.6043053 },
        { lng:24.2599164	, lat:63.6061864 },
        { lng:24.2699328	, lat:63.6106034 },
        { lng:24.2679004	, lat:63.6115111 },
        { lng:24.2654267	, lat:63.6126157 },
        { lng:24.2676154	, lat:63.6135599 },
        { lng:24.266513	, lat:63.6140599 },
        { lng:24.2650558	, lat:63.6147206 },
        { lng:24.2633471	, lat:63.6154954 },
        { lng:24.2548309	, lat:63.6193564 },
        { lng:24.2480503	, lat:63.6224549 },
        { lng:24.2444112	, lat:63.6240752 },
        { lng:24.2390123	, lat:63.6264786 },
        { lng:24.2280751	, lat:63.6314123 },
        { lng:24.2264638	, lat:63.632139 },
        { lng:24.2103491	, lat:63.6393714 },
        { lng:24.2170164	, lat:63.6424465 },
        { lng:24.22205	, lat:63.6447678 },
        { lng:24.2276826	, lat:63.6473649 },
        { lng:24.2150936	, lat:63.653003 },
        { lng:24.2107783	, lat:63.6549353 },
        { lng:24.1973048	, lat:63.6609618 },
        { lng:24.1947065	, lat:63.6621238 },
        { lng:24.1830754	, lat:63.6673298 },
        { lng:24.1801466	, lat:63.6682233 },
        { lng:24.1754391	, lat:63.6696593 },
        { lng:24.1745851	, lat:63.6699197 },
        { lng:24.1700302	, lat:63.6713091 },
        { lng:24.1593442	, lat:63.6745923 },
        { lng:24.1546622	, lat:63.6767913 },
        { lng:24.1537652	, lat:63.6766476 },
        { lng:24.1526495	, lat:63.6770948 },
        { lng:24.1523276	, lat:63.6769616 },
        { lng:24.151126	, lat:63.676876 },
        { lng:24.1512118	, lat:63.6770948 },
        { lng:24.1471091	, lat:63.6780568 },
        { lng:24.1472164	, lat:63.6784468 },
        { lng:24.149684	, lat:63.6782756 },
        { lng:24.1502891	, lat:63.6776181 },
        { lng:24.1509328	, lat:63.6774469 },
        { lng:24.1518727	, lat:63.6776857 },
        { lng:24.1538725	, lat:63.6768379 },
        { lng:24.1544691	, lat:63.6768864 },
        { lng:24.1529806	, lat:63.6775891 },
        { lng:24.1460356	, lat:63.6808677 },
        { lng:24.1457429	, lat:63.6810059 },
        { lng:24.1434577	, lat:63.6820846 },
        { lng:24.1391968	, lat:63.6840958 },
        { lng:24.138015	, lat:63.6846536 },
        { lng:24.1369048	, lat:63.6851775 },
        { lng:24.136121	, lat:63.6855475 },
        { lng:24.1358593	, lat:63.685671 },
        { lng:24.135681	, lat:63.6857552 },
        { lng:24.1355022	, lat:63.6858395 },
        { lng:24.1353164	, lat:63.6859273 },
        { lng:24.1351247	, lat:63.6860177 },
        { lng:24.135028	, lat:63.6860634 },
        { lng:24.1349101	, lat:63.686119 },
        { lng:24.1348222	, lat:63.6861605 },
        { lng:24.1347051	, lat:63.6862158 },
        { lng:24.1341967	, lat:63.6864557 },
        { lng:24.1308045	, lat:63.6880567 },
        { lng:24.1285385	, lat:63.6891261 },
        { lng:24.1225873	, lat:63.6919343 },
        { lng:24.1163431	, lat:63.6948299 },
        { lng:24.1121588	, lat:63.6968125 },
        { lng:24.095572	, lat:63.7046368 },
        { lng:24.080247	, lat:63.7118896 },
        { lng:24.0696179	, lat:63.7168404 },
        { lng:24.0644799	, lat:63.7193383 },
        { lng:24.0547381	, lat:63.7239363 },
        { lng:24.0493522	, lat:63.726501 },
        { lng:24.0469919	, lat:63.7275458 },
        { lng:24.0388122	, lat:63.7314122 },
        { lng:24.0290103	, lat:63.7359313 },
        { lng:24.0255771	, lat:63.73746 },
        { lng:24.0166507	, lat:63.7415614 },
        { lng:24.0077415	, lat:63.7457391 },
        { lng:24.002184	, lat:63.7483398 },
        { lng:23.9916579	, lat:63.7532158 },
        { lng:23.9877698	, lat:63.7550747 },
        { lng:23.9852002	, lat:63.7562622 },
        { lng:23.9816276	, lat:63.7579439 },
        { lng:23.9722742	, lat:63.7623535 },
        { lng:23.9637351	, lat:63.7663944 },
        { lng:23.956226	, lat:63.769878 },
        { lng:23.9521265	, lat:63.7717624 },
        { lng:23.9518905	, lat:63.7719236 },
        { lng:23.9428783	, lat:63.7761053 },
        { lng:23.9245749	, lat:63.7846281 },
        { lng:23.9185667	, lat:63.7818412 },
        { lng:23.9131808	, lat:63.7840878 },
        { lng:23.9053488	, lat:63.7804097 },
        { lng:23.8988257	, lat:63.7831493 },
        { lng:23.9066942	, lat:63.7868134 },
        { lng:23.8960147	, lat:63.7913194 },
        { lng:23.9007955	, lat:63.7935875 },
        { lng:23.9030013	, lat:63.794626 },
        { lng:23.8998513	, lat:63.7960757 },
        { lng:23.8966155	, lat:63.7975827 },
        { lng:23.8665748	, lat:63.805597 },
        { lng:23.8610816	, lat:63.8112227 },
        { lng:23.8524127	, lat:63.8199431 },
        { lng:23.8498807	, lat:63.8226032 },
        { lng:23.8444948	, lat:63.8280836 },
        { lng:23.8348818	, lat:63.8223476 },
        { lng:23.8332295	, lat:63.8230481 },
        { lng:23.8392098	, lat:63.8266218 },
        { lng:23.8434434	, lat:63.829153 },
        { lng:23.8373709	, lat:63.8352755 },
        { lng:23.8297749	, lat:63.8430236 },
        { lng:23.8245392	, lat:63.8483298 },
        { lng:23.8168359	, lat:63.8487837 },
        { lng:23.8127804	, lat:63.8449816 },
        { lng:23.8050771	, lat:63.8410182 },
        { lng:23.7994981	, lat:63.8431088 },
        { lng:23.8078816	, lat:63.8471906 },
        { lng:23.8054848	, lat:63.8476772 },
        { lng:23.8049483	, lat:63.8489729 },
        { lng:23.7996483	, lat:63.8494457 },
        { lng:23.8007212	, lat:63.8503063 },
        { lng:23.7815809	, lat:63.8519234 },
        { lng:23.7687707	, lat:63.8530014 },
        { lng:23.7648225	, lat:63.8533418 },
        { lng:23.7620974	, lat:63.8535687 },
        { lng:23.7620545	, lat:63.8584001 },
        { lng:23.7568402	, lat:63.8584569 },
        { lng:23.757043	, lat:63.8567816 },
        { lng:23.7513042	, lat:63.8566322 },
        { lng:23.7514544	, lat:63.8544764 },
        { lng:23.7334514	, lat:63.8559514 },
        { lng:23.730619	, lat:63.8561973 },
        { lng:23.7304258	, lat:63.8544103 },
        { lng:23.7244177	, lat:63.8553747 },
        { lng:23.7206626	, lat:63.8559893 },
        { lng:23.7206411	, lat:63.8564809 },
        { lng:23.7206841	, lat:63.8570387 },
        { lng:23.7174654	, lat:63.8573129 },
        { lng:23.7189084	, lat:63.8584276 },
        { lng:23.7258983	, lat:63.8637223 },
        { lng:23.7261933	, lat:63.8635205 },
        { lng:23.7360478	, lat:63.8578518 },
        { lng:23.7423713	, lat:63.8636897 },
        { lng:23.7435097	, lat:63.8647762 },
        { lng:23.7454248	, lat:63.8665484 },
        { lng:23.7462831	, lat:63.8663688 },
        { lng:23.7575634	, lat:63.8704189 },
        { lng:23.7570484	, lat:63.8715056 },
        { lng:23.7622261	, lat:63.8733052 },
        { lng:23.7582564	, lat:63.8759792 },
        { lng:23.7607241	, lat:63.8797204 },
        { lng:23.7628484	, lat:63.879711 },
        { lng:23.769908	, lat:63.8715004 },
        { lng:23.7731845	, lat:63.8726962 },
        { lng:23.7851644	, lat:63.8692324 },
        { lng:23.788147	, lat:63.8684008 },
        { lng:23.7832332	, lat:63.8653858 },
        { lng:23.7865934	, lat:63.8638867 },
        { lng:23.7946701	, lat:63.860376 },
        { lng:23.8056972	, lat:63.8641921 },
        { lng:23.8082893	, lat:63.8651736 },
        { lng:23.8074063	, lat:63.8657681 },
        { lng:23.7948632	, lat:63.8736076 },
        { lng:23.7855291	, lat:63.8794748 },
        { lng:23.7806705	, lat:63.8825164 },
        { lng:23.7786981	, lat:63.8837336 },
        { lng:23.7783933	, lat:63.8838079 },
        { lng:23.7655521	, lat:63.8853974 },
        { lng:23.7668974	, lat:63.8877071 },
        { lng:23.7662602	, lat:63.8885519 },
        { lng:23.7617755	, lat:63.8902517 },
        { lng:23.7552595	, lat:63.8885488 },
        { lng:23.7547286	, lat:63.8894792 },
        { lng:23.7691151	, lat:63.8950056 },
        { lng:23.7525702	, lat:63.9015249 },
        { lng:23.7592864	, lat:63.9048663 },
        { lng:23.7604022	, lat:63.9041867 },
        { lng:23.7593722	, lat:63.9033278 },
        { lng:23.7596726	, lat:63.9023745 },
        { lng:23.7651658	, lat:63.9045926 },
        { lng:23.764742	, lat:63.9048319 },
        { lng:23.7620545	, lat:63.9064519 },
        { lng:23.7607026	, lat:63.907509 },
        { lng:23.7565184	, lat:63.9072353 },
        { lng:23.7461758	, lat:63.9125104 },
        { lng:23.7428713	, lat:63.9141711 },
        { lng:23.7256703	, lat:63.9079831 },
        { lng:23.7175942	, lat:63.9050928 },
        { lng:23.7274003	, lat:63.898287 },
        { lng:23.7351487	, lat:63.892867 },
        { lng:23.729353	, lat:63.8900252 },
        { lng:23.7293959	, lat:63.8889958 },
        { lng:23.7297392	, lat:63.8883631 },
        { lng:23.729589	, lat:63.8878531 },
        { lng:23.7309558	, lat:63.8872161 },
        { lng:23.7304258	, lat:63.8864931 },
        { lng:23.7308121	, lat:63.8864081 },
        { lng:23.7319064	, lat:63.8858414 },
        { lng:23.7345672	, lat:63.8851425 },
        { lng:23.7339878	, lat:63.8849064 },
        { lng:23.7351894	, lat:63.8833857 },
        { lng:23.7341166	, lat:63.8827811 },
        { lng:23.7334299	, lat:63.8829417 },
        { lng:23.732872	, lat:63.883414 },
        { lng:23.730619	, lat:63.8840185 },
        { lng:23.7300825	, lat:63.8839902 },
        { lng:23.729825	, lat:63.8839713 },
        { lng:23.7291384	, lat:63.884368 },
        { lng:23.728323	, lat:63.8853125 },
        { lng:23.7273145	, lat:63.8853692 },
        { lng:23.7244606	, lat:63.8845758 },
        { lng:23.7253618	, lat:63.8843869 },
        { lng:23.7272716	, lat:63.8829417 },
        { lng:23.7274647	, lat:63.8827528 },
        { lng:23.7274861	, lat:63.88246 },
        { lng:23.7274153	, lat:63.8823329 },
        { lng:23.7280655	, lat:63.8817326 },
        { lng:23.7284088	, lat:63.8816193 },
        { lng:23.7299752	, lat:63.8809202 },
        { lng:23.7309344	, lat:63.8809065 },
        { lng:23.7322927	, lat:63.881317 },
        { lng:23.7335157	, lat:63.8807597 },
        { lng:23.7359834	, lat:63.8801834 },
        { lng:23.737421	, lat:63.8799756 },
        { lng:23.7374607	, lat:63.8789978 },
        { lng:23.7393453	, lat:63.8787896 },
        { lng:23.7433648	, lat:63.8783455 },
        { lng:23.7463839	, lat:63.8787243 },
        { lng:23.7484653	, lat:63.879858 },
        { lng:23.7532353	, lat:63.8774248 },
        { lng:23.7512183	, lat:63.8755541 },
        { lng:23.7492228	, lat:63.8767446 },
        { lng:23.7470126	, lat:63.8759604 },
        { lng:23.7461329	, lat:63.8765084 },
        { lng:23.7432361	, lat:63.8758848 },
        { lng:23.7439656	, lat:63.8746943 },
        { lng:23.7384725	, lat:63.8735132 },
        { lng:23.7310556	, lat:63.8766383 },
        { lng:23.7289345	, lat:63.8775032 },
        { lng:23.728515	, lat:63.8776757 },
        { lng:23.7275473	, lat:63.8779676 },
        { lng:23.727293	, lat:63.8779161 },
        { lng:23.7260485	, lat:63.8783507 },
        { lng:23.7260742	, lat:63.8784692 },
        { lng:23.7263918	, lat:63.8786435 },
        { lng:23.7263382	, lat:63.8789411 },
        { lng:23.7253769	, lat:63.879225 },
        { lng:23.7244649	, lat:63.8792014 },
        { lng:23.7241752	, lat:63.8791683 },
        { lng:23.7242246	, lat:63.8790498 },
        { lng:23.7234349	, lat:63.8789369 },
        { lng:23.7225659	, lat:63.8792534 },
        { lng:23.7220295	, lat:63.8798391 },
        { lng:23.7222977	, lat:63.8800894 },
        { lng:23.7223084	, lat:63.880302 },
        { lng:23.7221582	, lat:63.8803964 },
        { lng:23.7209952	, lat:63.8807833 },
        { lng:23.7203515	, lat:63.8805377 },
        { lng:23.7197399	, lat:63.8802826 },
        { lng:23.7203423	, lat:63.8798003 },
        { lng:23.7197335	, lat:63.8795887 },
        { lng:23.7193215	, lat:63.8794843 },
        { lng:23.7199867	, lat:63.8790639 },
        { lng:23.7192679	, lat:63.8789033 },
        { lng:23.7193108	, lat:63.8780011 },
        { lng:23.7176735	, lat:63.8773025 },
        { lng:23.7165792	, lat:63.87743 },
        { lng:23.7163282	, lat:63.8786152 },
        { lng:23.7149484	, lat:63.8797541 },
        { lng:23.7151802	, lat:63.8804432 },
        { lng:23.7160857	, lat:63.8806798 },
        { lng:23.7164462	, lat:63.8807738 },
        { lng:23.718725	, lat:63.8810057 },
        { lng:23.7199116	, lat:63.8809297 },
        { lng:23.7198365	, lat:63.8819782 },
        { lng:23.7216861	, lat:63.8830792 },
        { lng:23.7230058	, lat:63.883967 },
        { lng:23.7237954	, lat:63.884675 },
        { lng:23.7235701	, lat:63.8847647 },
        { lng:23.7181735	, lat:63.8872015 },
        { lng:23.7022734	, lat:63.8942838 },
        { lng:23.7016296	, lat:63.8908467 },
        { lng:23.7005997	, lat:63.8907712 },
        { lng:23.698926	, lat:63.8954357 },
        { lng:23.6980462	, lat:63.8958795 },
        { lng:23.694849	, lat:63.8976639 },
        { lng:23.6830044	, lat:63.9029219 },
        { lng:23.6730909	, lat:63.9073391 },
        { lng:23.6721897	, lat:63.9070371 },
        { lng:23.6703014	, lat:63.9085943 },
        { lng:23.6673403	, lat:63.9099154 },
        { lng:23.6768235	, lat:63.9157689 },
        { lng:23.6732153	, lat:63.9170808 },
        { lng:23.6699581	, lat:63.9181902 },
        { lng:23.6678553	, lat:63.9168223 },
        { lng:23.6653447	, lat:63.9165581 },
        { lng:23.6626196	, lat:63.9175581 },
        { lng:23.6597872	, lat:63.9158222 },
        { lng:23.6586499	, lat:63.9160203 },
        { lng:23.6580491	, lat:63.9161147 },
        { lng:23.6572337	, lat:63.9162468 },
        { lng:23.6565042	, lat:63.9164732 },
        { lng:23.6608536	, lat:63.9187143 },
        { lng:23.6616325	, lat:63.9184449 },
        { lng:23.6619115	, lat:63.9183695 },
        { lng:23.6621904	, lat:63.9190015 },
        { lng:23.6623986	, lat:63.9191765 },
        { lng:23.664422	, lat:63.9200958 },
        { lng:23.6665893	, lat:63.91936 },
        { lng:23.6723764	, lat:63.921016 },
        { lng:23.6726832	, lat:63.9211523 },
        { lng:23.6754298	, lat:63.9227465 },
        { lng:23.678155	, lat:63.9243499 },
        { lng:23.6808801	, lat:63.9248215 },
        { lng:23.680864	, lat:63.9248664 },
        { lng:23.6809015	, lat:63.92489 },
        { lng:23.6809702	, lat:63.9248951 },
        { lng:23.6810399	, lat:63.9248904 },
        { lng:23.6812599	, lat:63.9248408 },
        { lng:23.6813564	, lat:63.9247843 },
        { lng:23.6815238	, lat:63.9247815 },
        { lng:23.6816418	, lat:63.9248169 },
        { lng:23.6819369	, lat:63.9252578 },
        { lng:23.6817169	, lat:63.9257364 },
        { lng:23.670044	, lat:63.930678 },
        { lng:23.6630563	, lat:63.9302423 },
        { lng:23.6592293	, lat:63.9335916 },
        { lng:23.6629415	, lat:63.9354302 },
        { lng:23.6651516	, lat:63.9343459 },
        { lng:23.6655378	, lat:63.9342045 },
        { lng:23.6682565	, lat:63.9346245 },
        { lng:23.6687994	, lat:63.9343365 },
        { lng:23.6734986	, lat:63.9346571 },
        { lng:23.6815453	, lat:63.9321867 },
        { lng:23.6949349	, lat:63.9280658 },
        { lng:23.7021875	, lat:63.9212372 },
        { lng:23.7058107	, lat:63.9200204 },
        { lng:23.7068793	, lat:63.9196643 },
        { lng:23.7099273	, lat:63.9186482 },
        { lng:23.7086678	, lat:63.9169732 },
        { lng:23.7208986	, lat:63.9156618 },
        { lng:23.7347313	, lat:63.9217824 },
        { lng:23.7389166	, lat:63.9236288 },
        { lng:23.7378882	, lat:63.9260521 },
        { lng:23.737715	, lat:63.9264678 },
        { lng:23.7396247	, lat:63.9272505 },
        { lng:23.7375069	, lat:63.9324036 },
        { lng:23.7399101	, lat:63.9337331 },
        { lng:23.740232	, lat:63.9337331 },
        { lng:23.7412834	, lat:63.9343742 },
        { lng:23.7410474	, lat:63.9344496 },
        { lng:23.7408757	, lat:63.9346665 },
        { lng:23.7406397	, lat:63.9347325 },
        { lng:23.7424142	, lat:63.9353977 },
        { lng:23.741777	, lat:63.9356753 },
        { lng:23.7423499	, lat:63.9358031 },
        { lng:23.7411118	, lat:63.9363541 },
        { lng:23.7413693	, lat:63.9367124 },
        { lng:23.7414486	, lat:63.9369061 },
        { lng:23.7416697	, lat:63.9370141 },
        { lng:23.7421203	, lat:63.9367595 },
        { lng:23.742764	, lat:63.9365333 },
        { lng:23.7433648	, lat:63.9365993 },
        { lng:23.7437511	, lat:63.936571 },
        { lng:23.744266	, lat:63.9363541 },
        { lng:23.7451029	, lat:63.9361939 },
        { lng:23.7457037	, lat:63.936175 },
        { lng:23.746062	, lat:63.9361802 },
        { lng:23.746856	, lat:63.9374341 },
        { lng:23.749624	, lat:63.9380469 },
        { lng:23.7504565	, lat:63.938546 },
        { lng:23.7545164	, lat:63.9413745 },
        { lng:23.7568338	, lat:63.9423548 },
        { lng:23.7643247	, lat:63.9424292 },
        { lng:23.7649223	, lat:63.9417539 },
        { lng:23.7675476	, lat:63.9418783 },
        { lng:23.7685497	, lat:63.9424208 },
        { lng:23.7846644	, lat:63.9485469 },
        { lng:23.7733197	, lat:63.9536206 },
        { lng:23.7696719	, lat:63.9537714 },
        { lng:23.7670927	, lat:63.9551717 },
        { lng:23.7669725	, lat:63.9552369 },
        { lng:23.7673911	, lat:63.9555337 },
        { lng:23.7680025	, lat:63.9559671 },
        { lng:23.7679458	, lat:63.9559941 },
        { lng:23.7674983	, lat:63.9562073 },
        { lng:23.7547803	, lat:63.9618637 },
        { lng:23.7522054	, lat:63.9627302 },
        { lng:23.7526281	, lat:63.9629238 },
        { lng:23.7599731	, lat:63.965584 },
        { lng:23.747828	, lat:63.9727502 },
        { lng:23.7461243	, lat:63.973727 },
        { lng:23.7358326	, lat:63.9798183 },
        { lng:23.7353364	, lat:63.9801101 },
        { lng:23.7344964	, lat:63.9805881 },
        { lng:23.7340844	, lat:63.9808436 },
        { lng:23.732048	, lat:63.9820369 },
        { lng:23.7260968	, lat:63.985536 },
        { lng:23.7115645	, lat:63.9776365 },
        { lng:23.7027605	, lat:63.9853977 },
        { lng:23.6929972	, lat:63.9939983 },
        { lng:23.6870599	, lat:63.999252 },
        { lng:23.6782408	, lat:64.0070208 },
        { lng:23.6746788	, lat:64.0101522 },
        { lng:23.6688573	, lat:64.0151123 },
        { lng:23.6610961	, lat:64.0216591 },
        { lng:23.6567831	, lat:64.0252869 },
        { lng:23.654573	, lat:64.0271571 },
        { lng:23.6496098	, lat:64.0313249 },
        { lng:23.6465263	, lat:64.0340068 },
        { lng:23.6406255	, lat:64.039136 },
        { lng:23.6381095	, lat:64.0401317 },
        { lng:23.6311412	, lat:64.0428931 },
        { lng:23.6212921	, lat:64.0467999 },
        { lng:23.617197	, lat:64.0485222 },
        { lng:23.6163354	, lat:64.0488845 },
        { lng:23.6017013	, lat:64.0549779 },
        { lng:23.5845351	, lat:64.0621212 },
        { lng:23.5608823	, lat:64.0719794 },
        { lng:23.5605176	, lat:64.072092 },
        { lng:23.5515976	, lat:64.0758676 },
        { lng:23.5459907	, lat:64.0782273 },
        { lng:23.5422356	, lat:64.0798218 },
        { lng:23.5403259	, lat:64.0814538 },
        { lng:23.538738	, lat:64.0812756 },
        { lng:23.5292602	, lat:64.0851344 },
        { lng:23.5239816	, lat:64.0870567 },
        { lng:23.5186386	, lat:64.0890352 },
        { lng:23.5063434	, lat:64.094698 },
        { lng:23.5004854	, lat:64.0998442 },
        { lng:23.4922028	, lat:64.107154 },
        { lng:23.4695864	, lat:64.1094965 },
        { lng:23.4504247	, lat:64.1114828 },
        { lng:23.4120798	, lat:64.115408 },
        { lng:23.3842063	, lat:64.1182837 },
        { lng:23.356719	, lat:64.1210936 },
        { lng:23.3325791	, lat:64.123566 },
        { lng:23.3052206	, lat:64.1263566 },
        { lng:23.2775831	, lat:64.1291749 },
        { lng:23.238337	, lat:64.1331633 },
        { lng:23.1975181	, lat:64.1372966 },
        { lng:23.1583858	, lat:64.1412505 },
        { lng:23.1236029	, lat:64.1447505 },
        { lng:23.0905151	, lat:64.1480723 },
        { lng:23.0514987	, lat:64.1519788 },
        { lng:22.981596	, lat:64.1589518 },
        { lng:22.9326725	, lat:64.1638055 },
        { lng:22.8800798	, lat:64.169023 },
        { lng:22.7788929	, lat:64.1789752 },
        { lng:22.6877648	, lat:64.1581728 },
        { lng:22.5966374	, lat:64.1375004 },
        { lng:22.579417	, lat:64.1333313 },
        { lng:22.5635398	, lat:64.1291343 },
        { lng:22.5489042	, lat:64.1249122 },
        { lng:22.5316696	, lat:64.1194312 },
        { lng:22.4550356	, lat:64.0937946 },
        { lng:22.4234855	, lat:64.0823766 },
        { lng:22.4115021	, lat:64.0778352 },
        { lng:22.3967908	, lat:64.071893 },
        { lng:22.3857658	, lat:64.067059 },
        { lng:22.3746336	, lat:64.0617923 },
        { lng:22.3652261	, lat:64.0569796 },
        { lng:22.3558581	, lat:64.0519168 },
        { lng:22.3478158	, lat:64.0472802 },
        { lng:22.3399033	, lat:64.0424226 },
        { lng:22.287872	, lat:64.0093865 },
      ],
    },
    {
      id: 12,
      regionName: 'eCom TP  Kotka kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:26.7492365	, lat:60.5746854 },
        { lng:26.7500861	, lat:60.5743166 },
        { lng:26.8192761	, lat:60.5006311 },
        { lng:26.8238856	, lat:60.4957683 },
        { lng:26.825325	, lat:60.493549 },
        { lng:26.8274187	, lat:60.4906518 },
        { lng:26.8293874	, lat:60.4880691 },
        { lng:26.8343801	, lat:60.4816621 },
        { lng:26.8397074	, lat:60.4747824 },
        { lng:26.8401356	, lat:60.4730457 },
        { lng:26.8408865	, lat:60.4670754 },
        { lng:26.8430018	, lat:60.4502511 },
        { lng:26.8432904	, lat:60.4478916 },
        { lng:26.842976	, lat:60.4479427 },
        { lng:26.8425308	, lat:60.447863 },
        { lng:26.8423747	, lat:60.4478038 },
        { lng:26.8423178	, lat:60.4475043 },
        { lng:26.8425088	, lat:60.4473254 },
        { lng:26.8426032	, lat:60.447101 },
        { lng:26.8422738	, lat:60.4468883 },
        { lng:26.8419638	, lat:60.4469198 },
        { lng:26.8416408	, lat:60.4468177 },
        { lng:26.8413383	, lat:60.4467457 },
        { lng:26.8414059	, lat:60.4465938 },
        { lng:26.8416934	, lat:60.4464602 },
        { lng:26.8419461	, lat:60.4456627 },
        { lng:26.8421177	, lat:60.4453724 },
        { lng:26.8428618	, lat:60.4451891 },
        { lng:26.8435216	, lat:60.444952 },
        { lng:26.8436868	, lat:60.4448596 },
        { lng:26.8707683	, lat:60.2318938 },
        { lng:26.8756331	, lat:60.2003421 },
        { lng:27.2938861	, lat:60.2003975 },
        { lng:27.3031364	, lat:60.22789 },
        { lng:27.265797	, lat:60.3614003 },
        { lng:27.2487251	, lat:60.3587435 },
        { lng:27.1184888	, lat:60.4064582 },
        { lng:27.1194748	, lat:60.4153941 },
        { lng:27.1200313	, lat:60.4155169 },
        { lng:27.1203759	, lat:60.4157116 },
        { lng:27.1203625	, lat:60.4158838 },
        { lng:27.12074	, lat:60.4160436 },
        { lng:27.1206053	, lat:60.4163366 },
        { lng:27.120384	, lat:60.4165803 },
        { lng:27.1204238	, lat:60.4169225 },
        { lng:27.1201064	, lat:60.4172384 },
        { lng:27.1197014	, lat:60.4174251 },
        { lng:27.1195374	, lat:60.4173994 },
        { lng:27.1196544	, lat:60.4208174 },
        { lng:27.1199763	, lat:60.4212728 },
        { lng:27.1196445	, lat:60.423327 },
        { lng:27.1115867	, lat:60.4430272 },
        { lng:27.0626694	, lat:60.4746329 },
        { lng:27.029614	, lat:60.5177476 },
        { lng:27.0261635	, lat:60.5216326 },
        { lng:27.0126319	, lat:60.5445767 },
        { lng:27.0096062	, lat:60.5491162 },
        { lng:27.0060591	, lat:60.5572605 },
        { lng:27.0209086	, lat:60.56372 },
        { lng:27.0200609	, lat:60.5670324 },
        { lng:27.0202476	, lat:60.5705642 },
        { lng:27.0204343	, lat:60.5741874 },
        { lng:27.0211811	, lat:60.5779936 },
        { lng:27.0276832	, lat:60.6047068 },
        { lng:26.9321872	, lat:60.6444356 },
        { lng:26.922146	, lat:60.6485489 },
        { lng:26.9210112	, lat:60.6481676 },
        { lng:26.9175756	, lat:60.6470132 },
        { lng:26.8998623	, lat:60.6409226 },
        { lng:26.8983335	, lat:60.6403888 },
        { lng:26.8941116	, lat:60.6389605 },
        { lng:26.888479	, lat:60.6377348 },
        { lng:26.8820846	, lat:60.6363354 },
        { lng:26.8762266	, lat:60.6373034 },
        { lng:26.8634808	, lat:60.6381451 },
        { lng:26.8618393	, lat:60.6389605 },
        { lng:26.8588137	, lat:60.6395233 },
        { lng:26.8555951	, lat:60.6405123 },
        { lng:26.8531489	, lat:60.641538 },
        { lng:26.8520975	, lat:60.6416748 },
        { lng:26.8511963	, lat:60.6415117 },
        { lng:26.850146	, lat:60.6410134 },
        { lng:26.8498659	, lat:60.6408805 },
        { lng:26.8487715	, lat:60.6407227 },
        { lng:26.8472373	, lat:60.6408752 },
        { lng:26.8470876	, lat:60.6408895 },
        { lng:26.8464112	, lat:60.6409541 },
        { lng:26.8454456	, lat:60.6407122 },
        { lng:26.8450272	, lat:60.6402756 },
        { lng:26.8424072	, lat:60.6396601 },
        { lng:26.8412184	, lat:60.6392482 },
        { lng:26.8403172	, lat:60.6390236 },
        { lng:26.8337404	, lat:60.6390025 },
        { lng:26.8323242	, lat:60.6392445 },
        { lng:26.8305111	, lat:60.6395286 },
        { lng:26.8286657	, lat:60.6401861 },
        { lng:26.8278932	, lat:60.640912 },
        { lng:26.8266701	, lat:60.6427899 },
        { lng:26.8261337	, lat:60.6434736 },
        { lng:26.8265414	, lat:60.6441732 },
        { lng:26.8274748	, lat:60.644778 },
        { lng:26.8304145	, lat:60.6448937 },
        { lng:26.8328714	, lat:60.6449884 },
        { lng:26.834706	, lat:60.6450568 },
        { lng:26.8374741	, lat:60.6459246 },
        { lng:26.8396842	, lat:60.6462612 },
        { lng:26.8401456	, lat:60.6465294 },
        { lng:26.8411004	, lat:60.6470711 },
        { lng:26.8414035	, lat:60.647611 },
        { lng:26.8416334	, lat:60.6477553 },
        { lng:26.8417316	, lat:60.647951 },
        { lng:26.8418539	, lat:60.6484015 },
        { lng:26.8419153	, lat:60.648586 },
        { lng:26.8420586	, lat:60.6487449 },
        { lng:26.8422364	, lat:60.6489766 },
        { lng:26.8423839	, lat:60.6492486 },
        { lng:26.8424826	, lat:60.6494913 },
        { lng:26.8426056	, lat:60.649609 },
        { lng:26.8422541	, lat:60.6501732 },
        { lng:26.8409357	, lat:60.6523869 },
        { lng:26.8397612	, lat:60.6512041 },
        { lng:26.8378818	, lat:60.6493114 },
        { lng:26.8339872	, lat:60.648165 },
        { lng:26.8309724	, lat:60.648349 },
        { lng:26.8266594	, lat:60.6486172 },
        { lng:26.8232905	, lat:60.6496217 },
        { lng:26.8198037	, lat:60.6516778 },
        { lng:26.8190848	, lat:60.6526768 },
        { lng:26.8175721	, lat:60.6535707 },
        { lng:26.8103408	, lat:60.6577927 },
        { lng:26.8031311	, lat:60.6619721 },
        { lng:26.798757	, lat:60.665726 },
        { lng:26.7953956	, lat:60.6686106 },
        { lng:26.7918336	, lat:60.669236 },
        { lng:26.7856752	, lat:60.6712488 },
        { lng:26.7815661	, lat:60.6735085 },
        { lng:26.7721033	, lat:60.6755736 },
        { lng:26.767203	, lat:60.675125 },
        { lng:26.7653548	, lat:60.6748011 },
        { lng:26.7568683	, lat:60.6748222 },
        { lng:26.7533969	, lat:60.6741021 },
        { lng:26.752786	, lat:60.6739754 },
        { lng:26.7505061	, lat:60.6727202 },
        { lng:26.7506456	, lat:60.6709388 },
        { lng:26.7529598	, lat:60.6688781 },
        { lng:26.7538551	, lat:60.6680716 },
        { lng:26.754957	, lat:60.6670809 },
        { lng:26.7557525	, lat:60.6663874 },
        { lng:26.7531347	, lat:60.6645898 },
        { lng:26.7534995	, lat:60.6635858 },
        { lng:26.7527377	, lat:60.6626817 },
        { lng:26.7436024	, lat:60.6576159 },
        { lng:26.74113	, lat:60.656271 },
        { lng:26.7453314	, lat:60.6550063 },
        { lng:26.7477274	, lat:60.6542701 },
        { lng:26.7502379	, lat:60.6537022 },
        { lng:26.7521691	, lat:60.6529398 },
        { lng:26.7536068	, lat:60.6518355 },
        { lng:26.753759	, lat:60.6517074 },
        { lng:26.7541217	, lat:60.6513991 },
        { lng:26.7537999	, lat:60.6507786 },
        { lng:26.7525567	, lat:60.6476812 },
        { lng:26.7523358	, lat:60.6465074 },
        { lng:26.7527424	, lat:60.6448268 },
        { lng:26.7547762	, lat:60.6428056 },
        { lng:26.757673	, lat:60.6419009 },
        { lng:26.7595183	, lat:60.6403808 },
        { lng:26.7586493	, lat:60.6398179 },
        { lng:26.7590999	, lat:60.6391551 },
        { lng:26.7602157	, lat:60.638224 },
        { lng:26.7589819	, lat:60.637719 },
        { lng:26.7598295	, lat:60.6369088 },
        { lng:26.7566216	, lat:60.6350201 },
        { lng:26.755942	, lat:60.6334189 },
        { lng:26.7548823	, lat:60.6327151 },
        { lng:26.7535756	, lat:60.6318432 },
        { lng:26.7524867	, lat:60.6315065 },
        { lng:26.7501413	, lat:60.6307319 },
        { lng:26.7506027	, lat:60.6297585 },
        { lng:26.7512035	, lat:60.6284745 },
        { lng:26.7547864	, lat:60.6261353 },
        { lng:26.7623078	, lat:60.6241067 },
        { lng:26.7647218	, lat:60.6234277 },
        { lng:26.7646253	, lat:60.6233015 },
        { lng:26.7646896	, lat:60.6231752 },
        { lng:26.764915	, lat:60.6229805 },
        { lng:26.7651617	, lat:60.6228751 },
        { lng:26.7656123	, lat:60.6226541 },
        { lng:26.7659664	, lat:60.6224593 },
        { lng:26.7664814	, lat:60.6221384 },
        { lng:26.7667818	, lat:60.6219804 },
        { lng:26.7671251	, lat:60.6217172 },
        { lng:26.7673075	, lat:60.6215488 },
        { lng:26.7675757	, lat:60.6214383 },
        { lng:26.767801	, lat:60.6212803 },
        { lng:26.7681765	, lat:60.6210857 },
        { lng:26.7684877	, lat:60.6209593 },
        { lng:26.7689608	, lat:60.6209077 },
        { lng:26.7692065	, lat:60.6207698 },
        { lng:26.7694854	, lat:60.6207382 },
        { lng:26.7698931	, lat:60.620733 },
        { lng:26.7702365	, lat:60.6207066 },
        { lng:26.7705905	, lat:60.6206277 },
        { lng:26.7709982	, lat:60.6205383 },
        { lng:26.7714547	, lat:60.6205416 },
        { lng:26.7728221	, lat:60.6204119 },
        { lng:26.7756331	, lat:60.6201329 },
        { lng:26.7789613	, lat:60.6191966 },
        { lng:26.7815447	, lat:60.6184697 },
        { lng:26.7828322	, lat:60.6160482 },
        { lng:26.7838944	, lat:60.6114996 },
        { lng:26.783948	, lat:60.6107152 },
        { lng:26.7864264	, lat:60.609462 },
        { lng:26.7866839	, lat:60.6045123 },
        { lng:26.7821885	, lat:60.6017528 },
        { lng:26.7807186	, lat:60.5989982 },
        { lng:26.7824996	, lat:60.5973075 },
        { lng:26.7843986	, lat:60.5956219 },
        { lng:26.7843986	, lat:60.593931 },
        { lng:26.7843772	, lat:60.5918501 },
        { lng:26.7830361	, lat:60.5910072 },
        { lng:26.78015	, lat:60.5895637 },
        { lng:26.7771888	, lat:60.589511 },
        { lng:26.7757941	, lat:60.5887102 },
        { lng:26.775837	, lat:60.5863339 },
        { lng:26.7763198	, lat:60.5860283 },
        { lng:26.777736	, lat:60.5850904 },
        { lng:26.7782617	, lat:60.5843158 },
        { lng:26.7771019	, lat:60.5828978 },
        { lng:26.7756117	, lat:60.5826928 },
        { lng:26.7739809	, lat:60.5811435 },
        { lng:26.7717922	, lat:60.5800315 },
        { lng:26.7688525	, lat:60.5781448 },
        { lng:26.765709	, lat:60.5764108 },
        { lng:26.7627371	, lat:60.5754568 },
        { lng:26.7568419	, lat:60.5753428 },
        { lng:26.754072	, lat:60.5757385 },
        { lng:26.7538592	, lat:60.5757223 },
        { lng:26.7538914	, lat:60.5756801 },
        { lng:26.7535304	, lat:60.5756219 },
        { lng:26.7492365	, lat:60.5746854 },
      ],
    },
    {
      id: 13,
      regionName: 'eCom TP  Kouvola kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:26.2356111	, lat:61.1298416 },
        { lng:26.2552037	, lat:61.1121985 },
        { lng:26.2544424	, lat:61.1121213 },
        { lng:26.253789	, lat:61.1118414 },
        { lng:26.2537477	, lat:61.1115488 },
        { lng:26.2533872	, lat:61.1113202 },
        { lng:26.2536517	, lat:61.1112132 },
        { lng:26.253642	, lat:61.1110857 },
        { lng:26.2534226	, lat:61.1110261 },
        { lng:26.2537364	, lat:61.1107661 },
        { lng:26.2535905	, lat:61.1105246 },
        { lng:26.2559509	, lat:61.1081057 },
        { lng:26.2559144	, lat:61.1079411 },
        { lng:26.2561182	, lat:61.1077856 },
        { lng:26.2559251	, lat:61.1075679 },
        { lng:26.255968	, lat:61.1073657 },
        { lng:26.2556676	, lat:61.1071583 },
        { lng:26.2555282	, lat:61.1069251 },
        { lng:26.2557427	, lat:61.1067436 },
        { lng:26.2555389	, lat:61.1066918 },
        { lng:26.2555067	, lat:61.1066192 },
        { lng:26.2556676	, lat:61.1064896 },
        { lng:26.2557213	, lat:61.1063807 },
        { lng:26.2555711	, lat:61.1062563 },
        { lng:26.2558178	, lat:61.1061008 },
        { lng:26.2554947	, lat:61.1057675 },
        { lng:26.255732	, lat:61.1056861 },
        { lng:26.2564401	, lat:61.1056861 },
        { lng:26.2565048	, lat:61.1053453 },
        { lng:26.259015	, lat:61.1052039 },
        { lng:26.260914	, lat:61.1053128 },
        { lng:26.2623732	, lat:61.1058572 },
        { lng:26.2628667	, lat:61.1057483 },
        { lng:26.2654845	, lat:61.1055202 },
        { lng:26.2658922	, lat:61.1051677 },
        { lng:26.2658493	, lat:61.1048514 },
        { lng:26.2658493	, lat:61.1044678 },
        { lng:26.2656454	, lat:61.1042604 },
        { lng:26.2656454	, lat:61.1040893 },
        { lng:26.2675445	, lat:61.1038716 },
        { lng:26.2698833	, lat:61.1037316 },
        { lng:26.2702696	, lat:61.1037834 },
        { lng:26.2708167	, lat:61.1040012 },
        { lng:26.2718574	, lat:61.1040945 },
        { lng:26.273499	, lat:61.1048462 },
        { lng:26.2748401	, lat:61.1054632 },
        { lng:26.2749366	, lat:61.1056031 },
        { lng:26.2759022	, lat:61.1058157 },
        { lng:26.2763743	, lat:61.1060438 },
        { lng:26.2770931	, lat:61.1061164 },
        { lng:26.2791423	, lat:61.1065933 },
        { lng:26.2793569	, lat:61.1065725 },
        { lng:26.2802259	, lat:61.1067281 },
        { lng:26.2805156	, lat:61.1069199 },
        { lng:26.2815349	, lat:61.1071065 },
        { lng:26.2822	, lat:61.1070547 },
        { lng:26.2825756	, lat:61.1070702 },
        { lng:26.2830154	, lat:61.1069043 },
        { lng:26.283509	, lat:61.1069043 },
        { lng:26.2838523	, lat:61.1067281 },
        { lng:26.2855796	, lat:61.1068317 },
        { lng:26.2857727	, lat:61.1067747 },
        { lng:26.2872426	, lat:61.1067903 },
        { lng:26.2877683	, lat:61.1068473 },
        { lng:26.288412	, lat:61.1067333 },
        { lng:26.2904612	, lat:61.1066762 },
        { lng:26.291223	, lat:61.1070236 },
        { lng:26.291856	, lat:61.107205 },
        { lng:26.2920813	, lat:61.107262 },
        { lng:26.2926499	, lat:61.1072568 },
        { lng:26.2934653	, lat:61.1071894 },
        { lng:26.2937657	, lat:61.1071946 },
        { lng:26.294152	, lat:61.107122 },
        { lng:26.2948709	, lat:61.1073294 },
        { lng:26.2955574	, lat:61.1074279 },
        { lng:26.2958578	, lat:61.1073657 },
        { lng:26.2972633	, lat:61.1074279 },
        { lng:26.2976496	, lat:61.1073605 },
        { lng:26.298186	, lat:61.1073242 },
        { lng:26.298465	, lat:61.1073294 },
        { lng:26.2989692	, lat:61.107262 },
        { lng:26.2995057	, lat:61.1072724 },
        { lng:26.2997739	, lat:61.1072309 },
        { lng:26.3000528	, lat:61.1071946 },
        { lng:26.3003425	, lat:61.1071532 },
        { lng:26.3008897	, lat:61.1071946 },
        { lng:26.3011473	, lat:61.1071376 },
        { lng:26.3016943	, lat:61.1071532 },
        { lng:26.3036684	, lat:61.1069147 },
        { lng:26.3051276	, lat:61.1071583 },
        { lng:26.3057713	, lat:61.1068784 },
        { lng:26.3067583	, lat:61.1065518 },
        { lng:26.3070158	, lat:61.1065881 },
        { lng:26.3078205	, lat:61.1068473 },
        { lng:26.308711	, lat:61.1069821 },
        { lng:26.3091723	, lat:61.1068214 },
        { lng:26.309462	, lat:61.1065259 },
        { lng:26.3096873	, lat:61.1064015 },
        { lng:26.3100628	, lat:61.1063496 },
        { lng:26.3103418	, lat:61.1063548 },
        { lng:26.3105242	, lat:61.1064222 },
        { lng:26.3108246	, lat:61.1066399 },
        { lng:26.3109907	, lat:61.1066156 },
        { lng:26.2684505	, lat:61.0707909 },
        { lng:26.2673066	, lat:61.0698221 },
        { lng:26.2598025	, lat:61.057632 },
        { lng:26.2564531	, lat:61.0521132 },
        { lng:26.2490977	, lat:61.0399566 },
        { lng:26.2580842	, lat:61.0242376 },
        { lng:26.2596683	, lat:61.0215251 },
        { lng:26.2617495	, lat:61.0179455 },
        { lng:26.2620867	, lat:61.0174159 },
        { lng:26.2649519	, lat:61.0130032 },
        { lng:26.265816	, lat:61.0116588 },
        { lng:26.2732983	, lat:61.0121563 },
        { lng:26.2819855	, lat:61.0126516 },
        { lng:26.2857149	, lat:61.0129033 },
        { lng:26.3051499	, lat:61.0035005 },
        { lng:26.3092743	, lat:61.0055532 },
        { lng:26.3142431	, lat:61.0080587 },
        { lng:26.315037	, lat:61.0049796 },
        { lng:26.3171861	, lat:61.0024448 },
        { lng:26.3205813	, lat:61.0033605 },
        { lng:26.3199075	, lat:61.0040116 },
        { lng:26.3244189	, lat:61.0051231 },
        { lng:26.3267	, lat:61.0020519 },
        { lng:26.3306985	, lat:61.0001837 },
        { lng:26.3369696	, lat:60.9994341 },
        { lng:26.3386723	, lat:61.0029938 },
        { lng:26.3421173	, lat:61.000672 },
        { lng:26.3436805	, lat:61.0012343 },
        { lng:26.3462886	, lat:61.0004896 },
        { lng:26.3551414	, lat:61.0226265 },
        { lng:26.3736878	, lat:61.0257288 },
        { lng:26.3753594	, lat:61.028055 },
        { lng:26.3862736	, lat:61.0277544 },
        { lng:26.4012009	, lat:61.0201549 },
        { lng:26.4049551	, lat:61.0163182 },
        { lng:26.4230629	, lat:61.0120592 },
        { lng:26.452741	, lat:61.0173677 },
        { lng:26.4603113	, lat:61.0141964 },
        { lng:26.5043145	, lat:61.009807 },
        { lng:26.4953147	, lat:60.9904076 },
        { lng:26.504959	, lat:60.9772012 },
        { lng:26.5059509	, lat:60.976407 },
        { lng:26.5073499	, lat:60.9746495 },
        { lng:26.5080744	, lat:60.9739223 },
        { lng:26.5092735	, lat:60.9738374 },
        { lng:26.5104614	, lat:60.9739092 },
        { lng:26.5109872	, lat:60.9736864 },
        { lng:26.5110322	, lat:60.9733178 },
        { lng:26.5109915	, lat:60.9729805 },
        { lng:26.5118219	, lat:60.9729794 },
        { lng:26.5142594	, lat:60.9734563 },
        { lng:26.5158581	, lat:60.973222 },
        { lng:26.5189909	, lat:60.9608464 },
        { lng:26.5168172	, lat:60.9595809 },
        { lng:26.5168945	, lat:60.958657 },
        { lng:26.5163108	, lat:60.9584288 },
        { lng:26.5162851	, lat:60.9580309 },
        { lng:26.5152551	, lat:60.9573403 },
        { lng:26.5153002	, lat:60.9567653 },
        { lng:26.5149568	, lat:60.9562652 },
        { lng:26.5152959	, lat:60.9560975 },
        { lng:26.5161434	, lat:60.956158 },
        { lng:26.5175339	, lat:60.95506 },
        { lng:26.5168773	, lat:60.9545224 },
        { lng:26.5188427	, lat:60.9521564 },
        { lng:26.5205594	, lat:60.950802 },
        { lng:26.5217975	, lat:60.950405 },
        { lng:26.5411996	, lat:60.9393995 },
        { lng:26.5354853	, lat:60.9376693 },
        { lng:26.5354386	, lat:60.9347176 },
        { lng:26.5354716	, lat:60.9312298 },
        { lng:26.5376158	, lat:60.9312213 },
        { lng:26.5378064	, lat:60.9299881 },
        { lng:26.5449019	, lat:60.8840289 },
        { lng:26.5386339	, lat:60.8718789 },
        { lng:26.5384362	, lat:60.8718576 },
        { lng:26.5380084	, lat:60.871904 },
        { lng:26.5364957	, lat:60.8719289 },
        { lng:26.535088	, lat:60.8718847 },
        { lng:26.533843	, lat:60.8718598 },
        { lng:26.5327869	, lat:60.8715781 },
        { lng:26.5324773	, lat:60.8713368 },
        { lng:26.5319883	, lat:60.8711648 },
        { lng:26.5310704	, lat:60.8712214 },
        { lng:26.5297368	, lat:60.8714144 },
        { lng:26.5288348	, lat:60.8716233 },
        { lng:26.5278759	, lat:60.8716053 },
        { lng:26.5268488	, lat:60.8717419 },
        { lng:26.5267348	, lat:60.8720167 },
        { lng:26.5265662	, lat:60.8721633 },
        { lng:26.5257604	, lat:60.8723758 },
        { lng:26.5252846	, lat:60.872415 },
        { lng:26.5244238	, lat:60.8721139 },
        { lng:26.5239179	, lat:60.8721573 },
        { lng:26.5227711	, lat:60.872326 },
        { lng:26.5225678	, lat:60.8721862 },
        { lng:26.5208608	, lat:60.8720504 },
        { lng:26.5205228	, lat:60.8719586 },
        { lng:26.5201598	, lat:60.8719122 },
        { lng:26.5193088	, lat:60.8719508 },
        { lng:26.5186204	, lat:60.872066 },
        { lng:26.5178841	, lat:60.8722657 },
        { lng:26.5168684	, lat:60.8724506 },
        { lng:26.5155729	, lat:60.8725082 },
        { lng:26.5135506	, lat:60.872368 },
        { lng:26.5123374	, lat:60.8723437 },
        { lng:26.5097859	, lat:60.8724163 },
        { lng:26.5085836	, lat:60.8725286 },
        { lng:26.506262	, lat:60.8730001 },
        { lng:26.5042819	, lat:60.8731011 },
        { lng:26.5035516	, lat:60.8730439 },
        { lng:26.5024158	, lat:60.87277 },
        { lng:26.5018256	, lat:60.8726136 },
        { lng:26.5012643	, lat:60.8725652 },
        { lng:26.5001417	, lat:60.872586 },
        { lng:26.4995441	, lat:60.8726714 },
        { lng:26.4988499	, lat:60.8725909 },
        { lng:26.498615	, lat:60.8726121 },
        { lng:26.4984333	, lat:60.8726933 },
        { lng:26.4982122	, lat:60.8728248 },
        { lng:26.4979336	, lat:60.8730062 },
        { lng:26.4971881	, lat:60.8731592 },
        { lng:26.4963561	, lat:60.8733013 },
        { lng:26.4917024	, lat:60.8734044 },
        { lng:26.4911656	, lat:60.8734611 },
        { lng:26.4910518	, lat:60.8732432 },
        { lng:26.4900781	, lat:60.8734744 },
        { lng:26.4894353	, lat:60.8736492 },
        { lng:26.4871249	, lat:60.8741755 },
        { lng:26.4858181	, lat:60.8744131 },
        { lng:26.4832805	, lat:60.8748041 },
        { lng:26.4825645	, lat:60.8748384 },
        { lng:26.481946	, lat:60.8748601 },
        { lng:26.4812658	, lat:60.8748124 },
        { lng:26.4796115	, lat:60.8748238 },
        { lng:26.4787868	, lat:60.8748756 },
        { lng:26.4780203	, lat:60.8749247 },
        { lng:26.4755886	, lat:60.8753921 },
        { lng:26.4744649	, lat:60.8756138 },
        { lng:26.4721103	, lat:60.8763901 },
        { lng:26.4717173	, lat:60.8765287 },
        { lng:26.4710526	, lat:60.8767663 },
        { lng:26.4696145	, lat:60.8771715 },
        { lng:26.4684636	, lat:60.8772672 },
        { lng:26.4667041	, lat:60.8772553 },
        { lng:26.4648521	, lat:60.8773436 },
        { lng:26.464381	, lat:60.8774287 },
        { lng:26.4640683	, lat:60.8773053 },
        { lng:26.4639706	, lat:60.8772722 },
        { lng:26.4637231	, lat:60.8771504 },
        { lng:26.4600124	, lat:60.8735322 },
        { lng:26.4639149	, lat:60.8712172 },
        { lng:26.4655135	, lat:60.8702824 },
        { lng:26.4680874	, lat:60.8687516 },
        { lng:26.4739357	, lat:60.865263 },
        { lng:26.4784565	, lat:60.8625952 },
        { lng:26.4804802	, lat:60.8613922 },
        { lng:26.4859841	, lat:60.8578761 },
        { lng:26.4901775	, lat:60.8550648 },
        { lng:26.495045	, lat:60.8518118 },
        { lng:26.4988373	, lat:60.8492542 },
        { lng:26.5022705	, lat:60.8469337 },
        { lng:26.5065883	, lat:60.8439809 },
        { lng:26.5088366	, lat:60.8424438 },
        { lng:26.5090404	, lat:60.8421877 },
        { lng:26.5114115	, lat:60.8406143 },
        { lng:26.5085544	, lat:60.8394984 },
        { lng:26.5011333	, lat:60.8364737 },
        { lng:26.4993415	, lat:60.8357523 },
        { lng:26.496779	, lat:60.8346921 },
        { lng:26.49505	, lat:60.8340112 },
        { lng:26.492753	, lat:60.8329911 },
        { lng:26.4869712	, lat:60.8305183 },
        { lng:26.4871321	, lat:60.8304294 },
        { lng:26.486821	, lat:60.8304713 },
        { lng:26.4862738	, lat:60.8305497 },
        { lng:26.4852868	, lat:60.8305863 },
        { lng:26.4848147	, lat:60.8306804 },
        { lng:26.4841173	, lat:60.8306595 },
        { lng:26.4831764	, lat:60.8305701 },
        { lng:26.4827268	, lat:60.8304371 },
        { lng:26.4824329	, lat:60.8303562 },
        { lng:26.4821432	, lat:60.8303039 },
        { lng:26.4817248	, lat:60.8302307 },
        { lng:26.4809822	, lat:60.8300618 },
        { lng:26.4804372	, lat:60.8299691 },
        { lng:26.4798043	, lat:60.8299326 },
        { lng:26.479021	, lat:60.8299012 },
        { lng:26.4782916	, lat:60.829849 },
        { lng:26.4758025	, lat:60.8295666 },
        { lng:26.4754055	, lat:60.8295143 },
        { lng:26.4750622	, lat:60.8295404 },
        { lng:26.4658139	, lat:60.8285312 },
        { lng:26.4691291	, lat:60.8262824 },
        { lng:26.4717577	, lat:60.8244624 },
        { lng:26.4727877	, lat:60.8238295 },
        { lng:26.4689682	, lat:60.8221505 },
        { lng:26.464741	, lat:60.8215647 },
        { lng:26.4610289	, lat:60.8200373 },
        { lng:26.4535918	, lat:60.8169588 },
        { lng:26.4527516	, lat:60.8166109 },
        { lng:26.4484742	, lat:60.8175863 },
        { lng:26.444313	, lat:60.8185073 },
        { lng:26.4402836	, lat:60.8193723 },
        { lng:26.4375987	, lat:60.8199551 },
        { lng:26.4374109	, lat:60.8199548 },
        { lng:26.43683	, lat:60.8200766 },
        { lng:26.436712	, lat:60.8201158 },
        { lng:26.4366422	, lat:60.8201446 },
        { lng:26.436528	, lat:60.8201597 },
        { lng:26.4363938	, lat:60.8201597 },
        { lng:26.4363257	, lat:60.8201393 },
        { lng:26.4362882	, lat:60.8200635 },
        { lng:26.4363418	, lat:60.8199484 },
        { lng:26.4364582	, lat:60.8198668 },
        { lng:26.4367656	, lat:60.8197784 },
        { lng:26.4369196	, lat:60.8197308 },
        { lng:26.4369625	, lat:60.8196288 },
        { lng:26.4368997	, lat:60.8195456 },
        { lng:26.4367747	, lat:60.8194849 },
        { lng:26.4367817	, lat:60.8194227 },
        { lng:26.4369265	, lat:60.8193678 },
        { lng:26.4369622	, lat:60.8192758 },
        { lng:26.436735	, lat:60.8192541 },
        { lng:26.4365601	, lat:60.8191528 },
        { lng:26.436374	, lat:60.8190827 },
        { lng:26.4362436	, lat:60.8190586 },
        { lng:26.4358912	, lat:60.8190277 },
        { lng:26.4357142	, lat:60.8190068 },
        { lng:26.4356283	, lat:60.8189571 },
        { lng:26.4355908	, lat:60.8188865 },
        { lng:26.4355211	, lat:60.8188237 },
        { lng:26.4354143	, lat:60.8188091 },
        { lng:26.4353065	, lat:60.8188577 },
        { lng:26.4351724	, lat:60.8188656 },
        { lng:26.4350597	, lat:60.8188368 },
        { lng:26.4349202	, lat:60.8187374 },
        { lng:26.4348934	, lat:60.818625 },
        { lng:26.4347754	, lat:60.8185229 },
        { lng:26.4345018	, lat:60.818638 },
        { lng:26.4342926	, lat:60.8186354 },
        { lng:26.4342175	, lat:60.8185465 },
        { lng:26.4342819	, lat:60.8182719 },
        { lng:26.4342282	, lat:60.8181908 },
        { lng:26.4341049	, lat:60.8181542 },
        { lng:26.4338742	, lat:60.8181385 },
        { lng:26.4336435	, lat:60.8181542 },
        { lng:26.4334558	, lat:60.8180757 },
        { lng:26.4332573	, lat:60.8179737 },
        { lng:26.4330481	, lat:60.8179057 },
        { lng:26.4328442	, lat:60.8178664 },
        { lng:26.4327742	, lat:60.8178477 },
        { lng:26.4326524	, lat:60.8178157 },
        { lng:26.4325291	, lat:60.8177579 },
        { lng:26.4324352	, lat:60.8176752 },
        { lng:26.4322909	, lat:60.8176572 },
        { lng:26.4321522	, lat:60.8176729 },
        { lng:26.4319913	, lat:60.8177069 },
        { lng:26.4318572	, lat:60.817686 },
        { lng:26.431752	, lat:60.8176483 },
        { lng:26.4316909	, lat:60.817597 },
        { lng:26.4317016	, lat:60.8175473 },
        { lng:26.4317821	, lat:60.8174976 },
        { lng:26.4318303	, lat:60.8174192 },
        { lng:26.4317713	, lat:60.8173433 },
        { lng:26.4316694	, lat:60.8173093 },
        { lng:26.4315246	, lat:60.817325 },
        { lng:26.4313851	, lat:60.8173956 },
        { lng:26.4312403	, lat:60.8173904 },
        { lng:26.4312027	, lat:60.8173538 },
        { lng:26.4311812	, lat:60.8172439 },
        { lng:26.431251	, lat:60.817189 },
        { lng:26.4313583	, lat:60.8171654 },
        { lng:26.431487	, lat:60.8170948 },
        { lng:26.4314977	, lat:60.8170294 },
        { lng:26.4314226	, lat:60.8169745 },
        { lng:26.4312993	, lat:60.8169431 },
        { lng:26.4311544	, lat:60.8169666 },
        { lng:26.4310096	, lat:60.8170216 },
        { lng:26.4309077	, lat:60.8170137 },
        { lng:26.4308057	, lat:60.8169457 },
        { lng:26.4306931	, lat:60.8168359 },
        { lng:26.4305	, lat:60.8167835 },
        { lng:26.4301459	, lat:60.8167391 },
        { lng:26.4299689	, lat:60.8167286 },
        { lng:26.4298455	, lat:60.8167443 },
        { lng:26.4297382	, lat:60.8167992 },
        { lng:26.4295719	, lat:60.816896 },
        { lng:26.4294539	, lat:60.8169326 },
        { lng:26.4293412	, lat:60.8169274 },
        { lng:26.4292232	, lat:60.8169013 },
        { lng:26.4291535	, lat:60.816828 },
        { lng:26.429132	, lat:60.8166475 },
        { lng:26.4292018	, lat:60.8164775 },
        { lng:26.4291642	, lat:60.8164043 },
        { lng:26.4290462	, lat:60.8163389 },
        { lng:26.4288745	, lat:60.8163075 },
        { lng:26.4286975	, lat:60.816352 },
        { lng:26.4285473	, lat:60.8164357 },
        { lng:26.4284508	, lat:60.8165351 },
        { lng:26.4283113	, lat:60.8165664 },
        { lng:26.4281343	, lat:60.8165691 },
        { lng:26.428027	, lat:60.8165481 },
        { lng:26.4278124	, lat:60.8165141 },
        { lng:26.4276783	, lat:60.8165063 },
        { lng:26.4275603	, lat:60.8164723 },
        { lng:26.4273242	, lat:60.8163363 },
        { lng:26.4271043	, lat:60.8162212 },
        { lng:26.4270453	, lat:60.8161427 },
        { lng:26.4270775	, lat:60.8160642 },
        { lng:26.427115	, lat:60.8159753 },
        { lng:26.4270775	, lat:60.8159282 },
        { lng:26.4268897	, lat:60.8159047 },
        { lng:26.4266698	, lat:60.8159047 },
        { lng:26.4264606	, lat:60.8158837 },
        { lng:26.4261816	, lat:60.8158785 },
        { lng:26.4260368	, lat:60.8159151 },
        { lng:26.4259563	, lat:60.8159544 },
        { lng:26.4259295	, lat:60.8160145 },
        { lng:26.4259402	, lat:60.8161035 },
        { lng:26.4259617	, lat:60.8161741 },
        { lng:26.4259295	, lat:60.8162342 },
        { lng:26.4258168	, lat:60.8162578 },
        { lng:26.4256505	, lat:60.8162552 },
        { lng:26.4254199	, lat:60.8162499 },
        { lng:26.4252697	, lat:60.8163206 },
        { lng:26.4251516	, lat:60.8163546 },
        { lng:26.4250497	, lat:60.8164095 },
        { lng:26.424921	, lat:60.8164252 },
        { lng:26.4247869	, lat:60.8164017 },
        { lng:26.4246474	, lat:60.8162813 },
        { lng:26.4244918	, lat:60.8162107 },
        { lng:26.4242933	, lat:60.816161 },
        { lng:26.424229	, lat:60.8160721 },
        { lng:26.424229	, lat:60.8159727 },
        { lng:26.424406	, lat:60.8159047 },
        { lng:26.4246367	, lat:60.8158654 },
        { lng:26.4247976	, lat:60.8158183 },
        { lng:26.4247547	, lat:60.8157503 },
        { lng:26.4246313	, lat:60.8156954 },
        { lng:26.4243684	, lat:60.8156771 },
        { lng:26.4240774	, lat:60.8156218 },
        { lng:26.4238886	, lat:60.8156058 },
        { lng:26.4237837	, lat:60.8156326 },
        { lng:26.4237569	, lat:60.8156745 },
        { lng:26.4237569	, lat:60.8157242 },
        { lng:26.4236872	, lat:60.8157739 },
        { lng:26.4235852	, lat:60.8157634 },
        { lng:26.4234726	, lat:60.8157189 },
        { lng:26.4233599	, lat:60.8157137 },
        { lng:26.4232473	, lat:60.8157372 },
        { lng:26.42314	, lat:60.8157242 },
        { lng:26.4231024	, lat:60.8156457 },
        { lng:26.4231239	, lat:60.8155594 },
        { lng:26.4232044	, lat:60.8154626 },
        { lng:26.4231883	, lat:60.8153553 },
        { lng:26.4231883	, lat:60.8152586 },
        { lng:26.4231722	, lat:60.8151644 },
        { lng:26.4230541	, lat:60.8151068 },
        { lng:26.4229415	, lat:60.8150964 },
        { lng:26.4227806	, lat:60.8151356 },
        { lng:26.4225928	, lat:60.8152455 },
        { lng:26.4224426	, lat:60.8153266 },
        { lng:26.4223139	, lat:60.8153318 },
        { lng:26.4222012	, lat:60.8152769 },
        { lng:26.4220617	, lat:60.8152062 },
        { lng:26.421933	, lat:60.8151775 },
        { lng:26.4217828	, lat:60.8152141 },
        { lng:26.4216219	, lat:60.8153109 },
        { lng:26.4214448	, lat:60.8154286 },
        { lng:26.4212839	, lat:60.8154469 },
        { lng:26.4211176	, lat:60.8154077 },
        { lng:26.420903	, lat:60.8153213 },
        { lng:26.4206616	, lat:60.8152298 },
        { lng:26.4206133	, lat:60.8151618 },
        { lng:26.4206133	, lat:60.8150624 },
        { lng:26.4207099	, lat:60.8149551 },
        { lng:26.4206884	, lat:60.8148766 },
        { lng:26.420608	, lat:60.8148034 },
        { lng:26.4202754	, lat:60.8146674 },
        { lng:26.4201091	, lat:60.8145863 },
        { lng:26.4199482	, lat:60.814547 },
        { lng:26.4197228	, lat:60.8144607 },
        { lng:26.4193795	, lat:60.8143927 },
        { lng:26.419283	, lat:60.8143535 },
        { lng:26.4191649	, lat:60.8142253 },
        { lng:26.4190577	, lat:60.8141834 },
        { lng:26.4185749	, lat:60.8142698 },
        { lng:26.4184783	, lat:60.8143064 },
        { lng:26.4183013	, lat:60.8143535 },
        { lng:26.4183155	, lat:60.8144105 },
        { lng:26.4183048	, lat:60.8145258 },
        { lng:26.4182852	, lat:60.814636 },
        { lng:26.418253	, lat:60.8147275 },
        { lng:26.4181028	, lat:60.8147903 },
        { lng:26.4179633	, lat:60.8147929 },
        { lng:26.4178077	, lat:60.8147511 },
        { lng:26.4176415	, lat:60.8146464 },
        { lng:26.417325	, lat:60.8144005 },
        { lng:26.4172552	, lat:60.8142802 },
        { lng:26.4173679	, lat:60.8141677 },
        { lng:26.4175771	, lat:60.8140265 },
        { lng:26.4175449	, lat:60.8139715 },
        { lng:26.4174322	, lat:60.8139428 },
        { lng:26.4172606	, lat:60.8139663 },
        { lng:26.4171211	, lat:60.8139637 },
        { lng:26.4170245	, lat:60.8138957 },
        { lng:26.4169602	, lat:60.8138093 },
        { lng:26.4168422	, lat:60.813791 },
        { lng:26.4166866	, lat:60.8138146 },
        { lng:26.416531	, lat:60.8138721 },
        { lng:26.4163379	, lat:60.8139035 },
        { lng:26.4161394	, lat:60.8139349 },
        { lng:26.4159946	, lat:60.8139689 },
        { lng:26.4158766	, lat:60.813948 },
        { lng:26.4157103	, lat:60.8138957 },
        { lng:26.4155976	, lat:60.8137518 },
        { lng:26.4155922	, lat:60.8136419 },
        { lng:26.4156942	, lat:60.8135896 },
        { lng:26.4157639	, lat:60.8135242 },
        { lng:26.4157103	, lat:60.8134693 },
        { lng:26.4154689	, lat:60.8134536 },
        { lng:26.4153562	, lat:60.8134693 },
        { lng:26.4152972	, lat:60.8135634 },
        { lng:26.4151738	, lat:60.8136262 },
        { lng:26.4150558	, lat:60.8136158 },
        { lng:26.4149914	, lat:60.8135791 },
        { lng:26.4149646	, lat:60.8134928 },
        { lng:26.4150451	, lat:60.8134039 },
        { lng:26.4150075	, lat:60.8133202 },
        { lng:26.4149378	, lat:60.8132914 },
        { lng:26.4147876	, lat:60.8133123 },
        { lng:26.4145569	, lat:60.8133856 },
        { lng:26.4143799	, lat:60.8133934 },
        { lng:26.4141492	, lat:60.8133882 },
        { lng:26.4140795	, lat:60.8133385 },
        { lng:26.4139132	, lat:60.8132783 },
        { lng:26.4136568	, lat:60.8130681 },
        { lng:26.4136932	, lat:60.8128336 },
        { lng:26.4136503	, lat:60.8127891 },
        { lng:26.413543	, lat:60.8127734 },
        { lng:26.4134197	, lat:60.812797 },
        { lng:26.413307	, lat:60.8128571 },
        { lng:26.4131514	, lat:60.8129984 },
        { lng:26.4130012	, lat:60.8129748 },
        { lng:26.4126257	, lat:60.8126374 },
        { lng:26.412497	, lat:60.8125694 },
        { lng:26.4122073	, lat:60.8124752 },
        { lng:26.4121966	, lat:60.8124229 },
        { lng:26.4122663	, lat:60.8122214 },
        { lng:26.412159	, lat:60.8121456 },
        { lng:26.4118463	, lat:60.812148 },
        { lng:26.411636	, lat:60.8121788 },
        { lng:26.4114775	, lat:60.8121959 },
        { lng:26.4110539	, lat:60.8122371 },
        { lng:26.4109574	, lat:60.8122188 },
        { lng:26.4109252	, lat:60.8121717 },
        { lng:26.4109467	, lat:60.812088 },
        { lng:26.4110057	, lat:60.8120174 },
        { lng:26.411011	, lat:60.8119755 },
        { lng:26.4109198	, lat:60.811952 },
        { lng:26.4107911	, lat:60.8119493 },
        { lng:26.4106945	, lat:60.8119703 },
        { lng:26.4106433	, lat:60.8119958 },
        { lng:26.4106194	, lat:60.8120697 },
        { lng:26.4105711	, lat:60.8121456 },
        { lng:26.4105014	, lat:60.812224 },
        { lng:26.4103888	, lat:60.8122528 },
        { lng:26.4102761	, lat:60.8122607 },
        { lng:26.4101581	, lat:60.8123104 },
        { lng:26.4100562	, lat:60.8123627 },
        { lng:26.4099489	, lat:60.8123653 },
        { lng:26.4098416	, lat:60.8123522 },
        { lng:26.4097772	, lat:60.8122947 },
        { lng:26.4097128	, lat:60.812211 },
        { lng:26.409568	, lat:60.8121796 },
        { lng:26.4093856	, lat:60.8121508 },
        { lng:26.4092515	, lat:60.8120932 },
        { lng:26.4090852	, lat:60.812054 },
        { lng:26.4089404	, lat:60.8120357 },
        { lng:26.4088384	, lat:60.8120671 },
        { lng:26.4087827	, lat:60.8121272 },
        { lng:26.4085809	, lat:60.8121456 },
        { lng:26.4084898	, lat:60.8121377 },
        { lng:26.4084468	, lat:60.8120645 },
        { lng:26.4084361	, lat:60.8120383 },
        { lng:26.4083235	, lat:60.8120226 },
        { lng:26.408184	, lat:60.8119729 },
        { lng:26.4080713	, lat:60.8119807 },
        { lng:26.4080015	, lat:60.8121717 },
        { lng:26.4079371	, lat:60.8122816 },
        { lng:26.4077923	, lat:60.8122737 },
        { lng:26.4076796	, lat:60.8122162 },
        { lng:26.4076635	, lat:60.8121429 },
        { lng:26.4075455	, lat:60.8120069 },
        { lng:26.4074329	, lat:60.8119807 },
        { lng:26.4073578	, lat:60.8119964 },
        { lng:26.4073191	, lat:60.8120382 },
        { lng:26.4071915	, lat:60.8121246 },
        { lng:26.4070949	, lat:60.8121926 },
        { lng:26.4070949	, lat:60.8122502 },
        { lng:26.4071378	, lat:60.8122999 },
        { lng:26.4071646	, lat:60.812347 },
        { lng:26.4070949	, lat:60.812381 },
        { lng:26.406875	, lat:60.8124019 },
        { lng:26.4067569	, lat:60.8123862 },
        { lng:26.4066389	, lat:60.8123287 },
        { lng:26.4065477	, lat:60.8123156 },
        { lng:26.4064404	, lat:60.8123496 },
        { lng:26.4063171	, lat:60.8124438 },
        { lng:26.406242	, lat:60.8124595 },
        { lng:26.4061347	, lat:60.8124464 },
        { lng:26.4060703	, lat:60.8123784 },
        { lng:26.4059898	, lat:60.8122502 },
        { lng:26.4060006	, lat:60.8121639 },
        { lng:26.4061701	, lat:60.8120926 },
        { lng:26.4061669	, lat:60.8120331 },
        { lng:26.405963	, lat:60.8119546 },
        { lng:26.4056709	, lat:60.811936 },
        { lng:26.4053853	, lat:60.8119408 },
        { lng:26.4052959	, lat:60.8119907 },
        { lng:26.4052066	, lat:60.8120174 },
        { lng:26.4051637	, lat:60.8119677 },
        { lng:26.4052013	, lat:60.8119153 },
        { lng:26.404976	, lat:60.8118447 },
        { lng:26.4048097	, lat:60.8118055 },
        { lng:26.4046648	, lat:60.8118133 },
        { lng:26.4045468	, lat:60.8118369 },
        { lng:26.4042303	, lat:60.8119153 },
        { lng:26.4041016	, lat:60.8119153 },
        { lng:26.404005	, lat:60.8118473 },
        { lng:26.4039889	, lat:60.811706 },
        { lng:26.4039567	, lat:60.8116328 },
        { lng:26.4038333	, lat:60.811536 },
        { lng:26.4036885	, lat:60.8114811 },
        { lng:26.4035544	, lat:60.8114968 },
        { lng:26.4034632	, lat:60.8115517 },
        { lng:26.4033935	, lat:60.8116223 },
        { lng:26.4032808	, lat:60.811638 },
        { lng:26.4031091	, lat:60.811638 },
        { lng:26.4028999	, lat:60.8116773 },
        { lng:26.4027175	, lat:60.8117191 },
        { lng:26.4025566	, lat:60.8116982 },
        { lng:26.4023689	, lat:60.8115936 },
        { lng:26.4021543	, lat:60.8115282 },
        { lng:26.4019182	, lat:60.8114732 },
        { lng:26.4017734	, lat:60.8114078 },
        { lng:26.4016232	, lat:60.8113476 },
        { lng:26.401407	, lat:60.8113236 },
        { lng:26.4010975	, lat:60.8113084 },
        { lng:26.4004484	, lat:60.8111907 },
        { lng:26.3999747	, lat:60.8111064 },
        { lng:26.3997387	, lat:60.8110672 },
        { lng:26.3995794	, lat:60.8110756 },
        { lng:26.3994812	, lat:60.8111038 },
        { lng:26.3992666	, lat:60.811232 },
        { lng:26.3990091	, lat:60.811368 },
        { lng:26.3988536	, lat:60.8114178 },
        { lng:26.3987302	, lat:60.8114125 },
        { lng:26.3985869	, lat:60.8113529 },
        { lng:26.3983868	, lat:60.8112843 },
        { lng:26.3981256	, lat:60.8112456 },
        { lng:26.3979899	, lat:60.8112346 },
        { lng:26.3978987	, lat:60.8111901 },
        { lng:26.3978772	, lat:60.8111195 },
        { lng:26.3978826	, lat:60.8110253 },
        { lng:26.3977485	, lat:60.8109311 },
        { lng:26.3976519	, lat:60.810816 },
        { lng:26.3976535	, lat:60.8107224 },
        { lng:26.3977324	, lat:60.8106224 },
        { lng:26.3977324	, lat:60.8105361 },
        { lng:26.3976626	, lat:60.8104838 },
        { lng:26.3975285	, lat:60.8104602 },
        { lng:26.3973676	, lat:60.8104471 },
        { lng:26.3971745	, lat:60.8104602 },
        { lng:26.3969132	, lat:60.8105235 },
        { lng:26.3967829	, lat:60.8105832 },
        { lng:26.3966343	, lat:60.8106517 },
        { lng:26.3965576	, lat:60.8107716 },
        { lng:26.3965039	, lat:60.810871 },
        { lng:26.3963929	, lat:60.810984 },
        { lng:26.3963323	, lat:60.8110201 },
        { lng:26.3962733	, lat:60.8110279 },
        { lng:26.3961515	, lat:60.8110075 },
        { lng:26.3960174	, lat:60.8109631 },
        { lng:26.3957974	, lat:60.8108453 },
        { lng:26.3956349	, lat:60.8107428 },
        { lng:26.3955437	, lat:60.8105937 },
        { lng:26.395372	, lat:60.8105047 },
        { lng:26.3953093	, lat:60.8104267 },
        { lng:26.395356	, lat:60.810332 },
        { lng:26.3953184	, lat:60.8102692 },
        { lng:26.3952111	, lat:60.8101934 },
        { lng:26.3951736	, lat:60.8101149 },
        { lng:26.3952878	, lat:60.8099166 },
        { lng:26.3953238	, lat:60.8098533 },
        { lng:26.3952986	, lat:60.8098119 },
        { lng:26.3951897	, lat:60.8097852 },
        { lng:26.3950019	, lat:60.8097826 },
        { lng:26.3947927	, lat:60.8098035 },
        { lng:26.3945314	, lat:60.8098485 },
        { lng:26.3943582	, lat:60.8098794 },
        { lng:26.3941506	, lat:60.8098799 },
        { lng:26.3940218	, lat:60.8099009 },
        { lng:26.3939505	, lat:60.8099658 },
        { lng:26.3938448	, lat:60.8100291 },
        { lng:26.3937198	, lat:60.8100547 },
        { lng:26.3936356	, lat:60.8100552 },
        { lng:26.3935696	, lat:60.8100207 },
        { lng:26.3935122	, lat:60.8099009 },
        { lng:26.3933888	, lat:60.809825 },
        { lng:26.3932102	, lat:60.8097774 },
        { lng:26.392942	, lat:60.8097669 },
        { lng:26.3925986	, lat:60.8097774 },
        { lng:26.3923894	, lat:60.8097774 },
        { lng:26.3922982	, lat:60.8097565 },
        { lng:26.39225	, lat:60.8097015 },
        { lng:26.3922178	, lat:60.8095628 },
        { lng:26.3921765	, lat:60.8095084 },
        { lng:26.3920568	, lat:60.8094608 },
        { lng:26.3918691	, lat:60.8094503 },
        { lng:26.3916454	, lat:60.8094509 },
        { lng:26.3914576	, lat:60.8094142 },
        { lng:26.3912844	, lat:60.8093614 },
        { lng:26.3911342	, lat:60.8093562 },
        { lng:26.3910161	, lat:60.8093719 },
        { lng:26.3908498	, lat:60.8093928 },
        { lng:26.3905457	, lat:60.8094038 },
        { lng:26.3904368	, lat:60.8094268 },
        { lng:26.3902598	, lat:60.8095027 },
        { lng:26.3901273	, lat:60.8095137 },
        { lng:26.3899701	, lat:60.8094687 },
        { lng:26.3898199	, lat:60.8093954 },
        { lng:26.3896536	, lat:60.8093457 },
        { lng:26.3895463	, lat:60.8092803 },
        { lng:26.3895034	, lat:60.8091861 },
        { lng:26.389446	, lat:60.8091055 },
        { lng:26.3893532	, lat:60.8090736 },
        { lng:26.3892582	, lat:60.8090794 },
        { lng:26.38916	, lat:60.8091259 },
        { lng:26.3890544	, lat:60.8091997 },
        { lng:26.3889417	, lat:60.8093358 },
        { lng:26.388813	, lat:60.8093828 },
        { lng:26.3887148	, lat:60.8094451 },
        { lng:26.3886359	, lat:60.8095267 },
        { lng:26.388468	, lat:60.8095628 },
        { lng:26.3883194	, lat:60.8095608 },
        { lng:26.3882266	, lat:60.8095288 },
        { lng:26.3881354	, lat:60.809432 },
        { lng:26.3879638	, lat:60.809262 },
        { lng:26.3877294	, lat:60.8091395 },
        { lng:26.3875936	, lat:60.8090631 },
        { lng:26.3875845	, lat:60.808993 },
        { lng:26.3879101	, lat:60.8088669 },
        { lng:26.3880067	, lat:60.808812 },
        { lng:26.3880083	, lat:60.8087602 },
        { lng:26.3877653	, lat:60.8086681 },
        { lng:26.3876151	, lat:60.8086262 },
        { lng:26.3874343	, lat:60.8086189 },
        { lng:26.3872771	, lat:60.8086183 },
        { lng:26.3871055	, lat:60.8085791 },
        { lng:26.3870143	, lat:60.8085529 },
        { lng:26.3868802	, lat:60.8085739 },
        { lng:26.3867208	, lat:60.8085665 },
        { lng:26.3865476	, lat:60.8085137 },
        { lng:26.3864564	, lat:60.8084457 },
        { lng:26.3864617	, lat:60.808375 },
        { lng:26.3866173	, lat:60.8082782 },
        { lng:26.3866243	, lat:60.808229 },
        { lng:26.3865545	, lat:60.8081819 },
        { lng:26.3864242	, lat:60.8081631 },
        { lng:26.3862364	, lat:60.8081814 },
        { lng:26.3860288	, lat:60.8082343 },
        { lng:26.3858502	, lat:60.8083148 },
        { lng:26.3857805	, lat:60.8083201 },
        { lng:26.3857161	, lat:60.8082913 },
        { lng:26.3856785	, lat:60.8081474 },
        { lng:26.3856785	, lat:60.8079852 },
        { lng:26.3857553	, lat:60.8078915 },
        { lng:26.3858143	, lat:60.8078287 },
        { lng:26.3856946	, lat:60.8077994 },
        { lng:26.3854908	, lat:60.8077863 },
        { lng:26.3853567	, lat:60.807768 },
        { lng:26.3853046	, lat:60.8076874 },
        { lng:26.3853529	, lat:60.8076037 },
        { lng:26.3854602	, lat:60.80752 },
        { lng:26.3854157	, lat:60.8074881 },
        { lng:26.3852655	, lat:60.8074776 },
        { lng:26.3850938	, lat:60.8075116 },
        { lng:26.3850134	, lat:60.8075404 },
        { lng:26.3848792	, lat:60.807543 },
        { lng:26.3848004	, lat:60.8075252 },
        { lng:26.3847344	, lat:60.8074671 },
        { lng:26.384611	, lat:60.8074462 },
        { lng:26.3845268	, lat:60.8074624 },
        { lng:26.3844571	, lat:60.8075174 },
        { lng:26.3843283	, lat:60.8075435 },
        { lng:26.3842248	, lat:60.8075221 },
        { lng:26.3841926	, lat:60.807441 },
        { lng:26.3842087	, lat:60.8072159 },
        { lng:26.3842087	, lat:60.8071139 },
        { lng:26.3839405	, lat:60.8069962 },
        { lng:26.3837313	, lat:60.8069046 },
        { lng:26.3835864	, lat:60.8069124 },
        { lng:26.383529	, lat:60.8069653 },
        { lng:26.3834754	, lat:60.8070569 },
        { lng:26.3833879	, lat:60.8071191 },
        { lng:26.3832393	, lat:60.8071275 },
        { lng:26.3831358	, lat:60.807106 },
        { lng:26.3830446	, lat:60.8070119 },
        { lng:26.3830178	, lat:60.8069307 },
        { lng:26.3829802	, lat:60.8068392 },
        { lng:26.3830661	, lat:60.8067267 },
        { lng:26.383168	, lat:60.8066612 },
        { lng:26.3832216	, lat:60.8065932 },
        { lng:26.3831696	, lat:60.8065388 },
        { lng:26.3830178	, lat:60.8065095 },
        { lng:26.3829212	, lat:60.8065173 },
        { lng:26.3828263	, lat:60.8065859 },
        { lng:26.3827227	, lat:60.8066429 },
        { lng:26.3824921	, lat:60.8066665 },
        { lng:26.3823435	, lat:60.8066879 },
        { lng:26.3822071	, lat:60.8067758 },
        { lng:26.3820292	, lat:60.8068507 },
        { lng:26.3818067	, lat:60.8069773 },
        { lng:26.3818805	, lat:60.8070406 },
        { lng:26.3819841	, lat:60.8071013 },
        { lng:26.3822523	, lat:60.8071877 },
        { lng:26.3823633	, lat:60.8071479 },
        { lng:26.38244	, lat:60.8071458 },
        { lng:26.382499	, lat:60.807206 },
        { lng:26.3824813	, lat:60.8073441 },
        { lng:26.3823794	, lat:60.8073965 },
        { lng:26.3823687	, lat:60.8075221 },
        { lng:26.3826262	, lat:60.8075639 },
        { lng:26.3826262	, lat:60.8076032 },
        { lng:26.3823955	, lat:60.8076032 },
        { lng:26.3821235	, lat:60.8075435 },
        { lng:26.3818966	, lat:60.8075038 },
        { lng:26.3816713	, lat:60.807509 },
        { lng:26.3816003	, lat:60.8075211 },
        { lng:26.3812904	, lat:60.8075744 },
        { lng:26.3812261	, lat:60.8075482 },
        { lng:26.3812904	, lat:60.807441 },
        { lng:26.3812851	, lat:60.8073389 },
        { lng:26.3812207	, lat:60.8072186 },
        { lng:26.3812422	, lat:60.8071374 },
        { lng:26.3813495	, lat:60.8070302 },
        { lng:26.3813387	, lat:60.8069098 },
        { lng:26.3812851	, lat:60.8068156 },
        { lng:26.3811134	, lat:60.8067633 },
        { lng:26.3808452	, lat:60.80669 },
        { lng:26.3807663	, lat:60.8065126 },
        { lng:26.3807878	, lat:60.806319 },
        { lng:26.380813	, lat:60.8061929 },
        { lng:26.3805984	, lat:60.8061039 },
        { lng:26.3802728	, lat:60.8060521 },
        { lng:26.3794934	, lat:60.8060725 },
        { lng:26.3792573	, lat:60.8061458 },
        { lng:26.3789961	, lat:60.8061515 },
        { lng:26.3788711	, lat:60.8061091 },
        { lng:26.378914	, lat:60.8060254 },
        { lng:26.3790175	, lat:60.8058899 },
        { lng:26.3792144	, lat:60.8057638 },
        { lng:26.3793539	, lat:60.8057114 },
        { lng:26.3796758	, lat:60.8056696 },
        { lng:26.3798903	, lat:60.8054864 },
        { lng:26.3798689	, lat:60.8054079 },
        { lng:26.3797364	, lat:60.8053613 },
        { lng:26.3795577	, lat:60.8053608 },
        { lng:26.3793072	, lat:60.8053875 },
        { lng:26.3791715	, lat:60.8054236 },
        { lng:26.3790206	, lat:60.8055012 },
        { lng:26.3788647	, lat:60.8055171 },
        { lng:26.3795219	, lat:60.8040216 },
        { lng:26.380509	, lat:60.8018129 },
        { lng:26.3831268	, lat:60.7985364 },
        { lng:26.3842319	, lat:60.7970393 },
        { lng:26.3858626	, lat:60.7947412 },
        { lng:26.3889418	, lat:60.7904063 },
        { lng:26.391683	, lat:60.7866468 },
        { lng:26.3952435	, lat:60.7816114 },
        { lng:26.3958259	, lat:60.7808141 },
        { lng:26.3968919	, lat:60.7794094 },
        { lng:26.3938556	, lat:60.7782676 },
        { lng:26.3806484	, lat:60.7733595 },
        { lng:26.3683854	, lat:60.7687754 },
        { lng:26.369587	, lat:60.767256 },
        { lng:26.3711421	, lat:60.7652387 },
        { lng:26.3719882	, lat:60.7642138 },
        { lng:26.3724101	, lat:60.7636462 },
        { lng:26.3726233	, lat:60.7633942 },
        { lng:26.3732563	, lat:60.7625925 },
        { lng:26.3739827	, lat:60.7617031 },
        { lng:26.3741243	, lat:60.7615488 },
        { lng:26.37429	, lat:60.7613639 },
        { lng:26.3744257	, lat:60.761099 },
        { lng:26.3681556	, lat:60.7603939 },
        { lng:26.3638471	, lat:60.7599094 },
        { lng:26.3561223	, lat:60.7590132 },
        { lng:26.3518667	, lat:60.7585043 },
        { lng:26.344224	, lat:60.7576191 },
        { lng:26.339278	, lat:60.7570583 },
        { lng:26.3334415	, lat:60.7563612 },
        { lng:26.3246798	, lat:60.7554277 },
        { lng:26.3243472	, lat:60.7554172 },
        { lng:26.3218147	, lat:60.7551224 },
        { lng:26.3169703	, lat:60.7546256 },
        { lng:26.3128277	, lat:60.7541923 },
        { lng:26.3108277	, lat:60.7539747 },
        { lng:26.3050568	, lat:60.7533468 },
        { lng:26.3050085	, lat:60.7532784 },
        { lng:26.3048867	, lat:60.7532351 },
        { lng:26.3046979	, lat:60.7532037 },
        { lng:26.3044104	, lat:60.7531995 },
        { lng:26.3042521	, lat:60.7531633 },
        { lng:26.3040697	, lat:60.753048 },
        { lng:26.3037763	, lat:60.7528179 },
        { lng:26.3034974	, lat:60.7526554 },
        { lng:26.3033724	, lat:60.7523666 },
        { lng:26.3032651	, lat:60.7521674 },
        { lng:26.3030543	, lat:60.7519729 },
        { lng:26.3027608	, lat:60.7518843 },
        { lng:26.3023531	, lat:60.7518057 },
        { lng:26.30216	, lat:60.7517165 },
        { lng:26.3021278	, lat:60.751596 },
        { lng:26.3022888	, lat:60.7513391 },
        { lng:26.3025103	, lat:60.7510513 },
        { lng:26.302557	, lat:60.7508781 },
        { lng:26.3024712	, lat:60.750747 },
        { lng:26.302285	, lat:60.7506686 },
        { lng:26.3020061	, lat:60.7506005 },
        { lng:26.3016987	, lat:60.7505583 },
        { lng:26.3010979	, lat:60.7505111 },
        { lng:26.3007722	, lat:60.7504694 },
        { lng:26.3006258	, lat:60.7503591 },
        { lng:26.3005829	, lat:60.7502438 },
        { lng:26.3005469	, lat:60.7500396 },
        { lng:26.3005223	, lat:60.749929 },
        { lng:26.3001918	, lat:60.7495433 },
        { lng:26.3001243	, lat:60.7494523 },
        { lng:26.3000883	, lat:60.7492343 },
        { lng:26.3003946	, lat:60.7487032 },
        { lng:26.3004442	, lat:60.7485428 },
        { lng:26.3004705	, lat:60.7483517 },
        { lng:26.3005318	, lat:60.7482095 },
        { lng:26.3007256	, lat:60.7479872 },
        { lng:26.3010839	, lat:60.7477996 },
        { lng:26.3014919	, lat:60.7476899 },
        { lng:26.3018165	, lat:60.7475392 },
        { lng:26.3019068	, lat:60.7474658 },
        { lng:26.3019428	, lat:60.7473763 },
        { lng:26.3018772	, lat:60.7472453 },
        { lng:26.301706	, lat:60.7471428 },
        { lng:26.3013876	, lat:60.7470424 },
        { lng:26.301078	, lat:60.746923 },
        { lng:26.300923	, lat:60.7468251 },
        { lng:26.3008592	, lat:60.7467081 },
        { lng:26.3008906	, lat:60.7465429 },
        { lng:26.3010037	, lat:60.7462876 },
        { lng:26.3010598	, lat:60.746099 },
        { lng:26.3010349	, lat:60.7458844 },
        { lng:26.3008474	, lat:60.7455478 },
        { lng:26.3004649	, lat:60.745109 },
        { lng:26.3003563	, lat:60.7448941 },
        { lng:26.3002073	, lat:60.7444997 },
        { lng:26.30008	, lat:60.7440545 },
        { lng:26.3000159	, lat:60.7436796 },
        { lng:26.3000277	, lat:60.743331 },
        { lng:26.3001004	, lat:60.7429907 },
        { lng:26.3000784	, lat:60.7424556 },
        { lng:26.3000156	, lat:60.7421859 },
        { lng:26.2999066	, lat:60.741964 },
        { lng:26.299712	, lat:60.741736 },
        { lng:26.2995559	, lat:60.7415436 },
        { lng:26.2994811	, lat:60.7413861 },
        { lng:26.2994234	, lat:60.7412291 },
        { lng:26.2993817	, lat:60.7410687 },
        { lng:26.2994749	, lat:60.7403708 },
        { lng:26.2995559	, lat:60.7400716 },
        { lng:26.2995698	, lat:60.7397815 },
        { lng:26.2995232	, lat:60.7395871 },
        { lng:26.2994958	, lat:60.7390156 },
        { lng:26.2993997	, lat:60.7387426 },
        { lng:26.2992469	, lat:60.7383362 },
        { lng:26.2991474	, lat:60.7380821 },
        { lng:26.2990956	, lat:60.7378276 },
        { lng:26.2991299	, lat:60.7373322 },
        { lng:26.2990285	, lat:60.7369845 },
        { lng:26.2987421	, lat:60.736433 },
        { lng:26.2985316	, lat:60.7360334 },
        { lng:26.2984283	, lat:60.7356876 },
        { lng:26.2982451	, lat:60.7352325 },
        { lng:26.2981066	, lat:60.7347185 },
        { lng:26.2980522	, lat:60.734397 },
        { lng:26.2979817	, lat:60.7333307 },
        { lng:26.2978859	, lat:60.7329814 },
        { lng:26.2979128	, lat:60.7324339 },
        { lng:26.2979304	, lat:60.7323793 },
        { lng:26.2980299	, lat:60.7320703 },
        { lng:26.2981625	, lat:60.7316355 },
        { lng:26.2980662	, lat:60.7309339 },
        { lng:26.2983575	, lat:60.7309187 },
        { lng:26.3004592	, lat:60.7309424 },
        { lng:26.3064414	, lat:60.7273023 },
        { lng:26.3067166	, lat:60.727125 },
        { lng:26.312928	, lat:60.7233916 },
        { lng:26.3132317	, lat:60.7231015 },
        { lng:26.3229327	, lat:60.7171737 },
        { lng:26.3288357	, lat:60.7135153 },
        { lng:26.3309374	, lat:60.7122404 },
        { lng:26.3314781	, lat:60.7119124 },
        { lng:26.3310187	, lat:60.7109795 },
        { lng:26.3309128	, lat:60.7106606 },
        { lng:26.3308645	, lat:60.7093967 },
        { lng:26.3304182	, lat:60.7094277 },
        { lng:26.3302868	, lat:60.7091125 },
        { lng:26.3274786	, lat:60.7023788 },
        { lng:26.3250816	, lat:60.6966297 },
        { lng:26.3297026	, lat:60.689487 },
        { lng:26.3336539	, lat:60.6833102 },
        { lng:26.3384379	, lat:60.6758299 },
        { lng:26.3557596	, lat:60.6702619 },
        { lng:26.3620885	, lat:60.6682274 },
        { lng:26.3584602	, lat:60.6665592 },
        { lng:26.3470894	, lat:60.6613555 },
        { lng:26.3530418	, lat:60.6605186 },
        { lng:26.3862411	, lat:60.6558206 },
        { lng:26.411912	, lat:60.6522278 },
        { lng:26.4313955	, lat:60.649547 },
        { lng:26.443825	, lat:60.6447292 },
        { lng:26.458429	, lat:60.6390857 },
        { lng:26.4619319	, lat:60.6353143 },
        { lng:26.4640169	, lat:60.6330947 },
        { lng:26.4744743	, lat:60.6220207 },
        { lng:26.4753634	, lat:60.6210789 },
        { lng:26.477536	, lat:60.6188303 },
        { lng:26.477963	, lat:60.6183834 },
        { lng:26.4782649	, lat:60.6180441 },
        { lng:26.4818479	, lat:60.6142189 },
        { lng:26.481835	, lat:60.6124016 },
        { lng:26.4818307	, lat:60.6117887 },
        { lng:26.4818812	, lat:60.6094094 },
        { lng:26.4829627	, lat:60.6008427 },
        { lng:26.4834471	, lat:60.5968459 },
        { lng:26.4945819	, lat:60.5976776 },
        { lng:26.5123065	, lat:60.598978 },
        { lng:26.5181682	, lat:60.5981297 },
        { lng:26.5185014	, lat:60.5980892 },
        { lng:26.5300547	, lat:60.5964418 },
        { lng:26.5372891	, lat:60.5953702 },
        { lng:26.5387514	, lat:60.5951735 },
        { lng:26.5391397	, lat:60.5949455 },
        { lng:26.5417865	, lat:60.5750065 },
        { lng:26.5615222	, lat:60.6026206 },
        { lng:26.563881	, lat:60.6059355 },
        { lng:26.5643756	, lat:60.6065358 },
        { lng:26.5646744	, lat:60.6068386 },
        { lng:26.5648696	, lat:60.6070355 },
        { lng:26.5651089	, lat:60.6072024 },
        { lng:26.5652372	, lat:60.6073235 },
        { lng:26.5657377	, lat:60.6078536 },
        { lng:26.5665316	, lat:60.6086103 },
        { lng:26.5670776	, lat:60.6089416 },
        { lng:26.5675455	, lat:60.6090786 },
        { lng:26.5684489	, lat:60.6092045 },
        { lng:26.5687116	, lat:60.6092657 },
        { lng:26.5689047	, lat:60.6094219 },
        { lng:26.5690481	, lat:60.6096894 },
        { lng:26.5691676	, lat:60.6098562 },
        { lng:26.5696303	, lat:60.6100906 },
        { lng:26.5697428	, lat:60.6102334 },
        { lng:26.5698336	, lat:60.6104113 },
        { lng:26.5698914	, lat:60.6105247 },
        { lng:26.5700543	, lat:60.6107711 },
        { lng:26.5703821	, lat:60.6109537 },
        { lng:26.5706493	, lat:60.6110502 },
        { lng:26.5708972	, lat:60.6110904 },
        { lng:26.5711374	, lat:60.6110427 },
        { lng:26.571315	, lat:60.6109825 },
        { lng:26.5715185	, lat:60.6109583 },
        { lng:26.5717661	, lat:60.6109898 },
        { lng:26.5720027	, lat:60.6110794 },
        { lng:26.5721723	, lat:60.6112634 },
        { lng:26.5722705	, lat:60.6116693 },
        { lng:26.5723868	, lat:60.6118427 },
        { lng:26.572581	, lat:60.6119536 },
        { lng:26.5728122	, lat:60.6120791 },
        { lng:26.5732479	, lat:60.6121724 },
        { lng:26.574414	, lat:60.6122484 },
        { lng:26.5747069	, lat:60.6122856 },
        { lng:26.5758201	, lat:60.6125107 },
        { lng:26.5761768	, lat:60.6126331 },
        { lng:26.5763565	, lat:60.6127799 },
        { lng:26.5765571	, lat:60.6131323 },
        { lng:26.5766908	, lat:60.6132962 },
        { lng:26.5768612	, lat:60.6134243 },
        { lng:26.5775753	, lat:60.6137378 },
        { lng:26.5782733	, lat:60.6141272 },
        { lng:26.5786075	, lat:60.6143195 },
        { lng:26.5792479	, lat:60.6147441 },
        { lng:26.5794254	, lat:60.6150233 },
        { lng:26.57947	, lat:60.6151847 },
        { lng:26.5793321	, lat:60.6153835 },
        { lng:26.5789985	, lat:60.6156552 },
        { lng:26.5787093	, lat:60.6158533 },
        { lng:26.5785367	, lat:60.615976 },
        { lng:26.5784282	, lat:60.6160968 },
        { lng:26.5784743	, lat:60.6162902 },
        { lng:26.5786717	, lat:60.6164176 },
        { lng:26.5789056	, lat:60.6164795 },
        { lng:26.5796015	, lat:60.6165406 },
        { lng:26.5798669	, lat:60.6166229 },
        { lng:26.57992	, lat:60.616802 },
        { lng:26.5798563	, lat:60.6170238 },
        { lng:26.5798503	, lat:60.6172998 },
        { lng:26.579905	, lat:60.6175459 },
        { lng:26.5800183	, lat:60.6177445 },
        { lng:26.5801926	, lat:60.6179102 },
        { lng:26.5807135	, lat:60.6182195 },
        { lng:26.5811616	, lat:60.618575 },
        { lng:26.5813395	, lat:60.6188172 },
        { lng:26.5813885	, lat:60.6189117 },
        { lng:26.5814441	, lat:60.6190185 },
        { lng:26.5814957	, lat:60.6193497 },
        { lng:26.5814113	, lat:60.6194867 },
        { lng:26.5810552	, lat:60.6196239 },
        { lng:26.5805986	, lat:60.6196872 },
        { lng:26.5803073	, lat:60.6197817 },
        { lng:26.580169	, lat:60.6198837 },
        { lng:26.5800617	, lat:60.6200715 },
        { lng:26.5800955	, lat:60.6203283 },
        { lng:26.5801732	, lat:60.6205218 },
        { lng:26.5803622	, lat:60.620699 },
        { lng:26.5805476	, lat:60.6209318 },
        { lng:26.5808336	, lat:60.6211276 },
        { lng:26.581147	, lat:60.6212077 },
        { lng:26.5816201	, lat:60.6212382 },
        { lng:26.5819076	, lat:60.6212922 },
        { lng:26.5820681	, lat:60.6213663 },
        { lng:26.5821759	, lat:60.6214922 },
        { lng:26.5821223	, lat:60.6217695 },
        { lng:26.5820745	, lat:60.6220675 },
        { lng:26.58214	, lat:60.6222702 },
        { lng:26.5823519	, lat:60.6224481 },
        { lng:26.5825305	, lat:60.6225719 },
        { lng:26.582555	, lat:60.622705 },
        { lng:26.5824607	, lat:60.6228188 },
        { lng:26.582187	, lat:60.6230266 },
        { lng:26.5821591	, lat:60.6231855 },
        { lng:26.5822033	, lat:60.6233865 },
        { lng:26.5823068	, lat:60.6235816 },
        { lng:26.5823791	, lat:60.6236829 },
        { lng:26.5824464	, lat:60.6237772 },
        { lng:26.5827455	, lat:60.623921 },
        { lng:26.5831469	, lat:60.6239925 },
        { lng:26.5836026	, lat:60.624003 },
        { lng:26.584115	, lat:60.6240771 },
        { lng:26.5845918	, lat:60.6242339 },
        { lng:26.5848907	, lat:60.6242884 },
        { lng:26.5858999	, lat:60.6243706 },
        { lng:26.5862597	, lat:60.6244441 },
        { lng:26.5865408	, lat:60.624536 },
        { lng:26.5870118	, lat:60.624897 },
        { lng:26.5873353	, lat:60.6250995 },
        { lng:26.587604	, lat:60.6252659 },
        { lng:26.5880723	, lat:60.6256612 },
        { lng:26.5883519	, lat:60.6258507 },
        { lng:26.588634	, lat:60.6259343 },
        { lng:26.5889414	, lat:60.6259385 },
        { lng:26.5894452	, lat:60.6258549 },
        { lng:26.589701	, lat:60.6257854 },
        { lng:26.5900588	, lat:60.6257775 },
        { lng:26.5904448	, lat:60.6258934 },
        { lng:26.5905862	, lat:60.6259925 },
        { lng:26.5906811	, lat:60.6261969 },
        { lng:26.5907932	, lat:60.626439 },
        { lng:26.5910201	, lat:60.626545 },
        { lng:26.5913768	, lat:60.62667 },
        { lng:26.5922835	, lat:60.6268753 },
        { lng:26.5931896	, lat:60.6271911 },
        { lng:26.5937141	, lat:60.6273734 },
        { lng:26.5939507	, lat:60.6274778 },
        { lng:26.5941857	, lat:60.6275869 },
        { lng:26.5942892	, lat:60.6277179 },
        { lng:26.5943628	, lat:60.6281347 },
        { lng:26.5943248	, lat:60.6283976 },
        { lng:26.5943118	, lat:60.628487 },
        { lng:26.5943027	, lat:60.628802 },
        { lng:26.5943903	, lat:60.6290523 },
        { lng:26.5946282	, lat:60.6292862 },
        { lng:26.5947935	, lat:60.6294274 },
        { lng:26.5948445	, lat:60.6296249 },
        { lng:26.5947984	, lat:60.6298164 },
        { lng:26.5947419	, lat:60.6300636 },
        { lng:26.5947939	, lat:60.6303863 },
        { lng:26.5947264	, lat:60.6305963 },
        { lng:26.5946287	, lat:60.6308807 },
        { lng:26.5945763	, lat:60.6310069 },
        { lng:26.5943294	, lat:60.6311336 },
        { lng:26.5940939	, lat:60.6312543 },
        { lng:26.5939288	, lat:60.631396 },
        { lng:26.5939491	, lat:60.6316108 },
        { lng:26.5944459	, lat:60.6320245 },
        { lng:26.5946899	, lat:60.6322825 },
        { lng:26.5947918	, lat:60.6325013 },
        { lng:26.5949245	, lat:60.6326508 },
        { lng:26.5950848	, lat:60.6328203 },
        { lng:26.5951341	, lat:60.6330709 },
        { lng:26.5951464	, lat:60.6332853 },
        { lng:26.5951432	, lat:60.6335644 },
        { lng:26.5952752	, lat:60.6338799 },
        { lng:26.5954345	, lat:60.6341363 },
        { lng:26.5955305	, lat:60.6342778 },
        { lng:26.5955654	, lat:60.6344238 },
        { lng:26.5955783	, lat:60.6346091 },
        { lng:26.5956035	, lat:60.6347732 },
        { lng:26.5957145	, lat:60.6348897 },
        { lng:26.5962359	, lat:60.6351375 },
        { lng:26.5965509	, lat:60.6352744 },
        { lng:26.5966367	, lat:60.6353746 },
        { lng:26.5965846	, lat:60.6355166 },
        { lng:26.5962386	, lat:60.6357688 },
        { lng:26.5959816	, lat:60.6359822 },
        { lng:26.5957193	, lat:60.6362421 },
        { lng:26.5955826	, lat:60.6363884 },
        { lng:26.595325	, lat:60.6367092 },
        { lng:26.5952317	, lat:60.6369683 },
        { lng:26.5952751	, lat:60.6371106 },
        { lng:26.5956295	, lat:60.6375162 },
        { lng:26.5957579	, lat:60.6376204 },
        { lng:26.5963805	, lat:60.6379165 },
        { lng:26.5967043	, lat:60.6381792 },
        { lng:26.5967655	, lat:60.6383622 },
        { lng:26.5967226	, lat:60.6385093 },
        { lng:26.5964018	, lat:60.6388099 },
        { lng:26.5962204	, lat:60.6390097 },
        { lng:26.5962087	, lat:60.6390555 },
        { lng:26.5961796	, lat:60.6391699 },
        { lng:26.5962746	, lat:60.6392542 },
        { lng:26.5965418	, lat:60.6394199 },
        { lng:26.5967117	, lat:60.6396218 },
        { lng:26.5968211	, lat:60.6398968 },
        { lng:26.5969248	, lat:60.6402134 },
        { lng:26.5970974	, lat:60.6407088 },
        { lng:26.5972563	, lat:60.6411073 },
        { lng:26.5972772	, lat:60.6413964 },
        { lng:26.5974017	, lat:60.6415374 },
        { lng:26.597671	, lat:60.6416852 },
        { lng:26.5978652	, lat:60.641803 },
        { lng:26.5979982	, lat:60.6419726 },
        { lng:26.5980583	, lat:60.6422472 },
        { lng:26.5980744	, lat:60.6425233 },
        { lng:26.5982343	, lat:60.6427074 },
        { lng:26.598488	, lat:60.6428355 },
        { lng:26.598827	, lat:60.6429231 },
        { lng:26.5991049	, lat:60.6429599 },
        { lng:26.5996827	, lat:60.6429499 },
        { lng:26.5999487	, lat:60.6429539 },
        { lng:26.6001756	, lat:60.6430012 },
        { lng:26.6009052	, lat:60.6432539 },
        { lng:26.6012035	, lat:60.6433917 },
        { lng:26.6014738	, lat:60.643448 },
        { lng:26.6017742	, lat:60.6434299 },
        { lng:26.602065	, lat:60.6433907 },
        { lng:26.6023847	, lat:60.6433823 },
        { lng:26.6026245	, lat:60.6434412 },
        { lng:26.6028595	, lat:60.6436119 },
        { lng:26.6030032	, lat:60.6438049 },
        { lng:26.6030494	, lat:60.6440429 },
        { lng:26.6030504	, lat:60.6443953 },
        { lng:26.6037205	, lat:60.6445073 },
        { lng:26.6071163	, lat:60.6450974 },
        { lng:26.6165323	, lat:60.6466968 },
        { lng:26.6262076	, lat:60.6483668 },
        { lng:26.6400156	, lat:60.6507806 },
        { lng:26.6490997	, lat:60.6523676 },
        { lng:26.6585014	, lat:60.6514799 },
        { lng:26.6629431	, lat:60.651054 },
        { lng:26.6652498	, lat:60.6508542 },
        { lng:26.6700134	, lat:60.6504651 },
        { lng:26.670582	, lat:60.6503862 },
        { lng:26.6788003	, lat:60.6496657 },
        { lng:26.6827807	, lat:60.6492924 },
        { lng:26.6872439	, lat:60.6446874 },
        { lng:26.6915145	, lat:60.6403002 },
        { lng:26.6963312	, lat:60.6353694 },
        { lng:26.7018673	, lat:60.6296659 },
        { lng:26.7056117	, lat:60.6257455 },
        { lng:26.7108044	, lat:60.6206087 },
        { lng:26.7114052	, lat:60.6199455 },
        { lng:26.7150185	, lat:60.61619 },
        { lng:26.7172417	, lat:60.6138708 },
        { lng:26.7201814	, lat:60.6108751 },
        { lng:26.7226813	, lat:60.6082741 },
        { lng:26.7274878	, lat:60.6032399 },
        { lng:26.7283997	, lat:60.6022919 },
        { lng:26.7294619	, lat:60.601128 },
        { lng:26.7319724	, lat:60.5985209 },
        { lng:26.7329737	, lat:60.5974926 },
        { lng:26.7364142	, lat:60.5939593 },
        { lng:26.7374889	, lat:60.5927621 },
        { lng:26.7382485	, lat:60.5919856 },
        { lng:26.7394719	, lat:60.5907195 },
        { lng:26.7421326	, lat:60.5879113 },
        { lng:26.7446432	, lat:60.5852399 },
        { lng:26.7465422	, lat:60.5832797 },
        { lng:26.7504475	, lat:60.5791797 },
        { lng:26.7521319	, lat:60.5774405 },
        { lng:26.7538592	, lat:60.5757223 },
        { lng:26.754072	, lat:60.5757385 },
        { lng:26.7568419	, lat:60.5753428 },
        { lng:26.7627371	, lat:60.5754568 },
        { lng:26.765709	, lat:60.5764108 },
        { lng:26.7688525	, lat:60.5781448 },
        { lng:26.7717922	, lat:60.5800315 },
        { lng:26.7739809	, lat:60.5811435 },
        { lng:26.7756117	, lat:60.5826928 },
        { lng:26.7771019	, lat:60.5828978 },
        { lng:26.7782617	, lat:60.5843158 },
        { lng:26.777736	, lat:60.5850904 },
        { lng:26.7763198	, lat:60.5860283 },
        { lng:26.775837	, lat:60.5863339 },
        { lng:26.7757941	, lat:60.5887102 },
        { lng:26.7771888	, lat:60.589511 },
        { lng:26.78015	, lat:60.5895637 },
        { lng:26.7830361	, lat:60.5910072 },
        { lng:26.7843772	, lat:60.5918501 },
        { lng:26.7843986	, lat:60.593931 },
        { lng:26.7843986	, lat:60.5956219 },
        { lng:26.7824996	, lat:60.5973075 },
        { lng:26.7807186	, lat:60.5989982 },
        { lng:26.7821885	, lat:60.6017528 },
        { lng:26.7866839	, lat:60.6045123 },
        { lng:26.7864264	, lat:60.609462 },
        { lng:26.783948	, lat:60.6107152 },
        { lng:26.7838944	, lat:60.6114996 },
        { lng:26.7828322	, lat:60.6160482 },
        { lng:26.7815447	, lat:60.6184697 },
        { lng:26.7789613	, lat:60.6191966 },
        { lng:26.7756331	, lat:60.6201329 },
        { lng:26.7728221	, lat:60.6204119 },
        { lng:26.7714547	, lat:60.6205416 },
        { lng:26.7709982	, lat:60.6205383 },
        { lng:26.7705905	, lat:60.6206277 },
        { lng:26.7702365	, lat:60.6207066 },
        { lng:26.7698931	, lat:60.620733 },
        { lng:26.7694854	, lat:60.6207382 },
        { lng:26.7692065	, lat:60.6207698 },
        { lng:26.7689608	, lat:60.6209077 },
        { lng:26.7684877	, lat:60.6209593 },
        { lng:26.7681765	, lat:60.6210857 },
        { lng:26.767801	, lat:60.6212803 },
        { lng:26.7675757	, lat:60.6214383 },
        { lng:26.7673075	, lat:60.6215488 },
        { lng:26.7671251	, lat:60.6217172 },
        { lng:26.7667818	, lat:60.6219804 },
        { lng:26.7664814	, lat:60.6221384 },
        { lng:26.7659664	, lat:60.6224593 },
        { lng:26.7656123	, lat:60.6226541 },
        { lng:26.7651617	, lat:60.6228751 },
        { lng:26.764915	, lat:60.6229805 },
        { lng:26.7646896	, lat:60.6231752 },
        { lng:26.7646253	, lat:60.6233015 },
        { lng:26.7647218	, lat:60.6234277 },
        { lng:26.7623078	, lat:60.6241067 },
        { lng:26.7547864	, lat:60.6261353 },
        { lng:26.7512035	, lat:60.6284745 },
        { lng:26.7506027	, lat:60.6297585 },
        { lng:26.7501413	, lat:60.6307319 },
        { lng:26.7524867	, lat:60.6315065 },
        { lng:26.7535756	, lat:60.6318432 },
        { lng:26.7548823	, lat:60.6327151 },
        { lng:26.755942	, lat:60.6334189 },
        { lng:26.7566216	, lat:60.6350201 },
        { lng:26.7598295	, lat:60.6369088 },
        { lng:26.7589819	, lat:60.637719 },
        { lng:26.7602157	, lat:60.638224 },
        { lng:26.7590999	, lat:60.6391551 },
        { lng:26.7586493	, lat:60.6398179 },
        { lng:26.7595183	, lat:60.6403808 },
        { lng:26.757673	, lat:60.6419009 },
        { lng:26.7547762	, lat:60.6428056 },
        { lng:26.7527424	, lat:60.6448268 },
        { lng:26.7523358	, lat:60.6465074 },
        { lng:26.7525567	, lat:60.6476812 },
        { lng:26.7537999	, lat:60.6507786 },
        { lng:26.7541217	, lat:60.6513991 },
        { lng:26.753759	, lat:60.6517074 },
        { lng:26.7536068	, lat:60.6518355 },
        { lng:26.7521691	, lat:60.6529398 },
        { lng:26.7502379	, lat:60.6537022 },
        { lng:26.7477274	, lat:60.6542701 },
        { lng:26.7453314	, lat:60.6550063 },
        { lng:26.74113	, lat:60.656271 },
        { lng:26.7436024	, lat:60.6576159 },
        { lng:26.7527377	, lat:60.6626817 },
        { lng:26.7534995	, lat:60.6635858 },
        { lng:26.7531347	, lat:60.6645898 },
        { lng:26.7557525	, lat:60.6663874 },
        { lng:26.754957	, lat:60.6670809 },
        { lng:26.7538551	, lat:60.6680716 },
        { lng:26.7529598	, lat:60.6688781 },
        { lng:26.7506456	, lat:60.6709388 },
        { lng:26.7505061	, lat:60.6727202 },
        { lng:26.752786	, lat:60.6739754 },
        { lng:26.7533969	, lat:60.6741021 },
        { lng:26.7568683	, lat:60.6748222 },
        { lng:26.7653548	, lat:60.6748011 },
        { lng:26.767203	, lat:60.675125 },
        { lng:26.7721033	, lat:60.6755736 },
        { lng:26.7815661	, lat:60.6735085 },
        { lng:26.7856752	, lat:60.6712488 },
        { lng:26.7918336	, lat:60.669236 },
        { lng:26.7953956	, lat:60.6686106 },
        { lng:26.798757	, lat:60.665726 },
        { lng:26.8031311	, lat:60.6619721 },
        { lng:26.8103408	, lat:60.6577927 },
        { lng:26.8175721	, lat:60.6535707 },
        { lng:26.8190848	, lat:60.6526768 },
        { lng:26.8198037	, lat:60.6516778 },
        { lng:26.8232905	, lat:60.6496217 },
        { lng:26.8266594	, lat:60.6486172 },
        { lng:26.8309724	, lat:60.648349 },
        { lng:26.8339872	, lat:60.648165 },
        { lng:26.8378818	, lat:60.6493114 },
        { lng:26.8397612	, lat:60.6512041 },
        { lng:26.8409357	, lat:60.6523869 },
        { lng:26.8422541	, lat:60.6501732 },
        { lng:26.8426056	, lat:60.649609 },
        { lng:26.8424826	, lat:60.6494913 },
        { lng:26.8423839	, lat:60.6492486 },
        { lng:26.8422364	, lat:60.6489766 },
        { lng:26.8420586	, lat:60.6487449 },
        { lng:26.8419153	, lat:60.648586 },
        { lng:26.8418539	, lat:60.6484015 },
        { lng:26.8417316	, lat:60.647951 },
        { lng:26.8416334	, lat:60.6477553 },
        { lng:26.8414035	, lat:60.647611 },
        { lng:26.8411004	, lat:60.6470711 },
        { lng:26.8401456	, lat:60.6465294 },
        { lng:26.8396842	, lat:60.6462612 },
        { lng:26.8374741	, lat:60.6459246 },
        { lng:26.834706	, lat:60.6450568 },
        { lng:26.8328714	, lat:60.6449884 },
        { lng:26.8304145	, lat:60.6448937 },
        { lng:26.8274748	, lat:60.644778 },
        { lng:26.8265414	, lat:60.6441732 },
        { lng:26.8261337	, lat:60.6434736 },
        { lng:26.8266701	, lat:60.6427899 },
        { lng:26.8278932	, lat:60.640912 },
        { lng:26.8286657	, lat:60.6401861 },
        { lng:26.8305111	, lat:60.6395286 },
        { lng:26.8323242	, lat:60.6392445 },
        { lng:26.8337404	, lat:60.6390025 },
        { lng:26.8403172	, lat:60.6390236 },
        { lng:26.8412184	, lat:60.6392482 },
        { lng:26.8424072	, lat:60.6396601 },
        { lng:26.8450272	, lat:60.6402756 },
        { lng:26.8454456	, lat:60.6407122 },
        { lng:26.8464112	, lat:60.6409541 },
        { lng:26.8470876	, lat:60.6408895 },
        { lng:26.8472373	, lat:60.6408752 },
        { lng:26.8487715	, lat:60.6407227 },
        { lng:26.8498659	, lat:60.6408805 },
        { lng:26.850146	, lat:60.6410134 },
        { lng:26.8511963	, lat:60.6415117 },
        { lng:26.8520975	, lat:60.6416748 },
        { lng:26.8531489	, lat:60.641538 },
        { lng:26.8555951	, lat:60.6405123 },
        { lng:26.8588137	, lat:60.6395233 },
        { lng:26.8618393	, lat:60.6389605 },
        { lng:26.8634808	, lat:60.6381451 },
        { lng:26.8762266	, lat:60.6373034 },
        { lng:26.8820846	, lat:60.6363354 },
        { lng:26.888479	, lat:60.6377348 },
        { lng:26.8941116	, lat:60.6389605 },
        { lng:26.8983335	, lat:60.6403888 },
        { lng:26.8998623	, lat:60.6409226 },
        { lng:26.9175756	, lat:60.6470132 },
        { lng:26.9210112	, lat:60.6481676 },
        { lng:26.922146	, lat:60.6485489 },
        { lng:26.9344949	, lat:60.6515672 },
        { lng:26.9464361	, lat:60.6545175 },
        { lng:26.9691265	, lat:60.6604248 },
        { lng:27.0020125	, lat:60.6689867 },
        { lng:27.0303613	, lat:60.6763437 },
        { lng:27.0410279	, lat:60.6791368 },
        { lng:27.04175	, lat:60.6793036 },
        { lng:27.0421299	, lat:60.6793238 },
        { lng:27.042545	, lat:60.6792849 },
        { lng:27.042817	, lat:60.6792006 },
        { lng:27.0430465	, lat:60.6790233 },
        { lng:27.0432225	, lat:60.6788105 },
        { lng:27.043598	, lat:60.6778974 },
        { lng:27.0439146	, lat:60.6770207 },
        { lng:27.0440443	, lat:60.6764383 },
        { lng:27.0440937	, lat:60.675854 },
        { lng:27.0441543	, lat:60.6756088 },
        { lng:27.0444547	, lat:60.6753431 },
        { lng:27.0452089	, lat:60.6747429 },
        { lng:27.0455625	, lat:60.6744167 },
        { lng:27.0460292	, lat:60.6741113 },
        { lng:27.0466333	, lat:60.6737837 },
        { lng:27.0473333	, lat:60.6735369 },
        { lng:27.0478725	, lat:60.6733057 },
        { lng:27.0482372	, lat:60.6730582 },
        { lng:27.0499151	, lat:60.6716656 },
        { lng:27.0505954	, lat:60.6710193 },
        { lng:27.0506117	, lat:60.6708577 },
        { lng:27.0519531	, lat:60.6709002 },
        { lng:27.0522417	, lat:60.6708839 },
        { lng:27.0525206	, lat:60.6708839 },
        { lng:27.0527352	, lat:60.6708892 },
        { lng:27.0529401	, lat:60.6709107 },
        { lng:27.0533948	, lat:60.6710584 },
        { lng:27.0537786	, lat:60.6712684 },
        { lng:27.0543445	, lat:60.6717061 },
        { lng:27.0549684	, lat:60.6719218 },
        { lng:27.0553536	, lat:60.6719896 },
        { lng:27.0558536	, lat:60.6720301 },
        { lng:27.0563535	, lat:60.672131 },
        { lng:27.0571947	, lat:60.6723982 },
        { lng:27.0573856	, lat:60.6724589 },
        { lng:27.0585063	, lat:60.6729142 },
        { lng:27.0594193	, lat:60.6732881 },
        { lng:27.0603972	, lat:60.6738512 },
        { lng:27.060776	, lat:60.6742739 },
        { lng:27.0610249	, lat:60.6747093 },
        { lng:27.0612309	, lat:60.6749378 },
        { lng:27.0614873	, lat:60.6750682 },
        { lng:27.0627184	, lat:60.6753283 },
        { lng:27.0630226	, lat:60.6755705 },
        { lng:27.0636003	, lat:60.6756207 },
        { lng:27.0640917	, lat:60.6755426 },
        { lng:27.0643529	, lat:60.6754031 },
        { lng:27.0645219	, lat:60.6752597 },
        { lng:27.0648738	, lat:60.674781 },
        { lng:27.0650728	, lat:60.6743414 },
        { lng:27.0651764	, lat:60.6741749 },
        { lng:27.065436	, lat:60.6741139 },
        { lng:27.0657552	, lat:60.6740832 },
        { lng:27.0660497	, lat:60.6740107 },
        { lng:27.0662037	, lat:60.6739137 },
        { lng:27.0666999	, lat:60.6734001 },
        { lng:27.0669869	, lat:60.6729369 },
        { lng:27.0670148	, lat:60.6727731 },
        { lng:27.0671317	, lat:60.6726602 },
        { lng:27.0674836	, lat:60.6726171 },
        { lng:27.0679047	, lat:60.672436 },
        { lng:27.0679873	, lat:60.6722448 },
        { lng:27.0679026	, lat:60.6719804 },
        { lng:27.0677545	, lat:60.6718351 },
        { lng:27.0677534	, lat:60.6717082 },
        { lng:27.0679664	, lat:60.6715458 },
        { lng:27.0681949	, lat:60.6715048 },
        { lng:27.0684571	, lat:60.6715107 },
        { lng:27.0686135	, lat:60.6715288 },
        { lng:27.0688853	, lat:60.6716073 },
        { lng:27.0693306	, lat:60.6717968 },
        { lng:27.0695446	, lat:60.6719392 },
        { lng:27.0695988	, lat:60.6720976 },
        { lng:27.0695521	, lat:60.672307 },
        { lng:27.0696224	, lat:60.672436 },
        { lng:27.0697442	, lat:60.6725461 },
        { lng:27.0699657	, lat:60.6726326 },
        { lng:27.0705172	, lat:60.6727689 },
        { lng:27.0709061	, lat:60.6729618 },
        { lng:27.0711336	, lat:60.6730587 },
        { lng:27.0713514	, lat:60.6730884 },
        { lng:27.0723293	, lat:60.6730819 },
        { lng:27.0725943	, lat:60.6730364 },
        { lng:27.0729779	, lat:60.6730346 },
        { lng:27.0731957	, lat:60.6729618 },
        { lng:27.0735722	, lat:60.6726147 },
        { lng:27.0737514	, lat:60.6724938 },
        { lng:27.0740497	, lat:60.6724011 },
        { lng:27.074357	, lat:60.6723966 },
        { lng:27.0746226	, lat:60.6724471 },
        { lng:27.0749884	, lat:60.6725551 },
        { lng:27.0753361	, lat:60.6727067 },
        { lng:27.0801495	, lat:60.6739891 },
        { lng:27.0833746	, lat:60.6748509 },
        { lng:27.1030824	, lat:60.6854913 },
        { lng:27.1084781	, lat:60.6952177 },
        { lng:27.1101335	, lat:60.6982233 },
        { lng:27.1136891	, lat:60.7036821 },
        { lng:27.1141826	, lat:60.7046244 },
        { lng:27.1154529	, lat:60.706434 },
        { lng:27.1160741	, lat:60.7075957 },
        { lng:27.1171867	, lat:60.7091053 },
        { lng:27.1181077	, lat:60.7107106 },
        { lng:27.118832	, lat:60.7119728 },
        { lng:27.119076	, lat:60.7124645 },
        { lng:27.1197708	, lat:60.7125971 },
        { lng:27.1204649	, lat:60.7128291 },
        { lng:27.1207868	, lat:60.7130595 },
        { lng:27.1211001	, lat:60.7133474 },
        { lng:27.1213511	, lat:60.7134542 },
        { lng:27.1225259	, lat:60.7136945 },
        { lng:27.1233525	, lat:60.7138517 },
        { lng:27.124006	, lat:60.713852 },
        { lng:27.1258984	, lat:60.7135909 },
        { lng:27.1262134	, lat:60.7136132 },
        { lng:27.1265466	, lat:60.7137056 },
        { lng:27.1275792	, lat:60.7137628 },
        { lng:27.1279624	, lat:60.7139191 },
        { lng:27.1281672	, lat:60.7140832 },
        { lng:27.1283321	, lat:60.7144894 },
        { lng:27.1286569	, lat:60.7148371 },
        { lng:27.1289595	, lat:60.7149767 },
        { lng:27.1291993	, lat:60.714962 },
        { lng:27.1293082	, lat:60.7148497 },
        { lng:27.1293688	, lat:60.7146715 },
        { lng:27.1299047	, lat:60.7144668 },
        { lng:27.1303086	, lat:60.714429 },
        { lng:27.1307448	, lat:60.7144778 },
        { lng:27.1313724	, lat:60.7146633 },
        { lng:27.1320681	, lat:60.7150664 },
        { lng:27.1325842	, lat:60.7154506 },
        { lng:27.1329473	, lat:60.7158331 },
        { lng:27.132927	, lat:60.7162131 },
        { lng:27.1328321	, lat:60.7167074 },
        { lng:27.1324367	, lat:60.7170336 },
        { lng:27.1313921	, lat:60.7177727 },
        { lng:27.1309196	, lat:60.7182206 },
        { lng:27.1305034	, lat:60.7184079 },
        { lng:27.1300286	, lat:60.7184357 },
        { lng:27.1297835	, lat:60.7183806 },
        { lng:27.129528	, lat:60.7180886 },
        { lng:27.1292411	, lat:60.7180768 },
        { lng:27.1282841	, lat:60.7185449 },
        { lng:27.1280475	, lat:60.718775 },
        { lng:27.1279158	, lat:60.7194311 },
        { lng:27.1278287	, lat:60.7198647 },
        { lng:27.1275545	, lat:60.7201078 },
        { lng:27.1271646	, lat:60.7201154 },
        { lng:27.1268813	, lat:60.7200496 },
        { lng:27.126671	, lat:60.7199583 },
        { lng:27.1262809	, lat:60.7199606 },
        { lng:27.1260021	, lat:60.7200921 },
        { lng:27.1256276	, lat:60.7204848 },
        { lng:27.1251024	, lat:60.7213233 },
        { lng:27.1247903	, lat:60.7221914 },
        { lng:27.1245842	, lat:60.7226891 },
        { lng:27.1242216	, lat:60.7230986 },
        { lng:27.1238649	, lat:60.7238911 },
        { lng:27.1236176	, lat:60.7242471 },
        { lng:27.1234137	, lat:60.724416 },
        { lng:27.1230999	, lat:60.7245933 },
        { lng:27.123057	, lat:60.7247418 },
        { lng:27.1234771	, lat:60.7252295 },
        { lng:27.1238353	, lat:60.7254197 },
        { lng:27.12419	, lat:60.72555 },
        { lng:27.1245737	, lat:60.725545 },
        { lng:27.1248493	, lat:60.7255917 },
        { lng:27.1253337	, lat:60.7258842 },
        { lng:27.1255047	, lat:60.7263262 },
        { lng:27.1252349	, lat:60.7272291 },
        { lng:27.1252027	, lat:60.7283664 },
        { lng:27.1249131	, lat:60.7291199 },
        { lng:27.1244999	, lat:60.7296492 },
        { lng:27.1239963	, lat:60.7298745 },
        { lng:27.1222448	, lat:60.7302643 },
        { lng:27.1215926	, lat:60.7305313 },
        { lng:27.1209482	, lat:60.7311295 },
        { lng:27.1196635	, lat:60.7317076 },
        { lng:27.1190439	, lat:60.7321172 },
        { lng:27.1183224	, lat:60.733412 },
        { lng:27.1176002	, lat:60.7342827 },
        { lng:27.1175065	, lat:60.7346128 },
        { lng:27.1173364	, lat:60.7355894 },
        { lng:27.1170498	, lat:60.735946 },
        { lng:27.1164497	, lat:60.736229 },
        { lng:27.1149725	, lat:60.7367574 },
        { lng:27.1147547	, lat:60.7368113 },
        { lng:27.1133391	, lat:60.7371618 },
        { lng:27.1130824	, lat:60.7372254 },
        { lng:27.1127107	, lat:60.7373955 },
        { lng:27.1125668	, lat:60.7374614 },
        { lng:27.1121415	, lat:60.7379979 },
        { lng:27.1119323	, lat:60.7383581 },
        { lng:27.1109415	, lat:60.7394608 },
        { lng:27.1106137	, lat:60.7397996 },
        { lng:27.1106679	, lat:60.7399755 },
        { lng:27.11073	, lat:60.7401771 },
        { lng:27.1111459	, lat:60.7405508 },
        { lng:27.1124033	, lat:60.7406666 },
        { lng:27.1133791	, lat:60.7409808 },
        { lng:27.1140255	, lat:60.7411535 },
        { lng:27.1151954	, lat:60.7413945 },
        { lng:27.1156026	, lat:60.7416984 },
        { lng:27.1162227	, lat:60.7419414 },
        { lng:27.1168337	, lat:60.7426328 },
        { lng:27.1170815	, lat:60.7429287 },
        { lng:27.1188314	, lat:60.7435176 },
        { lng:27.1191292	, lat:60.743913 },
        { lng:27.1193599	, lat:60.7440805 },
        { lng:27.1201558	, lat:60.7440532 },
        { lng:27.1206473	, lat:60.7441308 },
        { lng:27.1218161	, lat:60.7445136 },
        { lng:27.1225222	, lat:60.7447401 },
        { lng:27.1233799	, lat:60.7453328 },
        { lng:27.1240151	, lat:60.7454925 },
        { lng:27.1248983	, lat:60.7458531 },
        { lng:27.1250228	, lat:60.7460801 },
        { lng:27.1253232	, lat:60.7462115 },
        { lng:27.125571	, lat:60.7462551 },
        { lng:27.1260071	, lat:60.7461876 },
        { lng:27.1263172	, lat:60.7462377 },
        { lng:27.1267222	, lat:60.7463771 },
        { lng:27.1271213	, lat:60.7466622 },
        { lng:27.1273788	, lat:60.746962 },
        { lng:27.1278504	, lat:60.7473035 },
        { lng:27.1286115	, lat:60.7477067 },
        { lng:27.1290402	, lat:60.7478748 },
        { lng:27.1292783	, lat:60.7480677 },
        { lng:27.1294264	, lat:60.7483919 },
        { lng:27.1295857	, lat:60.7485282 },
        { lng:27.1296528	, lat:60.7486066 },
        { lng:27.1294634	, lat:60.7486691 },
        { lng:27.1291732	, lat:60.7486845 },
        { lng:27.1288728	, lat:60.7486087 },
        { lng:27.128633	, lat:60.7485618 },
        { lng:27.1283723	, lat:60.7487255 },
        { lng:27.1276046	, lat:60.7493206 },
        { lng:27.1268885	, lat:60.7499969 },
        { lng:27.1258505	, lat:60.7507969 },
        { lng:27.1251456	, lat:60.7514658 },
        { lng:27.1247111	, lat:60.7519208 },
        { lng:27.1241767	, lat:60.7521082 },
        { lng:27.1238968	, lat:60.7523276 },
        { lng:27.1239268	, lat:60.7524736 },
        { lng:27.1241835	, lat:60.7527031 },
        { lng:27.1246826	, lat:60.7528814 },
        { lng:27.1252014	, lat:60.7529457 },
        { lng:27.1259717	, lat:60.7530494 },
        { lng:27.1272844	, lat:60.7533962 },
        { lng:27.1274653	, lat:60.7535353 },
        { lng:27.1274871	, lat:60.7536847 },
        { lng:27.1272397	, lat:60.7538447 },
        { lng:27.1267594	, lat:60.7540046 },
        { lng:27.1264756	, lat:60.7541717 },
        { lng:27.1265411	, lat:60.7542784 },
        { lng:27.1269696	, lat:60.7544187 },
        { lng:27.1272979	, lat:60.7546055 },
        { lng:27.1274435	, lat:60.7547371 },
        { lng:27.1275017	, lat:60.7549432 },
        { lng:27.1275017	, lat:60.7551495 },
        { lng:27.1272252	, lat:60.7554161 },
        { lng:27.1269049	, lat:60.7557575 },
        { lng:27.1269923	, lat:60.7560347 },
        { lng:27.1270068	, lat:60.7562622 },
        { lng:27.1268176	, lat:60.756433 },
        { lng:27.1263532	, lat:60.756662 },
        { lng:27.125116	, lat:60.75711 },
        { lng:27.1248322	, lat:60.7573126 },
        { lng:27.1245775	, lat:60.7574265 },
        { lng:27.1242718	, lat:60.7575082 },
        { lng:27.1236567	, lat:60.7576135 },
        { lng:27.1232821	, lat:60.7577072 },
        { lng:27.1230711	, lat:60.757821 },
        { lng:27.122729	, lat:60.7581091 },
        { lng:27.1226126	, lat:60.7583436 },
        { lng:27.1225714	, lat:60.7585912 },
        { lng:27.1224307	, lat:60.7587702 },
        { lng:27.1222715	, lat:60.7588795 },
        { lng:27.1221048	, lat:60.7590398 },
        { lng:27.1215978	, lat:60.7592135 },
        { lng:27.1207026	, lat:60.7594958 },
        { lng:27.1205753	, lat:60.7596551 },
        { lng:27.1206431	, lat:60.7597667 },
        { lng:27.1208512	, lat:60.7599771 },
        { lng:27.1208716	, lat:60.760219 },
        { lng:27.1206553	, lat:60.760552 },
        { lng:27.1197819	, lat:60.7610032 },
        { lng:27.119386	, lat:60.7613936 },
        { lng:27.1191302	, lat:60.7615561 },
        { lng:27.1187268	, lat:60.7616545 },
        { lng:27.1183201	, lat:60.761675 },
        { lng:27.1179398	, lat:60.7617596 },
        { lng:27.1175627	, lat:60.7620175 },
        { lng:27.1170622	, lat:60.7624304 },
        { lng:27.1168782	, lat:60.7626431 },
        { lng:27.1169949	, lat:60.7628564 },
        { lng:27.1171201	, lat:60.7629811 },
        { lng:27.1173922	, lat:60.7630415 },
        { lng:27.1178824	, lat:60.7630396 },
        { lng:27.1182445	, lat:60.7631087 },
        { lng:27.1185052	, lat:60.7632063 },
        { lng:27.1185261	, lat:60.7633969 },
        { lng:27.1182938	, lat:60.7635575 },
        { lng:27.1180638	, lat:60.7636837 },
        { lng:27.1179608	, lat:60.7638935 },
        { lng:27.1178148	, lat:60.7640808 },
        { lng:27.1175667	, lat:60.7643431 },
        { lng:27.1175914	, lat:60.7645157 },
        { lng:27.1176999	, lat:60.7645733 },
        { lng:27.1178343	, lat:60.7646099 },
        { lng:27.1179727	, lat:60.764608 },
        { lng:27.1180814	, lat:60.7645885 },
        { lng:27.1181973	, lat:60.7643902 },
        { lng:27.1182402	, lat:60.7642816 },
        { lng:27.1185179	, lat:60.7642727 },
        { lng:27.1186892	, lat:60.7643718 },
        { lng:27.1186935	, lat:60.764589 },
        { lng:27.1188024	, lat:60.7647041 },
        { lng:27.1195105	, lat:60.764947 },
        { lng:27.1198593	, lat:60.7649837 },
        { lng:27.1203668	, lat:60.764804 },
        { lng:27.1207191	, lat:60.7648434 },
        { lng:27.1209804	, lat:60.7648262 },
        { lng:27.1211994	, lat:60.7647683 },
        { lng:27.1214026	, lat:60.7647143 },
        { lng:27.1216965	, lat:60.7646465 },
        { lng:27.1220281	, lat:60.7647052 },
        { lng:27.1223908	, lat:60.7648965 },
        { lng:27.1235012	, lat:60.7651598 },
        { lng:27.1244748	, lat:60.7653367 },
        { lng:27.1259844	, lat:60.7655533 },
        { lng:27.1269617	, lat:60.7657354 },
        { lng:27.1273072	, lat:60.7659353 },
        { lng:27.1273625	, lat:60.7661928 },
        { lng:27.1274591	, lat:60.7664393 },
        { lng:27.1277486	, lat:60.7666536 },
        { lng:27.1282364	, lat:60.7668601 },
        { lng:27.1287669	, lat:60.766958 },
        { lng:27.1291439	, lat:60.7671171 },
        { lng:27.1292507	, lat:60.7672291 },
        { lng:27.12921	, lat:60.7673992 },
        { lng:27.1290222	, lat:60.7676942 },
        { lng:27.1290137	, lat:60.7679156 },
        { lng:27.1292261	, lat:60.7681034 },
        { lng:27.1294216	, lat:60.7682696 },
        { lng:27.1294271	, lat:60.7685252 },
        { lng:27.1292486	, lat:60.7688405 },
        { lng:27.1289222	, lat:60.7692589 },
        { lng:27.128396	, lat:60.7694027 },
        { lng:27.1280275	, lat:60.7695498 },
        { lng:27.1274328	, lat:60.7695395 },
        { lng:27.1270441	, lat:60.7695992 },
        { lng:27.1267189	, lat:60.7697753 },
        { lng:27.1266468	, lat:60.7700375 },
        { lng:27.1265089	, lat:60.770585 },
        { lng:27.1263143	, lat:60.7707342 },
        { lng:27.1260775	, lat:60.7708892 },
        { lng:27.1258426	, lat:60.7709996 },
        { lng:27.1256182	, lat:60.7710491 },
        { lng:27.1250732	, lat:60.7711392 },
        { lng:27.1245691	, lat:60.771187 },
        { lng:27.124387	, lat:60.7712564 },
        { lng:27.1240346	, lat:60.7715431 },
        { lng:27.1235487	, lat:60.7717358 },
        { lng:27.1230327	, lat:60.7718104 },
        { lng:27.1228164	, lat:60.7718756 },
        { lng:27.1226569	, lat:60.7719937 },
        { lng:27.1226946	, lat:60.7721314 },
        { lng:27.1227971	, lat:60.7723309 },
        { lng:27.1231409	, lat:60.7728616 },
        { lng:27.1233201	, lat:60.7732786 },
        { lng:27.1237257	, lat:60.7736572 },
        { lng:27.1238678	, lat:60.7738423 },
        { lng:27.124133	, lat:60.77418 },
        { lng:27.1248717	, lat:60.7748747 },
        { lng:27.1254136	, lat:60.7757283 },
        { lng:27.1260588	, lat:60.7762695 },
        { lng:27.1259822	, lat:60.7764945 },
        { lng:27.12573	, lat:60.7765916 },
        { lng:27.1256354	, lat:60.7766019 },
        { lng:27.1251245	, lat:60.7766574 },
        { lng:27.1248809	, lat:60.7767139 },
        { lng:27.1246206	, lat:60.7768768 },
        { lng:27.1249058	, lat:60.7768434 },
        { lng:27.1271415	, lat:60.7765814 },
        { lng:27.1312398	, lat:60.7760689 },
        { lng:27.2031198	, lat:60.7604918 },
        { lng:27.2226682	, lat:60.7610714 },
        { lng:27.2226773	, lat:60.7608358 },
        { lng:27.2231719	, lat:60.7606543 },
        { lng:27.2238908	, lat:60.760779 },
        { lng:27.2248585	, lat:60.7607564 },
        { lng:27.225205	, lat:60.7604535 },
        { lng:27.2257426	, lat:60.7604011 },
        { lng:27.2257427	, lat:60.7606616 },
        { lng:27.2260526	, lat:60.7608145 },
        { lng:27.2263401	, lat:60.7608623 },
        { lng:27.2263863	, lat:60.7609587 },
        { lng:27.2260901	, lat:60.761116 },
        { lng:27.2257801	, lat:60.7613444 },
        { lng:27.2259485	, lat:60.7617695 },
        { lng:27.2268884	, lat:60.7622808 },
        { lng:27.2267167	, lat:60.762689 },
        { lng:27.22678	, lat:60.7629978 },
        { lng:27.2255462	, lat:60.7633767 },
        { lng:27.2251353	, lat:60.7636455 },
        { lng:27.225293	, lat:60.7639048 },
        { lng:27.2264034	, lat:60.7642989 },
        { lng:27.227196	, lat:60.7646945 },
        { lng:27.2276383	, lat:60.7658456 },
        { lng:27.2290106	, lat:60.7664 },
        { lng:27.2299837	, lat:60.7667192 },
        { lng:27.2300877	, lat:60.7671954 },
        { lng:27.229642	, lat:60.7677848 },
        { lng:27.2297734	, lat:60.7680165 },
        { lng:27.2304969	, lat:60.7685878 },
        { lng:27.2308076	, lat:60.7690895 },
        { lng:27.230622	, lat:60.7693445 },
        { lng:27.2306961	, lat:60.769541 },
        { lng:27.2321123	, lat:60.7702053 },
        { lng:27.2326197	, lat:60.7718613 },
        { lng:27.2317958	, lat:60.7727702 },
        { lng:27.2303514	, lat:60.7734248 },
        { lng:27.2305287	, lat:60.773906 },
        { lng:27.2308153	, lat:60.7741485 },
        { lng:27.2311799	, lat:60.7750998 },
        { lng:27.2315397	, lat:60.7752571 },
        { lng:27.2320242	, lat:60.7754692 },
        { lng:27.2332581	, lat:60.7757342 },
        { lng:27.2349812	, lat:60.77584 },
        { lng:27.2363491	, lat:60.7758578 },
        { lng:27.2373479	, lat:60.7753942 },
        { lng:27.2375325	, lat:60.7750804 },
        { lng:27.2378543	, lat:60.77453 },
        { lng:27.2383125	, lat:60.7742218 },
        { lng:27.2386601	, lat:60.7741449 },
        { lng:27.2398113	, lat:60.7744613 },
        { lng:27.2407661	, lat:60.7752974 },
        { lng:27.2431876	, lat:60.7758907 },
        { lng:27.2441801	, lat:60.7758683 },
        { lng:27.2441638	, lat:60.7762628 },
        { lng:27.2447905	, lat:60.7763176 },
        { lng:27.2455694	, lat:60.7769463 },
        { lng:27.2473515	, lat:60.7772894 },
        { lng:27.2482635	, lat:60.7773564 },
        { lng:27.249803	, lat:60.7771951 },
        { lng:27.2511785	, lat:60.7769221 },
        { lng:27.2520046	, lat:60.7768421 },
        { lng:27.2524144	, lat:60.7768772 },
        { lng:27.2528814	, lat:60.7767278 },
        { lng:27.2546278	, lat:60.776854 },
        { lng:27.2554405	, lat:60.7770889 },
        { lng:27.2569989	, lat:60.777214 },
        { lng:27.2575106	, lat:60.7774506 },
        { lng:27.2588013	, lat:60.7776854 },
        { lng:27.2602991	, lat:60.7784286 },
        { lng:27.2609975	, lat:60.7785527 },
        { lng:27.2618794	, lat:60.7785417 },
        { lng:27.2626776	, lat:60.7777843 },
        { lng:27.2633675	, lat:60.7777916 },
        { lng:27.2646726	, lat:60.7774588 },
        { lng:27.267155	, lat:60.7778975 },
        { lng:27.2675334	, lat:60.7781112 },
        { lng:27.2670984	, lat:60.7789898 },
        { lng:27.267491	, lat:60.7792517 },
        { lng:27.2668344	, lat:60.7795727 },
        { lng:27.2698181	, lat:60.7815446 },
        { lng:27.2711109	, lat:60.784471 },
        { lng:27.2713738	, lat:60.7865379 },
        { lng:27.2727738	, lat:60.7869972 },
        { lng:27.2698492	, lat:60.7910733 },
        { lng:27.2656553	, lat:60.7943737 },
        { lng:27.2614775	, lat:60.794904 },
        { lng:27.2621566	, lat:60.7969907 },
        { lng:27.2600205	, lat:60.798717 },
        { lng:27.2613444	, lat:60.8008897 },
        { lng:27.2661917	, lat:60.8023677 },
        { lng:27.2716344	, lat:60.8040274 },
        { lng:27.2911351	, lat:60.8207207 },
        { lng:27.2926584	, lat:60.8220246 },
        { lng:27.2958191	, lat:60.8215491 },
        { lng:27.3021589	, lat:60.8209805 },
        { lng:27.3101207	, lat:60.8205814 },
        { lng:27.3346292	, lat:60.8168791 },
        { lng:27.3350829	, lat:60.8170066 },
        { lng:27.3356187	, lat:60.81688 },
        { lng:27.3365208	, lat:60.8168961 },
        { lng:27.3363376	, lat:60.8171261 },
        { lng:27.3374169	, lat:60.8172649 },
        { lng:27.3374036	, lat:60.8173739 },
        { lng:27.3379987	, lat:60.8175236 },
        { lng:27.3385076	, lat:60.8175192 },
        { lng:27.3389677	, lat:60.8176495 },
        { lng:27.3401345	, lat:60.8175067 },
        { lng:27.3405806	, lat:60.8176058 },
        { lng:27.3410151	, lat:60.8174016 },
        { lng:27.3411243	, lat:60.8171974 },
        { lng:27.3419515	, lat:60.817134 },
        { lng:27.3424951	, lat:60.8173321 },
        { lng:27.3426855	, lat:60.8172805 },
        { lng:27.3426507	, lat:60.8170724 },
        { lng:27.3430235	, lat:60.8169445 },
        { lng:27.3431611	, lat:60.8170709 },
        { lng:27.3435552	, lat:60.8168883 },
        { lng:27.3443069	, lat:60.816777 },
        { lng:27.3454637	, lat:60.8169552 },
        { lng:27.3458398	, lat:60.8172048 },
        { lng:27.3466224	, lat:60.817161 },
        { lng:27.346965	, lat:60.8175673 },
        { lng:27.3466245	, lat:60.8177198 },
        { lng:27.3466287	, lat:60.8182428 },
        { lng:27.3471013	, lat:60.8185876 },
        { lng:27.3468823	, lat:60.8188076 },
        { lng:27.3472812	, lat:60.819091 },
        { lng:27.346893	, lat:60.8199234 },
        { lng:27.3472803	, lat:60.8203108 },
        { lng:27.3471797	, lat:60.8205238 },
        { lng:27.3473688	, lat:60.8207913 },
        { lng:27.3477047	, lat:60.8208225 },
        { lng:27.3480406	, lat:60.8213135 },
        { lng:27.3477692	, lat:60.8214868 },
        { lng:27.3479421	, lat:60.8219128 },
        { lng:27.3484808	, lat:60.822212 },
        { lng:27.3487593	, lat:60.8222575 },
        { lng:27.3486313	, lat:60.8225804 },
        { lng:27.349479	, lat:60.8231729 },
        { lng:27.3493713	, lat:60.8237465 },
        { lng:27.3491336	, lat:60.824003 },
        { lng:27.3492435	, lat:60.8242465 },
        { lng:27.3488453	, lat:60.8247335 },
        { lng:27.3490586	, lat:60.8250505 },
        { lng:27.3496179	, lat:60.8252334 },
        { lng:27.3509871	, lat:60.8254042 },
        { lng:27.3514292	, lat:60.8256541 },
        { lng:27.351468	, lat:60.8261494 },
        { lng:27.3519381	, lat:60.8265134 },
        { lng:27.3529368	, lat:60.826857 },
        { lng:27.354456	, lat:60.8271974 },
        { lng:27.3549936	, lat:60.8268334 },
        { lng:27.3554171	, lat:60.8267865 },
        { lng:27.3570361	, lat:60.8275805 },
        { lng:27.3583786	, lat:60.8284379 },
        { lng:27.3578271	, lat:60.828931 },
        { lng:27.3583774	, lat:60.829717 },
        { lng:27.3565096	, lat:60.8307738 },
        { lng:27.354456	, lat:60.8310369 },
        { lng:27.3591855	, lat:60.8319381 },
        { lng:27.3778942	, lat:60.8384806 },
        { lng:27.3931098	, lat:60.8400412 },
        { lng:27.3993714	, lat:60.8404999 },
        { lng:27.4283735	, lat:60.842625 },
        { lng:27.4177766	, lat:60.8480462 },
        { lng:27.4098934	, lat:60.8530773 },
        { lng:27.4081816	, lat:60.8541861 },
        { lng:27.4055423	, lat:60.8565823 },
        { lng:27.3915563	, lat:60.8643518 },
        { lng:27.3887281	, lat:60.866951 },
        { lng:27.3872777	, lat:60.8687457 },
        { lng:27.3860395	, lat:60.8711847 },
        { lng:27.3726056	, lat:60.8789931 },
        { lng:27.3603885	, lat:60.8830404 },
        { lng:27.3509094	, lat:60.8851572 },
        { lng:27.3436364	, lat:60.8866358 },
        { lng:27.3390962	, lat:60.887701 },
        { lng:27.3346821	, lat:60.8878633 },
        { lng:27.3276799	, lat:60.888882 },
        { lng:27.3202129	, lat:60.890093 },
        { lng:27.3162174	, lat:60.8902725 },
        { lng:27.3090677	, lat:60.8921452 },
        { lng:27.3054178	, lat:60.8935073 },
        { lng:27.2772288	, lat:60.8967407 },
        { lng:27.2587495	, lat:60.8983835 },
        { lng:27.2553463	, lat:60.8985358 },
        { lng:27.2132829	, lat:60.9040894 },
        { lng:27.2124468	, lat:60.9046824 },
        { lng:27.2076983	, lat:60.9080505 },
        { lng:27.2063741	, lat:60.9089677 },
        { lng:27.1843867	, lat:60.9247535 },
        { lng:27.1609267	, lat:60.9365321 },
        { lng:27.1361398	, lat:60.9512422 },
        { lng:27.1324674	, lat:60.9548006 },
        { lng:27.1278808	, lat:60.9588148 },
        { lng:27.1238467	, lat:60.9613 },
        { lng:27.1211303	, lat:60.9640085 },
        { lng:27.12282	, lat:60.9644786 },
        { lng:27.1243596	, lat:60.9651764 },
        { lng:27.1260226	, lat:60.9654613 },
        { lng:27.1294183	, lat:60.9669089 },
        { lng:27.1318816	, lat:60.9661486 },
        { lng:27.1329831	, lat:60.966296 },
        { lng:27.1350197	, lat:60.9648614 },
        { lng:27.1389241	, lat:60.9634826 },
        { lng:27.1407079	, lat:60.9594221 },
        { lng:27.1420815	, lat:60.9580882 },
        { lng:27.1456221	, lat:60.9567799 },
        { lng:27.1457229	, lat:60.9552803 },
        { lng:27.1493768	, lat:60.9542136 },
        { lng:27.1509217	, lat:60.9532134 },
        { lng:27.152295	, lat:60.9532134 },
        { lng:27.1541944	, lat:60.9524331 },
        { lng:27.1570246	, lat:60.9524544 },
        { lng:27.1584987	, lat:60.9532368 },
        { lng:27.1591457	, lat:60.9545088 },
        { lng:27.1597143	, lat:60.9547203 },
        { lng:27.1602733	, lat:60.954527 },
        { lng:27.1606209	, lat:60.9535467 },
        { lng:27.1615393	, lat:60.9529508 },
        { lng:27.1643632	, lat:60.9529243 },
        { lng:27.1658116	, lat:60.9530139 },
        { lng:27.1684498	, lat:60.9541427 },
        { lng:27.169631	, lat:60.9530763 },
        { lng:27.1709871	, lat:60.952671 },
        { lng:27.1721674	, lat:60.9524482 },
        { lng:27.1743314	, lat:60.9507369 },
        { lng:27.1800616	, lat:60.9504593 },
        { lng:27.1849465	, lat:60.9508557 },
        { lng:27.1863627	, lat:60.9519799 },
        { lng:27.1876501	, lat:60.9526243 },
        { lng:27.1890031	, lat:60.9529259 },
        { lng:27.1912422	, lat:60.9527024 },
        { lng:27.1938041	, lat:60.9520147 },
        { lng:27.1952396	, lat:60.9519522 },
        { lng:27.1969832	, lat:60.9524065 },
        { lng:27.197689	, lat:60.953354 },
        { lng:27.1998993	, lat:60.9534192 },
        { lng:27.2022305	, lat:60.9543473 },
        { lng:27.205108	, lat:60.9544395 },
        { lng:27.2123383	, lat:60.9556559 },
        { lng:27.213639	, lat:60.9562086 },
        { lng:27.2147292	, lat:60.9563012 },
        { lng:27.2160826	, lat:60.9570585 },
        { lng:27.2170676	, lat:60.9571683 },
        { lng:27.2200426	, lat:60.9585804 },
        { lng:27.2220639	, lat:60.9602657 },
        { lng:27.2266461	, lat:60.9605875 },
        { lng:27.2346048	, lat:60.9632414 },
        { lng:27.2416527	, lat:60.9634763 },
        { lng:27.2442158	, lat:60.9646766 },
        { lng:27.244419	, lat:60.9651261 },
        { lng:27.2457521	, lat:60.9663842 },
        { lng:27.2458578	, lat:60.9667136 },
        { lng:27.245391	, lat:60.9669179 },
        { lng:27.2453182	, lat:60.9672132 },
        { lng:27.2457646	, lat:60.9674207 },
        { lng:27.2474488	, lat:60.9675227 },
        { lng:27.2481425	, lat:60.9677794 },
        { lng:27.2482857	, lat:60.9683471 },
        { lng:27.2482988	, lat:60.9687742 },
        { lng:27.2483104	, lat:60.9691526 },
        { lng:27.2483459	, lat:60.9703111 },
        { lng:27.241635	, lat:60.9731807 },
        { lng:27.2287519	, lat:60.9712294 },
        { lng:27.2192425	, lat:60.969789 },
        { lng:27.2195637	, lat:60.9700806 },
        { lng:27.2204547	, lat:60.9704055 },
        { lng:27.2209402	, lat:60.9710154 },
        { lng:27.2212143	, lat:60.9712989 },
        { lng:27.221202	, lat:60.9715662 },
        { lng:27.2210514	, lat:60.9717874 },
        { lng:27.2198411	, lat:60.9723213 },
        { lng:27.2185134	, lat:60.9729492 },
        { lng:27.2175413	, lat:60.9736306 },
        { lng:27.2165838	, lat:60.9739663 },
        { lng:27.2160847	, lat:60.9741413 },
        { lng:27.2125806	, lat:60.9786249 },
        { lng:27.2127908	, lat:60.9825819 },
        { lng:27.2133814	, lat:60.983851 },
        { lng:27.213382	, lat:60.9847366 },
        { lng:27.2146384	, lat:60.9859659 },
        { lng:27.2175545	, lat:60.9869038 },
        { lng:27.2180806	, lat:60.988282 },
        { lng:27.2180883	, lat:60.988302 },
        { lng:27.2176177	, lat:60.988814 },
        { lng:27.2168646	, lat:60.9885065 },
        { lng:27.2158716	, lat:60.9883743 },
        { lng:27.2155878	, lat:60.9882559 },
        { lng:27.2148347	, lat:60.9882749 },
        { lng:27.2146405	, lat:60.98822 },
        { lng:27.2141662	, lat:60.988233 },
        { lng:27.2132849	, lat:60.9884516 },
        { lng:27.2124846	, lat:60.9891683 },
        { lng:27.2127066	, lat:60.9894035 },
        { lng:27.2121122	, lat:60.9895984 },
        { lng:27.2118172	, lat:60.9894959 },
        { lng:27.211631	, lat:60.9895581 },
        { lng:27.2116063	, lat:60.9898585 },
        { lng:27.2117426	, lat:60.9900466 },
        { lng:27.2111901	, lat:60.9902069 },
        { lng:27.2109519	, lat:60.9901996 },
        { lng:27.2107663	, lat:60.9902618 },
        { lng:27.2108194	, lat:60.9905693 },
        { lng:27.2106461	, lat:60.9906299 },
        { lng:27.2103307	, lat:60.9905885 },
        { lng:27.2103162	, lat:60.9906879 },
        { lng:27.2100544	, lat:60.9907407 },
        { lng:27.2101327	, lat:60.9908867 },
        { lng:27.2098248	, lat:60.9909436 },
        { lng:27.2098382	, lat:60.991095 },
        { lng:27.209628	, lat:60.9912657 },
        { lng:27.2091548	, lat:60.9914717 },
        { lng:27.2093281	, lat:60.9918614 },
        { lng:27.2088755	, lat:60.991991 },
        { lng:27.2086323	, lat:60.9922253 },
        { lng:27.2085481	, lat:60.9928445 },
        { lng:27.208959	, lat:60.993301 },
        { lng:27.2093774	, lat:60.993327 },
        { lng:27.2094144	, lat:60.9936579 },
        { lng:27.2092916	, lat:60.9937916 },
        { lng:27.2091688	, lat:60.9943612 },
        { lng:27.2093104	, lat:60.9948208 },
        { lng:27.2093227	, lat:60.9953094 },
        { lng:27.2091618	, lat:60.9954914 },
        { lng:27.2093978	, lat:60.9956319 },
        { lng:27.2093227	, lat:60.9957567 },
        { lng:27.2089687	, lat:60.9958348 },
        { lng:27.2088185	, lat:60.9959804 },
        { lng:27.2088721	, lat:60.9961365 },
        { lng:27.2085395	, lat:60.9962666 },
        { lng:27.2083217	, lat:60.9965607 },
        { lng:27.208443	, lat:60.9968232 },
        { lng:27.2082176	, lat:60.9970104 },
        { lng:27.208282	, lat:60.9971925 },
        { lng:27.2079709	, lat:60.997333 },
        { lng:27.2079602	, lat:60.997463 },
        { lng:27.2082606	, lat:60.9975514 },
        { lng:27.2081211	, lat:60.9977023 },
        { lng:27.2077134	, lat:60.9977959 },
        { lng:27.207413	, lat:60.9977699 },
        { lng:27.2073164	, lat:60.9978063 },
        { lng:27.2074452	, lat:60.9980092 },
        { lng:27.2071662	, lat:60.99803 },
        { lng:27.2071984	, lat:60.9980768 },
        { lng:27.2074666	, lat:60.9981652 },
        { lng:27.2074666	, lat:60.9982433 },
        { lng:27.2073808	, lat:60.9984617 },
        { lng:27.2071662	, lat:60.9986438 },
        { lng:27.2072413	, lat:60.9987166 },
        { lng:27.2072413	, lat:60.9987947 },
        { lng:27.2071662	, lat:60.9988883 },
        { lng:27.2073057	, lat:60.9989351 },
        { lng:27.2071018	, lat:60.9991328 },
        { lng:27.2068765	, lat:60.9992888 },
        { lng:27.2070911	, lat:60.9995749 },
        { lng:27.2072842	, lat:60.9996061 },
        { lng:27.207295	, lat:60.9997309 },
        { lng:27.2071126	, lat:60.9997413 },
        { lng:27.2071018	, lat:60.9998454 },
        { lng:27.2066834	, lat:61.0001002 },
        { lng:27.2067371	, lat:61.0002355 },
        { lng:27.2064796	, lat:61.0004279 },
        { lng:27.2062865	, lat:61.0004539 },
        { lng:27.2059431	, lat:61.0005632 },
        { lng:27.2056964	, lat:61.0006152 },
        { lng:27.2047522	, lat:61.0005892 },
        { lng:27.2045484	, lat:61.0005476 },
        { lng:27.2043445	, lat:61.0005996 },
        { lng:27.204248	, lat:61.0008388 },
        { lng:27.2043338	, lat:61.0009273 },
        { lng:27.2043123	, lat:61.0011769 },
        { lng:27.2044518	, lat:61.0012549 },
        { lng:27.2044518	, lat:61.0013434 },
        { lng:27.2046557	, lat:61.0014318 },
        { lng:27.2047522	, lat:61.0015514 },
        { lng:27.2046342	, lat:61.0016398 },
        { lng:27.2049024	, lat:61.001697 },
        { lng:27.2048702	, lat:61.0018687 },
        { lng:27.2045806	, lat:61.0020663 },
        { lng:27.2043982	, lat:61.0022536 },
        { lng:27.2045269	, lat:61.0023472 },
        { lng:27.2045162	, lat:61.0025292 },
        { lng:27.2044518	, lat:61.002576 },
        { lng:27.2042802	, lat:61.0025448 },
        { lng:27.2042158	, lat:61.0025708 },
        { lng:27.2042265	, lat:61.002654 },
        { lng:27.2039368	, lat:61.0026384 },
        { lng:27.2038832	, lat:61.0027685 },
        { lng:27.2038403	, lat:61.0028465 },
        { lng:27.2037437	, lat:61.0027373 },
        { lng:27.2036901	, lat:61.0027529 },
        { lng:27.2036901	, lat:61.0029297 },
        { lng:27.2036257	, lat:61.0029921 },
        { lng:27.203336	, lat:61.0029921 },
        { lng:27.2033468	, lat:61.0031377 },
        { lng:27.2032502	, lat:61.0031897 },
        { lng:27.2030893	, lat:61.0031065 },
        { lng:27.2029927	, lat:61.0032157 },
        { lng:27.202703	, lat:61.0031845 },
        { lng:27.2024777	, lat:61.0032157 },
        { lng:27.2022846	, lat:61.0032677 },
        { lng:27.2020808	, lat:61.0031481 },
        { lng:27.2018876	, lat:61.0031325 },
        { lng:27.2016945	, lat:61.0031845 },
        { lng:27.2015229	, lat:61.0031533 },
        { lng:27.2014048	, lat:61.0032105 },
        { lng:27.2011688	, lat:61.0032209 },
        { lng:27.2009328	, lat:61.003351 },
        { lng:27.2005251	, lat:61.003351 },
        { lng:27.2003212	, lat:61.0034082 },
        { lng:27.2002461	, lat:61.0035954 },
        { lng:27.2000852	, lat:61.0037462 },
        { lng:27.1997097	, lat:61.0038554 },
        { lng:27.1993234	, lat:61.0040583 },
        { lng:27.1991089	, lat:61.0044067 },
        { lng:27.1992376	, lat:61.00462 },
        { lng:27.1992591	, lat:61.0047656 },
        { lng:27.1990338	, lat:61.0050672 },
        { lng:27.1990445	, lat:61.005218 },
        { lng:27.1987763	, lat:61.0054052 },
        { lng:27.1984651	, lat:61.0055093 },
        { lng:27.1983042	, lat:61.0056237 },
        { lng:27.1980682	, lat:61.0056809 },
        { lng:27.198036	, lat:61.0058473 },
        { lng:27.1975961	, lat:61.0060293 },
        { lng:27.1973064	, lat:61.0062477 },
        { lng:27.1968558	, lat:61.006799 },
        { lng:27.1966841	, lat:61.0068874 },
        { lng:27.1960511	, lat:61.0070174 },
        { lng:27.1958366	, lat:61.0069602 },
        { lng:27.1956327	, lat:61.0069394 },
        { lng:27.1952572	, lat:61.0070174 },
        { lng:27.1950641	, lat:61.0071994 },
        { lng:27.1946457	, lat:61.0073814 },
        { lng:27.1929827	, lat:61.007657 },
        { lng:27.1906734	, lat:61.0041602 },
        { lng:27.186612	, lat:61.0031442 },
        { lng:27.1733328	, lat:60.9980024 },
        { lng:27.1719907	, lat:60.9980019 },
        { lng:27.1667808	, lat:60.9997595 },
        { lng:27.1616047	, lat:61.0046673 },
        { lng:27.1495884	, lat:61.0080954 },
        { lng:27.1411491	, lat:61.0105352 },
        { lng:27.1417649	, lat:61.0146469 },
        { lng:27.1479104	, lat:61.0212542 },
        { lng:27.1540872	, lat:61.023061 },
        { lng:27.159516	, lat:61.027726 },
        { lng:27.1506934	, lat:61.0441865 },
        { lng:27.1468998	, lat:61.0479044 },
        { lng:27.1513693	, lat:61.0587321 },
        { lng:27.1496505	, lat:61.0673639 },
        { lng:27.1529165	, lat:61.0708621 },
        { lng:27.1614073	, lat:61.0715472 },
        { lng:27.1692862	, lat:61.0757865 },
        { lng:27.1735964	, lat:61.0781335 },
        { lng:27.1762352	, lat:61.0800977 },
        { lng:27.1791204	, lat:61.0822915 },
        { lng:27.1807369	, lat:61.0835979 },
        { lng:27.1833581	, lat:61.0850311 },
        { lng:27.1850733	, lat:61.0860555 },
        { lng:27.2123344	, lat:61.089695 },
        { lng:27.2179692	, lat:61.0901058 },
        { lng:27.2232752	, lat:61.090156 },
        { lng:27.2285288	, lat:61.0902056 },
        { lng:27.2284882	, lat:61.0919382 },
        { lng:27.2281228	, lat:61.0922054 },
        { lng:27.2231795	, lat:61.0958184 },
        { lng:27.2222762	, lat:61.0978605 },
        { lng:27.2132619	, lat:61.1033649 },
        { lng:27.2019237	, lat:61.1065729 },
        { lng:27.1899889	, lat:61.1015607 },
        { lng:27.189347	, lat:61.1015481 },
        { lng:27.1883022	, lat:61.1015274 },
        { lng:27.1873688	, lat:61.1017203 },
        { lng:27.1866242	, lat:61.1023113 },
        { lng:27.1844012	, lat:61.1028049 },
        { lng:27.1836705	, lat:61.1032239 },
        { lng:27.1829142	, lat:61.1035058 },
        { lng:27.1823113	, lat:61.1041518 },
        { lng:27.1825516	, lat:61.1049938 },
        { lng:27.1825047	, lat:61.1053521 },
        { lng:27.1824271	, lat:61.1059435 },
        { lng:27.181041	, lat:61.1063468 },
        { lng:27.1800732	, lat:61.106803 },
        { lng:27.1786377	, lat:61.1070508 },
        { lng:27.1780433	, lat:61.1069793 },
        { lng:27.176683	, lat:61.1070198 },
        { lng:27.1805937	, lat:61.1091858 },
        { lng:27.1854098	, lat:61.1118529 },
        { lng:27.1867917	, lat:61.1210723 },
        { lng:27.2121127	, lat:61.1384406 },
        { lng:27.218276	, lat:61.1427824 },
        { lng:27.2153384	, lat:61.1458363 },
        { lng:27.2134781	, lat:61.1460258 },
        { lng:27.2125919	, lat:61.1503166 },
        { lng:27.2107744	, lat:61.1529142 },
        { lng:27.2111006	, lat:61.1531865 },
        { lng:27.2108903	, lat:61.1538594 },
        { lng:27.2113945	, lat:61.1544267 },
        { lng:27.2107444	, lat:61.1552487 },
        { lng:27.2094977	, lat:61.1556193 },
        { lng:27.2083711	, lat:61.1557011 },
        { lng:27.2079828	, lat:61.1553678 },
        { lng:27.2074292	, lat:61.1554692 },
        { lng:27.2096886	, lat:61.1591967 },
        { lng:27.2094423	, lat:61.1610085 },
        { lng:27.2058143	, lat:61.1624774 },
        { lng:27.1855455	, lat:61.1619146 },
        { lng:27.1726462	, lat:61.1616512 },
        { lng:27.1667485	, lat:61.1609903 },
        { lng:27.1621362	, lat:61.1621966 },
        { lng:27.1571902	, lat:61.1623053 },
        { lng:27.1471726	, lat:61.1631839 },
        { lng:27.1449729	, lat:61.1626157 },
        { lng:27.1413201	, lat:61.1616719 },
        { lng:27.1317296	, lat:61.1610385 },
        { lng:27.1241475	, lat:61.1641175 },
        { lng:27.1209052	, lat:61.1643823 },
        { lng:27.1179881	, lat:61.1618463 },
        { lng:27.1139133	, lat:61.1594616 },
        { lng:27.1115386	, lat:61.1579243 },
        { lng:27.096366	, lat:61.1553686 },
        { lng:27.0862251	, lat:61.1555067 },
        { lng:27.0839442	, lat:61.1559778 },
        { lng:27.0810204	, lat:61.1556682 },
        { lng:27.079864	, lat:61.1552874 },
        { lng:27.0785848	, lat:61.1552639 },
        { lng:27.0561392	, lat:61.1516354 },
        { lng:27.0473458	, lat:61.1512994 },
        { lng:27.0312397	, lat:61.1520594 },
        { lng:27.0229195	, lat:61.156324 },
        { lng:27.0038355	, lat:61.1596588 },
        { lng:27.000889	, lat:61.1603992 },
        { lng:26.9669754	, lat:61.1692968 },
        { lng:26.954546	, lat:61.1713967 },
        { lng:26.9502588	, lat:61.1728028 },
        { lng:26.9365226	, lat:61.1749956 },
        { lng:26.9210688	, lat:61.1802171 },
        { lng:26.9200024	, lat:61.1810053 },
        { lng:26.9180701	, lat:61.1811842 },
        { lng:26.9121767	, lat:61.1831705 },
        { lng:26.9272467	, lat:61.206456 },
        { lng:26.9294386	, lat:61.2099371 },
        { lng:26.9301177	, lat:61.2100819 },
        { lng:26.9294096	, lat:61.2122091 },
        { lng:26.9254797	, lat:61.2125532 },
        { lng:26.9265547	, lat:61.2152784 },
        { lng:26.9263079	, lat:61.2153172 },
        { lng:26.92678	, lat:61.2183454 },
        { lng:26.9213233	, lat:61.2198853 },
        { lng:26.920759	, lat:61.2257733 },
        { lng:26.9190521	, lat:61.2275281 },
        { lng:26.9144944	, lat:61.2284855 },
        { lng:26.9071591	, lat:61.2316855 },
        { lng:26.9054736	, lat:61.2340817 },
        { lng:26.9028622	, lat:61.2351359 },
        { lng:26.8994118	, lat:61.2351639 },
        { lng:26.890848	, lat:61.2424447 },
        { lng:26.8967171	, lat:61.2441064 },
        { lng:26.8795075	, lat:61.275456 },
        { lng:26.8780143	, lat:61.2781748 },
        { lng:26.8803046	, lat:61.2817309 },
        { lng:26.8808478	, lat:61.2844809 },
        { lng:26.8811767	, lat:61.2852131 },
        { lng:26.8523989	, lat:61.2837023 },
        { lng:26.8318191	, lat:61.2799907 },
        { lng:26.8288532	, lat:61.2794173 },
        { lng:26.8089559	, lat:61.2754008 },
        { lng:26.7809687	, lat:61.2698449 },
        { lng:26.7793712	, lat:61.2700585 },
        { lng:26.771943	, lat:61.266148 },
        { lng:26.7733907	, lat:61.2585811 },
        { lng:26.7724241	, lat:61.2568267 },
        { lng:26.7705192	, lat:61.256437 },
        { lng:26.7698675	, lat:61.2543952 },
        { lng:26.763873	, lat:61.2485967 },
        { lng:26.7633453	, lat:61.2463692 },
        { lng:26.7612509	, lat:61.2444504 },
        { lng:26.7631177	, lat:61.2432478 },
        { lng:26.7605944	, lat:61.239685 },
        { lng:26.7547987	, lat:61.2381672 },
        { lng:26.7543458	, lat:61.2364689 },
        { lng:26.7507046	, lat:61.2351895 },
        { lng:26.745428	, lat:61.229645 },
        { lng:26.6613125	, lat:61.206878 },
        { lng:26.6298701	, lat:61.2210325 },
        { lng:26.6711145	, lat:61.2435837 },
        { lng:26.6670026	, lat:61.2523106 },
        { lng:26.6520976	, lat:61.2629969 },
        { lng:26.6517918	, lat:61.2633299 },
        { lng:26.6517757	, lat:61.2637209 },
        { lng:26.6520251	, lat:61.2638542 },
        { lng:26.6520117	, lat:61.2639643 },
        { lng:26.651519	, lat:61.2639506 },
        { lng:26.650331	, lat:61.2642702 },
        { lng:26.6500297	, lat:61.2643883 },
        { lng:26.6448663	, lat:61.275758 },
        { lng:26.6418258	, lat:61.2824864 },
        { lng:26.6405539	, lat:61.2832164 },
        { lng:26.6399348	, lat:61.283436 },
        { lng:26.6398533	, lat:61.2836417 },
        { lng:26.6399659	, lat:61.2839474 },
        { lng:26.6398608	, lat:61.2841567 },
        { lng:26.639555	, lat:61.2842477 },
        { lng:26.6386017	, lat:61.2843941 },
        { lng:26.6381452	, lat:61.2845312 },
        { lng:26.6372027	, lat:61.2848369 },
        { lng:26.6364781	, lat:61.2852258 },
        { lng:26.6358343	, lat:61.2853858 },
        { lng:26.6356493	, lat:61.2855119 },
        { lng:26.6351155	, lat:61.2855149 },
        { lng:26.6347549	, lat:61.2858052 },
        { lng:26.6346208	, lat:61.2859889 },
        { lng:26.6340854	, lat:61.2862106 },
        { lng:26.6342646	, lat:61.2862783 },
        { lng:26.6342308	, lat:61.2865518 },
        { lng:26.6341541	, lat:61.286625 },
        { lng:26.6336198	, lat:61.2866577 },
        { lng:26.6330415	, lat:61.2871474 },
        { lng:26.6331156	, lat:61.2874669 },
        { lng:26.6330297	, lat:61.2876545 },
        { lng:26.6291502	, lat:61.2925944 },
        { lng:26.6171833	, lat:61.2870652 },
        { lng:26.5894352	, lat:61.2791677 },
        { lng:26.5617935	, lat:61.2705528 },
        { lng:26.5505132	, lat:61.2763777 },
        { lng:26.550202	, lat:61.276881 },
        { lng:26.5496483	, lat:61.2770088 },
        { lng:26.5488782	, lat:61.2769285 },
        { lng:26.5485413	, lat:61.2771873 },
        { lng:26.5476315	, lat:61.2770646 },
        { lng:26.5456681	, lat:61.277343 },
        { lng:26.5445416	, lat:61.2771616 },
        { lng:26.5422477	, lat:61.2774852 },
        { lng:26.5407331	, lat:61.2770942 },
        { lng:26.5401149	, lat:61.2767872 },
        { lng:26.5395894	, lat:61.2767476 },
        { lng:26.5364055	, lat:61.2760296 },
        { lng:26.5347053	, lat:61.2755641 },
        { lng:26.5349479	, lat:61.274106 },
        { lng:26.5341818	, lat:61.2733263 },
        { lng:26.5330338	, lat:61.273087 },
        { lng:26.5326176	, lat:61.2727426 },
        { lng:26.5320275	, lat:61.2725662 },
        { lng:26.5321949	, lat:61.2722269 },
        { lng:26.5313902	, lat:61.272165 },
        { lng:26.5295277	, lat:61.2728673 },
        { lng:26.5270944	, lat:61.27289 },
        { lng:26.5261803	, lat:61.2730117 },
        { lng:26.5249894	, lat:61.2721846 },
        { lng:26.5250194	, lat:61.271772 },
        { lng:26.5236483	, lat:61.2716854 },
        { lng:26.5230796	, lat:61.2712955 },
        { lng:26.5222578	, lat:61.2711347 },
        { lng:26.5185006	, lat:61.2720412 },
        { lng:26.516063	, lat:61.2719587 },
        { lng:26.5154085	, lat:61.2716091 },
        { lng:26.5141618	, lat:61.2715761 },
        { lng:26.5137025	, lat:61.2743503 },
        { lng:26.5051196	, lat:61.2697732 },
        { lng:26.4848635	, lat:61.2652665 },
        { lng:26.4673326	, lat:61.263131 },
        { lng:26.4439303	, lat:61.2628364 },
        { lng:26.4440376	, lat:61.2624547 },
        { lng:26.4441878	, lat:61.2622793 },
        { lng:26.4443165	, lat:61.2620214 },
        { lng:26.4447457	, lat:61.2618357 },
        { lng:26.4447457	, lat:61.2617325 },
        { lng:26.4450247	, lat:61.2613817 },
        { lng:26.4447457	, lat:61.2612579 },
        { lng:26.44481	, lat:61.2611547 },
        { lng:26.4450462	, lat:61.2610928 },
        { lng:26.4448531	, lat:61.2609071 },
        { lng:26.4450246	, lat:61.2606698 },
        { lng:26.4445741	, lat:61.260577 },
        { lng:26.4444668	, lat:61.2604738 },
        { lng:26.4449389	, lat:61.2603809 },
        { lng:26.4448744	, lat:61.2600817 },
        { lng:26.4448959	, lat:61.2598857 },
        { lng:26.4447457	, lat:61.2597516 },
        { lng:26.4445526	, lat:61.2597825 },
        { lng:26.4444238	, lat:61.2596484 },
        { lng:26.4445955	, lat:61.259442 },
        { lng:26.4446598	, lat:61.2593079 },
        { lng:26.4443809	, lat:61.2591531 },
        { lng:26.4444667	, lat:61.2589261 },
        { lng:26.4440376	, lat:61.2589365 },
        { lng:26.4438659	, lat:61.2588849 },
        { lng:26.4436299	, lat:61.2588746 },
        { lng:26.4433938	, lat:61.2587817 },
        { lng:26.443351	, lat:61.2586166 },
        { lng:26.4432007	, lat:61.2582555 },
        { lng:26.4432301	, lat:61.2580902 },
        { lng:26.4430292	, lat:61.2580801 },
        { lng:26.4430077	, lat:61.2579046 },
        { lng:26.4432436	, lat:61.2577189 },
        { lng:26.4432651	, lat:61.2575332 },
        { lng:26.443587	, lat:61.257461 },
        { lng:26.4437157	, lat:61.2572855 },
        { lng:26.4437587	, lat:61.2570379 },
        { lng:26.4434153	, lat:61.2566767 },
        { lng:26.4434582	, lat:61.2565426 },
        { lng:26.4434368	, lat:61.2563878 },
        { lng:26.4431149	, lat:61.2563981 },
        { lng:26.4427931	, lat:61.2563878 },
        { lng:26.4425784	, lat:61.2563053 },
        { lng:26.4424068	, lat:61.2563775 },
        { lng:26.4425141	, lat:61.2565426 },
        { lng:26.4424497	, lat:61.2566148 },
        { lng:26.4420205	, lat:61.2565942 },
        { lng:26.4417845	, lat:61.2565426 },
        { lng:26.4416987	, lat:61.2563981 },
        { lng:26.4413554	, lat:61.2563672 },
        { lng:26.4411622	, lat:61.2564291 },
        { lng:26.4408618	, lat:61.2565116 },
        { lng:26.4407116	, lat:61.2564188 },
        { lng:26.4407545	, lat:61.2562537 },
        { lng:26.4404541	, lat:61.2560989 },
        { lng:26.4402181	, lat:61.2560886 },
        { lng:26.440025	, lat:61.2560576 },
        { lng:26.4398533	, lat:61.2560886 },
        { lng:26.4395316	, lat:61.2560989 },
        { lng:26.4394028	, lat:61.2559441 },
        { lng:26.4391882	, lat:61.2558512 },
        { lng:26.4387804	, lat:61.2557996 },
        { lng:26.4383727	, lat:61.2557584 },
        { lng:26.4381152	, lat:61.2557893 },
        { lng:26.4379221	, lat:61.255748 },
        { lng:26.4377505	, lat:61.2557996 },
        { lng:26.4373858	, lat:61.25581 },
        { lng:26.4371926	, lat:61.2557584 },
        { lng:26.4370425	, lat:61.2557687 },
        { lng:26.4368923	, lat:61.2559441 },
        { lng:26.4365489	, lat:61.2559235 },
        { lng:26.4362485	, lat:61.2558203 },
        { lng:26.4360982	, lat:61.2557068 },
        { lng:26.4358622	, lat:61.2556861 },
        { lng:26.4353902	, lat:61.2557274 },
        { lng:26.4351112	, lat:61.2556552 },
        { lng:26.434682	, lat:61.2556655 },
        { lng:26.4342101	, lat:61.2555933 },
        { lng:26.433974	, lat:61.2553869 },
        { lng:26.4337379	, lat:61.2553456 },
        { lng:26.4333516	, lat:61.2552424 },
        { lng:26.4331585	, lat:61.2551495 },
        { lng:26.4330299	, lat:61.2550463 },
        { lng:26.4328581	, lat:61.2548503 },
        { lng:26.4326435	, lat:61.2546542 },
        { lng:26.4325149	, lat:61.2542105 },
        { lng:26.4323002	, lat:61.254066 },
        { lng:26.4319355	, lat:61.2540247 },
        { lng:26.4315707	, lat:61.2540454 },
        { lng:26.4312917	, lat:61.2539112 },
        { lng:26.4313346	, lat:61.2537564 },
        { lng:26.4312488	, lat:61.2536119 },
        { lng:26.4308627	, lat:61.253581 },
        { lng:26.4304549	, lat:61.2534262 },
        { lng:26.4301759	, lat:61.2533952 },
        { lng:26.429897	, lat:61.2531372 },
        { lng:26.4296396	, lat:61.2531579 },
        { lng:26.4293176	, lat:61.2531475 },
        { lng:26.4292532	, lat:61.2529308 },
        { lng:26.4289957	, lat:61.2526728 },
        { lng:26.4287168	, lat:61.2524458 },
        { lng:26.4283306	, lat:61.2524871 },
        { lng:26.4280946	, lat:61.2524458 },
        { lng:26.4280516	, lat:61.25226 },
        { lng:26.4275366	, lat:61.2521775 },
        { lng:26.4268714	, lat:61.2520949 },
        { lng:26.4265281	, lat:61.2519091 },
        { lng:26.4260131	, lat:61.2519195 },
        { lng:26.4256913	, lat:61.2518988 },
        { lng:26.4254767	, lat:61.2517131 },
        { lng:26.4251119	, lat:61.2517234 },
        { lng:26.4248759	, lat:61.2514963 },
        { lng:26.4251548	, lat:61.2512383 },
        { lng:26.4250261	, lat:61.2511041 },
        { lng:26.425305	, lat:61.2510525 },
        { lng:26.4251334	, lat:61.2509184 },
        { lng:26.4254338	, lat:61.2508771 },
        { lng:26.4257771	, lat:61.2506088 },
        { lng:26.4262277	, lat:61.2504643 },
        { lng:26.42685	, lat:61.2504127 },
        { lng:26.4272148	, lat:61.2502269 },
        { lng:26.4277083	, lat:61.2502062 },
        { lng:26.4278799	, lat:61.2499482 },
        { lng:26.4283736	, lat:61.2498553 },
        { lng:26.4287382	, lat:61.2499482 },
        { lng:26.4289099	, lat:61.2497934 },
        { lng:26.4286953	, lat:61.2497315 },
        { lng:26.4286096	, lat:61.2494735 },
        { lng:26.4288886	, lat:61.2493703 },
        { lng:26.4290387	, lat:61.2492464 },
        { lng:26.4287597	, lat:61.2491122 },
        { lng:26.4288242	, lat:61.249009 },
        { lng:26.4291245	, lat:61.2489574 },
        { lng:26.42891	, lat:61.2486581 },
        { lng:26.4289958	, lat:61.2485549 },
        { lng:26.4293821	, lat:61.2485136 },
        { lng:26.4292103	, lat:61.2484001 },
        { lng:26.4292319	, lat:61.2483072 },
        { lng:26.429618	, lat:61.2482039 },
        { lng:26.429854	, lat:61.2482143 },
        { lng:26.4297468	, lat:61.2479356 },
        { lng:26.4298326	, lat:61.2475846 },
        { lng:26.4297038	, lat:61.2473472 },
        { lng:26.4293562	, lat:61.2469812 },
        { lng:26.4293079	, lat:61.2468688 },
        { lng:26.431304	, lat:61.2372934 },
        { lng:26.3964021	, lat:61.2136933 },
        { lng:26.3854801	, lat:61.1881371 },
        { lng:26.3642337	, lat:61.180518 },
        { lng:26.3476878	, lat:61.1737002 },
        { lng:26.3398504	, lat:61.1726237 },
        { lng:26.3396605	, lat:61.1724369 },
        { lng:26.3389353	, lat:61.1723759 },
        { lng:26.3384492	, lat:61.1724571 },
        { lng:26.328391	, lat:61.1711684 },
        { lng:26.3054752	, lat:61.1678671 },
        { lng:26.3043176	, lat:61.1684688 },
        { lng:26.3022394	, lat:61.1680901 },
        { lng:26.3027619	, lat:61.1675085 },
        { lng:26.2841292	, lat:61.1648889 },
        { lng:26.2705764	, lat:61.1630048 },
        { lng:26.2690036	, lat:61.1495383 },
        { lng:26.2354153	, lat:61.1305446 },
        { lng:26.2356111	, lat:61.1298416 },
      ],
    },
    {
      id: 14,
      regionName: 'eCom TP Kuopio kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:26.9089472	, lat:62.8499494 },
        { lng:26.9131293	, lat:62.8611778 },
        { lng:26.9005402	, lat:62.8698311 },
        { lng:26.9037373	, lat:62.8730852 },
        { lng:26.9045033	, lat:62.8726861 },
        { lng:26.9135864	, lat:62.8713437 },
        { lng:26.9166698	, lat:62.8725099 },
        { lng:26.9164553	, lat:62.873958 },
        { lng:26.9165625	, lat:62.8740167 },
        { lng:26.9165196	, lat:62.8741341 },
        { lng:26.9163909	, lat:62.8742319 },
        { lng:26.9164338	, lat:62.8743004 },
        { lng:26.9164123	, lat:62.874545 },
        { lng:26.9162836	, lat:62.8747504 },
        { lng:26.9163265	, lat:62.8749069 },
        { lng:26.9162836	, lat:62.8751222 },
        { lng:26.9160905	, lat:62.8752885 },
        { lng:26.9160046	, lat:62.8754548 },
        { lng:26.9157901	, lat:62.8756603 },
        { lng:26.9156828	, lat:62.875895 },
        { lng:26.9155111	, lat:62.8760516 },
        { lng:26.915318	, lat:62.876169 },
        { lng:26.9152965	, lat:62.8763059 },
        { lng:26.915082	, lat:62.876394 },
        { lng:26.9146957	, lat:62.8766288 },
        { lng:26.9140949	, lat:62.8773331 },
        { lng:26.9139662	, lat:62.8775679 },
        { lng:26.9137945	, lat:62.8777538 },
        { lng:26.9128718	, lat:62.8787418 },
        { lng:26.9127431	, lat:62.8789276 },
        { lng:26.9125929	, lat:62.8791037 },
        { lng:26.9123568	, lat:62.8793287 },
        { lng:26.9118526	, lat:62.8797424 },
        { lng:26.9106831	, lat:62.8806883 },
        { lng:26.9111123	, lat:62.8868695 },
        { lng:26.9094171	, lat:62.8879256 },
        { lng:26.9080868	, lat:62.8882679 },
        { lng:26.9100823	, lat:62.889588 },
        { lng:26.9118633	, lat:62.8901747 },
        { lng:26.9080653	, lat:62.893548 },
        { lng:26.911241	, lat:62.8937729 },
        { lng:26.9266047	, lat:62.8977421 },
        { lng:26.9178951	, lat:62.9034803 },
        { lng:26.9144415	, lat:62.9024541 },
        { lng:26.9084981	, lat:62.9070312 },
        { lng:26.9080009	, lat:62.9073989 },
        { lng:26.9171634	, lat:62.9109656 },
        { lng:26.9209399	, lat:62.909578 },
        { lng:26.9243088	, lat:62.90778 },
        { lng:26.9337166	, lat:62.9139438 },
        { lng:26.934307	, lat:62.9141162 },
        { lng:26.940166	, lat:62.9157727 },
        { lng:26.9388142	, lat:62.9188695 },
        { lng:26.9367113	, lat:62.9232846 },
        { lng:26.9236007	, lat:62.9421584 },
        { lng:26.9118419	, lat:62.9538399 },
        { lng:26.8962207	, lat:62.9565912 },
        { lng:26.8901267	, lat:62.9638391 },
        { lng:26.9048252	, lat:62.976711 },
        { lng:26.8955126	, lat:62.9890414 },
        { lng:26.918086	, lat:62.9917114 },
        { lng:26.9238152	, lat:62.9936213 },
        { lng:26.923944	, lat:62.9938161 },
        { lng:26.9245663	, lat:62.9942741 },
        { lng:26.9247379	, lat:62.9946054 },
        { lng:26.9250598	, lat:62.9949171 },
        { lng:26.9255319	, lat:62.9951705 },
        { lng:26.9257679	, lat:62.9952192 },
        { lng:26.9266477	, lat:62.9951607 },
        { lng:26.9270124	, lat:62.9949951 },
        { lng:26.9275274	, lat:62.9949561 },
        { lng:26.9284501	, lat:62.9949464 },
        { lng:26.9291153	, lat:62.9949951 },
        { lng:26.9296517	, lat:62.9949561 },
        { lng:26.9304457	, lat:62.9949366 },
        { lng:26.9417968	, lat:62.9960863 },
        { lng:26.9616237	, lat:62.9865757 },
        { lng:26.979455	, lat:62.9832814 },
        { lng:26.993719	, lat:62.9900203 },
        { lng:27.0048394	, lat:62.9914386 },
        { lng:27.0135405	, lat:62.9925558 },
        { lng:27.0143022	, lat:62.9898599 },
        { lng:27.0211043	, lat:62.9907272 },
        { lng:27.0283173	, lat:62.9916442 },
        { lng:27.0264366	, lat:62.9941766 },
        { lng:27.0253422	, lat:63.0027643 },
        { lng:27.0348024	, lat:63.0042782 },
        { lng:27.0387897	, lat:63.0057648 },
        { lng:27.0409098	, lat:63.0065434 },
        { lng:27.0449333	, lat:63.0080069 },
        { lng:27.0458346	, lat:63.0083769 },
        { lng:27.0478889	, lat:63.0091973 },
        { lng:27.0473795	, lat:63.0078973 },
        { lng:27.0473256	, lat:63.0077267 },
        { lng:27.0471971	, lat:63.0076538 },
        { lng:27.0471325	, lat:63.0075928 },
        { lng:27.0469608	, lat:63.0075027 },
        { lng:27.0469715	, lat:63.0073834 },
        { lng:27.0470788	, lat:63.0072617 },
        { lng:27.047111	, lat:63.0071351 },
        { lng:27.0469933	, lat:63.0070743 },
        { lng:27.0468967	, lat:63.0069891 },
        { lng:27.0468538	, lat:63.0068771 },
        { lng:27.0468857	, lat:63.0067431 },
        { lng:27.0469501	, lat:63.0064606 },
        { lng:27.0469501	, lat:63.0062439 },
        { lng:27.0468484	, lat:63.0060639 },
        { lng:27.0468377	, lat:63.0059884 },
        { lng:27.0469074	, lat:63.0059178 },
        { lng:27.0471164	, lat:63.0057935 },
        { lng:27.0472615	, lat:63.0056597 },
        { lng:27.047288	, lat:63.0054891 },
        { lng:27.0473095	, lat:63.0054234 },
        { lng:27.0474168	, lat:63.0053649 },
        { lng:27.0476314	, lat:63.0053235 },
        { lng:27.0479318	, lat:63.0052481 },
        { lng:27.0486723	, lat:63.0051264 },
        { lng:27.0489996	, lat:63.0050193 },
        { lng:27.0491927	, lat:63.0049633 },
        { lng:27.0493483	, lat:63.0049584 },
        { lng:27.0494877	, lat:63.0049876 },
        { lng:27.0495789	, lat:63.0050656 },
        { lng:27.049761	, lat:63.0052481 },
        { lng:27.0498686	, lat:63.0052871 },
        { lng:27.0501583	, lat:63.0052847 },
        { lng:27.0502978	, lat:63.0052384 },
        { lng:27.0503619	, lat:63.0051555 },
        { lng:27.050378	, lat:63.0049875 },
        { lng:27.050507	, lat:63.0048586 },
        { lng:27.0506733	, lat:63.0048172 },
        { lng:27.0508288	, lat:63.0046711 },
        { lng:27.0509734	, lat:63.0045882 },
        { lng:27.0511987	, lat:63.0044737 },
        { lng:27.0516547	, lat:63.0043349 },
        { lng:27.0522233	, lat:63.0042132 },
        { lng:27.0529529	, lat:63.0041255 },
        { lng:27.0538651	, lat:63.003999 },
        { lng:27.0541709	, lat:63.0040185 },
        { lng:27.0544552	, lat:63.0040039 },
        { lng:27.0547285	, lat:63.0039575 },
        { lng:27.0554168	, lat:63.0038088 },
        { lng:27.0556139	, lat:63.0039235 },
        { lng:27.0558499	, lat:63.0040526 },
        { lng:27.0560645	, lat:63.004094 },
        { lng:27.0564668	, lat:63.0040964 },
        { lng:27.056692	, lat:63.0040792 },
        { lng:27.0569873	, lat:63.0040429 },
        { lng:27.0571965	, lat:63.0040574 },
        { lng:27.0576854	, lat:63.0041483 },
        { lng:27.0581245	, lat:63.0042546 },
        { lng:27.0583013	, lat:63.0043495 },
        { lng:27.0586771	, lat:63.0044982 },
        { lng:27.0593958	, lat:63.0045226 },
        { lng:27.0598304	, lat:63.0044178 },
        { lng:27.0602319	, lat:63.0043223 },
        { lng:27.0605599	, lat:63.0042937 },
        { lng:27.0610213	, lat:63.004257 },
        { lng:27.0614719	, lat:63.0042035 },
        { lng:27.0620674	, lat:63.0041718 },
        { lng:27.0635423	, lat:63.0040402 },
        { lng:27.0636714	, lat:63.004016 },
        { lng:27.0638481	, lat:63.0039282 },
        { lng:27.0640843	, lat:63.0037823 },
        { lng:27.0644492	, lat:63.0035825 },
        { lng:27.0647332	, lat:63.0034364 },
        { lng:27.0654041	, lat:63.0032173 },
        { lng:27.065742	, lat:63.003132 },
        { lng:27.0659512	, lat:63.0031297 },
        { lng:27.0660958	, lat:63.0031442 },
        { lng:27.0662355	, lat:63.0031929 },
        { lng:27.0663804	, lat:63.0032708 },
        { lng:27.0665038	, lat:63.0033512 },
        { lng:27.0666432	, lat:63.0033926 },
        { lng:27.0668522	, lat:63.0033925 },
        { lng:27.0669973	, lat:63.0033464 },
        { lng:27.0672226	, lat:63.0032782 },
        { lng:27.0674211	, lat:63.0032757 },
        { lng:27.0676998	, lat:63.0033099 },
        { lng:27.0679303	, lat:63.0033026 },
        { lng:27.0681718	, lat:63.003266 },
        { lng:27.0684132	, lat:63.0031833 },
        { lng:27.0684883	, lat:63.0031151 },
        { lng:27.0684776	, lat:63.0029323 },
        { lng:27.0685311	, lat:63.0026937 },
        { lng:27.0686922	, lat:63.0024648 },
        { lng:27.0688577	, lat:63.0023579 },
        { lng:27.0689886	, lat:63.0022942 },
        { lng:27.0691321	, lat:63.002231 },
        { lng:27.0694647	, lat:63.0021676 },
        { lng:27.070044	, lat:63.0020508 },
        { lng:27.0703443	, lat:63.001929 },
        { lng:27.0705696	, lat:63.0018755 },
        { lng:27.0708701	, lat:63.0017828 },
        { lng:27.0710257	, lat:63.0015856 },
        { lng:27.0713314	, lat:63.0011256 },
        { lng:27.0715139	, lat:63.0009308 },
        { lng:27.0716747	, lat:63.0008356 },
        { lng:27.0750597	, lat:62.99991 },
        { lng:27.0758966	, lat:63.0006699 },
        { lng:27.076321	, lat:63.0010525 },
        { lng:27.0766428	, lat:63.0013691 },
        { lng:27.0768144	, lat:63.0021289 },
        { lng:27.0761064	, lat:63.0033124 },
        { lng:27.0746467	, lat:63.0035386 },
        { lng:27.0744756	, lat:63.0037069 },
        { lng:27.0742931	, lat:63.0038384 },
        { lng:27.0742927	, lat:63.0039672 },
        { lng:27.0744219	, lat:63.0045348 },
        { lng:27.0744642	, lat:63.0050727 },
        { lng:27.0744005	, lat:63.0052458 },
        { lng:27.0740785	, lat:63.0057864 },
        { lng:27.0741001	, lat:63.0059227 },
        { lng:27.0741425	, lat:63.0061538 },
        { lng:27.0742396	, lat:63.0063123 },
        { lng:27.0742282	, lat:63.0064411 },
        { lng:27.0741746	, lat:63.0065629 },
        { lng:27.0741966	, lat:63.0066824 },
        { lng:27.0740029	, lat:63.0070157 },
        { lng:27.073842	, lat:63.0072057 },
        { lng:27.0736275	, lat:63.0073031 },
        { lng:27.073467	, lat:63.0074421 },
        { lng:27.0734242	, lat:63.0075736 },
        { lng:27.0733806	, lat:63.0076877 },
        { lng:27.0733914	, lat:63.0079069 },
        { lng:27.0731988	, lat:63.0080943 },
        { lng:27.0727047	, lat:63.0084815 },
        { lng:27.0725659	, lat:63.0086154 },
        { lng:27.0725121	, lat:63.0087468 },
        { lng:27.072523	, lat:63.008854 },
        { lng:27.0726623	, lat:63.0090293 },
        { lng:27.0726624	, lat:63.0091607 },
        { lng:27.0726088	, lat:63.0093312 },
        { lng:27.0725551	, lat:63.00956 },
        { lng:27.0725659	, lat:63.009711 },
        { lng:27.0725014	, lat:63.0098035 },
        { lng:27.0723191	, lat:63.0099495 },
        { lng:27.0722117	, lat:63.0101687 },
        { lng:27.0722655	, lat:63.0102806 },
        { lng:27.0726839	, lat:63.010714 },
        { lng:27.072877	, lat:63.0108698 },
        { lng:27.0732096	, lat:63.0110061 },
        { lng:27.0738533	, lat:63.0111571 },
        { lng:27.0742396	, lat:63.0112009 },
        { lng:27.0745721	, lat:63.011235 },
        { lng:27.0750228	, lat:63.011308 },
        { lng:27.0753661	, lat:63.0113129 },
        { lng:27.0760635	, lat:63.0112593 },
        { lng:27.0764712	, lat:63.0111425 },
        { lng:27.076771	, lat:63.0111157 },
        { lng:27.0770933	, lat:63.0110402 },
        { lng:27.0776186	, lat:63.010955 },
        { lng:27.0778551	, lat:63.0108893 },
        { lng:27.0782199	, lat:63.0107237 },
        { lng:27.0785203	, lat:63.0107091 },
        { lng:27.0788202	, lat:63.0107456 },
        { lng:27.0806554	, lat:63.0110402 },
        { lng:27.0808806	, lat:63.0110743 },
        { lng:27.0818779	, lat:63.0114711 },
        { lng:27.0824149	, lat:63.011605 },
        { lng:27.0829085	, lat:63.0116293 },
        { lng:27.0831015	, lat:63.0116732 },
        { lng:27.0833268	, lat:63.0118241 },
        { lng:27.0838842	, lat:63.0122501 },
        { lng:27.0838526	, lat:63.0123889 },
        { lng:27.0838628	, lat:63.0125374 },
        { lng:27.0840885	, lat:63.012754 },
        { lng:27.0842817	, lat:63.0127978 },
        { lng:27.0847216	, lat:63.0128368 },
        { lng:27.0853117	, lat:63.0128368 },
        { lng:27.0862237	, lat:63.012793 },
        { lng:27.0869747	, lat:63.0127978 },
        { lng:27.0871893	, lat:63.0127492 },
        { lng:27.087511	, lat:63.0127492 },
        { lng:27.0878544	, lat:63.0126664 },
        { lng:27.0882085	, lat:63.0126469 },
        { lng:27.0884655	, lat:63.0126494 },
        { lng:27.0887557	, lat:63.0127005 },
        { lng:27.0890667	, lat:63.0127297 },
        { lng:27.0891466	, lat:63.0127791 },
        { lng:27.0893274	, lat:63.0128039 },
        { lng:27.089496	, lat:63.0128757 },
        { lng:27.0897213	, lat:63.0130267 },
        { lng:27.0900217	, lat:63.0130948 },
        { lng:27.090225	, lat:63.0131995 },
        { lng:27.0903756	, lat:63.0133869 },
        { lng:27.0904181	, lat:63.0134965 },
        { lng:27.0903645	, lat:63.0135793 },
        { lng:27.0904717	, lat:63.0137156 },
        { lng:27.0904616	, lat:63.0138543 },
        { lng:27.090343	, lat:63.0139931 },
        { lng:27.0902255	, lat:63.0141075 },
        { lng:27.0902041	, lat:63.0142779 },
        { lng:27.0900533	, lat:63.0145189 },
        { lng:27.0899465	, lat:63.0146333 },
        { lng:27.0899246	, lat:63.0148499 },
        { lng:27.090011	, lat:63.0149448 },
        { lng:27.0902791	, lat:63.0150812 },
        { lng:27.0905581	, lat:63.0151639 },
        { lng:27.0911375	, lat:63.0152905 },
        { lng:27.0913413	, lat:63.0153343 },
        { lng:27.0914915	, lat:63.01531 },
        { lng:27.0919528	, lat:63.0153879 },
        { lng:27.0925859	, lat:63.015529 },
        { lng:27.0927896	, lat:63.0155339 },
        { lng:27.093133	, lat:63.0154755 },
        { lng:27.0935622	, lat:63.015383 },
        { lng:27.094002	, lat:63.0153732 },
        { lng:27.0943234	, lat:63.0153416 },
        { lng:27.0948067	, lat:63.01531 },
        { lng:27.0950749	, lat:63.0152077 },
        { lng:27.0954182	, lat:63.0151055 },
        { lng:27.0957616	, lat:63.0149692 },
        { lng:27.0960727	, lat:63.0148377 },
        { lng:27.0962444	, lat:63.0147891 },
        { lng:27.0966414	, lat:63.0147452 },
        { lng:27.0970705	, lat:63.0146868 },
        { lng:27.0973279	, lat:63.0146479 },
        { lng:27.0975426	, lat:63.0145456 },
        { lng:27.0978537	, lat:63.0144677 },
        { lng:27.0981863	, lat:63.0144288 },
        { lng:27.0985082	, lat:63.0144288 },
        { lng:27.098787	, lat:63.014385 },
        { lng:27.098926	, lat:63.0142852 },
        { lng:27.0989695	, lat:63.014161 },
        { lng:27.0991305	, lat:63.0140831 },
        { lng:27.0994415	, lat:63.0139468 },
        { lng:27.0998599	, lat:63.0136498 },
        { lng:27.1004929	, lat:63.0134259 },
        { lng:27.1007398	, lat:63.0132847 },
        { lng:27.1007934	, lat:63.0131484 },
        { lng:27.10075	, lat:63.0129561 },
        { lng:27.1009216	, lat:63.012849 },
        { lng:27.1013292	, lat:63.0127272 },
        { lng:27.1017369	, lat:63.0125422 },
        { lng:27.1020374	, lat:63.0124449 },
        { lng:27.1023271	, lat:63.0124108 },
        { lng:27.1026061	, lat:63.0123718 },
        { lng:27.1028421	, lat:63.012255 },
        { lng:27.1031962	, lat:63.0121284 },
        { lng:27.1035395	, lat:63.0120554 },
        { lng:27.103915	, lat:63.0119531 },
        { lng:27.1041296	, lat:63.0119336 },
        { lng:27.1043656	, lat:63.0119093 },
        { lng:27.1045908	, lat:63.011846 },
        { lng:27.1047088	, lat:63.0117681 },
        { lng:27.1047089	, lat:63.0116074 },
        { lng:27.1048484	, lat:63.0114906 },
        { lng:27.1050414	, lat:63.0114175 },
        { lng:27.1057496	, lat:63.0113591 },
        { lng:27.1062968	, lat:63.0112228 },
        { lng:27.1065756	, lat:63.011179 },
        { lng:27.1078417	, lat:63.0109112 },
        { lng:27.1086249	, lat:63.0107359 },
        { lng:27.1088931	, lat:63.0106385 },
        { lng:27.1090434	, lat:63.0105168 },
        { lng:27.1092043	, lat:63.0104243 },
        { lng:27.1095583	, lat:63.0103318 },
        { lng:27.1097515	, lat:63.0102344 },
        { lng:27.1098602	, lat:63.0101814 },
        { lng:27.1099643	, lat:63.0101307 },
        { lng:27.1100653	, lat:63.0100881 },
        { lng:27.110245	, lat:63.010025 },
        { lng:27.110556	, lat:63.0099909 },
        { lng:27.1109638	, lat:63.0099374 },
        { lng:27.1113715	, lat:63.009913 },
        { lng:27.1116827	, lat:63.0098156 },
        { lng:27.1118436	, lat:63.0097231 },
        { lng:27.1120581	, lat:63.0096598 },
        { lng:27.1122727	, lat:63.0096014 },
        { lng:27.1124443	, lat:63.0094748 },
        { lng:27.1126696	, lat:63.0094115 },
        { lng:27.112852	, lat:63.0093969 },
        { lng:27.1129915	, lat:63.0093336 },
        { lng:27.1130989	, lat:63.0092021 },
        { lng:27.1132812	, lat:63.0089879 },
        { lng:27.1132704	, lat:63.0088174 },
        { lng:27.1135708	, lat:63.0086275 },
        { lng:27.1137104	, lat:63.0086081 },
        { lng:27.1138069	, lat:63.0085691 },
        { lng:27.1137854	, lat:63.0083889 },
        { lng:27.1138284	, lat:63.0082477 },
        { lng:27.1144614	, lat:63.0079556 },
        { lng:27.1146975	, lat:63.0078679 },
        { lng:27.1149442	, lat:63.007863 },
        { lng:27.1150408	, lat:63.0077559 },
        { lng:27.1152125	, lat:63.007605 },
        { lng:27.1152768	, lat:63.007454 },
        { lng:27.1154806	, lat:63.0073907 },
        { lng:27.1156523	, lat:63.0073858 },
        { lng:27.1158776	, lat:63.0073566 },
        { lng:27.1160171	, lat:63.0073566 },
        { lng:27.1162316	, lat:63.0073371 },
        { lng:27.1165643	, lat:63.0073031 },
        { lng:27.1168218	, lat:63.00723 },
        { lng:27.1170255	, lat:63.0072154 },
        { lng:27.1173368	, lat:63.007118 },
        { lng:27.1173367	, lat:63.0070011 },
        { lng:27.1174762	, lat:63.0069135 },
        { lng:27.117568	, lat:63.0066425 },
        { lng:27.1176782	, lat:63.006461 },
        { lng:27.1179786	, lat:63.0064512 },
        { lng:27.1181395	, lat:63.0065097 },
        { lng:27.1186545	, lat:63.0065632 },
        { lng:27.1189227	, lat:63.0065535 },
        { lng:27.1192016	, lat:63.0065145 },
        { lng:27.119545	, lat:63.0064561 },
        { lng:27.1198132	, lat:63.0064366 },
        { lng:27.1199634	, lat:63.0064951 },
        { lng:27.1201672	, lat:63.0065097 },
        { lng:27.1203496	, lat:63.0065097 },
        { lng:27.1205428	, lat:63.0065194 },
        { lng:27.1206822	, lat:63.0064951 },
        { lng:27.1207251	, lat:63.0062272 },
        { lng:27.1210256	, lat:63.0061834 },
        { lng:27.1217015	, lat:63.006125 },
        { lng:27.1221843	, lat:63.0060081 },
        { lng:27.1224418	, lat:63.0060081 },
        { lng:27.1226885	, lat:63.0059691 },
        { lng:27.1228065	, lat:63.0060081 },
        { lng:27.122946	, lat:63.0060032 },
        { lng:27.1230104	, lat:63.0059497 },
        { lng:27.1230318	, lat:63.0057549 },
        { lng:27.123461	, lat:63.0056429 },
        { lng:27.1241047	, lat:63.005638 },
        { lng:27.1246911	, lat:63.0056012 },
        { lng:27.1250548	, lat:63.0055501 },
        { lng:27.1255601	, lat:63.0055036 },
        { lng:27.1257677	, lat:63.0054724 },
        { lng:27.125993	, lat:63.0054578 },
        { lng:27.1268299	, lat:63.0053409 },
        { lng:27.1271088	, lat:63.0052727 },
        { lng:27.1274865	, lat:63.0050706 },
        { lng:27.1278834	, lat:63.0049976 },
        { lng:27.1282246	, lat:63.0050293 },
        { lng:27.1286859	, lat:63.0050244 },
        { lng:27.1290078	, lat:63.0049903 },
        { lng:27.1296086	, lat:63.0049465 },
        { lng:27.1301236	, lat:63.0049172 },
        { lng:27.1304347	, lat:63.0049611 },
        { lng:27.1310248	, lat:63.0049611 },
        { lng:27.1314454	, lat:63.0047979 },
        { lng:27.1319067	, lat:63.00472 },
        { lng:27.1320891	, lat:63.0045252 },
        { lng:27.1327436	, lat:63.0042671 },
        { lng:27.1330225	, lat:63.0040966 },
        { lng:27.1333122	, lat:63.0037996 },
        { lng:27.1336448	, lat:63.0035366 },
        { lng:27.1341598	, lat:63.0032492 },
        { lng:27.134428	, lat:63.0030788 },
        { lng:27.1346211	, lat:63.0028645 },
        { lng:27.1352112	, lat:63.0027719 },
        { lng:27.1356404	, lat:63.0026843 },
        { lng:27.1358657	, lat:63.0025138 },
        { lng:27.136091	, lat:63.0024261 },
        { lng:27.1363914	, lat:63.0023969 },
        { lng:27.1366167	, lat:63.0022606 },
        { lng:27.1366274	, lat:63.0020901 },
        { lng:27.1366811	, lat:63.0019342 },
        { lng:27.1370759	, lat:63.0019367 },
        { lng:27.1371424	, lat:63.001793 },
        { lng:27.1373248	, lat:63.0017199 },
        { lng:27.1377432	, lat:63.0016712 },
        { lng:27.1380758	, lat:63.0013449 },
        { lng:27.1382475	, lat:63.001267 },
        { lng:27.1385801	, lat:63.0011939 },
        { lng:27.1389127	, lat:63.0011549 },
        { lng:27.1392989	, lat:63.0011111 },
        { lng:27.1398461	, lat:63.0009406 },
        { lng:27.1409726	, lat:63.0007215 },
        { lng:27.1412623	, lat:63.00059 },
        { lng:27.1412516	, lat:63.0004682 },
        { lng:27.1414554	, lat:63.0004438 },
        { lng:27.1416807	, lat:63.0003756 },
        { lng:27.1418631	, lat:63.0002636 },
        { lng:27.1420777	, lat:63.0001467 },
        { lng:27.1422601	, lat:63.0000152 },
        { lng:27.1423008	, lat:62.9998666 },
        { lng:27.1429725	, lat:62.999703 },
        { lng:27.1435346	, lat:62.9996767 },
        { lng:27.1439295	, lat:62.9996604 },
        { lng:27.1440711	, lat:62.9995354 },
        { lng:27.1443179	, lat:62.9993114 },
        { lng:27.1445861	, lat:62.9992383 },
        { lng:27.1447899	, lat:62.9992237 },
        { lng:27.1447685	, lat:62.9991068 },
        { lng:27.1445753	, lat:62.9989558 },
        { lng:27.1444112	, lat:62.9987916 },
        { lng:27.1444208	, lat:62.998534 },
        { lng:27.1447915	, lat:62.998155 },
        { lng:27.1450367	, lat:62.997967 },
        { lng:27.1456053	, lat:62.9978257 },
        { lng:27.1458843	, lat:62.9977137 },
        { lng:27.1461739	, lat:62.9975821 },
        { lng:27.1466567	, lat:62.9969635 },
        { lng:27.1471074	, lat:62.9965738 },
        { lng:27.1472683	, lat:62.9965202 },
        { lng:27.147515	, lat:62.9963838 },
        { lng:27.1479657	, lat:62.9961549 },
        { lng:27.1480944	, lat:62.9960038 },
        { lng:27.1483197	, lat:62.9958626 },
        { lng:27.1488669	, lat:62.9955362 },
        { lng:27.1493497	, lat:62.9952147 },
        { lng:27.1496823	, lat:62.995127 },
        { lng:27.1503475	, lat:62.9949126 },
        { lng:27.1508303	, lat:62.9947518 },
        { lng:27.1511521	, lat:62.9945229 },
        { lng:27.1513882	, lat:62.9944595 },
        { lng:27.1516456	, lat:62.9944303 },
        { lng:27.1520748	, lat:62.9943183 },
        { lng:27.152504	, lat:62.9942549 },
        { lng:27.1528258	, lat:62.9941672 },
        { lng:27.1532121	, lat:62.9940113 },
        { lng:27.1532764	, lat:62.9938895 },
        { lng:27.1533837	, lat:62.9938019 },
        { lng:27.1539416	, lat:62.9935583 },
        { lng:27.1544888	, lat:62.9934852 },
        { lng:27.1546712	, lat:62.993529 },
        { lng:27.1550574	, lat:62.9935826 },
        { lng:27.1552291	, lat:62.9935826 },
        { lng:27.1555939	, lat:62.9936362 },
        { lng:27.1557333	, lat:62.9937239 },
        { lng:27.156141	, lat:62.9938944 },
        { lng:27.1563771	, lat:62.9939626 },
        { lng:27.1565916	, lat:62.9940406 },
        { lng:27.1568277	, lat:62.9940552 },
        { lng:27.1571066	, lat:62.9940747 },
        { lng:27.1573319	, lat:62.994026 },
        { lng:27.1575894	, lat:62.9940649 },
        { lng:27.1577182	, lat:62.9940698 },
        { lng:27.1580508	, lat:62.9940406 },
        { lng:27.158319	, lat:62.9940113 },
        { lng:27.1585228	, lat:62.9940406 },
        { lng:27.1587052	, lat:62.9939772 },
        { lng:27.1589413	, lat:62.993948 },
        { lng:27.1592524	, lat:62.9937385 },
        { lng:27.1594455	, lat:62.9936508 },
        { lng:27.1595099	, lat:62.9935972 },
        { lng:27.1595957	, lat:62.9935388 },
        { lng:27.1597566	, lat:62.9935193 },
        { lng:27.159939	, lat:62.9935583 },
        { lng:27.1601536	, lat:62.9935485 },
        { lng:27.1602716	, lat:62.9935193 },
        { lng:27.160336	, lat:62.993378 },
        { lng:27.1604969	, lat:62.9932903 },
        { lng:27.160733	, lat:62.9932611 },
        { lng:27.1608939	, lat:62.9932124 },
        { lng:27.1609475	, lat:62.9932708 },
        { lng:27.1610656	, lat:62.9932806 },
        { lng:27.1611299	, lat:62.9931977 },
        { lng:27.1611514	, lat:62.9931149 },
        { lng:27.1613552	, lat:62.9930954 },
        { lng:27.1614947	, lat:62.993149 },
        { lng:27.1615376	, lat:62.9930565 },
        { lng:27.1617093	, lat:62.9929639 },
        { lng:27.1617308	, lat:62.9928957 },
        { lng:27.1618702	, lat:62.9928518 },
        { lng:27.1621706	, lat:62.9928421 },
        { lng:27.1624067	, lat:62.9927982 },
        { lng:27.1625998	, lat:62.9925498 },
        { lng:27.1627071	, lat:62.9924913 },
        { lng:27.16275	, lat:62.9923939 },
        { lng:27.1629217	, lat:62.9923354 },
        { lng:27.1630611	, lat:62.9923744 },
        { lng:27.1631684	, lat:62.9923744 },
        { lng:27.1632435	, lat:62.9923062 },
        { lng:27.1632435	, lat:62.9921844 },
        { lng:27.1634796	, lat:62.9921308 },
        { lng:27.1638014	, lat:62.9921551 },
        { lng:27.1638873	, lat:62.9920479 },
        { lng:27.1640267	, lat:62.9920187 },
        { lng:27.1640804	, lat:62.991931 },
        { lng:27.1642198	, lat:62.9918823 },
        { lng:27.1644773	, lat:62.9918823 },
        { lng:27.1646061	, lat:62.9918043 },
        { lng:27.1648743	, lat:62.9917654 },
        { lng:27.1650595	, lat:62.9916916 },
        { lng:27.1652511	, lat:62.9917737 },
        { lng:27.1669462	, lat:62.9928623 },
        { lng:27.1797767	, lat:63.0009918 },
        { lng:27.1888103	, lat:63.0009431 },
        { lng:27.2023072	, lat:63.0008846 },
        { lng:27.2050624	, lat:63.005591 },
        { lng:27.2056332	, lat:63.0061396 },
        { lng:27.2058402	, lat:63.0062667 },
        { lng:27.2060838	, lat:63.0063782 },
        { lng:27.2062983	, lat:63.0065779 },
        { lng:27.2065451	, lat:63.0066704 },
        { lng:27.2067168	, lat:63.0068359 },
        { lng:27.2067811	, lat:63.0069187 },
        { lng:27.2067168	, lat:63.007021 },
        { lng:27.2065773	, lat:63.007133 },
        { lng:27.2065773	, lat:63.007245 },
        { lng:27.2066953	, lat:63.0073278 },
        { lng:27.2069313	, lat:63.007469 },
        { lng:27.2071137	, lat:63.0075469 },
        { lng:27.2071459	, lat:63.007654 },
        { lng:27.2074034	, lat:63.0077855 },
        { lng:27.2076502	, lat:63.0078439 },
        { lng:27.2078218	, lat:63.0078926 },
        { lng:27.2079828	, lat:63.0078878 },
        { lng:27.2080793	, lat:63.0079316 },
        { lng:27.2081973	, lat:63.0080046 },
        { lng:27.2083475	, lat:63.0080046 },
        { lng:27.2085514	, lat:63.0080095 },
        { lng:27.2088942	, lat:63.0084694 },
        { lng:27.2090878	, lat:63.0085987 },
        { lng:27.2092488	, lat:63.0089688 },
        { lng:27.2096264	, lat:63.0091562 },
        { lng:27.2097337	, lat:63.0092731 },
        { lng:27.209841	, lat:63.0095068 },
        { lng:27.2098925	, lat:63.0097381 },
        { lng:27.209914	, lat:63.0099601 },
        { lng:27.2099354	, lat:63.0102494 },
        { lng:27.2089934	, lat:63.0117174 },
        { lng:27.2088175	, lat:63.0119963 },
        { lng:27.2087574	, lat:63.0121361 },
        { lng:27.2086716	, lat:63.0124915 },
        { lng:27.2087574	, lat:63.0127398 },
        { lng:27.2087788	, lat:63.0129151 },
        { lng:27.2087145	, lat:63.0130368 },
        { lng:27.2086608	, lat:63.013436 },
        { lng:27.2082724	, lat:63.013677 },
        { lng:27.2081351	, lat:63.0138888 },
        { lng:27.2080064	, lat:63.0140154 },
        { lng:27.2080064	, lat:63.0140738 },
        { lng:27.2081458	, lat:63.0141906 },
        { lng:27.2083068	, lat:63.0142831 },
        { lng:27.2085428	, lat:63.0143367 },
        { lng:27.2089612	, lat:63.0143415 },
        { lng:27.2093046	, lat:63.0143075 },
        { lng:27.2097981	, lat:63.014288 },
        { lng:27.2100019	, lat:63.0142442 },
        { lng:27.2101629	, lat:63.014288 },
        { lng:27.2106242	, lat:63.0143026 },
        { lng:27.2108495	, lat:63.0143562 },
        { lng:27.2113945	, lat:63.0143683 },
        { lng:27.2116864	, lat:63.0143221 },
        { lng:27.2120404	, lat:63.0142296 },
        { lng:27.2123065	, lat:63.0141492 },
        { lng:27.2128558	, lat:63.0140738 },
        { lng:27.2131348	, lat:63.0139813 },
        { lng:27.213521	, lat:63.0139034 },
        { lng:27.2139287	, lat:63.0138304 },
        { lng:27.2142173	, lat:63.0138158 },
        { lng:27.2144973	, lat:63.0138644 },
        { lng:27.2146443	, lat:63.0138267 },
        { lng:27.2147763	, lat:63.0137281 },
        { lng:27.2150767	, lat:63.0136502 },
        { lng:27.2158384	, lat:63.0136356 },
        { lng:27.215913	, lat:63.0135772 },
        { lng:27.2159988	, lat:63.0134944 },
        { lng:27.216428	, lat:63.0134506 },
        { lng:27.2167391	, lat:63.0133581 },
        { lng:27.2171253	, lat:63.0133484 },
        { lng:27.2179515	, lat:63.013548 },
        { lng:27.2184235	, lat:63.0135529 },
        { lng:27.2187239	, lat:63.0136161 },
        { lng:27.2190565	, lat:63.0136502 },
        { lng:27.2194213	, lat:63.0137038 },
        { lng:27.2197968	, lat:63.0138693 },
        { lng:27.219947	, lat:63.013991 },
        { lng:27.2203762	, lat:63.0144194 },
        { lng:27.2204942	, lat:63.0145898 },
        { lng:27.2205264	, lat:63.0147115 },
        { lng:27.2205049	, lat:63.0148576 },
        { lng:27.2204084	, lat:63.0150523 },
        { lng:27.220741	, lat:63.0155489 },
        { lng:27.2209985	, lat:63.0156706 },
        { lng:27.2213096	, lat:63.0159383 },
        { lng:27.2217709	, lat:63.0160016 },
        { lng:27.2220284	, lat:63.0161039 },
        { lng:27.222361	, lat:63.0161574 },
        { lng:27.2227794	, lat:63.016172 },
        { lng:27.2231013	, lat:63.0162353 },
        { lng:27.2235626	, lat:63.0162596 },
        { lng:27.2237971	, lat:63.016301 },
        { lng:27.2240653	, lat:63.0162621 },
        { lng:27.2242477	, lat:63.0162961 },
        { lng:27.2246125	, lat:63.0165736 },
        { lng:27.2248056	, lat:63.0165882 },
        { lng:27.2249236	, lat:63.0166077 },
        { lng:27.2251596	, lat:63.016817 },
        { lng:27.2253849	, lat:63.01689 },
        { lng:27.2254493	, lat:63.0170409 },
        { lng:27.2255781	, lat:63.0170896 },
        { lng:27.225739	, lat:63.0170945 },
        { lng:27.2261467	, lat:63.0170799 },
        { lng:27.2269513	, lat:63.0173525 },
        { lng:27.227123	, lat:63.017372 },
        { lng:27.227241	, lat:63.0174937 },
        { lng:27.2275522	, lat:63.0174937 },
        { lng:27.2279384	, lat:63.0176397 },
        { lng:27.2279599	, lat:63.0177078 },
        { lng:27.2279062	, lat:63.0177614 },
        { lng:27.2278848	, lat:63.0178344 },
        { lng:27.2279599	, lat:63.0178977 },
        { lng:27.2281208	, lat:63.0179026 },
        { lng:27.2282774	, lat:63.0179371 },
        { lng:27.2283354	, lat:63.0180437 },
        { lng:27.2282388	, lat:63.0181995 },
        { lng:27.228153	, lat:63.0182579 },
        { lng:27.2280564	, lat:63.018365 },
        { lng:27.2276487	, lat:63.0184624 },
        { lng:27.2274449	, lat:63.0185451 },
        { lng:27.2255459	, lat:63.0194359 },
        { lng:27.2244317	, lat:63.0196379 },
        { lng:27.224142	, lat:63.0197109 },
        { lng:27.2238845	, lat:63.0198374 },
        { lng:27.2233572	, lat:63.020166 },
        { lng:27.2229066	, lat:63.0204337 },
        { lng:27.2230031	, lat:63.0206722 },
        { lng:27.2229602	, lat:63.0208669 },
        { lng:27.2227955	, lat:63.0210769 },
        { lng:27.2226271	, lat:63.0212835 },
        { lng:27.2224109	, lat:63.0213909 },
        { lng:27.2220392	, lat:63.0217454 },
        { lng:27.2219392	, lat:63.0218565 },
        { lng:27.2216173	, lat:63.0227666 },
        { lng:27.2269533	, lat:63.0240953 },
        { lng:27.2273363	, lat:63.024324 },
        { lng:27.2280224	, lat:63.0247474 },
        { lng:27.2283443	, lat:63.0249762 },
        { lng:27.2290738	, lat:63.0251854 },
        { lng:27.2293421	, lat:63.0252438 },
        { lng:27.2296317	, lat:63.0253071 },
        { lng:27.2298141	, lat:63.0253947 },
        { lng:27.230018	, lat:63.0256283 },
        { lng:27.230254	, lat:63.0257499 },
        { lng:27.2305544	, lat:63.0258035 },
        { lng:27.2310125	, lat:63.0258283 },
        { lng:27.2313698	, lat:63.0258181 },
        { lng:27.2314449	, lat:63.0257402 },
        { lng:27.2315307	, lat:63.0256088 },
        { lng:27.2317239	, lat:63.0255747 },
        { lng:27.2319642	, lat:63.0256144 },
        { lng:27.232168	, lat:63.0256144 },
        { lng:27.2323397	, lat:63.0255755 },
        { lng:27.2326186	, lat:63.0254392 },
        { lng:27.2328225	, lat:63.0253759 },
        { lng:27.2332946	, lat:63.0253565 },
        { lng:27.2336486	, lat:63.0253224 },
        { lng:27.2340027	, lat:63.0253224 },
        { lng:27.2342387	, lat:63.0253419 },
        { lng:27.2343889	, lat:63.0253127 },
        { lng:27.2346035	, lat:63.0253175 },
        { lng:27.2349683	, lat:63.0253808 },
        { lng:27.2351506	, lat:63.0254149 },
        { lng:27.2355798	, lat:63.0253905 },
        { lng:27.235848	, lat:63.0253905 },
        { lng:27.2361055	, lat:63.0254295 },
        { lng:27.2364982	, lat:63.0254628 },
        { lng:27.2366913	, lat:63.0254725 },
        { lng:27.2369059	, lat:63.0254287 },
        { lng:27.2371634	, lat:63.0253947 },
        { lng:27.237496	, lat:63.0252487 },
        { lng:27.2377964	, lat:63.0250492 },
        { lng:27.238129	, lat:63.0250248 },
        { lng:27.2384401	, lat:63.0249762 },
        { lng:27.2386869	, lat:63.0249762 },
        { lng:27.2388585	, lat:63.0249956 },
        { lng:27.2390302	, lat:63.0250102 },
        { lng:27.2391589	, lat:63.0250248 },
        { lng:27.2396417	, lat:63.0250248 },
        { lng:27.2398134	, lat:63.0250443 },
        { lng:27.2400816	, lat:63.0250686 },
        { lng:27.2404571	, lat:63.025093 },
        { lng:27.2406932	, lat:63.0251076 },
        { lng:27.2414871	, lat:63.0251027 },
        { lng:27.2416373	, lat:63.0251124 },
        { lng:27.2415515	, lat:63.0251952 },
        { lng:27.2415837	, lat:63.0252633 },
        { lng:27.2417231	, lat:63.0252827 },
        { lng:27.2417982	, lat:63.0253947 },
        { lng:27.2418948	, lat:63.0255017 },
        { lng:27.2418948	, lat:63.0255699 },
        { lng:27.2418197	, lat:63.0256429 },
        { lng:27.2416695	, lat:63.0257402 },
        { lng:27.2416051	, lat:63.0259057 },
        { lng:27.2416158	, lat:63.026402 },
        { lng:27.2416266	, lat:63.0265383 },
        { lng:27.2416373	, lat:63.0266843 },
        { lng:27.2414978	, lat:63.0268449 },
        { lng:27.2413905	, lat:63.0269665 },
        { lng:27.2413584	, lat:63.0271174 },
        { lng:27.2412403	, lat:63.0273169 },
        { lng:27.2412081	, lat:63.0274775 },
        { lng:27.2426673	, lat:63.0281344 },
        { lng:27.2440878	, lat:63.0287969 },
        { lng:27.2469309	, lat:63.0275415 },
        { lng:27.248088	, lat:63.0261254 },
        { lng:27.2504779	, lat:63.0259203 },
        { lng:27.2506281	, lat:63.0259105 },
        { lng:27.2508319	, lat:63.0258619 },
        { lng:27.2516688	, lat:63.0258813 },
        { lng:27.2520014	, lat:63.0259057 },
        { lng:27.2528919	, lat:63.0259008 },
        { lng:27.2532459	, lat:63.0258327 },
        { lng:27.2540935	, lat:63.0256769 },
        { lng:27.2544261	, lat:63.025638 },
        { lng:27.254705	, lat:63.0255942 },
        { lng:27.2549625	, lat:63.0255601 },
        { lng:27.2557243	, lat:63.025565 },
        { lng:27.2559388	, lat:63.0255115 },
        { lng:27.2562607	, lat:63.0254482 },
        { lng:27.2565289	, lat:63.0253314 },
        { lng:27.2568508	, lat:63.0251611 },
        { lng:27.2572907	, lat:63.0250492 },
        { lng:27.2577091	, lat:63.0249178 },
        { lng:27.2580202	, lat:63.0248302 },
        { lng:27.2583421	, lat:63.0248058 },
        { lng:27.2585781	, lat:63.0247815 },
        { lng:27.2588785	, lat:63.0247134 },
        { lng:27.2590717	, lat:63.0247085 },
        { lng:27.2593077	, lat:63.0247523 },
        { lng:27.2594579	, lat:63.0248302 },
        { lng:27.2596832	, lat:63.0249859 },
        { lng:27.2598012	, lat:63.0250346 },
        { lng:27.2601338	, lat:63.0250346 },
        { lng:27.2603162	, lat:63.0250005 },
        { lng:27.2607775	, lat:63.0249324 },
        { lng:27.2612174	, lat:63.0248642 },
        { lng:27.2616679	, lat:63.0248594 },
        { lng:27.2618182	, lat:63.0248253 },
        { lng:27.2621508	, lat:63.0246842 },
        { lng:27.2624405	, lat:63.0245917 },
        { lng:27.2629019	, lat:63.0244408 },
        { lng:27.2632666	, lat:63.024324 },
        { lng:27.2636529	, lat:63.0242656 },
        { lng:27.2642537	, lat:63.0241488 },
        { lng:27.2646292	, lat:63.0240369 },
        { lng:27.26523	, lat:63.0238373 },
        { lng:27.2654875	, lat:63.0237692 },
        { lng:27.2656914	, lat:63.0237449 },
        { lng:27.2660561	, lat:63.0236524 },
        { lng:27.2665008	, lat:63.0236196 },
        { lng:27.2668426	, lat:63.0236339 },
        { lng:27.2730084	, lat:63.0247766 },
        { lng:27.2748162	, lat:63.0227204 },
        { lng:27.2930134	, lat:63.0211176 },
        { lng:27.2945305	, lat:63.0214087 },
        { lng:27.2930606	, lat:63.0241634 },
        { lng:27.2934646	, lat:63.0258251 },
        { lng:27.2932687	, lat:63.0270497 },
        { lng:27.2932323	, lat:63.0273461 },
        { lng:27.2932752	, lat:63.0275213 },
        { lng:27.2934469	, lat:63.0276965 },
        { lng:27.2935327	, lat:63.0277841 },
        { lng:27.2937473	, lat:63.0278571 },
        { lng:27.2939511	, lat:63.0279154 },
        { lng:27.2940477	, lat:63.028003 },
        { lng:27.2940691	, lat:63.0281636 },
        { lng:27.2941764	, lat:63.0282755 },
        { lng:27.2941871	, lat:63.0284556 },
        { lng:27.2942944	, lat:63.028514 },
        { lng:27.2944875	, lat:63.0285432 },
        { lng:27.2946163	, lat:63.0286162 },
        { lng:27.2947987	, lat:63.0286356 },
        { lng:27.2950776	, lat:63.0286405 },
        { lng:27.29526	, lat:63.0286794 },
        { lng:27.2954746	, lat:63.028694 },
        { lng:27.2960305	, lat:63.02867 },
        { lng:27.296366	, lat:63.0287069 },
        { lng:27.2968693	, lat:63.0287621 },
        { lng:27.2972127	, lat:63.0288546 },
        { lng:27.2975775	, lat:63.0290152 },
        { lng:27.2976526	, lat:63.0291125 },
        { lng:27.297792	, lat:63.0291611 },
        { lng:27.2978349	, lat:63.0292439 },
        { lng:27.297792	, lat:63.0294142 },
        { lng:27.2979208	, lat:63.029531 },
        { lng:27.2981461	, lat:63.0296623 },
        { lng:27.2983392	, lat:63.0297645 },
        { lng:27.2984465	, lat:63.0298716 },
        { lng:27.2986396	, lat:63.029964 },
        { lng:27.2988864	, lat:63.0300662 },
        { lng:27.2990688	, lat:63.0302073 },
        { lng:27.2991868	, lat:63.030256 },
        { lng:27.2995301	, lat:63.0302803 },
        { lng:27.2997876	, lat:63.0303387 },
        { lng:27.2999807	, lat:63.030436 },
        { lng:27.3001524	, lat:63.030616 },
        { lng:27.3002167	, lat:63.0308107 },
        { lng:27.300324	, lat:63.0308982 },
        { lng:27.3004313	, lat:63.0310053 },
        { lng:27.3005601	, lat:63.0310929 },
        { lng:27.3007532	, lat:63.0311269 },
        { lng:27.3007425	, lat:63.031234 },
        { lng:27.3005815	, lat:63.0312923 },
        { lng:27.300324	, lat:63.0313215 },
        { lng:27.3002382	, lat:63.0313702 },
        { lng:27.3001953	, lat:63.031448 },
        { lng:27.3002275	, lat:63.0315259 },
        { lng:27.3003884	, lat:63.0316135 },
        { lng:27.3004313	, lat:63.0317935 },
        { lng:27.3005493	, lat:63.0318519 },
        { lng:27.3007425	, lat:63.0318762 },
        { lng:27.3008497	, lat:63.0319443 },
        { lng:27.3010214	, lat:63.0320708 },
        { lng:27.3012574	, lat:63.0321292 },
        { lng:27.3017188	, lat:63.0321827 },
        { lng:27.3021157	, lat:63.0322265 },
        { lng:27.3022338	, lat:63.0322995 },
        { lng:27.3021265	, lat:63.0323628 },
        { lng:27.3020943	, lat:63.0324747 },
        { lng:27.3020514	, lat:63.0326498 },
        { lng:27.3020406	, lat:63.0328882 },
        { lng:27.3020299	, lat:63.0331023 },
        { lng:27.3021801	, lat:63.0332336 },
        { lng:27.3024162	, lat:63.0332823 },
        { lng:27.3026093	, lat:63.0333407 },
        { lng:27.3029633	, lat:63.0333893 },
        { lng:27.3032101	, lat:63.0334574 },
        { lng:27.3033603	, lat:63.0335645 },
        { lng:27.303371	, lat:63.0336764 },
        { lng:27.3032208	, lat:63.0338175 },
        { lng:27.3030062	, lat:63.0339148 },
        { lng:27.3026093	, lat:63.0339926 },
        { lng:27.3022981	, lat:63.0340267 },
        { lng:27.3019693	, lat:63.0341442 },
        { lng:27.3012574	, lat:63.0341337 },
        { lng:27.301	, lat:63.0341629 },
        { lng:27.3002811	, lat:63.0341921 },
        { lng:27.2994228	, lat:63.0342797 },
        { lng:27.2987684	, lat:63.0343088 },
        { lng:27.2984143	, lat:63.0343429 },
        { lng:27.298189	, lat:63.034411 },
        { lng:27.2978457	, lat:63.0345326 },
        { lng:27.2976204	, lat:63.0346397 },
        { lng:27.2974916	, lat:63.034737 },
        { lng:27.2975345	, lat:63.0348343 },
        { lng:27.2974916	, lat:63.0349072 },
        { lng:27.2972449	, lat:63.0349851 },
        { lng:27.2971483	, lat:63.035097 },
        { lng:27.2970732	, lat:63.0351797 },
        { lng:27.2969659	, lat:63.0352575 },
        { lng:27.296805	, lat:63.0353791 },
        { lng:27.2967942	, lat:63.0355251 },
        { lng:27.2969337	, lat:63.0356175 },
        { lng:27.2972449	, lat:63.0357391 },
        { lng:27.2975024	, lat:63.035817 },
        { lng:27.2976526	, lat:63.0359143 },
        { lng:27.2976847	, lat:63.036031 },
        { lng:27.2976204	, lat:63.0361429 },
        { lng:27.2973629	, lat:63.0362305 },
        { lng:27.29732	, lat:63.0364153 },
        { lng:27.2971805	, lat:63.0365467 },
        { lng:27.2969445	, lat:63.0366294 },
        { lng:27.2967835	, lat:63.036751 },
        { lng:27.2965475	, lat:63.0368288 },
        { lng:27.2964295	, lat:63.0368823 },
        { lng:27.2963973	, lat:63.036965 },
        { lng:27.2962041	, lat:63.0370429 },
        { lng:27.2956355	, lat:63.0371499 },
        { lng:27.2953534	, lat:63.0371715 },
        { lng:27.2950884	, lat:63.0372229 },
        { lng:27.2946592	, lat:63.0372229 },
        { lng:27.2944232	, lat:63.037291 },
        { lng:27.2942301	, lat:63.0373299 },
        { lng:27.2942086	, lat:63.0374272 },
        { lng:27.2942515	, lat:63.0375634 },
        { lng:27.2943604	, lat:63.0377049 },
        { lng:27.294222	, lat:63.037875 },
        { lng:27.2941898	, lat:63.0379584 },
        { lng:27.2943803	, lat:63.0381082 },
        { lng:27.2946378	, lat:63.0383222 },
        { lng:27.2946265	, lat:63.0385095 },
        { lng:27.2952171	, lat:63.038653 },
        { lng:27.2951678	, lat:63.0391258 },
        { lng:27.2950819	, lat:63.0423069 },
        { lng:27.2961334	, lat:63.0517118 },
        { lng:27.2989228	, lat:63.054065 },
        { lng:27.3047443	, lat:63.0557121 },
        { lng:27.3070328	, lat:63.0553188 },
        { lng:27.3165385	, lat:63.0513617 },
        { lng:27.3282383	, lat:63.0528608 },
        { lng:27.342933	, lat:63.0475397 },
        { lng:27.352472	, lat:63.0490117 },
        { lng:27.3832545	, lat:63.0408786 },
        { lng:27.382688	, lat:63.0309676 },
        { lng:27.3882788	, lat:63.025193 },
        { lng:27.3932386	, lat:63.0136077 },
        { lng:27.3940294	, lat:63.0109128 },
        { lng:27.4069856	, lat:63.0076967 },
        { lng:27.4071208	, lat:63.0046814 },
        { lng:27.4114402	, lat:63.0023641 },
        { lng:27.4139958	, lat:62.9974246 },
        { lng:27.4202625	, lat:62.9935654 },
        { lng:27.4713157	, lat:62.976971 },
        { lng:27.484539	, lat:62.984265 },
        { lng:27.5289767	, lat:62.9662478 },
        { lng:27.5399459	, lat:62.9657938 },
        { lng:27.5207155	, lat:62.9782013 },
        { lng:27.5259061	, lat:62.9820626 },
        { lng:27.5425692	, lat:62.9791062 },
        { lng:27.5426098	, lat:62.9795632 },
        { lng:27.5493733	, lat:62.9793197 },
        { lng:27.5497307	, lat:62.9789049 },
        { lng:27.5581292	, lat:62.9773597 },
        { lng:27.5585562	, lat:62.9698358 },
        { lng:27.5770206	, lat:62.9691639 },
        { lng:27.5799206	, lat:62.9643519 },
        { lng:27.600491	, lat:62.9612833 },
        { lng:27.6045711	, lat:62.9701005 },
        { lng:27.6237543	, lat:62.9691956 },
        { lng:27.6350625	, lat:62.9723979 },
        { lng:27.6562584	, lat:62.9689396 },
        { lng:27.6633779	, lat:62.9714964 },
        { lng:27.6699065	, lat:62.9739024 },
        { lng:27.67614	, lat:62.9795307 },
        { lng:27.6904254	, lat:62.9761488 },
        { lng:27.690522	, lat:62.9737649 },
        { lng:27.7019516	, lat:62.967963 },
        { lng:27.7022006	, lat:62.9678408 },
        { lng:27.7023822	, lat:62.9677545 },
        { lng:27.7063621	, lat:62.9657079 },
        { lng:27.7096215	, lat:62.9641109 },
        { lng:27.7116659	, lat:62.9631355 },
        { lng:27.7116884	, lat:62.9629718 },
        { lng:27.7130081	, lat:62.9634922 },
        { lng:27.7174572	, lat:62.964433 },
        { lng:27.7378915	, lat:62.9689184 },
        { lng:27.7389354	, lat:62.9691749 },
        { lng:27.7411766	, lat:62.9696396 },
        { lng:27.7555125	, lat:62.9725411 },
        { lng:27.7637368	, lat:62.9742519 },
        { lng:27.7661662	, lat:62.9747573 },
        { lng:27.7663018	, lat:62.9747704 },
        { lng:27.7825652	, lat:62.9763532 },
        { lng:27.7870338	, lat:62.9767882 },
        { lng:27.7947092	, lat:62.9712218 },
        { lng:27.7956755	, lat:62.9637262 },
        { lng:27.7957185	, lat:62.963558 },
        { lng:27.7958218	, lat:62.9629672 },
        { lng:27.7958817	, lat:62.9626513 },
        { lng:27.7960823	, lat:62.9615925 },
        { lng:27.7961255	, lat:62.9613646 },
        { lng:27.7919358	, lat:62.9577703 },
        { lng:27.8122434	, lat:62.9524257 },
        { lng:27.8319458	, lat:62.951871 },
        { lng:27.8284589	, lat:62.9670543 },
        { lng:27.8315521	, lat:62.9750264 },
        { lng:27.8240075	, lat:62.9934553 },
        { lng:27.8504606	, lat:63.0003506 },
        { lng:27.9097985	, lat:62.9815747 },
        { lng:27.9491823	, lat:63.0032385 },
        { lng:27.9432396	, lat:63.006556 },
        { lng:27.9613977	, lat:63.0114287 },
        { lng:27.966892	, lat:63.0113118 },
        { lng:27.965891	, lat:63.0160304 },
        { lng:27.9693811	, lat:63.0193308 },
        { lng:27.9614063	, lat:63.0233024 },
        { lng:27.9565998	, lat:63.0294243 },
        { lng:27.9486605	, lat:63.0353309 },
        { lng:27.9522182	, lat:63.0440563 },
        { lng:27.9441329	, lat:63.0507097 },
        { lng:27.9555409	, lat:63.0479905 },
        { lng:27.9584881	, lat:63.0485021 },
        { lng:27.9687642	, lat:63.0457502 },
        { lng:27.9702683	, lat:63.0511862 },
        { lng:27.9783288	, lat:63.0489437 },
        { lng:27.9824133	, lat:63.0473254 },
        { lng:27.9850987	, lat:63.0471435 },
        { lng:27.9877348	, lat:63.0469655 },
        { lng:28.0014248	, lat:63.0411294 },
        { lng:28.0093566	, lat:63.0280172 },
        { lng:28.0169387	, lat:63.0243538 },
        { lng:28.0203719	, lat:63.0285291 },
        { lng:28.0280677	, lat:63.0262264 },
        { lng:28.0355639	, lat:63.0229716 },
        { lng:28.0520574	, lat:63.0195392 },
        { lng:28.0706397	, lat:63.0218171 },
        { lng:28.077828	, lat:63.0131231 },
        { lng:28.0906243	, lat:63.0074326 },
        { lng:28.10877	, lat:63.0050401 },
        { lng:28.1043572	, lat:62.9992311 },
        { lng:28.1157298	, lat:62.9974775 },
        { lng:28.1216661	, lat:62.9953863 },
        { lng:28.1277676	, lat:62.9925475 },
        { lng:28.1197853	, lat:62.9911443 },
        { lng:28.1249351	, lat:62.9860962 },
        { lng:28.1192274	, lat:62.9832599 },
        { lng:28.120164	, lat:62.9807484 },
        { lng:28.1249781	, lat:62.9755879 },
        { lng:28.1243129	, lat:62.969874 },
        { lng:28.1298275	, lat:62.9661486 },
        { lng:28.1441108	, lat:62.9654401 },
        { lng:28.1876559	, lat:62.9536135 },
        { lng:28.207604	, lat:62.9480841 },
        { lng:28.2080117	, lat:62.9480548 },
        { lng:28.2083551	, lat:62.947928 },
        { lng:28.2088701	, lat:62.9478109 },
        { lng:28.2085267	, lat:62.9480061 },
        { lng:28.2081834	, lat:62.9481134 },
        { lng:28.2078615	, lat:62.9483574 },
        { lng:28.2076899	, lat:62.9485428 },
        { lng:28.2073895	, lat:62.9486989 },
        { lng:28.2070751	, lat:62.9488809 },
        { lng:28.2072039	, lat:62.9491737 },
        { lng:28.2072468	, lat:62.9494079 },
        { lng:28.2074184	, lat:62.9496421 },
        { lng:28.2075826	, lat:62.9498309 },
        { lng:28.2079763	, lat:62.9500227 },
        { lng:28.2084624	, lat:62.9501139 },
        { lng:28.2084194	, lat:62.9503188 },
        { lng:28.2081405	, lat:62.9504554 },
        { lng:28.2075182	, lat:62.9505921 },
        { lng:28.2067887	, lat:62.9506116 },
        { lng:28.2064958	, lat:62.9507448 },
        { lng:28.2063456	, lat:62.9510765 },
        { lng:28.2061524	, lat:62.9513205 },
        { lng:28.2059808	, lat:62.9515449 },
        { lng:28.2061739	, lat:62.9518474 },
        { lng:28.2065312	, lat:62.9519777 },
        { lng:28.2065955	, lat:62.9523192 },
        { lng:28.2059733	, lat:62.952407 },
        { lng:28.2056729	, lat:62.9525046 },
        { lng:28.2054368	, lat:62.9526704 },
        { lng:28.2047287	, lat:62.9528558 },
        { lng:28.2043714	, lat:62.9530573 },
        { lng:28.2041923	, lat:62.9533535 },
        { lng:28.2030765	, lat:62.9535193 },
        { lng:28.2024757	, lat:62.9533339 },
        { lng:28.2018963	, lat:62.9529729 },
        { lng:28.2017676	, lat:62.9527192 },
        { lng:28.2016817	, lat:62.9525241 },
        { lng:28.2012118	, lat:62.9524241 },
        { lng:28.2006947	, lat:62.9525534 },
        { lng:28.1999651	, lat:62.9526412 },
        { lng:28.1992141	, lat:62.9527387 },
        { lng:28.1985993	, lat:62.9527451 },
        { lng:28.1981198	, lat:62.9528558 },
        { lng:28.1976552	, lat:62.9530768 },
        { lng:28.1973473	, lat:62.9534022 },
        { lng:28.1970254	, lat:62.9536071 },
        { lng:28.1966639	, lat:62.953573 },
        { lng:28.1960673	, lat:62.9534866 },
        { lng:28.1956167	, lat:62.9535354 },
        { lng:28.1953088	, lat:62.9537145 },
        { lng:28.1950084	, lat:62.9537633 },
        { lng:28.1942005	, lat:62.9537696 },
        { lng:28.1934495	, lat:62.9539355 },
        { lng:28.1928487	, lat:62.9541696 },
        { lng:28.192441	, lat:62.9542379 },
        { lng:28.192162	, lat:62.9543453 },
        { lng:28.1917039	, lat:62.954534 },
        { lng:28.1913606	, lat:62.954494 },
        { lng:28.1909958	, lat:62.9546414 },
        { lng:28.1896869	, lat:62.954817 },
        { lng:28.1893296	, lat:62.9548038 },
        { lng:28.1890507	, lat:62.9548819 },
        { lng:28.1890646	, lat:62.9551877 },
        { lng:28.1888071	, lat:62.9551292 },
        { lng:28.1886215	, lat:62.9552234 },
        { lng:28.188275	, lat:62.9552243 },
        { lng:28.1876237	, lat:62.9553638 },
        { lng:28.1869264	, lat:62.9555746 },
        { lng:28.1936136	, lat:62.9576073 },
        { lng:28.2145059	, lat:62.9522611 },
        { lng:28.2190238	, lat:62.9511131 },
        { lng:28.2450852	, lat:62.9444638 },
        { lng:28.246421	, lat:62.944395 },
        { lng:28.2735435	, lat:62.942238 },
        { lng:28.2870403	, lat:62.9389581 },
        { lng:28.2937566	, lat:62.9378062 },
        { lng:28.3024684	, lat:62.9360587 },
        { lng:28.3050863	, lat:62.9343208 },
        { lng:28.3089272	, lat:62.9317041 },
        { lng:28.3070604	, lat:62.931128 },
        { lng:28.3071033	, lat:62.9299367 },
        { lng:28.3074679	, lat:62.9291826 },
        { lng:28.3074895	, lat:62.9290578 },
        { lng:28.3070389	, lat:62.9285696 },
        { lng:28.3040134	, lat:62.9278176 },
        { lng:28.3025542	, lat:62.9281594 },
        { lng:28.3018461	, lat:62.9281008 },
        { lng:28.3010308	, lat:62.9283059 },
        { lng:28.3000222	, lat:62.9284719 },
        { lng:28.2985422	, lat:62.9283564 },
        { lng:28.2956988	, lat:62.9281484 },
        { lng:28.2952801	, lat:62.9280715 },
        { lng:28.2950084	, lat:62.9279999 },
        { lng:28.2945991	, lat:62.9280626 },
        { lng:28.2928165	, lat:62.9279191 },
        { lng:28.291974	, lat:62.9279289 },
        { lng:28.2910027	, lat:62.927911 },
        { lng:28.2899745	, lat:62.9278803 },
        { lng:28.2896293	, lat:62.9277092 },
        { lng:28.2876379	, lat:62.9259684 },
        { lng:28.2864017	, lat:62.9257123 },
        { lng:28.2860226	, lat:62.9254934 },
        { lng:28.2854833	, lat:62.9253656 },
        { lng:28.2849252	, lat:62.9254744 },
        { lng:28.2825329	, lat:62.9248528 },
        { lng:28.2807188	, lat:62.9238934 },
        { lng:28.2795678	, lat:62.9225845 },
        { lng:28.2717044	, lat:62.9195421 },
        { lng:28.2825709	, lat:62.9147023 },
        { lng:28.3008728	, lat:62.9127632 },
        { lng:28.2993519	, lat:62.8984238 },
        { lng:28.2706062	, lat:62.9027194 },
        { lng:28.2678686	, lat:62.8973151 },
        { lng:28.2999604	, lat:62.8897383 },
        { lng:28.2910374	, lat:62.8818128 },
        { lng:28.2459852	, lat:62.8897977 },
        { lng:28.2459327	, lat:62.8890004 },
        { lng:28.2432389	, lat:62.8856602 },
        { lng:28.2462671	, lat:62.8843601 },
        { lng:28.2490876	, lat:62.8835845 },
        { lng:28.2505302	, lat:62.8827158 },
        { lng:28.2564616	, lat:62.8720358 },
        { lng:28.2497187	, lat:62.8707643 },
        { lng:28.2379061	, lat:62.8733535 },
        { lng:28.2149399	, lat:62.8819514 },
        { lng:28.1880701	, lat:62.8892069 },
        { lng:28.1637351	, lat:62.8847937 },
        { lng:28.1647997	, lat:62.8811195 },
        { lng:28.1666656	, lat:62.87653 },
        { lng:28.1818445	, lat:62.8716477 },
        { lng:28.1871651	, lat:62.869162 },
        { lng:28.1912364	, lat:62.8652562 },
        { lng:28.1972999	, lat:62.8632162 },
        { lng:28.1993538	, lat:62.8606199 },
        { lng:28.2092931	, lat:62.8547869 },
        { lng:28.2070612	, lat:62.8509385 },
        { lng:28.2101078	, lat:62.847893 },
        { lng:28.2060285	, lat:62.8436098 },
        { lng:28.200111	, lat:62.841282 },
        { lng:28.2282228	, lat:62.8256724 },
        { lng:28.2288312	, lat:62.8250935 },
        { lng:28.2578304	, lat:62.8180538 },
        { lng:28.2661449	, lat:62.8103869 },
        { lng:28.3224703	, lat:62.833775 },
        { lng:28.3382373	, lat:62.8065875 },
        { lng:28.3293652	, lat:62.7973881 },
        { lng:28.3046246	, lat:62.7922658 },
        { lng:28.2505299	, lat:62.7689606 },
        { lng:28.2749156	, lat:62.7682183 },
        { lng:28.3167921	, lat:62.7857283 },
        { lng:28.3281991	, lat:62.7797227 },
        { lng:28.330227	, lat:62.7780994 },
        { lng:28.3399103	, lat:62.7644134 },
        { lng:28.333928	, lat:62.763787 },
        { lng:28.3121786	, lat:62.7667335 },
        { lng:28.2924064	, lat:62.7644598 },
        { lng:28.36749	, lat:62.7045847 },
        { lng:28.3618119	, lat:62.6973999 },
        { lng:28.3415834	, lat:62.6747645 },
        { lng:28.3215577	, lat:62.6538352 },
        { lng:28.3162344	, lat:62.6481055 },
        { lng:28.3643913	, lat:62.6332486 },
        { lng:28.3641509	, lat:62.6328232 },
        { lng:28.3646739	, lat:62.6326766 },
        { lng:28.3648697	, lat:62.6326384 },
        { lng:28.3652785	, lat:62.6329992 },
        { lng:28.3684612	, lat:62.6317976 },
        { lng:28.3666239	, lat:62.6306532 },
        { lng:28.3539021	, lat:62.6319754 },
        { lng:28.3608819	, lat:62.6231296 },
        { lng:28.3539144	, lat:62.6224539 },
        { lng:28.3514105	, lat:62.6212259 },
        { lng:28.3619741	, lat:62.6166667 },
        { lng:28.3622411	, lat:62.6147266 },
        { lng:28.3607485	, lat:62.6124158 },
        { lng:28.3505731	, lat:62.6134244 },
        { lng:28.3487046	, lat:62.6146351 },
        { lng:28.3438858	, lat:62.6163053 },
        { lng:28.3381742	, lat:62.619844 },
        { lng:28.3346241	, lat:62.6191655 },
        { lng:28.3289084	, lat:62.6167512 },
        { lng:28.332417	, lat:62.614888 },
        { lng:28.334854	, lat:62.6117703 },
        { lng:28.3338652	, lat:62.6094869 },
        { lng:28.3407497	, lat:62.6062135 },
        { lng:28.3430465	, lat:62.6039884 },
        { lng:28.3544031	, lat:62.5978121 },
        { lng:28.3492756	, lat:62.5948347 },
        { lng:28.3487336	, lat:62.5922177 },
        { lng:28.3430492	, lat:62.5928791 },
        { lng:28.3336132	, lat:62.5965728 },
        { lng:28.3310849	, lat:62.5989841 },
        { lng:28.32155	, lat:62.6004764 },
        { lng:28.3160379	, lat:62.6029632 },
        { lng:28.3085899	, lat:62.6038635 },
        { lng:28.29834	, lat:62.6079594 },
        { lng:28.2581648	, lat:62.6076823 },
        { lng:28.2284051	, lat:62.5984908 },
        { lng:28.2188232	, lat:62.6038101 },
        { lng:28.2203442	, lat:62.6220005 },
        { lng:28.2051348	, lat:62.6316975 },
        { lng:28.2049827	, lat:62.6475182 },
        { lng:28.1663002	, lat:62.6578356 },
        { lng:28.1559578	, lat:62.653411 },
        { lng:28.102218	, lat:62.6689408 },
        { lng:28.0738779	, lat:62.6745035 },
        { lng:28.0650652	, lat:62.6724487 },
        { lng:28.0649251	, lat:62.6699164 },
        { lng:28.061258	, lat:62.6700464 },
        { lng:28.0532775	, lat:62.6717682 },
        { lng:28.0512277	, lat:62.6713569 },
        { lng:28.0496782	, lat:62.671436 },
        { lng:28.0483806	, lat:62.6715412 },
        { lng:28.0473413	, lat:62.6715793 },
        { lng:28.0464365	, lat:62.6715261 },
        { lng:28.0461974	, lat:62.6714882 },
        { lng:28.045499	, lat:62.6715127 },
        { lng:28.0453183	, lat:62.6715895 },
        { lng:28.0448653	, lat:62.6716788 },
        { lng:28.0442627	, lat:62.6717356 },
        { lng:28.0438749	, lat:62.6716823 },
        { lng:28.043659	, lat:62.6714872 },
        { lng:28.042977	, lat:62.6712735 },
        { lng:28.0424485	, lat:62.6712445 },
        { lng:28.0420374	, lat:62.6713709 },
        { lng:28.0416899	, lat:62.6715579 },
        { lng:28.0409	, lat:62.6721512 },
        { lng:28.0403253	, lat:62.6723512 },
        { lng:28.0396838	, lat:62.672412 },
        { lng:28.0391207	, lat:62.672358 },
        { lng:28.0386842	, lat:62.6722001 },
        { lng:28.0379542	, lat:62.6721992 },
        { lng:28.0375216	, lat:62.6719847 },
        { lng:28.0369017	, lat:62.6719013 },
        { lng:28.0360866	, lat:62.6720138 },
        { lng:28.0357997	, lat:62.6719304 },
        { lng:28.0353802	, lat:62.6719106 },
        { lng:28.0350243	, lat:62.6720148 },
        { lng:28.0346782	, lat:62.672012 },
        { lng:28.0343547	, lat:62.6719688 },
        { lng:28.0336621	, lat:62.6719377 },
        { lng:28.032175	, lat:62.6722622 },
        { lng:28.0318025	, lat:62.6724939 },
        { lng:28.0307596	, lat:62.6726842 },
        { lng:28.028462	, lat:62.6735531 },
        { lng:28.0277441	, lat:62.6737304 },
        { lng:28.0271513	, lat:62.673777 },
        { lng:28.0262969	, lat:62.6738483 },
        { lng:28.0258325	, lat:62.6739724 },
        { lng:28.0257331	, lat:62.6741474 },
        { lng:28.0251369	, lat:62.6743237 },
        { lng:28.0243582	, lat:62.674416 },
        { lng:28.0235894	, lat:62.6744112 },
        { lng:28.0232359	, lat:62.6745029 },
        { lng:28.0230512	, lat:62.6746363 },
        { lng:28.0229324	, lat:62.6748187 },
        { lng:28.021621	, lat:62.6750312 },
        { lng:28.0210396	, lat:62.6752884 },
        { lng:28.0196308	, lat:62.6756007 },
        { lng:28.0185743	, lat:62.6761449 },
        { lng:28.0178898	, lat:62.6763121 },
        { lng:28.0164208	, lat:62.6764566 },
        { lng:28.0152296	, lat:62.6767549 },
        { lng:28.0139815	, lat:62.6769626 },
        { lng:28.0132298	, lat:62.6772926 },
        { lng:28.0130601	, lat:62.6772422 },
        { lng:28.0129553	, lat:62.6769836 },
        { lng:28.0128768	, lat:62.6766943 },
        { lng:28.0126512	, lat:62.6763903 },
        { lng:28.0118149	, lat:62.6756447 },
        { lng:28.0122152	, lat:62.6750085 },
        { lng:28.0119696	, lat:62.6749179 },
        { lng:28.011745	, lat:62.6746824 },
        { lng:28.0096987	, lat:62.6747144 },
        { lng:28.0096329	, lat:62.6743291 },
        { lng:28.0093645	, lat:62.6739284 },
        { lng:28.0095287	, lat:62.6733331 },
        { lng:28.0061032	, lat:62.6733829 },
        { lng:28.0059583	, lat:62.6732227 },
        { lng:28.0055163	, lat:62.6731889 },
        { lng:28.0053499	, lat:62.6729861 },
        { lng:28.0046083	, lat:62.6729187 },
        { lng:28.0046481	, lat:62.6723404 },
        { lng:28.0040911	, lat:62.6720107 },
        { lng:28.0037597	, lat:62.6719327 },
        { lng:28.0024437	, lat:62.6719627 },
        { lng:28.0022671	, lat:62.6721606 },
        { lng:27.9998436	, lat:62.6721888 },
        { lng:27.9991873	, lat:62.6724558 },
        { lng:27.9982293	, lat:62.6724993 },
        { lng:27.9951793	, lat:62.6717777 },
        { lng:27.987342	, lat:62.6674517 },
        { lng:27.9869919	, lat:62.6663613 },
        { lng:27.9848341	, lat:62.6661044 },
        { lng:27.9844831	, lat:62.6661121 },
        { lng:27.9838737	, lat:62.6660212 },
        { lng:27.9835869	, lat:62.6661193 },
        { lng:27.9834048	, lat:62.666106 },
        { lng:27.9832397	, lat:62.6660414 },
        { lng:27.9830732	, lat:62.6660347 },
        { lng:27.9830228	, lat:62.6660105 },
        { lng:27.9826553	, lat:62.6659521 },
        { lng:27.9820115	, lat:62.6655457 },
        { lng:27.9821584	, lat:62.6660233 },
        { lng:27.963146	, lat:62.6677946 },
        { lng:27.9592352	, lat:62.6699426 },
        { lng:27.9574942	, lat:62.671651 },
        { lng:27.9537784	, lat:62.6725621 },
        { lng:27.9535604	, lat:62.6725779 },
        { lng:27.947897	, lat:62.6736063 },
        { lng:27.9452623	, lat:62.6741671 },
        { lng:27.9416635	, lat:62.6734295 },
        { lng:27.940956	, lat:62.6731599 },
        { lng:27.9376614	, lat:62.6713026 },
        { lng:27.9350389	, lat:62.672525 },
        { lng:27.9360093	, lat:62.6742052 },
        { lng:27.936754	, lat:62.677933 },
        { lng:27.9387567	, lat:62.6783085 },
        { lng:27.9390018	, lat:62.6783703 },
        { lng:27.9495892	, lat:62.6833329 },
        { lng:27.9480501	, lat:62.6839764 },
        { lng:27.9508508	, lat:62.6908479 },
        { lng:27.947366	, lat:62.6922699 },
        { lng:27.9468602	, lat:62.6923502 },
        { lng:27.9458114	, lat:62.6923647 },
        { lng:27.9431595	, lat:62.6929244 },
        { lng:27.94242	, lat:62.6930472 },
        { lng:27.9417372	, lat:62.6933606 },
        { lng:27.9408238	, lat:62.6934891 },
        { lng:27.9400568	, lat:62.6936453 },
        { lng:27.9389726	, lat:62.6937214 },
        { lng:27.9382609	, lat:62.6935111 },
        { lng:27.9164084	, lat:62.6866841 },
        { lng:27.9077524	, lat:62.6839705 },
        { lng:27.8830509	, lat:62.6764118 },
        { lng:27.8796381	, lat:62.6753093 },
        { lng:27.8782351	, lat:62.6752135 },
        { lng:27.8780858	, lat:62.67674 },
        { lng:27.8720852	, lat:62.6785865 },
        { lng:27.8623557	, lat:62.6815663 },
        { lng:27.8594491	, lat:62.6821113 },
        { lng:27.8528916	, lat:62.6831588 },
        { lng:27.8481209	, lat:62.6772288 },
        { lng:27.8479502	, lat:62.6720679 },
        { lng:27.8472789	, lat:62.6722584 },
        { lng:27.8452951	, lat:62.6695717 },
        { lng:27.8456786	, lat:62.6686426 },
        { lng:27.845594	, lat:62.6684485 },
        { lng:27.8448238	, lat:62.6681548 },
        { lng:27.84425	, lat:62.6682167 },
        { lng:27.8424729	, lat:62.6687797 },
        { lng:27.8403375	, lat:62.6691878 },
        { lng:27.8401378	, lat:62.6703769 },
        { lng:27.8393188	, lat:62.6709778 },
        { lng:27.8380765	, lat:62.6712424 },
        { lng:27.8374504	, lat:62.671229 },
        { lng:27.8370001	, lat:62.6713429 },
        { lng:27.8367815	, lat:62.6717872 },
        { lng:27.8329685	, lat:62.6735364 },
        { lng:27.8333377	, lat:62.6750161 },
        { lng:27.8322386	, lat:62.6760428 },
        { lng:27.8311497	, lat:62.6788051 },
        { lng:27.8295262	, lat:62.6787847 },
        { lng:27.8275394	, lat:62.6793232 },
        { lng:27.8250722	, lat:62.680089 },
        { lng:27.8241752	, lat:62.679543 },
        { lng:27.824574	, lat:62.6791865 },
        { lng:27.8219825	, lat:62.6778279 },
        { lng:27.8213431	, lat:62.6778277 },
        { lng:27.821433	, lat:62.6766653 },
        { lng:27.822315	, lat:62.6763967 },
        { lng:27.8231466	, lat:62.6748259 },
        { lng:27.8211031	, lat:62.6740441 },
        { lng:27.8166982	, lat:62.673546 },
        { lng:27.8143961	, lat:62.6737768 },
        { lng:27.8130425	, lat:62.6735542 },
        { lng:27.7915867	, lat:62.6705053 },
        { lng:27.7855374	, lat:62.671909 },
        { lng:27.7814641	, lat:62.6733765 },
        { lng:27.7737174	, lat:62.6714938 },
        { lng:27.7401551	, lat:62.6867824 },
        { lng:27.7134533	, lat:62.6893388 },
        { lng:27.7060502	, lat:62.6748602 },
        { lng:27.6673446	, lat:62.6775288 },
        { lng:27.5972519	, lat:62.6895387 },
        { lng:27.5971813	, lat:62.6894583 },
        { lng:27.5971532	, lat:62.6894207 },
        { lng:27.59687	, lat:62.6891916 },
        { lng:27.5944181	, lat:62.6897706 },
        { lng:27.5927459	, lat:62.6901246 },
        { lng:27.5927351	, lat:62.6901049 },
        { lng:27.5927256	, lat:62.6900748 },
        { lng:27.5927074	, lat:62.6900567 },
        { lng:27.5926672	, lat:62.6900537 },
        { lng:27.5925948	, lat:62.6900562 },
        { lng:27.5925488	, lat:62.6900498 },
        { lng:27.5925106	, lat:62.6900314 },
        { lng:27.5924767	, lat:62.6900077 },
        { lng:27.5924307	, lat:62.6899815 },
        { lng:27.5923757	, lat:62.6899703 },
        { lng:27.5922934	, lat:62.6899718 },
        { lng:27.5922223	, lat:62.6899714 },
        { lng:27.5921525	, lat:62.6899482 },
        { lng:27.5920895	, lat:62.6899188 },
        { lng:27.5919986	, lat:62.6898819 },
        { lng:27.5918264	, lat:62.6898467 },
        { lng:27.5917358	, lat:62.6898228 },
        { lng:27.5916885	, lat:62.6897819 },
        { lng:27.5917077	, lat:62.6897432 },
        { lng:27.5917664	, lat:62.6897129 },
        { lng:27.5918327	, lat:62.6896863 },
        { lng:27.5918532	, lat:62.6896719 },
        { lng:27.5918663	, lat:62.6896492 },
        { lng:27.5918623	, lat:62.6896224 },
        { lng:27.5918322	, lat:62.6895843 },
        { lng:27.5917814	, lat:62.6895321 },
        { lng:27.5917827	, lat:62.6894962 },
        { lng:27.5917858	, lat:62.6894739 },
        { lng:27.5917667	, lat:62.6894476 },
        { lng:27.5917319	, lat:62.6894213 },
        { lng:27.5917271	, lat:62.6893966 },
        { lng:27.591721	, lat:62.689355 },
        { lng:27.591717	, lat:62.689321 },
        { lng:27.5916966	, lat:62.6892882 },
        { lng:27.5916391	, lat:62.6892414 },
        { lng:27.5916064	, lat:62.6892098 },
        { lng:27.5915901	, lat:62.6891647 },
        { lng:27.5915871	, lat:62.6890996 },
        { lng:27.5915407	, lat:62.6890424 },
        { lng:27.5915151	, lat:62.688984 },
        { lng:27.5915245	, lat:62.6889602 },
        { lng:27.5911531	, lat:62.6886954 },
        { lng:27.5910241	, lat:62.6887151 },
        { lng:27.5909833	, lat:62.6887613 },
        { lng:27.5909768	, lat:62.6888288 },
        { lng:27.5909527	, lat:62.6888982 },
        { lng:27.5909008	, lat:62.6889929 },
        { lng:27.5908492	, lat:62.6891138 },
        { lng:27.5907989	, lat:62.6891744 },
        { lng:27.5906581	, lat:62.6892626 },
        { lng:27.5905553	, lat:62.6892345 },
        { lng:27.5904863	, lat:62.689182 },
        { lng:27.5904104	, lat:62.6891487 },
        { lng:27.5904171	, lat:62.6891172 },
        { lng:27.5904099	, lat:62.6890824 },
        { lng:27.5903461	, lat:62.689068 },
        { lng:27.5902988	, lat:62.6890788 },
        { lng:27.590261	, lat:62.6891181 },
        { lng:27.5901897	, lat:62.6891714 },
        { lng:27.5901354	, lat:62.6892086 },
        { lng:27.5900725	, lat:62.6892098 },
        { lng:27.5900474	, lat:62.6891831 },
        { lng:27.5900431	, lat:62.6891341 },
        { lng:27.5900049	, lat:62.6890663 },
        { lng:27.5899573	, lat:62.6890321 },
        { lng:27.5898959	, lat:62.6890202 },
        { lng:27.5898435	, lat:62.6890533 },
        { lng:27.589798	, lat:62.6891086 },
        { lng:27.589759	, lat:62.6891631 },
        { lng:27.5897224	, lat:62.6891957 },
        { lng:27.5896499	, lat:62.6892105 },
        { lng:27.5894831	, lat:62.6892026 },
        { lng:27.5893756	, lat:62.6891874 },
        { lng:27.5892683	, lat:62.689195 },
        { lng:27.589143	, lat:62.6892078 },
        { lng:27.5889795	, lat:62.6891952 },
        { lng:27.588655	, lat:62.6891811 },
        { lng:27.5885998	, lat:62.6891974 },
        { lng:27.5885263	, lat:62.6892365 },
        { lng:27.5883288	, lat:62.68928 },
        { lng:27.5881893	, lat:62.6892194 },
        { lng:27.5879857	, lat:62.6891655 },
        { lng:27.5877558	, lat:62.6891803 },
        { lng:27.5875761	, lat:62.6891341 },
        { lng:27.5874062	, lat:62.6890926 },
        { lng:27.587259	, lat:62.6890949 },
        { lng:27.5871207	, lat:62.6891034 },
        { lng:27.5870636	, lat:62.6891007 },
        { lng:27.5869926	, lat:62.6890599 },
        { lng:27.5869709	, lat:62.6890201 },
        { lng:27.5869852	, lat:62.6889796 },
        { lng:27.5869999	, lat:62.6889418 },
        { lng:27.586996	, lat:62.6889158 },
        { lng:27.5869664	, lat:62.688895 },
        { lng:27.5869044	, lat:62.6888976 },
        { lng:27.5868499	, lat:62.6889084 },
        { lng:27.5867882	, lat:62.6889298 },
        { lng:27.5867036	, lat:62.6889503 },
        { lng:27.5864816	, lat:62.6889763 },
        { lng:27.5863034	, lat:62.6889831 },
        { lng:27.5862442	, lat:62.6889585 },
        { lng:27.5862082	, lat:62.6889318 },
        { lng:27.5861884	, lat:62.6888967 },
        { lng:27.5861971	, lat:62.6888475 },
        { lng:27.5862061	, lat:62.6888014 },
        { lng:27.5861974	, lat:62.6887749 },
        { lng:27.5861434	, lat:62.68876 },
        { lng:27.5859993	, lat:62.6887607 },
        { lng:27.5858738	, lat:62.6887489 },
        { lng:27.5858203	, lat:62.6887584 },
        { lng:27.5857946	, lat:62.6887864 },
        { lng:27.5857583	, lat:62.6888155 },
        { lng:27.5857034	, lat:62.6888201 },
        { lng:27.5856143	, lat:62.6888015 },
        { lng:27.5855732	, lat:62.6887806 },
        { lng:27.5855666	, lat:62.6887439 },
        { lng:27.5855812	, lat:62.6887004 },
        { lng:27.5855783	, lat:62.6886635 },
        { lng:27.5855487	, lat:62.6886391 },
        { lng:27.5854697	, lat:62.6886286 },
        { lng:27.5854021	, lat:62.6886336 },
        { lng:27.5853083	, lat:62.6886482 },
        { lng:27.5852212	, lat:62.6886575 },
        { lng:27.5851286	, lat:62.6886642 },
        { lng:27.5850347	, lat:62.6886626 },
        { lng:27.5849292	, lat:62.6886545 },
        { lng:27.5848412	, lat:62.6886538 },
        { lng:27.5847705	, lat:62.6886671 },
        { lng:27.5846958	, lat:62.6886687 },
        { lng:27.5845908	, lat:62.6886626 },
        { lng:27.5845063	, lat:62.6886489 },
        { lng:27.5844861	, lat:62.6886251 },
        { lng:27.5844831	, lat:62.6885826 },
        { lng:27.5844837	, lat:62.6885252 },
        { lng:27.5844573	, lat:62.688478 },
        { lng:27.5844109	, lat:62.6884617 },
        { lng:27.5843233	, lat:62.6884656 },
        { lng:27.5842392	, lat:62.6884814 },
        { lng:27.5841564	, lat:62.6885269 },
        { lng:27.5841173	, lat:62.6885725 },
        { lng:27.5841009	, lat:62.688613 },
        { lng:27.584073	, lat:62.6886492 },
        { lng:27.5839982	, lat:62.6886788 },
        { lng:27.5839183	, lat:62.6886872 },
        { lng:27.583821	, lat:62.6886845 },
        { lng:27.5837069	, lat:62.6886651 },
        { lng:27.5836275	, lat:62.6886648 },
        { lng:27.5835672	, lat:62.6886854 },
        { lng:27.5834883	, lat:62.6887117 },
        { lng:27.5834226	, lat:62.6887212 },
        { lng:27.583364	, lat:62.6887251 },
        { lng:27.5833014	, lat:62.6886973 },
        { lng:27.5832741	, lat:62.688666 },
        { lng:27.5832297	, lat:62.6886574 },
        { lng:27.5831794	, lat:62.6886609 },
        { lng:27.5831258	, lat:62.6886878 },
        { lng:27.5829766	, lat:62.6888024 },
        { lng:27.582915	, lat:62.6888575 },
        { lng:27.5827639	, lat:62.6889334 },
        { lng:27.5827458	, lat:62.6889545 },
        { lng:27.5826948	, lat:62.6889628 },
        { lng:27.5826017	, lat:62.6889647 },
        { lng:27.5825395	, lat:62.6890044 },
        { lng:27.5824899	, lat:62.6890227 },
        { lng:27.5824385	, lat:62.689027 },
        { lng:27.5823615	, lat:62.6890132 },
        { lng:27.5822904	, lat:62.6889758 },
        { lng:27.5821981	, lat:62.6889519 },
        { lng:27.5821084	, lat:62.6889558 },
        { lng:27.5820401	, lat:62.6889703 },
        { lng:27.5819559	, lat:62.6890064 },
        { lng:27.5818485	, lat:62.689043 },
        { lng:27.5817507	, lat:62.6890744 },
        { lng:27.5816758	, lat:62.6891239 },
        { lng:27.5815455	, lat:62.6892576 },
        { lng:27.5815332	, lat:62.689296 },
        { lng:27.5814837	, lat:62.6893394 },
        { lng:27.5813964	, lat:62.689379 },
        { lng:27.5813264	, lat:62.6893899 },
        { lng:27.581275	, lat:62.6894549 },
        { lng:27.5812275	, lat:62.6895261 },
        { lng:27.5811801	, lat:62.6895607 },
        { lng:27.5811104	, lat:62.6895819 },
        { lng:27.5810518	, lat:62.6895946 },
        { lng:27.5810127	, lat:62.6896271 },
        { lng:27.5809763	, lat:62.6896711 },
        { lng:27.5809793	, lat:62.6897134 },
        { lng:27.5809498	, lat:62.6897448 },
        { lng:27.5808256	, lat:62.689792 },
        { lng:27.5806447	, lat:62.6897965 },
        { lng:27.5805386	, lat:62.6898096 },
        { lng:27.5804549	, lat:62.6898087 },
        { lng:27.5803775	, lat:62.6897894 },
        { lng:27.5803252	, lat:62.6897638 },
        { lng:27.5802988	, lat:62.6897294 },
        { lng:27.5803023	, lat:62.6897059 },
        { lng:27.5803167	, lat:62.689682 },
        { lng:27.5802889	, lat:62.6896735 },
        { lng:27.5802482	, lat:62.6896695 },
        { lng:27.5801903	, lat:62.6896759 },
        { lng:27.5801141	, lat:62.6896916 },
        { lng:27.5800547	, lat:62.6897036 },
        { lng:27.5800152	, lat:62.689705 },
        { lng:27.5799575	, lat:62.6896993 },
        { lng:27.5798926	, lat:62.6896867 },
        { lng:27.5797788	, lat:62.6896622 },
        { lng:27.5796909	, lat:62.6896523 },
        { lng:27.5796175	, lat:62.6896552 },
        { lng:27.579513	, lat:62.6896733 },
        { lng:27.5794563	, lat:62.6896786 },
        { lng:27.5793707	, lat:62.6896774 },
        { lng:27.5793201	, lat:62.6896846 },
        { lng:27.5792965	, lat:62.6897006 },
        { lng:27.5792918	, lat:62.6897314 },
        { lng:27.5793149	, lat:62.6897638 },
        { lng:27.5793462	, lat:62.6897937 },
        { lng:27.5793534	, lat:62.6898186 },
        { lng:27.5793213	, lat:62.6898395 },
        { lng:27.5792653	, lat:62.6898597 },
        { lng:27.5791811	, lat:62.6898652 },
        { lng:27.5791	, lat:62.6898576 },
        { lng:27.5790274	, lat:62.6898468 },
        { lng:27.5789861	, lat:62.6898539 },
        { lng:27.578957	, lat:62.6898787 },
        { lng:27.5789294	, lat:62.6899093 },
        { lng:27.5788892	, lat:62.689923 },
        { lng:27.578812	, lat:62.6899308 },
        { lng:27.5787106	, lat:62.6899323 },
        { lng:27.5786381	, lat:62.6899378 },
        { lng:27.5785738	, lat:62.6899225 },
        { lng:27.578495	, lat:62.6898938 },
        { lng:27.5784474	, lat:62.6898944 },
        { lng:27.5784168	, lat:62.6899135 },
        { lng:27.578383	, lat:62.6899471 },
        { lng:27.5783779	, lat:62.689986 },
        { lng:27.5783853	, lat:62.6900479 },
        { lng:27.5784014	, lat:62.6901173 },
        { lng:27.5784035	, lat:62.6901396 },
        { lng:27.5783908	, lat:62.6901494 },
        { lng:27.5783593	, lat:62.6901332 },
        { lng:27.5783051	, lat:62.6901224 },
        { lng:27.5781787	, lat:62.6901198 },
        { lng:27.5780778	, lat:62.6901264 },
        { lng:27.5779534	, lat:62.6901472 },
        { lng:27.577934	, lat:62.6901618 },
        { lng:27.5779376	, lat:62.6902023 },
        { lng:27.5779413	, lat:62.6902283 },
        { lng:27.5779187	, lat:62.6902533 },
        { lng:27.5778731	, lat:62.6902752 },
        { lng:27.5778043	, lat:62.690286 },
        { lng:27.5777419	, lat:62.6902751 },
        { lng:27.5776843	, lat:62.6902569 },
        { lng:27.577646	, lat:62.6902633 },
        { lng:27.577617	, lat:62.6902886 },
        { lng:27.5775833	, lat:62.6903175 },
        { lng:27.5775488	, lat:62.6903373 },
        { lng:27.57748	, lat:62.6903445 },
        { lng:27.5773886	, lat:62.690338 },
        { lng:27.5773153	, lat:62.6903301 },
        { lng:27.5772521	, lat:62.6903401 },
        { lng:27.5771751	, lat:62.6903608 },
        { lng:27.5771251	, lat:62.6903887 },
        { lng:27.5770959	, lat:62.6904253 },
        { lng:27.5771033	, lat:62.6904663 },
        { lng:27.5771406	, lat:62.6904879 },
        { lng:27.5772451	, lat:62.690513 },
        { lng:27.5773354	, lat:62.6905393 },
        { lng:27.5773668	, lat:62.6905678 },
        { lng:27.5773565	, lat:62.6905993 },
        { lng:27.5773185	, lat:62.6906284 },
        { lng:27.5772636	, lat:62.6906509 },
        { lng:27.5771969	, lat:62.690661 },
        { lng:27.5770984	, lat:62.6906581 },
        { lng:27.5770462	, lat:62.6906612 },
        { lng:27.5770226	, lat:62.6906869 },
        { lng:27.5770169	, lat:62.690727 },
        { lng:27.5769906	, lat:62.6907654 },
        { lng:27.5769381	, lat:62.6907837 },
        { lng:27.5768334	, lat:62.6908026 },
        { lng:27.5767658	, lat:62.6908213 },
        { lng:27.5767251	, lat:62.6908448 },
        { lng:27.5766885	, lat:62.6908961 },
        { lng:27.5766453	, lat:62.6909398 },
        { lng:27.5765655	, lat:62.6909853 },
        { lng:27.5764715	, lat:62.6910184 },
        { lng:27.5763808	, lat:62.6910359 },
        { lng:27.5762703	, lat:62.6910441 },
        { lng:27.5761784	, lat:62.6910411 },
        { lng:27.5760875	, lat:62.6910312 },
        { lng:27.5760224	, lat:62.6910125 },
        { lng:27.5759845	, lat:62.6909851 },
        { lng:27.5759617	, lat:62.6909439 },
        { lng:27.57594	, lat:62.6908997 },
        { lng:27.575904	, lat:62.6908759 },
        { lng:27.5758238	, lat:62.6908592 },
        { lng:27.5757182	, lat:62.6908545 },
        { lng:27.5756257	, lat:62.6908497 },
        { lng:27.5755621	, lat:62.6908435 },
        { lng:27.5755111	, lat:62.6908301 },
        { lng:27.5754879	, lat:62.6907981 },
        { lng:27.5754612	, lat:62.6907494 },
        { lng:27.5754385	, lat:62.6907343 },
        { lng:27.575376	, lat:62.6907316 },
        { lng:27.5752705	, lat:62.6907534 },
        { lng:27.5750777	, lat:62.6907832 },
        { lng:27.5749613	, lat:62.6908017 },
        { lng:27.5748891	, lat:62.6908301 },
        { lng:27.574745	, lat:62.6909062 },
        { lng:27.5746891	, lat:62.6909316 },
        { lng:27.5746048	, lat:62.6909543 },
        { lng:27.5745248	, lat:62.6909696 },
        { lng:27.5744351	, lat:62.6909737 },
        { lng:27.574365	, lat:62.6909707 },
        { lng:27.5742979	, lat:62.6909582 },
        { lng:27.5742447	, lat:62.6909475 },
        { lng:27.5742097	, lat:62.6909594 },
        { lng:27.5741892	, lat:62.6909879 },
        { lng:27.5741885	, lat:62.691088 },
        { lng:27.5741892	, lat:62.6911268 },
        { lng:27.5741703	, lat:62.6911588 },
        { lng:27.5741197	, lat:62.6911883 },
        { lng:27.5740357	, lat:62.6912096 },
        { lng:27.5739249	, lat:62.6912321 },
        { lng:27.573868	, lat:62.6912619 },
        { lng:27.5738677	, lat:62.6912824 },
        { lng:27.5737787	, lat:62.691331 },
        { lng:27.5737357	, lat:62.6913727 },
        { lng:27.5736626	, lat:62.6913973 },
        { lng:27.5735654	, lat:62.6914019 },
        { lng:27.5734469	, lat:62.6913968 },
        { lng:27.5733541	, lat:62.6913857 },
        { lng:27.5732612	, lat:62.6913786 },
        { lng:27.573138	, lat:62.6913981 },
        { lng:27.5730078	, lat:62.6914498 },
        { lng:27.5729538	, lat:62.6914882 },
        { lng:27.5729537	, lat:62.6915205 },
        { lng:27.5728892	, lat:62.6915743 },
        { lng:27.5728689	, lat:62.6916335 },
        { lng:27.5728357	, lat:62.6916782 },
        { lng:27.5727366	, lat:62.6917301 },
        { lng:27.572643	, lat:62.691789 },
        { lng:27.5725311	, lat:62.6918636 },
        { lng:27.5724652	, lat:62.6918948 },
        { lng:27.5723787	, lat:62.6919131 },
        { lng:27.5721845	, lat:62.6919427 },
        { lng:27.572055	, lat:62.6919673 },
        { lng:27.571984	, lat:62.692017 },
        { lng:27.5719717	, lat:62.6920725 },
        { lng:27.5719974	, lat:62.6921092 },
        { lng:27.5719942	, lat:62.6921368 },
        { lng:27.5719757	, lat:62.6921996 },
        { lng:27.5720189	, lat:62.6922571 },
        { lng:27.5721239	, lat:62.6923427 },
        { lng:27.5721703	, lat:62.6923812 },
        { lng:27.5721721	, lat:62.6924157 },
        { lng:27.5721259	, lat:62.6924505 },
        { lng:27.5720621	, lat:62.692474 },
        { lng:27.5719801	, lat:62.6924833 },
        { lng:27.5719193	, lat:62.6924917 },
        { lng:27.5719044	, lat:62.6925119 },
        { lng:27.5719095	, lat:62.692544 },
        { lng:27.5718809	, lat:62.6925818 },
        { lng:27.5718158	, lat:62.6926081 },
        { lng:27.5717753	, lat:62.6926335 },
        { lng:27.5717153	, lat:62.6926887 },
        { lng:27.5716653	, lat:62.6927416 },
        { lng:27.57161	, lat:62.6927711 },
        { lng:27.5715405	, lat:62.6927901 },
        { lng:27.571419	, lat:62.6928102 },
        { lng:27.5711832	, lat:62.6928307 },
        { lng:27.5710263	, lat:62.6928424 },
        { lng:27.5708488	, lat:62.692869 },
        { lng:27.5707582	, lat:62.6928979 },
        { lng:27.570673	, lat:62.6929455 },
        { lng:27.5706403	, lat:62.6929769 },
        { lng:27.5705691	, lat:62.6930251 },
        { lng:27.5705526	, lat:62.6930608 },
        { lng:27.5705442	, lat:62.6931213 },
        { lng:27.5705315	, lat:62.6931408 },
        { lng:27.5704689	, lat:62.6931804 },
        { lng:27.5704355	, lat:62.6932272 },
        { lng:27.5703939	, lat:62.6932457 },
        { lng:27.5703566	, lat:62.6932542 },
        { lng:27.5702998	, lat:62.6932515 },
        { lng:27.5702745	, lat:62.6932261 },
        { lng:27.5702707	, lat:62.693191 },
        { lng:27.5703001	, lat:62.6931471 },
        { lng:27.5702894	, lat:62.6931231 },
        { lng:27.5701968	, lat:62.6930819 },
        { lng:27.5700858	, lat:62.6930455 },
        { lng:27.5699615	, lat:62.6929808 },
        { lng:27.5698419	, lat:62.6929503 },
        { lng:27.5697613	, lat:62.6929292 },
        { lng:27.5696818	, lat:62.6929294 },
        { lng:27.5690949	, lat:62.6931818 },
        { lng:27.5685693	, lat:62.6931972 },
        { lng:27.5678178	, lat:62.6937327 },
        { lng:27.5677353	, lat:62.6937601 },
        { lng:27.5674632	, lat:62.6937581 },
        { lng:27.5672709	, lat:62.6937242 },
        { lng:27.567181	, lat:62.6937275 },
        { lng:27.5671084	, lat:62.6937556 },
        { lng:27.5670513	, lat:62.6938114 },
        { lng:27.5669809	, lat:62.6939173 },
        { lng:27.5669254	, lat:62.6939708 },
        { lng:27.5668413	, lat:62.6940139 },
        { lng:27.5667592	, lat:62.6940361 },
        { lng:27.5666834	, lat:62.6940402 },
        { lng:27.5665168	, lat:62.6940309 },
        { lng:27.5663516	, lat:62.6940087 },
        { lng:27.5662014	, lat:62.6940098 },
        { lng:27.5660834	, lat:62.6940301 },
        { lng:27.5660334	, lat:62.6940564 },
        { lng:27.5660224	, lat:62.6941089 },
        { lng:27.5659967	, lat:62.6941529 },
        { lng:27.565953	, lat:62.6941753 },
        { lng:27.5659183	, lat:62.6941793 },
        { lng:27.5658596	, lat:62.6941652 },
        { lng:27.5657806	, lat:62.6941122 },
        { lng:27.5656981	, lat:62.69407 },
        { lng:27.5655957	, lat:62.6940421 },
        { lng:27.5654756	, lat:62.6940465 },
        { lng:27.5653984	, lat:62.694078 },
        { lng:27.5653561	, lat:62.6941061 },
        { lng:27.5652606	, lat:62.6941601 },
        { lng:27.5652561	, lat:62.6941989 },
        { lng:27.565276	, lat:62.6942495 },
        { lng:27.5652827	, lat:62.6942793 },
        { lng:27.5652631	, lat:62.6943111 },
        { lng:27.5652187	, lat:62.6943416 },
        { lng:27.5651863	, lat:62.6943513 },
        { lng:27.5651372	, lat:62.6943566 },
        { lng:27.565098	, lat:62.69435 },
        { lng:27.5650134	, lat:62.6943367 },
        { lng:27.5649126	, lat:62.6943326 },
        { lng:27.5647359	, lat:62.6943352 },
        { lng:27.5646546	, lat:62.6943449 },
        { lng:27.5645799	, lat:62.6943622 },
        { lng:27.5645119	, lat:62.6943908 },
        { lng:27.5644666	, lat:62.6944246 },
        { lng:27.564424	, lat:62.6944488 },
        { lng:27.5642628	, lat:62.6944886 },
        { lng:27.5641254	, lat:62.6945205 },
        { lng:27.5640353	, lat:62.6945458 },
        { lng:27.5639235	, lat:62.6946057 },
        { lng:27.563885	, lat:62.6946531 },
        { lng:27.5638626	, lat:62.6946958 },
        { lng:27.5638213	, lat:62.6947331 },
        { lng:27.5637689	, lat:62.6947564 },
        { lng:27.5637134	, lat:62.6947639 },
        { lng:27.5636624	, lat:62.6947629 },
        { lng:27.5635213	, lat:62.6947347 },
        { lng:27.5633921	, lat:62.6947192 },
        { lng:27.5632341	, lat:62.6947173 },
        { lng:27.56309	, lat:62.6947327 },
        { lng:27.5629697	, lat:62.6947625 },
        { lng:27.5626134	, lat:62.6948813 },
        { lng:27.5622924	, lat:62.6949597 },
        { lng:27.5620704	, lat:62.6950216 },
        { lng:27.5619092	, lat:62.6950501 },
        { lng:27.5618105	, lat:62.6950709 },
        { lng:27.5617106	, lat:62.6951096 },
        { lng:27.5615762	, lat:62.6951782 },
        { lng:27.5614785	, lat:62.6952112 },
        { lng:27.5611631	, lat:62.6952725 },
        { lng:27.5610478	, lat:62.695288 },
        { lng:27.5606358	, lat:62.6953541 },
        { lng:27.5598197	, lat:62.6954575 },
        { lng:27.5596985	, lat:62.6954791 },
        { lng:27.5595698	, lat:62.6955073 },
        { lng:27.5593282	, lat:62.6955401 },
        { lng:27.5592093	, lat:62.6955745 },
        { lng:27.5590823	, lat:62.6956486 },
        { lng:27.5590002	, lat:62.6956992 },
        { lng:27.5589345	, lat:62.6957224 },
        { lng:27.5588521	, lat:62.6957397 },
        { lng:27.5587664	, lat:62.6957396 },
        { lng:27.5586321	, lat:62.6957302 },
        { lng:27.5585451	, lat:62.695729 },
        { lng:27.5584205	, lat:62.695756 },
        { lng:27.5582054	, lat:62.6958221 },
        { lng:27.5579251	, lat:62.6959473 },
        { lng:27.5577611	, lat:62.6960366 },
        { lng:27.5576848	, lat:62.696078 },
        { lng:27.5576136	, lat:62.6961227 },
        { lng:27.5575043	, lat:62.6961757 },
        { lng:27.5574158	, lat:62.6962043 },
        { lng:27.5573137	, lat:62.6962206 },
        { lng:27.5572052	, lat:62.696219 },
        { lng:27.5570502	, lat:62.6962266 },
        { lng:27.556923	, lat:62.6962499 },
        { lng:27.556812	, lat:62.6962765 },
        { lng:27.556652	, lat:62.69634 },
        { lng:27.5564559	, lat:62.6964084 },
        { lng:27.5563111	, lat:62.6964502 },
        { lng:27.556188	, lat:62.6964924 },
        { lng:27.5560076	, lat:62.6966153 },
        { lng:27.5559242	, lat:62.6967507 },
        { lng:27.555831	, lat:62.6968629 },
        { lng:27.5557863	, lat:62.6970083 },
        { lng:27.5557588	, lat:62.6970572 },
        { lng:27.5557193	, lat:62.6971267 },
        { lng:27.5557017	, lat:62.6971783 },
        { lng:27.5556269	, lat:62.6972378 },
        { lng:27.5555271	, lat:62.6972763 },
        { lng:27.5554413	, lat:62.6972889 },
        { lng:27.5553448	, lat:62.697303 },
        { lng:27.555202	, lat:62.6973301 },
        { lng:27.5550841	, lat:62.6973426 },
        { lng:27.5549532	, lat:62.6973346 },
        { lng:27.5545285	, lat:62.6972499 },
        { lng:27.5542024	, lat:62.6971994 },
        { lng:27.5541007	, lat:62.6971849 },
        { lng:27.5540457	, lat:62.6971569 },
        { lng:27.5539271	, lat:62.6971528 },
        { lng:27.5537992	, lat:62.6971556 },
        { lng:27.5536468	, lat:62.6971378 },
        { lng:27.5534931	, lat:62.6971089 },
        { lng:27.5533898	, lat:62.6970654 },
        { lng:27.5533693	, lat:62.6970185 },
        { lng:27.5534059	, lat:62.6969762 },
        { lng:27.553465	, lat:62.6969228 },
        { lng:27.5534732	, lat:62.6968737 },
        { lng:27.5534484	, lat:62.6968241 },
        { lng:27.5533663	, lat:62.6967731 },
        { lng:27.5533085	, lat:62.6967197 },
        { lng:27.5532433	, lat:62.6966765 },
        { lng:27.5531716	, lat:62.6966489 },
        { lng:27.553081	, lat:62.6966485 },
        { lng:27.5529977	, lat:62.6966633 },
        { lng:27.5529068	, lat:62.6967082 },
        { lng:27.5528391	, lat:62.6967373 },
        { lng:27.5526006	, lat:62.696797 },
        { lng:27.5525397	, lat:62.6968229 },
        { lng:27.5524945	, lat:62.6968757 },
        { lng:27.5524733	, lat:62.696934 },
        { lng:27.5524271	, lat:62.6969899 },
        { lng:27.5523719	, lat:62.6970282 },
        { lng:27.5522439	, lat:62.6971617 },
        { lng:27.5522234	, lat:62.6972016 },
        { lng:27.5521797	, lat:62.6972206 },
        { lng:27.5520814	, lat:62.6972354 },
        { lng:27.5519735	, lat:62.6972397 },
        { lng:27.551891	, lat:62.6972216 },
        { lng:27.5518025	, lat:62.6971941 },
        { lng:27.5517196	, lat:62.6971788 },
        { lng:27.551483	, lat:62.6971764 },
        { lng:27.5513948	, lat:62.6971955 },
        { lng:27.5512969	, lat:62.6972014 },
        { lng:27.5511983	, lat:62.6971938 },
        { lng:27.5510282	, lat:62.6971662 },
        { lng:27.5508816	, lat:62.6971501 },
        { lng:27.5506873	, lat:62.6971604 },
        { lng:27.5504267	, lat:62.6971614 },
        { lng:27.5503062	, lat:62.6971559 },
        { lng:27.5501996	, lat:62.6971455 },
        { lng:27.5500967	, lat:62.6971482 },
        { lng:27.5500342	, lat:62.6971688 },
        { lng:27.5499483	, lat:62.6971987 },
        { lng:27.5498687	, lat:62.6972154 },
        { lng:27.5497418	, lat:62.6972202 },
        { lng:27.5496319	, lat:62.6971954 },
        { lng:27.5495304	, lat:62.6971578 },
        { lng:27.549499	, lat:62.6971016 },
        { lng:27.5494494	, lat:62.6970881 },
        { lng:27.5493621	, lat:62.697093 },
        { lng:27.5492075	, lat:62.697152 },
        { lng:27.548966	, lat:62.6971851 },
        { lng:27.5488751	, lat:62.6972189 },
        { lng:27.5487569	, lat:62.6972745 },
        { lng:27.5486208	, lat:62.6973052 },
        { lng:27.5485118	, lat:62.6973422 },
        { lng:27.548447	, lat:62.6973818 },
        { lng:27.5483739	, lat:62.697425 },
        { lng:27.5483288	, lat:62.6974416 },
        { lng:27.5482343	, lat:62.6974469 },
        { lng:27.54809	, lat:62.697448 },
        { lng:27.5479913	, lat:62.6974627 },
        { lng:27.5477166	, lat:62.6975085 },
        { lng:27.5476146	, lat:62.6975295 },
        { lng:27.5475576	, lat:62.6975638 },
        { lng:27.5475285	, lat:62.6976124 },
        { lng:27.5474892	, lat:62.6976981 },
        { lng:27.5474485	, lat:62.6977451 },
        { lng:27.5473325	, lat:62.6978746 },
        { lng:27.5471061	, lat:62.6979924 },
        { lng:27.5469609	, lat:62.6980402 },
        { lng:27.546797	, lat:62.6980987 },
        { lng:27.5466494	, lat:62.6981606 },
        { lng:27.5465163	, lat:62.6982147 },
        { lng:27.5463933	, lat:62.698243 },
        { lng:27.5462283	, lat:62.6982619 },
        { lng:27.5460936	, lat:62.6982811 },
        { lng:27.5459968	, lat:62.6983086 },
        { lng:27.5458761	, lat:62.6983515 },
        { lng:27.5458423	, lat:62.6983468 },
        { lng:27.5457657	, lat:62.6983128 },
        { lng:27.5456624	, lat:62.6982864 },
        { lng:27.5455641	, lat:62.6982492 },
        { lng:27.5453625	, lat:62.6981594 },
        { lng:27.5452715	, lat:62.6981052 },
        { lng:27.5451996	, lat:62.6980542 },
        { lng:27.5451271	, lat:62.6979809 },
        { lng:27.5450823	, lat:62.6979205 },
        { lng:27.5450632	, lat:62.6978643 },
        { lng:27.5450266	, lat:62.697787 },
        { lng:27.5449912	, lat:62.6976979 },
        { lng:27.5449396	, lat:62.6976344 },
        { lng:27.5448608	, lat:62.6975752 },
        { lng:27.5447707	, lat:62.697467 },
        { lng:27.5447296	, lat:62.6974335 },
        { lng:27.5446022	, lat:62.697347 },
        { lng:27.5445616	, lat:62.6973053 },
        { lng:27.544556	, lat:62.6972388 },
        { lng:27.5445813	, lat:62.6971835 },
        { lng:27.5446473	, lat:62.6971007 },
        { lng:27.5446597	, lat:62.6970557 },
        { lng:27.54466	, lat:62.6969967 },
        { lng:27.5446703	, lat:62.6969379 },
        { lng:27.5446476	, lat:62.6968823 },
        { lng:27.5445455	, lat:62.6968236 },
        { lng:27.5444641	, lat:62.6967717 },
        { lng:27.5444191	, lat:62.6966959 },
        { lng:27.5443718	, lat:62.6966699 },
        { lng:27.5442799	, lat:62.6966348 },
        { lng:27.544134	, lat:62.696552 },
        { lng:27.5440211	, lat:62.6964959 },
        { lng:27.5438508	, lat:62.6964445 },
        { lng:27.5437027	, lat:62.6964023 },
        { lng:27.5430844	, lat:62.6963755 },
        { lng:27.5426271	, lat:62.6963562 },
        { lng:27.5384049	, lat:62.6940211 },
        { lng:27.5377345	, lat:62.6937482 },
        { lng:27.534532	, lat:62.6935237 },
        { lng:27.5309708	, lat:62.6932595 },
        { lng:27.5226018	, lat:62.6939923 },
        { lng:27.5186141	, lat:62.6943205 },
        { lng:27.4886512	, lat:62.7047513 },
        { lng:27.4882032	, lat:62.7049098 },
        { lng:27.485532	, lat:62.70584 },
        { lng:27.4825154	, lat:62.7057915 },
        { lng:27.483613	, lat:62.7067344 },
        { lng:27.4769991	, lat:62.7161649 },
        { lng:27.4679105	, lat:62.7178409 },
        { lng:27.4609818	, lat:62.7188882 },
        { lng:27.4607458	, lat:62.7188734 },
        { lng:27.4605527	, lat:62.7187849 },
        { lng:27.4601665	, lat:62.7187849 },
        { lng:27.4595318	, lat:62.7186477 },
        { lng:27.459394	, lat:62.7186472 },
        { lng:27.4591687	, lat:62.7186866 },
        { lng:27.4590812	, lat:62.7186625 },
        { lng:27.4591365	, lat:62.7186226 },
        { lng:27.4592744	, lat:62.7185739 },
        { lng:27.4592223	, lat:62.7185292 },
        { lng:27.4590399	, lat:62.7184997 },
        { lng:27.458761	, lat:62.7184997 },
        { lng:27.4585893	, lat:62.7184554 },
        { lng:27.458364	, lat:62.718303 },
        { lng:27.4581709	, lat:62.7182243 },
        { lng:27.4579118	, lat:62.7181805 },
        { lng:27.4576114	, lat:62.7181953 },
        { lng:27.4575041	, lat:62.7182445 },
        { lng:27.4554484	, lat:62.7113126 },
        { lng:27.4488354	, lat:62.7097493 },
        { lng:27.4485272	, lat:62.7094456 },
        { lng:27.4481883	, lat:62.7091379 },
        { lng:27.447836	, lat:62.7088099 },
        { lng:27.4476475	, lat:62.7086473 },
        { lng:27.4460163	, lat:62.7071995 },
        { lng:27.4446032	, lat:62.7059233 },
        { lng:27.4388756	, lat:62.7007636 },
        { lng:27.4378725	, lat:62.6999436 },
        { lng:27.4376665	, lat:62.7000531 },
        { lng:27.4375377	, lat:62.7000875 },
        { lng:27.4374304	, lat:62.7001908 },
        { lng:27.4374197	, lat:62.7002745 },
        { lng:27.4371193	, lat:62.7004615 },
        { lng:27.4368511	, lat:62.7005894 },
        { lng:27.4367223	, lat:62.7006878 },
        { lng:27.4365292	, lat:62.7007961 },
        { lng:27.4364434	, lat:62.7008994 },
        { lng:27.4364648	, lat:62.7010569 },
        { lng:27.4363576	, lat:62.7011799 },
        { lng:27.4362932	, lat:62.7013324 },
        { lng:27.436143	, lat:62.7014554 },
        { lng:27.4359928	, lat:62.7016178 },
        { lng:27.4358318	, lat:62.7017113 },
        { lng:27.4357997	, lat:62.7017802 },
        { lng:27.4359391	, lat:62.7018737 },
        { lng:27.436025	, lat:62.7019032 },
        { lng:27.4360142	, lat:62.7020016 },
        { lng:27.4358318	, lat:62.7020508 },
        { lng:27.4356709	, lat:62.7020951 },
        { lng:27.4355851	, lat:62.7021541 },
        { lng:27.4355207	, lat:62.7022427 },
        { lng:27.434995	, lat:62.7024838 },
        { lng:27.4347482	, lat:62.7025724 },
        { lng:27.4345337	, lat:62.7026659 },
        { lng:27.4343405	, lat:62.7027938 },
        { lng:27.4341903	, lat:62.7030054 },
        { lng:27.4338899	, lat:62.7031727 },
        { lng:27.4337183	, lat:62.7033104 },
        { lng:27.4336324	, lat:62.7034039 },
        { lng:27.4332784	, lat:62.7035171 },
        { lng:27.4331067	, lat:62.7036352 },
        { lng:27.4327956	, lat:62.7039845 },
        { lng:27.4324952	, lat:62.7041911 },
        { lng:27.4318193	, lat:62.7045995 },
        { lng:27.4314008	, lat:62.7048209 },
        { lng:27.4310361	, lat:62.7050472 },
        { lng:27.4308322	, lat:62.7051358 },
        { lng:27.4305854	, lat:62.7051702 },
        { lng:27.4303408	, lat:62.7052352 },
        { lng:27.430167	, lat:62.7053818 },
        { lng:27.4296628	, lat:62.7055835 },
        { lng:27.4294375	, lat:62.7056917 },
        { lng:27.4293624	, lat:62.7058295 },
        { lng:27.4291049	, lat:62.7059672 },
        { lng:27.4289654	, lat:62.7061394 },
        { lng:27.4285899	, lat:62.7061886 },
        { lng:27.428386	, lat:62.7062427 },
        { lng:27.4282787	, lat:62.7062329 },
        { lng:27.428032	, lat:62.7061493 },
        { lng:27.4278496	, lat:62.7061296 },
        { lng:27.4276028	, lat:62.7061443 },
        { lng:27.4274741	, lat:62.7061689 },
        { lng:27.4272595	, lat:62.7061394 },
        { lng:27.4271522	, lat:62.7060853 },
        { lng:27.4270771	, lat:62.7060361 },
        { lng:27.4270127	, lat:62.7059967 },
        { lng:27.4269269	, lat:62.7058787 },
        { lng:27.4268089	, lat:62.7058196 },
        { lng:27.4266372	, lat:62.7057803 },
        { lng:27.4265729	, lat:62.7057163 },
        { lng:27.4265943	, lat:62.7056278 },
        { lng:27.4267123	, lat:62.7055589 },
        { lng:27.4267445	, lat:62.7054211 },
        { lng:27.4266737	, lat:62.705304 },
        { lng:27.4264227	, lat:62.7052489 },
        { lng:27.4263154	, lat:62.7051702 },
        { lng:27.426251	, lat:62.7051013 },
        { lng:27.4262725	, lat:62.7050718 },
        { lng:27.4263797	, lat:62.7050472 },
        { lng:27.4264978	, lat:62.7050325 },
        { lng:27.4265407	, lat:62.704939 },
        { lng:27.4263368	, lat:62.7048061 },
        { lng:27.4261222	, lat:62.704752 },
        { lng:27.426015	, lat:62.7046389 },
        { lng:27.4259399	, lat:62.7044716 },
        { lng:27.425972	, lat:62.7044027 },
        { lng:27.426015	, lat:62.7043633 },
        { lng:27.4261437	, lat:62.7043191 },
        { lng:27.4262403	, lat:62.7042403 },
        { lng:27.4262939	, lat:62.7041222 },
        { lng:27.4263046	, lat:62.7039992 },
        { lng:27.4264012	, lat:62.7038762 },
        { lng:27.4265192	, lat:62.7037631 },
        { lng:27.4266372	, lat:62.7035909 },
        { lng:27.4266694	, lat:62.7035122 },
        { lng:27.4267552	, lat:62.7034137 },
        { lng:27.4267016	, lat:62.7033006 },
        { lng:27.4263905	, lat:62.7032071 },
        { lng:27.426369	, lat:62.7029611 },
        { lng:27.426133	, lat:62.7027889 },
        { lng:27.4261866	, lat:62.7027003 },
        { lng:27.4262188	, lat:62.7026265 },
        { lng:27.4262188	, lat:62.7025527 },
        { lng:27.4262081	, lat:62.7024592 },
        { lng:27.4260686	, lat:62.7023411 },
        { lng:27.4261974	, lat:62.7021246 },
        { lng:27.4261866	, lat:62.7019573 },
        { lng:27.4261008	, lat:62.7018589 },
        { lng:27.4259399	, lat:62.7017113 },
        { lng:27.4259077	, lat:62.7015145 },
        { lng:27.4259506	, lat:62.7014112 },
        { lng:27.426015	, lat:62.7013177 },
        { lng:27.4260166	, lat:62.7011061 },
        { lng:27.4258111	, lat:62.700988 },
        { lng:27.4258004	, lat:62.7008748 },
        { lng:27.4259399	, lat:62.7007764 },
        { lng:27.4259828	, lat:62.7006534 },
        { lng:27.4260042	, lat:62.7004959 },
        { lng:27.4259828	, lat:62.7004123 },
        { lng:27.4260257	, lat:62.7002696 },
        { lng:27.4260257	, lat:62.7001908 },
        { lng:27.4258755	, lat:62.7000137 },
        { lng:27.4255643	, lat:62.6999546 },
        { lng:27.4254356	, lat:62.6999005 },
        { lng:27.425382	, lat:62.6997972 },
        { lng:27.4254249	, lat:62.6996791 },
        { lng:27.4255536	, lat:62.6995905 },
        { lng:27.4255965	, lat:62.6994183 },
        { lng:27.4256609	, lat:62.6993149 },
        { lng:27.4258648	, lat:62.6992165 },
        { lng:27.426015	, lat:62.6989557 },
        { lng:27.4260364	, lat:62.698877 },
        { lng:27.4259399	, lat:62.6988278 },
        { lng:27.425382	, lat:62.6988376 },
        { lng:27.4251567	, lat:62.698813 },
        { lng:27.4250172	, lat:62.6988278 },
        { lng:27.424749	, lat:62.698872 },
        { lng:27.4244593	, lat:62.6989705 },
        { lng:27.4242554	, lat:62.6990492 },
        { lng:27.4239121	, lat:62.6992313 },
        { lng:27.422571	, lat:62.6997381 },
        { lng:27.4222921	, lat:62.6998021 },
        { lng:27.4220882	, lat:62.6998119 },
        { lng:27.4217556	, lat:62.6997923 },
        { lng:27.4214874	, lat:62.6997972 },
        { lng:27.4210904	, lat:62.6998661 },
        { lng:27.4207149	, lat:62.6999497 },
        { lng:27.4205325	, lat:62.700058 },
        { lng:27.4201677	, lat:62.7002942 },
        { lng:27.4199854	, lat:62.7003286 },
        { lng:27.4198995	, lat:62.7003778 },
        { lng:27.4198244	, lat:62.7004467 },
        { lng:27.4195777	, lat:62.7004959 },
        { lng:27.4192451	, lat:62.7005304 },
        { lng:27.4190198	, lat:62.7005746 },
        { lng:27.418773	, lat:62.7006091 },
        { lng:27.4183653	, lat:62.7006583 },
        { lng:27.4180252	, lat:62.7007279 },
        { lng:27.417861	, lat:62.700801 },
        { lng:27.4178523	, lat:62.7010694 },
        { lng:27.417216	, lat:62.7010048 },
        { lng:27.4172506	, lat:62.7007766 },
        { lng:27.4171315	, lat:62.7006288 },
        { lng:27.4171208	, lat:62.7004812 },
        { lng:27.4171744	, lat:62.7003483 },
        { lng:27.4171744	, lat:62.70024 },
        { lng:27.4170027	, lat:62.7001416 },
        { lng:27.416874	, lat:62.7000481 },
        { lng:27.416756	, lat:62.6999842 },
        { lng:27.4167131	, lat:62.6998759 },
        { lng:27.4166487	, lat:62.699748 },
        { lng:27.4166165	, lat:62.6996545 },
        { lng:27.4166701	, lat:62.6995019 },
        { lng:27.4165414	, lat:62.6993641 },
        { lng:27.4162303	, lat:62.6992657 },
        { lng:27.4159835	, lat:62.6992214 },
        { lng:27.4158548	, lat:62.6992805 },
        { lng:27.4155758	, lat:62.6991821 },
        { lng:27.4153612	, lat:62.6991722 },
        { lng:27.4149428	, lat:62.6993838 },
        { lng:27.414857	, lat:62.6994921 },
        { lng:27.4144493	, lat:62.6997184 },
        { lng:27.4141811	, lat:62.6998365 },
        { lng:27.4141167	, lat:62.6999153 },
        { lng:27.4140201	, lat:62.7000629 },
        { lng:27.4137353	, lat:62.7001123 },
        { lng:27.4135888	, lat:62.7002233 },
        { lng:27.4134322	, lat:62.700304 },
        { lng:27.4132503	, lat:62.7003636 },
        { lng:27.4127772	, lat:62.7004716 },
        { lng:27.4127648	, lat:62.7005304 },
        { lng:27.4127648	, lat:62.7005796 },
        { lng:27.4126039	, lat:62.7006042 },
        { lng:27.4123786	, lat:62.7006731 },
        { lng:27.4120246	, lat:62.7006878 },
        { lng:27.4115847	, lat:62.7006632 },
        { lng:27.4113701	, lat:62.7006337 },
        { lng:27.4111877	, lat:62.7006632 },
        { lng:27.4110804	, lat:62.7007616 },
        { lng:27.4109839	, lat:62.7008453 },
        { lng:27.4108229	, lat:62.7009043 },
        { lng:27.4105869	, lat:62.7009142 },
        { lng:27.4102972	, lat:62.7009339 },
        { lng:27.4100612	, lat:62.7009142 },
        { lng:27.4099968	, lat:62.7007616 },
        { lng:27.4097286	, lat:62.7007075 },
        { lng:27.4091492	, lat:62.7005894 },
        { lng:27.4085055	, lat:62.7004566 },
        { lng:27.4081407	, lat:62.7003434 },
        { lng:27.4077759	, lat:62.700304 },
        { lng:27.4072717	, lat:62.7002892 },
        { lng:27.4069713	, lat:62.7002843 },
        { lng:27.4068318	, lat:62.7002056 },
        { lng:27.4066494	, lat:62.7001416 },
        { lng:27.4065314	, lat:62.6999694 },
        { lng:27.4064134	, lat:62.6998218 },
        { lng:27.406216	, lat:62.6997096 },
        { lng:27.4003623	, lat:62.6976615 },
        { lng:27.389521	, lat:62.6988113 },
        { lng:27.3805536	, lat:62.6997869 },
        { lng:27.3685446	, lat:62.7046914 },
        { lng:27.3669257	, lat:62.7053476 },
        { lng:27.3616265	, lat:62.7093616 },
        { lng:27.36144	, lat:62.709513 },
        { lng:27.3611348	, lat:62.7096112 },
        { lng:27.3609186	, lat:62.709622 },
        { lng:27.3608006	, lat:62.7096712 },
        { lng:27.3606933	, lat:62.7096761 },
        { lng:27.3605324	, lat:62.7097302 },
        { lng:27.3603393	, lat:62.7097647 },
        { lng:27.360232	, lat:62.7097597 },
        { lng:27.3600496	, lat:62.709681 },
        { lng:27.3598779	, lat:62.7096023 },
        { lng:27.3597063	, lat:62.7095826 },
        { lng:27.3596634	, lat:62.7095433 },
        { lng:27.3596419	, lat:62.7094793 },
        { lng:27.3595221	, lat:62.7094046 },
        { lng:27.3594694	, lat:62.7093845 },
        { lng:27.3593917	, lat:62.709375 },
        { lng:27.3592922	, lat:62.7093873 },
        { lng:27.3591852	, lat:62.709412 },
        { lng:27.3590143	, lat:62.7094668 },
        { lng:27.3588145	, lat:62.7095125 },
        { lng:27.3587908	, lat:62.70953 },
        { lng:27.3587943	, lat:62.7095531 },
        { lng:27.3586888	, lat:62.7096659 },
        { lng:27.3584908	, lat:62.7097612 },
        { lng:27.3584765	, lat:62.7097788 },
        { lng:27.3584655	, lat:62.70981 },
        { lng:27.3584452	, lat:62.7098393 },
        { lng:27.358291	, lat:62.7099013 },
        { lng:27.3581943	, lat:62.7099069 },
        { lng:27.3581124	, lat:62.7099069 },
        { lng:27.3580387	, lat:62.7098916 },
        { lng:27.3578911	, lat:62.7098068 },
        { lng:27.3577858	, lat:62.7097843 },
        { lng:27.3573797	, lat:62.7097967 },
        { lng:27.3573004	, lat:62.7097713 },
        { lng:27.3572355	, lat:62.7097373 },
        { lng:27.3571703	, lat:62.7097133 },
        { lng:27.3570664	, lat:62.7097177 },
        { lng:27.356981	, lat:62.709717 },
        { lng:27.3568363	, lat:62.7097942 },
        { lng:27.3566522	, lat:62.7098791 },
        { lng:27.3563857	, lat:62.7099221 },
        { lng:27.3562677	, lat:62.7099811 },
        { lng:27.3562474	, lat:62.7100426 },
        { lng:27.3562087	, lat:62.7100669 },
        { lng:27.3562259	, lat:62.7100998 },
        { lng:27.356252	, lat:62.7101199 },
        { lng:27.3563134	, lat:62.7101412 },
        { lng:27.3564016	, lat:62.7101734 },
        { lng:27.3564455	, lat:62.7101915 },
        { lng:27.3564611	, lat:62.7102184 },
        { lng:27.3564518	, lat:62.7102767 },
        { lng:27.3563957	, lat:62.7103234 },
        { lng:27.3563382	, lat:62.7103445 },
        { lng:27.3563103	, lat:62.7103651 },
        { lng:27.3563041	, lat:62.710393 },
        { lng:27.3562843	, lat:62.7104202 },
        { lng:27.3562288	, lat:62.7104672 },
        { lng:27.3560773	, lat:62.7105356 },
        { lng:27.3558268	, lat:62.7105448 },
        { lng:27.3554469	, lat:62.7105419 },
        { lng:27.3553075	, lat:62.7105173 },
        { lng:27.3551465	, lat:62.710532 },
        { lng:27.3548032	, lat:62.7106107 },
        { lng:27.3547531	, lat:62.7106393 },
        { lng:27.3547581	, lat:62.7107093 },
        { lng:27.3547174	, lat:62.7107414 },
        { lng:27.3546377	, lat:62.7107532 },
        { lng:27.3545287	, lat:62.7107577 },
        { lng:27.3545061	, lat:62.7107731 },
        { lng:27.3545053	, lat:62.710804 },
        { lng:27.3543741	, lat:62.7108714 },
        { lng:27.3542842	, lat:62.7109001 },
        { lng:27.3541983	, lat:62.7108912 },
        { lng:27.353983	, lat:62.7108406 },
        { lng:27.353922	, lat:62.7108375 },
        { lng:27.3538054	, lat:62.7108714 },
        { lng:27.353679	, lat:62.7108592 },
        { lng:27.3536123	, lat:62.7108665 },
        { lng:27.3533655	, lat:62.7109354 },
        { lng:27.3531724	, lat:62.7109452 },
        { lng:27.353033	, lat:62.7109846 },
        { lng:27.3529967	, lat:62.7110115 },
        { lng:27.3530008	, lat:62.7110928 },
        { lng:27.352872	, lat:62.7111912 },
        { lng:27.3528184	, lat:62.7112551 },
        { lng:27.3526038	, lat:62.7113338 },
        { lng:27.3524858	, lat:62.7114568 },
        { lng:27.3525823	, lat:62.7115306 },
        { lng:27.3525931	, lat:62.7116142 },
        { lng:27.3524536	, lat:62.7116535 },
        { lng:27.3521854	, lat:62.7117027 },
        { lng:27.3517728	, lat:62.7117175 },
        { lng:27.3516918	, lat:62.7117765 },
        { lng:27.3515797	, lat:62.7119438 },
        { lng:27.3512198	, lat:62.7121061 },
        { lng:27.3510267	, lat:62.7121897 },
        { lng:27.3509408	, lat:62.7122586 },
        { lng:27.350855	, lat:62.7123323 },
        { lng:27.3508121	, lat:62.7123963 },
        { lng:27.3508228	, lat:62.7125045 },
        { lng:27.3507584	, lat:62.7126029 },
        { lng:27.3508121	, lat:62.7127258 },
        { lng:27.3507692	, lat:62.7128537 },
        { lng:27.3507584	, lat:62.7129668 },
        { lng:27.3506833	, lat:62.7130308 },
        { lng:27.3503615	, lat:62.7130554 },
        { lng:27.3501791	, lat:62.7130505 },
        { lng:27.3499645	, lat:62.7129373 },
        { lng:27.3498465	, lat:62.7128636 },
        { lng:27.3498358	, lat:62.7127996 },
        { lng:27.3496963	, lat:62.7127701 },
        { lng:27.3495246	, lat:62.7127553 },
        { lng:27.3494281	, lat:62.7127062 },
        { lng:27.3493803	, lat:62.7126324 },
        { lng:27.3492457	, lat:62.7125192 },
        { lng:27.3491014	, lat:62.7124553 },
        { lng:27.3490048	, lat:62.7123963 },
        { lng:27.3488702	, lat:62.7123815 },
        { lng:27.3487259	, lat:62.7124701 },
        { lng:27.34872	, lat:62.7126324 },
        { lng:27.3486019	, lat:62.7127012 },
        { lng:27.3484147	, lat:62.7126963 },
        { lng:27.3483337	, lat:62.7127553 },
        { lng:27.3480929	, lat:62.7127504 },
        { lng:27.3479319	, lat:62.7127258 },
        { lng:27.3477651	, lat:62.7127504 },
        { lng:27.3475934	, lat:62.712839 },
        { lng:27.3473097	, lat:62.7129177 },
        { lng:27.3469449	, lat:62.712957 },
        { lng:27.3465479	, lat:62.7129029 },
        { lng:27.3461939	, lat:62.7127947 },
        { lng:27.3460222	, lat:62.7127849 },
        { lng:27.3457325	, lat:62.7127701 },
        { lng:27.3454691	, lat:62.7126619 },
        { lng:27.3453355	, lat:62.7126225 },
        { lng:27.3451853	, lat:62.7126078 },
        { lng:27.3450459	, lat:62.7125635 },
        { lng:27.3448361	, lat:62.7124848 },
        { lng:27.3445786	, lat:62.7124602 },
        { lng:27.3444177	, lat:62.7123914 },
        { lng:27.3441661	, lat:62.712411 },
        { lng:27.3440481	, lat:62.7123077 },
        { lng:27.3438813	, lat:62.7122881 },
        { lng:27.3436189	, lat:62.7121897 },
        { lng:27.3431469	, lat:62.7121651 },
        { lng:27.3429108	, lat:62.7121258 },
        { lng:27.3427225	, lat:62.7119192 },
        { lng:27.3425461	, lat:62.7118601 },
        { lng:27.3422027	, lat:62.7118208 },
        { lng:27.3419023	, lat:62.7117618 },
        { lng:27.3414673	, lat:62.7117126 },
        { lng:27.3412264	, lat:62.7116486 },
        { lng:27.3410655	, lat:62.711629 },
        { lng:27.3409045	, lat:62.7116191 },
        { lng:27.3407597	, lat:62.7115591 },
        { lng:27.337621	, lat:62.7117805 },
        { lng:27.3288995	, lat:62.7124624 },
        { lng:27.3212217	, lat:62.7139071 },
        { lng:27.3178728	, lat:62.7132203 },
        { lng:27.3142702	, lat:62.7122964 },
        { lng:27.3072754	, lat:62.7105246 },
        { lng:27.3014335	, lat:62.7090293 },
        { lng:27.2914941	, lat:62.7064934 },
        { lng:27.2871134	, lat:62.7053705 },
        { lng:27.2757464	, lat:62.7024368 },
        { lng:27.2459144	, lat:62.7158168 },
        { lng:27.2235594	, lat:62.7258799 },
        { lng:27.2170559	, lat:62.7244921 },
        { lng:27.2168722	, lat:62.7245791 },
        { lng:27.2056599	, lat:62.7288507 },
        { lng:27.2026184	, lat:62.7326403 },
        { lng:27.2025626	, lat:62.7327271 },
        { lng:27.2024494	, lat:62.7328117 },
        { lng:27.2019817	, lat:62.7329297 },
        { lng:27.2016341	, lat:62.7330968 },
        { lng:27.2007157	, lat:62.7336866 },
        { lng:27.1997458	, lat:62.7340897 },
        { lng:27.199102	, lat:62.7343354 },
        { lng:27.1986085	, lat:62.7349547 },
        { lng:27.1982437	, lat:62.7350923 },
        { lng:27.1970464	, lat:62.7354266 },
        { lng:27.196334	, lat:62.7357608 },
        { lng:27.1957804	, lat:62.7358984 },
        { lng:27.1954757	, lat:62.7359967 },
        { lng:27.1951538	, lat:62.7361834 },
        { lng:27.1942526	, lat:62.7364685 },
        { lng:27.1935445	, lat:62.7365963 },
        { lng:27.1930724	, lat:62.7366553 },
        { lng:27.1923858	, lat:62.7370189 },
        { lng:27.1920253	, lat:62.7370681 },
        { lng:27.1915704	, lat:62.7373335 },
        { lng:27.1912915	, lat:62.7376087 },
        { lng:27.1912485	, lat:62.737992 },
        { lng:27.1903731	, lat:62.7384245 },
        { lng:27.1893388	, lat:62.7389061 },
        { lng:27.188738	, lat:62.7391125 },
        { lng:27.1879226	, lat:62.7394172 },
        { lng:27.1864206	, lat:62.7397808 },
        { lng:27.1856095	, lat:62.7399676 },
        { lng:27.1854163	, lat:62.7401052 },
        { lng:27.1851803	, lat:62.7401936 },
        { lng:27.1848756	, lat:62.7401739 },
        { lng:27.1845752	, lat:62.7402133 },
        { lng:27.1837856	, lat:62.7404 },
        { lng:27.1833779	, lat:62.7404884 },
        { lng:27.1828157	, lat:62.7406555 },
        { lng:27.1823436	, lat:62.7408816 },
        { lng:27.181893	, lat:62.7410978 },
        { lng:27.1817685	, lat:62.7412649 },
        { lng:27.1818115	, lat:62.7415499 },
        { lng:27.1817213	, lat:62.7417464 },
        { lng:27.1814853	, lat:62.7420019 },
        { lng:27.1810347	, lat:62.7422378 },
        { lng:27.180863	, lat:62.7424049 },
        { lng:27.1807815	, lat:62.7426505 },
        { lng:27.1804167	, lat:62.7427193 },
        { lng:27.1799446	, lat:62.7428373 },
        { lng:27.1790177	, lat:62.7429159 },
        { lng:27.1780735	, lat:62.7430633 },
        { lng:27.1777731	, lat:62.7432304 },
        { lng:27.177241	, lat:62.743309 },
        { lng:27.1768504	, lat:62.7434662 },
        { lng:27.176593	, lat:62.743594 },
        { lng:27.1763569	, lat:62.7436922 },
        { lng:27.1759106	, lat:62.7440558 },
        { lng:27.1756703	, lat:62.7441737 },
        { lng:27.1754825	, lat:62.7443605 },
        { lng:27.175048	, lat:62.7445472 },
        { lng:27.1748152	, lat:62.7445162 },
        { lng:27.1744375	, lat:62.7444268 },
        { lng:27.1740245	, lat:62.7444086 },
        { lng:27.1734172	, lat:62.7445472 },
        { lng:27.1730782	, lat:62.7445865 },
        { lng:27.1727134	, lat:62.7446258 },
        { lng:27.1723701	, lat:62.744557 },
        { lng:27.171898	, lat:62.7445766 },
        { lng:27.171383	, lat:62.7447142 },
        { lng:27.1707393	, lat:62.7449402 },
        { lng:27.1701814	, lat:62.7450975 },
        { lng:27.1696664	, lat:62.7452252 },
        { lng:27.1693016	, lat:62.7455691 },
        { lng:27.1690441	, lat:62.7457067 },
        { lng:27.1686364	, lat:62.7457657 },
        { lng:27.1682341	, lat:62.7456851 },
        { lng:27.167659	, lat:62.7455578 },
        { lng:27.1671773	, lat:62.7454807 },
        { lng:27.1669413	, lat:62.7454217 },
        { lng:27.1667267	, lat:62.7453529 },
        { lng:27.1664692	, lat:62.7453529 },
        { lng:27.1662976	, lat:62.7452252 },
        { lng:27.1659929	, lat:62.7451859 },
        { lng:27.165671	, lat:62.7452252 },
        { lng:27.1653963	, lat:62.7452645 },
        { lng:27.1649672	, lat:62.7452252 },
        { lng:27.1645166	, lat:62.7452645 },
        { lng:27.1640016	, lat:62.7453235 },
        { lng:27.1634866	, lat:62.745461 },
        { lng:27.1628429	, lat:62.7457558 },
        { lng:27.162521	, lat:62.7458541 },
        { lng:27.1619417	, lat:62.7460211 },
        { lng:27.1615554	, lat:62.7461882 },
        { lng:27.161255	, lat:62.7463945 },
        { lng:27.1609975	, lat:62.7466009 },
        { lng:27.1603752	, lat:62.746876 },
        { lng:27.1600534	, lat:62.747043 },
        { lng:27.1598817	, lat:62.7472297 },
        { lng:27.1597315	, lat:62.7474754 },
        { lng:27.1595169	, lat:62.7476326 },
        { lng:27.1593238	, lat:62.7476915 },
        { lng:27.1588303	, lat:62.747721 },
        { lng:27.1578218	, lat:62.7479175 },
        { lng:27.1568991	, lat:62.7480453 },
        { lng:27.1563197	, lat:62.7481435 },
        { lng:27.1559979	, lat:62.7483106 },
        { lng:27.1551997	, lat:62.7484383 },
        { lng:27.1548349	, lat:62.7485267 },
        { lng:27.1545817	, lat:62.7487134 },
        { lng:27.1542555	, lat:62.7488903 },
        { lng:27.1541954	, lat:62.749018 },
        { lng:27.1541268	, lat:62.749244 },
        { lng:27.1538907	, lat:62.7493815 },
        { lng:27.1537834	, lat:62.7495387 },
        { lng:27.1538693	, lat:62.749637 },
        { lng:27.1542598	, lat:62.749804 },
        { lng:27.1544272	, lat:62.7499317 },
        { lng:27.1545173	, lat:62.7501773 },
        { lng:27.1543886	, lat:62.750423 },
        { lng:27.1540238	, lat:62.750757 },
        { lng:27.1537234	, lat:62.7510026 },
        { lng:27.1535474	, lat:62.7512482 },
        { lng:27.1533114	, lat:62.7513858 },
        { lng:27.1527149	, lat:62.7514742 },
        { lng:27.1523715	, lat:62.7514447 },
        { lng:27.152157	, lat:62.7513759 },
        { lng:27.1519595	, lat:62.7513858 },
        { lng:27.1514875	, lat:62.7514742 },
        { lng:27.150848	, lat:62.7514545 },
        { lng:27.1501828	, lat:62.7513759 },
        { lng:27.149582	, lat:62.7513072 },
        { lng:27.1491915	, lat:62.7512974 },
        { lng:27.1488696	, lat:62.7512875 },
        { lng:27.1484405	, lat:62.7513367 },
        { lng:27.1480757	, lat:62.7513367 },
        { lng:27.1477968	, lat:62.7514054 },
        { lng:27.1474105	, lat:62.7515037 },
        { lng:27.1470243	, lat:62.7515921 },
        { lng:27.1466166	, lat:62.7518475 },
        { lng:27.146166	, lat:62.7519556 },
        { lng:27.1459085	, lat:62.7520637 },
        { lng:27.1455437	, lat:62.752211 },
        { lng:27.1453506	, lat:62.7522994 },
        { lng:27.1451575	, lat:62.7524173 },
        { lng:27.1444923	, lat:62.7525745 },
        { lng:27.1437885	, lat:62.7525942 },
        { lng:27.1435052	, lat:62.7525254 },
        { lng:27.1431834	, lat:62.7525057 },
        { lng:27.1430117	, lat:62.7524075 },
        { lng:27.1426469	, lat:62.7523289 },
        { lng:27.1421748	, lat:62.7522601 },
        { lng:27.1417285	, lat:62.7522896 },
        { lng:27.1413208	, lat:62.7523977 },
        { lng:27.1409947	, lat:62.7524271 },
        { lng:27.1404582	, lat:62.7525254 },
        { lng:27.1401192	, lat:62.7525843 },
        { lng:27.1396471	, lat:62.7526531 },
        { lng:27.1391751	, lat:62.7528889 },
        { lng:27.1386601	, lat:62.7529576 },
        { lng:27.1382095	, lat:62.7529478 },
        { lng:27.1378876	, lat:62.7529478 },
        { lng:27.1375657	, lat:62.7531148 },
        { lng:27.1372439	, lat:62.7532524 },
        { lng:27.1367933	, lat:62.7532474 },
        { lng:27.1366645	, lat:62.7533309 },
        { lng:27.1363963	, lat:62.7533997 },
        { lng:27.136407	, lat:62.7535078 },
        { lng:27.1362354	, lat:62.7535765 },
        { lng:27.136053	, lat:62.7535815 },
        { lng:27.1359242	, lat:62.7536011 },
        { lng:27.1359779	, lat:62.75366 },
        { lng:27.1358491	, lat:62.7536895 },
        { lng:27.1357418	, lat:62.7536158 },
        { lng:27.1355594	, lat:62.7536306 },
        { lng:27.1355165	, lat:62.7536944 },
        { lng:27.1355273	, lat:62.7538418 },
        { lng:27.1353985	, lat:62.7539105 },
        { lng:27.1351732	, lat:62.753994 },
        { lng:27.135023	, lat:62.7540874 },
        { lng:27.1348728	, lat:62.7540825 },
        { lng:27.1346797	, lat:62.7540432 },
        { lng:27.1344758	, lat:62.7540874 },
        { lng:27.1341218	, lat:62.7542593 },
        { lng:27.1337892	, lat:62.7543624 },
        { lng:27.1334351	, lat:62.7546178 },
        { lng:27.1330918	, lat:62.7547505 },
        { lng:27.1320189	, lat:62.7550353 },
        { lng:27.1315683	, lat:62.7551925 },
        { lng:27.1311928	, lat:62.7552465 },
        { lng:27.130989	, lat:62.7552072 },
        { lng:27.1308066	, lat:62.7551876 },
        { lng:27.1304418	, lat:62.7552269 },
        { lng:27.1298517	, lat:62.7554626 },
        { lng:27.1294118	, lat:62.7555461 },
        { lng:27.1290149	, lat:62.7556935 },
        { lng:27.1287681	, lat:62.7557672 },
        { lng:27.1286501	, lat:62.7558752 },
        { lng:27.1286608	, lat:62.7560668 },
        { lng:27.128575	, lat:62.7562583 },
        { lng:27.1284355	, lat:62.7563467 },
        { lng:27.1283068	, lat:62.7563713 },
        { lng:27.1281458	, lat:62.7565039 },
        { lng:27.1279371	, lat:62.7565677 },
        { lng:27.1278191	, lat:62.7566168 },
        { lng:27.127824	, lat:62.7567052 },
        { lng:27.1276201	, lat:62.7567396 },
        { lng:27.1274651	, lat:62.7568771 },
        { lng:27.1271647	, lat:62.7569557 },
        { lng:27.1269442	, lat:62.7569999 },
        { lng:27.1267892	, lat:62.7570687 },
        { lng:27.1268047	, lat:62.7571767 },
        { lng:27.1267892	, lat:62.7573142 },
        { lng:27.1266974	, lat:62.7574075 },
        { lng:27.126515	, lat:62.7574959 },
        { lng:27.126279	, lat:62.7575254 },
        { lng:27.1261395	, lat:62.7575745 },
        { lng:27.1259035	, lat:62.7575745 },
        { lng:27.1258928	, lat:62.7576826 },
        { lng:27.1257747	, lat:62.7578103 },
        { lng:27.1256031	, lat:62.7577906 },
        { lng:27.1252812	, lat:62.7577906 },
        { lng:27.1250345	, lat:62.7578004 },
        { lng:27.1247984	, lat:62.7579183 },
        { lng:27.124498	, lat:62.7579822 },
        { lng:27.1243478	, lat:62.7581049 },
        { lng:27.124144	, lat:62.7581933 },
        { lng:27.1237255	, lat:62.7583014 },
        { lng:27.1235539	, lat:62.7582965 },
        { lng:27.1234037	, lat:62.7582719 },
        { lng:27.1232749	, lat:62.7582916 },
        { lng:27.1231998	, lat:62.7583505 },
        { lng:27.122996	, lat:62.7583652 },
        { lng:27.1227599	, lat:62.758375 },
        { lng:27.1227707	, lat:62.7584487 },
        { lng:27.1225776	, lat:62.7584978 },
        { lng:27.1224381	, lat:62.7584536 },
        { lng:27.1223093	, lat:62.7584683 },
        { lng:27.1222342	, lat:62.7585567 },
        { lng:27.1221806	, lat:62.7586992 },
        { lng:27.1219553	, lat:62.7587777 },
        { lng:27.1217192	, lat:62.7589103 },
        { lng:27.121612	, lat:62.7590478 },
        { lng:27.1216441	, lat:62.7591952 },
        { lng:27.1215369	, lat:62.7593179 },
        { lng:27.1212794	, lat:62.7595488 },
        { lng:27.121097	, lat:62.7596224 },
        { lng:27.1208609	, lat:62.7596863 },
        { lng:27.1207966	, lat:62.7597796 },
        { lng:27.1207215	, lat:62.759868 },
        { lng:27.1205605	, lat:62.7599171 },
        { lng:27.1203138	, lat:62.7599514 },
        { lng:27.1200777	, lat:62.7601086 },
        { lng:27.1198846	, lat:62.7602952 },
        { lng:27.1197988	, lat:62.7604131 },
        { lng:27.1196164	, lat:62.7605064 },
        { lng:27.1196915	, lat:62.7605997 },
        { lng:27.1197451	, lat:62.7606635 },
        { lng:27.1196915	, lat:62.7608747 },
        { lng:27.1195413	, lat:62.7609483 },
        { lng:27.1193804	, lat:62.7609827 },
        { lng:27.1195735	, lat:62.7610416 },
        { lng:27.1196808	, lat:62.761076 },
        { lng:27.1198524	, lat:62.7610858 },
        { lng:27.1198739	, lat:62.7611447 },
        { lng:27.1197881	, lat:62.7612184 },
        { lng:27.1197988	, lat:62.7613264 },
        { lng:27.1196057	, lat:62.7614394 },
        { lng:27.1195413	, lat:62.7615081 },
        { lng:27.1193804	, lat:62.7615376 },
        { lng:27.1193589	, lat:62.7618519 },
        { lng:27.1194077	, lat:62.7619879 },
        { lng:27.1196314	, lat:62.7621025 },
        { lng:27.1170678	, lat:62.7632667 },
        { lng:27.1016771	, lat:62.7630241 },
        { lng:27.1011872	, lat:62.7630164 },
        { lng:27.1000449	, lat:62.7629984 },
        { lng:27.0827623	, lat:62.7627499 },
        { lng:27.0824709	, lat:62.7627457 },
        { lng:27.081629	, lat:62.7627326 },
        { lng:27.080858	, lat:62.7627214 },
        { lng:27.0793442	, lat:62.7627014 },
        { lng:27.0783717	, lat:62.7626885 },
        { lng:27.0746192	, lat:62.7626387 },
        { lng:27.0743917	, lat:62.7625753 },
        { lng:27.0747436	, lat:62.7625407 },
        { lng:27.074941	, lat:62.7624619 },
        { lng:27.0752736	, lat:62.7623735 },
        { lng:27.0755097	, lat:62.7622606 },
        { lng:27.0756169	, lat:62.7621476 },
        { lng:27.0757135	, lat:62.7620445 },
        { lng:27.0759495	, lat:62.7619316 },
        { lng:27.0772263	, lat:62.7613521 },
        { lng:27.0788125	, lat:62.7606556 },
        { lng:27.0792326	, lat:62.7604584 },
        { lng:27.0798656	, lat:62.7602914 },
        { lng:27.0802733	, lat:62.7602276 },
        { lng:27.0806702	, lat:62.7601981 },
        { lng:27.0811316	, lat:62.7601736 },
        { lng:27.0815071	, lat:62.7601097 },
        { lng:27.0818504	, lat:62.7600017 },
        { lng:27.0820006	, lat:62.7599035 },
        { lng:27.0820435	, lat:62.7597905 },
        { lng:27.0820086	, lat:62.7595941 },
        { lng:27.0800645	, lat:62.75887 },
        { lng:27.0797802	, lat:62.7587641 },
        { lng:27.0786983	, lat:62.7583611 },
        { lng:27.0669442	, lat:62.7539823 },
        { lng:27.0658371	, lat:62.7535691 },
        { lng:27.0590409	, lat:62.7510332 },
        { lng:27.051402	, lat:62.748184 },
        { lng:27.0491918	, lat:62.7491469 },
        { lng:27.0449637	, lat:62.7499777 },
        { lng:27.0436544	, lat:62.7502349 },
        { lng:27.0434412	, lat:62.7502767 },
        { lng:27.0416441	, lat:62.7498606 },
        { lng:27.0361992	, lat:62.7543095 },
        { lng:27.0348903	, lat:62.7540443 },
        { lng:27.0327767	, lat:62.7551986 },
        { lng:27.0302983	, lat:62.7565247 },
        { lng:27.0250627	, lat:62.7571386 },
        { lng:27.022874	, lat:62.7574038 },
        { lng:27.0215114	, lat:62.7574873 },
        { lng:27.0208339	, lat:62.757574 },
        { lng:27.0203452	, lat:62.757632 },
        { lng:27.0199021	, lat:62.7576542 },
        { lng:27.0194729	, lat:62.7575757 },
        { lng:27.0177778	, lat:62.7565639 },
        { lng:27.0120057	, lat:62.7560237 },
        { lng:27.0072421	, lat:62.7589606 },
        { lng:27.005254	, lat:62.7601797 },
        { lng:26.9993876	, lat:62.7637762 },
        { lng:26.9991525	, lat:62.7639202 },
        { lng:26.9934598	, lat:62.7673832 },
        { lng:26.9839802	, lat:62.7732858 },
        { lng:26.9794758	, lat:62.77609 },
        { lng:26.9790104	, lat:62.7763426 },
        { lng:26.9789137	, lat:62.776395 },
        { lng:26.9787463	, lat:62.7765219 },
        { lng:26.9769437	, lat:62.7784707 },
        { lng:26.9770692	, lat:62.778824 },
        { lng:26.9774411	, lat:62.7795792 },
        { lng:26.978375	, lat:62.781497 },
        { lng:26.978705	, lat:62.785236 },
        { lng:26.9811158	, lat:62.7887813 },
        { lng:26.9811988	, lat:62.7888378 },
        { lng:26.9811494	, lat:62.7889433 },
        { lng:26.98123	, lat:62.7890399 },
        { lng:26.9811485	, lat:62.7891093 },
        { lng:26.9705639	, lat:62.8164128 },
        { lng:26.9581217	, lat:62.8231912 },
        { lng:26.9089472	, lat:62.8499494 },
      ],
    },
    {
      id: 15,
      regionName: 'eCom TP Lahti kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [     
        { lng:25.5403066	, lat:60.9982644 },
        { lng:25.5405794	, lat:60.9982941 },
        { lng:25.5449454	, lat:60.998769 },
        { lng:25.5458593	, lat:60.9988708 },
        { lng:25.5492712	, lat:60.9992621 },
        { lng:25.5573476	, lat:61.0001351 },
        { lng:25.5586554	, lat:61.0002734 },
        { lng:25.5729049	, lat:61.0017811 },
        { lng:25.5730505	, lat:61.0017965 },
        { lng:25.5731262	, lat:61.0018796 },
        { lng:25.5742487	, lat:61.0031117 },
        { lng:25.5743087	, lat:61.0031775 },
        { lng:25.5743537	, lat:61.003227 },
        { lng:25.5743856	, lat:61.0032619 },
        { lng:25.5744307	, lat:61.0033114 },
        { lng:25.5744932	, lat:61.0033801 },
        { lng:25.5745641	, lat:61.0034579 },
        { lng:25.5746864	, lat:61.0035921 },
        { lng:25.5747576	, lat:61.0036703 },
        { lng:25.5748268	, lat:61.0037462 },
        { lng:25.5749543	, lat:61.0038862 },
        { lng:25.5750457	, lat:61.0039865 },
        { lng:25.5751015	, lat:61.0040478 },
        { lng:25.5751684	, lat:61.0041211 },
        { lng:25.5753361	, lat:61.0043053 },
        { lng:25.5754759	, lat:61.0044587 },
        { lng:25.5757654	, lat:61.0047764 },
        { lng:25.5758368	, lat:61.0048548 },
        { lng:25.5758657	, lat:61.0048866 },
        { lng:25.5758937	, lat:61.0049174 },
        { lng:25.5759999	, lat:61.0050338 },
        { lng:25.5760332	, lat:61.0050703 },
        { lng:25.5761093	, lat:61.0051539 },
        { lng:25.5764705	, lat:61.0055504 },
        { lng:25.5764962	, lat:61.0055786 },
        { lng:25.5765351	, lat:61.0056213 },
        { lng:25.5766015	, lat:61.0056942 },
        { lng:25.5766412	, lat:61.0057378 },
        { lng:25.576681	, lat:61.0057815 },
        { lng:25.5767259	, lat:61.0058307 },
        { lng:25.576772	, lat:61.0058813 },
        { lng:25.5768128	, lat:61.0059261 },
        { lng:25.5772236	, lat:61.0063769 },
        { lng:25.5773069	, lat:61.0064683 },
        { lng:25.5774075	, lat:61.0065787 },
        { lng:25.5778104	, lat:61.0070209 },
        { lng:25.578965	, lat:61.008288 },
        { lng:25.579476	, lat:61.00884 },
        { lng:25.579846	, lat:61.009248 },
        { lng:25.581697	, lat:61.011278 },
        { lng:25.581997	, lat:61.011614 },
        { lng:25.582296	, lat:61.01194 },
        { lng:25.582596	, lat:61.012266 },
        { lng:25.5828959	, lat:61.012592 },
        { lng:25.5829462	, lat:61.0126484 },
        { lng:25.5831949	, lat:61.012928 },
        { lng:25.5839719	, lat:61.013762 },
        { lng:25.5832229	, lat:61.014795 },
        { lng:25.5828859	, lat:61.014899 },
        { lng:25.5822859	, lat:61.015072 },
        { lng:25.5818819	, lat:61.015436 },
        { lng:25.5823379	, lat:61.015575 },
        { lng:25.5825019	, lat:61.015622 },
        { lng:25.5825749	, lat:61.015641 },
        { lng:25.581173	, lat:61.016802 },
        { lng:25.5810496	, lat:61.0169388 },
        { lng:25.581171	, lat:61.0169705 },
        { lng:25.5812209	, lat:61.016982 },
        { lng:25.581029	, lat:61.017132 },
        { lng:25.5808759	, lat:61.017239 },
        { lng:25.5807049	, lat:61.017344 },
        { lng:25.5804979	, lat:61.017423 },
        { lng:25.580272	, lat:61.01751 },
        { lng:25.58001	, lat:61.017588 },
        { lng:25.579879	, lat:61.017632 },
        { lng:25.579729	, lat:61.017675 },
        { lng:25.579486	, lat:61.017727 },
        { lng:25.579262	, lat:61.017778 },
        { lng:25.579094	, lat:61.017812 },
        { lng:25.57887	, lat:61.017846 },
        { lng:25.578553	, lat:61.017905 },
        { lng:25.578292	, lat:61.017947 },
        { lng:25.57805	, lat:61.01799 },
        { lng:25.577826	, lat:61.018032 },
        { lng:25.57764	, lat:61.018057 },
        { lng:25.577435	, lat:61.018091 },
        { lng:25.5771939	, lat:61.018115 },
        { lng:25.5769889	, lat:61.01814 },
        { lng:25.5767479	, lat:61.018164 },
        { lng:25.5766539	, lat:61.018181 },
        { lng:25.5766519	, lat:61.018226 },
        { lng:25.5775579	, lat:61.021126 },
        { lng:25.5950829	, lat:61.02595 },
        { lng:25.598633	, lat:61.029784 },
        { lng:25.598485	, lat:61.029773 },
        { lng:25.598393	, lat:61.029772 },
        { lng:25.598264	, lat:61.029762 },
        { lng:25.598134	, lat:61.029752 },
        { lng:25.598005	, lat:61.02975 },
        { lng:25.597876	, lat:61.02974 },
        { lng:25.597728	, lat:61.029729 },
        { lng:25.597599	, lat:61.029719 },
        { lng:25.597451	, lat:61.029718 },
        { lng:25.597303	, lat:61.029716 },
        { lng:25.597193	, lat:61.029715 },
        { lng:25.597045	, lat:61.029704 },
        { lng:25.596879	, lat:61.029703 },
        { lng:25.596749	, lat:61.029701 },
        { lng:25.596601	, lat:61.029709 },
        { lng:25.596489	, lat:61.029717 },
        { lng:25.59636	, lat:61.029706 },
        { lng:25.596268	, lat:61.029705 },
        { lng:25.596175	, lat:61.029713 },
        { lng:25.596045	, lat:61.029721 },
        { lng:25.595897	, lat:61.029719 },
        { lng:25.595749	, lat:61.029718 },
        { lng:25.595601	, lat:61.029725 },
        { lng:25.595471	, lat:61.029724 },
        { lng:25.59536	, lat:61.029732 },
        { lng:25.595249	, lat:61.02974 },
        { lng:25.595192	, lat:61.029757 },
        { lng:25.595137	, lat:61.029765 },
        { lng:25.595007	, lat:61.029773 },
        { lng:25.594876	, lat:61.029798 },
        { lng:25.59469	, lat:61.029823 },
        { lng:25.594542	, lat:61.029831 },
        { lng:25.594337	, lat:61.029865 },
        { lng:25.594132	, lat:61.029889 },
        { lng:25.593927	, lat:61.029914 },
        { lng:25.593796	, lat:61.029949 },
        { lng:25.593703	, lat:61.029975 },
        { lng:25.593591	, lat:61.030001 },
        { lng:25.593478	, lat:61.030035 },
        { lng:25.593384	, lat:61.030061 },
        { lng:25.593235	, lat:61.030087 },
        { lng:25.593031	, lat:61.030111 },
        { lng:25.592881	, lat:61.030137 },
        { lng:25.592787	, lat:61.030172 },
        { lng:25.592692	, lat:61.030225 },
        { lng:25.59256	, lat:61.030286 },
        { lng:25.592484	, lat:61.03033 },
        { lng:25.592408	, lat:61.030383 },
        { lng:25.592295	, lat:61.030436 },
        { lng:25.592201	, lat:61.030453 },
        { lng:25.592162	, lat:61.030497 },
        { lng:25.592104	, lat:61.030568 },
        { lng:25.59199	, lat:61.030621 },
        { lng:25.591879	, lat:61.030638 },
        { lng:25.591748	, lat:61.030654 },
        { lng:25.5916	, lat:61.030662 },
        { lng:25.591562	, lat:61.030688 },
        { lng:25.591504	, lat:61.030733 },
        { lng:25.591429	, lat:61.030759 },
        { lng:25.5913856	, lat:61.0307553 },
        { lng:25.591202	, lat:61.030864 },
        { lng:25.591182	, lat:61.030909 },
        { lng:25.591162	, lat:61.030945 },
        { lng:25.591216	, lat:61.030981 },
        { lng:25.591214	, lat:61.031017 },
        { lng:25.591157	, lat:61.031043 },
        { lng:25.591082	, lat:61.031069 },
        { lng:25.591062	, lat:61.031105 },
        { lng:25.591042	, lat:61.03115 },
        { lng:25.59102	, lat:61.031212 },
        { lng:25.590982	, lat:61.031248 },
        { lng:25.590944	, lat:61.031274 },
        { lng:25.586142	, lat:61.031072 },
        { lng:25.586383	, lat:61.032726 },
        { lng:25.589215	, lat:61.032701 },
        { lng:25.589401	, lat:61.033511 },
        { lng:25.590351	, lat:61.033368 },
        { lng:25.590387	, lat:61.033405 },
        { lng:25.590422	, lat:61.033441 },
        { lng:25.590494	, lat:61.033478 },
        { lng:25.590549	, lat:61.033505 },
        { lng:25.590621	, lat:61.033542 },
        { lng:25.59073	, lat:61.033579 },
        { lng:25.590747	, lat:61.033624 },
        { lng:25.590782	, lat:61.033678 },
        { lng:25.590817	, lat:61.033714 },
        { lng:25.590852	, lat:61.033751 },
        { lng:25.590925	, lat:61.033778 },
        { lng:25.590981	, lat:61.03377 },
        { lng:25.590961	, lat:61.033797 },
        { lng:25.590978	, lat:61.033833 },
        { lng:25.591051	, lat:61.033869 },
        { lng:25.591123	, lat:61.033906 },
        { lng:25.591196	, lat:61.033934 },
        { lng:25.591268	, lat:61.033979 },
        { lng:25.591377	, lat:61.034025 },
        { lng:25.591467	, lat:61.034071 },
        { lng:25.591558	, lat:61.034117 },
        { lng:25.591629	, lat:61.034181 },
        { lng:25.591738	, lat:61.034236 },
        { lng:25.591829	, lat:61.034255 },
        { lng:25.585087	, lat:61.038968 },
        { lng:25.591965	, lat:61.03913 },
        { lng:25.59694	, lat:61.039621 },
        { lng:25.597655	, lat:61.038094 },
        { lng:25.597692	, lat:61.038085 },
        { lng:25.597712	, lat:61.038068 },
        { lng:25.597788	, lat:61.038015 },
        { lng:25.597828	, lat:61.037943 },
        { lng:25.597868	, lat:61.03789 },
        { lng:25.597926	, lat:61.037837 },
        { lng:25.5979406	, lat:61.0378231 },
        { lng:25.597964	, lat:61.037801 },
        { lng:25.598001	, lat:61.037801 },
        { lng:25.598057	, lat:61.037784 },
        { lng:25.598115	, lat:61.037731 },
        { lng:25.598173	, lat:61.037678 },
        { lng:25.598194	, lat:61.037633 },
        { lng:25.598233	, lat:61.037588 },
        { lng:25.598308	, lat:61.037562 },
        { lng:25.598329	, lat:61.0375 },
        { lng:25.598387	, lat:61.037446 },
        { lng:25.598444	, lat:61.037411 },
        { lng:25.598501	, lat:61.037376 },
        { lng:25.598539	, lat:61.037349 },
        { lng:25.598559	, lat:61.037323 },
        { lng:25.598615	, lat:61.037305 },
        { lng:25.5986	, lat:61.037233 },
        { lng:25.598603	, lat:61.037161 },
        { lng:25.598642	, lat:61.037108 },
        { lng:25.598663	, lat:61.037063 },
        { lng:25.598663	, lat:61.037054 },
        { lng:25.598702	, lat:61.037019 },
        { lng:25.598667	, lat:61.036974 },
        { lng:25.59865	, lat:61.036938 },
        { lng:25.598652	, lat:61.036884 },
        { lng:25.598637	, lat:61.036821 },
        { lng:25.598601	, lat:61.036784 },
        { lng:25.598546	, lat:61.036775 },
        { lng:25.598509	, lat:61.036774 },
        { lng:25.59849	, lat:61.036783 },
        { lng:25.598435	, lat:61.036774 },
        { lng:25.598417	, lat:61.036756 },
        { lng:25.598362	, lat:61.036755 },
        { lng:25.598345	, lat:61.036728 },
        { lng:25.598308	, lat:61.03671 },
        { lng:25.598309	, lat:61.036692 },
        { lng:25.598329	, lat:61.036656 },
        { lng:25.598312	, lat:61.036638 },
        { lng:25.598257	, lat:61.036619 },
        { lng:25.598961	, lat:61.034921 },
        { lng:25.599252	, lat:61.034206 },
        { lng:25.599309	, lat:61.034162 },
        { lng:25.599423	, lat:61.034091 },
        { lng:25.599649	, lat:61.034004 },
        { lng:25.617855	, lat:61.037037 },
        { lng:25.619414	, lat:61.034809 },
        { lng:25.619506	, lat:61.03481 },
        { lng:25.619579	, lat:61.034838 },
        { lng:25.619616	, lat:61.034847 },
        { lng:25.619636	, lat:61.034802 },
        { lng:25.619655	, lat:61.034784 },
        { lng:25.619728	, lat:61.034821 },
        { lng:25.619799	, lat:61.034876 },
        { lng:25.619872	, lat:61.034912 },
        { lng:25.619982	, lat:61.03494 },
        { lng:25.620092	, lat:61.034959 },
        { lng:25.620166	, lat:61.03496 },
        { lng:25.620202	, lat:61.034979 },
        { lng:25.620331	, lat:61.034998 },
        { lng:25.620405	, lat:61.03499 },
        { lng:25.62048	, lat:61.034972 },
        { lng:25.620519	, lat:61.034919 },
        { lng:25.620539	, lat:61.034883 },
        { lng:25.620595	, lat:61.034875 },
        { lng:25.620669	, lat:61.034876 },
        { lng:25.620726	, lat:61.03484 },
        { lng:25.6208	, lat:61.03485 },
        { lng:25.620873	, lat:61.034878 },
        { lng:25.620909	, lat:61.034896 },
        { lng:25.620907	, lat:61.034932 },
        { lng:25.620943	, lat:61.03495 },
        { lng:25.620982	, lat:61.034924 },
        { lng:25.621003	, lat:61.034852 },
        { lng:25.621042	, lat:61.034817 },
        { lng:25.621117	, lat:61.034781 },
        { lng:25.621137	, lat:61.034764 },
        { lng:25.621175	, lat:61.034728 },
        { lng:25.621232	, lat:61.034693 },
        { lng:25.621215	, lat:61.034666 },
        { lng:25.621272	, lat:61.03463 },
        { lng:25.621348	, lat:61.034586 },
        { lng:25.621443	, lat:61.034533 },
        { lng:25.621519	, lat:61.034489 },
        { lng:25.621558	, lat:61.034436 },
        { lng:25.621617	, lat:61.034365 },
        { lng:25.621693	, lat:61.034303 },
        { lng:25.621733	, lat:61.034249 },
        { lng:25.621808	, lat:61.034205 },
        { lng:25.621847	, lat:61.034161 },
        { lng:25.62194	, lat:61.034153 },
        { lng:25.621978	, lat:61.034126 },
        { lng:25.624198	, lat:61.034149 },
        { lng:25.624232	, lat:61.033359 },
        { lng:25.622383	, lat:61.033331 },
        { lng:25.622646	, lat:61.033235 },
        { lng:25.622667	, lat:61.033191 },
        { lng:25.622651	, lat:61.033137 },
        { lng:25.622616	, lat:61.033082 },
        { lng:25.622543	, lat:61.033046 },
        { lng:25.622415	, lat:61.033027 },
        { lng:25.622212	, lat:61.032998 },
        { lng:25.631507	, lat:61.031584 },
        { lng:25.631828	, lat:61.031866 },
        { lng:25.633168	, lat:61.032104 },
        { lng:25.633205	, lat:61.032104 },
        { lng:25.633242	, lat:61.032113 },
        { lng:25.635538	, lat:61.032505 },
        { lng:25.635703	, lat:61.032533 },
        { lng:25.636273	, lat:61.032629 },
        { lng:25.636916	, lat:61.032734 },
        { lng:25.636914	, lat:61.033192 },
        { lng:25.636917	, lat:61.03356 },
        { lng:25.636913	, lat:61.034089 },
        { lng:25.638296	, lat:61.034633 },
        { lng:25.639006	, lat:61.034909 },
        { lng:25.639261	, lat:61.034562 },
        { lng:25.639399	, lat:61.034366 },
        { lng:25.639773	, lat:61.033849 },
        { lng:25.639911	, lat:61.033644 },
        { lng:25.640029	, lat:61.033475 },
        { lng:25.640206	, lat:61.033234 },
        { lng:25.640226	, lat:61.033198 },
        { lng:25.640403	, lat:61.032958 },
        { lng:25.640442	, lat:61.032913 },
        { lng:25.640501	, lat:61.032824 },
        { lng:25.640502	, lat:61.032806 },
        { lng:25.640504	, lat:61.032752 },
        { lng:25.640558	, lat:61.032367 },
        { lng:25.640566	, lat:61.032169 },
        { lng:25.640597	, lat:61.031883 },
        { lng:25.640598	, lat:61.031865 },
        { lng:25.64063	, lat:61.031542 },
        { lng:25.64064	, lat:61.031327 },
        { lng:25.640706	, lat:61.030645 },
        { lng:25.640708	, lat:61.030582 },
        { lng:25.640712	, lat:61.030502 },
        { lng:25.640731	, lat:61.030493 },
        { lng:25.640845	, lat:61.02913 },
        { lng:25.640284	, lat:61.028837 },
        { lng:25.641928	, lat:61.027148 },
        { lng:25.647509	, lat:61.029035 },
        { lng:25.647546	, lat:61.029044 },
        { lng:25.647639	, lat:61.029027 },
        { lng:25.647733	, lat:61.029001 },
        { lng:25.647808	, lat:61.028966 },
        { lng:25.647883	, lat:61.028949 },
        { lng:25.647958	, lat:61.028914 },
        { lng:25.648033	, lat:61.028888 },
        { lng:25.648109	, lat:61.028853 },
        { lng:25.648165	, lat:61.028826 },
        { lng:25.648241	, lat:61.028791 },
        { lng:25.648298	, lat:61.028765 },
        { lng:25.648392	, lat:61.02873 },
        { lng:25.648429	, lat:61.028712 },
        { lng:25.649836	, lat:61.029139 },
        { lng:25.6499446	, lat:61.0291688 },
        { lng:25.6501104	, lat:61.0292253 },
        { lng:25.650201	, lat:61.02925 },
        { lng:25.650329	, lat:61.029288 },
        { lng:25.650494	, lat:61.029334 },
        { lng:25.65316	, lat:61.030142 },
        { lng:25.660375	, lat:61.027988 },
        { lng:25.665302	, lat:61.02608 },
        { lng:25.668086	, lat:61.027615 },
        { lng:25.668648	, lat:61.027908 },
        { lng:25.66892	, lat:61.028036 },
        { lng:25.669373	, lat:61.028265 },
        { lng:25.66939	, lat:61.028283 },
        { lng:25.669499	, lat:61.028329 },
        { lng:25.669536	, lat:61.028348 },
        { lng:25.669898	, lat:61.028531 },
        { lng:25.67026	, lat:61.028714 },
        { lng:25.670333	, lat:61.02875 },
        { lng:25.670623	, lat:61.028897 },
        { lng:25.670985	, lat:61.029071 },
        { lng:25.671329	, lat:61.029254 },
        { lng:25.671347	, lat:61.029263 },
        { lng:25.671674	, lat:61.029419 },
        { lng:25.671837	, lat:61.02951 },
        { lng:25.672543	, lat:61.029867 },
        { lng:25.672616	, lat:61.029895 },
        { lng:25.673316	, lat:61.029964 },
        { lng:25.673464	, lat:61.029984 },
        { lng:25.673666	, lat:61.030004 },
        { lng:25.673922	, lat:61.0300311 },
        { lng:25.674035	, lat:61.030043 },
        { lng:25.674385	, lat:61.030074 },
        { lng:25.675545	, lat:61.030193 },
        { lng:25.676872	, lat:61.030322 },
        { lng:25.677609	, lat:61.030402 },
        { lng:25.678269	, lat:61.030094 },
        { lng:25.679909	, lat:61.0293584 },
        { lng:25.6801462	, lat:61.0292284 },
        { lng:25.6833655	, lat:61.0277438 },
        { lng:25.683647	, lat:61.027579 },
        { lng:25.683665	, lat:61.027571 },
        { lng:25.685269	, lat:61.026823 },
        { lng:25.686162	, lat:61.027604 },
        { lng:25.6874271	, lat:61.0287235 },
        { lng:25.689874	, lat:61.030889 },
        { lng:25.691008	, lat:61.031654 },
        { lng:25.691998	, lat:61.032328 },
        { lng:25.6940054	, lat:61.0336815 },
        { lng:25.6956381	, lat:61.0347861 },
        { lng:25.6970858	, lat:61.0357597 },
        { lng:25.6975583	, lat:61.0360775 },
        { lng:25.697886	, lat:61.036298 },
        { lng:25.707175	, lat:61.032663 },
        { lng:25.711274	, lat:61.03105 },
        { lng:25.712121	, lat:61.030717 },
        { lng:25.712722	, lat:61.030481 },
        { lng:25.713154	, lat:61.030314 },
        { lng:25.714038	, lat:61.029964 },
        { lng:25.716089	, lat:61.030046 },
        { lng:25.71655	, lat:61.030069 },
        { lng:25.732653	, lat:61.030822 },
        { lng:25.733743	, lat:61.030868 },
        { lng:25.734593	, lat:61.030912 },
        { lng:25.735885	, lat:61.030969 },
        { lng:25.7369	, lat:61.031042 },
        { lng:25.737011	, lat:61.031043 },
        { lng:25.737917	, lat:61.031069 },
        { lng:25.738489	, lat:61.031092 },
        { lng:25.742553	, lat:61.031265 },
        { lng:25.743403	, lat:61.0313 },
        { lng:25.751288	, lat:61.031678 },
        { lng:25.7667882	, lat:61.0324357 },
        { lng:25.7817698	, lat:61.0357058 },
        { lng:25.8079829	, lat:61.0410747 },
        { lng:25.8161595	, lat:61.0469985 },
        { lng:25.8161421	, lat:61.0470668 },
        { lng:25.8160875	, lat:61.0471488 },
        { lng:25.8159852	, lat:61.0472196 },
        { lng:25.8155758	, lat:61.0474649 },
        { lng:25.8152493	, lat:61.0475971 },
        { lng:25.8150689	, lat:61.047649 },
        { lng:25.814835	, lat:61.0476891 },
        { lng:25.8146595	, lat:61.0477575 },
        { lng:25.8145475	, lat:61.0478165 },
        { lng:25.8144695	, lat:61.0478943 },
        { lng:25.8144451	, lat:61.0479887 },
        { lng:25.8145182	, lat:61.0480807 },
        { lng:25.8146293	, lat:61.0481619 },
        { lng:25.8148155	, lat:61.0482293 },
        { lng:25.8150787	, lat:61.0482576 },
        { lng:25.8153614	, lat:61.0482364 },
        { lng:25.8155758	, lat:61.0481774 },
        { lng:25.8157171	, lat:61.0481114 },
        { lng:25.8158731	, lat:61.0480099 },
        { lng:25.8160485	, lat:61.0479627 },
        { lng:25.8162922	, lat:61.047958 },
        { lng:25.8164872	, lat:61.0479769 },
        { lng:25.8166919	, lat:61.0480264 },
        { lng:25.816804	, lat:61.0480878 },
        { lng:25.8168771	, lat:61.0481609 },
        { lng:25.8169453	, lat:61.0482175 },
        { lng:25.8170866	, lat:61.0482576 },
        { lng:25.8176763	, lat:61.0485006 },
        { lng:25.8178177	, lat:61.0485832 },
        { lng:25.8179541	, lat:61.0487106 },
        { lng:25.818037	, lat:61.0487885 },
        { lng:25.8180857	, lat:61.0488569 },
        { lng:25.8181052	, lat:61.0489678 },
        { lng:25.8181393	, lat:61.0490338 },
        { lng:25.8182563	, lat:61.0491211 },
        { lng:25.8184513	, lat:61.0491942 },
        { lng:25.8187242	, lat:61.0492556 },
        { lng:25.8189191	, lat:61.0493287 },
        { lng:25.8191531	, lat:61.0494325 },
        { lng:25.8194211	, lat:61.0495009 },
        { lng:25.8196715	, lat:61.0495425 },
        { lng:25.822195	, lat:61.0513702 },
        { lng:25.7875878	, lat:61.0567978 },
        { lng:25.7497691	, lat:61.0626847 },
        { lng:25.7470135	, lat:61.0630869 },
        { lng:25.7467496	, lat:61.0631547 },
        { lng:25.7304354	, lat:61.0656285 },
        { lng:25.7237145	, lat:61.0634998 },
        { lng:25.7250153	, lat:61.0647969 },
        { lng:25.7242373	, lat:61.065606 },
        { lng:25.7210195	, lat:61.0650371 },
        { lng:25.7205425	, lat:61.0623947 },
        { lng:25.7167152	, lat:61.0611299 },
        { lng:25.7151692	, lat:61.0627345 },
        { lng:25.7165226	, lat:61.0634518 },
        { lng:25.7214335	, lat:61.0659487 },
        { lng:25.7459919	, lat:61.0853628 },
        { lng:25.7522916	, lat:61.0884088 },
        { lng:25.7834679	, lat:61.0962836 },
        { lng:25.7844305	, lat:61.0964027 },
        { lng:25.7903552	, lat:61.0971348 },
        { lng:25.7940946	, lat:61.0975968 },
        { lng:25.858059	, lat:61.1026647 },
        { lng:25.8944866	, lat:61.0852383 },
        { lng:25.9063416	, lat:61.0793558 },
        { lng:25.9153176	, lat:61.078582 },
        { lng:25.9224303	, lat:61.0778137 },
        { lng:25.9368491	, lat:61.070922 },
        { lng:25.9919326	, lat:61.0859371 },
        { lng:25.996027	, lat:61.0824381 },
        { lng:26.0066225	, lat:61.0735148 },
        { lng:26.0092439	, lat:61.0712975 },
        { lng:26.0106377	, lat:61.073044 },
        { lng:26.0122058	, lat:61.0749662 },
        { lng:26.0282216	, lat:61.0950708 },
        { lng:26.051031	, lat:61.1009871 },
        { lng:26.0459053	, lat:61.09392 },
        { lng:26.107299	, lat:61.0765373 },
        { lng:26.1072456	, lat:61.0759464 },
        { lng:26.1050094	, lat:61.0512326 },
        { lng:26.104974	, lat:61.0511977 },
        { lng:26.1049466	, lat:61.0511709 },
        { lng:26.1048082	, lat:61.051106 },
        { lng:26.1047189	, lat:61.0509915 },
        { lng:26.1047323	, lat:61.0508532 },
        { lng:26.1046743	, lat:61.0507278 },
        { lng:26.1042521	, lat:61.0503819 },
        { lng:26.1041247	, lat:61.0502277 },
        { lng:26.1039269	, lat:61.0500826 },
        { lng:26.1039017	, lat:61.0500409 },
        { lng:26.1039722	, lat:61.0499618 },
        { lng:26.104008	, lat:61.0499215 },
        { lng:26.1040975	, lat:61.0498208 },
        { lng:26.1041038	, lat:61.0496772 },
        { lng:26.1040569	, lat:61.0495328 },
        { lng:26.1039333	, lat:61.0494816 },
        { lng:26.1037754	, lat:61.0494174 },
        { lng:26.1034307	, lat:61.0492251 },
        { lng:26.1033516	, lat:61.0491814 },
        { lng:26.1031503	, lat:61.0491331 },
        { lng:26.1029735	, lat:61.0490353 },
        { lng:26.1028662	, lat:61.0489131 },
        { lng:26.102837	, lat:61.0487748 },
        { lng:26.1029735	, lat:61.0486135 },
        { lng:26.1031454	, lat:61.0485095 },
        { lng:26.1036807	, lat:61.0484057 },
        { lng:26.1041669	, lat:61.0484057 },
        { lng:26.1047352	, lat:61.0483568 },
        { lng:26.1054263	, lat:61.0483964 },
        { lng:26.1058846	, lat:61.0484351 },
        { lng:26.1063074	, lat:61.0485035 },
        { lng:26.1065095	, lat:61.0485494 },
        { lng:26.1068126	, lat:61.0486349 },
        { lng:26.1071479	, lat:61.04868 },
        { lng:26.1078102	, lat:61.048638 },
        { lng:26.1082711	, lat:61.0486838 },
        { lng:26.1086437	, lat:61.0487939 },
        { lng:26.1087952	, lat:61.0488 },
        { lng:26.1091172	, lat:61.048748 },
        { lng:26.1095277	, lat:61.0486594 },
        { lng:26.1097613	, lat:61.0486349 },
        { lng:26.1101149	, lat:61.0486319 },
        { lng:26.1102794	, lat:61.0485794 },
        { lng:26.1104811	, lat:61.0483385 },
        { lng:26.1105	, lat:61.0481123 },
        { lng:26.1105884	, lat:61.0479442 },
        { lng:26.1107147	, lat:61.0478372 },
        { lng:26.1111441	, lat:61.0476813 },
        { lng:26.1116057	, lat:61.0474803 },
        { lng:26.1119081	, lat:61.0473909 },
        { lng:26.1122116	, lat:61.047347 },
        { lng:26.1124613	, lat:61.0472594 },
        { lng:26.1129563	, lat:61.047015 },
        { lng:26.1142065	, lat:61.0468071 },
        { lng:26.1150778	, lat:61.046532 },
        { lng:26.1153178	, lat:61.0464281 },
        { lng:26.1155325	, lat:61.0462752 },
        { lng:26.1156166	, lat:61.0462318 },
        { lng:26.1157377	, lat:61.0462089 },
        { lng:26.1159693	, lat:61.0462123 },
        { lng:26.1166661	, lat:61.0462605 },
        { lng:26.11721	, lat:61.0462853 },
        { lng:26.1175473	, lat:61.0463458 },
        { lng:26.1178847	, lat:61.046447 },
        { lng:26.1182509	, lat:61.0465601 },
        { lng:26.1186251	, lat:61.0466544 },
        { lng:26.1190023	, lat:61.0466762 },
        { lng:26.1192928	, lat:61.0466548 },
        { lng:26.1195769	, lat:61.0465998 },
        { lng:26.1197982	, lat:61.0465218 },
        { lng:26.1199033	, lat:61.0463235 },
        { lng:26.1201553	, lat:61.0460879 },
        { lng:26.1201467	, lat:61.046037 },
        { lng:26.1200855	, lat:61.046003 },
        { lng:26.1198878	, lat:61.045991 },
        { lng:26.1197041	, lat:61.045945 },
        { lng:26.1195459	, lat:61.0458926 },
        { lng:26.1194361	, lat:61.045796 },
        { lng:26.1194443	, lat:61.0456461 },
        { lng:26.1196878	, lat:61.0454622 },
        { lng:26.1197923	, lat:61.0453047 },
        { lng:26.1198382	, lat:61.0451107 },
        { lng:26.1197474	, lat:61.044998 },
        { lng:26.1198484	, lat:61.0447932 },
        { lng:26.1199331	, lat:61.044695 },
        { lng:26.1198108	, lat:61.0445816 },
        { lng:26.1190023	, lat:61.0441666 },
        { lng:26.1187624	, lat:61.0439923 },
        { lng:26.1186334	, lat:61.0437896 },
        { lng:26.1182699	, lat:61.0435491 },
        { lng:26.1179036	, lat:61.0431272 },
        { lng:26.1179163	, lat:61.0430172 },
        { lng:26.1180804	, lat:61.0427756 },
        { lng:26.1180776	, lat:61.0426471 },
        { lng:26.117992	, lat:61.0425372 },
        { lng:26.1177647	, lat:61.0423813 },
        { lng:26.1176826	, lat:61.0422621 },
        { lng:26.1177142	, lat:61.0421367 },
        { lng:26.1178594	, lat:61.0419197 },
        { lng:26.1179352	, lat:61.0417485 },
        { lng:26.1179668	, lat:61.0416017 },
        { lng:26.11791	, lat:61.0415008 },
        { lng:26.1179352	, lat:61.0414275 },
        { lng:26.1180741	, lat:61.0412624 },
        { lng:26.1181751	, lat:61.0409597 },
        { lng:26.1183456	, lat:61.0408588 },
        { lng:26.1184782	, lat:61.0407426 },
        { lng:26.1185324	, lat:61.0405163 },
        { lng:26.1187687	, lat:61.0403421 },
        { lng:26.1191689	, lat:61.0401441 },
        { lng:26.1192928	, lat:61.0400609 },
        { lng:26.1193073	, lat:61.04001 },
        { lng:26.1192403	, lat:61.0398976 },
        { lng:26.119278	, lat:61.0398191 },
        { lng:26.1193433	, lat:61.0397612 },
        { lng:26.1197726	, lat:61.0395656 },
        { lng:26.11988	, lat:61.0394677 },
        { lng:26.1198204	, lat:61.0392664 },
        { lng:26.1198077	, lat:61.0390616 },
        { lng:26.1199242	, lat:61.0389541 },
        { lng:26.1200856	, lat:61.0389362 },
        { lng:26.120616	, lat:61.0389423 },
        { lng:26.1209317	, lat:61.0389179 },
        { lng:26.1211029	, lat:61.0388466 },
        { lng:26.1212411	, lat:61.03871 },
        { lng:26.1212411	, lat:61.0384348 },
        { lng:26.1212853	, lat:61.0383125 },
        { lng:26.1213863	, lat:61.0381382 },
        { lng:26.1212347	, lat:61.0376735 },
        { lng:26.1212284	, lat:61.0372821 },
        { lng:26.121196	, lat:61.0370663 },
        { lng:26.1213686	, lat:61.0369332 },
        { lng:26.1215082	, lat:61.0368859 },
        { lng:26.1219118	, lat:61.0368393 },
        { lng:26.1221484	, lat:61.0367473 },
        { lng:26.1225861	, lat:61.0365578 },
        { lng:26.1226942	, lat:61.0364851 },
        { lng:26.1226582	, lat:61.0362328 },
        { lng:26.1222275	, lat:61.0359009 },
        { lng:26.1222201	, lat:61.0357787 },
        { lng:26.1223113	, lat:61.0356 },
        { lng:26.1227152	, lat:61.0355145 },
        { lng:26.1227549	, lat:61.0353409 },
        { lng:26.122868	, lat:61.0353136 },
        { lng:26.1232158	, lat:61.0353336 },
        { lng:26.123602	, lat:61.0352827 },
        { lng:26.123863	, lat:61.0350659 },
        { lng:26.1243502	, lat:61.0347519 },
        { lng:26.1245254	, lat:61.0346134 },
        { lng:26.1246285	, lat:61.0345142 },
        { lng:26.1249745	, lat:61.0343659 },
        { lng:26.1248765	, lat:61.0341806 },
        { lng:26.1244899	, lat:61.0338327 },
        { lng:26.1244552	, lat:61.0337149 },
        { lng:26.1244718	, lat:61.0336095 },
        { lng:26.1245531	, lat:61.0335437 },
        { lng:26.1246541	, lat:61.0335253 },
        { lng:26.1249509	, lat:61.0335804 },
        { lng:26.125134	, lat:61.0335957 },
        { lng:26.125247	, lat:61.0335808 },
        { lng:26.1253171	, lat:61.0335315 },
        { lng:26.1252982	, lat:61.0333342 },
        { lng:26.125346	, lat:61.0332813 },
        { lng:26.1254655	, lat:61.0332593 },
        { lng:26.1257938	, lat:61.0332547 },
        { lng:26.1259712	, lat:61.0332176 },
        { lng:26.126078	, lat:61.0331721 },
        { lng:26.1261001	, lat:61.0331079 },
        { lng:26.1260906	, lat:61.0329627 },
        { lng:26.1261222	, lat:61.0329 },
        { lng:26.1261896	, lat:61.0328472 },
        { lng:26.1265073	, lat:61.0327242 },
        { lng:26.1265831	, lat:61.0326737 },
        { lng:26.1266075	, lat:61.032625 },
        { lng:26.1265326	, lat:61.0325697 },
        { lng:26.12628	, lat:61.032504 },
        { lng:26.1261853	, lat:61.0324489 },
        { lng:26.1261496	, lat:61.0323846 },
        { lng:26.1261916	, lat:61.0323205 },
        { lng:26.1264473	, lat:61.0322104 },
        { lng:26.1266106	, lat:61.0321162 },
        { lng:26.1267283	, lat:61.0319872 },
        { lng:26.1267757	, lat:61.0318144 },
        { lng:26.1267757	, lat:61.0315972 },
        { lng:26.126945	, lat:61.0314796 },
        { lng:26.1270227	, lat:61.0314256 },
        { lng:26.1329231	, lat:61.0277283 },
        { lng:26.1211223	, lat:61.0079231 },
        { lng:26.1167137	, lat:60.9845494 },
        { lng:26.0761774	, lat:60.971395 },
        { lng:26.0755388	, lat:60.9716291 },
        { lng:26.0748059	, lat:60.9717302 },
        { lng:26.0744149	, lat:60.9718283 },
        { lng:26.0728373	, lat:60.9723422 },
        { lng:26.0721607	, lat:60.9724809 },
        { lng:26.0711757	, lat:60.9724993 },
        { lng:26.0708916	, lat:60.9725422 },
        { lng:26.0704099	, lat:60.972685 },
        { lng:26.0698393	, lat:60.9729878 },
        { lng:26.069407	, lat:60.9731067 },
        { lng:26.0683912	, lat:60.973109 },
        { lng:26.0680439	, lat:60.9731212 },
        { lng:26.0674934	, lat:60.9731751 },
        { lng:26.0667397	, lat:60.973406 },
        { lng:26.0664061	, lat:60.9735796 },
        { lng:26.0660846	, lat:60.9738049 },
        { lng:26.0657274	, lat:60.9742165 },
        { lng:26.0655489	, lat:60.9746065 },
        { lng:26.0652452	, lat:60.9749921 },
        { lng:26.0649756	, lat:60.975281 },
        { lng:26.0643255	, lat:60.9758066 },
        { lng:26.0634683	, lat:60.9763178 },
        { lng:26.0630111	, lat:60.9766068 },
        { lng:26.062861	, lat:60.9767727 },
        { lng:26.0628521	, lat:60.9772146 },
        { lng:26.0629057	, lat:60.9774312 },
        { lng:26.0628789	, lat:60.9776305 },
        { lng:26.062736	, lat:60.9779034 },
        { lng:26.0624681	, lat:60.978276 },
        { lng:26.0623419	, lat:60.9785183 },
        { lng:26.0622788	, lat:60.9787729 },
        { lng:26.0624519	, lat:60.9790282 },
        { lng:26.0627912	, lat:60.9793227 },
        { lng:26.0630323	, lat:60.979574 },
        { lng:26.063202	, lat:60.9796693 },
        { lng:26.0636395	, lat:60.9798556 },
        { lng:26.0638984	, lat:60.9800158 },
        { lng:26.0642467	, lat:60.9803017 },
        { lng:26.0646217	, lat:60.9805963 },
        { lng:26.0652111	, lat:60.9808302 },
        { lng:26.0659612	, lat:60.9810728 },
        { lng:26.0661914	, lat:60.9811913 },
        { lng:26.0662813	, lat:60.9813375 },
        { lng:26.0663005	, lat:60.9815233 },
        { lng:26.0664028	, lat:60.981911 },
        { lng:26.0664521	, lat:60.9822768 },
        { lng:26.0664177	, lat:60.982474 },
        { lng:26.0663017	, lat:60.9826776 },
        { lng:26.0663106	, lat:60.9828292 },
        { lng:26.0662838	, lat:60.9832537 },
        { lng:26.0663463	, lat:60.9835309 },
        { lng:26.066382	, lat:60.983886 },
        { lng:26.0662078	, lat:60.9840879 },
        { lng:26.0659207	, lat:60.9842342 },
        { lng:26.065873	, lat:60.9842585 },
        { lng:26.0654087	, lat:60.9843971 },
        { lng:26.0648015	, lat:60.9845487 },
        { lng:26.0643371	, lat:60.984696 },
        { lng:26.0639264	, lat:60.9848346 },
        { lng:26.0633638	, lat:60.9851421 },
        { lng:26.0629173	, lat:60.985298 },
        { lng:26.0624084	, lat:60.9854193 },
        { lng:26.0619573	, lat:60.9854479 },
        { lng:26.0611798	, lat:60.9853755 },
        { lng:26.0605268	, lat:60.9852832 },
        { lng:26.0601875	, lat:60.9851658 },
        { lng:26.0599303	, lat:60.9850451 },
        { lng:26.0596353	, lat:60.9848408 },
        { lng:26.0594206	, lat:60.9845223 },
        { lng:26.0591852	, lat:60.9843077 },
        { lng:26.0586692	, lat:60.9840874 },
        { lng:26.058064	, lat:60.9839615 },
        { lng:26.0576905	, lat:60.9839312 },
        { lng:26.0568381	, lat:60.9839894 },
        { lng:26.0563832	, lat:60.9840768 },
        { lng:26.0560741	, lat:60.9842191 },
        { lng:26.0556606	, lat:60.9847052 },
        { lng:26.0554363	, lat:60.9848163 },
        { lng:26.055108	, lat:60.9849205 },
        { lng:26.0543882	, lat:60.9850368 },
        { lng:26.0540914	, lat:60.9850583 },
        { lng:26.0537882	, lat:60.9850488 },
        { lng:26.0534726	, lat:60.9849664 },
        { lng:26.0526202	, lat:60.9846632 },
        { lng:26.0518688	, lat:60.9844029 },
        { lng:26.0513574	, lat:60.9841762 },
        { lng:26.0510732	, lat:60.9839833 },
        { lng:26.0509154	, lat:60.9838301 },
        { lng:26.0507386	, lat:60.9835759 },
        { lng:26.050587	, lat:60.9834259 },
        { lng:26.050366	, lat:60.9833187 },
        { lng:26.0501135	, lat:60.9832237 },
        { lng:26.0499559	, lat:60.9831523 },
        { lng:26.0497851	, lat:60.9830522 },
        { lng:26.0495578	, lat:60.9829726 },
        { lng:26.0493305	, lat:60.9829297 },
        { lng:26.0490716	, lat:60.9828929 },
        { lng:26.0477056	, lat:60.9828058 },
        { lng:26.0477672	, lat:60.9827017 },
        { lng:26.049341	, lat:60.9800356 },
        { lng:26.0499649	, lat:60.9789787 },
        { lng:26.0495138	, lat:60.9782475 },
        { lng:26.0492865	, lat:60.9779871 },
        { lng:26.0490235	, lat:60.9777912 },
        { lng:26.0489392	, lat:60.97769 },
        { lng:26.0489582	, lat:60.9773867 },
        { lng:26.0490087	, lat:60.9772887 },
        { lng:26.0492297	, lat:60.9771386 },
        { lng:26.049457	, lat:60.977013 },
        { lng:26.0495833	, lat:60.9768966 },
        { lng:26.0497348	, lat:60.9768139 },
        { lng:26.0497521	, lat:60.9767034 },
        { lng:26.0496843	, lat:60.9765382 },
        { lng:26.0495517	, lat:60.9763482 },
        { lng:26.0493939	, lat:60.9760817 },
        { lng:26.0490466	, lat:60.9758458 },
        { lng:26.0485713	, lat:60.9756074 },
        { lng:26.0483583	, lat:60.9753495 },
        { lng:26.0482636	, lat:60.9750003 },
        { lng:26.0481255	, lat:60.974902 },
        { lng:26.0470628	, lat:60.9743322 },
        { lng:26.0421985	, lat:60.972922 },
        { lng:26.0551892	, lat:60.9465039 },
        { lng:26.060197	, lat:60.9360889 },
        { lng:26.0664129	, lat:60.9357188 },
        { lng:26.1048775	, lat:60.9334283 },
        { lng:26.1317273	, lat:60.9317308 },
        { lng:26.1689722	, lat:60.9293759 },
        { lng:26.1602052	, lat:60.9197679 },
        { lng:26.1602291	, lat:60.9188392 },
        { lng:26.1547938	, lat:60.9156768 },
        { lng:26.1542313	, lat:60.9153455 },
        { lng:26.1532621	, lat:60.9147747 },
        { lng:26.1497469	, lat:60.9126907 },
        { lng:26.1470144	, lat:60.9111192 },
        { lng:26.1423621	, lat:60.9085447 },
        { lng:26.1399228	, lat:60.9072315 },
        { lng:26.1224377	, lat:60.9081145 },
        { lng:26.1125495	, lat:60.898308 },
        { lng:26.1131841	, lat:60.8967773 },
        { lng:26.1037756	, lat:60.8885528 },
        { lng:26.1034625	, lat:60.8886985 },
        { lng:26.1032461	, lat:60.8887696 },
        { lng:26.1026823	, lat:60.8882349 },
        { lng:26.0857474	, lat:60.8917372 },
        { lng:26.0837495	, lat:60.896104 },
        { lng:26.0831852	, lat:60.8961472 },
        { lng:26.0803754	, lat:60.8963161 },
        { lng:26.0745208	, lat:60.8967856 },
        { lng:26.0740633	, lat:60.8967917 },
        { lng:26.0514604	, lat:60.8984888 },
        { lng:26.014908	, lat:60.9042162 },
        { lng:25.9774302	, lat:60.9237583 },
        { lng:25.9689047	, lat:60.925199 },
        { lng:25.9645854	, lat:60.9258113 },
        { lng:25.9592023	, lat:60.9285332 },
        { lng:25.9501223	, lat:60.9285657 },
        { lng:25.946609	, lat:60.9192623 },
        { lng:25.9446177	, lat:60.9136473 },
        { lng:25.9205044	, lat:60.8885391 },
        { lng:25.9195343	, lat:60.8874119 },
        { lng:25.9186551	, lat:60.8866527 },
        { lng:25.876387	, lat:60.8924645 },
        { lng:25.8262793	, lat:60.8969973 },
        { lng:25.8157587	, lat:60.8960276 },
        { lng:25.7776714	, lat:60.89105 },
        { lng:25.7795172	, lat:60.9061364 },
        { lng:25.780035	, lat:60.9062229 },
        { lng:25.7796202	, lat:60.9066668 },
        { lng:25.7809247	, lat:60.9189213 },
        { lng:25.7778028	, lat:60.9234967 },
        { lng:25.7684052	, lat:60.921248 },
        { lng:25.7682852	, lat:60.9212805 },
        { lng:25.7676957	, lat:60.9211109 },
        { lng:25.767736	, lat:60.9210611 },
        { lng:25.7667237	, lat:60.9206416 },
        { lng:25.7656512	, lat:60.9203199 },
        { lng:25.7628212	, lat:60.9195505 },
        { lng:25.7608228	, lat:60.9188301 },
        { lng:25.7577775	, lat:60.9182383 },
        { lng:25.7566472	, lat:60.9175762 },
        { lng:25.756364	, lat:60.9174326 },
        { lng:25.7554222	, lat:60.917079 },
        { lng:25.7544384	, lat:60.9166723 },
        { lng:25.7539005	, lat:60.9161644 },
        { lng:25.7533789	, lat:60.9157956 },
        { lng:25.746675	, lat:60.9138906 },
        { lng:25.740915	, lat:60.9186584 },
        { lng:25.7323494	, lat:60.9298361 },
        { lng:25.728292	, lat:60.934555 },
        { lng:25.726938	, lat:60.933357 },
        { lng:25.722875	, lat:60.929782 },
        { lng:25.722769	, lat:60.929683 },
        { lng:25.722483	, lat:60.929438 },
        { lng:25.722038	, lat:60.929047 },
        { lng:25.719543	, lat:60.92687 },
        { lng:25.71949	, lat:60.926824 },
        { lng:25.719436	, lat:60.926779 },
        { lng:25.718083	, lat:60.925581 },
        { lng:25.716231	, lat:60.923948 },
        { lng:25.714574	, lat:60.922505 },
        { lng:25.71429	, lat:60.922233 },
        { lng:25.714237	, lat:60.922188 },
        { lng:25.712562	, lat:60.920727 },
        { lng:25.712417	, lat:60.920662 },
        { lng:25.712254	, lat:60.920589 },
        { lng:25.712091	, lat:60.920525 },
        { lng:25.71191	, lat:60.920451 },
        { lng:25.711764	, lat:60.920396 },
        { lng:25.711545	, lat:60.92034 },
        { lng:25.711344	, lat:60.920302 },
        { lng:25.711124	, lat:60.920264 },
        { lng:25.710923	, lat:60.920226 },
        { lng:25.710867	, lat:60.920235 },
        { lng:25.71081	, lat:60.920279 },
        { lng:25.710772	, lat:60.920306 },
        { lng:25.710626	, lat:60.920268 },
        { lng:25.710589	, lat:60.920277 },
        { lng:25.710534	, lat:60.920276 },
        { lng:25.710497	, lat:60.920285 },
        { lng:25.710459	, lat:60.920303 },
        { lng:25.710441	, lat:60.920302 },
        { lng:25.710423	, lat:60.920293 },
        { lng:25.710369	, lat:60.920257 },
        { lng:25.710296	, lat:60.920238 },
        { lng:25.71026	, lat:60.92022 },
        { lng:25.710205	, lat:60.920201 },
        { lng:25.710132	, lat:60.920183 },
        { lng:25.710078	, lat:60.920155 },
        { lng:25.710024	, lat:60.920119 },
        { lng:25.70997	, lat:60.920091 },
        { lng:25.709934	, lat:60.920073 },
        { lng:25.709879	, lat:60.920055 },
        { lng:25.709824	, lat:60.920054 },
        { lng:25.70975	, lat:60.920062 },
        { lng:25.709713	, lat:60.920062 },
        { lng:25.709677	, lat:60.920044 },
        { lng:25.709604	, lat:60.920025 },
        { lng:25.709567	, lat:60.920034 },
        { lng:25.709511	, lat:60.920051 },
        { lng:25.709436	, lat:60.920077 },
        { lng:25.709342	, lat:60.920121 },
        { lng:25.709286	, lat:60.920139 },
        { lng:25.709249	, lat:60.920147 },
        { lng:25.709193	, lat:60.920147 },
        { lng:25.709158	, lat:60.920111 },
        { lng:25.709142	, lat:60.920057 },
        { lng:25.709125	, lat:60.920003 },
        { lng:25.709074	, lat:60.919903 },
        { lng:25.709005	, lat:60.919795 },
        { lng:25.708898	, lat:60.919695 },
        { lng:25.708844	, lat:60.919668 },
        { lng:25.708827	, lat:60.919632 },
        { lng:25.708791	, lat:60.919604 },
        { lng:25.708737	, lat:60.919586 },
        { lng:25.708719	, lat:60.919568 },
        { lng:25.708739	, lat:60.919532 },
        { lng:25.708758	, lat:60.919514 },
        { lng:25.708795	, lat:60.919497 },
        { lng:25.708796	, lat:60.919479 },
        { lng:25.708761	, lat:60.919443 },
        { lng:25.708707	, lat:60.919415 },
        { lng:25.708692	, lat:60.919307 },
        { lng:25.708694	, lat:60.91928 },
        { lng:25.708695	, lat:60.919253 },
        { lng:25.708696	, lat:60.919218 },
        { lng:25.708642	, lat:60.919181 },
        { lng:25.708552	, lat:60.919135 },
        { lng:25.708479	, lat:60.919108 },
        { lng:25.708407	, lat:60.91908 },
        { lng:25.708389	, lat:60.919053 },
        { lng:25.708336	, lat:60.918999 },
        { lng:25.708283	, lat:60.918953 },
        { lng:25.708265	, lat:60.918926 },
        { lng:25.708286	, lat:60.918873 },
        { lng:25.708306	, lat:60.918846 },
        { lng:25.708252	, lat:60.918791 },
        { lng:25.708199	, lat:60.918755 },
        { lng:25.708144	, lat:60.918728 },
        { lng:25.708128	, lat:60.918683 },
        { lng:25.708055	, lat:60.918664 },
        { lng:25.707982	, lat:60.918645 },
        { lng:25.70789	, lat:60.918635 },
        { lng:25.707872	, lat:60.918617 },
        { lng:25.707875	, lat:60.918545 },
        { lng:25.707859	, lat:60.9185 },
        { lng:25.707842	, lat:60.918446 },
        { lng:25.707863	, lat:60.918393 },
        { lng:25.707884	, lat:60.91833 },
        { lng:25.707849	, lat:60.918285 },
        { lng:25.707796	, lat:60.918222 },
        { lng:25.70778	, lat:60.918177 },
        { lng:25.707745	, lat:60.918122 },
        { lng:25.707691	, lat:60.918077 },
        { lng:25.707675	, lat:60.918023 },
        { lng:25.707677	, lat:60.917969 },
        { lng:25.707698	, lat:60.917915 },
        { lng:25.707663	, lat:60.917861 },
        { lng:25.707627	, lat:60.917843 },
        { lng:25.707594	, lat:60.917753 },
        { lng:25.707595	, lat:60.917726 },
        { lng:25.707615	, lat:60.917699 },
        { lng:25.70756	, lat:60.917672 },
        { lng:25.707524	, lat:60.917654 },
        { lng:25.707434	, lat:60.917617 },
        { lng:25.707361	, lat:60.91758 },
        { lng:25.707307	, lat:60.917562 },
        { lng:25.707234	, lat:60.917534 },
        { lng:25.707198	, lat:60.917507 },
        { lng:25.707199	, lat:60.91748 },
        { lng:25.70722	, lat:60.917426 },
        { lng:25.707221	, lat:60.917399 },
        { lng:25.707204	, lat:60.917372 },
        { lng:25.707205	, lat:60.917336 },
        { lng:25.70717	, lat:60.9173 },
        { lng:25.707116	, lat:60.917273 },
        { lng:25.707042	, lat:60.917263 },
        { lng:25.706988	, lat:60.917235 },
        { lng:25.706953	, lat:60.91719 },
        { lng:25.706936	, lat:60.917154 },
        { lng:25.706901	, lat:60.917109 },
        { lng:25.706846	, lat:60.917091 },
        { lng:25.70681	, lat:60.917081 },
        { lng:25.706774	, lat:60.917063 },
        { lng:25.706775	, lat:60.917027 },
        { lng:25.70674	, lat:60.917 },
        { lng:25.706685	, lat:60.916972 },
        { lng:25.706593	, lat:60.91698 },
        { lng:25.706519	, lat:60.916989 },
        { lng:25.706446	, lat:60.916952 },
        { lng:25.706429	, lat:60.916916 },
        { lng:25.706412	, lat:60.916898 },
        { lng:25.706376	, lat:60.916871 },
        { lng:25.706321	, lat:60.916861 },
        { lng:25.706229	, lat:60.916869 },
        { lng:25.706155	, lat:60.916877 },
        { lng:25.706099	, lat:60.916877 },
        { lng:25.706026	, lat:60.916867 },
        { lng:25.705972	, lat:60.91684 },
        { lng:25.705917	, lat:60.916812 },
        { lng:25.705808	, lat:60.916793 },
        { lng:25.705716	, lat:60.916783 },
        { lng:25.705643	, lat:60.916774 },
        { lng:25.705532	, lat:60.916764 },
        { lng:25.705441	, lat:60.916745 },
        { lng:25.705367	, lat:60.916744 },
        { lng:25.705294	, lat:60.916726 },
        { lng:25.705259	, lat:60.91668 },
        { lng:25.705223	, lat:60.916653 },
        { lng:25.705169	, lat:60.916635 },
        { lng:25.705041	, lat:60.916606 },
        { lng:25.704968	, lat:60.916579 },
        { lng:25.70497	, lat:60.916543 },
        { lng:25.705008	, lat:60.916507 },
        { lng:25.705028	, lat:60.916481 },
        { lng:25.704991	, lat:60.916462 },
        { lng:25.704918	, lat:60.916462 },
        { lng:25.704881	, lat:60.916452 },
        { lng:25.704826	, lat:60.916452 },
        { lng:25.704789	, lat:60.916442 },
        { lng:25.704715	, lat:60.91646 },
        { lng:25.704641	, lat:60.916459 },
        { lng:25.704567	, lat:60.916476 },
        { lng:25.704547	, lat:60.916512 },
        { lng:25.704528	, lat:60.916521 },
        { lng:25.704436	, lat:60.916511 },
        { lng:25.704364	, lat:60.916474 },
        { lng:25.70431	, lat:60.916447 },
        { lng:25.704275	, lat:60.916393 },
        { lng:25.70422	, lat:60.916383 },
        { lng:25.704146	, lat:60.9164 },
        { lng:25.704091	, lat:60.9164 },
        { lng:25.703999	, lat:60.91639 },
        { lng:25.703926	, lat:60.916371 },
        { lng:25.703853	, lat:60.916344 },
        { lng:25.703818	, lat:60.916316 },
        { lng:25.703764	, lat:60.91628 },
        { lng:25.703747	, lat:60.916244 },
        { lng:25.703711	, lat:60.916208 },
        { lng:25.703658	, lat:60.916153 },
        { lng:25.703604	, lat:60.916135 },
        { lng:25.703549	, lat:60.916107 },
        { lng:25.703496	, lat:60.916071 },
        { lng:25.703441	, lat:60.916052 },
        { lng:25.703387	, lat:60.916016 },
        { lng:25.703333	, lat:60.91598 },
        { lng:25.703298	, lat:60.915943 },
        { lng:25.703189	, lat:60.915915 },
        { lng:25.703115	, lat:60.915915 },
        { lng:25.703059	, lat:60.915932 },
        { lng:25.702948	, lat:60.915949 },
        { lng:25.702856	, lat:60.915939 },
        { lng:25.702801	, lat:60.915921 },
        { lng:25.702747	, lat:60.915893 },
        { lng:25.702692	, lat:60.915875 },
        { lng:25.7026	, lat:60.915874 },
        { lng:25.702453	, lat:60.915872 },
        { lng:25.70236	, lat:60.915889 },
        { lng:25.702304	, lat:60.915898 },
        { lng:25.702249	, lat:60.915897 },
        { lng:25.702174	, lat:60.915924 },
        { lng:25.702101	, lat:60.915914 },
        { lng:25.701973	, lat:60.915877 },
        { lng:25.701863	, lat:60.915867 },
        { lng:25.701753	, lat:60.915866 },
        { lng:25.701678	, lat:60.915883 },
        { lng:25.701474	, lat:60.915926 },
        { lng:25.701269	, lat:60.91596 },
        { lng:25.701083	, lat:60.916003 },
        { lng:25.700897	, lat:60.916046 },
        { lng:25.700711	, lat:60.916089 },
        { lng:25.700507	, lat:60.916132 },
        { lng:25.700321	, lat:60.916175 },
        { lng:25.700116	, lat:60.916209 },
        { lng:25.699707	, lat:60.916295 },
        { lng:25.699521	, lat:60.916338 },
        { lng:25.699335	, lat:60.916381 },
        { lng:25.69913	, lat:60.916424 },
        { lng:25.698926	, lat:60.916467 },
        { lng:25.698814	, lat:60.916492 },
        { lng:25.69874	, lat:60.91651 },
        { lng:25.698554	, lat:60.916553 },
        { lng:25.698403	, lat:60.916632 },
        { lng:25.698252	, lat:60.916711 },
        { lng:25.698065	, lat:60.916772 },
        { lng:25.697897	, lat:60.916843 },
        { lng:25.697728	, lat:60.916913 },
        { lng:25.697559	, lat:60.916983 },
        { lng:25.69739	, lat:60.917053 },
        { lng:25.69724	, lat:60.917132 },
        { lng:25.697035	, lat:60.917175 },
        { lng:25.696884	, lat:60.917264 },
        { lng:25.696752	, lat:60.917343 },
        { lng:25.696564	, lat:60.917413 },
        { lng:25.688847	, lat:60.914538 },
        { lng:25.6874909	, lat:60.9140261 },
        { lng:25.6872836	, lat:60.913947 },
        { lng:25.6871311	, lat:60.9138888 },
        { lng:25.680187	, lat:60.911321 },
        { lng:25.666064	, lat:60.906119 },
        { lng:25.6658684	, lat:60.9060461 },
        { lng:25.66561	, lat:60.905953 },
        { lng:25.663686	, lat:60.905243 },
        { lng:25.66307	, lat:60.905434 },
        { lng:25.661987	, lat:60.904894 },
        { lng:25.660458	, lat:60.9053 },
        { lng:25.659667	, lat:60.905705 },
        { lng:25.658104	, lat:60.906516 },
        { lng:25.657563	, lat:60.906672 },
        { lng:25.657515	, lat:60.906492 },
        { lng:25.657499	, lat:60.9064379 },
        { lng:25.657294	, lat:60.9064809 },
        { lng:25.657071	, lat:60.9065229 },
        { lng:25.656885	, lat:60.9065659 },
        { lng:25.656643	, lat:60.9066359 },
        { lng:25.656494	, lat:60.9066609 },
        { lng:25.656365	, lat:60.9066689 },
        { lng:25.65618	, lat:60.9066759 },
        { lng:25.65594	, lat:60.9066919 },
        { lng:25.6557359	, lat:60.906725 },
        { lng:25.6554939	, lat:60.906768 },
        { lng:25.6552899	, lat:60.906802 },
        { lng:25.6550859	, lat:60.906845 },
        { lng:25.6548989	, lat:60.906897 },
        { lng:25.6546949	, lat:60.906939 },
        { lng:25.6545459	, lat:60.906974 },
        { lng:25.6544339	, lat:60.906991 },
        { lng:25.6542689	, lat:60.906989 },
        { lng:25.6541029	, lat:60.906987 },
        { lng:25.6539919	, lat:60.906995 },
        { lng:25.6538799	, lat:60.907021 },
        { lng:25.6537119	, lat:60.907082 },
        { lng:25.6535989	, lat:60.907135 },
        { lng:25.6535409	, lat:60.907188 },
        { lng:25.6535209	, lat:60.907233 },
        { lng:25.6535719	, lat:60.907341 },
        { lng:25.6536979	, lat:60.907405 },
        { lng:25.6538439	, lat:60.907451 },
        { lng:25.6539709	, lat:60.907489 },
        { lng:25.6541169	, lat:60.907526 },
        { lng:25.6542079	, lat:60.907563 },
        { lng:25.6542969	, lat:60.907618 },
        { lng:25.6543509	, lat:60.907663 },
        { lng:25.6543669	, lat:60.907726 },
        { lng:25.6543439	, lat:60.907815 },
        { lng:25.6543049	, lat:60.907878 },
        { lng:25.6542289	, lat:60.90794 },
        { lng:25.6541709	, lat:60.908002 },
        { lng:25.6541129	, lat:60.908056 },
        { lng:25.6540739	, lat:60.908118 },
        { lng:25.6540519	, lat:60.90819 },
        { lng:25.6540499	, lat:60.908252 },
        { lng:25.6540289	, lat:60.908306 },
        { lng:25.6540089	, lat:60.908342 },
        { lng:25.6539509	, lat:60.908413 },
        { lng:25.6539119	, lat:60.908448 },
        { lng:25.6538359	, lat:60.908502 },
        { lng:25.6537419	, lat:60.908545 },
        { lng:25.6536289	, lat:60.908616 },
        { lng:25.6535339	, lat:60.908669 },
        { lng:25.6534579	, lat:60.908722 },
        { lng:25.6533629	, lat:60.908793 },
        { lng:25.6533229	, lat:60.908873 },
        { lng:25.6532829	, lat:60.908954 },
        { lng:25.6532429	, lat:60.909016 },
        { lng:25.6531859	, lat:60.90906 },
        { lng:25.6531109	, lat:60.909096 },
        { lng:25.6530179	, lat:60.909104 },
        { lng:25.6529079	, lat:60.909094 },
        { lng:25.6528169	, lat:60.909075 },
        { lng:25.6527069	, lat:60.909047 },
        { lng:25.6526169	, lat:60.90901 },
        { lng:25.6525449	, lat:60.908955 },
        { lng:25.6524739	, lat:60.908901 },
        { lng:25.6523839	, lat:60.908837 },
        { lng:25.6522769	, lat:60.908773 },
        { lng:25.6521689	, lat:60.908709 },
        { lng:25.6520619	, lat:60.908627 },
        { lng:25.6519359	, lat:60.908554 },
        { lng:25.6518279	, lat:60.90849 },
        { lng:25.6516109	, lat:60.908381 },
        { lng:25.6514849	, lat:60.908307 },
        { lng:25.6513769	, lat:60.908244 },
        { lng:25.6513059	, lat:60.908189 },
        { lng:25.6512169	, lat:60.908116 },
        { lng:25.6511459	, lat:60.908053 },
        { lng:25.6510929	, lat:60.907989 },
        { lng:25.6510409	, lat:60.907926 },
        { lng:25.6509879	, lat:60.907872 },
        { lng:25.6508989	, lat:60.907799 },
        { lng:25.6507909	, lat:60.907735 },
        { lng:25.6506629	, lat:60.907698 },
        { lng:25.6505529	, lat:60.907679 },
        { lng:25.6504429	, lat:60.907678 },
        { lng:25.6502949	, lat:60.907685 },
        { lng:25.6501289	, lat:60.907701 },
        { lng:25.6499419	, lat:60.907744 },
        { lng:25.6498299	, lat:60.907779 },
        { lng:25.6496439	, lat:60.907831 },
        { lng:25.6495309	, lat:60.907875 },
        { lng:25.6494189	, lat:60.907919 },
        { lng:25.6493439	, lat:60.907945 },
        { lng:25.6492499	, lat:60.907998 },
        { lng:25.6491169	, lat:60.908077 },
        { lng:25.6489659	, lat:60.908174 },
        { lng:25.6488709	, lat:60.908236 },
        { lng:25.6487569	, lat:60.908307 },
        { lng:25.6486809	, lat:60.908378 },
        { lng:25.6486409	, lat:60.908449 },
        { lng:25.6486009	, lat:60.90853 },
        { lng:25.6485789	, lat:60.90861 },
        { lng:25.6485759	, lat:60.908673 },
        { lng:25.6486109	, lat:60.908718 },
        { lng:25.6486639	, lat:60.908764 },
        { lng:25.6487369	, lat:60.908791 },
        { lng:25.6488839	, lat:60.908811 },
        { lng:25.6490489	, lat:60.908813 },
        { lng:25.6491969	, lat:60.908805 },
        { lng:25.6493809	, lat:60.908807 },
        { lng:25.6495459	, lat:60.908827 },
        { lng:25.6496569	, lat:60.908828 },
        { lng:25.6498589	, lat:60.908848 },
        { lng:25.6501159	, lat:60.908868 },
        { lng:25.6502819	, lat:60.908879 },
        { lng:25.6504829	, lat:60.908899 },
        { lng:25.6506099	, lat:60.908945 },
        { lng:25.6506999	, lat:60.909 },
        { lng:25.6506789	, lat:60.909062 },
        { lng:25.6506219	, lat:60.909107 },
        { lng:25.6505289	, lat:60.909133 },
        { lng:25.6503619	, lat:60.909149 },
        { lng:25.6502149	, lat:60.909147 },
        { lng:25.6500679	, lat:60.909137 },
        { lng:25.6499199	, lat:60.909135 },
        { lng:25.6497729	, lat:60.909134 },
        { lng:25.6496429	, lat:60.909151 },
        { lng:25.6495129	, lat:60.909176 },
        { lng:25.6493649	, lat:60.909202 },
        { lng:25.6492529	, lat:60.909228 },
        { lng:25.6491229	, lat:60.909253 },
        { lng:25.6489739	, lat:60.909279 },
        { lng:25.6488259	, lat:60.909304 },
        { lng:25.6486769	, lat:60.909329 },
        { lng:25.6485669	, lat:60.909328 },
        { lng:25.6484559	, lat:60.909327 },
        { lng:25.6483279	, lat:60.909317 },
        { lng:25.6482169	, lat:60.909307 },
        { lng:25.6480889	, lat:60.909288 },
        { lng:25.6479789	, lat:60.909278 },
        { lng:25.6477599	, lat:60.909239 },
        { lng:25.6475399	, lat:60.90921 },
        { lng:25.6473199	, lat:60.909181 },
        { lng:25.6471539	, lat:60.909171 },
        { lng:25.6470069	, lat:60.909169 },
        { lng:25.6469139	, lat:60.909177 },
        { lng:25.6467849	, lat:60.909185 },
        { lng:25.6466549	, lat:60.90921 },
        { lng:25.6466169	, lat:60.909237 },
        { lng:25.6465779	, lat:60.909281 },
        { lng:25.6465759	, lat:60.909326 },
        { lng:25.6465919	, lat:60.909389 },
        { lng:25.6466259	, lat:60.909452 },
        { lng:25.6466789	, lat:60.909516 },
        { lng:25.6467869	, lat:60.909571 },
        { lng:25.6468589	, lat:60.909607 },
        { lng:25.6468949	, lat:60.909644 },
        { lng:25.6469289	, lat:60.909698 },
        { lng:25.6469069	, lat:60.909778 },
        { lng:25.6468679	, lat:60.909832 },
        { lng:25.6467929	, lat:60.909867 },
        { lng:25.6466429	, lat:60.909919 },
        { lng:25.6464759	, lat:60.909954 },
        { lng:25.6462909	, lat:60.909979 },
        { lng:25.6460859	, lat:60.910012 },
        { lng:25.6459749	, lat:60.910029 },
        { lng:25.6457529	, lat:60.910045 },
        { lng:25.6455499	, lat:60.910061 },
        { lng:25.6453469	, lat:60.910068 },
        { lng:25.6451799	, lat:60.910093 },
        { lng:25.6450319	, lat:60.91011 },
        { lng:25.6448459	, lat:60.910144 },
        { lng:25.6446599	, lat:60.910178 },
        { lng:25.6445119	, lat:60.910194 },
        { lng:25.6443449	, lat:60.910228 },
        { lng:25.6442139	, lat:60.910263 },
        { lng:25.6440469	, lat:60.910297 },
        { lng:25.6439349	, lat:60.910323 },
        { lng:25.6437669	, lat:60.910366 },
        { lng:25.6436729	, lat:60.91041 },
        { lng:25.6435789	, lat:60.910463 },
        { lng:25.6435029	, lat:60.910507 },
        { lng:25.6434269	, lat:60.91056 },
        { lng:25.6434069	, lat:60.910605 },
        { lng:25.6434054	, lat:60.9106462 },
        { lng:25.6434049	, lat:60.910659 },
        { lng:25.6434199	, lat:60.910722 },
        { lng:25.6434369	, lat:60.910767 },
        { lng:25.6434529	, lat:60.91083 },
        { lng:25.6434869	, lat:60.910884 },
        { lng:25.6435029	, lat:60.910938 },
        { lng:25.6435379	, lat:60.910992 },
        { lng:25.6436099	, lat:60.911038 },
        { lng:25.6436819	, lat:60.911074 },
        { lng:25.6437729	, lat:60.911111 },
        { lng:25.6438089	, lat:60.911129 },
        { lng:25.6438999	, lat:60.911148 },
        { lng:25.6440269	, lat:60.911186 },
        { lng:25.6441559	, lat:60.911205 },
        { lng:25.6442849	, lat:60.911206 },
        { lng:25.6443959	, lat:60.911189 },
        { lng:25.6444699	, lat:60.911172 },
        { lng:25.6445639	, lat:60.911128 },
        { lng:25.6446209	, lat:60.911102 },
        { lng:25.6447339	, lat:60.911049 },
        { lng:25.6448469	, lat:60.910987 },
        { lng:25.6449409	, lat:60.910934 },
        { lng:25.6450359	, lat:60.910882 },
        { lng:25.6451109	, lat:60.910837 },
        { lng:25.6452229	, lat:60.910803 },
        { lng:25.6453359	, lat:60.910759 },
        { lng:25.6454469	, lat:60.910733 },
        { lng:25.6455959	, lat:60.910717 },
        { lng:25.6457069	, lat:60.910709 },
        { lng:25.6458729	, lat:60.910701 },
        { lng:25.6460389	, lat:60.910703 },
        { lng:25.6462219	, lat:60.910732 },
        { lng:25.6463319	, lat:60.910751 },
        { lng:25.6464589	, lat:60.910779 },
        { lng:25.6465869	, lat:60.910807 },
        { lng:25.6466959	, lat:60.910844 },
        { lng:25.6467679	, lat:60.91089 },
        { lng:25.6468029	, lat:60.910926 },
        { lng:25.6468199	, lat:60.910962 },
        { lng:25.6468189	, lat:60.910998 },
        { lng:25.6467799	, lat:60.911043 },
        { lng:25.6467419	, lat:60.91106 },
        { lng:25.6466309	, lat:60.911086 },
        { lng:25.6465379	, lat:60.911103 },
        { lng:25.6464639	, lat:60.911102 },
        { lng:25.6463729	, lat:60.911083 },
        { lng:25.6462269	, lat:60.911046 },
        { lng:25.6461539	, lat:60.911018 },
        { lng:25.6460819	, lat:60.910991 },
        { lng:25.6459719	, lat:60.910963 },
        { lng:25.6458809	, lat:60.910944 },
        { lng:25.6457889	, lat:60.910943 },
        { lng:25.6457329	, lat:60.910951 },
        { lng:25.6456589	, lat:60.910969 },
        { lng:25.6455829	, lat:60.911004 },
        { lng:25.6455079	, lat:60.911048 },
        { lng:25.6454689	, lat:60.911092 },
        { lng:25.6454299	, lat:60.911155 },
        { lng:25.6454459	, lat:60.911209 },
        { lng:25.6454619	, lat:60.911254 },
        { lng:25.6454619	, lat:60.911263 },
        { lng:25.6454589	, lat:60.911326 },
        { lng:25.6454559	, lat:60.911397 },
        { lng:25.6454529	, lat:60.911478 },
        { lng:25.645412	, lat:60.911576 },
        { lng:25.64537	, lat:60.911684 },
        { lng:25.64533	, lat:60.911773 },
        { lng:25.645307	, lat:60.911863 },
        { lng:25.645322	, lat:60.911953 },
        { lng:25.645355	, lat:60.912052 },
        { lng:25.645388	, lat:60.912133 },
        { lng:25.64544	, lat:60.912223 },
        { lng:25.645492	, lat:60.912286 },
        { lng:25.645544	, lat:60.912359 },
        { lng:25.645616	, lat:60.912413 },
        { lng:25.64567	, lat:60.912432 },
        { lng:25.645743	, lat:60.91245 },
        { lng:25.645835	, lat:60.91246 },
        { lng:25.645909	, lat:60.912452 },
        { lng:25.646039	, lat:60.912435 },
        { lng:25.646189	, lat:60.912374 },
        { lng:25.646265	, lat:60.91233 },
        { lng:25.646341	, lat:60.912277 },
        { lng:25.646435	, lat:60.912215 },
        { lng:25.64653	, lat:60.912153 },
        { lng:25.646644	, lat:60.912082 },
        { lng:25.646757	, lat:60.912021 },
        { lng:25.646851	, lat:60.911977 },
        { lng:25.646963	, lat:60.911933 },
        { lng:25.647094	, lat:60.91189 },
        { lng:25.647225	, lat:60.911846 },
        { lng:25.64745	, lat:60.911767 },
        { lng:25.647598	, lat:60.911742 },
        { lng:25.64771	, lat:60.911725 },
        { lng:25.647839	, lat:60.911718 },
        { lng:25.647948	, lat:60.911746 },
        { lng:25.648002	, lat:60.911782 },
        { lng:25.648019	, lat:60.911827 },
        { lng:25.648017	, lat:60.911872 },
        { lng:25.647978	, lat:60.911916 },
        { lng:25.647939	, lat:60.91197 },
        { lng:25.647863	, lat:60.912014 },
        { lng:25.64775	, lat:60.912067 },
        { lng:25.647656	, lat:60.912111 },
        { lng:25.647525	, lat:60.912154 },
        { lng:25.647431	, lat:60.912198 },
        { lng:25.647301	, lat:60.912242 },
        { lng:25.647188	, lat:60.912285 },
        { lng:25.647094	, lat:60.91232 },
        { lng:25.647001	, lat:60.912355 },
        { lng:25.646907	, lat:60.912399 },
        { lng:25.646812	, lat:60.912452 },
        { lng:25.646717	, lat:60.912523 },
        { lng:25.64666	, lat:60.912576 },
        { lng:25.646584	, lat:60.912629 },
        { lng:25.646508	, lat:60.912665 },
        { lng:25.64647	, lat:60.9127 },
        { lng:25.646413	, lat:60.912744 },
        { lng:25.646374	, lat:60.912789 },
        { lng:25.646317	, lat:60.912824 },
        { lng:25.646297	, lat:60.912869 },
        { lng:25.646258	, lat:60.912913 },
        { lng:25.64622	, lat:60.912949 },
        { lng:25.646199	, lat:60.913003 },
        { lng:25.646179	, lat:60.913038 },
        { lng:25.646176	, lat:60.91311 },
        { lng:25.646174	, lat:60.913155 },
        { lng:25.646154	, lat:60.9132 },
        { lng:25.64617	, lat:60.913254 },
        { lng:25.646168	, lat:60.913307 },
        { lng:25.646203	, lat:60.913353 },
        { lng:25.646238	, lat:60.91338 },
        { lng:25.646292	, lat:60.913416 },
        { lng:25.646365	, lat:60.913444 },
        { lng:25.646493	, lat:60.913472 },
        { lng:25.646584	, lat:60.913482 },
        { lng:25.646658	, lat:60.913483 },
        { lng:25.646751	, lat:60.9134749 },
        { lng:25.646843	, lat:60.913458 },
        { lng:25.646919	, lat:60.913423 },
        { lng:25.646975	, lat:60.913387 },
        { lng:25.647015	, lat:60.913334 },
        { lng:25.647072	, lat:60.91329 },
        { lng:25.647129	, lat:60.913245 },
        { lng:25.647187	, lat:60.913192 },
        { lng:25.647225	, lat:60.913165 },
        { lng:25.647263	, lat:60.913121 },
        { lng:25.647283	, lat:60.913094 },
        { lng:25.64734	, lat:60.913059 },
        { lng:25.647396	, lat:60.913024 },
        { lng:25.647452	, lat:60.913006 },
        { lng:25.647527	, lat:60.91298 },
        { lng:25.647601	, lat:60.912972 },
        { lng:25.647713	, lat:60.9129549 },
        { lng:25.647805	, lat:60.9129559 },
        { lng:25.647878	, lat:60.9129659 },
        { lng:25.647951	, lat:60.9129839 },
        { lng:25.648042	, lat:60.9130119 },
        { lng:25.648096	, lat:60.9130399 },
        { lng:25.64815	, lat:60.9130759 },
        { lng:25.648204	, lat:60.9131029 },
        { lng:25.648221	, lat:60.9131489 },
        { lng:25.648274	, lat:60.9131939 },
        { lng:25.648291	, lat:60.9132389 },
        { lng:25.648307	, lat:60.9132839 },
        { lng:25.648306	, lat:60.9133199 },
        { lng:25.648322	, lat:60.9133649 },
        { lng:25.648321	, lat:60.9134009 },
        { lng:25.648319	, lat:60.9134459 },
        { lng:25.648335	, lat:60.9134909 },
        { lng:25.648369	, lat:60.9135629 },
        { lng:25.648404	, lat:60.9136169 },
        { lng:25.648439	, lat:60.9136619 },
        { lng:25.6484919	, lat:60.913708 },
        { lng:25.6485449	, lat:60.913762 },
        { lng:25.6485989	, lat:60.913799 },
        { lng:25.6487069	, lat:60.913862 },
        { lng:25.6488329	, lat:60.913936 },
        { lng:25.6489399	, lat:60.914008 },
        { lng:25.6490479	, lat:60.914063 },
        { lng:25.6491929	, lat:60.914137 },
        { lng:25.6493559	, lat:60.914201 },
        { lng:25.6495009	, lat:60.914256 },
        { lng:25.6496649	, lat:60.914312 },
        { lng:25.6498099	, lat:60.914358 },
        { lng:25.6499189	, lat:60.914404 },
        { lng:25.6499909	, lat:60.91445 },
        { lng:25.6499879	, lat:60.914513 },
        { lng:25.649948	, lat:60.914593 },
        { lng:25.649871	, lat:60.914673 },
        { lng:25.649794	, lat:60.914735 },
        { lng:25.649663	, lat:60.914797 },
        { lng:25.6495489	, lat:60.914867 },
        { lng:25.6493979	, lat:60.914938 },
        { lng:25.6492679	, lat:60.914981 },
        { lng:25.6490989	, lat:60.915042 },
        { lng:25.648931	, lat:60.9151029 },
        { lng:25.648725	, lat:60.9151639 },
        { lng:25.648576	, lat:60.915208 },
        { lng:25.648408	, lat:60.9152509 },
        { lng:25.648241	, lat:60.9152849 },
        { lng:25.647981	, lat:60.9153269 },
        { lng:25.647741	, lat:60.915343 },
        { lng:25.6475739	, lat:60.915359 },
        { lng:25.6475	, lat:60.915358 },
        { lng:25.6473719	, lat:60.915339 },
        { lng:25.6472989	, lat:60.915311 },
        { lng:25.647245	, lat:60.915284 },
        { lng:25.6471919	, lat:60.915247 },
        { lng:25.6471019	, lat:60.915175 },
        { lng:25.647068	, lat:60.915121 },
        { lng:25.6470329	, lat:60.915066 },
        { lng:25.6469999	, lat:60.914994 },
        { lng:25.6469839	, lat:60.914922 },
        { lng:25.6468949	, lat:60.91485 },
        { lng:25.6468039	, lat:60.914822 },
        { lng:25.6466949	, lat:60.914794 },
        { lng:25.6466019	, lat:60.914811 },
        { lng:25.6465259	, lat:60.914855 },
        { lng:25.6464869	, lat:60.914908 },
        { lng:25.646484	, lat:60.91498 },
        { lng:25.6465179	, lat:60.915043 },
        { lng:25.646588	, lat:60.915125 },
        { lng:25.6466769	, lat:60.915197 },
        { lng:25.64673	, lat:60.91527 },
        { lng:25.646764	, lat:60.915324 },
        { lng:25.646816	, lat:60.915405 },
        { lng:25.6468489	, lat:60.915495 },
        { lng:25.6469179	, lat:60.915604 },
        { lng:25.6469499	, lat:60.915721 },
        { lng:25.6470009	, lat:60.915829 },
        { lng:25.6470169	, lat:60.915892 },
        { lng:25.6470299	, lat:60.916009 },
        { lng:25.6470439	, lat:60.916117 },
        { lng:25.6470779	, lat:60.916189 },
        { lng:25.6471299	, lat:60.916261 },
        { lng:25.6471629	, lat:60.916351 },
        { lng:25.6471609	, lat:60.916414 },
        { lng:25.6471219	, lat:60.916458 },
        { lng:25.6470279	, lat:60.916502 },
        { lng:25.6469159	, lat:60.916519 },
        { lng:25.6468059	, lat:60.916518 },
        { lng:25.6466959	, lat:60.916508 },
        { lng:25.6466049	, lat:60.91648 },
        { lng:25.6465319	, lat:60.916444 },
        { lng:25.6464609	, lat:60.916398 },
        { lng:25.6464259	, lat:60.916353 },
        { lng:25.6463909	, lat:60.916299 },
        { lng:25.6463379	, lat:60.916244 },
        { lng:25.6463039	, lat:60.916181 },
        { lng:25.6462699	, lat:60.916109 },
        { lng:25.6462559	, lat:60.91601 },
        { lng:25.6462399	, lat:60.915956 },
        { lng:25.646206	, lat:60.915893 },
        { lng:25.646153	, lat:60.915829 },
        { lng:25.6460999	, lat:60.915775 },
        { lng:25.6460469	, lat:60.915712 },
        { lng:25.645976	, lat:60.915657 },
        { lng:25.645886	, lat:60.915602 },
        { lng:25.645759	, lat:60.915547 },
        { lng:25.645613	, lat:60.91551 },
        { lng:25.6454869	, lat:60.915455 },
        { lng:25.6453049	, lat:60.91539 },
        { lng:25.6451419	, lat:60.915317 },
        { lng:25.6449789	, lat:60.915261 },
        { lng:25.644816	, lat:60.915197 },
        { lng:25.64467	, lat:60.91515 },
        { lng:25.644452	, lat:60.915085 },
        { lng:25.644252	, lat:60.91502 },
        { lng:25.644105	, lat:60.914992 },
        { lng:25.643995	, lat:60.914982 },
        { lng:25.643903	, lat:60.914981 },
        { lng:25.64381	, lat:60.915007 },
        { lng:25.643716	, lat:60.915051 },
        { lng:25.643695	, lat:60.915096 },
        { lng:25.643711	, lat:60.91515 },
        { lng:25.643728	, lat:60.915195 },
        { lng:25.643781	, lat:60.91524 },
        { lng:25.643962	, lat:60.915323 },
        { lng:25.64407	, lat:60.915387 },
        { lng:25.644178	, lat:60.91545 },
        { lng:25.644249	, lat:60.915505 },
        { lng:25.644302	, lat:60.915559 },
        { lng:25.644338	, lat:60.915596 },
        { lng:25.644355	, lat:60.915632 },
        { lng:25.64435	, lat:60.91573 },
        { lng:25.6443439	, lat:60.915892 },
        { lng:25.644338	, lat:60.916027 },
        { lng:25.644351	, lat:60.916143 },
        { lng:25.644384	, lat:60.916251 },
        { lng:25.644415	, lat:60.916377 },
        { lng:25.6444469	, lat:60.916494 },
        { lng:25.6444619	, lat:60.916575 },
        { lng:25.6444409	, lat:60.916638 },
        { lng:25.6443459	, lat:60.916709 },
        { lng:25.6442329	, lat:60.916761 },
        { lng:25.6440279	, lat:60.916813 },
        { lng:25.6438239	, lat:60.916838 },
        { lng:25.6435469	, lat:60.916853 },
        { lng:25.6433999	, lat:60.916843 },
        { lng:25.6432719	, lat:60.916824 },
        { lng:25.6431439	, lat:60.916777 },
        { lng:25.6430349	, lat:60.916749 },
        { lng:25.6428529	, lat:60.916703 },
        { lng:25.6426499	, lat:60.916692 },
        { lng:25.6425209	, lat:60.916699 },
        { lng:25.6424079	, lat:60.916761 },
        { lng:25.6423869	, lat:60.916824 },
        { lng:25.6424199	, lat:60.916896 },
        { lng:25.6424729	, lat:60.916959 },
        { lng:25.6425809	, lat:60.917023 },
        { lng:25.6427619	, lat:60.917106 },
        { lng:25.6430339	, lat:60.917207 },
        { lng:25.6432319	, lat:60.917317 },
        { lng:25.6433919	, lat:60.917453 },
        { lng:25.6434439	, lat:60.917534 },
        { lng:25.6434579	, lat:60.917633 },
        { lng:25.6434179	, lat:60.917723 },
        { lng:25.6433049	, lat:60.917766 },
        { lng:25.6431579	, lat:60.917765 },
        { lng:25.6430659	, lat:60.917755 },
        { lng:25.6429389	, lat:60.917718 },
        { lng:25.6427929	, lat:60.917663 },
        { lng:25.6426659	, lat:60.917616 },
        { lng:25.6425579	, lat:60.917561 },
        { lng:25.6424139	, lat:60.917479 },
        { lng:25.6422519	, lat:60.917388 },
        { lng:25.642089	, lat:60.917314 },
        { lng:25.64198	, lat:60.917277 },
        { lng:25.641834	, lat:60.917249 },
        { lng:25.641669	, lat:60.917229 },
        { lng:25.641484	, lat:60.917236 },
        { lng:25.641318	, lat:60.917244 },
        { lng:25.641133	, lat:60.91726 },
        { lng:25.640948	, lat:60.917267 },
        { lng:25.640801	, lat:60.917256 },
        { lng:25.640617	, lat:60.917246 },
        { lng:25.640434	, lat:60.917208 },
        { lng:25.640271	, lat:60.917161 },
        { lng:25.640125	, lat:60.917124 },
        { lng:25.639979	, lat:60.917087 },
        { lng:25.639814	, lat:60.917067 },
        { lng:25.639647	, lat:60.917074 },
        { lng:25.639462	, lat:60.917099 },
        { lng:25.639275	, lat:60.917151 },
        { lng:25.639163	, lat:60.917195 },
        { lng:25.639124	, lat:60.917248 },
        { lng:25.639104	, lat:60.917284 },
        { lng:25.639138	, lat:60.917338 },
        { lng:25.639191	, lat:60.917393 },
        { lng:25.639299	, lat:60.917457 },
        { lng:25.639444	, lat:60.917512 },
        { lng:25.639571	, lat:60.917567 },
        { lng:25.639697	, lat:60.917649 },
        { lng:25.639767	, lat:60.917731 },
        { lng:25.639763	, lat:60.91782 },
        { lng:25.639669	, lat:60.917864 },
        { lng:25.639576	, lat:60.917881 },
        { lng:25.639466	, lat:60.91788 },
        { lng:25.639337	, lat:60.917861 },
        { lng:25.639228	, lat:60.917833 },
        { lng:25.6391	, lat:60.917796 },
        { lng:25.638973	, lat:60.917768 },
        { lng:25.638863	, lat:60.91774 },
        { lng:25.63868	, lat:60.917711 },
        { lng:25.638496	, lat:60.9177 },
        { lng:25.638292	, lat:60.917725 },
        { lng:25.638106	, lat:60.917759 },
        { lng:25.637976	, lat:60.917793 },
        { lng:25.637808	, lat:60.917846 },
        { lng:25.637658	, lat:60.917889 },
        { lng:25.637583	, lat:60.917924 },
        { lng:25.637489	, lat:60.917959 },
        { lng:25.637283	, lat:60.918047 },
        { lng:25.637058	, lat:60.918125 },
        { lng:25.636834	, lat:60.918204 },
        { lng:25.636703	, lat:60.918247 },
        { lng:25.636534	, lat:60.918317 },
        { lng:25.636383	, lat:60.918406 },
        { lng:25.636269	, lat:60.918485 },
        { lng:25.636116	, lat:60.9186 },
        { lng:25.635984	, lat:60.91868 },
        { lng:25.635907	, lat:60.91876 },
        { lng:25.635886	, lat:60.918813 },
        { lng:25.635883	, lat:60.918876 },
        { lng:25.635917	, lat:60.918948 },
        { lng:25.635968	, lat:60.919039 },
        { lng:25.636111	, lat:60.919157 },
        { lng:25.6362	, lat:60.919238 },
        { lng:25.636306	, lat:60.919329 },
        { lng:25.636393	, lat:60.919447 },
        { lng:25.636463	, lat:60.919555 },
        { lng:25.636514	, lat:60.919654 },
        { lng:25.636547	, lat:60.919745 },
        { lng:25.63658	, lat:60.919817 },
        { lng:25.636596	, lat:60.919889 },
        { lng:25.636537	, lat:60.919969 },
        { lng:25.636479	, lat:60.920022 },
        { lng:25.636365	, lat:60.920111 },
        { lng:25.63627	, lat:60.920182 },
        { lng:25.636137	, lat:60.920261 },
        { lng:25.63595	, lat:60.920331 },
        { lng:25.635818	, lat:60.920392 },
        { lng:25.635669	, lat:60.920436 },
        { lng:25.635427	, lat:60.920478 },
        { lng:25.635222	, lat:60.92053 },
        { lng:25.635055	, lat:60.920564 },
        { lng:25.634961	, lat:60.920617 },
        { lng:25.634884	, lat:60.920688 },
        { lng:25.634825	, lat:60.920759 },
        { lng:25.634765	, lat:60.920866 },
        { lng:25.634725	, lat:60.920947 },
        { lng:25.634685	, lat:60.921027 },
        { lng:25.634681	, lat:60.921108 },
        { lng:25.634734	, lat:60.921171 },
        { lng:25.63475	, lat:60.921225 },
        { lng:25.634747	, lat:60.921288 },
        { lng:25.634727	, lat:60.921333 },
        { lng:25.634614	, lat:60.921394 },
        { lng:25.634444	, lat:60.921474 },
        { lng:25.634314	, lat:60.921517 },
        { lng:25.634127	, lat:60.921569 },
        { lng:25.633978	, lat:60.921603 },
        { lng:25.633792	, lat:60.921646 },
        { lng:25.633253	, lat:60.921749 },
        { lng:25.632974	, lat:60.921791 },
        { lng:25.632676	, lat:60.921859 },
        { lng:25.632508	, lat:60.921921 },
        { lng:25.632412	, lat:60.922 },
        { lng:25.632334	, lat:60.922089 },
        { lng:25.632312	, lat:60.922188 },
        { lng:25.63231	, lat:60.922233 },
        { lng:25.632344	, lat:60.922305 },
        { lng:25.632395	, lat:60.922395 },
        { lng:25.632447	, lat:60.922476 },
        { lng:25.632515	, lat:60.922621 },
        { lng:25.63253	, lat:60.922684 },
        { lng:25.632527	, lat:60.922756 },
        { lng:25.632508	, lat:60.922782 },
        { lng:25.632488	, lat:60.922809 },
        { lng:25.63243	, lat:60.92288 },
        { lng:25.632316	, lat:60.92296 },
        { lng:25.63222	, lat:60.923031 },
        { lng:25.632125	, lat:60.923093 },
        { lng:25.632068	, lat:60.923137 },
        { lng:25.631993	, lat:60.923181 },
        { lng:25.631899	, lat:60.923225 },
        { lng:25.631823	, lat:60.92326 },
        { lng:25.631766	, lat:60.923295 },
        { lng:25.631748	, lat:60.923295 },
        { lng:25.63171	, lat:60.923313 },
        { lng:25.631635	, lat:60.923348 },
        { lng:25.631541	, lat:60.923401 },
        { lng:25.631409	, lat:60.923453 },
        { lng:25.631297	, lat:60.923497 },
        { lng:25.631203	, lat:60.923541 },
        { lng:25.6311469	, lat:60.923558 },
        { lng:25.631072	, lat:60.923584 },
        { lng:25.631016	, lat:60.923602 },
        { lng:25.630959	, lat:60.923628 },
        { lng:25.630903	, lat:60.923655 },
        { lng:25.630847	, lat:60.923672 },
        { lng:25.630808	, lat:60.923707 },
        { lng:25.63077	, lat:60.923743 },
        { lng:25.630732	, lat:60.923761 },
        { lng:25.630694	, lat:60.923796 },
        { lng:25.630673	, lat:60.92385 },
        { lng:25.630652	, lat:60.923912 },
        { lng:25.630649	, lat:60.923984 },
        { lng:25.630646	, lat:60.924056 },
        { lng:25.630644	, lat:60.924101 },
        { lng:25.630642	, lat:60.924137 },
        { lng:25.630659	, lat:60.924173 },
        { lng:25.630658	, lat:60.924209 },
        { lng:25.630674	, lat:60.924254 },
        { lng:25.63071	, lat:60.92429 },
        { lng:25.630762	, lat:60.924353 },
        { lng:25.630815	, lat:60.924417 },
        { lng:25.630885	, lat:60.924507 },
        { lng:25.630902	, lat:60.924534 },
        { lng:25.630902	, lat:60.924543 },
        { lng:25.630919	, lat:60.92457 },
        { lng:25.630936	, lat:60.924606 },
        { lng:25.630988	, lat:60.924679 },
        { lng:25.630987	, lat:60.924706 },
        { lng:25.631003	, lat:60.92476 },
        { lng:25.631	, lat:60.924822 },
        { lng:25.630981	, lat:60.924849 },
        { lng:25.630978	, lat:60.924903 },
        { lng:25.630956	, lat:60.924983 },
        { lng:25.630934	, lat:60.925064 },
        { lng:25.630933	, lat:60.925109 },
        { lng:25.63093	, lat:60.925163 },
        { lng:25.630928	, lat:60.925208 },
        { lng:25.630908	, lat:60.925252 },
        { lng:25.630869	, lat:60.925297 },
        { lng:25.63085	, lat:60.925315 },
        { lng:25.630736	, lat:60.925403 },
        { lng:25.630678	, lat:60.925456 },
        { lng:25.630582	, lat:60.925536 },
        { lng:25.630563	, lat:60.925554 },
        { lng:25.630488	, lat:60.925589 },
        { lng:25.630412	, lat:60.925642 },
        { lng:25.630374	, lat:60.925669 },
        { lng:25.630318	, lat:60.925686 },
        { lng:25.630224	, lat:60.925712 },
        { lng:25.630133	, lat:60.925702 },
        { lng:25.630096	, lat:60.925693 },
        { lng:25.630042	, lat:60.925674 },
        { lng:25.629987	, lat:60.925647 },
        { lng:25.629898	, lat:60.925592 },
        { lng:25.62988	, lat:60.925574 },
        { lng:25.629827	, lat:60.92552 },
        { lng:25.629757	, lat:60.92542 },
        { lng:25.629724	, lat:60.925339 },
        { lng:25.629689	, lat:60.925294 },
        { lng:25.629637	, lat:60.925221 },
        { lng:25.629584	, lat:60.925158 },
        { lng:25.629513	, lat:60.925094 },
        { lng:25.629496	, lat:60.925076 },
        { lng:25.629459	, lat:60.925058 },
        { lng:25.629369	, lat:60.925012 },
        { lng:25.629241	, lat:60.924984 },
        { lng:25.629112	, lat:60.925001 },
        { lng:25.628963	, lat:60.925035 },
        { lng:25.628888	, lat:60.925052 },
        { lng:25.628813	, lat:60.925078 },
        { lng:25.628683	, lat:60.925095 },
        { lng:25.628627	, lat:60.925112 },
        { lng:25.628534	, lat:60.925138 },
        { lng:25.628478	, lat:60.925156 },
        { lng:25.628365	, lat:60.925199 },
        { lng:25.62829	, lat:60.925226 },
        { lng:25.628178	, lat:60.925278 },
        { lng:25.628083	, lat:60.92534 },
        { lng:25.628007	, lat:60.925384 },
        { lng:25.627912	, lat:60.925446 },
        { lng:25.627837	, lat:60.92549 },
        { lng:25.627798	, lat:60.925526 },
        { lng:25.62774	, lat:60.925579 },
        { lng:25.627721	, lat:60.925615 },
        { lng:25.627719	, lat:60.92566 },
        { lng:25.627736	, lat:60.925687 },
        { lng:25.627752	, lat:60.925732 },
        { lng:25.62777	, lat:60.925759 },
        { lng:25.627805	, lat:60.925795 },
        { lng:25.627821	, lat:60.925858 },
        { lng:25.627873	, lat:60.925939 },
        { lng:25.627906	, lat:60.92603 },
        { lng:25.627958	, lat:60.926102 },
        { lng:25.627957	, lat:60.926129 },
        { lng:25.627973	, lat:60.926165 },
        { lng:25.627972	, lat:60.92621 },
        { lng:25.627988	, lat:60.926264 },
        { lng:25.628004	, lat:60.926309 },
        { lng:25.628002	, lat:60.926354 },
        { lng:25.628	, lat:60.926407 },
        { lng:25.62798	, lat:60.926443 },
        { lng:25.627941	, lat:60.926497 },
        { lng:25.627863	, lat:60.926586 },
        { lng:25.627712	, lat:60.926674 },
        { lng:25.627542	, lat:60.926762 },
        { lng:25.627467	, lat:60.926797 },
        { lng:25.627391	, lat:60.926841 },
        { lng:25.627125	, lat:60.927027 },
        { lng:25.627106	, lat:60.927036 },
        { lng:25.627087	, lat:60.927044 },
        { lng:25.627068	, lat:60.927062 },
        { lng:25.62703	, lat:60.92708 },
        { lng:25.626992	, lat:60.927106 },
        { lng:25.626955	, lat:60.927124 },
        { lng:25.626936	, lat:60.927142 },
        { lng:25.626879	, lat:60.927159 },
        { lng:25.626842	, lat:60.927168 },
        { lng:25.626786	, lat:60.927185 },
        { lng:25.62673	, lat:60.927202 },
        { lng:25.626675	, lat:60.927202 },
        { lng:25.626638	, lat:60.92721 },
        { lng:25.626545	, lat:60.927218 },
        { lng:25.626471	, lat:60.927227 },
        { lng:25.626416	, lat:60.927226 },
        { lng:25.626397	, lat:60.927226 },
        { lng:25.626342	, lat:60.927225 },
        { lng:25.626268	, lat:60.927234 },
        { lng:25.626194	, lat:60.927233 },
        { lng:25.626083	, lat:60.92725 },
        { lng:25.625935	, lat:60.927257 },
        { lng:25.625879	, lat:60.927265 },
        { lng:25.625842	, lat:60.927265 },
        { lng:25.625232	, lat:60.927304 },
        { lng:25.625195	, lat:60.927312 },
        { lng:25.62512	, lat:60.927338 },
        { lng:25.625044	, lat:60.927374 },
        { lng:25.625025	, lat:60.9274 },
        { lng:25.625005	, lat:60.927436 },
        { lng:25.625003	, lat:60.927472 },
        { lng:25.625108	, lat:60.927617 },
        { lng:25.625143	, lat:60.927644 },
        { lng:25.625198	, lat:60.927671 },
        { lng:25.625288	, lat:60.927708 },
        { lng:25.62536	, lat:60.927745 },
        { lng:25.625488	, lat:60.927791 },
        { lng:25.625578	, lat:60.927837 },
        { lng:25.625632	, lat:60.927855 },
        { lng:25.625668	, lat:60.927883 },
        { lng:25.625721	, lat:60.927928 },
        { lng:25.625792	, lat:60.928001 },
        { lng:25.625845	, lat:60.928064 },
        { lng:25.625897	, lat:60.928136 },
        { lng:25.625913	, lat:60.928181 },
        { lng:25.625967	, lat:60.928227 },
        { lng:25.62602	, lat:60.928272 },
        { lng:25.626073	, lat:60.928327 },
        { lng:25.626108	, lat:60.928363 },
        { lng:25.626161	, lat:60.928417 },
        { lng:25.626196	, lat:60.928462 },
        { lng:25.626212	, lat:60.928534 },
        { lng:25.626247	, lat:60.92858 },
        { lng:25.626243	, lat:60.92866 },
        { lng:25.62624	, lat:60.928732 },
        { lng:25.626218	, lat:60.928804 },
        { lng:25.626141	, lat:60.928884 },
        { lng:25.626103	, lat:60.92891 },
        { lng:25.626047	, lat:60.928937 },
        { lng:25.625972	, lat:60.928954 },
        { lng:25.625879	, lat:60.928989 },
        { lng:25.625804	, lat:60.929015 },
        { lng:25.625729	, lat:60.929041 },
        { lng:25.625691	, lat:60.929059 },
        { lng:25.625634	, lat:60.929094 },
        { lng:25.625462	, lat:60.929236 },
        { lng:25.625423	, lat:60.92928 },
        { lng:25.625423	, lat:60.929298 },
        { lng:25.625403	, lat:60.929316 },
        { lng:25.625384	, lat:60.929343 },
        { lng:25.625364	, lat:60.929369 },
        { lng:25.625326	, lat:60.929405 },
        { lng:25.625288	, lat:60.929432 },
        { lng:25.625231	, lat:60.929458 },
        { lng:25.625194	, lat:60.929475 },
        { lng:25.625138	, lat:60.929493 },
        { lng:25.625044	, lat:60.929528 },
        { lng:25.62497	, lat:60.929536 },
        { lng:25.624896	, lat:60.929544 },
        { lng:25.624821	, lat:60.929552 },
        { lng:25.62471	, lat:60.929569 },
        { lng:25.624636	, lat:60.929586 },
        { lng:25.624542	, lat:60.929612 },
        { lng:25.624505	, lat:60.929621 },
        { lng:25.62443	, lat:60.929656 },
        { lng:25.624392	, lat:60.929674 },
        { lng:25.624354	, lat:60.9297 },
        { lng:25.624261	, lat:60.929726 },
        { lng:25.624185	, lat:60.929761 },
        { lng:25.624092	, lat:60.929796 },
        { lng:25.623961	, lat:60.92984 },
        { lng:25.623904	, lat:60.929866 },
        { lng:25.62381	, lat:60.929901 },
        { lng:25.623754	, lat:60.929936 },
        { lng:25.623678	, lat:60.929981 },
        { lng:25.62364	, lat:60.930007 },
        { lng:25.623583	, lat:60.930033 },
        { lng:25.623527	, lat:60.930069 },
        { lng:25.623452	, lat:60.930095 },
        { lng:25.623376	, lat:60.93013 },
        { lng:25.623339	, lat:60.930148 },
        { lng:25.623301	, lat:60.930174 },
        { lng:25.623281	, lat:60.930201 },
        { lng:25.623243	, lat:60.930227 },
        { lng:25.623205	, lat:60.930254 },
        { lng:25.623129	, lat:60.930316 },
        { lng:25.623091	, lat:60.930334 },
        { lng:25.623017	, lat:60.930333 },
        { lng:25.622962	, lat:60.930323 },
        { lng:25.622613	, lat:60.930293 },
        { lng:25.621144	, lat:60.930143 },
        { lng:25.618794	, lat:60.929904 },
        { lng:25.613964	, lat:60.929414 },
        { lng:25.61211	, lat:60.929225 },
        { lng:25.610842	, lat:60.929104 },
        { lng:25.60919	, lat:60.928934 },
        { lng:25.60913	, lat:60.929041 },
        { lng:25.6090817	, lat:60.9291279 },
        { lng:25.608709	, lat:60.9298 },
        { lng:25.607823	, lat:60.931092 },
        { lng:25.607548	, lat:60.931475 },
        { lng:25.606721	, lat:60.932678 },
        { lng:25.606523	, lat:60.932982 },
        { lng:25.60227	, lat:60.932381 },
        { lng:25.600779	, lat:60.929798 },
        { lng:25.599579	, lat:60.927712 },
        { lng:25.598774	, lat:60.926304 },
        { lng:25.594933	, lat:60.928095 },
        { lng:25.592954	, lat:60.928227 },
        { lng:25.592344	, lat:60.928265 },
        { lng:25.591068	, lat:60.928342 },
        { lng:25.590032	, lat:60.928412 },
        { lng:25.589385	, lat:60.92845 },
        { lng:25.586832	, lat:60.928612 },
        { lng:25.584881	, lat:60.928941 },
        { lng:25.58397	, lat:60.929102 },
        { lng:25.583338	, lat:60.929212 },
        { lng:25.582948	, lat:60.929271 },
        { lng:25.580755	, lat:60.929652 },
        { lng:25.575811	, lat:60.930497 },
        { lng:25.576592	, lat:60.932022 },
        { lng:25.577709	, lat:60.934269 },
        { lng:25.5744539	, lat:60.9351138 },
        { lng:25.573675	, lat:60.935384 },
        { lng:25.572666	, lat:60.93567 },
        { lng:25.572329	, lat:60.935765 },
        { lng:25.571245	, lat:60.936076 },
        { lng:25.564996	, lat:60.93189 },
        { lng:25.564232	, lat:60.932052 },
        { lng:25.564006	, lat:60.9321204 },
        { lng:25.556337	, lat:60.933691 },
        { lng:25.558311	, lat:60.935723 },
        { lng:25.558629	, lat:60.936041 },
        { lng:25.558822	, lat:60.936249 },
        { lng:25.559052	, lat:60.936476 },
        { lng:25.56004	, lat:60.937465 },
        { lng:25.557859	, lat:60.937962 },
        { lng:25.557785	, lat:60.937979 },
        { lng:25.557748	, lat:60.937988 },
        { lng:25.557729	, lat:60.937988 },
        { lng:25.557729	, lat:60.937997 },
        { lng:25.557746	, lat:60.938015 },
        { lng:25.557764	, lat:60.938033 },
        { lng:25.557781	, lat:60.938069 },
        { lng:25.557816	, lat:60.938105 },
        { lng:25.557833	, lat:60.938133 },
        { lng:25.557868	, lat:60.938178 },
        { lng:25.557885	, lat:60.938223 },
        { lng:25.557902	, lat:60.93825 },
        { lng:25.557937	, lat:60.938286 },
        { lng:25.557954	, lat:60.938322 },
        { lng:25.557989	, lat:60.93835 },
        { lng:25.558007	, lat:60.938377 },
        { lng:25.558042	, lat:60.938404 },
        { lng:25.558132	, lat:60.938459 },
        { lng:25.558168	, lat:60.938477 },
        { lng:25.558223	, lat:60.938487 },
        { lng:25.558259	, lat:60.938505 },
        { lng:25.558314	, lat:60.938515 },
        { lng:25.558351	, lat:60.938524 },
        { lng:25.558405	, lat:60.938543 },
        { lng:25.558911	, lat:60.938781 },
        { lng:25.558928	, lat:60.938799 },
        { lng:25.559167	, lat:60.938838 },
        { lng:25.559221	, lat:60.938856 },
        { lng:25.559257	, lat:60.938884 },
        { lng:25.559256	, lat:60.938893 },
        { lng:25.559292	, lat:60.93892 },
        { lng:25.559192	, lat:60.939099 },
        { lng:25.559172	, lat:60.939116 },
        { lng:25.559134	, lat:60.939143 },
        { lng:25.559115	, lat:60.939169 },
        { lng:25.559077	, lat:60.939187 },
        { lng:25.55902	, lat:60.939213 },
        { lng:25.558982	, lat:60.93924 },
        { lng:25.558926	, lat:60.939257 },
        { lng:25.558889	, lat:60.939275 },
        { lng:25.558852	, lat:60.939274 },
        { lng:25.558814	, lat:60.939283 },
        { lng:25.558759	, lat:60.939282 },
        { lng:25.558722	, lat:60.939282 },
        { lng:25.558704	, lat:60.939273 },
        { lng:25.558579	, lat:60.939191 },
        { lng:25.558542	, lat:60.939181 },
        { lng:25.558505	, lat:60.939181 },
        { lng:25.558431	, lat:60.939189 },
        { lng:25.558376	, lat:60.939189 },
        { lng:25.558302	, lat:60.939197 },
        { lng:25.558283	, lat:60.939197 },
        { lng:25.558246	, lat:60.939205 },
        { lng:25.558189	, lat:60.939231 },
        { lng:25.55817	, lat:60.939249 },
        { lng:25.558132	, lat:60.939285 },
        { lng:25.558076	, lat:60.939302 },
        { lng:25.558037	, lat:60.939329 },
        { lng:25.558	, lat:60.939346 },
        { lng:25.557906	, lat:60.939372 },
        { lng:25.557869	, lat:60.939372 },
        { lng:25.557796	, lat:60.939371 },
        { lng:25.557704	, lat:60.939361 },
        { lng:25.557667	, lat:60.939351 },
        { lng:25.557594	, lat:60.939333 },
        { lng:25.557521	, lat:60.939332 },
        { lng:25.557465	, lat:60.93934 },
        { lng:25.557428	, lat:60.93934 },
        { lng:25.557391	, lat:60.939349 },
        { lng:25.557353	, lat:60.939366 },
        { lng:25.557315	, lat:60.939393 },
        { lng:25.557296	, lat:60.939401 },
        { lng:25.55733	, lat:60.939474 },
        { lng:25.557346	, lat:60.939519 },
        { lng:25.557364	, lat:60.939528 },
        { lng:25.557345	, lat:60.939537 },
        { lng:25.557308	, lat:60.939554 },
        { lng:25.55727	, lat:60.939572 },
        { lng:25.557233	, lat:60.93958 },
        { lng:25.557195	, lat:60.939589 },
        { lng:25.557158	, lat:60.939597 },
        { lng:25.557102	, lat:60.939606 },
        { lng:25.557047	, lat:60.939605 },
        { lng:25.556991	, lat:60.939614 },
        { lng:25.556992	, lat:60.939605 },
        { lng:25.556936	, lat:60.939622 },
        { lng:25.55688	, lat:60.939639 },
        { lng:25.556879	, lat:60.939657 },
        { lng:25.55695	, lat:60.939712 },
        { lng:25.556987	, lat:60.939721 },
        { lng:25.557059	, lat:60.93974 },
        { lng:25.557114	, lat:60.939758 },
        { lng:25.557205	, lat:60.939777 },
        { lng:25.557297	, lat:60.939796 },
        { lng:25.557352	, lat:60.939806 },
        { lng:25.557479	, lat:60.939843 },
        { lng:25.557626	, lat:60.939863 },
        { lng:25.557681	, lat:60.939872 },
        { lng:25.557846	, lat:60.939883 },
        { lng:25.557884	, lat:60.939874 },
        { lng:25.55807	, lat:60.939832 },
        { lng:25.558126	, lat:60.939814 },
        { lng:25.558182	, lat:60.939797 },
        { lng:25.558238	, lat:60.939779 },
        { lng:25.558296	, lat:60.939726 },
        { lng:25.558556	, lat:60.939693 },
        { lng:25.558666	, lat:60.939712 },
        { lng:25.558684	, lat:60.939721 },
        { lng:25.558702	, lat:60.939722 },
        { lng:25.558738	, lat:60.93974 },
        { lng:25.558792	, lat:60.939785 },
        { lng:25.558844	, lat:60.939849 },
        { lng:25.558897	, lat:60.939912 },
        { lng:25.558911	, lat:60.939993 },
        { lng:25.558945	, lat:60.940074 },
        { lng:25.558942	, lat:60.940128 },
        { lng:25.558996	, lat:60.940174 },
        { lng:25.559031	, lat:60.94021 },
        { lng:25.559103	, lat:60.940246 },
        { lng:25.559175	, lat:60.940292 },
        { lng:25.559228	, lat:60.940338 },
        { lng:25.559283	, lat:60.940347 },
        { lng:25.559375	, lat:60.940357 },
        { lng:25.559448	, lat:60.940376 },
        { lng:25.559503	, lat:60.940385 },
        { lng:25.559609	, lat:60.940476 },
        { lng:25.559662	, lat:60.940531 },
        { lng:25.559734	, lat:60.940576 },
        { lng:25.559769	, lat:60.940613 },
        { lng:25.559842	, lat:60.940631 },
        { lng:25.559951	, lat:60.940659 },
        { lng:25.560062	, lat:60.940661 },
        { lng:25.560173	, lat:60.940662 },
        { lng:25.560246	, lat:60.940663 },
        { lng:25.560469	, lat:60.940629 },
        { lng:25.560505	, lat:60.940656 },
        { lng:25.560519	, lat:60.940746 },
        { lng:25.560535	, lat:60.940818 },
        { lng:25.560531	, lat:60.940899 },
        { lng:25.560529	, lat:60.940944 },
        { lng:25.560546	, lat:60.94098 },
        { lng:25.560581	, lat:60.941007 },
        { lng:25.5606	, lat:60.941016 },
        { lng:25.560654	, lat:60.941026 },
        { lng:25.560765	, lat:60.941036 },
        { lng:25.560913	, lat:60.941029 },
        { lng:25.561171	, lat:60.941023 },
        { lng:25.561356	, lat:60.941025 },
        { lng:25.561485	, lat:60.941026 },
        { lng:25.56165	, lat:60.941037 },
        { lng:25.561668	, lat:60.941046 },
        { lng:25.561722	, lat:60.941073 },
        { lng:25.561758	, lat:60.941101 },
        { lng:25.561793	, lat:60.941155 },
        { lng:25.561827	, lat:60.941218 },
        { lng:25.561825	, lat:60.941245 },
        { lng:25.561806	, lat:60.941272 },
        { lng:25.56173	, lat:60.941325 },
        { lng:25.561672	, lat:60.941369 },
        { lng:25.561578	, lat:60.941413 },
        { lng:25.561521	, lat:60.941448 },
        { lng:25.561463	, lat:60.941501 },
        { lng:25.561443	, lat:60.941546 },
        { lng:25.561404	, lat:60.941582 },
        { lng:25.561347	, lat:60.941626 },
        { lng:25.56129	, lat:60.941661 },
        { lng:25.561233	, lat:60.941696 },
        { lng:25.561158	, lat:60.941714 },
        { lng:25.560824	, lat:60.941764 },
        { lng:25.560768	, lat:60.941781 },
        { lng:25.56071	, lat:60.941834 },
        { lng:25.56071	, lat:60.941843 },
        { lng:25.56069	, lat:60.94187 },
        { lng:25.560707	, lat:60.941906 },
        { lng:25.560723	, lat:60.941951 },
        { lng:25.560757	, lat:60.942014 },
        { lng:25.560791	, lat:60.942078 },
        { lng:25.56086	, lat:60.942204 },
        { lng:25.560928	, lat:60.94233 },
        { lng:25.560944	, lat:60.942375 },
        { lng:25.56096	, lat:60.942438 },
        { lng:25.560939	, lat:60.942483 },
        { lng:25.56092	, lat:60.942501 },
        { lng:25.560882	, lat:60.942518 },
        { lng:25.560844	, lat:60.942554 },
        { lng:25.560824	, lat:60.942572 },
        { lng:25.560768	, lat:60.942607 },
        { lng:25.560619	, lat:60.942632 },
        { lng:25.560507	, lat:60.942658 },
        { lng:25.560358	, lat:60.942683 },
        { lng:25.560246	, lat:60.942709 },
        { lng:25.560134	, lat:60.942735 },
        { lng:25.560041	, lat:60.942752 },
        { lng:25.559855	, lat:60.942804 },
        { lng:25.559799	, lat:60.942812 },
        { lng:25.559705	, lat:60.942838 },
        { lng:25.559649	, lat:60.942864 },
        { lng:25.55963	, lat:60.942882 },
        { lng:25.55961	, lat:60.9429 },
        { lng:25.559591	, lat:60.942926 },
        { lng:25.559571	, lat:60.942953 },
        { lng:25.55957	, lat:60.942971 },
        { lng:25.559586	, lat:60.943025 },
        { lng:25.559621	, lat:60.943079 },
        { lng:25.559654	, lat:60.943161 },
        { lng:25.559651	, lat:60.943214 },
        { lng:25.559667	, lat:60.943277 },
        { lng:25.559682	, lat:60.943358 },
        { lng:25.559698	, lat:60.943403 },
        { lng:25.559695	, lat:60.943475 },
        { lng:25.559693	, lat:60.94352 },
        { lng:25.559692	, lat:60.943547 },
        { lng:25.559616	, lat:60.943582 },
        { lng:25.559577	, lat:60.943644 },
        { lng:25.559444	, lat:60.943715 },
        { lng:25.559314	, lat:60.943749 },
        { lng:25.559182	, lat:60.943802 },
        { lng:25.559051	, lat:60.943845 },
        { lng:25.558957	, lat:60.943871 },
        { lng:25.558864	, lat:60.943897 },
        { lng:25.558733	, lat:60.943932 },
        { lng:25.55864	, lat:60.943966 },
        { lng:25.558583	, lat:60.943993 },
        { lng:25.558489	, lat:60.944028 },
        { lng:25.558432	, lat:60.944063 },
        { lng:25.558374	, lat:60.944116 },
        { lng:25.558297	, lat:60.944196 },
        { lng:25.558294	, lat:60.944259 },
        { lng:25.558274	, lat:60.944286 },
        { lng:25.558309	, lat:60.944331 },
        { lng:25.558345	, lat:60.944367 },
        { lng:25.558398	, lat:60.944404 },
        { lng:25.558452	, lat:60.944431 },
        { lng:25.558507	, lat:60.944459 },
        { lng:25.558579	, lat:60.944495 },
        { lng:25.558633	, lat:60.944514 },
        { lng:25.558706	, lat:60.944533 },
        { lng:25.558834	, lat:60.944561 },
        { lng:25.558999	, lat:60.94459 },
        { lng:25.559164	, lat:60.944618 },
        { lng:25.55931	, lat:60.944656 },
        { lng:25.559437	, lat:60.944684 },
        { lng:25.559492	, lat:60.944703 },
        { lng:25.559672	, lat:60.944794 },
        { lng:25.55969	, lat:60.944812 },
        { lng:25.559707	, lat:60.944849 },
        { lng:25.559724	, lat:60.944876 },
        { lng:25.559685	, lat:60.944929 },
        { lng:25.559665	, lat:60.944956 },
        { lng:25.559645	, lat:60.944983 },
        { lng:25.559627	, lat:60.944991 },
        { lng:25.559569	, lat:60.945036 },
        { lng:25.559531	, lat:60.945071 },
        { lng:25.559474	, lat:60.945097 },
        { lng:25.559418	, lat:60.945124 },
        { lng:25.559211	, lat:60.945202 },
        { lng:25.559098	, lat:60.945255 },
        { lng:25.558947	, lat:60.945325 },
        { lng:25.558872	, lat:60.94536 },
        { lng:25.558852	, lat:60.945387 },
        { lng:25.558832	, lat:60.945414 },
        { lng:25.55883	, lat:60.945459 },
        { lng:25.558865	, lat:60.945513 },
        { lng:25.558918	, lat:60.945567 },
        { lng:25.558988	, lat:60.945649 },
        { lng:25.559003	, lat:60.945721 },
        { lng:25.559037	, lat:60.945784 },
        { lng:25.559054	, lat:60.94582 },
        { lng:25.559089	, lat:60.945865 },
        { lng:25.559142	, lat:60.94592 },
        { lng:25.559247	, lat:60.946037 },
        { lng:25.559336	, lat:60.94611 },
        { lng:25.55946	, lat:60.946228 },
        { lng:25.559585	, lat:60.946319 },
        { lng:25.559746	, lat:60.946438 },
        { lng:25.559961	, lat:60.946584 },
        { lng:25.560049	, lat:60.946665 },
        { lng:25.560119	, lat:60.946747 },
        { lng:25.560189	, lat:60.946837 },
        { lng:25.560203	, lat:60.946945 },
        { lng:25.5602	, lat:60.94699 },
        { lng:25.56016	, lat:60.94707 },
        { lng:25.560101	, lat:60.947142 },
        { lng:25.560024	, lat:60.947222 },
        { lng:25.559908	, lat:60.947328 },
        { lng:25.559811	, lat:60.947435 },
        { lng:25.559752	, lat:60.947515 },
        { lng:25.559713	, lat:60.947568 },
        { lng:25.559672	, lat:60.947658 },
        { lng:25.55967	, lat:60.947711 },
        { lng:25.559704	, lat:60.947757 },
        { lng:25.559868	, lat:60.947803 },
        { lng:25.55996	, lat:60.947822 },
        { lng:25.56007	, lat:60.947832 },
        { lng:25.560144	, lat:60.947842 },
        { lng:25.56029	, lat:60.947871 },
        { lng:25.560436	, lat:60.947899 },
        { lng:25.560601	, lat:60.947937 },
        { lng:25.560692	, lat:60.947956 },
        { lng:25.560783	, lat:60.947975 },
        { lng:25.560856	, lat:60.947993 },
        { lng:25.560911	, lat:60.948012 },
        { lng:25.560964	, lat:60.948057 },
        { lng:25.560981	, lat:60.948093 },
        { lng:25.560998	, lat:60.948139 },
        { lng:25.561014	, lat:60.948175 },
        { lng:25.561049	, lat:60.948229 },
        { lng:25.561062	, lat:60.948337 },
        { lng:25.561076	, lat:60.948436 },
        { lng:25.561072	, lat:60.948534 },
        { lng:25.561227	, lat:60.948778 },
        { lng:25.561471	, lat:60.949104 },
        { lng:25.561503	, lat:60.949194 },
        { lng:25.561519	, lat:60.949266 },
        { lng:25.561497	, lat:60.949329 },
        { lng:25.561473	, lat:60.949463 },
        { lng:25.561469	, lat:60.949544 },
        { lng:25.561484	, lat:60.949634 },
        { lng:25.561498	, lat:60.949715 },
        { lng:25.561566	, lat:60.949859 },
        { lng:25.561618	, lat:60.949931 },
        { lng:25.56167	, lat:60.950004 },
        { lng:25.561759	, lat:60.950085 },
        { lng:25.561793	, lat:60.95014 },
        { lng:25.561846	, lat:60.950203 },
        { lng:25.561881	, lat:60.950239 },
        { lng:25.561915	, lat:60.950294 },
        { lng:25.561986	, lat:60.950357 },
        { lng:25.562075	, lat:60.950439 },
        { lng:25.562144	, lat:60.950538 },
        { lng:25.562159	, lat:60.950628 },
        { lng:25.562154	, lat:60.950718 },
        { lng:25.562133	, lat:60.95079 },
        { lng:25.56211	, lat:60.950879 },
        { lng:25.562071	, lat:60.950924 },
        { lng:25.562085	, lat:60.951031 },
        { lng:25.56208	, lat:60.951148 },
        { lng:25.562092	, lat:60.951274 },
        { lng:25.562125	, lat:60.951382 },
        { lng:25.562138	, lat:60.95149 },
        { lng:25.562134	, lat:60.95157 },
        { lng:25.562204	, lat:60.95167 },
        { lng:25.562291	, lat:60.951779 },
        { lng:25.562398	, lat:60.951861 },
        { lng:25.562488	, lat:60.951924 },
        { lng:25.562596	, lat:60.951979 },
        { lng:25.562797	, lat:60.952026 },
        { lng:25.562962	, lat:60.952055 },
        { lng:25.563183	, lat:60.952066 },
        { lng:25.563385	, lat:60.952077 },
        { lng:25.56355	, lat:60.952115 },
        { lng:25.56362	, lat:60.952197 },
        { lng:25.563596	, lat:60.952322 },
        { lng:25.563554	, lat:60.952429 },
        { lng:25.563458	, lat:60.952509 },
        { lng:25.563361	, lat:60.952607 },
        { lng:25.563265	, lat:60.952696 },
        { lng:25.563168	, lat:60.952793 },
        { lng:25.563165	, lat:60.952856 },
        { lng:25.563218	, lat:60.952919 },
        { lng:25.563254	, lat:60.952938 },
        { lng:25.563345	, lat:60.952975 },
        { lng:25.563454	, lat:60.953003 },
        { lng:25.5636	, lat:60.953031 },
        { lng:25.563766	, lat:60.953051 },
        { lng:25.563895	, lat:60.953043 },
        { lng:25.564063	, lat:60.953018 },
        { lng:25.564267	, lat:60.952984 },
        { lng:25.564434	, lat:60.952977 },
        { lng:25.564581	, lat:60.952979 },
        { lng:25.564709	, lat:60.953007 },
        { lng:25.564819	, lat:60.953035 },
        { lng:25.564872	, lat:60.953072 },
        { lng:25.564962	, lat:60.953118 },
        { lng:25.565013	, lat:60.953235 },
        { lng:25.565045	, lat:60.953343 },
        { lng:25.565076	, lat:60.95346 },
        { lng:25.565125	, lat:60.953613 },
        { lng:25.565158	, lat:60.953685 },
        { lng:25.565154	, lat:60.953793 },
        { lng:25.56513	, lat:60.9539 },
        { lng:25.565108	, lat:60.953981 },
        { lng:25.565048	, lat:60.954088 },
        { lng:25.565007	, lat:60.954186 },
        { lng:25.565039	, lat:60.954276 },
        { lng:25.565092	, lat:60.954331 },
        { lng:25.565163	, lat:60.954412 },
        { lng:25.565233	, lat:60.954494 },
        { lng:25.565322	, lat:60.954558 },
        { lng:25.565394	, lat:60.954594 },
        { lng:25.565428	, lat:60.954657 },
        { lng:25.565445	, lat:60.954693 },
        { lng:25.565444	, lat:60.95472 },
        { lng:25.565442	, lat:60.954765 },
        { lng:25.565401	, lat:60.954855 },
        { lng:25.56536	, lat:60.954953 },
        { lng:25.56532	, lat:60.955006 },
        { lng:25.565263	, lat:60.955051 },
        { lng:25.565241	, lat:60.95514 },
        { lng:25.565219	, lat:60.955203 },
        { lng:25.565217	, lat:60.955256 },
        { lng:25.565214	, lat:60.95531 },
        { lng:25.565265	, lat:60.95541 },
        { lng:25.565373	, lat:60.955474 },
        { lng:25.5657	, lat:60.955603 },
        { lng:25.5659	, lat:60.955668 },
        { lng:25.566065	, lat:60.955696 },
        { lng:25.566211	, lat:60.955716 },
        { lng:25.566432	, lat:60.955736 },
        { lng:25.566616	, lat:60.955756 },
        { lng:25.566799	, lat:60.955785 },
        { lng:25.566926	, lat:60.955822 },
        { lng:25.567018	, lat:60.955841 },
        { lng:25.567109	, lat:60.955869 },
        { lng:25.567198	, lat:60.955942 },
        { lng:25.567232	, lat:60.956005 },
        { lng:25.567248	, lat:60.956059 },
        { lng:25.567246	, lat:60.956104 },
        { lng:25.567223	, lat:60.956202 },
        { lng:25.567201	, lat:60.956292 },
        { lng:25.567142	, lat:60.956372 },
        { lng:25.567065	, lat:60.956434 },
        { lng:25.566934	, lat:60.956469 },
        { lng:25.56684	, lat:60.956521 },
        { lng:25.5668	, lat:60.956575 },
        { lng:25.566761	, lat:60.956637 },
        { lng:25.56672	, lat:60.956709 },
        { lng:25.566718	, lat:60.956762 },
        { lng:25.566733	, lat:60.956834 },
        { lng:25.566749	, lat:60.956897 },
        { lng:25.566801	, lat:60.956961 },
        { lng:25.566836	, lat:60.957006 },
        { lng:25.566908	, lat:60.957061 },
        { lng:25.566943	, lat:60.957106 },
        { lng:25.567032	, lat:60.957179 },
        { lng:25.567121	, lat:60.957242 },
        { lng:25.56721	, lat:60.957306 },
        { lng:25.567246	, lat:60.957343 },
        { lng:25.567318	, lat:60.957388 },
        { lng:25.567425	, lat:60.957452 },
        { lng:25.567515	, lat:60.957507 },
        { lng:25.567661	, lat:60.957553 },
        { lng:25.567807	, lat:60.957591 },
        { lng:25.567953	, lat:60.957619 },
        { lng:25.568008	, lat:60.957638 },
        { lng:25.568045	, lat:60.957638 },
        { lng:25.568173	, lat:60.957658 },
        { lng:25.568376	, lat:60.95766 },
        { lng:25.568487	, lat:60.957661 },
        { lng:25.568617	, lat:60.957626 },
        { lng:25.568711	, lat:60.957591 },
        { lng:25.568824	, lat:60.957539 },
        { lng:25.568992	, lat:60.957514 },
        { lng:25.569177	, lat:60.957498 },
        { lng:25.569325	, lat:60.957481 },
        { lng:25.569473	, lat:60.957483 },
        { lng:25.569583	, lat:60.957502 },
        { lng:25.569656	, lat:60.957521 },
        { lng:25.569727	, lat:60.957575 },
        { lng:25.569834	, lat:60.957657 },
        { lng:25.569885	, lat:60.957757 },
        { lng:25.569899	, lat:60.957855 },
        { lng:25.569915	, lat:60.957927 },
        { lng:25.569911	, lat:60.957999 },
        { lng:25.569908	, lat:60.958071 },
        { lng:25.569886	, lat:60.958143 },
        { lng:25.569847	, lat:60.958196 },
        { lng:25.569825	, lat:60.958268 },
        { lng:25.569805	, lat:60.958321 },
        { lng:25.5698	, lat:60.958429 },
        { lng:25.56974	, lat:60.958518 },
        { lng:25.569664	, lat:60.958571 },
        { lng:25.56957	, lat:60.958624 },
        { lng:25.569493	, lat:60.958686 },
        { lng:25.569472	, lat:60.958748 },
        { lng:25.56945	, lat:60.95882 },
        { lng:25.569448	, lat:60.958874 },
        { lng:25.569464	, lat:60.958919 },
        { lng:25.569499	, lat:60.958955 },
        { lng:25.569535	, lat:60.958991 },
        { lng:25.569644	, lat:60.959029 },
        { lng:25.569789	, lat:60.959075 },
        { lng:25.569862	, lat:60.959094 },
        { lng:25.569953	, lat:60.959122 },
        { lng:25.570026	, lat:60.95914 },
        { lng:25.570649	, lat:60.959255 },
        { lng:25.570721	, lat:60.959282 },
        { lng:25.57083	, lat:60.959328 },
        { lng:25.570938	, lat:60.959392 },
        { lng:25.571027	, lat:60.959456 },
        { lng:25.57108	, lat:60.959519 },
        { lng:25.571159	, lat:60.959825 },
        { lng:25.571227	, lat:60.959943 },
        { lng:25.571241	, lat:60.960051 },
        { lng:25.571217	, lat:60.960167 },
        { lng:25.571171	, lat:60.960364 },
        { lng:25.571151	, lat:60.960409 },
        { lng:25.571129	, lat:60.96048 },
        { lng:25.57109	, lat:60.960525 },
        { lng:25.571032	, lat:60.960587 },
        { lng:25.570992	, lat:60.960667 },
        { lng:25.570952	, lat:60.96073 },
        { lng:25.570915	, lat:60.960729 },
        { lng:25.570879	, lat:60.960711 },
        { lng:25.570806	, lat:60.960683 },
        { lng:25.570752	, lat:60.960656 },
        { lng:25.570753	, lat:60.960629 },
        { lng:25.570633	, lat:60.96043 },
        { lng:25.570616	, lat:60.960403 },
        { lng:25.570581	, lat:60.960358 },
        { lng:25.570601	, lat:60.960322 },
        { lng:25.570585	, lat:60.960268 },
        { lng:25.570569	, lat:60.960214 },
        { lng:25.570552	, lat:60.960178 },
        { lng:25.570617	, lat:60.959963 },
        { lng:25.570637	, lat:60.959937 },
        { lng:25.570639	, lat:60.959892 },
        { lng:25.570622	, lat:60.959856 },
        { lng:25.570624	, lat:60.959811 },
        { lng:25.570589	, lat:60.959766 },
        { lng:25.570571	, lat:60.959756 },
        { lng:25.570554	, lat:60.95972 },
        { lng:25.570556	, lat:60.959675 },
        { lng:25.570539	, lat:60.959648 },
        { lng:25.570171	, lat:60.959627 },
        { lng:25.570004	, lat:60.959643 },
        { lng:25.569929	, lat:60.95966 },
        { lng:25.569723	, lat:60.959721 },
        { lng:25.569631	, lat:60.959729 },
        { lng:25.569575	, lat:60.959737 },
        { lng:25.569501	, lat:60.959727 },
        { lng:25.569465	, lat:60.959718 },
        { lng:25.569355	, lat:60.95969 },
        { lng:25.569246	, lat:60.959653 },
        { lng:25.569137	, lat:60.959625 },
        { lng:25.568991	, lat:60.959587 },
        { lng:25.5689	, lat:60.959559 },
        { lng:25.568641	, lat:60.959574 },
        { lng:25.568603	, lat:60.959592 },
        { lng:25.568565	, lat:60.959609 },
        { lng:25.568528	, lat:60.959618 },
        { lng:25.568509	, lat:60.959636 },
        { lng:25.568471	, lat:60.959653 },
        { lng:25.568451	, lat:60.95968 },
        { lng:25.568451	, lat:60.959698 },
        { lng:25.568449	, lat:60.959725 },
        { lng:25.568501	, lat:60.959797 },
        { lng:25.568518	, lat:60.959833 },
        { lng:25.568554	, lat:60.959861 },
        { lng:25.568643	, lat:60.959924 },
        { lng:25.568679	, lat:60.959952 },
        { lng:25.568733	, lat:60.959979 },
        { lng:25.568769	, lat:60.960007 },
        { lng:25.568804	, lat:60.960043 },
        { lng:25.568839	, lat:60.960097 },
        { lng:25.568856	, lat:60.960124 },
        { lng:25.568872	, lat:60.960169 },
        { lng:25.56889	, lat:60.960187 },
        { lng:25.568908	, lat:60.960206 },
        { lng:25.568943	, lat:60.960233 },
        { lng:25.569088	, lat:60.960297 },
        { lng:25.569177	, lat:60.96037 },
        { lng:25.569176	, lat:60.960397 },
        { lng:25.569138	, lat:60.960432 },
        { lng:25.569099	, lat:60.960459 },
        { lng:25.568969	, lat:60.960493 },
        { lng:25.568805	, lat:60.960447 },
        { lng:25.56875	, lat:60.960428 },
        { lng:25.568695	, lat:60.960419 },
        { lng:25.56864	, lat:60.9604 },
        { lng:25.568494	, lat:60.960381 },
        { lng:25.56842	, lat:60.960371 },
        { lng:25.568365	, lat:60.960379 },
        { lng:25.568309	, lat:60.960388 },
        { lng:25.568271	, lat:60.960414 },
        { lng:25.568251	, lat:60.960441 },
        { lng:25.568213	, lat:60.960467 },
        { lng:25.568138	, lat:60.960485 },
        { lng:25.568027	, lat:60.960501 },
        { lng:25.567971	, lat:60.960501 },
        { lng:25.567898	, lat:60.9605 },
        { lng:25.56786	, lat:60.960509 },
        { lng:25.567786	, lat:60.960508 },
        { lng:25.567713	, lat:60.960498 },
        { lng:25.567658	, lat:60.960479 },
        { lng:25.567531	, lat:60.960442 },
        { lng:25.567327	, lat:60.960458 },
        { lng:25.567181	, lat:60.96043 },
        { lng:25.567106	, lat:60.960438 },
        { lng:25.566958	, lat:60.960445 },
        { lng:25.566772	, lat:60.960479 },
        { lng:25.566642	, lat:60.960514 },
        { lng:25.566156	, lat:60.960634 },
        { lng:25.565988	, lat:60.960686 },
        { lng:25.565838	, lat:60.960738 },
        { lng:25.565762	, lat:60.960782 },
        { lng:25.565703	, lat:60.960854 },
        { lng:25.565702	, lat:60.960881 },
        { lng:25.565661	, lat:60.960979 },
        { lng:25.565639	, lat:60.961041 },
        { lng:25.565638	, lat:60.961077 },
        { lng:25.565654	, lat:60.961131 },
        { lng:25.565666	, lat:60.961275 },
        { lng:25.565646	, lat:60.961293 },
        { lng:25.565609	, lat:60.96131 },
        { lng:25.565553	, lat:60.96131 },
        { lng:25.565498	, lat:60.9613 },
        { lng:25.565444	, lat:60.961282 },
        { lng:25.565426	, lat:60.961264 },
        { lng:25.565372	, lat:60.961236 },
        { lng:25.565355	, lat:60.961209 },
        { lng:25.565336	, lat:60.961209 },
        { lng:25.565282	, lat:60.96119 },
        { lng:25.565226	, lat:60.96119 },
        { lng:25.565171	, lat:60.961198 },
        { lng:25.565	, lat:60.961304 },
        { lng:25.564921	, lat:60.961411 },
        { lng:25.564883	, lat:60.961428 },
        { lng:25.564865	, lat:60.961437 },
        { lng:25.564809	, lat:60.961445 },
        { lng:25.564753	, lat:60.961445 },
        { lng:25.564366	, lat:60.961441 },
        { lng:25.564217	, lat:60.961457 },
        { lng:25.564124	, lat:60.961474 },
        { lng:25.564087	, lat:60.961483 },
        { lng:25.564013	, lat:60.961491 },
        { lng:25.563938	, lat:60.961517 },
        { lng:25.563899	, lat:60.961552 },
        { lng:25.563861	, lat:60.961579 },
        { lng:25.563768	, lat:60.961587 },
        { lng:25.563695	, lat:60.961586 },
        { lng:25.563474	, lat:60.961566 },
        { lng:25.563364	, lat:60.961556 },
        { lng:25.563236	, lat:60.961527 },
        { lng:25.563144	, lat:60.961517 },
        { lng:25.562979	, lat:60.961489 },
        { lng:25.562249	, lat:60.961292 },
        { lng:25.562176	, lat:60.961283 },
        { lng:25.562103	, lat:60.961273 },
        { lng:25.562048	, lat:60.961263 },
        { lng:25.561866	, lat:60.96119 },
        { lng:25.561828	, lat:60.961216 },
        { lng:25.56162	, lat:60.961331 },
        { lng:25.561291	, lat:60.961246 },
        { lng:25.560855	, lat:60.961502 },
        { lng:25.560462	, lat:60.962036 },
        { lng:25.560753	, lat:60.962138 },
        { lng:25.561589	, lat:60.962416 },
        { lng:25.561735	, lat:60.962463 },
        { lng:25.561715	, lat:60.962499 },
        { lng:25.562119	, lat:60.962952 },
        { lng:25.562119	, lat:60.962961 },
        { lng:25.562098	, lat:60.963005 },
        { lng:25.56271	, lat:60.963362 },
        { lng:25.563076	, lat:60.963429 },
        { lng:25.563488	, lat:60.963711 },
        { lng:25.563808	, lat:60.963993 },
        { lng:25.563801	, lat:60.964136 },
        { lng:25.564068	, lat:60.964364 },
        { lng:25.564179	, lat:60.964347 },
        { lng:25.564429	, lat:60.96496 },
        { lng:25.563754	, lat:60.964773 },
        { lng:25.562681	, lat:60.966045 },
        { lng:25.5627754	, lat:60.9665439 },
        { lng:25.562359	, lat:60.9668869 },
        { lng:25.5621859	, lat:60.9670296 },
        { lng:25.5618501	, lat:60.9673107 },
        { lng:25.5611072	, lat:60.9679328 },
        { lng:25.558242	, lat:60.9671715 },
        { lng:25.5574296	, lat:60.9684378 },
        { lng:25.5572958	, lat:60.9686434 },
        { lng:25.556785	, lat:60.969429 },
        { lng:25.556507	, lat:60.969866 },
        { lng:25.556069	, lat:60.970552 },
        { lng:25.556194	, lat:60.970652 },
        { lng:25.555995	, lat:60.971368 },
        { lng:25.552715	, lat:60.97285 },
        { lng:25.551055	, lat:60.973604 },
        { lng:25.548679	, lat:60.974674 },
        { lng:25.551533	, lat:60.976087 },
        { lng:25.551659	, lat:60.976151 },
        { lng:25.551696	, lat:60.97616 },
        { lng:25.550464	, lat:60.976838 },
        { lng:25.550127	, lat:60.976951 },
        { lng:25.549172	, lat:60.977228 },
        { lng:25.548886	, lat:60.977422 },
        { lng:25.548789	, lat:60.977538 },
        { lng:25.548476	, lat:60.977912 },
        { lng:25.548377	, lat:60.978063 },
        { lng:25.548437	, lat:60.978369 },
        { lng:25.548467	, lat:60.978522 },
        { lng:25.548482	, lat:60.978585 },
        { lng:25.548402	, lat:60.978728 },
        { lng:25.548297	, lat:60.979005 },
        { lng:25.552255	, lat:60.97929 },
        { lng:25.552679	, lat:60.97933 },
        { lng:25.552463	, lat:60.979606 },
        { lng:25.551418	, lat:60.981049 },
        { lng:25.550887	, lat:60.981752 },
        { lng:25.550769	, lat:60.981904 },
        { lng:25.550729	, lat:60.981975 },
        { lng:25.5507942	, lat:60.9820021 },
        { lng:25.550929	, lat:60.982058 },
        { lng:25.550755	, lat:60.982218 },
        { lng:25.550577	, lat:60.982476 },
        { lng:25.550098	, lat:60.982444 },
        { lng:25.549749	, lat:60.982404 },
        { lng:25.549705	, lat:60.982565 },
        { lng:25.549235	, lat:60.983548 },
        { lng:25.548949	, lat:60.984137 },
        { lng:25.548786	, lat:60.984485 },
        { lng:25.547024	, lat:60.984215 },
        { lng:25.5462794	, lat:60.985763 },
        { lng:25.545963	, lat:60.986421 },
        { lng:25.545942	, lat:60.986474 },
        { lng:25.545718	, lat:60.986939 },
        { lng:25.545533	, lat:60.987332 },
        { lng:25.5454371	, lat:60.9875372 },
        { lng:25.545391	, lat:60.9876361 },
        { lng:25.545258	, lat:60.9878849 },
        { lng:25.545208	, lat:60.987983 },
        { lng:25.544779	, lat:60.988885 },
        { lng:25.5447617	, lat:60.9889496 },
        { lng:25.5447479	, lat:60.9889771 },
        { lng:25.544716	, lat:60.989037 },
        { lng:25.544206	, lat:60.9901 },
        { lng:25.543818	, lat:60.990903 },
        { lng:25.542734	, lat:60.99318 },
        { lng:25.5424524	, lat:60.9937746 },
        { lng:25.5423173	, lat:60.9940602 },
        { lng:25.5422277	, lat:60.99425 },
        { lng:25.5422214	, lat:60.9942632 },
        { lng:25.5421847	, lat:60.9943402 },
        { lng:25.5417464	, lat:60.9952473 },
        { lng:25.541303	, lat:60.996181 },
        { lng:25.540835	, lat:60.997118 },
        { lng:25.5403066	, lat:60.9982644 },
      ],
    },
    {
      id: 16,
      regionName: 'eCom TP  Lappeenranta kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:27.7138414	, lat:60.7953333 },
        { lng:27.7187523	, lat:60.8011529 },
        { lng:27.7190212	, lat:60.8168282 },
        { lng:27.7507083	, lat:60.8162685 },
        { lng:27.7457241	, lat:60.8197503 },
        { lng:27.7464921	, lat:60.8292905 },
        { lng:27.7437011	, lat:60.8316389 },
        { lng:27.7668809	, lat:60.8337444 },
        { lng:27.7670703	, lat:60.8333333 },
        { lng:27.7650987	, lat:60.8294681 },
        { lng:27.7599886	, lat:60.8255484 },
        { lng:27.7607579	, lat:60.824216 },
        { lng:27.8084926	, lat:60.8231253 },
        { lng:27.8120614	, lat:60.82184 },
        { lng:27.8157574	, lat:60.8219026 },
        { lng:27.8296772	, lat:60.8232429 },
        { lng:27.8455781	, lat:60.8247078 },
        { lng:27.836876	, lat:60.8293183 },
        { lng:27.8474452	, lat:60.835838 },
        { lng:27.8533941	, lat:60.8383998 },
        { lng:27.8762231	, lat:60.8485899 },
        { lng:27.8637951	, lat:60.8546392 },
        { lng:27.8645489	, lat:60.8548319 },
        { lng:27.8576639	, lat:60.8585576 },
        { lng:27.8573995	, lat:60.8582847 },
        { lng:27.8549739	, lat:60.8600238 },
        { lng:27.8717187	, lat:60.8753208 },
        { lng:27.8833844	, lat:60.8781089 },
        { lng:27.8947518	, lat:60.8868741 },
        { lng:27.8996943	, lat:60.87564 },
        { lng:27.9011743	, lat:60.8737247 },
        { lng:27.8834186	, lat:60.8653493 },
        { lng:27.8846664	, lat:60.8643336 },
        { lng:27.8850038	, lat:60.8634074 },
        { lng:27.8860571	, lat:60.8623 },
        { lng:27.8889008	, lat:60.8515331 },
        { lng:27.8947591	, lat:60.8488514 },
        { lng:27.8932785	, lat:60.8479211 },
        { lng:27.8935145	, lat:60.8475448 },
        { lng:27.8951453	, lat:60.8474194 },
        { lng:27.896267	, lat:60.8479276 },
        { lng:27.9010966	, lat:60.8477925 },
        { lng:27.9045652	, lat:60.850137 },
        { lng:27.9053377	, lat:60.8532202 },
        { lng:27.9043506	, lat:60.855791 },
        { lng:27.9115829	, lat:60.8580267 },
        { lng:27.9266512	, lat:60.87042 },
        { lng:27.9317484	, lat:60.8757818 },
        { lng:27.9332147	, lat:60.8793173 },
        { lng:27.9312592	, lat:60.8796837 },
        { lng:27.9319757	, lat:60.8841631 },
        { lng:27.9153381	, lat:60.8866008 },
        { lng:27.9034709	, lat:60.8908735 },
        { lng:27.901084	, lat:60.8911246 },
        { lng:27.8984283	, lat:60.8961968 },
        { lng:27.8956303	, lat:60.9010018 },
        { lng:27.8937935	, lat:60.9043469 },
        { lng:27.8928708	, lat:60.9064755 },
        { lng:27.8913383	, lat:60.9088494 },
        { lng:27.8945015	, lat:60.9160416 },
        { lng:27.9024838	, lat:60.9278257 },
        { lng:27.9020117	, lat:60.9288475 },
        { lng:27.900853	, lat:60.9375834 },
        { lng:27.8990935	, lat:60.939199 },
        { lng:27.8949093	, lat:60.9463065 },
        { lng:27.8960465	, lat:60.9496253 },
        { lng:27.8968834	, lat:60.9495836 },
        { lng:27.89757	, lat:60.951631 },
        { lng:27.8967331	, lat:60.9517768 },
        { lng:27.8981493	, lat:60.9557148 },
        { lng:27.9036425	, lat:60.9659222 },
        { lng:27.9008959	, lat:60.9689214 },
        { lng:27.8977631	, lat:60.971587 },
        { lng:27.9042888	, lat:60.9827469 },
        { lng:27.9082738	, lat:60.9895602 },
        { lng:27.9012069	, lat:61.0008533 },
        { lng:27.8964542	, lat:61.0006653 },
        { lng:27.8896736	, lat:61.0049093 },
        { lng:27.8872703	, lat:61.00389 },
        { lng:27.8840304	, lat:61.0032786 },
        { lng:27.8841161	, lat:61.0013519 },
        { lng:27.8847383	, lat:61.0009462 },
        { lng:27.8858541	, lat:60.9990529 },
        { lng:27.8847812	, lat:60.9977525 },
        { lng:27.8830861	, lat:60.9968317 },
        { lng:27.8804039	, lat:60.9965664 },
        { lng:27.8783332	, lat:60.9960982 },
        { lng:27.8771852	, lat:60.9962023 },
        { lng:27.8743636	, lat:60.9953335 },
        { lng:27.8722499	, lat:60.9952867 },
        { lng:27.8703486	, lat:60.9939572 },
        { lng:27.8575794	, lat:60.9937609 },
        { lng:27.861049	, lat:60.9963688 },
        { lng:27.8605448	, lat:60.9970294 },
        { lng:27.8563069	, lat:61.0018512 },
        { lng:27.8564571	, lat:61.0113523 },
        { lng:27.8593807	, lat:61.0146279 },
        { lng:27.8729044	, lat:61.0177055 },
        { lng:27.8661721	, lat:61.0252477 },
        { lng:27.8662418	, lat:61.025401 },
        { lng:27.8667461	, lat:61.0255803 },
        { lng:27.8675453	, lat:61.025583 },
        { lng:27.8671068	, lat:61.0266283 },
        { lng:27.8775393	, lat:61.0288908 },
        { lng:27.8840087	, lat:61.030216 },
        { lng:27.8965186	, lat:61.0270771 },
        { lng:27.9043721	, lat:61.028657 },
        { lng:27.9101227	, lat:61.0290831 },
        { lng:27.9139422	, lat:61.0294885 },
        { lng:27.9179762	, lat:61.0306109 },
        { lng:27.9202507	, lat:61.030798 },
        { lng:27.9244565	, lat:61.0286362 },
        { lng:27.9286622	, lat:61.0243122 },
        { lng:27.9342304	, lat:61.0203879 },
        { lng:27.9415797	, lat:61.0200812 },
        { lng:27.9419445	, lat:61.0238236 },
        { lng:27.9424197	, lat:61.0275599 },
        { lng:27.9377697	, lat:61.0341638 },
        { lng:27.9371653	, lat:61.0350884 },
        { lng:27.9298436	, lat:61.03814 },
        { lng:27.931661	, lat:61.038651 },
        { lng:27.9490389	, lat:61.0411043 },
        { lng:27.9576219	, lat:61.0396566 },
        { lng:27.9659127	, lat:61.0425085 },
        { lng:27.9673611	, lat:61.0428331 },
        { lng:27.9836099	, lat:61.0438616 },
        { lng:27.9870002	, lat:61.0433006 },
        { lng:27.9893016	, lat:61.0440979 },
        { lng:27.9909216	, lat:61.0462483 },
        { lng:27.990707	, lat:61.0463158 },
        { lng:27.9897415	, lat:61.0471104 },
        { lng:27.9884218	, lat:61.0478843 },
        { lng:27.9872953	, lat:61.0497591 },
        { lng:27.9860721	, lat:61.0510627 },
        { lng:27.984377	, lat:61.0512184 },
        { lng:27.9816519	, lat:61.0502941 },
        { lng:27.9781435	, lat:61.0504966 },
        { lng:27.9769861	, lat:61.0510073 },
        { lng:27.9907285	, lat:61.0610629 },
        { lng:27.9911568	, lat:61.0615935 },
        { lng:27.9945318	, lat:61.065779 },
        { lng:27.9958676	, lat:61.0679097 },
        { lng:27.9984854	, lat:61.0706085 },
        { lng:28.0047296	, lat:61.072591 },
        { lng:28.0087422	, lat:61.0727104 },
        { lng:28.0080556	, lat:61.0685273 },
        { lng:28.0136775	, lat:61.0676709 },
        { lng:28.0322023	, lat:61.0743 },
        { lng:28.0355428	, lat:61.075715 },
        { lng:28.0413453	, lat:61.0787425 },
        { lng:28.0525068	, lat:61.083296 },
        { lng:28.0500165	, lat:61.0952789 },
        { lng:28.0613457	, lat:61.0999178 },
        { lng:28.0662273	, lat:61.1018258 },
        { lng:28.0666887	, lat:61.1018673 },
        { lng:28.0665062	, lat:61.1021162 },
        { lng:28.0671607	, lat:61.1053305 },
        { lng:28.0690543	, lat:61.1125513 },
        { lng:28.0901097	, lat:61.1088932 },
        { lng:28.0978345	, lat:61.1047706 },
        { lng:28.106278	, lat:61.0992541 },
        { lng:28.1045936	, lat:61.0960651 },
        { lng:28.11124	, lat:61.0920551 },
        { lng:28.1280658	, lat:61.0962508 },
        { lng:28.1344734	, lat:61.1042055 },
        { lng:28.1463943	, lat:61.1009266 },
        { lng:28.1600832	, lat:61.0940218 },
        { lng:28.1625125	, lat:61.0950408 },
        { lng:28.1579565	, lat:61.1034538 },
        { lng:28.1575941	, lat:61.1072797 },
        { lng:28.1594365	, lat:61.1103393 },
        { lng:28.155582	, lat:61.1135938 },
        { lng:28.1426166	, lat:61.1183401 },
        { lng:28.1347629	, lat:61.1259494 },
        { lng:28.1351708	, lat:61.1297179 },
        { lng:28.1565736	, lat:61.1326076 },
        { lng:28.1714361	, lat:61.1267142 },
        { lng:28.1831072	, lat:61.1166508 },
        { lng:28.1847809	, lat:61.1110533 },
        { lng:28.1875919	, lat:61.1089489 },
        { lng:28.1952415	, lat:61.1013864 },
        { lng:28.2056521	, lat:61.1061629 },
        { lng:28.2067424	, lat:61.1071709 },
        { lng:28.2110465	, lat:61.1077114 },
        { lng:28.2208753	, lat:61.1084054 },
        { lng:28.2347993	, lat:61.1102012 },
        { lng:28.2355498	, lat:61.1174799 },
        { lng:28.2443474	, lat:61.1209207 },
        { lng:28.2501839	, lat:61.1249518 },
        { lng:28.2604407	, lat:61.1291377 },
        { lng:28.282037	, lat:61.1316506 },
        { lng:28.2902668	, lat:61.1336028 },
        { lng:28.2983026	, lat:61.132684 },
        { lng:28.307153	, lat:61.1228449 },
        { lng:28.3311616	, lat:61.1294192 },
        { lng:28.3319245	, lat:61.1291697 },
        { lng:28.3339866	, lat:61.1306317 },
        { lng:28.3302786	, lat:61.131904 },
        { lng:28.3472267	, lat:61.1433375 },
        { lng:28.3900984	, lat:61.1669102 },
        { lng:28.3923954	, lat:61.1660199 },
        { lng:28.4091881	, lat:61.176222 },
        { lng:28.3685825	, lat:61.1901999 },
        { lng:28.3156894	, lat:61.2084841 },
        { lng:28.3021906	, lat:61.2405742 },
        { lng:28.309291	, lat:61.2475199 },
        { lng:28.3979658	, lat:61.2846964 },
        { lng:28.4110844	, lat:61.2787676 },
        { lng:28.469019	, lat:61.2556307 },
        { lng:28.4863034	, lat:61.2490991 },
        { lng:28.5046809	, lat:61.2412489 },
        { lng:28.593666	, lat:61.2017793 },
        { lng:28.5911882	, lat:61.2000277 },
        { lng:28.5918152	, lat:61.1993249 },
        { lng:28.5934526	, lat:61.1993679 },
        { lng:28.595786	, lat:61.2008293 },
        { lng:28.5981699	, lat:61.199772 },
        { lng:28.601973	, lat:61.201002 },
        { lng:28.602367	, lat:61.198142 },
        { lng:28.607526	, lat:61.198584 },
        { lng:28.605052	, lat:61.2019 },
        { lng:28.652185	, lat:61.216275 },
        { lng:28.650764	, lat:61.222569 },
        { lng:28.639924	, lat:61.228627 },
        { lng:28.6903029	, lat:61.2375 },
        { lng:28.7016649	, lat:61.231566 },
        { lng:28.6685769	, lat:61.221327 },
        { lng:28.6703497	, lat:61.2208458 },
        { lng:28.6984949	, lat:61.213206 },
        { lng:28.7055229	, lat:61.211987 },
        { lng:28.6971419	, lat:61.207782 },
        { lng:28.7176429	, lat:61.198328 },
        { lng:28.7182309	, lat:61.197162 },
        { lng:28.7300889	, lat:61.192135 },
        { lng:28.7215122	, lat:61.188069 },
        { lng:28.7186819	, lat:61.18665 },
        { lng:28.7195327	, lat:61.186392 },
        { lng:28.7211039	, lat:61.186035 },
        { lng:28.7295924	, lat:61.1813855 },
        { lng:28.7297649	, lat:61.181291 },
        { lng:28.7353519	, lat:61.17823 },
        { lng:28.7317653	, lat:61.1736222 },
        { lng:28.731478	, lat:61.1731683 },
        { lng:28.7313979	, lat:61.1730595 },
        { lng:28.7311249	, lat:61.172707 },
        { lng:28.7304589	, lat:61.168702 },
        { lng:28.7298289	, lat:61.164992 },
        { lng:28.7289189	, lat:61.158556 },
        { lng:28.7370299	, lat:61.158354 },
        { lng:28.7368769	, lat:61.157943 },
        { lng:28.7375069	, lat:61.157908 },
        { lng:28.7374553	, lat:61.1577467 },
        { lng:28.7366469	, lat:61.155108 },
        { lng:28.7375291	, lat:61.1550398 },
        { lng:28.7377731	, lat:61.1549635 },
        { lng:28.7378254	, lat:61.1548346 },
        { lng:28.7376642	, lat:61.1547276 },
        { lng:28.7377903	, lat:61.1546463 },
        { lng:28.7378499	, lat:61.1544052 },
        { lng:28.7381006	, lat:61.1542883 },
        { lng:28.7383227	, lat:61.1543338 },
        { lng:28.7386478	, lat:61.1543087 },
        { lng:28.7388479	, lat:61.154245 },
        { lng:28.7372859	, lat:61.148149 },
        { lng:28.7419251	, lat:61.1455797 },
        { lng:28.7430227	, lat:61.1449494 },
        { lng:28.745175	, lat:61.1437613 },
        { lng:28.7452729	, lat:61.143727 },
        { lng:28.7446502	, lat:61.1431798 },
        { lng:28.7434874	, lat:61.1424733 },
        { lng:28.7432415	, lat:61.1423126 },
        { lng:28.7430675	, lat:61.1420712 },
        { lng:28.7430028	, lat:61.1418682 },
        { lng:28.7429544	, lat:61.14131 },
        { lng:28.7434389	, lat:61.140537 },
        { lng:28.7435923	, lat:61.1403028 },
        { lng:28.7437862	, lat:61.1400881 },
        { lng:28.7440204	, lat:61.1398422 },
        { lng:28.744416	, lat:61.1396001 },
        { lng:28.7448279	, lat:61.1394517 },
        { lng:28.7452519	, lat:61.1393 },
        { lng:28.7452312	, lat:61.1392235 },
        { lng:28.7435249	, lat:61.132911 },
        { lng:28.7431489	, lat:61.131488 },
        { lng:28.7427629	, lat:61.129867 },
        { lng:28.7405749	, lat:61.123861 },
        { lng:28.7334029	, lat:61.124088 },
        { lng:28.7294639	, lat:61.123689 },
        { lng:28.7294029	, lat:61.123259 },
        { lng:28.7320139	, lat:61.122453 },
        { lng:28.7339719	, lat:61.121934 },
        { lng:28.7348439	, lat:61.121923 },
        { lng:28.7373959	, lat:61.121415 },
        { lng:28.7384269	, lat:61.12159 },
        { lng:28.7381999	, lat:61.122203 },
        { lng:28.7399209	, lat:61.122092 },
        { lng:28.7378699	, lat:61.116164 },
        { lng:28.7360749	, lat:61.111203 },
        { lng:28.7351789	, lat:61.109078 },
        { lng:28.7345199	, lat:61.106536 },
        { lng:28.7345399	, lat:61.105548 },
        { lng:28.7347359	, lat:61.100651 },
        { lng:28.7484979	, lat:61.095553 },
        { lng:28.7499879	, lat:61.094977 },
        { lng:28.7524039	, lat:61.0937239 },
        { lng:28.7604789	, lat:61.089497 },
        { lng:28.7626299	, lat:61.088167 },
        { lng:28.7679509	, lat:61.084811 },
        { lng:28.7722449	, lat:61.082025 },
        { lng:28.7733809	, lat:61.0812785 },
        { lng:28.7755359	, lat:61.079862 },
        { lng:28.7771683	, lat:61.0795492 },
        { lng:28.7138343	, lat:61.0444583 },
        { lng:28.7130748	, lat:61.041358 },
        { lng:28.7121034	, lat:61.0366734 },
        { lng:28.711184	, lat:61.0322392 },
        { lng:28.7109512	, lat:61.0311166 },
        { lng:28.7101878	, lat:61.0269848 },
        { lng:28.7071457	, lat:61.0214668 },
        { lng:28.7037055	, lat:61.0151124 },
        { lng:28.7019213	, lat:61.0118165 },
        { lng:28.7001583	, lat:61.0085592 },
        { lng:28.6984772	, lat:61.005453 },
        { lng:28.6964013	, lat:61.0016168 },
        { lng:28.6940795	, lat:60.9973259 },
        { lng:28.6920327	, lat:60.9935425 },
        { lng:28.6906904	, lat:60.9910879 },
        { lng:28.6909581	, lat:60.9906252 },
        { lng:28.690158	, lat:60.9901657 },
        { lng:28.6872372	, lat:60.9847712 },
        { lng:28.6851721	, lat:60.981024 },
        { lng:28.684067	, lat:60.9789468 },
        { lng:28.6815644	, lat:60.9763139 },
        { lng:28.6769191	, lat:60.9715329 },
        { lng:28.6725998	, lat:60.9668848 },
        { lng:28.6683493	, lat:60.9624396 },
        { lng:28.6651566	, lat:60.9589748 },
        { lng:28.6578641	, lat:60.9511517 },
        { lng:28.6550021	, lat:60.9494999 },
        { lng:28.6492316	, lat:60.9481425 },
        { lng:28.6363152	, lat:60.9508348 },
        { lng:28.6217367	, lat:60.9538984 },
        { lng:28.6148217	, lat:60.9553598 },
        { lng:28.6087183	, lat:60.9548218 },
        { lng:28.5752628	, lat:60.9519156 },
        { lng:28.5728672	, lat:60.9516868 },
        { lng:28.5562594	, lat:60.9543299 },
        { lng:28.5524215	, lat:60.9549344 },
        { lng:28.5453243	, lat:60.9552721 },
        { lng:28.542466	, lat:60.9560336 },
        { lng:28.5419575	, lat:60.9560622 },
        { lng:28.5373565	, lat:60.956476 },
        { lng:28.5299573	, lat:60.9547609 },
        { lng:28.527467	, lat:60.954915 },
        { lng:28.524607	, lat:60.9571057 },
        { lng:28.5214213	, lat:60.9549294 },
        { lng:28.5212715	, lat:60.9548288 },
        { lng:28.5151085	, lat:60.9505821 },
        { lng:28.5114161	, lat:60.9480759 },
        { lng:28.5056055	, lat:60.9441855 },
        { lng:28.49538	, lat:60.9372288 },
        { lng:28.4930266	, lat:60.9370808 },
        { lng:28.4922828	, lat:60.9351268 },
        { lng:28.4836071	, lat:60.9292671 },
        { lng:28.4835935	, lat:60.9288178 },
        { lng:28.4824115	, lat:60.9277584 },
        { lng:28.4836226	, lat:60.9268124 },
        { lng:28.484122	, lat:60.9256902 },
        { lng:28.4739332	, lat:60.924204 },
        { lng:28.4654024	, lat:60.9233269 },
        { lng:28.4638088	, lat:60.9224912 },
        { lng:28.3979857	, lat:60.8874902 },
        { lng:28.4001375	, lat:60.8856615 },
        { lng:28.3974588	, lat:60.8835537 },
        { lng:28.3935851	, lat:60.8843599 },
        { lng:28.3927528	, lat:60.8846819 },
        { lng:28.3426872	, lat:60.8579171 },
        { lng:28.3377801	, lat:60.8552884 },
        { lng:28.337665	, lat:60.855226 },
        { lng:28.3362539	, lat:60.8544691 },
        { lng:28.3356509	, lat:60.8541446 },
        { lng:28.3348247	, lat:60.8549762 },
        { lng:28.3336939	, lat:60.8552552 },
        { lng:28.3321613	, lat:60.8555316 },
        { lng:28.3307166	, lat:60.8562307 },
        { lng:28.3298251	, lat:60.8563232 },
        { lng:28.3287812	, lat:60.8561009 },
        { lng:28.3277126	, lat:60.8561027 },
        { lng:28.326968	, lat:60.8564472 },
        { lng:28.326372	, lat:60.85691 },
        { lng:28.3250921	, lat:60.8576919 },
        { lng:28.3242268	, lat:60.8579147 },
        { lng:28.3240141	, lat:60.8575181 },
        { lng:28.323778	, lat:60.8572223 },
        { lng:28.3223987	, lat:60.855355 },
        { lng:28.3199593	, lat:60.8520867 },
        { lng:28.3192486	, lat:60.8511324 },
        { lng:28.3180802	, lat:60.8495662 },
        { lng:28.3160463	, lat:60.8468449 },
        { lng:28.3119069	, lat:60.8412693 },
        { lng:28.3053337	, lat:60.8375851 },
        { lng:28.2935275	, lat:60.8309744 },
        { lng:28.280474	, lat:60.8236999 },
        { lng:28.2789305	, lat:60.8228371 },
        { lng:28.276409	, lat:60.8214282 },
        { lng:28.2731051	, lat:60.8195882 },
        { lng:28.2684621	, lat:60.8169924 },
        { lng:28.2611842	, lat:60.8129369 },
        { lng:28.2588077	, lat:60.81161 },
        { lng:28.2558445	, lat:60.8099522 },
        { lng:28.2532858	, lat:60.8079951 },
        { lng:28.2499528	, lat:60.8054275 },
        { lng:28.245797	, lat:60.8022408 },
        { lng:28.2356131	, lat:60.7944179 },
        { lng:28.2313752	, lat:60.7911646 },
        { lng:28.2276254	, lat:60.788286 },
        { lng:28.2204938	, lat:60.782793 },
        { lng:28.2111165	, lat:60.7819437 },
        { lng:28.2069681	, lat:60.7815711 },
        { lng:28.2013834	, lat:60.7810702 },
        { lng:28.1917024	, lat:60.7801949 },
        { lng:28.1852626	, lat:60.7796109 },
        { lng:28.1742827	, lat:60.7786193 },
        { lng:28.1714444	, lat:60.7758675 },
        { lng:28.1633834	, lat:60.7680309 },
        { lng:28.1581624	, lat:60.762952 },
        { lng:28.1535783	, lat:60.758502 },
        { lng:28.1500219	, lat:60.7550408 },
        { lng:28.146655	, lat:60.7517674 },
        { lng:28.1438178	, lat:60.7490038 },
        { lng:28.1368873	, lat:60.7422572 },
        { lng:28.1354613	, lat:60.7408695 },
        { lng:28.13265	, lat:60.7396382 },
        { lng:28.1203662	, lat:60.734281 },
        { lng:28.1133336	, lat:60.7312156 },
        { lng:28.111013	, lat:60.7302092 },
        { lng:28.1025494	, lat:60.7265088 },
        { lng:28.0943523	, lat:60.7229336 },
        { lng:28.0889884	, lat:60.7205952 },
        { lng:28.0868998	, lat:60.7195149 },
        { lng:28.0785244	, lat:60.7151776 },
        { lng:28.0674076	, lat:60.7093124 },
        { lng:28.06296	, lat:60.7069668 },
        { lng:28.0602885	, lat:60.7055792 },
        { lng:28.0562373	, lat:60.7034794 },
        { lng:28.0504108	, lat:60.7004557 },
        { lng:28.0452854	, lat:60.6977896 },
        { lng:28.042009	, lat:60.6960973 },
        { lng:28.0380411	, lat:60.6940284 },
        { lng:28.025133	, lat:60.6874878 },
        { lng:28.0157416	, lat:60.6827283 },
        { lng:28.0151664	, lat:60.6824395 },
        { lng:28.0178274	, lat:60.68118 },
        { lng:28.0050086	, lat:60.6741921 },
        { lng:27.9980779	, lat:60.6704129 },
        { lng:27.9953655	, lat:60.671215 },
        { lng:27.9912938	, lat:60.6689908 },
        { lng:27.9905375	, lat:60.6690367 },
        { lng:27.9863403	, lat:60.6685549 },
        { lng:27.9669796	, lat:60.6666743 },
        { lng:27.9515	, lat:60.6671862 },
        { lng:27.9530084	, lat:60.6712518 },
        { lng:27.9571777	, lat:60.6774188 },
        { lng:27.9546092	, lat:60.6827954 },
        { lng:27.9528518	, lat:60.6828563 },
        { lng:27.9524828	, lat:60.6860377 },
        { lng:27.9316474	, lat:60.6896062 },
        { lng:27.9018641	, lat:60.6964197 },
        { lng:27.8892856	, lat:60.70833 },
        { lng:27.8854661	, lat:60.7097578 },
        { lng:27.8807969	, lat:60.7134108 },
        { lng:27.8665147	, lat:60.7334783 },
        { lng:27.8594037	, lat:60.7406947 },
        { lng:27.8521584	, lat:60.7652581 },
        { lng:27.8146861	, lat:60.7651117 },
        { lng:27.8141416	, lat:60.7650069 },
        { lng:27.8136507	, lat:60.7649702 },
        { lng:27.8132135	, lat:60.7653383 },
        { lng:27.8127656	, lat:60.7654221 },
        { lng:27.8123096	, lat:60.7652112 },
        { lng:27.8119006	, lat:60.7651628 },
        { lng:27.8116216	, lat:60.7651903 },
        { lng:27.8113252	, lat:60.7652826 },
        { lng:27.8110718	, lat:60.7655033 },
        { lng:27.8108156	, lat:60.7656402 },
        { lng:27.8104562	, lat:60.7656664 },
        { lng:27.8098038	, lat:60.7655839 },
        { lng:27.8094819	, lat:60.7656651 },
        { lng:27.8092968	, lat:60.7660306 },
        { lng:27.8090823	, lat:60.7661786 },
        { lng:27.8087282	, lat:60.7662245 },
        { lng:27.8076218	, lat:60.7661603 },
        { lng:27.8071926	, lat:60.7661177 },
        { lng:27.8067662	, lat:60.7661171 },
        { lng:27.8065905	, lat:60.7661858 },
        { lng:27.8065757	, lat:60.7662814 },
        { lng:27.8066508	, lat:60.7664544 },
        { lng:27.8066508	, lat:60.7666096 },
        { lng:27.8064825	, lat:60.7667609 },
        { lng:27.8059944	, lat:60.7668539 },
        { lng:27.8052876	, lat:60.7669017 },
        { lng:27.8049537	, lat:60.766979 },
        { lng:27.8047927	, lat:60.7670707 },
        { lng:27.8046841	, lat:60.7673195 },
        { lng:27.8045916	, lat:60.7674748 },
        { lng:27.8043596	, lat:60.7675429 },
        { lng:27.804149	, lat:60.7675029 },
        { lng:27.8041007	, lat:60.7674348 },
        { lng:27.8041168	, lat:60.7672292 },
        { lng:27.804094	, lat:60.767127 },
        { lng:27.8039539	, lat:60.7670923 },
        { lng:27.803811	, lat:60.7671067 },
        { lng:27.8036233	, lat:60.7671591 },
        { lng:27.8033899	, lat:60.7672757 },
        { lng:27.803052	, lat:60.7675239 },
        { lng:27.8028354	, lat:60.7675468 },
        { lng:27.8026242	, lat:60.7674905 },
        { lng:27.8026456	, lat:60.7673529 },
        { lng:27.8026537	, lat:60.7671375 },
        { lng:27.8024921	, lat:60.767091 },
        { lng:27.8023063	, lat:60.7671322 },
        { lng:27.8020716	, lat:60.7672894 },
        { lng:27.8018222	, lat:60.7673824 },
        { lng:27.8016036	, lat:60.7673641 },
        { lng:27.8014024	, lat:60.7672658 },
        { lng:27.8013662	, lat:60.7671741 },
        { lng:27.8013743	, lat:60.7670418 },
        { lng:27.8012911	, lat:60.7669272 },
        { lng:27.8010556	, lat:60.7668087 },
        { lng:27.8003295	, lat:60.7667858 },
        { lng:27.8001565	, lat:60.7668427 },
        { lng:27.8000868	, lat:60.7669233 },
        { lng:27.8000868	, lat:60.7670314 },
        { lng:27.8003671	, lat:60.7671486 },
        { lng:27.8004797	, lat:60.7672403 },
        { lng:27.8003845	, lat:60.7673228 },
        { lng:27.8001123	, lat:60.7673412 },
        { lng:27.7997314	, lat:60.7672206 },
        { lng:27.7995504	, lat:60.7671624 },
        { lng:27.7993519	, lat:60.7671388 },
        { lng:27.7992265	, lat:60.7671689 },
        { lng:27.7991058	, lat:60.7678232 },
        { lng:27.798961	, lat:60.7679037 },
        { lng:27.7979437	, lat:60.7681716 },
        { lng:27.7976728	, lat:60.768184 },
        { lng:27.797477	, lat:60.7681664 },
        { lng:27.7973335	, lat:60.7681199 },
        { lng:27.7972302	, lat:60.7680328 },
        { lng:27.7971685	, lat:60.7679332 },
        { lng:27.797186	, lat:60.7676732 },
        { lng:27.7970183	, lat:60.7675514 },
        { lng:27.7968024	, lat:60.7675082 },
        { lng:27.7966133	, lat:60.7675258 },
        { lng:27.796152	, lat:60.7675926 },
        { lng:27.7952897	, lat:60.7676431 },
        { lng:27.79465	, lat:60.7676182 },
        { lng:27.7944327	, lat:60.7676784 },
        { lng:27.7942919	, lat:60.7677835 },
        { lng:27.7940019	, lat:60.7683533 },
        { lng:27.7940106	, lat:60.7685104 },
        { lng:27.7940745	, lat:60.7686087 },
        { lng:27.7943105	, lat:60.7687868 },
        { lng:27.7944701	, lat:60.7689237 },
        { lng:27.794505	, lat:60.7690167 },
        { lng:27.7944607	, lat:60.7690606 },
        { lng:27.7943306	, lat:60.7690841 },
        { lng:27.7941777	, lat:60.7690566 },
        { lng:27.7939511	, lat:60.7689217 },
        { lng:27.7938626	, lat:60.7689021 },
        { lng:27.7937538	, lat:60.7689065 },
        { lng:27.793643	, lat:60.7689499 },
        { lng:27.7934351	, lat:60.7692793 },
        { lng:27.7932004	, lat:60.7692996 },
        { lng:27.7929577	, lat:60.769221 },
        { lng:27.7927927	, lat:60.7691319 },
        { lng:27.7925044	, lat:60.7688122 },
        { lng:27.7920066	, lat:60.7687729 },
        { lng:27.7732803	, lat:60.7588817 },
        { lng:27.7513902	, lat:60.7575558 },
        { lng:27.7275408	, lat:60.7627883 },
        { lng:27.7104527	, lat:60.789918 },
        { lng:27.7138414	, lat:60.7953333 },
      ],
    },
    {
      id: 17,
      regionName: 'eCom TP  Lohja',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [       
        { lng:23.6144848	, lat:60.2702947 },
        { lng:23.6131991	, lat:60.2608081 },
        { lng:23.6128927	, lat:60.2609858 },
        { lng:23.612704	, lat:60.2610185 },
        { lng:23.6122757	, lat:60.2610693 },
        { lng:23.611359	, lat:60.2613662 },
        { lng:23.6107833	, lat:60.2617636 },
        { lng:23.6101758	, lat:60.2619914 },
        { lng:23.6100455	, lat:60.2621399 },
        { lng:23.6099318	, lat:60.2623561 },
        { lng:23.6094052	, lat:60.2628506 },
        { lng:23.6088733	, lat:60.2630364 },
        { lng:23.6086029	, lat:60.2630949 },
        { lng:23.6079207	, lat:60.263426 },
        { lng:23.60751	, lat:60.2635349 },
        { lng:23.6070751	, lat:60.2635136 },
        { lng:23.6067537	, lat:60.2634433 },
        { lng:23.6065402	, lat:60.2634761 },
        { lng:23.6048825	, lat:60.2638221 },
        { lng:23.6042989	, lat:60.2638502 },
        { lng:23.6039706	, lat:60.2639159 },
        { lng:23.6036311	, lat:60.2639301 },
        { lng:23.6032949	, lat:60.2638627 },
        { lng:23.6026634	, lat:60.2638564 },
        { lng:23.6024361	, lat:60.2638157 },
        { lng:23.6018692	, lat:60.263817 },
        { lng:23.5989257	, lat:60.266391 },
        { lng:23.5950376	, lat:60.2658092 },
        { lng:23.5944407	, lat:60.2660332 },
        { lng:23.5935493	, lat:60.2662697 },
        { lng:23.593466	, lat:60.26633 },
        { lng:23.5935246	, lat:60.2664337 },
        { lng:23.5939123	, lat:60.2665598 },
        { lng:23.5939246	, lat:60.2666766 },
        { lng:23.5937488	, lat:60.2667887 },
        { lng:23.5935309	, lat:60.2668751 },
        { lng:23.5934678	, lat:60.2670817 },
        { lng:23.5936484	, lat:60.2672541 },
        { lng:23.5933922	, lat:60.2672334 },
        { lng:23.5933103	, lat:60.2670709 },
        { lng:23.5933999	, lat:60.2668755 },
        { lng:23.5936701	, lat:60.2667242 },
        { lng:23.593687	, lat:60.2665811 },
        { lng:23.5934531	, lat:60.2664717 },
        { lng:23.5932438	, lat:60.2663911 },
        { lng:23.5931886	, lat:60.2663517 },
        { lng:23.5929503	, lat:60.2662807 },
        { lng:23.593142	, lat:60.2661472 },
        { lng:23.5934334	, lat:60.2661382 },
        { lng:23.5939702	, lat:60.2660411 },
        { lng:23.5945278	, lat:60.2658863 },
        { lng:23.5951511	, lat:60.2654084 },
        { lng:23.5952705	, lat:60.265406 },
        { lng:23.5956626	, lat:60.2651735 },
        { lng:23.5960369	, lat:60.2650179 },
        { lng:23.5961677	, lat:60.2648634 },
        { lng:23.5961299	, lat:60.2647131 },
        { lng:23.5958323	, lat:60.2645633 },
        { lng:23.5957004	, lat:60.26445 },
        { lng:23.5954519	, lat:60.2640013 },
        { lng:23.5931962	, lat:60.2636097 },
        { lng:23.5930108	, lat:60.2635776 },
        { lng:23.5928885	, lat:60.2634388 },
        { lng:23.5930919	, lat:60.2633092 },
        { lng:23.5931186	, lat:60.2631496 },
        { lng:23.5928145	, lat:60.2629559 },
        { lng:23.5923978	, lat:60.2628369 },
        { lng:23.5921735	, lat:60.2627036 },
        { lng:23.5918294	, lat:60.262627 },
        { lng:23.5916879	, lat:60.2624548 },
        { lng:23.5913353	, lat:60.2622211 },
        { lng:23.5911979	, lat:60.2621979 },
        { lng:23.5909138	, lat:60.2622104 },
        { lng:23.5907662	, lat:60.2621773 },
        { lng:23.589796	, lat:60.261468 },
        { lng:23.5895529	, lat:60.2612206 },
        { lng:23.5891046	, lat:60.260995 },
        { lng:23.5887636	, lat:60.2606724 },
        { lng:23.5883278	, lat:60.260403 },
        { lng:23.5879426	, lat:60.2601148 },
        { lng:23.5878416	, lat:60.2599895 },
        { lng:23.5874753	, lat:60.2597013 },
        { lng:23.5870459	, lat:60.259507 },
        { lng:23.5863479	, lat:60.258991 },
        { lng:23.5863067	, lat:60.2588564 },
        { lng:23.5864986	, lat:60.2587327 },
        { lng:23.5864977	, lat:60.2586355 },
        { lng:23.5866408	, lat:60.2585615 },
        { lng:23.5869242	, lat:60.2585891 },
        { lng:23.5870314	, lat:60.2585417 },
        { lng:23.587057	, lat:60.258456 },
        { lng:23.5869937	, lat:60.2583484 },
        { lng:23.5871726	, lat:60.2582537 },
        { lng:23.5872243	, lat:60.2581381 },
        { lng:23.587458	, lat:60.2580911 },
        { lng:23.5876916	, lat:60.2581631 },
        { lng:23.5881273	, lat:60.2580503 },
        { lng:23.5886114	, lat:60.2580507 },
        { lng:23.5920855	, lat:60.2566615 },
        { lng:23.5927869	, lat:60.256488 },
        { lng:23.5936827	, lat:60.2564353 },
        { lng:23.5946022	, lat:60.2563567 },
        { lng:23.5953326	, lat:60.2562333 },
        { lng:23.5956294	, lat:60.2560641 },
        { lng:23.5979869	, lat:60.2555699 },
        { lng:23.5988459	, lat:60.2551572 },
        { lng:23.598487	, lat:60.2530638 },
        { lng:23.5994602	, lat:60.2517936 },
        { lng:23.6007737	, lat:60.2507719 },
        { lng:23.6010413	, lat:60.2506781 },
        { lng:23.6023988	, lat:60.250613 },
        { lng:23.603335	, lat:60.250836 },
        { lng:23.6052548	, lat:60.2508342 },
        { lng:23.6070417	, lat:60.2506751 },
        { lng:23.6104884	, lat:60.249681 },
        { lng:23.6112197	, lat:60.2505165 },
        { lng:23.6121057	, lat:60.250652 },
        { lng:23.6127682	, lat:60.2500214 },
        { lng:23.6123553	, lat:60.2491661 },
        { lng:23.6330029	, lat:60.2433839 },
        { lng:23.6457552	, lat:60.2363438 },
        { lng:23.6414087	, lat:60.2353052 },
        { lng:23.6334268	, lat:60.2347576 },
        { lng:23.6325202	, lat:60.2329731 },
        { lng:23.6326994	, lat:60.2300416 },
        { lng:23.6372282	, lat:60.2192952 },
        { lng:23.6474953	, lat:60.211073 },
        { lng:23.6438387	, lat:60.2100889 },
        { lng:23.6435732	, lat:60.2098248 },
        { lng:23.643511	, lat:60.2096036 },
        { lng:23.6437972	, lat:60.2091245 },
        { lng:23.6437513	, lat:60.2087697 },
        { lng:23.6436374	, lat:60.2086454 },
        { lng:23.643313	, lat:60.2085689 },
        { lng:23.643167	, lat:60.2085769 },
        { lng:23.6430583	, lat:60.2085264 },
        { lng:23.6489433	, lat:60.2015641 },
        { lng:23.6204355	, lat:60.1941517 },
        { lng:23.6165541	, lat:60.1880988 },
        { lng:23.6028612	, lat:60.1839382 },
        { lng:23.6041011	, lat:60.1809146 },
        { lng:23.6047659	, lat:60.1752249 },
        { lng:23.6111318	, lat:60.1692827 },
        { lng:23.6223845	, lat:60.1664145 },
        { lng:23.6474678	, lat:60.1699578 },
        { lng:23.6489131	, lat:60.1700953 },
        { lng:23.651887	, lat:60.1705057 },
        { lng:23.6603317	, lat:60.1694931 },
        { lng:23.6607006	, lat:60.1694488 },
        { lng:23.6650407	, lat:60.1689284 },
        { lng:23.6740857	, lat:60.1716963 },
        { lng:23.6759545	, lat:60.1727212 },
        { lng:23.6766412	, lat:60.1730978 },
        { lng:23.6774874	, lat:60.1735618 },
        { lng:23.6777667	, lat:60.173715 },
        { lng:23.681907	, lat:60.1729707 },
        { lng:23.6820577	, lat:60.1729613 },
        { lng:23.6822322	, lat:60.172916 },
        { lng:23.682442	, lat:60.1728933 },
        { lng:23.6827102	, lat:60.1728239 },
        { lng:23.6829785	, lat:60.1727371 },
        { lng:23.6835189	, lat:60.1723727 },
        { lng:23.6838888	, lat:60.1718398 },
        { lng:23.6843684	, lat:60.1715463 },
        { lng:23.6848853	, lat:60.1714228 },
        { lng:23.6855055	, lat:60.171168 },
        { lng:23.6856356	, lat:60.1710758 },
        { lng:23.6911777	, lat:60.1712371 },
        { lng:23.6931783	, lat:60.170905 },
        { lng:23.6932072	, lat:60.1708536 },
        { lng:23.69655	, lat:60.1701405 },
        { lng:23.6967245	, lat:60.1702438 },
        { lng:23.7036084	, lat:60.168983 },
        { lng:23.7078974	, lat:60.168748 },
        { lng:23.7121022	, lat:60.168555 },
        { lng:23.7175463	, lat:60.1683867 },
        { lng:23.7348775	, lat:60.1696716 },
        { lng:23.7428584	, lat:60.1717491 },
        { lng:23.7631327	, lat:60.1770257 },
        { lng:23.7634193	, lat:60.1770556 },
        { lng:23.7643168	, lat:60.1771978 },
        { lng:23.7649669	, lat:60.1773751 },
        { lng:23.7662264	, lat:60.1777871 },
        { lng:23.7661971	, lat:60.1779088 },
        { lng:23.7851077	, lat:60.1864649 },
        { lng:23.7872567	, lat:60.1875453 },
        { lng:23.8097139	, lat:60.1889829 },
        { lng:23.8258495	, lat:60.1975314 },
        { lng:23.8285681	, lat:60.1962116 },
        { lng:23.8358885	, lat:60.1923962 },
        { lng:23.8386224	, lat:60.1896208 },
        { lng:23.8487927	, lat:60.191796 },
        { lng:23.8488423	, lat:60.1914165 },
        { lng:23.8505736	, lat:60.1914393 },
        { lng:23.8544741	, lat:60.1925777 },
        { lng:23.8557298	, lat:60.1931552 },
        { lng:23.8556844	, lat:60.1932558 },
        { lng:23.8651929	, lat:60.1952594 },
        { lng:23.8654847	, lat:60.1950941 },
        { lng:23.8744193	, lat:60.1965516 },
        { lng:23.8789828	, lat:60.1965443 },
        { lng:23.879768	, lat:60.1976004 },
        { lng:23.8823033	, lat:60.1990242 },
        { lng:23.8790709	, lat:60.2025178 },
        { lng:23.8989829	, lat:60.2059978 },
        { lng:23.8992123	, lat:60.2018106 },
        { lng:23.9019333	, lat:60.1918791 },
        { lng:23.9066567	, lat:60.187643 },
        { lng:23.9083852	, lat:60.1857798 },
        { lng:23.9062737	, lat:60.1820842 },
        { lng:23.903934	, lat:60.1775188 },
        { lng:23.8995736	, lat:60.1729642 },
        { lng:23.9009611	, lat:60.1630418 },
        { lng:23.9010455	, lat:60.1624374 },
        { lng:23.9066897	, lat:60.1567555 },
        { lng:23.9069022	, lat:60.1565416 },
        { lng:23.9069905	, lat:60.1564526 },
        { lng:23.9070426	, lat:60.1564002 },
        { lng:23.9088436	, lat:60.1545867 },
        { lng:23.9092796	, lat:60.1541479 },
        { lng:23.9095524	, lat:60.1538732 },
        { lng:23.9105416	, lat:60.1528773 },
        { lng:23.910673	, lat:60.1527449 },
        { lng:23.9118562	, lat:60.1515534 },
        { lng:23.9121302	, lat:60.1512775 },
        { lng:23.9145148	, lat:60.1488761 },
        { lng:23.9146758	, lat:60.148714 },
        { lng:23.9158679	, lat:60.1475133 },
        { lng:23.9306121	, lat:60.1374742 },
        { lng:23.9311361	, lat:60.1371174 },
        { lng:23.9332812	, lat:60.1356565 },
        { lng:23.9336146	, lat:60.1354294 },
        { lng:23.9423447	, lat:60.1294831 },
        { lng:23.9542125	, lat:60.1301067 },
        { lng:23.9637124	, lat:60.1314537 },
        { lng:23.9668269	, lat:60.1318953 },
        { lng:23.9681534	, lat:60.1320834 },
        { lng:23.9717823	, lat:60.1325979 },
        { lng:23.9754341	, lat:60.1331157 },
        { lng:23.9761179	, lat:60.1332126 },
        { lng:23.976544	, lat:60.133273 },
        { lng:23.9772557	, lat:60.1333739 },
        { lng:23.9780932	, lat:60.1334927 },
        { lng:23.9784636	, lat:60.1335452 },
        { lng:23.9806012	, lat:60.1338482 },
        { lng:23.9808499	, lat:60.1338834 },
        { lng:23.9819175	, lat:60.1338167 },
        { lng:23.9824757	, lat:60.1337818 },
        { lng:23.9954142	, lat:60.1329725 },
        { lng:23.9972627	, lat:60.1328569 },
        { lng:23.9988549	, lat:60.1327573 },
        { lng:23.9996462	, lat:60.1327078 },
        { lng:24.0027194	, lat:60.1325155 },
        { lng:24.0033125	, lat:60.1326162 },
        { lng:24.0134531	, lat:60.134337 },
        { lng:24.0194803	, lat:60.1353598 },
        { lng:24.0273121	, lat:60.1366886 },
        { lng:24.0275876	, lat:60.136886 },
        { lng:24.0278755	, lat:60.1370921 },
        { lng:24.0280062	, lat:60.1371857 },
        { lng:24.0339486	, lat:60.1414878 },
        { lng:24.0346479	, lat:60.1419947 },
        { lng:24.0355621	, lat:60.1426572 },
        { lng:24.0358786	, lat:60.1428866 },
        { lng:24.0364749	, lat:60.1433188 },
        { lng:24.0378511	, lat:60.1443191 },
        { lng:24.0406678	, lat:60.1463624 },
        { lng:24.0425355	, lat:60.1477306 },
        { lng:24.0426166	, lat:60.1477901 },
        { lng:24.0452906	, lat:60.1497489 },
        { lng:24.0457977	, lat:60.1501204 },
        { lng:24.0454346	, lat:60.1501402 },
        { lng:24.0234177	, lat:60.1513383 },
        { lng:24.0218319	, lat:60.1514244 },
        { lng:24.0190579	, lat:60.1515749 },
        { lng:24.0186809	, lat:60.1522815 },
        { lng:24.0167333	, lat:60.155931 },
        { lng:24.0160088	, lat:60.1572803 },
        { lng:24.0172476	, lat:60.1601451 },
        { lng:24.0197906	, lat:60.1666592 },
        { lng:24.0166909	, lat:60.1695408 },
        { lng:24.0172546	, lat:60.1722341 },
        { lng:24.0180232	, lat:60.1759069 },
        { lng:24.017843	, lat:60.1773213 },
        { lng:24.0176852	, lat:60.178559 },
        { lng:24.017388	, lat:60.1808908 },
        { lng:24.0175104	, lat:60.1809771 },
        { lng:24.0175645	, lat:60.1810196 },
        { lng:24.0175328	, lat:60.181058 },
        { lng:24.0169175	, lat:60.1809664 },
        { lng:24.0162982	, lat:60.1809298 },
        { lng:24.015613	, lat:60.1809488 },
        { lng:24.0151663	, lat:60.1809425 },
        { lng:24.0151917	, lat:60.1812378 },
        { lng:24.0133277	, lat:60.1818164 },
        { lng:24.0125776	, lat:60.1820492 },
        { lng:24.0115879	, lat:60.1818612 },
        { lng:24.0110831	, lat:60.1819652 },
        { lng:24.0102517	, lat:60.1821366 },
        { lng:24.0095247	, lat:60.1822864 },
        { lng:24.0082612	, lat:60.1825004 },
        { lng:24.0188919	, lat:60.1885485 },
        { lng:24.018949	, lat:60.1885522 },
        { lng:24.0190125	, lat:60.1885674 },
        { lng:24.0190887	, lat:60.188616 },
        { lng:24.019133	, lat:60.1886721 },
        { lng:24.0191267	, lat:60.1887163 },
        { lng:24.0191178	, lat:60.188739 },
        { lng:24.019147	, lat:60.1887743 },
        { lng:24.0191749	, lat:60.1887894 },
        { lng:24.0192498	, lat:60.1887838 },
        { lng:24.0193526	, lat:60.1887554 },
        { lng:24.0194071	, lat:60.1887522 },
        { lng:24.0195416	, lat:60.1887945 },
        { lng:24.0196051	, lat:60.1888071 },
        { lng:24.0196914	, lat:60.1888103 },
        { lng:24.0197459	, lat:60.1887875 },
        { lng:24.019803	, lat:60.188761 },
        { lng:24.0199388	, lat:60.188761 },
        { lng:24.0201279	, lat:60.1888733 },
        { lng:24.0201736	, lat:60.188932 },
        { lng:24.0202129	, lat:60.1889648 },
        { lng:24.0202573	, lat:60.1889755 },
        { lng:24.0203068	, lat:60.1889667 },
        { lng:24.0203779	, lat:60.188944 },
        { lng:24.0204147	, lat:60.1889207 },
        { lng:24.0205022	, lat:60.1888084 },
        { lng:24.0205212	, lat:60.1887762 },
        { lng:24.0204781	, lat:60.1887213 },
        { lng:24.0204833	, lat:60.1886412 },
        { lng:24.0205238	, lat:60.1886374 },
        { lng:24.0208829	, lat:60.1887137 },
        { lng:24.021025	, lat:60.1887144 },
        { lng:24.0211988	, lat:60.1887863 },
        { lng:24.0213676	, lat:60.1889459 },
        { lng:24.021407	, lat:60.1890228 },
        { lng:24.0214602	, lat:60.1890531 },
        { lng:24.021544	, lat:60.1890557 },
        { lng:24.0217026	, lat:60.1890039 },
        { lng:24.0217369	, lat:60.1890046 },
        { lng:24.0217724	, lat:60.1890184 },
        { lng:24.0217927	, lat:60.1890569 },
        { lng:24.0218282	, lat:60.1890702 },
        { lng:24.0219044	, lat:60.1890847 },
        { lng:24.0219437	, lat:60.1891023 },
        { lng:24.0219653	, lat:60.1891244 },
        { lng:24.0219564	, lat:60.189185 },
        { lng:24.0219183	, lat:60.1892071 },
        { lng:24.0218409	, lat:60.1892291 },
        { lng:24.0216912	, lat:60.1893225 },
        { lng:24.0213917	, lat:60.1893963 },
        { lng:24.021346	, lat:60.1894184 },
        { lng:24.0213283	, lat:60.1894449 },
        { lng:24.0213295	, lat:60.1894783 },
        { lng:24.0214729	, lat:60.1895931 },
        { lng:24.0215389	, lat:60.1896076 },
        { lng:24.0216201	, lat:60.1896171 },
        { lng:24.0216874	, lat:60.1896076 },
        { lng:24.0217914	, lat:60.1895761 },
        { lng:24.0218295	, lat:60.1895837 },
        { lng:24.0219424	, lat:60.1896764 },
        { lng:24.0219919	, lat:60.1896997 },
        { lng:24.0220719	, lat:60.1897105 },
        { lng:24.0222	, lat:60.1897098 },
        { lng:24.022299	, lat:60.1897067 },
        { lng:24.022351	, lat:60.1897161 },
        { lng:24.0223663	, lat:60.1897376 },
        { lng:24.0223612	, lat:60.1897697 },
        { lng:24.0223358	, lat:60.189788 },
        { lng:24.022318	, lat:60.1898126 },
        { lng:24.0223257	, lat:60.1898227 },
        { lng:24.0223536	, lat:60.1898461 },
        { lng:24.0223548	, lat:60.1898719 },
        { lng:24.0222952	, lat:60.1898921 },
        { lng:24.0220693	, lat:60.1899464 },
        { lng:24.0220249	, lat:60.189976 },
        { lng:24.0220034	, lat:60.1900063 },
        { lng:24.0220046	, lat:60.1900315 },
        { lng:24.0220376	, lat:60.19007 },
        { lng:24.0220605	, lat:60.1900959 },
        { lng:24.0220528	, lat:60.1901306 },
        { lng:24.0219044	, lat:60.1901318 },
        { lng:24.0218638	, lat:60.1901489 },
        { lng:24.0218701	, lat:60.1901842 },
        { lng:24.0218879	, lat:60.1902025 },
        { lng:24.0220478	, lat:60.1903116 },
        { lng:24.0221125	, lat:60.1903413 },
        { lng:24.0221556	, lat:60.1904182 },
        { lng:24.0222153	, lat:60.1904327 },
        { lng:24.0223003	, lat:60.1904409 },
        { lng:24.022351	, lat:60.1904277 },
        { lng:24.0223764	, lat:60.1904018 },
        { lng:24.0224094	, lat:60.190364 },
        { lng:24.0224475	, lat:60.1903375 },
        { lng:24.0224957	, lat:60.1903179 },
        { lng:24.0225579	, lat:60.1903167 },
        { lng:24.0226162	, lat:60.190335 },
        { lng:24.0226632	, lat:60.1903703 },
        { lng:24.0226607	, lat:60.1905646 },
        { lng:24.0226962	, lat:60.190663 },
        { lng:24.0227152	, lat:60.1907343 },
        { lng:24.0227457	, lat:60.1907532 },
        { lng:24.0228142	, lat:60.1907746 },
        { lng:24.0229271	, lat:60.1907828 },
        { lng:24.0230122	, lat:60.1907772 },
        { lng:24.0230426	, lat:60.1907708 },
        { lng:24.02312	, lat:60.1907431 },
        { lng:24.0231796	, lat:60.1907147 },
        { lng:24.0232456	, lat:60.1906825 },
        { lng:24.0232964	, lat:60.1906642 },
        { lng:24.02337	, lat:60.1906466 },
        { lng:24.0234347	, lat:60.1906453 },
        { lng:24.0234855	, lat:60.1906567 },
        { lng:24.0236073	, lat:60.1907103 },
        { lng:24.0236479	, lat:60.1907469 },
        { lng:24.023653	, lat:60.1907727 },
        { lng:24.0236327	, lat:60.1908137 },
        { lng:24.0236136	, lat:60.1908629 },
        { lng:24.0236352	, lat:60.1908907 },
        { lng:24.0236618	, lat:60.1909071 },
        { lng:24.0237215	, lat:60.1909191 },
        { lng:24.023757	, lat:60.1909153 },
        { lng:24.0239435	, lat:60.1908749 },
        { lng:24.0239829	, lat:60.1908743 },
        { lng:24.0241263	, lat:60.1909027 },
        { lng:24.0242011	, lat:60.1909058 },
        { lng:24.0243115	, lat:60.1909039 },
        { lng:24.0243927	, lat:60.1908793 },
        { lng:24.0244004	, lat:60.1908434 },
        { lng:24.0243902	, lat:60.1907847 },
        { lng:24.024408	, lat:60.1907576 },
        { lng:24.0244448	, lat:60.1907425 },
        { lng:24.024559	, lat:60.1907425 },
        { lng:24.0246415	, lat:60.1907406 },
        { lng:24.0248648	, lat:60.1907198 },
        { lng:24.0249612	, lat:60.1907147 },
        { lng:24.0250069	, lat:60.1907254 },
        { lng:24.0250907	, lat:60.1907664 },
        { lng:24.0251363	, lat:60.1907784 },
        { lng:24.0251858	, lat:60.1907759 },
        { lng:24.0252582	, lat:60.1907513 },
        { lng:24.0253229	, lat:60.1907481 },
        { lng:24.0253965	, lat:60.1908005 },
        { lng:24.0254612	, lat:60.1908219 },
        { lng:24.0255322	, lat:60.1908667 },
        { lng:24.025616	, lat:60.1908743 },
        { lng:24.0256668	, lat:60.190868 },
        { lng:24.0256959	, lat:60.1908465 },
        { lng:24.0257137	, lat:60.1908137 },
        { lng:24.0257175	, lat:60.1907582 },
        { lng:24.025701	, lat:60.1907292 },
        { lng:24.0256693	, lat:60.1907084 },
        { lng:24.0255589	, lat:60.1906756 },
        { lng:24.0254802	, lat:60.1906283 },
        { lng:24.0254802	, lat:60.1905822 },
        { lng:24.0254967	, lat:60.1905595 },
        { lng:24.0255627	, lat:60.1905482 },
        { lng:24.0256515	, lat:60.1905425 },
        { lng:24.0257264	, lat:60.1905513 },
        { lng:24.0258089	, lat:60.1905721 },
        { lng:24.0258924	, lat:60.1906537 },
        { lng:24.0260022	, lat:60.1906978 },
        { lng:24.026083	, lat:60.1907531 },
        { lng:24.0261171	, lat:60.1908039 },
        { lng:24.026153	, lat:60.1908717 },
        { lng:24.026214	, lat:60.1909092 },
        { lng:24.0263797	, lat:60.1909514 },
        { lng:24.026494	, lat:60.19096 },
        { lng:24.0266393	, lat:60.1909529 },
        { lng:24.0267416	, lat:60.1909261 },
        { lng:24.0268008	, lat:60.1908967 },
        { lng:24.02686	, lat:60.1908637 },
        { lng:24.0268941	, lat:60.1908539 },
        { lng:24.0269695	, lat:60.1908396 },
        { lng:24.0270377	, lat:60.1908378 },
        { lng:24.0271077	, lat:60.1908592 },
        { lng:24.0271884	, lat:60.1908708 },
        { lng:24.0272548	, lat:60.1908682 },
        { lng:24.0272997	, lat:60.1908441 },
        { lng:24.0276263	, lat:60.190729 },
        { lng:24.0276783	, lat:60.1907236 },
        { lng:24.0278309	, lat:60.1908013 },
        { lng:24.0278991	, lat:60.1908048 },
        { lng:24.0279709	, lat:60.1908048 },
        { lng:24.0280642	, lat:60.1907932 },
        { lng:24.0281288	, lat:60.1907709 },
        { lng:24.0281557	, lat:60.1907415 },
        { lng:24.0281037	, lat:60.1906853 },
        { lng:24.0280965	, lat:60.1906344 },
        { lng:24.0280893	, lat:60.1906059 },
        { lng:24.0281019	, lat:60.190588 },
        { lng:24.0282149	, lat:60.1905541 },
        { lng:24.0282903	, lat:60.190514 },
        { lng:24.0283643	, lat:60.1905225 },
        { lng:24.0284069	, lat:60.1905595 },
        { lng:24.0284608	, lat:60.1906648 },
        { lng:24.0284823	, lat:60.1907727 },
        { lng:24.028511	, lat:60.1908075 },
        { lng:24.0285505	, lat:60.1908298 },
        { lng:24.0286205	, lat:60.1908227 },
        { lng:24.0286779	, lat:60.1908039 },
        { lng:24.0287228	, lat:60.1907816 },
        { lng:24.0287963	, lat:60.1906505 },
        { lng:24.0288143	, lat:60.1906095 },
        { lng:24.028791	, lat:60.190564 },
        { lng:24.0287892	, lat:60.1905408 },
        { lng:24.0288089	, lat:60.1905211 },
        { lng:24.0289094	, lat:60.1905194 },
        { lng:24.0290009	, lat:60.190539 },
        { lng:24.0291247	, lat:60.1905631 },
        { lng:24.029245	, lat:60.1906086 },
        { lng:24.0292898	, lat:60.1906469 },
        { lng:24.0293401	, lat:60.1906764 },
        { lng:24.0293885	, lat:60.1906835 },
        { lng:24.0294334	, lat:60.1906674 },
        { lng:24.0294496	, lat:60.190646 },
        { lng:24.029507	, lat:60.1905229 },
        { lng:24.0295267	, lat:60.1904569 },
        { lng:24.0295698	, lat:60.1904275 },
        { lng:24.0296308	, lat:60.1904123 },
        { lng:24.0297134	, lat:60.1904346 },
        { lng:24.0297959	, lat:60.1904382 },
        { lng:24.0298695	, lat:60.1904391 },
        { lng:24.0298874	, lat:60.1904159 },
        { lng:24.0298731	, lat:60.1903829 },
        { lng:24.0298768	, lat:60.1903584 },
        { lng:24.029909	, lat:60.1903374 },
        { lng:24.0299754	, lat:60.1903472 },
        { lng:24.0300059	, lat:60.1903793 },
        { lng:24.03004	, lat:60.1904025 },
        { lng:24.0300759	, lat:60.1904114 },
        { lng:24.0301333	, lat:60.1904007 },
        { lng:24.0302266	, lat:60.1903632 },
        { lng:24.0302786	, lat:60.1903302 },
        { lng:24.0302876	, lat:60.1903044 },
        { lng:24.0302571	, lat:60.1902821 },
        { lng:24.0301889	, lat:60.1902446 },
        { lng:24.0301548	, lat:60.1902035 },
        { lng:24.0301512	, lat:60.1901723 },
        { lng:24.0301853	, lat:60.1901491 },
        { lng:24.0302374	, lat:60.1901375 },
        { lng:24.0303612	, lat:60.1901402 },
        { lng:24.0304276	, lat:60.1901277 },
        { lng:24.0305773	, lat:60.1901011 },
        { lng:24.030686	, lat:60.1901143 },
        { lng:24.0307344	, lat:60.1901357 },
        { lng:24.0307847	, lat:60.1901714 },
        { lng:24.0308852	, lat:60.190225 },
        { lng:24.0309301	, lat:60.1902285 },
        { lng:24.0309606	, lat:60.1902196 },
        { lng:24.0309857	, lat:60.1901937 },
        { lng:24.0309462	, lat:60.1901429 },
        { lng:24.0309552	, lat:60.1901018 },
        { lng:24.0309803	, lat:60.1900688 },
        { lng:24.0312727	, lat:60.1900077 },
        { lng:24.03135	, lat:60.189968 },
        { lng:24.0314774	, lat:60.1898958 },
        { lng:24.0315635	, lat:60.1898699 },
        { lng:24.0316389	, lat:60.189861 },
        { lng:24.0317268	, lat:60.1898788 },
        { lng:24.0317914	, lat:60.1898833 },
        { lng:24.0318327	, lat:60.1898592 },
        { lng:24.0318973	, lat:60.1898512 },
        { lng:24.0319673	, lat:60.1898628 },
        { lng:24.0320193	, lat:60.1898895 },
        { lng:24.0320283	, lat:60.1899234 },
        { lng:24.031987	, lat:60.1899662 },
        { lng:24.031935	, lat:60.1900233 },
        { lng:24.0319493	, lat:60.190059 },
        { lng:24.031987	, lat:60.1900867 },
        { lng:24.0320768	, lat:60.1901277 },
        { lng:24.0322275	, lat:60.1901661 },
        { lng:24.0322688	, lat:60.1901964 },
        { lng:24.0322957	, lat:60.1902696 },
        { lng:24.0323459	, lat:60.1902919 },
        { lng:24.0324052	, lat:60.1903008 },
        { lng:24.032459	, lat:60.1902945 },
        { lng:24.0325272	, lat:60.1902812 },
        { lng:24.0326708	, lat:60.1902196 },
        { lng:24.0327587	, lat:60.1901732 },
        { lng:24.0328269	, lat:60.1901063 },
        { lng:24.0328556	, lat:60.1900787 },
        { lng:24.0329399	, lat:60.1900662 },
        { lng:24.0330225	, lat:60.1900769 },
        { lng:24.0330763	, lat:60.1901143 },
        { lng:24.0331302	, lat:60.1901242 },
        { lng:24.0332576	, lat:60.1901331 },
        { lng:24.0333383	, lat:60.1901482 },
        { lng:24.033428	, lat:60.1901839 },
        { lng:24.0334801	, lat:60.1902178 },
        { lng:24.0335339	, lat:60.1902205 },
        { lng:24.0335698	, lat:60.1901804 },
        { lng:24.033638	, lat:60.1900947 },
        { lng:24.0336954	, lat:60.1900653 },
        { lng:24.0337762	, lat:60.1900572 },
        { lng:24.0338193	, lat:60.1900706 },
        { lng:24.0338318	, lat:60.1900947 },
        { lng:24.0337744	, lat:60.1901634 },
        { lng:24.0337529	, lat:60.1901928 },
        { lng:24.0337816	, lat:60.1902232 },
        { lng:24.0338139	, lat:60.1902374 },
        { lng:24.0338749	, lat:60.1902366 },
        { lng:24.0339503	, lat:60.1902285 },
        { lng:24.0339969	, lat:60.1902125 },
        { lng:24.0340167	, lat:60.190192 },
        { lng:24.0340328	, lat:60.1900564 },
        { lng:24.034049	, lat:60.1899743 },
        { lng:24.0340687	, lat:60.18996 },
        { lng:24.0341297	, lat:60.1899591 },
        { lng:24.0342482	, lat:60.1899796 },
        { lng:24.0343253	, lat:60.1899921 },
        { lng:24.0345407	, lat:60.1900019 },
        { lng:24.0346878	, lat:60.1900216 },
        { lng:24.0348773	, lat:60.190084 },
        { lng:24.0349462	, lat:60.1901161 },
        { lng:24.0350324	, lat:60.1901215 },
        { lng:24.0350629	, lat:60.1901099 },
        { lng:24.0352226	, lat:60.1899752 },
        { lng:24.0352244	, lat:60.1899359 },
        { lng:24.0352028	, lat:60.1899056 },
        { lng:24.0351311	, lat:60.189861 },
        { lng:24.0350772	, lat:60.1898369 },
        { lng:24.0350826	, lat:60.1897985 },
        { lng:24.0351203	, lat:60.1897843 },
        { lng:24.0352082	, lat:60.189778 },
        { lng:24.0352226	, lat:60.1897361 },
        { lng:24.0352513	, lat:60.1897218 },
        { lng:24.0353913	, lat:60.1897298 },
        { lng:24.0354541	, lat:60.1897254 },
        { lng:24.0355492	, lat:60.1896852 },
        { lng:24.0357017	, lat:60.189687 },
        { lng:24.0357753	, lat:60.1897066 },
        { lng:24.0358273	, lat:60.1897057 },
        { lng:24.0358991	, lat:60.1896897 },
        { lng:24.0359889	, lat:60.1896665 },
        { lng:24.0361306	, lat:60.1896754 },
        { lng:24.036206	, lat:60.1896834 },
        { lng:24.0362329	, lat:60.1897084 },
        { lng:24.0362383	, lat:60.1897414 },
        { lng:24.0362562	, lat:60.1898101 },
        { lng:24.0362832	, lat:60.1898422 },
        { lng:24.0363191	, lat:60.1898628 },
        { lng:24.0363729	, lat:60.1898663 },
        { lng:24.0364231	, lat:60.1898503 },
        { lng:24.0364698	, lat:60.1898476 },
        { lng:24.0365111	, lat:60.1898637 },
        { lng:24.03652	, lat:60.1898895 },
        { lng:24.0364931	, lat:60.1899145 },
        { lng:24.0363244	, lat:60.1899734 },
        { lng:24.036285	, lat:60.1900001 },
        { lng:24.0362598	, lat:60.1900225 },
        { lng:24.0362437	, lat:60.190051 },
        { lng:24.0362509	, lat:60.1900769 },
        { lng:24.036285	, lat:60.1901108 },
        { lng:24.0363442	, lat:60.190134 },
        { lng:24.0363926	, lat:60.1901473 },
        { lng:24.0365164	, lat:60.1901509 },
        { lng:24.0365775	, lat:60.1901536 },
        { lng:24.0366277	, lat:60.1901759 },
        { lng:24.0366582	, lat:60.1902044 },
        { lng:24.0366564	, lat:60.1902339 },
        { lng:24.0366528	, lat:60.1902767 },
        { lng:24.0367462	, lat:60.19034 },
        { lng:24.0368	, lat:60.190365 },
        { lng:24.0369095	, lat:60.1903864 },
        { lng:24.0369651	, lat:60.1903864 },
        { lng:24.0370225	, lat:60.1903686 },
        { lng:24.0370997	, lat:60.1903516 },
        { lng:24.0371715	, lat:60.1903615 },
        { lng:24.0372163	, lat:60.1903918 },
        { lng:24.0372325	, lat:60.1904069 },
        { lng:24.0372881	, lat:60.1904239 },
        { lng:24.0373509	, lat:60.190415 },
        { lng:24.0374317	, lat:60.1904069 },
        { lng:24.0374891	, lat:60.1904087 },
        { lng:24.0375411	, lat:60.1904301 },
        { lng:24.0375519	, lat:60.1904596 },
        { lng:24.0375322	, lat:60.1904863 },
        { lng:24.0375017	, lat:60.1905122 },
        { lng:24.0374514	, lat:60.1905372 },
        { lng:24.0374209	, lat:60.1906041 },
        { lng:24.0374299	, lat:60.1906273 },
        { lng:24.0374801	, lat:60.1906558 },
        { lng:24.0375465	, lat:60.1906665 },
        { lng:24.0376596	, lat:60.1906639 },
        { lng:24.0377349	, lat:60.1906451 },
        { lng:24.0378013	, lat:60.1906309 },
        { lng:24.0378462	, lat:60.1906425 },
        { lng:24.0378677	, lat:60.1906701 },
        { lng:24.037857	, lat:60.1906969 },
        { lng:24.0377816	, lat:60.1907352 },
        { lng:24.0376685	, lat:60.1907647 },
        { lng:24.0376201	, lat:60.1907932 },
        { lng:24.0375932	, lat:60.1908227 },
        { lng:24.0376004	, lat:60.1908548 },
        { lng:24.0376524	, lat:60.1908851 },
        { lng:24.0377242	, lat:60.1908994 },
        { lng:24.0378211	, lat:60.1908798 },
        { lng:24.0379	, lat:60.1908699 },
        { lng:24.0379664	, lat:60.1908691 },
        { lng:24.0380346	, lat:60.1908691 },
        { lng:24.0381082	, lat:60.1908905 },
        { lng:24.0381369	, lat:60.1909208 },
        { lng:24.0381423	, lat:60.1909636 },
        { lng:24.0381082	, lat:60.1909948 },
        { lng:24.0380669	, lat:60.1910225 },
        { lng:24.0380687	, lat:60.1910466 },
        { lng:24.0380831	, lat:60.191068 },
        { lng:24.0381208	, lat:60.1910778 },
        { lng:24.0382015	, lat:60.1910787 },
        { lng:24.0383092	, lat:60.1910903 },
        { lng:24.0384061	, lat:60.1911108 },
        { lng:24.0384187	, lat:60.1911465 },
        { lng:24.0384402	, lat:60.1912268 },
        { lng:24.038442	, lat:60.1912723 },
        { lng:24.0384546	, lat:60.1913026 },
        { lng:24.0385227	, lat:60.1913142 },
        { lng:24.0385586	, lat:60.1913142 },
        { lng:24.0385945	, lat:60.1912821 },
        { lng:24.0386107	, lat:60.191258 },
        { lng:24.0386663	, lat:60.1912375 },
        { lng:24.0387291	, lat:60.1912446 },
        { lng:24.0388368	, lat:60.1912741 },
        { lng:24.0388924	, lat:60.1913044 },
        { lng:24.038975	, lat:60.1913615 },
        { lng:24.0390234	, lat:60.1914007 },
        { lng:24.0390575	, lat:60.191432 },
        { lng:24.0391078	, lat:60.1914436 },
        { lng:24.0391778	, lat:60.1914293 },
        { lng:24.0392065	, lat:60.1914123 },
        { lng:24.0392603	, lat:60.1913749 },
        { lng:24.039307	, lat:60.1913427 },
        { lng:24.0393572	, lat:60.1913276 },
        { lng:24.039411	, lat:60.1913267 },
        { lng:24.0394631	, lat:60.1913329 },
        { lng:24.0395313	, lat:60.1913526 },
        { lng:24.0395564	, lat:60.1913642 },
        { lng:24.0397646	, lat:60.191349 },
        { lng:24.0398543	, lat:60.1913713 },
        { lng:24.0399584	, lat:60.1913793 },
        { lng:24.0400086	, lat:60.1913856 },
        { lng:24.0400786	, lat:60.1914123 },
        { lng:24.0402042	, lat:60.19144 },
        { lng:24.0404232	, lat:60.1914382 },
        { lng:24.040477	, lat:60.1914623 },
        { lng:24.0405201	, lat:60.1914998 },
        { lng:24.0405254	, lat:60.1915818 },
        { lng:24.0405398	, lat:60.1916389 },
        { lng:24.0405649	, lat:60.1916603 },
        { lng:24.0406439	, lat:60.1916844 },
        { lng:24.0407246	, lat:60.1916844 },
        { lng:24.0408574	, lat:60.1916559 },
        { lng:24.0409095	, lat:60.1916452 },
        { lng:24.0409633	, lat:60.1916487 },
        { lng:24.0410207	, lat:60.1916835 },
        { lng:24.0410584	, lat:60.1917379 },
        { lng:24.0410943	, lat:60.1917915 },
        { lng:24.041123	, lat:60.1918182 },
        { lng:24.0411679	, lat:60.1918218 },
        { lng:24.0413258	, lat:60.1918191 },
        { lng:24.0414084	, lat:60.1918155 },
        { lng:24.0414478	, lat:60.1917995 },
        { lng:24.0414317	, lat:60.1917754 },
        { lng:24.0414137	, lat:60.1917522 },
        { lng:24.0414335	, lat:60.1917254 },
        { lng:24.0415824	, lat:60.1917103 },
        { lng:24.0418103	, lat:60.1916862 },
        { lng:24.042058	, lat:60.1917023 },
        { lng:24.0421585	, lat:60.1917192 },
        { lng:24.0422374	, lat:60.1917103 },
        { lng:24.0422877	, lat:60.1916924 },
        { lng:24.0422787	, lat:60.1916585 },
        { lng:24.0422751	, lat:60.1916184 },
        { lng:24.0422931	, lat:60.1915738 },
        { lng:24.0423254	, lat:60.1915256 },
        { lng:24.0424295	, lat:60.1914917 },
        { lng:24.042582	, lat:60.1914525 },
        { lng:24.0426466	, lat:60.191415 },
        { lng:24.0428368	, lat:60.1913579 },
        { lng:24.0428889	, lat:60.1913356 },
        { lng:24.0430217	, lat:60.1913026 },
        { lng:24.0431562	, lat:60.191299 },
        { lng:24.0431957	, lat:60.1912687 },
        { lng:24.0432926	, lat:60.1912625 },
        { lng:24.0434129	, lat:60.1912741 },
        { lng:24.0435098	, lat:60.1912464 },
        { lng:24.04363	, lat:60.1912544 },
        { lng:24.0437305	, lat:60.1912946 },
        { lng:24.0438543	, lat:60.1913784 },
        { lng:24.0441533	, lat:60.1914912 },
        { lng:24.0443186	, lat:60.1916326 },
        { lng:24.044394	, lat:60.1917628 },
        { lng:24.0444855	, lat:60.1918556 },
        { lng:24.0445537	, lat:60.1921071 },
        { lng:24.0445752	, lat:60.1921526 },
        { lng:24.0446883	, lat:60.192323 },
        { lng:24.0447152	, lat:60.1923944 },
        { lng:24.0448426	, lat:60.192579 },
        { lng:24.0448677	, lat:60.1926825 },
        { lng:24.0449198	, lat:60.1927396 },
        { lng:24.044961	, lat:60.1928386 },
        { lng:24.0449808	, lat:60.1929198 },
        { lng:24.0450328	, lat:60.193001 },
        { lng:24.0450382	, lat:60.1930545 },
        { lng:24.0451225	, lat:60.1931544 },
        { lng:24.0452643	, lat:60.1934827 },
        { lng:24.0455622	, lat:60.1941115 },
        { lng:24.0456448	, lat:60.1942248 },
        { lng:24.0457793	, lat:60.1943756 },
        { lng:24.0458368	, lat:60.1944621 },
        { lng:24.0459014	, lat:60.1945406 },
        { lng:24.046018	, lat:60.1946226 },
        { lng:24.0430376	, lat:60.1963849 },
        { lng:24.0425782	, lat:60.196945 },
        { lng:24.0425172	, lat:60.1975872 },
        { lng:24.0428438	, lat:60.1978476 },
        { lng:24.0373462	, lat:60.2036414 },
        { lng:24.0443859	, lat:60.205354 },
        { lng:24.0459788	, lat:60.2068474 },
        { lng:24.046609	, lat:60.2074382 },
        { lng:24.047386	, lat:60.2081667 },
        { lng:24.0480379	, lat:60.2087778 },
        { lng:24.048647	, lat:60.2093488 },
        { lng:24.0491325	, lat:60.209804 },
        { lng:24.049414	, lat:60.210068 },
        { lng:24.0497386	, lat:60.210159 },
        { lng:24.0642409	, lat:60.2142276 },
        { lng:24.0643221	, lat:60.2142647 },
        { lng:24.0683217	, lat:60.2160997 },
        { lng:24.0702239	, lat:60.2170286 },
        { lng:24.0716532	, lat:60.2175845 },
        { lng:24.0766637	, lat:60.2198677 },
        { lng:24.0791881	, lat:60.2210181 },
        { lng:24.0817474	, lat:60.2221843 },
        { lng:24.0820264	, lat:60.2223115 },
        { lng:24.0769571	, lat:60.2274893 },
        { lng:24.0855057	, lat:60.2302945 },
        { lng:24.0863939	, lat:60.2297199 },
        { lng:24.0872517	, lat:60.2293091 },
        { lng:24.0875461	, lat:60.2287887 },
        { lng:24.0878659	, lat:60.2286917 },
        { lng:24.0878684	, lat:60.2285203 },
        { lng:24.0873735	, lat:60.2281939 },
        { lng:24.0880055	, lat:60.2277604 },
        { lng:24.0889176	, lat:60.2252377 },
        { lng:24.0938971	, lat:60.2267496 },
        { lng:24.0953397	, lat:60.2271566 },
        { lng:24.0992711	, lat:60.2282687 },
        { lng:24.1205942	, lat:60.2307709 },
        { lng:24.1286565	, lat:60.2317442 },
        { lng:24.1306939	, lat:60.2319342 },
        { lng:24.1387189	, lat:60.2280135 },
        { lng:24.1471594	, lat:60.2238008 },
        { lng:24.1474756	, lat:60.2237635 },
        { lng:24.1477329	, lat:60.2237263 },
        { lng:24.147899	, lat:60.2236757 },
        { lng:24.1480705	, lat:60.2235693 },
        { lng:24.1482956	, lat:60.2237449 },
        { lng:24.1485046	, lat:60.2238673 },
        { lng:24.1487886	, lat:60.2239951 },
        { lng:24.1494424	, lat:60.2242107 },
        { lng:24.1497532	, lat:60.2242506 },
        { lng:24.1500533	, lat:60.2243118 },
        { lng:24.1502838	, lat:60.2243357 },
        { lng:24.1506267	, lat:60.2243411 },
        { lng:24.1510233	, lat:60.2243437 },
        { lng:24.1515699	, lat:60.2243224 },
        { lng:24.1518647	, lat:60.2243251 },
        { lng:24.1521755	, lat:60.2243224 },
        { lng:24.1524327	, lat:60.2243198 },
        { lng:24.1527972	, lat:60.2242772 },
        { lng:24.1530812	, lat:60.2242665 },
        { lng:24.1535206	, lat:60.2242692 },
        { lng:24.153869	, lat:60.2243198 },
        { lng:24.1540726	, lat:60.224349 },
        { lng:24.1542441	, lat:60.2244023 },
        { lng:24.1544156	, lat:60.2244768 },
        { lng:24.1546085	, lat:60.2245912 },
        { lng:24.1549569	, lat:60.2247935 },
        { lng:24.1550855	, lat:60.224884 },
        { lng:24.1551766	, lat:60.2250383 },
        { lng:24.1552784	, lat:60.2251714 },
        { lng:24.1559751	, lat:60.2259165 },
        { lng:24.1561251	, lat:60.2261268 },
        { lng:24.156243	, lat:60.2262705 },
        { lng:24.1564038	, lat:60.2263556 },
        { lng:24.156586	, lat:60.2264248 },
        { lng:24.1581228	, lat:60.2268048 },
        { lng:24.1592227	, lat:60.2270768 },
        { lng:24.1590619	, lat:60.2271912 },
        { lng:24.1592012	, lat:60.2271886 },
        { lng:24.159437	, lat:60.2271699 },
        { lng:24.1597157	, lat:60.2271699 },
        { lng:24.1606535	, lat:60.2274307 },
        { lng:24.1607661	, lat:60.2274839 },
        { lng:24.1608089	, lat:60.2275531 },
        { lng:24.1611519	, lat:60.2276383 },
        { lng:24.1615003	, lat:60.2278352 },
        { lng:24.1615324	, lat:60.2278272 },
        { lng:24.1617361	, lat:60.2278911 },
        { lng:24.1618164	, lat:60.2279816 },
        { lng:24.1619236	, lat:60.2280561 },
        { lng:24.1619343	, lat:60.2281971 },
        { lng:24.1613824	, lat:60.2280188 },
        { lng:24.160884	, lat:60.2280321 },
        { lng:24.1601391	, lat:60.2282131 },
        { lng:24.1590726	, lat:60.2286495 },
        { lng:24.1586653	, lat:60.2288251 },
        { lng:24.158049	, lat:60.2290619 },
        { lng:24.1573952	, lat:60.2292455 },
        { lng:24.1542602	, lat:60.2299587 },
        { lng:24.1540351	, lat:60.2299959 },
        { lng:24.1538475	, lat:60.2300518 },
        { lng:24.1534938	, lat:60.2302088 },
        { lng:24.153108	, lat:60.2303125 },
        { lng:24.1527436	, lat:60.2304216 },
        { lng:24.1522345	, lat:60.2305494 },
        { lng:24.152063	, lat:60.2305919 },
        { lng:24.1517521	, lat:60.2306824 },
        { lng:24.1515217	, lat:60.230717 },
        { lng:24.1512752	, lat:60.2307276 },
        { lng:24.1502302	, lat:60.2307942 },
        { lng:24.1500105	, lat:60.2308048 },
        { lng:24.1498497	, lat:60.23085 },
        { lng:24.1497211	, lat:60.2309166 },
        { lng:24.1495389	, lat:60.2309831 },
        { lng:24.1492548	, lat:60.2310203 },
        { lng:24.148601	, lat:60.2310868 },
        { lng:24.1659215	, lat:60.2435689 },
        { lng:24.166034	, lat:60.2436061 },
        { lng:24.1672023	, lat:60.2429757 },
        { lng:24.1679365	, lat:60.2426539 },
        { lng:24.1691101	, lat:60.2419677 },
        { lng:24.1692119	, lat:60.2418746 },
        { lng:24.1694102	, lat:60.2417841 },
        { lng:24.1695978	, lat:60.2416963 },
        { lng:24.1697746	, lat:60.2415474 },
        { lng:24.1699086	, lat:60.2413638 },
        { lng:24.1699408	, lat:60.2412042 },
        { lng:24.1699461	, lat:60.2410819 },
        { lng:24.1699354	, lat:60.240925 },
        { lng:24.1698818	, lat:60.2407866 },
        { lng:24.1698765	, lat:60.2406483 },
        { lng:24.1699086	, lat:60.2404807 },
        { lng:24.1699568	, lat:60.2403477 },
        { lng:24.1699568	, lat:60.2402333 },
        { lng:24.1698925	, lat:60.2401402 },
        { lng:24.1698175	, lat:60.2400764 },
        { lng:24.1697264	, lat:60.2399727 },
        { lng:24.1695656	, lat:60.2398849 },
        { lng:24.1694799	, lat:60.2398157 },
        { lng:24.1693352	, lat:60.2396694 },
        { lng:24.1692495	, lat:60.2394938 },
        { lng:24.1692227	, lat:60.2393688 },
        { lng:24.1691048	, lat:60.2392411 },
        { lng:24.1689922	, lat:60.2390044 },
        { lng:24.1689377	, lat:60.2389302 },
        { lng:24.1688904	, lat:60.2388661 },
        { lng:24.1687403	, lat:60.2388049 },
        { lng:24.1684938	, lat:60.2387863 },
        { lng:24.1682098	, lat:60.2387809 },
        { lng:24.1679686	, lat:60.2387836 },
        { lng:24.1679117	, lat:60.2387716 },
        { lng:24.1677918	, lat:60.2387464 },
        { lng:24.1672988	, lat:60.2385894 },
        { lng:24.1669987	, lat:60.2385309 },
        { lng:24.1670576	, lat:60.2384378 },
        { lng:24.1679217	, lat:60.2323586 },
        { lng:24.1680626	, lat:60.231452 },
        { lng:24.1902655	, lat:60.2444432 },
        { lng:24.2185296	, lat:60.2607275 },
        { lng:24.2250334	, lat:60.2604768 },
        { lng:24.2276201	, lat:60.2604325 },
        { lng:24.2284154	, lat:60.260344 },
        { lng:24.2482326	, lat:60.2611983 },
        { lng:24.2502569	, lat:60.26256 },
        { lng:24.2536204	, lat:60.2648583 },
        { lng:24.2599366	, lat:60.2691741 },
        { lng:24.2642328	, lat:60.2722962 },
        { lng:24.2707411	, lat:60.2767727 },
        { lng:24.2846446	, lat:60.2853522 },
        { lng:24.2893255	, lat:60.2882326 },
        { lng:24.3056864	, lat:60.2946383 },
        { lng:24.2919377	, lat:60.3032139 },
        { lng:24.2806664	, lat:60.3078975 },
        { lng:24.2805776	, lat:60.3079346 },
        { lng:24.2792464	, lat:60.3084876 },
        { lng:24.277497	, lat:60.3092145 },
        { lng:24.2761054	, lat:60.3097927 },
        { lng:24.2686239	, lat:60.3070753 },
        { lng:24.2675315	, lat:60.3090035 },
        { lng:24.261405	, lat:60.3100896 },
        { lng:24.2592616	, lat:60.3116678 },
        { lng:24.2582104	, lat:60.3119403 },
        { lng:24.2583007	, lat:60.3123349 },
        { lng:24.2574291	, lat:60.3129551 },
        { lng:24.2557795	, lat:60.3141285 },
        { lng:24.2507532	, lat:60.3129172 },
        { lng:24.2458985	, lat:60.3112893 },
        { lng:24.2438137	, lat:60.310924 },
        { lng:24.2413864	, lat:60.3099835 },
        { lng:24.2424665	, lat:60.3093707 },
        { lng:24.2412706	, lat:60.3084733 },
        { lng:24.2399001	, lat:60.308784 },
        { lng:24.2403995	, lat:60.309319 },
        { lng:24.2310091	, lat:60.3062569 },
        { lng:24.2261086	, lat:60.3046542 },
        { lng:24.2132402	, lat:60.3004647 },
        { lng:24.2081236	, lat:60.2987104 },
        { lng:24.2023817	, lat:60.3192414 },
        { lng:24.207897	, lat:60.3441407 },
        { lng:24.2038863	, lat:60.3508275 },
        { lng:24.2031616	, lat:60.3505829 },
        { lng:24.2034106	, lat:60.3510959 },
        { lng:24.2035667	, lat:60.3510941 },
        { lng:24.2037042	, lat:60.3510738 },
        { lng:24.2039012	, lat:60.3510904 },
        { lng:24.2040164	, lat:60.3511492 },
        { lng:24.2041241	, lat:60.3512136 },
        { lng:24.2041427	, lat:60.3512889 },
        { lng:24.2040981	, lat:60.3513588 },
        { lng:24.2040498	, lat:60.3514176 },
        { lng:24.2040201	, lat:60.3515004 },
        { lng:24.2039458	, lat:60.3515261 },
        { lng:24.2038528	, lat:60.3515224 },
        { lng:24.2037636	, lat:60.3514967 },
        { lng:24.2037116	, lat:60.3514949 },
        { lng:24.2037116	, lat:60.35155 },
        { lng:24.2037897	, lat:60.3516474 },
        { lng:24.2038677	, lat:60.3517247 },
        { lng:24.2039643	, lat:60.3517817 },
        { lng:24.2039978	, lat:60.3518405 },
        { lng:24.2040015	, lat:60.3519122 },
        { lng:24.2039532	, lat:60.3519508 },
        { lng:24.2039123	, lat:60.3520059 },
        { lng:24.2039235	, lat:60.3520685 },
        { lng:24.2039903	, lat:60.3521328 },
        { lng:24.2039903	, lat:60.3521861 },
        { lng:24.2039458	, lat:60.3522284 },
        { lng:24.2039643	, lat:60.3528681 },
        { lng:24.2052763	, lat:60.3533368 },
        { lng:24.2046444	, lat:60.3537927 },
        { lng:24.2065934	, lat:60.3578339 },
        { lng:24.2057832	, lat:60.3607178 },
        { lng:24.2040402	, lat:60.3629232 },
        { lng:24.2021647	, lat:60.3639542 },
        { lng:24.2019788	, lat:60.3647996 },
        { lng:24.2012504	, lat:60.364976 },
        { lng:24.200332	, lat:60.3656587 },
        { lng:24.1991316	, lat:60.3663991 },
        { lng:24.1849098	, lat:60.3794255 },
        { lng:24.1685196	, lat:60.3775897 },
        { lng:24.1622929	, lat:60.3897694 },
        { lng:24.1339781	, lat:60.3918473 },
        { lng:24.1382303	, lat:60.3958468 },
        { lng:24.1433562	, lat:60.4031885 },
        { lng:24.1416407	, lat:60.4032571 },
        { lng:24.1415365	, lat:60.4047527 },
        { lng:24.1408906	, lat:60.4049071 },
        { lng:24.1401196	, lat:60.4044543 },
        { lng:24.1378137	, lat:60.40513 },
        { lng:24.1368969	, lat:60.4044474 },
        { lng:24.1370914	, lat:60.4040598 },
        { lng:24.1373553	, lat:60.4033669 },
        { lng:24.1255746	, lat:60.4037096 },
        { lng:24.1254812	, lat:60.4038625 },
        { lng:24.1257022	, lat:60.4038163 },
        { lng:24.1268318	, lat:60.4048884 },
        { lng:24.1274457	, lat:60.4054584 },
        { lng:24.1283248	, lat:60.4059337 },
        { lng:24.1295232	, lat:60.4061617 },
        { lng:24.1294544	, lat:60.4064067 },
        { lng:24.1298129	, lat:60.4064309 },
        { lng:24.1298424	, lat:60.4066056 },
        { lng:24.1303237	, lat:60.4065887 },
        { lng:24.1306871	, lat:60.4066274 },
        { lng:24.1307903	, lat:60.4068408 },
        { lng:24.1309671	, lat:60.4069355 },
        { lng:24.1313108	, lat:60.4069573 },
        { lng:24.1317921	, lat:60.40695 },
        { lng:24.1344295	, lat:60.4072022 },
        { lng:24.1347831	, lat:60.4074059 },
        { lng:24.1349795	, lat:60.4080195 },
        { lng:24.1348273	, lat:60.4081044 },
        { lng:24.1360502	, lat:60.4091084 },
        { lng:24.1392375	, lat:60.4102191 },
        { lng:24.1401658	, lat:60.4109564 },
        { lng:24.1403131	, lat:60.4113516 },
        { lng:24.1409466	, lat:60.4114559 },
        { lng:24.1418896	, lat:60.4121276 },
        { lng:24.1416047	, lat:60.412649 },
        { lng:24.138143	, lat:60.4143513 },
        { lng:24.1379704	, lat:60.4144361 },
        { lng:24.1378477	, lat:60.4147271 },
        { lng:24.1372485	, lat:60.41504 },
        { lng:24.1370324	, lat:60.4161188 },
        { lng:24.1371208	, lat:60.4166693 },
        { lng:24.136836	, lat:60.4173845 },
        { lng:24.1365364	, lat:60.4174136 },
        { lng:24.1356112	, lat:60.4197498 },
        { lng:24.1352693	, lat:60.4220406 },
        { lng:24.1352625	, lat:60.422791 },
        { lng:24.1357356	, lat:60.4241596 },
        { lng:24.1349036	, lat:60.4257941 },
        { lng:24.1341277	, lat:60.4258577 },
        { lng:24.1414021	, lat:60.4439897 },
        { lng:24.1469605	, lat:60.4552325 },
        { lng:24.1623042	, lat:60.473547 },
        { lng:24.1712327	, lat:60.4847281 },
        { lng:24.1631286	, lat:60.493616 },
        { lng:24.1534471	, lat:60.5061251 },
        { lng:24.1553536	, lat:60.513215 },
        { lng:24.0983085	, lat:60.5482755 },
        { lng:24.0824163	, lat:60.561674 },
        { lng:24.0715533	, lat:60.5718179 },
        { lng:24.0567323	, lat:60.5846761 },
        { lng:24.0276095	, lat:60.6106917 },
        { lng:24.0244585	, lat:60.6021642 },
        { lng:24.0080866	, lat:60.5930726 },
        { lng:24.0080662	, lat:60.5924808 },
        { lng:23.998107	, lat:60.5951038 },
        { lng:23.9932552	, lat:60.5979021 },
        { lng:23.9967996	, lat:60.5994566 },
        { lng:23.9988629	, lat:60.5993462 },
        { lng:24.0034928	, lat:60.607031 },
        { lng:24.0129792	, lat:60.6064001 },
        { lng:24.0167228	, lat:60.6061876 },
        { lng:24.021199	, lat:60.6088271 },
        { lng:24.0195238	, lat:60.609569 },
        { lng:24.0284257	, lat:60.611592 },
        { lng:24.0286857	, lat:60.6118401 },
        { lng:24.0285791	, lat:60.611965 },
        { lng:24.0282257	, lat:60.6123695 },
        { lng:24.0280527	, lat:60.6124655 },
        { lng:24.027879	, lat:60.6125608 },
        { lng:24.0276952	, lat:60.6137637 },
        { lng:24.0255195	, lat:60.6145456 },
        { lng:24.0249045	, lat:60.6147572 },
        { lng:24.0243346	, lat:60.6148271 },
        { lng:24.0224623	, lat:60.6151466 },
        { lng:24.0219128	, lat:60.6155461 },
        { lng:24.0208953	, lat:60.6159755 },
        { lng:24.0202033	, lat:60.6158656 },
        { lng:24.0195521	, lat:60.6160653 },
        { lng:24.0197963	, lat:60.6169041 },
        { lng:24.0190351	, lat:60.6180693 },
        { lng:24.0185561	, lat:60.6186153 },
        { lng:24.0186376	, lat:60.6191419 },
        { lng:24.0191841	, lat:60.6199219 },
        { lng:24.0198301	, lat:60.6202875 },
        { lng:24.0225131	, lat:60.6211894 },
        { lng:24.0225312	, lat:60.6213722 },
        { lng:24.0205956	, lat:60.6220457 },
        { lng:24.0200779	, lat:60.6230725 },
        { lng:24.0196053	, lat:60.6238675 },
        { lng:24.0188851	, lat:60.6243753 },
        { lng:24.0183899	, lat:60.6248611 },
        { lng:24.0173096	, lat:60.6250157 },
        { lng:24.0154686	, lat:60.627055 },
        { lng:24.0064157	, lat:60.6231167 },
        { lng:24.0033799	, lat:60.6244274 },
        { lng:23.9712644	, lat:60.6382891 },
        { lng:23.9670705	, lat:60.6377921 },
        { lng:23.9667675	, lat:60.6378606 },
        { lng:23.9666766	, lat:60.6377457 },
        { lng:23.9649056	, lat:60.6381574 },
        { lng:23.9637547	, lat:60.6382819 },
        { lng:23.9636872	, lat:60.638598 },
        { lng:23.9633085	, lat:60.638577 },
        { lng:23.9629685	, lat:60.6383445 },
        { lng:23.9625992	, lat:60.6383362 },
        { lng:23.96236	, lat:60.6385457 },
        { lng:23.9619779	, lat:60.6385832 },
        { lng:23.9616647	, lat:60.6381645 },
        { lng:23.9598307	, lat:60.6383368 },
        { lng:23.9467466	, lat:60.6423785 },
        { lng:23.9453823	, lat:60.6430034 },
        { lng:23.944847	, lat:60.6432087 },
        { lng:23.9443583	, lat:60.6432658 },
        { lng:23.9439393	, lat:60.6434369 },
        { lng:23.9434273	, lat:60.6434598 },
        { lng:23.9394989	, lat:60.6445465 },
        { lng:23.9382331	, lat:60.6450037 },
        { lng:23.9381925	, lat:60.6452417 },
        { lng:23.9381304	, lat:60.645335 },
        { lng:23.9377947	, lat:60.6455083 },
        { lng:23.9374701	, lat:60.6456197 },
        { lng:23.9371864	, lat:60.645646 },
        { lng:23.9369262	, lat:60.6456993 },
        { lng:23.9367121	, lat:60.6456906 },
        { lng:23.9362302	, lat:60.6455787 },
        { lng:23.9358994	, lat:60.6455587 },
        { lng:23.9356633	, lat:60.6455965 },
        { lng:23.9352497	, lat:60.6456999 },
        { lng:23.9350559	, lat:60.6456611 },
        { lng:23.9349403	, lat:60.6456395 },
        { lng:23.9348754	, lat:60.6456767 },
        { lng:23.934719	, lat:60.6457034 },
        { lng:23.9346304	, lat:60.6456673 },
        { lng:23.9346343	, lat:60.6455653 },
        { lng:23.9345044	, lat:60.6455588 },
        { lng:23.9343117	, lat:60.6456398 },
        { lng:23.9342154	, lat:60.6456937 },
        { lng:23.9340777	, lat:60.645714 },
        { lng:23.9339105	, lat:60.6456554 },
        { lng:23.9337138	, lat:60.6457243 },
        { lng:23.933486	, lat:60.6457207 },
        { lng:23.9331774	, lat:60.6453772 },
        { lng:23.9328041	, lat:60.6454229 },
        { lng:23.9326548	, lat:60.6455053 },
        { lng:23.9326361	, lat:60.6457615 },
        { lng:23.9322814	, lat:60.645917 },
        { lng:23.9319268	, lat:60.6460909 },
        { lng:23.9318148	, lat:60.646283 },
        { lng:23.9316095	, lat:60.6462922 },
        { lng:23.9311429	, lat:60.6462236 },
        { lng:23.9310158	, lat:60.6462454 },
        { lng:23.931051	, lat:60.6463659 },
        { lng:23.9309434	, lat:60.6464387 },
        { lng:23.9307992	, lat:60.6464434 },
        { lng:23.9308118	, lat:60.6465408 },
        { lng:23.9307172	, lat:60.6465927 },
        { lng:23.9307933	, lat:60.6466731 },
        { lng:23.9307406	, lat:60.6467075 },
        { lng:23.9307375	, lat:60.6468085 },
        { lng:23.9308842	, lat:60.6468215 },
        { lng:23.9309104	, lat:60.6468769 },
        { lng:23.9308003	, lat:60.6469391 },
        { lng:23.9309032	, lat:60.6472502 },
        { lng:23.9307888	, lat:60.6473553 },
        { lng:23.9305601	, lat:60.6474534 },
        { lng:23.9304171	, lat:60.6476356 },
        { lng:23.9304171	, lat:60.6481051 },
        { lng:23.9301094	, lat:60.6482404 },
        { lng:23.9294849	, lat:60.6483617 },
        { lng:23.9286916	, lat:60.6486209 },
        { lng:23.9278466	, lat:60.6493835 },
        { lng:23.9267376	, lat:60.649533 },
        { lng:23.9260715	, lat:60.6497269 },
        { lng:23.9247688	, lat:60.6497729 },
        { lng:23.9239693	, lat:60.6500431 },
        { lng:23.92347	, lat:60.6505039 },
        { lng:23.9229404	, lat:60.6510863 },
        { lng:23.9225505	, lat:60.6516284 },
        { lng:23.9225088	, lat:60.6518324 },
        { lng:23.9219885	, lat:60.6520364 },
        { lng:23.921926	, lat:60.6522404 },
        { lng:23.9219677	, lat:60.6526485 },
        { lng:23.9216763	, lat:60.6527505 },
        { lng:23.920803	, lat:60.6528171 },
        { lng:23.9203969	, lat:60.6528636 },
        { lng:23.9197929	, lat:60.652772 },
        { lng:23.9192518	, lat:60.6528682 },
        { lng:23.9185402	, lat:60.6531568 },
        { lng:23.9182922	, lat:60.6535214 },
        { lng:23.9177464	, lat:60.6537632 },
        { lng:23.9171834	, lat:60.6538563 },
        { lng:23.900453	, lat:60.6497456 },
        { lng:23.8664155	, lat:60.6186434 },
        { lng:23.9205414	, lat:60.6061011 },
        { lng:23.9082896	, lat:60.5868794 },
        { lng:23.8890651	, lat:60.5741335 },
        { lng:23.8891128	, lat:60.5738481 },
        { lng:23.8875089	, lat:60.5585098 },
        { lng:23.8444969	, lat:60.5564312 },
        { lng:23.8459865	, lat:60.5540401 },
        { lng:23.8644651	, lat:60.5387423 },
        { lng:23.8653681	, lat:60.5358991 },
        { lng:23.858342	, lat:60.5341808 },
        { lng:23.8537461	, lat:60.5327608 },
        { lng:23.8547994	, lat:60.5321509 },
        { lng:23.851537	, lat:60.530601 },
        { lng:23.8522268	, lat:60.5294867 },
        { lng:23.8537461	, lat:60.5288217 },
        { lng:23.8552282	, lat:60.5284961 },
        { lng:23.8564959	, lat:60.527533 },
        { lng:23.8564494	, lat:60.5260609 },
        { lng:23.8580154	, lat:60.5257031 },
        { lng:23.8588822	, lat:60.5252628 },
        { lng:23.8589474	, lat:60.5242171 },
        { lng:23.8601219	, lat:60.5241896 },
        { lng:23.8607184	, lat:60.5230429 },
        { lng:23.8612964	, lat:60.5239557 },
        { lng:23.8645588	, lat:60.5215017 },
        { lng:23.8718411	, lat:60.5079392 },
        { lng:23.8675267	, lat:60.5049753 },
        { lng:23.8375973	, lat:60.4912847 },
        { lng:23.8230429	, lat:60.4946421 },
        { lng:23.7374695	, lat:60.4903668 },
        { lng:23.7210221	, lat:60.4800862 },
        { lng:23.7094309	, lat:60.4728391 },
        { lng:23.7151714	, lat:60.4648374 },
        { lng:23.6971927	, lat:60.4515789 },
        { lng:23.696833	, lat:60.4515212 },
        { lng:23.6963982	, lat:60.4514428 },
        { lng:23.6959046	, lat:60.4513644 },
        { lng:23.6954363	, lat:60.4512283 },
        { lng:23.695085	, lat:60.4510509 },
        { lng:23.6948507	, lat:60.4509189 },
        { lng:23.6946082	, lat:60.4508818 },
        { lng:23.6941566	, lat:60.4508488 },
        { lng:23.6938053	, lat:60.4508034 },
        { lng:23.6933451	, lat:60.4506838 },
        { lng:23.6927598	, lat:60.4504899 },
        { lng:23.6926176	, lat:60.450362 },
        { lng:23.6925339	, lat:60.450197 },
        { lng:23.6922411	, lat:60.4499042 },
        { lng:23.6947684	, lat:60.449912 },
        { lng:23.7841303	, lat:60.4501875 },
        { lng:23.7866104	, lat:60.4441725 },
        { lng:23.7922044	, lat:60.4312278 },
        { lng:23.792386	, lat:60.4308072 },
        { lng:23.7962515	, lat:60.4297145 },
        { lng:23.7953408	, lat:60.4293145 },
        { lng:23.7952239	, lat:60.4293446 },
        { lng:23.7948269	, lat:60.4292776 },
        { lng:23.7940577	, lat:60.4289148 },
        { lng:23.7938585	, lat:60.428485 },
        { lng:23.7934968	, lat:60.4282373 },
        { lng:23.7933418	, lat:60.4279423 },
        { lng:23.7930733	, lat:60.4277711 },
        { lng:23.790945	, lat:60.4235722 },
        { lng:23.7843857	, lat:60.404837 },
        { lng:23.7899434	, lat:60.3892615 },
        { lng:23.7900164	, lat:60.3890687 },
        { lng:23.790487	, lat:60.3878263 },
        { lng:23.7902291	, lat:60.38781 },
        { lng:23.7898781	, lat:60.3877879 },
        { lng:23.7896934	, lat:60.3877762 },
        { lng:23.7887329	, lat:60.3877044 },
        { lng:23.7885106	, lat:60.3876878 },
        { lng:23.7880697	, lat:60.3876548 },
        { lng:23.7883909	, lat:60.3870339 },
        { lng:23.7884265	, lat:60.3869651 },
        { lng:23.7866892	, lat:60.3861067 },
        { lng:23.7814363	, lat:60.3855411 },
        { lng:23.7801915	, lat:60.386003 },
        { lng:23.7796352	, lat:60.3862621 },
        { lng:23.7780145	, lat:60.3869059 },
        { lng:23.7776977	, lat:60.3870448 },
        { lng:23.777595	, lat:60.387133 },
        { lng:23.777537	, lat:60.3872542 },
        { lng:23.7775013	, lat:60.387402 },
        { lng:23.777363	, lat:60.3874372 },
        { lng:23.7772113	, lat:60.3874549 },
        { lng:23.7771469	, lat:60.3874643 },
        { lng:23.7770462	, lat:60.3874791 },
        { lng:23.7769034	, lat:60.387488 },
        { lng:23.7766	, lat:60.3875673 },
        { lng:23.7764662	, lat:60.3875541 },
        { lng:23.7762698	, lat:60.3875894 },
        { lng:23.7762573	, lat:60.3876476 },
        { lng:23.7762454	, lat:60.3877026 },
        { lng:23.7762223	, lat:60.3878102 },
        { lng:23.7762029	, lat:60.3879003 },
        { lng:23.7760824	, lat:60.3879223 },
        { lng:23.7758058	, lat:60.3879135 },
        { lng:23.774744	, lat:60.3879546 },
        { lng:23.7745297	, lat:60.3879753 },
        { lng:23.7743619	, lat:60.3879799 },
        { lng:23.7737562	, lat:60.3880259 },
        { lng:23.7728428	, lat:60.388118 },
        { lng:23.7724607	, lat:60.3881342 },
        { lng:23.7716538	, lat:60.3881961 },
        { lng:23.7675248	, lat:60.3885131 },
        { lng:23.7670927	, lat:60.3885463 },
        { lng:23.7665009	, lat:60.3876691 },
        { lng:23.7665324	, lat:60.3875379 },
        { lng:23.7668537	, lat:60.3862003 },
        { lng:23.7668737	, lat:60.3861171 },
        { lng:23.767333	, lat:60.3862354 },
        { lng:23.7691709	, lat:60.3867089 },
        { lng:23.7704093	, lat:60.3868254 },
        { lng:23.7716919	, lat:60.3869461 },
        { lng:23.7720786	, lat:60.3867895 },
        { lng:23.7727483	, lat:60.3871579 },
        { lng:23.7735789	, lat:60.3876147 },
        { lng:23.7738074	, lat:60.3877404 },
        { lng:23.7755735	, lat:60.3878878 },
        { lng:23.7516077	, lat:60.3738896 },
        { lng:23.7457361	, lat:60.3715777 },
        { lng:23.741665	, lat:60.3700029 },
        { lng:23.7231792	, lat:60.3687584 },
        { lng:23.723551	, lat:60.3671209 },
        { lng:23.723627	, lat:60.3659034 },
        { lng:23.7243447	, lat:60.3638344 },
        { lng:23.7236704	, lat:60.3626096 },
        { lng:23.7234466	, lat:60.3618817 },
        { lng:23.7226001	, lat:60.3599531 },
        { lng:23.7232316	, lat:60.3599703 },
        { lng:23.7234156	, lat:60.3598425 },
        { lng:23.7312427	, lat:60.3588981 },
        { lng:23.7339481	, lat:60.3557029 },
        { lng:23.7337675	, lat:60.343245 },
        { lng:23.7353147	, lat:60.3379311 },
        { lng:23.7288631	, lat:60.3289909 },
        { lng:23.7301163	, lat:60.3180295 },
        { lng:23.7309572	, lat:60.3178731 },
        { lng:23.7311041	, lat:60.3174512 },
        { lng:23.7301971	, lat:60.3171548 },
        { lng:23.7307379	, lat:60.3134209 },
        { lng:23.7277892	, lat:60.3077347 },
        { lng:23.7168225	, lat:60.3030058 },
        { lng:23.7012821	, lat:60.2983256 },
        { lng:23.6947324	, lat:60.2974364 },
        { lng:23.6897856	, lat:60.2936892 },
        { lng:23.6866257	, lat:60.2936601 },
        { lng:23.6862605	, lat:60.2938553 },
        { lng:23.6739486	, lat:60.3030604 },
        { lng:23.6656241	, lat:60.2982482 },
        { lng:23.6562422	, lat:60.2924248 },
        { lng:23.6547833	, lat:60.2914546 },
        { lng:23.6522996	, lat:60.2905703 },
        { lng:23.6490511	, lat:60.2875976 },
        { lng:23.6489752	, lat:60.2875358 },
        { lng:23.6489643	, lat:60.2874605 },
        { lng:23.6488071	, lat:60.2873584 },
        { lng:23.6487311	, lat:60.2872616 },
        { lng:23.6486932	, lat:60.2871971 },
        { lng:23.6485901	, lat:60.287095 },
        { lng:23.6484383	, lat:60.2869874 },
        { lng:23.6484058	, lat:60.2869122 },
        { lng:23.6481726	, lat:60.2866407 },
        { lng:23.6481997	, lat:60.2865869 },
        { lng:23.6481834	, lat:60.2865654 },
        { lng:23.6480695	, lat:60.286474 },
        { lng:23.6479556	, lat:60.2864149 },
        { lng:23.6478201	, lat:60.2863477 },
        { lng:23.6477441	, lat:60.2862778 },
        { lng:23.6476899	, lat:60.2861622 },
        { lng:23.6476682	, lat:60.2860386 },
        { lng:23.6476194	, lat:60.2858934 },
        { lng:23.6474784	, lat:60.2857805 },
        { lng:23.6474025	, lat:60.2856219 },
        { lng:23.6429719	, lat:60.2828085 },
        { lng:23.6294336	, lat:60.2826667 },
        { lng:23.6261631	, lat:60.2692965 },
        { lng:23.6271541	, lat:60.2605135 },
        { lng:23.6260115	, lat:60.2606513 },
        { lng:23.6258499	, lat:60.2606126 },
        { lng:23.6255257	, lat:60.2602275 },
        { lng:23.6252043	, lat:60.2601962 },
        { lng:23.6236313	, lat:60.2604327 },
        { lng:23.6231868	, lat:60.2603675 },
        { lng:23.6229752	, lat:60.2603763 },
        { lng:23.6227984	, lat:60.2604265 },
        { lng:23.6223675	, lat:60.2605519 },
        { lng:23.6221708	, lat:60.2607706 },
        { lng:23.6218903	, lat:60.2609446 },
        { lng:23.6209984	, lat:60.2612137 },
        { lng:23.6204829	, lat:60.2613352 },
        { lng:23.6198199	, lat:60.2705376 },
        { lng:23.6214817	, lat:60.26968 },
        { lng:23.621932	, lat:60.2697645 },
        { lng:23.6216623	, lat:60.2700418 },
        { lng:23.6215875	, lat:60.270078 },
        { lng:23.6215907	, lat:60.2701578 },
        { lng:23.6213729	, lat:60.2703412 },
        { lng:23.6200972	, lat:60.2707309 },
        { lng:23.6199322	, lat:60.2709405 },
        { lng:23.6195983	, lat:60.2710817 },
        { lng:23.6193376	, lat:60.271151 },
        { lng:23.6184585	, lat:60.271221 },
        { lng:23.6179975	, lat:60.2712288 },
        { lng:23.6175355	, lat:60.2712001 },
        { lng:23.6168229	, lat:60.2710817 },
        { lng:23.614582	, lat:60.2710578 },
        { lng:23.6144475	, lat:60.2711733 },
        { lng:23.6141962	, lat:60.2712636 },
        { lng:23.6139651	, lat:60.2712742 },
        { lng:23.6136392	, lat:60.2711671 },
        { lng:23.6134516	, lat:60.270885 },
        { lng:23.6136146	, lat:60.2707074 },
        { lng:23.6141305	, lat:60.2703999 },
        { lng:23.6144848	, lat:60.2702947 },
      ],
    },
    {
      id: 18,
      regionName: 'eCom TP  Mikkeli kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:26.8322152	, lat:61.8503106 },
        { lng:26.8299756	, lat:61.8498617 },
        { lng:26.8266235	, lat:61.8331306 },
        { lng:26.8407759	, lat:61.7993028 },
        { lng:26.862189	, lat:61.7994773 },
        { lng:26.8610814	, lat:61.7874951 },
        { lng:26.8885247	, lat:61.7869133 },
        { lng:26.8858173	, lat:61.7347992 },
        { lng:26.9228595	, lat:61.7394027 },
        { lng:26.9409499	, lat:61.693978 },
        { lng:26.9735619	, lat:61.7052384 },
        { lng:26.9754078	, lat:61.6891926 },
        { lng:26.952641	, lat:61.6852236 },
        { lng:26.9571944	, lat:61.6759996 },
        { lng:26.9882065	, lat:61.666364 },
        { lng:26.9952211	, lat:61.6533365 },
        { lng:26.9854991	, lat:61.6449212 },
        { lng:26.9953442	, lat:61.6365622 },
        { lng:26.9560868	, lat:61.6205391 },
        { lng:26.9514104	, lat:61.6108862 },
        { lng:26.9583019	, lat:61.6021667 },
        { lng:26.977623	, lat:61.6046833 },
        { lng:26.9964421	, lat:61.5762847 },
        { lng:27.0020188	, lat:61.571726 },
        { lng:27.0026015	, lat:61.5669601 },
        { lng:27.0036241	, lat:61.5667535 },
        { lng:27.0194586	, lat:61.5680083 },
        { lng:27.0242608	, lat:61.5610544 },
        { lng:27.0243476	, lat:61.5467178 },
        { lng:27.0242123	, lat:61.5454421 },
        { lng:27.0248163	, lat:61.5444744 },
        { lng:27.0232212	, lat:61.5442101 },
        { lng:27.0226189	, lat:61.5439956 },
        { lng:27.0184171	, lat:61.5432756 },
        { lng:27.0186959	, lat:61.5423985 },
        { lng:27.0195266	, lat:61.5418501 },
        { lng:27.0202912	, lat:61.5409397 },
        { lng:27.0074183	, lat:61.5400555 },
        { lng:27.0074674	, lat:61.537774 },
        { lng:27.0012169	, lat:61.5389986 },
        { lng:26.9995669	, lat:61.5379617 },
        { lng:26.9972958	, lat:61.5372809 },
        { lng:26.9955218	, lat:61.5352144 },
        { lng:26.9928047	, lat:61.5316473 },
        { lng:26.9896832	, lat:61.5212926 },
        { lng:26.973808	, lat:61.5209405 },
        { lng:26.9630239	, lat:61.5033548 },
        { lng:26.9737299	, lat:61.4832071 },
        { lng:26.9892308	, lat:61.464222 },
        { lng:27.0066598	, lat:61.4632888 },
        { lng:27.0172389	, lat:61.4799246 },
        { lng:27.0476482	, lat:61.4732222 },
        { lng:27.0530878	, lat:61.465589 },
        { lng:27.0539813	, lat:61.4635727 },
        { lng:27.0643041	, lat:61.4645408 },
        { lng:27.0677873	, lat:61.4641173 },
        { lng:27.0668373	, lat:61.4629374 },
        { lng:27.0443084	, lat:61.4447644 },
        { lng:27.0768485	, lat:61.4059686 },
        { lng:27.0770198	, lat:61.4053344 },
        { lng:27.0768093	, lat:61.404883 },
        { lng:27.074352	, lat:61.4032339 },
        { lng:27.0780918	, lat:61.4015804 },
        { lng:27.0914439	, lat:61.4003616 },
        { lng:27.0982097	, lat:61.3984726 },
        { lng:27.1061209	, lat:61.3997594 },
        { lng:27.1128867	, lat:61.3994468 },
        { lng:27.1291419	, lat:61.4031278 },
        { lng:27.1551964	, lat:61.4025255 },
        { lng:27.159263	, lat:61.4027831 },
        { lng:27.1608079	, lat:61.4024763 },
        { lng:27.1618379	, lat:61.4022585 },
        { lng:27.162569	, lat:61.4019917 },
        { lng:27.1628754	, lat:61.4020015 },
        { lng:27.1631822	, lat:61.4017493 },
        { lng:27.1636355	, lat:61.4017902 },
        { lng:27.164578	, lat:61.4016451 },
        { lng:27.1649284	, lat:61.4016489 },
        { lng:27.1558217	, lat:61.3976183 },
        { lng:27.153941	, lat:61.3964787 },
        { lng:27.1510101	, lat:61.3942651 },
        { lng:27.1501513	, lat:61.3923063 },
        { lng:27.1512808	, lat:61.3906901 },
        { lng:27.1488272	, lat:61.3893845 },
        { lng:27.1456097	, lat:61.3861819 },
        { lng:27.150218	, lat:61.3835074 },
        { lng:27.147874	, lat:61.3814726 },
        { lng:27.1490679	, lat:61.3792519 },
        { lng:27.1399238	, lat:61.3797153 },
        { lng:27.1358263	, lat:61.3777131 },
        { lng:27.135075	, lat:61.3694121 },
        { lng:27.1222571	, lat:61.37189 },
        { lng:27.1138819	, lat:61.3711596 },
        { lng:27.1066839	, lat:61.3688055 },
        { lng:27.096909	, lat:61.3691362 },
        { lng:27.0892113	, lat:61.3699403 },
        { lng:27.0909675	, lat:61.372937 },
        { lng:27.0863268	, lat:61.3741769 },
        { lng:27.0728086	, lat:61.3799051 },
        { lng:27.0711727	, lat:61.3817751 },
        { lng:27.0549967	, lat:61.3814709 },
        { lng:27.0510127	, lat:61.3819639 },
        { lng:27.0487671	, lat:61.3805537 },
        { lng:27.0311241	, lat:61.3824168 },
        { lng:27.0279949	, lat:61.3836553 },
        { lng:27.0192854	, lat:61.3849832 },
        { lng:27.0185124	, lat:61.3833538 },
        { lng:27.0151593	, lat:61.3799844 },
        { lng:27.015116	, lat:61.3787934 },
        { lng:27.0154006	, lat:61.376238 },
        { lng:27.000153	, lat:61.3676309 },
        { lng:26.999882	, lat:61.3672233 },
        { lng:26.9920216	, lat:61.3655656 },
        { lng:26.9886564	, lat:61.3645964 },
        { lng:26.9683631	, lat:61.358751 },
        { lng:26.9610045	, lat:61.3568379 },
        { lng:26.9601753	, lat:61.3546761 },
        { lng:26.958636	, lat:61.3504348 },
        { lng:26.9563555	, lat:61.3498955 },
        { lng:26.953902	, lat:61.3476048 },
        { lng:26.9839385	, lat:61.3447901 },
        { lng:27.0127359	, lat:61.3490604 },
        { lng:27.0190667	, lat:61.3500162 },
        { lng:27.0278698	, lat:61.3513453 },
        { lng:27.0317482	, lat:61.3510647 },
        { lng:27.056904	, lat:61.3420057 },
        { lng:27.0714997	, lat:61.3368753 },
        { lng:27.0747687	, lat:61.3337508 },
        { lng:27.1034016	, lat:61.3295505 },
        { lng:27.1069258	, lat:61.3283792 },
        { lng:27.1095712	, lat:61.3269358 },
        { lng:27.1106808	, lat:61.3259599 },
        { lng:27.1172938	, lat:61.3242664 },
        { lng:27.1188187	, lat:61.3230966 },
        { lng:27.1270048	, lat:61.3204576 },
        { lng:27.1307165	, lat:61.3205296 },
        { lng:27.1398421	, lat:61.3170643 },
        { lng:27.1433122	, lat:61.3184626 },
        { lng:27.1430545	, lat:61.3225135 },
        { lng:27.1438451	, lat:61.3277384 },
        { lng:27.1717694	, lat:61.3243734 },
        { lng:27.1912262	, lat:61.3298677 },
        { lng:27.198142	, lat:61.3298332 },
        { lng:27.2097903	, lat:61.3312157 },
        { lng:27.2274016	, lat:61.330319 },
        { lng:27.237755	, lat:61.3275503 },
        { lng:27.2387399	, lat:61.32775 },
        { lng:27.2396551	, lat:61.3277042 },
        { lng:27.2402538	, lat:61.3277006 },
        { lng:27.2424338	, lat:61.3270915 },
        { lng:27.2467029	, lat:61.3267421 },
        { lng:27.2483058	, lat:61.325886 },
        { lng:27.2514386	, lat:61.3262103 },
        { lng:27.2614754	, lat:61.3261682 },
        { lng:27.2578802	, lat:61.3147749 },
        { lng:27.2439803	, lat:61.3153957 },
        { lng:27.2363186	, lat:61.3161019 },
        { lng:27.22749	, lat:61.3175875 },
        { lng:27.217583	, lat:61.3170211 },
        { lng:27.2277442	, lat:61.3074428 },
        { lng:27.2653447	, lat:61.3019804 },
        { lng:27.270566	, lat:61.3003568 },
        { lng:27.289976	, lat:61.3002213 },
        { lng:27.3024553	, lat:61.2801386 },
        { lng:27.3058655	, lat:61.2723881 },
        { lng:27.3250901	, lat:61.260125 },
        { lng:27.3373042	, lat:61.254059 },
        { lng:27.3395823	, lat:61.2504131 },
        { lng:27.347487	, lat:61.2488986 },
        { lng:27.348252	, lat:61.2465573 },
        { lng:27.3478051	, lat:61.2461063 },
        { lng:27.3483339	, lat:61.2451781 },
        { lng:27.3478303	, lat:61.2439842 },
        { lng:27.3468491	, lat:61.2436003 },
        { lng:27.3456674	, lat:61.2435923 },
        { lng:27.3440953	, lat:61.2438658 },
        { lng:27.3439649	, lat:61.2443943 },
        { lng:27.3432327	, lat:61.2444455 },
        { lng:27.3426141	, lat:61.2439925 },
        { lng:27.3398318	, lat:61.2428595 },
        { lng:27.3356578	, lat:61.2424478 },
        { lng:27.3327414	, lat:61.2419055 },
        { lng:27.331828	, lat:61.2411963 },
        { lng:27.3312235	, lat:61.2411364 },
        { lng:27.322584	, lat:61.2379995 },
        { lng:27.321639	, lat:61.2336487 },
        { lng:27.3211402	, lat:61.2334599 },
        { lng:27.317174	, lat:61.2292602 },
        { lng:27.3293143	, lat:61.2187293 },
        { lng:27.3436718	, lat:61.2142535 },
        { lng:27.3547569	, lat:61.2108069 },
        { lng:27.3562864	, lat:61.2096296 },
        { lng:27.3578471	, lat:61.2101854 },
        { lng:27.3587691	, lat:61.2099617 },
        { lng:27.3610827	, lat:61.2094667 },
        { lng:27.3616346	, lat:61.2094343 },
        { lng:27.3626875	, lat:61.2096349 },
        { lng:27.3640018	, lat:61.2096197 },
        { lng:27.3664923	, lat:61.2089244 },
        { lng:27.367099	, lat:61.2090287 },
        { lng:27.3680524	, lat:61.2095721 },
        { lng:27.3685445	, lat:61.2097046 },
        { lng:27.3701777	, lat:61.2095747 },
        { lng:27.3713357	, lat:61.2102463 },
        { lng:27.3724267	, lat:61.2105689 },
        { lng:27.3731815	, lat:61.2108915 },
        { lng:27.3748606	, lat:61.211018 },
        { lng:27.3756763	, lat:61.2112203 },
        { lng:27.3766415	, lat:61.2116039 },
        { lng:27.3775507	, lat:61.2124547 },
        { lng:27.3783167	, lat:61.2132316 },
        { lng:27.3785348	, lat:61.2142477 },
        { lng:27.3795876	, lat:61.219745 },
        { lng:27.3759924	, lat:61.2231724 },
        { lng:27.3762473	, lat:61.2234513 },
        { lng:27.3767575	, lat:61.2240098 },
        { lng:27.3768411	, lat:61.2245184 },
        { lng:27.3765511	, lat:61.2248292 },
        { lng:27.3764154	, lat:61.2251358 },
        { lng:27.3763773	, lat:61.2256067 },
        { lng:27.3761385	, lat:61.2258206 },
        { lng:27.3755118	, lat:61.2259925 },
        { lng:27.3742085	, lat:61.2260695 },
        { lng:27.3735456	, lat:61.2262806 },
        { lng:27.3729968	, lat:61.2266703 },
        { lng:27.3729	, lat:61.2269602 },
        { lng:27.3730389	, lat:61.2275076 },
        { lng:27.3729989	, lat:61.2276324 },
        { lng:27.372339	, lat:61.2280508 },
        { lng:27.3721217	, lat:61.2284091 },
        { lng:27.3721786	, lat:61.2289929 },
        { lng:27.3709774	, lat:61.2297916 },
        { lng:27.369625	, lat:61.2304756 },
        { lng:27.3681736	, lat:61.2312835 },
        { lng:27.3675044	, lat:61.2319663 },
        { lng:27.3667414	, lat:61.232639 },
        { lng:27.3662279	, lat:61.2339748 },
        { lng:27.3665067	, lat:61.2343812 },
        { lng:27.3671346	, lat:61.2346373 },
        { lng:27.3673021	, lat:61.2348718 },
        { lng:27.3673205	, lat:61.2357428 },
        { lng:27.3676697	, lat:61.2362867 },
        { lng:27.3668778	, lat:61.2369498 },
        { lng:27.3664619	, lat:61.2370495 },
        { lng:27.3654769	, lat:61.2370876 },
        { lng:27.3652244	, lat:61.2371676 },
        { lng:27.3650115	, lat:61.2373239 },
        { lng:27.3649101	, lat:61.2377624 },
        { lng:27.3643405	, lat:61.2386649 },
        { lng:27.3643628	, lat:61.2393482 },
        { lng:27.3668587	, lat:61.239522 },
        { lng:27.3679971	, lat:61.2406405 },
        { lng:27.3671016	, lat:61.241559 },
        { lng:27.3681703	, lat:61.2434504 },
        { lng:27.3697433	, lat:61.2438322 },
        { lng:27.3746086	, lat:61.2481515 },
        { lng:27.3893514	, lat:61.2449331 },
        { lng:27.4054607	, lat:61.2465968 },
        { lng:27.4058154	, lat:61.2466333 },
        { lng:27.4081258	, lat:61.247151 },
        { lng:27.4112208	, lat:61.2468241 },
        { lng:27.4160777	, lat:61.2467291 },
        { lng:27.4192159	, lat:61.2462323 },
        { lng:27.4209719	, lat:61.2462567 },
        { lng:27.4332915	, lat:61.2476933 },
        { lng:27.4372323	, lat:61.2502895 },
        { lng:27.4364893	, lat:61.2533189 },
        { lng:27.438867	, lat:61.255893 },
        { lng:27.4356917	, lat:61.2598568 },
        { lng:27.4303054	, lat:61.2716375 },
        { lng:27.4627788	, lat:61.2680572 },
        { lng:27.5075222	, lat:61.269802 },
        { lng:27.5434152	, lat:61.2569428 },
        { lng:27.570612	, lat:61.2626822 },
        { lng:27.5766557	, lat:61.2631181 },
        { lng:27.5967511	, lat:61.2809108 },
        { lng:27.5893475	, lat:61.2838147 },
        { lng:27.5700076	, lat:61.2811286 },
        { lng:27.5580713	, lat:61.2861377 },
        { lng:27.542962	, lat:61.2894041 },
        { lng:27.5573425	, lat:61.303564 },
        { lng:27.5848067	, lat:61.2931796 },
        { lng:27.5915716	, lat:61.2973253 },
        { lng:27.584305	, lat:61.2998202 },
        { lng:27.5884163	, lat:61.3055981 },
        { lng:27.5885273	, lat:61.3059679 },
        { lng:27.5889323	, lat:61.3063159 },
        { lng:27.592096	, lat:61.310831 },
        { lng:27.6020322	, lat:61.307668 },
        { lng:27.6098924	, lat:61.308024 },
        { lng:27.6098177	, lat:61.30917 },
        { lng:27.613724	, lat:61.3174661 },
        { lng:27.6134679	, lat:61.3175266 },
        { lng:27.6034382	, lat:61.3198487 },
        { lng:27.5887275	, lat:61.3196531 },
        { lng:27.5886269	, lat:61.3198351 },
        { lng:27.5891458	, lat:61.3199692 },
        { lng:27.5891515	, lat:61.3200985 },
        { lng:27.5888059	, lat:61.3201322 },
        { lng:27.5964903	, lat:61.3267804 },
        { lng:27.5958445	, lat:61.343142 },
        { lng:27.6466118	, lat:61.3437941 },
        { lng:27.6960193	, lat:61.3297343 },
        { lng:27.7369676	, lat:61.3295085 },
        { lng:27.7544515	, lat:61.3290499 },
        { lng:27.792608	, lat:61.3620782 },
        { lng:27.7596179	, lat:61.3677981 },
        { lng:27.7493961	, lat:61.3695716 },
        { lng:27.7344165	, lat:61.3733123 },
        { lng:27.7285371	, lat:61.3737932 },
        { lng:27.7062104	, lat:61.3816889 },
        { lng:27.6976166	, lat:61.387037 },
        { lng:27.7005885	, lat:61.3908329 },
        { lng:27.6984857	, lat:61.391865 },
        { lng:27.6865123	, lat:61.3915033 },
        { lng:27.6763316	, lat:61.3951318 },
        { lng:27.6771997	, lat:61.401567 },
        { lng:27.6743887	, lat:61.4030863 },
        { lng:27.6787983	, lat:61.4060816 },
        { lng:27.6887332	, lat:61.4060775 },
        { lng:27.6882933	, lat:61.4074074 },
        { lng:27.6895593	, lat:61.408748 },
        { lng:27.6821778	, lat:61.4113264 },
        { lng:27.6805792	, lat:61.4131358 },
        { lng:27.6838837	, lat:61.4138155 },
        { lng:27.6868878	, lat:61.4166711 },
        { lng:27.6821564	, lat:61.418704 },
        { lng:27.6793454	, lat:61.4233426 },
        { lng:27.6932526	, lat:61.4297066 },
        { lng:27.706704	, lat:61.429612 },
        { lng:27.7122079	, lat:61.4309565 },
        { lng:27.7223037	, lat:61.4314083 },
        { lng:27.7256725	, lat:61.4331372 },
        { lng:27.7597113	, lat:61.4351598 },
        { lng:27.7578912	, lat:61.4424664 },
        { lng:27.7554338	, lat:61.4458718 },
        { lng:27.7566252	, lat:61.4480712 },
        { lng:27.7549054	, lat:61.4497718 },
        { lng:27.7553163	, lat:61.4509509 },
        { lng:27.7555094	, lat:61.4512093 },
        { lng:27.7564321	, lat:61.4515367 },
        { lng:27.7533422	, lat:61.4531126 },
        { lng:27.7540396	, lat:61.4562914 },
        { lng:27.7495871	, lat:61.4609109 },
        { lng:27.7437828	, lat:61.462814 },
        { lng:27.7434277	, lat:61.463946 },
        { lng:27.740017	, lat:61.4658602 },
        { lng:27.7409181	, lat:61.4681677 },
        { lng:27.7445446	, lat:61.4677013 },
        { lng:27.7476736	, lat:61.468788 },
        { lng:27.7487181	, lat:61.4688825 },
        { lng:27.7495978	, lat:61.4688561 },
        { lng:27.7500104	, lat:61.4690603 },
        { lng:27.7507523	, lat:61.4694685 },
        { lng:27.7506047	, lat:61.4696466 },
        { lng:27.7502845	, lat:61.4698044 },
        { lng:27.7487074	, lat:61.4710573 },
        { lng:27.7436326	, lat:61.4745884 },
        { lng:27.7462719	, lat:61.4749111 },
        { lng:27.747849	, lat:61.474843 },
        { lng:27.7531276	, lat:61.4740755 },
        { lng:27.7566145	, lat:61.4728988 },
        { lng:27.7590929	, lat:61.4726742 },
        { lng:27.7655624	, lat:61.4739794 },
        { lng:27.7590069	, lat:61.478659 },
        { lng:27.7598653	, lat:61.4810254 },
        { lng:27.7586852	, lat:61.4824039 },
        { lng:27.7589856	, lat:61.4838168 },
        { lng:27.7522908	, lat:61.487804 },
        { lng:27.7548657	, lat:61.4908368 },
        { lng:27.7494369	, lat:61.4911322 },
        { lng:27.741379	, lat:61.4946915 },
        { lng:27.7356739	, lat:61.4964436 },
        { lng:27.7453278	, lat:61.4974765 },
        { lng:27.7514217	, lat:61.4956495 },
        { lng:27.7651331	, lat:61.4987672 },
        { lng:27.7656804	, lat:61.4997839 },
        { lng:27.7652619	, lat:61.5017039 },
        { lng:27.7649079	, lat:61.5034308 },
        { lng:27.7631484	, lat:61.5049989 },
        { lng:27.7634541	, lat:61.5050785 },
        { lng:27.7633308	, lat:61.5080852 },
        { lng:27.7611099	, lat:61.5094548 },
        { lng:27.7664849	, lat:61.5101129 },
        { lng:27.771313	, lat:61.5095712 },
        { lng:27.7757547	, lat:61.5098626 },
        { lng:27.7773533	, lat:61.5097155 },
        { lng:27.7852605	, lat:61.5033097 },
        { lng:27.7921377	, lat:61.5028784 },
        { lng:27.7968262	, lat:61.5006127 },
        { lng:27.8015898	, lat:61.4992612 },
        { lng:27.8056238	, lat:61.5007126 },
        { lng:27.810537	, lat:61.4997071 },
        { lng:27.8113847	, lat:61.5005503 },
        { lng:27.8113418	, lat:61.5010527 },
        { lng:27.8118675	, lat:61.5015268 },
        { lng:27.8118567	, lat:61.5016824 },
        { lng:27.8112318	, lat:61.5022847 },
        { lng:27.811154	, lat:61.5026041 },
        { lng:27.8109126	, lat:61.5027167 },
        { lng:27.8108732	, lat:61.5030843 },
        { lng:27.8110891	, lat:61.5036342 },
        { lng:27.8112559	, lat:61.5037233 },
        { lng:27.8115134	, lat:61.5045976 },
        { lng:27.8076724	, lat:61.5097227 },
        { lng:27.8097217	, lat:61.5124171 },
        { lng:27.81574	, lat:61.5114117 },
        { lng:27.8195434	, lat:61.5134402 },
        { lng:27.8187441	, lat:61.5143557 },
        { lng:27.81942	, lat:61.5158135 },
        { lng:27.8206378	, lat:61.5167176 },
        { lng:27.8228104	, lat:61.5160742 },
        { lng:27.8272398	, lat:61.515893 },
        { lng:27.827509	, lat:61.5159857 },
        { lng:27.8276861	, lat:61.5161253 },
        { lng:27.8279784	, lat:61.5162295 },
        { lng:27.8334979	, lat:61.5200775 },
        { lng:27.8372401	, lat:61.5226024 },
        { lng:27.8376746	, lat:61.522972 },
        { lng:27.8384932	, lat:61.5237795 },
        { lng:27.8386853	, lat:61.5242164 },
        { lng:27.8408782	, lat:61.5251936 },
        { lng:27.8415842	, lat:61.5254138 },
        { lng:27.8427746	, lat:61.5259064 },
        { lng:27.8426501	, lat:61.5260034 },
        { lng:27.8429076	, lat:61.5265678 },
        { lng:27.8428411	, lat:61.5273425 },
        { lng:27.8433716	, lat:61.5278381 },
        { lng:27.8437284	, lat:61.5280396 },
        { lng:27.8437723	, lat:61.5282414 },
        { lng:27.8434408	, lat:61.528256 },
        { lng:27.8427081	, lat:61.5286897 },
        { lng:27.8427156	, lat:61.5288889 },
        { lng:27.8416829	, lat:61.5289759 },
        { lng:27.8412999	, lat:61.5291556 },
        { lng:27.8423411	, lat:61.5302478 },
        { lng:27.8447025	, lat:61.5325011 },
        { lng:27.8423358	, lat:61.5341693 },
        { lng:27.8436538	, lat:61.5362014 },
        { lng:27.8473943	, lat:61.5387992 },
        { lng:27.8585562	, lat:61.5403302 },
        { lng:27.8592342	, lat:61.5448053 },
        { lng:27.8694914	, lat:61.5463085 },
        { lng:27.8778718	, lat:61.5459532 },
        { lng:27.8850263	, lat:61.5441957 },
        { lng:27.9003803	, lat:61.5455897 },
        { lng:27.8886872	, lat:61.5650943 },
        { lng:27.8821407	, lat:61.571751 },
        { lng:27.8816003	, lat:61.5718198 },
        { lng:27.8774341	, lat:61.573656 },
        { lng:27.875235	, lat:61.5739055 },
        { lng:27.874117	, lat:61.5753494 },
        { lng:27.8715271	, lat:61.5767595 },
        { lng:27.8703869	, lat:61.5784705 },
        { lng:27.8714473	, lat:61.5814205 },
        { lng:27.8657783	, lat:61.5850444 },
        { lng:27.8699755	, lat:61.5901478 },
        { lng:27.8743515	, lat:61.597094 },
        { lng:27.8708891	, lat:61.6055453 },
        { lng:27.8769499	, lat:61.6074267 },
        { lng:27.8751154	, lat:61.6152996 },
        { lng:27.8704764	, lat:61.6204563 },
        { lng:27.8522668	, lat:61.6283766 },
        { lng:27.8557037	, lat:61.6311643 },
        { lng:27.8763261	, lat:61.6329292 },
        { lng:27.879515	, lat:61.6382298 },
        { lng:27.8774597	, lat:61.6451677 },
        { lng:27.8771036	, lat:61.6530926 },
        { lng:27.8443933	, lat:61.66548 },
        { lng:27.8341099	, lat:61.6697242 },
        { lng:27.8297345	, lat:61.6720161 },
        { lng:27.8265877	, lat:61.6716651 },
        { lng:27.8242587	, lat:61.6722137 },
        { lng:27.8245818	, lat:61.6777029 },
        { lng:27.8109364	, lat:61.6870915 },
        { lng:27.7708732	, lat:61.6818599 },
        { lng:27.7302017	, lat:61.6900339 },
        { lng:27.7043714	, lat:61.7026465 },
        { lng:27.6467096	, lat:61.7004358 },
        { lng:27.6239205	, lat:61.7196153 },
        { lng:27.6012937	, lat:61.7232654 },
        { lng:27.573609	, lat:61.7506587 },
        { lng:27.572426	, lat:61.7511025 },
        { lng:27.5702944	, lat:61.7527337 },
        { lng:27.5513786	, lat:61.7547006 },
        { lng:27.5512365	, lat:61.7548068 },
        { lng:27.5509172	, lat:61.7550393 },
        { lng:27.5458343	, lat:61.7587373 },
        { lng:27.5407063	, lat:61.7627031 },
        { lng:27.5447458	, lat:61.7638136 },
        { lng:27.5460041	, lat:61.7681207 },
        { lng:27.5523514	, lat:61.7729646 },
        { lng:27.5525506	, lat:61.7763099 },
        { lng:27.54547	, lat:61.7827662 },
        { lng:27.5399772	, lat:61.7902353 },
        { lng:27.5251601	, lat:61.7920248 },
        { lng:27.5102963	, lat:61.8009423 },
        { lng:27.5120251	, lat:61.8023389 },
        { lng:27.4930529	, lat:61.8146057 },
        { lng:27.4901752	, lat:61.8151437 },
        { lng:27.4899838	, lat:61.8151837 },
        { lng:27.4878411	, lat:61.815632 },
        { lng:27.486007	, lat:61.8191865 },
        { lng:27.4852635	, lat:61.8193425 },
        { lng:27.4793655	, lat:61.8344831 },
        { lng:27.4688443	, lat:61.8361937 },
        { lng:27.4698355	, lat:61.8376792 },
        { lng:27.4572144	, lat:61.8437824 },
        { lng:27.4552309	, lat:61.8451518 },
        { lng:27.4544316	, lat:61.8457037 },
        { lng:27.452008	, lat:61.8488733 },
        { lng:27.4512722	, lat:61.8498356 },
        { lng:27.4514892	, lat:61.8505161 },
        { lng:27.4515051	, lat:61.8505661 },
        { lng:27.4514859	, lat:61.8507418 },
        { lng:27.4406261	, lat:61.8545129 },
        { lng:27.4354196	, lat:61.861801 },
        { lng:27.4337599	, lat:61.8641238 },
        { lng:27.4333998	, lat:61.8646278 },
        { lng:27.4304114	, lat:61.8649732 },
        { lng:27.4205321	, lat:61.8883122 },
        { lng:27.4202414	, lat:61.8889986 },
        { lng:27.4199926	, lat:61.8895861 },
        { lng:27.4273349	, lat:61.9027219 },
        { lng:27.427358	, lat:61.9027631 },
        { lng:27.4296806	, lat:61.9069173 },
        { lng:27.4352108	, lat:61.9104265 },
        { lng:27.4405136	, lat:61.9137911 },
        { lng:27.4303564	, lat:61.9213837 },
        { lng:27.4703988	, lat:61.9376835 },
        { lng:27.4677624	, lat:61.9389239 },
        { lng:27.420886	, lat:61.9609686 },
        { lng:27.4196647	, lat:61.9606952 },
        { lng:27.4160384	, lat:61.9626822 },
        { lng:27.4130129	, lat:61.9660003 },
        { lng:27.4112131	, lat:61.9676504 },
        { lng:27.4083351	, lat:61.9731699 },
        { lng:27.4048709	, lat:61.9747869 },
        { lng:27.4040527	, lat:61.9750801 },
        { lng:27.4038067	, lat:61.9753774 },
        { lng:27.4038491	, lat:61.9762942 },
        { lng:27.4028004	, lat:61.9771739 },
        { lng:27.4015319	, lat:61.9779565 },
        { lng:27.4011821	, lat:61.9782376 },
        { lng:27.4013355	, lat:61.9785187 },
        { lng:27.4006073	, lat:61.9787288 },
        { lng:27.4000954	, lat:61.9787249 },
        { lng:27.399698	, lat:61.9790189 },
        { lng:27.399516	, lat:61.9791534 },
        { lng:27.3987595	, lat:61.9788483 },
        { lng:27.3969089	, lat:61.9788207 },
        { lng:27.3962588	, lat:61.9796637 },
        { lng:27.3958519	, lat:61.9801914 },
        { lng:27.3951815	, lat:61.9806957 },
        { lng:27.394903	, lat:61.9811639 },
        { lng:27.3942589	, lat:61.9813358 },
        { lng:27.3930378	, lat:61.9818216 },
        { lng:27.392626	, lat:62.006444 },
        { lng:27.3807555	, lat:62.0074285 },
        { lng:27.3697776	, lat:62.0152192 },
        { lng:27.3337644	, lat:62.0484331 },
        { lng:27.3472415	, lat:62.0527417 },
        { lng:27.2977959	, lat:62.0853924 },
        { lng:27.2477702	, lat:62.1158166 },
        { lng:27.187302	, lat:62.1525272 },
        { lng:27.1619545	, lat:62.1660321 },
        { lng:27.1615082	, lat:62.1735531 },
        { lng:27.168336	, lat:62.1790104 },
        { lng:27.1525384	, lat:62.1920455 },
        { lng:27.1354021	, lat:62.199123 },
        { lng:27.1262984	, lat:62.1974578 },
        { lng:27.1089389	, lat:62.193482 },
        { lng:27.0934983	, lat:62.1822176 },
        { lng:27.0723902	, lat:62.1752404 },
        { lng:27.0417768	, lat:62.186195 },
        { lng:27.0557447	, lat:62.1599265 },
        { lng:27.0334764	, lat:62.1614478 },
        { lng:27.007285	, lat:62.1816868 },
        { lng:27.0052189	, lat:62.1839619 },
        { lng:26.9990329	, lat:62.1858847 },
        { lng:26.9879133	, lat:62.1821135 },
        { lng:26.9761767	, lat:62.1672406 },
        { lng:26.9693489	, lat:62.1512139 },
        { lng:26.9600221	, lat:62.1570712 },
        { lng:26.9464111	, lat:62.1570712 },
        { lng:26.9487317	, lat:62.1436665 },
        { lng:26.9361918	, lat:62.1393916 },
        { lng:26.9189662	, lat:62.1468566 },
        { lng:26.9269542	, lat:62.1227449 },
        { lng:26.9144143	, lat:62.1178827 },
        { lng:26.8856752	, lat:62.1403926 },
        { lng:26.8534136	, lat:62.1286681 },
        { lng:26.8309782	, lat:62.1125312 },
        { lng:26.8333751	, lat:62.0754929 },
        { lng:26.8735632	, lat:62.0444777 },
        { lng:26.903085	, lat:62.0547011 },
        { lng:26.9751519	, lat:62.0365176 },
        { lng:26.9922002	, lat:62.0279868 },
        { lng:26.9978728	, lat:62.0177545 },
        { lng:27.0321885	, lat:62.0075937 },
        { lng:27.0572761	, lat:62.0086625 },
        { lng:27.079807	, lat:61.9972983 },
        { lng:27.0765312	, lat:61.9913519 },
        { lng:27.0886356	, lat:61.9737312 },
        { lng:27.121522	, lat:61.9665448 },
        { lng:27.129162	, lat:61.9647698 },
        { lng:27.1279695	, lat:61.964298 },
        { lng:27.1274358	, lat:61.9645782 },
        { lng:27.1266257	, lat:61.9648724 },
        { lng:27.1262481	, lat:61.9649912 },
        { lng:27.1259359	, lat:61.9648976 },
        { lng:27.1265485	, lat:61.9641825 },
        { lng:27.1270903	, lat:61.9639208 },
        { lng:27.1024301	, lat:61.9534869 },
        { lng:27.0927012	, lat:61.9540736 },
        { lng:27.082365	, lat:61.958472 },
        { lng:27.0755747	, lat:61.9578123 },
        { lng:27.0757394	, lat:61.9572751 },
        { lng:27.0755431	, lat:61.9571034 },
        { lng:27.0752217	, lat:61.9573647 },
        { lng:27.0750833	, lat:61.9577563 },
        { lng:27.0657117	, lat:61.9567168 },
        { lng:27.0579853	, lat:61.9536821 },
        { lng:27.0599492	, lat:61.9440198 },
        { lng:27.046661	, lat:61.9383994 },
        { lng:27.0411952	, lat:61.9372711 },
        { lng:27.0328702	, lat:61.9383797 },
        { lng:27.0210685	, lat:61.935954 },
        { lng:27.0128716	, lat:61.9387451 },
        { lng:27.0092346	, lat:61.9378006 },
        { lng:27.0023461	, lat:61.9341121 },
        { lng:26.9984414	, lat:61.9317249 },
        { lng:26.9986988	, lat:61.9284775 },
        { lng:26.9982053	, lat:61.9255635 },
        { lng:26.9967784	, lat:61.9246375 },
        { lng:26.995963	, lat:61.9244338 },
        { lng:26.9944824	, lat:61.9243797 },
        { lng:26.9933666	, lat:61.9245666 },
        { lng:26.9886674	, lat:61.9230618 },
        { lng:26.9909955	, lat:61.9216474 },
        { lng:26.9928194	, lat:61.9180691 },
        { lng:26.9915856	, lat:61.9173714 },
        { lng:26.9910492	, lat:61.9168863 },
        { lng:26.9910706	, lat:61.9161911 },
        { lng:26.9914097	, lat:61.9150688 },
        { lng:26.9826587	, lat:61.9023838 },
        { lng:26.963519	, lat:61.9088803 },
        { lng:26.9591797	, lat:61.9142998 },
        { lng:26.9511272	, lat:61.9196809 },
        { lng:26.9422131	, lat:61.9222536 },
        { lng:26.9339181	, lat:61.9200423 },
        { lng:26.9338108	, lat:61.9192128 },
        { lng:26.9334568	, lat:61.9185055 },
        { lng:26.9334461	, lat:61.91502 },
        { lng:26.9331344	, lat:61.9145079 },
        { lng:26.9325127	, lat:61.914075 },
        { lng:26.9297124	, lat:61.9130892 },
        { lng:26.9264509	, lat:61.9145147 },
        { lng:26.9232644	, lat:61.9146392 },
        { lng:26.9189514	, lat:61.9150114 },
        { lng:26.918887	, lat:61.9148809 },
        { lng:26.9192599	, lat:61.9148634 },
        { lng:26.9192169	, lat:61.9146857 },
        { lng:26.9186725	, lat:61.9144308 },
        { lng:26.9200511	, lat:61.9133804 },
        { lng:26.9220317	, lat:61.9116448 },
        { lng:26.9205178	, lat:61.9100066 },
        { lng:26.9185115	, lat:61.9094567 },
        { lng:26.9133724	, lat:61.9085903 },
        { lng:26.9074072	, lat:61.9082323 },
        { lng:26.9036199	, lat:61.9086282 },
        { lng:26.8987383	, lat:61.9077453 },
        { lng:26.8938888	, lat:61.9080894 },
        { lng:26.8924297	, lat:61.907738 },
        { lng:26.8918933	, lat:61.9071338 },
        { lng:26.8933953	, lat:61.9043077 },
        { lng:26.895069	, lat:61.9035677 },
        { lng:26.894951	, lat:61.9018873 },
        { lng:26.8940734	, lat:61.9020104 },
        { lng:26.8928777	, lat:61.9018118 },
        { lng:26.8920328	, lat:61.9011801 },
        { lng:26.8802525	, lat:61.8981968 },
        { lng:26.8758199	, lat:61.9030412 },
        { lng:26.8663908	, lat:61.9012713 },
        { lng:26.8614448	, lat:61.8931702 },
        { lng:26.8561233	, lat:61.8886577 },
        { lng:26.8468063	, lat:61.8895354 },
        { lng:26.8415965	, lat:61.8900262 },
        { lng:26.8377368	, lat:61.8866722 },
        { lng:26.8511076	, lat:61.8720309 },
        { lng:26.8535055	, lat:61.8653205 },
        { lng:26.8468751	, lat:61.8572 },
        { lng:26.845696	, lat:61.8545563 },
        { lng:26.8410386	, lat:61.8518832 },
        { lng:26.8322152	, lat:61.8503106 },
      ],
    },
    {
      id: 19,
      regionName: 'eCom TP  Multisilta',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:23.7328122, lat:61.4444023 },
        { lng:23.7378003, lat:61.4445002 },
        { lng:23.7387881, lat:61.4445003 },
        { lng:23.748236	, lat:61.4471528 },
        { lng:23.7484632	, lat:61.447312 },
        { lng:23.7508327	, lat:61.4465322 },
        { lng:23.7496631	, lat:61.4454611 },
        { lng:23.750492	, lat:61.4452982 },
        { lng:23.75229	, lat:61.4454014 },
        { lng:23.7524869	, lat:61.4455986 },
        { lng:23.7526042	, lat:61.445595 },
        { lng:23.7526837	, lat:61.4455009 },
        { lng:23.7525777	, lat:61.4453941 },
        { lng:23.7525512	, lat:61.4445781 },
        { lng:23.7532856	, lat:61.4445727 },
        { lng:23.7539366	, lat:61.444484 },
        { lng:23.7548489	, lat:61.4439738 },
        { lng:23.7560223	, lat:61.4443393 },
        { lng:23.7561737	, lat:61.445101 },
        { lng:23.7566885	, lat:61.445101 },
        { lng:23.7573244	, lat:61.4452078 },
        { lng:23.7576045	, lat:61.4453001 },
        { lng:23.7578998, lat:	61.4454955 },
        { lng:23.7580777, lat:	61.4453996 },
        { lng:23.7578014, lat:	61.4450051 },
        { lng:23.7581723, lat:	61.4449165 },
        { lng:23.7590921	, lat:61.4444895 },
        { lng:23.7594744	, lat:61.444124 },
        { lng:23.7602769	, lat:61.4435015 },
        { lng:23.7610226	, lat:61.4431939 },
        { lng:23.7625063	, lat:61.4429197 },
        { lng:23.7609643	, lat:61.4407534 },
        { lng:23.7589584	, lat:61.4378119 },
        { lng:23.7597009	, lat:61.4378135 },
        { lng:23.7588823	, lat:61.4365336 },
        { lng:23.7563075	, lat:61.4323099 },
        { lng:23.7411181	, lat:61.4321633 },
        { lng:23.738339	, lat:61.4343042 },
        { lng:23.7343726	, lat:61.4384874 },
        { lng:23.7340616	, lat:61.4388155 },
        { lng:23.7328122	, lat:61.4444023 }
      ],
    },
    {
      id: 20,
      regionName: 'eCom TP Oulu kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:24.1149326	, lat:65.1739343 },
        { lng:24.1827314	, lat:65.1714548 },
        { lng:24.2643349	, lat:65.1684272 },
        { lng:24.3577616	, lat:65.1648946 },
        { lng:24.4240658	, lat:65.1623529 },
        { lng:24.4990174	, lat:65.1594505 },
        { lng:24.5432503	, lat:65.1577094 },
        { lng:24.5889895	, lat:65.1559059 },
        { lng:24.6279105	, lat:65.1543597 },
        { lng:24.6959958	, lat:65.1516249 },
        { lng:24.696442	, lat:65.151584 },
        { lng:24.6970471	, lat:65.1515542 },
        { lng:24.6975582	, lat:65.1515621 },
        { lng:24.7814626	, lat:65.1481582 },
        { lng:24.8718445	, lat:65.1444285 },
        { lng:24.9509589	, lat:65.1411227 },
        { lng:24.9736611	, lat:65.1313481 },
        { lng:25.025975	, lat:65.1087782 },
        { lng:25.0616161	, lat:65.093366 },
        { lng:25.1103036	, lat:65.0718952 },
        { lng:25.1308213	, lat:65.0630436 },
        { lng:25.1347908	, lat:65.0613306 },
        { lng:25.1244321	, lat:65.0506284 },
        { lng:25.1242101	, lat:65.0504492 },
        { lng:25.0514513	, lat:64.9824245 },
        { lng:25.1502147	, lat:64.9463106 },
        { lng:25.2216239	, lat:64.9202686 },
        { lng:25.3190263	, lat:64.8844044 },
        { lng:25.3297448	, lat:64.8861825 },
        { lng:25.3274729	, lat:64.8824589 },
        { lng:25.3868057	, lat:64.8755268 },
        { lng:25.3897136	, lat:64.8775411 },
        { lng:25.3960092	, lat:64.8800655 },
        { lng:25.3983161	, lat:64.8820776 },
        { lng:25.3990376	, lat:64.882048 },
        { lng:25.4013999	, lat:64.8825716 },
        { lng:25.4018421	, lat:64.8830754 },
        { lng:25.4008413	, lat:64.8836731 },
        { lng:25.4017309	, lat:64.8843693 },
        { lng:25.4215304	, lat:64.8821763 },
        { lng:25.4293959	, lat:64.8818863 },
        { lng:25.4291497	, lat:64.8809135 },
        { lng:25.4300919	, lat:64.8809031 },
        { lng:25.4301883	, lat:64.8818503 },
        { lng:25.4423509	, lat:64.881377 },
        { lng:25.4451079	, lat:64.8923958 },
        { lng:25.4452697	, lat:64.8936141 },
        { lng:25.4429649	, lat:64.8944772 },
        { lng:25.4417329	, lat:64.8974654 },
        { lng:25.4362092	, lat:64.902897 },
        { lng:25.4374912	, lat:64.9038569 },
        { lng:25.4355775	, lat:64.907735 },
        { lng:25.4330202	, lat:64.9111332 },
        { lng:25.4348276	, lat:64.912705 },
        { lng:25.434676	, lat:64.913969 },
        { lng:25.4324888	, lat:64.9145274 },
        { lng:25.4294147	, lat:64.9149878 },
        { lng:25.4278477	, lat:64.9147183 },
        { lng:25.4294995	, lat:64.9164092 },
        { lng:25.4270404	, lat:64.9197766 },
        { lng:25.4256886	, lat:64.9216957 },
        { lng:25.4281476	, lat:64.9231127 },
        { lng:25.4316238	, lat:64.9251134 },
        { lng:25.4333217	, lat:64.9266379 },
        { lng:25.434253	, lat:64.9277507 },
        { lng:25.4352358	, lat:64.9289496 },
        { lng:25.4394924	, lat:64.9313196 },
        { lng:25.4402276	, lat:64.9320735 },
        { lng:25.4415029	, lat:64.9348438 },
        { lng:25.441318	, lat:64.9352049 },
        { lng:25.4400577	, lat:64.9363794 },
        { lng:25.4397654	, lat:64.9366549 },
        { lng:25.4384902	, lat:64.9377508 },
        { lng:25.435885	, lat:64.9392432 },
        { lng:25.4357522	, lat:64.939416 },
        { lng:25.4357229	, lat:64.9394921 },
        { lng:25.4356491	, lat:64.9396766 },
        { lng:25.4356102	, lat:64.9397748 },
        { lng:25.4355784	, lat:64.9398549 },
        { lng:25.4355525	, lat:64.9399206 },
        { lng:25.4351943	, lat:64.9408247 },
        { lng:25.4357308	, lat:64.9424424 },
        { lng:25.4352587	, lat:64.9439873 },
        { lng:25.43573	, lat:64.9456621 },
        { lng:25.4360935	, lat:64.9460612 },
        { lng:25.4376234	, lat:64.9482709 },
        { lng:25.467494	, lat:64.9450589 },
        { lng:25.4736581	, lat:64.9444036 },
        { lng:25.4930101	, lat:64.9430692 },
        { lng:25.4941355	, lat:64.9430051 },
        { lng:25.4971768	, lat:64.9427935 },
        { lng:25.503347	, lat:64.9423049 },
        { lng:25.5198215	, lat:64.9411457 },
        { lng:25.5195434	, lat:64.9405308 },
        { lng:25.519603	, lat:64.9396209 },
        { lng:25.5215643	, lat:64.9394038 },
        { lng:25.5226787	, lat:64.939473 },
        { lng:25.5236073	, lat:64.9394101 },
        { lng:25.5282133	, lat:64.9394541 },
        { lng:25.5303678	, lat:64.9396304 },
        { lng:25.5300186	, lat:64.9405901 },
        { lng:25.5763823	, lat:64.9408818 },
        { lng:25.5770517	, lat:64.940886 },
        { lng:25.5818514	, lat:64.9409163 },
        { lng:25.5829756	, lat:64.9402711 },
        { lng:25.5856022	, lat:64.9402399 },
        { lng:25.5846146	, lat:64.9409386 },
        { lng:25.5978896	, lat:64.9410145 },
        { lng:25.6141794	, lat:64.9411077 },
        { lng:25.6350029	, lat:64.9355714 },
        { lng:25.6359694	, lat:64.9367019 },
        { lng:25.636936	, lat:64.9360788 },
        { lng:25.6356122	, lat:64.9358117 },
        { lng:25.6364737	, lat:64.9351975 },
        { lng:25.6589067	, lat:64.9293828 },
        { lng:25.6622944	, lat:64.9278686 },
        { lng:25.6624727	, lat:64.9280292 },
        { lng:25.6618635	, lat:64.9282842 },
        { lng:25.6613137	, lat:64.9287847 },
        { lng:25.6713741	, lat:64.9261301 },
        { lng:25.6721021	, lat:64.9268857 },
        { lng:25.6757424	, lat:64.927742 },
        { lng:25.6773916	, lat:64.927975 },
        { lng:25.6829486	, lat:64.9295553 },
        { lng:25.6839738	, lat:64.93089 },
        { lng:25.685727	, lat:64.9313748 },
        { lng:25.6856645	, lat:64.9283836 },
        { lng:25.6858746	, lat:64.9271548 },
        { lng:25.6836053	, lat:64.9266472 },
        { lng:25.6828698	, lat:64.9258725 },
        { lng:25.6812309	, lat:64.9260595 },
        { lng:25.6824286	, lat:64.9270569 },
        { lng:25.684761	, lat:64.9277247 },
        { lng:25.6820714	, lat:64.9288243 },
        { lng:25.6807371	, lat:64.928722 },
        { lng:25.6806005	, lat:64.9278627 },
        { lng:25.6765556	, lat:64.927137 },
        { lng:25.6760618	, lat:64.9248751 },
        { lng:25.7193857	, lat:64.9107093 },
        { lng:25.7267709	, lat:64.9054845 },
        { lng:25.727603	, lat:64.9055286 },
        { lng:25.7286282	, lat:64.90585 },
        { lng:25.7292671	, lat:64.9046149 },
        { lng:25.7283579	, lat:64.9044361 },
        { lng:25.7343378	, lat:64.9002689 },
        { lng:25.7403933	, lat:64.8960485 },
        { lng:25.734825	, lat:64.894212 },
        { lng:25.7363694	, lat:64.8935344 },
        { lng:25.738292	, lat:64.8931421 },
        { lng:25.7381975	, lat:64.8927543 },
        { lng:25.7368422	, lat:64.8923976 },
        { lng:25.7404824	, lat:64.8908028 },
        { lng:25.7323506	, lat:64.8903837 },
        { lng:25.7349141	, lat:64.8887163 },
        { lng:25.7399781	, lat:64.8878959 },
        { lng:25.7421424	, lat:64.8878869 },
        { lng:25.7436974	, lat:64.8884755 },
        { lng:25.7503265	, lat:64.8889299 },
        { lng:25.7499853	, lat:64.8893049 },
        { lng:25.7522883	, lat:64.8897305 },
        { lng:25.7580459	, lat:64.8885829 },
        { lng:25.757843	, lat:64.8876125 },
        { lng:25.7614887	, lat:64.8864978 },
        { lng:25.7614467	, lat:64.8839783 },
        { lng:25.7620666	, lat:64.883519 },
        { lng:25.7623187	, lat:64.8816816 },
        { lng:25.7628202	, lat:64.8814833 },
        { lng:25.7646406	, lat:64.8827252 },
        { lng:25.7654811	, lat:64.8839114 },
        { lng:25.765334	, lat:64.8851422 },
        { lng:25.7679186	, lat:64.8872157 },
        { lng:25.768528	, lat:64.8888386 },
        { lng:25.7708604	, lat:64.8894138 },
        { lng:25.7712491	, lat:64.8910857 },
        { lng:25.7689062	, lat:64.8909252 },
        { lng:25.7683178	, lat:64.8912997 },
        { lng:25.7918365	, lat:64.8942517 },
        { lng:25.7931078	, lat:64.8951566 },
        { lng:25.7952931	, lat:64.8946061 },
        { lng:25.79585	, lat:64.8940467 },
        { lng:25.7964541	, lat:64.8937569 },
        { lng:25.796307	, lat:64.8933 },
        { lng:25.7969111	, lat:64.8931752 },
        { lng:25.7991122	, lat:64.8924396 },
        { lng:25.8009613	, lat:64.8918846 },
        { lng:25.8011767	, lat:64.8919559 },
        { lng:25.8011346	, lat:64.8920941 },
        { lng:25.8009665	, lat:64.8922145 },
        { lng:25.8012998	, lat:64.892392 },
        { lng:25.8015862	, lat:64.8923344 },
        { lng:25.8019998	, lat:64.8923178 },
        { lng:25.8022115	, lat:64.8924307 },
        { lng:25.802243	, lat:64.8926692 },
        { lng:25.8023901	, lat:64.8927405 },
        { lng:25.8023534	, lat:64.8927985 },
        { lng:25.802101	, lat:64.8927433 },
        { lng:25.8019504	, lat:64.8928417 },
        { lng:25.8020325	, lat:64.8929748 },
        { lng:25.8021748	, lat:64.8930125 },
        { lng:25.8026213	, lat:64.8930192 },
        { lng:25.8028366	, lat:64.8931172 },
        { lng:25.8030153	, lat:64.8930526 },
        { lng:25.8032516	, lat:64.8930927 },
        { lng:25.8035038	, lat:64.8932175 },
        { lng:25.8033357	, lat:64.8933 },
        { lng:25.8036177	, lat:64.8932932 },
        { lng:25.8039208	, lat:64.893155 },
        { lng:25.8043603	, lat:64.8931228 },
        { lng:25.8046407	, lat:64.8930521 },
        { lng:25.8048453	, lat:64.8930842 },
        { lng:25.8055501	, lat:64.8932354 },
        { lng:25.8059365	, lat:64.8934508 },
        { lng:25.8062421	, lat:64.8934578 },
        { lng:25.8066257	, lat:64.8934946 },
        { lng:25.8070316	, lat:64.8935557 },
        { lng:25.807227	, lat:64.8935849 },
        { lng:25.8074513	, lat:64.8936274 },
        { lng:25.8075659	, lat:64.8937104 },
        { lng:25.8075734	, lat:64.8938527 },
        { lng:25.807528	, lat:64.8939427 },
        { lng:25.807293	, lat:64.8939813 },
        { lng:25.8071339	, lat:64.894171 },
        { lng:25.8072551	, lat:64.8942578 },
        { lng:25.8075052	, lat:64.8942771 },
        { lng:25.8077098	, lat:64.8944283 },
        { lng:25.8077477	, lat:64.8946823 },
        { lng:25.8080205	, lat:64.8947273 },
        { lng:25.8082706	, lat:64.8946726 },
        { lng:25.8085889	, lat:64.8946694 },
        { lng:25.8089147	, lat:64.8947241 },
        { lng:25.808892	, lat:64.8948205 },
        { lng:25.8090739	, lat:64.8948977 },
        { lng:25.8094831	, lat:64.8948141 },
        { lng:25.8098014	, lat:64.8948302 },
        { lng:25.8099681	, lat:64.8948012 },
        { lng:25.8100136	, lat:64.894634 },
        { lng:25.8101879	, lat:64.8945794 },
        { lng:25.810491	, lat:64.8946405 },
        { lng:25.8106274	, lat:64.8947048 },
        { lng:25.810688	, lat:64.8948109 },
        { lng:25.8108775	, lat:64.8948623 },
        { lng:25.8112185	, lat:64.8948655 },
        { lng:25.811514	, lat:64.8949234 },
        { lng:25.8116277	, lat:64.8949652 },
        { lng:25.8118475	, lat:64.8949716 },
        { lng:25.8121582	, lat:64.8949845 },
        { lng:25.8178342	, lat:64.8965567 },
        { lng:25.8173911	, lat:64.8973828 },
        { lng:25.8170589	, lat:64.8978261 },
        { lng:25.8167588	, lat:64.8982352 },
        { lng:25.8169142	, lat:64.8985512 },
        { lng:25.8170053	, lat:64.8988172 },
        { lng:25.8170696	, lat:64.8990627 },
        { lng:25.8192291	, lat:64.8993787 },
        { lng:25.8219265	, lat:64.8956707 },
        { lng:25.8210762	, lat:64.8949918 },
        { lng:25.8231975	, lat:64.8925142 },
        { lng:25.8263822	, lat:64.8908381 },
        { lng:25.8304782	, lat:64.8927368 },
        { lng:25.8346652	, lat:64.8907514 },
        { lng:25.836141	, lat:64.8891277 },
        { lng:25.8410385	, lat:64.8906586 },
        { lng:25.8410832	, lat:64.8910054 },
        { lng:25.8401556	, lat:64.8919304 },
        { lng:25.8399701	, lat:64.8930456 },
        { lng:25.8437733	, lat:64.8949938 },
        { lng:25.8447782	, lat:64.8950594 },
        { lng:25.8505913	, lat:64.8974797 },
        { lng:25.848535	, lat:64.8980766 },
        { lng:25.8484268	, lat:64.89908 },
        { lng:25.8495554	, lat:64.8999523 },
        { lng:25.8512251	, lat:64.9002999 },
        { lng:25.8517199	, lat:64.9001621 },
        { lng:25.8510396	, lat:64.8990013 },
        { lng:25.8514106	, lat:64.8983389 },
        { lng:25.8518126	, lat:64.8984439 },
        { lng:25.8526011	, lat:64.8982012 },
        { lng:25.8584141	, lat:64.9005491 },
        { lng:25.8577957	, lat:64.9007196 },
        { lng:25.8567908	, lat:64.9007983 },
        { lng:25.8547655	, lat:64.9002212 },
        { lng:25.8539152	, lat:64.9006212 },
        { lng:25.8546882	, lat:64.9017426 },
        { lng:25.8569145	, lat:64.9026541 },
        { lng:25.8600162	, lat:64.9011613 },
        { lng:25.8855133	, lat:64.9113943 },
        { lng:25.8908726	, lat:64.9135447 },
        { lng:25.8945849	, lat:64.9150342 },
        { lng:25.8936448	, lat:64.9154698 },
        { lng:25.894812	, lat:64.915868 },
        { lng:25.8955469	, lat:64.9161187 },
        { lng:25.896334	, lat:64.9156738 },
        { lng:25.994772	, lat:64.955089 },
        { lng:25.9886599	, lat:64.9559785 },
        { lng:25.9884304	, lat:64.9579637 },
        { lng:25.9805702	, lat:64.9617486 },
        { lng:25.9770173	, lat:64.9598192 },
        { lng:25.9733332	, lat:64.9651999 },
        { lng:25.9723931	, lat:64.9659817 },
        { lng:25.9433861	, lat:64.9538705 },
        { lng:25.9397684	, lat:64.955389 },
        { lng:25.9449012	, lat:64.9594136 },
        { lng:25.9679567	, lat:64.9774505 },
        { lng:25.968034	, lat:64.9777578 },
        { lng:25.9639216	, lat:64.9785883 },
        { lng:25.9630094	, lat:64.9780194 },
        { lng:25.9595463	, lat:64.9797326 },
        { lng:25.9598091	, lat:64.9798307 },
        { lng:25.9605358	, lat:64.9798503 },
        { lng:25.9612315	, lat:64.9797522 },
        { lng:25.9621282	, lat:64.9795691 },
        { lng:25.9625765	, lat:64.9793337 },
        { lng:25.9630094	, lat:64.978961 },
        { lng:25.9635505	, lat:64.9788041 },
        { lng:25.9641844	, lat:64.9787452 },
        { lng:25.9648183	, lat:64.9786929 },
        { lng:25.9655449	, lat:64.9788237 },
        { lng:25.9651584	, lat:64.979713 },
        { lng:25.9680958	, lat:64.9808703 },
        { lng:25.9724092	, lat:64.9775224 },
        { lng:25.9710945	, lat:64.9693479 },
        { lng:25.9738275	, lat:64.9685523 },
        { lng:25.9792717	, lat:64.971013 },
        { lng:25.982595	, lat:64.9699839 },
        { lng:25.9847066	, lat:64.9717866 },
        { lng:25.9862918	, lat:64.9716987 },
        { lng:25.9879862	, lat:64.9714166 },
        { lng:25.9829138	, lat:64.9698209 },
        { lng:25.9831871	, lat:64.9691641 },
        { lng:25.9762452	, lat:64.9659539 },
        { lng:25.9860573	, lat:64.9630145 },
        { lng:26.0389611	, lat:64.9755056 },
        { lng:26.0405844	, lat:64.9736221 },
        { lng:26.0432899	, lat:64.9746097 },
        { lng:26.0509737	, lat:64.9778206 },
        { lng:26.0515148	, lat:64.9775852 },
        { lng:26.0523651	, lat:64.9773105 },
        { lng:26.0517312	, lat:64.9762381 },
        { lng:26.0528444	, lat:64.9745966 },
        { lng:26.0546996	, lat:64.9739622 },
        { lng:26.0555035	, lat:64.9738641 },
        { lng:26.0578998	, lat:64.97304 },
        { lng:26.061332	, lat:64.9737333 },
        { lng:26.0662947	, lat:64.9731905 },
        { lng:26.0673924	, lat:64.9734848 },
        { lng:26.0673306	, lat:64.9742238 },
        { lng:26.0693404	, lat:64.9742107 },
        { lng:26.07067	, lat:64.9738118 },
        { lng:26.071814	, lat:64.9732035 },
        { lng:26.0726953	, lat:64.9731447 },
        { lng:26.0752307	, lat:64.9721767 },
        { lng:26.076313	, lat:64.9722159 },
        { lng:26.079776	, lat:64.9712675 },
        { lng:26.082899	, lat:64.9707508 },
        { lng:26.089114	, lat:64.9700836 },
        { lng:26.0918968	, lat:64.9703649 },
        { lng:26.0938448	, lat:64.9703518 },
        { lng:26.0953599	, lat:64.9708097 },
        { lng:26.0801225	, lat:64.9453135 },
        { lng:26.0815293	, lat:64.9452087 },
        { lng:26.0847605	, lat:64.9454248 },
        { lng:26.0860592	, lat:64.9453528 },
        { lng:26.0876825	, lat:64.9453528 },
        { lng:26.0886101	, lat:64.9450909 },
        { lng:26.0887338	, lat:64.944973 },
        { lng:26.0923051	, lat:64.9464199 },
        { lng:26.0929699	, lat:64.9463414 },
        { lng:26.0940212	, lat:64.9463675 },
        { lng:26.0954126	, lat:64.9466032 },
        { lng:26.0959383	, lat:64.9468651 },
        { lng:26.0969123	, lat:64.9476245 },
        { lng:26.0984583	, lat:64.9480762 },
        { lng:26.108863	, lat:64.9508452 },
        { lng:26.1188812	, lat:64.9477031 },
        { lng:26.1186339	, lat:64.9474674 },
        { lng:26.1190049	, lat:64.9473365 },
        { lng:26.1194687	, lat:64.9470353 },
        { lng:26.1199789	, lat:64.9467014 },
        { lng:26.1208292	, lat:64.9464461 },
        { lng:26.1221433	, lat:64.9461777 },
        { lng:26.1232255	, lat:64.9458569 },
        { lng:26.1242614	, lat:64.9455033 },
        { lng:26.1250498	, lat:64.9453462 },
        { lng:26.1255136	, lat:64.9452873 },
        { lng:26.1261166	, lat:64.9449534 },
        { lng:26.1275853	, lat:64.9455623 },
        { lng:26.140751	, lat:64.9439173 },
        { lng:26.1562267	, lat:64.946582 },
        { lng:26.1754746	, lat:64.9455803 },
        { lng:26.1799736	, lat:64.9436423 },
        { lng:26.1906102	, lat:64.9500843 },
        { lng:26.2073609	, lat:64.9407069 },
        { lng:26.189979	, lat:64.9347702 },
        { lng:26.1973909	, lat:64.9316299 },
        { lng:26.1935865	, lat:64.9307776 },
        { lng:26.1947672	, lat:64.9236619 },
        { lng:26.1949858	, lat:64.9234858 },
        { lng:26.1947672	, lat:64.9232542 },
        { lng:26.1950514	, lat:64.9230874 },
        { lng:26.195445	, lat:64.9230225 },
        { lng:26.1995117	, lat:64.9211876 },
        { lng:26.2121272	, lat:64.9167296 },
        { lng:26.2136358	, lat:64.917536 },
        { lng:26.2157129	, lat:64.9176009 },
        { lng:26.2165656	, lat:64.9175731 },
        { lng:26.2183585	, lat:64.9171931 },
        { lng:26.2192986	, lat:64.9166277 },
        { lng:26.2201732	, lat:64.9163681 },
        { lng:26.2214194	, lat:64.9162569 },
        { lng:26.2237152	, lat:64.9163311 },
        { lng:26.224546	, lat:64.9164608 },
        { lng:26.2250051	, lat:64.9166277 },
        { lng:26.2255955	, lat:64.9170262 },
        { lng:26.2594847	, lat:64.9120391 },
        { lng:26.2733028	, lat:64.9059106 },
        { lng:26.2254071	, lat:64.8802673 },
        { lng:26.2244992	, lat:64.8797752 },
        { lng:26.2504844	, lat:64.8754286 },
        { lng:26.2680942	, lat:64.8730631 },
        { lng:26.2830652	, lat:64.8729435 },
        { lng:26.293124	, lat:64.8778416 },
        { lng:26.3171072	, lat:64.869424 },
        { lng:26.3082806	, lat:64.8646547 },
        { lng:26.3092339	, lat:64.8643716 },
        { lng:26.3329682	, lat:64.8564905 },
        { lng:26.3420904	, lat:64.8615623 },
        { lng:26.3712911	, lat:64.8519677 },
        { lng:26.3943141	, lat:64.844412 },
        { lng:26.4069094	, lat:64.8651455 },
        { lng:26.4042163	, lat:64.865825 },
        { lng:26.3995515	, lat:64.8683988 },
        { lng:26.3945367	, lat:64.8695007 },
        { lng:26.3840883	, lat:64.8772414 },
        { lng:26.3846371	, lat:64.8792787 },
        { lng:26.3782736	, lat:64.8808549 },
        { lng:26.3806089	, lat:64.8822774 },
        { lng:26.3875678	, lat:64.8813753 },
        { lng:26.3869256	, lat:64.8780147 },
        { lng:26.3885953	, lat:64.8751394 },
        { lng:26.3982631	, lat:64.8707764 },
        { lng:26.3966284	, lat:64.8698641 },
        { lng:26.4007851	, lat:64.8689715 },
        { lng:26.4058058	, lat:64.8667003 },
        { lng:26.408016	, lat:64.8661702 },
        { lng:26.4282988	, lat:64.8609226 },
        { lng:26.4317316	, lat:64.8624503 },
        { lng:26.4349542	, lat:64.861627 },
        { lng:26.4270898	, lat:64.8583018 },
        { lng:26.4583463	, lat:64.8516859 },
        { lng:26.4677576	, lat:64.8669025 },
        { lng:26.4771011	, lat:64.8695767 },
        { lng:26.4845828	, lat:64.868024 },
        { lng:26.499546	, lat:64.8594535 },
        { lng:26.4948742	, lat:64.8520312 },
        { lng:26.5167435	, lat:64.849384 },
        { lng:26.5327562	, lat:64.8623873 },
        { lng:26.559839	, lat:64.9069593 },
        { lng:26.6335718	, lat:64.8922539 },
        { lng:26.6612639	, lat:64.8916218 },
        { lng:26.6770733	, lat:64.915961 },
        { lng:26.6789346	, lat:64.9163881 },
        { lng:26.6774785	, lat:64.9172976 },
        { lng:26.6756087	, lat:64.9178918 },
        { lng:26.6751228	, lat:64.9182805 },
        { lng:26.6754853	, lat:64.9187609 },
        { lng:26.6765744	, lat:64.9192706 },
        { lng:26.6782336	, lat:64.9195127 },
        { lng:26.6764818	, lat:64.9221888 },
        { lng:26.6782589	, lat:64.9223574 },
        { lng:26.6799796	, lat:64.9233983 },
        { lng:26.6834901	, lat:64.9224301 },
        { lng:26.6839029	, lat:64.9202295 },
        { lng:26.6823067	, lat:64.9193917 },
        { lng:26.6781456	, lat:64.9187135 },
        { lng:26.6785513	, lat:64.9178886 },
        { lng:26.6789571	, lat:64.9176063 },
        { lng:26.6842384	, lat:64.9164485 },
        { lng:26.7684503	, lat:64.9527695 },
        { lng:26.7660129	, lat:64.9526091 },
        { lng:26.7651323	, lat:64.9544114 },
        { lng:26.7710683	, lat:64.9569498 },
        { lng:26.7644453	, lat:64.9596933 },
        { lng:26.7715283	, lat:64.9613058 },
        { lng:26.7710288	, lat:64.962418 },
        { lng:26.7588741	, lat:64.967485 },
        { lng:26.7389826	, lat:64.9900822 },
        { lng:26.6887425	, lat:64.9788786 },
        { lng:26.6823381	, lat:64.9864048 },
        { lng:26.6792567	, lat:64.9902414 },
        { lng:26.6930287	, lat:64.9969475 },
        { lng:26.6903308	, lat:64.9978753 },
        { lng:26.6785333	, lat:64.9964967 },
        { lng:26.674869	, lat:64.9964768 },
        { lng:26.6750676	, lat:64.9974177 },
        { lng:26.6756737	, lat:64.9979301 },
        { lng:26.6760988	, lat:64.9980214 },
        { lng:26.6763764	, lat:64.9986171 },
        { lng:26.6757649	, lat:64.9988756 },
        { lng:26.67692	, lat:64.9990017 },
        { lng:26.6771409	, lat:64.9979434 },
        { lng:26.6838788	, lat:64.998074 },
        { lng:26.6871485	, lat:64.9991102 },
        { lng:26.6998916	, lat:65.000336 },
        { lng:26.7142006	, lat:65.0073225 },
        { lng:26.7105819	, lat:65.0079652 },
        { lng:26.7052629	, lat:65.0140475 },
        { lng:26.7016587	, lat:65.0130281 },
        { lng:26.6590973	, lat:65.0144774 },
        { lng:26.6106191	, lat:65.0538001 },
        { lng:26.6271396	, lat:65.0705861 },
        { lng:26.6264957	, lat:65.0972918 },
        { lng:26.5934237	, lat:65.0976627 },
        { lng:26.5873965	, lat:65.1019173 },
        { lng:26.5797929	, lat:65.1053766 },
        { lng:26.5620333	, lat:65.1128006 },
        { lng:26.4758581	, lat:65.1467756 },
        { lng:26.4485232	, lat:65.1561532 },
        { lng:26.3165578	, lat:65.2011922 },
        { lng:26.233696	, lat:65.2742419 },
        { lng:26.2549842	, lat:65.2841441 },
        { lng:26.281316	, lat:65.2788589 },
        { lng:26.2876835	, lat:65.2810011 },
        { lng:26.3454699	, lat:65.3128141 },
        { lng:26.3536088	, lat:65.3210112 },
        { lng:26.3770861	, lat:65.3170746 },
        { lng:26.3991167	, lat:65.3255044 },
        { lng:26.3986176	, lat:65.3259471 },
        { lng:26.3827167	, lat:65.3276217 },
        { lng:26.3735572	, lat:65.3287378 },
        { lng:26.3882843	, lat:65.3298908 },
        { lng:26.3911746	, lat:65.3309234 },
        { lng:26.3953743	, lat:65.3313028 },
        { lng:26.3977447	, lat:65.3329686 },
        { lng:26.3988065	, lat:65.3332419 },
        { lng:26.4015283	, lat:65.3327472 },
        { lng:26.4037961	, lat:65.3329174 },
        { lng:26.4017571	, lat:65.3345408 },
        { lng:26.3829554	, lat:65.3307088 },
        { lng:26.3920532	, lat:65.3499406 },
        { lng:26.4267155	, lat:65.3475643 },
        { lng:26.4011976	, lat:65.3729934 },
        { lng:26.4017242	, lat:65.3740906 },
        { lng:26.1532956	, lat:65.4191379 },
        { lng:26.1543967	, lat:65.5282452 },
        { lng:26.1549234	, lat:65.5551439 },
        { lng:26.018754	, lat:65.5545494 },
        { lng:26.01527	, lat:65.5611872 },
        { lng:26.0106154	, lat:65.5632621 },
        { lng:26.0102701	, lat:65.5633282 },
        { lng:26.0049289	, lat:65.5643522 },
        { lng:25.9465624	, lat:65.5618703 },
        { lng:25.9006922	, lat:65.5599176 },
        { lng:25.899885	, lat:65.5592625 },
        { lng:25.7891181	, lat:65.5502905 },
        { lng:25.6616568	, lat:65.4134134 },
        { lng:25.6394881	, lat:65.3892131 },
        { lng:25.6471713	, lat:65.3786133 },
        { lng:25.612579	, lat:65.3498945 },
        { lng:25.6629865	, lat:65.3312395 },
        { lng:25.6618753	, lat:65.3307055 },
        { lng:25.6653746	, lat:65.3294029 },
        { lng:25.6617945	, lat:65.3270476 },
        { lng:25.6673002	, lat:65.3225488 },
        { lng:25.6727607	, lat:65.3241067 },
        { lng:25.6750148	, lat:65.3255804 },
        { lng:25.6767258	, lat:65.3244751 },
        { lng:25.6776309	, lat:65.3243652 },
        { lng:25.6775532	, lat:65.3239515 },
        { lng:25.679539	, lat:65.3219993 },
        { lng:25.6804306	, lat:65.3216309 },
        { lng:25.6806548	, lat:65.3212753 },
        { lng:25.6816014	, lat:65.3208422 },
        { lng:25.6817437	, lat:65.3204801 },
        { lng:25.6831382	, lat:65.3196979 },
        { lng:25.6845813	, lat:65.3185212 },
        { lng:25.6851988	, lat:65.31781 },
        { lng:25.6848321	, lat:65.3169048 },
        { lng:25.6836514	, lat:65.3164264 },
        { lng:25.6808759	, lat:65.3146805 },
        { lng:25.6802435	, lat:65.3130122 },
        { lng:25.6772582	, lat:65.310309 },
        { lng:25.6763861	, lat:65.3087244 },
        { lng:25.6767712	, lat:65.3078771 },
        { lng:25.6761544	, lat:65.3074308 },
        { lng:25.6770265	, lat:65.3070816 },
        { lng:25.6771219	, lat:65.30648 },
        { lng:25.6791655	, lat:65.3060208 },
        { lng:25.6807485	, lat:65.3061178 },
        { lng:25.6830732	, lat:65.3058397 },
        { lng:25.6854235	, lat:65.3049136 },
        { lng:25.6883482	, lat:65.3043341 },
        { lng:25.6903141	, lat:65.3037632 },
        { lng:25.6917451	, lat:65.3029319 },
        { lng:25.6925568	, lat:65.3020748 },
        { lng:25.6918994	, lat:65.3000748 },
        { lng:25.6902082	, lat:65.2982854 },
        { lng:25.6897556	, lat:65.2980489 },
        { lng:25.6875026	, lat:65.2977572 },
        { lng:25.6854293	, lat:65.2961486 },
        { lng:25.6858912	, lat:65.2959816 },
        { lng:25.6852239	, lat:65.2954364 },
        { lng:25.6836812	, lat:65.2945946 },
        { lng:25.6831532	, lat:65.2946014 },
        { lng:25.6812498	, lat:65.2942855 },
        { lng:25.6796286	, lat:65.2943746 },
        { lng:25.6774418	, lat:65.2949319 },
        { lng:25.6754724	, lat:65.2957087 },
        { lng:25.6754653	, lat:65.2958843 },
        { lng:25.6759413	, lat:65.2958462 },
        { lng:25.6773094	, lat:65.2953297 },
        { lng:25.6744372	, lat:65.2973622 },
        { lng:25.6746765	, lat:65.2974846 },
        { lng:25.6752375	, lat:65.2975173 },
        { lng:25.6745422	, lat:65.2980606 },
        { lng:25.6729798	, lat:65.2984477 },
        { lng:25.6709494	, lat:65.2985585 },
        { lng:25.6694849	, lat:65.2985109 },
        { lng:25.6656324	, lat:65.2953573 },
        { lng:25.6665995	, lat:65.2953268 },
        { lng:25.6669193	, lat:65.2954054 },
        { lng:25.6687385	, lat:65.2963397 },
        { lng:25.6699243	, lat:65.2962559 },
        { lng:25.6744997	, lat:65.2942633 },
        { lng:25.6726051	, lat:65.2937357 },
        { lng:25.675621	, lat:65.2917888 },
        { lng:25.6846973	, lat:65.2870599 },
        { lng:25.6863872	, lat:65.2866392 },
        { lng:25.7085427	, lat:65.2836969 },
        { lng:25.7127572	, lat:65.2844166 },
        { lng:25.7135692	, lat:65.283937 },
        { lng:25.7255501	, lat:65.2857273 },
        { lng:25.729901	, lat:65.2832006 },
        { lng:25.7256431	, lat:65.2792771 },
        { lng:25.73113	, lat:65.2762561 },
        { lng:25.7275775	, lat:65.275043 },
        { lng:25.7282657	, lat:65.2739516 },
        { lng:25.7378818	, lat:65.2690382 },
        { lng:25.6594953	, lat:65.240177 },
        { lng:25.6499308	, lat:65.2429683 },
        { lng:25.6457902	, lat:65.2393591 },
        { lng:25.6484797	, lat:65.238523 },
        { lng:25.649458	, lat:65.2377491 },
        { lng:25.6440194	, lat:65.2378205 },
        { lng:25.6389936	, lat:65.2394805 },
        { lng:25.6423583	, lat:65.2401157 },
        { lng:25.6444871	, lat:65.2409007 },
        { lng:25.6454998	, lat:65.2425659 },
        { lng:25.6451423	, lat:65.2439511 },
        { lng:25.6476119	, lat:65.2440901 },
        { lng:25.6401925	, lat:65.2478238 },
        { lng:25.624523	, lat:65.2495359 },
        { lng:25.6221428	, lat:65.2495716 },
        { lng:25.6214017	, lat:65.2483966 },
        { lng:25.6245159	, lat:65.2467744 },
        { lng:25.6219957	, lat:65.245838 },
        { lng:25.6183301	, lat:65.2468075 },
        { lng:25.6096731	, lat:65.2454316 },
        { lng:25.608806	, lat:65.2460159 },
        { lng:25.6162844	, lat:65.2471509 },
        { lng:25.619381	, lat:65.2484088 },
        { lng:25.6182198	, lat:65.249829 },
        { lng:25.5524609	, lat:65.2529179 },
        { lng:25.5146694	, lat:65.2507989 },
        { lng:25.4943114	, lat:65.2515804 },
        { lng:25.404108	, lat:65.2548259 },
        { lng:25.4015873	, lat:65.2560268 },
        { lng:25.4009136	, lat:65.2560441 },
        { lng:25.397795	, lat:65.2561242 },
        { lng:25.4011694	, lat:65.2549283 },
        { lng:25.3955723	, lat:65.255131 },
        { lng:25.3732744	, lat:65.2559384 },
        { lng:25.3730761	, lat:65.2559456 },
        { lng:25.3539185	, lat:65.2566533 },
        { lng:25.3530612	, lat:65.2577885 },
        { lng:25.3468802	, lat:65.2572071 },
        { lng:25.3462776	, lat:65.2566009 },
        { lng:25.341595	, lat:65.257563 },
        { lng:25.3303818	, lat:65.2592846 },
        { lng:25.3195429	, lat:65.2626344 },
        { lng:25.307414	, lat:65.2622792 },
        { lng:25.3000795	, lat:65.2605921 },
        { lng:25.2535229	, lat:65.2496703 },
        { lng:25.22493	, lat:65.2367158 },
        { lng:25.1771683	, lat:65.2386746 },
        { lng:25.1798278	, lat:65.252291 },
        { lng:24.6092148	, lat:65.2140451 },
        { lng:24.1374975	, lat:65.2416396 },
        { lng:24.1332264	, lat:65.2288625 },
        { lng:24.1284767	, lat:65.2145896 },
        { lng:24.126288	, lat:65.2080135 },
        { lng:24.1203024	, lat:65.1900741 },
        { lng:24.1149326	, lat:65.1739343 },
      ],
    },
    {
      id: 21,
      regionName: 'eCom TP  Pirkkala kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:23.4776362	, lat:61.3968306 },
        { lng:23.4839014	, lat:61.394204 },
        { lng:23.4968534	, lat:61.3867398 },
        { lng:23.5024175	, lat:61.3857231 },
        { lng:23.5083463	, lat:61.3820627 },
        { lng:23.5137405	, lat:61.3748085 },
        { lng:23.5285602	, lat:61.3694111 },
        { lng:23.5316743	, lat:61.3692088 },
        { lng:23.5390895	, lat:61.3569611 },
        { lng:23.5483654	, lat:61.358396 },
        { lng:23.5462649	, lat:61.3634619 },
        { lng:23.5496602	, lat:61.3685495 },
        { lng:23.5493684	, lat:61.368733 },
        { lng:23.5491962	, lat:61.3690119 },
        { lng:23.549291	, lat:61.3690848 },
        { lng:23.5501213	, lat:61.3695026 },
        { lng:23.5524593	, lat:61.3691105 },
        { lng:23.5592394	, lat:61.3781835 },
        { lng:23.5573655	, lat:61.3788965 },
        { lng:23.5559663	, lat:61.3786874 },
        { lng:23.5546804	, lat:61.3805353 },
        { lng:23.557369	, lat:61.3805353 },
        { lng:23.5615774	, lat:61.3812632 },
        { lng:23.5676561	, lat:61.3870298 },
        { lng:23.5696434	, lat:61.3877016 },
        { lng:23.5692927	, lat:61.3882614 },
        { lng:23.570111	, lat:61.3903326 },
        { lng:23.5664214	, lat:61.3916865 },
        { lng:23.5662314	, lat:61.392666 },
        { lng:23.570301	, lat:61.3919803 },
        { lng:23.5728581	, lat:61.3948663 },
        { lng:23.5708489	, lat:61.3952161 },
        { lng:23.5717622	, lat:61.396178 },
        { lng:23.5737203	, lat:61.3955554 },
        { lng:23.5750272	, lat:61.3969181 },
        { lng:23.593942	, lat:61.4036456 },
        { lng:23.6122084	, lat:61.4047168 },
        { lng:23.6367421	, lat:61.4061585 },
        { lng:23.6376732	, lat:61.4062132 },
        { lng:23.6505423	, lat:61.4069687 },
        { lng:23.6582065	, lat:61.4074186 },
        { lng:23.6986014	, lat:61.419503 },
        { lng:23.7323881	, lat:61.429564 },
        { lng:23.7411181	, lat:61.4321633 },
        { lng:23.738339	, lat:61.4343042 },
        { lng:23.7343726	, lat:61.4384874 },
        { lng:23.7340616	, lat:61.4388155 },
        { lng:23.7328122	, lat:61.4444023 },
        { lng:23.7321814	, lat:61.4472223 },
        { lng:23.732014	, lat:61.4477439 },
        { lng:23.7326464	, lat:61.4477944 },
        { lng:23.7334201	, lat:61.4477181 },
        { lng:23.7334242	, lat:61.4481815 },
        { lng:23.7335343	, lat:61.4484844 },
        { lng:23.7334362	, lat:61.4485833 },
        { lng:23.7325992	, lat:61.4484869 },
        { lng:23.731676	, lat:61.4485819 },
        { lng:23.7304454	, lat:61.4522731 },
        { lng:23.7289277	, lat:61.4537933 },
        { lng:23.728321	, lat:61.4544011 },
        { lng:23.7278444	, lat:61.4548125 },
        { lng:23.7269495	, lat:61.4557356 },
        { lng:23.7245094	, lat:61.4582682 },
        { lng:23.7231159	, lat:61.4597386 },
        { lng:23.722138	, lat:61.4607704 },
        { lng:23.7221269	, lat:61.4607821 },
        { lng:23.7209202	, lat:61.4618197 },
        { lng:23.7213104	, lat:61.4619243 },
        { lng:23.7230253	, lat:61.4624237 },
        { lng:23.7238477	, lat:61.4625866 },
        { lng:23.7247972	, lat:61.4625829 },
        { lng:23.7280634	, lat:61.4635021 },
        { lng:23.7258292	, lat:61.4649114 },
        { lng:23.7257521	, lat:61.4668476 },
        { lng:23.7258507	, lat:61.4688062 },
        { lng:23.7219552	, lat:61.4714257 },
        { lng:23.7213499	, lat:61.4718459 },
        { lng:23.7207502	, lat:61.4723901 },
        { lng:23.7184058	, lat:61.4733477 },
        { lng:23.7188442	, lat:61.4740228 },
        { lng:23.7180431	, lat:61.4746972 },
        { lng:23.7182051	, lat:61.4748507 },
        { lng:23.7256194	, lat:61.4841025 },
        { lng:23.7077645	, lat:61.4872551 },
        { lng:23.7054758	, lat:61.4845886 },
        { lng:23.6963859	, lat:61.4813497 },
        { lng:23.6674062	, lat:61.4874693 },
        { lng:23.643798	, lat:61.4832384 },
        { lng:23.6394769	, lat:61.4826538 },
        { lng:23.6342735	, lat:61.4817354 },
        { lng:23.6298932	, lat:61.4807635 },
        { lng:23.6131342	, lat:61.4777953 },
        { lng:23.5910572	, lat:61.4738416 },
        { lng:23.5683885	, lat:61.475984 },
        { lng:23.5485745	, lat:61.4693129 },
        { lng:23.5468543	, lat:61.4687287 },
        { lng:23.5179465	, lat:61.4351456 },
        { lng:23.507797	, lat:61.4311664 },
        { lng:23.5084161	, lat:61.4281146 },
        { lng:23.5028617	, lat:61.4242172 },
        { lng:23.4913583	, lat:61.4227343 },
        { lng:23.485671	, lat:61.4182551 },
        { lng:23.484539	, lat:61.40714 },
        { lng:23.4771833	, lat:61.4026663 },
        { lng:23.4776362	, lat:61.3968306 },
      ],
    },
    {
      id: 22,
      regionName: 'eCom TP  Pori kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng: 20.8747852	, lat:61.5303891 },
        { lng: 20.8835861	, lat:61.5667118 },
        { lng: 20.8933945	, lat:61.6065058 },
        { lng: 20.903203	, lat:61.6462487 },
        { lng: 20.8930009	, lat:61.6842962 },
        { lng: 20.8888476	, lat:61.7002223 },
        { lng: 21.3652204	, lat:61.727754 },
        { lng: 21.4231235	, lat:61.7222409 },
        { lng: 21.4461104	, lat:61.722663 },
        { lng: 21.5494865	, lat:61.7414906 },
        { lng: 21.5525579	, lat:61.7489798 },
        { lng: 21.5556907	, lat:61.7497009 },
        { lng: 21.5578365	, lat:61.7495079 },
        { lng: 21.5584159	, lat:61.7495892 },
        { lng: 21.5589416	, lat:61.7494317 },
        { lng: 21.5631473	, lat:61.7490915 },
        { lng: 21.5665698	, lat:61.7477102 },
        { lng: 21.5668702	, lat:61.7473801 },
        { lng: 21.5663552	, lat:61.7472328 },
        { lng: 21.5672564	, lat:61.7463694 },
        { lng: 21.566956	, lat:61.7462373 },
        { lng: 21.5680826	, lat:61.7457955 },
        { lng: 21.5679967	, lat:61.745633 },
        { lng: 21.5674281	, lat:61.7457853 },
        { lng: 21.5673745	, lat:61.7456736 },
        { lng: 21.5695202	, lat:61.7450844 },
        { lng: 21.5692198	, lat:61.7453841 },
        { lng: 21.5691447	, lat:61.745765 },
        { lng: 21.5697026	, lat:61.7463796 },
        { lng: 21.5710437	, lat:61.7465624 },
        { lng: 21.5718269	, lat:61.7468468 },
        { lng: 21.5727925	, lat:61.7466944 },
        { lng: 21.5732002	, lat:61.7469585 },
        { lng: 21.5755176	, lat:61.7472125 },
        { lng: 21.5769553	, lat:61.7476187 },
        { lng: 21.5771745	, lat:61.7480038 },
        { lng: 21.6047625	, lat:61.7458153 },
        { lng: 21.6045713	, lat:61.7448559 },
        { lng: 21.6063523	, lat:61.7450997 },
        { lng: 21.6074896	, lat:61.7448559 },
        { lng: 21.6083693	, lat:61.7444597 },
        { lng: 21.6134922	, lat:61.7446197 },
        { lng: 21.6130256	, lat:61.745252 },
        { lng: 21.614356	, lat:61.7455162 },
        { lng: 21.6167378	, lat:61.7456279 },
        { lng: 21.6175747	, lat:61.745892 },
        { lng: 21.6187978	, lat:61.7467351 },
        { lng: 21.6202783	, lat:61.7468874 },
        { lng: 21.6217589	, lat:61.7469179 },
        { lng: 21.6244734	, lat:61.7456332 },
        { lng: 21.6252351	, lat:61.7447086 },
        { lng: 21.6265762	, lat:61.7448203 },
        { lng: 21.6269517	, lat:61.7446934 },
        { lng: 21.6275418	, lat:61.7447086 },
        { lng: 21.6283357	, lat:61.7443175 },
        { lng: 21.628561	, lat:61.7439772 },
        { lng: 21.6303264	, lat:61.7438383 },
        { lng: 21.6302884	, lat:61.7426972 },
        { lng: 21.6307604	, lat:61.7426007 },
        { lng: 21.6314256	, lat:61.7425652 },
        { lng: 21.6315865	, lat:61.7426921 },
        { lng: 21.6314793	, lat:61.7428801 },
        { lng: 21.6317582	, lat:61.7430579 },
        { lng: 21.632123	, lat:61.7431087 },
        { lng: 21.6324448	, lat:61.7430426 },
        { lng: 21.6327023	, lat:61.7429207 },
        { lng: 21.6326594	, lat:61.7427887 },
        { lng: 21.6323161	, lat:61.7426363 },
        { lng: 21.6323805	, lat:61.742489 },
        { lng: 21.6326916	, lat:61.742489 },
        { lng: 21.6330027	, lat:61.7426921 },
        { lng: 21.6332924	, lat:61.7426414 },
        { lng: 21.6332388	, lat:61.742428 },
        { lng: 21.6334426	, lat:61.7423366 },
        { lng: 21.6337538	, lat:61.7423671 },
        { lng: 21.6338932	, lat:61.7424839 },
        { lng: 21.6340756	, lat:61.7435404 },
        { lng: 21.6493304	, lat:61.7423382 },
        { lng: 21.6492247	, lat:61.7421334 },
        { lng: 21.6554475	, lat:61.7416559 },
        { lng: 21.6556406	, lat:61.7418591 },
        { lng: 21.6778064	, lat:61.7401219 },
        { lng: 21.7008627	, lat:61.7383473 },
        { lng: 21.7064309	, lat:61.7402844 },
        { lng: 21.7280496	, lat:61.7479212 },
        { lng: 21.753215	, lat:61.7339675 },
        { lng: 21.8142838	, lat:61.6991036 },
        { lng: 21.8306364	, lat:61.6898604 },
        { lng: 21.8329132	, lat:61.6890021 },
        { lng: 21.8332189	, lat:61.6891751 },
        { lng: 21.8348717	, lat:61.689391 },
        { lng: 21.8354559	, lat:61.6904014 },
        { lng: 21.8368935	, lat:61.690264 },
        { lng: 21.8367541	, lat:61.6906405 },
        { lng: 21.8362605	, lat:61.6907016 },
        { lng: 21.8369043	, lat:61.6916733 },
        { lng: 21.8380844	, lat:61.6915258 },
        { lng: 21.8383741	, lat:61.6912307 },
        { lng: 21.8386209	, lat:61.6907168 },
        { lng: 21.8385243	, lat:61.6903454 },
        { lng: 21.8394041	, lat:61.6902233 },
        { lng: 21.8393934	, lat:61.6903505 },
        { lng: 21.8408525	, lat:61.690381 },
        { lng: 21.8424618	, lat:61.6893634 },
        { lng: 21.8421829	, lat:61.689165 },
        { lng: 21.8428802	, lat:61.6888749 },
        { lng: 21.8432128	, lat:61.6890021 },
        { lng: 21.8434381	, lat:61.6888342 },
        { lng: 21.8430626	, lat:61.6885798 },
        { lng: 21.8437064	, lat:61.6884679 },
        { lng: 21.8437707	, lat:61.687959 },
        { lng: 21.8426335	, lat:61.6876079 },
        { lng: 21.8420219	, lat:61.6877402 },
        { lng: 21.8417752	, lat:61.6875316 },
        { lng: 21.8416893	, lat:61.6871093 },
        { lng: 21.8422043	, lat:61.6868599 },
        { lng: 21.8406379	, lat:61.686636 },
        { lng: 21.8398762	, lat:61.6873281 },
        { lng: 21.8401658	, lat:61.6877809 },
        { lng: 21.8394685	, lat:61.6879336 },
        { lng: 21.8397367	, lat:61.6883152 },
        { lng: 21.8393075	, lat:61.6884119 },
        { lng: 21.8393719	, lat:61.688132 },
        { lng: 21.837666	, lat:61.6871194 },
        { lng: 21.838181	, lat:61.6868854 },
        { lng: 21.8381274	, lat:61.6868497 },
        { lng: 21.8408418	, lat:61.6857506 },
        { lng: 21.8408739	, lat:61.6862696 },
        { lng: 21.8407559	, lat:61.6865444 },
        { lng: 21.8424082	, lat:61.6867938 },
        { lng: 21.8427622	, lat:61.6866411 },
        { lng: 21.8459748	, lat:61.6859281 },
        { lng: 21.8469357	, lat:61.6844326 },
        { lng: 21.8481626	, lat:61.6838949 },
        { lng: 21.8473649	, lat:61.6837099 },
        { lng: 21.8469679	, lat:61.6834504 },
        { lng: 21.8473112	, lat:61.6832723 },
        { lng: 21.8485236	, lat:61.6833791 },
        { lng: 21.8492746	, lat:61.6828346 },
        { lng: 21.8501437	, lat:61.682972 },
        { lng: 21.8511093	, lat:61.6826412 },
        { lng: 21.851528	, lat:61.6816169 },
        { lng: 21.8575926	, lat:61.6793412 },
        { lng: 21.8748914	, lat:61.6725222 },
        { lng: 21.9181714	, lat:61.6554605 },
        { lng: 21.9485212	, lat:61.6432831 },
        { lng: 21.9598253	, lat:61.6387093 },
        { lng: 21.9828195	, lat:61.6294039 },
        { lng: 21.9873609	, lat:61.6281015 },
        { lng:22.0261414	, lat:61.6172862 },
        { lng:22.0271032	, lat:61.6170179 },
        { lng:22.0266826	, lat:61.6165725 },
        { lng:22.0270603	, lat:61.6164875 },
        { lng:22.0276101	, lat:61.6165431 },
        { lng:22.0280961	, lat:61.6164817 },
        { lng:22.0280197	, lat:61.6163713 },
        { lng:22.0281047	, lat:61.6162486 },
        { lng:22.028315	, lat:61.6161866 },
        { lng:22.0299007	, lat:61.6161196 },
        { lng:22.0298685	, lat:61.6162593 },
        { lng:22.0343452	, lat:61.6150084 },
        { lng:22.0359545	, lat:61.6167986 },
        { lng:22.0402353	, lat:61.6167068 },
        { lng:22.043175	, lat:61.6163192 },
        { lng:22.047842	, lat:61.6172118 },
        { lng:22.0533996	, lat:61.617074 },
        { lng:22.0570367	, lat:61.6171199 },
        { lng:22.0577791	, lat:61.6194598 },
        { lng:22.0595454	, lat:61.6195138 },
        { lng:22.062209	, lat:61.6193649 },
        { lng:22.0640817	, lat:61.619573 },
        { lng:22.0752226	, lat:61.6213527 },
        { lng:22.080374	, lat:61.6221727 },
        { lng:22.087035	, lat:61.6222063 },
        { lng:22.0885692	, lat:61.6220391 },
        { lng:22.0949845	, lat:61.622393 },
        { lng:22.0973519	, lat:61.6220544 },
        { lng:22.099437	, lat:61.6218372 },
        { lng:22.1012609	, lat:61.6218127 },
        { lng:22.1039753	, lat:61.6223104 },
        { lng:22.1074729	, lat:61.6224899 },
        { lng:22.110198	, lat:61.6216332 },
        { lng:22.1144472	, lat:61.6216903 },
        { lng:22.1179764	, lat:61.6216434 },
        { lng:22.1217739	, lat:61.6216801 },
        { lng:22.1274268	, lat:61.6391519 },
        { lng:22.1289539	, lat:61.6385315 },
        { lng:22.1319151	, lat:61.6369311 },
        { lng:22.1330094	, lat:61.6356161 },
        { lng:22.1348119	, lat:61.6343826 },
        { lng:22.1357131	, lat:61.6340564 },
        { lng:22.1378803	, lat:61.6338933 },
        { lng:22.1390819	, lat:61.6334957 },
        { lng:22.1399617	, lat:61.6337913 },
        { lng:22.1409809	, lat:61.6337353 },
        { lng:22.1414423	, lat:61.6339239 },
        { lng:22.1418178	, lat:61.6341838 },
        { lng:22.1436202	, lat:61.634454 },
        { lng:22.1441781	, lat:61.6344438 },
        { lng:22.1453261	, lat:61.6345202 },
        { lng:22.1472905	, lat:61.6349211 },
        { lng:22.1478796	, lat:61.6348618 },
        { lng:22.1503258	, lat:61.6351268 },
        { lng:22.1526003	, lat:61.635871 },
        { lng:22.1542525	, lat:61.6361156 },
        { lng:22.1564197	, lat:61.6359016 },
        { lng:22.1593809	, lat:61.6354632 },
        { lng:22.1642035	, lat:61.6352925 },
        { lng:22.1647668	, lat:61.6351345 },
        { lng:22.164976	, lat:61.6351752 },
        { lng:22.1650404	, lat:61.6352593 },
        { lng:22.1654212	, lat:61.6353587 },
        { lng:22.1658182	, lat:61.6357053 },
        { lng:22.1658826	, lat:61.6358914 },
        { lng:22.165432	, lat:61.6361972 },
        { lng:22.1655392	, lat:61.6367578 },
        { lng:22.1660542	, lat:61.6371656 },
        { lng:22.1680927	, lat:61.6380626 },
        { lng:22.1699482	, lat:61.6390252 },
        { lng:22.1713006	, lat:61.6392246 },
        { lng:22.1726417	, lat:61.639245 },
        { lng:22.1740969	, lat:61.638868 },
        { lng:22.1749592	, lat:61.6389902 },
        { lng:22.1765685	, lat:61.6401114 },
        { lng:22.1769333	, lat:61.6405904 },
        { lng:22.1774268	, lat:61.6408656 },
        { lng:22.1792078	, lat:61.6407739 },
        { lng:22.1804094	, lat:61.639245 },
        { lng:22.1815837	, lat:61.6387782 },
        { lng:22.217952	, lat:61.6575816 },
        { lng:22.2298991	, lat:61.6637426 },
        { lng:22.2306431	, lat:61.6707032 },
        { lng:22.2322109	, lat:61.6872182 },
        { lng:22.2328827	, lat:61.6944766 },
        { lng:22.2317006	, lat:61.6955914 },
        { lng:22.2348166	, lat:61.6947903 },
        { lng:22.23676	, lat:61.6960329 },
        { lng:22.2528291	, lat:61.6974729 },
        { lng:22.2653389	, lat:61.6985716 },
        { lng:22.2979273	, lat:61.7015355 },
        { lng:22.3330257	, lat:61.7050076 },
        { lng:22.3338355	, lat:61.7046579 },
        { lng:22.3345238	, lat:61.7048466 },
        { lng:22.3344406	, lat:61.7048619 },
        { lng:22.3340938	, lat:61.7050966 },
        { lng:22.3351461	, lat:61.7052102 },
        { lng:22.3354626	, lat:61.7052585 },
        { lng:22.3355404	, lat:61.7053196 },
        { lng:22.3356128	, lat:61.7054124 },
        { lng:22.3357844	, lat:61.7055217 },
        { lng:22.3359212	, lat:61.705551 },
        { lng:22.336058	, lat:61.705551 },
        { lng:22.3362914	, lat:61.7055383 },
        { lng:22.3364684	, lat:61.7055535 },
        { lng:22.3365998	, lat:61.7055789 },
        { lng:22.3366749	, lat:61.705598 },
        { lng:22.3366481	, lat:61.7056845 },
        { lng:22.3365757	, lat:61.7057633 },
        { lng:22.3364603	, lat:61.7058675 },
        { lng:22.3363155	, lat:61.7059438 },
        { lng:22.3360848	, lat:61.7059934 },
        { lng:22.3358998	, lat:61.7060341 },
        { lng:22.3358408	, lat:61.7060735 },
        { lng:22.3358461	, lat:61.7061472 },
        { lng:22.3359212	, lat:61.7062248 },
        { lng:22.3360366	, lat:61.7062871 },
        { lng:22.3361975	, lat:61.7063252 },
        { lng:22.3363665	, lat:61.7063189 },
        { lng:22.3366776	, lat:61.7062934 },
        { lng:22.3369297	, lat:61.7062426 },
        { lng:22.3371094	, lat:61.7062095 },
        { lng:22.3373964	, lat:61.706193 },
        { lng:22.3376164	, lat:61.7061549 },
        { lng:22.3377719	, lat:61.7061714 },
        { lng:22.3378605	, lat:61.7062222 },
        { lng:22.3379195	, lat:61.7063125 },
        { lng:22.3379865	, lat:61.706432 },
        { lng:22.3381126	, lat:61.7065909 },
        { lng:22.3381984	, lat:61.7066888 },
        { lng:22.3383111	, lat:61.7067677 },
        { lng:22.338472	, lat:61.7067854 },
        { lng:22.3386168	, lat:61.7069177 },
        { lng:22.3384935	, lat:61.7071312 },
        { lng:22.3386705	, lat:61.7073041 },
        { lng:22.3385632	, lat:61.7075991 },
        { lng:22.339282	, lat:61.7080923 },
        { lng:22.3390567	, lat:61.7082373 },
        { lng:22.338928	, lat:61.7082779 },
        { lng:22.338708	, lat:61.7083135 },
        { lng:22.3384184	, lat:61.7083008 },
        { lng:22.3381877	, lat:61.7082779 },
        { lng:22.3380268	, lat:61.7083873 },
        { lng:22.3380482	, lat:61.7085398 },
        { lng:22.3381918	, lat:61.7086357 },
        { lng:22.3384774	, lat:61.7086314 },
        { lng:22.3388153	, lat:61.7085754 },
        { lng:22.3390567	, lat:61.708578 },
        { lng:22.3393464	, lat:61.7086746 },
        { lng:22.3396897	, lat:61.7086492 },
        { lng:22.3399204	, lat:61.7086186 },
        { lng:22.3399687	, lat:61.7086797 },
        { lng:22.339915	, lat:61.708822 },
        { lng:22.3400277	, lat:61.7090407 },
        { lng:22.3402262	, lat:61.7094144 },
        { lng:22.3400974	, lat:61.709478 },
        { lng:22.3398547	, lat:61.7094952 },
        { lng:22.3398614	, lat:61.7096305 },
        { lng:22.340017	, lat:61.70975 },
        { lng:22.3401082	, lat:61.7098772 },
        { lng:22.3400813	, lat:61.7099458 },
        { lng:22.3394644	, lat:61.7101416 },
        { lng:22.3392445	, lat:61.7101619 },
        { lng:22.3389548	, lat:61.7101695 },
        { lng:22.3388797	, lat:61.7102407 },
        { lng:22.3388797	, lat:61.7103221 },
        { lng:22.3389816	, lat:61.7104263 },
        { lng:22.3390728	, lat:61.7105178 },
        { lng:22.3390299	, lat:61.7106043 },
        { lng:22.3389012	, lat:61.7106373 },
        { lng:22.3386705	, lat:61.7106755 },
        { lng:22.338751	, lat:61.7108483 },
        { lng:22.3387349	, lat:61.7110517 },
        { lng:22.338531	, lat:61.7111534 },
        { lng:22.3382199	, lat:61.7112373 },
        { lng:22.3379087	, lat:61.7112627 },
        { lng:22.3375547	, lat:61.7113415 },
        { lng:22.336672	, lat:61.7113771 },
        { lng:22.3363155	, lat:61.7113822 },
        { lng:22.3360848	, lat:61.7114127 },
        { lng:22.3357683	, lat:61.7115551 },
        { lng:22.3356289	, lat:61.7117305 },
        { lng:22.3350602	, lat:61.7120356 },
        { lng:22.3345131	, lat:61.7120864 },
        { lng:22.3342931	, lat:61.7121627 },
        { lng:22.3342985	, lat:61.7122923 },
        { lng:22.334438	, lat:61.7123966 },
        { lng:22.3344951	, lat:61.7125246 },
        { lng:22.3344272	, lat:61.7126279 },
        { lng:22.334218	, lat:61.7127042 },
        { lng:22.333703	, lat:61.7127931 },
        { lng:22.3332363	, lat:61.7129202 },
        { lng:22.3329788	, lat:61.7129685 },
        { lng:22.3328179	, lat:61.7131058 },
        { lng:22.3328125	, lat:61.7132533 },
        { lng:22.3330057	, lat:61.7134134 },
        { lng:22.3332685	, lat:61.7134744 },
        { lng:22.3337996	, lat:61.7136066 },
        { lng:22.3343092	, lat:61.7136956 },
        { lng:22.3344433	, lat:61.713782 },
        { lng:22.334395	, lat:61.7138761 },
        { lng:22.3342448	, lat:61.7139345 },
        { lng:22.3341645	, lat:61.7140312 },
        { lng:22.334041	, lat:61.7141074 },
        { lng:22.3340142	, lat:61.7141862 },
        { lng:22.3340303	, lat:61.7143108 },
        { lng:22.3341644	, lat:61.7144201 },
        { lng:22.3344701	, lat:61.7145777 },
        { lng:22.334438	, lat:61.7146641 },
        { lng:22.3342019	, lat:61.7147937 },
        { lng:22.3338479	, lat:61.7149183 },
        { lng:22.3334777	, lat:61.7150505 },
        { lng:22.3333865	, lat:61.7152284 },
        { lng:22.3334938	, lat:61.7153479 },
        { lng:22.3334831	, lat:61.7154369 },
        { lng:22.3333548	, lat:61.7155057 },
        { lng:22.333129	, lat:61.7155665 },
        { lng:22.3328876	, lat:61.7156529 },
        { lng:22.3327589	, lat:61.71578 },
        { lng:22.3327535	, lat:61.7158741 },
        { lng:22.3330754	, lat:61.7160444 },
        { lng:22.3330915	, lat:61.7161079 },
        { lng:22.3330539	, lat:61.7161766 },
        { lng:22.332893	, lat:61.7162503 },
        { lng:22.3326677	, lat:61.7163087 },
        { lng:22.3325604	, lat:61.7166214 },
        { lng:22.3324048	, lat:61.7167891 },
        { lng:22.332459	, lat:61.7168704 },
        { lng:22.3328823	, lat:61.7170179 },
        { lng:22.3332041	, lat:61.7170992 },
        { lng:22.333408	, lat:61.7171348 },
        { lng:22.3335582	, lat:61.7172136 },
        { lng:22.3337191	, lat:61.7172848 },
        { lng:22.3338157	, lat:61.7173433 },
        { lng:22.3340088	, lat:61.7174093 },
        { lng:22.3341912	, lat:61.7174627 },
        { lng:22.3342395	, lat:61.7175237 },
        { lng:22.3342663	, lat:61.717572 },
        { lng:22.334438	, lat:61.7175822 },
        { lng:22.334969	, lat:61.7175415 },
        { lng:22.3356396	, lat:61.7174526 },
        { lng:22.3361975	, lat:61.7173916 },
        { lng:22.336514	, lat:61.7173331 },
        { lng:22.3371631	, lat:61.717084 },
        { lng:22.3378605	, lat:61.7169696 },
        { lng:22.338865	, lat:61.7168798 },
        { lng:22.3446786	, lat:61.7164816 },
        { lng:22.3478544	, lat:61.7162744 },
        { lng:22.3499431	, lat:61.7161476 },
        { lng:22.3502684	, lat:61.716174 },
        { lng:22.3503864	, lat:61.7161156 },
        { lng:22.3503998	, lat:61.7160851 },
        { lng:22.3503408	, lat:61.7160533 },
        { lng:22.3502979	, lat:61.7160164 },
        { lng:22.3502174	, lat:61.7159783 },
        { lng:22.3501959	, lat:61.7159452 },
        { lng:22.3502335	, lat:61.7159058 },
        { lng:22.350314	, lat:61.7159147 },
        { lng:22.3504025	, lat:61.7159592 },
        { lng:22.3504856	, lat:61.716005 },
        { lng:22.3505715	, lat:61.7160075 },
        { lng:22.3505902	, lat:61.7159757 },
        { lng:22.3506439	, lat:61.715916 },
        { lng:22.350727	, lat:61.7159097 },
        { lng:22.3508075	, lat:61.7159389 },
        { lng:22.3509979	, lat:61.7160317 },
        { lng:22.3511052	, lat:61.7160736 },
        { lng:22.3512152	, lat:61.7160711 },
        { lng:22.3512393	, lat:61.7159872 },
        { lng:22.3511776	, lat:61.7159173 },
        { lng:22.3511615	, lat:61.7158614 },
        { lng:22.3512688	, lat:61.7158105 },
        { lng:22.351411	, lat:61.7158232 },
        { lng:22.3515639	, lat:61.7158512 },
        { lng:22.3516819	, lat:61.7158385 },
        { lng:22.3517007	, lat:61.7157965 },
        { lng:22.3516068	, lat:61.7157521 },
        { lng:22.35158	, lat:61.7157123 },
        { lng:22.3516148	, lat:61.715672 },
        { lng:22.3517114	, lat:61.7156567 },
        { lng:22.351765	, lat:61.7156364 },
        { lng:22.3517892	, lat:61.7155919 },
        { lng:22.3519206	, lat:61.7155004 },
        { lng:22.351867	, lat:61.7153581 },
        { lng:22.3519421	, lat:61.7152462 },
        { lng:22.3524356	, lat:61.7151344 },
        { lng:22.3528647	, lat:61.7150962 },
        { lng:22.3533422	, lat:61.7151242 },
        { lng:22.3536801	, lat:61.7151191 },
        { lng:22.353707	, lat:61.7150505 },
        { lng:22.353487	, lat:61.7149132 },
        { lng:22.3534924	, lat:61.7147912 },
        { lng:22.3538572	, lat:61.7147531 },
        { lng:22.3540127	, lat:61.7147709 },
        { lng:22.3541951	, lat:61.7147277 },
        { lng:22.354297	, lat:61.7146768 },
        { lng:22.354399	, lat:61.7146006 },
        { lng:22.3546296	, lat:61.7145421 },
        { lng:22.3548174	, lat:61.7145751 },
        { lng:22.3549515	, lat:61.7147149 },
        { lng:22.3550695	, lat:61.7147582 },
        { lng:22.3551768	, lat:61.7146794 },
        { lng:22.3552358	, lat:61.7146056 },
        { lng:22.3553538	, lat:61.7145472 },
        { lng:22.3555255	, lat:61.7145548 },
        { lng:22.3559976	, lat:61.7147175 },
        { lng:22.3568344	, lat:61.7146972 },
        { lng:22.3570436	, lat:61.7147709 },
        { lng:22.3573118	, lat:61.7147912 },
        { lng:22.3576552	, lat:61.7149208 },
        { lng:22.3579073	, lat:61.7149793 },
        { lng:22.3582399	, lat:61.7149107 },
        { lng:22.3591332	, lat:61.7146695 },
        { lng:22.3592538	, lat:61.7145726 },
        { lng:22.3593128	, lat:61.7144328 },
        { lng:22.3597741	, lat:61.7142599 },
        { lng:22.3605627	, lat:61.7138557 },
        { lng:22.3619789	, lat:61.7135558 },
        { lng:22.3727862	, lat:61.7114244 },
        { lng:22.3766913	, lat:61.7106107 },
        { lng:22.376853	, lat:61.710577 },
        { lng:22.3811841	, lat:61.7096744 },
        { lng:22.382627	, lat:61.7093737 },
        { lng:22.3846241	, lat:61.7091231 },
        { lng:22.4064105	, lat:61.7063902 },
        { lng:22.4092429	, lat:61.7067512 },
        { lng:22.4112245	, lat:61.7063535 },
        { lng:22.4129765	, lat:61.7055205 },
        { lng:22.4771537	, lat:61.6973979 },
        { lng:22.4862491	, lat:61.6962005 },
        { lng:22.4942001	, lat:61.6950913 },
        { lng:22.526055	, lat:61.690554 },
        { lng:22.5428885	, lat:61.6881473 },
        { lng:22.5467724	, lat:61.6875978 },
        { lng:22.5511677	, lat:61.686931 },
        { lng:22.5522602	, lat:61.6867531 },
        { lng:22.5561665	, lat:61.6860698 },
        { lng:22.5960285	, lat:61.6910221 },
        { lng:22.6350693	, lat:61.6957559 },
        { lng:22.6476414	, lat:61.6966172 },
        { lng:22.6541294	, lat:61.6823005 },
        { lng:22.648915	, lat:61.6678289 },
        { lng:22.6549151	, lat:61.6541306 },
        { lng:22.6853869	, lat:61.660682 },
        { lng:22.6894416	, lat:61.6749299 },
        { lng:22.6998353	, lat:61.6757647 },
        { lng:22.7033114	, lat:61.6707146 },
        { lng:22.7163337	, lat:61.6719499 },
        { lng:22.7166581	, lat:61.6708266 },
        { lng:22.7168727	, lat:61.6706026 },
        { lng:22.7160787	, lat:61.6703277 },
        { lng:22.7156281	, lat:61.6704091 },
        { lng:22.715671	, lat:61.6706841 },
        { lng:22.714684	, lat:61.6711117 },
        { lng:22.7140617	, lat:61.6710303 },
        { lng:22.7115726	, lat:61.6702971 },
        { lng:22.7118301	, lat:61.6699611 },
        { lng:22.7117872	, lat:61.6695843 },
        { lng:22.7122807	, lat:61.6688919 },
        { lng:22.7126662	, lat:61.6687081 },
        { lng:22.7124738	, lat:61.6671913 },
        { lng:22.7127528	, lat:61.6665293 },
        { lng:22.712903	, lat:61.6659183 },
        { lng:22.7124953	, lat:61.6652665 },
        { lng:22.7130103	, lat:61.6649813 },
        { lng:22.7130532	, lat:61.6647267 },
        { lng:22.7126884	, lat:61.6642379 },
        { lng:22.7126455	, lat:61.663749 },
        { lng:22.7132678	, lat:61.6637286 },
        { lng:22.7123022	, lat:61.6628935 },
        { lng:22.7119159	, lat:61.6627611 },
        { lng:22.7119803	, lat:61.6622518 },
        { lng:22.7130532	, lat:61.6611518 },
        { lng:22.7127528	, lat:61.6610907 },
        { lng:22.7127099	, lat:61.6604897 },
        { lng:22.7124524	, lat:61.6600313 },
        { lng:22.712903	, lat:61.6597869 },
        { lng:22.7139805	, lat:61.6596922 },
        { lng:22.7135467	, lat:61.6593285 },
        { lng:22.713139	, lat:61.658371 },
        { lng:22.7127313	, lat:61.6580348 },
        { lng:22.7127099	, lat:61.6576477 },
        { lng:22.7138257	, lat:61.6565679 },
        { lng:22.7136969	, lat:61.6562419 },
        { lng:22.7138901	, lat:61.6560484 },
        { lng:22.7138471	, lat:61.6556816 },
        { lng:22.7145465	, lat:61.6543593 },
        { lng:22.7142763	, lat:61.6535371 },
        { lng:22.7155852	, lat:61.6531245 },
        { lng:22.7160039	, lat:61.6530973 },
        { lng:22.7162933	, lat:61.6529156 },
        { lng:22.7164543	, lat:61.6525437 },
        { lng:22.7163792	, lat:61.6522941 },
        { lng:22.7163899	, lat:61.6520801 },
        { lng:22.7166903	, lat:61.6518916 },
        { lng:22.7166259	, lat:61.6517032 },
        { lng:22.7171087	, lat:61.651153 },
        { lng:22.7173233	, lat:61.6512039 },
        { lng:22.7173662	, lat:61.6509747 },
        { lng:22.7194905	, lat:61.6508524 },
        { lng:22.7202523	, lat:61.6503837 },
        { lng:22.7200699	, lat:61.6501595 },
        { lng:22.7203596	, lat:61.6499812 },
        { lng:22.7209067	, lat:61.6491457 },
        { lng:22.7217543	, lat:61.6489164 },
        { lng:22.7216363	, lat:61.6484782 },
        { lng:22.7231491	, lat:61.6476427 },
        { lng:22.7237069	, lat:61.6473981 },
        { lng:22.7236855	, lat:61.6471943 },
        { lng:22.7233958	, lat:61.6469243 },
        { lng:22.7236855	, lat:61.6465217 },
        { lng:22.7235353	, lat:61.6461141 },
        { lng:22.7238035	, lat:61.6458135 },
        { lng:22.7238786	, lat:61.6456199 },
        { lng:22.7242327	, lat:61.6452071 },
        { lng:22.7249099	, lat:61.6449366 },
        { lng:22.7245337	, lat:61.6443756 },
        { lng:22.7239215	, lat:61.6441677 },
        { lng:22.7239215	, lat:61.6440556 },
        { lng:22.7237606	, lat:61.6438008 },
        { lng:22.7238006	, lat:61.6435507 },
        { lng:22.7203267	, lat:61.6401166 },
        { lng:22.7042839	, lat:61.623876 },
        { lng:22.6609159	, lat:61.641651 },
        { lng:22.6884636	, lat:61.6090193 },
        { lng:22.6960427	, lat:61.5997655 },
        { lng:22.6825753	, lat:61.5958095 },
        { lng:22.666887	, lat:61.5910463 },
        { lng:22.6458106	, lat:61.584153 },
        { lng:22.64647	, lat:61.5785023 },
        { lng:22.6445818	, lat:61.5727876 },
        { lng:22.6408189	, lat:61.5611721 },
        { lng:22.6183605	, lat:61.5507057 },
        { lng:22.6128888	, lat:61.5480683 },
        { lng:22.6027822	, lat:61.5424817 },
        { lng:22.5824939	, lat:61.5483052 },
        { lng:22.5670349	, lat:61.5422131 },
        { lng:22.5591956	, lat:61.5390476 },
        { lng:22.4341638	, lat:61.4889232 },
        { lng:22.3905565	, lat:61.5075089 },
        { lng:22.3645842	, lat:61.5186708 },
        { lng:22.3553617	, lat:61.5226233 },
        { lng:22.3416558	, lat:61.5285166 },
        { lng:22.317847	, lat:61.5388408 },
        { lng:22.2686523	, lat:61.5603715 },
        { lng:22.2419701	, lat:61.5721859 },
        { lng:22.1944082	, lat:61.5723766 },
        { lng:22.1477323	, lat:61.5727753 },
        { lng:22.1101367	, lat:61.57307 },
        { lng:22.0970773	, lat:61.5732415 },
        { lng:22.0883413	, lat:61.5703564 },
        { lng:22.0741003	, lat:61.5655522 },
        { lng:22.0603224	, lat:61.5609217 },
        { lng:22.0626469	, lat:61.5578387 },
        { lng:22.0594276	, lat:61.5510239 },
        { lng:22.0390094	, lat:61.5599424 },
        { lng:22.0318527	, lat:61.5579675 },
        { lng:22.0439801	, lat:61.5415711 },
        { lng:22.0410846	, lat:61.5414387 },
        { lng:22.0407888	, lat:61.5418488 },
        { lng:22.0398924	, lat:61.5416907 },
        { lng:22.0391842	, lat:61.5416523 },
        { lng:22.0389511	, lat:61.5418488 },
        { lng:22.0357176	, lat:61.5410523 },
        { lng:22.0343738	, lat:61.5411278 },
        { lng:22.02517	, lat:61.54757 },
        { lng:22.0252778	, lat:61.5478116 },
        { lng:22.0222035	, lat:61.548488 },
        { lng:22.0209484	, lat:61.5440123 },
        { lng:22.0226472	, lat:61.5431515 },
        { lng:22.0201054	, lat:61.5421789 },
        { lng:22.016112	, lat:61.5413543 },
        { lng:22.0155306	, lat:61.5421321 },
        { lng:22.0158729	, lat:61.5423223 },
        { lng:22.0154513	, lat:61.542496 },
        { lng:22.015388	, lat:61.5425504 },
        { lng:22.0045374	, lat:61.5376262 },
        { lng: 21.9830015	, lat:61.5474672 },
        { lng: 21.9726821	, lat:61.5454196 },
        { lng: 21.9799463	, lat:61.5436437 },
        { lng: 21.9783489	, lat:61.5426832 },
        { lng: 21.9872611	, lat:61.5393304 },
        { lng: 21.9843707	, lat:61.5374031 },
        { lng: 21.9808083	, lat:61.5386718 },
        { lng: 21.9779686	, lat:61.5404661 },
        { lng: 21.9740259	, lat:61.5387202 },
        { lng: 21.97253	, lat:61.5389316 },
        { lng: 21.9699819	, lat:61.5385933 },
        { lng: 21.9688789	, lat:61.5392699 },
        { lng: 21.968245	, lat:61.5395418 },
        { lng: 21.9680676	, lat:61.5397593 },
        { lng: 21.9686761	, lat:61.5399224 },
        { lng: 21.9687395	, lat:61.5402909 },
        { lng: 21.9683591	, lat:61.5406836 },
        { lng: 21.9678774	, lat:61.5409615 },
        { lng: 21.9672816	, lat:61.5411186 },
        { lng: 21.965456	, lat:61.5414025 },
        { lng: 21.9644718	, lat:61.541932 },
        { lng: 21.9639457	, lat:61.542521 },
        { lng: 21.9630456	, lat:61.5424183 },
        { lng: 21.9620821	, lat:61.5426327 },
        { lng: 21.9622913	, lat:61.5429015 },
        { lng: 21.9627794	, lat:61.5429106 },
        { lng: 21.9632358	, lat:61.5431975 },
        { lng: 21.96309	, lat:61.5435116 },
        { lng: 21.9607828	, lat:61.5430586 },
        { lng: 21.9355261	, lat:61.5353288 },
        { lng: 21.9065969	, lat:61.5176573 },
        { lng: 21.88411	, lat:61.5038218 },
        { lng: 21.8799049	, lat:61.4947521 },
        { lng: 21.8852279	, lat:61.4902796 },
        { lng: 21.8846614	, lat:61.4780615 },
        { lng: 21.8735354	, lat:61.4789293 },
        { lng: 21.8733327	, lat:61.4789451 },
        { lng: 21.8731445	, lat:61.4768366 },
        { lng: 21.8717519	, lat:61.4764747 },
        { lng: 21.8746538	, lat:61.4733791 },
        { lng: 21.8774779	, lat:61.4698401 },
        { lng: 21.8783848	, lat:61.4680889 },
        { lng: 21.8785014	, lat:61.467433 },
        { lng: 21.8783718	, lat:61.4666533 },
        { lng: 21.8781127	, lat:61.4658179 },
        { lng: 21.8777629	, lat:61.4651248 },
        { lng: 21.8773613	, lat:61.4645493 },
        { lng: 21.8768172	, lat:61.4641718 },
        { lng: 21.8762861	, lat:61.4639118 },
        { lng: 21.8743428	, lat:61.4628226 },
        { lng: 21.8735137	, lat:61.4623275 },
        { lng: 21.8729437	, lat:61.4619004 },
        { lng: 21.8727105	, lat:61.4615724 },
        { lng: 21.8721146	, lat:61.4607492 },
        { lng: 21.8718684	, lat:61.4601489 },
        { lng: 21.8715316	, lat:61.4592204 },
        { lng: 21.8714021	, lat:61.4586386 },
        { lng: 21.8711948	, lat:61.4581063 },
        { lng: 21.870858	, lat:61.4552402 },
        { lng: 21.8707025	, lat:61.4542683 },
        { lng: 21.8706377	, lat:61.4540826 },
        { lng: 21.8705082	, lat:61.4539031 },
        { lng: 21.8703009	, lat:61.4534697 },
        { lng: 21.8701584	, lat:61.4531664 },
        { lng: 21.8690313	, lat:61.4516743 },
        { lng: 21.8687463	, lat:61.451241 },
        { lng: 21.8679319	, lat:61.4493826 },
        { lng: 21.8674508	, lat:61.4485724 },
        { lng: 21.8673022	, lat:61.4475853 },
        { lng: 21.8670233	, lat:61.4458913 },
        { lng: 21.8669497	, lat:61.4451222 },
        { lng: 21.8677358	, lat:61.4445847 },
        { lng: 21.8688759	, lat:61.4438416 },
        { lng: 21.8700375	, lat:61.4431172 },
        { lng: 21.8703917	, lat:61.4428817 },
        { lng: 21.8272756	, lat:61.4374068 },
        { lng: 21.8136372	, lat:61.4352799 },
        { lng: 21.8118367	, lat:61.4326591 },
        { lng: 21.8070442	, lat:61.4326591 },
        { lng: 21.8069443	, lat:61.4309602 },
        { lng: 21.8029968	, lat:61.4301557 },
        { lng: 21.8066863	, lat:61.4254093 },
        { lng: 21.8067456	, lat:61.4253674 },
        { lng: 21.8063725	, lat:61.4159332 },
        { lng: 21.8092131	, lat:61.4142494 },
        { lng: 21.8111747	, lat:61.4131957 },
        { lng: 21.8110246	, lat:61.4097631 },
        { lng: 21.8104515	, lat:61.4097586 },
        { lng: 21.810185	, lat:61.4040137 },
        { lng: 21.8125253	, lat:61.4049571 },
        { lng: 21.8123078	, lat:61.3994562 },
        { lng: 21.8113715	, lat:61.3994513 },
        { lng: 21.8112544	, lat:61.3969032 },
        { lng: 21.8054559	, lat:61.3950564 },
        { lng: 21.8037132	, lat:61.3851471 },
        { lng: 21.8035012	, lat:61.3840243 },
        { lng: 21.7765015	, lat:61.3857747 },
        { lng: 21.7756392	, lat:61.3858515 },
        { lng: 21.7738493	, lat:61.3859524 },
        { lng: 21.7463056	, lat:61.3877263 },
        { lng: 21.7449443	, lat:61.3878451 },
        { lng: 21.7429519	, lat:61.387947 },
        { lng: 21.7132773	, lat:61.3898668 },
        { lng: 21.7125641	, lat:61.3902436 },
        { lng: 21.7047911	, lat:61.3950278 },
        { lng: 21.6782379	, lat:61.4113164 },
        { lng: 21.6581303	, lat:61.4236239 },
        { lng: 21.6497285	, lat:61.4250659 },
        { lng: 21.6400804	, lat:61.4239735 },
        { lng: 21.6111641	, lat:61.4207683 },
        { lng: 21.5966399	, lat:61.4191477 },
        { lng: 21.5854149	, lat:61.4179068 },
        { lng: 21.5822883	, lat:61.4174732 },
        { lng: 21.5571571	, lat:61.4234018 },
        { lng: 21.5520175	, lat:61.4246142 },
        { lng: 21.5535576	, lat:61.426176 },
        { lng: 21.5465901	, lat:61.4278582 },
        { lng: 21.5466493	, lat:61.4277944 },
        { lng: 21.5466049	, lat:61.427798 },
        { lng: 21.5464494	, lat:61.4278121 },
        { lng: 21.5463161	, lat:61.4278369 },
        { lng: 21.5462421	, lat:61.4278228 },
        { lng: 21.5462347	, lat:61.4277803 },
        { lng: 21.5462791	, lat:61.4277094 },
        { lng: 21.546442	, lat:61.4276599 },
        { lng: 21.5466419	, lat:61.4275784 },
        { lng: 21.5470047	, lat:61.4274332 },
        { lng: 21.5471306	, lat:61.4273943 },
        { lng: 21.5471602	, lat:61.4273376 },
        { lng: 21.5471084	, lat:61.4272916 },
        { lng: 21.5467234	, lat:61.4271818 },
        { lng: 21.5465234	, lat:61.4271216 },
        { lng: 21.5463828	, lat:61.4271039 },
        { lng: 21.5463309	, lat:61.4271641 },
        { lng: 21.5461902	, lat:61.4272349 },
        { lng: 21.5460348	, lat:61.4273128 },
        { lng: 21.5459015	, lat:61.4274226 },
        { lng: 21.545783	, lat:61.4275076 },
        { lng: 21.5457016	, lat:61.4275147 },
        { lng: 21.5456201	, lat:61.4275005 },
        { lng: 21.5456127	, lat:61.4274403 },
        { lng: 21.5456793	, lat:61.4273588 },
        { lng: 21.5459385	, lat:61.4271995 },
        { lng: 21.5460051	, lat:61.4271357 },
        { lng: 21.5460348	, lat:61.4269056 },
        { lng: 21.5459385	, lat:61.4268949 },
        { lng: 21.5458496	, lat:61.426941 },
        { lng: 21.545746	, lat:61.4270153 },
        { lng: 21.5454646	, lat:61.427157 },
        { lng: 21.5452721	, lat:61.4272137 },
        { lng: 21.5451018	, lat:61.4272632 },
        { lng: 21.5448352	, lat:61.4273164 },
        { lng: 21.5446575	, lat:61.427327 },
        { lng: 21.5442503	, lat:61.4273199 },
        { lng: 21.5438949	, lat:61.4272845 },
        { lng: 21.5437394	, lat:61.4272951 },
        { lng: 21.5436654	, lat:61.4273341 },
        { lng: 21.5435839	, lat:61.4273128 },
        { lng: 21.5435025	, lat:61.4272739 },
        { lng: 21.543347	, lat:61.427203 },
        { lng: 21.5431767	, lat:61.4271995 },
        { lng: 21.5429101	, lat:61.4272172 },
        { lng: 21.5426658	, lat:61.4272562 },
        { lng: 21.5425769	, lat:61.4272668 },
        { lng: 21.5424436	, lat:61.4272384 },
        { lng: 21.5422437	, lat:61.4272809 },
        { lng: 21.5419401	, lat:61.4273199 },
        { lng: 21.5415773	, lat:61.4273376 },
        { lng: 21.5413848	, lat:61.4273376 },
        { lng: 21.541059	, lat:61.4273376 },
        { lng: 21.5409332	, lat:61.4273199 },
        { lng: 21.5407406	, lat:61.4272809 },
        { lng: 21.5407406	, lat:61.4272349 },
        { lng: 21.5208302	, lat:61.4318625 },
        { lng: 21.5232466	, lat:61.4364797 },
        { lng: 21.5238772	, lat:61.4394922 },
        { lng: 21.5274717	, lat:61.4514739 },
        { lng: 21.431452	, lat:61.4747207 },
        { lng: 21.4003703	, lat:61.4996576 },
        { lng: 20.8747852	, lat:61.5303891 },
      ],
    },
    {
      id: 23,
      regionName: 'eCom TP  Porvoo',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [       
        { lng:25.3290778	, lat:59.9375507 },
        { lng:25.3375808	, lat:59.9930274 },
        { lng:25.3460836	, lat:60.0484114 },
        { lng:25.3559197	, lat:60.103 },
        { lng:25.3610749	, lat:60.1136133 },
        { lng:25.3671118	, lat:60.1260331 },
        { lng:25.3888994	, lat:60.1693656 },
        { lng:25.4109949	, lat:60.1836037 },
        { lng:25.4110207	, lat:60.1836161 },
        { lng:25.4113245	, lat:60.1838053 },
        { lng:25.4120275	, lat:60.184243 },
        { lng:25.4916951	, lat:60.2335793 },
        { lng:25.4889393	, lat:60.2403717 },
        { lng:25.5269348	, lat:60.265264 },
        { lng:25.5054318	, lat:60.2758162 },
        { lng:25.5034474	, lat:60.2757802 },
        { lng:25.5012451	, lat:60.2760031 },
        { lng:25.5016837	, lat:60.2776484 },
        { lng:25.5016267	, lat:60.2776761 },
        { lng:25.4950646	, lat:60.2808555 },
        { lng:25.4919845	, lat:60.2796836 },
        { lng:25.4805686	, lat:60.2844497 },
        { lng:25.4818014	, lat:60.2871732 },
        { lng:25.46836	, lat:60.2933007 },
        { lng:25.4671095	, lat:60.2938707 },
        { lng:25.4659008	, lat:60.2944178 },
        { lng:25.4657099	, lat:60.2945649 },
        { lng:25.4652193	, lat:60.2949426 },
        { lng:25.4651255	, lat:60.2950148 },
        { lng:25.463672	, lat:60.296135 },
        { lng:25.4636367	, lat:60.2961624 },
        { lng:25.4636167	, lat:60.2961779 },
        { lng:25.4619624	, lat:60.2974522 },
        { lng:25.4618314	, lat:60.2975531 },
        { lng:25.4616548	, lat:60.297689 },
        { lng:25.4609408	, lat:60.2982386 },
        { lng:25.4609031	, lat:60.2982652 },
        { lng:25.4607388	, lat:60.2983917 },
        { lng:25.460701	, lat:60.2984209 },
        { lng:25.4606035	, lat:60.2984959 },
        { lng:25.4604792	, lat:60.2985915 },
        { lng:25.4600371	, lat:60.2989319 },
        { lng:25.45963	, lat:60.2992451 },
        { lng:25.4592073	, lat:60.2995706 },
        { lng:25.4583778	, lat:60.300209 },
        { lng:25.4535477	, lat:60.3038683 },
        { lng:25.4498012	, lat:60.3067062 },
        { lng:25.4477577	, lat:60.3082541 },
        { lng:25.4474971	, lat:60.3084515 },
        { lng:25.4246929	, lat:60.3257196 },
        { lng:25.4242303	, lat:60.3255876 },
        { lng:25.4222838	, lat:60.3266675 },
        { lng:25.4233259	, lat:60.3269979 },
        { lng:25.4207466	, lat:60.3290541 },
        { lng:25.4184459	, lat:60.3290351 },
        { lng:25.4161452	, lat:60.33118 },
        { lng:25.4171038	, lat:60.3322049 },
        { lng:25.4153521	, lat:60.3337684 },
        { lng:25.4126504	, lat:60.3348827 },
        { lng:25.4117498	, lat:60.3360983 },
        { lng:25.4123064	, lat:60.3363573 },
        { lng:25.380871	, lat:60.3623223 },
        { lng:25.3756842	, lat:60.3666046 },
        { lng:25.3741618	, lat:60.3661167 },
        { lng:25.3730234	, lat:60.3670284 },
        { lng:25.373769	, lat:60.3682271 },
        { lng:25.3663949	, lat:60.3744305 },
        { lng:25.365174	, lat:60.3755436 },
        { lng:25.3651441	, lat:60.3784844 },
        { lng:25.3652412	, lat:60.380543 },
        { lng:25.3660336	, lat:60.380596 },
        { lng:25.3662735	, lat:60.38088 },
        { lng:25.365961	, lat:60.3810516 },
        { lng:25.3652823	, lat:60.3810735 },
        { lng:25.3654307	, lat:60.3875944 },
        { lng:25.3657495	, lat:60.3874415 },
        { lng:25.3657558	, lat:60.3878065 },
        { lng:25.3630057	, lat:60.3893471 },
        { lng:25.3628005	, lat:60.3894205 },
        { lng:25.3655659	, lat:60.3915792 },
        { lng:25.3657539	, lat:60.398862 },
        { lng:25.363438	, lat:60.4061001 },
        { lng:25.4042211	, lat:60.410303 },
        { lng:25.4055557	, lat:60.4104811 },
        { lng:25.4055211	, lat:60.4102341 },
        { lng:25.4051351	, lat:60.4096606 },
        { lng:25.4044814	, lat:60.4089265 },
        { lng:25.4032627	, lat:60.4076612 },
        { lng:25.4029408	, lat:60.4073386 },
        { lng:25.4027098	, lat:60.4070141 },
        { lng:25.4025931	, lat:60.4067466 },
        { lng:25.4024278	, lat:60.406153 },
        { lng:25.4021993	, lat:60.4055305 },
        { lng:25.4020498	, lat:60.4052033 },
        { lng:25.4018581	, lat:60.4049606 },
        { lng:25.4015246	, lat:60.4047533 },
        { lng:25.4011898	, lat:60.4045599 },
        { lng:25.4009635	, lat:60.4043294 },
        { lng:25.4008565	, lat:60.4041662 },
        { lng:25.4009116	, lat:60.4039429 },
        { lng:25.4010997	, lat:60.403655 },
        { lng:25.401437	, lat:60.403293 },
        { lng:25.4015888	, lat:60.4029827 },
        { lng:25.4018977	, lat:60.4026116 },
        { lng:25.4022493	, lat:60.4023541 },
        { lng:25.4024415	, lat:60.4022047 },
        { lng:25.4026917	, lat:60.4020926 },
        { lng:25.4031614	, lat:60.4019013 },
        { lng:25.403948	, lat:60.401684 },
        { lng:25.4053968	, lat:60.4012393 },
        { lng:25.4066305	, lat:60.4009223 },
        { lng:25.4073143	, lat:60.4007436 },
        { lng:25.4077935	, lat:60.4012623 },
        { lng:25.4077934	, lat:60.4016645 },
        { lng:25.4060367	, lat:60.4024346 },
        { lng:25.4037098	, lat:60.4025495 },
        { lng:25.403477	, lat:60.4030034 },
        { lng:25.4027267	, lat:60.4030063 },
        { lng:25.4024764	, lat:60.4034172 },
        { lng:25.4023252	, lat:60.4035838 },
        { lng:25.4021772	, lat:60.4039863 },
        { lng:25.4022354	, lat:60.4042764 },
        { lng:25.4028695	, lat:60.4050694 },
        { lng:25.4031894	, lat:60.4052877 },
        { lng:25.4033639	, lat:60.4055233 },
        { lng:25.4033756	, lat:60.4062272 },
        { lng:25.4035966	, lat:60.4065518 },
        { lng:25.403329	, lat:60.407043 },
        { lng:25.4041143	, lat:60.4072039 },
        { lng:25.4043063	, lat:60.4076664 },
        { lng:25.4049826	, lat:60.4084941 },
        { lng:25.4061903	, lat:60.4101234 },
        { lng:25.4064454	, lat:60.4102371 },
        { lng:25.4066551	, lat:60.4106088 },
        { lng:25.40712	, lat:60.4106881 },
        { lng:25.4073627	, lat:60.4102717 },
        { lng:25.4088723	, lat:60.4100665 },
        { lng:25.4088394	, lat:60.4096014 },
        { lng:25.4096127	, lat:60.4094795 },
        { lng:25.4100981	, lat:60.4098817 },
        { lng:25.4106698	, lat:60.4100279 },
        { lng:25.4103448	, lat:60.4106292 },
        { lng:25.4104724	, lat:60.4112527 },
        { lng:25.4161313	, lat:60.4121359 },
        { lng:25.4165644	, lat:60.4117795 },
        { lng:25.4171207	, lat:60.4115887 },
        { lng:25.4175368	, lat:60.4115824 },
        { lng:25.418093	, lat:60.4119598 },
        { lng:25.4185134	, lat:60.411899 },
        { lng:25.4190952	, lat:60.4123603 },
        { lng:25.4187088	, lat:60.4125511 },
        { lng:25.4268135	, lat:60.4138414 },
        { lng:25.4269967	, lat:60.4134528 },
        { lng:25.4274631	, lat:60.4132534 },
        { lng:25.4273923	, lat:60.4129717 },
        { lng:25.4277921	, lat:60.4127045 },
        { lng:25.4279586	, lat:60.4127045 },
        { lng:25.4288955	, lat:60.4126222 },
        { lng:25.4292453	, lat:60.4128648 },
        { lng:25.4293411	, lat:60.413165 },
        { lng:25.429212	, lat:60.4132596 },
        { lng:25.4292203	, lat:60.413607 },
        { lng:25.4293744	, lat:60.4137345 },
        { lng:25.4296784	, lat:60.4136687 },
        { lng:25.4300823	, lat:60.4136934 },
        { lng:25.4305486	, lat:60.4139401 },
        { lng:25.4309359	, lat:60.4140305 },
        { lng:25.4314814	, lat:60.4140079 },
        { lng:25.4317021	, lat:60.4142855 },
        { lng:25.4307152	, lat:60.4144746 },
        { lng:25.4391156	, lat:60.4158602 },
        { lng:25.440132	, lat:60.4159885 },
        { lng:25.4475661	, lat:60.4171578 },
        { lng:25.4478968	, lat:60.4173614 },
        { lng:25.4490932	, lat:60.4175106 },
        { lng:25.4492647	, lat:60.4174642 },
        { lng:25.4496077	, lat:60.4175146 },
        { lng:25.4663975	, lat:60.422148 },
        { lng:25.4672468	, lat:60.4216905 },
        { lng:25.4704295	, lat:60.4231809 },
        { lng:25.4737624	, lat:60.4241235 },
        { lng:25.4766748	, lat:60.4250867 },
        { lng:25.4769331	, lat:60.4250927 },
        { lng:25.4799656	, lat:60.42605 },
        { lng:25.4801518	, lat:60.4259077 },
        { lng:25.4816831	, lat:60.4262071 },
        { lng:25.4841572	, lat:60.426782 },
        { lng:25.485064	, lat:60.427274 },
        { lng:25.4855324	, lat:60.4274548 },
        { lng:25.4871598	, lat:60.4282609 },
        { lng:25.4881866	, lat:60.4281928 },
        { lng:25.4912072	, lat:60.4288121 },
        { lng:25.5029112	, lat:60.4356157 },
        { lng:25.5042802	, lat:60.4353343 },
        { lng:25.5053371	, lat:60.4364335 },
        { lng:25.505217	, lat:60.4369609 },
        { lng:25.5101833	, lat:60.4397932 },
        { lng:25.4993594	, lat:60.453845 },
        { lng:25.4908014	, lat:60.457101 },
        { lng:25.4905122	, lat:60.4569125 },
        { lng:25.4875663	, lat:60.458092 },
        { lng:25.4875369	, lat:60.4583699 },
        { lng:25.47852	, lat:60.4620484 },
        { lng:25.4777367	, lat:60.4619083 },
        { lng:25.4744786	, lat:60.4632377 },
        { lng:25.4749708	, lat:60.4635316 },
        { lng:25.4314816	, lat:60.4829459 },
        { lng:25.4551894	, lat:60.4829459 },
        { lng:25.4555146	, lat:60.4822493 },
        { lng:25.4588371	, lat:60.4825942 },
        { lng:25.459134	, lat:60.4829668 },
        { lng:25.4626615	, lat:60.4829564 },
        { lng:25.468034	, lat:60.4834215 },
        { lng:25.4710314	, lat:60.483728 },
        { lng:25.4722968	, lat:60.4837698 },
        { lng:25.4824128	, lat:60.4846195 },
        { lng:25.4887256	, lat:60.485337 },
        { lng:25.5060451	, lat:60.4869111 },
        { lng:25.5062218	, lat:60.4864723 },
        { lng:25.5038466	, lat:60.4861136 },
        { lng:25.5040516	, lat:60.4856887 },
        { lng:25.5058967	, lat:60.4855146 },
        { lng:25.50736	, lat:60.4856121 },
        { lng:25.5072681	, lat:60.4858907 },
        { lng:25.5113258	, lat:60.4865733 },
        { lng:25.5185937	, lat:60.4871717 },
        { lng:25.5187737	, lat:60.4880139 },
        { lng:25.5300907	, lat:60.4890677 },
        { lng:25.5310005	, lat:60.4887378 },
        { lng:25.5326002	, lat:60.4882699 },
        { lng:25.533122	, lat:60.4882358 },
        { lng:25.5340834	, lat:60.4884134 },
        { lng:25.5344298	, lat:60.4884169 },
        { lng:25.5357305	, lat:60.4879189 },
        { lng:25.5372504	, lat:60.4871458 },
        { lng:25.5369252	, lat:60.4870135 },
        { lng:25.5371373	, lat:60.486916 },
        { lng:25.5375614	, lat:60.4864076 },
        { lng:25.5385158	, lat:60.4856762 },
        { lng:25.5396256	, lat:60.4859409 },
        { lng:25.5408119	, lat:60.4863833 },
        { lng:25.5409799	, lat:60.4865488 },
        { lng:25.5410046	, lat:60.4866389 },
        { lng:25.5409453	, lat:60.4867509 },
        { lng:25.540886	, lat:60.4868312 },
        { lng:25.5407971	, lat:60.4869115 },
        { lng:25.5408317	, lat:60.4869845 },
        { lng:25.5409157	, lat:60.487043 },
        { lng:25.5410244	, lat:60.4870819 },
        { lng:25.5412566	, lat:60.4870892 },
        { lng:25.5413456	, lat:60.4869943 },
        { lng:25.5414148	, lat:60.4869553 },
        { lng:25.5415383	, lat:60.4869407 },
        { lng:25.5416865	, lat:60.4869529 },
        { lng:25.5417706	, lat:60.4869943 },
        { lng:25.541899	, lat:60.4870673 },
        { lng:25.5420473	, lat:60.4870916 },
        { lng:25.5422153	, lat:60.4870965 },
        { lng:25.5427045	, lat:60.4871306 },
        { lng:25.5427588	, lat:60.4869432 },
        { lng:25.5429831	, lat:60.4868314 },
        { lng:25.5451395	, lat:60.4866667 },
        { lng:25.5463065	, lat:60.4868463 },
        { lng:25.5466126	, lat:60.4863301 },
        { lng:25.5471837	, lat:60.4863509 },
        { lng:25.5484472	, lat:60.4863909 },
        { lng:25.5483566	, lat:60.4867433 },
        { lng:25.5486093	, lat:60.4868857 },
        { lng:25.5499707	, lat:60.4874401 },
        { lng:25.5507016	, lat:60.4871686 },
        { lng:25.5513115	, lat:60.4871484 },
        { lng:25.5516953	, lat:60.48728 },
        { lng:25.5513663	, lat:60.4877391 },
        { lng:25.5511607	, lat:60.4881881 },
        { lng:25.5520311	, lat:60.4883232 },
        { lng:25.5562108	, lat:60.4894735 },
        { lng:25.5563444	, lat:60.4892271 },
        { lng:25.5571343	, lat:60.4887169 },
        { lng:25.5572197	, lat:60.4885291 },
        { lng:25.5592432	, lat:60.4887308 },
        { lng:25.5596368	, lat:60.489719 },
        { lng:25.5593113	, lat:60.4902254 },
        { lng:25.5585746	, lat:60.4905174 },
        { lng:25.5578413	, lat:60.4907402 },
        { lng:25.5570258	, lat:60.490622 },
        { lng:25.556827	, lat:60.4907368 },
        { lng:25.555477	, lat:60.4912752 },
        { lng:25.5660015	, lat:60.4921912 },
        { lng:25.5667429	, lat:60.4916518 },
        { lng:25.5688702	, lat:60.4908928 },
        { lng:25.5709636	, lat:60.4909931 },
        { lng:25.5704839	, lat:60.4925755 },
        { lng:25.5829727	, lat:60.4936653 },
        { lng:25.5832779	, lat:60.4944529 },
        { lng:25.5839612	, lat:60.4949231 },
        { lng:25.5840969	, lat:60.4953598 },
        { lng:25.5843925	, lat:60.4957488 },
        { lng:25.5844991	, lat:60.4959994 },
        { lng:25.5849505	, lat:60.4964624 },
        { lng:25.585501	, lat:60.4965181 },
        { lng:25.5856949	, lat:60.4991704 },
        { lng:25.586322	, lat:60.499275 },
        { lng:25.5876858	, lat:60.4992699 },
        { lng:25.5880729	, lat:60.4981208 },
        { lng:25.5886349	, lat:60.4966528 },
        { lng:25.5887034	, lat:60.4964233 },
        { lng:25.5887582	, lat:60.4962444 },
        { lng:25.5888131	, lat:60.4961027 },
        { lng:25.5889159	, lat:60.4959677 },
        { lng:25.5891009	, lat:60.4957483 },
        { lng:25.5892722	, lat:60.4955896 },
        { lng:25.5893716	, lat:60.4954158 },
        { lng:25.5895703	, lat:60.4949281 },
        { lng:25.5896834	, lat:60.4946328 },
        { lng:25.5897177	, lat:60.4944877 },
        { lng:25.5897005	, lat:60.4943831 },
        { lng:25.5896286	, lat:60.4941907 },
        { lng:25.5896252	, lat:60.4940557 },
        { lng:25.5896423	, lat:60.4939257 },
        { lng:25.5897211	, lat:60.493811 },
        { lng:25.5898582	, lat:60.4936557 },
        { lng:25.5899335	, lat:60.4934988 },
        { lng:25.5899918	, lat:60.493394 },
        { lng:25.5900398	, lat:60.4932456 },
        { lng:25.5900946	, lat:60.49306 },
        { lng:25.5902145	, lat:60.4928136 },
        { lng:25.5902556	, lat:60.4926617 },
        { lng:25.5903276	, lat:60.4923916 },
        { lng:25.5904372	, lat:60.4921385 },
        { lng:25.590612	, lat:60.4918835 },
        { lng:25.5908587	, lat:60.4916322 },
        { lng:25.5911568	, lat:60.4912913 },
        { lng:25.5913898	, lat:60.4911225 },
        { lng:25.5916571	, lat:60.490952 },
        { lng:25.5918455	, lat:60.4907849 },
        { lng:25.5920374	, lat:60.4905384 },
        { lng:25.592219	, lat:60.4903562 },
        { lng:25.5924623	, lat:60.4901318 },
        { lng:25.5926131	, lat:60.4899579 },
        { lng:25.5929249	, lat:60.4893098 },
        { lng:25.593038	, lat:60.4890616 },
        { lng:25.5931716	, lat:60.4886751 },
        { lng:25.5934765	, lat:60.4878464 },
        { lng:25.5935999	, lat:60.4875679 },
        { lng:25.5937781	, lat:60.4872708 },
        { lng:25.5939665	, lat:60.4870276 },
        { lng:25.5941893	, lat:60.4867273 },
        { lng:25.5944463	, lat:60.4864386 },
        { lng:25.5946655	, lat:60.4862681 },
        { lng:25.5950904	, lat:60.4860183 },
        { lng:25.5954879	, lat:60.485728 },
        { lng:25.5958648	, lat:60.4855085 },
        { lng:25.5965536	, lat:60.4851118 },
        { lng:25.5970847	, lat:60.484759 },
        { lng:25.5983422	, lat:60.4838812 },
        { lng:25.5985889	, lat:60.4836903 },
        { lng:25.5988836	, lat:60.4834018 },
        { lng:25.5991714	, lat:60.4830843 },
        { lng:25.5994078	, lat:60.4827957 },
        { lng:25.5995483	, lat:60.4826066 },
        { lng:25.5996306	, lat:60.4824057 },
        { lng:25.5996545	, lat:60.4821778 },
        { lng:25.5996751	, lat:60.4817406 },
        { lng:25.5996751	, lat:60.4815397 },
        { lng:25.5995894	, lat:60.4811379 },
        { lng:25.5995483	, lat:60.4807867 },
        { lng:25.5995141	, lat:60.4805098 },
        { lng:25.5995312	, lat:60.4802633 },
        { lng:25.5995792	, lat:60.4800118 },
        { lng:25.5996511	, lat:60.4797957 },
        { lng:25.5997745	, lat:60.4795035 },
        { lng:25.6000452	, lat:60.4790072 },
        { lng:25.6002233	, lat:60.4787725 },
        { lng:25.6003707	, lat:60.4785767 },
        { lng:25.6006859	, lat:60.4782694 },
        { lng:25.6009875	, lat:60.4779552 },
        { lng:25.6017344	, lat:60.4770858 },
        { lng:25.6027727	, lat:60.475995 },
        { lng:25.6033757	, lat:60.4754631 },
        { lng:25.6040747	, lat:60.474786 },
        { lng:25.6046333	, lat:60.4743216 },
        { lng:25.604993	, lat:60.4740479 },
        { lng:25.605507	, lat:60.4735786 },
        { lng:25.6056509	, lat:60.4734249 },
        { lng:25.6060724	, lat:60.4726194 },
        { lng:25.6061238	, lat:60.4725637 },
        { lng:25.6062163	, lat:60.4725265 },
        { lng:25.606326	, lat:60.4724978 },
        { lng:25.6064253	, lat:60.472459 },
        { lng:25.606463	, lat:60.4723813 },
        { lng:25.6064459	, lat:60.4722833 },
        { lng:25.606415	, lat:60.4721955 },
        { lng:25.606439	, lat:60.4720638 },
        { lng:25.6064664	, lat:60.4715285 },
        { lng:25.6064185	, lat:60.4712312 },
        { lng:25.606439	, lat:60.4710573 },
        { lng:25.6066069	, lat:60.4707314 },
        { lng:25.6067166	, lat:60.4706267 },
        { lng:25.6068982	, lat:60.4704476 },
        { lng:25.607001	, lat:60.470272 },
        { lng:25.6071175	, lat:60.4701183 },
        { lng:25.6072065	, lat:60.4699069 },
        { lng:25.6526064	, lat:60.5017297 },
        { lng:25.655463	, lat:60.4989629 },
        { lng:25.6558494	, lat:60.4988521 },
        { lng:25.6557385	, lat:60.49868 },
        { lng:25.6587995	, lat:60.4956786 },
        { lng:25.6592363	, lat:60.4957845 },
        { lng:25.6614204	, lat:60.4946427 },
        { lng:25.660493	, lat:60.4942091 },
        { lng:25.6605386	, lat:60.4939454 },
        { lng:25.6748982	, lat:60.4895485 },
        { lng:25.675429	, lat:60.4892026 },
        { lng:25.6741321	, lat:60.4886382 },
        { lng:25.6768086	, lat:60.4869323 },
        { lng:25.678119	, lat:60.4875182 },
        { lng:25.6863593	, lat:60.48236 },
        { lng:25.6869723	, lat:60.4824747 },
        { lng:25.6871339	, lat:60.4825613 },
        { lng:25.6872384	, lat:60.4826129 },
        { lng:25.687362	, lat:60.4826409 },
        { lng:25.6875568	, lat:60.4826386 },
        { lng:25.6877278	, lat:60.4825941 },
        { lng:25.6879844	, lat:60.4825684 },
        { lng:25.6882173	, lat:60.4824911 },
        { lng:25.6883218	, lat:60.4825005 },
        { lng:25.6886402	, lat:60.4825684 },
        { lng:25.688778	, lat:60.4825731 },
        { lng:25.689429	, lat:60.4825145 },
        { lng:25.6927267	, lat:60.4825941 },
        { lng:25.6937151	, lat:60.4827065 },
        { lng:25.6945942	, lat:60.4823155 },
        { lng:25.695421	, lat:60.482456 },
        { lng:25.695977	, lat:60.4829406 },
        { lng:25.6976448	, lat:60.4831279 },
        { lng:25.6989281	, lat:60.4833972 },
        { lng:25.7004983	, lat:60.4831845 },
        { lng:25.7020495	, lat:60.483081 },
        { lng:25.7027652	, lat:60.4828244 },
        { lng:25.7044545	, lat:60.4819293 },
        { lng:25.704426	, lat:60.4815102 },
        { lng:25.7055522	, lat:60.4808804 },
        { lng:25.705785	, lat:60.480843 },
        { lng:25.70636	, lat:60.4807798 },
        { lng:25.7071535	, lat:60.4806206 },
        { lng:25.7076857	, lat:60.4805269 },
        { lng:25.7079376	, lat:60.4805456 },
        { lng:25.7085458	, lat:60.4806112 },
        { lng:25.708945	, lat:60.4806487 },
        { lng:25.7099476	, lat:60.4806229 },
        { lng:25.7106319	, lat:60.4805878 },
        { lng:25.710955	, lat:60.4805293 },
        { lng:25.7113066	, lat:60.4804286 },
        { lng:25.7119576	, lat:60.4802834 },
        { lng:25.7134715	, lat:60.4799016 },
        { lng:25.7217609	, lat:60.4809969 },
        { lng:25.7220745	, lat:60.4811912 },
        { lng:25.7223026	, lat:60.4814956 },
        { lng:25.7224024	, lat:60.4816782 },
        { lng:25.7224071	, lat:60.4818678 },
        { lng:25.7225639	, lat:60.4823899 },
        { lng:25.7226305	, lat:60.4826474 },
        { lng:25.7228586	, lat:60.4830103 },
        { lng:25.7236711	, lat:60.4838086 },
        { lng:25.7239847	, lat:60.4840661 },
        { lng:25.7240322	, lat:60.4841902 },
        { lng:25.7242366	, lat:60.4848129 },
        { lng:25.7244266	, lat:60.4851571 },
        { lng:25.7249416	, lat:60.4859175 },
        { lng:25.7258018	, lat:60.4859076 },
        { lng:25.7270699	, lat:60.4887479 },
        { lng:25.7271338	, lat:60.4890028 },
        { lng:25.7270565	, lat:60.4890292 },
        { lng:25.7320388	, lat:60.4942994 },
        { lng:25.7367569	, lat:60.499291 },
        { lng:25.7371669	, lat:60.4998999 },
        { lng:25.7373281	, lat:60.5003052 },
        { lng:25.7373046	, lat:60.5005634 },
        { lng:25.7350937	, lat:60.5015279 },
        { lng:25.7352617	, lat:60.5016338 },
        { lng:25.732312	, lat:60.5031659 },
        { lng:25.7481022	, lat:60.5146189 },
        { lng:25.7529348	, lat:60.517395 },
        { lng:25.7541798	, lat:60.5182556 },
        { lng:25.7603571	, lat:60.5166957 },
        { lng:25.7610939	, lat:60.5170539 },
        { lng:25.7614203	, lat:60.5173846 },
        { lng:25.7615931	, lat:60.5177626 },
        { lng:25.7617179	, lat:60.5179256 },
        { lng:25.7619051	, lat:60.518039 },
        { lng:25.7621163	, lat:60.5180839 },
        { lng:25.7623707	, lat:60.5180839 },
        { lng:25.7627883	, lat:60.5181028 },
        { lng:25.7630331	, lat:60.5181878 },
        { lng:25.7631675	, lat:60.5183296 },
        { lng:25.7632059	, lat:60.5184264 },
        { lng:25.7635227	, lat:60.5188753 },
        { lng:25.7636331	, lat:60.519017 },
        { lng:25.7635755	, lat:60.5192603 },
        { lng:25.7635659	, lat:60.5194281 },
        { lng:25.7636235	, lat:60.5195084 },
        { lng:25.7637819	, lat:60.5196477 },
        { lng:25.7640652	, lat:60.5198344 },
        { lng:25.7641948	, lat:60.5198745 },
        { lng:25.76431	, lat:60.5199194 },
        { lng:25.7644972	, lat:60.5200446 },
        { lng:25.76455	, lat:60.5201391 },
        { lng:25.7645788	, lat:60.5202525 },
        { lng:25.7644636	, lat:60.5204037 },
        { lng:25.7643964	, lat:60.5205005 },
        { lng:25.7642284	, lat:60.5205619 },
        { lng:25.7639932	, lat:60.5206092 },
        { lng:25.7635995	, lat:60.5206446 },
        { lng:25.7634699	, lat:60.5206895 },
        { lng:25.7633931	, lat:60.5207533 },
        { lng:25.7633211	, lat:60.52081 },
        { lng:25.7633259	, lat:60.5209092 },
        { lng:25.7633931	, lat:60.521006 },
        { lng:25.7635275	, lat:60.5211076 },
        { lng:25.7638155	, lat:60.5214761 },
        { lng:25.7639308	, lat:60.5216225 },
        { lng:25.763974	, lat:60.5217217 },
        { lng:25.7641228	, lat:60.5218068 },
        { lng:25.7643052	, lat:60.5218682 },
        { lng:25.764406	, lat:60.5219414 },
        { lng:25.7647468	, lat:60.5223997 },
        { lng:25.764838	, lat:60.5226241 },
        { lng:25.7649244	, lat:60.5228319 },
        { lng:25.7650444	, lat:60.5229051 },
        { lng:25.7653564	, lat:60.5230303 },
        { lng:25.7666188	, lat:60.524209 },
        { lng:25.7671372	, lat:60.5246648 },
        { lng:25.7671996	, lat:60.5247994 },
        { lng:25.7672572	, lat:60.5250144 },
        { lng:25.7673052	, lat:60.525201 },
        { lng:25.7673628	, lat:60.5253025 },
        { lng:25.76743	, lat:60.5253781 },
        { lng:25.7675741	, lat:60.5254277 },
        { lng:25.7677709	, lat:60.525482 },
        { lng:25.7678957	, lat:60.5255222 },
        { lng:25.7680061	, lat:60.5256048 },
        { lng:25.7680589	, lat:60.5257158 },
        { lng:25.7681069	, lat:60.5258859 },
        { lng:25.7680875	, lat:60.5267043 },
        { lng:25.7681556	, lat:60.5270478 },
        { lng:25.7681442	, lat:60.5271695 },
        { lng:25.7679874	, lat:60.5274618 },
        { lng:25.7683105	, lat:60.5274782 },
        { lng:25.7750153	, lat:60.5274384 },
        { lng:25.7750343	, lat:60.5274805 },
        { lng:25.7684958	, lat:60.5275693 },
        { lng:25.7681965	, lat:60.5276582 },
        { lng:25.7678876	, lat:60.5276558 },
        { lng:25.7677165	, lat:60.5279005 },
        { lng:25.7676737	, lat:60.5280385 },
        { lng:25.7676404	, lat:60.5282372 },
        { lng:25.7675644	, lat:60.5283681 },
        { lng:25.7674599	, lat:60.528464 },
        { lng:25.7674551	, lat:60.5285809 },
        { lng:25.7673553	, lat:60.5287048 },
        { lng:25.7671035	, lat:60.5287913 },
        { lng:25.7669277	, lat:60.5288731 },
        { lng:25.7668089	, lat:60.5289198 },
        { lng:25.7667471	, lat:60.5289736 },
        { lng:25.7667328	, lat:60.5290297 },
        { lng:25.7667661	, lat:60.5290928 },
        { lng:25.7668801	, lat:60.5291256 },
        { lng:25.7670214	, lat:60.5291685 },
        { lng:25.7673506	, lat:60.5293196 },
        { lng:25.7675406	, lat:60.5293851 },
        { lng:25.7676024	, lat:60.5294459 },
        { lng:25.7675644	, lat:60.5294903 },
        { lng:25.7674456	, lat:60.5295627 },
        { lng:25.7673078	, lat:60.529682 },
        { lng:25.7672175	, lat:60.5297615 },
        { lng:25.767208	, lat:60.5298479 },
        { lng:25.7672936	, lat:60.5299228 },
        { lng:25.7676404	, lat:60.5301332 },
        { lng:25.767688	, lat:60.5301963 },
        { lng:25.767707	, lat:60.5302921 },
        { lng:25.7676642	, lat:60.5303739 },
        { lng:25.7674979	, lat:60.5304768 },
        { lng:25.7672698	, lat:60.5305376 },
        { lng:25.767094	, lat:60.530561 },
        { lng:25.7667281	, lat:60.530575 },
        { lng:25.7665713	, lat:60.5306054 },
        { lng:25.7663289	, lat:60.5307199 },
        { lng:25.7661816	, lat:60.5308695 },
        { lng:25.7655829	, lat:60.5316222 },
        { lng:25.7655306	, lat:60.5317835 },
        { lng:25.7654499	, lat:60.5320243 },
        { lng:25.7653738	, lat:60.5321552 },
        { lng:25.7652883	, lat:60.5322043 },
        { lng:25.765103	, lat:60.5322698 },
        { lng:25.7646516	, lat:60.5323142 },
        { lng:25.764528	, lat:60.5323399 },
        { lng:25.764414	, lat:60.5323679 },
        { lng:25.7643189	, lat:60.5324965 },
        { lng:25.7639863	, lat:60.5330365 },
        { lng:25.7638628	, lat:60.5331417 },
        { lng:25.7636727	, lat:60.5332048 },
        { lng:25.7627508	, lat:60.5333894 },
        { lng:25.7622186	, lat:60.5333731 },
        { lng:25.7620381	, lat:60.5333871 },
        { lng:25.761848	, lat:60.5334432 },
        { lng:25.7616864	, lat:60.5335367 },
        { lng:25.7616104	, lat:60.5336162 },
        { lng:25.7615629	, lat:60.533726 },
        { lng:25.7614726	, lat:60.5338032 },
        { lng:25.7611162	, lat:60.5339154 },
        { lng:25.7610402	, lat:60.5339574 },
        { lng:25.7610069	, lat:60.5340018 },
        { lng:25.7610449	, lat:60.534065 },
        { lng:25.7611162	, lat:60.5341094 },
        { lng:25.7613681	, lat:60.5342473 },
        { lng:25.7614678	, lat:60.5343338 },
        { lng:25.7614774	, lat:60.5344156 },
        { lng:25.7614631	, lat:60.5344834 },
        { lng:25.7613871	, lat:60.5345441 },
        { lng:25.761292	, lat:60.5345885 },
        { lng:25.7608691	, lat:60.5346587 },
        { lng:25.7603512	, lat:60.5347732 },
        { lng:25.7602371	, lat:60.5348176 },
        { lng:25.7602039	, lat:60.5348854 },
        { lng:25.7602514	, lat:60.5349976 },
        { lng:25.7604177	, lat:60.5352337 },
        { lng:25.7604415	, lat:60.5353178 },
        { lng:25.7604272	, lat:60.53543 },
        { lng:25.7603844	, lat:60.5355001 },
        { lng:25.7598998	, lat:60.5356217 },
        { lng:25.7597382	, lat:60.5356731 },
        { lng:25.7594721	, lat:60.5357245 },
        { lng:25.7593581	, lat:60.5357596 },
        { lng:25.7592963	, lat:60.5358624 },
        { lng:25.7593485	, lat:60.5359769 },
        { lng:25.7593295	, lat:60.5360611 },
        { lng:25.7593295	, lat:60.5361639 },
        { lng:25.7592868	, lat:60.5362223 },
        { lng:25.7591727	, lat:60.5362925 },
        { lng:25.7588639	, lat:60.5363696 },
        { lng:25.7587688	, lat:60.536435 },
        { lng:25.7587308	, lat:60.5364935 },
        { lng:25.7587546	, lat:60.5365776 },
        { lng:25.7588354	, lat:60.5366384 },
        { lng:25.7589304	, lat:60.5366875 },
        { lng:25.7590159	, lat:60.5368113 },
        { lng:25.7590254	, lat:60.5368604 },
        { lng:25.7589779	, lat:60.5369446 },
        { lng:25.758612	, lat:60.5372344 },
        { lng:25.758479	, lat:60.5373559 },
        { lng:25.7582556	, lat:60.537842 },
        { lng:25.7581416	, lat:60.5379589 },
        { lng:25.7578945	, lat:60.5381085 },
        { lng:25.7576141	, lat:60.5383328 },
        { lng:25.7574953	, lat:60.5384287 },
        { lng:25.7575096	, lat:60.5385058 },
        { lng:25.7575524	, lat:60.5385829 },
        { lng:25.7576759	, lat:60.5386367 },
        { lng:25.7578897	, lat:60.5387021 },
        { lng:25.7580159	, lat:60.5387723 },
        { lng:25.7580824	, lat:60.5388704 },
        { lng:25.7580919	, lat:60.5389499 },
        { lng:25.7580301	, lat:60.5390434 },
        { lng:25.7579161	, lat:60.5391275 },
        { lng:25.7578686	, lat:60.5391883 },
        { lng:25.7578733	, lat:60.5392233 },
        { lng:25.7578876	, lat:60.5392771 },
        { lng:25.7579636	, lat:60.5393192 },
        { lng:25.7582297	, lat:60.5394103 },
        { lng:25.7584578	, lat:60.5395038 },
        { lng:25.7585386	, lat:60.5395505 },
        { lng:25.7585528	, lat:60.5396206 },
        { lng:25.7585101	, lat:60.5396534 },
        { lng:25.7584435	, lat:60.5397258 },
        { lng:25.7582297	, lat:60.5397959 },
        { lng:25.7580682	, lat:60.5398777 },
        { lng:25.7579446	, lat:60.5399548 },
        { lng:25.7579256	, lat:60.5399946 },
        { lng:25.7579399	, lat:60.5400366 },
        { lng:25.7580016	, lat:60.540074 },
        { lng:25.7581394	, lat:60.5400974 },
        { lng:25.7583865	, lat:60.5401138 },
        { lng:25.7586479	, lat:60.5401792 },
        { lng:25.7587809	, lat:60.5402657 },
        { lng:25.7588997	, lat:60.5403545 },
        { lng:25.7590043	, lat:60.5405157 },
        { lng:25.759028	, lat:60.5406279 },
        { lng:25.759104	, lat:60.5407424 },
        { lng:25.759085	, lat:60.5408359 },
        { lng:25.7590565	, lat:60.5409107 },
        { lng:25.7589662	, lat:60.5409761 },
        { lng:25.7586716	, lat:60.5411327 },
        { lng:25.7586241	, lat:60.5411794 },
        { lng:25.7586289	, lat:60.5412332 },
        { lng:25.7586764	, lat:60.5413079 },
        { lng:25.7586954	, lat:60.5413477 },
        { lng:25.7586289	, lat:60.5413897 },
        { lng:25.7584958	, lat:60.5414038 },
        { lng:25.758434	, lat:60.5414458 },
        { lng:25.7583913	, lat:60.5415042 },
        { lng:25.7584435	, lat:60.541579 },
        { lng:25.7584008	, lat:60.5416351 },
        { lng:25.7583628	, lat:60.5416655 },
        { lng:25.7581679	, lat:60.5417426 },
        { lng:25.7580206	, lat:60.5418314 },
        { lng:25.757555	, lat:60.541988 },
        { lng:25.8016012	, lat:60.5478639 },
        { lng:25.802874	, lat:60.5480789 },
        { lng:25.8175561	, lat:60.5501559 },
        { lng:25.8232078	, lat:60.5510037 },
        { lng:25.8256962	, lat:60.5487694 },
        { lng:25.8250161	, lat:60.5365727 },
        { lng:25.8169639	, lat:60.5221584 },
        { lng:25.8084371	, lat:60.5046139 },
        { lng:25.8045607	, lat:60.4953725 },
        { lng:25.8010104	, lat:60.4869066 },
        { lng:25.8062653	, lat:60.4825218 },
        { lng:25.8089207	, lat:60.480154 },
        { lng:25.812441	, lat:60.4773677 },
        { lng:25.817104	, lat:60.4734754 },
        { lng:25.8448998	, lat:60.4596722 },
        { lng:25.8452417	, lat:60.4595024 },
        { lng:25.8553829	, lat:60.4544648 },
        { lng:25.8695438	, lat:60.4427374 },
        { lng:25.9065781	, lat:60.4369462 },
        { lng:25.9223685	, lat:60.4318252 },
        { lng:25.9467948	, lat:60.4080886 },
        { lng:25.9492498	, lat:60.392762 },
        { lng:25.9501015	, lat:60.387442 },
        { lng:25.9348393	, lat:60.3657666 },
        { lng:25.9305354	, lat:60.3588938 },
        { lng:25.9295207	, lat:60.3576844 },
        { lng:25.9266665	, lat:60.3554103 },
        { lng:25.9226962	, lat:60.3476 },
        { lng:25.9229655	, lat:60.3343662 },
        { lng:25.920318	, lat:60.3194479 },
        { lng:25.9182944	, lat:60.3133969 },
        { lng:25.9188501	, lat:60.2902199 },
        { lng:25.9269173	, lat:60.2822285 },
        { lng:25.9279443	, lat:60.2785356 },
        { lng:25.9304579	, lat:60.275392 },
        { lng:25.9310499	, lat:60.2707694 },
        { lng:25.931031	, lat:60.2684443 },
        { lng:25.9327067	, lat:60.2664927 },
        { lng:25.9431189	, lat:60.263677 },
        { lng:25.9471084	, lat:60.2592438 },
        { lng:25.948646	, lat:60.2579656 },
        { lng:25.9598362	, lat:60.2563672 },
        { lng:25.9614623	, lat:60.2536117 },
        { lng:25.9550244	, lat:60.2509978 },
        { lng:25.9445398	, lat:60.2483651 },
        { lng:25.9408519	, lat:60.2486492 },
        { lng:25.9386618	, lat:60.248048 },
        { lng:25.9310663	, lat:60.2481999 },
        { lng:25.9250485	, lat:60.2471825 },
        { lng:25.9227119	, lat:60.2477573 },
        { lng:25.9238814	, lat:60.2445606 },
        { lng:25.9281118	, lat:60.2436504 },
        { lng:25.9281019	, lat:60.2435911 },
        { lng:25.9282517	, lat:60.2435316 },
        { lng:25.9303447	, lat:60.239655 },
        { lng:25.9388888	, lat:60.2406845 },
        { lng:25.9452628	, lat:60.2401163 },
        { lng:25.9453926	, lat:60.2400155 },
        { lng:25.9458154	, lat:60.2399792 },
        { lng:25.9459918	, lat:60.2400486 },
        { lng:25.9661786	, lat:60.238241 },
        { lng:25.9754584	, lat:60.2391316 },
        { lng:25.9853587	, lat:60.2407299 },
        { lng:25.9830286	, lat:60.2469909 },
        { lng:26.0554903	, lat:60.260206 },
        { lng:26.0804341	, lat:60.2354994 },
        { lng:26.1122427	, lat:60.2037748 },
        { lng:26.1440512	, lat:60.1720196 },
        { lng:26.1758598	, lat:60.1402337 },
        { lng:26.2076683	, lat:60.1084171 },
        { lng:26.2394769	, lat:60.0765697 },
        { lng:26.2712854	, lat:60.0446916 },
        { lng:26.1885563	, lat:60.0382203 },
        { lng:26.0750701	, lat:60.0140663 },
        { lng:26.0173046	, lat:59.9767968 },
        { lng:25.9180552	, lat:59.9656036 },
        { lng:25.8188059	, lat:59.9544065 },
        { lng:25.7195565	, lat:59.9432056 },
        { lng:25.6203073	, lat:59.9320008 },
        { lng:25.5826893	, lat:59.9281996 },
        { lng:25.4712671	, lat:59.9313133 },
        { lng:25.413615	, lat:59.9344339 },
        { lng:25.3290778	, lat:59.9375507 },
      ],
    },
    {
      id: 24,
      regionName: 'eCom TP  Rauma',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [    
        { lng: 20.773152	, lat:61.1335299 },
        { lng: 20.9345321	, lat:61.1237107 },
        { lng: 21.090302	, lat:61.1140971 },
        { lng: 21.2720914	, lat:61.1026619 },
        { lng: 21.3159508	, lat:61.1035765 },
        { lng: 21.3398461	, lat:61.0872744 },
        { lng: 21.345159	, lat:61.0860222 },
        { lng: 21.3488927	, lat:61.0814788 },
        { lng: 21.3630526	, lat:61.0781001 },
        { lng: 21.3709211	, lat:61.0745052 },
        { lng: 21.3739724	, lat:61.0737611 },
        { lng: 21.3783154	, lat:61.0750449 },
        { lng: 21.3893833	, lat:61.0701875 },
        { lng: 21.3888747	, lat:61.0660612 },
        { lng: 21.4171002	, lat:61.0551668 },
        { lng: 21.4180829	, lat:61.0524209 },
        { lng: 21.4199468	, lat:61.0474296 },
        { lng: 21.4201128	, lat:61.0469851 },
        { lng: 21.4203349	, lat:61.0463671 },
        { lng: 21.4204519	, lat:61.0461261 },
        { lng: 21.4222876	, lat:61.0462824 },
        { lng: 21.4233722	, lat:61.0434521 },
        { lng: 21.4214647	, lat:61.0432932 },
        { lng: 21.4271788	, lat:61.0273883 },
        { lng: 21.4466769	, lat:61.0186066 },
        { lng: 21.4490839	, lat:61.0175224 },
        { lng: 21.4534677	, lat:61.0162487 },
        { lng: 21.4536093	, lat:61.0159716 },
        { lng: 21.4539398	, lat:61.0158021 },
        { lng: 21.4548163	, lat:61.0157808 },
        { lng: 21.4550931	, lat:61.0158749 },
        { lng: 21.4548109	, lat:61.0161946 },
        { lng: 21.4548002	, lat:61.0165643 },
        { lng: 21.4604865	, lat:61.0152016 },
        { lng: 21.461568	, lat:61.0143708 },
        { lng: 21.4632417	, lat:61.0154585 },
        { lng: 21.466853	, lat:61.0134407 },
        { lng: 21.4656921	, lat:61.0122069 },
        { lng: 21.4658518	, lat:61.0121536 },
        { lng: 21.4704605	, lat:61.0106169 },
        { lng: 21.4747129	, lat:61.0091989 },
        { lng: 21.4776172	, lat:61.0090117 },
        { lng: 21.4768866	, lat:61.0078802 },
        { lng: 21.4792485	, lat:61.006458 },
        { lng: 21.4798102	, lat:61.0061199 },
        { lng: 21.479881	, lat:61.0053918 },
        { lng: 21.4818444	, lat:61.0037858 },
        { lng: 21.4811578	, lat:61.0033718 },
        { lng: 21.4799593	, lat:61.0033656 },
        { lng: 21.4799387	, lat:61.0032791 },
        { lng: 21.4802522	, lat:60.9994787 },
        { lng: 21.5276415	, lat:61.0051094 },
        { lng: 21.5505132	, lat:61.0047984 },
        { lng: 21.5661174	, lat:61.0091776 },
        { lng: 21.5663415	, lat:61.0092405 },
        { lng: 21.5992801	, lat:61.0095301 },
        { lng: 21.5997189	, lat:61.0090621 },
        { lng: 21.6010278	, lat:61.0089051 },
        { lng: 21.6029622	, lat:61.0069015 },
        { lng: 21.6034375	, lat:61.0069259 },
        { lng: 21.603886	, lat:61.007824 },
        { lng: 21.603459	, lat:61.0095421 },
        { lng: 21.6059545	, lat:61.0096008 },
        { lng: 21.6056283	, lat:61.0101889 },
        { lng: 21.6055768	, lat:61.0104151 },
        { lng: 21.6069812	, lat:61.0106928 },
        { lng: 21.6060607	, lat:61.011805 },
        { lng: 21.6012549	, lat:61.0172843 },
        { lng: 21.5997082	, lat:61.0190476 },
        { lng: 21.5911482	, lat:61.0288179 },
        { lng: 21.5912302	, lat:61.0289398 },
        { lng: 21.5915248	, lat:61.0293254 },
        { lng: 21.5913536	, lat:61.029679 },
        { lng: 21.5914506	, lat:61.0297882 },
        { lng: 21.5916894	, lat:61.0300269 },
        { lng: 21.6071953	, lat:61.033777 },
        { lng: 21.6074775	, lat:61.0354467 },
        { lng: 21.6080311	, lat:61.0382628 },
        { lng: 21.6082123	, lat:61.0394624 },
        { lng: 21.6084672	, lat:61.0411507 },
        { lng: 21.6090305	, lat:61.0414717 },
        { lng: 21.6114509	, lat:61.0427457 },
        { lng: 21.6113795	, lat:61.0428174 },
        { lng: 21.6151454	, lat:61.0461422 },
        { lng: 21.6157639	, lat:61.046735 },
        { lng: 21.6201648	, lat:61.0506091 },
        { lng: 21.6211964	, lat:61.0517526 },
        { lng: 21.6215821	, lat:61.0516389 },
        { lng: 21.6285237	, lat:61.0436609 },
        { lng: 21.6340404	, lat:61.0374173 },
        { lng: 21.6345468	, lat:61.0367731 },
        { lng: 21.6366937	, lat:61.0373913 },
        { lng: 21.6367806	, lat:61.0379337 },
        { lng: 21.6377773	, lat:61.0385088 },
        { lng: 21.6385423	, lat:61.0373625 },
        { lng: 21.6365317	, lat:61.0371274 },
        { lng: 21.635947	, lat:61.0369487 },
        { lng: 21.6348633	, lat:61.036439 },
        { lng: 21.638377	, lat:61.032325 },
        { lng: 21.6514383	, lat:61.0173264 },
        { lng: 21.6514587	, lat:61.0172642 },
        { lng: 21.6521722	, lat:61.0134885 },
        { lng: 21.6531805	, lat:61.0078998 },
        { lng: 21.6532097	, lat:61.0077382 },
        { lng: 21.6527247	, lat:61.0076706 },
        { lng: 21.6529071	, lat:61.0064241 },
        { lng: 21.6534317	, lat:61.0064449 },
        { lng: 21.6546666	, lat:60.9999219 },
        { lng: 21.6656015	, lat:60.9963249 },
        { lng: 21.665845	, lat:60.9965923 },
        { lng: 21.6676571	, lat:60.9967041 },
        { lng: 21.668083	, lat:60.9963821 },
        { lng: 21.6728456	, lat:60.9973595 },
        { lng: 21.6721066	, lat:60.9976178 },
        { lng: 21.6729778	, lat:60.9988651 },
        { lng: 21.6737717	, lat:60.9991044 },
        { lng: 21.6764539	, lat:60.9986467 },
        { lng: 21.6760248	, lat:60.9982825 },
        { lng: 21.6761964	, lat:60.9980849 },
        { lng: 21.6768402	, lat:60.9981889 },
        { lng: 21.6910451	, lat:61.0011538 },
        { lng: 21.691131	, lat:61.0012682 },
        { lng: 21.6915816	, lat:61.001185 },
        { lng: 21.6918176	, lat:61.0014034 },
        { lng: 21.6921051	, lat:61.0014565 },
        { lng: 21.6916116	, lat:61.0024863 },
        { lng: 21.6916331	, lat:61.0031208 },
        { lng: 21.6932767	, lat:61.0035567 },
        { lng: 21.7017713	, lat:61.0031715 },
        { lng: 21.7019456	, lat:61.0032345 },
        { lng: 21.7022844	, lat:61.0030916 },
        { lng: 21.7024351	, lat:61.0030578 },
        { lng: 21.7028487	, lat:61.0029452 },
        { lng: 21.703313	, lat:61.0028638 },
        { lng: 21.7042561	, lat:61.0027137 },
        { lng: 21.7047311	, lat:61.0026457 },
        { lng: 21.7052608	, lat:61.0025063 },
        { lng: 21.7062565	, lat:61.0021638 },
        { lng: 21.7085954	, lat:61.0014565 },
        { lng: 21.7093059	, lat:61.0012244 },
        { lng: 21.7099901	, lat:61.0010014 },
        { lng: 21.7105909	, lat:61.0008323 },
        { lng: 21.7118969	, lat:61.0005087 },
        { lng: 21.7129263	, lat:61.0002222 },
        { lng: 21.713409	, lat:61.0000642 },
        { lng: 21.7137256	, lat:60.9999346 },
        { lng: 21.7139651	, lat:60.9998129 },
        { lng: 21.7143078	, lat:60.9995897 },
        { lng: 21.7142065	, lat:60.9994019 },
        { lng: 21.71393	, lat:60.999201 },
        { lng: 21.7136462	, lat:60.9990407 },
        { lng: 21.7152162	, lat:60.9991801 },
        { lng: 21.7220338	, lat:60.9997536 },
        { lng: 21.7232552	, lat:60.9998995 },
        { lng: 21.7237016	, lat:61.0002602 },
        { lng: 21.7277356	, lat:61.0010456 },
        { lng: 21.73045	, lat:61.0005619 },
        { lng: 21.7312439	, lat:61.0006347 },
        { lng: 21.7319091	, lat:61.0010612 },
        { lng: 21.7324241	, lat:61.0011028 },
        { lng: 21.7327674	, lat:61.0007491 },
        { lng: 21.7343124	, lat:61.0008739 },
        { lng: 21.7440327	, lat:61.0047331 },
        { lng: 21.7553624	, lat:61.0093197 },
        { lng: 21.7660483	, lat:61.0136666 },
        { lng: 21.765117	, lat:61.0145078 },
        { lng: 21.7630657	, lat:61.0165988 },
        { lng: 21.7605028	, lat:61.0192677 },
        { lng: 21.7580574	, lat:61.0217327 },
        { lng: 21.7532595	, lat:61.0265684 },
        { lng: 21.7696317	, lat:61.032056 },
        { lng: 21.7700179	, lat:61.0321703 },
        { lng: 21.7757471	, lat:61.0341136 },
        { lng: 21.789244	, lat:61.034124 },
        { lng: 21.802183	, lat:61.034176 },
        { lng: 21.8251856	, lat:61.034176 },
        { lng: 21.8104013	, lat:61.023793 },
        { lng: 21.804994	, lat:61.0201233 },
        { lng: 21.8057021	, lat:61.0194683 },
        { lng: 21.8065604	, lat:61.019146 },
        { lng: 21.8088993	, lat:61.0190109 },
        { lng: 21.8107661	, lat:61.0184807 },
        { lng: 21.8112167	, lat:61.0181688 },
        { lng: 21.812075	, lat:61.0178153 },
        { lng: 21.8127831	, lat:61.0178984 },
        { lng: 21.8147572	, lat:61.0173786 },
        { lng: 21.81686	, lat:61.0168171 },
        { lng: 21.8339189	, lat:61.0121588 },
        { lng: 21.8337043	, lat:61.0118052 },
        { lng: 21.8331679	, lat:61.0118052 },
        { lng: 21.8322452	, lat:61.0110669 },
        { lng: 21.8337043	, lat:61.0110461 },
        { lng: 21.8347772	, lat:61.0107965 },
        { lng: 21.8355282	, lat:61.0110149 },
        { lng: 21.8347772	, lat:61.0114516 },
        { lng: 21.8346055	, lat:61.01193 },
        { lng: 21.8439031	, lat:61.0094546 },
        { lng: 21.8441028	, lat:61.0094014 },
        { lng: 21.862787	, lat:61.0127837 },
        { lng: 21.8757313	, lat:61.0151727 },
        { lng: 21.8857306	, lat:61.01535 },
        { lng: 21.8874343	, lat:61.0147584 },
        { lng: 21.887091	, lat:61.0130011 },
        { lng: 21.8887432	, lat:61.013157 },
        { lng: 21.8919404	, lat:61.0154862 },
        { lng: 21.9019816	, lat:61.015676 },
        { lng: 21.9202431	, lat:61.0154342 },
        { lng: 21.956338	, lat:61.0163431 },
        { lng: 21.9816955	, lat:61.0193537 },
        { lng: 21.9821476	, lat:61.0227257 },
        { lng: 21.9825352	, lat:61.0256182 },
        { lng: 21.9908057	, lat:61.0318204 },
        { lng: 21.9989504	, lat:61.032898 },
        { lng:22.0211172	, lat:61.0304174 },
        { lng:22.0704887	, lat:61.0424935 },
        { lng:22.0497913	, lat:61.0803389 },
        { lng:22.0274146	, lat:61.0889661 },
        { lng: 21.9898821	, lat:61.0856576 },
        { lng: 21.9041956	, lat:61.1163545 },
        { lng: 21.8918947	, lat:61.1307088 },
        { lng: 21.8674944	, lat:61.1456829 },
        { lng: 21.8286547	, lat:61.1623752 },
        { lng: 21.8113121	, lat:61.149861 },
        { lng: 21.7652671	, lat:61.1478751 },
        { lng: 21.7315427	, lat:61.1384617 },
        { lng: 21.6594545	, lat:61.1478434 },
        { lng: 21.645613	, lat:61.1496375 },
        { lng: 21.6304832	, lat:61.1569937 },
        { lng: 21.6182932	, lat:61.1686644 },
        { lng: 21.6182095	, lat:61.1687446 },
        { lng: 21.5972428	, lat:61.1888081 },
        { lng: 21.5749958	, lat:61.1814914 },
        { lng: 21.5537353	, lat:61.195192 },
        { lng: 21.5003902	, lat:61.2117843 },
        { lng: 21.4391721	, lat:61.2305907 },
        { lng: 21.425592	, lat:61.2348342 },
        { lng: 21.4118402	, lat:61.2451907 },
        { lng: 21.3699526	, lat:61.250234 },
        { lng: 20.8682419	, lat:61.2813689 },
        { lng: 20.8121334	, lat:61.2848724 },
        { lng: 20.8024662	, lat:61.2473337 },
        { lng: 20.7921273	, lat:61.2072428 },
        { lng: 20.7817884	, lat:61.1671009 },
        { lng: 20.773152	, lat:61.1335299 },
      ],
    },
    {
      id: 25,
      regionName: 'eCom TP  Riihimäki',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [        
        { lng:24.6167233	, lat:60.6670342 },
        { lng:24.6390828	, lat:60.658768 },
        { lng:24.6516401	, lat:60.6602154 },
        { lng:24.6914425	, lat:60.6493973 },
        { lng:24.7124237	, lat:60.6501793 },
        { lng:24.7398895	, lat:60.6477392 },
        { lng:24.7417778	, lat:60.6502634 },
        { lng:24.7460694	, lat:60.6492537 },
        { lng:24.7493101	, lat:60.6495801 },
        { lng:24.750577	, lat:60.6522776 },
        { lng:24.7517532	, lat:60.6548414 },
        { lng:24.7572576	, lat:60.6543664 },
        { lng:24.7604122	, lat:60.6560093 },
        { lng:24.7615105	, lat:60.6571516 },
        { lng:24.7641233	, lat:60.6599113 },
        { lng:24.7702748	, lat:60.6575026 },
        { lng:24.7744777	, lat:60.6558146 },
        { lng:24.7769455	, lat:60.6548163 },
        { lng:24.7792715	, lat:60.6538954 },
        { lng:24.7806671	, lat:60.654901 },
        { lng:24.7815711	, lat:60.6555033 },
        { lng:24.7822287	, lat:60.655852 },
        { lng:24.7834693	, lat:60.6565024 },
        { lng:24.7846853	, lat:60.6572494 },
        { lng:24.7870367	, lat:60.6587493 },
        { lng:24.7899327	, lat:60.6605898 },
        { lng:24.7936485	, lat:60.6629153 },
        { lng:24.7955424	, lat:60.6640921 },
        { lng:24.7991359	, lat:60.6663194 },
        { lng:24.8036103	, lat:60.6691365 },
        { lng:24.8094534	, lat:60.6728329 },
        { lng:24.8105329	, lat:60.6722726 },
        { lng:24.818316	, lat:60.668471 },
        { lng:24.8235737	, lat:60.6677786 },
        { lng:24.8237963	, lat:60.6677492 },
        { lng:24.8350991	, lat:60.6662604 },
        { lng:24.8339339	, lat:60.6812767 },
        { lng:24.8280697	, lat:60.6874458 },
        { lng:24.8260232	, lat:60.6867627 },
        { lng:24.8255419	, lat:60.6880899 },
        { lng:24.8262578	, lat:60.6883168 },
        { lng:24.8157201	, lat:60.6973024 },
        { lng:24.8139588	, lat:60.6979955 },
        { lng:24.8084088	, lat:60.7001795 },
        { lng:24.8082184	, lat:60.7002598 },
        { lng:24.8038832	, lat:60.7020035 },
        { lng:24.8078108	, lat:60.7047099 },
        { lng:24.8079736	, lat:60.7048225 },
        { lng:24.8080912	, lat:60.704904 },
        { lng:24.8093002	, lat:60.7051897 },
        { lng:24.8093744	, lat:60.7051397 },
        { lng:24.8108044	, lat:60.7055452 },
        { lng:24.8117928	, lat:60.7058643 },
        { lng:24.8133024	, lat:60.706352 },
        { lng:24.8140913	, lat:60.7060193 },
        { lng:24.8151763	, lat:60.7057557 },
        { lng:24.8162869	, lat:60.7060078 },
        { lng:24.8151247	, lat:60.7074188 },
        { lng:24.8137438	, lat:60.7090949 },
        { lng:24.8280205	, lat:60.7125814 },
        { lng:24.8288969	, lat:60.7117964 },
        { lng:24.830513	, lat:60.712175 },
        { lng:24.8298188	, lat:60.7129934 },
        { lng:24.8285326	, lat:60.7126761 },
        { lng:24.8268254	, lat:60.7142016 },
        { lng:24.8286692	, lat:60.7151815 },
        { lng:24.8225346	, lat:60.719223 },
        { lng:24.8235247	, lat:60.7194458 },
        { lng:24.8251538	, lat:60.7218975 },
        { lng:24.8282998	, lat:60.7266321 },
        { lng:24.8290042	, lat:60.7276922 },
        { lng:24.8295166	, lat:60.7276852 },
        { lng:24.8312961	, lat:60.7276607 },
        { lng:24.8332946	, lat:60.7276333 },
        { lng:24.8329553	, lat:60.7294475 },
        { lng:24.8337072	, lat:60.7303932 },
        { lng:24.8343125	, lat:60.7311545 },
        { lng:24.8381432	, lat:60.7329952 },
        { lng:24.8377674	, lat:60.7357775 },
        { lng:24.8390367	, lat:60.7357472 },
        { lng:24.8390521	, lat:60.7353765 },
        { lng:24.8404499	, lat:60.7353262 },
        { lng:24.844725	, lat:60.7351722 },
        { lng:24.841861	, lat:60.7396231 },
        { lng:24.8404492	, lat:60.7395268 },
        { lng:24.8400004	, lat:60.7409231 },
        { lng:24.8417954	, lat:60.7410247 },
        { lng:24.8409636	, lat:60.744844 },
        { lng:24.838793	, lat:60.744536 },
        { lng:24.836911	, lat:60.744269 },
        { lng:24.8365528	, lat:60.7442182 },
        { lng:24.8371876	, lat:60.7444589 },
        { lng:24.8305987	, lat:60.7492029 },
        { lng:24.8313221	, lat:60.7494703 },
        { lng:24.8322085	, lat:60.7498023 },
        { lng:24.8403257	, lat:60.7528417 },
        { lng:24.8387877	, lat:60.7538465 },
        { lng:24.8376823	, lat:60.7545255 },
        { lng:24.8363262	, lat:60.7553583 },
        { lng:24.8331489	, lat:60.7573095 },
        { lng:24.8322733	, lat:60.7569246 },
        { lng:24.8308942	, lat:60.7579511 },
        { lng:24.8280486	, lat:60.7574967 },
        { lng:24.8302157	, lat:60.7560317 },
        { lng:24.8293633	, lat:60.755613 },
        { lng:24.8240975	, lat:60.7530266 },
        { lng:24.8239456	, lat:60.7552127 },
        { lng:24.823879	, lat:60.7561681 },
        { lng:24.8221383	, lat:60.756577 },
        { lng:24.8356006	, lat:60.7700535 },
        { lng:24.8357674	, lat:60.7712501 },
        { lng:24.8358268	, lat:60.7716769 },
        { lng:24.8358487	, lat:60.7718337 },
        { lng:24.8359057	, lat:60.7722428 },
        { lng:24.8364433	, lat:60.776103 },
        { lng:24.8342325	, lat:60.7753014 },
        { lng:24.8338166	, lat:60.7752319 },
        { lng:24.8311789	, lat:60.7743876 },
        { lng:24.8304456	, lat:60.774238 },
        { lng:24.8282894	, lat:60.7736021 },
        { lng:24.8191504	, lat:60.774858 },
        { lng:24.8194459	, lat:60.7740778 },
        { lng:24.8163813	, lat:60.7742915 },
        { lng:24.8172241	, lat:60.7756809 },
        { lng:24.8131307	, lat:60.7766053 },
        { lng:24.8152649	, lat:60.7794907 },
        { lng:24.8083477	, lat:60.7812538 },
        { lng:24.8070891	, lat:60.7820552 },
        { lng:24.8028972	, lat:60.782146 },
        { lng:24.7942178	, lat:60.7836419 },
        { lng:24.7929045	, lat:60.7815851 },
        { lng:24.7917662	, lat:60.7813179 },
        { lng:24.7909672	, lat:60.7813179 },
        { lng:24.7894568	, lat:60.7824078 },
        { lng:24.7888986	, lat:60.782349 },
        { lng:24.7881611	, lat:60.7824791 },
        { lng:24.7797815	, lat:60.7839571 },
        { lng:24.7795079	, lat:60.7831077 },
        { lng:24.7780394	, lat:60.7850579 },
        { lng:24.7772037	, lat:60.7849041 },
        { lng:24.7769863	, lat:60.7855881 },
        { lng:24.777525	, lat:60.7857108 },
        { lng:24.7767834	, lat:60.7866888 },
        { lng:24.7765326	, lat:60.7871346 },
        { lng:24.7764702	, lat:60.7871284 },
        { lng:24.776496	, lat:60.7869567 },
        { lng:24.7761849	, lat:60.7868726 },
        { lng:24.774134	, lat:60.7860084 },
        { lng:24.7737135	, lat:60.7859018 },
        { lng:24.7735859	, lat:60.785736 },
        { lng:24.7732178	, lat:60.7858341 },
        { lng:24.7716209	, lat:60.7865091 },
        { lng:24.7653521	, lat:60.7892197 },
        { lng:24.7638497	, lat:60.7896607 },
        { lng:24.7635269	, lat:60.7897814 },
        { lng:24.7633397	, lat:60.7899195 },
        { lng:24.7629971	, lat:60.7903046 },
        { lng:24.7625855	, lat:60.7903788 },
        { lng:24.7565284	, lat:60.7915244 },
        { lng:24.7563261	, lat:60.7913422 },
        { lng:24.7560736	, lat:60.7910813 },
        { lng:24.7566737	, lat:60.7902784 },
        { lng:24.7406399	, lat:60.793917 },
        { lng:24.735257	, lat:60.7951723 },
        { lng:24.7344173	, lat:60.7953684 },
        { lng:24.7279253	, lat:60.7956166 },
        { lng:24.7270343	, lat:60.8003074 },
        { lng:24.7217971	, lat:60.8000928 },
        { lng:24.721273	, lat:60.7969222 },
        { lng:24.7146398	, lat:60.7964786 },
        { lng:24.7309027	, lat:60.7759601 },
        { lng:24.7318482	, lat:60.7747897 },
        { lng:24.7324861	, lat:60.7742053 },
        { lng:24.7339913	, lat:60.7728436 },
        { lng:24.7399744	, lat:60.7671357 },
        { lng:24.7493157	, lat:60.758296 },
        { lng:24.7328973	, lat:60.7563894 },
        { lng:24.729299	, lat:60.7559715 },
        { lng:24.728884	, lat:60.7559232 },
        { lng:24.7207161	, lat:60.7549748 },
        { lng:24.7159713	, lat:60.7544127 },
        { lng:24.7100871	, lat:60.7537157 },
        { lng:24.7079471	, lat:60.7534622 },
        { lng:24.7053191	, lat:60.7531566 },
        { lng:24.6986177	, lat:60.7523732 },
        { lng:24.6909516	, lat:60.7514771 },
        { lng:24.6891924	, lat:60.7378946 },
        { lng:24.6877039	, lat:60.7263973 },
        { lng:24.6877003	, lat:60.7255944 },
        { lng:24.6245601	, lat:60.7004112 },
        { lng:24.6056254	, lat:60.69277 },
        { lng:24.6181053	, lat:60.685285 },
        { lng:24.6196762	, lat:60.684339 },
        { lng:24.6188943	, lat:60.6830141 },
        { lng:24.6186356	, lat:60.6829065 },
        { lng:24.6178882	, lat:60.6813095 },
        { lng:24.6134166	, lat:60.6738815 },
        { lng:24.6167233	, lat:60.6670342 },
      ],
    },
    {
      id: 26,
      regionName: 'eCom TP  Rovaniemi kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:24.7365799	, lat:67.1519437 },
        { lng:24.7428108	, lat:67.139378 },
        { lng:24.7431431	, lat:67.1387076 },
        { lng:24.750893	, lat:67.1256654 },
        { lng:24.7518296	, lat:67.1252878 },
        { lng:24.7603288	, lat:67.1218601 },
        { lng:24.7599909	, lat:67.1214996 },
        { lng:24.7565595	, lat:67.117838 },
        { lng:24.7559058	, lat:67.1171403 },
        { lng:24.7576606	, lat:67.1140805 },
        { lng:24.7590079	, lat:67.1119848 },
        { lng:24.7714509	, lat:67.1094423 },
        { lng:24.7700894	, lat:67.108137 },
        { lng:24.7691435	, lat:67.1072302 },
        { lng:24.7684446	, lat:67.1072746 },
        { lng:24.7612487	, lat:67.1077312 },
        { lng:24.7732363	, lat:67.0885762 },
        { lng:24.794703	, lat:67.0542365 },
        { lng:24.7995473	, lat:67.0468594 },
        { lng:24.8456594	, lat:66.9762807 },
        { lng:24.8489019	, lat:66.9711698 },
        { lng:24.8582018	, lat:66.9662491 },
        { lng:24.8573416	, lat:66.965481 },
        { lng:24.857038	, lat:66.9652099 },
        { lng:24.8543848	, lat:66.9628405 },
        { lng:24.8702469	, lat:66.9379063 },
        { lng:24.8837741	, lat:66.9163055 },
        { lng:24.884478	, lat:66.9151811 },
        { lng:24.9057599	, lat:66.8811564 },
        { lng:24.9125277	, lat:66.8854393 },
        { lng:24.9184586	, lat:66.8852473 },
        { lng:24.9182783	, lat:66.8838472 },
        { lng:24.9145876	, lat:66.8812002 },
        { lng:24.9133087	, lat:66.8713256 },
        { lng:24.9120256	, lat:66.871243 },
        { lng:24.9135036	, lat:66.8686973 },
        { lng:24.9139525	, lat:66.8680813 },
        { lng:24.9236771	, lat:66.8674364 },
        { lng:24.9240247	, lat:66.8674046 },
        { lng:24.9278115	, lat:66.8670585 },
        { lng:24.9315664	, lat:66.8667154 },
        { lng:24.9371718	, lat:66.8662031 },
        { lng:24.9341913	, lat:66.8604985 },
        { lng:24.9308064	, lat:66.8603441 },
        { lng:24.9277736	, lat:66.8602057 },
        { lng:24.919274	, lat:66.8598179 },
        { lng:24.9239665	, lat:66.8524249 },
        { lng:24.9647542	, lat:66.7880676 },
        { lng:24.966568	, lat:66.7852018 },
        { lng:24.9677467	, lat:66.7833392 },
        { lng:24.9700997	, lat:66.7796207 },
        { lng:24.9704861	, lat:66.7790099 },
        { lng:24.9731733	, lat:66.7747624 },
        { lng:24.9620654	, lat:66.7722488 },
        { lng:24.9621314	, lat:66.7719454 },
        { lng:24.9627116	, lat:66.7721506 },
        { lng:24.9636707	, lat:66.7723558 },
        { lng:24.964813	, lat:66.7724961 },
        { lng:24.9653564	, lat:66.7724419 },
        { lng:24.9661944	, lat:66.7724209 },
        { lng:24.9669866	, lat:66.7722794 },
        { lng:24.967442	, lat:66.7723537 },
        { lng:24.9679389	, lat:66.7723304 },
        { lng:24.9679816	, lat:66.7723284 },
        { lng:24.9697223	, lat:66.771003 },
        { lng:24.9703455	, lat:66.7705285 },
        { lng:24.9709513	, lat:66.7700672 },
        { lng:24.967862	, lat:66.7696122 },
        { lng:24.9667271	, lat:66.7701962 },
        { lng:24.9652229	, lat:66.7709702 },
        { lng:24.960999	, lat:66.7705866 },
        { lng:24.9655971	, lat:66.7687793 },
        { lng:24.9666379	, lat:66.7683702 },
        { lng:24.967677	, lat:66.7679618 },
        { lng:24.968676	, lat:66.7682266 },
        { lng:24.969454	, lat:66.7684328 },
        { lng:24.9712332	, lat:66.7689044 },
        { lng:24.9713986	, lat:66.7689482 },
        { lng:24.9762816	, lat:66.7702424 },
        { lng:24.9880398	, lat:66.7543709 },
        { lng:24.993019	, lat:66.7407386 },
        { lng:24.9962241	, lat:66.7318192 },
        { lng:24.9979139	, lat:66.7262418 },
        { lng:24.9966043	, lat:66.727028 },
        { lng:24.9917006	, lat:66.7255727 },
        { lng:24.9993192	, lat:66.7214308 },
        { lng:25.0087707	, lat:66.6874849 },
        { lng:25.0172828	, lat:66.648001 },
        { lng:25.0280436	, lat:66.5979949 },
        { lng:25.0297277	, lat:66.5889268 },
        { lng:25.0310414	, lat:66.5817268 },
        { lng:25.032237	, lat:66.5755579 },
        { lng:25.0336704	, lat:66.5680047 },
        { lng:25.0343978	, lat:66.5640342 },
        { lng:25.0358212	, lat:66.5566496 },
        { lng:25.0369457	, lat:66.5509346 },
        { lng:25.0390171	, lat:66.5399022 },
        { lng:25.0402677	, lat:66.5331957 },
        { lng:25.0435219	, lat:66.5166964 },
        { lng:25.0504173	, lat:66.4758689 },
        { lng:24.9523911	, lat:66.4187635 },
        { lng:24.914946	, lat:66.3977347 },
        { lng:24.9118526	, lat:66.3959967 },
        { lng:24.909939	, lat:66.3949215 },
        { lng:24.9050522	, lat:66.3921755 },
        { lng:24.8905463	, lat:66.3842065 },
        { lng:24.876594	, lat:66.3766827 },
        { lng:24.8747196	, lat:66.3756718 },
        { lng:24.8661816	, lat:66.3710664 },
        { lng:24.8604709	, lat:66.3680594 },
        { lng:24.8595699	, lat:66.367585 },
        { lng:24.850602	, lat:66.3628621 },
        { lng:24.8414273	, lat:66.3580294 },
        { lng:24.8411577	, lat:66.3578874 },
        { lng:24.8215432	, lat:66.3483047 },
        { lng:24.8214639	, lat:66.3482622 },
        { lng:24.8210575	, lat:66.3480446 },
        { lng:24.8094021	, lat:66.3418037 },
        { lng:24.8062215	, lat:66.3401004 },
        { lng:24.8016493	, lat:66.3376517 },
        { lng:24.8172859	, lat:66.3340655 },
        { lng:24.8225511	, lat:66.3328578 },
        { lng:24.857873	, lat:66.3247546 },
        { lng:24.858417	, lat:66.3246298 },
        { lng:24.8644815	, lat:66.3232382 },
        { lng:24.8843016	, lat:66.3186898 },
        { lng:24.9325019	, lat:66.3075545 },
        { lng:25.0339184	, lat:66.2840676 },
        { lng:25.048221	, lat:66.2806744 },
        { lng:25.1277892	, lat:66.2616641 },
        { lng:25.1304696	, lat:66.2634929 },
        { lng:25.131767	, lat:66.2632513 },
        { lng:25.1325829	, lat:66.2630537 },
        { lng:25.1297825	, lat:66.2611811 },
        { lng:25.2140087	, lat:66.2408497 },
        { lng:25.2415969	, lat:66.2341073 },
        { lng:25.2579409	, lat:66.2301105 },
        { lng:25.2678813	, lat:66.2276794 },
        { lng:25.2679813	, lat:66.2276549 },
        { lng:25.2842919	, lat:66.2236281 },
        { lng:25.2915086	, lat:66.2218462 },
        { lng:25.2916931	, lat:66.2218007 },
        { lng:25.301344	, lat:66.2194176 },
        { lng:25.3017376	, lat:66.2193204 },
        { lng:25.3096417	, lat:66.2173684 },
        { lng:25.3098328	, lat:66.2173212 },
        { lng:25.3314252	, lat:66.2119919 },
        { lng:25.3323799	, lat:66.2117563 },
        { lng:25.3337831	, lat:66.2114099 },
        { lng:25.3325626	, lat:66.2107388 },
        { lng:25.3333675	, lat:66.2105223 },
        { lng:25.3346582	, lat:66.2111745 },
        { lng:25.3395894	, lat:66.2099201 },
        { lng:25.3392651	, lat:66.2097991 },
        { lng:25.3481084	, lat:66.2098595 },
        { lng:25.348362	, lat:66.2098593 },
        { lng:25.361559	, lat:66.2098425 },
        { lng:25.368433	, lat:66.2098338 },
        { lng:25.4260352	, lat:66.2097607 },
        { lng:25.4816376	, lat:66.2096901 },
        { lng:25.4955847	, lat:66.2096724 },
        { lng:25.4983926	, lat:66.2096708 },
        { lng:25.510126	, lat:66.209664 },
        { lng:25.5129693	, lat:66.2096623 },
        { lng:25.5350466	, lat:66.2096495 },
        { lng:25.5359425	, lat:66.2114103 },
        { lng:25.5371828	, lat:66.212316 },
        { lng:25.5399775	, lat:66.213376 },
        { lng:25.5444756	, lat:66.2097392 },
        { lng:25.5742383	, lat:66.2102521 },
        { lng:25.7440537	, lat:66.2131786 },
        { lng:25.7565688	, lat:66.2133943 },
        { lng:25.7574782	, lat:66.2134099 },
        { lng:25.7619512	, lat:66.213487 },
        { lng:25.7648031	, lat:66.2135362 },
        { lng:25.7708367	, lat:66.2136401 },
        { lng:25.7750766	, lat:66.2137132 },
        { lng:25.7769687	, lat:66.2133615 },
        { lng:25.7877376	, lat:66.2113593 },
        { lng:25.797654	, lat:66.2095156 },
        { lng:25.7978654	, lat:66.2094763 },
        { lng:25.7980716	, lat:66.209438 },
        { lng:25.8069558	, lat:66.207786 },
        { lng:25.8071205	, lat:66.2077554 },
        { lng:25.8072817	, lat:66.2077254 },
        { lng:25.818294	, lat:66.2056776 },
        { lng:25.9400847	, lat:66.1830186 },
        { lng:25.937134	, lat:66.1812077 },
        { lng:25.934508	, lat:66.179596 },
        { lng:25.9323623	, lat:66.178279 },
        { lng:25.9414742	, lat:66.1765256 },
        { lng:25.9428115	, lat:66.1762682 },
        { lng:25.9429605	, lat:66.1764981 },
        { lng:25.9431402	, lat:66.1767753 },
        { lng:25.9464277	, lat:66.1818464 },
        { lng:26.0531752	, lat:66.1618096 },
        { lng:26.0663252	, lat:66.1593402 },
        { lng:26.0673813	, lat:66.1591419 },
        { lng:26.0753725	, lat:66.1576688 },
        { lng:26.0870622	, lat:66.1555137 },
        { lng:26.0862645	, lat:66.1571431 },
        { lng:26.0851704	, lat:66.1593776 },
        { lng:26.0849945	, lat:66.1597368 },
        { lng:26.0934831	, lat:66.1653115 },
        { lng:26.0933633	, lat:66.1704937 },
        { lng:26.1030388	, lat:66.1705337 },
        { lng:26.1105418	, lat:66.1645089 },
        { lng:26.1214518	, lat:66.1634754 },
        { lng:26.1219144	, lat:66.164179 },
        { lng:26.1325251	, lat:66.1631015 },
        { lng:26.130377	, lat:66.1595285 },
        { lng:26.1353967	, lat:66.1623182 },
        { lng:26.1368767	, lat:66.1631407 },
        { lng:26.1393718	, lat:66.1645272 },
        { lng:26.1474025	, lat:66.1689893 },
        { lng:26.1486649	, lat:66.1692143 },
        { lng:26.1507335	, lat:66.169583 },
        { lng:26.150015	, lat:66.1703377 },
        { lng:26.1519985	, lat:66.1714144 },
        { lng:26.1583973	, lat:66.1748876 },
        { lng:26.1787625	, lat:66.1809358 },
        { lng:26.1816339	, lat:66.1817885 },
        { lng:26.1853855	, lat:66.1814842 },
        { lng:26.18604	, lat:66.1814312 },
        { lng:26.1901904	, lat:66.1810945 },
        { lng:26.2000118	, lat:66.1802979 },
        { lng:26.2024187	, lat:66.1801027 },
        { lng:26.2026799	, lat:66.1801844 },
        { lng:26.203503	, lat:66.1804421 },
        { lng:26.2047345	, lat:66.1808275 },
        { lng:26.206664	, lat:66.1814315 },
        { lng:26.2087863	, lat:66.1820957 },
        { lng:26.2147919	, lat:66.1839753 },
        { lng:26.2179575	, lat:66.184966 },
        { lng:26.2183718	, lat:66.1850957 },
        { lng:26.2220595	, lat:66.1862497 },
        { lng:26.226189	, lat:66.1875419 },
        { lng:26.2280816	, lat:66.1881342 },
        { lng:26.2282574	, lat:66.1881892 },
        { lng:26.2286887	, lat:66.1883234 },
        { lng:26.2306251	, lat:66.1889258 },
        { lng:26.2361723	, lat:66.1906514 },
        { lng:26.2372287	, lat:66.19098 },
        { lng:26.2425329	, lat:66.1926299 },
        { lng:26.2494509	, lat:66.1947817 },
        { lng:26.2538453	, lat:66.1961484 },
        { lng:26.2923624	, lat:66.1955595 },
        { lng:26.3686518	, lat:66.2228983 },
        { lng:26.416882	, lat:66.2227846 },
        { lng:26.4167717	, lat:66.2159004 },
        { lng:26.4413376	, lat:66.2158782 },
        { lng:26.4413742	, lat:66.2092056 },
        { lng:26.4562104	, lat:66.1912553 },
        { lng:26.4693637	, lat:66.1877606 },
        { lng:26.5450147	, lat:66.2024116 },
        { lng:26.6190657	, lat:66.2169203 },
        { lng:26.6287641	, lat:66.2222665 },
        { lng:26.6338827	, lat:66.2248888 },
        { lng:26.5911203	, lat:66.2384333 },
        { lng:26.5848054	, lat:66.2457553 },
        { lng:26.5759026	, lat:66.2462298 },
        { lng:26.5717569	, lat:66.2483984 },
        { lng:26.5828442	, lat:66.2614183 },
        { lng:26.604982	, lat:66.262888 },
        { lng:26.6034225	, lat:66.2664791 },
        { lng:26.5788412	, lat:66.2669843 },
        { lng:26.5794764	, lat:66.2819512 },
        { lng:26.5796058	, lat:66.2850011 },
        { lng:26.6557457	, lat:66.2920547 },
        { lng:26.7274772	, lat:66.2986484 },
        { lng:26.8023056	, lat:66.3000268 },
        { lng:26.8111838	, lat:66.3001965 },
        { lng:26.8368321	, lat:66.3006325 },
        { lng:26.8675797	, lat:66.3012806 },
        { lng:26.8679691	, lat:66.3011921 },
        { lng:26.868464	, lat:66.2973561 },
        { lng:26.8756384	, lat:66.2949963 },
        { lng:26.8762076	, lat:66.2949074 },
        { lng:26.8803502	, lat:66.2949137 },
        { lng:26.886153	, lat:66.2961278 },
        { lng:26.8879239	, lat:66.2979774 },
        { lng:26.8895366	, lat:66.2975706 },
        { lng:26.897727	, lat:66.3018733 },
        { lng:26.9004696	, lat:66.301895 },
        { lng:26.8999859	, lat:66.2987945 },
        { lng:26.9033109	, lat:66.2924669 },
        { lng:26.9110637	, lat:66.2931101 },
        { lng:26.9116058	, lat:66.290365 },
        { lng:26.9188905	, lat:66.2763598 },
        { lng:26.9331086	, lat:66.2682998 },
        { lng:26.9331514	, lat:66.2678501 },
        { lng:26.9324415	, lat:66.2613728 },
        { lng:26.9285795	, lat:66.25964 },
        { lng:26.9394557	, lat:66.2554814 },
        { lng:26.9310048	, lat:66.2484757 },
        { lng:26.9351766	, lat:66.244117 },
        { lng:26.9402755	, lat:66.242748 },
        { lng:26.9542504	, lat:66.2429475 },
        { lng:26.962594	, lat:66.2463436 },
        { lng:26.9512994	, lat:66.2508816 },
        { lng:26.9546343	, lat:66.2537222 },
        { lng:26.9655987	, lat:66.2524952 },
        { lng:26.9712653	, lat:66.2422429 },
        { lng:27.0006822	, lat:66.2469322 },
        { lng:27.0025574	, lat:66.2478767 },
        { lng:27.0216204	, lat:66.2401154 },
        { lng:27.0342883	, lat:66.2363645 },
        { lng:27.0391472	, lat:66.2344937 },
        { lng:27.0384005	, lat:66.2321112 },
        { lng:27.0387291	, lat:66.2320551 },
        { lng:27.0379317	, lat:66.2314944 },
        { lng:27.0378299	, lat:66.231303 },
        { lng:27.0382201	, lat:66.2310842 },
        { lng:27.0391362	, lat:66.2309064 },
        { lng:27.0413417	, lat:66.2307013 },
        { lng:27.0421391	, lat:66.2303799 },
        { lng:27.0425292	, lat:66.2299901 },
        { lng:27.0429873	, lat:66.229867 },
        { lng:27.0424639	, lat:66.2293387 },
        { lng:27.0391938	, lat:66.2293236 },
        { lng:27.0361123	, lat:66.2307181 },
        { lng:27.029271	, lat:66.226015 },
        { lng:27.0291148	, lat:66.224275 },
        { lng:27.0528191	, lat:66.2221566 },
        { lng:27.0543306	, lat:66.2223307 },
        { lng:27.0583104	, lat:66.2208981 },
        { lng:27.0647192	, lat:66.2204443 },
        { lng:27.0665666	, lat:66.2118619 },
        { lng:27.068246	, lat:66.2112135 },
        { lng:27.0681711	, lat:66.2089437 },
        { lng:27.0731366	, lat:66.2072792 },
        { lng:27.0860979	, lat:66.2063073 },
        { lng:27.0969269	, lat:66.2014707 },
        { lng:27.1096657	, lat:66.2011451 },
        { lng:27.111825	, lat:66.2005642 },
        { lng:27.187178	, lat:66.1975233 },
        { lng:27.1975823	, lat:66.200283 },
        { lng:27.2342663	, lat:66.2008735 },
        { lng:27.2531334	, lat:66.2031931 },
        { lng:27.2539159	, lat:66.1999731 },
        { lng:27.2600501	, lat:66.1982851 },
        { lng:27.284023	, lat:66.2123986 },
        { lng:27.3172422	, lat:66.2319427 },
        { lng:27.3247373	, lat:66.2363502 },
        { lng:27.3251888	, lat:66.2366157 },
        { lng:27.3266436	, lat:66.2374712 },
        { lng:27.3123325	, lat:66.2435462 },
        { lng:27.2611834	, lat:66.2564911 },
        { lng:27.2720603	, lat:66.2769342 },
        { lng:27.2339093	, lat:66.3125725 },
        { lng:27.2342837	, lat:66.3130349 },
        { lng:27.2496888	, lat:66.3320598 },
        { lng:27.16584	, lat:66.3536686 },
        { lng:27.1652637	, lat:66.353817 },
        { lng:27.1544768	, lat:66.3589458 },
        { lng:27.1541627	, lat:66.3590951 },
        { lng:27.15363	, lat:66.3593483 },
        { lng:27.1512085	, lat:66.3604995 },
        { lng:27.1485787	, lat:66.3617496 },
        { lng:27.1443038	, lat:66.3615956 },
        { lng:27.1437236	, lat:66.3632033 },
        { lng:27.1448928	, lat:66.3635359 },
        { lng:27.1284679	, lat:66.3712835 },
        { lng:26.9738216	, lat:66.4441114 },
        { lng:26.6954886	, lat:66.5746816 },
        { lng:26.7047889	, lat:66.5931383 },
        { lng:26.7103121	, lat:66.6073474 },
        { lng:26.7105438	, lat:66.6079433 },
        { lng:26.7126462	, lat:66.6133498 },
        { lng:26.7135925	, lat:66.6156859 },
        { lng:26.7140624	, lat:66.6168459 },
        { lng:26.7136664	, lat:66.6181377 },
        { lng:26.7130284	, lat:66.6202183 },
        { lng:26.7129087	, lat:66.6206087 },
        { lng:26.712865	, lat:66.6207508 },
        { lng:26.7124489	, lat:66.6222267 },
        { lng:26.7002023	, lat:66.6656141 },
        { lng:26.6966206	, lat:66.6782714 },
        { lng:26.6950821	, lat:66.6838105 },
        { lng:26.6878671	, lat:66.7077006 },
        { lng:26.6674708	, lat:66.7807273 },
        { lng:26.6629531	, lat:66.7794299 },
        { lng:26.6618826	, lat:66.7828826 },
        { lng:26.6668329	, lat:66.7831096 },
        { lng:26.6652922	, lat:66.7885069 },
        { lng:26.6544974	, lat:66.8222549 },
        { lng:26.6066768	, lat:66.8422641 },
        { lng:26.5320754	, lat:66.8965703 },
        { lng:26.4666204	, lat:66.9348904 },
        { lng:26.4732611	, lat:66.9359306 },
        { lng:26.5179779	, lat:66.9429332 },
        { lng:26.4852941	, lat:66.9452487 },
        { lng:26.4826987	, lat:66.9454325 },
        { lng:26.4754738	, lat:66.9459443 },
        { lng:26.4509628	, lat:66.9476804 },
        { lng:26.4061586	, lat:66.950933 },
        { lng:26.4103771	, lat:66.9520319 },
        { lng:26.4136685	, lat:66.9528891 },
        { lng:26.4131243	, lat:66.9531361 },
        { lng:26.4127103	, lat:66.9532136 },
        { lng:26.4118465	, lat:66.9532777 },
        { lng:26.4118962	, lat:66.9540002 },
        { lng:26.4115532	, lat:66.9543915 },
        { lng:26.4111758	, lat:66.954715 },
        { lng:26.4102771	, lat:66.9545595 },
        { lng:26.4019932	, lat:66.9531257 },
        { lng:26.4006176	, lat:66.9528876 },
        { lng:26.3961989	, lat:66.9521227 },
        { lng:26.395659	, lat:66.9522398 },
        { lng:26.3886139	, lat:66.9537667 },
        { lng:26.3768573	, lat:66.9563144 },
        { lng:26.3724464	, lat:66.9539124 },
        { lng:26.3605166	, lat:66.9602954 },
        { lng:26.3531237	, lat:66.96425 },
        { lng:26.3487055	, lat:66.9666131 },
        { lng:26.3519657	, lat:66.9675878 },
        { lng:26.351914	, lat:66.9676153 },
        { lng:26.3271198	, lat:66.9807892 },
        { lng:26.3269456	, lat:66.9808817 },
        { lng:26.3268044	, lat:66.9809568 },
        { lng:26.3058544	, lat:66.9920859 },
        { lng:26.3021094	, lat:66.9910599 },
        { lng:26.297705	, lat:66.9934177 },
        { lng:26.2912093	, lat:66.9955341 },
        { lng:26.2907282	, lat:66.9956908 },
        { lng:26.2891349	, lat:66.9962098 },
        { lng:26.2636796	, lat:67.0045008 },
        { lng:26.2312184	, lat:67.0150611 },
        { lng:26.1359635	, lat:67.0460009 },
        { lng:26.0762836	, lat:67.065105 },
        { lng:25.9923727	, lat:67.0919072 },
        { lng:25.9044432	, lat:67.1199439 },
        { lng:25.8777717	, lat:67.1215001 },
        { lng:25.8005919	, lat:67.1260024 },
        { lng:25.7931462	, lat:67.1264367 },
        { lng:25.7752356	, lat:67.1274813 },
        { lng:25.7580698	, lat:67.1284491 },
        { lng:25.7573928	, lat:67.1285171 },
        { lng:25.7555635	, lat:67.1287006 },
        { lng:25.7567284	, lat:67.1275734 },
        { lng:25.7573766	, lat:67.1259036 },
        { lng:25.7561228	, lat:67.125871 },
        { lng:25.748097	, lat:67.1276772 },
        { lng:25.7476985	, lat:67.1285807 },
        { lng:25.7470582	, lat:67.128681 },
        { lng:25.7454286	, lat:67.1291828 },
        { lng:25.7428248	, lat:67.1293348 },
        { lng:25.7230676	, lat:67.1304449 },
        { lng:25.721836	, lat:67.1272219 },
        { lng:25.7144787	, lat:67.1262288 },
        { lng:25.7084583	, lat:67.126617 },
        { lng:25.7084041	, lat:67.1267631 },
        { lng:25.7066838	, lat:67.1314016 },
        { lng:25.6709954	, lat:67.1335276 },
        { lng:25.6721859	, lat:67.1315821 },
        { lng:25.6725	, lat:67.1290616 },
        { lng:25.6734223	, lat:67.1281291 },
        { lng:25.6727747	, lat:67.1269831 },
        { lng:25.6724081	, lat:67.1259584 },
        { lng:25.6709357	, lat:67.1248049 },
        { lng:25.6699943	, lat:67.1232264 },
        { lng:25.6644118	, lat:67.1075453 },
        { lng:25.6629561	, lat:67.1034543 },
        { lng:25.6619821	, lat:67.1007982 },
        { lng:25.6584011	, lat:67.1011262 },
        { lng:25.6148944	, lat:67.1049681 },
        { lng:25.6110771	, lat:67.1367003 },
        { lng:25.600381	, lat:67.1373457 },
        { lng:25.557294	, lat:67.1398587 },
        { lng:25.5392234	, lat:67.1408609 },
        { lng:25.5256697	, lat:67.1416072 },
        { lng:25.4025525	, lat:67.1484266 },
        { lng:25.3740492	, lat:67.1499981 },
        { lng:25.3500123	, lat:67.1512865 },
        { lng:25.3464353	, lat:67.1514803 },
        { lng:25.3434313	, lat:67.151187 },
        { lng:25.0340601	, lat:67.1678847 },
        { lng:24.9802369	, lat:67.1707884 },
        { lng:24.9404989	, lat:67.1729177 },
        { lng:24.9226732	, lat:67.1738622 },
        { lng:24.9246562	, lat:67.1756217 },
        { lng:24.9304303	, lat:67.1769225 },
        { lng:24.9302079	, lat:67.1771345 },
        { lng:24.9332162	, lat:67.1777873 },
        { lng:24.9358431	, lat:67.1763668 },
        { lng:24.936761	, lat:67.1774777 },
        { lng:24.9358417	, lat:67.1794991 },
        { lng:24.9353158	, lat:67.1794245 },
        { lng:24.9227662	, lat:67.1765762 },
        { lng:24.9221587	, lat:67.1763212 },
        { lng:24.9216164	, lat:67.1758637 },
        { lng:24.919481	, lat:67.1740627 },
        { lng:24.9193357	, lat:67.1740621 },
        { lng:24.8984628	, lat:67.1751814 },
        { lng:24.8709016	, lat:67.1766378 },
        { lng:24.8619295	, lat:67.1771117 },
        { lng:24.8472652	, lat:67.1741092 },
        { lng:24.7926913	, lat:67.163183 },
        { lng:24.7840045	, lat:67.1614434 },
        { lng:24.767045	, lat:67.1580467 },
        { lng:24.7505394	, lat:67.1547404 },
        { lng:24.7365799	, lat:67.1519437 },
      ],
    },
    {
      id: 27,
      regionName: 'eCom TP Salo',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:22.7909881	, lat:60.0586266 },
        { lng:22.8116057	, lat:60.0547098 },
        { lng:22.8332747	, lat:60.0542267 },
        { lng:22.8412504	, lat:60.06098 },
        { lng:22.8505557	, lat:60.0679717 },
        { lng:22.8288781	, lat:60.0754282 },
        { lng:22.8275144	, lat:60.0793521 },
        { lng:22.827758	, lat:60.0863657 },
        { lng:22.8478102	, lat:60.0918676 },
        { lng:22.8500096	, lat:60.0997481 },
        { lng:22.8592996	, lat:60.104498 },
        { lng:22.8629249	, lat:60.1065107 },
        { lng:22.868873	, lat:60.1150266 },
        { lng:22.8787492	, lat:60.1207627 },
        { lng:22.8807207	, lat:60.1221731 },
        { lng:22.9006293	, lat:60.1328199 },
        { lng:22.8893493	, lat:60.1419427 },
        { lng:22.8792779	, lat:60.1510088 },
        { lng:22.8735014	, lat:60.1547996 },
        { lng:22.868256	, lat:60.1591246 },
        { lng:22.8662819	, lat:60.1623831 },
        { lng:22.8627253	, lat:60.1685661 },
        { lng:22.858879	, lat:60.1752773 },
        { lng:22.8760666	, lat:60.1809757 },
        { lng:22.8731055	, lat:60.1856648 },
        { lng:22.8743071	, lat:60.188334 },
        { lng:22.8758547	, lat:60.1889127 },
        { lng:22.8758873	, lat:60.1889913 },
        { lng:22.8762382	, lat:60.1901592 },
        { lng:22.8694576	, lat:60.1940173 },
        { lng:22.8619581	, lat:60.1959051 },
        { lng:22.8638572	, lat:60.2000482 },
        { lng:22.8759003	, lat:60.2100089 },
        { lng:22.8984952	, lat:60.2218295 },
        { lng:22.9021162	, lat:60.2295419 },
        { lng:22.9030603	, lat:60.232248 },
        { lng:22.9063863	, lat:60.2338993 },
        { lng:22.9043532	, lat:60.2417197 },
        { lng:22.9141325	, lat:60.2467335 },
        { lng:22.9166216	, lat:60.2504816 },
        { lng:22.9204035	, lat:60.250899 },
        { lng:22.9182738	, lat:60.2546765 },
        { lng:22.9165598	, lat:60.2580768 },
        { lng:22.9164821	, lat:60.2582794 },
        { lng:22.9158008	, lat:60.25861 },
        { lng:22.9157261	, lat:60.2586553 },
        { lng:22.9089086	, lat:60.263851 },
        { lng:22.9055065	, lat:60.2619549 },
        { lng:22.9045195	, lat:60.2613615 },
        { lng:22.9036832	, lat:60.2609332 },
        { lng:22.9013609	, lat:60.2598479 },
        { lng:22.8963446	, lat:60.2564273 },
        { lng:22.8890776	, lat:60.2514015 },
        { lng:22.8862804	, lat:60.2495047 },
        { lng:22.876271	, lat:60.2482402 },
        { lng:22.8759432	, lat:60.2482157 },
        { lng:22.8739155	, lat:60.2480166 },
        { lng:22.869021	, lat:60.2477105 },
        { lng:22.8680693	, lat:60.2477137 },
        { lng:22.8609014	, lat:60.2473192 },
        { lng:22.8570122	, lat:60.2503219 },
        { lng:22.8595227	, lat:60.2580085 },
        { lng:22.8549576	, lat:60.2614382 },
        { lng:22.8645224	, lat:60.2653634 },
        { lng:22.8664326	, lat:60.2755895 },
        { lng:22.8676557	, lat:60.2822597 },
        { lng:22.8688011	, lat:60.286271 },
        { lng:22.8804607	, lat:60.295531 },
        { lng:22.8801018	, lat:60.3049383 },
        { lng:22.8787064	, lat:60.3058257 },
        { lng:22.877317	, lat:60.3067358 },
        { lng:22.8769512	, lat:60.3070446 },
        { lng:22.8768501	, lat:60.3071299 },
        { lng:22.8769346	, lat:60.3072388 },
        { lng:22.8764947	, lat:60.3080903 },
        { lng:22.8754013	, lat:60.3084156 },
        { lng:22.8750022	, lat:60.3087453 },
        { lng:22.8735564	, lat:60.3099393 },
        { lng:22.8710327	, lat:60.3120927 },
        { lng:22.8712986	, lat:60.3122087 },
        { lng:22.8717702	, lat:60.3121815 },
        { lng:22.8722984	, lat:60.3123497 },
        { lng:22.8721648	, lat:60.3125815 },
        { lng:22.8718281	, lat:60.3127517 },
        { lng:22.871113	, lat:60.313029 },
        { lng:22.8701233	, lat:60.3132628 },
        { lng:22.8692969	, lat:60.3135882 },
        { lng:22.8689206	, lat:60.313924 },
        { lng:22.867927	, lat:60.3140552 },
        { lng:22.8668065	, lat:60.3150937 },
        { lng:22.8668027	, lat:60.3154227 },
        { lng:22.8667293	, lat:60.3158071 },
        { lng:22.866256	, lat:60.3162088 },
        { lng:22.8655475	, lat:60.3168102 },
        { lng:22.8621576	, lat:60.3196876 },
        { lng:22.8610419	, lat:60.3206343 },
        { lng:22.8614276	, lat:60.3208112 },
        { lng:22.8618379	, lat:60.3215615 },
        { lng:22.8617762	, lat:60.3222707 },
        { lng:22.8620337	, lat:60.3229506 },
        { lng:22.8618628	, lat:60.323019 },
        { lng:22.8609769	, lat:60.3233729 },
        { lng:22.8589795	, lat:60.3224868 },
        { lng:22.8533901	, lat:60.3273161 },
        { lng:22.8519253	, lat:60.3285815 },
        { lng:22.8468683	, lat:60.3329496 },
        { lng:22.8418691	, lat:60.3372969 },
        { lng:22.8424709	, lat:60.3385804 },
        { lng:22.8429581	, lat:60.3396197 },
        { lng:22.8435592	, lat:60.3409016 },
        { lng:22.8442186	, lat:60.3423079 },
        { lng:22.8452622	, lat:60.3445337 },
        { lng:22.840147	, lat:60.3490884 },
        { lng:22.8365291	, lat:60.3523274 },
        { lng:22.8372881	, lat:60.3525652 },
        { lng:22.8375237	, lat:60.352639 },
        { lng:22.8363627	, lat:60.3536893 },
        { lng:22.8348308	, lat:60.3550748 },
        { lng:22.8337176	, lat:60.3561067 },
        { lng:22.8335996	, lat:60.3562158 },
        { lng:22.8337633	, lat:60.3562343 },
        { lng:22.8339537	, lat:60.3562131 },
        { lng:22.8341146	, lat:60.3561707 },
        { lng:22.8343104	, lat:60.3561362 },
        { lng:22.8345116	, lat:60.3561375 },
        { lng:22.8346725	, lat:60.3561627 },
        { lng:22.83482	, lat:60.3562383 },
        { lng:22.8349032	, lat:60.3563113 },
        { lng:22.8350105	, lat:60.3564718 },
        { lng:22.835091	, lat:60.3565249 },
        { lng:22.8352197	, lat:60.3565713 },
        { lng:22.8353216	, lat:60.3565832 },
        { lng:22.8354852	, lat:60.3565634 },
        { lng:22.8357293	, lat:60.3565421 },
        { lng:22.835842	, lat:60.3565474 },
        { lng:22.8359466	, lat:60.3566005 },
        { lng:22.8360324	, lat:60.3566655 },
        { lng:22.8361236	, lat:60.3567743 },
        { lng:22.8362684	, lat:60.3568791 },
        { lng:22.8363328	, lat:60.3569507 },
        { lng:22.8363301	, lat:60.3570622 },
        { lng:22.836365	, lat:60.3571391 },
        { lng:22.8364803	, lat:60.3571895 },
        { lng:22.8366761	, lat:60.3572479 },
        { lng:22.837049	, lat:60.3573248 },
        { lng:22.8371455	, lat:60.357362 },
        { lng:22.8374754	, lat:60.3575822 },
        { lng:22.8379582	, lat:60.3577626 },
        { lng:22.8381245	, lat:60.3578422 },
        { lng:22.8385295	, lat:60.3580093 },
        { lng:22.8386717	, lat:60.3580889 },
        { lng:22.8388112	, lat:60.3581911 },
        { lng:22.8389909	, lat:60.3582614 },
        { lng:22.8391652	, lat:60.3583675 },
        { lng:22.8393423	, lat:60.3584458 },
        { lng:22.8395059	, lat:60.3585599 },
        { lng:22.8396909	, lat:60.3586448 },
        { lng:22.839927	, lat:60.3587854 },
        { lng:22.8400718	, lat:60.3588411 },
        { lng:22.8403803	, lat:60.3589446 },
        { lng:22.8408362	, lat:60.3591258 },
        { lng:22.8388023	, lat:60.3606141 },
        { lng:22.8378296	, lat:60.3613256 },
        { lng:22.8252507	, lat:60.3690783 },
        { lng:22.8243756	, lat:60.3696174 },
        { lng:22.8227797	, lat:60.3698256 },
        { lng:22.8222942	, lat:60.3699941 },
        { lng:22.822085	, lat:60.3701372 },
        { lng:22.8212053	, lat:60.3700867 },
        { lng:22.8205749	, lat:60.3700059 },
        { lng:22.8200197	, lat:60.3700059 },
        { lng:22.8195879	, lat:60.3699449 },
        { lng:22.8192097	, lat:60.3699621 },
        { lng:22.8189415	, lat:60.3700151 },
        { lng:22.8189093	, lat:60.3701372 },
        { lng:22.8192205	, lat:60.3702459 },
        { lng:22.8196415	, lat:60.370238 },
        { lng:22.8197623	, lat:60.370303 },
        { lng:22.8198024	, lat:60.3704873 },
        { lng:22.8195074	, lat:60.3706954 },
        { lng:22.8200599	, lat:60.371027 },
        { lng:22.8211623	, lat:60.3712048 },
        { lng:22.8215378	, lat:60.37132 },
        { lng:22.8214048	, lat:60.371402 },
        { lng:22.8189315	, lat:60.3729236 },
        { lng:22.8251098	, lat:60.3794821 },
        { lng:22.8257014	, lat:60.38011 },
        { lng:22.832347	, lat:60.3871628 },
        { lng:22.8343451	, lat:60.4005704 },
        { lng:22.8343737	, lat:60.4007628 },
        { lng:22.8345186	, lat:60.4017342 },
        { lng:22.8351714	, lat:60.4061142 },
        { lng:22.8362859	, lat:60.4068498 },
        { lng:22.8389732	, lat:60.4086233 },
        { lng:22.8397606	, lat:60.4091429 },
        { lng:22.8404097	, lat:60.4095712 },
        { lng:22.8404598	, lat:60.4095522 },
        { lng:22.842467	, lat:60.4087845 },
        { lng:22.8466313	, lat:60.4124473 },
        { lng:22.8470333	, lat:60.4128009 },
        { lng:22.8481931	, lat:60.4138209 },
        { lng:22.8483877	, lat:60.413992 },
        { lng:22.8485851	, lat:60.4141655 },
        { lng:22.848746	, lat:60.4144158 },
        { lng:22.8489123	, lat:60.4145416 },
        { lng:22.8491265	, lat:60.4147963 },
        { lng:22.8492561	, lat:60.4149503 },
        { lng:22.8502883	, lat:60.416177 },
        { lng:22.8503486	, lat:60.4162147 },
        { lng:22.850461	, lat:60.4162847 },
        { lng:22.8515391	, lat:60.4169563 },
        { lng:22.8516411	, lat:60.4170198 },
        { lng:22.8517798	, lat:60.4171062 },
        { lng:22.8519207	, lat:60.417194 },
        { lng:22.8520852	, lat:60.4172965 },
        { lng:22.8528901	, lat:60.4177978 },
        { lng:22.8537964	, lat:60.4174965 },
        { lng:22.8540181	, lat:60.4174228 },
        { lng:22.8546979	, lat:60.4171966 },
        { lng:22.8580828	, lat:60.4158936 },
        { lng:22.858115	, lat:60.4159969 },
        { lng:22.8589197	, lat:60.4159095 },
        { lng:22.8591074	, lat:60.4170192 },
        { lng:22.8594355	, lat:60.4170381 },
        { lng:22.8661948	, lat:60.4174247 },
        { lng:22.8664543	, lat:60.4174396 },
        { lng:22.8685059	, lat:60.4175568 },
        { lng:22.869383	, lat:60.4175753 },
        { lng:22.869473	, lat:60.4179642 },
        { lng:22.8695279	, lat:60.4182011 },
        { lng:22.8697211	, lat:60.4190354 },
        { lng:22.8700544	, lat:60.4204751 },
        { lng:22.8704263	, lat:60.422081 },
        { lng:22.8677441	, lat:60.4219381 },
        { lng:22.8707882	, lat:60.426444 },
        { lng:22.8708753	, lat:60.426573 },
        { lng:22.865889	, lat:60.4272855 },
        { lng:22.8657888	, lat:60.4272997 },
        { lng:22.8655876	, lat:60.4268946 },
        { lng:22.8580211	, lat:60.4278887 },
        { lng:22.8576864	, lat:60.4278962 },
        { lng:22.855819	, lat:60.4279377 },
        { lng:22.8558179	, lat:60.4280469 },
        { lng:22.8558164	, lat:60.4281866 },
        { lng:22.8562283	, lat:60.4285333 },
        { lng:22.8641553	, lat:60.4352067 },
        { lng:22.8672238	, lat:60.432708 },
        { lng:22.8685971	, lat:60.4338727 },
        { lng:22.8651424	, lat:60.4360537 },
        { lng:22.8667304	, lat:60.4374146 },
        { lng:22.8676985	, lat:60.4382442 },
        { lng:22.8758927	, lat:60.4452658 },
        { lng:22.8588269	, lat:60.4584893 },
        { lng:22.8562418	, lat:60.4604919 },
        { lng:22.8520979	, lat:60.4637018 },
        { lng:22.8516308	, lat:60.4640636 },
        { lng:22.8515431	, lat:60.4641316 },
        { lng:22.8510877	, lat:60.4644843 },
        { lng:22.8496052	, lat:60.4656325 },
        { lng:22.8459885	, lat:60.4699321 },
        { lng:22.8457278	, lat:60.4702418 },
        { lng:22.8458858	, lat:60.4705145 },
        { lng:22.8477839	, lat:60.4737907 },
        { lng:22.8541917	, lat:60.4848472 },
        { lng:22.8574511	, lat:60.4904702 },
        { lng:22.8462296	, lat:60.5108657 },
        { lng:22.933493	, lat:60.5373067 },
        { lng:23.0651431	, lat:60.5600826 },
        { lng:23.1006686	, lat:60.573577 },
        { lng:23.1118217	, lat:60.5770282 },
        { lng:23.140552	, lat:60.5780626 },
        { lng:23.1610392	, lat:60.5811971 },
        { lng:23.1904238	, lat:60.5711983 },
        { lng:23.1990977	, lat:60.5782588 },
        { lng:23.2000144	, lat:60.5780434 },
        { lng:23.2012669	, lat:60.5791106 },
        { lng:23.2003457	, lat:60.5793698 },
        { lng:23.200557	, lat:60.5795653 },
        { lng:23.2017994	, lat:60.5793244 },
        { lng:23.2048234	, lat:60.5777651 },
        { lng:23.2072329	, lat:60.5793742 },
        { lng:23.2020613	, lat:60.5808546 },
        { lng:23.2075837	, lat:60.5856488 },
        { lng:23.2410732	, lat:60.5865706 },
        { lng:23.2423635	, lat:60.586606 },
        { lng:23.2427227	, lat:60.586575 },
        { lng:23.2435986	, lat:60.586499 },
        { lng:23.244408	, lat:60.5864288 },
        { lng:23.2504669	, lat:60.5858587 },
        { lng:23.258769	, lat:60.5851831 },
        { lng:23.2672001	, lat:60.5844516 },
        { lng:23.2931472	, lat:60.5821375 },
        { lng:23.3002906	, lat:60.5815002 },
        { lng:23.3043193	, lat:60.579893 },
        { lng:23.3033108	, lat:60.5788798 },
        { lng:23.3032974	, lat:60.5786426 },
        { lng:23.3035709	, lat:60.5785728 },
        { lng:23.3041342	, lat:60.5787046 },
        { lng:23.3050891	, lat:60.5791446 },
        { lng:23.3053868	, lat:60.5794635 },
        { lng:23.3142877	, lat:60.5759046 },
        { lng:23.3201389	, lat:60.573563 },
        { lng:23.3216517	, lat:60.5736421 },
        { lng:23.3244734	, lat:60.5725219 },
        { lng:23.3268391	, lat:60.5713148 },
        { lng:23.3264636	, lat:60.571067 },
        { lng:23.3301758	, lat:60.5695883 },
        { lng:23.3309912	, lat:60.5704107 },
        { lng:23.3350735	, lat:60.5691152 },
        { lng:23.3356958	, lat:60.5691771 },
        { lng:23.3376725	, lat:60.5692746 },
        { lng:23.3389949	, lat:60.5690888 },
        { lng:23.339239	, lat:60.5688753 },
        { lng:23.3393597	, lat:60.5679962 },
        { lng:23.3391075	, lat:60.5673714 },
        { lng:23.3378952	, lat:60.5670235 },
        { lng:23.3368652	, lat:60.5668205 },
        { lng:23.3452176	, lat:60.5629373 },
        { lng:23.3448153	, lat:60.5626472 },
        { lng:23.3446141	, lat:60.5622017 },
        { lng:23.3450406	, lat:60.56159 },
        { lng:23.3461188	, lat:60.5609586 },
        { lng:23.3469503	, lat:60.5613105 },
        { lng:23.3457916	, lat:60.5618906 },
        { lng:23.3465372	, lat:60.562315 },
        { lng:23.3525454	, lat:60.5595888 },
        { lng:23.3528324	, lat:60.5598472 },
        { lng:23.3531543	, lat:60.5597694 },
        { lng:23.3532911	, lat:60.5597167 },
        { lng:23.3530201	, lat:60.5593739 },
        { lng:23.3655112	, lat:60.5541356 },
        { lng:23.3668469	, lat:60.5547606 },
        { lng:23.368408	, lat:60.5541409 },
        { lng:23.3675175	, lat:60.5536689 },
        { lng:23.3790349	, lat:60.5509444 },
        { lng:23.3793728	, lat:60.5512083 },
        { lng:23.3796598	, lat:60.55113 },
        { lng:23.382672	, lat:60.5503089 },
        { lng:23.382511	, lat:60.5501058 },
        { lng:23.3863654	, lat:60.5491682 },
        { lng:23.3864592	, lat:60.5492776 },
        { lng:23.3866577	, lat:60.5491669 },
        { lng:23.386993	, lat:60.5492328 },
        { lng:23.3871486	, lat:60.5490587 },
        { lng:23.3872639	, lat:60.5490798 },
        { lng:23.3873524	, lat:60.5490007 },
        { lng:23.38717	, lat:60.5489743 },
        { lng:23.3871754	, lat:60.5489097 },
        { lng:23.3873202	, lat:60.5488899 },
        { lng:23.3877038	, lat:60.548882 },
        { lng:23.3879237	, lat:60.5485259 },
        { lng:23.3888706	, lat:60.548431 },
        { lng:23.3889886	, lat:60.5483294 },
        { lng:23.3893024	, lat:60.5484376 },
        { lng:23.3896055	, lat:60.5481461 },
        { lng:23.3896323	, lat:60.5480709 },
        { lng:23.3895626	, lat:60.548034 },
        { lng:23.3893158	, lat:60.5480314 },
        { lng:23.3895223	, lat:60.5478124 },
        { lng:23.3897718	, lat:60.5478045 },
        { lng:23.389702	, lat:60.5479193 },
        { lng:23.3898201	, lat:60.5479219 },
        { lng:23.390327	, lat:60.5477821 },
        { lng:23.390665	, lat:60.5475988 },
        { lng:23.3910431	, lat:60.5477953 },
        { lng:23.392285	, lat:60.5475632 },
        { lng:23.3923172	, lat:60.5475249 },
        { lng:23.3921375	, lat:60.5474656 },
        { lng:23.3923038	, lat:60.5473759 },
        { lng:23.3923838	, lat:60.5474 },
        { lng:23.3927195	, lat:60.5475012 },
        { lng:23.3930012	, lat:60.5473957 },
        { lng:23.3929502	, lat:60.5471662 },
        { lng:23.3932265	, lat:60.5471649 },
        { lng:23.3934464	, lat:60.5472836 },
        { lng:23.3938863	, lat:60.5471385 },
        { lng:23.3938246	, lat:60.5470079 },
        { lng:23.3944174	, lat:60.5469011 },
        { lng:23.3959328	, lat:60.5464158 },
        { lng:23.3964317	, lat:60.5461229 },
        { lng:23.4535336	, lat:60.5742949 },
        { lng:23.4683066	, lat:60.5824075 },
        { lng:23.4744185	, lat:60.5853481 },
        { lng:23.4757087	, lat:60.5854511 },
        { lng:23.4849499	, lat:60.5897518 },
        { lng:23.4920783	, lat:60.5950417 },
        { lng:23.4981101	, lat:60.5947803 },
        { lng:23.5156409	, lat:60.5955009 },
        { lng:23.4966463	, lat:60.5353264 },
        { lng:23.5192236	, lat:60.5171019 },
        { lng:23.5384055	, lat:60.5091942 },
        { lng:23.6106163	, lat:60.5012344 },
        { lng:23.6012361	, lat:60.5005746 },
        { lng:23.5978569	, lat:60.5001729 },
        { lng:23.5959343	, lat:60.4971317 },
        { lng:23.5935455	, lat:60.4970169 },
        { lng:23.5941864	, lat:60.5004885 },
        { lng:23.5867289	, lat:60.5026975 },
        { lng:23.5824175	, lat:60.502927 },
        { lng:23.5802618	, lat:60.5034433 },
        { lng:23.5746905	, lat:60.5026222 },
        { lng:23.5861004	, lat:60.483734 },
        { lng:23.5911642	, lat:60.4845723 },
        { lng:23.6057768	, lat:60.4867418 },
        { lng:23.6046898	, lat:60.4876 },
        { lng:23.6048591	, lat:60.4879159 },
        { lng:23.6060838	, lat:60.4873718 },
        { lng:23.6060571	, lat:60.4871546 },
        { lng:23.6065875	, lat:60.4868622 },
        { lng:23.6169223	, lat:60.4883965 },
        { lng:23.6191968	, lat:60.4850752 },
        { lng:23.6426447	, lat:60.4673029 },
        { lng:23.6433133	, lat:60.4676291 },
        { lng:23.6643181	, lat:60.4768879 },
        { lng:23.6741165	, lat:60.481188 },
        { lng:23.6937323	, lat:60.4897948 },
        { lng:23.7111343	, lat:60.497405 },
        { lng:23.7126664	, lat:60.4979895 },
        { lng:23.7281645	, lat:60.4932267 },
        { lng:23.7374695	, lat:60.4903668 },
        { lng:23.7210221	, lat:60.4800862 },
        { lng:23.7094309	, lat:60.4728391 },
        { lng:23.7151714	, lat:60.4648374 },
        { lng:23.6971927	, lat:60.4515789 },
        { lng:23.696833	, lat:60.4515212 },
        { lng:23.6963982	, lat:60.4514428 },
        { lng:23.6959046	, lat:60.4513644 },
        { lng:23.6954363	, lat:60.4512283 },
        { lng:23.695085	, lat:60.4510509 },
        { lng:23.6948507	, lat:60.4509189 },
        { lng:23.6946082	, lat:60.4508818 },
        { lng:23.6941566	, lat:60.4508488 },
        { lng:23.6938053	, lat:60.4508034 },
        { lng:23.6933451	, lat:60.4506838 },
        { lng:23.6927598	, lat:60.4504899 },
        { lng:23.6926176	, lat:60.450362 },
        { lng:23.6925339	, lat:60.450197 },
        { lng:23.6922411	, lat:60.4499042 },
        { lng:23.6947684	, lat:60.449912 },
        { lng:23.7841303	, lat:60.4501875 },
        { lng:23.7866104	, lat:60.4441725 },
        { lng:23.7922044	, lat:60.4312278 },
        { lng:23.792386	, lat:60.4308072 },
        { lng:23.7962515	, lat:60.4297145 },
        { lng:23.7953408	, lat:60.4293145 },
        { lng:23.7952239	, lat:60.4293446 },
        { lng:23.7948269	, lat:60.4292776 },
        { lng:23.7940577	, lat:60.4289148 },
        { lng:23.7938585	, lat:60.428485 },
        { lng:23.7934968	, lat:60.4282373 },
        { lng:23.7933418	, lat:60.4279423 },
        { lng:23.7930733	, lat:60.4277711 },
        { lng:23.790945	, lat:60.4235722 },
        { lng:23.7843857	, lat:60.404837 },
        { lng:23.7899434	, lat:60.3892615 },
        { lng:23.7900164	, lat:60.3890687 },
        { lng:23.790487	, lat:60.3878263 },
        { lng:23.7902291	, lat:60.38781 },
        { lng:23.7898781	, lat:60.3877879 },
        { lng:23.7896934	, lat:60.3877762 },
        { lng:23.7887329	, lat:60.3877044 },
        { lng:23.7885106	, lat:60.3876878 },
        { lng:23.7880697	, lat:60.3876548 },
        { lng:23.7883909	, lat:60.3870339 },
        { lng:23.7884265	, lat:60.3869651 },
        { lng:23.7866892	, lat:60.3861067 },
        { lng:23.7814363	, lat:60.3855411 },
        { lng:23.7801915	, lat:60.386003 },
        { lng:23.7796352	, lat:60.3862621 },
        { lng:23.7780145	, lat:60.3869059 },
        { lng:23.7776977	, lat:60.3870448 },
        { lng:23.777595	, lat:60.387133 },
        { lng:23.777537	, lat:60.3872542 },
        { lng:23.7775013	, lat:60.387402 },
        { lng:23.777363	, lat:60.3874372 },
        { lng:23.7772113	, lat:60.3874549 },
        { lng:23.7771469	, lat:60.3874643 },
        { lng:23.7770462	, lat:60.3874791 },
        { lng:23.7769034	, lat:60.387488 },
        { lng:23.7766	, lat:60.3875673 },
        { lng:23.7764662	, lat:60.3875541 },
        { lng:23.7762698	, lat:60.3875894 },
        { lng:23.7762573	, lat:60.3876476 },
        { lng:23.7762454	, lat:60.3877026 },
        { lng:23.7762223	, lat:60.3878102 },
        { lng:23.7762029	, lat:60.3879003 },
        { lng:23.7760824	, lat:60.3879223 },
        { lng:23.7758058	, lat:60.3879135 },
        { lng:23.774744	, lat:60.3879546 },
        { lng:23.7745297	, lat:60.3879753 },
        { lng:23.7743619	, lat:60.3879799 },
        { lng:23.7737562	, lat:60.3880259 },
        { lng:23.7728428	, lat:60.388118 },
        { lng:23.7724607	, lat:60.3881342 },
        { lng:23.7716538	, lat:60.3881961 },
        { lng:23.7675248	, lat:60.3885131 },
        { lng:23.7670927	, lat:60.3885463 },
        { lng:23.7665009	, lat:60.3876691 },
        { lng:23.7665324	, lat:60.3875379 },
        { lng:23.7668537	, lat:60.3862003 },
        { lng:23.7668737	, lat:60.3861171 },
        { lng:23.767333	, lat:60.3862354 },
        { lng:23.7691709	, lat:60.3867089 },
        { lng:23.7704093	, lat:60.3868254 },
        { lng:23.7716919	, lat:60.3869461 },
        { lng:23.7720786	, lat:60.3867895 },
        { lng:23.7727483	, lat:60.3871579 },
        { lng:23.7735789	, lat:60.3876147 },
        { lng:23.7738074	, lat:60.3877404 },
        { lng:23.7755735	, lat:60.3878878 },
        { lng:23.7516077	, lat:60.3738896 },
        { lng:23.7457361	, lat:60.3715777 },
        { lng:23.741665	, lat:60.3700029 },
        { lng:23.7231792	, lat:60.3687584 },
        { lng:23.723551	, lat:60.3671209 },
        { lng:23.723627	, lat:60.3659034 },
        { lng:23.7243447	, lat:60.3638344 },
        { lng:23.7236704	, lat:60.3626096 },
        { lng:23.7234466	, lat:60.3618817 },
        { lng:23.7226001	, lat:60.3599531 },
        { lng:23.7232316	, lat:60.3599703 },
        { lng:23.7234156	, lat:60.3598425 },
        { lng:23.7312427	, lat:60.3588981 },
        { lng:23.7339481	, lat:60.3557029 },
        { lng:23.7337675	, lat:60.343245 },
        { lng:23.7353147	, lat:60.3379311 },
        { lng:23.7288631	, lat:60.3289909 },
        { lng:23.7301163	, lat:60.3180295 },
        { lng:23.7309572	, lat:60.3178731 },
        { lng:23.7311041	, lat:60.3174512 },
        { lng:23.7301971	, lat:60.3171548 },
        { lng:23.7307379	, lat:60.3134209 },
        { lng:23.7277892	, lat:60.3077347 },
        { lng:23.7168225	, lat:60.3030058 },
        { lng:23.7012821	, lat:60.2983256 },
        { lng:23.6947324	, lat:60.2974364 },
        { lng:23.6897856	, lat:60.2936892 },
        { lng:23.6866257	, lat:60.2936601 },
        { lng:23.6862605	, lat:60.2938553 },
        { lng:23.6739486	, lat:60.3030604 },
        { lng:23.6656241	, lat:60.2982482 },
        { lng:23.6562422	, lat:60.2924248 },
        { lng:23.6547833	, lat:60.2914546 },
        { lng:23.6522996	, lat:60.2905703 },
        { lng:23.6490511	, lat:60.2875976 },
        { lng:23.6489752	, lat:60.2875358 },
        { lng:23.6489643	, lat:60.2874605 },
        { lng:23.6488071	, lat:60.2873584 },
        { lng:23.6487311	, lat:60.2872616 },
        { lng:23.6486932	, lat:60.2871971 },
        { lng:23.6485901	, lat:60.287095 },
        { lng:23.6484383	, lat:60.2869874 },
        { lng:23.6484058	, lat:60.2869122 },
        { lng:23.6481726	, lat:60.2866407 },
        { lng:23.6481997	, lat:60.2865869 },
        { lng:23.6481834	, lat:60.2865654 },
        { lng:23.6480695	, lat:60.286474 },
        { lng:23.6479556	, lat:60.2864149 },
        { lng:23.6478201	, lat:60.2863477 },
        { lng:23.6477441	, lat:60.2862778 },
        { lng:23.6476899	, lat:60.2861622 },
        { lng:23.6476682	, lat:60.2860386 },
        { lng:23.6476194	, lat:60.2858934 },
        { lng:23.6474784	, lat:60.2857805 },
        { lng:23.6474025	, lat:60.2856219 },
        { lng:23.6429719	, lat:60.2828085 },
        { lng:23.6294336	, lat:60.2826667 },
        { lng:23.6261631	, lat:60.2692965 },
        { lng:23.6271541	, lat:60.2605135 },
        { lng:23.6260115	, lat:60.2606513 },
        { lng:23.6258499	, lat:60.2606126 },
        { lng:23.6255257	, lat:60.2602275 },
        { lng:23.6252043	, lat:60.2601962 },
        { lng:23.6236313	, lat:60.2604327 },
        { lng:23.6231868	, lat:60.2603675 },
        { lng:23.6229752	, lat:60.2603763 },
        { lng:23.6227984	, lat:60.2604265 },
        { lng:23.6223675	, lat:60.2605519 },
        { lng:23.6221708	, lat:60.2607706 },
        { lng:23.6218903	, lat:60.2609446 },
        { lng:23.6209984	, lat:60.2612137 },
        { lng:23.6204829	, lat:60.2613352 },
        { lng:23.6198199	, lat:60.2705376 },
        { lng:23.6214817	, lat:60.26968 },
        { lng:23.621932	, lat:60.2697645 },
        { lng:23.6216623	, lat:60.2700418 },
        { lng:23.6215875	, lat:60.270078 },
        { lng:23.6215907	, lat:60.2701578 },
        { lng:23.6213729	, lat:60.2703412 },
        { lng:23.6200972	, lat:60.2707309 },
        { lng:23.6199322	, lat:60.2709405 },
        { lng:23.6195983	, lat:60.2710817 },
        { lng:23.6193376	, lat:60.271151 },
        { lng:23.6184585	, lat:60.271221 },
        { lng:23.6179975	, lat:60.2712288 },
        { lng:23.6175355	, lat:60.2712001 },
        { lng:23.6168229	, lat:60.2710817 },
        { lng:23.614582	, lat:60.2710578 },
        { lng:23.6144475	, lat:60.2711733 },
        { lng:23.6141962	, lat:60.2712636 },
        { lng:23.6139651	, lat:60.2712742 },
        { lng:23.6136392	, lat:60.2711671 },
        { lng:23.6134516	, lat:60.270885 },
        { lng:23.6136146	, lat:60.2707074 },
        { lng:23.6141305	, lat:60.2703999 },
        { lng:23.6144848	, lat:60.2702947 },
        { lng:23.6131991	, lat:60.2608081 },
        { lng:23.6128927	, lat:60.2609858 },
        { lng:23.612704	, lat:60.2610185 },
        { lng:23.6122757	, lat:60.2610693 },
        { lng:23.611359	, lat:60.2613662 },
        { lng:23.6107833	, lat:60.2617636 },
        { lng:23.6101758	, lat:60.2619914 },
        { lng:23.6100455	, lat:60.2621399 },
        { lng:23.6099318	, lat:60.2623561 },
        { lng:23.6094052	, lat:60.2628506 },
        { lng:23.6088733	, lat:60.2630364 },
        { lng:23.6086029	, lat:60.2630949 },
        { lng:23.6079207	, lat:60.263426 },
        { lng:23.60751	, lat:60.2635349 },
        { lng:23.6070751	, lat:60.2635136 },
        { lng:23.6067537	, lat:60.2634433 },
        { lng:23.6065402	, lat:60.2634761 },
        { lng:23.6048825	, lat:60.2638221 },
        { lng:23.6042989	, lat:60.2638502 },
        { lng:23.6039706	, lat:60.2639159 },
        { lng:23.6036311	, lat:60.2639301 },
        { lng:23.6032949	, lat:60.2638627 },
        { lng:23.6026634	, lat:60.2638564 },
        { lng:23.6024361	, lat:60.2638157 },
        { lng:23.6018692	, lat:60.263817 },
        { lng:23.5989257	, lat:60.266391 },
        { lng:23.5950376	, lat:60.2658092 },
        { lng:23.5944407	, lat:60.2660332 },
        { lng:23.5935493	, lat:60.2662697 },
        { lng:23.593466	, lat:60.26633 },
        { lng:23.5935246	, lat:60.2664337 },
        { lng:23.5939123	, lat:60.2665598 },
        { lng:23.5939246	, lat:60.2666766 },
        { lng:23.5937488	, lat:60.2667887 },
        { lng:23.5935309	, lat:60.2668751 },
        { lng:23.5934678	, lat:60.2670817 },
        { lng:23.5936484	, lat:60.2672541 },
        { lng:23.5933922	, lat:60.2672334 },
        { lng:23.5933103	, lat:60.2670709 },
        { lng:23.5933999	, lat:60.2668755 },
        { lng:23.5936701	, lat:60.2667242 },
        { lng:23.593687	, lat:60.2665811 },
        { lng:23.5934531	, lat:60.2664717 },
        { lng:23.5932438	, lat:60.2663911 },
        { lng:23.5931886	, lat:60.2663517 },
        { lng:23.5929503	, lat:60.2662807 },
        { lng:23.593142	, lat:60.2661472 },
        { lng:23.5934334	, lat:60.2661382 },
        { lng:23.5939702	, lat:60.2660411 },
        { lng:23.5945278	, lat:60.2658863 },
        { lng:23.5951511	, lat:60.2654084 },
        { lng:23.5952705	, lat:60.265406 },
        { lng:23.5956626	, lat:60.2651735 },
        { lng:23.5960369	, lat:60.2650179 },
        { lng:23.5961677	, lat:60.2648634 },
        { lng:23.5961299	, lat:60.2647131 },
        { lng:23.5958323	, lat:60.2645633 },
        { lng:23.5957004	, lat:60.26445 },
        { lng:23.5954519	, lat:60.2640013 },
        { lng:23.5931962	, lat:60.2636097 },
        { lng:23.5930108	, lat:60.2635776 },
        { lng:23.5928885	, lat:60.2634388 },
        { lng:23.5930919	, lat:60.2633092 },
        { lng:23.5931186	, lat:60.2631496 },
        { lng:23.5928145	, lat:60.2629559 },
        { lng:23.5923978	, lat:60.2628369 },
        { lng:23.5921735	, lat:60.2627036 },
        { lng:23.5918294	, lat:60.262627 },
        { lng:23.5916879	, lat:60.2624548 },
        { lng:23.5913353	, lat:60.2622211 },
        { lng:23.5911979	, lat:60.2621979 },
        { lng:23.5909138	, lat:60.2622104 },
        { lng:23.5907662	, lat:60.2621773 },
        { lng:23.589796	, lat:60.261468 },
        { lng:23.5895529	, lat:60.2612206 },
        { lng:23.5891046	, lat:60.260995 },
        { lng:23.5887636	, lat:60.2606724 },
        { lng:23.5883278	, lat:60.260403 },
        { lng:23.5879426	, lat:60.2601148 },
        { lng:23.5878416	, lat:60.2599895 },
        { lng:23.5874753	, lat:60.2597013 },
        { lng:23.5870459	, lat:60.259507 },
        { lng:23.5863479	, lat:60.258991 },
        { lng:23.5863067	, lat:60.2588564 },
        { lng:23.5864986	, lat:60.2587327 },
        { lng:23.5864977	, lat:60.2586355 },
        { lng:23.5866408	, lat:60.2585615 },
        { lng:23.5869242	, lat:60.2585891 },
        { lng:23.5870314	, lat:60.2585417 },
        { lng:23.587057	, lat:60.258456 },
        { lng:23.5869937	, lat:60.2583484 },
        { lng:23.5871726	, lat:60.2582537 },
        { lng:23.5872243	, lat:60.2581381 },
        { lng:23.587458	, lat:60.2580911 },
        { lng:23.5876916	, lat:60.2581631 },
        { lng:23.5881273	, lat:60.2580503 },
        { lng:23.5886114	, lat:60.2580507 },
        { lng:23.5920855	, lat:60.2566615 },
        { lng:23.5927869	, lat:60.256488 },
        { lng:23.5936827	, lat:60.2564353 },
        { lng:23.5946022	, lat:60.2563567 },
        { lng:23.5953326	, lat:60.2562333 },
        { lng:23.5956294	, lat:60.2560641 },
        { lng:23.5979869	, lat:60.2555699 },
        { lng:23.5988459	, lat:60.2551572 },
        { lng:23.598487	, lat:60.2530638 },
        { lng:23.5994602	, lat:60.2517936 },
        { lng:23.6007737	, lat:60.2507719 },
        { lng:23.6010413	, lat:60.2506781 },
        { lng:23.6023988	, lat:60.250613 },
        { lng:23.603335	, lat:60.250836 },
        { lng:23.6052548	, lat:60.2508342 },
        { lng:23.6070417	, lat:60.2506751 },
        { lng:23.6104884	, lat:60.249681 },
        { lng:23.6112197	, lat:60.2505165 },
        { lng:23.6121057	, lat:60.250652 },
        { lng:23.6127682	, lat:60.2500214 },
        { lng:23.6123553	, lat:60.2491661 },
        { lng:23.6330029	, lat:60.2433839 },
        { lng:23.6457552	, lat:60.2363438 },
        { lng:23.6414087	, lat:60.2353052 },
        { lng:23.6334268	, lat:60.2347576 },
        { lng:23.6325202	, lat:60.2329731 },
        { lng:23.6326994	, lat:60.2300416 },
        { lng:23.6372282	, lat:60.2192952 },
        { lng:23.6474953	, lat:60.211073 },
        { lng:23.6438387	, lat:60.2100889 },
        { lng:23.6435732	, lat:60.2098248 },
        { lng:23.643511	, lat:60.2096036 },
        { lng:23.6437972	, lat:60.2091245 },
        { lng:23.6437513	, lat:60.2087697 },
        { lng:23.6436374	, lat:60.2086454 },
        { lng:23.643313	, lat:60.2085689 },
        { lng:23.643167	, lat:60.2085769 },
        { lng:23.6430583	, lat:60.2085264 },
        { lng:23.6489433	, lat:60.2015641 },
        { lng:23.6204355	, lat:60.1941517 },
        { lng:23.6084771	, lat:60.1914766 },
        { lng:23.6070723	, lat:60.1916709 },
        { lng:23.5874086	, lat:60.1995472 },
        { lng:23.5536231	, lat:60.2021951 },
        { lng:23.5432142	, lat:60.1988491 },
        { lng:23.5385939	, lat:60.1982927 },
        { lng:23.5005753	, lat:60.183895 },
        { lng:23.4702032	, lat:60.1726022 },
        { lng:23.4445871	, lat:60.1699917 },
        { lng:23.4254121	, lat:60.1681581 },
        { lng:23.4153886	, lat:60.1651986 },
        { lng:23.3792207	, lat:60.1670544 },
        { lng:23.3629433	, lat:60.1499023 },
        { lng:23.3463142	, lat:60.1576395 },
        { lng:23.3159622	, lat:60.1715754 },
        { lng:23.3160884	, lat:60.1716688 },
        { lng:23.3155229	, lat:60.1719833 },
        { lng:23.3155493	, lat:60.1726258 },
        { lng:23.3169595	, lat:60.1740472 },
        { lng:23.3160789	, lat:60.1750644 },
        { lng:23.3180246	, lat:60.179657 },
        { lng:23.3191647	, lat:60.1801649 },
        { lng:23.3192474	, lat:60.1802141 },
        { lng:23.3190268	, lat:60.1810172 },
        { lng:23.3190097	, lat:60.1811477 },
        { lng:23.3194044	, lat:60.1813649 },
        { lng:23.3215777	, lat:60.182819 },
        { lng:23.3222249	, lat:60.1831567 },
        { lng:23.3238227	, lat:60.1838622 },
        { lng:23.32427	, lat:60.1840597 },
        { lng:23.3272515	, lat:60.186095 },
        { lng:23.3273947	, lat:60.1862595 },
        { lng:23.3273068	, lat:60.1864184 },
        { lng:23.3265797	, lat:60.1869468 },
        { lng:23.3264617	, lat:60.1872463 },
        { lng:23.3263367	, lat:60.1875634 },
        { lng:23.3258602	, lat:60.1885288 },
        { lng:23.32443	, lat:60.1883883 },
        { lng:23.3234063	, lat:60.1882245 },
        { lng:23.3228184	, lat:60.1882656 },
        { lng:23.3223431	, lat:60.1882461 },
        { lng:23.3220272	, lat:60.1881858 },
        { lng:23.3214808	, lat:60.1880768 },
        { lng:23.3206394	, lat:60.1880072 },
        { lng:23.3194276	, lat:60.1877985 },
        { lng:23.3191062	, lat:60.1877168 },
        { lng:23.318505	, lat:60.1873738 },
        { lng:23.3181533	, lat:60.1872473 },
        { lng:23.3177919	, lat:60.1871693 },
        { lng:23.3171649	, lat:60.1871656 },
        { lng:23.3164351	, lat:60.1873205 },
        { lng:23.3155708	, lat:60.1877211 },
        { lng:23.3145854	, lat:60.187983 },
        { lng:23.314165	, lat:60.1880418 },
        { lng:23.3137519	, lat:60.1880586 },
        { lng:23.3115763	, lat:60.1884987 },
        { lng:23.3110747	, lat:60.1885668 },
        { lng:23.3096338	, lat:60.1885931 },
        { lng:23.3077084	, lat:60.1887202 },
        { lng:23.3066068	, lat:60.1887242 },
        { lng:23.3059083	, lat:60.1886578 },
        { lng:23.305049	, lat:60.1884399 },
        { lng:23.3043723	, lat:60.1882311 },
        { lng:23.3035543	, lat:60.1880556 },
        { lng:23.3028559	, lat:60.1878772 },
        { lng:23.3024591	, lat:60.1877954 },
        { lng:23.3018165	, lat:60.1877138 },
        { lng:23.3013214	, lat:60.1876351 },
        { lng:23.3006031	, lat:60.1876007 },
        { lng:23.2999166	, lat:60.1874276 },
        { lng:23.2992952	, lat:60.1872056 },
        { lng:23.2990074	, lat:60.1870283 },
        { lng:23.2984921	, lat:60.1867669 },
        { lng:23.2977186	, lat:60.1864558 },
        { lng:23.2967824	, lat:60.1859139 },
        { lng:23.2964776	, lat:60.1856416 },
        { lng:23.2957153	, lat:60.184652 },
        { lng:23.2949072	, lat:60.1836559 },
        { lng:23.294519	, lat:60.1833334 },
        { lng:23.2939725	, lat:60.183026 },
        { lng:23.2927299	, lat:60.1824099 },
        { lng:23.2925409	, lat:60.1822265 },
        { lng:23.2921578	, lat:60.1814773 },
        { lng:23.2915833	, lat:60.1811051 },
        { lng:23.2910636	, lat:60.1809084 },
        { lng:23.2909733	, lat:60.1807952 },
        { lng:23.2908379	, lat:60.1803623 },
        { lng:23.2908865	, lat:60.1801566 },
        { lng:23.2908417	, lat:60.1800253 },
        { lng:23.2905228	, lat:60.1798439 },
        { lng:23.2897596	, lat:60.179235 },
        { lng:23.2894432	, lat:60.1789645 },
        { lng:23.2891633	, lat:60.1788404 },
        { lng:23.2887874	, lat:60.1787436 },
        { lng:23.2882763	, lat:60.1787618 },
        { lng:23.2881851	, lat:60.1788223 },
        { lng:23.2879417	, lat:60.1792186 },
        { lng:23.2877166	, lat:60.1792882 },
        { lng:23.286956	, lat:60.1792277 },
        { lng:23.2863598	, lat:60.1792132 },
        { lng:23.2861954	, lat:60.1791611 },
        { lng:23.286092	, lat:60.1789675 },
        { lng:23.2858	, lat:60.1788374 },
        { lng:23.2853569	, lat:60.1787019 },
        { lng:23.2850861	, lat:60.1785429 },
        { lng:23.2849177	, lat:60.1783866 },
        { lng:23.2847677	, lat:60.1781637 },
        { lng:23.2845264	, lat:60.1779913 },
        { lng:23.2842921	, lat:60.1778783 },
        { lng:23.2839514	, lat:60.1778006 },
        { lng:23.2831636	, lat:60.1776948 },
        { lng:23.2825816	, lat:60.1775445 },
        { lng:23.2821262	, lat:60.1773312 },
        { lng:23.2820398	, lat:60.1771913 },
        { lng:23.2820208	, lat:60.1769429 },
        { lng:23.2819467	, lat:60.1767133 },
        { lng:23.2817903	, lat:60.1765952 },
        { lng:23.2814813	, lat:60.1764593 },
        { lng:23.2811454	, lat:60.1763532 },
        { lng:23.2809205	, lat:60.1761486 },
        { lng:23.2807098	, lat:60.1758002 },
        { lng:23.2805697	, lat:60.1753255 },
        { lng:23.2802978	, lat:60.1749982 },
        { lng:23.2801026	, lat:60.174816 },
        { lng:23.2799808	, lat:60.1738225 },
        { lng:23.2798009	, lat:60.1736398 },
        { lng:23.2795549	, lat:60.1735605 },
        { lng:23.2792529	, lat:60.1735004 },
        { lng:23.2786487	, lat:60.1731968 },
        { lng:23.2778896	, lat:60.1729325 },
        { lng:23.2775263	, lat:60.1726489 },
        { lng:23.2775745	, lat:60.1724849 },
        { lng:23.2776259	, lat:60.1723819 },
        { lng:23.2777083	, lat:60.1712793 },
        { lng:23.2778163	, lat:60.1709956 },
        { lng:23.277808	, lat:60.1706535 },
        { lng:23.2780252	, lat:60.1702118 },
        { lng:23.2779481	, lat:60.1698193 },
        { lng:23.2777831	, lat:60.1694237 },
        { lng:23.2779637	, lat:60.1688319 },
        { lng:23.2778348	, lat:60.1685523 },
        { lng:23.2775985	, lat:60.1682223 },
        { lng:23.2772973	, lat:60.1681566 },
        { lng:23.2770195	, lat:60.1678887 },
        { lng:23.2768177	, lat:60.1674843 },
        { lng:23.276808	, lat:60.1672348 },
        { lng:23.2769313	, lat:60.1670041 },
        { lng:23.2772011	, lat:60.1667499 },
        { lng:23.2775915	, lat:60.1664992 },
        { lng:23.2778611	, lat:60.1663508 },
        { lng:23.2776727	, lat:60.1661003 },
        { lng:23.2772802	, lat:60.1658348 },
        { lng:23.2767737	, lat:60.1655838 },
        { lng:23.2759234	, lat:60.1656764 },
        { lng:23.2757269	, lat:60.1656966 },
        { lng:23.2754876	, lat:60.1656539 },
        { lng:23.2754694	, lat:60.1651968 },
        { lng:23.2752419	, lat:60.1648041 },
        { lng:23.2748482	, lat:60.1644256 },
        { lng:23.2747592	, lat:60.1641861 },
        { lng:23.2749012	, lat:60.1639318 },
        { lng:23.2755898	, lat:60.1635611 },
        { lng:23.275753	, lat:60.1632926 },
        { lng:23.2757602	, lat:60.1629784 },
        { lng:23.2755897	, lat:60.1625792 },
        { lng:23.2756111	, lat:60.1621732 },
        { lng:23.2757459	, lat:60.1617069 },
        { lng:23.2760583	, lat:60.1612973 },
        { lng:23.276626	, lat:60.1608204 },
        { lng:23.2772721	, lat:60.1604002 },
        { lng:23.2776908	, lat:60.1602377 },
        { lng:23.278024	, lat:60.1600698 },
        { lng:23.2779961	, lat:60.1597397 },
        { lng:23.2777665	, lat:60.1594292 },
        { lng:23.2774424	, lat:60.1589627 },
        { lng:23.277194	, lat:60.1587578 },
        { lng:23.2767539	, lat:60.1586695 },
        { lng:23.2760086	, lat:60.1585988 },
        { lng:23.2753272	, lat:60.1584718 },
        { lng:23.2754122	, lat:60.1582597 },
        { lng:23.2758098	, lat:60.1579984 },
        { lng:23.2765551	, lat:60.1575886 },
        { lng:23.2775205	, lat:60.1571471 },
        { lng:23.2781592	, lat:60.1569775 },
        { lng:23.2785923	, lat:60.1569035 },
        { lng:23.278649	, lat:60.1565466 },
        { lng:23.2785496	, lat:60.156151 },
        { lng:23.2784645	, lat:60.1557872 },
        { lng:23.2784504	, lat:60.155374 },
        { lng:23.2784503	, lat:60.1551301 },
        { lng:23.2782801	, lat:60.1549023 },
        { lng:23.2780372	, lat:60.1547087 },
        { lng:23.2776375	, lat:60.1536483 },
        { lng:23.276138	, lat:60.1534247 },
        { lng:23.2755705	, lat:60.1533402 },
        { lng:23.2743643	, lat:60.1534059 },
        { lng:23.2715098	, lat:60.1542854 },
        { lng:23.2687289	, lat:60.1534835 },
        { lng:23.2644685	, lat:60.1517448 },
        { lng:23.2530081	, lat:60.1480576 },
        { lng:23.2412978	, lat:60.1437663 },
        { lng:23.2329672	, lat:60.1399108 },
        { lng:23.2271335	, lat:60.1393143 },
        { lng:23.2241798	, lat:60.1379081 },
        { lng:23.2150952	, lat:60.1362732 },
        { lng:23.2145983	, lat:60.1356248 },
        { lng:23.21459	, lat:60.135279 },
        { lng:23.2146643	, lat:60.1351616 },
        { lng:23.2151703	, lat:60.1344851 },
        { lng:23.2156884	, lat:60.1337641 },
        { lng:23.2157641	, lat:60.1335925 },
        { lng:23.2157576	, lat:60.1331603 },
        { lng:23.2156079	, lat:60.1329413 },
        { lng:23.215351	, lat:60.1328043 },
        { lng:23.2143258	, lat:60.1323883 },
        { lng:23.2135748	, lat:60.1322227 },
        { lng:23.2134192	, lat:60.1321051 },
        { lng:23.213258	, lat:60.1320598 },
        { lng:23.2128238	, lat:60.1320624 },
        { lng:23.2123728	, lat:60.1319691 },
        { lng:23.2118474	, lat:60.1319555 },
        { lng:23.2113056	, lat:60.1318807 },
        { lng:23.210796	, lat:60.1318941 },
        { lng:23.2104241	, lat:60.1318568 },
        { lng:23.2097111	, lat:60.1316933 },
        { lng:23.2092434	, lat:60.1314009 },
        { lng:23.2088434	, lat:60.1312235 },
        { lng:23.2084275	, lat:60.1311577 },
        { lng:23.2077857	, lat:60.1311857 },
        { lng:23.2070056	, lat:60.1313142 },
        { lng:23.2059148	, lat:60.1316047 },
        { lng:23.2052707	, lat:60.1319689 },
        { lng:23.2045085	, lat:60.1322881 },
        { lng:23.2043775	, lat:60.1324144 },
        { lng:23.204175	, lat:60.1329141 },
        { lng:23.2040442	, lat:60.1329972 },
        { lng:23.2036187	, lat:60.1331483 },
        { lng:23.2029968	, lat:60.1334417 },
        { lng:23.2022253	, lat:60.1336468 },
        { lng:23.2017733	, lat:60.1338842 },
        { lng:23.200248	, lat:60.1342284 },
        { lng:23.199971	, lat:60.1342189 },
        { lng:23.1990277	, lat:60.1340135 },
        { lng:23.1984525	, lat:60.1338602 },
        { lng:23.1979637	, lat:60.1338377 },
        { lng:23.1973903	, lat:60.1338976 },
        { lng:23.196795	, lat:60.1339483 },
        { lng:23.1957685	, lat:60.1341746 },
        { lng:23.1974984	, lat:60.1229118 },
        { lng:23.1789422	, lat:60.1144017 },
        { lng:23.1694278	, lat:60.1100782 },
        { lng:23.1645973	, lat:60.1078463 },
        { lng:23.1619048	, lat:60.1066012 },
        { lng:23.1576025	, lat:60.1045906 },
        { lng:23.1486842	, lat:60.1004419 },
        { lng:23.1422603	, lat:60.099724 },
        { lng:23.1319928	, lat:60.098435 },
        { lng:23.1064045	, lat:60.0951938 },
        { lng:23.1058762	, lat:60.0952152 },
        { lng:23.0729338	, lat:60.0911767 },
        { lng:23.0699716	, lat:60.0967714 },
        { lng:23.0564567	, lat:60.0886042 },
        { lng:23.0288671	, lat:60.1014729 },
        { lng:23.028104	, lat:60.1017956 },
        { lng:23.0198178	, lat:60.1053004 },
        { lng:22.9873831	, lat:60.0955612 },
        { lng:22.9598891	, lat:60.0709139 },
        { lng:22.9737806	, lat:60.0639279 },
        { lng:22.9836313	, lat:60.0412651 },
        { lng:22.9795559	, lat:60.0335434 },
        { lng:22.9534826	, lat:59.9994359 },
        { lng:22.9255385	, lat:59.9982315 },
        { lng:22.9167968	, lat:59.9980663 },
        { lng:22.8510369	, lat:59.9952294 },
        { lng:22.8476612	, lat:60.0301898 },
        { lng:22.8012999	, lat:60.02542 },
        { lng:22.7981988	, lat:60.0322217 },
        { lng:22.7955715	, lat:60.0377214 },
        { lng:22.7909881	, lat:60.0586266 },
      ],
    },
    {
      id: 28,
      regionName: 'eCom TP  Seinäjoki kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:22.3026579	, lat:62.795113 },
        { lng:22.4086759	, lat:62.864229 },
        { lng:22.4001013	, lat:62.8781235 },
        { lng:22.3894499	, lat:62.895374 },
        { lng:22.4265289	, lat:62.94445 },
        { lng:22.4174583	, lat:62.9521955 },
        { lng:22.4227329	, lat:62.9539114 },
        { lng:22.4230078	, lat:62.9537341 },
        { lng:22.4273009	, lat:62.9550933 },
        { lng:22.4351644	, lat:62.9576866 },
        { lng:22.4372349	, lat:62.9583638 },
        { lng:22.4327799	, lat:62.9609937 },
        { lng:22.4349455	, lat:62.9619941 },
        { lng:22.4390226	, lat:62.9638276 },
        { lng:22.4545451	, lat:62.9735165 },
        { lng:22.4620686	, lat:62.9782274 },
        { lng:22.4701033	, lat:62.9856137 },
        { lng:22.4690305	, lat:62.9934423 },
        { lng:22.4689715	, lat:63.0003377 },
        { lng:22.4690133	, lat:63.0059449 },
        { lng:22.4765186	, lat:63.0116646 },
        { lng:22.4889057	, lat:63.0211025 },
        { lng:22.4911738	, lat:63.0215114 },
        { lng:22.5015383	, lat:63.0309526 },
        { lng:22.5035452	, lat:63.0327802 },
        { lng:22.5092143	, lat:63.0379073 },
        { lng:22.5122195	, lat:63.0370404 },
        { lng:22.4966659	, lat:63.0231827 },
        { lng:22.4976079	, lat:63.0229953 },
        { lng:22.5107582	, lat:63.0347418 },
        { lng:22.5112625	, lat:63.0346065 },
        { lng:22.5131357	, lat:63.036317 },
        { lng:22.5145916	, lat:63.0358724 },
        { lng:22.5215854	, lat:63.0420212 },
        { lng:22.5222775	, lat:63.042719 },
        { lng:22.5211641	, lat:63.0428772 },
        { lng:22.5156774	, lat:63.0437363 },
        { lng:22.5220191	, lat:63.0494791 },
        { lng:22.5339195	, lat:63.0423925 },
        { lng:22.5323779	, lat:63.0463371 },
        { lng:22.5372402	, lat:63.0472835 },
        { lng:22.5422559	, lat:63.0500479 },
        { lng:22.5398076	, lat:63.0513092 },
        { lng:22.5403483	, lat:63.0517555 },
        { lng:22.5405307	, lat:63.0520375 },
        { lng:22.5406369	, lat:63.0523584 },
        { lng:22.5409513	, lat:63.0525904 },
        { lng:22.5415713	, lat:63.052658 },
        { lng:22.5435434	, lat:63.0533133 },
        { lng:22.5446785	, lat:63.0536352 },
        { lng:22.5457836	, lat:63.0537995 },
        { lng:22.5466987	, lat:63.0539279 },
        { lng:22.5478079	, lat:63.053987 },
        { lng:22.5461247	, lat:63.0519437 },
        { lng:22.5514633	, lat:63.0474275 },
        { lng:22.5587675	, lat:63.05065 },
        { lng:22.5506813	, lat:63.0555492 },
        { lng:22.5466	, lat:63.0591669 },
        { lng:22.5449049	, lat:63.059079 },
        { lng:22.5444596	, lat:63.0601727 },
        { lng:22.5415017	, lat:63.0597989 },
        { lng:22.5405125	, lat:63.0597473 },
        { lng:22.5396955	, lat:63.0594379 },
        { lng:22.5390598	, lat:63.0593923 },
        { lng:22.5384053	, lat:63.0592841 },
        { lng:22.5368674	, lat:63.0617543 },
        { lng:22.5366694	, lat:63.0617268 },
        { lng:22.5382551	, lat:63.0592348 },
        { lng:22.5374537	, lat:63.0591001 },
        { lng:22.5366828	, lat:63.0589412 },
        { lng:22.5345891	, lat:63.0615299 },
        { lng:22.5331262	, lat:63.0633281 },
        { lng:22.5328011	, lat:63.0647062 },
        { lng:22.5339872	, lat:63.0649635 },
        { lng:22.5363121	, lat:63.0660652 },
        { lng:22.5367745	, lat:63.0656342 },
        { lng:22.5865692	, lat:63.0816837 },
        { lng:22.5947328	, lat:63.0842686 },
        { lng:22.5978495	, lat:63.0843429 },
        { lng:22.6055196	, lat:63.0768534 },
        { lng:22.6058951	, lat:63.0773154 },
        { lng:22.6060378	, lat:63.0776977 },
        { lng:22.6059691	, lat:63.0780227 },
        { lng:22.6055228	, lat:63.0791861 },
        { lng:22.6052986	, lat:63.0798928 },
        { lng:22.6060506	, lat:63.0800604 },
        { lng:22.6117026	, lat:63.0740672 },
        { lng:22.6292153	, lat:63.0770987 },
        { lng:22.6265341	, lat:63.0828314 },
        { lng:22.6280598	, lat:63.0829364 },
        { lng:22.6298826	, lat:63.0831229 },
        { lng:22.6293666	, lat:63.0842113 },
        { lng:22.6321216	, lat:63.0841889 },
        { lng:22.6320412	, lat:63.085354 },
        { lng:22.6328073	, lat:63.085374 },
        { lng:22.6328724	, lat:63.0841828 },
        { lng:22.6762265	, lat:63.0838645 },
        { lng:22.6850633	, lat:63.0793248 },
        { lng:22.6851797	, lat:63.0792427 },
        { lng:22.6853508	, lat:63.0791147 },
        { lng:22.6864511	, lat:63.0780293 },
        { lng:22.6876098	, lat:63.0769168 },
        { lng:22.6908338	, lat:63.0773288 },
        { lng:22.6937091	, lat:63.0777077 },
        { lng:22.6936126	, lat:63.0768236 },
        { lng:22.6935053	, lat:63.0759151 },
        { lng:22.6902008	, lat:63.0752058 },
        { lng:22.6860165	, lat:63.0743265 },
        { lng:22.6911449	, lat:63.0640346 },
        { lng:22.7009618	, lat:63.0615607 },
        { lng:22.7072382	, lat:63.0602969 },
        { lng:22.7136433	, lat:63.0602678 },
        { lng:22.7211964	, lat:63.0591498 },
        { lng:22.7300531	, lat:63.0578334 },
        { lng:22.7326119	, lat:63.0567873 },
        { lng:22.7264213	, lat:63.0535592 },
        { lng:22.7286744	, lat:63.0524701 },
        { lng:22.7372038	, lat:63.0561942 },
        { lng:22.7430081	, lat:63.0585519 },
        { lng:22.7477825	, lat:63.0561796 },
        { lng:22.7506578	, lat:63.0570984 },
        { lng:22.7523422	, lat:63.0576477 },
        { lng:22.7541715	, lat:63.0582952 },
        { lng:22.7561563	, lat:63.0553687 },
        { lng:22.7563602	, lat:63.0551937 },
        { lng:22.7568483	, lat:63.0545704 },
        { lng:22.7633017	, lat:63.0535504 },
        { lng:22.7633071	, lat:63.0534473 },
        { lng:22.7584469	, lat:63.0507926 },
        { lng:22.7588868	, lat:63.0505884 },
        { lng:22.7725285	, lat:63.0437375 },
        { lng:22.7800333	, lat:63.0399428 },
        { lng:22.7817499	, lat:63.0390916 },
        { lng:22.7835417	, lat:63.038216 },
        { lng:22.784121	, lat:63.0379387 },
        { lng:22.7874394	, lat:63.0313128 },
        { lng:22.788037	, lat:63.0302227 },
        { lng:22.7881551	, lat:63.0297701 },
        { lng:22.7882731	, lat:63.029522 },
        { lng:22.7887827	, lat:63.0287493 },
        { lng:22.7890777	, lat:63.028106 },
        { lng:22.7897751	, lat:63.0266705 },
        { lng:22.7941525	, lat:63.0178562 },
        { lng:22.7994289	, lat:63.0073724 },
        { lng:22.800968	, lat:63.0043018 },
        { lng:22.8011745	, lat:63.0038291 },
        { lng:22.8014411	, lat:63.003273 },
        { lng:22.8020596	, lat:63.0020607 },
        { lng:22.8013698	, lat:62.9991607 },
        { lng:22.8012281	, lat:62.9983015 },
        { lng:22.8008741	, lat:62.9969034 },
        { lng:22.8002089	, lat:62.9940487 },
        { lng:22.7999353	, lat:62.9926349 },
        { lng:22.7996778	, lat:62.9914607 },
        { lng:22.7990346	, lat:62.9886232 },
        { lng:22.826972	, lat:62.946841 },
        { lng:22.8316471	, lat:62.9398386 },
        { lng:22.8316459	, lat:62.9396693 },
        { lng:22.8319255	, lat:62.9309495 },
        { lng:22.8320178	, lat:62.9252718 },
        { lng:22.8322935	, lat:62.9241809 },
        { lng:22.8356838	, lat:62.921993 },
        { lng:22.8351688	, lat:62.9210163 },
        { lng:22.8396106	, lat:62.9180076 },
        { lng:22.8377223	, lat:62.9166008 },
        { lng:22.8390366	, lat:62.9162648 },
        { lng:22.8354049	, lat:62.9132497 },
        { lng:22.8336453	, lat:62.9134061 },
        { lng:22.8334093	, lat:62.9137773 },
        { lng:22.8325564	, lat:62.9151315 },
        { lng:22.8322291	, lat:62.9152526 },
        { lng:22.8323636	, lat:62.9113123 },
        { lng:22.8323965	, lat:62.9093408 },
        { lng:22.8325724	, lat:62.9026761 },
        { lng:22.832787	, lat:62.8943084 },
        { lng:22.8328514	, lat:62.8903876 },
        { lng:22.8328299	, lat:62.889664 },
        { lng:22.8329372	, lat:62.8862414 },
        { lng:22.8318941	, lat:62.8851295 },
        { lng:22.8316712	, lat:62.8848918 },
        { lng:22.828753	, lat:62.881987 },
        { lng:22.8240752	, lat:62.8772233 },
        { lng:22.8218007	, lat:62.8749536 },
        { lng:22.8177881	, lat:62.8709911 },
        { lng:22.8290534	, lat:62.8713042 },
        { lng:22.830534	, lat:62.871314 },
        { lng:22.8402328	, lat:62.8715782 },
        { lng:22.8479844	, lat:62.8717895 },
        { lng:22.8500378	, lat:62.8718309 },
        { lng:22.851026	, lat:62.8718522 },
        { lng:22.8525925	, lat:62.8719402 },
        { lng:22.8693938	, lat:62.8723218 },
        { lng:22.8711748	, lat:62.8728012 },
        { lng:22.8742647	, lat:62.872674 },
        { lng:22.8764963	, lat:62.8724881 },
        { lng:22.8820109	, lat:62.8726447 },
        { lng:22.9119873	, lat:62.873398 },
        { lng:22.9350006	, lat:62.8739655 },
        { lng:22.9353121	, lat:62.873982 },
        { lng:22.9354264	, lat:62.8739843 },
        { lng:22.936449	, lat:62.8740046 },
        { lng:22.9365681	, lat:62.8740077 },
        { lng:22.9392216	, lat:62.8740762 },
        { lng:22.9444098	, lat:62.8742101 },
        { lng:22.9445171	, lat:62.8743177 },
        { lng:22.9454827	, lat:62.8746308 },
        { lng:22.9479074	, lat:62.8741709 },
        { lng:22.9499441	, lat:62.8738735 },
        { lng:22.9502721	, lat:62.8738256 },
        { lng:22.9506121	, lat:62.8740315 },
        { lng:22.9510959	, lat:62.8743519 },
        { lng:22.9511948	, lat:62.874494 },
        { lng:22.9512507	, lat:62.8746618 },
        { lng:22.9512936	, lat:62.8748838 },
        { lng:22.9513703	, lat:62.8751242 },
        { lng:22.9514893	, lat:62.8752327 },
        { lng:22.9516314	, lat:62.8753334 },
        { lng:22.9520902	, lat:62.8754325 },
        { lng:22.9525092	, lat:62.8755385 },
        { lng:22.9530687	, lat:62.8757474 },
        { lng:22.9536271	, lat:62.8760954 },
        { lng:22.9539258	, lat:62.8763482 },
        { lng:22.9542276	, lat:62.8763298 },
        { lng:22.9547769	, lat:62.8762964 },
        { lng:22.9555889	, lat:62.8762469 },
        { lng:22.955729	, lat:62.8762384 },
        { lng:22.9570694	, lat:62.8761567 },
        { lng:22.9586788	, lat:62.8761565 },
        { lng:22.9585929	, lat:62.8765574 },
        { lng:22.9598106	, lat:62.8774331 },
        { lng:22.9607602	, lat:62.8774086 },
        { lng:22.9659751	, lat:62.8771684 },
        { lng:22.9669185	, lat:62.8771248 },
        { lng:22.9685493	, lat:62.8785922 },
        { lng:22.9685922	, lat:62.8787488 },
        { lng:22.9689141	, lat:62.8791008 },
        { lng:22.9697861	, lat:62.8789289 },
        { lng:22.9709719	, lat:62.878695 },
        { lng:22.9698775	, lat:62.87712 },
        { lng:22.9689355	, lat:62.8771444 },
        { lng:22.9672731	, lat:62.8768188 },
        { lng:22.9663392	, lat:62.8766357 },
        { lng:22.9658027	, lat:62.8761857 },
        { lng:22.9664313	, lat:62.8761145 },
        { lng:22.9714918	, lat:62.8755401 },
        { lng:22.9724678	, lat:62.8754293 },
        { lng:22.9728148	, lat:62.8753898 },
        { lng:22.9731152	, lat:62.8753554 },
        { lng:22.9790199	, lat:62.8746775 },
        { lng:22.9793425	, lat:62.8746405 },
        { lng:22.9796858	, lat:62.8742393 },
        { lng:22.9807137	, lat:62.8739209 },
        { lng:22.9810525	, lat:62.8740064 },
        { lng:22.9821832	, lat:62.8743252 },
        { lng:22.9846336	, lat:62.8740541 },
        { lng:23.0628015	, lat:62.8654004 },
        { lng:23.0960413	, lat:62.861749 },
        { lng:23.1135881	, lat:62.8597925 },
        { lng:23.102914	, lat:62.853688 },
        { lng:23.094035	, lat:62.848635 },
        { lng:23.0557145	, lat:62.8265326 },
        { lng:23.0415077	, lat:62.8183412 },
        { lng:23.0281727	, lat:62.8106417 },
        { lng:23.0446886	, lat:62.8112349 },
        { lng:23.0671258	, lat:62.8120011 },
        { lng:23.0865644	, lat:62.8126801 },
        { lng:23.1004904	, lat:62.8131016 },
        { lng:23.1161116	, lat:62.8136801 },
        { lng:23.1168411	, lat:62.8141115 },
        { lng:23.1182573	, lat:62.8140526 },
        { lng:23.11815	, lat:62.8139742 },
        { lng:23.1181715	, lat:62.8138663 },
        { lng:23.1183432	, lat:62.813788 },
        { lng:23.1290936	, lat:62.8141616 },
        { lng:23.1317564	, lat:62.8142541 },
        { lng:23.1447082	, lat:62.8147545 },
        { lng:23.1485681	, lat:62.8148866 },
        { lng:23.1786778	, lat:62.8160687 },
        { lng:23.1896277	, lat:62.8164795 },
        { lng:23.1980606	, lat:62.8117835 },
        { lng:23.206569	, lat:62.7977201 },
        { lng:23.2069634	, lat:62.7970679 },
        { lng:23.2088338	, lat:62.7959598 },
        { lng:23.2313178	, lat:62.7826351 },
        { lng:23.2589553	, lat:62.7779828 },
        { lng:23.2789109	, lat:62.7746944 },
        { lng:23.2776042	, lat:62.7682397 },
        { lng:23.2778348	, lat:62.768005 },
        { lng:23.2773241	, lat:62.7665061 },
        { lng:23.2772947	, lat:62.766336 },
        { lng:23.277284	, lat:62.7662742 },
        { lng:23.2722129	, lat:62.740828 },
        { lng:23.2721968	, lat:62.7404491 },
        { lng:23.2720681	, lat:62.7400363 },
        { lng:23.2719608	, lat:62.739653 },
        { lng:23.2712622	, lat:62.7357304 },
        { lng:23.2642276	, lat:62.7345468 },
        { lng:23.2341479	, lat:62.7294175 },
        { lng:23.2331224	, lat:62.7292431 },
        { lng:23.2324025	, lat:62.7287972 },
        { lng:23.2319615	, lat:62.7289722 },
        { lng:23.2316397	, lat:62.7290017 },
        { lng:23.2157696	, lat:62.7262958 },
        { lng:23.2026922	, lat:62.7240711 },
        { lng:23.1995688	, lat:62.7189023 },
        { lng:23.1949696	, lat:62.7112898 },
        { lng:23.1943484	, lat:62.7102615 },
        { lng:23.1946059	, lat:62.7101632 },
        { lng:23.1950543	, lat:62.7101873 },
        { lng:23.1952282	, lat:62.7100156 },
        { lng:23.1938978	, lat:62.7094745 },
        { lng:23.1924783	, lat:62.7073764 },
        { lng:23.1912478	, lat:62.7051881 },
        { lng:23.1886675	, lat:62.7007943 },
        { lng:23.1829308	, lat:62.6913412 },
        { lng:23.1796499	, lat:62.6859514 },
        { lng:23.1796713	, lat:62.6858529 },
        { lng:23.1770949	, lat:62.6816569 },
        { lng:23.16678	, lat:62.6645881 },
        { lng:23.1640239	, lat:62.660064 },
        { lng:23.1612392	, lat:62.6554616 },
        { lng:23.1635786	, lat:62.6558628 },
        { lng:23.1663869	, lat:62.6552295 },
        { lng:23.1666148	, lat:62.6551117 },
        { lng:23.1708261	, lat:62.6529345 },
        { lng:23.1767102	, lat:62.6498922 },
        { lng:23.1878349	, lat:62.6505024 },
        { lng:23.1901426	, lat:62.6506414 },
        { lng:23.1910215	, lat:62.6497279 },
        { lng:23.1932938	, lat:62.6473657 },
        { lng:23.1961079	, lat:62.6444401 },
        { lng:23.1766112	, lat:62.6397391 },
        { lng:23.1762103	, lat:62.6396428 },
        { lng:23.1735891	, lat:62.6396561 },
        { lng:23.1734356	, lat:62.6396562 },
        { lng:23.1723037	, lat:62.639657 },
        { lng:23.1704234	, lat:62.6396583 },
        { lng:23.1699441	, lat:62.6396694 },
        { lng:23.1679528	, lat:62.6397156 },
        { lng:23.1679342	, lat:62.639619 },
        { lng:23.1674382	, lat:62.6370387 },
        { lng:23.1686708	, lat:62.6366689 },
        { lng:23.171407	, lat:62.6358479 },
        { lng:23.1721876	, lat:62.6356137 },
        { lng:23.1777595	, lat:62.6339418 },
        { lng:23.1761951	, lat:62.6250884 },
        { lng:23.1764097	, lat:62.6250588 },
        { lng:23.1826324	, lat:62.6256212 },
        { lng:23.1852502	, lat:62.6258284 },
        { lng:23.1860872	, lat:62.6238847 },
        { lng:23.1864498	, lat:62.6231096 },
        { lng:23.1871386	, lat:62.6212501 },
        { lng:23.1976314	, lat:62.6151611 },
        { lng:23.2126732	, lat:62.6064645 },
        { lng:23.2194255	, lat:62.6075609 },
        { lng:23.2233569	, lat:62.608246 },
        { lng:23.2275627	, lat:62.6072292 },
        { lng:23.2275005	, lat:62.6060203 },
        { lng:23.2337854	, lat:62.6061234 },
        { lng:23.2339377	, lat:62.6077776 },
        { lng:23.233337	, lat:62.6098704 },
        { lng:23.257627	, lat:62.613878 },
        { lng:23.2591959	, lat:62.6141128 },
        { lng:23.2608221	, lat:62.6143562 },
        { lng:23.2625173	, lat:62.6146523 },
        { lng:23.2650428	, lat:62.6115544 },
        { lng:23.2699436	, lat:62.6054129 },
        { lng:23.2752289	, lat:62.5987434 },
        { lng:23.2755667	, lat:62.5983076 },
        { lng:23.2699416	, lat:62.5911031 },
        { lng:23.2766815	, lat:62.5918889 },
        { lng:23.2732032	, lat:62.5799688 },
        { lng:23.2738491	, lat:62.5791639 },
        { lng:23.2830115	, lat:62.5777014 },
        { lng:23.2829849	, lat:62.5804136 },
        { lng:23.282978	, lat:62.5811114 },
        { lng:23.2829686	, lat:62.582069 },
        { lng:23.2882878	, lat:62.5823403 },
        { lng:23.2943175	, lat:62.5836148 },
        { lng:23.3020959	, lat:62.5785093 },
        { lng:23.3027125	, lat:62.5781127 },
        { lng:23.3032117	, lat:62.5777811 },
        { lng:23.3037858	, lat:62.5773996 },
        { lng:23.3039259	, lat:62.5773065 },
        { lng:23.3078347	, lat:62.5747865 },
        { lng:23.3032746	, lat:62.5738713 },
        { lng:23.2712956	, lat:62.5639619 },
        { lng:23.2691927	, lat:62.5620537 },
        { lng:23.2734414	, lat:62.5622515 },
        { lng:23.2664655	, lat:62.5542757 },
        { lng:23.2603736	, lat:62.5539153 },
        { lng:23.2620259	, lat:62.5481586 },
        { lng:23.2590218	, lat:62.5482576 },
        { lng:23.2584738	, lat:62.5476228 },
        { lng:23.2573682	, lat:62.5462977 },
        { lng:23.2547087	, lat:62.5459625 },
        { lng:23.2546701	, lat:62.5458804 },
        { lng:23.254594	, lat:62.5457864 },
        { lng:23.2544083	, lat:62.5457152 },
        { lng:23.2541294	, lat:62.5457152 },
        { lng:23.2538875	, lat:62.5457899 },
        { lng:23.253711	, lat:62.5458191 },
        { lng:23.253593	, lat:62.5457894 },
        { lng:23.2534321	, lat:62.5457053 },
        { lng:23.2533999	, lat:62.5455915 },
        { lng:23.2533892	, lat:62.5454728 },
        { lng:23.2533033	, lat:62.5453838 },
        { lng:23.2531617	, lat:62.5453635 },
        { lng:23.2530029	, lat:62.545369 },
        { lng:23.2526382	, lat:62.5454085 },
        { lng:23.2523028	, lat:62.5454686 },
        { lng:23.2521351	, lat:62.5454682 },
        { lng:23.2520159	, lat:62.5454679 },
        { lng:23.2517477	, lat:62.5454481 },
        { lng:23.2515438	, lat:62.5453887 },
        { lng:23.2511789	, lat:62.5452057 },
        { lng:23.2509752	, lat:62.5451068 },
        { lng:23.2508679	, lat:62.5450326 },
        { lng:23.2507928	, lat:62.5449238 },
        { lng:23.2507499	, lat:62.5447754 },
        { lng:23.2507284	, lat:62.5446072 },
        { lng:23.2506426	, lat:62.5444835 },
        { lng:23.2504923	, lat:62.5444044 },
        { lng:23.2502564	, lat:62.5443648 },
        { lng:23.2500096	, lat:62.5443203 },
        { lng:23.249913	, lat:62.5442659 },
        { lng:23.2498915	, lat:62.5441867 },
        { lng:23.2499967	, lat:62.544117 },
        { lng:23.25031	, lat:62.5440235 },
        { lng:23.2507176	, lat:62.5439196 },
        { lng:23.2508894	, lat:62.5438553 },
        { lng:23.2510074	, lat:62.5437712 },
        { lng:23.2510696	, lat:62.5437113 },
        { lng:23.2510825	, lat:62.5436327 },
        { lng:23.2510073	, lat:62.5435535 },
        { lng:23.2509108	, lat:62.5434249 },
        { lng:23.2509323	, lat:62.5433309 },
        { lng:23.2509001	, lat:62.5432369 },
        { lng:23.2507713	, lat:62.5430786 },
        { lng:23.2506104	, lat:62.5429253 },
        { lng:23.2505888	, lat:62.5427917 },
        { lng:23.2505888	, lat:62.5425196 },
        { lng:23.2505997	, lat:62.5423168 },
        { lng:23.2506748	, lat:62.5421981 },
        { lng:23.2508121	, lat:62.5420888 },
        { lng:23.2508872	, lat:62.5419898 },
        { lng:23.2508894	, lat:62.5418073 },
        { lng:23.2509516	, lat:62.5416633 },
        { lng:23.2509752	, lat:62.5415797 },
        { lng:23.2509752	, lat:62.5414412 },
        { lng:23.2508464	, lat:62.5413472 },
        { lng:23.2506104	, lat:62.5412186 },
        { lng:23.2504924	, lat:62.5411048 },
        { lng:23.2504066	, lat:62.5409267 },
        { lng:23.2502778	, lat:62.5408228 },
        { lng:23.2499559	, lat:62.5406744 },
        { lng:23.2497843	, lat:62.5405705 },
        { lng:23.2497628	, lat:62.5404864 },
        { lng:23.2499023	, lat:62.5404122 },
        { lng:23.2500632	, lat:62.5403429 },
        { lng:23.2501061	, lat:62.5402835 },
        { lng:23.2500632	, lat:62.5402143 },
        { lng:23.2498057	, lat:62.5400609 },
        { lng:23.2494624	, lat:62.5398778 },
        { lng:23.2492049	, lat:62.5397591 },
        { lng:23.2489881	, lat:62.539724 },
        { lng:23.2488187	, lat:62.5397344 },
        { lng:23.2487007	, lat:62.5397096 },
        { lng:23.2487007	, lat:62.5396552 },
        { lng:23.2488079	, lat:62.5395711 },
        { lng:23.2489667	, lat:62.5394667 },
        { lng:23.2490333	, lat:62.539393 },
        { lng:23.2490011	, lat:62.5393336 },
        { lng:23.2488938	, lat:62.5392693 },
        { lng:23.2486899	, lat:62.5392347 },
        { lng:23.2485483	, lat:62.5391748 },
        { lng:23.2484646	, lat:62.5390566 },
        { lng:23.2483788	, lat:62.5389081 },
        { lng:23.2482479	, lat:62.5387345 },
        { lng:23.2482071	, lat:62.5385569 },
        { lng:23.2480677	, lat:62.5383886 },
        { lng:23.2479389	, lat:62.5382303 },
        { lng:23.2477351	, lat:62.5381017 },
        { lng:23.2475849	, lat:62.5380571 },
        { lng:23.2472737	, lat:62.5378592 },
        { lng:23.2467051	, lat:62.537404 },
        { lng:23.2523102	, lat:62.5338859 },
        { lng:23.2619464	, lat:62.5278582 },
        { lng:23.2584188	, lat:62.5249374 },
        { lng:23.258612	, lat:62.5248483 },
        { lng:23.2616611	, lat:62.5246854 },
        { lng:23.2636351	, lat:62.5242004 },
        { lng:23.2639549	, lat:62.5240365 },
        { lng:23.2643196	, lat:62.5237395 },
        { lng:23.2645127	, lat:62.5234326 },
        { lng:23.2635923	, lat:62.5231014 },
        { lng:23.2686755	, lat:62.5208485 },
        { lng:23.2671543	, lat:62.5199926 },
        { lng:23.2646866	, lat:62.5217847 },
        { lng:23.2629486	, lat:62.5212104 },
        { lng:23.2649012	, lat:62.5196658 },
        { lng:23.2715939	, lat:62.5165115 },
        { lng:23.2718991	, lat:62.5148915 },
        { lng:23.2719371	, lat:62.5146894 },
        { lng:23.2732697	, lat:62.5142591 },
        { lng:23.2784195	, lat:62.5132984 },
        { lng:23.28567	, lat:62.5098562 },
        { lng:23.2861157	, lat:62.5096478 },
        { lng:23.2955192	, lat:62.5052502 },
        { lng:23.3125565	, lat:62.4971853 },
        { lng:23.3124707	, lat:62.4958377 },
        { lng:23.3121117	, lat:62.495775 },
        { lng:23.2983752	, lat:62.4933756 },
        { lng:23.3125587	, lat:62.4785065 },
        { lng:23.3232232	, lat:62.4710097 },
        { lng:23.3276134	, lat:62.472351 },
        { lng:23.3339896	, lat:62.4651259 },
        { lng:23.3315681	, lat:62.4639817 },
        { lng:23.3330271	, lat:62.4631286 },
        { lng:23.333909	, lat:62.4625983 },
        { lng:23.3357738	, lat:62.4615016 },
        { lng:23.3383487	, lat:62.4602218 },
        { lng:23.345151	, lat:62.45537 },
        { lng:23.3472739	, lat:62.4538234 },
        { lng:23.3515473	, lat:62.4508105 },
        { lng:23.3678207	, lat:62.4332471 },
        { lng:23.3590146	, lat:62.4308657 },
        { lng:23.3567186	, lat:62.4318389 },
        { lng:23.3547759	, lat:62.4312094 },
        { lng:23.3539894	, lat:62.4309546 },
        { lng:23.3382307	, lat:62.4258477 },
        { lng:23.3377907	, lat:62.4251001 },
        { lng:23.3135214	, lat:62.4184482 },
        { lng:23.2954015	, lat:62.4134932 },
        { lng:23.2750682	, lat:62.4078808 },
        { lng:23.2764256	, lat:62.4073404 },
        { lng:23.2779821	, lat:62.406721 },
        { lng:23.2785862	, lat:62.4061316 },
        { lng:23.2792084	, lat:62.4053295 },
        { lng:23.2783201	, lat:62.4045636 },
        { lng:23.2771002	, lat:62.4040811 },
        { lng:23.2768936	, lat:62.4040273 },
        { lng:23.2751669	, lat:62.4035781 },
        { lng:23.2727851	, lat:62.4049602 },
        { lng:23.2710587	, lat:62.4026948 },
        { lng:23.2713375	, lat:62.4024237 },
        { lng:23.2714878	, lat:62.4021675 },
        { lng:23.2716981	, lat:62.4016814 },
        { lng:23.2717561	, lat:62.4014378 },
        { lng:23.2719782	, lat:62.4012335 },
        { lng:23.2724675	, lat:62.4008464 },
        { lng:23.2703883	, lat:62.400322 },
        { lng:23.2709708	, lat:62.4016785 },
        { lng:23.2704815	, lat:62.4019876 },
        { lng:23.2664559	, lat:62.3968299 },
        { lng:23.2605364	, lat:62.3891594 },
        { lng:23.2501107	, lat:62.3756449 },
        { lng:23.2555428	, lat:62.3701847 },
        { lng:23.260742	, lat:62.3699792 },
        { lng:23.2643704	, lat:62.3715191 },
        { lng:23.2671438	, lat:62.3721838 },
        { lng:23.2649444	, lat:62.3674495 },
        { lng:23.2648264	, lat:62.3672137 },
        { lng:23.2647492	, lat:62.366926 },
        { lng:23.2629221	, lat:62.3628991 },
        { lng:23.2639395	, lat:62.3618743 },
        { lng:23.2642	, lat:62.3616119 },
        { lng:23.2659701	, lat:62.3598288 },
        { lng:23.2631463	, lat:62.3592375 },
        { lng:23.260389	, lat:62.3596989 },
        { lng:23.260715	, lat:62.3604971 },
        { lng:23.2610821	, lat:62.3613961 },
        { lng:23.2604576	, lat:62.3613956 },
        { lng:23.2590436	, lat:62.3585003 },
        { lng:23.2600285	, lat:62.3584804 },
        { lng:23.2553268	, lat:62.3573832 },
        { lng:23.2505946	, lat:62.3562713 },
        { lng:23.2474049	, lat:62.3593246 },
        { lng:23.2450135	, lat:62.3587059 },
        { lng:23.2387393	, lat:62.3578666 },
        { lng:23.235924	, lat:62.3581549 },
        { lng:23.2361633	, lat:62.3576178 },
        { lng:23.2373552	, lat:62.3560831 },
        { lng:23.2370945	, lat:62.3555724 },
        { lng:23.2317998	, lat:62.3536707 },
        { lng:23.2320595	, lat:62.3516758 },
        { lng:23.229418	, lat:62.3517421 },
        { lng:23.2271886	, lat:62.3543274 },
        { lng:23.2239474	, lat:62.353494 },
        { lng:23.2211247	, lat:62.3555316 },
        { lng:23.2226911	, lat:62.3568522 },
        { lng:23.2204402	, lat:62.3598765 },
        { lng:23.2191916	, lat:62.3597387 },
        { lng:23.2190972	, lat:62.3598937 },
        { lng:23.2190529	, lat:62.3599705 },
        { lng:23.2189239	, lat:62.3600703 },
        { lng:23.2187552	, lat:62.3601225 },
        { lng:23.2171818	, lat:62.3602732 },
        { lng:23.2165563	, lat:62.3603653 },
        { lng:23.2162323	, lat:62.360331 },
        { lng:23.2159625	, lat:62.3602026 },
        { lng:23.2157205	, lat:62.3601784 },
        { lng:23.2154668	, lat:62.3601869 },
        { lng:23.2152721	, lat:62.3602469 },
        { lng:23.2151278	, lat:62.3603265 },
        { lng:23.2149051	, lat:62.3607105 },
        { lng:23.2145962	, lat:62.3607227 },
        { lng:23.2138087	, lat:62.36077 },
        { lng:23.2132443	, lat:62.3608118 },
        { lng:23.2129866	, lat:62.3608523 },
        { lng:23.2127476	, lat:62.3608899 },
        { lng:23.2122401	, lat:62.3611661 },
        { lng:23.2117262	, lat:62.3613757 },
        { lng:23.2111222	, lat:62.3616081 },
        { lng:23.210487	, lat:62.3615807 },
        { lng:23.2095976	, lat:62.3617231 },
        { lng:23.2092543	, lat:62.3616798 },
        { lng:23.2090815	, lat:62.3616141 },
        { lng:23.2090633	, lat:62.3613856 },
        { lng:23.2088208	, lat:62.361192 },
        { lng:23.208072	, lat:62.360867 },
        { lng:23.2075656	, lat:62.3607814 },
        { lng:23.2067255	, lat:62.3606132 },
        { lng:23.2069497	, lat:62.3602717 },
        { lng:23.2118163	, lat:62.361088 },
        { lng:23.2153532	, lat:62.360106 },
        { lng:23.2183701	, lat:62.3592684 },
        { lng:23.2198029	, lat:62.3588706 },
        { lng:23.2198629	, lat:62.3570278 },
        { lng:23.2199209	, lat:62.3552488 },
        { lng:23.2227683	, lat:62.352693 },
        { lng:23.223971	, lat:62.3512875 },
        { lng:23.2240311	, lat:62.3492029 },
        { lng:23.2239952	, lat:62.347974 },
        { lng:23.2239933	, lat:62.3479081 },
        { lng:23.2239807	, lat:62.3474781 },
        { lng:23.2280812	, lat:62.3471728 },
        { lng:23.2263582	, lat:62.3448857 },
        { lng:23.220909	, lat:62.3461475 },
        { lng:23.2191031	, lat:62.3445228 },
        { lng:23.2186109	, lat:62.34408 },
        { lng:23.2092296	, lat:62.3361624 },
        { lng:23.2093948	, lat:62.333589 },
        { lng:23.2097532	, lat:62.3335132 },
        { lng:23.2104698	, lat:62.3346022 },
        { lng:23.217597	, lat:62.3347521 },
        { lng:23.2178093	, lat:62.3341678 },
        { lng:23.2077722	, lat:62.3321161 },
        { lng:23.14451	, lat:62.3191816 },
        { lng:23.1023736	, lat:62.3869677 },
        { lng:23.1185496	, lat:62.3865013 },
        { lng:23.1326632	, lat:62.3860945 },
        { lng:23.1191824	, lat:62.409636 },
        { lng:23.1101996	, lat:62.413441 },
        { lng:23.1085373	, lat:62.4141451 },
        { lng:23.108901	, lat:62.4113255 },
        { lng:23.1100415	, lat:62.4113354 },
        { lng:23.1097411	, lat:62.4095168 },
        { lng:23.1101681	, lat:62.4083515 },
        { lng:23.1105135	, lat:62.4079763 },
        { lng:23.1108547	, lat:62.4070794 },
        { lng:23.1089471	, lat:62.4066644 },
        { lng:23.1054066	, lat:62.4085627 },
        { lng:23.1050335	, lat:62.4090603 },
        { lng:23.1048702	, lat:62.4092783 },
        { lng:23.1044839	, lat:62.410759 },
        { lng:23.1016944	, lat:62.4129055 },
        { lng:23.1016861	, lat:62.4129721 },
        { lng:23.1016166	, lat:62.4135273 },
        { lng:23.1015228	, lat:62.4142767 },
        { lng:23.1022309	, lat:62.4156181 },
        { lng:23.102336	, lat:62.4191949 },
        { lng:23.1014326	, lat:62.4199279 },
        { lng:23.0999923	, lat:62.4210966 },
        { lng:23.0986179	, lat:62.4222118 },
        { lng:23.0976518	, lat:62.4229957 },
        { lng:23.0970374	, lat:62.4234943 },
        { lng:23.0957721	, lat:62.4245295 },
        { lng:23.0902317	, lat:62.4246765 },
        { lng:23.0905794	, lat:62.4247778 },
        { lng:23.090986	, lat:62.4248573 },
        { lng:23.0911319	, lat:62.424967 },
        { lng:23.0911587	, lat:62.4250758 },
        { lng:23.0911158	, lat:62.4252844 },
        { lng:23.0911802	, lat:62.4257214 },
        { lng:23.0913948	, lat:62.4260194 },
        { lng:23.0916093	, lat:62.4263571 },
        { lng:23.0919741	, lat:62.4266154 },
        { lng:23.0924247	, lat:62.4268835 },
        { lng:23.0928753	, lat:62.4271517 },
        { lng:23.0931543	, lat:62.4273305 },
        { lng:23.0931543	, lat:62.4275589 },
        { lng:23.0933045	, lat:62.4279661 },
        { lng:23.0933903	, lat:62.4280952 },
        { lng:23.0936049	, lat:62.4282045 },
        { lng:23.0936478	, lat:62.4283336 },
        { lng:23.0934761	, lat:62.428423 },
        { lng:23.0933045	, lat:62.4285025 },
        { lng:23.093283	, lat:62.4286216 },
        { lng:23.0934118	, lat:62.4287309 },
        { lng:23.0936049	, lat:62.4289395 },
        { lng:23.0937766	, lat:62.4292275 },
        { lng:23.093798	, lat:62.4293963 },
        { lng:23.0935834	, lat:62.4295056 },
        { lng:23.0935191	, lat:62.4296446 },
        { lng:23.0935834	, lat:62.4297737 },
        { lng:23.0937766	, lat:62.4298234 },
        { lng:23.0942057	, lat:62.4299525 },
        { lng:23.0942894	, lat:62.4300989 },
        { lng:23.094034	, lat:62.4303299 },
        { lng:23.0934976	, lat:62.4307271 },
        { lng:23.0932187	, lat:62.4308363 },
        { lng:23.0929826	, lat:62.4309555 },
        { lng:23.0927037	, lat:62.4310151 },
        { lng:23.092532	, lat:62.4311343 },
        { lng:23.0924419	, lat:62.4313334 },
        { lng:23.0925738	, lat:62.4314923 },
        { lng:23.0930438	, lat:62.4315424 },
        { lng:23.0933045	, lat:62.4316308 },
        { lng:23.0933259	, lat:62.43174 },
        { lng:23.0930684	, lat:62.431889 },
        { lng:23.0930255	, lat:62.4320777 },
        { lng:23.093047	, lat:62.4322862 },
        { lng:23.0929397	, lat:62.4324848 },
        { lng:23.0931328	, lat:62.4325742 },
        { lng:23.0932401	, lat:62.4327132 },
        { lng:23.0933259	, lat:62.4328423 },
        { lng:23.0936049	, lat:62.4328423 },
        { lng:23.0939654	, lat:62.4328538 },
        { lng:23.0943108	, lat:62.4329789 },
        { lng:23.0948065	, lat:62.4330906 },
        { lng:23.0950426	, lat:62.4332296 },
        { lng:23.0953644	, lat:62.4334183 },
        { lng:23.0952764	, lat:62.4335945 },
        { lng:23.0948044	, lat:62.4342102 },
        { lng:23.0875731	, lat:62.4334555 },
        { lng:23.0878113	, lat:62.4331402 },
        { lng:23.0874251	, lat:62.4328721 },
        { lng:23.088734	, lat:62.4321075 },
        { lng:23.0889893	, lat:62.4316085 },
        { lng:23.0882812	, lat:62.4310325 },
        { lng:23.0883048	, lat:62.4303398 },
        { lng:23.0878263	, lat:62.4303219 },
        { lng:23.0843759	, lat:62.4311219 },
        { lng:23.0836678	, lat:62.4319958 },
        { lng:23.0819748	, lat:62.4322068 },
        { lng:23.0804921	, lat:62.4327405 },
        { lng:23.0783678	, lat:62.4311914 },
        { lng:23.0853651	, lat:62.4297439 },
        { lng:23.0863286	, lat:62.4290859 },
        { lng:23.0778067	, lat:62.4293188 },
        { lng:23.0761006	, lat:62.4329176 },
        { lng:23.0615647	, lat:62.4635616 },
        { lng:23.0614044	, lat:62.4638784 },
        { lng:23.043306	, lat:62.4685479 },
        { lng:23.0203892	, lat:62.4920653 },
        { lng:22.988693	, lat:62.5245359 },
        { lng:22.9732242	, lat:62.5404128 },
        { lng:22.9636089	, lat:62.5500596 },
        { lng:22.9626466	, lat:62.5510251 },
        { lng:22.9615145	, lat:62.5509597 },
        { lng:22.9542072	, lat:62.5505379 },
        { lng:22.9500101	, lat:62.5502957 },
        { lng:22.9496807	, lat:62.5502778 },
        { lng:22.9415002	, lat:62.5498356 },
        { lng:22.9337237	, lat:62.5494152 },
        { lng:22.9319487	, lat:62.5539975 },
        { lng:22.9315747	, lat:62.554963 },
        { lng:22.9311485	, lat:62.556063 },
        { lng:22.9310189	, lat:62.5563976 },
        { lng:22.9308427	, lat:62.5568525 },
        { lng:22.930695	, lat:62.557234 },
        { lng:22.922111	, lat:62.579213 },
        { lng:22.9174321	, lat:62.5912691 },
        { lng:22.9174071	, lat:62.5914336 },
        { lng:22.91631	, lat:62.592348 },
        { lng:22.907532	, lat:62.599731 },
        { lng:22.8983508	, lat:62.5977466 },
        { lng:22.897816	, lat:62.597631 },
        { lng:22.887076	, lat:62.595329 },
        { lng:22.8856248	, lat:62.5950139 },
        { lng:22.871364	, lat:62.591917 },
        { lng:22.86798	, lat:62.596896 },
        { lng:22.86457	, lat:62.608355 },
        { lng:22.8645454	, lat:62.6084403 },
        { lng:22.8640145	, lat:62.6102893 },
        { lng:22.863623	, lat:62.611653 },
        { lng:22.8635367	, lat:62.6119806 },
        { lng:22.8623843	, lat:62.6163585 },
        { lng:22.8609424	, lat:62.6218353 },
        { lng:22.8603863	, lat:62.623947 },
        { lng:22.8594062	, lat:62.6276693 },
        { lng:22.8584938	, lat:62.6311337 },
        { lng:22.857683	, lat:62.634212 },
        { lng:22.85795	, lat:62.634255 },
        { lng:22.8627147	, lat:62.6345876 },
        { lng:22.8659677	, lat:62.6348146 },
        { lng:22.868452	, lat:62.634988 },
        { lng:22.872573	, lat:62.635263 },
        { lng:22.871428	, lat:62.6357815 },
        { lng:22.8680886	, lat:62.6372938 },
        { lng:22.8673098	, lat:62.6376465 },
        { lng:22.865645	, lat:62.6384004 },
        { lng:22.8643916	, lat:62.638968 },
        { lng:22.855728	, lat:62.642891 },
        { lng:22.8533936	, lat:62.6439426 },
        { lng:22.8510755	, lat:62.6449869 },
        { lng:22.8400021	, lat:62.6499748 },
        { lng:22.834644	, lat:62.652388 },
        { lng:22.839066	, lat:62.654434 },
        { lng:22.842855	, lat:62.6527343 },
        { lng:22.850513	, lat:62.649299 },
        { lng:22.863916	, lat:62.643286 },
        { lng:22.865436	, lat:62.644015 },
        { lng:22.85179	, lat:62.650227 },
        { lng:22.8551474	, lat:62.6518029 },
        { lng:22.858024	, lat:62.653153 },
        { lng:22.868395	, lat:62.648426 },
        { lng:22.869812	, lat:62.649052 },
        { lng:22.862068	, lat:62.652599 },
        { lng:22.863767	, lat:62.653449 },
        { lng:22.864831	, lat:62.652968 },
        { lng:22.867841	, lat:62.65436 },
        { lng:22.852481	, lat:62.661484 },
        { lng:22.853729	, lat:62.66206 },
        { lng:22.861987	, lat:62.658215 },
        { lng:22.863316	, lat:62.658775 },
        { lng:22.854478	, lat:62.662856 },
        { lng:22.857593	, lat:62.662804 },
        { lng:22.842855	, lat:62.669622 },
        { lng:22.843953	, lat:62.670158 },
        { lng:22.853968	, lat:62.665519 },
        { lng:22.855303	, lat:62.666314 },
        { lng:22.854629	, lat:62.666663 },
        { lng:22.856	, lat:62.667341 },
        { lng:22.855311	, lat:62.667654 },
        { lng:22.856613	, lat:62.668267 },
        { lng:22.860831	, lat:62.666352 },
        { lng:22.862187	, lat:62.666994 },
        { lng:22.863399	, lat:62.66756 },
        { lng:22.862813	, lat:62.667831 },
        { lng:22.8576783	, lat:62.670229 },
        { lng:22.8563122	, lat:62.6708669 },
        { lng:22.85407	, lat:62.671914 },
        { lng:22.8580887	, lat:62.6738525 },
        { lng:22.8560262	, lat:62.6742993 },
        { lng:22.8547138	, lat:62.6745913 },
        { lng:22.8540524	, lat:62.6747813 },
        { lng:22.855664	, lat:62.6757218 },
        { lng:22.855986	, lat:62.6759233 },
        { lng:22.85579	, lat:62.6759797 },
        { lng:22.8554858	, lat:62.6759985 },
        { lng:22.8551291	, lat:62.6759872 },
        { lng:22.8546849	, lat:62.6759735 },
        { lng:22.8542056	, lat:62.6759808 },
        { lng:22.8539268	, lat:62.6760027 },
        { lng:22.8537625	, lat:62.6760299 },
        { lng:22.853623	, lat:62.6760772 },
        { lng:22.8533838	, lat:62.6762446 },
        { lng:22.8532124	, lat:62.676332 },
        { lng:22.8531023	, lat:62.6764465 },
        { lng:22.8530812	, lat:62.6765951 },
        { lng:22.8529586	, lat:62.6766999 },
        { lng:22.8528331	, lat:62.6767798 },
        { lng:22.8527835	, lat:62.6770801 },
        { lng:22.8526697	, lat:62.6772273 },
        { lng:22.8524715	, lat:62.6773256 },
        { lng:22.852209	, lat:62.677439 },
        { lng:22.8519203	, lat:62.6775567 },
        { lng:22.8516468	, lat:62.6776357 },
        { lng:22.8514589	, lat:62.6777069 },
        { lng:22.8518869	, lat:62.6777189 },
        { lng:22.851567	, lat:62.6778668 },
        { lng:22.8514389	, lat:62.6779584 },
        { lng:22.8513616	, lat:62.6780718 },
        { lng:22.8513099	, lat:62.6781897 },
        { lng:22.851317	, lat:62.6783468 },
        { lng:22.8513655	, lat:62.6786322 },
        { lng:22.8514927	, lat:62.6790903 },
        { lng:22.8515819	, lat:62.6793369 },
        { lng:22.8594182	, lat:62.6802615 },
        { lng:22.8610659	, lat:62.6804719 },
        { lng:22.8609469	, lat:62.6806209 },
        { lng:22.8648619	, lat:62.6811589 },
        { lng:22.8655579	, lat:62.6807929 },
        { lng:22.8676659	, lat:62.6811069 },
        { lng:22.8680219	, lat:62.6806589 },
        { lng:22.8707437	, lat:62.6810418 },
        { lng:22.8775559	, lat:62.6819999 },
        { lng:22.8753028	, lat:62.6837586 },
        { lng:22.8707289	, lat:62.6873279 },
        { lng:22.8632794	, lat:62.6932036 },
        { lng:22.8624608	, lat:62.6938492 },
        { lng:22.861869	, lat:62.6943158 },
        { lng:22.8581064	, lat:62.6972827 },
        { lng:22.8551739	, lat:62.699577 },
        { lng:22.8411669	, lat:62.7105301 },
        { lng:22.8410939	, lat:62.7105827 },
        { lng:22.8323869	, lat:62.7174399 },
        { lng:22.8320849	, lat:62.7176379 },
        { lng:22.831998	, lat:62.7177026 },
        { lng:22.8315159	, lat:62.7180609 },
        { lng:22.8313486	, lat:62.7181389 },
        { lng:22.8301993	, lat:62.7186741 },
        { lng:22.8300071	, lat:62.7187635 },
        { lng:22.8269579	, lat:62.7201009 },
        { lng:22.8081359	, lat:62.7283859 },
        { lng:22.7942329	, lat:62.7345229 },
        { lng:22.7936019	, lat:62.7348269 },
        { lng:22.7925999	, lat:62.7352359 },
        { lng:22.7810309	, lat:62.7403829 },
        { lng:22.7805259	, lat:62.7406199 },
        { lng:22.7802789	, lat:62.7407289 },
        { lng:22.7801791	, lat:62.7407727 },
        { lng:22.7782748	, lat:62.7416053 },
        { lng:22.7764751	, lat:62.7423722 },
        { lng:22.7679825	, lat:62.7461618 },
        { lng:22.7643976	, lat:62.7477297 },
        { lng:22.7621995	, lat:62.7486933 },
        { lng:22.7611572	, lat:62.7491503 },
        { lng:22.7366189	, lat:62.7599059 },
        { lng:22.7274829	, lat:62.7627209 },
        { lng:22.7067409	, lat:62.7691089 },
        { lng:22.6978557	, lat:62.7718543 },
        { lng:22.6942138	, lat:62.7729796 },
        { lng:22.6913186	, lat:62.7738742 },
        { lng:22.6874324	, lat:62.7750751 },
        { lng:22.6871663	, lat:62.7751573 },
        { lng:22.6869929	, lat:62.7752109 },
        { lng:22.6886489	, lat:62.7759629 },
        { lng:22.6924779	, lat:62.7772939 },
        { lng:22.6926919	, lat:62.7777049 },
        { lng:22.6936049	, lat:62.7780389 },
        { lng:22.6946049	, lat:62.7780429 },
        { lng:22.6956719	, lat:62.7783989 },
        { lng:22.6943349	, lat:62.7789509 },
        { lng:22.6945019	, lat:62.7790189 },
        { lng:22.6930499	, lat:62.7799549 },
        { lng:22.6946249	, lat:62.7804619 },
        { lng:22.7014419	, lat:62.7827019 },
        { lng:22.7032689	, lat:62.7832349 },
        { lng:22.7142469	, lat:62.7885959 },
        { lng:22.7149239	, lat:62.7945949 },
        { lng:22.7045739	, lat:62.7974339 },
        { lng:22.7072509	, lat:62.8003569 },
        { lng:22.707455	, lat:62.80057 },
        { lng:22.7105652	, lat:62.8035413 },
        { lng:22.7103109	, lat:62.8036389 },
        { lng:22.7101231	, lat:62.8037589 },
        { lng:22.7097179	, lat:62.804058 },
        { lng:22.7092866	, lat:62.8043158 },
        { lng:22.708403	, lat:62.8047793 },
        { lng:22.7081818	, lat:62.8049364 },
        { lng:22.7079095	, lat:62.8051697 },
        { lng:22.7077899	, lat:62.8053754 },
        { lng:22.7076201	, lat:62.8057238 },
        { lng:22.7075485	, lat:62.805891 },
        { lng:22.7073736	, lat:62.8061017 },
        { lng:22.7071867	, lat:62.8062977 },
        { lng:22.7071496	, lat:62.8064317 },
        { lng:22.7072201	, lat:62.8065992 },
        { lng:22.7073854	, lat:62.8067467 },
        { lng:22.7075981	, lat:62.8069399 },
        { lng:22.7077204	, lat:62.8071183 },
        { lng:22.7075863	, lat:62.8079152 },
        { lng:22.707445	, lat:62.8084323 },
        { lng:22.7073251	, lat:62.8086516 },
        { lng:22.7072001	, lat:62.8087902 },
        { lng:22.7068664	, lat:62.808995 },
        { lng:22.7064584	, lat:62.8092381 },
        { lng:22.706344	, lat:62.8093816 },
        { lng:22.7062634	, lat:62.8095261 },
        { lng:22.7065051	, lat:62.8111242 },
        { lng:22.7078779	, lat:62.8117321 },
        { lng:22.7096521	, lat:62.8126596 },
        { lng:22.7113773	, lat:62.81356 },
        { lng:22.7118743	, lat:62.8138817 },
        { lng:22.7124001	, lat:62.8143377 },
        { lng:22.7139887	, lat:62.815879 },
        { lng:22.7147174	, lat:62.8166182 },
        { lng:22.7166707	, lat:62.8189035 },
        { lng:22.7173096	, lat:62.8195719 },
        { lng:22.717598	, lat:62.8198254 },
        { lng:22.7181066	, lat:62.8202722 },
        { lng:22.7212257	, lat:62.8230138 },
        { lng:22.7235943	, lat:62.825204 },
        { lng:22.7242951	, lat:62.8257556 },
        { lng:22.7253896	, lat:62.8264427 },
        { lng:22.727977	, lat:62.8279585 },
        { lng:22.7287312	, lat:62.8283568 },
        { lng:22.7296968	, lat:62.8287443 },
        { lng:22.7309644	, lat:62.8292327 },
        { lng:22.7319887	, lat:62.8295913 },
        { lng:22.7344696	, lat:62.8304039 },
        { lng:22.7359112	, lat:62.8308328 },
        { lng:22.7374649	, lat:62.8312542 },
        { lng:22.738466	, lat:62.8315243 },
        { lng:22.739576	, lat:62.8318282 },
        { lng:22.7399426	, lat:62.8319285 },
        { lng:22.7424434	, lat:62.8326283 },
        { lng:22.7437162	, lat:62.8330805 },
        { lng:22.745029	, lat:62.8336994 },
        { lng:22.745619	, lat:62.8340977 },
        { lng:22.7459241	, lat:62.8344888 },
        { lng:22.7462021	, lat:62.8348805 },
        { lng:22.7465213	, lat:62.8355942 },
        { lng:22.746899	, lat:62.8362055 },
        { lng:22.7478232	, lat:62.8374566 },
        { lng:22.7485156	, lat:62.8382673 },
        { lng:22.7490227	, lat:62.838782 },
        { lng:22.749698	, lat:62.8391321 },
        { lng:22.7503496	, lat:62.8394071 },
        { lng:22.7510786	, lat:62.8396357 },
        { lng:22.7523674	, lat:62.8399039 },
        { lng:22.7534725	, lat:62.8401478 },
        { lng:22.7541518	, lat:62.8403246 },
        { lng:22.7548401	, lat:62.840618 },
        { lng:22.755325	, lat:62.8409058 },
        { lng:22.7559535	, lat:62.8415469 },
        { lng:22.7563989	, lat:62.8421003 },
        { lng:22.7566329	, lat:62.8425748 },
        { lng:22.7567956	, lat:62.8432166 },
        { lng:22.7569737	, lat:62.8442498 },
        { lng:22.757028	, lat:62.8444421 },
        { lng:22.7571439	, lat:62.8446367 },
        { lng:22.7572675	, lat:62.844768 },
        { lng:22.7575069	, lat:62.8449064 },
        { lng:22.758239	, lat:62.8452134 },
        { lng:22.7594165	, lat:62.8455776 },
        { lng:22.7610901	, lat:62.8460288 },
        { lng:22.7622621	, lat:62.8463595 },
        { lng:22.7635819	, lat:62.846849 },
        { lng:22.7650352	, lat:62.8475101 },
        { lng:22.766867	, lat:62.8484909 },
        { lng:22.7662605	, lat:62.8486992 },
        { lng:22.765977	, lat:62.8487969 },
        { lng:22.76616	, lat:62.8488919 },
        { lng:22.765909	, lat:62.8492709 },
        { lng:22.765708	, lat:62.8494359 },
        { lng:22.765395	, lat:62.8494259 },
        { lng:22.762226	, lat:62.8490249 },
        { lng:22.761412	, lat:62.849486 },
        { lng:22.753114	, lat:62.847285 },
        { lng:22.745452	, lat:62.852645 },
        { lng:22.7444251	, lat:62.8545021 },
        { lng:22.744386	, lat:62.854573 },
        { lng:22.744158	, lat:62.856283 },
        { lng:22.745582	, lat:62.861936 },
        { lng:22.746336	, lat:62.865016 },
        { lng:22.73694	, lat:62.871302 },
        { lng:22.714843	, lat:62.872969 },
        { lng:22.71457	, lat:62.873086 },
        { lng:22.712749	, lat:62.873283 },
        { lng:22.710776	, lat:62.873582 },
        { lng:22.710437	, lat:62.873608 },
        { lng:22.699147	, lat:62.875142 },
        { lng:22.693679	, lat:62.8759 },
        { lng:22.6846268	, lat:62.8728766 },
        { lng:22.599093	, lat:62.844293 },
        { lng:22.595569	, lat:62.843527 },
        { lng:22.596056	, lat:62.84303 },
        { lng:22.591124	, lat:62.841949 },
        { lng:22.590533	, lat:62.8424789 },
        { lng:22.5588062	, lat:62.8356557 },
        { lng:22.558603	, lat:62.8356119 },
        { lng:22.5589029	, lat:62.8351992 },
        { lng:22.5591875	, lat:62.8348992 },
        { lng:22.5594453	, lat:62.8346869 },
        { lng:22.5596725	, lat:62.8345247 },
        { lng:22.5599455	, lat:62.8343683 },
        { lng:22.5601252	, lat:62.8342967 },
        { lng:22.5603106	, lat:62.8342208 },
        { lng:22.5608078	, lat:62.834018 },
        { lng:22.5611544	, lat:62.8338482 },
        { lng:22.5617332	, lat:62.8335554 },
        { lng:22.562127	, lat:62.8333759 },
        { lng:22.554257	, lat:62.8317069 },
        { lng:22.55339	, lat:62.8328929 },
        { lng:22.5497546	, lat:62.8325547 },
        { lng:22.54606	, lat:62.8322119 },
        { lng:22.540361	, lat:62.8311499 },
        { lng:22.537802	, lat:62.8324619 },
        { lng:22.5322094	, lat:62.8321657 },
        { lng:22.52719	, lat:62.8318999 },
        { lng:22.52623	, lat:62.8316179 },
        { lng:22.526467	, lat:62.8310949 },
        { lng:22.527115	, lat:62.8304499 },
        { lng:22.532049	, lat:62.830077 },
        { lng:22.532557	, lat:62.829967 },
        { lng:22.411159	, lat:62.80397 },
        { lng:22.3959456	, lat:62.8037349 },
        { lng:22.360472	, lat:62.803186 },
        { lng:22.360014	, lat:62.8031221 },
        { lng:22.3589698	, lat:62.8029763 },
        { lng:22.3026579	, lat:62.795113 },
      ],
    },
    {
      id: 29,
      regionName: 'eCom TP Turku kevyt',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:22.0730442	, lat:60.3415718 },
        { lng:22.0801032	, lat:60.3431058 },
        { lng:22.107408	, lat:60.3383091 },
        { lng:22.1268241	, lat:60.3422272 },
        { lng:22.1345673	, lat:60.3407869 },
        { lng:22.1344072	, lat:60.340614 },
        { lng:22.1348147	, lat:60.3404844 },
        { lng:22.1355861	, lat:60.3403978 },
        { lng:22.1591175	, lat:60.3385798 },
        { lng:22.1687037	, lat:60.3396761 },
        { lng:22.1801621	, lat:60.3367506 },
        { lng:22.183016	, lat:60.3355665 },
        { lng:22.1959442	, lat:60.3335221 },
        { lng:22.2013945	, lat:60.3344195 },
        { lng:22.2120581	, lat:60.3339598 },
        { lng:22.2212205	, lat:60.3401084 },
        { lng:22.2317992	, lat:60.3367263 },
        { lng:22.2385476	, lat:60.3376183 },
        { lng:22.2427962	, lat:60.3388129 },
        { lng:22.2461114	, lat:60.3409313 },
        { lng:22.2550056	, lat:60.3435645 },
        { lng:22.260134	, lat:60.3439148 },
        { lng:22.2664211	, lat:60.3434478 },
        { lng:22.2732554	, lat:60.3433044 },
        { lng:22.2866235	, lat:60.3471794 },
        { lng:22.2896276	, lat:60.347275 },
        { lng:22.3040364	, lat:60.3458525 },
        { lng:22.309197	, lat:60.3481987 },
        { lng:22.3157201	, lat:60.3560162 },
        { lng:22.311224	, lat:60.3621149 },
        { lng:22.3096114	, lat:60.3634134 },
        { lng:22.3074995	, lat:60.3654477 },
        { lng:22.305043	, lat:60.3663214 },
        { lng:22.3047603	, lat:60.3683224 },
        { lng:22.3081003	, lat:60.3712931 },
        { lng:22.3050607	, lat:60.3730142 },
        { lng:22.3044775	, lat:60.3747353 },
        { lng:22.3046719	, lat:60.3776791 },
        { lng:22.3033288	, lat:60.3810419 },
        { lng:22.2925663	, lat:60.3812429 },
        { lng:22.2882543	, lat:60.3815572 },
        { lng:22.2876358	, lat:60.3834437 },
        { lng:22.2817155	, lat:60.3863955 },
        { lng:22.2806729	, lat:60.3883428 },
        { lng:22.2819454	, lat:60.3894298 },
        { lng:22.2838009	, lat:60.3910148 },
        { lng:22.2820513	, lat:60.3932587 },
        { lng:22.2797716	, lat:60.398427 },
        { lng:22.2791896	, lat:60.3991221 },
        { lng:22.2779513	, lat:60.4006007 },
        { lng:22.2778684	, lat:60.4006452 },
        { lng:22.2742048	, lat:60.4026169 },
        { lng:22.2805315	, lat:60.4057765 },
        { lng:22.2814328	, lat:60.4067365 },
        { lng:22.2826875	, lat:60.4071991 },
        { lng:22.2858332	, lat:60.4086564 },
        { lng:22.2867345	, lat:60.4084994 },
        { lng:22.2869289	, lat:60.40829 },
        { lng:22.2869819	, lat:60.4080455 },
        { lng:22.2873195	, lat:60.4080491 },
        { lng:22.2878648	, lat:60.4080548 },
        { lng:22.2881548	, lat:60.408058 },
        { lng:22.2881659	, lat:60.4084296 },
        { lng:22.2884404	, lat:60.4084666 },
        { lng:22.2886819	, lat:60.4084993 },
        { lng:22.2907461	, lat:60.4087786 },
        { lng:22.2908698	, lat:60.4096862 },
        { lng:22.29186	, lat:60.4098818 },
        { lng:22.2930788	, lat:60.4101226 },
        { lng:22.2936974	, lat:60.4096426 },
        { lng:22.2952549	, lat:60.4098581 },
        { lng:22.2953026	, lat:60.4098647 },
        { lng:22.2955883	, lat:60.4099043 },
        { lng:22.2960726	, lat:60.4093153 },
        { lng:22.2963129	, lat:60.409023 },
        { lng:22.2967083	, lat:60.4090628 },
        { lng:22.297556	, lat:60.4091483 },
        { lng:22.2998708	, lat:60.4093818 },
        { lng:22.3004659	, lat:60.4094418 },
        { lng:22.3013318	, lat:60.4090229 },
        { lng:22.3013593	, lat:60.4090323 },
        { lng:22.301586	, lat:60.4091101 },
        { lng:22.3038236	, lat:60.4098783 },
        { lng:22.3079766	, lat:60.4107333 },
        { lng:22.3089783	, lat:60.411252 },
        { lng:22.3094258	, lat:60.4114839 },
        { lng:22.3120552	, lat:60.4123638 },
        { lng:22.3122592	, lat:60.412432 },
        { lng:22.313469	, lat:60.4128367 },
        { lng:22.3190652	, lat:60.4148115 },
        { lng:22.3202504	, lat:60.4152296 },
        { lng:22.3232845	, lat:60.4162169 },
        { lng:22.3260067	, lat:60.4171675 },
        { lng:22.3261868	, lat:60.4172265 },
        { lng:22.3263009	, lat:60.4172658 },
        { lng:22.3264302	, lat:60.4173105 },
        { lng:22.3266648	, lat:60.4174001 },
        { lng:22.3267146	, lat:60.4174175 },
        { lng:22.3268706	, lat:60.4174719 },
        { lng:22.3275569	, lat:60.4177036 },
        { lng:22.3283507	, lat:60.4179717 },
        { lng:22.3288922	, lat:60.418162 },
        { lng:22.3300693	, lat:60.4188188 },
        { lng:22.3307105	, lat:60.4191748 },
        { lng:22.3342512	, lat:60.4211405 },
        { lng:22.3357988	, lat:60.4219502 },
        { lng:22.3387589	, lat:60.4234308 },
        { lng:22.3392832	, lat:60.4236931 },
        { lng:22.3395019	, lat:60.423803 },
        { lng:22.3403347	, lat:60.4242213 },
        { lng:22.3410108	, lat:60.4235103 },
        { lng:22.3411865	, lat:60.4233254 },
        { lng:22.3416355	, lat:60.4233291 },
        { lng:22.3420869	, lat:60.423333 },
        { lng:22.3440282	, lat:60.423349 },
        { lng:22.3445126	, lat:60.4238767 },
        { lng:22.3445816	, lat:60.423952 },
        { lng:22.3460816	, lat:60.425586 },
        { lng:22.3461996	, lat:60.4257681 },
        { lng:22.3469265	, lat:60.4265065 },
        { lng:22.3474049	, lat:60.4264631 },
        { lng:22.3474161	, lat:60.4264619 },
        { lng:22.3505847	, lat:60.4261664 },
        { lng:22.3549852	, lat:60.4275533 },
        { lng:22.3595622	, lat:60.4293411 },
        { lng:22.3630091	, lat:60.4300699 },
        { lng:22.3629945	, lat:60.4304633 },
        { lng:22.3634486	, lat:60.4313047 },
        { lng:22.3634773	, lat:60.431358 },
        { lng:22.3625977	, lat:60.4319807 },
        { lng:22.3631014	, lat:60.4321601 },
        { lng:22.363912	, lat:60.4324691 },
        { lng:22.3641081	, lat:60.4325138 },
        { lng:22.3652404	, lat:60.4326018 },
        { lng:22.3656026	, lat:60.4326234 },
        { lng:22.3663159	, lat:60.432764 },
        { lng:22.3669519	, lat:60.4328241 },
        { lng:22.3672362	, lat:60.4328467 },
        { lng:22.3674899	, lat:60.4328669 },
        { lng:22.3660737	, lat:60.4340526 },
        { lng:22.3673612	, lat:60.4344814 },
        { lng:22.3670818	, lat:60.434792 },
        { lng:22.3668907	, lat:60.4350044 },
        { lng:22.3668617	, lat:60.4350366 },
        { lng:22.3667536	, lat:60.4351568 },
        { lng:22.3654664	, lat:60.4362708 },
        { lng:22.3650258	, lat:60.4366522 },
        { lng:22.3639776	, lat:60.4375591 },
        { lng:22.3638523	, lat:60.4376677 },
        { lng:22.361867	, lat:60.4394699 },
        { lng:22.3616572	, lat:60.4396606 },
        { lng:22.363918	, lat:60.4399789 },
        { lng:22.3659018	, lat:60.4402822 },
        { lng:22.3661738	, lat:60.4402297 },
        { lng:22.3668429	, lat:60.4403238 },
        { lng:22.3687641	, lat:60.4406667 },
        { lng:22.3666994	, lat:60.4430919 },
        { lng:22.3665733	, lat:60.44324 },
        { lng:22.3657762	, lat:60.4441763 },
        { lng:22.3657326	, lat:60.4442274 },
        { lng:22.3656913	, lat:60.4442759 },
        { lng:22.3656331	, lat:60.4443443 },
        { lng:22.3633466	, lat:60.4470298 },
        { lng:22.3594126	, lat:60.4516498 },
        { lng:22.3584819	, lat:60.4527425 },
        { lng:22.3580552	, lat:60.4532437 },
        { lng:22.3550552	, lat:60.456766 },
        { lng:22.3548777	, lat:60.4569801 },
        { lng:22.35462	, lat:60.4572833 },
        { lng:22.3542295	, lat:60.4577471 },
        { lng:22.3534566	, lat:60.4586652 },
        { lng:22.3517498	, lat:60.4606818 },
        { lng:22.3511285	, lat:60.4614158 },
        { lng:22.3502406	, lat:60.4618946 },
        { lng:22.3473614	, lat:60.464381 },
        { lng:22.3468097	, lat:60.464825 },
        { lng:22.34553	, lat:60.4658545 },
        { lng:22.3454905	, lat:60.4658862 },
        { lng:22.3450813	, lat:60.4662154 },
        { lng:22.3449514	, lat:60.4663199 },
        { lng:22.3448258	, lat:60.4664209 },
        { lng:22.3436184	, lat:60.4673922 },
        { lng:22.3426053	, lat:60.4668645 },
        { lng:22.3411011	, lat:60.4663431 },
        { lng:22.3403056	, lat:60.4662076 },
        { lng:22.3378009	, lat:60.4660155 },
        { lng:22.3375354	, lat:60.4663537 },
        { lng:22.3375059	, lat:60.4663886 },
        { lng:22.3359191	, lat:60.4683425 },
        { lng:22.3357616	, lat:60.4685293 },
        { lng:22.3356294	, lat:60.4686382 },
        { lng:22.3355457	, lat:60.4687068 },
        { lng:22.3354772	, lat:60.4687445 },
        { lng:22.3341643	, lat:60.4694627 },
        { lng:22.3340308	, lat:60.469537 },
        { lng:22.3328555	, lat:60.4701809 },
        { lng:22.3311406	, lat:60.4711205 },
        { lng:22.3319743	, lat:60.4717788 },
        { lng:22.3351692	, lat:60.4742962 },
        { lng:22.3398942	, lat:60.4779703 },
        { lng:22.340026	, lat:60.4780727 },
        { lng:22.3403769	, lat:60.4783431 },
        { lng:22.3391833	, lat:60.4787211 },
        { lng:22.3517204	, lat:60.4817161 },
        { lng:22.352626	, lat:60.4808702 },
        { lng:22.3520016	, lat:60.4806355 },
        { lng:22.351006	, lat:60.4795961 },
        { lng:22.3591863	, lat:60.4765807 },
        { lng:22.359916	, lat:60.4763112 },
        { lng:22.3605491	, lat:60.4768563 },
        { lng:22.3608985	, lat:60.4769424 },
        { lng:22.361175	, lat:60.4775736 },
        { lng:22.3614807	, lat:60.4774732 },
        { lng:22.362929	, lat:60.4770159 },
        { lng:22.3645995	, lat:60.4779384 },
        { lng:22.3652822	, lat:60.4783185 },
        { lng:22.3656906	, lat:60.478682 },
        { lng:22.3669854	, lat:60.4798228 },
        { lng:22.3679108	, lat:60.4806368 },
        { lng:22.3686217	, lat:60.4813199 },
        { lng:22.36904	, lat:60.4818186 },
        { lng:22.3693053	, lat:60.4822648 },
        { lng:22.3695395	, lat:60.4828648 },
        { lng:22.3698131	, lat:60.4839973 },
        { lng:22.3699574	, lat:60.4843414 },
        { lng:22.3701635	, lat:60.4847766 },
        { lng:22.370373	, lat:60.4851062 },
        { lng:22.3705971	, lat:60.4853651 },
        { lng:22.3709897	, lat:60.4856673 },
        { lng:22.3716304	, lat:60.4860273 },
        { lng:22.3723234	, lat:60.4863587 },
        { lng:22.3725672	, lat:60.4864753 },
        { lng:22.3637752	, lat:60.4877589 },
        { lng:22.3631385	, lat:60.4876813 },
        { lng:22.3626126	, lat:60.4880524 },
        { lng:22.3626368	, lat:60.4884487 },
        { lng:22.362459	, lat:60.4888625 },
        { lng:22.3616289	, lat:60.4897404 },
        { lng:22.36058	, lat:60.4903042 },
        { lng:22.3579468	, lat:60.489277 },
        { lng:22.3572576	, lat:60.4895818 },
        { lng:22.3566213	, lat:60.4901649 },
        { lng:22.3585123	, lat:60.4911747 },
        { lng:22.3584296	, lat:60.4912028 },
        { lng:22.3575581	, lat:60.4914968 },
        { lng:22.3565684	, lat:60.4917406 },
        { lng:22.3562973	, lat:60.4919418 },
        { lng:22.3558349	, lat:60.4922848 },
        { lng:22.3551723	, lat:60.4927764 },
        { lng:22.3533166	, lat:60.4933159 },
        { lng:22.354426	, lat:60.4943479 },
        { lng:22.3562326	, lat:60.4945693 },
        { lng:22.3562419	, lat:60.4946554 },
        { lng:22.3563186	, lat:60.4953705 },
        { lng:22.35853	, lat:60.4973109 },
        { lng:22.3574697	, lat:60.4971195 },
        { lng:22.3573397	, lat:60.4976757 },
        { lng:22.3573298	, lat:60.4977179 },
        { lng:22.3572619	, lat:60.4980088 },
        { lng:22.3571869	, lat:60.4983291 },
        { lng:22.3585465	, lat:60.4987822 },
        { lng:22.3595374	, lat:60.4991124 },
        { lng:22.3604283	, lat:60.4993228 },
        { lng:22.3608274	, lat:60.4994169 },
        { lng:22.3606995	, lat:60.4996691 },
        { lng:22.3604795	, lat:60.5001024 },
        { lng:22.3602442	, lat:60.5005656 },
        { lng:22.3603616	, lat:60.5006861 },
        { lng:22.3605837	, lat:60.5009143 },
        { lng:22.3608628	, lat:60.501201 },
        { lng:22.3619407	, lat:60.5012183 },
        { lng:22.3624829	, lat:60.501442 },
        { lng:22.3630412	, lat:60.5013362 },
        { lng:22.363036	, lat:60.5022101 },
        { lng:22.3630356	, lat:60.5022715 },
        { lng:22.3627101	, lat:60.5028742 },
        { lng:22.3623817	, lat:60.5034822 },
        { lng:22.3626481	, lat:60.5038377 },
        { lng:22.3628612	, lat:60.5041222 },
        { lng:22.3643574	, lat:60.5051502 },
        { lng:22.3655312	, lat:60.5059568 },
        { lng:22.3656183	, lat:60.5059795 },
        { lng:22.3661467	, lat:60.5061171 },
        { lng:22.3671717	, lat:60.506004 },
        { lng:22.3678257	, lat:60.5072309 },
        { lng:22.3671542	, lat:60.5074745 },
        { lng:22.3674307	, lat:60.5078305 },
        { lng:22.3678843	, lat:60.5084146 },
        { lng:22.3687623	, lat:60.5095451 },
        { lng:22.3689388	, lat:60.5098468 },
        { lng:22.3691907	, lat:60.5105802 },
        { lng:22.3693284	, lat:60.5109552 },
        { lng:22.3696444	, lat:60.5118157 },
        { lng:22.3697479	, lat:60.5120129 },
        { lng:22.3701709	, lat:60.5126239 },
        { lng:22.3704058	, lat:60.5128072 },
        { lng:22.3715722	, lat:60.5126333 },
        { lng:22.3717278	, lat:60.5125773 },
        { lng:22.3720316	, lat:60.512468 },
        { lng:22.3724383	, lat:60.5129694 },
        { lng:22.3727977	, lat:60.5134127 },
        { lng:22.373184	, lat:60.513889 },
        { lng:22.3732998	, lat:60.5140318 },
        { lng:22.3771036	, lat:60.5187219 },
        { lng:22.3763438	, lat:60.5187133 },
        { lng:22.3764851	, lat:60.5203569 },
        { lng:22.3776877	, lat:60.520273 },
        { lng:22.3782876	, lat:60.5202004 },
        { lng:22.3783476	, lat:60.5202731 },
        { lng:22.3817868	, lat:60.5244442 },
        { lng:22.3821757	, lat:60.5254356 },
        { lng:22.381557	, lat:60.5257659 },
        { lng:22.3810446	, lat:60.5253312 },
        { lng:22.3799489	, lat:60.5252443 },
        { lng:22.3799489	, lat:60.5248877 },
        { lng:22.3790477	, lat:60.5248008 },
        { lng:22.3780403	, lat:60.5249834 },
        { lng:22.37698	, lat:60.5249225 },
        { lng:22.3769977	, lat:60.5246964 },
        { lng:22.3766088	, lat:60.524679 },
        { lng:22.3764145	, lat:60.525279 },
        { lng:22.3756191	, lat:60.5256355 },
        { lng:22.3755839	, lat:60.5261051 },
        { lng:22.3761317	, lat:60.5263747 },
        { lng:22.3793303	, lat:60.5268789 },
        { lng:22.3766088	, lat:60.528244 },
        { lng:22.3745234	, lat:60.5277745 },
        { lng:22.3738342	, lat:60.5283048 },
        { lng:22.3757958	, lat:60.5286526 },
        { lng:22.3734631	, lat:60.529809 },
        { lng:22.3733301	, lat:60.5321426 },
        { lng:22.3732688	, lat:60.533217 },
        { lng:22.3760963	, lat:60.5331735 },
        { lng:22.376326	, lat:60.5362682 },
        { lng:22.3744705	, lat:60.5365378 },
        { lng:22.3745059	, lat:60.5360684 },
        { lng:22.3736576	, lat:60.5361814 },
        { lng:22.3737283	, lat:60.5358684 },
        { lng:22.3730744	, lat:60.5359466 },
        { lng:22.3728906	, lat:60.5383858 },
        { lng:22.3728623	, lat:60.5387628 },
        { lng:22.3724205	, lat:60.5391367 },
        { lng:22.3723467	, lat:60.5394487 },
        { lng:22.3722717	, lat:60.5397656 },
        { lng:22.3722478	, lat:60.5398673 },
        { lng:22.3718716	, lat:60.5413843 },
        { lng:22.3719366	, lat:60.541747 },
        { lng:22.3719696	, lat:60.5419277 },
        { lng:22.3723959	, lat:60.5443818 },
        { lng:22.3725619	, lat:60.545368 },
        { lng:22.3719278	, lat:60.5453755 },
        { lng:22.3718197	, lat:60.5453768 },
        { lng:22.3718543	, lat:60.5455134 },
        { lng:22.3723498	, lat:60.5474709 },
        { lng:22.3730233	, lat:60.5486963 },
        { lng:22.3734935	, lat:60.5495517 },
        { lng:22.374446	, lat:60.5512847 },
        { lng:22.3751819	, lat:60.5526235 },
        { lng:22.3756546	, lat:60.5534835 },
        { lng:22.3760257	, lat:60.5535879 },
        { lng:22.3766972	, lat:60.5536747 },
        { lng:22.3763362	, lat:60.5548372 },
        { lng:22.3757076	, lat:60.5553602 },
        { lng:22.3766972	, lat:60.5556816 },
        { lng:22.3763438	, lat:60.5564982 },
        { lng:22.3772804	, lat:60.5564808 },
        { lng:22.3773688	, lat:60.5566024 },
        { lng:22.3772097	, lat:60.5566372 },
        { lng:22.3777768	, lat:60.557773 },
        { lng:22.377946	, lat:60.5577429 },
        { lng:22.3779873	, lat:60.5578214 },
        { lng:22.3786178	, lat:60.5579118 },
        { lng:22.3794333	, lat:60.5596515 },
        { lng:22.3794431	, lat:60.5596962 },
        { lng:22.3791006	, lat:60.5598315 },
        { lng:22.380055	, lat:60.5615275 },
        { lng:22.3824634	, lat:60.5638841 },
        { lng:22.3828333	, lat:60.5642461 },
        { lng:22.3883713	, lat:60.5696645 },
        { lng:22.3887103	, lat:60.5700546 },
        { lng:22.3936686	, lat:60.5757604 },
        { lng:22.3939101	, lat:60.5760383 },
        { lng:22.3940691	, lat:60.5760036 },
        { lng:22.3941661	, lat:60.5760822 },
        { lng:22.3944114	, lat:60.5762812 },
        { lng:22.3945437	, lat:60.5763885 },
        { lng:22.394733	, lat:60.576542 },
        { lng:22.3948291	, lat:60.57662 },
        { lng:22.3945816	, lat:60.5766807 },
        { lng:22.3969086	, lat:60.5789902 },
        { lng:22.3982407	, lat:60.5803123 },
        { lng:22.3982976	, lat:60.5803688 },
        { lng:22.3990736	, lat:60.5812099 },
        { lng:22.4000502	, lat:60.5822684 },
        { lng:22.4014385	, lat:60.5837729 },
        { lng:22.401845	, lat:60.5836687 },
        { lng:22.4028221	, lat:60.5849065 },
        { lng:22.4028523	, lat:60.5849446 },
        { lng:22.4025872	, lat:60.5849273 },
        { lng:22.4059803	, lat:60.5884509 },
        { lng:22.4073234	, lat:60.588208 },
        { lng:22.4076769	, lat:60.5878695 },
        { lng:22.4080456	, lat:60.5877471 },
        { lng:22.4120419	, lat:60.58642 },
        { lng:22.4125544	, lat:60.5867412 },
        { lng:22.4126781	, lat:60.587045 },
        { lng:22.4137886	, lat:60.5877074 },
        { lng:22.4138909	, lat:60.5877684 },
        { lng:22.4168964	, lat:60.589561 },
        { lng:22.4170432	, lat:60.5896485 },
        { lng:22.4133673	, lat:60.5928072 },
        { lng:22.4180464	, lat:60.5955797 },
        { lng:22.4181129	, lat:60.5956191 },
        { lng:22.4183272	, lat:60.5957394 },
        { lng:22.4199479	, lat:60.5966493 },
        { lng:22.4213661	, lat:60.5974825 },
        { lng:22.4231316	, lat:60.5984704 },
        { lng:22.4258702	, lat:60.5999898 },
        { lng:22.4263604	, lat:60.6002617 },
        { lng:22.4273905	, lat:60.6008527 },
        { lng:22.4278739	, lat:60.60113 },
        { lng:22.4289792	, lat:60.601764 },
        { lng:22.4301384	, lat:60.6024289 },
        { lng:22.4302193	, lat:60.6026295 },
        { lng:22.4305186	, lat:60.6033694 },
        { lng:22.430923	, lat:60.6043182 },
        { lng:22.4314356	, lat:60.6056057 },
        { lng:22.432	, lat:60.6070232 },
        { lng:22.4322755	, lat:60.6077152 },
        { lng:22.4333636	, lat:60.6104059 },
        { lng:22.4335087	, lat:60.6107648 },
        { lng:22.4335917	, lat:60.610956 },
        { lng:22.4338058	, lat:60.6114992 },
        { lng:22.434104	, lat:60.6122367 },
        { lng:22.4344161	, lat:60.6130085 },
        { lng:22.4349145	, lat:60.6142408 },
        { lng:22.4350867	, lat:60.6146668 },
        { lng:22.4356345	, lat:60.6146061 },
        { lng:22.4368271	, lat:60.6175635 },
        { lng:22.4369599	, lat:60.6178923 },
        { lng:22.4395958	, lat:60.6176625 },
        { lng:22.4396972	, lat:60.6176545 },
        { lng:22.4407948	, lat:60.6172854 },
        { lng:22.4411236	, lat:60.6180157 },
        { lng:22.4412677	, lat:60.6183356 },
        { lng:22.4414777	, lat:60.6188017 },
        { lng:22.4416153	, lat:60.6191073 },
        { lng:22.4418769	, lat:60.6196881 },
        { lng:22.4422048	, lat:60.6204163 },
        { lng:22.4427434	, lat:60.6216119 },
        { lng:22.4429737	, lat:60.6221232 },
        { lng:22.4435517	, lat:60.6234064 },
        { lng:22.4411306	, lat:60.6236404 },
        { lng:22.4415412	, lat:60.6246791 },
        { lng:22.4420575	, lat:60.6259846 },
        { lng:22.4423878	, lat:60.6268201 },
        { lng:22.4425967	, lat:60.6273484 },
        { lng:22.4427211	, lat:60.6276626 },
        { lng:22.4427744	, lat:60.6276714 },
        { lng:22.4439862	, lat:60.6278696 },
        { lng:22.4444755	, lat:60.6279496 },
        { lng:22.4457581	, lat:60.6281593 },
        { lng:22.4468379	, lat:60.6283358 },
        { lng:22.4475718	, lat:60.6284557 },
        { lng:22.4479168	, lat:60.6285121 },
        { lng:22.4477477	, lat:60.628907 },
        { lng:22.4472469	, lat:60.6300764 },
        { lng:22.4469744	, lat:60.6307126 },
        { lng:22.4462909	, lat:60.6323083 },
        { lng:22.444647	, lat:60.6330547 },
        { lng:22.4428722	, lat:60.6338604 },
        { lng:22.4429747	, lat:60.634169 },
        { lng:22.4430346	, lat:60.6343495 },
        { lng:22.4433932	, lat:60.6351775 },
        { lng:22.4434334	, lat:60.6352699 },
        { lng:22.446843	, lat:60.6351264 },
        { lng:22.447105	, lat:60.6358009 },
        { lng:22.4437289	, lat:60.6359581 },
        { lng:22.4437867	, lat:60.6361038 },
        { lng:22.4439585	, lat:60.6365367 },
        { lng:22.4441941	, lat:60.6371305 },
        { lng:22.4443603	, lat:60.6375491 },
        { lng:22.4447509	, lat:60.6385334 },
        { lng:22.4448615	, lat:60.6388124 },
        { lng:22.4450813	, lat:60.6393658 },
        { lng:22.4451025	, lat:60.639419 },
        { lng:22.4457114	, lat:60.6409092 },
        { lng:22.4458445	, lat:60.641379 },
        { lng:22.44586	, lat:60.6414178 },
        { lng:22.4466405	, lat:60.6413768 },
        { lng:22.4468413	, lat:60.6424445 },
        { lng:22.4469693	, lat:60.6430903 },
        { lng:22.4478974	, lat:60.6436988 },
        { lng:22.4490698	, lat:60.6457531 },
        { lng:22.4490974	, lat:60.6458013 },
        { lng:22.4494134	, lat:60.6463149 },
        { lng:22.4494745	, lat:60.6463901 },
        { lng:22.449473	, lat:60.6464639 },
        { lng:22.4494702	, lat:60.646601 },
        { lng:22.4497984	, lat:60.6481574 },
        { lng:22.4498648	, lat:60.6484716 },
        { lng:22.4496431	, lat:60.6485572 },
        { lng:22.4495856	, lat:60.6485795 },
        { lng:22.4495175	, lat:60.6486058 },
        { lng:22.44879	, lat:60.6488869 },
        { lng:22.4506279	, lat:60.6494734 },
        { lng:22.4507453	, lat:60.649363 },
        { lng:22.4508124	, lat:60.6494031 },
        { lng:22.4509716	, lat:60.6494985 },
        { lng:22.4511622	, lat:60.6496123 },
        { lng:22.4525966	, lat:60.650755 },
        { lng:22.4527608	, lat:60.6508638 },
        { lng:22.4531255	, lat:60.6510058 },
        { lng:22.4530989	, lat:60.6510601 },
        { lng:22.4525936	, lat:60.6520928 },
        { lng:22.4522447	, lat:60.652806 },
        { lng:22.4529412	, lat:60.6537744 },
        { lng:22.4536122	, lat:60.654707 },
        { lng:22.4538181	, lat:60.6549929 },
        { lng:22.453795	, lat:60.6550413 },
        { lng:22.4530542	, lat:60.6565975 },
        { lng:22.4524064	, lat:60.6576457 },
        { lng:22.4522807	, lat:60.6578493 },
        { lng:22.4522658	, lat:60.6579419 },
        { lng:22.4520972	, lat:60.6589998 },
        { lng:22.4529485	, lat:60.6600886 },
        { lng:22.4521422	, lat:60.6607449 },
        { lng:22.4526551	, lat:60.6624066 },
        { lng:22.4526847	, lat:60.6625012 },
        { lng:22.4528316	, lat:60.6627948 },
        { lng:22.4530193	, lat:60.6652902 },
        { lng:22.4537981	, lat:60.6664358 },
        { lng:22.4538857	, lat:60.6665643 },
        { lng:22.4532409	, lat:60.6674552 },
        { lng:22.4531352	, lat:60.6678901 },
        { lng:22.4532808	, lat:60.6698409 },
        { lng:22.4532918	, lat:60.6699881 },
        { lng:22.4534254	, lat:60.671796 },
        { lng:22.4534275	, lat:60.6718254 },
        { lng:22.4534436	, lat:60.672044 },
        { lng:22.4536158	, lat:60.6719662 },
        { lng:22.4536802	, lat:60.6718821 },
        { lng:22.4537907	, lat:60.6717544 },
        { lng:22.4539195	, lat:60.6716861 },
        { lng:22.4541094	, lat:60.6716667 },
        { lng:22.4543594	, lat:60.6716125 },
        { lng:22.4545739	, lat:60.6715442 },
        { lng:22.4547992	, lat:60.6715074 },
        { lng:22.4549891	, lat:60.67153 },
        { lng:22.4551747	, lat:60.6715968 },
        { lng:22.4553325	, lat:60.6716877 },
        { lng:22.4555073	, lat:60.6717649 },
        { lng:22.4556575	, lat:60.671786 },
        { lng:22.4557863	, lat:60.6717387 },
        { lng:22.4559687	, lat:60.6716598 },
        { lng:22.4561833	, lat:60.6716388 },
        { lng:22.4563335	, lat:60.6716651 },
        { lng:22.4563764	, lat:60.6717282 },
        { lng:22.4563764	, lat:60.671828 },
        { lng:22.4562873	, lat:60.671961 },
        { lng:22.4562047	, lat:60.6721118 },
        { lng:22.4562262	, lat:60.6721959 },
        { lng:22.4563549	, lat:60.6722431 },
        { lng:22.4565695	, lat:60.6722589 },
        { lng:22.4568055	, lat:60.6722431 },
        { lng:22.4570705	, lat:60.6722763 },
        { lng:22.457342	, lat:60.6722537 },
        { lng:22.4575029	, lat:60.6721959 },
        { lng:22.4576713	, lat:60.6720976 },
        { lng:22.457857	, lat:60.6720645 },
        { lng:22.4580179	, lat:60.672075 },
        { lng:22.4581359	, lat:60.672138 },
        { lng:22.4581649	, lat:60.6723025 },
        { lng:22.4580393	, lat:60.6725742 },
        { lng:22.4577824	, lat:60.6725856 },
        { lng:22.4547333	, lat:60.6727197 },
        { lng:22.4544135	, lat:60.6727337 },
        { lng:22.453552	, lat:60.6739988 },
        { lng:22.4536475	, lat:60.675973 },
        { lng:22.4534236	, lat:60.6772845 },
        { lng:22.4532782	, lat:60.6781371 },
        { lng:22.4531953	, lat:60.6786233 },
        { lng:22.4530542	, lat:60.6794499 },
        { lng:22.4529707	, lat:60.6799418 },
        { lng:22.4529403	, lat:60.6801207 },
        { lng:22.4529154	, lat:60.6802675 },
        { lng:22.452649	, lat:60.6818364 },
        { lng:22.4526088	, lat:60.6820829 },
        { lng:22.4524448	, lat:60.6830386 },
        { lng:22.4523391	, lat:60.6835662 },
        { lng:22.4521331	, lat:60.6845951 },
        { lng:22.4532908	, lat:60.6848517 },
        { lng:22.4532038	, lat:60.6852429 },
        { lng:22.4531174	, lat:60.685631 },
        { lng:22.4531026	, lat:60.6856976 },
        { lng:22.4530343	, lat:60.6859908 },
        { lng:22.451962	, lat:60.6860609 },
        { lng:22.451939	, lat:60.6861831 },
        { lng:22.4517755	, lat:60.6870496 },
        { lng:22.4516715	, lat:60.6876008 },
        { lng:22.4514504	, lat:60.6887725 },
        { lng:22.4513641	, lat:60.6892568 },
        { lng:22.4512934	, lat:60.6896542 },
        { lng:22.4512239	, lat:60.6900452 },
        { lng:22.4491925	, lat:60.6926541 },
        { lng:22.4488754	, lat:60.6930613 },
        { lng:22.4488172	, lat:60.693136 },
        { lng:22.4479778	, lat:60.694214 },
        { lng:22.4483986	, lat:60.6942649 },
        { lng:22.4494692	, lat:60.6943942 },
        { lng:22.4517147	, lat:60.6967154 },
        { lng:22.4511813	, lat:60.6969583 },
        { lng:22.4510871	, lat:60.697001 },
        { lng:22.4499997	, lat:60.6974934 },
        { lng:22.4491932	, lat:60.6965781 },
        { lng:22.448436	, lat:60.6957186 },
        { lng:22.4476136	, lat:60.695932 },
        { lng:22.4474701	, lat:60.6959502 },
        { lng:22.4473903	, lat:60.6959604 },
        { lng:22.4471898	, lat:60.6959858 },
        { lng:22.4471678	, lat:60.6961455 },
        { lng:22.4470595	, lat:60.6963198 },
        { lng:22.4447222	, lat:60.6992002 },
        { lng:22.4446996	, lat:60.6992645 },
        { lng:22.444484	, lat:60.6998822 },
        { lng:22.4442437	, lat:60.7006131 },
        { lng:22.4441629	, lat:60.7007863 },
        { lng:22.443998	, lat:60.7010922 },
        { lng:22.4424933	, lat:60.7014164 },
        { lng:22.441359	, lat:60.7029134 },
        { lng:22.4406518	, lat:60.7038366 },
        { lng:22.439951	, lat:60.7047514 },
        { lng:22.4349368	, lat:60.7112963 },
        { lng:22.43453	, lat:60.7118272 },
        { lng:22.4340217	, lat:60.7124905 },
        { lng:22.4334292	, lat:60.7132685 },
        { lng:22.4330432	, lat:60.7137757 },
        { lng:22.4325867	, lat:60.7143752 },
        { lng:22.4305665	, lat:60.7170279 },
        { lng:22.4292719	, lat:60.7187281 },
        { lng:22.4288023	, lat:60.7193445 },
        { lng:22.4279514	, lat:60.7204619 },
        { lng:22.4271463	, lat:60.7215085 },
        { lng:22.4149969	, lat:60.7372962 },
        { lng:22.4148017	, lat:60.7364226 },
        { lng:22.410367	, lat:60.7167359 },
        { lng:22.4096815	, lat:60.7136922 },
        { lng:22.4095298	, lat:60.7132116 },
        { lng:22.409124	, lat:60.7112347 },
        { lng:22.4090897	, lat:60.7109054 },
        { lng:22.4078163	, lat:60.7052352 },
        { lng:22.4073674	, lat:60.7032367 },
        { lng:22.4072628	, lat:60.7027706 },
        { lng:22.4069939	, lat:60.701573 },
        { lng:22.4069668	, lat:60.7014524 },
        { lng:22.4066823	, lat:60.7001856 },
        { lng:22.4057337	, lat:60.6959597 },
        { lng:22.4056538	, lat:60.6956038 },
        { lng:22.4055266	, lat:60.6950377 },
        { lng:22.4029109	, lat:60.6955555 },
        { lng:22.4028991	, lat:60.6955118 },
        { lng:22.4028482	, lat:60.6953224 },
        { lng:22.4023503	, lat:60.6934734 },
        { lng:22.4023231	, lat:60.6933725 },
        { lng:22.4025053	, lat:60.6933332 },
        { lng:22.4026932	, lat:60.6932728 },
        { lng:22.4045269	, lat:60.6925288 },
        { lng:22.4048035	, lat:60.6924198 },
        { lng:22.4051125	, lat:60.6923264 },
        { lng:22.4054964	, lat:60.6922581 },
        { lng:22.4058804	, lat:60.6922212 },
        { lng:22.4065593	, lat:60.6921627 },
        { lng:22.4067669	, lat:60.6921448 },
        { lng:22.4067424	, lat:60.6920873 },
        { lng:22.4061172	, lat:60.6906181 },
        { lng:22.4060759	, lat:60.6905213 },
        { lng:22.4063599	, lat:60.6904663 },
        { lng:22.4065766	, lat:60.6904568 },
        { lng:22.4068549	, lat:60.6904917 },
        { lng:22.4074309	, lat:60.6906095 },
        { lng:22.4078816	, lat:60.69062 },
        { lng:22.4078798	, lat:60.6905207 },
        { lng:22.4078654	, lat:60.6897361 },
        { lng:22.4072711	, lat:60.6886384 },
        { lng:22.404311	, lat:60.6891804 },
        { lng:22.4040846	, lat:60.688296 },
        { lng:22.4038904	, lat:60.6875371 },
        { lng:22.4037433	, lat:60.686962 },
        { lng:22.4032461	, lat:60.6850191 },
        { lng:22.4031704	, lat:60.684723 },
        { lng:22.4030845	, lat:60.6843874 },
        { lng:22.4024196	, lat:60.6812749 },
        { lng:22.4013905	, lat:60.6765996 },
        { lng:22.4005743	, lat:60.6728905 },
        { lng:22.4000666	, lat:60.670583 },
        { lng:22.397981	, lat:60.661796 },
        { lng:22.3971004	, lat:60.6579273 },
        { lng:22.3968952	, lat:60.6570762 },
        { lng:22.3968501	, lat:60.656889 },
        { lng:22.396698	, lat:60.6562663 },
        { lng:22.3964547	, lat:60.6552701 },
        { lng:22.3960266	, lat:60.6535174 },
        { lng:22.3959774	, lat:60.6533157 },
        { lng:22.3946923	, lat:60.6524798 },
        { lng:22.393729	, lat:60.6527499 },
        { lng:22.3923873	, lat:60.6531258 },
        { lng:22.392275	, lat:60.6531572 },
        { lng:22.3910092	, lat:60.6509919 },
        { lng:22.3907312	, lat:60.6505164 },
        { lng:22.3901448	, lat:60.6495134 },
        { lng:22.3900803	, lat:60.649422 },
        { lng:22.3906318	, lat:60.6492352 },
        { lng:22.3915083	, lat:60.6489472 },
        { lng:22.3911613	, lat:60.6475018 },
        { lng:22.394478	, lat:60.646906 },
        { lng:22.3944317	, lat:60.646532 },
        { lng:22.3943383	, lat:60.6461721 },
        { lng:22.3933547	, lat:60.6419683 },
        { lng:22.3924148	, lat:60.6419084 },
        { lng:22.3920946	, lat:60.6411993 },
        { lng:22.391964	, lat:60.6409103 },
        { lng:22.3922428	, lat:60.6408805 },
        { lng:22.3923988	, lat:60.6408638 },
        { lng:22.3922744	, lat:60.640644 },
        { lng:22.3920925	, lat:60.6403228 },
        { lng:22.3920091	, lat:60.6403361 },
        { lng:22.389281	, lat:60.6407741 },
        { lng:22.3880263	, lat:60.6400811 },
        { lng:22.3868069	, lat:60.6391539 },
        { lng:22.3862944	, lat:60.638452 },
        { lng:22.3897373	, lat:60.6364147 },
        { lng:22.39034	, lat:60.636058 },
        { lng:22.3914194	, lat:60.6354191 },
        { lng:22.3917092	, lat:60.6344797 },
        { lng:22.3916707	, lat:60.6343693 },
        { lng:22.3914476	, lat:60.6336875 },
        { lng:22.3909475	, lat:60.6331744 },
        { lng:22.3905072	, lat:60.6327651 },
        { lng:22.3904379	, lat:60.6326971 },
        { lng:22.3903767	, lat:60.6326372 },
        { lng:22.391278	, lat:60.6325332 },
        { lng:22.3906065	, lat:60.6307391 },
        { lng:22.3880225	, lat:60.6304783 },
        { lng:22.3880035	, lat:60.6304611 },
        { lng:22.3822121	, lat:60.6252611 },
        { lng:22.3820177	, lat:60.6252524 },
        { lng:22.3806923	, lat:60.6237874 },
        { lng:22.3800384	, lat:60.6236747 },
        { lng:22.3789957	, lat:60.6215941 },
        { lng:22.3790311	, lat:60.62136 },
        { lng:22.3780414	, lat:60.6209178 },
        { lng:22.3779884	, lat:60.6205624 },
        { lng:22.3785716	, lat:60.6200942 },
        { lng:22.3781651	, lat:60.6199381 },
        { lng:22.3775113	, lat:60.6200075 },
        { lng:22.3764056	, lat:60.6208736 },
        { lng:22.3753649	, lat:60.6198525 },
        { lng:22.3751072	, lat:60.6194752 },
        { lng:22.3741706	, lat:60.6190938 },
        { lng:22.3751602	, lat:60.6188163 },
        { lng:22.3739232	, lat:60.6177759 },
        { lng:22.3675827	, lat:60.6139871 },
        { lng:22.362693	, lat:60.611065 },
        { lng:22.3603219	, lat:60.6099488 },
        { lng:22.3604936	, lat:60.6097698 },
        { lng:22.3601717	, lat:60.6094328 },
        { lng:22.35971	, lat:60.6096506 },
        { lng:22.3273341	, lat:60.5937787 },
        { lng:22.3242109	, lat:60.5922316 },
        { lng:22.3203102	, lat:60.5895086 },
        { lng:22.3173992	, lat:60.5870284 },
        { lng:22.3183016	, lat:60.5868211 },
        { lng:22.3137751	, lat:60.5824964 },
        { lng:22.3140807	, lat:60.582425 },
        { lng:22.3130328	, lat:60.5814241 },
        { lng:22.3127271	, lat:60.5814883 },
        { lng:22.308271	, lat:60.5772247 },
        { lng:22.3098173	, lat:60.5771119 },
        { lng:22.3107274	, lat:60.5766603 },
        { lng:22.310427	, lat:60.5762567 },
        { lng:22.3105684	, lat:60.5751106 },
        { lng:22.3090221	, lat:60.5744117 },
        { lng:22.3078203	, lat:60.574516 },
        { lng:22.3070781	, lat:60.5747591 },
        { lng:22.3058865	, lat:60.5749379 },
        { lng:22.3055684	, lat:60.5746316 },
        { lng:22.304029	, lat:60.5731487 },
        { lng:22.3032958	, lat:60.5724424 },
        { lng:22.3031969	, lat:60.5724366 },
        { lng:22.3028986	, lat:60.5724192 },
        { lng:22.3015732	, lat:60.5721413 },
        { lng:22.3007341	, lat:60.5720204 },
        { lng:22.3008451	, lat:60.5719397 },
        { lng:22.3014327	, lat:60.5715128 },
        { lng:22.3016656	, lat:60.5710694 },
        { lng:22.3019559	, lat:60.5708514 },
        { lng:22.3020589	, lat:60.5707741 },
        { lng:22.3016732	, lat:60.5697244 },
        { lng:22.3004718	, lat:60.5662613 },
        { lng:22.3002869	, lat:60.5657278 },
        { lng:22.2998209	, lat:60.5643845 },
        { lng:22.2995843	, lat:60.5637025 },
        { lng:22.2963386	, lat:60.5639743 },
        { lng:22.2959601	, lat:60.5628513 },
        { lng:22.2992581	, lat:60.5625703 },
        { lng:22.2981262	, lat:60.5594122 },
        { lng:22.295041	, lat:60.5504758 },
        { lng:22.2948188	, lat:60.5498324 },
        { lng:22.2941262	, lat:60.547826 },
        { lng:22.2926841	, lat:60.5462185 },
        { lng:22.2925591	, lat:60.5460791 },
        { lng:22.2914438	, lat:60.544836 },
        { lng:22.2907501	, lat:60.5440627 },
        { lng:22.2872564	, lat:60.5401676 },
        { lng:22.2873996	, lat:60.5396099 },
        { lng:22.287458	, lat:60.5393831 },
        { lng:22.2876057	, lat:60.5388076 },
        { lng:22.2874456	, lat:60.5382994 },
        { lng:22.2869507	, lat:60.5379914 },
        { lng:22.2865869	, lat:60.5370679 },
        { lng:22.2859711	, lat:60.5374187 },
        { lng:22.2852042	, lat:60.5378554 },
        { lng:22.2835304	, lat:60.5359727 },
        { lng:22.2836031	, lat:60.5349417 },
        { lng:22.2854661	, lat:60.5346983 },
        { lng:22.2848548	, lat:60.533789 },
        { lng:22.2837487	, lat:60.5338606 },
        { lng:22.2837778	, lat:60.5333093 },
        { lng:22.2828785	, lat:60.5328091 },
        { lng:22.2833411	, lat:60.5325217 },
        { lng:22.2832247	, lat:60.5318631 },
        { lng:22.2831594	, lat:60.531747 },
        { lng:22.2830179	, lat:60.5314956 },
        { lng:22.2825182	, lat:60.530608 },
        { lng:22.281842	, lat:60.529407 },
        { lng:22.2802701	, lat:60.5295574 },
        { lng:22.2801245	, lat:60.5302664 },
        { lng:22.2793677	, lat:60.5306672 },
        { lng:22.2775629	, lat:60.529536 },
        { lng:22.2771229	, lat:60.5291188 },
        { lng:22.2768837	, lat:60.5288919 },
        { lng:22.2765118	, lat:60.5285393 },
        { lng:22.2764431	, lat:60.5284742 },
        { lng:22.2740726	, lat:60.5262267 },
        { lng:22.2735294	, lat:60.5257116 },
        { lng:22.2711328	, lat:60.5234393 },
        { lng:22.2705149	, lat:60.5228532 },
        { lng:22.2691678	, lat:60.5230312 },
        { lng:22.2684737	, lat:60.5231228 },
        { lng:22.2672366	, lat:60.522301 },
        { lng:22.2670478	, lat:60.5215009 },
        { lng:22.2669627	, lat:60.5211401 },
        { lng:22.2668744	, lat:60.5207661 },
        { lng:22.2683412	, lat:60.5207922 },
        { lng:22.2649216	, lat:60.5175394 },
        { lng:22.2641084	, lat:60.5181773 },
        { lng:22.2638704	, lat:60.5183653 },
        { lng:22.2618422	, lat:60.519866 },
        { lng:22.2615352	, lat:60.5200931 },
        { lng:22.2614615	, lat:60.5201479 },
        { lng:22.2584888	, lat:60.5225836 },
        { lng:22.256863	, lat:60.5229923 },
        { lng:22.2561135	, lat:60.5209468 },
        { lng:22.2567393	, lat:60.520801 },
        { lng:22.2564388	, lat:60.5205487 },
        { lng:22.2568718	, lat:60.5204096 },
        { lng:22.2573048	, lat:60.5199878 },
        { lng:22.2579945	, lat:60.5189261 },
        { lng:22.2586165	, lat:60.5179685 },
        { lng:22.2586609	, lat:60.5179 },
        { lng:22.258863	, lat:60.5175887 },
        { lng:22.2564656	, lat:60.51679 },
        { lng:22.2561785	, lat:60.5166943 },
        { lng:22.2548853	, lat:60.5162633 },
        { lng:22.2543904	, lat:60.5166244 },
        { lng:22.2539928	, lat:60.5176638 },
        { lng:22.2527027	, lat:60.5171332 },
        { lng:22.2522609	, lat:60.5183596 },
        { lng:22.2484607	, lat:60.5200026 },
        { lng:22.2485144	, lat:60.5200816 },
        { lng:22.2470735	, lat:60.5208724 },
        { lng:22.2440603	, lat:60.5211028 },
        { lng:22.2387766	, lat:60.5223133 },
        { lng:22.2386197	, lat:60.5223492 },
        { lng:22.2380202	, lat:60.5224829 },
        { lng:22.2296088	, lat:60.5244135 },
        { lng:22.22903	, lat:60.5245464 },
        { lng:22.2286589	, lat:60.5242464 },
        { lng:22.2281935	, lat:60.5241473 },
        { lng:22.2272716	, lat:60.5239507 },
        { lng:22.2266796	, lat:60.5241074 },
        { lng:22.225955	, lat:60.5245335 },
        { lng:22.2258221	, lat:60.5245254 },
        { lng:22.2258012	, lat:60.5245241 },
        { lng:22.2253807	, lat:60.5244987 },
        { lng:22.2245144	, lat:60.5241899 },
        { lng:22.223746	, lat:60.523916 },
        { lng:22.2218992	, lat:60.5241248 },
        { lng:22.2219345	, lat:60.5244291 },
        { lng:22.2218714	, lat:60.524422 },
        { lng:22.2217501	, lat:60.5244085 },
        { lng:22.2214662	, lat:60.5243768 },
        { lng:22.2215787	, lat:60.5230412 },
        { lng:22.2294225	, lat:60.5199966 },
        { lng:22.2296563	, lat:60.5199075 },
        { lng:22.239884	, lat:60.5159357 },
        { lng:22.240961	, lat:60.5155358 },
        { lng:22.2417187	, lat:60.5152233 },
        { lng:22.2355953	, lat:60.51207 },
        { lng:22.2356748	, lat:60.512131 },
        { lng:22.2347471	, lat:60.5120919 },
        { lng:22.2338722	, lat:60.5108827 },
        { lng:22.2336248	, lat:60.5107434 },
        { lng:22.2347117	, lat:60.5096865 },
        { lng:22.2351623	, lat:60.5095212 },
        { lng:22.2354539	, lat:60.5094951 },
        { lng:22.2355423	, lat:60.5094037 },
        { lng:22.2365407	, lat:60.5093211 },
        { lng:22.2378838	, lat:60.5086295 },
        { lng:22.2384052	, lat:60.5086816 },
        { lng:22.2403055	, lat:60.5077459 },
        { lng:22.243577	, lat:60.5061345 },
        { lng:22.2458391	, lat:60.5050204 },
        { lng:22.2493954	, lat:60.5033128 },
        { lng:22.2482045	, lat:60.5022809 },
        { lng:22.2456028	, lat:60.5002586 },
        { lng:22.2454323	, lat:60.5001262 },
        { lng:22.2428217	, lat:60.4980939 },
        { lng:22.2427177	, lat:60.4980129 },
        { lng:22.2423806	, lat:60.4977507 },
        { lng:22.2422058	, lat:60.4976214 },
        { lng:22.24073	, lat:60.4964741 },
        { lng:22.2406799	, lat:60.4964323 },
        { lng:22.2411532	, lat:60.4964821 },
        { lng:22.2419308	, lat:60.4963122 },
        { lng:22.2410118	, lat:60.4955376 },
        { lng:22.2399605	, lat:60.4958301 },
        { lng:22.2399063	, lat:60.4958452 },
        { lng:22.239889	, lat:60.4958317 },
        { lng:22.2386964	, lat:60.494905 },
        { lng:22.2386759	, lat:60.4948894 },
        { lng:22.2382859	, lat:60.494593 },
        { lng:22.2376438	, lat:60.4941049 },
        { lng:22.2376088	, lat:60.4940783 },
        { lng:22.2375334	, lat:60.4940211 },
        { lng:22.2363614	, lat:60.4931303 },
        { lng:22.2336601	, lat:60.4910768 },
        { lng:22.2326443	, lat:60.4904243 },
        { lng:22.2303478	, lat:60.4889393 },
        { lng:22.2294718	, lat:60.4883739 },
        { lng:22.2292332	, lat:60.488461 },
        { lng:22.2291735	, lat:60.4885327 },
        { lng:22.2291131	, lat:60.4886052 },
        { lng:22.2286989	, lat:60.4891028 },
        { lng:22.2285882	, lat:60.4892358 },
        { lng:22.2285506	, lat:60.4893009 },
        { lng:22.2283751	, lat:60.4896046 },
        { lng:22.2282789	, lat:60.4897711 },
        { lng:22.2282511	, lat:60.4898488 },
        { lng:22.2276981	, lat:60.4913947 },
        { lng:22.2259371	, lat:60.491762 },
        { lng:22.2251825	, lat:60.4914299 },
        { lng:22.2241127	, lat:60.4912421 },
        { lng:22.2241954	, lat:60.4908943 },
        { lng:22.2243514	, lat:60.490238 },
        { lng:22.2243557	, lat:60.4902194 },
        { lng:22.2238379	, lat:60.4902597 },
        { lng:22.2235177	, lat:60.4902855 },
        { lng:22.2223411	, lat:60.4903761 },
        { lng:22.222237	, lat:60.4900664 },
        { lng:22.2219611	, lat:60.4891705 },
        { lng:22.2219442	, lat:60.4889997 },
        { lng:22.2219362	, lat:60.4889201 },
        { lng:22.2218904	, lat:60.4884609 },
        { lng:22.2202306	, lat:60.487634 },
        { lng:22.2195753	, lat:60.4873076 },
        { lng:22.2145088	, lat:60.4852901 },
        { lng:22.2103769	, lat:60.4836292 },
        { lng:22.2084475	, lat:60.4828709 },
        { lng:22.2090807	, lat:60.4819406 },
        { lng:22.2110042	, lat:60.4789839 },
        { lng:22.20891	, lat:60.4771855 },
        { lng:22.2087252	, lat:60.4760297 },
        { lng:22.2085633	, lat:60.4751693 },
        { lng:22.2076948	, lat:60.4746557 },
        { lng:22.2076495	, lat:60.4746298 },
        { lng:22.2075779	, lat:60.4745889 },
        { lng:22.2073975	, lat:60.4744902 },
        { lng:22.2073649	, lat:60.4745096 },
        { lng:22.2071341	, lat:60.4746296 },
        { lng:22.2068079	, lat:60.474695 },
        { lng:22.2064183	, lat:60.4747733 },
        { lng:22.2063043	, lat:60.4748022 },
        { lng:22.2056328	, lat:60.4749708 },
        { lng:22.2044078	, lat:60.475583 },
        { lng:22.2040419	, lat:60.4757443 },
        { lng:22.2036667	, lat:60.475879 },
        { lng:22.2032582	, lat:60.4759948 },
        { lng:22.2030846	, lat:60.47603 },
        { lng:22.202758	, lat:60.4760984 },
        { lng:22.2027495	, lat:60.4760882 },
        { lng:22.2027276	, lat:60.4760617 },
        { lng:22.2027158	, lat:60.4760475 },
        { lng:22.2027006	, lat:60.4760276 },
        { lng:22.2026661	, lat:60.4759858 },
        { lng:22.2023529	, lat:60.4756213 },
        { lng:22.202072	, lat:60.4753079 },
        { lng:22.2015511	, lat:60.4746315 },
        { lng:22.2006747	, lat:60.4735671 },
        { lng:22.1998419	, lat:60.4727707 },
        { lng:22.1997317	, lat:60.4721577 },
        { lng:22.1989325	, lat:60.471565 },
        { lng:22.1988505	, lat:60.4710534 },
        { lng:22.1984522	, lat:60.4701374 },
        { lng:22.1983849	, lat:60.4700729 },
        { lng:22.1981684	, lat:60.4698688 },
        { lng:22.1981204	, lat:60.4698208 },
        { lng:22.1979799	, lat:60.4696853 },
        { lng:22.1976811	, lat:60.4693807 },
        { lng:22.1974955	, lat:60.4692699 },
        { lng:22.197252	, lat:60.4692244 },
        { lng:22.1956802	, lat:60.4693739 },
        { lng:22.1951902	, lat:60.4694228 },
        { lng:22.1948972	, lat:60.4694631 },
        { lng:22.1946688	, lat:60.4694714 },
        { lng:22.1945771	, lat:60.4694515 },
        { lng:22.1938137	, lat:60.4692362 },
        { lng:22.193444	, lat:60.4690995 },
        { lng:22.1931474	, lat:60.4688384 },
        { lng:22.1929209	, lat:60.4683379 },
        { lng:22.1926964	, lat:60.4677703 },
        { lng:22.1918083	, lat:60.4668683 },
        { lng:22.1914948	, lat:60.4663807 },
        { lng:22.1912553	, lat:60.4659736 },
        { lng:22.1912599	, lat:60.4658965 },
        { lng:22.1912951	, lat:60.4657647 },
        { lng:22.1913757	, lat:60.4654497 },
        { lng:22.1914248	, lat:60.4651304 },
        { lng:22.1912078	, lat:60.4645641 },
        { lng:22.1907306	, lat:60.4641817 },
        { lng:22.1905496	, lat:60.463677 },
        { lng:22.1903294	, lat:60.4629081 },
        { lng:22.1902634	, lat:60.4626749 },
        { lng:22.1901875	, lat:60.4614411 },
        { lng:22.1901569	, lat:60.4611623 },
        { lng:22.1811482	, lat:60.461905 },
        { lng:22.1810738	, lat:60.4621809 },
        { lng:22.1811197	, lat:60.4622681 },
        { lng:22.1811974	, lat:60.4624158 },
        { lng:22.1813795	, lat:60.4627621 },
        { lng:22.1800014	, lat:60.4628109 },
        { lng:22.1794428	, lat:60.4628306 },
        { lng:22.1767039	, lat:60.4629206 },
        { lng:22.1746643	, lat:60.4629876 },
        { lng:22.173856	, lat:60.4630141 },
        { lng:22.1710461	, lat:60.4631206 },
        { lng:22.1709484	, lat:60.4631239 },
        { lng:22.1706603	, lat:60.4631334 },
        { lng:22.1689767	, lat:60.463189 },
        { lng:22.1675702	, lat:60.4632354 },
        { lng:22.1675064	, lat:60.4632347 },
        { lng:22.1672878	, lat:60.4631238 },
        { lng:22.1672214	, lat:60.46309 },
        { lng:22.1670278	, lat:60.4629918 },
        { lng:22.1666007	, lat:60.4627605 },
        { lng:22.1661995	, lat:60.462543 },
        { lng:22.1661584	, lat:60.4622663 },
        { lng:22.164475	, lat:60.4620592 },
        { lng:22.1626273	, lat:60.4618318 },
        { lng:22.1626376	, lat:60.4619556 },
        { lng:22.1624714	, lat:60.4619439 },
        { lng:22.1613866	, lat:60.4617398 },
        { lng:22.160428	, lat:60.4615236 },
        { lng:22.1605462	, lat:60.4618784 },
        { lng:22.1596156	, lat:60.4620008 },
        { lng:22.1590599	, lat:60.4615676 },
        { lng:22.1584126	, lat:60.4616054 },
        { lng:22.1586885	, lat:60.4623722 },
        { lng:22.1577243	, lat:60.4625141 },
        { lng:22.1576299	, lat:60.4624178 },
        { lng:22.1567455	, lat:60.4625366 },
        { lng:22.1567826	, lat:60.462611 },
        { lng:22.1575005	, lat:60.4629884 },
        { lng:22.1576031	, lat:60.4630426 },
        { lng:22.1575024	, lat:60.4630578 },
        { lng:22.1573589	, lat:60.4630855 },
        { lng:22.1571042	, lat:60.4631415 },
        { lng:22.1569088	, lat:60.4632018 },
        { lng:22.1559557	, lat:60.4635715 },
        { lng:22.1558907	, lat:60.4636025 },
        { lng:22.1555313	, lat:60.4637748 },
        { lng:22.1553013	, lat:60.4638935 },
        { lng:22.1551683	, lat:60.4639617 },
        { lng:22.1547534	, lat:60.4641743 },
        { lng:22.1546152	, lat:60.4642451 },
        { lng:22.1545292	, lat:60.4642857 },
        { lng:22.1544829	, lat:60.4643076 },
        { lng:22.1544085	, lat:60.4643341 },
        { lng:22.1543302	, lat:60.4643596 },
        { lng:22.1542103	, lat:60.464395 },
        { lng:22.1540569	, lat:60.4644182 },
        { lng:22.1536577	, lat:60.4644405 },
        { lng:22.1532781	, lat:60.4644717 },
        { lng:22.1529762	, lat:60.464514 },
        { lng:22.1526718	, lat:60.4645704 },
        { lng:22.1525985	, lat:60.464584 },
        { lng:22.1525254	, lat:60.4645975 },
        { lng:22.1523523	, lat:60.4646292 },
        { lng:22.1521417	, lat:60.4646679 },
        { lng:22.1521087	, lat:60.4646739 },
        { lng:22.1519189	, lat:60.4647039 },
        { lng:22.1518281	, lat:60.4647182 },
        { lng:22.1517104	, lat:60.4647333 },
        { lng:22.1516243	, lat:60.4647443 },
        { lng:22.1514751	, lat:60.4647579 },
        { lng:22.1511131	, lat:60.4647715 },
        { lng:22.1507762	, lat:60.4647822 },
        { lng:22.150283	, lat:60.4647979 },
        { lng:22.1499746	, lat:60.4648076 },
        { lng:22.1498308	, lat:60.4648147 },
        { lng:22.1496321	, lat:60.4648317 },
        { lng:22.1494937	, lat:60.4648509 },
        { lng:22.1490611	, lat:60.4649296 },
        { lng:22.1489176	, lat:60.4647267 },
        { lng:22.1483384	, lat:60.4647739 },
        { lng:22.1470031	, lat:60.4648826 },
        { lng:22.147042	, lat:60.4650745 },
        { lng:22.1468035	, lat:60.4650506 },
        { lng:22.1466017	, lat:60.4650189 },
        { lng:22.1465345	, lat:60.4650035 },
        { lng:22.1463807	, lat:60.4649683 },
        { lng:22.1461629	, lat:60.4649153 },
        { lng:22.1458885	, lat:60.4648441 },
        { lng:22.1446687	, lat:60.4645365 },
        { lng:22.1444147	, lat:60.4644724 },
        { lng:22.1441986	, lat:60.4644182 },
        { lng:22.1436625	, lat:60.4642833 },
        { lng:22.1435826	, lat:60.4642631 },
        { lng:22.1434099	, lat:60.4642197 },
        { lng:22.143324	, lat:60.4641954 },
        { lng:22.1431948	, lat:60.4641588 },
        { lng:22.1430625	, lat:60.4641233 },
        { lng:22.1428356	, lat:60.4640779 },
        { lng:22.14268	, lat:60.4640614 },
        { lng:22.1424961	, lat:60.4640496 },
        { lng:22.1421858	, lat:60.4640402 },
        { lng:22.1419624	, lat:60.464044 },
        { lng:22.1418518	, lat:60.4640499 },
        { lng:22.1416795	, lat:60.4640592 },
        { lng:22.1413638	, lat:60.4640784 },
        { lng:22.1408276	, lat:60.4641157 },
        { lng:22.1406352	, lat:60.4641381 },
        { lng:22.1403942	, lat:60.4641698 },
        { lng:22.1397273	, lat:60.4642617 },
        { lng:22.1394355	, lat:60.4643038 },
        { lng:22.13926	, lat:60.4643196 },
        { lng:22.1390777	, lat:60.4643302 },
        { lng:22.1389509	, lat:60.4643293 },
        { lng:22.138783	, lat:60.4643214 },
        { lng:22.1385311	, lat:60.4642985 },
        { lng:22.1383561	, lat:60.4642731 },
        { lng:22.138099	, lat:60.4642158 },
        { lng:22.1379853	, lat:60.464187 },
        { lng:22.1379068	, lat:60.4641635 },
        { lng:22.1377107	, lat:60.4640934 },
        { lng:22.1376337	, lat:60.4640666 },
        { lng:22.1375526	, lat:60.4642681 },
        { lng:22.1363058	, lat:60.4646363 },
        { lng:22.1361848	, lat:60.4646177 },
        { lng:22.1351352	, lat:60.4644426 },
        { lng:22.1330475	, lat:60.4640907 },
        { lng:22.1300965	, lat:60.4636209 },
        { lng:22.12958	, lat:60.4635356 },
        { lng:22.1216217	, lat:60.4622676 },
        { lng:22.1218152	, lat:60.4615444 },
        { lng:22.1219959	, lat:60.4609825 },
        { lng:22.1205092	, lat:60.460371 },
        { lng:22.1173384	, lat:60.4590665 },
        { lng:22.1160867	, lat:60.4576816 },
        { lng:22.1150387	, lat:60.4550835 },
        { lng:22.113394	, lat:60.4534112 },
        { lng:22.1091008	, lat:60.454939 },
        { lng:22.1087399	, lat:60.4547064 },
        { lng:22.1085036	, lat:60.4478627 },
        { lng:22.1078509	, lat:60.4479411 },
        { lng:22.1051124	, lat:60.4404462 },
        { lng:22.1039917	, lat:60.4409489 },
        { lng:22.0866278	, lat:60.4387731 },
        { lng:22.0852014	, lat:60.4373299 },
        { lng:22.083615	, lat:60.436741 },
        { lng:22.0813007	, lat:60.4353263 },
        { lng:22.0792049	, lat:60.4338468 },
        { lng:22.0758136	, lat:60.4286325 },
        { lng:22.0781569	, lat:60.4255078 },
        { lng:22.0795833	, lat:60.4210177 },
        { lng:22.0845465	, lat:60.4189198 },
        { lng:22.0849831	, lat:60.4133509 },
        { lng:22.0880978	, lat:60.4073426 },
        { lng:22.0877776	, lat:60.406782 },
        { lng:22.0903839	, lat:60.3945617 },
        { lng:22.07923	, lat:60.3801883 },
        { lng:22.0773669	, lat:60.3790158 },
        { lng:22.0726803	, lat:60.3766273 },
        { lng:22.0686632	, lat:60.3769726 },
        { lng:22.0707736	, lat:60.374843 },
        { lng:22.0685322	, lat:60.370274 },
        { lng:22.0685467	, lat:60.3667767 },
        { lng:22.0661598	, lat:60.3652222 },
        { lng:22.0725639	, lat:60.3603207 },
        { lng:22.0792445	, lat:60.3603207 },
        { lng:22.088545	, lat:60.3582908 },
        { lng:22.0733789	, lat:60.3465769 },
        { lng:22.0730442	, lat:60.3415718 },
      ],
    },
    {
      id: 30,
      regionName: 'eCom TP  Vaasa',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [       
        { lng: 21.0276273	, lat:63.1082991 },
        { lng: 21.0370621	, lat:63.1092607 },
        { lng: 21.0953351	, lat:63.1151988 },
        { lng: 21.199932	, lat:63.1257876 },
        { lng: 21.2003136	, lat:63.1257107 },
        { lng: 21.2007492	, lat:63.1256877 },
        { lng: 21.2016554	, lat:63.1258602 },
        { lng: 21.2020133	, lat:63.125992 },
        { lng: 21.238927	, lat:63.1297249 },
        { lng: 21.2866903	, lat:63.1342875 },
        { lng: 21.2936604	, lat:63.1349531 },
        { lng: 21.3054118	, lat:63.1361086 },
        { lng: 21.3884629	, lat:63.1442726 },
        { lng: 21.4115917	, lat:63.1362829 },
        { lng: 21.4480708	, lat:63.1569365 },
        { lng: 21.443328	, lat:63.1752266 },
        { lng: 21.4630798	, lat:63.1708169 },
        { lng: 21.4720401	, lat:63.1788539 },
        { lng: 21.4905821	, lat:63.1742757 },
        { lng: 21.502355	, lat:63.1660976 },
        { lng: 21.5170346	, lat:63.1682047 },
        { lng: 21.5254767	, lat:63.1697971 },
        { lng: 21.5301981	, lat:63.1702881 },
        { lng: 21.535114	, lat:63.1705724 },
        { lng: 21.5367002	, lat:63.170664 },
        { lng: 21.5525956	, lat:63.1690375 },
        { lng: 21.5569455	, lat:63.1686698 },
        { lng: 21.5694589	, lat:63.1673677 },
        { lng: 21.5870958	, lat:63.1640922 },
        { lng: 21.5938784	, lat:63.1627891 },
        { lng: 21.5979181	, lat:63.1620697 },
        { lng: 21.5971302	, lat:63.1624041 },
        { lng: 21.5970142	, lat:63.162775 },
        { lng: 21.5994492	, lat:63.1639567 },
        { lng: 21.6014028	, lat:63.163887 },
        { lng: 21.6000927	, lat:63.161664 },
        { lng: 21.6034823	, lat:63.1602679 },
        { lng: 21.6053344	, lat:63.1574089 },
        { lng: 21.6160568	, lat:63.1544878 },
        { lng: 21.6197589	, lat:63.15163 },
        { lng: 21.6198331	, lat:63.1477437 },
        { lng: 21.6260566	, lat:63.1490596 },
        { lng: 21.6277075	, lat:63.1493619 },
        { lng: 21.6286922	, lat:63.146123 },
        { lng: 21.630179	, lat:63.1462305 },
        { lng: 21.6325102	, lat:63.1463187 },
        { lng: 21.6343868	, lat:63.1463211 },
        { lng: 21.6345034	, lat:63.1463198 },
        { lng: 21.6354922	, lat:63.1462203 },
        { lng: 21.6369164	, lat:63.1460841 },
        { lng: 21.6382681	, lat:63.1465624 },
        { lng: 21.6391778	, lat:63.146824 },
        { lng: 21.6393635	, lat:63.1468776 },
        { lng: 21.6400899	, lat:63.1470865 },
        { lng: 21.6417534	, lat:63.1472626 },
        { lng: 21.6429127	, lat:63.1470139 },
        { lng: 21.6436313	, lat:63.1479225 },
        { lng: 21.645048	, lat:63.1475792 },
        { lng: 21.6468542	, lat:63.1471412 },
        { lng: 21.6492912	, lat:63.1475926 },
        { lng: 21.6536599	, lat:63.1476948 },
        { lng: 21.6532309	, lat:63.147258 },
        { lng: 21.6551183	, lat:63.147099 },
        { lng: 21.6568148	, lat:63.1468104 },
        { lng: 21.6565726	, lat:63.1465228 },
        { lng: 21.6554405	, lat:63.1445849 },
        { lng: 21.6550439	, lat:63.1445606 },
        { lng: 21.655243	, lat:63.144029 },
        { lng: 21.6544109	, lat:63.1439702 },
        { lng: 21.6548557	, lat:63.1435833 },
        { lng: 21.6552279	, lat:63.1431148 },
        { lng: 21.6563016	, lat:63.1427046 },
        { lng: 21.6572628	, lat:63.1419003 },
        { lng: 21.6588674	, lat:63.1412228 },
        { lng: 21.6593021	, lat:63.1409482 },
        { lng: 21.6596722	, lat:63.1405739 },
        { lng: 21.6603963	, lat:63.1396026 },
        { lng: 21.6605599	, lat:63.1390805 },
        { lng: 21.661606	, lat:63.1386321 },
        { lng: 21.66207	, lat:63.1380945 },
        { lng: 21.6628741	, lat:63.1364478 },
        { lng: 21.6637972	, lat:63.1359314 },
        { lng: 21.6634481	, lat:63.135835 },
        { lng: 21.6631127	, lat:63.1355643 },
        { lng: 21.6617086	, lat:63.135817 },
        { lng: 21.6615762	, lat:63.1356018 },
        { lng: 21.6619978	, lat:63.135273 },
        { lng: 21.6621719	, lat:63.1348139 },
        { lng: 21.6642302	, lat:63.1347851 },
        { lng: 21.6653257	, lat:63.1332695 },
        { lng: 21.6664561	, lat:63.1334193 },
        { lng: 21.6670098	, lat:63.1320648 },
        { lng: 21.6660729	, lat:63.1315511 },
        { lng: 21.6660596	, lat:63.1312328 },
        { lng: 21.6648536	, lat:63.1298443 },
        { lng: 21.6646738	, lat:63.1297612 },
        { lng: 21.6640505	, lat:63.1296657 },
        { lng: 21.6637917	, lat:63.1295745 },
        { lng: 21.6674029	, lat:63.1284193 },
        { lng: 21.6643527	, lat:63.1267085 },
        { lng: 21.6651596	, lat:63.1264645 },
        { lng: 21.6656152	, lat:63.126327 },
        { lng: 21.6657998	, lat:63.1251171 },
        { lng: 21.6686757	, lat:63.1247418 },
        { lng: 21.6675503	, lat:63.1231591 },
        { lng: 21.6658958	, lat:63.1213022 },
        { lng: 21.6650455	, lat:63.1211782 },
        { lng: 21.6650595	, lat:63.1205943 },
        { lng: 21.664467	, lat:63.1203734 },
        { lng: 21.6645216	, lat:63.1203095 },
        { lng: 21.6637125	, lat:63.1201932 },
        { lng: 21.6637637	, lat:63.1198961 },
        { lng: 21.6634521	, lat:63.1195254 },
        { lng: 21.6639114	, lat:63.1192862 },
        { lng: 21.6627909	, lat:63.117428 },
        { lng: 21.6644647	, lat:63.1158601 },
        { lng: 21.6646781	, lat:63.1156602 },
        { lng: 21.665247	, lat:63.1149535 },
        { lng: 21.6658122	, lat:63.1142515 },
        { lng: 21.6659958	, lat:63.1137328 },
        { lng: 21.6634838	, lat:63.1139955 },
        { lng: 21.6632013	, lat:63.1136273 },
        { lng: 21.6629853	, lat:63.1133458 },
        { lng: 21.6626211	, lat:63.112871 },
        { lng: 21.6653448	, lat:63.1127657 },
        { lng: 21.6641804	, lat:63.1110639 },
        { lng: 21.6653526	, lat:63.1100057 },
        { lng: 21.6664035	, lat:63.1090568 },
        { lng: 21.6669801	, lat:63.1089569 },
        { lng: 21.6680656	, lat:63.1087686 },
        { lng: 21.6764214	, lat:63.1051152 },
        { lng: 21.679299	, lat:63.1042507 },
        { lng: 21.6840791	, lat:63.1036107 },
        { lng: 21.6854392	, lat:63.1033021 },
        { lng: 21.6867254	, lat:63.1030957 },
        { lng: 21.6896895	, lat:63.1026197 },
        { lng: 21.7045493	, lat:63.1005893 },
        { lng: 21.7051788	, lat:63.101254 },
        { lng: 21.709445	, lat:63.1050845 },
        { lng: 21.7138682	, lat:63.1095135 },
        { lng: 21.7156107	, lat:63.1107394 },
        { lng: 21.7155485	, lat:63.1107515 },
        { lng: 21.7160075	, lat:63.1111406 },
        { lng: 21.7160695	, lat:63.1111287 },
        { lng: 21.7168554	, lat:63.1121609 },
        { lng: 21.7172749	, lat:63.1141879 },
        { lng: 21.717587	, lat:63.1148835 },
        { lng: 21.719318	, lat:63.1166711 },
        { lng: 21.721815	, lat:63.1160762 },
        { lng: 21.7248539	, lat:63.1154742 },
        { lng: 21.7254994	, lat:63.11541 },
        { lng: 21.7269133	, lat:63.115043 },
        { lng: 21.7275582	, lat:63.1149343 },
        { lng: 21.7293792	, lat:63.114442 },
        { lng: 21.7304922	, lat:63.1144936 },
        { lng: 21.7405284	, lat:63.1145288 },
        { lng: 21.7410848	, lat:63.1133657 },
        { lng: 21.7446032	, lat:63.1094463 },
        { lng: 21.7551599	, lat:63.1126287 },
        { lng: 21.7542089	, lat:63.1144381 },
        { lng: 21.752947	, lat:63.1145785 },
        { lng: 21.7524863	, lat:63.1150474 },
        { lng: 21.7526583	, lat:63.1156845 },
        { lng: 21.7535425	, lat:63.1157035 },
        { lng: 21.7503978	, lat:63.1216769 },
        { lng: 21.7587031	, lat:63.1251074 },
        { lng: 21.779966	, lat:63.1145741 },
        { lng: 21.7808981	, lat:63.1152715 },
        { lng: 21.7846428	, lat:63.1151243 },
        { lng: 21.785229	, lat:63.1151848 },
        { lng: 21.7863408	, lat:63.1149005 },
        { lng: 21.7884208	, lat:63.1149589 },
        { lng: 21.7890771	, lat:63.1147383 },
        { lng: 21.7898542	, lat:63.1147844 },
        { lng: 21.7911501	, lat:63.1145525 },
        { lng: 21.7923665	, lat:63.113462 },
        { lng: 21.8024025	, lat:63.1125667 },
        { lng: 21.8035143	, lat:63.1127128 },
        { lng: 21.8041604	, lat:63.1130888 },
        { lng: 21.8048869	, lat:63.112994 },
        { lng: 21.8050374	, lat:63.1126361 },
        { lng: 21.8048532	, lat:63.11235 },
        { lng: 21.8143742	, lat:63.1115259 },
        { lng: 21.800379	, lat:63.1062401 },
        { lng: 21.8007025	, lat:63.1049949 },
        { lng: 21.8009113	, lat:63.0955946 },
        { lng: 21.8057389	, lat:63.0950289 },
        { lng: 21.8070894	, lat:63.0930918 },
        { lng: 21.8252899	, lat:63.0669718 },
        { lng: 21.827369	, lat:63.0638151 },
        { lng: 21.8281536	, lat:63.0627137 },
        { lng: 21.8288944	, lat:63.0616735 },
        { lng: 21.8201538	, lat:63.0580862 },
        { lng: 21.8198974	, lat:63.0581705 },
        { lng: 21.8192427	, lat:63.0579156 },
        { lng: 21.8203301	, lat:63.0574168 },
        { lng: 21.8189808	, lat:63.0571593 },
        { lng: 21.8179807	, lat:63.0571007 },
        { lng: 21.8103266	, lat:63.0539285 },
        { lng: 21.8018468	, lat:63.0505925 },
        { lng: 21.7921507	, lat:63.0454948 },
        { lng: 21.7893762	, lat:63.0443518 },
        { lng: 21.788406	, lat:63.0441994 },
        { lng: 21.7877297	, lat:63.0438701 },
        { lng: 21.787987	, lat:63.0437688 },
        { lng: 21.786815	, lat:63.0432607 },
        { lng: 21.7867623	, lat:63.0428822 },
        { lng: 21.785153	, lat:63.0422223 },
        { lng: 21.785153	, lat:63.0418995 },
        { lng: 21.7859192	, lat:63.0415325 },
        { lng: 21.7877576	, lat:63.042141 },
        { lng: 21.7882464	, lat:63.0421655 },
        { lng: 21.7884923	, lat:63.0420251 },
        { lng: 21.7877749	, lat:63.0413493 },
        { lng: 21.7854827	, lat:63.0403687 },
        { lng: 21.7848963	, lat:63.0401574 },
        { lng: 21.7832368	, lat:63.0406375 },
        { lng: 21.7806205	, lat:63.0397095 },
        { lng: 21.7804644	, lat:63.0398555 },
        { lng: 21.7804781	, lat:63.0400163 },
        { lng: 21.7801893	, lat:63.040625 },
        { lng: 21.7790241	, lat:63.0402128 },
        { lng: 21.7775599	, lat:63.0396041 },
        { lng: 21.7776276	, lat:63.0395144 },
        { lng: 21.7773186	, lat:63.039398 },
        { lng: 21.7723225	, lat:63.0377066 },
        { lng: 21.7703651	, lat:63.0380548 },
        { lng: 21.7696429	, lat:63.0381414 },
        { lng: 21.766917	, lat:63.0381809 },
        { lng: 21.7662531	, lat:63.0382566 },
        { lng: 21.7627487	, lat:63.0388903 },
        { lng: 21.7606492	, lat:63.0391863 },
        { lng: 21.7552902	, lat:63.0402367 },
        { lng: 21.7501705	, lat:63.0413374 },
        { lng: 21.748327	, lat:63.0415199 },
        { lng: 21.7446488	, lat:63.0421065 },
        { lng: 21.7451192	, lat:63.0427186 },
        { lng: 21.7442701	, lat:63.0428267 },
        { lng: 21.7342552	, lat:63.0436577 },
        { lng: 21.7340243	, lat:63.0421165 },
        { lng: 21.7285699	, lat:63.0423731 },
        { lng: 21.7164847	, lat:63.0429416 },
        { lng: 21.6837987	, lat:63.0496865 },
        { lng: 21.661644	, lat:63.0564501 },
        { lng: 21.648879	, lat:63.0604124 },
        { lng: 21.6487711	, lat:63.0604458 },
        { lng: 21.6390398	, lat:63.0628976 },
        { lng: 21.6374788	, lat:63.0633275 },
        { lng: 21.6291664	, lat:63.0559616 },
        { lng: 21.6291101	, lat:63.0558339 },
        { lng: 21.6294978	, lat:63.0556601 },
        { lng: 21.629317	, lat:63.0553435 },
        { lng: 21.6285065	, lat:63.0552771 },
        { lng: 21.6275343	, lat:63.0545527 },
        { lng: 21.6276427	, lat:63.0543659 },
        { lng: 21.6280336	, lat:63.0542881 },
        { lng: 21.6274091	, lat:63.0539794 },
        { lng: 21.6276203	, lat:63.0538271 },
        { lng: 21.6271329	, lat:63.0537035 },
        { lng: 21.6267907	, lat:63.0538363 },
        { lng: 21.6219667	, lat:63.0494493 },
        { lng: 21.6123701	, lat:63.040971 },
        { lng: 21.6085674	, lat:63.0376106 },
        { lng: 21.5964637	, lat:63.0307498 },
        { lng: 21.5956471	, lat:63.0309508 },
        { lng: 21.5956817	, lat:63.0306238 },
        { lng: 21.5939333	, lat:63.0283373 },
        { lng: 21.5610117	, lat:62.9924705 },
        { lng: 21.5577313	, lat:62.9889806 },
        { lng: 21.5630848	, lat:62.9848319 },
        { lng: 21.552603	, lat:62.9822161 },
        { lng: 21.5519276	, lat:62.9824834 },
        { lng: 21.5516437	, lat:62.9818565 },
        { lng: 21.5504812	, lat:62.9822361 },
        { lng: 21.5480282	, lat:62.9832101 },
        { lng: 21.5423756	, lat:62.9855637 },
        { lng: 21.54031	, lat:62.9855437 },
        { lng: 21.5379627	, lat:62.9866265 },
        { lng: 21.537407	, lat:62.9867766 },
        { lng: 21.5372846	, lat:62.9866122 },
        { lng: 21.5370377	, lat:62.9865004 },
        { lng: 21.5359375	, lat:62.9863921 },
        { lng: 21.5361701	, lat:62.9866235 },
        { lng: 21.5361156	, lat:62.9867437 },
        { lng: 21.5353879	, lat:62.9872339 },
        { lng: 21.5342014	, lat:62.9878323 },
        { lng: 21.5338108	, lat:62.9880948 },
        { lng: 21.5340573	, lat:62.9886934 },
        { lng: 21.5336837	, lat:62.9891413 },
        { lng: 21.5303485	, lat:62.990502 },
        { lng: 21.5284862	, lat:62.9916008 },
        { lng: 21.5271655	, lat:62.9919905 },
        { lng: 21.5263234	, lat:62.9921576 },
        { lng: 21.5199576	, lat:62.9947659 },
        { lng: 21.51447	, lat:62.9971074 },
        { lng: 21.4962236	, lat:63.0047112 },
        { lng: 21.4752547	, lat:63.0086635 },
        { lng: 21.4712218	, lat:63.0094603 },
        { lng: 21.4363203	, lat:63.0169831 },
        { lng: 21.4293649	, lat:63.018482 },
        { lng: 21.4295376	, lat:63.0190242 },
        { lng: 21.4292843	, lat:63.0192743 },
        { lng: 21.4259378	, lat:63.0198423 },
        { lng: 21.4248606	, lat:63.0201819 },
        { lng: 21.4238197	, lat:63.0197256 },
        { lng: 21.4234955	, lat:63.0196142 },
        { lng: 21.4233158	, lat:63.0196061 },
        { lng: 21.4214413	, lat:63.0188948 },
        { lng: 21.4203869	, lat:63.0180752 },
        { lng: 21.398154	, lat:63.0170787 },
        { lng: 21.3812274	, lat:63.0199266 },
        { lng: 21.3844382	, lat:63.0237928 },
        { lng: 21.3817779	, lat:63.0245931 },
        { lng: 21.3798931	, lat:63.0244306 },
        { lng: 21.379592	, lat:63.0246624 },
        { lng: 21.3792412	, lat:63.0252884 },
        { lng: 21.3682489	, lat:63.0266889 },
        { lng: 21.3680705	, lat:63.0277566 },
        { lng: 21.3689783	, lat:63.0278112 },
        { lng: 21.3692931	, lat:63.0280636 },
        { lng: 21.3687754	, lat:63.0280943 },
        { lng: 21.368947	, lat:63.028457 },
        { lng: 21.3679708	, lat:63.0289118 },
        { lng: 21.3678864	, lat:63.029427 },
        { lng: 21.367476	, lat:63.0296237 },
        { lng: 21.3674329	, lat:63.0296516 },
        { lng: 21.3728951	, lat:63.0352494 },
        { lng: 21.3793053	, lat:63.0418175 },
        { lng: 21.3784859	, lat:63.0418497 },
        { lng: 21.3779214	, lat:63.0418176 },
        { lng: 21.3749578	, lat:63.0412089 },
        { lng: 21.3742051	, lat:63.0412369 },
        { lng: 21.3739494	, lat:63.0410772 },
        { lng: 21.3742309	, lat:63.0408829 },
        { lng: 21.3742074	, lat:63.0408032 },
        { lng: 21.3741151	, lat:63.040734 },
        { lng: 21.373783	, lat:63.0406597 },
        { lng: 21.372207	, lat:63.0392705 },
        { lng: 21.3593724	, lat:63.0362162 },
        { lng: 21.3537665	, lat:63.034882 },
        { lng: 21.3520915	, lat:63.0344836 },
        { lng: 21.3515047	, lat:63.0346339 },
        { lng: 21.3514591	, lat:63.0346455 },
        { lng: 21.3503711	, lat:63.0349271 },
        { lng: 21.3495966	, lat:63.0351276 },
        { lng: 21.3495714	, lat:63.0351336 },
        { lng: 21.342074	, lat:63.0369265 },
        { lng: 21.3412602	, lat:63.0371196 },
        { lng: 21.3404759	, lat:63.0373045 },
        { lng: 21.3400917	, lat:63.0373939 },
        { lng: 21.3389782	, lat:63.0376562 },
        { lng: 21.337177	, lat:63.0380741 },
        { lng: 21.3352358	, lat:63.03853 },
        { lng: 21.3337412	, lat:63.0388809 },
        { lng: 21.3323722	, lat:63.0392024 },
        { lng: 21.3226305	, lat:63.0414892 },
        { lng: 21.3165913	, lat:63.0429063 },
        { lng: 21.3110382	, lat:63.0442089 },
        { lng: 21.3025836	, lat:63.0461917 },
        { lng: 21.3021556	, lat:63.0462922 },
        { lng: 21.3018908	, lat:63.046368 },
        { lng: 21.3011442	, lat:63.0465294 },
        { lng: 21.3008464	, lat:63.0465991 },
        { lng: 21.2927908	, lat:63.0484791 },
        { lng: 21.2569512	, lat:63.0568193 },
        { lng: 21.2528696	, lat:63.0577682 },
        { lng: 21.1941014	, lat:63.0706515 },
        { lng: 21.0952627	, lat:63.0929902 },
        { lng: 21.0509617	, lat:63.1029885 },
        { lng: 21.0276273	, lat:63.1082991 },
      ],
    },
    {
      id: 31,
      regionName: 'eCom TP  Valkeakoski',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [      
        { lng:23.8114836	, lat:61.2307149 },
        { lng:23.8295238	, lat:61.2367934 },
        { lng:23.8320263	, lat:61.2382001 },
        { lng:23.8900664	, lat:61.2704617 },
        { lng:23.8909195	, lat:61.2709924 },
        { lng:23.8922845	, lat:61.2720054 },
        { lng:23.8945726	, lat:61.2736744 },
        { lng:23.8960579	, lat:61.2734332 },
        { lng:23.8971217	, lat:61.273385 },
        { lng:23.8981554	, lat:61.273877 },
        { lng:23.9001426	, lat:61.2752468 },
        { lng:23.9004236	, lat:61.2753288 },
        { lng:23.9010659	, lat:61.2757774 },
        { lng:23.9014272	, lat:61.2758787 },
        { lng:23.9016626	, lat:61.2763818 },
        { lng:23.9012465	, lat:61.2764237 },
        { lng:23.9008652	, lat:61.2763755 },
        { lng:23.8986937	, lat:61.276615 },
        { lng:23.9089241	, lat:61.2839276 },
        { lng:23.9114231	, lat:61.2857021 },
        { lng:23.9140926	, lat:61.2876693 },
        { lng:23.9170987	, lat:61.2899326 },
        { lng:23.9341145	, lat:61.2996386 },
        { lng:23.9343955	, lat:61.2994458 },
        { lng:23.934817	, lat:61.2995808 },
        { lng:23.9350278	, lat:61.2998699 },
        { lng:23.9356099	, lat:61.3001929 },
        { lng:23.9354693	, lat:61.3004387 },
        { lng:23.9374063	, lat:61.3015278 },
        { lng:23.9402063	, lat:61.3018941 },
        { lng:23.9395339	, lat:61.3025302 },
        { lng:23.9391124	, lat:61.3027808 },
        { lng:23.9396343	, lat:61.3027905 },
        { lng:23.9423541	, lat:61.3043663 },
        { lng:23.9436788	, lat:61.3046072 },
        { lng:23.9435483	, lat:61.3050457 },
        { lng:23.9521392	, lat:61.3099412 },
        { lng:23.9646124	, lat:61.3171025 },
        { lng:23.9583508	, lat:61.3196222 },
        { lng:23.9577477	, lat:61.3200771 },
        { lng:23.9543012	, lat:61.3181129 },
        { lng:23.9533606	, lat:61.3184922 },
        { lng:23.9559451	, lat:61.319922 },
        { lng:23.9518025	, lat:61.3209248 },
        { lng:23.9514201	, lat:61.3207077 },
        { lng:23.9471066	, lat:61.3182576 },
        { lng:23.9458572	, lat:61.3190433 },
        { lng:23.9447257	, lat:61.3194238 },
        { lng:23.9490064	, lat:61.3221147 },
        { lng:23.943584	, lat:61.3232952 },
        { lng:23.9437363	, lat:61.3237922 },
        { lng:23.9450509	, lat:61.3237842 },
        { lng:23.9456257	, lat:61.3241213 },
        { lng:23.9520993	, lat:61.3279175 },
        { lng:23.9578137	, lat:61.3312683 },
        { lng:23.9590334	, lat:61.3300132 },
        { lng:23.9613745	, lat:61.3303989 },
        { lng:23.9633644	, lat:61.3301791 },
        { lng:23.9666605	, lat:61.3294381 },
        { lng:23.966727	, lat:61.3295929 },
        { lng:23.9691589	, lat:61.3287743 },
        { lng:23.9700733	, lat:61.329391 },
        { lng:23.9704985	, lat:61.3296778 },
        { lng:23.9737522	, lat:61.3287267 },
        { lng:23.9669092	, lat:61.3242088 },
        { lng:23.9691973	, lat:61.3220822 },
        { lng:23.9699176	, lat:61.3218748 },
        { lng:23.9711781	, lat:61.3218402 },
        { lng:23.9997313	, lat:61.3401962 },
        { lng:24.0203928	, lat:61.3302357 },
        { lng:24.0204455	, lat:61.3256131 },
        { lng:24.0190673	, lat:61.3244885 },
        { lng:24.0204317	, lat:61.3236127 },
        { lng:24.0235884	, lat:61.3243384 },
        { lng:24.0253276	, lat:61.3222007 },
        { lng:24.0236742	, lat:61.3182126 },
        { lng:24.022451	, lat:61.3176919 },
        { lng:24.0218817	, lat:61.3178704 },
        { lng:24.0218773	, lat:61.3176997 },
        { lng:24.0216789	, lat:61.317565 },
        { lng:24.0214432	, lat:61.3173676 },
        { lng:24.0211585	, lat:61.3172775 },
        { lng:24.0207432	, lat:61.3170082 },
        { lng:24.0209566	, lat:61.3167748 },
        { lng:24.0211755	, lat:61.316685 },
        { lng:24.0217399	, lat:61.3166848 },
        { lng:24.021793	, lat:61.3165883 },
        { lng:24.02179	, lat:61.3164379 },
        { lng:24.022419	, lat:61.3164188 },
        { lng:24.0227117	, lat:61.3163638 },
        { lng:24.022883	, lat:61.3160892 },
        { lng:24.0230434	, lat:61.3159204 },
        { lng:24.0232412	, lat:61.3157786 },
        { lng:24.0483446	, lat:61.332725 },
        { lng:24.0561313	, lat:61.3380148 },
        { lng:24.0555456	, lat:61.3382545 },
        { lng:24.0609709	, lat:61.3416577 },
        { lng:24.0641502	, lat:61.3438213 },
        { lng:24.0657779	, lat:61.3418288 },
        { lng:24.0612296	, lat:61.3388284 },
        { lng:24.0670746	, lat:61.3356318 },
        { lng:24.0687299	, lat:61.3357331 },
        { lng:24.0717393	, lat:61.334224 },
        { lng:24.0718578	, lat:61.3336134 },
        { lng:24.0790851	, lat:61.3303023 },
        { lng:24.0799845	, lat:61.3305856 },
        { lng:24.085306	, lat:61.3278034 },
        { lng:24.1016567	, lat:61.3175682 },
        { lng:24.0882457	, lat:61.3060727 },
        { lng:24.0844752	, lat:61.2988499 },
        { lng:24.0846863	, lat:61.2948951 },
        { lng:24.0878535	, lat:61.2913455 },
        { lng:24.103126	, lat:61.2896889 },
        { lng:24.1666797	, lat:61.2646599 },
        { lng:24.2023491	, lat:61.2621473 },
        { lng:24.2172791	, lat:61.261908 },
        { lng:24.2281436	, lat:61.2582878 },
        { lng:24.2294509	, lat:61.2578522 },
        { lng:24.2316449	, lat:61.2571211 },
        { lng:24.2755068	, lat:61.2425012 },
        { lng:24.2766489	, lat:61.2421204 },
        { lng:24.2789909	, lat:61.2413396 },
        { lng:24.2798738	, lat:61.2410357 },
        { lng:24.2825508	, lat:61.240114 },
        { lng:24.2798202	, lat:61.2377217 },
        { lng:24.2771232	, lat:61.2353585 },
        { lng:24.2770431	, lat:61.2352883 },
        { lng:24.2762282	, lat:61.2345742 },
        { lng:24.275771	, lat:61.2346047 },
        { lng:24.2749569	, lat:61.2346594 },
        { lng:24.2747067	, lat:61.2344701 },
        { lng:24.2724414	, lat:61.232757 },
        { lng:24.2718481	, lat:61.2323084 },
        { lng:24.2699587	, lat:61.2307476 },
        { lng:24.269713	, lat:61.2305446 },
        { lng:24.2693322	, lat:61.2303714 },
        { lng:24.2692643	, lat:61.2302662 },
        { lng:24.2687985	, lat:61.2300021 },
        { lng:24.2734977	, lat:61.2286595 },
        { lng:24.2730964	, lat:61.2282701 },
        { lng:24.2721674	, lat:61.2273685 },
        { lng:24.2721234	, lat:61.2272151 },
        { lng:24.2718884	, lat:61.2271412 },
        { lng:24.2718641	, lat:61.2271167 },
        { lng:24.2702464	, lat:61.2254885 },
        { lng:24.2700001	, lat:61.2252408 },
        { lng:24.2684541	, lat:61.2254592 },
        { lng:24.2664875	, lat:61.2256916 },
        { lng:24.264655	, lat:61.224166 },
        { lng:24.2635387	, lat:61.2231516 },
        { lng:24.2628898	, lat:61.2225614 },
        { lng:24.2619503	, lat:61.2217075 },
        { lng:24.2618662	, lat:61.2216322 },
        { lng:24.2617652	, lat:61.2215421 },
        { lng:24.2613993	, lat:61.2212154 },
        { lng:24.2613307	, lat:61.221154 },
        { lng:24.2610495	, lat:61.2209029 },
        { lng:24.2609368	, lat:61.2208023 },
        { lng:24.2608402	, lat:61.2207161 },
        { lng:24.2604718	, lat:61.2203873 },
        { lng:24.2596575	, lat:61.2200655 },
        { lng:24.2598925	, lat:61.2198294 },
        { lng:24.2595932	, lat:61.2195489 },
        { lng:24.2580257	, lat:61.2182694 },
        { lng:24.2594644	, lat:61.2163049 },
        { lng:24.2616316	, lat:61.2134325 },
        { lng:24.2655155	, lat:61.2082139 },
        { lng:24.2697748	, lat:61.2059764 },
        { lng:24.2699787	, lat:61.2059402 },
        { lng:24.2701949	, lat:61.2059629 },
        { lng:24.2703542	, lat:61.2060022 },
        { lng:24.2705902	, lat:61.2059506 },
        { lng:24.270719	, lat:61.205842 },
        { lng:24.2710596	, lat:61.2057262 },
        { lng:24.2711923	, lat:61.2055905 },
        { lng:24.2712232	, lat:61.2054545 },
        { lng:24.2712221	, lat:61.205332 },
        { lng:24.2710837	, lat:61.2053046 },
        { lng:24.2716223	, lat:61.2050028 },
        { lng:24.2963502	, lat:61.191912 },
        { lng:24.2971441	, lat:61.1914881 },
        { lng:24.2816398	, lat:61.1815719 },
        { lng:24.2815218	, lat:61.1817064 },
        { lng:24.2811463	, lat:61.1817736 },
        { lng:24.2807494	, lat:61.1819132 },
        { lng:24.2806206	, lat:61.1820011 },
        { lng:24.2801807	, lat:61.1820942 },
        { lng:24.2798707	, lat:61.1821082 },
        { lng:24.2796443	, lat:61.1820477 },
        { lng:24.2795048	, lat:61.1820684 },
        { lng:24.2792806	, lat:61.182072 },
        { lng:24.2792591	, lat:61.1818134 },
        { lng:24.2791411	, lat:61.1817824 },
        { lng:24.279066	, lat:61.1817307 },
        { lng:24.278948	, lat:61.181591 },
        { lng:24.2788396	, lat:61.1814581 },
        { lng:24.2786476	, lat:61.1814462 },
        { lng:24.278551	, lat:61.18141 },
        { lng:24.2787656	, lat:61.1810997 },
        { lng:24.2787119	, lat:61.1806498 },
        { lng:24.2786894	, lat:61.1805427 },
        { lng:24.2785081	, lat:61.1805153 },
        { lng:24.2783354	, lat:61.1804548 },
        { lng:24.2780993	, lat:61.1804444 },
        { lng:24.2777034	, lat:61.1804894 },
        { lng:24.277403	, lat:61.180386 },
        { lng:24.2772314	, lat:61.1802515 },
        { lng:24.2771777	, lat:61.1801378 },
        { lng:24.2770597	, lat:61.1800136 },
        { lng:24.2770694	, lat:61.1798755 },
        { lng:24.2772957	, lat:61.1797395 },
        { lng:24.2775414	, lat:61.1796014 },
        { lng:24.2776069	, lat:61.1795119 },
        { lng:24.2775522	, lat:61.1792808 },
        { lng:24.2774985	, lat:61.1791153 },
        { lng:24.2776165	, lat:61.1790222 },
        { lng:24.2778354	, lat:61.1788824 },
        { lng:24.2775843	, lat:61.1787118 },
        { lng:24.2675003	, lat:61.1721927 },
        { lng:24.2355118	, lat:61.1514239 },
        { lng:24.2318909	, lat:61.1499866 },
        { lng:24.2067607	, lat:61.1475073 },
        { lng:24.1823665	, lat:61.1450785 },
        { lng:24.1389362	, lat:61.1432145 },
        { lng:24.1343228	, lat:61.1382744 },
        { lng:24.1345771	, lat:61.137845 },
        { lng:24.1340868	, lat:61.1366585 },
        { lng:24.1352423	, lat:61.1343232 },
        { lng:24.1353528	, lat:61.1331884 },
        { lng:24.1372625	, lat:61.1318934 },
        { lng:24.1387431	, lat:61.1316552 },
        { lng:24.1408889	, lat:61.1291584 },
        { lng:24.1441687	, lat:61.1290087 },
        { lng:24.143335	, lat:61.1276561 },
        { lng:24.1435464	, lat:61.1274235 },
        { lng:24.1446407	, lat:61.1256309 },
        { lng:24.142495	, lat:61.1248538 },
        { lng:24.1419371	, lat:61.1231544 },
        { lng:24.1120249	, lat:61.1282042 },
        { lng:24.1110809	, lat:61.1282731 },
        { lng:24.1104169	, lat:61.1281981 },
        { lng:24.1099233	, lat:61.1281456 },
        { lng:24.0881137	, lat:61.1256807 },
        { lng:24.0873091	, lat:61.1256045 },
        { lng:24.0637485	, lat:61.1229207 },
        { lng:24.0652474	, lat:61.1222528 },
        { lng:24.0653793	, lat:61.1218741 },
        { lng:24.0663202	, lat:61.121258 },
        { lng:24.0660842	, lat:61.12103 },
        { lng:24.0632765	, lat:61.1195009 },
        { lng:24.0624377	, lat:61.1192143 },
        { lng:24.0615169	, lat:61.1188998 },
        { lng:24.0525262	, lat:61.1206305 },
        { lng:24.0524618	, lat:61.1207859 },
        { lng:24.0521828	, lat:61.1210347 },
        { lng:24.0520756	, lat:61.1212316 },
        { lng:24.0520112	, lat:61.1215114 },
        { lng:24.0517752	, lat:61.1216979 },
        { lng:24.0513031	, lat:61.1218637 },
        { lng:24.0501229	, lat:61.1223197 },
        { lng:24.0497367	, lat:61.1225062 },
        { lng:24.0495436	, lat:61.1227031 },
        { lng:24.0492217	, lat:61.123159 },
        { lng:24.048299	, lat:61.1235839 },
        { lng:24.0476124	, lat:61.1239466 },
        { lng:24.046429	, lat:61.1244704 },
        { lng:24.0415398	, lat:61.1226098 },
        { lng:24.0371625	, lat:61.1248377 },
        { lng:24.027004	, lat:61.1224693 },
        { lng:24.024481	, lat:61.1218948 },
        { lng:24.02373	, lat:61.120589 },
        { lng:24.0263017	, lat:61.1195584 },
        { lng:24.0118177	, lat:61.1158065 },
        { lng:23.9943941	, lat:61.1112871 },
        { lng:23.9805539	, lat:61.1076897 },
        { lng:23.9706652	, lat:61.1051439 },
        { lng:23.9582648	, lat:61.1019078 },
        { lng:23.9577906	, lat:61.1021159 },
        { lng:23.957311	, lat:61.1019717 },
        { lng:23.9536278	, lat:61.1035572 },
        { lng:23.952365	, lat:61.1046651 },
        { lng:23.9454954	, lat:61.1027277 },
        { lng:23.9444042	, lat:61.1036386 },
        { lng:23.9439515	, lat:61.1035261 },
        { lng:23.9432219	, lat:61.1033291 },
        { lng:23.9392093	, lat:61.1062012 },
        { lng:23.936849	, lat:61.1068958 },
        { lng:23.9364198	, lat:61.1067818 },
        { lng:23.9341239	, lat:61.1074142 },
        { lng:23.9431575	, lat:61.1102859 },
        { lng:23.9403702	, lat:61.1122954 },
        { lng:23.9350487	, lat:61.1105954 },
        { lng:23.9342955	, lat:61.1112811 },
        { lng:23.9334158	, lat:61.1117372 },
        { lng:23.9323665	, lat:61.1128448 },
        { lng:23.9369799	, lat:61.1143168 },
        { lng:23.934789	, lat:61.1150335 },
        { lng:23.9339973	, lat:61.1156539 },
        { lng:23.9276436	, lat:61.1172205 },
        { lng:23.928826	, lat:61.118017 },
        { lng:23.9215282	, lat:61.1198322 },
        { lng:23.9250065	, lat:61.121323 },
        { lng:23.9239958	, lat:61.1219256 },
        { lng:23.9236761	, lat:61.1218308 },
        { lng:23.9231161	, lat:61.1221743 },
        { lng:23.9241472	, lat:61.1226189 },
        { lng:23.9311198	, lat:61.125625 },
        { lng:23.9301542	, lat:61.1261845 },
        { lng:23.9417864	, lat:61.1311146 },
        { lng:23.941162	, lat:61.1314788 },
        { lng:23.9357117	, lat:61.1318207 },
        { lng:23.9261201	, lat:61.1275729 },
        { lng:23.9243177	, lat:61.1285158 },
        { lng:23.9250709	, lat:61.1288768 },
        { lng:23.9226869	, lat:61.1300077 },
        { lng:23.9195112	, lat:61.1284432 },
        { lng:23.9191679	, lat:61.1292514 },
        { lng:23.9191249	, lat:61.1298626 },
        { lng:23.9383725	, lat:61.139082 },
        { lng:23.9398316	, lat:61.1397759 },
        { lng:23.9386514	, lat:61.1403559 },
        { lng:23.9418701	, lat:61.1418887 },
        { lng:23.9407972	, lat:61.1418887 },
        { lng:23.940411	, lat:61.1419405 },
        { lng:23.9310983	, lat:61.1447469 },
        { lng:23.9426018	, lat:61.1556063 },
        { lng:23.9483921	, lat:61.1536824 },
        { lng:23.9487601	, lat:61.1539189 },
        { lng:23.9497257	, lat:61.1536084 },
        { lng:23.9581586	, lat:61.1601712 },
        { lng:23.9581339	, lat:61.160328 },
        { lng:23.9540784	, lat:61.1602762 },
        { lng:23.9537995	, lat:61.1601934 },
        { lng:23.9537995	, lat:61.1601106 },
        { lng:23.9519756	, lat:61.1599554 },
        { lng:23.9491861	, lat:61.1599036 },
        { lng:23.9492224	, lat:61.1601095 },
        { lng:23.9492336	, lat:61.160218 },
        { lng:23.9492719	, lat:61.1603901 },
        { lng:23.9479268	, lat:61.1602561 },
        { lng:23.9473868	, lat:61.1602022 },
        { lng:23.9468043	, lat:61.1601831 },
        { lng:23.9465419	, lat:61.1608584 },
        { lng:23.9450265	, lat:61.1647561 },
        { lng:23.9465929	, lat:61.1651493 },
        { lng:23.947448	, lat:61.1653992 },
        { lng:23.9479662	, lat:61.1656461 },
        { lng:23.9480538	, lat:61.1658114 },
        { lng:23.9481916	, lat:61.1660717 },
        { lng:23.9482848	, lat:61.1662478 },
        { lng:23.9491002	, lat:61.1660202 },
        { lng:23.9494221	, lat:61.1660098 },
        { lng:23.9498298	, lat:61.1659684 },
        { lng:23.9503662	, lat:61.1671378 },
        { lng:23.9510776	, lat:61.1671466 },
        { lng:23.9511317	, lat:61.1672063 },
        { lng:23.9512278	, lat:61.1673121 },
        { lng:23.9586092	, lat:61.1729617 },
        { lng:23.958091	, lat:61.1736564 },
        { lng:23.958091	, lat:61.1739357 },
        { lng:23.9581768	, lat:61.1760462 },
        { lng:23.9583303	, lat:61.1761688 },
        { lng:23.9583056	, lat:61.1762945 },
        { lng:23.9583732	, lat:61.1841646 },
        { lng:23.93816	, lat:61.1850023 },
        { lng:23.9286972	, lat:61.1962837 },
        { lng:23.9230796	, lat:61.2029617 },
        { lng:23.9065845	, lat:61.2064097 },
        { lng:23.9054135	, lat:61.2068184 },
        { lng:23.882928	, lat:61.2019384 },
        { lng:23.8819409	, lat:61.2026722 },
        { lng:23.86915	, lat:61.2057952 },
        { lng:23.8464385	, lat:61.2095902 },
        { lng:23.8457039	, lat:61.2097129 },
        { lng:23.8406693	, lat:61.2105683 },
        { lng:23.8372446	, lat:61.211829 },
        { lng:23.8183619	, lat:61.2157245 },
        { lng:23.8114525	, lat:61.2124593 },
        { lng:23.7976552	, lat:61.2169746 },
        { lng:23.7984921	, lat:61.2268399 },
        { lng:23.8089634	, lat:61.2288229 },
        { lng:23.8114836	, lat:61.2307149 },
      ],
    },
    {
      id: 32,
      regionName: 'eCom TP  Vantaa Loke',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [       
        { lng:24.7462404	, lat:60.3222773 },
        { lng:24.7609248	, lat:60.3253743 },
        { lng:24.7619421	, lat:60.3229304 },
        { lng:24.7621789	, lat:60.3222169 },
        { lng:24.7629191	, lat:60.3204728 },
        { lng:24.7635009	, lat:60.3190132 },
        { lng:24.7635229	, lat:60.3189583 },
        { lng:24.7648679	, lat:60.315584 },
        { lng:24.7653381	, lat:60.314399 },
        { lng:24.7666117	, lat:60.3114211 },
        { lng:24.7678155	, lat:60.3086056 },
        { lng:24.7684251	, lat:60.3071258 },
        { lng:24.769196	, lat:60.3053199 },
        { lng:24.7694499	, lat:60.3047067 },
        { lng:24.7695067	, lat:60.3045698 },
        { lng:24.7705547	, lat:60.3020397 },
        { lng:24.7706884	, lat:60.3017168 },
        { lng:24.7708113	, lat:60.3014207 },
        { lng:24.7708776	, lat:60.3012608 },
        { lng:24.7709543	, lat:60.3010762 },
        { lng:24.7710356	, lat:60.3008794 },
        { lng:24.7711352	, lat:60.3006315 },
        { lng:24.771801	, lat:60.2992165 },
        { lng:24.7717229	, lat:60.2988709 },
        { lng:24.7711941	, lat:60.2968204 },
        { lng:24.7711158	, lat:60.296501 },
        { lng:24.7710619	, lat:60.2962754 },
        { lng:24.7710074	, lat:60.2960584 },
        { lng:24.7687715	, lat:60.2869257 },
        { lng:24.7672625	, lat:60.2807609 },
        { lng:24.7656884	, lat:60.274307 },
        { lng:24.7650684	, lat:60.2717917 },
        { lng:24.7649364	, lat:60.2712464 },
        { lng:24.7654861	, lat:60.270065 },
        { lng:24.765652	, lat:60.2701495 },
        { lng:24.7667272	, lat:60.269714 },
        { lng:24.7679559	, lat:60.2692189 },
        { lng:24.7693353	, lat:60.2686632 },
        { lng:24.7699768	, lat:60.2683345 },
        { lng:24.7685378	, lat:60.2669064 },
        { lng:24.7671623	, lat:60.2663036 },
        { lng:24.7676557	, lat:60.2651893 },
        { lng:24.7679621	, lat:60.2643796 },
        { lng:24.7679684	, lat:60.2638154 },
        { lng:24.7677635	, lat:60.2636786 },
        { lng:24.7686989	, lat:60.2626407 },
        { lng:24.7690343	, lat:60.2622554 },
        { lng:24.7691072	, lat:60.2621681 },
        { lng:24.7691363	, lat:60.2621332 },
        { lng:24.7691652	, lat:60.262102 },
        { lng:24.7692115	, lat:60.2620519 },
        { lng:24.7695585	, lat:60.2620766 },
        { lng:24.7698836	, lat:60.261901 },
        { lng:24.7699684	, lat:60.2617613 },
        { lng:24.7699299	, lat:60.2614233 },
        { lng:24.769911	, lat:60.2612571 },
        { lng:24.7703573	, lat:60.2607933 },
        { lng:24.7705244	, lat:60.2607819 },
        { lng:24.7709521	, lat:60.2603756 },
        { lng:24.7722935	, lat:60.2591009 },
        { lng:24.7724305	, lat:60.2589623 },
        { lng:24.7728555	, lat:60.2585148 },
        { lng:24.7730307	, lat:60.2583598 },
        { lng:24.7731025	, lat:60.2582931 },
        { lng:24.7731611	, lat:60.2582387 },
        { lng:24.7738902	, lat:60.2575609 },
        { lng:24.7740363	, lat:60.257415 },
        { lng:24.7740514	, lat:60.2573942 },
        { lng:24.7740745	, lat:60.2573625 },
        { lng:24.7740942	, lat:60.2573334 },
        { lng:24.7744031	, lat:60.256878 },
        { lng:24.775269	, lat:60.2555692 },
        { lng:24.7753063	, lat:60.2555128 },
        { lng:24.775432	, lat:60.2553227 },
        { lng:24.775819	, lat:60.2547333 },
        { lng:24.7761539	, lat:60.2541915 },
        { lng:24.7761996	, lat:60.2541251 },
        { lng:24.7762427	, lat:60.2540624 },
        { lng:24.7763119	, lat:60.2539618 },
        { lng:24.7763512	, lat:60.2539046 },
        { lng:24.7770683	, lat:60.2529115 },
        { lng:24.7773111	, lat:60.2525492 },
        { lng:24.7774543	, lat:60.2523263 },
        { lng:24.7774766	, lat:60.2522915 },
        { lng:24.7775155	, lat:60.252234 },
        { lng:24.7796296	, lat:60.2490962 },
        { lng:24.779708	, lat:60.2489798 },
        { lng:24.7802506	, lat:60.2481624 },
        { lng:24.7810992	, lat:60.2470717 },
        { lng:24.7835043	, lat:60.2433489 },
        { lng:24.783641	, lat:60.2431437 },
        { lng:24.7838861	, lat:60.2427759 },
        { lng:24.784756	, lat:60.2415058 },
        { lng:24.7850613	, lat:60.2415895 },
        { lng:24.7860569	, lat:60.2418639 },
        { lng:24.7873929	, lat:60.2422199 },
        { lng:24.7877029	, lat:60.2423007 },
        { lng:24.7881196	, lat:60.2424095 },
        { lng:24.7894979	, lat:60.2427938 },
        { lng:24.7896835	, lat:60.2428456 },
        { lng:24.7936592	, lat:60.2439544 },
        { lng:24.7946459	, lat:60.2442109 },
        { lng:24.7947436	, lat:60.2442363 },
        { lng:24.7965204	, lat:60.2446984 },
        { lng:24.7966337	, lat:60.2447333 },
        { lng:24.7969576	, lat:60.2448121 },
        { lng:24.79764	, lat:60.2449895 },
        { lng:24.7981083	, lat:60.2451083 },
        { lng:24.7982895	, lat:60.2451582 },
        { lng:24.8004301	, lat:60.2457497 },
        { lng:24.8010874	, lat:60.2459293 },
        { lng:24.8012795	, lat:60.2459811 },
        { lng:24.8013891	, lat:60.246011 },
        { lng:24.8018961	, lat:60.246149 },
        { lng:24.8020339	, lat:60.2461866 },
        { lng:24.8026767	, lat:60.2463617 },
        { lng:24.8030796	, lat:60.2464752 },
        { lng:24.8039359	, lat:60.2467047 },
        { lng:24.8041153	, lat:60.2467536 },
        { lng:24.8044553	, lat:60.2468461 },
        { lng:24.8045231	, lat:60.2468647 },
        { lng:24.8046338	, lat:60.2468924 },
        { lng:24.8061284	, lat:60.2473079 },
        { lng:24.8062773	, lat:60.2473482 },
        { lng:24.8078776	, lat:60.247781 },
        { lng:24.8081431	, lat:60.2478527 },
        { lng:24.8083359	, lat:60.2479045 },
        { lng:24.8098269	, lat:60.2483042 },
        { lng:24.8110427	, lat:60.2486266 },
        { lng:24.8120074	, lat:60.2488825 },
        { lng:24.813201	, lat:60.249199 },
        { lng:24.8153201	, lat:60.2497609 },
        { lng:24.8168178	, lat:60.2501713 },
        { lng:24.8180775	, lat:60.2504938 },
        { lng:24.8206987	, lat:60.251187 },
        { lng:24.8224167	, lat:60.2516427 },
        { lng:24.8226527	, lat:60.2517053 },
        { lng:24.8229433	, lat:60.2517832 },
        { lng:24.8274664	, lat:60.2529976 },
        { lng:24.8282926	, lat:60.2532182 },
        { lng:24.8314026	, lat:60.254055 },
        { lng:24.8335667	, lat:60.2546418 },
        { lng:24.8336844	, lat:60.2546666 },
        { lng:24.8341945	, lat:60.2565675 },
        { lng:24.8346828	, lat:60.2582944 },
        { lng:24.8347319	, lat:60.2584995 },
        { lng:24.8354657	, lat:60.2587737 },
        { lng:24.8368517	, lat:60.2590293 },
        { lng:24.837406	, lat:60.2590842 },
        { lng:24.8377056	, lat:60.2591009 },
        { lng:24.8386407	, lat:60.2590969 },
        { lng:24.8387479	, lat:60.259067 },
        { lng:24.8390847	, lat:60.2590705 },
        { lng:24.8401411	, lat:60.2590816 },
        { lng:24.8409102	, lat:60.2584648 },
        { lng:24.8423513	, lat:60.2580356 },
        { lng:24.8424347	, lat:60.2580115 },
        { lng:24.8425132	, lat:60.2579924 },
        { lng:24.8449232	, lat:60.2572984 },
        { lng:24.8451675	, lat:60.2572287 },
        { lng:24.8456011	, lat:60.2571046 },
        { lng:24.8456573	, lat:60.2570881 },
        { lng:24.8457658	, lat:60.2570586 },
        { lng:24.8460145	, lat:60.2569864 },
        { lng:24.8466351	, lat:60.2568079 },
        { lng:24.8468314	, lat:60.2567509 },
        { lng:24.8468647	, lat:60.2567426 },
        { lng:24.8470024	, lat:60.256702 },
        { lng:24.8481256	, lat:60.2563859 },
        { lng:24.8487094	, lat:60.256214 },
        { lng:24.8497979	, lat:60.2559033 },
        { lng:24.8498471	, lat:60.2558887 },
        { lng:24.8498979	, lat:60.2558741 },
        { lng:24.8499678	, lat:60.2558548 },
        { lng:24.8500173	, lat:60.255841 },
        { lng:24.8520081	, lat:60.2552704 },
        { lng:24.8525281	, lat:60.255122 },
        { lng:24.8540772	, lat:60.2546787 },
        { lng:24.8541794	, lat:60.2546498 },
        { lng:24.854991	, lat:60.2544203 },
        { lng:24.8551253	, lat:60.2543815 },
        { lng:24.8564324	, lat:60.2540038 },
        { lng:24.856661	, lat:60.2539378 },
        { lng:24.8570813	, lat:60.2538179 },
        { lng:24.8578163	, lat:60.2536079 },
        { lng:24.8624912	, lat:60.2531432 },
        { lng:24.8626826	, lat:60.253226 },
        { lng:24.8629895	, lat:60.2533548 },
        { lng:24.8635991	, lat:60.2536149 },
        { lng:24.8640596	, lat:60.2538088 },
        { lng:24.8643083	, lat:60.2539151 },
        { lng:24.8645215	, lat:60.2537479 },
        { lng:24.8646325	, lat:60.2536937 },
        { lng:24.8648705	, lat:60.2536048 },
        { lng:24.8650692	, lat:60.2535488 },
        { lng:24.8652043	, lat:60.2534961 },
        { lng:24.8654198	, lat:60.2533752 },
        { lng:24.8655258	, lat:60.2533249 },
        { lng:24.8656288	, lat:60.2533016 },
        { lng:24.8657557	, lat:60.2532871 },
        { lng:24.8659188	, lat:60.2532781 },
        { lng:24.8660905	, lat:60.2532838 },
        { lng:24.8664724	, lat:60.2532869 },
        { lng:24.8666245	, lat:60.2532817 },
        { lng:24.8668533	, lat:60.2532309 },
        { lng:24.8670655	, lat:60.2531813 },
        { lng:24.8671406	, lat:60.2531453 },
        { lng:24.8671966	, lat:60.253092 },
        { lng:24.8674744	, lat:60.2528227 },
        { lng:24.86759	, lat:60.252669 },
        { lng:24.8676063	, lat:60.2526172 },
        { lng:24.8676068	, lat:60.252539 },
        { lng:24.8676297	, lat:60.2525045 },
        { lng:24.8676957	, lat:60.2524556 },
        { lng:24.8677319	, lat:60.2524405 },
        { lng:24.8678739	, lat:60.2523805 },
        { lng:24.8687634	, lat:60.2532664 },
        { lng:24.8689712	, lat:60.2533162 },
        { lng:24.8690876	, lat:60.2533446 },
        { lng:24.8713695	, lat:60.2538922 },
        { lng:24.8719335	, lat:60.2540313 },
        { lng:24.8724329	, lat:60.2541514 },
        { lng:24.8727701	, lat:60.2545758 },
        { lng:24.8740573	, lat:60.2562387 },
        { lng:24.8747097	, lat:60.2570861 },
        { lng:24.875069	, lat:60.2575465 },
        { lng:24.8755432	, lat:60.2581681 },
        { lng:24.8760797	, lat:60.2588492 },
        { lng:24.8768598	, lat:60.2598241 },
        { lng:24.876914	, lat:60.2598886 },
        { lng:24.8772516	, lat:60.2603098 },
        { lng:24.8776355	, lat:60.2604742 },
        { lng:24.8779537	, lat:60.2606092 },
        { lng:24.8785208	, lat:60.2608514 },
        { lng:24.8788967	, lat:60.261011 },
        { lng:24.8795718	, lat:60.2612997 },
        { lng:24.8801794	, lat:60.2615605 },
        { lng:24.8847165	, lat:60.2635126 },
        { lng:24.8848996	, lat:60.2635914 },
        { lng:24.8849821	, lat:60.2636285 },
        { lng:24.8852107	, lat:60.2637253 },
        { lng:24.890791	, lat:60.2661138 },
        { lng:24.8914713	, lat:60.2664204 },
        { lng:24.8919896	, lat:60.2667981 },
        { lng:24.8924568	, lat:60.2671387 },
        { lng:24.8925609	, lat:60.2675183 },
        { lng:24.8926795	, lat:60.26797 },
        { lng:24.8928066	, lat:60.2684385 },
        { lng:24.8928328	, lat:60.2685352 },
        { lng:24.8938924	, lat:60.2685103 },
        { lng:24.894365	, lat:60.2684972 },
        { lng:24.8951969	, lat:60.2685231 },
        { lng:24.8961578	, lat:60.2685649 },
        { lng:24.8975398	, lat:60.2686477 },
        { lng:24.8977868	, lat:60.2686776 },
        { lng:24.8981452	, lat:60.2687312 },
        { lng:24.8985458	, lat:60.2687963 },
        { lng:24.8989443	, lat:60.2688738 },
        { lng:24.8992717	, lat:60.2689375 },
        { lng:24.8994524	, lat:60.268975 },
        { lng:24.8996381	, lat:60.2690251 },
        { lng:24.8997875	, lat:60.2690731 },
        { lng:24.9000116	, lat:60.2691492 },
        { lng:24.900161	, lat:60.2691923 },
        { lng:24.9002962	, lat:60.2692253 },
        { lng:24.9013258	, lat:60.2694015 },
        { lng:24.9016408	, lat:60.2694416 },
        { lng:24.904029	, lat:60.2698801 },
        { lng:24.9054099	, lat:60.2701894 },
        { lng:24.9058095	, lat:60.2702965 },
        { lng:24.9060922	, lat:60.2703807 },
        { lng:24.9069511	, lat:60.270675 },
        { lng:24.907246	, lat:60.2707686 },
        { lng:24.907358	, lat:60.2707946 },
        { lng:24.9074902	, lat:60.2708181 },
        { lng:24.9077042	, lat:60.2708437 },
        { lng:24.9079131	, lat:60.2708532 },
        { lng:24.9083673	, lat:60.2708482 },
        { lng:24.9085814	, lat:60.2708342 },
        { lng:24.9087993	, lat:60.2708222 },
        { lng:24.9092315	, lat:60.2708071 },
        { lng:24.9094736	, lat:60.2708031 },
        { lng:24.9096877	, lat:60.2708101 },
        { lng:24.9098734	, lat:60.2708352 },
        { lng:24.9100248	, lat:60.2708672 },
        { lng:24.9101692	, lat:60.2709128 },
        { lng:24.9102903	, lat:60.2709578 },
        { lng:24.9104428	, lat:60.2710334 },
        { lng:24.9105719	, lat:60.2711185 },
        { lng:24.9106568	, lat:60.2712086 },
        { lng:24.9107203	, lat:60.2713027 },
        { lng:24.9108091	, lat:60.2713988 },
        { lng:24.9108909	, lat:60.2714544 },
        { lng:24.9111019	, lat:60.271559 },
        { lng:24.9112584	, lat:60.2716306 },
        { lng:24.9114239	, lat:60.2716996 },
        { lng:24.9115864	, lat:60.2717612 },
        { lng:24.9117671	, lat:60.2718218 },
        { lng:24.9119416	, lat:60.2718743 },
        { lng:24.9121163	, lat:60.2719264 },
        { lng:24.9123233	, lat:60.2719759 },
        { lng:24.9125191	, lat:60.2720185 },
        { lng:24.9127089	, lat:60.2720555 },
        { lng:24.9129279	, lat:60.2720911 },
        { lng:24.9135133	, lat:60.2721766 },
        { lng:24.9136103	, lat:60.2721922 },
        { lng:24.9139181	, lat:60.2722442 },
        { lng:24.9144158	, lat:60.2723383 },
        { lng:24.9150183	, lat:60.2724369 },
        { lng:24.9152344	, lat:60.2724665 },
        { lng:24.9162488	, lat:60.2725746 },
        { lng:24.916905	, lat:60.2726286 },
        { lng:24.91734	, lat:60.2726617 },
        { lng:24.9177457	, lat:60.2726971 },
        { lng:24.9179669	, lat:60.2727187 },
        { lng:24.9180961	, lat:60.2727388 },
        { lng:24.9183635	, lat:60.2727883 },
        { lng:24.9186522	, lat:60.2728459 },
        { lng:24.9187905	, lat:60.2728734 },
        { lng:24.9194557	, lat:60.272977 },
        { lng:24.9196465	, lat:60.273007 },
        { lng:24.9199825	, lat:60.2730525 },
        { lng:24.9202673	, lat:60.2730851 },
        { lng:24.9205297	, lat:60.2731107 },
        { lng:24.9205852	, lat:60.2731317 },
        { lng:24.9206125	, lat:60.2731432 },
        { lng:24.9206962	, lat:60.2731981 },
        { lng:24.9208093	, lat:60.2733084 },
        { lng:24.920985	, lat:60.273451 },
        { lng:24.921202	, lat:60.2735726 },
        { lng:24.9212979	, lat:60.2736232 },
        { lng:24.9214633	, lat:60.2736907 },
        { lng:24.921636	, lat:60.2737503 },
        { lng:24.9218066	, lat:60.2738039 },
        { lng:24.9219802	, lat:60.2738534 },
        { lng:24.9227735	, lat:60.274051 },
        { lng:24.9228756	, lat:60.2740802 },
        { lng:24.9231239	, lat:60.2741683 },
        { lng:24.9233036	, lat:60.2742353 },
        { lng:24.9235034	, lat:60.2743189 },
        { lng:24.9236225	, lat:60.2743785 },
        { lng:24.9238991	, lat:60.2745441 },
        { lng:24.9241362	, lat:60.2746662 },
        { lng:24.9242776	, lat:60.2747248 },
        { lng:24.9244259	, lat:60.2747778 },
        { lng:24.9245784	, lat:60.2748149 },
        { lng:24.9247087	, lat:60.2748324 },
        { lng:24.9248803	, lat:60.2748494 },
        { lng:24.9250104	, lat:60.274857 },
        { lng:24.9254465	, lat:60.274865 },
        { lng:24.9257534	, lat:60.274879 },
        { lng:24.9268456	, lat:60.274923 },
        { lng:24.9271272	, lat:60.274944 },
        { lng:24.9274058	, lat:60.2749731 },
        { lng:24.9281487	, lat:60.2750587 },
        { lng:24.9283505	, lat:60.2750731 },
        { lng:24.9285787	, lat:60.2750762 },
        { lng:24.9290732	, lat:60.2750582 },
        { lng:24.9296254	, lat:60.274998 },
        { lng:24.9298556	, lat:60.2749851 },
        { lng:24.930538	, lat:60.2749631 },
        { lng:24.9309458	, lat:60.2749706 },
        { lng:24.9312688	, lat:60.2749896 },
        { lng:24.9315009	, lat:60.2750081 },
        { lng:24.931721	, lat:60.2750296 },
        { lng:24.9319641	, lat:60.2750621 },
        { lng:24.9323922	, lat:60.2751452 },
        { lng:24.932815	, lat:60.2752453 },
        { lng:24.9337691	, lat:60.2754696 },
        { lng:24.9340184	, lat:60.2755126 },
        { lng:24.9342909	, lat:60.2755461 },
        { lng:24.9349208	, lat:60.2756177 },
        { lng:24.9351367	, lat:60.2756552 },
        { lng:24.9358847	, lat:60.2758488 },
        { lng:24.9368457	, lat:60.2761232 },
        { lng:24.9369708	, lat:60.2761461 },
        { lng:24.9386239	, lat:60.2764567 },
        { lng:24.939047	, lat:60.2765407 },
        { lng:24.9392848	, lat:60.2765828 },
        { lng:24.9397932	, lat:60.2766767 },
        { lng:24.9399492	, lat:60.2767056 },
        { lng:24.9401025	, lat:60.276739 },
        { lng:24.940241	, lat:60.276773 },
        { lng:24.9403643	, lat:60.2768065 },
        { lng:24.9414371	, lat:60.2771938 },
        { lng:24.9419799	, lat:60.2773842 },
        { lng:24.9423079	, lat:60.2774777 },
        { lng:24.9426555	, lat:60.2775717 },
        { lng:24.9429684	, lat:60.2776468 },
        { lng:24.9432741	, lat:60.2777146 },
        { lng:24.9435049	, lat:60.2777689 },
        { lng:24.9437502	, lat:60.277839 },
        { lng:24.9443015	, lat:60.2780143 },
        { lng:24.9446129	, lat:60.2781214 },
        { lng:24.9447667	, lat:60.2781769 },
        { lng:24.9450882	, lat:60.2782778 },
        { lng:24.9453467	, lat:60.2783523 },
        { lng:24.9455349	, lat:60.2783908 },
        { lng:24.94564	, lat:60.2784028 },
        { lng:24.9457407	, lat:60.2784082 },
        { lng:24.9459603	, lat:60.2784049 },
        { lng:24.9461746	, lat:60.2783906 },
        { lng:24.9462931	, lat:60.2783893 },
        { lng:24.9463973	, lat:60.2783948 },
        { lng:24.9466155	, lat:60.2784151 },
        { lng:24.9472477	, lat:60.2784911 },
        { lng:24.9474615	, lat:60.2785081 },
        { lng:24.9476443	, lat:60.2785102 },
        { lng:24.9477729	, lat:60.2785 },
        { lng:24.9479262	, lat:60.278475 },
        { lng:24.9480932	, lat:60.2784354 },
        { lng:24.9482608	, lat:60.2783853 },
        { lng:24.9484724	, lat:60.2783216 },
        { lng:24.9486613	, lat:60.278268 },
        { lng:24.9489299	, lat:60.2781984 },
        { lng:24.9490508	, lat:60.2781762 },
        { lng:24.9491115	, lat:60.278165 },
        { lng:24.9492891	, lat:60.2781425 },
        { lng:24.9494139	, lat:60.2781332 },
        { lng:24.9495671	, lat:60.2781273 },
        { lng:24.9497286	, lat:60.2781296 },
        { lng:24.9499529	, lat:60.2781441 },
        { lng:24.9501723	, lat:60.2781615 },
        { lng:24.9505928	, lat:60.2782066 },
        { lng:24.9510822	, lat:60.2782658 },
        { lng:24.9512328	, lat:60.2782795 },
        { lng:24.9515274	, lat:60.2782971 },
        { lng:24.9516549	, lat:60.2782961 },
        { lng:24.9518686	, lat:60.2782885 },
        { lng:24.9520636	, lat:60.2782693 },
        { lng:24.9522603	, lat:60.2782363 },
        { lng:24.9525046	, lat:60.278191 },
        { lng:24.9526347	, lat:60.278158 },
        { lng:24.9527407	, lat:60.2781171 },
        { lng:24.9528235	, lat:60.278069 },
        { lng:24.9528871	, lat:60.2780115 },
        { lng:24.9529416	, lat:60.2779314 },
        { lng:24.9529729	, lat:60.2778538 },
        { lng:24.952984	, lat:60.2777702 },
        { lng:24.952987	, lat:60.2775499 },
        { lng:24.9530113	, lat:60.2774384 },
        { lng:24.9530537	, lat:60.2773362 },
        { lng:24.9531212	, lat:60.2772413 },
        { lng:24.9531779	, lat:60.2771781 },
        { lng:24.9532876	, lat:60.2770955 },
        { lng:24.9535465	, lat:60.27695 },
        { lng:24.9536162	, lat:60.2769169 },
        { lng:24.9536705	, lat:60.2768914 },
        { lng:24.9538006	, lat:60.2768519 },
        { lng:24.9539864	, lat:60.2768048 },
        { lng:24.9541035	, lat:60.2767827 },
        { lng:24.9544305	, lat:60.2767458 },
        { lng:24.954697	, lat:60.2767217 },
        { lng:24.9550957	, lat:60.2766957 },
        { lng:24.9553106	, lat:60.276674 },
        { lng:24.9554561	, lat:60.2766429 },
        { lng:24.9557106	, lat:60.2765766 },
        { lng:24.9560211	, lat:60.2764546 },
        { lng:24.9562293	, lat:60.2763619 },
        { lng:24.9563403	, lat:60.2762969 },
        { lng:24.9564553	, lat:60.2762248 },
        { lng:24.9565261	, lat:60.2761527 },
        { lng:24.9566128	, lat:60.2760536 },
        { lng:24.9566775	, lat:60.2759495 },
        { lng:24.956736	, lat:60.2758383 },
        { lng:24.9567602	, lat:60.2757393 },
        { lng:24.9567702	, lat:60.2756311 },
        { lng:24.9567643	, lat:60.2755576 },
        { lng:24.956737	, lat:60.2754781 },
        { lng:24.9566824	, lat:60.2753734 },
        { lng:24.9565786	, lat:60.2752118 },
        { lng:24.9565028	, lat:60.2750667 },
        { lng:24.9564564	, lat:60.2749601 },
        { lng:24.9562525	, lat:60.274396 },
        { lng:24.9562192	, lat:60.2742378 },
        { lng:24.9561829	, lat:60.2741287 },
        { lng:24.9560102	, lat:60.2737848 },
        { lng:24.9558932	, lat:60.2735791 },
        { lng:24.9558558	, lat:60.2735036 },
        { lng:24.9558245	, lat:60.2733918 },
        { lng:24.9558043	, lat:60.2732413 },
        { lng:24.9557992	, lat:60.2731441 },
        { lng:24.9558225	, lat:60.2729814 },
        { lng:24.9558578	, lat:60.2728729 },
        { lng:24.9558952	, lat:60.2727928 },
        { lng:24.9559516	, lat:60.2726901 },
        { lng:24.9560527	, lat:60.2725405 },
        { lng:24.9561354	, lat:60.2724289 },
        { lng:24.9565976	, lat:60.2718927 },
        { lng:24.9567804	, lat:60.2717172 },
        { lng:24.9572034	, lat:60.2713347 },
        { lng:24.9572851	, lat:60.2712652 },
        { lng:24.9574576	, lat:60.2711294 },
        { lng:24.9577262	, lat:60.2709453 },
        { lng:24.9578454	, lat:60.2708782 },
        { lng:24.9580281	, lat:60.2707746 },
        { lng:24.9582784	, lat:60.2706425 },
        { lng:24.9584923	, lat:60.2705307 },
        { lng:24.9594229	, lat:60.269936 },
        { lng:24.9596764	, lat:60.2697433 },
        { lng:24.9598127	, lat:60.2696202 },
        { lng:24.9599015	, lat:60.2695227 },
        { lng:24.959959	, lat:60.2694391 },
        { lng:24.9599944	, lat:60.269354 },
        { lng:24.9600156	, lat:60.2692878 },
        { lng:24.9600257	, lat:60.2691773 },
        { lng:24.9600196	, lat:60.2690551 },
        { lng:24.9599923	, lat:60.2688773 },
        { lng:24.9599924	, lat:60.2687908 },
        { lng:24.9600045	, lat:60.2687232 },
        { lng:24.9600478	, lat:60.2686295 },
        { lng:24.9601155	, lat:60.268515 },
        { lng:24.9601689	, lat:60.2684448 },
        { lng:24.9602225	, lat:60.2683857 },
        { lng:24.9602891	, lat:60.2683266 },
        { lng:24.9603739	, lat:60.2682801 },
        { lng:24.9604738	, lat:60.268225 },
        { lng:24.9606555	, lat:60.2681394 },
        { lng:24.9607816	, lat:60.2680974 },
        { lng:24.961031	, lat:60.2680333 },
        { lng:24.9612247	, lat:60.2679887 },
        { lng:24.9614388	, lat:60.2679372 },
        { lng:24.961566	, lat:60.2678936 },
        { lng:24.9620646	, lat:60.2676673 },
        { lng:24.9625949	, lat:60.2674122 },
        { lng:24.962743	, lat:60.2673379 },
        { lng:24.9631023	, lat:60.2671893 },
        { lng:24.9633274	, lat:60.2671107 },
        { lng:24.9634536	, lat:60.2670642 },
        { lng:24.9636747	, lat:60.266994 },
        { lng:24.9644519	, lat:60.2667693 },
        { lng:24.9647334	, lat:60.2666836 },
        { lng:24.9651494	, lat:60.266555 },
        { lng:24.9654219	, lat:60.2664533 },
        { lng:24.9658711	, lat:60.2663078 },
        { lng:24.9661608	, lat:60.2662331 },
        { lng:24.9663203	, lat:60.2661945 },
        { lng:24.9664424	, lat:60.2661695 },
        { lng:24.9665827	, lat:60.2661475 },
        { lng:24.9668775	, lat:60.2661055 },
        { lng:24.9671107	, lat:60.266073 },
        { lng:24.9673166	, lat:60.2660333 },
        { lng:24.9674206	, lat:60.2660048 },
        { lng:24.9681756	, lat:60.2658086 },
        { lng:24.9684017	, lat:60.26575 },
        { lng:24.9686076	, lat:60.2656964 },
        { lng:24.9688499	, lat:60.2656188 },
        { lng:24.9689928	, lat:60.2655629 },
        { lng:24.9690316	, lat:60.2655432 },
        { lng:24.9692425	, lat:60.2654385 },
        { lng:24.9693583	, lat:60.2653718 },
        { lng:24.9699402	, lat:60.2650433 },
        { lng:24.9702016	, lat:60.2649117 },
        { lng:24.9704512	, lat:60.2648141 },
        { lng:24.970691	, lat:60.2647284 },
        { lng:24.9708365	, lat:60.2646914 },
        { lng:24.9710016	, lat:60.2646563 },
        { lng:24.9714242	, lat:60.2645802 },
        { lng:24.971774	, lat:60.2645471 },
        { lng:24.9732006	, lat:60.2644383 },
        { lng:24.9732526	, lat:60.2644551 },
        { lng:24.9733918	, lat:60.2644995 },
        { lng:24.973661	, lat:60.2645674 },
        { lng:24.9743214	, lat:60.2646914 },
        { lng:24.9749347	, lat:60.2647732 },
        { lng:24.9752963	, lat:60.2648385 },
        { lng:24.9754339	, lat:60.2648737 },
        { lng:24.9755106	, lat:60.2649029 },
        { lng:24.9756325	, lat:60.264978 },
        { lng:24.9757268	, lat:60.2650666 },
        { lng:24.9758035	, lat:60.2651564 },
        { lng:24.9758119	, lat:60.265272 },
        { lng:24.9756962	, lat:60.2654715 },
        { lng:24.9755897	, lat:60.2657107 },
        { lng:24.9755251	, lat:60.2659177 },
        { lng:24.9755429	, lat:60.2661448 },
        { lng:24.9756144	, lat:60.2663142 },
        { lng:24.9757516	, lat:60.2665155 },
        { lng:24.9760455	, lat:60.2667788 },
        { lng:24.9763636	, lat:60.2669726 },
        { lng:24.9767719	, lat:60.2671524 },
        { lng:24.9772716	, lat:60.267321 },
        { lng:24.9777079	, lat:60.2675132 },
        { lng:24.9780276	, lat:60.2676752 },
        { lng:24.9780855	, lat:60.2677773 },
        { lng:24.9780801	, lat:60.2678834 },
        { lng:24.9780498	, lat:60.2679706 },
        { lng:24.9779621	, lat:60.2680672 },
        { lng:24.9778304	, lat:60.2681964 },
        { lng:24.977578	, lat:60.268357 },
        { lng:24.9772625	, lat:60.2685843 },
        { lng:24.976903	, lat:60.2687175 },
        { lng:24.976508	, lat:60.2688374 },
        { lng:24.9761541	, lat:60.2689815 },
        { lng:24.9758632	, lat:60.2692102 },
        { lng:24.9756629	, lat:60.2694211 },
        { lng:24.9755724	, lat:60.2695802 },
        { lng:24.9755367	, lat:60.2697244 },
        { lng:24.9755562	, lat:60.2699226 },
        { lng:24.9755945	, lat:60.2699905 },
        { lng:24.9756597	, lat:60.270048 },
        { lng:24.975803	, lat:60.2701497 },
        { lng:24.9758964	, lat:60.2702573 },
        { lng:24.9760225	, lat:60.2705021 },
        { lng:24.9761634	, lat:60.2707313 },
        { lng:24.9763639	, lat:60.2709646 },
        { lng:24.9766115	, lat:60.2711531 },
        { lng:24.9769115	, lat:60.2713847 },
        { lng:24.9771629	, lat:60.271734 },
        { lng:24.9772018	, lat:60.2722255 },
        { lng:24.9771674	, lat:60.2731201 },
        { lng:24.9773133	, lat:60.2734122 },
        { lng:24.9774633	, lat:60.2738576 },
        { lng:24.9776625	, lat:60.2740843 },
        { lng:24.9778459	, lat:60.2742157 },
        { lng:24.978588	, lat:60.2745081 },
        { lng:24.9792983	, lat:60.2749592 },
        { lng:24.9794815	, lat:60.2751492 },
        { lng:24.97975	, lat:60.2752628 },
        { lng:24.9801069	, lat:60.2753569 },
        { lng:24.9803231	, lat:60.2754641 },
        { lng:24.9804605	, lat:60.2756605 },
        { lng:24.980729	, lat:60.2758926 },
        { lng:24.9807682	, lat:60.2759527 },
        { lng:24.9807696	, lat:60.2760437 },
        { lng:24.9807453	, lat:60.2762027 },
        { lng:24.9808356	, lat:60.2764498 },
        { lng:24.9808397	, lat:60.276941 },
        { lng:24.9807714	, lat:60.2772462 },
        { lng:24.9808049	, lat:60.2773645 },
        { lng:24.9809114	, lat:60.2775023 },
        { lng:24.9812248	, lat:60.2776704 },
        { lng:24.98156	, lat:60.2778581 },
        { lng:24.9819342	, lat:60.2780047 },
        { lng:24.9819904	, lat:60.2780559 },
        { lng:24.9820209	, lat:60.2781122 },
        { lng:24.9820074	, lat:60.2784811 },
        { lng:24.9820799	, lat:60.2786823 },
        { lng:24.9822576	, lat:60.2788883 },
        { lng:24.9825067	, lat:60.279005 },
        { lng:24.9823979	, lat:60.2790305 },
        { lng:24.9824941	, lat:60.2791278 },
        { lng:24.9825651	, lat:60.2791865 },
        { lng:24.9827405	, lat:60.2792529 },
        { lng:24.9832	, lat:60.2793927 },
        { lng:24.9833853	, lat:60.2794377 },
        { lng:24.9834681	, lat:60.2794719 },
        { lng:24.9834637	, lat:60.2795508 },
        { lng:24.9835094	, lat:60.2796449 },
        { lng:24.9835884	, lat:60.279677 },
        { lng:24.9837224	, lat:60.2796938 },
        { lng:24.9842331	, lat:60.2797181 },
        { lng:24.9845189	, lat:60.2796829 },
        { lng:24.9848324	, lat:60.2796643 },
        { lng:24.9852049	, lat:60.279684 },
        { lng:24.9856484	, lat:60.2796889 },
        { lng:24.9859758	, lat:60.2797441 },
        { lng:24.9868471	, lat:60.2797895 },
        { lng:24.9876793	, lat:60.2797102 },
        { lng:24.9893747	, lat:60.27973 },
        { lng:24.9897567	, lat:60.2797842 },
        { lng:24.9904839	, lat:60.2799613 },
        { lng:24.9911207	, lat:60.280199 },
        { lng:24.9921206	, lat:60.2806386 },
        { lng:24.9930856	, lat:60.2811291 },
        { lng:24.9933558	, lat:60.2812274 },
        { lng:24.9936675	, lat:60.2812981 },
        { lng:24.9941196	, lat:60.281341 },
        { lng:24.9949914	, lat:60.2813368 },
        { lng:24.9953657	, lat:60.2813505 },
        { lng:24.9956342	, lat:60.2814349 },
        { lng:24.9960256	, lat:60.2816743 },
        { lng:24.9971807	, lat:60.2825541 },
        { lng:24.9983529	, lat:60.2836171 },
        { lng:24.9992254	, lat:60.2843465 },
        { lng:24.9995961	, lat:60.2846813 },
        { lng:24.9996702	, lat:60.2848181 },
        { lng:24.9997485	, lat:60.2849774 },
        { lng:24.9999133	, lat:60.2857492 },
        { lng:25.0000121	, lat:60.2861289 },
        { lng:25.000148	, lat:60.2863208 },
        { lng:25.000424	, lat:60.2864658 },
        { lng:25.0007041	, lat:60.2865556 },
        { lng:25.0009801	, lat:60.2866067 },
        { lng:25.001256	, lat:60.286623 },
        { lng:25.0015526	, lat:60.286623 },
        { lng:25.0020538	, lat:60.286584 },
        { lng:25.0032357	, lat:60.2864971 },
        { lng:25.0040634	, lat:60.2864303 },
        { lng:25.0045768	, lat:60.2863576 },
        { lng:25.0052505	, lat:60.2861716 },
        { lng:25.0054657	, lat:60.2861205 },
        { lng:25.0056306	, lat:60.2861004 },
        { lng:25.0059725	, lat:60.2861347 },
        { lng:25.0063084	, lat:60.2862102 },
        { lng:25.0069531	, lat:60.2864638 },
        { lng:25.0070511	, lat:60.286503 },
        { lng:25.0075968	, lat:60.2867503 },
        { lng:25.007904	, lat:60.2870279 },
        { lng:25.008114	, lat:60.2872086 },
        { lng:25.0083273	, lat:60.2874884 },
        { lng:25.0085144	, lat:60.2876514 },
        { lng:25.0087393	, lat:60.2877522 },
        { lng:25.0092436	, lat:60.2878237 },
        { lng:25.0100274	, lat:60.2878143 },
        { lng:25.010495	, lat:60.2878017 },
        { lng:25.0107642	, lat:60.2877657 },
        { lng:25.0110052	, lat:60.2876902 },
        { lng:25.0111838	, lat:60.2875954 },
        { lng:25.0114677	, lat:60.2874355 },
        { lng:25.0116449	, lat:60.2873543 },
        { lng:25.0118051	, lat:60.2873353 },
        { lng:25.0120814	, lat:60.2873455 },
        { lng:25.0123676	, lat:60.2873891 },
        { lng:25.0126465	, lat:60.2875002 },
        { lng:25.0129423	, lat:60.2876544 },
        { lng:25.0133955	, lat:60.2878231 },
        { lng:25.0136231	, lat:60.2879139 },
        { lng:25.0138201	, lat:60.288027 },
        { lng:25.0140321	, lat:60.2882113 },
        { lng:25.0143718	, lat:60.2884252 },
        { lng:25.0146113	, lat:60.2885474 },
        { lng:25.0154217	, lat:60.288806 },
        { lng:25.015933	, lat:60.2889319 },
        { lng:25.0164852	, lat:60.288978 },
        { lng:25.0180848	, lat:60.2888791 },
        { lng:25.0183514	, lat:60.2888727 },
        { lng:25.0185491	, lat:60.2888934 },
        { lng:25.0187403	, lat:60.2889389 },
        { lng:25.0196673	, lat:60.2892159 },
        { lng:25.019929	, lat:60.2892676 },
        { lng:25.0201348	, lat:60.289294 },
        { lng:25.0205797	, lat:60.2893154 },
        { lng:25.0209862	, lat:60.2893226 },
        { lng:25.0212914	, lat:60.2892995 },
        { lng:25.0215855	, lat:60.2892406 },
        { lng:25.0219196	, lat:60.2891467 },
        { lng:25.0224811	, lat:60.2889295 },
        { lng:25.0229371	, lat:60.2886973 },
        { lng:25.0233662	, lat:60.2883825 },
        { lng:25.0238983	, lat:60.288062 },
        { lng:25.0242992	, lat:60.2879308 },
        { lng:25.025306	, lat:60.2877104 },
        { lng:25.0264475	, lat:60.287519 },
        { lng:25.0267068	, lat:60.2874363 },
        { lng:25.0268853	, lat:60.2873275 },
        { lng:25.0270569	, lat:60.2870978 },
        { lng:25.0270999	, lat:60.2868 },
        { lng:25.0271573	, lat:60.2866079 },
        { lng:25.0272723	, lat:60.28646 },
        { lng:25.0275719	, lat:60.2862937 },
        { lng:25.0287306	, lat:60.28583 },
        { lng:25.0291619	, lat:60.2856918 },
        { lng:25.0294415	, lat:60.285617 },
        { lng:25.029943	, lat:60.2855147 },
        { lng:25.0308248	, lat:60.285433 },
        { lng:25.031368	, lat:60.2853876 },
        { lng:25.0317195	, lat:60.2852974 },
        { lng:25.0322926	, lat:60.2850812 },
        { lng:25.0326664	, lat:60.2849785 },
        { lng:25.0331277	, lat:60.2849411 },
        { lng:25.0335168	, lat:60.2849551 },
        { lng:25.0338588	, lat:60.2850018 },
        { lng:25.0341978	, lat:60.2851309 },
        { lng:25.0343798	, lat:60.285232 },
        { lng:25.0345241	, lat:60.2853673 },
        { lng:25.0345837	, lat:60.2855664 },
        { lng:25.0344519	, lat:60.2859677 },
        { lng:25.0344739	, lat:60.2863659 },
        { lng:25.0346057	, lat:60.2865572 },
        { lng:25.0349258	, lat:60.2868621 },
        { lng:25.0351737	, lat:60.287139 },
        { lng:25.0354624	, lat:60.2873116 },
        { lng:25.0357699	, lat:60.2873676 },
        { lng:25.0361277	, lat:60.2873832 },
        { lng:25.036606	, lat:60.2873498 },
        { lng:25.0368821	, lat:60.2873436 },
        { lng:25.0371049	, lat:60.2873808 },
        { lng:25.0372901	, lat:60.2874338 },
        { lng:25.0375066	, lat:60.2875146 },
        { lng:25.0376792	, lat:60.2876033 },
        { lng:25.0378204	, lat:60.2877184 },
        { lng:25.0379378	, lat:60.2878716 },
        { lng:25.0380316	, lat:60.2877762 },
        { lng:25.0383633	, lat:60.2874389 },
        { lng:25.0384689	, lat:60.2873315 },
        { lng:25.0393059	, lat:60.2864803 },
        { lng:25.0393305	, lat:60.286455 },
        { lng:25.0405491	, lat:60.2850881 },
        { lng:25.0408664	, lat:60.2847361 },
        { lng:25.0406458	, lat:60.2841367 },
        { lng:25.040735	, lat:60.2840536 },
        { lng:25.0411984	, lat:60.2836211 },
        { lng:25.0412372	, lat:60.2835855 },
        { lng:25.0415565	, lat:60.2832852 },
        { lng:25.0422522	, lat:60.2831572 },
        { lng:25.0422231	, lat:60.2830669 },
        { lng:25.0423616	, lat:60.2829463 },
        { lng:25.0425181	, lat:60.2828099 },
        { lng:25.0425594	, lat:60.2827754 },
        { lng:25.0430634	, lat:60.2823532 },
        { lng:25.0431516	, lat:60.2822227 },
        { lng:25.04318	, lat:60.2821804 },
        { lng:25.0433794	, lat:60.2819394 },
        { lng:25.0434079	, lat:60.2819048 },
        { lng:25.0424695	, lat:60.2815822 },
        { lng:25.042263	, lat:60.281511 },
        { lng:25.0424889	, lat:60.2811015 },
        { lng:25.0426599	, lat:60.2811513 },
        { lng:25.0428392	, lat:60.2809366 },
        { lng:25.0430694	, lat:60.280662 },
        { lng:25.0435	, lat:60.2801762 },
        { lng:25.0437325	, lat:60.2798772 },
        { lng:25.0441799	, lat:60.2800146 },
        { lng:25.0443114	, lat:60.2799142 },
        { lng:25.0446331	, lat:60.2796534 },
        { lng:25.0448673	, lat:60.2794613 },
        { lng:25.0450481	, lat:60.279338 },
        { lng:25.0451371	, lat:60.279226 },
        { lng:25.0451502	, lat:60.2788767 },
        { lng:25.0451575	, lat:60.2785443 },
        { lng:25.0457345	, lat:60.2784179 },
        { lng:25.046043	, lat:60.2788728 },
        { lng:25.0466546	, lat:60.2781798 },
        { lng:25.0466925	, lat:60.2781363 },
        { lng:25.047036	, lat:60.2777421 },
        { lng:25.0470785	, lat:60.2777036 },
        { lng:25.0475222	, lat:60.2777901 },
        { lng:25.0475942	, lat:60.2778041 },
        { lng:25.0481159	, lat:60.2779057 },
        { lng:25.048632	, lat:60.2780122 },
        { lng:25.0491601	, lat:60.2781085 },
        { lng:25.0496921	, lat:60.2781983 },
        { lng:25.0506533	, lat:60.2783206 },
        { lng:25.0510953	, lat:60.2779477 },
        { lng:25.0516583	, lat:60.277474 },
        { lng:25.0517392	, lat:60.2774059 },
        { lng:25.0518203	, lat:60.2773359 },
        { lng:25.0533908	, lat:60.2760155 },
        { lng:25.0566778	, lat:60.2758125 },
        { lng:25.0594281	, lat:60.2761413 },
        { lng:25.0615549	, lat:60.2763742 },
        { lng:25.0618047	, lat:60.2760909 },
        { lng:25.0623771	, lat:60.2754352 },
        { lng:25.0634713	, lat:60.2753612 },
        { lng:25.0635942	, lat:60.275353 },
        { lng:25.0640752	, lat:60.2753241 },
        { lng:25.0647295	, lat:60.27528 },
        { lng:25.0650897	, lat:60.2752607 },
        { lng:25.0653989	, lat:60.2752348 },
        { lng:25.0659209	, lat:60.2752066 },
        { lng:25.0664237	, lat:60.2751704 },
        { lng:25.0670496	, lat:60.2751292 },
        { lng:25.0677343	, lat:60.2750855 },
        { lng:25.0683143	, lat:60.2750471 },
        { lng:25.0684843	, lat:60.2750316 },
        { lng:25.0689491	, lat:60.2750639 },
        { lng:25.0694651	, lat:60.2750922 },
        { lng:25.0700231	, lat:60.2751279 },
        { lng:25.0705592	, lat:60.2751579 },
        { lng:25.0710982	, lat:60.2751943 },
        { lng:25.0717309	, lat:60.2752309 },
        { lng:25.0724551	, lat:60.2752736 },
        { lng:25.0731382	, lat:60.2753176 },
        { lng:25.0735094	, lat:60.275344 },
        { lng:25.073589	, lat:60.2753469 },
        { lng:25.0762216	, lat:60.2755146 },
        { lng:25.0763891	, lat:60.2755253 },
        { lng:25.0765549	, lat:60.2755358 },
        { lng:25.0795054	, lat:60.2744748 },
        { lng:25.084769	, lat:60.272456 },
        { lng:25.0856519	, lat:60.2721394 },
        { lng:25.0860494	, lat:60.2719886 },
        { lng:25.0879554	, lat:60.2712506 },
        { lng:25.0887222	, lat:60.2688561 },
        { lng:25.0890904	, lat:60.2677976 },
        { lng:25.0890655	, lat:60.2677044 },
        { lng:25.0886948	, lat:60.266797 },
        { lng:25.0884031	, lat:60.2660296 },
        { lng:25.0880398	, lat:60.2650738 },
        { lng:25.0878302	, lat:60.2645225 },
        { lng:25.08741	, lat:60.263417 },
        { lng:25.0863495	, lat:60.2606268 },
        { lng:25.0875307	, lat:60.2592976 },
        { lng:25.0866058	, lat:60.2575783 },
        { lng:25.0847949	, lat:60.2541885 },
        { lng:25.0847689	, lat:60.254139 },
        { lng:25.0824407	, lat:60.2496979 },
        { lng:25.0859911	, lat:60.247897 },
        { lng:25.0913755	, lat:60.2451832 },
        { lng:25.0919556	, lat:60.2450797 },
        { lng:25.0925588	, lat:60.2449721 },
        { lng:25.0927691	, lat:60.2449347 },
        { lng:25.0954338	, lat:60.2444592 },
        { lng:25.0955542	, lat:60.2444377 },
        { lng:25.0956933	, lat:60.2444145 },
        { lng:25.0982033	, lat:60.2439663 },
        { lng:25.0983433	, lat:60.2439413 },
        { lng:25.1013731	, lat:60.2433976 },
        { lng:25.1015289	, lat:60.2433689 },
        { lng:25.1059923	, lat:60.2425568 },
        { lng:25.1068138	, lat:60.2424044 },
        { lng:25.1086945	, lat:60.2420659 },
        { lng:25.1088051	, lat:60.2420456 },
        { lng:25.1089812	, lat:60.2420146 },
        { lng:25.109115	, lat:60.2419901 },
        { lng:25.1126595	, lat:60.2413377 },
        { lng:25.1134592	, lat:60.2411905 },
        { lng:25.1295994	, lat:60.2382195 },
        { lng:25.1296068	, lat:60.2379612 },
        { lng:25.1350875	, lat:60.2372173 },
        { lng:25.1354537	, lat:60.2372467 },
        { lng:25.135846	, lat:60.2372286 },
        { lng:25.1359002	, lat:60.237361 },
        { lng:25.1363065	, lat:60.2376622 },
        { lng:25.1366332	, lat:60.2380906 },
        { lng:25.1367221	, lat:60.2382032 },
        { lng:25.136825	, lat:60.2384066 },
        { lng:25.1368949	, lat:60.2385298 },
        { lng:25.1370971	, lat:60.2387954 },
        { lng:25.137176	, lat:60.2389411 },
        { lng:25.1373315	, lat:60.239296 },
        { lng:25.1380492	, lat:60.2401554 },
        { lng:25.1387102	, lat:60.2407112 },
        { lng:25.1390147	, lat:60.2409352 },
        { lng:25.1397071	, lat:60.2415342 },
        { lng:25.140739	, lat:60.242702 },
        { lng:25.1418668	, lat:60.2435835 },
        { lng:25.141708	, lat:60.2436316 },
        { lng:25.1416813	, lat:60.2438832 },
        { lng:25.142244	, lat:60.2441494 },
        { lng:25.1431759	, lat:60.2447263 },
        { lng:25.1446726	, lat:60.2450895 },
        { lng:25.1451879	, lat:60.2452911 },
        { lng:25.1470578	, lat:60.2454752 },
        { lng:25.147606	, lat:60.2454692 },
        { lng:25.1483483	, lat:60.2455336 },
        { lng:25.1501088	, lat:60.2454282 },
        { lng:25.1507582	, lat:60.2455295 },
        { lng:25.1519642	, lat:60.2461403 },
        { lng:25.1522817	, lat:60.2458957 },
        { lng:25.1537886	, lat:60.2463092 },
        { lng:25.1541064	, lat:60.246435 },
        { lng:25.1552835	, lat:60.2467511 },
        { lng:25.1574404	, lat:60.2480769 },
        { lng:25.1583228	, lat:60.2484553 },
        { lng:25.1588278	, lat:60.2485946 },
        { lng:25.1598757	, lat:60.2488132 },
        { lng:25.1595849	, lat:60.2492184 },
        { lng:25.1598502	, lat:60.2497345 },
        { lng:25.1591182	, lat:60.2507513 },
        { lng:25.1589863	, lat:60.2507247 },
        { lng:25.1586152	, lat:60.250964 },
        { lng:25.1573866	, lat:60.2510766 },
        { lng:25.1573689	, lat:60.2512447 },
        { lng:25.157307	, lat:60.251706 },
        { lng:25.1571026	, lat:60.2536152 },
        { lng:25.157688	, lat:60.2538842 },
        { lng:25.157486	, lat:60.2539967 },
        { lng:25.157319	, lat:60.2540468 },
        { lng:25.1571706	, lat:60.2541256 },
        { lng:25.1570159	, lat:60.2542375 },
        { lng:25.156957	, lat:60.2543044 },
        { lng:25.1569259	, lat:60.2544081 },
        { lng:25.1569233	, lat:60.2544453 },
        { lng:25.1568791	, lat:60.2544881 },
        { lng:25.1567929	, lat:60.2545174 },
        { lng:25.1565782	, lat:60.254509 },
        { lng:25.1560668	, lat:60.2546614 },
        { lng:25.1559679	, lat:60.2547157 },
        { lng:25.1558101	, lat:60.2548849 },
        { lng:25.1557406	, lat:60.2549319 },
        { lng:25.1556544	, lat:60.2549548 },
        { lng:25.1555576	, lat:60.2549569 },
        { lng:25.1553808	, lat:60.2549277 },
        { lng:25.1552314	, lat:60.2548818 },
        { lng:25.1551304	, lat:60.2548703 },
        { lng:25.1550378	, lat:60.2548807 },
        { lng:25.1549494	, lat:60.2549079 },
        { lng:25.1548421	, lat:60.2550519 },
        { lng:25.1546779	, lat:60.2550655 },
        { lng:25.1545853	, lat:60.2551188 },
        { lng:25.1544275	, lat:60.2553171 },
        { lng:25.1544191	, lat:60.255408 },
        { lng:25.1544696	, lat:60.2555092 },
        { lng:25.1547032	, lat:60.2556732 },
        { lng:25.1547053	, lat:60.2557369 },
        { lng:25.1545306	, lat:60.2559467 },
        { lng:25.1544443	, lat:60.2561023 },
        { lng:25.1542928	, lat:60.2562432 },
        { lng:25.1542528	, lat:60.2563633 },
        { lng:25.1540255	, lat:60.2565397 },
        { lng:25.1539813	, lat:60.2566462 },
        { lng:25.1539077	, lat:60.2566807 },
        { lng:25.153834	, lat:60.2566755 },
        { lng:25.153571	, lat:60.2566243 },
        { lng:25.1534552	, lat:60.2566452 },
        { lng:25.1530954	, lat:60.2568394 },
        { lng:25.1530112	, lat:60.2568394 },
        { lng:25.1528071	, lat:60.256806 },
        { lng:25.1527944	, lat:60.2569908 },
        { lng:25.1525314	, lat:60.2570148 },
        { lng:25.1524662	, lat:60.2570148 },
        { lng:25.1523799	, lat:60.2570231 },
        { lng:25.1522894	, lat:60.2570618 },
        { lng:25.1522746	, lat:60.2570931 },
        { lng:25.1522641	, lat:60.2572006 },
        { lng:25.1522557	, lat:60.2572779 },
        { lng:25.1522199	, lat:60.2573499 },
        { lng:25.15214	, lat:60.2574429 },
        { lng:25.1520632	, lat:60.2574951 },
        { lng:25.1519622	, lat:60.2575504 },
        { lng:25.1518328	, lat:60.2575901 },
        { lng:25.1517244	, lat:60.2576146 },
        { lng:25.1516181	, lat:60.2576199 },
        { lng:25.1515013	, lat:60.2576219 },
        { lng:25.1513877	, lat:60.2576423 },
        { lng:25.1512919	, lat:60.2576757 },
        { lng:25.1510994	, lat:60.2577754 },
        { lng:25.1510215	, lat:60.2577817 },
        { lng:25.1509216	, lat:60.257753 },
        { lng:25.1508353	, lat:60.2577478 },
        { lng:25.1506932	, lat:60.2577556 },
        { lng:25.1505301	, lat:60.2577932 },
        { lng:25.1504944	, lat:60.2578125 },
        { lng:25.1505038	, lat:60.2578704 },
        { lng:25.1505901	, lat:60.2579122 },
        { lng:25.1507648	, lat:60.257956 },
        { lng:25.1507869	, lat:60.2579884 },
        { lng:25.1506396	, lat:60.258086 },
        { lng:25.1505396	, lat:60.2581779 },
        { lng:25.1504712	, lat:60.2581988 },
        { lng:25.1498625	, lat:60.258233 },
        { lng:25.1490961	, lat:60.2581968 },
        { lng:25.1490039	, lat:60.2583171 },
        { lng:25.1488878	, lat:60.2583747 },
        { lng:25.1487643	, lat:60.2583666 },
        { lng:25.148614	, lat:60.2583046 },
        { lng:25.1485292	, lat:60.2581968 },
        { lng:25.1484131	, lat:60.2581149 },
        { lng:25.1483045	, lat:60.2580152 },
        { lng:25.1482122	, lat:60.2579997 },
        { lng:25.1480173	, lat:60.2580374 },
        { lng:25.1477137	, lat:60.2580721 },
        { lng:25.1474027	, lat:60.2581968 },
        { lng:25.1468179	, lat:60.2582928 },
        { lng:25.1467182	, lat:60.2583474 },
        { lng:25.1466468	, lat:60.2584079 },
        { lng:25.1465203	, lat:60.2584877 },
        { lng:25.1447412	, lat:60.2591685 },
        { lng:25.1446423	, lat:60.2592227 },
        { lng:25.1445918	, lat:60.2592822 },
        { lng:25.1440157	, lat:60.2602906 },
        { lng:25.1443193	, lat:60.2607571 },
        { lng:25.1441313	, lat:60.2613029 },
        { lng:25.142674	, lat:60.2629311 },
        { lng:25.1426412	, lat:60.2630108 },
        { lng:25.1425162	, lat:60.2630891 },
        { lng:25.1423883	, lat:60.2632869 },
        { lng:25.1417446	, lat:60.2648484 },
        { lng:25.1419314	, lat:60.2655804 },
        { lng:25.1431149	, lat:60.2658136 },
        { lng:25.1427957	, lat:60.2660912 },
        { lng:25.1424085	, lat:60.2668559 },
        { lng:25.1423712	, lat:60.2669295 },
        { lng:25.1423387	, lat:60.267167 },
        { lng:25.1424294	, lat:60.2674255 },
        { lng:25.1422537	, lat:60.267775 },
        { lng:25.142089	, lat:60.268027 },
        { lng:25.141904	, lat:60.2682925 },
        { lng:25.1420013	, lat:60.2687461 },
        { lng:25.1420137	, lat:60.269097 },
        { lng:25.1425241	, lat:60.2697779 },
        { lng:25.1723848	, lat:60.2764326 },
        { lng:25.1737822	, lat:60.2782564 },
        { lng:25.1748285	, lat:60.2783329 },
        { lng:25.1768698	, lat:60.2779906 },
        { lng:25.177796	, lat:60.2778992 },
        { lng:25.1782335	, lat:60.277829 },
        { lng:25.1787618	, lat:60.2778176 },
        { lng:25.1876581	, lat:60.2798927 },
        { lng:25.1877452	, lat:60.2800381 },
        { lng:25.1878028	, lat:60.2802283 },
        { lng:25.1878344	, lat:60.2802972 },
        { lng:25.1894229	, lat:60.2837721 },
        { lng:25.1895732	, lat:60.284101 },
        { lng:25.190239	, lat:60.2856802 },
        { lng:25.1905346	, lat:60.286367 },
        { lng:25.19249	, lat:60.2909087 },
        { lng:25.1930673	, lat:60.2922498 },
        { lng:25.1902563	, lat:60.2946 },
        { lng:25.1896271	, lat:60.2951177 },
        { lng:25.189226	, lat:60.2954478 },
        { lng:25.1888061	, lat:60.2958563 },
        { lng:25.1884774	, lat:60.2960435 },
        { lng:25.1879788	, lat:60.2964565 },
        { lng:25.1873106	, lat:60.2970123 },
        { lng:25.186635	, lat:60.2975826 },
        { lng:25.177964	, lat:60.3046687 },
        { lng:25.1715531	, lat:60.3099067 },
        { lng:25.1709476	, lat:60.3103678 },
        { lng:25.1702263	, lat:60.3109673 },
        { lng:25.1696032	, lat:60.3114852 },
        { lng:25.1682674	, lat:60.3118737 },
        { lng:25.1660227	, lat:60.3124695 },
        { lng:25.1646229	, lat:60.3128409 },
        { lng:25.1630107	, lat:60.3132621 },
        { lng:25.1611415	, lat:60.3137547 },
        { lng:25.1574344	, lat:60.3147312 },
        { lng:25.1542008	, lat:60.3155625 },
        { lng:25.1480868	, lat:60.3172088 },
        { lng:25.1461235	, lat:60.3177246 },
        { lng:25.146081	, lat:60.3177358 },
        { lng:25.1437197	, lat:60.3183563 },
        { lng:25.1398701	, lat:60.3193677 },
        { lng:25.137088	, lat:60.3200892 },
        { lng:25.1366321	, lat:60.3202074 },
        { lng:25.1356593	, lat:60.320477 },
        { lng:25.1169598	, lat:60.3284779 },
        { lng:25.1148383	, lat:60.3293855 },
        { lng:25.1136119	, lat:60.3299302 },
        { lng:25.1080962	, lat:60.3323799 },
        { lng:25.1079842	, lat:60.3324296 },
        { lng:25.1054557	, lat:60.3335525 },
        { lng:25.1049401	, lat:60.3337697 },
        { lng:25.1022097	, lat:60.3352481 },
        { lng:25.1125527	, lat:60.3354907 },
        { lng:25.1132382	, lat:60.3355067 },
        { lng:25.1138005	, lat:60.3355201 },
        { lng:25.1160622	, lat:60.3355732 },
        { lng:25.1174409	, lat:60.3356195 },
        { lng:25.1173583	, lat:60.3362927 },
        { lng:25.1173131	, lat:60.3366594 },
        { lng:25.120121	, lat:60.3382785 },
        { lng:25.1201825	, lat:60.3398132 },
        { lng:25.1200017	, lat:60.341727 },
        { lng:25.1199967	, lat:60.3417759 },
        { lng:25.1203064	, lat:60.3417909 },
        { lng:25.1225324	, lat:60.3418718 },
        { lng:25.1236934	, lat:60.3419086 },
        { lng:25.1238092	, lat:60.3419123 },
        { lng:25.124284	, lat:60.3411661 },
        { lng:25.1244832	, lat:60.3408482 },
        { lng:25.1246275	, lat:60.3405068 },
        { lng:25.1246852	, lat:60.3402779 },
        { lng:25.1247014	, lat:60.339951 },
        { lng:25.1269437	, lat:60.3400239 },
        { lng:25.1271765	, lat:60.3376456 },
        { lng:25.1272031	, lat:60.3373801 },
        { lng:25.1249383	, lat:60.3364302 },
        { lng:25.1244132	, lat:60.3362205 },
        { lng:25.1242747	, lat:60.3361652 },
        { lng:25.1230574	, lat:60.3356413 },
        { lng:25.1238879	, lat:60.3347835 },
        { lng:25.1243262	, lat:60.334798 },
        { lng:25.1244261	, lat:60.3348002 },
        { lng:25.1250504	, lat:60.3348219 },
        { lng:25.1308076	, lat:60.3349896 },
        { lng:25.1320103	, lat:60.3350246 },
        { lng:25.1323723	, lat:60.3350351 },
        { lng:25.1324652	, lat:60.3350378 },
        { lng:25.1325125	, lat:60.3350392 },
        { lng:25.1325944	, lat:60.3350416 },
        { lng:25.1326725	, lat:60.3350439 },
        { lng:25.1342947	, lat:60.335091 },
        { lng:25.1363805	, lat:60.3351517 },
        { lng:25.1376118	, lat:60.3351875 },
        { lng:25.1386209	, lat:60.3352169 },
        { lng:25.138713	, lat:60.3352196 },
        { lng:25.1387444	, lat:60.3352205 },
        { lng:25.1397662	, lat:60.3352503 },
        { lng:25.1404913	, lat:60.3352714 },
        { lng:25.1411612	, lat:60.3352909 },
        { lng:25.1432369	, lat:60.3353513 },
        { lng:25.1432353	, lat:60.335634 },
        { lng:25.1432513	, lat:60.3357769 },
        { lng:25.1432848	, lat:60.3359625 },
        { lng:25.1433582	, lat:60.3361315 },
        { lng:25.1434922	, lat:60.3363353 },
        { lng:25.1435942	, lat:60.3363397 },
        { lng:25.1438369	, lat:60.3364798 },
        { lng:25.144105	, lat:60.3366022 },
        { lng:25.1448725	, lat:60.3368857 },
        { lng:25.1453081	, lat:60.33702 },
        { lng:25.1457897	, lat:60.3371918 },
        { lng:25.1469398	, lat:60.33755 },
        { lng:25.1470545	, lat:60.3375818 },
        { lng:25.1488565	, lat:60.3380738 },
        { lng:25.1491021	, lat:60.3381719 },
        { lng:25.1503041	, lat:60.3388576 },
        { lng:25.1510353	, lat:60.3394338 },
        { lng:25.1516311	, lat:60.3398147 },
        { lng:25.151789	, lat:60.3399676 },
        { lng:25.151892	, lat:60.3400949 },
        { lng:25.1519831	, lat:60.3402077 },
        { lng:25.152026	, lat:60.3402948 },
        { lng:25.1520553	, lat:60.3404244 },
        { lng:25.1520689	, lat:60.3405662 },
        { lng:25.1520611	, lat:60.3406233 },
        { lng:25.1520192	, lat:60.3409235 },
        { lng:25.1519876	, lat:60.3412854 },
        { lng:25.1519072	, lat:60.3416056 },
        { lng:25.1517552	, lat:60.3418649 },
        { lng:25.1517326	, lat:60.3419866 },
        { lng:25.1517192	, lat:60.3421551 },
        { lng:25.1517349	, lat:60.3422468 },
        { lng:25.1518093	, lat:60.3424121 },
        { lng:25.1518496	, lat:60.3424697 },
        { lng:25.1520914	, lat:60.3428152 },
        { lng:25.1523983	, lat:60.3432886 },
        { lng:25.1525247	, lat:60.3435142 },
        { lng:25.1526133	, lat:60.3437964 },
        { lng:25.1526737	, lat:60.3440725 },
        { lng:25.1526646	, lat:60.3446062 },
        { lng:25.1527021	, lat:60.3449975 },
        { lng:25.1527144	, lat:60.3451273 },
        { lng:25.152724	, lat:60.345229 },
        { lng:25.1527188	, lat:60.3453788 },
        { lng:25.1526309	, lat:60.3455558 },
        { lng:25.1525428	, lat:60.3457328 },
        { lng:25.1525083	, lat:60.3458401 },
        { lng:25.1524817	, lat:60.3459228 },
        { lng:25.1524141	, lat:60.3461325 },
        { lng:25.1510031	, lat:60.3461075 },
        { lng:25.149437	, lat:60.3460733 },
        { lng:25.1486889	, lat:60.3460569 },
        { lng:25.1477509	, lat:60.34605 },
        { lng:25.1474862	, lat:60.3460451 },
        { lng:25.1441118	, lat:60.3459833 },
        { lng:25.143981	, lat:60.3459811 },
        { lng:25.1428661	, lat:60.3459627 },
        { lng:25.1420987	, lat:60.34595 },
        { lng:25.1419445	, lat:60.3472026 },
        { lng:25.1403918	, lat:60.3471997 },
        { lng:25.1352466	, lat:60.34719 },
        { lng:25.134951	, lat:60.3471894 },
        { lng:25.1348929	, lat:60.3473364 },
        { lng:25.1344346	, lat:60.3484973 },
        { lng:25.1424526	, lat:60.3534814 },
        { lng:25.1433991	, lat:60.3540697 },
        { lng:25.1476586	, lat:60.3567255 },
        { lng:25.1478158	, lat:60.3568149 },
        { lng:25.1482366	, lat:60.3570798 },
        { lng:25.1517295	, lat:60.3592798 },
        { lng:25.1500242	, lat:60.3592595 },
        { lng:25.1493262	, lat:60.3592512 },
        { lng:25.1476686	, lat:60.3592315 },
        { lng:25.147388	, lat:60.3592282 },
        { lng:25.1456424	, lat:60.3592075 },
        { lng:25.1453842	, lat:60.3592043 },
        { lng:25.1445546	, lat:60.3591945 },
        { lng:25.1403986	, lat:60.3591572 },
        { lng:25.1398639	, lat:60.3591524 },
        { lng:25.1395982	, lat:60.35915 },
        { lng:25.1378003	, lat:60.3591339 },
        { lng:25.1339701	, lat:60.3590995 },
        { lng:25.1328005	, lat:60.359089 },
        { lng:25.1326187	, lat:60.3590863 },
        { lng:25.1316869	, lat:60.3590779 },
        { lng:25.1316844	, lat:60.3591011 },
        { lng:25.1316288	, lat:60.3596248 },
        { lng:25.1315738	, lat:60.3601427 },
        { lng:25.1304854	, lat:60.3601208 },
        { lng:25.130245	, lat:60.3601173 },
        { lng:25.1289037	, lat:60.3600875 },
        { lng:25.1276001	, lat:60.3600629 },
        { lng:25.1247924	, lat:60.3600054 },
        { lng:25.1241243	, lat:60.3599917 },
        { lng:25.1240748	, lat:60.3599907 },
        { lng:25.1240169	, lat:60.360376 },
        { lng:25.1171297	, lat:60.3602074 },
        { lng:25.1169891	, lat:60.3605528 },
        { lng:25.1168733	, lat:60.3605686 },
        { lng:25.1162625	, lat:60.3605582 },
        { lng:25.1158807	, lat:60.3605823 },
        { lng:25.1153668	, lat:60.3605738 },
        { lng:25.1158651	, lat:60.3614621 },
        { lng:25.1158877	, lat:60.3615027 },
        { lng:25.1160225	, lat:60.3615967 },
        { lng:25.1159835	, lat:60.3617108 },
        { lng:25.1160859	, lat:60.3620273 },
        { lng:25.1162263	, lat:60.3621935 },
        { lng:25.1155134	, lat:60.3637667 },
        { lng:25.1154857	, lat:60.3638277 },
        { lng:25.1156332	, lat:60.3643285 },
        { lng:25.1156465	, lat:60.3643741 },
        { lng:25.1157407	, lat:60.364599 },
        { lng:25.1151434	, lat:60.3645354 },
        { lng:25.1146223	, lat:60.3656895 },
        { lng:25.0903782	, lat:60.3662986 },
        { lng:25.0898545	, lat:60.3663863 },
        { lng:25.089107	, lat:60.3665114 },
        { lng:25.0887924	, lat:60.366562 },
        { lng:25.0854202	, lat:60.3671045 },
        { lng:25.0885516	, lat:60.369251 },
        { lng:25.0852289	, lat:60.3697597 },
        { lng:25.0851902	, lat:60.3702034 },
        { lng:25.0829164	, lat:60.3704356 },
        { lng:25.0845966	, lat:60.372208 },
        { lng:25.0790077	, lat:60.3744713 },
        { lng:25.0789166	, lat:60.3742079 },
        { lng:25.0788338	, lat:60.3740425 },
        { lng:25.0787353	, lat:60.3738903 },
        { lng:25.0786031	, lat:60.3737373 },
        { lng:25.0781483	, lat:60.3732676 },
        { lng:25.0773507	, lat:60.373604 },
        { lng:25.0763261	, lat:60.3730005 },
        { lng:25.0762422	, lat:60.3729006 },
        { lng:25.0727614	, lat:60.3711428 },
        { lng:25.0709622	, lat:60.3709152 },
        { lng:25.0715376	, lat:60.3706649 },
        { lng:25.0711701	, lat:60.3706802 },
        { lng:25.0706122	, lat:60.3707108 },
        { lng:25.0698905	, lat:60.3708488 },
        { lng:25.0695318	, lat:60.3708925 },
        { lng:25.0687304	, lat:60.370886 },
        { lng:25.0679024	, lat:60.3708597 },
        { lng:25.067464	, lat:60.3707853 },
        { lng:25.06707	, lat:60.370597 },
        { lng:25.0661224	, lat:60.3699622 },
        { lng:25.0656398	, lat:60.369752 },
        { lng:25.0652369	, lat:60.3696448 },
        { lng:25.0649313	, lat:60.3695944 },
        { lng:25.0645196	, lat:60.3695857 },
        { lng:25.0642672	, lat:60.369601 },
        { lng:25.0639174	, lat:60.3697411 },
        { lng:25.0543579	, lat:60.373724 },
        { lng:25.0536024	, lat:60.3733977 },
        { lng:25.0528642	, lat:60.3734449 },
        { lng:25.0521087	, lat:60.3728654 },
        { lng:25.0518394	, lat:60.3728483 },
        { lng:25.0507365	, lat:60.3721528 },
        { lng:25.050259	, lat:60.3709248 },
        { lng:25.0434653	, lat:60.3681299 },
        { lng:25.0429734	, lat:60.3679274 },
        { lng:25.0383433	, lat:60.3707994 },
        { lng:25.0346589	, lat:60.3695608 },
        { lng:25.031257	, lat:60.3716009 },
        { lng:25.028384	, lat:60.3705309 },
        { lng:25.0211986	, lat:60.3678545 },
        { lng:25.0288078	, lat:60.3627491 },
        { lng:25.0293094	, lat:60.3624126 },
        { lng:25.0296694	, lat:60.3621667 },
        { lng:25.0298344	, lat:60.362054 },
        { lng:25.0309646	, lat:60.3612823 },
        { lng:25.0311204	, lat:60.3611758 },
        { lng:25.0322586	, lat:60.3603986 },
        { lng:25.0323824	, lat:60.360314 },
        { lng:25.0333466	, lat:60.3596556 },
        { lng:25.0334081	, lat:60.3596136 },
        { lng:25.0335481	, lat:60.3595182 },
        { lng:25.0351139	, lat:60.3584511 },
        { lng:25.0406341	, lat:60.3546886 },
        { lng:25.0474484	, lat:60.3500435 },
        { lng:25.0475869	, lat:60.3499491 },
        { lng:25.0476952	, lat:60.3498753 },
        { lng:25.0481689	, lat:60.3495523 },
        { lng:25.0492184	, lat:60.3488368 },
        { lng:25.0480453	, lat:60.3484269 },
        { lng:25.0485781	, lat:60.3480584 },
        { lng:25.0489062	, lat:60.3480761 },
        { lng:25.0492504	, lat:60.34747 },
        { lng:25.0381369	, lat:60.3425515 },
        { lng:25.037968	, lat:60.3424838 },
        { lng:25.0376787	, lat:60.3423409 },
        { lng:25.037153	, lat:60.3421588 },
        { lng:25.0370047	, lat:60.3421203 },
        { lng:25.0364646	, lat:60.3419794 },
        { lng:25.0360363	, lat:60.3418455 },
        { lng:25.0355342	, lat:60.3417404 },
        { lng:25.0348851	, lat:60.3415724 },
        { lng:25.0336614	, lat:60.3413128 },
        { lng:25.0334251	, lat:60.3412517 },
        { lng:25.023809	, lat:60.3386441 },
        { lng:25.0037899	, lat:60.3332126 },
        { lng:25.0036487	, lat:60.3331743 },
        { lng:24.9919383	, lat:60.3329668 },
        { lng:24.9729499	, lat:60.3328827 },
        { lng:24.9649463	, lat:60.3327113 },
        { lng:24.9569427	, lat:60.3325272 },
        { lng:24.9558014	, lat:60.3324613 },
        { lng:24.9545937	, lat:60.3324476 },
        { lng:24.9504926	, lat:60.3345094 },
        { lng:24.9428054	, lat:60.3385213 },
        { lng:24.9378895	, lat:60.3416981 },
        { lng:24.9216674	, lat:60.3532907 },
        { lng:24.9178378	, lat:60.3561786 },
        { lng:24.9092801	, lat:60.3627346 },
        { lng:24.8799894	, lat:60.3806289 },
        { lng:24.8786738	, lat:60.3814197 },
        { lng:24.8775613	, lat:60.3820883 },
        { lng:24.8771351	, lat:60.3823444 },
        { lng:24.8767206	, lat:60.3831397 },
        { lng:24.8764942	, lat:60.3835739 },
        { lng:24.8765747	, lat:60.3836635 },
        { lng:24.8778675	, lat:60.3851008 },
        { lng:24.8780392	, lat:60.3876456 },
        { lng:24.8778675	, lat:60.3890876 },
        { lng:24.8775242	, lat:60.3898509 },
        { lng:24.8754643	, lat:60.3912928 },
        { lng:24.873576	, lat:60.3922257 },
        { lng:24.8711727	, lat:60.3934131 },
        { lng:24.8692845	, lat:60.3941763 },
        { lng:24.8685978	, lat:60.3948548 },
        { lng:24.8687695	, lat:60.3971444 },
        { lng:24.8679112	, lat:60.3976532 },
        { lng:24.8675679	, lat:60.3982468 },
        { lng:24.8682545	, lat:60.3988403 },
        { lng:24.8692845	, lat:60.3996035 },
        { lng:24.8696278	, lat:60.4002818 },
        { lng:24.8693732	, lat:60.4013744 },
        { lng:24.8498635	, lat:60.3978734 },
        { lng:24.8305913	, lat:60.3944915 },
        { lng:24.8304536	, lat:60.3944621 },
        { lng:24.8298131	, lat:60.393993 },
        { lng:24.8219836	, lat:60.3888614 },
        { lng:24.8145738	, lat:60.3840494 },
        { lng:24.8138959	, lat:60.3836029 },
        { lng:24.8136265	, lat:60.3834254 },
        { lng:24.8063712	, lat:60.3787047 },
        { lng:24.78333	, lat:60.3637116 },
        { lng:24.7830477	, lat:60.3635251 },
        { lng:24.7831955	, lat:60.3586986 },
        { lng:24.7821884	, lat:60.358835 },
        { lng:24.7819616	, lat:60.3588662 },
        { lng:24.7805415	, lat:60.3618641 },
        { lng:24.7647671	, lat:60.3513145 },
        { lng:24.750775	, lat:60.3426855 },
        { lng:24.7492994	, lat:60.341786 },
        { lng:24.7491415	, lat:60.3408554 },
        { lng:24.7482672	, lat:60.335389 },
        { lng:24.7479832	, lat:60.3336126 },
        { lng:24.7474355	, lat:60.3292812 },
        { lng:24.7470279	, lat:60.3268642 },
        { lng:24.7469459	, lat:60.3264666 },
        { lng:24.7468225	, lat:60.325519 },
        { lng:24.7468054	, lat:60.325388 },
        { lng:24.7464627	, lat:60.3234487 },
        { lng:24.7462404	, lat:60.3222773 },
      ],
    },
    {
      id: 33,
      regionName: 'eCom TP  Ylivieska',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng: 24.3045747	, lat:64.0239691 },
        { lng: 24.3550049	, lat:64.0296206 },
        { lng: 24.3847554	, lat:63.9931215 },
        { lng: 24.4708441	, lat:63.9733935 },
        { lng: 24.6101336	, lat:63.9421348 },
        { lng: 24.6386696	, lat:63.9463543 },
        { lng: 24.6375395	, lat:63.9662021 },
        { lng: 24.6553391	, lat:63.9607454 },
        { lng: 24.6867005	, lat:63.9595051 },
        { lng: 24.7242776	, lat:63.9561559 },
        { lng: 24.728233	, lat:63.9685581 },
        { lng: 24.7491406	, lat:63.9831856 },
        { lng: 24.7565208	, lat:63.9851903 },
        { lng: 24.7613254	, lat:63.9848804 },
        { lng: 24.7648582	, lat:63.9850663 },
        { lng: 24.7657414	, lat:63.9860735 },
        { lng: 24.7660173	, lat:63.9859064 },
        { lng: 24.7714924	, lat:63.990087 },
        { lng: 24.7711783	, lat:63.991116 },
        { lng: 24.7706125	, lat:63.9918876 },
        { lng: 24.7760386	, lat:63.9925521 },
        { lng: 24.7828824	, lat:63.9895082 },
        { lng: 24.7775051	, lat:63.9866783 },
        { lng: 24.7958856	, lat:63.9790662 },
        { lng: 24.8002529	, lat:63.9823057 },
        { lng: 24.8022232	, lat:63.9838978 },
        { lng: 24.803986	, lat:63.9832761 },
        { lng: 24.80606	, lat:63.9835642 },
        { lng: 24.8038132	, lat:63.9860203 },
        { lng: 24.8047811	, lat:63.9864296 },
        { lng: 24.8095858	, lat:63.9857474 },
        { lng: 24.8131152	, lat:63.9840937 },
        { lng: 24.8166719	, lat:63.982427 },
        { lng: 24.8254518	, lat:63.9838674 },
        { lng: 24.827906	, lat:63.9820025 },
        { lng: 24.8198866	, lat:63.9806833 },
        { lng: 24.8185704	, lat:63.9799364 },
        { lng: 24.8250631	, lat:63.9776269 },
        { lng: 24.8261697	, lat:63.9779261 },
        { lng: 24.8267722	, lat:63.9779901 },
        { lng: 24.8325674	, lat:63.9769727 },
        { lng: 24.8411204	, lat:63.9789009 },
        { lng: 24.8445676	, lat:63.9780393 },
        { lng: 24.84464	, lat:63.9778166 },
        { lng: 24.8567233	, lat:63.9740864 },
        { lng: 24.8598057	, lat:63.9780327 },
        { lng: 24.8573	, lat:63.9804023 },
        { lng: 24.8619783	, lat:63.9823114 },
        { lng: 24.8652533	, lat:63.9822648 },
        { lng: 24.8766918	, lat:63.9897304 },
        { lng: 24.9130835	, lat:63.983182 },
        { lng: 25.0215354	, lat:64.0680949 },
        { lng: 24.9694078	, lat:64.0872081 },
        { lng: 24.9669356	, lat:64.0885045 },
        { lng: 24.906332	, lat:64.1093927 },
        { lng: 24.7907236	, lat:64.1318355 },
        { lng: 24.7556187	, lat:64.1415106 },
        { lng: 24.7162052	, lat:64.1609121 },
        { lng: 24.6913119	, lat:64.1724816 },
        { lng: 24.6392084	, lat:64.1754895 },
        { lng: 24.6110987	, lat:64.1808171 },
        { lng: 24.5894743	, lat:64.1850212 },
        { lng: 24.5728237	, lat:64.1811734 },
        { lng: 24.5558561	, lat:64.1773356 },
        { lng: 24.5517614	, lat:64.1782579 },
        { lng: 24.5509997	, lat:64.177784 },
        { lng: 24.5504745	, lat:64.1760975 },
        { lng: 24.5149353	, lat:64.1681462 },
        { lng: 24.5062282	, lat:64.1726383 },
        { lng: 24.5010247	, lat:64.1711657 },
        { lng: 24.5063248	, lat:64.1664374 },
        { lng: 24.4888867	, lat:64.1623379 },
        { lng: 24.491011	, lat:64.1610613 },
        { lng: 24.4805257	, lat:64.1587173 },
        { lng: 24.4783011	, lat:64.159976 },
        { lng: 24.4625514	, lat:64.1564429 },
        { lng: 24.4651652	, lat:64.1540702 },
        { lng: 24.4487394	, lat:64.1485406 },
        { lng: 24.444008	, lat:64.1522602 },
        { lng: 24.4179047	, lat:64.1463805 },
        { lng: 24.4093431	, lat:64.1502637 },
        { lng: 24.3962233	, lat:64.1511408 },
        { lng: 24.3917693	, lat:64.1459406 },
        { lng: 24.3892694	, lat:64.1459895 },
        { lng: 24.3865443	, lat:64.1472784 },
        { lng: 24.3841309	, lat:64.1475512 },
        { lng: 24.3819524	, lat:64.147371 },
        { lng: 24.384935	, lat:64.1451658 },
        { lng: 24.3948592	, lat:64.1402426 },
        { lng: 24.3948055	, lat:64.1396778 },
        { lng: 24.3932069	, lat:64.1392346 },
        { lng: 24.3889368	, lat:64.1408306 },
        { lng: 24.3880571	, lat:64.1408823 },
        { lng: 24.3817399	, lat:64.1392455 },
        { lng: 24.3843117	, lat:64.1081056 },
        { lng: 24.374423	, lat:64.0906733 },
        { lng: 24.370185	, lat:64.086383 },
        { lng: 24.3647462	, lat:64.0968453 },
        { lng: 24.3465227	, lat:64.0938521 },
        { lng: 24.3663002	, lat:64.076627 },
        { lng: 24.3465511	, lat:64.0415678 },
        { lng: 24.3045747	, lat:64.0239691 },
      ],
    },
    {
      id: 34,
      regionName: 'eCom TP  Ylojarvi',
      fillColor: '#D5D5D5',
      fillColor1: 'red',
      coordinates: [
        { lng:23.2102483	, lat:61.7344109 },
        { lng:23.2261176	, lat:61.7005497 },
        { lng:23.2227659	, lat:61.6877784 },
        { lng:23.2139975	, lat:61.6656147 },
        { lng:23.2139899	, lat:61.665601 },
        { lng:23.2139566	, lat:61.665542 },
        { lng:23.2119326	, lat:61.6619642 },
        { lng:23.2292551	, lat:61.6632836 },
        { lng:23.2578095	, lat:61.6654585 },
        { lng:23.260779	, lat:61.6654964 },
        { lng:23.2741533	, lat:61.6656669 },
        { lng:23.3093425	, lat:61.6660976 },
        { lng:23.3108986	, lat:61.6551592 },
        { lng:23.323252	, lat:61.6438727 },
        { lng:23.3252733	, lat:61.642398 },
        { lng:23.3354453	, lat:61.6370606 },
        { lng:23.3662795	, lat:61.6578312 },
        { lng:23.3658578	, lat:61.6579096 },
        { lng:23.3680395	, lat:61.6594885 },
        { lng:23.369725	, lat:61.6604646 },
        { lng:23.3728482	, lat:61.6623003 },
        { lng:23.383209	, lat:61.6679175 },
        { lng:23.3877613	, lat:61.6710925 },
        { lng:23.409661	, lat:61.6864508 },
        { lng:23.422733	, lat:61.6832078 },
        { lng:23.4281922	, lat:61.6816738 },
        { lng:23.4256594	, lat:61.6808897 },
        { lng:23.4252116	, lat:61.6797969 },
        { lng:23.4285815	, lat:61.6785625 },
        { lng:23.4307784	, lat:61.6773229 },
        { lng:23.4354433	, lat:61.6712123 },
        { lng:23.4482067	, lat:61.6715732 },
        { lng:23.5003182	, lat:61.698047 },
        { lng:23.4930613	, lat:61.6894491 },
        { lng:23.4883108	, lat:61.6842531 },
        { lng:23.4834515	, lat:61.678064 },
        { lng:23.4853902	, lat:61.671559 },
        { lng:23.4857437	, lat:61.6701396 },
        { lng:23.4866342	, lat:61.6669421 },
        { lng:23.4870312	, lat:61.665552 },
        { lng:23.4911513	, lat:61.6520934 },
        { lng:23.4919181	, lat:61.6495884 },
        { lng:23.4793314	, lat:61.6279875 },
        { lng:23.48883	, lat:61.6093141 },
        { lng:23.4916334	, lat:61.5925996 },
        { lng:23.4927567	, lat:61.5858609 },
        { lng:23.4908041	, lat:61.5851098 },
        { lng:23.490037	, lat:61.5823252 },
        { lng:23.4887066	, lat:61.5810625 },
        { lng:23.4855094	, lat:61.5818189 },
        { lng:23.4464862	, lat:61.5731872 },
        { lng:23.445398	, lat:61.5730811 },
        { lng:23.4427357	, lat:61.5727094 },
        { lng:23.4421976	, lat:61.5722326 },
        { lng:23.4760479	, lat:61.5610349 },
        { lng:23.4764465	, lat:61.560878 },
        { lng:23.4769866	, lat:61.5606771 },
        { lng:23.4772023	, lat:61.5606132 },
        { lng:23.4780742	, lat:61.5603683 },
        { lng:23.4788664	, lat:61.5601199 },
        { lng:23.4797868	, lat:61.5598213 },
        { lng:23.4802651	, lat:61.5596578 },
        { lng:23.488461	, lat:61.5569498 },
        { lng:23.5058444	, lat:61.5511984 },
        { lng:23.5313646	, lat:61.5408137 },
        { lng:23.5391097	, lat:61.5356503 },
        { lng:23.5422877	, lat:61.5333693 },
        { lng:23.5432673	, lat:61.5332197 },
        { lng:23.5434853	, lat:61.5331244 },
        { lng:23.5436852	, lat:61.5331504 },
        { lng:23.5440122	, lat:61.5330205 },
        { lng:23.5442898	, lat:61.5329304 },
        { lng:23.5444118	, lat:61.5328906 },
        { lng:23.5448297	, lat:61.5328906 },
        { lng:23.5451748	, lat:61.5327174 },
        { lng:23.5454655	, lat:61.5327174 },
        { lng:23.545738	, lat:61.5325529 },
        { lng:23.5461558	, lat:61.5325442 },
        { lng:23.5462648	, lat:61.5323884 },
        { lng:23.5466463	, lat:61.5322412 },
        { lng:23.5472276	, lat:61.5322065 },
        { lng:23.5477908	, lat:61.5319035 },
        { lng:23.5487173	, lat:61.5314272 },
        { lng:23.5488626	, lat:61.5307344 },
        { lng:23.5502796	, lat:61.5300763 },
        { lng:23.5502796	, lat:61.5293402 },
        { lng:23.5496074	, lat:61.5291063 },
        { lng:23.5486083	, lat:61.528734 },
        { lng:23.5485719	, lat:61.5285694 },
        { lng:23.5487536	, lat:61.5283702 },
        { lng:23.5492441	, lat:61.5280844 },
        { lng:23.5494439	, lat:61.5281364 },
        { lng:23.5497164	, lat:61.5281364 },
        { lng:23.5498436	, lat:61.5279718 },
        { lng:23.5498981	, lat:61.52779 },
        { lng:23.5503159	, lat:61.527686 },
        { lng:23.5507338	, lat:61.5278073 },
        { lng:23.5514241	, lat:61.5278939 },
        { lng:23.5518782	, lat:61.52779 },
        { lng:23.5526957	, lat:61.52779 },
        { lng:23.5527321	, lat:61.5279892 },
        { lng:23.5530227	, lat:61.5282923 },
        { lng:23.5546759	, lat:61.5283529 },
        { lng:23.5547304	, lat:61.5280411 },
        { lng:23.5554207	, lat:61.5276774 },
        { lng:23.5555297	, lat:61.5272703 },
        { lng:23.5562927	, lat:61.5271837 },
        { lng:23.5572555	, lat:61.5270278 },
        { lng:23.5576552	, lat:61.5265168 },
        { lng:23.5580912	, lat:61.5263696 },
        { lng:23.5582547	, lat:61.5260925 },
        { lng:23.559163	, lat:61.5262743 },
        { lng:23.5593992	, lat:61.5263869 },
        { lng:23.5595941	, lat:61.5264581 },
        { lng:23.5596857	, lat:61.5264741 },
        { lng:23.5597245	, lat:61.5264913 },
        { lng:23.5597661	, lat:61.5265171 },
        { lng:23.55978	, lat:61.5265429 },
        { lng:23.5599035	, lat:61.5265839 },
        { lng:23.5600228	, lat:61.5266196 },
        { lng:23.5601199	, lat:61.5266408 },
        { lng:23.5602309	, lat:61.5266394 },
        { lng:23.5602989	, lat:61.5266222 },
        { lng:23.5603668	, lat:61.5266269 },
        { lng:23.5604959	, lat:61.5266229 },
        { lng:23.5605292	, lat:61.5265971 },
        { lng:23.5605874	, lat:61.5265845 },
        { lng:23.5607178	, lat:61.5265911 },
        { lng:23.5608122	, lat:61.5265872 },
        { lng:23.5608829	, lat:61.5265706 },
        { lng:23.5609204	, lat:61.5265389 },
        { lng:23.5609606	, lat:61.5264853 },
        { lng:23.5610189	, lat:61.5264357 },
        { lng:23.561098	, lat:61.526402 },
        { lng:23.5611923	, lat:61.5263894 },
        { lng:23.5612991	, lat:61.5263854 },
        { lng:23.5614157	, lat:61.5263769 },
        { lng:23.5614842	, lat:61.526401 },
        { lng:23.5614707	, lat:61.5264396 },
        { lng:23.5615054	, lat:61.5264584 },
        { lng:23.561541	, lat:61.5264717 },
        { lng:23.5615738	, lat:61.5264713 },
        { lng:23.5615873	, lat:61.5264589 },
        { lng:23.5616094	, lat:61.5264515 },
        { lng:23.5616634	, lat:61.5264506 },
        { lng:23.5617404	, lat:61.5264465 },
        { lng:23.5618204	, lat:61.5264322 },
        { lng:23.561885	, lat:61.5264171 },
        { lng:23.5620757	, lat:61.5264111 },
        { lng:23.5621634	, lat:61.5263886 },
        { lng:23.5623223	, lat:61.5263606 },
        { lng:23.5624457	, lat:61.5263266 },
        { lng:23.5625314	, lat:61.526322 },
        { lng:23.5626297	, lat:61.5263321 },
        { lng:23.5626807	, lat:61.5263399 },
        { lng:23.5626875	, lat:61.5263831 },
        { lng:23.562725	, lat:61.5263854 },
        { lng:23.5627597	, lat:61.526378 },
        { lng:23.5627732	, lat:61.5263684 },
        { lng:23.5628166	, lat:61.5263574 },
        { lng:23.5628667	, lat:61.5263473 },
        { lng:23.5629187	, lat:61.5263234 },
        { lng:23.5629437	, lat:61.5262976 },
        { lng:23.5630093	, lat:61.5262513 },
        { lng:23.5630574	, lat:61.5262457 },
        { lng:23.563252	, lat:61.5262453 },
        { lng:23.5633002	, lat:61.5262389 },
        { lng:23.5633349	, lat:61.5262173 },
        { lng:23.5633532	, lat:61.5261994 },
        { lng:23.5633802	, lat:61.5261828 },
        { lng:23.5634254	, lat:61.5261782 },
        { lng:23.5634563	, lat:61.5261805 },
        { lng:23.56349	, lat:61.526203 },
        { lng:23.5635083	, lat:61.5262265 },
        { lng:23.5635382	, lat:61.5262407 },
        { lng:23.563595	, lat:61.526249 },
        { lng:23.5636189	, lat:61.5262449 },
        { lng:23.5636486	, lat:61.526233 },
        { lng:23.563688	, lat:61.5261944 },
        { lng:23.5637321	, lat:61.5261756 },
        { lng:23.5637915	, lat:61.5261779 },
        { lng:23.5637972	, lat:61.5262001 },
        { lng:23.5638694	, lat:61.5262323 },
        { lng:23.5639505	, lat:61.5262376 },
        { lng:23.5640766	, lat:61.5262472 },
        { lng:23.5641119	, lat:61.5262464 },
        { lng:23.5641344	, lat:61.5262369 },
        { lng:23.5641392	, lat:61.5262039 },
        { lng:23.5641536	, lat:61.5261871 },
        { lng:23.5641697	, lat:61.5261787 },
        { lng:23.5642219	, lat:61.5261749 },
        { lng:23.5642757	, lat:61.5261745 },
        { lng:23.5643335	, lat:61.5261951 },
        { lng:23.5643535	, lat:61.5262055 },
        { lng:23.5643913	, lat:61.5262239 },
        { lng:23.5644234	, lat:61.5262334 },
        { lng:23.5644699	, lat:61.5262319 },
        { lng:23.5644917	, lat:61.526222 },
        { lng:23.5645558	, lat:61.5262045 },
        { lng:23.5645832	, lat:61.5261979 },
        { lng:23.5646274	, lat:61.5261956 },
        { lng:23.5647061	, lat:61.5261964 },
        { lng:23.5647365	, lat:61.5261904 },
        { lng:23.5647628	, lat:61.5261783 },
        { lng:23.5648076	, lat:61.5260855 },
        { lng:23.564781	, lat:61.5259501 },
        { lng:23.5648579	, lat:61.5258934 },
        { lng:23.5648261	, lat:61.525866 },
        { lng:23.5647143	, lat:61.5258591 },
        { lng:23.5645451	, lat:61.5257804 },
        { lng:23.564542	, lat:61.5257638 },
        { lng:23.5645799	, lat:61.5257349 },
        { lng:23.5646876	, lat:61.5257215 },
        { lng:23.5647257	, lat:61.5257119 },
        { lng:23.5647557	, lat:61.5256842 },
        { lng:23.5648218	, lat:61.5256747 },
        { lng:23.5650903	, lat:61.5256183 },
        { lng:23.5651243	, lat:61.5255801 },
        { lng:23.5650883	, lat:61.525563 },
        { lng:23.5650823	, lat:61.5255333 },
        { lng:23.5651424	, lat:61.5254321 },
        { lng:23.5652065	, lat:61.5254159 },
        { lng:23.5653006	, lat:61.5254436 },
        { lng:23.5654208	, lat:61.5254292 },
        { lng:23.5654809	, lat:61.5254359 },
        { lng:23.5654989	, lat:61.5254665 },
        { lng:23.565533	, lat:61.5254932 },
        { lng:23.5657527	, lat:61.5255215 },
        { lng:23.5658637	, lat:61.5255356 },
        { lng:23.5659544	, lat:61.5255532 },
        { lng:23.5660359	, lat:61.5255753 },
        { lng:23.5660729	, lat:61.5255682 },
        { lng:23.5661266	, lat:61.5255515 },
        { lng:23.5661951	, lat:61.5255382 },
        { lng:23.5662988	, lat:61.5255603 },
        { lng:23.5665913	, lat:61.5256009 },
        { lng:23.566682	, lat:61.5256035 },
        { lng:23.5667431	, lat:61.5255938 },
        { lng:23.5667783	, lat:61.5255753 },
        { lng:23.5668357	, lat:61.5255656 },
        { lng:23.5669282	, lat:61.5255665 },
        { lng:23.5670245	, lat:61.5255709 },
        { lng:23.5670856	, lat:61.5255621 },
        { lng:23.5671707	, lat:61.5255435 },
        { lng:23.5672448	, lat:61.5255153 },
        { lng:23.5672929	, lat:61.5255117 },
        { lng:23.5674336	, lat:61.52554 },
        { lng:23.5674688	, lat:61.5255409 },
        { lng:23.567541	, lat:61.5255418 },
        { lng:23.5675799	, lat:61.5255373 },
        { lng:23.5676113	, lat:61.5255241 },
        { lng:23.5676447	, lat:61.5254932 },
        { lng:23.5676502	, lat:61.5254694 },
        { lng:23.5676576	, lat:61.5254482 },
        { lng:23.5676817	, lat:61.5254385 },
        { lng:23.5677224	, lat:61.525435 },
        { lng:23.5678094	, lat:61.5254323 },
        { lng:23.567915	, lat:61.5254182 },
        { lng:23.5679686	, lat:61.5254005 },
        { lng:23.5679946	, lat:61.5253749 },
        { lng:23.5680131	, lat:61.5253476 },
        { lng:23.5680445	, lat:61.5253282 },
        { lng:23.5680964	, lat:61.5252911 },
        { lng:23.5680945	, lat:61.5252673 },
        { lng:23.5680575	, lat:61.5252478 },
        { lng:23.5680445	, lat:61.5252196 },
        { lng:23.5680519	, lat:61.5252037 },
        { lng:23.5680742	, lat:61.5251825 },
        { lng:23.5680982	, lat:61.5251631 },
        { lng:23.5681556	, lat:61.5251534 },
        { lng:23.5682223	, lat:61.5251587 },
        { lng:23.5683056	, lat:61.525164 },
        { lng:23.5683759	, lat:61.5251507 },
        { lng:23.5684407	, lat:61.5251384 },
        { lng:23.568487	, lat:61.5251163 },
        { lng:23.5685185	, lat:61.5250969 },
        { lng:23.5685648	, lat:61.525096 },
        { lng:23.5686092	, lat:61.5251181 },
        { lng:23.5686647	, lat:61.5251004 },
        { lng:23.5687184	, lat:61.525074 },
        { lng:23.568748	, lat:61.5250528 },
        { lng:23.5688721	, lat:61.5250528 },
        { lng:23.5689369	, lat:61.5250316 },
        { lng:23.5690294	, lat:61.5250281 },
        { lng:23.5691312	, lat:61.5250016 },
        { lng:23.5691405	, lat:61.5249795 },
        { lng:23.5690813	, lat:61.5249354 },
        { lng:23.5691035	, lat:61.5249045 },
        { lng:23.5691979	, lat:61.5248842 },
        { lng:23.5692849	, lat:61.5248957 },
        { lng:23.5693608	, lat:61.524916 },
        { lng:23.5694237	, lat:61.5249477 },
        { lng:23.5695089	, lat:61.5249504 },
        { lng:23.5696663	, lat:61.5249513 },
        { lng:23.5697607	, lat:61.5249407 },
        { lng:23.5698255	, lat:61.524901 },
        { lng:23.5698681	, lat:61.5248789 },
        { lng:23.5699458	, lat:61.5248648 },
        { lng:23.5700273	, lat:61.5248383 },
        { lng:23.5702587	, lat:61.5248392 },
        { lng:23.5703253	, lat:61.524825 },
        { lng:23.5703512	, lat:61.524803 },
        { lng:23.5703497	, lat:61.5247575 },
        { lng:23.5703775	, lat:61.5247465 },
        { lng:23.570433	, lat:61.5247281 },
        { lng:23.5705055	, lat:61.5247141 },
        { lng:23.5705487	, lat:61.5247134 },
        { lng:23.5706089	, lat:61.524684 },
        { lng:23.5706552	, lat:61.5246604 },
        { lng:23.5707107	, lat:61.5246501 },
        { lng:23.5707832	, lat:61.5246097 },
        { lng:23.5708141	, lat:61.5245928 },
        { lng:23.5708434	, lat:61.5245928 },
        { lng:23.5708711	, lat:61.5245876 },
        { lng:23.5709066	, lat:61.5245773 },
        { lng:23.5709514	, lat:61.524553 },
        { lng:23.5709976	, lat:61.5245288 },
        { lng:23.5710347	, lat:61.5244957 },
        { lng:23.571027	, lat:61.5244699 },
        { lng:23.5710686	, lat:61.524442 },
        { lng:23.5710964	, lat:61.5244199 },
        { lng:23.5711349	, lat:61.5244074 },
        { lng:23.5711627	, lat:61.5244067 },
        { lng:23.5711997	, lat:61.5243942 },
        { lng:23.5712213	, lat:61.5243802 },
        { lng:23.5712306	, lat:61.5243633 },
        { lng:23.5712537	, lat:61.5243346 },
        { lng:23.5712938	, lat:61.5243361 },
        { lng:23.5713201	, lat:61.5243434 },
        { lng:23.5713571	, lat:61.5243493 },
        { lng:23.5714142	, lat:61.5243434 },
        { lng:23.5714666	, lat:61.5243258 },
        { lng:23.5715191	, lat:61.5243118 },
        { lng:23.5715654	, lat:61.5242897 },
        { lng:23.5716471	, lat:61.5242574 },
        { lng:23.5717073	, lat:61.5242353 },
        { lng:23.5717675	, lat:61.5242073 },
        { lng:23.5717968	, lat:61.5241831 },
        { lng:23.5717767	, lat:61.5241529 },
        { lng:23.5717613	, lat:61.5241294 },
        { lng:23.5717166	, lat:61.5241235 },
        { lng:23.5716996	, lat:61.5241154 },
        { lng:23.5716919	, lat:61.5240963 },
        { lng:23.571698	, lat:61.5240558 },
        { lng:23.5716996	, lat:61.5240293 },
        { lng:23.5717212	, lat:61.5239992 },
        { lng:23.5717304	, lat:61.5239771 },
        { lng:23.5717181	, lat:61.5239536 },
        { lng:23.5716656	, lat:61.5239197 },
        { lng:23.5716348	, lat:61.5239035 },
        { lng:23.571587	, lat:61.5238712 },
        { lng:23.5715607	, lat:61.5238373 },
        { lng:23.5715237	, lat:61.5238373 },
        { lng:23.5715052	, lat:61.5238293 },
        { lng:23.571516	, lat:61.5238182 },
        { lng:23.5715391	, lat:61.5238109 },
        { lng:23.5715314	, lat:61.5237954 },
        { lng:23.5714929	, lat:61.5237756 },
        { lng:23.5714265	, lat:61.5237425 },
        { lng:23.5713401	, lat:61.5237145 },
        { lng:23.57128	, lat:61.5236755 },
        { lng:23.5712383	, lat:61.5236468 },
        { lng:23.5712707	, lat:61.523624 },
        { lng:23.5713309	, lat:61.5236071 },
        { lng:23.5714296	, lat:61.5235902 },
        { lng:23.571462	, lat:61.523574 },
        { lng:23.5714759	, lat:61.5235527 },
        { lng:23.5714635	, lat:61.5235372 },
        { lng:23.5714851	, lat:61.5235277 },
        { lng:23.5715793	, lat:61.5235166 },
        { lng:23.5717104	, lat:61.5235019 },
        { lng:23.5718384	, lat:61.5234887 },
        { lng:23.5718986	, lat:61.523471 },
        { lng:23.571985	, lat:61.5234556 },
        { lng:23.572059	, lat:61.5234416 },
        { lng:23.5721794	, lat:61.5234468 },
        { lng:23.5722519	, lat:61.5234541 },
        { lng:23.5723429	, lat:61.5234615 },
        { lng:23.5723892	, lat:61.523471 },
        { lng:23.5724478	, lat:61.5234909 },
        { lng:23.5725095	, lat:61.5235188 },
        { lng:23.5725666	, lat:61.5235225 },
        { lng:23.5726144	, lat:61.5235211 },
        { lng:23.5726993	, lat:61.5235078 },
        { lng:23.5727641	, lat:61.5235027 },
        { lng:23.5727903	, lat:61.523474 },
        { lng:23.572832	, lat:61.5234615 },
        { lng:23.5728952	, lat:61.5234696 },
        { lng:23.5729168	, lat:61.5234821 },
        { lng:23.5729708	, lat:61.5234983 },
        { lng:23.5730988	, lat:61.5235005 },
        { lng:23.5732038	, lat:61.5235027 },
        { lng:23.5732439	, lat:61.5234975 },
        { lng:23.5733796	, lat:61.5234629 },
        { lng:23.5734722	, lat:61.5234129 },
        { lng:23.5734861	, lat:61.5233813 },
        { lng:23.5734784	, lat:61.5233585 },
        { lng:23.5734768	, lat:61.523335 },
        { lng:23.5735077	, lat:61.5233107 },
        { lng:23.5735231	, lat:61.5232768 },
        { lng:23.5735462	, lat:61.5232401 },
        { lng:23.5735971	, lat:61.5232349 },
        { lng:23.5736033	, lat:61.5232173 },
        { lng:23.5737345	, lat:61.5232099 },
        { lng:23.5738409	, lat:61.5231952 },
        { lng:23.5739597	, lat:61.523193 },
        { lng:23.5740044	, lat:61.5232084 },
        { lng:23.5740553	, lat:61.5232128 },
        { lng:23.5741001	, lat:61.5232055 },
        { lng:23.5741479	, lat:61.5231878 },
        { lng:23.5742497	, lat:61.5231687 },
        { lng:23.5743438	, lat:61.523165 },
        { lng:23.5744256	, lat:61.5231643 },
        { lng:23.5744441	, lat:61.5231511 },
        { lng:23.5744657	, lat:61.5231356 },
        { lng:23.5745243	, lat:61.5231143 },
        { lng:23.5745984	, lat:61.523101 },
        { lng:23.5746632	, lat:61.5231172 },
        { lng:23.5747465	, lat:61.5231341 },
        { lng:23.5747959	, lat:61.5231503 },
        { lng:23.574856	, lat:61.5231783 },
        { lng:23.5749208	, lat:61.5232011 },
        { lng:23.5749548	, lat:61.5232121 },
        { lng:23.5749949	, lat:61.5232151 },
        { lng:23.5750365	, lat:61.523243 },
        { lng:23.5751106	, lat:61.5233107 },
        { lng:23.5751399	, lat:61.523343 },
        { lng:23.5751615	, lat:61.5233747 },
        { lng:23.575197	, lat:61.5233909 },
        { lng:23.5752417	, lat:61.5233975 },
        { lng:23.5752618	, lat:61.5234092 },
        { lng:23.5752818	, lat:61.5234313 },
        { lng:23.5753142	, lat:61.5234431 },
        { lng:23.5753559	, lat:61.523446 },
        { lng:23.5753836	, lat:61.5234379 },
        { lng:23.5754577	, lat:61.5234225 },
        { lng:23.5754916	, lat:61.5234151 },
        { lng:23.5755533	, lat:61.5234137 },
        { lng:23.575615	, lat:61.5234328 },
        { lng:23.5756644	, lat:61.523446 },
        { lng:23.5757462	, lat:61.5234475 },
        { lng:23.5757817	, lat:61.5234365 },
        { lng:23.5758819	, lat:61.5234321 },
        { lng:23.5759205	, lat:61.5234144 },
        { lng:23.5760131	, lat:61.5234115 },
        { lng:23.5760964	, lat:61.52341 },
        { lng:23.5761797	, lat:61.5234063 },
        { lng:23.5762198	, lat:61.5233923 },
        { lng:23.5762352	, lat:61.5233739 },
        { lng:23.5762337	, lat:61.5233622 },
        { lng:23.5762213	, lat:61.5233416 },
        { lng:23.5762244	, lat:61.5233107 },
        { lng:23.5762291	, lat:61.5232791 },
        { lng:23.5762645	, lat:61.523268 },
        { lng:23.5762645	, lat:61.5232577 },
        { lng:23.5762429	, lat:61.5232533 },
        { lng:23.5761936	, lat:61.5232173 },
        { lng:23.5761982	, lat:61.523179 },
        { lng:23.5761859	, lat:61.5231555 },
        { lng:23.5761982	, lat:61.52314 },
        { lng:23.5762167	, lat:61.5231209 },
        { lng:23.5761997	, lat:61.5231003 },
        { lng:23.576172	, lat:61.5230731 },
        { lng:23.5761427	, lat:61.5230576 },
        { lng:23.5761319	, lat:61.5230451 },
        { lng:23.5761442	, lat:61.5230378 },
        { lng:23.5761519	, lat:61.5230231 },
        { lng:23.5761334	, lat:61.5230032 },
        { lng:23.576101	, lat:61.5229819 },
        { lng:23.5761056	, lat:61.5229591 },
        { lng:23.5760702	, lat:61.5229363 },
        { lng:23.576027	, lat:61.5229274 },
        { lng:23.5759838	, lat:61.5229135 },
        { lng:23.5759652	, lat:61.5228973 },
        { lng:23.5759606	, lat:61.522859 },
        { lng:23.5759714	, lat:61.5228252 },
        { lng:23.5759853	, lat:61.5227803 },
        { lng:23.5759791	, lat:61.5227516 },
        { lng:23.5759591	, lat:61.5227266 },
        { lng:23.5759267	, lat:61.5227009 },
        { lng:23.5759236	, lat:61.5226766 },
        { lng:23.5759328	, lat:61.5226509 },
        { lng:23.5759637	, lat:61.5226384 },
        { lng:23.5759745	, lat:61.5226163 },
        { lng:23.5759467	, lat:61.5225957 },
        { lng:23.5759051	, lat:61.5225898 },
        { lng:23.5758418	, lat:61.5225501 },
        { lng:23.5757847	, lat:61.5225052 },
        { lng:23.5757709	, lat:61.5224655 },
        { lng:23.5757323	, lat:61.5224405 },
        { lng:23.575686	, lat:61.5223765 },
        { lng:23.5756228	, lat:61.5223618 },
        { lng:23.5756012	, lat:61.52235 },
        { lng:23.5755981	, lat:61.5223368 },
        { lng:23.5756042	, lat:61.5223198 },
        { lng:23.5756351	, lat:61.5222978 },
        { lng:23.5756366	, lat:61.5222853 },
        { lng:23.5756228	, lat:61.5222735 },
        { lng:23.5755934	, lat:61.5222581 },
        { lng:23.5755718	, lat:61.5222514 },
        { lng:23.575578	, lat:61.5222397 },
        { lng:23.575578	, lat:61.5222323 },
        { lng:23.575595	, lat:61.5222183 },
        { lng:23.5756135	, lat:61.522211 },
        { lng:23.5756336	, lat:61.5221999 },
        { lng:23.5756459	, lat:61.522186 },
        { lng:23.5756474	, lat:61.5221676 },
        { lng:23.5756243	, lat:61.5221565 },
        { lng:23.5756104	, lat:61.5221462 },
        { lng:23.5756104	, lat:61.5221337 },
        { lng:23.5756166	, lat:61.522122 },
        { lng:23.5756737	, lat:61.5220955 },
        { lng:23.5757107	, lat:61.5220778 },
        { lng:23.5757354	, lat:61.5220631 },
        { lng:23.5757354	, lat:61.5220469 },
        { lng:23.57573	, lat:61.5220224 },
        { lng:23.5757852	, lat:61.5219886 },
        { lng:23.5758177	, lat:61.5219711 },
        { lng:23.5758707	, lat:61.5219595 },
        { lng:23.5759358	, lat:61.5219595 },
        { lng:23.5759765	, lat:61.5219478 },
        { lng:23.575944	, lat:61.5219264 },
        { lng:23.5759195	, lat:61.521907 },
        { lng:23.5759195	, lat:61.5218857 },
        { lng:23.5759521	, lat:61.5218507 },
        { lng:23.5759969	, lat:61.521843 },
        { lng:23.5760824	, lat:61.5218255 },
        { lng:23.5761476	, lat:61.52181 },
        { lng:23.5761842	, lat:61.5217886 },
        { lng:23.5762494	, lat:61.5217362 },
        { lng:23.5762697	, lat:61.5216915 },
        { lng:23.5763674	, lat:61.5216197 },
        { lng:23.5764285	, lat:61.521575 },
        { lng:23.5764855	, lat:61.5215381 },
        { lng:23.5765832	, lat:61.5214915 },
        { lng:23.5766525	, lat:61.5214566 },
        { lng:23.5767461	, lat:61.5214469 },
        { lng:23.5768561	, lat:61.5214469 },
        { lng:23.5768805	, lat:61.5214197 },
        { lng:23.5769375	, lat:61.5213886 },
        { lng:23.5769986	, lat:61.5213459 },
        { lng:23.5770922	, lat:61.5213032 },
        { lng:23.5772103	, lat:61.5212993 },
        { lng:23.5772429	, lat:61.5212644 },
        { lng:23.5773827	, lat:61.521256 },
        { lng:23.5777389	, lat:61.521256 },
        { lng:23.5778125	, lat:61.5212802 },
        { lng:23.5782196	, lat:61.5212802 },
        { lng:23.578296	, lat:61.5212991 },
        { lng:23.5783667	, lat:61.5213328 },
        { lng:23.5784402	, lat:61.5213611 },
        { lng:23.5785759	, lat:61.5214151 },
        { lng:23.5787145	, lat:61.5213935 },
        { lng:23.5788983	, lat:61.5213868 },
        { lng:23.5789435	, lat:61.5213679 },
        { lng:23.5789718	, lat:61.5213328 },
        { lng:23.5789859	, lat:61.5213166 },
        { lng:23.5790199	, lat:61.5213005 },
        { lng:23.5790849	, lat:61.5213005 },
        { lng:23.579133	, lat:61.5213126 },
        { lng:23.5791612	, lat:61.5213342 },
        { lng:23.5791952	, lat:61.5213315 },
        { lng:23.5792206	, lat:61.5213059 },
        { lng:23.5792304	, lat:61.521265 },
        { lng:23.5792755	, lat:61.5212678 },
        { lng:23.5793227	, lat:61.52128 },
        { lng:23.5793541	, lat:61.521279 },
        { lng:23.5793914	, lat:61.5212584 },
        { lng:23.5794385	, lat:61.5212275 },
        { lng:23.5794896	, lat:61.5211835 },
        { lng:23.5795426	, lat:61.5211536 },
        { lng:23.5795112	, lat:61.521132 },
        { lng:23.5795033	, lat:61.5211124 },
        { lng:23.5795485	, lat:61.5210927 },
        { lng:23.5796211	, lat:61.5210571 },
        { lng:23.5797016	, lat:61.5210262 },
        { lng:23.5797547	, lat:61.5210103 },
        { lng:23.5797959	, lat:61.5209888 },
        { lng:23.5798136	, lat:61.5209672 },
        { lng:23.5797468	, lat:61.520941 },
        { lng:23.5797311	, lat:61.520926 },
        { lng:23.5797527	, lat:61.5209101 },
        { lng:23.5797802	, lat:61.520898 },
        { lng:23.5798371	, lat:61.5208933 },
        { lng:23.5798784	, lat:61.5208989 },
        { lng:23.5799137	, lat:61.5209139 },
        { lng:23.5799432	, lat:61.5209289 },
        { lng:23.5799746	, lat:61.5209204 },
        { lng:23.5799903	, lat:61.5209092 },
        { lng:23.5799785	, lat:61.5208923 },
        { lng:23.5799451	, lat:61.520883 },
        { lng:23.5799373	, lat:61.5208652 },
        { lng:23.5799392	, lat:61.520853 },
        { lng:23.5799392	, lat:61.5208362 },
        { lng:23.5799726	, lat:61.5208137 },
        { lng:23.5799805	, lat:61.5207912 },
        { lng:23.5799864	, lat:61.5207659 },
        { lng:23.5800256	, lat:61.520765 },
        { lng:23.5800531	, lat:61.5207678 },
        { lng:23.5800944	, lat:61.5207687 },
        { lng:23.5801002	, lat:61.5207556 },
        { lng:23.580116	, lat:61.5207435 },
        { lng:23.5801042	, lat:61.5207247 },
        { lng:23.5800767	, lat:61.5207144 },
        { lng:23.5800453	, lat:61.520706 },
        { lng:23.5799746	, lat:61.5207051 },
        { lng:23.579949	, lat:61.5207004 },
        { lng:23.5799117	, lat:61.5206779 },
        { lng:23.5798921	, lat:61.5206601 },
        { lng:23.57986	, lat:61.5206339 },
        { lng:23.5798535	, lat:61.5206167 },
        { lng:23.5798535	, lat:61.5206027 },
        { lng:23.5798682	, lat:61.5205902 },
        { lng:23.5798846	, lat:61.5205754 },
        { lng:23.5798813	, lat:61.5205574 },
        { lng:23.57986	, lat:61.5205504 },
        { lng:23.5798437	, lat:61.520545 },
        { lng:23.5798338	, lat:61.520534 },
        { lng:23.5798437	, lat:61.5205216 },
        { lng:23.5798682	, lat:61.5205098 },
        { lng:23.5798829	, lat:61.5205013 },
        { lng:23.579896	, lat:61.5204911 },
        { lng:23.5799222	, lat:61.5204833 },
        { lng:23.5799713	, lat:61.5204755 },
        { lng:23.5800008	, lat:61.5204825 },
        { lng:23.5800384	, lat:61.5204942 },
        { lng:23.5800777	, lat:61.5204997 },
        { lng:23.5800973	, lat:61.5204989 },
        { lng:23.5801235	, lat:61.5204919 },
        { lng:23.5801529	, lat:61.520481 },
        { lng:23.5802053	, lat:61.5204732 },
        { lng:23.580256	, lat:61.5204747 },
        { lng:23.5803215	, lat:61.520481 },
        { lng:23.5803526	, lat:61.5204646 },
        { lng:23.580382	, lat:61.520449 },
        { lng:23.5804262	, lat:61.5204303 },
        { lng:23.5805113	, lat:61.5204131 },
        { lng:23.5806308	, lat:61.5203998 },
        { lng:23.5806291	, lat:61.5204147 },
        { lng:23.5806357	, lat:61.5204334 },
        { lng:23.5806831	, lat:61.5204482 },
        { lng:23.580724	, lat:61.5204505 },
        { lng:23.58076	, lat:61.520449 },
        { lng:23.5807748	, lat:61.5204373 },
        { lng:23.5807764	, lat:61.5204256 },
        { lng:23.5808026	, lat:61.5204139 },
        { lng:23.5808255	, lat:61.5204068 },
        { lng:23.5808582	, lat:61.5203975 },
        { lng:23.58085	, lat:61.5203819 },
        { lng:23.5808729	, lat:61.5203616 },
        { lng:23.5808958	, lat:61.5203514 },
        { lng:23.5809302	, lat:61.5203483 },
        { lng:23.5809777	, lat:61.5203335 },
        { lng:23.5810104	, lat:61.5203179 },
        { lng:23.5810971	, lat:61.5202711 },
        { lng:23.5811789	, lat:61.5202399 },
        { lng:23.5812428	, lat:61.5202305 },
        { lng:23.5813164	, lat:61.5202383 },
        { lng:23.5813344	, lat:61.5202344 },
        { lng:23.5813082	, lat:61.5202165 },
        { lng:23.5812968	, lat:61.5202048 },
        { lng:23.5813131	, lat:61.5201907 },
        { lng:23.5813377	, lat:61.5201845 },
        { lng:23.5813851	, lat:61.5201774 },
        { lng:23.5814358	, lat:61.5201852 },
        { lng:23.5815962	, lat:61.5200986 },
        { lng:23.5816818	, lat:61.5200473 },
        { lng:23.581728	, lat:61.5200109 },
        { lng:23.5817759	, lat:61.5199796 },
        { lng:23.5818114	, lat:61.5199364 },
        { lng:23.5818452	, lat:61.5198915 },
        { lng:23.5818647	, lat:61.519878 },
        { lng:23.5819091	, lat:61.5198619 },
        { lng:23.5818984	, lat:61.5198466 },
        { lng:23.5819073	, lat:61.5198178 },
        { lng:23.5818807	, lat:61.5198145 },
        { lng:23.5818434	, lat:61.5198119 },
        { lng:23.5818239	, lat:61.5198043 },
        { lng:23.5818008	, lat:61.5197958 },
        { lng:23.5817901	, lat:61.5197789 },
        { lng:23.5818061	, lat:61.5197586 },
        { lng:23.5818452	, lat:61.5197408 },
        { lng:23.5818984	, lat:61.5197306 },
        { lng:23.5819091	, lat:61.5197129 },
        { lng:23.5819198	, lat:61.519596 },
        { lng:23.5819251	, lat:61.5195672 },
        { lng:23.5819517	, lat:61.5195528 },
        { lng:23.5820032	, lat:61.5195367 },
        { lng:23.5820867	, lat:61.5195266 },
        { lng:23.5821222	, lat:61.5195139 },
        { lng:23.5821328	, lat:61.5195012 },
        { lng:23.5821222	, lat:61.5194834 },
        { lng:23.5821044	, lat:61.5194699 },
        { lng:23.582092	, lat:61.5194572 },
        { lng:23.5820902	, lat:61.5194445 },
        { lng:23.5821257	, lat:61.5194284 },
        { lng:23.5821648	, lat:61.5194089 },
        { lng:23.5821896	, lat:61.5193911 },
        { lng:23.5822145	, lat:61.519364 },
        { lng:23.5822358	, lat:61.5193268 },
        { lng:23.5822624	, lat:61.5193056 },
        { lng:23.5822926	, lat:61.5192802 },
        { lng:23.582337	, lat:61.5192345 },
        { lng:23.582392	, lat:61.5192006 },
        { lng:23.5824293	, lat:61.5191896 },
        { lng:23.5824986	, lat:61.5191854 },
        { lng:23.582582	, lat:61.519182 },
        { lng:23.5826371	, lat:61.5191532 },
        { lng:23.5826832	, lat:61.5191363 },
        { lng:23.5827294	, lat:61.5191236 },
        { lng:23.5827916	, lat:61.5191219 },
        { lng:23.5828324	, lat:61.5191159 },
        { lng:23.5828715	, lat:61.5190956 },
        { lng:23.5828786	, lat:61.5190482 },
        { lng:23.5829016	, lat:61.5190262 },
        { lng:23.5829141	, lat:61.5190059 },
        { lng:23.5828963	, lat:61.5189779 },
        { lng:23.5828697	, lat:61.5189551 },
        { lng:23.5828271	, lat:61.5189364 },
        { lng:23.5827779	, lat:61.5189151 },
        { lng:23.5827824	, lat:61.5188918 },
        { lng:23.5828475	, lat:61.5188368 },
        { lng:23.5828638	, lat:61.5188276 },
        { lng:23.5829067	, lat:61.5188185 },
        { lng:23.5829496	, lat:61.5188015 },
        { lng:23.5829866	, lat:61.5187578 },
        { lng:23.5830191	, lat:61.5187338 },
        { lng:23.5830236	, lat:61.5187034 },
        { lng:23.582991	, lat:61.5186583 },
        { lng:23.5829836	, lat:61.5186272 },
        { lng:23.5829999	, lat:61.5186033 },
        { lng:23.5829599	, lat:61.5185927 },
        { lng:23.5829614	, lat:61.5185129 },
        { lng:23.5829422	, lat:61.5184812 },
        { lng:23.5828726	, lat:61.518417 },
        { lng:23.5827306	, lat:61.5182998 },
        { lng:23.5827114	, lat:61.5182921 },
        { lng:23.5826551	, lat:61.5182956 },
        { lng:23.5826211	, lat:61.5182928 },
        { lng:23.5826019	, lat:61.5182737 },
        { lng:23.5825308	, lat:61.5182575 },
        { lng:23.5824795	, lat:61.5182355 },
        { lng:23.5823994	, lat:61.518232 },
        { lng:23.582334	, lat:61.5182191 },
        { lng:23.5822835	, lat:61.5181944 },
        { lng:23.5822502	, lat:61.5181614 },
        { lng:23.5822354	, lat:61.5181444 },
        { lng:23.5822231	, lat:61.518102 },
        { lng:23.5822162	, lat:61.5180839 },
        { lng:23.5822485	, lat:61.5180583 },
        { lng:23.5829533	, lat:61.5176704 },
        { lng:23.5836765	, lat:61.5173038 },
        { lng:23.5857534	, lat:61.5160836 },
        { lng:23.5881182	, lat:61.516911 },
        { lng:23.590305	, lat:61.5176705 },
        { lng:23.5955699	, lat:61.5195 },
        { lng:23.6017629	, lat:61.5216518 },
        { lng:23.6057882	, lat:61.5230221 },
        { lng:23.6088239	, lat:61.5233708 },
        { lng:23.6093825	, lat:61.5234352 },
        { lng:23.614326	, lat:61.5240019 },
        { lng:23.6197207	, lat:61.524628 },
        { lng:23.6240472	, lat:61.5251235 },
        { lng:23.6420815	, lat:61.527199 },
        { lng:23.6424245	, lat:61.5272385 },
        { lng:23.6451262	, lat:61.5279367 },
        { lng:23.6474068	, lat:61.5285264 },
        { lng:23.6459059	, lat:61.5298854 },
        { lng:23.6521481	, lat:61.5309215 },
        { lng:23.6535536	, lat:61.5301546 },
        { lng:23.6548407	, lat:61.530485 },
        { lng:23.6548689	, lat:61.5306069 },
        { lng:23.6605617	, lat:61.5344062 },
        { lng:23.6723509	, lat:61.5422725 },
        { lng:23.6742709	, lat:61.5393972 },
        { lng:23.6764283	, lat:61.5390373 },
        { lng:23.6835875	, lat:61.5384757 },
        { lng:23.6825609	, lat:61.536683 },
        { lng:23.6932871	, lat:61.5359263 },
        { lng:23.6953088	, lat:61.5361031 },
        { lng:23.6996726	, lat:61.5364848 },
        { lng:23.706877	, lat:61.5398817 },
        { lng:23.7227942	, lat:61.5372545 },
        { lng:23.7632199	, lat:61.5398668 },
        { lng:23.7532748	, lat:61.5837991 },
        { lng:23.7482928	, lat:61.6057834 },
        { lng:23.7243527	, lat:61.6482387 },
        { lng:23.7213207	, lat:61.6710395 },
        { lng:23.7262791	, lat:61.6861653 },
        { lng:23.7569816	, lat:61.6905511 },
        { lng:23.7792852	, lat:61.7257748 },
        { lng:23.7644277	, lat:61.7347113 },
        { lng:23.7555009	, lat:61.7495058 },
        { lng:23.7651539	, lat:61.7654918 },
        { lng:23.756449	, lat:61.7686472 },
        { lng:23.7619004	, lat:61.7723388 },
        { lng:23.7674862	, lat:61.7725704 },
        { lng:23.7826716	, lat:61.7889791 },
        { lng:23.8030002	, lat:61.7942708 },
        { lng:23.8078906	, lat:61.8215982 },
        { lng:23.8555302	, lat:61.836614 },
        { lng:23.8398514	, lat:61.8361687 },
        { lng:23.8384997	, lat:61.8366823 },
        { lng:23.8379904	, lat:61.8365621 },
        { lng:23.8374944	, lat:61.8366823 },
        { lng:23.8371057	, lat:61.8373845 },
        { lng:23.8341434	, lat:61.8384094 },
        { lng:23.8348404	, lat:61.8386751 },
        { lng:23.8363149	, lat:61.8385675 },
        { lng:23.8366366	, lat:61.8391369 },
        { lng:23.8365964	, lat:61.8402249 },
        { lng:23.8368108	, lat:61.8406108 },
        { lng:23.8372263	, lat:61.8407753 },
        { lng:23.8372129	, lat:61.8411295 },
        { lng:23.8364087	, lat:61.8409714 },
        { lng:23.8363551	, lat:61.8413256 },
        { lng:23.8357519	, lat:61.841819 },
        { lng:23.8350817	, lat:61.8417494 },
        { lng:23.8343579	, lat:61.8420847 },
        { lng:23.8349208	, lat:61.8424389 },
        { lng:23.8349342	, lat:61.8429323 },
        { lng:23.8345187	, lat:61.8430145 },
        { lng:23.8345723	, lat:61.8434003 },
        { lng:23.83413	, lat:61.8437988 },
        { lng:23.8712203	, lat:61.8589833 },
        { lng:23.8851119	, lat:61.8673344 },
        { lng:23.880005	, lat:61.8797406 },
        { lng:23.8591724	, lat:61.8958248 },
        { lng:23.8540143	, lat:61.9066018 },
        { lng:23.8414464	, lat:61.932353 },
        { lng:23.8443424	, lat:61.9495176 },
        { lng:23.830017	, lat:61.9588845 },
        { lng:23.8126153	, lat:61.9707393 },
        { lng:23.7835753	, lat:61.9666373 },
        { lng:23.7797762	, lat:61.980744 },
        { lng:23.7748096	, lat:62.0001344 },
        { lng:23.7700098	, lat:62.0033186 },
        { lng:23.7693797	, lat:62.0030489 },
        { lng:23.7676747	, lat:62.0030924 },
        { lng:23.7607993	, lat:62.0077636 },
        { lng:23.7624301	, lat:62.0082507 },
        { lng:23.7595391	, lat:62.0096772 },
        { lng:23.728127	, lat:62.0327257 },
        { lng:23.697623	, lat:62.031309 },
        { lng:23.6881081	, lat:62.0777884 },
        { lng:23.6823939	, lat:62.0775433 },
        { lng:23.6805925	, lat:62.084411 },
        { lng:23.6428031	, lat:62.0740627 },
        { lng:23.5376952	, lat:62.118334 },
        { lng:23.5296532	, lat:62.1200969 },
        { lng:23.5251182	, lat:62.1213753 },
        { lng:23.4240659	, lat:62.1580975 },
        { lng:23.3737648	, lat:62.1650078 },
        { lng:23.3628316	, lat:62.1533222 },
        { lng:23.3309509	, lat:62.1331577 },
        { lng:23.307385	, lat:62.1116336 },
        { lng:23.3060975	, lat:62.110585 },
        { lng:23.2930325	, lat:62.1072381 },
        { lng:23.2952062	, lat:62.0829405 },
        { lng:23.3252512	, lat:62.0766785 },
        { lng:23.3262168	, lat:62.0712218 },
        { lng:23.3541869	, lat:62.0721514 },
        { lng:23.3647012	, lat:62.0714882 },
        { lng:23.3661529	, lat:62.0382252 },
        { lng:23.3496993	, lat:62.019552 },
        { lng:23.3569929	, lat:62.0060381 },
        { lng:23.3336502	, lat:61.9893783 },
        { lng:23.3319354	, lat:61.9885534 },
        { lng:23.3317181	, lat:61.9882473 },
        { lng:23.3316231	, lat:61.9877512 },
        { lng:23.331517	, lat:61.9875847 },
        { lng:23.3311576	, lat:61.9875368 },
        { lng:23.3295697	, lat:61.9874663 },
        { lng:23.3292103	, lat:61.9873378 },
        { lng:23.3290762	, lat:61.9871664 },
        { lng:23.3291352	, lat:61.9861032 },
        { lng:23.3289689	, lat:61.9859848 },
        { lng:23.3259487	, lat:61.983077 },
        { lng:23.3250153	, lat:61.9827872 },
        { lng:23.3245701	, lat:61.9825504 },
        { lng:23.3238459	, lat:61.9824924 },
        { lng:23.323288	, lat:61.9827091 },
        { lng:23.3225477	, lat:61.9829712 },
        { lng:23.3212763	, lat:61.9830291 },
        { lng:23.3208686	, lat:61.9829359 },
        { lng:23.3206594	, lat:61.9827091 },
        { lng:23.3210295	, lat:61.9822177 },
        { lng:23.3212656	, lat:61.9816961 },
        { lng:23.3210671	, lat:61.9809678 },
        { lng:23.3217108	, lat:61.9792365 },
        { lng:23.3215928	, lat:61.9788207 },
        { lng:23.321169	, lat:61.9785964 },
        { lng:23.3198065	, lat:61.9784654 },
        { lng:23.3185512	, lat:61.9786241 },
        { lng:23.3149195	, lat:61.9800203 },
        { lng:23.3092667	, lat:61.9663903 },
        { lng:23.306126	, lat:61.9657874 },
        { lng:23.3048526	, lat:61.963755 },
        { lng:23.3052	, lat:61.9632701 },
        { lng:23.3041998	, lat:61.9626637 },
        { lng:23.3049043	, lat:61.9620159 },
        { lng:23.3024558	, lat:61.9604705 },
        { lng:23.3108545	, lat:61.9495018 },
        { lng:23.3640307	, lat:61.9313386 },
        { lng:23.3698403	, lat:61.9305385 },
        { lng:23.3768087	, lat:61.9295742 },
        { lng:23.3818888	, lat:61.928875 },
        { lng:23.3876314	, lat:61.928095 },
        { lng:23.3880485	, lat:61.9280055 },
        { lng:23.3887445	, lat:61.9278544 },
        { lng:23.3895049	, lat:61.9279391 },
        { lng:23.3899542	, lat:61.9279125 },
        { lng:23.3900883	, lat:61.9277876 },
        { lng:23.3901714	, lat:61.9275459 },
        { lng:23.3904008	, lat:61.9275149 },
        { lng:23.3911196	, lat:61.9274355 },
        { lng:23.3912698	, lat:61.9272953 },
        { lng:23.3913368	, lat:61.92713 },
        { lng:23.3914455	, lat:61.92706 },
        { lng:23.3916339	, lat:61.9271019 },
        { lng:23.391888	, lat:61.9272334 },
        { lng:23.3920181	, lat:61.9273044 },
        { lng:23.3922367	, lat:61.927331 },
        { lng:23.3925928	, lat:61.9273503 },
        { lng:23.3927423	, lat:61.9273473 },
        { lng:23.3929442	, lat:61.9272484 },
        { lng:23.3931809	, lat:61.927054 },
        { lng:23.3933418	, lat:61.9269259 },
        { lng:23.3934806	, lat:61.9268709 },
        { lng:23.393541	, lat:61.9268293 },
        { lng:23.3935597	, lat:61.9267573 },
        { lng:23.393669	, lat:61.9266693 },
        { lng:23.3937911	, lat:61.9266267 },
        { lng:23.3941746	, lat:61.9265623 },
        { lng:23.3945206	, lat:61.9265125 },
        { lng:23.3952716	, lat:61.9264061 },
        { lng:23.395672	, lat:61.9263215 },
        { lng:23.3960052	, lat:61.9261666 },
        { lng:23.39637	, lat:61.9261764 },
        { lng:23.3970426	, lat:61.9261571 },
        { lng:23.3973356	, lat:61.9260738 },
        { lng:23.3975327	, lat:61.925876 },
        { lng:23.3976736	, lat:61.9257097 },
        { lng:23.3979981	, lat:61.9257122 },
        { lng:23.3980497	, lat:61.9257788 },
        { lng:23.3980363	, lat:61.9258498 },
        { lng:23.3981544	, lat:61.9259176 },
        { lng:23.3983294	, lat:61.9258999 },
        { lng:23.3986955	, lat:61.9257396 },
        { lng:23.3987203	, lat:61.9256825 },
        { lng:23.3986801	, lat:61.9255875 },
        { lng:23.3986975	, lat:61.9254613 },
        { lng:23.399655	, lat:61.9250823 },
        { lng:23.3998126	, lat:61.9249232 },
        { lng:23.3998964	, lat:61.9246913 },
        { lng:23.3998897	, lat:61.9244609 },
        { lng:23.3999454	, lat:61.9244161 },
        { lng:23.4000627	, lat:61.9243839 },
        { lng:23.4002505	, lat:61.924377 },
        { lng:23.400793	, lat:61.9244483 },
        { lng:23.4013053	, lat:61.9243883 },
        { lng:23.4017787	, lat:61.9242968 },
        { lng:23.402	, lat:61.9243287 },
        { lng:23.4021609	, lat:61.9243981 },
        { lng:23.4025874	, lat:61.9243397 },
        { lng:23.4030588	, lat:61.9242309 },
        { lng:23.4034068	, lat:61.9241873 },
        { lng:23.4037152	, lat:61.9241327 },
        { lng:23.4040975	, lat:61.9241396 },
        { lng:23.4044073	, lat:61.9241873 },
        { lng:23.4045655	, lat:61.9241355 },
        { lng:23.4046842	, lat:61.9239386 },
        { lng:23.4049913	, lat:61.9237287 },
        { lng:23.405568	, lat:61.9236271 },
        { lng:23.4062365	, lat:61.9235769 },
        { lng:23.4066053	, lat:61.9234466 },
        { lng:23.4072491	, lat:61.9231395 },
        { lng:23.4074502	, lat:61.9231143 },
        { lng:23.4078546	, lat:61.9231944 },
        { lng:23.4084192	, lat:61.9231402 },
        { lng:23.409022	, lat:61.9230818 },
        { lng:23.4096818	, lat:61.9230045 },
        { lng:23.4099018	, lat:61.9229694 },
        { lng:23.4101398	, lat:61.9228706 },
        { lng:23.4101673	, lat:61.9227965 },
        { lng:23.4100989	, lat:61.9227292 },
        { lng:23.4099816	, lat:61.9226084 },
        { lng:23.409995	, lat:61.9225421 },
        { lng:23.4102216	, lat:61.9224471 },
        { lng:23.4109337	, lat:61.9223117 },
        { lng:23.4111979	, lat:61.9223385 },
        { lng:23.4117558	, lat:61.9222085 },
        { lng:23.4123459	, lat:61.9220703 },
        { lng:23.4127275	, lat:61.9219683 },
        { lng:23.4131459	, lat:61.9219822 },
        { lng:23.413632	, lat:61.9217269 },
        { lng:23.4138533	, lat:61.9216252 },
        { lng:23.4141638	, lat:61.9215899 },
        { lng:23.4150026	, lat:61.9215514 },
        { lng:23.4157966	, lat:61.9215343 },
        { lng:23.4162291	, lat:61.9214845 },
        { lng:23.4167488	, lat:61.9213144 },
        { lng:23.4173891	, lat:61.9210795 },
        { lng:23.4176419	, lat:61.9211007 },
        { lng:23.4181335	, lat:61.9208491 },
        { lng:23.4186189	, lat:61.9208141 },
        { lng:23.4194296	, lat:61.9207592 },
        { lng:23.4199855	, lat:61.9205635 },
        { lng:23.4201056	, lat:61.9205439 },
        { lng:23.4202055	, lat:61.9205332 },
        { lng:23.4202879	, lat:61.9205588 },
        { lng:23.4203469	, lat:61.9206197 },
        { lng:23.4204623	, lat:61.9206181 },
        { lng:23.4206541	, lat:61.9206402 },
        { lng:23.4207272	, lat:61.9206939 },
        { lng:23.4208552	, lat:61.9207065 },
        { lng:23.4211315	, lat:61.9206269 },
        { lng:23.4214332	, lat:61.9206026 },
        { lng:23.4218523	, lat:61.9204915 },
        { lng:23.4222144	, lat:61.9203334 },
        { lng:23.4221167	, lat:61.920139 },
        { lng:23.4220421	, lat:61.9198997 },
        { lng:23.422138	, lat:61.9196677 },
        { lng:23.4224163	, lat:61.9194452 },
        { lng:23.4227113	, lat:61.9193395 },
        { lng:23.4231385	, lat:61.9191848 },
        { lng:23.4235757	, lat:61.9191877 },
        { lng:23.4238814	, lat:61.9190829 },
        { lng:23.424159	, lat:61.9190557 },
        { lng:23.4242911	, lat:61.9190996 },
        { lng:23.4245144	, lat:61.9190838 },
        { lng:23.4245292	, lat:61.9190017 },
        { lng:23.4248048	, lat:61.9190122 },
        { lng:23.4254311	, lat:61.9190914 },
        { lng:23.4256651	, lat:61.9191473 },
        { lng:23.4258904	, lat:61.9191097 },
        { lng:23.4259796	, lat:61.9190421 },
        { lng:23.4259803	, lat:61.9189497 },
        { lng:23.4260185	, lat:61.9187767 },
        { lng:23.4261405	, lat:61.9187555 },
        { lng:23.4264061	, lat:61.9187653 },
        { lng:23.4266703	, lat:61.9187666 },
        { lng:23.4269043	, lat:61.9186852 },
        { lng:23.4272114	, lat:61.9185131 },
        { lng:23.4276177	, lat:61.9180772 },
        { lng:23.4278585	, lat:61.9177719 },
        { lng:23.4282192	, lat:61.917578 },
        { lng:23.4289776	, lat:61.9171195 },
        { lng:23.4284197	, lat:61.9169629 },
        { lng:23.4296106	, lat:61.9147721 },
        { lng:23.4314313	, lat:61.9151027 },
        { lng:23.4314791	, lat:61.9151229 },
        { lng:23.4327006	, lat:61.9153466 },
        { lng:23.4329956	, lat:61.9154427 },
        { lng:23.4333738	, lat:61.9154477 },
        { lng:23.4338728	, lat:61.9154426 },
        { lng:23.434605	, lat:61.9154716 },
        { lng:23.434825	, lat:61.9155688 },
        { lng:23.435068	, lat:61.915684 },
        { lng:23.435255	, lat:61.9157475 },
        { lng:23.4358775	, lat:61.9159162 },
        { lng:23.4362548	, lat:61.9160638 },
        { lng:23.4361546	, lat:61.9161248 },
        { lng:23.4361909	, lat:61.9162046 },
        { lng:23.4362341	, lat:61.9162862 },
        { lng:23.4363587	, lat:61.916276 },
        { lng:23.4363408	, lat:61.91645 },
        { lng:23.436539	, lat:61.9165474 },
        { lng:23.4364976	, lat:61.9166146 },
        { lng:23.4364039	, lat:61.9166091 },
        { lng:23.43642	, lat:61.916665 },
        { lng:23.4364654	, lat:61.9167235 },
        { lng:23.4365825	, lat:61.9167243 },
        { lng:23.4367027	, lat:61.9166774 },
        { lng:23.436877	, lat:61.9167293 },
        { lng:23.4371538	, lat:61.9167435 },
        { lng:23.4374264	, lat:61.9168833 },
        { lng:23.4376904	, lat:61.9169516 },
        { lng:23.4381351	, lat:61.9170214 },
        { lng:23.4382356	, lat:61.9170565 },
        { lng:23.4383064	, lat:61.9171138 },
        { lng:23.4382715	, lat:61.9171579 },
        { lng:23.4382451	, lat:61.917305 },
        { lng:23.4383115	, lat:61.9173303 },
        { lng:23.438454	, lat:61.9173648 },
        { lng:23.4386004	, lat:61.9173747 },
        { lng:23.4389665	, lat:61.9173499 },
        { lng:23.4392606	, lat:61.9173121 },
        { lng:23.4395336	, lat:61.9172155 },
        { lng:23.4397156	, lat:61.9171342 },
        { lng:23.4398855	, lat:61.9171081 },
        { lng:23.4401166	, lat:61.9171287 },
        { lng:23.4406033	, lat:61.917221 },
        { lng:23.4408531	, lat:61.9172575 },
        { lng:23.4411094	, lat:61.9172637 },
        { lng:23.4413209	, lat:61.9172283 },
        { lng:23.4414741	, lat:61.9172059 },
        { lng:23.4416943	, lat:61.9171607 },
        { lng:23.4417839	, lat:61.917171 },
        { lng:23.4419516	, lat:61.9172659 },
        { lng:23.4420568	, lat:61.917291 },
        { lng:23.4421644	, lat:61.9173053 },
        { lng:23.442364	, lat:61.9172908 },
        { lng:23.4427284	, lat:61.9172298 },
        { lng:23.4429808	, lat:61.9169244 },
        { lng:23.4430184	, lat:61.9166333 },
        { lng:23.4427722	, lat:61.9164664 },
        { lng:23.442769	, lat:61.916364 },
        { lng:23.4428259	, lat:61.9162423 },
        { lng:23.4430163	, lat:61.9161779 },
        { lng:23.4438129	, lat:61.9161469 },
        { lng:23.4442977	, lat:61.9161226 },
        { lng:23.4445086	, lat:61.9160676 },
        { lng:23.4448517	, lat:61.9159374 },
        { lng:23.4453592	, lat:61.9155781 },
        { lng:23.445759	, lat:61.9154224 },
        { lng:23.4462847	, lat:61.9152854 },
        { lng:23.4468587	, lat:61.9151515 },
        { lng:23.4475171	, lat:61.9148845 },
        { lng:23.4475467	, lat:61.9145732 },
        { lng:23.4474662	, lat:61.9142929 },
        { lng:23.4474675	, lat:61.9139892 },
        { lng:23.4477344	, lat:61.9136956 },
        { lng:23.4478551	, lat:61.9132132 },
        { lng:23.4482654	, lat:61.9126368 },
        { lng:23.44756	, lat:61.912484 },
        { lng:23.4502905	, lat:61.9096033 },
        { lng:23.4513657	, lat:61.9096762 },
        { lng:23.446629	, lat:61.9065871 },
        { lng:23.4463092	, lat:61.9043899 },
        { lng:23.4453737	, lat:61.9026778 },
        { lng:23.4442831	, lat:61.901517 },
        { lng:23.4449279	, lat:61.9008347 },
        { lng:23.4459494	, lat:61.90074 },
        { lng:23.4470088	, lat:61.8998399 },
        { lng:23.4554977	, lat:61.9002874 },
        { lng:23.4582944	, lat:61.8883034 },
        { lng:23.4558829	, lat:61.8881811 },
        { lng:23.4575073	, lat:61.8811573 },
        { lng:23.4514702	, lat:61.8808377 },
        { lng:23.4483887	, lat:61.8735871 },
        { lng:23.4651355	, lat:61.8746134 },
        { lng:23.4664166	, lat:61.870891 },
        { lng:23.4675805	, lat:61.8696908 },
        { lng:23.4954707	, lat:61.8755863 },
        { lng:23.4971937	, lat:61.8646295 },
        { lng:23.5131682	, lat:61.8634652 },
        { lng:23.5350933	, lat:61.8687196 },
        { lng:23.5376821	, lat:61.8645377 },
        { lng:23.5147032	, lat:61.8591681 },
        { lng:23.5202642	, lat:61.8556333 },
        { lng:23.5217954	, lat:61.853639 },
        { lng:23.5221507	, lat:61.8533653 },
        { lng:23.5843634	, lat:61.8570731 },
        { lng:23.5843969	, lat:61.8559593 },
        { lng:23.5921339	, lat:61.8559909 },
        { lng:23.5933727	, lat:61.8225203 },
        { lng:23.5938585	, lat:61.8193778 },
        { lng:23.5270494	, lat:61.814365 },
        { lng:23.5124467	, lat:61.8123701 },
        { lng:23.5095702	, lat:61.8125174 },
        { lng:23.5066803	, lat:61.8137486 },
        { lng:23.5012139	, lat:61.8131242 },
        { lng:23.5008792	, lat:61.8133743 },
        { lng:23.4578585	, lat:61.8168842 },
        { lng:23.4499643	, lat:61.8120487 },
        { lng:23.4479489	, lat:61.8114843 },
        { lng:23.4463203	, lat:61.8103714 },
        { lng:23.4179864	, lat:61.8112096 },
        { lng:23.4148035	, lat:61.8063466 },
        { lng:23.3904469	, lat:61.7955788 },
        { lng:23.3706074	, lat:61.7878453 },
        { lng:23.3603022	, lat:61.7735094 },
        { lng:23.3621969	, lat:61.7714033 },
        { lng:23.361862	, lat:61.771324 },
        { lng:23.3596347	, lat:61.7729954 },
        { lng:23.3589733	, lat:61.7737201 },
        { lng:23.359169	, lat:61.7738018 },
        { lng:23.3586065	, lat:61.7741126 },
        { lng:23.3582431	, lat:61.7745209 },
        { lng:23.357995	, lat:61.7744681 },
        { lng:23.3559592	, lat:61.7757552 },
        { lng:23.3560642	, lat:61.7759126 },
        { lng:23.3550588	, lat:61.7766065 },
        { lng:23.3550923	, lat:61.7770778 },
        { lng:23.3553088	, lat:61.7771657 },
        { lng:23.3549299	, lat:61.7784817 },
        { lng:23.3544207	, lat:61.7787393 },
        { lng:23.3526089	, lat:61.7780841 },
        { lng:23.3502997	, lat:61.7789352 },
        { lng:23.3513773	, lat:61.7794224 },
        { lng:23.3496328	, lat:61.7801663 },
        { lng:23.3490216	, lat:61.7806969 },
        { lng:23.3475677	, lat:61.7815263 },
        { lng:23.3430679	, lat:61.7835392 },
        { lng:23.3363859	, lat:61.784735 },
        { lng:23.3355568	, lat:61.7847171 },
        { lng:23.3352222	, lat:61.7846205 },
        { lng:23.3350446	, lat:61.7844232 },
        { lng:23.3352194	, lat:61.7843938 },
        { lng:23.3188972	, lat:61.768158 },
        { lng:23.3428585	, lat:61.7650885 },
        { lng:23.3440727	, lat:61.7651915 },
        { lng:23.3485357	, lat:61.7613211 },
        { lng:23.3512655	, lat:61.7602593 },
        { lng:23.3302706	, lat:61.7531554 },
        { lng:23.3105167	, lat:61.7460773 },
        { lng:23.3041028	, lat:61.7445235 },
        { lng:23.3034503	, lat:61.7447898 },
        { lng:23.302871	, lat:61.7452164 },
        { lng:23.2997703	, lat:61.745384 },
        { lng:23.2967663	, lat:61.7465115 },
        { lng:23.2964659	, lat:61.7469178 },
        { lng:23.2956076	, lat:61.7473596 },
        { lng:23.2949853	, lat:61.7478167 },
        { lng:23.2943362	, lat:61.7480706 },
        { lng:23.2940411	, lat:61.7484464 },
        { lng:23.2916057	, lat:61.7490965 },
        { lng:23.2903719	, lat:61.7489086 },
        { lng:23.2886982	, lat:61.7488679 },
        { lng:23.2823789	, lat:61.7490558 },
        { lng:23.2817566	, lat:61.7495281 },
        { lng:23.2803619	, lat:61.7500816 },
        { lng:23.2796538	, lat:61.7507773 },
        { lng:23.279643	, lat:61.7511227 },
        { lng:23.2785058	, lat:61.7517929 },
        { lng:23.2771432	, lat:61.7522246 },
        { lng:23.2739031	, lat:61.7521535 },
        { lng:23.2592748	, lat:61.7568848 },
        { lng:23.2102483	, lat:61.7344109 },
      ],
    },
  ] 
}