/* eslint-disable global-require */
import React, { Component } from 'react';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { Button, Input } from '@postidigital/posti-components';
import LocalizedStrings from 'react-localization';
import { CommonService } from '../../service/CommonService.js';
import Toastr from '../../shared/Toastr';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants';
import './MultiContactEdit.css';
import { translation } from '../../locales/translation';

const translator = new LocalizedStrings(translation);
class MultiContactEdit extends Component {
  constructor(props) {
    super(props);
    const { data, selectedCostCenter }= this.props
    this.state = {
      selectedContact: data,
      selectedContactCopy: data,
      currentEditIndex: 0,
      inputName: data[ 0 ].name,
      inputPhoneNumber: data[ 0 ].contactNumber,
      inputRole: data[ 0 ].role,
      inputColor: data[ 0 ].color,
      updateDialogFlag: false,
      selectedCostCenter: selectedCostCenter.costCenterId,
      totalColors: [
        '#FFC226',
        '#EDBDBD',
        '#FFA894',
        '#A4DEDF',
        '#0051C2',
        '#008A00',
        '#29545C',
        '#C2C9D1',
      ],
    };
    // console.log('------------------>', localStorage.getItem('adal.idtoken')  );
    this.commonService = new CommonService();
  }

  handleCallback = (data) => {
    const { parentCallback } = this.props
    parentCallback(data);
  };

  multiRightSecNextClicked = () => {
    const { currentEditIndex, selectedContactCopy } = this.state
    this.setState({
      currentEditIndex: currentEditIndex + 1,
      inputName:
        selectedContactCopy[ currentEditIndex + 1 ].name,
      inputPhoneNumber:
        selectedContactCopy[ currentEditIndex + 1 ]
          .contactNumber,
      inputRole:
        selectedContactCopy[ currentEditIndex + 1 ].role,
      inputColor:
        selectedContactCopy[ currentEditIndex + 1 ].color,
    });
  };

  multiRightSecBackClicked = () => {
    const { currentEditIndex, selectedContactCopy } = this.state
    this.setState({
      currentEditIndex: currentEditIndex - 1,
      inputName:
        selectedContactCopy[ currentEditIndex - 1 ].name,
      inputPhoneNumber:
        selectedContactCopy[ currentEditIndex - 1 ]
          .contactNumber,
      inputRole:
        selectedContactCopy[ currentEditIndex - 1 ].role,
      inputColor:
        selectedContactCopy[ currentEditIndex - 1 ].color,
    });
  };

  updateButtonClicked = () => {
    const { selectedContactCopy, selectedCostCenter } = this.state
    selectedContactCopy.map((data) => {
      const jsonBody = getJsonBody();
      const subpayload = {
        AppId: jsonBody.applicationId,
        CostCenterId: selectedCostCenter,
        EmployeeId: jsonBody.masterGroupSourceSystemId,
        id: data.id,
        name: data.name,
        contactNumber: String(data.contactNumber),
        isHidden: data.isHidden,
        isCustomContact: data.isCustomContact,
        role: data.role,
        color: data.color,
        orderNumber: data.orderNumber,
        IsSupervisor: true,
      };
      const payload = [];
      payload.push(subpayload);
      const { parentCallback } = this.props
      this.commonService
        .postMethod(apiUrl.saveContact, payload)
        .then(() => {
          Toastr.showSuccessMessage('Contacts updated');
          parentCallback(false);
        })
        .catch(() => {
          Toastr.showErrorMessage('VIP Contact API Error');
          parentCallback(false);
        });
      return null;
    });
  };

  render() {
    const { browserLanguage, data } = this.props
    const { updateDialogFlag, selectedContact, currentEditIndex, inputName, inputPhoneNumber, inputRole, totalColors, inputColor, selectedContactCopy } = this.state
    translator.setLanguage(browserLanguage);
    return (
      updateDialogFlag === false ? (
        <Dialog
          visible
          style = { { width: '1005px', height: '820px' } }
          showHeader = { false }
          id = "editMultiContactDialog"
        >
          <div className = "multiSelectWholeContainer">
            <div className = "multiSelectLeftContainer">
              <div className = "multiSelectLeftHeaderContainer">
                <div className = "multiSelectLeftHeader">
                  {translator.EditDialog.vipContact}
                </div>
              </div>
              <div className = "multiSelectBodyContainer">
                <div className = "multiSelectBodyHeader">
                  {translator.EditDialog.multiEditText}
                </div>
                <div className = "multiSelectBody">
                  {selectedContact.map((dataRow, index) => (
                    <div className = "circleNameStatusContainer" key = { dataRow.index }>
                      <div
                        className = "multiSelectCircle"
                        style = { { backgroundColor: `${ dataRow.color }` } }
                      />
                      <div className = "multiSelectName">{dataRow.name}</div>
                      {currentEditIndex === index ? (
                        <div className = "multiSelectStatusEditing">
                          {translator.EditDialog.editingCurrentlyText}
                        </div>
                      ) : currentEditIndex > index ? (
                        <div className = "multiSelectStatusEdited">
                          {translator.EditDialog.editedText}
                          <img
                            src = { require('../../images/tick.png') }
                            style = { { marginLeft: '20px' } }
                            alt = "not found"
                          />
                        </div>
                      ) : (
                        <div className = "multiSelectStatusToBeEdited">
                          {translator.EditDialog.toBeEditedText}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div
                style = { {
                  border: '1px solid #C2C9D1',
                  backgroundColor: '#C2C9D1',
                  margin: '2px 32px',
                } }
              />
            </div>
            <div className = "multiSelectMiddleContainer" />
            <div className = "multiSelectRightContainer">
              <div className = "multiSelectRightHeaderContainer">
                <div className = "multiSelectRightHeader">
                  {' '}
                  {translator.EditDialog.contactDetails}
                </div>
              </div>
              <div className = "rightSectionWholeContainer">
                <div className = "rightSectionNameHeading">
                  {translator.EditDialog.name}
                </div>
                <div className = "rightSectionNameInputContainer">
                  <Input
                    value = { inputName }
                    onChange = { (e) => {
                      selectedContactCopy[ currentEditIndex ].name =
                          e.target.value;
                      this.setState({
                        inputName: e.target.value,
                        selectedContactCopy,
                      });
                    } }
                  />
                </div>
                <div className = "rightSectionNumberHeading">
                  {' '}
                  {translator.EditDialog.phoneNumber}
                </div>
                <div className = "rightSectionNumberInputContainer">
                  <Input
                    value = { inputPhoneNumber }
                    onChange = { (e) => {
                      selectedContactCopy[
                        currentEditIndex
                      ].contactNumber = e.target.value;
                      this.setState({
                        inputPhoneNumber: e.target.value,
                        selectedContactCopy,
                      });
                    } }
                  />
                </div>
                <div className = "rightSectionRoleHeading">
                  {' '}
                  {translator.EditDialog.role}
                </div>
                <div className = "rightSectionRoleInputContainer">
                  <Input
                    value = { inputRole }
                    onChange = { (e) => {
                      selectedContactCopy[ currentEditIndex ].role =
                          e.target.value;
                      this.setState({
                        inputRole: e.target.value,
                        selectedContactCopy,
                      });
                    } }
                  />
                </div>
                <div className = "rightSectionColorHeading">
                  {translator.EditDialog.colourIndicationText}
                </div>
                <div className = "multiEditContactColorContainer">
                  {totalColors.map((color) => (
                    <div
                      aria-hidden = "true"
                      key = { color.index }
                      onClick = { () => {
                        selectedContactCopy[
                          currentEditIndex
                        ].color = color;
                        this.setState({
                          inputColor: color,
                          selectedContactCopy,
                        });
                      } }
                    >
                      <div
                        style = { {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          backgroundColor:
                                inputColor === color
                                  ? `#0051C2`
                                  : 'white',
                        } }
                      >
                        <div
                          style = { {
                            width: '34px',
                            height: '34px',
                            borderRadius: '50%',
                            backgroundColor: `${ color }`,
                            border: '1px solid white',
                          } }
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className = "multiEditContactRightSideFooter">
                  <div
                    style = { {
                      border: '1px solid #C2C9D1',
                      backgroundColor: '#C2C9D1',
                      margin: '0px 32px',
                    } }
                  />
                  <div className = "multiEditContactRightSideFooterButtonsContainer">
                    {currentEditIndex > 0 ? (
                      <button
                        type = "button"
                        className = "multiEditContactRightSideBackButton"
                        onClick = { () => {
                          this.multiRightSecBackClicked();
                        } }
                      >
                        {translator.EditDialog.back}
                      </button>
                    ) : (
                      <button
                        type = "button"
                        className = "multiEditContactRightSideCancelButton"
                        onClick = { () => {
                          this.handleCallback(false);
                        } }
                      >
                        {translator.EditDialog.cancel}
                      </button>
                    )}

                    {currentEditIndex <
                      data.length - 1 ? (
                        <Button
                          className = "multiEditContactRightSideNextButton"
                          onClick = { () => this.multiRightSecNextClicked() }
                          style = { { height: '36.5px' } }
                        >
                          <div style = { { fontSize: '16px' } }>
                            {translator.EditDialog.next}
                          </div>
                        </Button>
                      ) : (
                        <Button
                          className = "multiEditContactRightSideNextButton"
                          onClick = { () => {
                            this.setState({
                              updateDialogFlag: true,
                            });
                          } }
                          style = { { height: '36.5px' } }
                        >
                          <div style = { { fontSize: '16px' } }>
                            {translator.EditDialog.save}
                          </div>
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <Dialog
          visible
          style = { { width: '562px', height: '820px' } }
          showHeader = { false }
          id = "editMultiContactUpdateDialog"
        >
          <div className = "multiUpdateDialogHeaderWrapper">
            <div className = "multiUpdateDialogHeader">
              {translator.EditDialog.vipContact}
            </div>
          </div>
          <div className = "multiUpdateDialogBodyWrapper">
            <div className = "multiUpdateDialogBodyHeader">
              {translator.EditDialog.multiEditText}
            </div>
            <div className = "multiUpdateDialogBody">
              {selectedContactCopy.map((dataRow) => (
                <div className = "circleNameStatusContainer2" key = { dataRow.index }>
                  <div
                    className = "multiSelectCircle2"
                    style = { { backgroundColor: `${ dataRow.color }` } }
                  />
                  <div className = "multiSelectName2">{dataRow.name}</div>
                  <div className = "multiSelectStatusEdited2">
                    {translator.EditDialog.editedText}
                    <img
                      src = { require('../../images/tick.png') }
                      style = { { marginLeft: '20px' } }
                      alt = "not found"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className = "editMultiContactUpdateDialogFooter">
            <div
              style = { {
                border: '1px solid #C2C9D1',
                backgroundColor: '#C2C9D1',
                margin: '0px 32px',
                width: '88%',
              } }
            />
            <div className = "UpdateDialogFooterButtonsWrapper">
              <div>
                <button
                  type = "button"
                  className = "UpdateDialogFooterCancelButton"
                  onClick = { () => {
                    this.handleCallback(false);
                  } }
                >
                  <div style = { { fontSize: '16px' } }>
                    {translator.EditDialog.cancel}
                  </div>
                </button>
              </div>
              <div className = "UpdateDialogFooterEditUpdate">
                <button
                  type = "button"
                  className = "UpdateDialogFooterEditButton"
                  onClick = { () => {
                    this.setState({
                      updateDialogFlag: false,
                    });
                  } }
                >
                  <div style = { { fontSize: '16px' } }>
                    {translator.EditDialog.edit}
                  </div>
                </button>
                <Button
                  className = "UpdateDialogFooterUpdateButton"
                  onClick = { () => {
                    this.updateButtonClicked();
                  } }
                  style = { { height: '36.5px' } }
                >
                  <div style = { { fontSize: '16px' } }>
                    {' '}
                    {translator.EditDialog.update}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MultiContactEdit);
