import React, { Component } from 'react';
import './PeopleRightSection.css';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { Carousel } from 'primereact/carousel';
import moment from 'moment';
import { Button } from '@postidigital/posti-components';
import { translation } from '../../locales/translation.js';
import Avatar from '../../components/Avatar/Avatar.js';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import Champs from '../NewTeam/Champs/Champs';
import NewTeam from '../NewTeam/NewTeam';
import EditTeamScreen from '../EditTeam/EditTeam';
import apiUrl from '../../urlConstants.js';
import Toastr from '../../shared/Toastr';
import DeleteTeamScreen from '../DeleteTeam/DeleteTeam';

const translator = new LocalizedStrings(translation);
class PeopleRightSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Indicator that shows whether response from API is there of not
      responseAvailable: 0,
      // Actual json that will we get
      actualJsonResponse: null,
      // For Carousel
      teams: null,
      // When team name is clicked on Carousel it will get store here
      clickedTeam: null,
      // For team information
      teamFormed: null,
      teamMembers: null,
      teamPoints: null,

      currentIndex: null,
      userList: [],
      formedDate: '',
      teamImg: '',
      TeamDetails: {},
      champsBox: false,
      editTeamDialog: false,
      newTeamDialog: false,
      teamId: '',
      flag: false,
      resLength: 0,
      informationTextFlag: true,
      deleteTeamDialog: false,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getRightSidePeopleScreenData();
    }, 2500);
  }
  
  componentDidUpdate(prevProps) {
    const { counter , selectedCostCenter }= this.props;
    if (
      prevProps.counter !== counter ||
      (prevProps.selectedCostCenter.costCenterId !== selectedCostCenter.costCenterId &&
        prevProps.selectedCostCenter.costCenterName !== '')
    ) {
      this.getRightSidePeopleScreenData();
    }
  }

  // Templet for Carousel
  carouselTemplate  = (team) => {
    const { teams,currentIndex } = this.state;
    return (
      <div className = { teams.length === 2 ? 'product-itemForTwo' : 'product-item' }>
        <div className = "product-item-content">
          <table>
            <tbody>
              <tr key = { team.teamId }>
                <td
                  aria-hidden = "true"
                  id = "tdContainer"
                  // tabIndex = "1"
                  style = { {
                    cursor: 'pointer',
                  } }
                  onClick = { () => {
                  // Date formatter
                    const date1 = moment(team.teamFormedDate).format('DD.MM.YYYY');
                    const endDate = this.dateFormatter(team.teamFormedDate);
                    this.setState({
                      teamId: team.teamId,
                      teamImg: team.teamImage,
                      clickedTeam: team.teamName,
                      formedDate: date1,
                      userList: team.userList,
                      teamFormed: endDate,
                      teamMembers: team.numberOfUsers,
                      teamPoints: team.totalPoints,
                      responseAvailable: 1,
                      currentIndex: team,
                    });
                  } }
                >
                  <Avatar
                    imageSource = { team.teamImage }
                    name = { team.teamName }
                    avatarColor = "#FFA894"
                    size = { 66 }
                    fontSize = "24px"
                  />
                  <div className = "carouselTeamNameContainer">
                    <p className = "carouselTeamName">{team.teamName}</p>
                    <hr
                      className = {
                        currentIndex === team
                          ? 'carouselTeamNameLine'
                          : 'carouselTeamNameLineHidden'
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    )}

  // To convert the date into proper format
  dateFormatter = (date) => {
    const year = date.split('-')[ 0 ];
    const month = date.split('-')[ 1 ];
    const tempDay = date.split('-')[ 2 ];
    const day = tempDay.split('T')[ 0 ];
    const endResultDate = `${ day }.${ month }.${ year }`;
    return endResultDate;
  };

  // Api call for people right side section
  getRightSidePeopleScreenData = () => {
    const jsonBody = getJsonBody();
    const { parentProps }= this.props;
    jsonBody.entityCode = parentProps.browserLanguage;
    jsonBody.costCenterId = parentProps.selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.getTeamsInACostCenter, jsonBody)
      .then((res) => {
        if (res.data.length > 0) {
          this.setState({ flag: true, resLength: res.data.length });
        } else {
          this.setState({ flag: false, resLength: res.data.length });
        }
        if (res.data !== null && res.data[ 0 ] !== undefined) {
          // Date formatter
          const date1 = moment(res.data[ 0 ].teamFormedDate).format('DD.MM.YYYY');

          const endDate = this.dateFormatter(res.data[ 0 ].teamFormedDate);
          this.setState({
            clickedTeam: res.data[ 0 ].teamName,
            teamFormed: endDate,
            teamMembers: res.data[ 0 ].numberOfUsers,
            teamPoints: res.data[ 0 ].totalPoints,
            responseAvailable: 1,
            formedDate: date1,
            teamImg: res.data[ 0 ].teamImage,
            userList: res.data[ 0 ].userList,
            teamId: res.data[ 0 ].teamId,
            actualJsonResponse: res.data,
            teams: res.data,
            currentIndex: res.data[ 0 ],
          });
        }
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  };

  dataLoad = () => {
    this.setState({ informationTextFlag: false });
    const newarr = [];
    const { userList ,clickedTeam ,teamImg,teamPoints,formedDate,teamId }= this.state;
    for (let i = 0; i < userList.length; i+=1) {
      const row = userList[ i ].userSourceSystemId;
      newarr.push(row);
    }
    const data = {
      Name: clickedTeam,
      Details: userList,
      TeamImage: teamImg,
      EngagementLevel: '100%',
      PointsTotal: teamPoints,
      selectedMembers: newarr,
      joiningDate: formedDate,
      teamId,
    };
    this.setState({ TeamDetails: data, champsBox: true });
  };

  render() {
    const { browserLanguage,remount }= this.props;
    const { deleteTeamDialog,responseAvailable,actualJsonResponse,clickedTeam ,teamFormed,teamMembers,teamPoints, TeamDetails,teams,resLength,flag , newTeamDialog,editTeamDialog ,champsBox ,informationTextFlag }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        {deleteTeamDialog && (
          <DeleteTeamScreen
            TeamDetails = { TeamDetails }
            OnCancel = { () => {
              this.setState({ deleteTeamDialog: false });
            } }
            OnDelete = { () => {
              remount();
              this.getRightSidePeopleScreenData();
              this.setState({
                newTeamDialog: false,
                champsBox: false,
                deleteTeamDialog: false,
              });
            } }
          />
        )}
        {newTeamDialog && (
          <NewTeam
            TeamDetails = { (e) => {
              this.setState({ TeamDetails: e });
            } }
            Dialog
            OnHide = { (e) => {
              if (e === 'cancel') {
                this.setState({
                  champsBox: false,
                  newTeamDialog: false,
                });
              } else {
                remount();
                this.getRightSidePeopleScreenData();
                this.setState({
                  champsBox: true,
                  newTeamDialog: false,
                });
              }
            } }
          />
        )}
        {champsBox && (
          <Champs
            informationTextFlag = { informationTextFlag }
            TeamDetails = { TeamDetails }
            OnHide = { () => this.setState({ newTeamDialog: false, champsBox: false }) }
            OnDelete = { () => {
              this.setState({
                newTeamDialog: false,
                champsBox: false,
                deleteTeamDialog: true,
              });
            } }
            OnEdit = { () => {
              this.setState({
                newTeamDialog: false,
                champsBox: false,
                editTeamDialog: true,
              });
            } }
          />
        )}

        {editTeamDialog && (
          <EditTeamScreen
            ResetTeamDetails = { (e) => {
              this.setState({ TeamDetails: e });
              this.getRightSidePeopleScreenData();
            } }
            TeamDetails = { TeamDetails }
            Dialog
            OnHide = { (e) => {
              if (e === 'cancel') {
                this.setState({
                  newTeamDialog: false,
                  champsBox: false,
                  editTeamDialog: false,
                });
              } else {
                this.setState({
                  newTeamDialog: false,
                  champsBox: true,
                  editTeamDialog: false,
                });
                remount();
                this.getRightSidePeopleScreenData();
              }
            } }
          />
        )}

        <div className = "rightSideHeaderContainer">
          <div className = "rightSideHeader">{translator.PeopleRightScreen.teamsMainText}</div>
          <div className = "rightSideHeaderTeams">
            {`${ resLength } ${ translator.PeopleRightScreen.teamsSideText }`}
          </div>
        </div>
        {flag && (
          <div className = "rightSideproductsCarousel">
            <div className = "card">
              {teams != null ? (
                teams.length > 1 ? (
                  <Carousel
                    value = { teams }
                    numVisible = { 3 }
                    numScroll = { 1 }
                    className = "custom-carousel"
                    itemTemplate = { this.carouselTemplate }
                    indicatorsContentClassName = "carouselIndicator"
                  />
                ) : (
                  <div
                    aria-hidden = "true"
                    className = "forOneTeam"
                    onClick = { () => {
                      // Date formatter
                      const date1 = moment(teams[ 0 ].teamFormedDate).format('DD.MM.YYYY');
                      const endDate = this.dateFormatter(teams[ 0 ].teamFormedDate);
                      this.setState({
                        teamId: teams[ 0 ].teamId,
                        teamImg: teams[ 0 ].teamImage,
                        clickedTeam: teams[ 0 ].teamName,
                        formedDate: date1,
                        userList: teams[ 0 ].userList,
                        teamFormed: endDate,
                        teamMembers: teams[ 0 ].numberOfUsers,
                        teamPoints: teams[ 0 ].totalPoints,
                        responseAvailable: 1,
                        currentIndex: teams[ 0 ],
                      });
                    } }
                  >
                    <div className = "forOneTeamAvatar">
                      <Avatar
                        imageSource = { teams[ 0 ].teamImage }
                        name = { teams[ 0 ].teamName }
                        avatarColor = "#FFA894"
                        size = { 65 }
                        fontSize = "24px"
                      />
                    </div>
                    <div className = "forOneTeamNameAndHR">
                      <p className = "forOneTeamTeamName">{teams[ 0 ].teamName}</p>
                      <hr className = "forOneTeamHR" />
                    </div>
                  </div>
                )
              ) : null}
            </div>
          </div>
        )}
        {flag && (
          <div className = "rightSideInformationContainer">
            <div className = "rightSideInformationHeader">
              {translator.PeopleRightScreen.informationText}
            </div>
            <div className = "rightSideInformationBody">
              <div className = "rightSideInformationBody1stColumn">
                {translator.PeopleRightScreen.formedText}
              </div>
              <div className = "rightSideInformationBody2ndColumn">{teamFormed}</div>
              <div className = "rightSideInformationBody1stColumn">
                {translator.PeopleRightScreen.membersText}
              </div>
              <div className = "rightSideInformationBody2ndColumn">{teamMembers}</div>
              <div className = "rightSideInformationBody1stColumn">
                {translator.PeopleRightScreen.pointsTotal}
              </div>
              <div className = "rightSideInformationBody2ndColumn">{teamPoints}</div>
            </div>
          </div>
        )}
        <div className = "rightSideTeamMembersContainer">
          {flag && (
            <div>
              <div className = "rightSideTeamMembersHeader">
                {translator.PeopleRightScreen.teamMembersText}
              </div>
              <div className = "rightSideTeamMembers">
                {responseAvailable !== 0
                  ? actualJsonResponse
                    .filter((obj) => obj.teamName === clickedTeam)[ 0 ]
                    .userList.map((item) => (
                      <div key = { item.index } className = "rightSideAvatarAndNameContainer">
                        {flag && (
                          <Avatar
                            imageSource = { item.userImagePath }
                            name = { item.firstName }
                            avatarColor = "#FFA894"
                            size = { 55 }
                            fontSize = "24px"
                          />
                        )}
                        <p className = "teamMemberName">{item.firstName}</p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          )}
        </div>
        <div className = "weatherScreenRightSectionFooter">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div
            style = { {
              height: '8vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            } }
          >
            <button
              type = "submit" 
              disabled = { !flag }
              className = "teamFooterResetButton"
              style = { flag ? { color: '#0051c2' } : { color: '#A7A7A7' } }
              onClick = { () => {
                this.dataLoad();
              } }
            >
              {translator.PeopleScreen.editText}
            </button>
            <Button
              id = "newTeamButton"
              onClick = { () => {
                this.setState({
                  newTeamDialog: true,
                  informationTextFlag: true,
                });
              } }
            >
              {translator.PeopleRightScreen.newTeamText}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleRightSection);
