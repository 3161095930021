/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Button, Loading, Radio } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import './Weather.css';
import moment from 'moment';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService.js';
import { getAppId, getJsonBody, isRolePEC } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';
import WeatherScreenRightSection from './WeatherScreenRightSection/WeatherScreenRightSection.js';
import WeatherNotificationHistory from './WeatherNotificationHistory/WeatherNotificationHistory.js';

const translator = new LocalizedStrings(translation);
class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedBadge: null,
      loading: true,
      openDialog: false,
      forecastData: null,
      hourlyWeatherData: null,
      weatherLogData: [],
      weatherNotificationHistoryData: null,
      resetWeatherHistory: null,
      currentIndexOfHistory: 0,
      badgeCheckArray: [],
      finalBadge: '',
      weatherBadgeDetails: [
        {
          image: require('../../images/brave_warrior.png'),
          disabledImage: require('../../images/brave_warrior_disabled.png'),
          BadgeNameKey: 'braveWarrior',
          BadgeDescription: 'braveWarriorDescription',
          requestBody: isRolePEC() ? 'brave' : 'BRAVE_WARRIOR',
          requestBodyEMD : 'EMD_BRAVE_WARRIOR',
          badgeCode: 'Brave Warrior',
        },
        {
          image: require('../../images/braver.png'),
          disabledImage: require('../../images/braver_disabled.png'),
          BadgeNameKey: 'braverBadge',
          BadgeDescription: 'braverBadgeDescription',
          requestBody: isRolePEC() ? 'braver' : 'EVEN_BRAVER_BADGE',
          requestBodyEMD : 'EMD_EVEN_BRAVER_BADGE',
          badgeCode: 'Even Braver',
        },
        {
          image: require('../../images/bravest.png'),
          disabledImage: require('../../images/bravest_disabled.png'),
          BadgeNameKey: 'bravestBadge',
          BadgeDescription: 'bravestBadgeDescription',
          requestBody: isRolePEC() ? 'bravest' : 'THE_BRAVEST_BADGE',
          requestBodyEMD : 'EMD_THE_BRAVEST_BADGE',
          badgeCode: 'The Bravest',
        },
      ],
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    // const { selectedCostCenter } = this.props
    setTimeout(() => {
      // const { longitude } = selectedCostCenter;
      // const { latitude } = selectedCostCenter;
      // this.getWeather(latitude, longitude);
      // this.getHourlyWeather(latitude, longitude);
      this.getWeatherLog();
      this.getWeatherNotificationHistory();
    }, 2500);
  };

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      // const { longitude } = selectedCostCenter;
      // const { latitude } = selectedCostCenter;
      this.setState({ loading: true, selectedBadge: null });
      // this.getWeather(latitude, longitude);
      // this.getHourlyWeather(latitude, longitude);
      this.getWeatherLog();
      this.getWeatherNotificationHistory();
    }
  }

  // to fetch information of weather
  getWeather(lat, long) {
    this.commonService
      .getWeatherInfo(lat, long)
      .then((data) => {
        if (data) {
          this.setState({ forecastData: data });
        }
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.updateWeather);
      });
  }

  // to fetch hourly information of weather
  getHourlyWeather(lat, long) {
    this.commonService
      .getHourlyWeather(lat, long)
      .then((data) => {
        if (data) {
          this.setState({ hourlyWeatherData: data, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.ToastMessages.updateWeather);
      });
  }

  notifyBadWeather = () => {
    const { selectedCostCenter } = this.props
    const { weatherBadgeDetails, selectedBadge } = this.state 
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.ActivityDate = moment().format('YYYY-MM-DD');
    jsonBody.BadgeAchievementCode = getAppId() === 5 ? weatherBadgeDetails[ selectedBadge ].requestBodyEMD :
      weatherBadgeDetails[ selectedBadge ].requestBody;
    this.setState({ loading: true });
    this.commonService
      .postMethod(apiUrl.informBadWeather, jsonBody)
      .then(() => {
        this.setState(
          {
            loading: false,
            openDialog: false,
            selectedBadge: null,
          },
          () => {
            this.componentDidMount();
          }
        );
        Toastr.showSuccessMessage(translator.ToastMessages.awardedSuccesfully);
      })
      .catch(() => {
        this.setState({
          loading: false,
          openDialog: false,
          selectedBadge: null,
        });
        Toastr.showErrorMessage(
          translator.ToastMessages.weatherNotificationHistory
        );
      });
  };

  getWeatherLog = () => {
    const { selectedCostCenter } = this.props
    const rowarr = [];
    let finalAwardBadge = '';
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.getWeather(selectedCostCenter.latitude, selectedCostCenter.longitude);
    this.getHourlyWeather(selectedCostCenter.latitude, selectedCostCenter.longitude);
    this.commonService
      .postMethod(apiUrl.getWeatherLog, jsonBody)
      .then((res) => {
        this.setState({
          weatherLogData: res.data,
        });
        for (let i = 0; i < res.data.length; i += 1) {
          // console.log(res.data[ i ].achievementCode);
          rowarr.push(res.data[ i ].achievementCode);
        }
        this.setState({ badgeCheckArray: rowarr });
        // rowarr.includes('The Bravest')
        //   ? (finalAwardBadge = 'The Bravest')
        //   : rowarr.includes('Even Braver')
        //     ? (finalAwardBadge = 'Even Braver')
        //     : rowarr.includes('Brave Warrior')
        //       ? (finalAwardBadge = 'Brave Warrior')
        //       : (finalAwardBadge = '');
        if( rowarr.includes('The Bravest') )
        {
          finalAwardBadge = 'The Bravest';
        }
        else if( rowarr.includes('Even Braver') )
        {
          finalAwardBadge = 'Even Braver';
        }
        else if( rowarr.includes('Brave Warrior') )
        {
          finalAwardBadge = 'Brave Warrior';
        }
        else
        {
          finalAwardBadge = ''
        }
        this.setState({ finalBadge: finalAwardBadge }, () => {
          // console.log(this.state.finalBadge, '======');
        });
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.weatherLogFail);
      });
  };

  getWeatherNotificationHistory = () => {
    const { selectedCostCenter } = this.props
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.getWeatherNotificationHistory, jsonBody)
      .then((res) => {
        this.setState({
          weatherNotificationHistoryData: res.data,
        });
      })
      .catch(() => {
        Toastr.showErrorMessage(
          translator.ToastMessages.weatherNotificationHistory
        );
      });
  };

  conditionChecker = (badgeCode) => {
    const { badgeCheckArray } = this.state
    if (badgeCode === 'Brave Warrior' || badgeCode === 'The Brave') {
      return badgeCheckArray.includes('Even Braver') ||
      badgeCheckArray.includes('The Bravest') ||
      badgeCheckArray.includes('Brave Warrior') || badgeCheckArray.includes('The Brave')
    } if (badgeCode === 'Even Braver') {
      return badgeCheckArray.includes('The Bravest') ||
      badgeCheckArray.includes('Even Braver')
    } if (badgeCode === 'The Bravest') {
      return badgeCheckArray.includes('The Bravest')
    }
    return null;
  };

  render() {
    const { browserLanguage } = this.props
    const { loading, openDialog, selectedBadge, weatherBadgeDetails, finalBadge, 
      weatherNotificationHistoryData, resetWeatherHistory, forecastData, 
      hourlyWeatherData, weatherLogData, currentIndexOfHistory } = this.state
    translator.setLanguage(browserLanguage);

    return (
      <div className = "weatherContainer">
        <Dialog visible = { loading } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        <Dialog
          showHeader = { false }
          visible = { openDialog }
          style = { { width: '30vw' } }
          id = "logOutDialog"
        >
          <div className = "logOutHeader">
            {translator.WeatherScreen.confirmBadWeather}
          </div>
          <div className = "logOutBody">
            {selectedBadge &&
              `${ translator.WeatherScreen.confirmationText }  ${
                translator.WeatherScreen[
                  weatherBadgeDetails[ selectedBadge ]
                    .BadgeNameKey
                ]
              } .`}
          </div>
          <div
            style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } }
          />
          <div className = "logOutFooter">
            <div
              className = "logOutFooterText"
              onClick = { () => this.setState({ openDialog: false }) }
              aria-hidden = "true"
            >
              {translator.WeatherScreen.cancel}
            </div>
            <Button
              size = "sm"
              id = "logOutButton"
              onClick = { () => {
                this.notifyBadWeather();
                setTimeout(() => {
                  this.getWeatherLog();
                  this.getWeatherNotificationHistory();
                }, 500);
              } }
            >
              {translator.WeatherScreen.confirm}
            </Button>
          </div>
        </Dialog>
        <div className = "weatherLeftSection">
          <div className = "weatherScreenHeaderContainer">
            <div className = "WeatherScreenHeader">
              {translator.WeatherScreen.weather}
            </div>
            <div className = "weatherScreenDate">
              {moment()
                .locale(browserLanguage)
                .format('dddd DD.M.YYYY')}
            </div>
          </div>

          <div className = "weatherHeading">
            {translator.WeatherScreen.badWeatherNotification}
          </div>
          <div
            className = "radioLabelDescription"
            style = { {
              paddingLeft: '40px',
              paddingRight: '40px',
              marginBottom: '20px',
              marginTop: '5px',
            } }
          >
            {translator.WeatherScreen.weatherIntro}
          </div>
          {weatherBadgeDetails.map((badge, index) => (
            <div key = { badge.index } style = { { paddingBottom: '16px' } }>
              <div
                style = { {
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '40px',
                  paddingRight: '40px',
                } }
              >
                <Radio
                  id = {
                    finalBadge === badge.badgeCode
                      ? 'markedRadio'
                      : ''
                  }
                  size = "small"
                  value = { index }
                  onChange = { (e) =>
                    this.setState({ selectedBadge: e.target.value })
                  }
                  disabled = { this.conditionChecker(badge.badgeCode) }
                  checked = { index.toString() === selectedBadge }
                />
                <img
                  src = {
                    this.conditionChecker(badge.badgeCode)
                      ? badge.disabledImage
                      : badge.image
                  }
                  alt = ""
                  className = "weatherBadgeImage"
                />
                <div style = { { paddingLeft: '16px' } }>
                  <div className = "radioLabelHeading">
                    {translator.WeatherScreen[ badge.BadgeNameKey ]}
                  </div>
                  <div className = "radioLabelDescription">
                    {translator.WeatherScreen[ badge.BadgeDescription ]}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div
            className = "weather-source"
            style = { {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginRight: '43px',
              marginTop: '60px',
            } }
          >
            {translator.WeatherScreen.openWeather}
          </div>
          {weatherNotificationHistoryData &&
            weatherNotificationHistoryData
              .weatherNotificationHistoryWeekDetailsModel.length > 0 && (
            <div style = { { padding: '16px 24px 2vh 24px' } }>
              <WeatherNotificationHistory
                data = { weatherNotificationHistoryData }
                reset = { resetWeatherHistory }
                resetMethod = { () =>
                  this.setState({ resetWeatherHistory: null })
                }
                changeIndex = { (index) => {
                  this.setState({ currentIndexOfHistory: index });
                  // console.log(index);
                } }
              />
            </div>
          )}
          <div
            style = { {
              marginBottom: '20px',
              marginLeft: '12.5%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            } }
          >
            <img
              alt = ""
              src = { require('../../images/manul_image.png') }
              style = { { width: '18px', height: '18px', marginRight: '8px' } }
            />
            <div className = "weatherNotificationHistoryText">
              {translator.WeatherScreen.manualPlaceHolderText}
            </div>
          </div>
          <div className = "homepageFooterContainer">
            <div style = { { border: '1px solid #C2C9D1' } } />
            <div
              style = { {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '8vh',
              } }
            >
              <div
                className = {
                  currentIndexOfHistory === 0
                    ? 'resetText'
                    : 'resetTextEnable'
                }
                onClick = { () => {
                  this.setState({
                    selectedBadge: null,
                    resetWeatherHistory: 0,
                    currentIndexOfHistory: 0,
                  });
                } }
                aria-hidden = "true"
              >
                {translator.LogScreen.resetText1}
              </div>
              <Button
                size = "sm"
                id = "weatherNotifyButton"
                onClick = { () => this.setState({ openDialog: true }) }
                disabled = { selectedBadge === null }
              >
                {translator.WeatherScreen.notify}
              </Button>
            </div>
          </div>
        </div>

        <div className = "weatherRightSection">
          <WeatherScreenRightSection
            weatherData = { forecastData }
            hourlyWeatherData = { hourlyWeatherData }
            weatherLogData = { weatherLogData }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Weather);
