import React, { useEffect, useState } from 'react';
import './Variable.css';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { ChevronLeftIcon, ChevronRightIcon, Button } from '@postidigital/posti-components';
import { translation } from '../../locales/translation.js';

const translator = new LocalizedStrings(translation);
function Variable(props) {
  const { allTargetList } = props
  const [ currentPageNumber, setcurrentPageNumber ] = useState(1);
  const [ totalPageNumber, settotalPageNumber ] = useState(0);
  const [ allTarget, setAllTarget ] = useState(allTargetList);

  useEffect(() => {
    const { selectedBadgeData } = props
    settotalPageNumber(selectedBadgeData.length);
    setAllTarget(allTargetList);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props ]);
  const { browserLanguage, selectedBadgeData, handleChange, prentBackClick, parentNextClick } = props
  translator.setLanguage(browserLanguage);
  return (
    <>
      <div style = { { paddingTop: '33.5px', paddingLeft: '30.5px' } }>
        <div className = "tabHeading">{translator.Varibale1.modify}</div>
      </div>
      {selectedBadgeData.length === 0 ? (
        ''
      ) : (
        <>
          <div className = "main-div">
            <ChevronLeftIcon
              style = { { cursor: 'pointer' } }
              aria-hidden = "true"
              height = "24px"
              onClick = { () => {
                if (currentPageNumber > 1) {
                  setcurrentPageNumber(currentPageNumber - 1);
                }
              } }
              color = { currentPageNumber === 1 ? 'rgba(115, 128, 140, 0.5)' : '#73808C' }
              width = "24px"
            />
            <div className = "peopleScreenPagination">
              {currentPageNumber} / {selectedBadgeData.length}
            </div>

            <div className = "midcontent">
              <div />
            </div>

            <div className = "midimage">
              {selectedBadgeData[ currentPageNumber - 1 ] ? (
                <img src = { selectedBadgeData[ currentPageNumber - 1 ].url } alt = "mid-img" />
              ) : (
                ''
              )}
            </div>
            <div className = "midcontent1">
              <div>
                {selectedBadgeData[ currentPageNumber - 1 ]
                  ? browserLanguage === 'en'
                    ? selectedBadgeData[ currentPageNumber - 1 ].heading
                    : selectedBadgeData[ currentPageNumber - 1 ].headingFi
                  : ''}
              </div>
            </div>
            <div>(</div>
            <div className = "targetPointsCss">{`${ allTargetList[ currentPageNumber ].targetPoints }`}</div>
            <div className = "targetPointsPTSCss">pts</div>
            <div>)</div>
            <ChevronRightIcon
              style = { { cursor: 'pointer' } }
              aria-hidden = "true"
              height = "24px"
              color = { currentPageNumber === totalPageNumber ? 'rgba(115, 128, 140, 0.5)' : '#73808C' }
              onClick = { () => {
                if (currentPageNumber !== totalPageNumber) {
                  setcurrentPageNumber(currentPageNumber + 1);
                }
              } }
              width = "24px"
            />
          </div>
          <div className = "contents">
            {translator.editConfiguration.desc1}{' '}
            {selectedBadgeData[ 0 ]
              ? selectedBadgeData[ currentPageNumber - 1 ].heading
              : ''}{' '}
            {translator.editConfiguration.desc2}{' '}
            <span className = "textblue">
              {allTargetList[ currentPageNumber ].targetValue}
            </span>{' '}
            {translator.editConfiguration.desc3}{' '}
            <span className = "textblue">
              {' '}
              {allTargetList[ currentPageNumber ].targetTime}{' '}
            </span>{' '}
            {translator.editConfiguration.desc4}{' '}
            <span className = "textblue">
              {' '}
              {+allTargetList[ currentPageNumber ].targetTime +
                +allTargetList[ currentPageNumber ].targetSlack}{' '}
            </span>{' '}
            {translator.editConfiguration.desc5}
          </div>
          <div className = "main-div2">
            <div>
              <div className = "heading">{translator.Varibale1.ttv}</div>
              <div className = "subheading">{translator.Varibale1.ttv1}</div>
            </div>
            <div className = "databox">
              <div>
                <input
                  type = "number"
                  name = "targetTime"
                  className = "textbox"
                  value = { allTargetList[ currentPageNumber ].targetTime }
                  onChange = { (data) => {
                    allTarget[ currentPageNumber ].targetTime = data.target.value;
                    setAllTarget([ ...allTarget ]);
                    handleChange(data, 'targetTime', currentPageNumber);
                  } }
                />
              </div>
            </div>
          </div>

          <div className = "main-div2">
            <div>
              <div className = "heading1">{translator.Varibale1.sv}</div>
              <div className = "subheading">{translator.Varibale1.sv1}</div>
            </div>
            <div className = "databox1">
              <div>
                <input
                  type = "number"
                  name = "targetSlack"
                  className = "textbox"
                  value = { allTargetList[ currentPageNumber ].targetSlack }
                  onChange = { (data) => {
                    allTarget[ currentPageNumber ].targetSlack = data.target.value;
                    setAllTarget([ ...allTarget ]);
                    handleChange(data, 'targetSlack', currentPageNumber);
                  } }
                />
              </div>
            </div>
          </div>

          <div className = "main-div2">
            <div>
              <div className = "heading1">{translator.Varibale1.tvv}</div>
              <div className = "subheading">{translator.Varibale1.tvv1}</div>
            </div>
            <div className = "databox1">
              <div>
                <input
                  type = "number"
                  name = "targetValue"
                  className = "textbox"
                  value = { allTargetList[ currentPageNumber ].targetValue }
                  onChange = { (data) => {
                    allTarget[ currentPageNumber ].targetValue = data.target.value;
                    setAllTarget([ ...allTarget ]);
                    handleChange(data, 'targetValue', currentPageNumber);
                  } }
                />
              </div>
            </div>
          </div>

          <div className = "main-div2">
            <div>
              <div className = "heading1">{translator.Varibale1.pv}</div>
              <div className = "subheading">{translator.Varibale1.pv1}</div>
            </div>
            <div className = "databox1">
              <div>
                <input
                  type = "number"
                  name = "targetPoints"
                  className = "textbox"
                  value = { allTargetList[ currentPageNumber ].targetPoints }
                  onChange = { (data) => {
                    allTarget[ currentPageNumber ].targetPoints = data.target.value;
                    setAllTarget([ ...allTarget ]);
                    handleChange(data, 'targetPoints', currentPageNumber);
                  } }
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div
        style = { {
          position: 'absolute',
          bottom: '70px',
          width: '364px',
        } }
      >
        <div
          style = { {
            border: '1px solid #C2C9D1',
            margin: '0px 20px 0px 20px',
            width: '362.03px',
          } }
        />
      </div>
      <div className = "badgeSelectionFooterBody" style = { { position: 'absolute' } }>
        <button
          type = "button"
          className = "badgeSelectionCancelButton"
          onClick = { () => {
            prentBackClick();
            const element = document.getElementById('hello');
            element.scrollBy(-200, 0);
            setcurrentPageNumber(1);
          } }
        >
          {translator.EditDialog.back}
        </button>
        <Button
          className = "badgeSelectionNextButton"
          onClick = { () => {
            parentNextClick();
            const element = document.getElementById('hello');
            element.scrollBy(-200, 0);
          } }
        >
          {translator.EditDialog.next}
        </Button>
      </div>
    </>
  );
}
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Variable);
