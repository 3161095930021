/* eslint-disable global-require */
import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Avatar } from 'primereact/avatar';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService.js';
import Toastr from '../../shared/Toastr';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants.js';
import './Message.css';

const translator = new LocalizedStrings(translation);
class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgData:props.dataFromParent,
      expandedMsgID:[]
    };
    this.commonService = new CommonService();
  }

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props;
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.getMessages()
    }
  };

  // To get the inbox message 
  getMessages = () =>{
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage, selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.isSuperAdmin = decoded.roles[ 0 ] == "PEC.SV.GAMIFICATION" ? "Y" : "Y";
    jsonBody.userSourceSystemId = '';
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      category: 'I',
    };
    this.commonService
      .postMethod(apiUrl.getSentArchiveMessages, data)
      .then((res) => {
        this.setState({
          msgData:res.data
        })
      })
      .catch(() => {
        Toastr.showErrorMessage('Message api error');
      });
  }

  // Api call for acknowledgement
  isMessageAcknowledged = (id) => {
    const { browserLanguage, selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.userSourceSystemId = '';
    jsonBody.interval = 'string';
    jsonBody.startDate = '2022-07-14T13:51:52.687Z';
    jsonBody.endDate = '2022-07-14T13:51:52.687Z';
    jsonBody.tertile = 'string';
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      userId: 0,
      msgId: id,
    };
    this.commonService
      .postMethod(apiUrl.acknowledgeMessage,data)
      .then(() => {
        this.getMessages();
      })
      .catch(() => {
        Toastr.showErrorMessage('Inbox Ack api error');
      });
  };

  // To expand colapse rows
  expandColapse = (id,ack) =>{
    const { expandedMsgID } = this.state;
    if(expandedMsgID.includes(id))
    {
      this.setState({
        expandedMsgID:expandedMsgID.filter((data)=> data!==id)
      })
    }
    else{
      if(!ack)
      {
        this.isMessageAcknowledged(id);
      }
      const tempArr = expandedMsgID;
      tempArr.push(id);
      this.setState({
        expandedMsgID:tempArr
      })
    }
  }

  // To change the format of date in msg heading
  dateFormatter = (value) =>{
    const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
    const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
    const [ day, month, year ] = value.split('.');
    const today = new Date(+year, month - 1, +day);
    const finalDate = ` ${ days[ today.getDay() ]  } ${ today.getDate()  }, ${ months[ today.getMonth() ]  } ${ today.getFullYear() }` ;
    return finalDate;
  }

  // To check whether received msg is of today or not
  dateCheck = (value) =>{
    const { msgData } = this.state;
    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth()+1<10?(`0${ today.getMonth() + 1 }`):today.getMonth() + 1;
    const todayday = today.getDate()<10?(`0${ today.getDate() }`):today.getDate();
    const convertedDate = `${ todayday }.${ todayMonth }.${ todayYear }`;
    const dataLength = msgData.filter((data) => data.messageCreatedTime === convertedDate)
    if(dataLength.length<1)
    {
      this.setState({
        msgData:[]
      })
    }
    if(value===convertedDate)
    {
      return true;
    }
    return false;
  }

  // Msg screen
  msgScreen = () =>{
    const { msgData,expandedMsgID } = this.state;
    return(
      msgData.map((rowData) =>
        this.dateCheck(rowData.messageCreatedTime) ?
          <>
            <div className = "wholeWrapperMsg">
              <div className = "gMsgAvatarContainer">
                <Avatar
                  image = { rowData.userProfileImage !== null && rowData.userProfileImage }
                  label = { rowData.userProfileImage === null && rowData.userName.charAt(0) }
                  // label = { rowData.userName.charAt(0) } 
                  size = "large" 
                  shape = "circle" 
                  style = { { backgroundColor: '#FFA894', color: '#ffff' } } 
                  className =  "primeSingleAvatar" 
                />
              </div>
              <div className = "gMsgDateHeaderWrapper">
                <div className = "gMsgDateWrapper">
                  { this.dateFormatter( rowData.messageCreatedTime) }
                  <div className = "gMsgNEWStyle">
                    {rowData.isSupervisorAcknowledged?null:`(new)`}
                  </div>
                </div>
                <div className = "gMsgHeaderWrapper">
                  { rowData.userName }
                  <div>
                    <img
                      src = { expandedMsgID.includes(rowData.id)?require("../../images/upArrow.png"):require("../../images/downArrow.png") }
                      alt = "notFound"
                      className = "gMsgArrow"
                      onClick = { () => this.expandColapse(rowData.id,rowData.isSupervisorAcknowledged) }
                      aria-hidden = "true"
                    />

                  </div>
                </div>
              </div>
            </div>
            <div className = { expandedMsgID.includes(rowData.id)?"gMsgBodyWrapperExpand":"gMsgBodyWrapper" }>
              { rowData.messageBody }
            </div>
          </> : null
      )
        
    )

  }

  render() {
    const { browserLanguage  }= this.props;
    const { msgData } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <div className = "greetingMsgContainer">
        { msgData.length < 1 ? <div className = "noMsgStyle">{translator.HomePage.noMessage}</div>:this.msgScreen()  }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
