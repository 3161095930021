/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Headline, Button, Checkbox, Loading } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LocalizedStrings from 'react-localization';
import { CommonService } from '../../service/CommonService.js';
import apiUrl from '../../urlConstants';
import { getJsonBody } from '../../utility/commonUtility.js';
import './Contacts.css';
import AddNewContact from './AddNewContact';
import DeleteContactDialogue from './DeleteContactDialogue';
import SingleContactEdit from './SingleContactEdit.js';
import MultiContactEdit from './MultiContactEdit.js';
import { translation } from '../../locales/translation';
import CustomApiErrorDialog from '../Configuration/CustomApiErrorDialog.js';

const translator = new LocalizedStrings(translation);
class Contacts extends Component {
  constructor(props) {
    super(props);
    // const { selectedCostCenter } = this.props
    this.state = {
      selectedPeopleList: [],
      addNewContactDialog: false,
      apiflag: false,
      isAllSelected: false,
      changeHistoryData: [],
      selectedPeopleWholeData: [],
      editButtonFlag: false,
      // selectedCostCenter: selectedCostCenter.costCenterId,
      deleteContactDialogue: false,
      loading: true,
    };
    this.contactsNameTemplate = this.contactsNameTemplate.bind(this);
    this.commonService = new CommonService();
  }

  componentDidMount () {
    const { selectedCostCenter } = this.props
    if (selectedCostCenter)
    {
      setTimeout(() => {
        this.contactData();
        this.ChangeHistory();
      }, 3500);
    }
  };

  componentDidUpdate(prevProps) {
    const { selectedCostCenter, browserLanguage } = this.props 
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.contactData();
      this.ChangeHistory();
    }
    if (prevProps.browserLanguage !== browserLanguage) {
      this.ChangeHistory();
    }
  }
  
  contactData = () => {
    const { selectedCostCenter, browserLanguage } = this.props
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.IsSupervisor = true;
    jsonBody.AppId = jsonBody.applicationId;
    jsonBody.EmployeeId = jsonBody.masterGroupSourceSystemId;
    this.setState({
      loading: true,
    });
    this.commonService
      .postMethod(apiUrl.contactData, jsonBody)
      .then((res) => {
        this.setState({
          contactData: res.data,
          loading: false,
          isAllSelected: false,
        });
        this.ChangeHistory();
      })
      .catch(() => {
        this.setState({
          loading: false,
          apiflag: true,
        });
      });
  };

  ChangeHistory = () => {
    const { selectedCostCenter, browserLanguage } = this.props
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.IsSupervisor = true;
    jsonBody.AppId = jsonBody.applicationId;
    jsonBody.EmployeeId = jsonBody.masterGroupSourceSystemId;
    jsonBody.EntityCode = browserLanguage;
    this.setState({
      loading: true,
    });
    this.commonService
      .postMethod(apiUrl.getChangeHistory, jsonBody)
      .then((res) => {
        this.setState({ changeHistoryData: res.data, loading: false });
      })
      .catch(() => {
        this.setState({
          loading: false,
          apiflag: true,
        });
      });
  };

  onCheckboxClick = (e, member) => {
    const { selectedPeopleWholeData, contactData, selectedPeopleList } = this.state
    let selectedPeopleList1 = [ ...selectedPeopleList ];
    const selectedPeopleFullData = [ ...selectedPeopleWholeData ];
    let masterList = [];
    let isAllSelected = false;

    masterList = [ ...contactData.map((data) => data.id) ];
    if (e.target.checked) {
      selectedPeopleList1.push(member.id);
      selectedPeopleFullData.push(member);
    } else {
      selectedPeopleList1 = selectedPeopleList1.filter((id) => id !== member.id);
      selectedPeopleFullData.splice(selectedPeopleFullData.indexOf(member), 1);
    }
    if (
      masterList.length === selectedPeopleList1.length &&
      masterList.length === selectedPeopleFullData.length
    ) {
      isAllSelected = true;
    } else {
      isAllSelected = false;
    }
    this.setState({
      selectedPeopleList: selectedPeopleList1,
      isAllSelected,
      selectedPeopleWholeData: selectedPeopleFullData,
      editButtonFlag: false,
    });
  };

  onSelectAllClick = () => {
    const { contactData, isAllSelected } = this.state
    let arr = [];
    arr = [ ...contactData.map((data) => data.id) ];
    if (isAllSelected) {
      this.setState({
        selectedPeopleWholeData: [],
        selectedPeopleList: [],
        isAllSelected: !isAllSelected,
      });
    } else {
      this.setState({
        selectedPeopleWholeData: [ ...contactData ],
        selectedPeopleList: arr,
        isAllSelected: !isAllSelected,
      });
    }
  };

  clearAllSelectedState = () => {
    this.setState({ isAllSelected: false });
  };

  editButtonClicked = () => {
    this.setState({
      editButtonFlag: true,
    });
  };

  childCallBack = (flag) => {
    this.setState({
      editButtonFlag: flag,
      selectedPeopleList: [],
      selectedPeopleWholeData: [],
    });
    this.contactData();
  };

  contactsNameTemplate(data) {
    const { selectedPeopleList } = this.state
    return (
      <div
        style = { {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          lineHeight: '5vh',
        } }
      >
        <Checkbox
          onChange = { (e) => this.onCheckboxClick(e, data) }
          checked = { selectedPeopleList.includes(data.id) }
        />
        <div
          style = { {
            background: data.color,
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            marginRight: '13px',
          } }
        />
        <div className = "image-text">{data.name}</div>
      </div>
    );
  }

  render() {
    const { browserLanguage } = this.props
    const { addNewContactDialog, counter, deleteContactDialogue, 
      selectedPeopleList, selectedPeopleWholeData, editButtonFlag, loading, contactData, 
      isAllSelected, changeHistoryData, apiflag } = this.state
    translator.setLanguage(browserLanguage);
    return (
      <>
        {apiflag === true ? <CustomApiErrorDialog /> : null}
        {addNewContactDialog && (
          <AddNewContact
            onCancel = { () => {
              this.setState({ addNewContactDialog: false });
            } }
            setLoader = { () => {
              this.setState({
                deleteContactDialogue: false,
                loading: true,
              });
            } }
            onConfirm = { () => {
              setTimeout(() => {
                this.contactData();
                this.setState({
                  addNewContactDialog: false,
                  counter: counter + 1,
                  loading: false,
                });
              }, 200);
            } }
          />
        )}
        {deleteContactDialogue && (
          <DeleteContactDialogue
            peopleEmployeeIdList = { selectedPeopleList }
            peopleList = { selectedPeopleWholeData }
            clearAllSelectedState = { this.clearAllSelectedState }
            setLoader = { () => {
              this.setState({
                deleteContactDialogue: false,
                loading: true,
              });
            } }
            onCancel = { () => {
              this.setState({ deleteContactDialogue: false });
            } }
            onConfirm = { () => {
              setTimeout(() => {
                this.contactData();
                this.setState({
                  deleteContactDialogue: false,
                  loading: false,
                  selectedPeopleList: [],
                  selectedPeopleWholeData: [],
                  counter: counter + 1,
                });
              }, 0);
            } }
          />
        )}

        {editButtonFlag === true ? (
          selectedPeopleWholeData.length === 1 ? (
            <SingleContactEdit
              data = { selectedPeopleWholeData[ 0 ] }
              parentCallback = { this.childCallBack }
            />
          ) : (
            <MultiContactEdit
              data = { selectedPeopleWholeData }
              parentCallback = { this.childCallBack }
            />
          )
        ) : null}
        <div className = "wholeContactsWrapper">
          <Dialog visible = { loading } id = "loader">
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
          <div className = "leftSideContacts">
            <Headline className = "leftSideContactsHeader">
              {translator.VIPContactScreen.vipContact}
            </Headline>
            <div className = "leftSideContactsContainer">
              <div className = "contactsDataTableContainer">
                <DataTable
                  id = "contactDataTable"
                  value = { contactData }
                  style = { {
                    borderBottom: '1px solid #eaedf1',
                    width: '55vw',
                    paddingLeft: '24px',
                  } }
                  emptyMessage = { translator.VIPContactScreen.emptyData }
                >
                  <Column
                    style = { { padding: '1rem 1rem 1rem 0rem' } }
                    field = "name"
                    header = { translator.VIPContactScreen.contactColumn }
                    className = "columnContact"
                    body = { this.contactsNameTemplate }
                    sortable
                  />

                  <Column
                    style = { { padding: '1rem 1rem 1rem 0rem' } }
                    field = "contactNumber"
                    header = { translator.VIPContactScreen.numberColumn }
                    className = "columnNumber"
                    sortable
                  />

                  <Column
                    style = { { padding: '1rem 1rem 1rem 0rem' } }
                    field = "role"
                    header = { translator.VIPContactScreen.roleColumn }
                    sortable
                    className = "columnRole"
                  />
                </DataTable>

                <div className = "leftSideContactsFooter">
                  <hr className = "leftSideContactsFooterLine" />
                  <div className = "footerButtonContainer">
                    <div
                      className = {
                        selectedPeopleList.length > 0
                          ? 'deleteButtonTextEnable'
                          : 'deleteButtonTextDisable'
                      }
                      onClick = { () =>
                        selectedPeopleList.length > 0
                          ? this.setState({ deleteContactDialogue: true })
                          : this.setState({ deleteContactDialogue: false })
                      }
                      aria-hidden = "true"
                    >
                      {translator.VIPContactScreen.delete}
                    </div>
                    <button
                      type = "button"
                      className = {
                        browserLanguage === 'en'
                          ? 'leftSideContactsFooterSelectButton'
                          : 'leftSideContactsFooterSelectButton1'
                      }
                      onClick = { this.onSelectAllClick }
                    >
                      {isAllSelected
                        ? translator.VIPContactScreen.deselectAll
                        : translator.VIPContactScreen.selectAll}
                    </button>
                    <div
                      aria-hidden = "true"
                      className = {
                        selectedPeopleList.length > 0
                          ? 'editButtonTextEnable'
                          : 'editButtonTextDisable'
                      }
                      onClick = { this.editButtonClicked }
                    >
                      {translator.VIPContactScreen.edit}
                    </div>
                    <Button
                      size = "sm"
                      id = "addNewButton"
                      onClick = { () => {
                        this.setState({ addNewContactDialog: true });
                      } }
                    >
                      {translator.VIPContactScreen.addNew}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className = "rightSideContacts">
            <div className = "rightSideContactsFilterHeader">
              {translator.VIPContactScreen.changeHistory}
            </div>
            <div className = "rightSideContactsFilterContainer">
              <div className = "rightSideContainerText">{translator.VIPContactScreen.actions}</div>
              <div style = { { marginTop: 18 } }>
                {changeHistoryData.length > 0 &&
                  changeHistoryData.map((change) => (
                    <div className = "rightSideContainerDateTime" key = { change.index }>
                      <div className = "rightSideContainerDate">
                        {change.updatedDate}
                        <span className = "rightSideContainerTime">{change.updatedTime}</span>
                      </div>
                      <div className = "rightSideContainerChanges">{change.description}</div>
                    </div>
                  ))}
              </div>
            </div>
            <div className = "filterFooter">
              <div
                style = { {
                  border: '1px solid #C2C9D1',
                  backgroundColor: '#C2C9D1',
                } }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
