/* eslint-disable no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable global-require */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { Component } from 'react';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import Tooltip from '@material-ui/core/Tooltip';
import './Messages.css';
import {
  Search,
  TabPanel,
  Tab,
  Tabs,
  TabContext,
  Loading,
  Checkbox,
  Button,
  Tooltip as PostiTooltip,
} from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import { AvatarGroup } from 'primereact/avatargroup';
import { Avatar } from 'primereact/avatar';
import moment from 'moment';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody, isRolePEC } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';
import MsgSupToDrivers from './MsgSupToDrivers.js';
// eslint-disable-next-line import/no-named-as-default
import TwoWayMessages from './TwoWayMessages';

const translator = new LocalizedStrings(translation);
class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentData: [],
      inboxData: [],
      archiveData: [],
      currentTab: 'tab1',
      isAllSelected: false,
      peopleDetailsList: [],
      peopleDetailsList1: [],
      selectedPeopleList: [],
      selectedPeopleMessageType:[],
      selectedPeopleUserId: [],
      selectedPeopleUserSourceSystemId: [],
      descendingPeople: false,
      originalDataList: [],
      originalDataList1: [],
      searchText: '',
      loading: true,
      flagMessageScreen: false,
      archive: false,
      originalArchiveDataList: [],
      checkboxCounter: 0,
      flagTwoWayMessageScreen: false,
      num:0,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getInboxMessage();
      this.getSentMessage();
      this.getArchiveMessages();
    }, 3500);
  }

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props;
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.getInboxMessage();
      this.getSentMessage();
      this.getArchiveMessages();
    }
  }

  formatForGroupMessageId = (resData) => {
    const data = resData;
    const array = {};
    const result = data.reduce((r, el) => {
      let e = null;
      if (el.groupMessageId === null) {
        e = el.id;
      } else {
        e = el.groupMessageId;
      }
      if (!array[ e ]) {
        array[ e ] = {
          id: [],
          userId: [],
          userName: [],
          userSourceSystemId: [],
          messageType:[],
          userProfileImage: el.userProfileImage,
          messageSubject: el.messageSubject,
          messageBody: el.messageBody,
          isSupervisorAcknowledged: el.isSupervisorAcknowledged,
          messageCreatedTime: el.messageCreatedTime,
          messageSentTime: el.messageSentTime,
          groupId: el.groupId,
          groupName: el.groupName,
          groupImagePath: el.groupImagePath,
          groupSourceSystemId: el.groupSourceSystemId,
          messageBy: el.messageBy,
          graphicsImage: el.graphicsImage,
          costCenterName:el.costCenterName,
        };
        r.push(array[ e ]);
      }
      array[ e ].id.push(el.id);
      array[ e ].userId.push(el.userId);
      array[ e ].userName.push(el.userName);
      array[ e ].userSourceSystemId.push(el.userSourceSystemId);
      array[ e ].messageType.push(el.messageType);
      return r;
    }, []);
    return result;
  };

  toolTipView = (value, dispValOnHover) =>
    dispValOnHover === 1 ? (
      <>
        <div className = "dateTooltipHeader">{value.messageType == "I" ? "Receiving Details" : translator.messageScreen.sendingDetails}</div>
        <div className = "dateTooltip1stBody">
          {`${ value.messageCreatedTime } by ${ value.messageType == "I" ? value.userName : value.messageBy }`}
        </div>
      </>
    ) : dispValOnHover === 2 ? (
      <>
        <div className = "dateTooltip1stBody">{value.messageCreatedTime}</div>
        <div>
          <img
            aria-hidden = "true"
            className = "GraphicHolder"
            src = { value.graphicsImage }
            alt = "noImage"
            height = { 35 }
            width = { 35 }
          />
        </div>
        <div className = "dateTooltipHeader">{value.messageSubject}</div>
        <div className = "dateTooltip1stBody">{value.messageBody}</div>
      </>
    ) : dispValOnHover === 4 ? (
      <>
        {value.isSupervisorAcknowledged === true ? 
          <>
            <div className = "dateTooltip1stBody">{value.messageCreatedTime}</div>
            <br />
            <div className = "dateTooltip1stBody">{value.messageBody}</div>
          </>
          : 
          <>      
            <div className = "dateTooltip1stBody" style = { { display : 'flex' } }><div>{value.messageCreatedTime}</div><div className = "newMsgIndicator" style = { { marginLeft : '168px' } }>(new)</div></div>
            <br />
            <div className = "dateTooltip1stBody">{value.messageBody}</div>
          </>
        }
      </>
    ) : (
      <>
        <div className = "dateTooltipHeader3">{value.messageType == "I" ? "Sender" : 
          value.userName.length > 1 ? `${ value.userName.length } Recipients` : `${ value.userName.length } Recipient`}
        </div>
        <div className = "dateTooltip1stBody3">{value.userName.join(',')}</div>
      </>
    );

  getInboxMessage = () => {
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage, selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.userSourceSystemId = '';
    jsonBody.isSuperAdmin = decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? "Y" : "N";
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      category: 'I',
    };
    this.commonService
      .postMethod(apiUrl.getSentArchiveMessages, data)
      .then((res) => {
        this.setState({
          inboxData: this.formatForGroupMessageId(res.data),
          peopleDetailsList1: this.formatForGroupMessageId(res.data),
          originalDataList1: this.formatForGroupMessageId(JSON.parse(JSON.stringify(res.data))),
          contactData2: res.data,
          selectedPeopleList: [],
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage('Inbox Error');
      });
  };

  getSentMessage = () => {
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage, selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.userSourceSystemId = '';
    jsonBody.isSuperAdmin = decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? "Y" : "N";
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      category: 'S',
    };
    this.commonService
      .postMethod(apiUrl.getSentArchiveMessages, data)
      .then((res) => {
        this.setState({
          sentData: this.formatForGroupMessageId(res.data),
          peopleDetailsList: this.formatForGroupMessageId(res.data),
          originalDataList: this.formatForGroupMessageId(JSON.parse(JSON.stringify(res.data))),
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.messageScreen.retrivingMsgFailed);
      });
  };

  archiveMessages = () => {
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage, selectedCostCenter } = this.props;
    const { selectedPeopleList, currentTab } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.userSourceSystemId = '';
    jsonBody.isSuperAdmin = decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? "Y" : "N";
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      messageIdList:  selectedPeopleList.reduce((accumulator, value) => ({ ...accumulator, [ value ]: ' ' }), {}), 
      actionType: 'archive',
      messageType: currentTab === 'tab1' ? "I" : "S",
    };
    this.commonService
      .postMethod(apiUrl.messageActions, data)
      .then(() => {
        this.setState({
          isAllSelected: false,
          loading: true,
          selectedPeopleMessageType:[],
          selectedPeopleList:[],
          selectedPeopleUserSourceSystemId:[],
        });
        this.getInboxMessage();
        this.getSentMessage();
        this.getArchiveMessages();
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.messageScreen.archivingFailed);
      });
  };

  getArchiveMessages = () => {
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage, selectedCostCenter } = this.props;
    const { archive } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.userSourceSystemId = '';
    jsonBody.isSuperAdmin = decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? "Y" : "N";
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      category: 'A',
    };
    this.commonService
      .postMethod(apiUrl.getSentArchiveMessages, data)
      .then((res) => {
        if (archive) {
          Toastr.showSuccessMessage(translator.messageScreen.messageArchived);
        }
        this.setState({
          archiveData: this.formatForGroupMessageId(res.data),
          originalArchiveDataList: this.formatForGroupMessageId(
            JSON.parse(JSON.stringify(res.data))
          ),
          contactData1: res.data,
          loading: false,
          archive: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.messageScreen.messageArchiveFailed);
      });
  };

  keyValuePair = (key,value) => {
    const obj3 = {};
    key.forEach((element, index) => {
      obj3[ element ] = value[ index ];
    });
    return obj3
  }

  unarchiveMessages = () => {
    const { browserLanguage, selectedCostCenter } = this.props;
    const { selectedPeopleList, archive, selectedPeopleMessageType, currentTab } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.userSourceSystemId = '';
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      messageType: " ",
      messageIdList: currentTab === "tab3" && selectedPeopleList.length === 1 ? this.keyValuePair(selectedPeopleList , selectedPeopleMessageType ) : selectedPeopleList.reduce((accumulator, value) => ({ ...accumulator, [ value ]: 'S' }), {}),
      actionType: 'unarchive', 
    };
    this.commonService
      .postMethod(apiUrl.messageActions, data)
      .then(() => {
        if (archive === false) {
          Toastr.showSuccessMessage(translator.messageScreen.messageUnarchive);
        }
        this.setState({
          isAllSelected: false,
          loading: true,
          archive: false,
          selectedPeopleMessageType:[],
          selectedPeopleList:[],
          selectedPeopleUserSourceSystemId:[],
        });
        this.getInboxMessage();
        this.getArchiveMessages();
        this.getSentMessage();
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.messageScreen.unarchiveFailed);
      });
  };

  unarchiveMessages1 = () => {
    const { browserLanguage, selectedCostCenter } = this.props;
    const { selectedPeopleList, archive, selectedPeopleMessageType } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.userSourceSystemId = '';
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      messageType: " ",
      messageIdList:  this.keyValuePair(selectedPeopleList , selectedPeopleMessageType ),
      actionType: 'unarchive', 
    };
    this.commonService
      .postMethod(apiUrl.messageActions, data)
      .then(() => {
        if (archive === false) {
          Toastr.showSuccessMessage(translator.messageScreen.messageUnarchive);
        }
        this.setState({
          isAllSelected: false,
          loading: true,
          archive: false,
          selectedPeopleMessageType:[],
          selectedPeopleList:[],
          selectedPeopleUserSourceSystemId:[],
        });
        this.getInboxMessage();
        this.getArchiveMessages();
        this.getSentMessage();
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.messageScreen.unarchiveFailed);
      });
  };

  onMsgDialogClose = () => {
    this.setState({
      flagMessageScreen: false,
      flagTwoWayMessageScreen: false,
      checkboxCounter:0,
      selectedPeopleUserSourceSystemId:[],
      selectedPeopleUserId:[],
      selectedPeopleMessageType:[],
      isAllSelected: false,
    });
    setTimeout(() => {
      this.getInboxMessage();
      this.getSentMessage();
    }, 500);
  };

  onCheckboxClick = (e, members) => {
    const {
      selectedPeopleMessageType,
      selectedPeopleList,
      checkboxCounter,
      selectedPeopleUserId,
      selectedPeopleUserSourceSystemId,
      num,
    } = this.state;
    let tempSelectedPeopleList = selectedPeopleList;
    let tempselectedPeopleUserId = selectedPeopleUserId;
    let tempselectedPeopleUserSourceSystemId = selectedPeopleUserSourceSystemId;
    let tempselectedPeopleMessageType = selectedPeopleMessageType;
    if (e.target.checked) {
      tempSelectedPeopleList = [ ...tempSelectedPeopleList, ...members.id ]; 
      tempselectedPeopleMessageType = [ ...tempselectedPeopleMessageType, ...members.messageType ] ;
      tempselectedPeopleUserId = [ ...tempselectedPeopleUserId, ...members.userId ];
      tempselectedPeopleUserSourceSystemId = [
        ...tempselectedPeopleUserSourceSystemId,
        ...members.userSourceSystemId,
      ];
      this.setState({
        checkboxCounter: checkboxCounter + 1,
      });
      if(tempSelectedPeopleList.length === num)
      {
        this.setState({ isAllSelected : true })
      }
    } else {
      const deleteIndez = [];
      const temp=selectedPeopleList.indexOf(members.id[ 0 ])
      tempSelectedPeopleList = tempSelectedPeopleList.filter((ele,index) => {
        if(members.id.indexOf(ele) >= 0)
        {
          deleteIndez.push(index);
        }
        return members.id.indexOf(ele) < 0
      });
      for (var i = deleteIndez.length -1; i >= 0; i--)
      {
        tempselectedPeopleMessageType.splice(deleteIndez[ i ],1)
        tempselectedPeopleUserSourceSystemId.splice(deleteIndez[ i ],1)
        tempselectedPeopleUserId.splice(deleteIndez[ i ],1)
      }
      // tempselectedPeopleMessageType = tempselectedPeopleMessageType.filter((ele, index) => index !== temp);
      // tempselectedPeopleUserId = tempselectedPeopleUserId.filter(
      //   (ele) => members.userId.indexOf(ele) < 0);
      // tempselectedPeopleUserSourceSystemId = tempselectedPeopleUserSourceSystemId.filter(
      //   (ele, index) => index !== temp);
      this.setState({
        checkboxCounter: checkboxCounter - 1,
        isAllSelected : false
      });
    }
    this.setState({
      selectedPeopleMessageType: tempselectedPeopleMessageType,
      selectedPeopleList: tempSelectedPeopleList,
      selectedPeopleUserId: tempselectedPeopleUserId,
      selectedPeopleUserSourceSystemId: tempselectedPeopleUserSourceSystemId,
    });
  };

  sortFunction = (value,sortFrom) => {
    const { peopleDetailsList, peopleDetailsList1, descendingPeople, archiveData, currentTab } =
      this.state;
    const array =
      currentTab === 'tab1'
        ? peopleDetailsList1
        : currentTab === 'tab2'
          ? peopleDetailsList
          : archiveData;
    const descending = descendingPeople;
    if (descending) {
      array.reverse();
    } else if (value === 1) {
      array.sort((a, b) => (a.userName > b.userName ? 1 : -1));
    } else if (value === 2) {
      array.sort((a, b) => (a.messageSubject > b.messageSubject ? 1 : -1));
    } else if (value === 3) {
      array.sort((a, b) => (a.messageBody > b.messageBody ? 1 : -1));
    } else {
      array.sort((a, b) => (a.messageCreatedTime > b.messageCreatedTime ? 1 : -1));
    }
    if(sortFrom === "I")
    {
      this.setState({
        inboxData: array,
        descendingPeople: !descending,
      });
    }
    else if(sortFrom === "S")
    {
      this.setState({
        sentData: array,
        descendingPeople: !descending,
      });
    }
    else{
      this.setState({
        inboxData: array,
        sentData: array,
        descendingPeople: !descending,
      });
    }
  };

  globalSearch = (search) => {
    const { originalDataList } = this.state;
    if (search) {
      const filtered = originalDataList.filter((value) =>
        value.messageSubject && value.messageSubject.toLowerCase().includes(search.toLowerCase())
      );
      const filtered1 = originalDataList.filter((value) =>
        value.messageBody && value.messageBody.toLowerCase().includes(search.toLowerCase())
      );
      const filtered2 = originalDataList.filter((value) =>
        value.userName[ 0 ] && value.userName[ 0 ].toLowerCase().includes(search.toLowerCase())
      );
      // const fil = originalDataList.map((ele)=>{
      //   for(let i=0;i< ele.userName && ele.userName.length;i++)
      //   {
      //     if(ele.username && ele.userName[ i ].toLowerCase().includes(search.toLowerCase()))
      //     {
      //       break;
      //     }
      //   }
      //   return ele
      // }
      // // console.log("devvvvv",ele.userName.map((ele1)=>ele1.toLowerCase().includes(search.toLowerCase())))
      // )
      const finalFilter = [ ...filtered, ...filtered1, ...filtered2 ]
      this.setState({
        sentData: finalFilter,
        searchText: search,
      });
    } else {
      this.setState({
        sentData: originalDataList,
        searchText: search,
      });
    }
  };

  globalInboxSearch = (search) => {
    const { originalDataList1 } = this.state;
    if (search) {
      const filtered = originalDataList1.filter((value) =>
        value.messageBody && value.messageBody.toLowerCase().includes(search.toLowerCase())
      );
      const filtered1 = originalDataList1.filter((value) =>
        value.userName[ 0 ] && value.userName[ 0 ].toLowerCase().includes(search.toLowerCase())
      );
      const finalFilter = [ ...filtered, ...filtered1 ]
      this.setState({
        inboxData: finalFilter,
        searchText: search,
      });
    } else {
      this.setState({
        inboxData: originalDataList1,
        searchText: search,
      });
    }
  };

  globalSearchArchive = (search) => {
    const { originalArchiveDataList } = this.state;
    if (search) {
      const filtered = originalArchiveDataList.filter((value) =>
        value.messageSubject && value.messageSubject.toLowerCase().includes(search.toLowerCase())
      );
      const filtered1 = originalArchiveDataList.filter((value) =>
        value.messageBody && value.messageBody.toLowerCase().includes(search.toLowerCase())
      );
      const filtered2 = originalArchiveDataList.filter((value) =>
        value.userName[ 0 ] && value.userName[ 0 ].toLowerCase().includes(search.toLowerCase())
      );
      const finalFilter = [ ...filtered, ...filtered1, ...filtered2 ]
      this.setState({
        archiveData: finalFilter,
        searchText: search,
      });
    } else {
      this.setState({
        archiveData: originalArchiveDataList,
        searchText: search,
      });
    }
  };

  onSelectAllClick = () => {
    const { isAllSelected, originalDataList } = this.state;
    let idCollection = [];
    let userSSID = [];
    originalDataList.map((rowData) => (idCollection = [ ...idCollection, ...rowData.id ]));
    originalDataList.map((rowData) => (userSSID = [ ...userSSID, ...rowData.userSourceSystemId ]));
    if (isAllSelected) {
      this.setState({
        selectedPeopleUserSourceSystemId:[],
        selectedPeopleList: [],
        selectedPeopleMessageType:[],
        isAllSelected: !isAllSelected,
      });
    } else {
      this.setState({
        selectedPeopleUserSourceSystemId:userSSID,
        selectedPeopleList: idCollection,
        isAllSelected: !isAllSelected,
      // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
      },()=>this.setState({ num:this.state.selectedPeopleList.length }));
    }
  };

  onSelectAllClick1 = () => {
    const { contactData1, isAllSelected } = this.state;
    let arr1 = [];
    let arr2 = [];
    let userSSID = [];
    contactData1.map((rowData) => (userSSID = [ ...userSSID, rowData.userSourceSystemId ]));
    arr1 = [ ...contactData1.map((data) => data.id) ];
    arr2 = [ ...contactData1.map((data) => data.messageType) ];
    if (isAllSelected) {
      this.setState({
        selectedPeopleUserSourceSystemId:[],
        selectedPeopleList: [],
        selectedPeopleMessageType:[],
        isAllSelected: !isAllSelected,
      });
    } else {
      this.setState({
        selectedPeopleUserSourceSystemId:userSSID,
        selectedPeopleList: arr1,
        selectedPeopleMessageType:arr2,
        isAllSelected: !isAllSelected,
      // eslint-disable-next-line react/no-access-state-in-setstate, react/destructuring-assignment
      },()=>this.setState({ num:this.state.selectedPeopleList.length }));
    }
  };

  onSelectAllClick2 = () => {
    const { contactData2, isAllSelected } = this.state;
    let arr2 = [];
    let userSSID = [];
    let userId = [];
    contactData2.map((rowData) => (userSSID = [ ...userSSID, rowData.userSourceSystemId ]));
    contactData2.map((rowData) => (userId = [ ...userId, rowData.userId ]));
    arr2 = [ ...contactData2.map((data) => data.id) ];
    if (isAllSelected) {
      this.setState({
        selectedPeopleUserSourceSystemId:[],
        selectedPeopleMessageType:[],
        selectedPeopleList: [],
        selectedPeopleUserId: [],
        isAllSelected: !isAllSelected,
      });
    } else {
      this.setState({
        selectedPeopleUserSourceSystemId:userSSID,
        selectedPeopleList: arr2,
        selectedPeopleUserId: userId,
        isAllSelected: !isAllSelected,
      // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
      },()=>this.setState({ num:this.state.selectedPeopleList.length }));
    }
  };

  render() {
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage } = this.props;
    const { currentTab, isAllSelected,  flagTwoWayMessageScreen } = this.state;
    translator.setLanguage(browserLanguage);
    const {
      loading,
      selectedPeopleList,
      flagMessageScreen,
      searchText,
      sentData,
      inboxData,
      archiveData,
      selectedPeopleUserSourceSystemId,
      selectedPeopleUserId,
      checkboxCounter,
      selectedPeopleMessageType,
    } = this.state;
    let name = '';
    // eslint-disable-next-line no-unused-vars
    let initial = '';
    return (
      <div className = "messageContainer">
        <Dialog visible = { loading } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        {flagMessageScreen && (
          <MsgSupToDrivers
            parentCallback = { this.onMsgDialogClose }
            selectedPeoples = { [ ...new Set(selectedPeopleUserSourceSystemId) ] }
            selectedPeoplesTest = { selectedPeopleUserSourceSystemId }
          />
        )}
        {flagTwoWayMessageScreen && (
          <TwoWayMessages
            parentCallback = { this.onMsgDialogClose }
            selectedPeoples = { [] }
            selectedPeopleUserId = { selectedPeopleUserId }
            selectedPeopleUserSourceSystemId = { selectedPeopleUserSourceSystemId }
            // selectedPeopleUserSourceSystemId = { [ ...new Set(selectedPeopleUserSourceSystemId) ] }
          />
        )}
        <div className = { !isRolePEC() ? 'messageLeftSection' : 'messageLeftSectionRoles' }>
          <div
            className = "messageScreenHeaderContainer"
            style = {
              currentTab === 'tab1'
                ? { backgroundColor: '#FEA794' }
                : currentTab === 'tab2'
                  ? { backgroundColor: '#EDBDBD' }
                  : { backgroundColor: '#D9BFAD' }
            }
          >
            <div className = "messageHeader">
              <TabContext>
                <TabPanel id = "id-tab1" value = "tab1">
                  <div style = { { width: '300px' } }>{translator.messageScreen.receivedmessage}</div>
                </TabPanel>
                <TabPanel id = "id-tab2" value = "tab2">
                  <div style = { { width: '300px' } }>{translator.messageScreen.sentMessage}</div>
                </TabPanel>
                <TabPanel id = "id-tab3" value = "tab3">
                  <div style = { { width: '300px' } }>{translator.messageScreen.archivedMessage}</div>
                </TabPanel>
                <Tabs
                  selectedTab = { currentTab }
                  onChange = { (e) => {
                    this.setState({ currentTab: e });
                  } }
                >
                  <Tab
                    value = "tab1"
                    panelId = "id-tab1"
                    className = "editMessage-tabStyle"
                    id = { currentTab === 'tab1' ? 'selectBadgeTabActive' : 'selectBadgeTabInActive' }
                  >
                    {translator.messageScreen.inbox}
                  </Tab>
                  <Tab
                    value = "tab2"
                    panelId = "id-tab2"
                    className = "editMessage-tabStyle"
                    id = { currentTab === 'tab2' ? 'selectBadgeTabActive' : 'selectBadgeTabInActive' }
                  >
                    {translator.messageScreen.sent}
                  </Tab>
                  <Tab
                    className = "editMessage-tabStyle1"
                    id = {
                      currentTab === 'tab3'
                        ? 'selectRecipientsTabActive'
                        : 'selectRecipientsTabInActive'
                    }
                    value = "tab3"
                    panelId = "id-tab3"
                  >
                    {translator.messageScreen.archived}
                  </Tab>
                </Tabs>
              </TabContext>
            </div>
            <div style = { { width: '24%', paddingLeft: '5%' } }>
              <Search
                aria-label = { translator.RewardsScreen.searchText }
                id = "peopleSearch"
                label = {
                  browserLanguage === 'en' ? (
                    <div style = { { color: '#B0B0B0' } }>Search</div>
                  ) : (
                    <div style = { { color: '#B0B0B0' } }>Hae</div>
                  )
                }
                message = ""
                value = { searchText }
                onChange = { (e) => {
                  currentTab === 'tab1'
                    ? this.globalInboxSearch(e.target.value)
                    : currentTab === 'tab2' ? this.globalSearch(e.target.value) : this.globalSearchArchive(e.target.value);
                } }
              />
            </div>
          </div>
          <TabContext>
            <TabPanel id = "id-tab1" value = "tab1">
              <div
                style = { {
                  display: 'flex',
                  alignItems: 'center',
                  height: '56px',
                  padding: '0px 24px 0px 24px',
                } }
              >
                <div className = { isRolePEC() ? decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? 'senderTableHeading1SuperAdmin' : 'senderTableHeading1' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.sender}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(1, "I") }
                  />
                </div>
                {decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" &&
                <div className = { isRolePEC() ? 'costCenterTableHeading1SuperAdmin' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.PeopleDetails.costCenter}</div>
                  {/* <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(1, "I") }
                  /> */}
                </div>}
                <div className = { isRolePEC() ? decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? 'subjectTableHeading21SuperAdmin' : 'subjectTableHeading21' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.message}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(3, "I") }
                  />
                </div>
                {isRolePEC() && (
                  <div className = "exceptionalRewardsTableHeading1">
                    <div className = "messageHeaderText">{translator.messageScreen.date}</div>
                    <img
                      aria-hidden = "true"
                      alt = "NoImage"
                      src = { require('../../images/sort.png') }
                      style = { { cursor: 'pointer' } }
                      onClick = { () => this.sortFunction(4, "I") }
                    />
                  </div>
                )}
              </div>
              <div style = { { border: '1px solid #000', margin: '0 40px 0 24px' } } />
              <div className = "checkboxBorder">
                {inboxData && 
                  inboxData.map((value, index) => (
                    <div key = { value.index }>
                      <div className = "checkboxInnerBorder1">
                        <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "checkboxInnerBorder2SuperAdmin":"checkboxInnerBorder2" }>
                          <Checkbox
                            id = { `checkbox-${ index }` }
                            onChange = { (e) => this.onCheckboxClick(e, value) }
                            checked = { selectedPeopleList.includes(value.id[ 0 ]) }
                            value = { value.id }
                          />
                          <div aria-hidden = "true" className = "avatarhover">
                            <div>
                              {value.userName.length > 1 ? (
                                <AvatarGroup className = "primeAvatarGrp">
                                  {value.userName.length > 1 ? (
                                    <Avatar
                                      label = { `${ value.userName.length }` }
                                      size = "large"
                                      shape = "circle"
                                      style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                      className = "primeCountAvatar"
                                    />
                                  ) : null}
                                  {value.userName.map((nameValue, i) => {
                                    if (i < 2) {
                                      return (
                                        <Avatar
                                          label = { nameValue && nameValue.charAt(0) }
                                          size = "large"
                                          shape = "circle"
                                          style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                          className = {
                                            i === 0 ? 'primeFirstAvatar' : 'primeSecondAvatar'
                                          }
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                                </AvatarGroup>
                              ) : (
                                <Avatar
                                  image = { value.userProfileImage !== null && value.userProfileImage }
                                  label = { value.userProfileImage === null && value.userName[ 0 ].charAt(0) }
                                  size = "large"
                                  shape = "circle"
                                  style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                  className = "primeSingleAvatar"
                                  onClick = { () => this.setState({ flagTwoWayMessageScreen: true, selectedPeopleUserId:value.userId, selectedPeopleUserSourceSystemId:[ value.userSourceSystemId ] }) }
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className = {
                              value.isSupervisorAcknowledged === false
                                ? 'messengerName1'
                                : 'messengerName'
                            }
                            onClick = { () => this.setState({ flagTwoWayMessageScreen: true, selectedPeopleUserId:value.userId, selectedPeopleUserSourceSystemId: [ value.userSourceSystemId ] }) }
                          >    
                            {value.userName.length > 1
                              ? `${ value.userName.length } ${ translator.PeopleScreen.recipients }`
                              : value.userName}                                                   
                          </div>
                        </div>
                        {decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" &&
                        <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "costCenter2SuperAdmin":"checkboxInnerBorder2" }>
                          <div
                            className = "costCenterBody1SuperAdmin"
                          >    
                            {value.userName.length > 1
                              ? `${ value.userName.length } ${ translator.PeopleScreen.recipients }`
                              : value.costCenterName}                                                   
                          </div>
                        </div>}

                        {isRolePEC() ? (
                          <>
                            <div
                              className = {
                                value.isSupervisorAcknowledged === false
                                  ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION"? "messageBodyPEC1SuperAdmin":'messageBodyPEC2'
                                  : decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION"? "messageBodyPEC1SuperAdmin":'messageBodyPEC1'
                              }
                            >
                              <div className = "messageBodyHide"
                                onClick = { () => this.setState({ flagTwoWayMessageScreen: true, selectedPeopleUserId:value.userId, selectedPeopleUserSourceSystemId: [ value.userSourceSystemId ] }) }
                              >
                                <PostiTooltip
                                  title = { this.toolTipView(value, 4) }
                                  placement = "bottom-start"
                                  arrow = { false }
                                  id = "postiMainDataTooltipx"
                                >
                                  <div>
                                    {browserLanguage === 'en' 
                                      ? value.messageBody
                                      : value.messageBody}
                                  </div>
                                </PostiTooltip>
                              </div>
                            </div>
                            <div
                              className = {
                                value.isSupervisorAcknowledged === false
                                  ? 'messageDatePEC1'
                                  : 'messageDatePEC'
                              }
                            >
                              <div>
                                {browserLanguage === 'en'
                                  ? moment(value.messageSentTime).format('DD.MM.YYYY HH:MM')
                                  : moment(value.messageSentTime).format('DD.MM.YYYY HH:MM')}
                                &nbsp;
                              </div>
                              {decoded.roles[ 0 ]!="PEC.ADMIN.GAMIFICATION" &&
                              <div className = "newMsgIndicator">
                                {value.isSupervisorAcknowledged === false ? '(new)' : null}
                              </div>}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))
                }
              </div>
              <div
                className = {
                  !isRolePEC() ? 'homepageFooterContainer' : 'homepageFooterContainerRoles'
                }
              >
                <div style = { { border: '1px solid #C2C9D1', marginRight: '4px' } } />
                <div className = "commonOuterFooter">
                  <div style = { { display: 'flex', alignItems: 'center', width: '40%' } }>
                    <div
                      className = {
                        selectedPeopleList.length > 0
                          ? 'peopleFooterTextEnable'
                          : 'peopleFooterTextDisable'
                      }
                    />
                  </div>
                  <div className = "commonInnerFooter">
                    {inboxData.length > 0 ? (
                      <button
                        type = "button"
                        className = {
                          browserLanguage === 'en'
                            ? 'messageFooterSelectButton'
                            : 'messageFooterSelectButton1'
                        }
                        onClick = { this.onSelectAllClick2 }
                      >
                        {isAllSelected
                          ? translator.VIPContactScreen.deselectAll
                          : translator.VIPContactScreen.selectAll}
                      </button>
                    ) : (
                      <button
                        type = "button"
                        className = {
                          browserLanguage === 'en'
                            ? 'messageFooterSelectButtonDisable'
                            : 'messageFooterSelectButtonDisable1'
                        }
                        onClick = { this.onSelectAllClick2 }
                      >
                        {isAllSelected
                          ? translator.VIPContactScreen.deselectAll
                          : translator.VIPContactScreen.selectAll}
                      </button>
                    )}
                    <div
                      aria-hidden = "true"
                      className = {
                        selectedPeopleList.length > 0 && decoded.roles[ 0 ]!="PEC.ADMIN.GAMIFICATION"
                          ? 'messageArchiveEnable'
                          : 'messageArchiveDisable'
                      }
                      onClick = { () => {
                        this.setState({
                          archive: true,
                          checkboxCounter: 0,
                        });
                        this.archiveMessages();
                      } }
                    >
                      {translator.messageScreen.archive}
                    </div>
                    <Button
                      disabled = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? true : false }
                      size = "sm"
                      id = "newRewardButton"
                      onClick = { () => {
                        checkboxCounter === 1 || new Set(this.state.selectedPeopleUserSourceSystemId).size == 1
                          ? this.setState({ flagTwoWayMessageScreen: true })
                          : this.setState({ flagMessageScreen: true });
                      } }
                    >
                      {translator.messageScreen.newMessage}
                    </Button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel id = "id-tab2" value = "tab2">
              <div
                style = { {
                  display: 'flex',
                  alignItems: 'center',
                  height: '56px',
                  padding: '0px 24px 0px 24px',
                } }
              >
                <div className = { isRolePEC() ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? 'senderTableHeading1SuperAdmin' : 'senderTableHeading1' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.recipient}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(1,"S") }
                  />
                </div>
                {decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" &&
                <div className = { isRolePEC() ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? 'ccNameSentboxSuperAdmin' : 'subjectTableHeading1' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.PeopleDetails.costCenter}</div>
                  {/* <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(2,"S") }
                  /> */}
                </div>}
                <div className = { isRolePEC() ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? 'subjectTableHeading1superAdmin' : 'subjectTableHeading1' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.title}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(2,"S") }
                  />
                </div>
                <div className = { isRolePEC() ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? 'subjectTableHeading2SuperAdmin' : 'subjectTableHeading2' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.message}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(3,"S") }
                  />
                </div>
                {isRolePEC() && (
                  <div className = "exceptionalRewardsTableHeading1">
                    <div className = "messageHeaderText">{translator.messageScreen.date}</div>
                    <img
                      aria-hidden = "true"
                      alt = "NoImage"
                      src = { require('../../images/sort.png') }
                      style = { { cursor: 'pointer' } }
                      onClick = { () => this.sortFunction(4,"S") }
                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className = "messageHeaderText">{translator.messageScreen.by}</div>
                    <img
                      aria-hidden = "true"
                      alt = "NoImage"
                      src = { require('../../images/sort.png') }
                      style = { { cursor: 'pointer' } }
                      onClick = { () => this.sortFunction(4,"S") }
                    />
                  </div>
                )}
              </div>
              <div style = { { border: '1px solid #000', margin: '0 40px 0 24px' } } />
              <div className = "checkboxBorder">
                {sentData &&
                  sentData.map((value, index) => (
                    <div key = { value.index }>
                      <div className = "checkboxInnerBorder1">
                        <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "checkboxInnerBorder2SuperAdmin":"checkboxInnerBorder2" }>
                          <Checkbox
                            id = { `checkbox-${ index }` }
                            onChange = { (e) => this.onCheckboxClick(e, value) }
                            checked = { selectedPeopleList.includes(value.id[ 0 ]) }
                            value = { value.id }
                          />
                          <div aria-hidden = "true" className = "avatarhover">
                            <div>
                              {value.userName.length > 1 ? (
                                <AvatarGroup className = "primeAvatarGrp">
                                  {value.userName.length > 1 ? (
                                    <Avatar
                                      label = { `${ value.userName.length }` }
                                      size = "large"
                                      shape = "circle"
                                      style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                      className = "primeCountAvatar"
                                    />
                                  ) : null}
                                  {value && value.userName && value.userName.map((nameValue, i) => {
                                    if (i < 2) {
                                      return (
                                        <Avatar
                                          label = { nameValue && nameValue.charAt(0) }
                                          size = "large"
                                          shape = "circle"
                                          style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                          className = {
                                            i === 0 ? 'primeFirstAvatar' : 'primeSecondAvatar'
                                          }
                                        />
                                      );
                                    }
                                    return null;
                                  })}
                                </AvatarGroup>
                              ) : (
                                <Avatar
                                  image = { value.userProfileImage !== null && value.userProfileImage }
                                  label = { value.userProfileImage === null && value.userName[ 0 ].charAt(0) }
                                  size = "large"
                                  shape = "circle"
                                  style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                  className = "primeSingleAvatar"
                                  onClick = { () => this.setState({ flagTwoWayMessageScreen: true, selectedPeopleUserId:value.userId, selectedPeopleUserSourceSystemId:[ value.userSourceSystemId ] }) }
                                />
                              )}
                            </div>
                          </div>
                          <PostiTooltip
                            title = { this.toolTipView(value, 3) }
                            placement = "bottom-start"
                            arrow = { false }
                            id = "postiMainDataTooltipx"
                          >
                            <div className = "messengerName"
                              onClick = { () => value.userName.length === 1 ? this.setState({ flagTwoWayMessageScreen: true, selectedPeopleUserId:value.userId, selectedPeopleUserSourceSystemId:[ value.userSourceSystemId ] }): null }
                            >
                              {value.userName.length > 1
                                ? `${ value.userName.length } ${ translator.PeopleScreen.recipients }`
                                : value.userName}
                            </div>
                          </PostiTooltip>
                        </div>
                        {decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" &&
                        <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "costCenter2SentBoxSuperAdmin":"checkboxInnerBorder2" }>
                          <div
                            className = "ccNameSentboxBodySuperAdmin"
                          >    
                            {value.userName.length > 1
                              ? `${ value.userName.length } ${ translator.PeopleScreen.recipients }`
                              : value.costCenterName}                                                   
                          </div>
                        </div>}
                        {isRolePEC() ? (
                          <>
                            <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "messageSubjectPECSuperAdmin":"messageSubjectPEC" }>
                              <PostiTooltip
                                title = { this.toolTipView(value, 2) }
                                placement = "bottom-start"
                                arrow = { false }
                                id = "postiMainDataTooltipx"
                              >
                                <div className = "messageSubjectHide">
                                  {browserLanguage === 'en'
                                    ? value.messageSubject
                                    : value.messageSubject}
                                </div>
                              </PostiTooltip>
                              
                            </div>
                            <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "messageBodyPECSuperAdmin":"messageBodyPEC" }>
                              <div className = "messageBodyHide">
                                {browserLanguage === 'en' ? value.messageBody : value.messageBody}
                              </div>
                            </div>
                            <div className = "messageDatePEC">
                              <Tooltip
                                title = { this.toolTipView(value, 1) }
                                placement = "bottom-start"
                                arrow = { false }
                                id = "dateTooltip1"
                              >
                                <div>
                                  {browserLanguage === 'en'
                                    ? value.messageCreatedTime
                                    : value.messageCreatedTime}
                                  /{value.messageBy && (name = value.messageBy.split(' '))}
                                  {value.messageBy && value.messageBy.split(' ').length === 1 ?
                                    (initial =  name.shift().charAt(0)) : (initial =  name.shift().charAt(0) + name.pop().charAt(0)) }
                                </div>
                              </Tooltip>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className = {
                  !isRolePEC() ? 'homepageFooterContainer' : 'homepageFooterContainerRoles'
                }
              >
                <div style = { { border: '1px solid #C2C9D1', marginRight: '4px' } } />
                <div className = "commonOuterFooter">
                  <div style = { { display: 'flex', alignItems: 'center', width: '40%' } }>
                    <div
                      className = {
                        selectedPeopleList.length > 0
                          ? 'peopleFooterTextEnable'
                          : 'peopleFooterTextDisable'
                      }
                    />
                  </div>
                  <div className = "commonInnerFooter">
                    {sentData.length > 0 ? (
                      <button
                        type = "button"
                        className = {
                          browserLanguage === 'en'
                            ? 'messageFooterSelectButton'
                            : 'messageFooterSelectButton1'
                        }
                        onClick = { this.onSelectAllClick }
                      >
                        {isAllSelected
                          ? translator.VIPContactScreen.deselectAll
                          : translator.VIPContactScreen.selectAll}
                      </button>
                    ) : (
                      <button
                        type = "button"
                        className = {
                          browserLanguage === 'en'
                            ? 'messageFooterSelectButtonDisable'
                            : 'messageFooterSelectButtonDisable1'
                        }
                        onClick = { this.onSelectAllClick }
                      >
                        {isAllSelected
                          ? translator.VIPContactScreen.deselectAll
                          : translator.VIPContactScreen.selectAll}
                      </button>
                    )}
                    <div
                      aria-hidden = "true"
                      className = {
                        selectedPeopleList.length > 0 && decoded.roles[ 0 ]!="PEC.ADMIN.GAMIFICATION"
                          ? 'messageArchiveEnable'
                          : 'messageArchiveDisable'
                      }
                      onClick = { () => {
                        this.setState({
                          archive: true,
                          checkboxCounter: 0,
                        });
                        this.archiveMessages();
                      } }
                    >
                      {translator.messageScreen.archive}
                    </div>
                    <Button
                      disabled = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? true : false }
                      size = "sm"
                      id = "newRewardButton"
                      onClick = { () => {
                        checkboxCounter === 1 && 
                        selectedPeopleList.length === 1 || new Set(this.state.selectedPeopleUserSourceSystemId).size == 1
                          ? this.setState({ flagTwoWayMessageScreen: true })
                          : this.setState({ flagMessageScreen: true });
                      } }
                    >
                      {translator.messageScreen.newMessage}
                    </Button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel id = "id-tab3" value = "tab3">
              <div
                style = { {
                  display: 'flex',
                  alignItems: 'center',
                  height: '56px',
                  padding: '0px 24px 0px 24px',
                } }
              >
                <div className = { isRolePEC() ? 'senderTableHeading1' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.recipients}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(1, "A") }
                  />
                </div>
                {decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" &&
                <div className = { isRolePEC() ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? 'ccNameSentboxSuperAdmin1' : 'subjectTableHeading1' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.PeopleDetails.costCenter}</div>
                  {/* <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(2,"S") }
                  /> */}
                </div>}
                <div className = { isRolePEC() ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? 'subjectTableHeading1superAdmin' : 'subjectTableHeading1' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.title}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(2, "A") }
                  />
                </div>
                <div className = { isRolePEC() ? decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? 'subjectTableHeading2SuperAdmin' :  'subjectTableHeading2' : 'peopleTableHeader' }>
                  <div className = "messageHeaderText">{translator.messageScreen.message}</div>
                  <img
                    aria-hidden = "true"
                    alt = "NoImage"
                    src = { require('../../images/sort.png') }
                    style = { { cursor: 'pointer' } }
                    onClick = { () => this.sortFunction(3, "A") }
                  />
                </div>
                {isRolePEC() && (
                  <div className = "exceptionalRewardsTableHeading1">
                    <div className = "messageHeaderText">{translator.messageScreen.date}</div>
                    <img
                      aria-hidden = "true"
                      alt = "NoImage"
                      src = { require('../../images/sort.png') }
                      style = { { cursor: 'pointer' } }
                      onClick = { () => this.sortFunction(4, "A") }
                    />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <div className = "messageHeaderText">{translator.messageScreen.by}</div>
                    <img
                      aria-hidden = "true"
                      alt = "NoImage"
                      src = { require('../../images/sort.png') }
                      style = { { cursor: 'pointer' } }
                      onClick = { () => this.sortFunction(4, "A") }
                    />
                  </div>
                )}
              </div>
              <div style = { { border: '1px solid #000', margin: '0 40px 0 24px' } } />
              <div className = "checkboxBorder">
                {archiveData &&
                  archiveData.map((value, index) => (
                    <div key = { value.index }>
                      <div className = "checkboxInnerBorder1">
                        <div className = "checkboxInnerBorder2">
                          <Checkbox
                            id = { `checkbox-${ index }` }
                            onChange = { (e) => this.onCheckboxClick(e, value) }
                            checked = { selectedPeopleList.includes(value.id[ 0 ]) }
                            value = { value.id }
                          />
                          <div aria-hidden = "true" className = "avatarhover">
                            <div>
                              {value.userName.length > 1 ? (
                                <div>
                                  <AvatarGroup className = "primeAvatarGrp">
                                    {value.userName.length > 1 ? (
                                      <Avatar
                                        label = { `${ value.userName.length }` }
                                        size = "large"
                                        shape = "circle"
                                        style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                        className = "primeCountAvatar"
                                      />
                                    ) : null}
                                    {value.userName.map((nameValue, i) => {
                                      if (i < 2) {
                                        return (
                                        
                                          <Avatar
                                            label = { nameValue && nameValue.charAt(0) }
                                            size = "large"
                                            shape = "circle"
                                            style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                            className = {
                                              i === 0 ? 'primeFirstAvatar' : 'primeSecondAvatar'
                                            }
                                          />
                                        
                                        );
                                      }
                                      return null;
                                    })}
                                  </AvatarGroup>
                                  <div className = "arrowCSS">
                                    {value.messageType == "I" ? <img
                                      aria-hidden = "true"
                                      alt = "NoImage"
                                      src = { require('../../images/ReceivedSymbol.png') }
                                      style = { { cursor: 'pointer', zIndex:500 } }
                                    /> : <img
                                      aria-hidden = "true"
                                      alt = "NoImage"
                                      src = { require('../../images/SentSymbol.png') }
                                      style = { { cursor: 'pointer', zIndex:500 } }
                                    />}
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <Avatar
                                    image = { value.userProfileImage !== null && value.userProfileImage }
                                    label = { value.userProfileImage === null && value.userName[ 0 ].charAt(0) }
                                    size = "large"
                                    shape = "circle"
                                    style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                                    className = "primeSingleAvatar"
                                  />
                                  <div className = "arrowCSS">
                                    {value.messageType == "I" ? <img
                                      aria-hidden = "true"
                                      alt = "NoImage"
                                      src = { require('../../images/ReceivedSymbol.png') }
                                      style = { { cursor: 'pointer' } }
                                    /> : <img
                                      aria-hidden = "true"
                                      alt = "NoImage"
                                      src = { require('../../images/SentSymbol.png') }
                                      style = { { cursor: 'pointer' } }
                                    />}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                          <PostiTooltip
                            title = { this.toolTipView(value, 3) }
                            placement = "bottom-start"
                            arrow = { false }
                            id = "postiMainDataTooltipx"
                          >
                            <div className = "messengerName">
                              {value.userName.length > 1
                                ? `${ value.userName.length } ${ translator.PeopleScreen.recipients }`
                                : value.userName}
                            </div>
                          </PostiTooltip>
                        </div>
                        {decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" &&
                        <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "costCenter2SentBoxSuperAdmin1":"checkboxInnerBorder2" }>
                          <div
                            className = "ccNameSentboxBodySuperAdmin"
                          >    
                            { value.costCenterName}                                                   
                          </div>
                        </div>}
                        {isRolePEC() ? (
                          <>
                            <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "messageSubjectPECSuperAdmin":"messageSubjectPEC" }>
                              <PostiTooltip
                                title = { this.toolTipView(value, 2) }
                                placement = "bottom-start"
                                arrow = { false }
                                id = "postiMainDataTooltipx"
                              >
                                <div className = "messageSubjectHide">
                                  {browserLanguage === 'en'
                                    ? value.messageSubject
                                    : value.messageSubject}
                                </div>
                              </PostiTooltip>
                            </div>
                            <div className = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? "messageBodyPECSuperAdmin" : "messageBodyPEC" }>
                              <div className = "messageBodyHide">
                                {browserLanguage === 'en' ? value.messageBody : value.messageBody}
                              </div>
                            </div>
                            <div className = "messageDatePEC">
                              {value.messageSubject != null && (
                                <Tooltip
                                  title = { this.toolTipView(value, 1) }
                                  placement = "bottom-start"
                                  arrow = { false }
                                  id = "dateTooltip1"
                                >
                                  <div>
                                    {browserLanguage === 'en'
                                      ? value.messageCreatedTime
                                      : value.messageCreatedTime}
                                  /{value.messageBy && (name = value.messageBy.split(' '))}
                                    {value.messageBy && value.messageBy.split(' ').length === 1 ?
                                      (initial = name.shift().charAt(0)) : (initial = name.shift().charAt(0) + name.pop().charAt(0)) }
                                  </div>
                                </Tooltip>
                              )}
                              {value.messageSubject == null && (
                                // <Tooltip
                                //   title = { this.toolTipView(value, 1) }
                                //   placement = "bottom-start"
                                //   arrow = { false }
                                //   id = "dateTooltip1"
                                // >
                                <div>
                                  {browserLanguage === 'en'
                                    ? moment(value.messageSentTime).format('DD.MM.YYYY HH:MM')
                                    : moment(value.messageSentTime).format('DD.MM.YYYY HH:MM')}
                                </div>
                                // </Tooltip>
                              )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className = {
                  !isRolePEC() ? 'homepageFooterContainer' : 'homepageFooterContainerRoles'
                }
              >
                <div style = { { border: '1px solid #C2C9D1', marginRight: '4px' } } />
                <div className = "commonOuterFooter">
                  <div style = { { display: 'flex', alignItems: 'center', width: '40%' } }>
                    <div
                      className = {
                        selectedPeopleList.length > 0
                          ? 'peopleFooterTextEnable'
                          : 'peopleFooterTextDisable'
                      }
                    />
                  </div>
                  <div className = "commonInnerFooter">
                    {archiveData.length > 0 ? (
                      <button
                        type = "button"
                        className = {
                          browserLanguage === 'en'
                            ? 'messageFooterSelectButton'
                            : 'messageFooterSelectButton1'
                        }
                        onClick = { this.onSelectAllClick1 }
                      >
                        {isAllSelected
                          ? translator.VIPContactScreen.deselectAll
                          : translator.VIPContactScreen.selectAll}
                      </button>
                    ) : (
                      <button
                        type = "button"
                        className = {
                          browserLanguage === 'en'
                            ? 'messageFooterSelectButtonDisable'
                            : 'messageFooterSelectButtonDisable1'
                        }
                        onClick = { this.onSelectAllClick1 }
                      >
                        {isAllSelected
                          ? translator.VIPContactScreen.deselectAll
                          : translator.VIPContactScreen.selectAll}
                      </button>
                    )}
                    <div
                      aria-hidden = "true"
                      className = {
                        selectedPeopleList.length > 0 && decoded.roles[ 0 ]!="PEC.ADMIN.GAMIFICATION"
                          ? 'messageArchiveEnable'
                          : 'messageArchiveDisable'
                      }
                      onClick = { () => {
                        this.setState({
                          checkboxCounter: 0,
                        });
                        selectedPeopleMessageType === "S" ? this.unarchiveMessages() :this.unarchiveMessages1();
                      } }
                    >
                      {translator.messageScreen.unarchive}
                    </div>
                    <Button
                      disabled = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? true : false }
                      size = "sm"
                      id = "newRewardButton"
                      onClick = { () => {
                        checkboxCounter === 1 && 
                        selectedPeopleList.length === 1 || new Set(this.state.selectedPeopleUserSourceSystemId).size == 1
                          ? this.setState({ flagTwoWayMessageScreen: true })
                          : this.setState({ flagMessageScreen: true });
                      } }
                    >
                      {translator.messageScreen.newMessage}
                    </Button>
                  </div>
                </div>
              </div>
            </TabPanel>
            <Tabs
              selectedTab = { currentTab }
              onChange = { (e) => {
                this.setState({ currentTab: e });
              } }
            />
          </TabContext>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
