/* eslint-disable max-lines */
/* eslint-disable global-require */
import React, { Component } from 'react';
import './Configuration.css';
import { SelectButton } from 'primereact/selectbutton';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Button, Checkbox, Loading } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../locales/translation';
import FilterSection from './ConfigurationFilter.js';
import Toastr from '../../shared/Toastr';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants';
import BadgeSelection from './BadgeSelection.js';
import EditDialog from './EditDialog';
import EditConfiguration from './EditConfiguration';
import CustomApiErrorDialog from './CustomApiErrorDialog';

const translator = new LocalizedStrings(translation);
class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerButton: 'Merkit',
      apiflag: false,
      actualJson: null,
      copyJson: null,
      badgeContainer: [],
      locationForSearchFilter: null,
      originalBadgeData: [],
      rowSelectedInBadgesID: [],
      rowSelectedInBadgesInfo: [],
      rowSelectedInLocationsID: [],
      rowSelectedInLocationsInfo: [],
      deleteDialogFlag: false,
      locationCheckFlag: false,
      isLoading: true,
      badgeSelectionDialog: false,
      filteredDataForBadgeSelection: [],
      deleteInformationFlag: false,
      newEditDialogFlag: false,
      badgeResponseFlag: false,
      duplicateData: [],
      columnNames:[ "badgesColumn","daysColumn","slackColumn","targetColumn","pointsColumn","startColumn","locationsColumn" ],
      // Width of the column. Total sum must be equal to 100%.
      columnWidth:[ "27%","7%","7%","7%","7%","18%","27%" ]
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getBadgesData();
      this.getLocationsList();
    }, 3500);
  }

  onEditCancelButton = () => {
    this.setState({ 
      newConifFlag1: false,
      newEditDialogFlag: false
    });
  };

  handleEditApi = () => {
    const { duplicateData,headerButton } = this.state;
    const data = duplicateData;
    const badgeapi = {
      id: Number(data[ 0 ].id),
      targetTimeVariable: Number(data[ 0 ].days),
      slackVariable: Number(data[ 0 ].slack),
      targetValueVariable: data[ 0 ].target,
      pointsVariable: Number(data[ 0 ].points),
      badgeName: data[ 0 ].badge,
    };
    const varapi = {
      requestType: 'Edit',
      applicationId: getJsonBody().applicationId,
      badges: [ { ...badgeapi } ],
    };
    this.commonService.postMethod(apiUrl.newconfiguration, varapi).then(() => {
      Toastr.showSuccessMessage(`${ translator.toasterNotification.configUpdated }`);
      this.onEditCancelButton();
      this.setState({
        rowSelectedInBadgesID: [],
        rowSelectedInBadgesInfo: [],
        rowSelectedInLocationsID: [],
        rowSelectedInLocationsInfo: [],
      });
      if (headerButton === 'Badges' || headerButton === 'Merkit') {
        this.getBadgesData();
      } else {
        this.getLocationsData();
      }
    })
      .catch(() => {
      // Toastr.showErrorMessage('Badges Screen API Error');
        this.setState({
          apiflag: true,
        });
      });
  };

  // To get the badges screen data
  getBadgesData = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.filter = {};
    this.commonService
      .postMethod(apiUrl.getBadgeData, jsonBody)
      .then((res) => {
        this.setState(
          {
            actualJson: JSON.parse(JSON.stringify(res.data)),
            copyJson: JSON.parse(JSON.stringify(res.data)),
            originalBadgeData: JSON.parse(JSON.stringify(res.data)),
            isLoading: false,
            badgeResponseFlag: true,
            locationCheckFlag: false,
          },
          () => {
            this.filterDataForBadgeScreen();
          }
        );
      })
      .catch(() => {
        // Toastr.showErrorMessage('Badges Screen API Error');
        this.setState({
          actualJson: null,
          copyJson: null,
          originalBadgeData: [],
          isLoading: false,
          apiflag: true,
        });
      });
  };

  // To get the location screen data
  getLocationsData = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.filter = {};
    this.commonService
      .postMethod(apiUrl.getLocationData, jsonBody)
      .then((res) => {
        this.setState({
          copyJson: JSON.parse(JSON.stringify(res.data)),
          actualJson: JSON.parse(JSON.stringify(res.data)),
          locationCheckFlag: true,
          isLoading: false,
          badgeResponseFlag: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          apiflag: true,
        });
        // Toastr.showErrorMessage('Location Screen API Error');
      });
  };

  // To get the location List for filter section
  getLocationsList = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.badges = [];
    this.commonService
      .postMethod(apiUrl.getlocationList, jsonBody)
      .then((res) => {
        this.processingLocationList(res.data);
      })
      .catch(() => {
        this.setState({
          apiflag: true,
        })
        // Toastr.showErrorMessage('Location List API Error');
      });
  };

  // To delete the config
  deleteDialogFooterDeleteButtonClicked = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const { rowSelectedInBadgesID,rowSelectedInLocationsID,headerButton } = this.state;
    const selectedRows =
      rowSelectedInBadgesID.length > 0
        ? rowSelectedInBadgesID
        : rowSelectedInLocationsID;
    const { length } = selectedRows;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.idList = selectedRows;
    this.commonService
      .postMethod(apiUrl.deleteConfigurations, jsonBody)
      .then(() => {
        if (length > 1) {
          Toastr.showSuccessMessage(
            `${ length } ${ translator.ConfigurationScreen.multiDeleteConfigSuccess }`
          );
        } else {
          Toastr.showSuccessMessage(`${ translator.ConfigurationScreen.deleteConfigSuccess }`);
        }
        this.setState(
          {
            deleteDialogFlag: false,
            rowSelectedInBadgesID: [],
            rowSelectedInBadgesInfo: [],
            rowSelectedInLocationsID: [],
            rowSelectedInLocationsInfo: [],
          },
          () => {
            if (headerButton === 'Badges' || headerButton === 'Merkit') {
              this.getBadgesData();
            } else {
              this.getLocationsData();
            }
          }
        );
      })
      .catch(() => {
        Toastr.showErrorMessage('Deletion API error ');
        this.setState({
          deleteDialogFlag: false,
          rowSelectedInBadgesID: [],
          rowSelectedInBadgesInfo: [],
          rowSelectedInLocationsID: [],
          rowSelectedInLocationsInfo: [],
        });
      });
  };

  // To get unique locations
  processingLocationList = (data) => {
    this.setState({
      isLoading: true,
    });
    const filtered = data.filter((d) => d.location !== null);
    const uniqueObjArray = [
      ...new Map(filtered.map((item) => [ item.costCenterId, item ])).values(),
    ];
    this.setState({
      locationForSearchFilter: JSON.parse(JSON.stringify(uniqueObjArray)),
      isLoading: false,
    });
  };

  // To get only yyyy-mm--dd
  processDate = (date) => {
    let processedDate = date.split('T')[ 0 ];
    const tokens = processedDate.split('-');
    const mm = tokens[ 1 ];
    const dd = tokens[ 2 ];
    if (mm.charAt(0) === '0') tokens[ 1 ] = mm.replace('0', '');
    if (dd.charAt(0) === '0') tokens[ 2 ] = dd.replace('0', '');
    processedDate = `${ tokens[ 0 ]  }-${  tokens[ 1 ]  }-${  tokens[ 2 ] }`;
    processedDate =
      `${ processedDate.split('-')[ 2 ] 
      }.${ processedDate.split('-')[ 1 ] 
      }.${ processedDate.split('-')[ 0 ] }`;
    return processedDate;
  };

  // Filtering data to send to BadgeSelectionScreen
  filterDataForBadgeScreen = () => {
    const { copyJson } = this.state;
    const data = JSON.parse(JSON.stringify(copyJson));
    const endResult = [];
    data.map((data1) =>  data1.badgeconfigurationList.map((data2) => {
      const body = {};
      body.badge = data2.badge;
      body.badgeFi = data2.badgeFi;
      body.badgeCategory = data2.badgeCategory;
      body.badgeCategoryFi = data2.badgeCategoryFi;
      body.iconURL = data2.iconURL;
      body.locations = data2.locations;
      body.locationsFi = data2.locationsFi;
      body.slack = data2.slack;
      body.target = data2.target;
      body.points = data2.points;
      body.days = data2.days;
      endResult.push(body);
      return body;
    })
    );
    this.setState(
      {
        filteredDataForBadgeSelection: endResult.filter(
          (data1) => data1.locations !== null && data1.locations !== data1.locationsFi
        ),
      },
    );
  };

  badgeHeaderClicked = (badgesCategory) => {
    const { badgeContainer } = this.state;
    if (badgeContainer.includes(badgesCategory)) {
      this.setState({
        badgeContainer: badgeContainer.filter((data) => data !== badgesCategory),
      });
    } else {
      this.setState({
        badgeContainer: badgeContainer.concat(badgesCategory),
      });
    }
  };

  callBackFunctionForBadges = (data, status) => {
    const { actualJson } = this.state;
    if (status === 'Filter') {
      this.setState({
        copyJson: data,
      });
    } else {
      this.setState({
        copyJson: actualJson,
        rowSelectedInBadgesID: [],
        rowSelectedInLocationsID: [],
        rowSelectedInBadgesInfo: [],
        rowSelectedInLocationsInfo: [],
      });
    }
  };

  callBackFunctionForLocations = (data, status) => {
    if (status === 'Filter') {
      this.setState({
        copyJson: data,
      });
    } else {
      this.setState({
        rowSelectedInBadgesID: [],
        rowSelectedInLocationsID: [],
        rowSelectedInBadgesInfo: [],
        rowSelectedInLocationsInfo: [],
      });
      this.getLocationsData();
    }
  };

  // When badges row is selected
  badgesRowSelected = (e, badgeList) => {
    const { rowSelectedInBadgesID,rowSelectedInBadgesInfo } = this.state;
    let { duplicateData } = this.state;
    const selectedRows = rowSelectedInBadgesID;
    let selectedRowInfo = rowSelectedInBadgesInfo;
    if (e.target.checked) {
      selectedRows.push(badgeList.id);
      duplicateData = [ ...selectedRowInfo, { ...badgeList } ];
      selectedRowInfo.push(badgeList);
    } else {
      selectedRows.splice(selectedRows.indexOf(badgeList.id), 1);
      selectedRowInfo = selectedRowInfo.filter((data) => data.id !== badgeList.id);
      duplicateData = [ ...selectedRowInfo ];
    }
    this.setState({
      rowSelectedInBadgesID: selectedRows,
      duplicateData,
      rowSelectedInBadgesInfo: selectedRowInfo,
    });
  };

  // When locations row is selected
  locationsRowSelected = (e, list) => {
    const { rowSelectedInLocationsID,rowSelectedInLocationsInfo } = this.state;
    let { duplicateData } = this.state;
    const selectedRows = rowSelectedInLocationsID;
    let selectedRowInfo = rowSelectedInLocationsInfo;
    if (e.target.checked) {
      selectedRows.push(list.id);
      duplicateData = [ ...selectedRowInfo, { ...list } ];
      selectedRowInfo.push(list);
    } else {
      selectedRows.splice(selectedRows.indexOf(list.id), 1);
      selectedRowInfo = selectedRowInfo.filter((data) => data.id !== list.id);
      duplicateData = [ ...selectedRowInfo ];
    }
    this.setState({
      rowSelectedInLocationsID: selectedRows,
      duplicateData,
      rowSelectedInLocationsInfo: selectedRowInfo,
    });
  };

  // For badge screen
  badgesScreen = () => {
    const { browserLanguage } = this.props;
    const { copyJson,badgeContainer,rowSelectedInBadgesID } = this.state;
    if (copyJson.length > 0)
    {
      return copyJson.map((badges, index1) => {
        // assign value according to language
        const badgesCategory =
          browserLanguage === 'en' ? badges.badgeCategory : badges.badgeCategoryFi;

        return badges.badgeconfigurationList.map((badgeList,index) => 
          <React.Fragment key = { badgeList.index }>
            {index === 0 && (badgesCategory !== "Freight points" && badgesCategory !== "Raskaan kuljetuksen pisteet") ? (
              <p
                className = { index1 === 0 ? 'uniqueBadgeHeading2' : 'uniqueBadgeHeading' }
                onClick = { () => this.badgeHeaderClicked(badgesCategory) }
                aria-hidden = "true"
              >
                {badgesCategory}
                <img
                  className = "downArrow"
                  src = {
                    badgeContainer.includes(badgesCategory)
                      ? require('../../images/upArrow.png')
                      : require('../../images/downArrow.png')
                  }
                  alt = "noImage"
                />
              </p>
            ) : null}
            {badgeContainer.includes(badgesCategory) || badgeList.badge === "Freight" || badgeList.badge === "Line-haul" || badgeList.badgeFi === "Nouto- ja jakelukuljetus" || badgeList.badgeFi === "Runkokuljetus"? null : (
              <div
                style = { {
                  display: 'flex',
                  marginBottom: '-31px',
                } }
                className = "badgesDataWrapperConfiguration"
              >
                <div className = "badgesHeadingData">
                  <div className = "checkboxImgBadgeDataContainer">
                    <Checkbox
                      className = "configurationBadgesCheckbox"
                      onChange = { (e) => {
                        this.badgesRowSelected(e, badgeList);
                      } }
                      checked = { rowSelectedInBadgesID.includes(badgeList.id) }
                    />
                    <img
                      className = "ellipseImage"
                      src = { require('../../images/Ellipse.png') }
                      alt = "noImage"
                      height = { 8 }
                      width = { 8 }
                    />
                    {browserLanguage === 'en' ? badgeList.badge : badgeList.badgeFi}
                  </div>
                </div>
                <p className = "daysHeadingData">{badgeList.days}</p>
                <p className = "slackHeadingData">{badgeList.slack}</p>
                <p className = "targetHeadingData">{badgeList.target}</p>
                <p className = "pointsHeadingData">{badgeList.points}</p>
                <p className = "startHeadingData">{this.processDate(badgeList.start)}</p>
                <p className = "locationsHeadingData">
                  {browserLanguage === 'en'
                    ? badgeList.locations
                    : badgeList.locationsFi}
                </p>
              </div>
            )}
          </React.Fragment>
          
        );
      });
    }
    return null;
  };

  // For Location screen
  LocationsScreen = () => {
    const { browserLanguage } = this.props;
    const { copyJson,badgeContainer,rowSelectedInLocationsID } = this.state;
    if (copyJson.length > 0)
    {
      return copyJson.map((data, index3) => {
        const locationName = data.location;
        return data.badges.map((badges, index1) => 
          badges.badgeCategory != "Freight points" && badges.badgeCategoryFi != "Raskaan kuljetuksen pisteet" ?
            badges.badgeconfigurationList.map((list, index) => 
              <React.Fragment key = { list.index }>
                {index === 0 && index1 === 0 ? (
                  <p
                    className = { index3 === 0 ? 'uniqueBadgeHeading2' : 'uniqueBadgeHeading' }
                    onClick = { () => this.badgeHeaderClicked(locationName) }
                    aria-hidden = "true"
                  >
                    {/* If location is "All" then translate it */}
                    {locationName === 'All' ? translator.ConfigurationScreen.allText : locationName}
                    <img
                      className = "downArrow"
                      src = {
                        badgeContainer.includes(locationName)
                          ? require('../../images/upArrow.png')
                          : require('../../images/downArrow.png')
                      }
                      alt = "noImage"
                    />
                  </p>
                ) : null}
                {badgeContainer.includes(locationName) ? null : (
                  <div
                    style = { {
                      display: 'flex',
                      marginBottom: '-31px',
                    } }
                    className = "badgesDataWrapperConfiguration"
                  >
                    <div className = "badgesHeadingData">
                      <div className = "checkboxImgBadgeDataContainer">
                        <Checkbox
                          className = "configurationLocationCheckbox"
                          onChange = { (e) => {
                            this.locationsRowSelected(e, list);
                          } }
                          checked = { rowSelectedInLocationsID.includes(list.id) }
                        />
                        <img
                          className = "ellipseImage"
                          src = { require('../../images/Ellipse.png') }
                          alt = "noImage"
                          height = { 8 }
                          width = { 8 }
                        />
                        {browserLanguage === 'en' ? list.badge : list.badgeFi}
                      </div>
                    </div>
                    <p className = "daysHeadingData">{list.days}</p>
                    <p className = "slackHeadingData">{list.slack}</p>
                    <p className = "targetHeadingData">{list.target}</p>
                    <p className = "pointsHeadingData">{list.points}</p>
                    <p className = "startHeadingData">{this.processDate(list.start)}</p>
                    <p className = "locationsHeadingData">
                      {browserLanguage === 'en' ? list.locations : list.locationsFi}
                    </p>
                  </div>
                )}
              </React.Fragment>
            
            ) : null
        );
      });
    }
    return null;
  };

  // to check for edit
  checkForEdit = () => {
    const { rowSelectedInBadgesInfo,rowSelectedInLocationsInfo } = this.state;
    if (
      rowSelectedInBadgesInfo.length === 0 &&
      rowSelectedInLocationsInfo.length === 0
    ) {
      return false;
    } 
    const selectedRows =
        rowSelectedInBadgesInfo.length === 0
          ? rowSelectedInLocationsInfo
          : rowSelectedInBadgesInfo;

    if (selectedRows.length > 1) {
      return false;
    }

    if (selectedRows.filter((e) => e.locations !== e.locationsFi).length === 1) {
      return true;
    } 
    return false;
  };

  onHandleEditData = (e, value) => {
    const { duplicateData } = this.state;
    duplicateData[ 0 ][ value ] = e.target.value;
    this.setState({ duplicateData });
  };

  // Delete dialog
  deleteDialog = () => {
    const { rowSelectedInBadgesID,rowSelectedInLocationsID } = this.state;
    const length =
      rowSelectedInBadgesID.length === 0
        ? rowSelectedInLocationsID.length
        : rowSelectedInBadgesID.length;
    return (
      <Dialog
        showHeader = { false }
        visible
        style = { { width: '477px' } }
        id = "deleteConfigurationDialog"
      >
        <div className = "configDeleteDialogHeader">
          <div className = "configDeleteDialogHeading">
            {length > 1
              ? `${ translator.ConfigurationScreen.deleteConfig } ${ length } ${ translator.ConfigurationScreen.configurationConfig }`
              : translator.ConfigurationScreen.deleteConfiguration}
          </div>
        </div>
        <div className = "configDeleteDialogBody">
          {length > 1
            ? `${ translator.ConfigurationScreen.confirmConfigFirstPart } ${ length } ${ translator.ConfigurationScreen.confirmConfigSecondPart }`
            : translator.ConfigurationScreen.confirmConfig}
        </div>
        <div className = "configDeleteDialogFooter">
          <button
            className = "configDeleteDialogFooterCancelButton"
            onClick = { () => {
              this.setState({
                deleteDialogFlag: false,
              });
            } }
            type = "button"
          >
            {translator.ConfigurationScreen.cancel}
          </button>
          <Button
            className = "configDeleteDialogFooterDeleteButton"
            onClick = { () => {
              this.deleteDialogFooterDeleteButtonClicked();
            } }
          >
            {translator.ConfigurationScreen.delete}
          </Button>
        </div>
      </Dialog>
    );
  };

  // Delete information dialog

  deleteInformationDialog = () => {
    const { browserLanguage } = this.props;
    return( 
      <Dialog
        showHeader = { false }
        visible
        style = { { width: '477px' } }
        id = "deleteConfigurationInformationDialog"
      >
        <div className = "deleteConfigurationInformationDialogHeader">
          {translator.ConfigurationScreen.deleteInfoDialogHeader}
        </div>

        <div className = "deleteConfigurationInformationDialogBody">
          <div>{translator.ConfigurationScreen.deleteInfoDialogBody}</div>
          <div>
            {' '}
            {browserLanguage === 'en'
              ? translator.ConfigurationScreen.deleteInfoDialogBodyLast
              : null}
          </div>
          <div className = "deleteConfigurationInformationDialogFooter">
            <Button
              className = "deleteInformationDialogFooterOkButton"
              onClick = { () => {
                this.setState({
                  deleteInformationFlag: false,
                });
              } }
            >
            Ok
            </Button>
          </div>
        </div>
      </Dialog>)
  }
   
  // checking conditions for delete button
  checkForDelete = () => {
    const { rowSelectedInLocationsInfo,rowSelectedInBadgesInfo } = this.state;
    if (
      rowSelectedInBadgesInfo.length === 0 &&
      rowSelectedInLocationsInfo.length === 0
    ) {
      return false;
    } 
    const selectedRows =
        rowSelectedInBadgesInfo.length === 0
          ? rowSelectedInLocationsInfo
          : rowSelectedInBadgesInfo;
    if (selectedRows.filter((e) => e.locations !== e.locationsFi).length > 0) {
      return false;
    } 
    return true;
  };

  render() {
    const { browserLanguage } = this.props;
    const { apiflag, columnNames, columnWidth, newConifFlag1,originalBadgeData,locationForSearchFilter,copyJson,duplicateData,locationCheckFlag,badgeResponseFlag,deleteInformationFlag,deleteDialogFlag,newEditDialogFlag,isLoading,headerButton,badgeSelectionDialog,filteredDataForBadgeSelection } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        {apiflag === true ? <CustomApiErrorDialog /> : null}
        {newConifFlag1 ? (
          <EditConfiguration
            editData = { duplicateData }
            onHandleEditData = { this.onHandleEditData }
            onEditCancelButton = { this.onEditCancelButton }
            handleEditApi = { this.handleEditApi }
          />
        ) : null}
        {newEditDialogFlag ? (
          <EditDialog onEditCancelButton = { this.onEditCancelButton } />
        ) : null}
        <div className = "wholeConfigurationContainer">
          {badgeSelectionDialog && (
            <BadgeSelection
              frontApiData = { filteredDataForBadgeSelection }
              getBadgesData = { this.getBadgesData }
              getLocationsData = { this.getLocationsData }
              headerButton = { headerButton }
              onCancel = { () => {
                this.setState({
                  badgeSelectionDialog: false,
                });
              } }
            />
          )}
          <Dialog visible = { isLoading } id = "loader">
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
          {/* To dispaly delete dialog */}
          {deleteDialogFlag === true ? this.deleteDialog() : null}
          {/* To display delete information dialog */}
          {deleteInformationFlag === true ? this.deleteInformationDialog() : null}
          <div className = "configurationLeftSection">
            <div className = "configurationLeftSectionHeaderContainer">
              <div className = "configurationHeader">
                {translator.ConfigurationScreen.configurations}
              </div>
              <SelectButton
                id = "headerButtons"
                value = {
                  headerButton === 'Badges' || headerButton === 'Merkit'
                    ? translator.ConfigurationScreen.badgesToggle
                    : translator.ConfigurationScreen.locationsToggle
                }
                options = { [
                  translator.ConfigurationScreen.badgesToggle,
                  translator.ConfigurationScreen.locationsToggle,
                ] }
                onChange = { (e) => {
                  if (e.value !== null) {
                    if (e.value === translator.ConfigurationScreen.badgesToggle) {
                      this.setState(
                        {
                          locationCheckFlag: false,
                          isLoading: true,
                        },
                        () => {
                          this.getBadgesData();
                        }
                      );
                    } else {
                      this.getLocationsData();
                      this.setState({
                        isLoading: true,
                      });
                    }
                    this.setState({
                      headerButton: e.value,
                      badgeContainer: [],
                      // Whenever toogle button clicked selected row becomes unselected
                      rowSelectedInBadgesID: [],
                      rowSelectedInLocationsID: [],
                      rowSelectedInBadgesInfo: [],
                      rowSelectedInLocationsInfo: [],
                    });
                  }
                } }
              />
            </div>
            <div className = "tableHeadings">
              { columnNames.map((data,index) => <p className = "commonHeadingConfigScreen" style = { { width: columnWidth[ index ] } }>{translator.ConfigurationScreen[ data ]}</p>)}
            </div>

            <div className = "tableBody">
              {badgeResponseFlag === true
                ? this.badgesScreen()
                : locationCheckFlag === true
                  ? this.LocationsScreen()
                  : null}
            </div>
            <div className = "configurationFooter">
              <hr className = "configurationFooterLine" />
              <div className = "configurationFooterLineWholeWrapper">
                <div className = "configurationFooterLineLeft">
                  <button
                    type = "button"
                    // To check if any row selected then enable css otherwise disabled css
                    className = {
                      this.checkForDelete() === true
                        ? 'newConfigurationDeleteButton'
                        : 'newConfigurationDeleteButtonDisable'
                    }
                    onClick = { () => {
                      if (this.checkForDelete() === true) {
                        this.setState({
                          deleteDialogFlag: true,
                        });
                      } else {
                        this.setState({
                          deleteInformationFlag: true,
                        });
                      }
                    } }
                  >
                    {translator.ConfigurationScreen.delete}
                  </button>
                </div>
                <div className = "configurationFooterLineRight">
                  <button
                    type = "button"
                    className = {
                      this.checkForEdit() === true
                        ? 'newConfigurationEditButton'
                        : 'newConfigurationEditButtonDisable'
                    }
                    onClick = { () =>
                      this.checkForEdit() === true ?  this.setState({ newConifFlag1: true }) : this.setState({ newEditDialogFlag: true })
                    }
                    style = { {
                      marginTop: '10px',
                    } }
                  >
                    {translator.ConfigurationScreen.edit}
                  </button>
                  <Button
                    className = "newConfigurationButton"
                    onClick = { () =>
                      this.setState({
                        badgeSelectionDialog: true,
                      })
                    }
                  >
                    {translator.ConfigurationScreen.configButton}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className = "configurationRightSection">
            <div
              className = "configurationRightSectionHeaderContainer"
              style = { { position: 'fixed', width: '100%', zIndex: '3' } }
            >
              <div className = "configurationFilterHeader">
                {translator.ConfigurationFilter.filters}
              </div>
            </div>
            {copyJson !== null && locationForSearchFilter !== null ? (
              <FilterSection
                data = { JSON.parse(JSON.stringify(originalBadgeData)) }
                // For location search in filter section
                locationData = { locationForSearchFilter }
                // To check which page are we currenlty on i.e. Badges or Locations
                currentPage = { headerButton }
                parentCallbackForBadges = { this.callBackFunctionForBadges }
                parentCallbackForLocations = { this.callBackFunctionForLocations }
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => 
  ({
    browserLanguage: state.browserLanguage,
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
