/* eslint-disable max-lines */
/* eslint-disable global-require */
import React, { Component } from 'react';
import './Badges.css';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Loading, Search } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../locales/translation';
import FilterSection from './OrganisedBadge';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants';
import CustomApiErrorDialog from '../Configuration/CustomApiErrorDialog';

const translator = new LocalizedStrings(translation);
class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiflag: false,
      actualJson: null,
      copyJson: null,
      badgeContainer: [],
      isLoading: true,
      badgeResponseFlag: false,
      searchText:null,
      selectedRowID:null,
      selectedRowWholeData:null,
      columnName:[ "badge","days","slack","daysPlusSlack","target","points","notes" ],
      // Width of column. Sum must be equal to 100.
      columnWidth:[ "25%","7%","7%","12%","9%","15%","25%" ],
      selectedRowHeading:null
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getBadgesData();
    }, 3500);
  }
 
  componentDidUpdate(prevProps) {
    const { selectedCostCenter, browserLanguage } = this.props;
    const  { copyJson } = this.state;
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.getBadgesData();
    }
    if( prevProps.browserLanguage !==  browserLanguage && copyJson !== null)
    {
      this.setState({
        selectedRowHeading:this.findSelectedHeading()
      })
    }
  }

  // To get the badges screen data
  getBadgesData = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.getDataForBadgeScreen, jsonBody)
      .then((res) => {
        this.setState(
          {
            actualJson: JSON.parse(JSON.stringify(res.data.badgesResponseModels)),
            copyJson: JSON.parse(JSON.stringify(res.data.badgesResponseModels)),
            isLoading: false,
            badgeResponseFlag: true,
            selectedRowID:res.data.badgesResponseModels[ 1 ].badges[ 0 ].id,
            selectedRowWholeData:res.data.badgesResponseModels[ 1 ].badges[ 0 ],
            selectedRowHeading:browserLanguage==="en"?res.data.badgesResponseModels[ 1 ].badgeCategory:res.data.badgesResponseModels[ 1 ].badgeCategoryFi
          }
        );
      })
      .catch(() => {
        // Toastr.showErrorMessage('Badges Screen API Error');
        this.setState({
          actualJson: null,
          copyJson: null,
          isLoading: false,
          apiflag: true,
        });
      });
  };

  // Expand colapse row
  badgeHeaderClicked = (badgesCategory) => {
    const { badgeContainer } = this.state;
    if (badgeContainer.includes(badgesCategory)) {
      this.setState({
        badgeContainer: badgeContainer.filter((data) => data !== badgesCategory),
      });
    } else {
      this.setState({
        badgeContainer: badgeContainer.concat(badgesCategory),
      });
    }
  };

  findSelectedHeading = (  ) =>{
    const { copyJson,selectedRowID } = this.state;
    const { browserLanguage } = this.props;
    const categoryName = copyJson.map((data) =>{
      const body = {};
      body.badgeCategory = data.badgeCategory;
      body.badgeCategoryFi = data.badgeCategoryFi;
      body.badges = data.badges;
      body.badges = body.badges.filter((rowdata)=>rowdata.id ===  selectedRowID )
      return body;
    }).filter((ele)=>ele.badges.length >0)
    if( browserLanguage === "en")
    {
      return categoryName[ 0 ].badgeCategory;
    }
    return categoryName[ 0 ].badgeCategoryFi
  }

  // For badge screen
  badgesScreen = () => {
    const { browserLanguage } = this.props;
    const { copyJson,badgeContainer,selectedRowID } = this.state;
    if (copyJson.length > 0)
    {
      return copyJson.map((badges, index1) => {
        // assign value according to language
        const badgesCategory =
          browserLanguage === 'en' ? badges.badgeCategory : badges.badgeCategoryFi;

        return badges.badges.map((badgeList,index) => 
          <React.Fragment key = { badgeList.index }>
            {index === 0 && (badgesCategory !== "Freight points" && badgesCategory !== "Raskaan kuljetuksen pisteet" )? (
              <div>
                <p
                  className = { index1 === 1 ? 'uniqueBadgeHeading2Badges' : 'uniqueBadgeHeadingBadges' }
                  onClick = { () => this.badgeHeaderClicked(badgesCategory) }
                  aria-hidden = "true"
                >
                  {badgesCategory}
                  <img
                    className = "downArrow"
                    src = {
                      badgeContainer.includes(badgesCategory)
                        ? require('../../images/upArrow.png')
                        : require('../../images/downArrow.png')
                    }
                    alt = "noImage"
                  />
                </p>
              </div>
            ) : null}
            {badgeContainer.includes(badgesCategory) || badgeList.badge === "Freight" || badgeList.badge === "Line-haul" ? null : (
              <div
                className = "badgesDataWrapper"
                style = { {
                  border:selectedRowID === badgeList.id ?"3px solid #EDBDBD":"3px solid white",
                  borderBottom:selectedRowID === badgeList.id ?"3px solid #EDBDBD":"1px solid #EAEDF1",
                } }
                onClick = { () => this.setState({
                  selectedRowID:badgeList.id,
                  selectedRowWholeData:badgeList,
                },() => this.setState({
                  selectedRowHeading:this.findSelectedHeading()
                })) }
                aria-hidden = "true"
              >
                <div className = "imgAndBadgeDataContainer">
                  <img
                    src = { badgeList.iconURL }
                    alt = "noImage"
                    height = { 44 }
                    width = { 44 }
                  />
                  {browserLanguage === 'en' ? badgeList.badgeName : badgeList.badgeNameFi}
                  
                </div>
                <p className = "badgesScreenColumn" style = { { width : "7%" } }>{badgeList.days}</p>
                <p className = "badgesScreenColumn" style = { { width : "7%" } }>{badgeList.slack}</p>
                <p className = "badgesScreenColumn" style = { { width : "12%" } }>{Number(badgeList.days)+Number(badgeList.slack)}</p>
                <p className = "badgesScreenColumn" style = { { width : "9%" } }>{badgeList.target}</p>
                <p className = "badgesScreenColumn" style = { { width : "15%" } }>{badgeList.points}</p>
                <p className = "badgesScreenColumn" style = { { width : "25%" } }>
                  {badgeList.notes}
                </p>
              </div>
            )}
          </React.Fragment>
          
        );
      });
    }
    return null;
  };

  // For search
  onSearch = (e) => {
    const { actualJson } = this.state;
    const { browserLanguage } = this.props;
    if (e) {
      this.setState({
        copyJson:actualJson.map((ele)=>{
          const temp = {};
          temp.badgeCategory = ele.badgeCategory;
          temp.badgeCategoryFi = ele.badgeCategoryFi;
          temp.badges = [];
          if( browserLanguage === "en")
          {
            temp.badges = ele.badges.filter((rowData)=>rowData.badgeName.toLowerCase().includes(e.toLowerCase()))
          }
          else{
            temp.badges = ele.badges.filter((rowData)=>rowData.badgeNameFi.toLowerCase().includes(e.toLowerCase()))
          }
          return temp;
        }).filter((element) => element.badges.length>0)
      })
    } else {
      this.setState({
        copyJson: actualJson,
      });
    }
  };

  render() {
    const { browserLanguage } = this.props;
    const { copyJson,badgeResponseFlag,isLoading, searchText, selectedRowWholeData, columnName, columnWidth, selectedRowHeading,apiflag } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        {apiflag === true ? <CustomApiErrorDialog /> : null}
        <div className = "wholeBadgesContainer">
          <Dialog visible = { isLoading } id = "loader">
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
          <div className = "badgesLeftSection">
            <div className = "badgesLeftSectionHeaderContainer">
              <div className = "badgesHeader">
                { translator.BadgesScreen.badges }
              </div>
              <Search 
                aria-label = { translator.RewardsScreen.searchText1 }
                label = { translator.RewardsScreen.searchText1 }
                message = ""
                value = { searchText }
                onChange = { (e) => {
                  this.setState({
                    searchText:e.target.value
                  })
                  this.onSearch(e.target.value)
                } } 
              />
            </div>
            {/* Table heading */}
            <div className = "tableHeadingsBadges">
              { columnName.map((columnData,index) =>   <p className = "badgesScreenColumn" style = { { width : columnWidth[ index ] } }>{ translator.BadgesScreen[ columnData ] }</p>)}
            </div>
            {/* Table data */}
            <div className = "tableBodyBadges">
              { badgeResponseFlag === true?this.badgesScreen():null}
            </div>
            {/* Footer */}
            <div className = "configurationFooter">
              <hr className = "configurationFooterLine" />
              <div className = "badgesFooterLineWholeContainer">
                <div className = "badgesFooterLineLeftContainer" />
                <div className = "badgesFooterLineRightContainer">
                  {/* <Button
                    className = "efficiencyTargetButton"
                  >
                    Efficiency targets
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className = "BadgesRightSection">
            <div
              className = "badgesRightSectionHeaderContainer"
              style = { { position: 'fixed', width: '100%', zIndex: '3' } }
            >
              <div className = "badgesFilterHeader">
                { selectedRowHeading }
                {selectedRowWholeData && (<img
                  src = { selectedRowWholeData.iconURL }
                  alt = "noImage"
                  height = { 88 }
                  width = { 88 }
                  className = "organizedBadgeImage"
                />)}
              </div>
            </div>
            {copyJson !== null && (
              <FilterSection
                data = { selectedRowWholeData } 
              />
            ) }
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => 
  ({
    browserLanguage: state.browserLanguage,
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Badges);
