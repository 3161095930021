import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../../locales/translation.js';
import './WeatherQuadrant.css';

const translator = new LocalizedStrings(translation);
class WeatherQuadrant extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  hourlyWeatherRender = () => {
    const Arr = [];
    const { hourlyWeatherData }= this.props;
    for (let i = 1; i < 9; i+=1) {
      const column = (
        <div style = { { width: '12.5%' } } key = { i }>
          <div className = "widgetHourText">
            {i === 1
              ? translator.HomePage.now
              : new Date(hourlyWeatherData.hourly[ i ].dt * 1000).getHours()}
          </div>
          <img
            alt = "error"
            className = "widgetImage"
            src = { `${ process.env.REACT_APP_WEATHER_IMAGE_URL }${ hourlyWeatherData.hourly[ i ].weather[ 0 ].icon }.png` }
          />
          <div className = "widgetTempText">
            {Math.round(hourlyWeatherData.hourly[ i ].temp)}&deg;
          </div>
        </div>
      );
      Arr.push(column);
    }
    return Arr;
  };

  render() {
    const { browserLanguage , weatherData ,hourlyWeatherData }= this.props; 
    translator.setLanguage(browserLanguage);
    return (
      <div>
        {hourlyWeatherData && weatherData && (
          <>
            <div
              style = { {
                display: 'flex',
                paddingTop: '33px',
                alignItems: 'center',
              } }
            >
              <img
                alt = "error"
                style = { { width: '100px', aspectRatio: 1 } }
                src = { `${ process.env.REACT_APP_WEATHER_IMAGE_URL }${ hourlyWeatherData.hourly[ 1 ].weather[ 0 ].icon }.png` }
              />
              <div className = "currentTempText">
                {Math.round(hourlyWeatherData.hourly[ 1 ].temp)}&deg;
              </div>
            </div>
            <div
              style = { {
                paddingTop: '40px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              } }
            >
              <div className = "cityName">{weatherData.city.name}</div>
              <div className = "minMaxTempText">
                {Math.round(hourlyWeatherData.daily[ 0 ].temp.max)}
                &deg; | {Math.round(hourlyWeatherData.daily[ 0 ].temp.min)}
                &deg;
              </div>
            </div>
            <div style = { { border: '1px solid #E0E0E0', margin: '13px 0' } } />
            <div style = { { display: 'flex' } }>{this.hourlyWeatherRender()}</div>
            <div style = { { border: '1px solid #E0E0E0', margin: '13px 0' } } />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WeatherQuadrant);
