import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../../locales/translation.js';
import './WeatherScreenRightSection.css';
import WeatherQuadrant from '../../Homepage/WeatherQuadrant/WeatherQuadrant.js';

const translator = new LocalizedStrings(translation);

class WeatherScreenRightSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { browserLanguage , weatherData , hourlyWeatherData , weatherLogData } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <div style = { { paddingBottom: '8vh' } }>
        <div className = "todaysWeatherHeading">{translator.WeatherScreen.todaysWeather}</div>
        <div style = { { padding: '24px' } }>
          <WeatherQuadrant
            weatherData = { weatherData }
            hourlyWeatherData = { hourlyWeatherData }
          />
        </div>
        <div className = "notificationLogText">{translator.WeatherScreen.notificationsLog}</div>
        {weatherLogData.map((data, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key = { index }
            style = { {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 24px 10px 24px',
            } }
          >
            <div style = { { display: 'flex', alignItems: 'center' } }>
              <img src = { data.badgeImage } alt = "" height = "44px" width = "44px" />
              <div className = "notificationLogBadgeName">
                {browserLanguage === 'en'
                  ? data.achievementCode
                  : data.achievementCode_Fi}
              </div>
            </div>
            <div className = "timeTextNotificationLog">
              {`${
                data.manualAwardIndicator
                  ? browserLanguage === 'fi'
                    ? 'Manuaalinen'
                    : 'manual'
                  : browserLanguage === 'fi'
                    ? 'automaattinen'
                    : 'automatic'
              }   ${ data.time }`}
            </div>
          </div>
        ))}

        <div className = "weatherScreenRightSectionFooter">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div style = { { height: '8vh' } } />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WeatherScreenRightSection);
