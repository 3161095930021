/* eslint-disable global-require */
/* eslint-disable max-lines */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import './PeopleDetailsPEC.css';
import { Button } from '@postidigital/posti-components';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import PeopleEdit from './PeopleEditPEC';
import Avatar from '../../components/Avatar/Avatar';
import { translation } from '../../locales/translation.js';
import { getJsonBody, getFirstAndLastName } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants.js';
import { CommonService } from '../../service/CommonService.js';

const translator = new LocalizedStrings(translation);
export class PeopleDetailsPEC extends Component {
  constructor(props) {
    super(props);
    const { selectedEmployeeData } = this.props
    this.state = {
      displayPoints: {},
      displayData: {},
      expand: false,
      closeState: true,
      firstName: '',
      lastName: '',
      groupImagePath: selectedEmployeeData.groupImagePath,
      startDate: moment(selectedEmployeeData.dojPosti).format('DD-MM-YYYY'),
      routeMasterStartDate: moment(selectedEmployeeData.dojRM).format('DD-MM-YYYY'),
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    const { selectedEmployeeData } = this.props
    const name = getFirstAndLastName(selectedEmployeeData.firstName);
    this.setState({
      firstName: name.firstNameInitial,
      lastName: name.lastNameInitial,
    });
    this.getDriverDetails();
    this.getUserPoints();
  }

  getDriverDetails = () => {
    const { selectedEmployeeData, OnHide } = this.props
    const jsonBody = getJsonBody();
    jsonBody.EmployeeId = selectedEmployeeData.userSourceSystemId;
    jsonBody.EntityCode = 'en';
    this.commonService
      .postMethod2(apiUrl.peopleDetails, jsonBody)
      .then((res) => {
        this.setState({ displayData: res });
      })
      .catch(() => OnHide());
  };

  getUserPoints = () => {
    const { selectedEmployeeData, OnHide } = this.props
    const jsonBody = getJsonBody();
    jsonBody.applicationId = 4;
    jsonBody.userSourceSystemId = selectedEmployeeData.userSourceSystemId;

    this.commonService
      .postMethod(apiUrl.getUserPoints, jsonBody)
      .then((res) => {
        if (res) {
          this.setState({ displayPoints: res });
        }
      })
      .catch(() => OnHide());
  };

  leftSectionContainer = () => {
    const { selectedEmployeeData, browserLanguage, selectedCostCenter } = this.props
    const { groupImagePath, firstName, lastName, startDate, routeMasterStartDate, 
      displayData, displayPoints } = this.state
    translator.setLanguage(browserLanguage);
    const experienceLevel =
      browserLanguage === 'fi'
        ? selectedEmployeeData.roleName_FI
        : selectedEmployeeData.roleName;
    return (
      <div className = "PeopleDetailLeftSection1">
        <div className = "PeopleDetailHeaderLeftContainer1">
          <div className = "team-heading">{selectedEmployeeData.firstName}</div>
          <div className = "peopleAvatarContainer">
            <Avatar
              imageSource = {
                selectedEmployeeData.userImagePath !== null
                  ? selectedEmployeeData.userImagePath
                  : groupImagePath
              }
              name = { selectedEmployeeData.firstName }
              avatarColor = "#FFA894"
              size = { 88 }
              fontSize = "24px"
            />
          </div>
        </div>
        <div
          style = { {
            height: '750px',
            paddingTop: '72px',
          } }
        >
          <div
            id = "peopleDetailMainContainer"
            style = { {
              display: 'flex',
              flexDirection: 'column',
              height: '650.55px',
              marginLeft: '32px',
            } }
          >
            <div className = "informationText01" style = { { marginBottom: '19px' } }>
              {translator.PeopleDetails.informationText}
            </div>
            <div className = "flexItems">
              <div className = "leftText">{translator.PeopleDetails.firstName}</div>
              <div className = "rightText">{firstName}</div>
            </div>
            {lastName && lastName.length > 0 && (
              <div className = "flexItems">
                <div className = "leftText">{translator.PeopleDetails.lastName}</div>
                <div className = "rightText">{lastName}</div>
              </div>
            )}
            <div className = "flexItems" style = { { marginTop: '14px' } }>
              <div className = "leftText">{translator.PeopleDetails.empNumber}</div>
              <div className = "rightText">{selectedEmployeeData.userSourceSystemId}</div>
            </div>
            <div className = "flexItems">
              <div className = "leftText">{translator.PeopleDetails.depot}</div>
              <div className = "rightText">{selectedEmployeeData.costCenter}</div>
            </div>

            <div className = "flexItems">
              <div className = "leftText">{translator.PeopleDetails.costCenter}</div>
              <div className = "rightText">{selectedCostCenter.costCenterId}</div>
            </div>

            <div className = "flexItems" style = { { marginTop: '14px' } }>
              <div className = "leftText">{translator.PeopleDetails.postiStartDate}</div>
              <div className = "rightText">{startDate}</div>
            </div>

            <div className = "flexItems">
              <div className = "leftText">{translator.PeopleDetails.routeMasterJoinDate}</div>
              <div className = "rightText">{routeMasterStartDate}</div>
            </div>

            <div className = "flexItems">
              <div className = "leftText">{translator.PeopleDetails.experienceLevel}</div>
              <div className = "rightText">{experienceLevel}</div>
            </div>
            <div className = "flexItems">
              <span className = "informationText1">{translator.peopledetailspec.progress}</span>
              <span
                style = { {
                  width: '132px',
                  fontFamily: 'Montserrat',
                  fontStyle: ' normal',
                  fontWeight: '400',
                  fontSize: '13px',
                  lineHeight: '20.2px',
                  color: '#3b4a57',
                  marginTop: '20px',
                } }
              >
                {translator.peopledetailspec.asOfYesterday}
              </span>
              <div className = "rightText2">
                <span>
                  {translator.PeopleDetails.week}
                </span>
              </div>
              <div className = "rightText3">
                <span>
                  {translator.PeopleDetails.month}
                </span>
              </div>
            </div>
            <div
              style = { {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: '18px',
              } }
            />
            <div className = "flexItems">
              <div className = "leftText">{translator.peopledetailspec.customesatisfaction}</div>
              <div className = "rightText31">
                {displayData.data[ 0 ].previousDayAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 0 ].lastWeekAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 0 ].lastMonthAverageRating}
              </div>
            </div>
            <div className = "flexItems">
              <div className = "leftText">{translator.peopledetailspec.efficiency}</div>
              <div className = "rightText31">
                {displayData.data[ 1 ].previousDayAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 1 ].lastWeekAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 1 ].lastMonthAverageRating}
              </div>
            </div>
            <div className = "flexItems">
              <div className = "leftText">{translator.peopledetailspec.processconformity}</div>
              <div className = "rightText31">
                {displayData.data[ 3 ].previousDayAverageRating >= 90
                  ? translator.peopledetailspec.Superb
                  : displayData.data[ 3 ].previousDayAverageRating >= 80 &&
                    displayData.data[ 3 ].previousDayAverageRating < 90
                    ? translator.peopledetailspec.Good
                    : displayData.data[ 3 ].previousDayAverageRating >= 70 &&
                    displayData.data[ 3 ].previousDayAverageRating < 80
                      ? translator.peopledetailspec.Fair
                      : displayData.data[ 3 ].previousDayAverageRating >= 60 &&
                    displayData.data[ 3 ].previousDayAverageRating < 70
                        ? translator.peopledetailspec.Poor
                        : translator.peopledetailspec.Weak}
              </div>
              <div className = "rightText1">
                {displayData.data[ 3 ].lastWeekAverageRating >= 90
                  ? translator.peopledetailspec.Superb
                  : displayData.data[ 3 ].lastWeekAverageRating >= 80 &&
                    displayData.data[ 3 ].lastWeekAverageRating < 90
                    ? translator.peopledetailspec.Good
                    : displayData.data[ 3 ].lastWeekAverageRating >= 70 &&
                    displayData.data[ 3 ].lastWeekAverageRating < 80
                      ? translator.peopledetailspec.Fair
                      : displayData.data[ 3 ].lastWeekAverageRating >= 60 &&
                    displayData.data[ 3 ].lastWeekAverageRating < 70
                        ? translator.peopledetailspec.Poor
                        : translator.peopledetailspec.Weak}
              </div>
              <div className = "rightText1">
                {displayData.data[ 3 ].lastMonthAverageRating >= 90
                  ? translator.peopledetailspec.Superb
                  : displayData.data[ 3 ].lastMonthAverageRating >= 80 &&
                    displayData.data[ 3 ].lastMonthAverageRating < 90
                    ? translator.peopledetailspec.Good
                    : displayData.data[ 3 ].lastMonthAverageRating >= 70 &&
                    displayData.data[ 3 ].lastMonthAverageRating < 80
                      ? translator.peopledetailspec.Fair
                      : displayData.data[ 3 ].lastMonthAverageRating >= 60 &&
                    displayData.data[ 3 ].lastMonthAverageRating < 70
                        ? translator.peopledetailspec.Poor
                        : translator.peopledetailspec.Weak}
              </div>
            </div>
            <div className = "flexItems">
              <div className = "leftText">{translator.peopledetailspec.delivery}</div>
              <div className = "rightText31">
                {displayData.data[ 2 ].previousDayAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 2 ].lastWeekAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 2 ].lastMonthAverageRating}
              </div>
            </div>
            {displayPoints.data && (
              <div className = "flexItems">
                <div className = "leftText">{translator.peopledetailspec.pointscollected}</div>
                <div className = "rightText31">
                  {displayPoints.data.previousDayAverageRating}
                </div>
                <div className = "rightText1">
                  {displayPoints.data.lastWeekAverageRating}{"*"}
                </div>
                <div className = "rightText1">
                  {displayPoints.data.lastMonthAverageRating}{"*"}
                </div>
              </div>
            )}
            <div className = "flexItems">
              <div className = "leftText">{translator.peopledetailspec.overalldeliveryscore}</div>
              <div className = "rightText31">
                {displayData.data[ 5 ].previousDayAverageRating}
                %
              </div>
              <div className = "rightText1">
                {displayData.data[ 5 ].lastWeekAverageRating}
                %
              </div>
              <div className = "rightText1">
                {displayData.data[ 5 ].lastMonthAverageRating}
                %
              </div>
            </div>
            <div className = "flexItems">
              <div className = "leftText">{translator.peopledetailspec.drivingscoreindex}</div>
              <div className = "rightText31">
                {displayData.data[ 4 ].previousDayAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 4 ].lastWeekAverageRating}
              </div>
              <div className = "rightText1">
                {displayData.data[ 4 ].lastMonthAverageRating}
              </div>
            </div>
            <div className = "pointsMarker">{translator.peopledetailspec.weeklyMothlySum}</div>
            <div className = "informationText01">{translator.peopledetailspec.exceptionalbadge}</div>
            <div
              style = { {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: '18px',
              } }
            />
            {selectedEmployeeData && selectedEmployeeData.userBadgeDetails.length !==0 ? 
              <div className = "dateContainer">
                {selectedEmployeeData.userBadgeDetails.some(
                  (item2) => item2.badgeName === 'Safety Badge'
                ) ? (
                    <div className = "flexItems">
                      <div className = "leftText">{translator.peopledetailspec.safety}</div>
                      <div className = "dateWrapper">
                        {selectedEmployeeData.userBadgeDetails
                          .filter((item) => item.badgeName === 'Safety Badge')
                          .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                            if (index === 0 && item1.badgeAwardedDates.length === 1) {
                              return <div className = "rightText12">{item3}</div>;
                            } 
                            if (index === 0) {
                              return <div className = "rightText12">{item3},</div>;
                            } 
                            if (index === item1.badgeAwardedDates.length-1){
                              return <div className = "rightText11">{item3}</div>;  
                            }
                            return <div className = "rightText11">{item3},</div>;
                          }))}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                {selectedEmployeeData.userBadgeDetails.some(
                  (item2) => item2.badgeName === 'Flexible Badge'
                ) ? (
                    <div className = "flexItems">
                      <div className = "leftText">{translator.peopledetailspec.flexibility}</div>
                      <div className = "dateWrapper">
                        {selectedEmployeeData.userBadgeDetails
                          .filter((item) => item.badgeName === 'Flexible Badge')
                          .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                            if (index === 0 && item1.badgeAwardedDates.length === 1) {
                              return <div className = "rightText12">{item3}</div>;
                            } 
                            if (index === 0) {
                              return <div className = "rightText12">{item3},</div>;
                            } 
                            if (index === item1.badgeAwardedDates.length-1){
                              return <div className = "rightText11">{item3}</div>;  
                            }
                            return <div className = "rightText11">{item3},</div>;
                          }))}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                {selectedEmployeeData.userBadgeDetails.some(
                  (item2) => item2.badgeName === 'Activity Badge'
                ) ? (
                    <div className = "flexItems">
                      <div className = "leftText">{translator.peopledetailspec.activity}</div>
                      <div className = "dateWrapper">
                        {selectedEmployeeData.userBadgeDetails
                          .filter((item) => item.badgeName === 'Activity Badge')
                          .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                            if (index === 0 && item1.badgeAwardedDates.length === 1) {
                              return <div className = "rightText12">{item3}</div>;
                            } 
                            if (index === 0) {
                              return <div className = "rightText12">{item3},</div>;
                            } 
                            if (index === item1.badgeAwardedDates.length-1){
                              return <div className = "rightText11">{item3}</div>;  
                            }
                            return <div className = "rightText11">{item3},</div>; 
                          }))}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                {selectedEmployeeData.userBadgeDetails.some(
                  (item2) => item2.badgeName === 'Kudos Badge'
                ) ? (
                    <div className = "flexItems">
                      <div className = "leftText">Kudos</div>
                      <div className = "rightText">
                        {selectedEmployeeData.userBadgeDetails
                          .filter((item) => item.badgeName === 'Kudos Badge')
                          .map((item1) => `${ item1.badgeAwardedDates } `)}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                {selectedEmployeeData.userBadgeDetails.some(
                  (item2) => item2.badgeName === 'Helper Badge'
                ) ? (
                    <div className = "flexItems">
                      <div className = "leftText">{translator.peopledetailspec.helper}</div>
                      <div className = "dateWrapper">
                        {selectedEmployeeData.userBadgeDetails
                          .filter((item) => item.badgeName === 'Helper Badge')
                          .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                            if (index === 0 && item1.badgeAwardedDates.length === 1) {
                              return <div className = "rightText12">{item3}</div>;
                            } 
                            if (index === 0) {
                              return <div className = "rightText12">{item3},</div>;
                            } 
                            if (index === item1.badgeAwardedDates.length-1){
                              return <div className = "rightText11">{item3}</div>;  
                            }
                            return <div className = "rightText11">{item3},</div>;
                          }))}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                {selectedEmployeeData.userBadgeDetails.some(
                  (item2) => item2.badgeName === 'Extra work Badge'
                ) ? (
                    <div className = "flexItems">
                      <div className = "leftText">{translator.peopledetailspec.extrawork}</div>
                      <div className = "dateWrapper">
                        {selectedEmployeeData.userBadgeDetails
                          .filter((item) => item.badgeName === 'Extra work Badge')
                          .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                            if (index === 0 && item1.badgeAwardedDates.length === 1) {
                              return <div className = "rightText12">{item3}</div>;
                            } 
                            if (index === 0) {
                              return <div className = "rightText12">{item3},</div>;
                            } 
                            if (index === item1.badgeAwardedDates.length-1){
                              return <div className = "rightText11">{item3}</div>;  
                            }
                            return <div className = "rightText11">{item3},</div>;
                          }))}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
              </div> 
              : <div style = { { marginLeft: "180px",marginTop:"30px" } }>{translator.peopledetailspec.noExceptionBadges}</div>}
          </div>
          <div
            style = { {
              border: '1px solid #c2c9d1',
              marginLeft: '32px',
              marginRight: '32px',
              marginTop: '20px',
            } }
          />
          {this.buttonSection()}
        </div>
      </div>
    );
  };

  buttonSection = () => {
    const { expand } = this.state
    const { OnHide } = this.props
    return(
      <div className = "peopleDetailButtonContainer">
        <button
          type = "button"
          className = "editButtonPeople"
          disabled = { expand }
          id = "peopleEditButton"
          onClick = { () => {
            this.setState({ expand: true });
          } }
          style = { {
            backgroundColor: '#FFFFFF',
            color: expand ? '#A7A7A7' : '#0051C2',
          } }
        >
          {translator.PeopleDetails.editText}
        </button>
        <Button
          id = "peopleEdit-nextButton"
          disabled = { expand }
          size = "sm"
          onClick = { OnHide }
        >
          {translator.PeopleDetails.close}
        </Button>
      </div>
      
    )
  }

  render() {
    const { browserLanguage, OnHide, selectedEmployeeData, OnSave, Dialog1 } = this.props
    const { expand, displayData, closeState, } = this.state
    translator.setLanguage(browserLanguage);
    return (
      <Dialog
        id = "people-detail"
        visible = { Dialog1 }
        style = { {
          width: 'auto',
          height: '820px',
        } }
        onHide = { () => {
          OnHide();
        } }
      >
        <div className = "PeopleDetailContainer">
          {Object.keys(displayData).length > 0 && this.leftSectionContainer()}
          <div
            className = {
              expand
                ? 'PeopleDetailsRightSectionExpand1'
                : closeState
                  ? 'PeopleDetailsRightSectionClose'
                  : 'PeopleDetailsRightSection'
            }
          >
            <PeopleEdit
              OnSave = { (e) => {
                this.setState({
                  // teamName: e.teamName,
                  startDate: e.startDate,
                  groupImagePath: e.teamImage,
                });
                OnSave();
              } }
              selectedEmployeeData = { selectedEmployeeData }
              closeTransition = { () => {
                this.setState({ expand: false });
              } }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleDetailsPEC);
