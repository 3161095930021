/* eslint-disable default-param-last */
// language reducer to transfer changed language to screen
const defaultValue = {
  costCenterId: '',
  costCenterName: '',
  latitude: 25.07825,
  longitude: 60.23116,
};
const selectedCostCenterReducer = (state = defaultValue, action) => {
  switch (action.type) {
  case 'ChangeCostCenter':
    return action.payload;
  default:
    return state;
  }
};
export default selectedCostCenterReducer;
