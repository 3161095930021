import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react';
import './SingleContactEdit.css';
import { Button, Input } from '@postidigital/posti-components';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { getJsonBody } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants';
import { CommonService } from '../../service/CommonService.js';
import { translation } from '../../locales/translation';

const translator = new LocalizedStrings(translation);
class SingleContactEdit extends Component {
  constructor(props) {
    super(props);
    const { data, selectedCostCenter } = this.props
    this.state = {
      inputName: data.name,
      inputPhoneNumber: data.contactNumber,
      inputRole: data.role,
      inputColor: data.color,
      // customStatus: data.isCustomContact,
      hiddenStatus: data.isHidden,
      selectedCostCenter: selectedCostCenter.costCenterId,
      totalColors: [
        '#FFC226',
        '#EDBDBD',
        '#FFA894',
        '#A4DEDF',
        '#0051C2',
        '#008A00',
        '#29545C',
        '#C2C9D1',
      ],
    };
    this.commonService = new CommonService();
  }

  cancelButtonClicked = () => {
    const { parentCallback } = this.props
    parentCallback(false);
  };

  updateButtonClicked = () => {
    const jsonBody = getJsonBody();
    const { selectedCostCenter, inputName, inputPhoneNumber, hiddenStatus, inputRole, inputColor } = this.state
    const { data, parentCallback } = this.props
    const subpayload = {
      AppId: jsonBody.applicationId,
      CostCenterId: selectedCostCenter,
      // CostCenterId: '99612095',
      EmployeeId: jsonBody.masterGroupSourceSystemId,
      id: data.id,
      name: inputName,
      contactNumber: String(inputPhoneNumber),
      isHidden: hiddenStatus,
      isCustomContact: false,
      role: inputRole,
      color: inputColor,
      orderNumber: data.orderNumber,
      IsSupervisor: true,
    };
    const payload = [];
    payload.push(subpayload);
    this.commonService
      .postMethod(apiUrl.saveContact, payload)
      .then(() => {
        Toastr.showSuccessMessage('Contact updated');
        parentCallback(false);
      })
      .catch(() => {
        Toastr.showErrorMessage('VIP Contact API Error');
        parentCallback(false);
      });
  };

  render() {
    const { browserLanguage } = this.props
    const { inputName, inputPhoneNumber, inputRole, totalColors, inputColor } = this.state
    translator.setLanguage(browserLanguage);
    return (
      <Dialog
        visible
        style = { { width: '426.48px', height: '820px' } }
        showHeader = { false }
        id = "editSingleContactDialog"
      >
        <div className = "singleEditContactHeaderContainer">
          <div className = "singleEditContactHeader">{translator.EditDialog.vipContact}</div>
        </div>
        <div className = "singleEditContactNameBody">
          <div className = "singleEditContactNameHeader">{translator.EditDialog.name}</div>
          <div className = "singleEditContactNameText">
            <Input
              value = { inputName }
              onChange = { (e) => {
                this.setState({
                  inputName: e.target.value,
                });
              } }
            />
          </div>
          <div className = "singleEditContactNumberHeader">{translator.EditDialog.phoneNumber}</div>
          <div className = "singleEditContactNumberText">
            <Input
              value = { inputPhoneNumber }
              onChange = { (e) => {
                this.setState({
                  inputPhoneNumber: e.target.value,
                });
              } }
            />
          </div>
          <div className = "singleEditContactRoleHeader">{translator.EditDialog.role}</div>
          <div className = "singleEditContactRoleText">
            <Input
              value = { inputRole }
              onChange = { (e) => {
                this.setState({
                  inputRole: e.target.value,
                });
              } }
            />
          </div>
          <div className = "singleEditContactColorHeader">
            {translator.EditDialog.colourIndicationText}
          </div>
          <div className = "singleEditContactColorContainer">
            {totalColors.map((color, index) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                // eslint-disable-next-line react/no-array-index-key
                key = { index }
                onClick = { () => {
                  this.setState({
                    inputColor: color,
                  });
                } }
                aria-hidden = "true"
              >
                <div
                  style = { {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: inputColor === color ? `#0051C2` : 'white',
                  } }
                >
                  <div
                    style = { {
                      width: '34px',
                      height: '34px',
                      borderRadius: '50%',
                      backgroundColor: `${ color }`,
                      border: '1px solid white',
                    } }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div style={{ border: '1px solid #C2C9D1', margin: '0px 32px' }}></div> */}
        <div className = "singleEditContactFooter">
          <div style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } } />
          <div
            style = { {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingTop: '12px',
              gap: '20px',
            } }
          >
            <button type = "button" className = "singleEditContactCancelButton" onClick = { this.cancelButtonClicked }>
              {translator.EditDialog.cancel}
            </button>
            <Button className = "singleEditContactUpdateButton" onClick = { this.updateButtonClicked }>
              {translator.EditDialog.update}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SingleContactEdit);
