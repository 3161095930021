export const BadgeDetails = [
  {
    BadgeName: 'Induction_Name',
    BadgeDescription: 'INDUCTION_BADGE_Description',
    BadgeMessage: 'INDUCTION_BADGE_Message',
    BadgeAcheivmentCode: 'INDUCTION_BADGE',
    BadgeImage: 'induction.png',
    BadgePoints: 10,
  },
  {
    BadgeName: 'Exceptional_Responsibility_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_RESPONSIBILTY_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_RESPONSIBILTY_Message',
    BadgeAcheivmentCode: 'EXCEPTIONAL_BADGE_RESPONSIBILITY',
    BadgeImage: 'responsibility.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Helper_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_HELPER_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_HELPER_Message',
    BadgeAcheivmentCode: 'EXCEPTIONAL_BADGE_HELPER',
    BadgeImage: 'helper.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Achiever_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_ACHIEVER_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_ACHIEVER_Message',
    BadgeAcheivmentCode: 'EXCEPTIONAL_BADGE_ACHIEVER',
    BadgeImage: 'acheiver.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Safety_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_SAFETY_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_SAFETY_Message',
    BadgeAcheivmentCode: 'Safety',
    BadgeImage: 'safety.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Patience__Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_PATIENCE_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_PATIENCE_Message',
    BadgeAcheivmentCode: 'EXCEPTIONAL_BADGE_PATIENCE',
    BadgeImage: 'patience.png',
    BadgePoints: 20,
  },
];
export const BadgeDetailsEMD = [
  // {
  //   BadgeName: 'Induction_Name',
  //   BadgeDescription: 'INDUCTION_BADGE_Description',
  //   BadgeMessage: 'INDUCTION_BADGE_Message',
  //   BadgeAcheivmentCode: 'INDUCTION_BADGE',
  //   BadgeImage: 'induction.png',
  //   BadgePoints: 10,
  // },
  {
    BadgeName: 'Exceptional_Responsibility_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_RESPONSIBILTY_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_RESPONSIBILTY_Message',
    BadgeAcheivmentCode: 'EMD_EXCEPTIONAL_BADGE_RESPONSIBILITY',
    BadgeImage: 'responsibility.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Helper_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_HELPER_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_HELPER_Message',
    BadgeAcheivmentCode: 'EMD_EXCEPTIONAL_BADGE_HELPER',
    BadgeImage: 'helper.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Achiever_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_ACHIEVER_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_ACHIEVER_Message',
    BadgeAcheivmentCode: 'EMD_EXCEPTIONAL_BADGE_ACHIEVER',
    BadgeImage: 'acheiver.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Safety_Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_SAFETY_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_SAFETY_Message',
    BadgeAcheivmentCode: 'Safety',
    BadgeImage: 'safety.png',
    BadgePoints: 20,
  },
  {
    BadgeName: 'Exceptional_Patience__Name',
    BadgeDescription: 'EXCEPTIONAL_BADGE_PATIENCE_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_PATIENCE_Message',
    BadgeAcheivmentCode: 'EMD_EXCEPTIONAL_BADGE_PATIENCE',
    BadgeImage: 'patience.png',
    BadgePoints: 20,
  },
  {
    BadgeName: "Exceptional_Mentor_Name",
    BadgeDescription: 'EXCEPTIONAL_BADGE_MENTOR_Description',
    BadgeMessage: 'EXCEPTIONAL_BADGE_MENTOR_Message',
    BadgeAcheivmentCode: 'EMD_EXCEPTIONAL_BADGE_MENTOR',
    BadgeImage: 'mentor.png',
    BadgePoints: 50,
  },
];
export const PECBadgeDetails = [
  {
    BadgeName: 'Activity_Name',
    BadgeDescription: 'ACTIVITY_BADGE_Description',
    BadgeMessage: 'ACTIVITY_BADGE_Message',
    BadgeAcheivmentCode: 'Activity',
    BadgeImage: 'responsibility.png',
    BadgePoints: 50,
  },
  {
    BadgeName: 'Extra_Work_Name',
    BadgeDescription: 'EXTRA_WORK_BADGE_Description',
    BadgeMessage: 'EXTRA_WORK_BADGE_Message',
    BadgeAcheivmentCode: 'Extra_work',
    BadgeImage: 'helper.png',
    BadgePoints: 50,
  },
  {
    BadgeName: 'Flexibility_Name',
    BadgeDescription: 'FLEXIBILITY_BADGE_Description',
    BadgeMessage: 'FLEXIBILITY_BADGE_Message',
    BadgeAcheivmentCode: 'Flexible',
    BadgeImage: 'acheiver.png',
    BadgePoints: 50,
  },
  {
    BadgeName: 'Helper_Name',
    BadgeDescription: 'HELPER_BADGE_Description',
    BadgeMessage: 'HELPER_BADGE_Message',
    BadgeAcheivmentCode: 'Helper',
    BadgeImage: 'helper1.png',
    BadgePoints: 50,
  },
  {
    BadgeName: 'Safety_Name',
    BadgeDescription: 'SAFETY_BADGE_Description',
    BadgeMessage: 'SAFETY_BADGE_Message',
    BadgeAcheivmentCode: 'Safety',
    BadgeImage: 'patience.png',
    BadgePoints: 50,
  },
  // {
  //   BadgeName: 'Kudos__Name',
  //   BadgeDescription: 'KUDOS_BADGE_Description',
  //   BadgeMessage: 'KUDOS_BADGE_Message',
  //   BadgeAcheivmentCode: 'Kudos',
  //   BadgeImage: 'kudos.png',
  //   BadgePoints: 50,
  // },
];
