/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import './EditConfiguration.css';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Button } from '@postidigital/posti-components';
import { translation } from '../../locales/translation.js';
import NewConfiguration from './NewConfiguration';

const translator = new LocalizedStrings(translation);
function EditConfiguration(props) {
  const { allTargetValue,browserLanguage,editData,selectedBadgeData,cancelButtonPass,onHandleEditData,onEditCancelButton,handleEditApi } = props;
  const [ allTarget, setAllTarget ] = useState(null);
  const [ redirectNewConfigartion ] = useState(false);
  const [ targetvalue, setTargetValue ] = useState(editData);
  
  useEffect(() => {
    setAllTarget(allTargetValue);
    // settotalPageNumber(props.selectedBadgeData.length)
    translator.setLanguage(browserLanguage);
  }, [ allTargetValue, browserLanguage ]);

  return (
    redirectNewConfigartion ? (
      <NewConfiguration
        allTargetValue = { allTarget }
        selectedBadgeData = { selectedBadgeData }
        onClickCancel = { cancelButtonPass }
      />
    ) : (
      <Dialog
        resizable = { false }
        className = "editConfiguration"
        showHeader = { false }
        visible
        style = { {
          width: '426.58px',
          height: '819.28px',
          borderRadius: '16px',
        } }
        id = "editConfigurationDialog"
      >
        <div className = "editConfigurationWrapper">
          <div className = "editConfigurationHeader">{translator.editConfiguration.editconfig}</div>
        </div>
        <div style = { { paddingTop: '34.45px', paddingLeft: '30.87px' } }>
          <div className = "edittabHeading">
            {translator.editConfiguration.modify_}{' '}
            {browserLanguage === 'en' ? editData[ 0 ].badge : editData[ 0 ].badgeFi}{' '}
            {translator.editConfiguration.badgevariable}
          </div>
        </div>

        <div className = "contentsedit">
          {translator.editConfiguration.desc1}{' '}
          {browserLanguage === 'en' ? editData[ 0 ].badge : editData[ 0 ].badgeFi}{' '}
          {translator.editConfiguration.desc2}{' '}
          <span className = "textblue">{editData[ 0 ].target}</span>{' '}
          {translator.editConfiguration.desc3}{' '}
          {browserLanguage === 'en'
            ? editData[ 0 ].badgeCategory
            : editData[ 0 ].badgeCategoryFi}{' '}
          {translator.editConfiguration.desc31}
          <span className = "textblue"> {editData[ 0 ].days} </span>{' '}
          {translator.editConfiguration.desc4}{' '}
          <span className = "textblue">{+editData[ 0 ].days + +editData[ 0 ].slack}</span>{' '}
          {translator.editConfiguration.desc5}
          {/* {props.editData[0].description} */}
        </div>
        <div className = "main-div2">
          <div>
            <div className = "heading">{translator.Varibale1.ttv}</div>
            <div className = "subheading">{translator.Varibale1.ttv1}</div>
          </div>
          <div className = "databox">
            <div>
              <input
                type = "number"
                name = "targetTime"
                className = "textbox"
                value = { editData[ 0 ].days }
                onChange = {
                  editData[ 0 ].badgeCategory === 'Warrior badges' ||
                    editData[ 0 ].badgeCategory === 'Route Master badges' ||
                    editData[ 0 ].badgeCategory === 'Exceptional badges'
                    ? ''
                    : (data) => {
                      targetvalue[ 0 ].days = data.target.value;
                      setTargetValue([ ...targetvalue ]);
                      onHandleEditData(data, 'days');
                    }
                }
              />
            </div>
          </div>
        </div>

        <div className = "main-div2">
          <div>
            <div className = "heading1">{translator.Varibale1.sv}</div>
            <div className = "subheading">{translator.Varibale1.sv1}</div>
          </div>
          <div className = "databox1">
            <div>
              <input
                type = "number"
                className = "textbox"
                name = "targetSlack"
                value = { editData[ 0 ].slack }
                onChange = {
                  editData[ 0 ].badgeCategory === 'Warrior badges' ||
                    editData[ 0 ].badgeCategory === 'Route Master badges' ||
                    editData[ 0 ].badgeCategory === 'Exceptional badges'
                    ? ''
                    : (data) => {
                      targetvalue.slack = data.target.value;
                      setTargetValue([ ...targetvalue ]);
                      onHandleEditData(data, 'slack');
                    }
                }
              />
            </div>
          </div>
        </div>

        <div className = "main-div2">
          <div>
            <div className = "heading1">{translator.Varibale1.tvv}</div>
            <div className = "subheading">{translator.Varibale1.tvv1}</div>
          </div>
          <div className = "databox1">
            <div>
              <input
                type = "number"
                className = "textbox"
                name = "targetValue"
                value = { editData[ 0 ].target }
                onChange = {
                  editData[ 0 ].badgeCategory === 'Warrior badges' ||
                    editData[ 0 ].badgeCategory === 'Route Master badges' ||
                    editData[ 0 ].badgeCategory === 'Exceptional badges'
                    ? ''
                    : (data) => {
                      targetvalue.target = data.target.value;
                      setTargetValue([ ...targetvalue ]);
                      onHandleEditData(data, 'target');
                    }
                }
              />
            </div>
          </div>
        </div>

        <div className = "main-div2">
          <div>
            <div className = "heading1">{translator.Varibale1.pv}</div>
            <div className = "subheading">{translator.Varibale1.pv1}</div>
          </div>
          <div className = "databox1">
            <div>
              <input
                type = "number"
                className = "textbox"
                name = "targetPoints"
                value = { editData[ 0 ].points }
                onChange = { (data) => {
                  targetvalue.points = data.target.value;
                  setTargetValue([ ...targetvalue ]);
                  onHandleEditData(data, 'points');
                } }
              />
            </div>
          </div>
        </div>

        <div
          style = { {
            position: 'absolute',
            bottom: '70px',
            width: '364px',
          } }
        >
          <div
            style = { {
              border: '1px solid #C2C9D1',
              margin: '0px 20px 0px 20px',
              width: '362.03px',
            } }
          />
        </div>

        <div className = "editPageFooterBody">
          <button
            className = "editPageCancelButton"
            // onClick={() => props.onClickCancel()}
            onClick = { () => onEditCancelButton() }
            type = "button"
          >
            {translator.logOut.cancel}
          </button>
          <Button
            className = "editPageNextButton"
            onClick = { handleEditApi }
            style = { {
              height: '36.5px',
            } }
          >
            <div
              style = { {
                fontSize: '16px',
              } }
            >
              {translator.EditDialog.save}
            </div>
          </Button>
        </div>
      </Dialog>
    )
    
  );
}
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditConfiguration);

// export default EditConfiguration;
