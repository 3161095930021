/* eslint-disable default-param-last */
// variable data
const defaultValue = [
  {
    targetTime: '',
    targetSlack: '',
    targetValue: '',
    targetPoints: '',
  },
  {
    targetTime: '',
    targetSlack: '',
    targetValue: '',
    targetPoints: '',
  },
  {
    targetTime: '',
    targetSlack: '',
    targetValue: '',
    targetPoints: '',
  },
  {
    targetTime: '',
    targetSlack: '',
    targetValue: '',
    targetPoints: '',
  },
];

const selectedTargetDataReducer = (state = defaultValue, action) => {
  switch (action.type) {
  case 'ChangeCostCenter':
    return action.payload;
  default:
    return state;
  }
};
export default selectedTargetDataReducer;
