import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Date.css';
import { DateInput } from '@postidigital/posti-components/build/datepicker';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation';

const translator = new LocalizedStrings(translation);
export class Date extends Component {
  constructor(props) {
    super(props);
    this.state = { date1: '', date2: '' };
  }

  render() {
    const { browserLanguage,dateProp,dateProp2 } = this.props;
    const { date1,date2 } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        <div className = { browserLanguage === 'fi' ? 'datesWrapperfi' : 'datesWrapper' }>
          <div className = "dateHeading">{translator.NewConfigDates.heading}</div>
          <div className = "dateSubHeading">{translator.NewConfigDates.subHeading}</div>
          <div className = "desc1">{translator.NewConfigDates.desc1}</div>

          <div className = "startDateInput">
            <DateInput
              id = "date1"
              isOutsideRange = { () => false }
              locale = { browserLanguage }
              firstDayOfWeek = { 0 }
              className = "startingDateCalender"
              label = { translator.NewConfigDates.selectStartDate }
              message = ""
              date = { date1 === '' ? '' : moment(date1) }
              nextMonthButtonAriaLabel = "Move to next month"
              prevMonthButtonAriaLabel = "Move to previous month"
              daySize = { 45 }
              onDateChange = { (data) => {
                if (data !== null && data !== '') {
                  dateProp(data);
                  this.setState({ date1: data });
                } else {
                  this.setState({});
                }
              } }
            />
          </div>
          <div className = "dateSubHeading2">{translator.NewConfigDates.subHeading2}</div>
          <div className = "desc2">{translator.NewConfigDates.desc2}</div>
          <div className = "endDateInput">
            <DateInput
              id = "date2"
              isOutsideRange = { () => false }
              firstDayOfWeek = { 0 }
              className = "endingDateCalender"
              label = { translator.NewConfigDates.selectPublishingDate }
              locale = { browserLanguage }
              message = ""
              date = { date2 === '' ? '' : moment(date2) }
              nextMonthButtonAriaLabel = "Move to next month"
              prevMonthButtonAriaLabel = "Move to previous month"
              daySize = { 44 }
              onDateChange = { (data) => {
                if (data !== null && data !== '') {
                  dateProp2(data);
                  this.setState(
                    {
                      date2: data,
                    },
                  );
                } else {
                  this.setState({});
                }
              } }
            />
          </div>
        </div>

        <div
          style = { {
            position: 'absolute',
            bottom: '70px',
            width: '364px',
          } }
        >
          <div
            style = { {
              border: '1px solid #C2C9D1',
              margin: '0px 20px 0px 20px',
              width: '361px',
            } }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => 
  ({
    browserLanguage: state.browserLanguage,
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Date);
