import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import moment from 'moment';
import 'moment/locale/fi';
import {
  Button,
  Loading,
  ArrowRightIcon,
} from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import jwtDecode from 'jwt-decode';
import { translation } from '../../locales/translation.js';
import './Homepage.css';
import PeopleQuadrant from './PeopleQuadrant/PeopleQuadrant.js';
import WeatherQuadrant from './WeatherQuadrant/WeatherQuadrant.js';
import Toastr from '../../shared/Toastr';
import { CommonService } from '../../service/CommonService.js';
import RewardsScreen from '../Rewards/Rewards';
import TodaysLog from './TodaysLog/TodaysLog.js';
import NewTeam from '../NewTeam/NewTeam.js';
import apiUrl from '../../urlConstants.js';
import { getJsonBody, isRolePEC } from '../../utility/commonUtility.js';
import Champs from '../NewTeam/Champs/Champs.js';
import DailyPulse from './DailyPulseQuadrant/DailyPulse.js';
import EditTeamScreen from '../EditTeam/EditTeam';
import DeleteTeamScreen from '../DeleteTeam/DeleteTeam';
import Greetings from './Greetings.js';
import Messages from '../Messages/MsgSupToDrivers.js';
import CustomApiErrorDialog from '../Configuration/CustomApiErrorDialog.js';

const translator = new LocalizedStrings(translation);
class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiflag: false,
      loading: true,
      loading2: true,
      forecastData: null,
      hourlyWeatherData: null,
      rewardsOverLay: false,
      newTeamDialog: false,
      homeScreenData: null,
      champsBox: false,
      editTeamDialog: false,
      deleteTeamDialog: false,
      greetingData: null,
      showPowerBI: false,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    // const { selectedCostCenter } = this.props;
    setTimeout(() => {
      // const { longitude } = selectedCostCenter;
      // const { latitude } = selectedCostCenter;
      this.getHomeScreenData();
      // this.getWeather(latitude, longitude);
      // this.getHourlyWeather(latitude, longitude);
      this.getGreetingData();
    }, 3500);
  };

  componentDidUpdate(prevProps ) {
    const { selectedCostCenter } = this.props
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      // const { longitude } = selectedCostCenter;
      // const { latitude } = selectedCostCenter;
      this.setState({ loading: true });
      this.getHomeScreenData();
      // this.getWeather(latitude, longitude);
      // this.getHourlyWeather(latitude, longitude);
      this.getGreetingData();
    }
  }

  getHomeScreenData = () => {
    const { browserLanguage, selectedCostCenter } = this.props
    this.setState({
      loading2: true,
    });
    const jsonBody = getJsonBody();
    if(jsonBody.applicationId === 2)
    {
      this.setState({ showPowerBI : true });
    }
    if(jsonBody.applicationId === 5)
    {
      this.setState({ showPowerBI : false });
    }

    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.getWeather(selectedCostCenter.latitude, selectedCostCenter.longitude);
    this.getHourlyWeather(selectedCostCenter.latitude, selectedCostCenter.longitude);
    this.commonService
      .postMethod(apiUrl.getHomeScreenData, jsonBody)
      .then((res) => {
        this.setState({ homeScreenData: res.data, loading2: false });
      })
      .catch(() => {
        this.setState({ loading2: false, apiflag: true });
        Toastr.showErrorMessage('Home Screen API Error');
      });
  };

  // to fetch information of weather
  getWeather(lat, long) {
    this.commonService
      .getWeatherInfo(lat, long)
      .then((data) => {
        if (data) {
          this.setState({ forecastData: data });
        }
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.updateWeather);
      });
  }

  // to fetch hourly information of weather
  getHourlyWeather(lat, long) {
    this.commonService
      .getHourlyWeather(lat, long)
      .then((data) => {
        if (data) {
          this.setState({ hourlyWeatherData: data, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.ToastMessages.updateWeather);
      });
  }

  getGreetingData = () => {
    const { selectedCostCenter } = this.props
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.greetingData, jsonBody)
      .then((res) => {
        this.setState({ greetingData: res.data });
      })
      .catch(() => {
        this.setState({
          apiflag: true
        })
        // Toastr.showErrorMessage(translator.ToastMessages.fetchCostCenterError);
      });
  };

  onMsgDialogClose = () =>{
    this.setState({
      flagMessageScreen:false
    })
  }

  render() {
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage, selectedCostCenter, history } = this.props
    const { apiflag, loading, loading2,flagMessageScreen, newTeamDialog, champsBox, TeamDetails, editTeamDialog, deleteTeamDialog, homeScreenData, greetingData, rewardsOverLay, forecastData, hourlyWeatherData, showPowerBI } = this.state
    translator.setLanguage(browserLanguage);
    return (
      <div className = "homepageContainer">
        {apiflag === true ? <CustomApiErrorDialog /> : null}
        <Dialog visible = { loading } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        <Dialog visible = { loading2 } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        {flagMessageScreen &&(
          <Messages
            parentCallback = { this.onMsgDialogClose }
            selectedPeoples = { [] }
          />
        )}
        <div className = "homepageLeftSection">
          <div className = "homepageHeaderContainer">
            <div className = "homepageHeader">
              { selectedCostCenter.costCenterName}
            </div>
            <div className = "homepageDate">
              {moment()
                .locale(browserLanguage)
                .format('dddd DD.M.YYYY')}
            </div>
          </div>

          {/* HomePage Body */}
          {newTeamDialog && (
            <NewTeam
              TeamDetails = { (e) => {
                this.setState({ TeamDetails: e });
              } }
              Dialog
              OnHide = { (e) => {
                if (e === 'cancel') {
                  this.setState({
                    champsBox: false,
                    newTeamDialog: false,
                  });
                } else {
                  this.setState({
                    champsBox: true,
                    newTeamDialog: false,
                  });
                }
              } }
            />
          )}

          {champsBox && (
            <Champs
              informationTextFlag
              TeamDetails = { TeamDetails }
              OnHide = { () =>
                this.setState({ newTeamDialog: false, champsBox: false })
              }
              OnEdit = { () => {
                this.setState({
                  newTeamDialog: false,
                  champsBox: false,
                  editTeamDialog: true,
                });
              } }
              OnDelete = { () => {
                this.setState({
                  newTeamDialog: false,
                  champsBox: false,
                  deleteTeamDialog: true,
                });
              } }
            />
          )}

          {deleteTeamDialog && (
            <DeleteTeamScreen
              TeamDetails = { TeamDetails }
              OnCancel = { () => {
                this.setState({ deleteTeamDialog: false });
              } }
              OnDelete = { () => {
                this.setState({
                  newTeamDialog: false,
                  champsBox: false,
                  deleteTeamDialog: false,
                });
              } }
            />
          )}

          {editTeamDialog && (
            <EditTeamScreen
              ResetTeamDetails = { (e) => {
                this.setState({ TeamDetails: e });
              } }
              TeamDetails = { TeamDetails }
              Dialog
              OnHide = { (e) => {
                if (e === 'cancel') {
                  this.setState({
                    newTeamDialog: false,
                    champsBox: false,
                    editTeamDialog: false,
                  });
                } else {
                  this.setState({
                    newTeamDialog: false,
                    champsBox: true,
                    editTeamDialog: false,
                  });
                }
              } }
            />
          )}

          <div style = { { margin: '25px 25px 8vh 25px' } }>
            {/* First Row */}
            <div style = { { display: 'flex', height: '400px' } }>
              {/* People Section */}
              <div
                style = { {
                  borderRight: '0.5px solid #73808C',
                  borderBottom: '0.5px solid #73808C',
                  width: '50%',
                  paddingTop: '20px',
                  paddingLeft: '5%',
                } }
              >
                <div style = { { display: 'flex' } }>
                  <div className = "subHeading">{translator.HomePage.people}</div>
                  <ArrowRightIcon
                    style = { {
                      marginLeft: '13px',
                      alignItems: 'center',
                      cursor: 'pointer',
                    } }
                    aria-hidden = "true"
                    height = "24px"
                    width = "24px"
                    onClick = { () => history.push('people') }
                  />
                </div>
                {homeScreenData && (
                  <PeopleQuadrant
                    peopleQuadrantData = { homeScreenData.teamRoleInfo }
                  />
                )}
              </div>
              {/* Pulse Section */}
              <div
                style = { {
                  borderLeft: '0.5px solid #73808C',
                  borderBottom: '0.5px solid #73808C',
                  width: '50%',
                  paddingTop: '20px',
                  paddingLeft: '5%',
                } }
              >
                {homeScreenData &&
                  homeScreenData.weeklyPulse.length > 0 && (
                  <div className = "subHeading">
                    {translator.HomePage.dailyPulse}{' '}
                    {homeScreenData.weeklyPulse[ 0 ].weekNumber}{' '}
                    {translator.HomePage.pulse}
                    <DailyPulse
                      dailyPulseData = {
                        homeScreenData.weeklyPulse[ 0 ]
                      }
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Second Row */}
            <div style = { { display: 'flex', height: '400px' } }>
              {/* Weather Section */}
              <div
                style = { {
                  borderRight: '0.5px solid #73808C',
                  borderTop: '0.5px solid #73808C',
                  width: '50%',
                  padding: '20px 5% 0px',
                } }
              >
                <div style = { { display: 'flex' } }>
                  <div className = "subHeading">
                    {translator.HomePage.weather}
                  </div>
                  <ArrowRightIcon
                    style = { {
                      marginLeft: '13px',
                      alignItems: 'center',
                      cursor: 'pointer',
                    } }
                    aria-hidden = "true"
                    height = "24px"
                    width = "24px"
                    onClick = { () => history.push('weather') }
                  />
                </div>
                <WeatherQuadrant
                  weatherData = { forecastData }
                  hourlyWeatherData = { hourlyWeatherData }
                />
              </div>
              {/* Today's Log section */}
              <div
                style = { {
                  borderLeft: '0.5px solid #73808C',
                  borderTop: '0.5px solid #73808C',
                  width: '50%',
                  paddingTop: '20px',
                  paddingLeft: '5%',
                } }
              >
                <div style = { { display: 'flex' } }>
                  <div className = "subHeading">
                    {translator.HomePage.todayLog}
                  </div>
                  <ArrowRightIcon
                    style = { {
                      marginLeft: '13px',
                      alignItems: 'center',
                      cursor: 'pointer',
                    } }
                    aria-hidden = "true"
                    height = "24px"
                    width = "24px"
                    onClick = { () => history.push('log') }
                  />
                </div>
                {homeScreenData && (
                  <TodaysLog
                    todaysLogData = { homeScreenData.logInfo }
                  />
                )}
              </div>
            </div>
          </div>

          {/* Footer Section */}
          <div className = "homepageFooterContainer">
            <div style = { { border: '1px solid #C2C9D1' } } />
            <div className = "buttonContainer">
              {!isRolePEC() && showPowerBI && 
              <Button
                style = { { marginRight: '10px' } }
                size = "sm"
                id = "powerBIButton"
                onClick = { () => window.open(process.env.REACT_APP_POWER_BI_URL) }
              >
                {translator.HomePage.powerBI}
              </Button> }
              {!isRolePEC() && (
                <Button
                  style = { { margin: '0 10px' } }
                  size = "sm"
                  id = "newTeamButton"
                  onClick = { () => {
                    this.setState({ newTeamDialog: true });
                  } }
                >
                  {translator.HomePage.newTeam}
                </Button>
              )}
              { isRolePEC() && (
                <Button disabled = { decoded.roles[ 0 ]=="PEC.ADMIN.GAMIFICATION" ? true : false } size = "sm" id = "newMessageHomeScreen" onClick = { ()=> this.setState({
                  flagMessageScreen:true
                }) }
                >{translator.msgSUPToDrivers.newMsg}
                </Button>
              )} 
              <Button
                style = { { marginLeft: '10px' } }
                size = "sm"
                id = "newRewardButton"
                onClick = { () => {
                  this.setState({ rewardsOverLay: true });
                } }
              >
                {translator.HomePage.newReward}
              </Button>
            </div>
          </div>
        </div>
        {rewardsOverLay && (
          <RewardsScreen
            closeRewardsOverlay = { () => {
              this.setState({ rewardsOverLay: false });
            } }
          />
        )}
        {/* Message Section */}
        <div className = "homepageRightSection">
          <Greetings greetingData = { greetingData } />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
