import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import './PeopleDetails.css';
import { Button } from '@postidigital/posti-components';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import PeopleEditScreen from './PeopleEdit';
import { getFirstAndLastName } from '../../utility/commonUtility';
import Avatar from '../../components/Avatar/Avatar';
import { translation } from '../../locales/translation.js';

const translator = new LocalizedStrings(translation);
export class PeopleDetails extends Component {
  constructor(props) {
    super(props);
    const { selectedEmployeeData } = this.props;
    this.state = {
      expand: false,
      closeState: true,
      firstName: '',
      lastName: '',
      teamName: selectedEmployeeData.groupName,
      groupImagePath: selectedEmployeeData.groupImagePath,
      startDate: moment(selectedEmployeeData.dojPosti).format('DD-MM-YYYY'),
      routeMasterStartDate: moment(selectedEmployeeData.dojRM).format('DD-MM-YYYY'),
    };
  }

  componentDidMount() {
    const { selectedEmployeeData }= this.props;
    console.log(this.props,"dataaaaaaaaaaaaa");
    const name = getFirstAndLastName(selectedEmployeeData.firstName);

    this.setState({
      firstName: name.firstNameInitial,
      lastName: name.lastNameInitial,
    });
  }

  leftSectionContainer = () => {
    const { browserLanguage , selectedEmployeeData ,selectedCostCenter }= this.props;
    const { groupImagePath , firstName , lastName , startDate ,routeMasterStartDate,teamName }= this.state;
    translator.setLanguage(browserLanguage);
    const experienceLevel =
      browserLanguage === 'fi'
        ? selectedEmployeeData.roleName_FI
        : selectedEmployeeData.roleName;
    return (
      <div className = "PeopleDetailLeftSection">
        <div className = "PeopleDetailHeaderLeftContainer">
          <div className = "team-heading">{selectedEmployeeData.firstName}</div>
          {selectedEmployeeData.userImagePath !== null ? (
            <div className = "peopleAvatarContainer">
              <Avatar
                imageSource = { selectedEmployeeData.userImagePath }
                name = { selectedEmployeeData.firstName }
                avatarColor = "#FFA894"
                size = { 88 }
                fontSize = "15px"
              />
              {groupImagePath !== null ? (
                <div className = "groupNameImageContainer">
                  <Avatar
                    imageSource = { groupImagePath }
                    name = ""
                    avatarColor = "#A4DEDF"
                    size = { 44 }
                    fontSize = "15px"
                  />
                </div>
              ) : (
                <div className = "groupImagePlaceHolder" />
              )}
            </div>
          ) : (
            <div id = "peopledetail-avatar">
              {groupImagePath !== null ? (
                <div className = "groupNameImageContainer">
                  <Avatar
                    imageSource = { groupImagePath }
                    name = ""
                    avatarColor = "#A4DEDF"
                    size = { 44 }
                    fontSize = "15px"
                  />
                </div>
              ) : (
                <div className = "groupImagePlaceHolder" />
              )}
            </div>
          )}
        </div>
        <div
          id = "peopleDetailMainContainer"
          style = { {
            height: '62vh',
            overflowY: 'auto',
            marginLeft: '32px',
          } }
        >
          <div className = "informationText" style = { { marginBottom: '19px' } }>
            {translator.PeopleDetails.informationText}
          </div>
          <div className = "flexItems">
            <div className = "leftText">{translator.PeopleDetails.firstName}</div>
            <div className = "rightText">{firstName}</div>
          </div>
          {lastName && lastName.length > 0 && (
            <div className = "flexItems">
              <div className = "leftText">{translator.PeopleDetails.lastName}</div>
              <div className = "rightText">{lastName}</div>
            </div>
          )}
          <div className = "flexItems" style = { { marginTop: '14px' } }>
            <div className = "leftText">{translator.PeopleDetails.empNumber}</div>
            <div className = "rightText">{selectedEmployeeData.userSourceSystemId}</div>
          </div>
          <div className = "flexItems">
            <div className = "leftText">{translator.PeopleDetails.depot}</div>
            <div className = "rightText">{selectedEmployeeData.costCenter}</div>
          </div>

          <div className = "flexItems">
            <div className = "leftText">{translator.PeopleDetails.costCenter}</div>
            <div className = "rightText">{selectedCostCenter.costCenterId}</div>
          </div>

          <div className = "flexItems" style = { { marginTop: '14px' } }>
            <div className = "leftText">{translator.PeopleDetails.postiStartDate}</div>
            <div className = "rightText">{startDate}</div>
          </div>

          <div className = "flexItems">
            <div className = "leftText">{translator.PeopleDetails.routeMasterStartDate}</div>
            <div className = "rightText">{routeMasterStartDate}</div>
          </div>

          <div className = "flexItems">
            <div className = "leftText">{translator.PeopleDetails.experienceLevel}</div>
            <div className = "rightText">{experienceLevel}</div>
          </div>

          <div className = "flexItems">
            <div className = "leftText">{translator.PeopleDetails.team}</div>
            <div className = "rightText">{teamName}</div>
          </div>
          <div className = "informationText">{translator.PeopleDetails.deliveredRoutes}</div>
          <div
            style = { {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: '18px',
            } }
          >
            {this.getDeliveryRoutes()}
          </div>
          <div className = "informationText">{translator.peopledetailspec.exceptionalbadge}</div>
          <div
            style = { {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginTop: '18px',
            } }
          />
          <div className = "dateContainer">
            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'Induction Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">{translator.peopledetailsPS.induction}</div>
                  <div className = "dateWrapper">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'Induction Badge')
                      .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                        if (index === 0 && item1.badgeAwardedDates.length === 1) {
                          return <div className = "rightText12">{item3}</div>;
                        } 
                        if (index === 0) {
                          return <div className = "rightText12">{item3},</div>;
                        } 
                        if (index === item1.badgeAwardedDates.length-1){
                          return <div className = "rightText11">{item3}</div>;  
                        }
                        return <div className = "rightText11">{item3},</div>;
                      }))}
                  </div>
                </div>
              ) : (
                ''
              )}
            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'Achiever Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">{translator.peopledetailsPS.achiever}</div>
                  <div className = "dateWrapper">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'Achiever Badge')
                      .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                        if (index === 0 && item1.badgeAwardedDates.length === 1) {
                          return <div className = "rightText12">{item3}</div>;
                        } 
                        if (index === 0) {
                          return <div className = "rightText12">{item3},</div>;
                        } 
                        if (index === item1.badgeAwardedDates.length-1){
                          return <div className = "rightText11">{item3}</div>;  
                        }
                        return <div className = "rightText11">{item3},</div>;
                      }))}
                  </div>
                </div>
              ) : (
                ''
              )}
            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'Responsibility Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">{translator.peopledetailsPS.responsibility}</div>
                  <div className = "dateWrapper">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'Responsibility Badge')
                      .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                        if (index === 0 && item1.badgeAwardedDates.length === 1) {
                          return <div className = "rightText12">{item3}</div>;
                        } 
                        if (index === 0) {
                          return <div className = "rightText12">{item3},</div>;
                        } 
                        if (index === item1.badgeAwardedDates.length-1){
                          return <div className = "rightText11">{item3}</div>;  
                        }
                        return <div className = "rightText11">{item3},</div>;
                      }))}
                  </div>
                </div>
              ) : (
                ''
              )}

            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'Safety Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">{translator.peopledetailsPS.safety}</div>
                  <div className = "dateWrapper">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'Safety Badge')
                      .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                        if (index === 0 && item1.badgeAwardedDates.length === 1) {
                          return <div className = "rightText12">{item3}</div>;
                        } 
                        if (index === 0) {
                          return <div className = "rightText12">{item3},</div>;
                        } 
                        if (index === item1.badgeAwardedDates.length-1){
                          return <div className = "rightText11">{item3}</div>;  
                        }
                        return <div className = "rightText11">{item3},</div>; 
                      }))}
                  </div>
                </div>
              ) : (
                ''
              )}
            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'Mentor Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">{translator.peopledetailsPS.mentor}</div>
                  <div className = "dateWrapper">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'Mentor Badge')
                      .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                        if (index === 0 && item1.badgeAwardedDates.length === 1) {
                          return <div className = "rightText12">{item3}</div>;
                        } 
                        if (index === 0) {
                          return <div className = "rightText12">{item3},</div>;
                        } 
                        if (index === item1.badgeAwardedDates.length-1){
                          return <div className = "rightText11">{item3}</div>;  
                        }
                        return <div className = "rightText11">{item3},</div>;
                      }))}
                  </div>
                </div>
              ) : (
                ''
              )}
            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'kudos Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">Kudos</div>
                  <div className = "rightText">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'kudos Badge')
                      .map((item1) => `${ item1.badgeAwardedDates } `)}
                  </div>
                </div>
              ) : (
                ''
              )}
            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'Helper Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">{translator.peopledetailsPS.helper}</div>
                  <div className = "dateWrapper">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'Helper Badge')
                      .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                        if (index === 0 && item1.badgeAwardedDates.length === 1) {
                          return <div className = "rightText12">{item3}</div>;
                        } 
                        if (index === 0) {
                          return <div className = "rightText12">{item3},</div>;
                        } 
                        if (index === item1.badgeAwardedDates.length-1){
                          return <div className = "rightText11">{item3}</div>;  
                        }
                        return <div className = "rightText11">{item3},</div>;
                      }))}
                  </div>
                </div>
              ) : (
                ''
              )}
            {selectedEmployeeData.userBadgeDetails.some(
              (item2) => item2.badgeName === 'Patience Badge'
            ) ? (
                <div className = "flexItems">
                  <div className = "leftText">{translator.peopledetailsPS.patience}</div>
                  <div className = "dateWrapper">
                    {selectedEmployeeData.userBadgeDetails
                      .filter((item) => item.badgeName === 'Patience Badge')
                      .map((item1) => item1.badgeAwardedDates.map((item3, index) => {
                        if (index === 0 && item1.badgeAwardedDates.length === 1) {
                          return <div className = "rightText12">{item3}</div>;
                        } 
                        if (index === 0) {
                          return <div className = "rightText12">{item3},</div>;
                        } 
                        if (index === item1.badgeAwardedDates.length-1){
                          return <div className = "rightText11">{item3}</div>;  
                        }
                        return <div className = "rightText11">{item3},</div>;
                      }))}
                  </div>
                </div>
              ) : (
                ''
              )}
          </div>
        </div>
        
        <div
          style = { {
            border: '1px solid #c2c9d1',
            marginLeft: '32px',
            marginRight: '32px',
          } }
        />

        {this.buttonSection()}
      </div>
    );
  };

  getDeliveryRoutes = () => {
    const { selectedEmployeeData } = this.props;
    return selectedEmployeeData.mailRoutes.map((data) => (
      <div key = { data.index } className = "routeContainer">
        {data}
      </div>
    ));
  };

  buttonSection = () => {
    const { expand } = this.state;
    const { OnHide } = this.props;

    return (
      <div className = "peopleDetailButtonContainer">
        <button
          type = "submit"
          className = "editButtonPeople"
          disabled = { expand }
          id = "peopleEditButton"
          onClick = { () => {
            this.setState({ expand: true });
          } }
          style = { {
            backgroundColor: '#FFFFFF',
            color: expand ? '#A7A7A7' : '#0051C2',
          } }
        >
          {translator.PeopleDetails.editText}
        </button>
        <Button
          id = "peopleEdit-nextButton"
          disabled = { expand }
          size = "sm"
          onClick = { OnHide }
        >
          {translator.PeopleDetails.close}
        </Button>
      </div>
    );
  };

  render() {
    const { browserLanguage , selectedEmployeeData, OnHide, OnSave, Dialog2 }= this.props;
    translator.setLanguage(browserLanguage);
    const { expand , closeState }= this.state;
    return (
      <Dialog
        id = "people-detail"
        // eslint-disable-next-line react/destructuring-assignment
        visible = { Dialog2 }
        style = { { width: 'auto', height: 'auto' } }
        onHide = { () => {
          OnHide();
        } }
      >
        <div className = "PeopleDetailContainer">
          {/* Left section */}
          {this.leftSectionContainer()}

          {/* Right section */}
          <div
            className = {
              expand
                ? 'PeopleDetailsRightSectionExpand'
                : closeState
                  ? 'PeopleDetailsRightSectionClose'
                  : 'PeopleDetailsRightSection'
            }
          >
            <PeopleEditScreen
              OnSave = { (e) => {
                this.setState({
                  teamName: e.teamName,
                  startDate: e.startDate,
                  groupImagePath: e.teamImage,
                });
                OnSave();
              } }
              selectedEmployeeData = { selectedEmployeeData }
              closeTransition = { () => {
                this.setState({ expand: false });
              } }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleDetails);
