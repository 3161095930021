import axios from 'axios';
import LocalizedStrings from 'react-localization';
import { translation } from "../locales/translation";
import { authContext } from "../adalConfig";
// import { getHeader } from "../utility/commonUtility";
import apiUrl from '../urlConstants.js';
import { getAppId, getEmailIdFromToken } from '../utility/commonUtility';
import Toastr from '../shared/Toastr';

const translator = new LocalizedStrings(translation);
const language = '';
// eslint-disable-next-line import/prefer-default-export
export class CommonService {
  // Function to implement logout.
  // eslint-disable-next-line class-methods-use-this
  logout = () => {
    authContext.logOut();
  };

  handleError = (error) => {
    translator.setLanguage(language);
    if (error.message === 'Network Error') {
      throw new Error(translator.commonService.serverError);
    } else if (error.response.status === 401) {
      Toastr.showWarningMessage(translator.commonService.notAuthorized);
      setTimeout(() => {
        this.logout();
      }, 3000);
    } else if (error.response.status === 500) {
      throw new Error(translator.commonText.internalServerError);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (error.response.data.apierror === undefined) {
        throw new Error(translator.commonService.serverError);
      } else {
        throw new Error(error.response.data.apierror.message);
      }
    }
  };

  //  to get weatehr forecast on home screen
  getWeatherInfo = (latitude, longitude) => {
    const appid = getAppId();
    let apiKey;
    if(appid === 2 )
    {
      apiKey = process.env.REACT_APP_WEATHER_API_KEY_PS;
    } else if(appid === 4 )
    {
      apiKey = process.env.REACT_APP_WEATHER_API_KEY_PEC;
    } else if(appid === 5)
    {
      apiKey = process.env.REACT_APP_WEATHER_API_KEY_EMD;
    }
    const url =
      `${ apiUrl.getWeatherInfo + latitude  }&lon=${  longitude  }&units=metric&appid=${  apiKey }`;
    return axios
      .get(url)
      .then((res) => res.data)
      .catch(this.handleError);
  };

  // get weather hourly data
  getHourlyWeather = async (latitude, longitude) => {
    const appid = getAppId();
    let apiKey2;
    if(appid === 2 )
    {
      apiKey2 = process.env.REACT_APP_WEATHER_API_KEY_PS;
    } else if(appid === 4 )
    {
      apiKey2 = process.env.REACT_APP_WEATHER_API_KEY_PEC;
    } else if(appid === 5)
    {
      apiKey2 = process.env.REACT_APP_WEATHER_API_KEY_EMD;
    }
    const url2 =
      `${ apiUrl.getHourlyWeather +
      latitude 
      }&lon=${ 
        longitude 
      }&units=metric&appid=${ 
        apiKey2 
      }&exclude=minutely,alerts`;
    try {
      const res = await axios.get(url2);
      return res.data;
    } catch (error) {
      return this.handleError(error);
    }
  };

  //  Function to call Service to get Employee List
  postMethod = async (Url, bodyTemplate) => {
    this.updateEmployeeEmailIdInPayload(bodyTemplate);
    const token = localStorage.getItem('adal.idtoken');
    const headers = {

      // eslint-disable-next-line template-curly-spacing

      Authorization: `Bearer ${ process.env.REACT_APP_API_KEY }`,

      custToken:token

      // token: localStorage.getItem('adal.idtoken'),

    };
    try {
      const res = await axios.post(Url, bodyTemplate, {
        headers,
      });
      return res;
    } catch (error) {
      return this.handleError(error);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateEmployeeEmailIdInPayload(bodyTemplate) {
    if (bodyTemplate) {
      if (bodyTemplate.MasterGroupSourceSystemId) {
        // eslint-disable-next-line no-param-reassign
        bodyTemplate.MasterGroupSourceSystemId = getEmailIdFromToken(
          localStorage.getItem('adal.idtoken')
        );
      } else if (bodyTemplate.basicParameters) {
        if (bodyTemplate.basicParameters.MasterGroupSourceSystemId) {
          // eslint-disable-next-line no-param-reassign
          bodyTemplate.basicParameters.MasterGroupSourceSystemId = getEmailIdFromToken(
            localStorage.getItem('adal.idtoken')
          );
        }
      }
    }
  }

  postMethod2 = (Url, bodyTemplate) => {
    this.updateEmployeeEmailIdInPayload(bodyTemplate);
    const token = localStorage.getItem('adal.idtoken');
    const headers = {
      Authorization: `Bearer ${ process.env.REACT_APP_PEC_WSO2_API_KEY }`,
      // token: localStorage.getItem('adal.idtoken'),
      custToken:token
    };
    return axios
      .post(Url, bodyTemplate, {
        headers,
      })
      .then((res) => res)
      .catch(this.handleError);
  };

}