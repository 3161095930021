/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import {
  Dialog,
  Button,
  Input,
  Checkbox,
  Tab,
  TabContext,
  TabPanel,
  Tabs,
  Radio,
  Search,
  Loading
} from '@postidigital/posti-components';
import { InputTextarea } from 'primereact/inputtextarea'
import { CommonService } from '../../service/CommonService.js';
import apiUrl from '../../urlConstants.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import { translation } from '../../locales/translation.js';
import './MsgSupToDrivers.css'
import Avatars from '../../components/Avatar/Avatar.js';

const translator = new LocalizedStrings(translation);

class MsgSupToDrivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText:null,
      inputTextArea:"",
      selectedTabID:"tab1",
      radioButtonOptions: [ "All","Individual" ],
      searchText:null,
      originalPeopleList: [],
      copyPeopleList: [],
      selectedPeopleList:[],
      selectedPeopleListID:[],
      selectedRadioButton: "Individual",
      templateSelectedRadioButton:null,
      templateData:[],
      expandedTemplatesList:[],
      graphicImageList:[ 
        "mark",
        "question",
        "money",
        "msg",
        "clap",
        "party",
        "wine",
        "snow",
        "flower",
        "flag" 
      ],
      selectedGraphicImage:"MailBox",
      loading:true
    };
    this.commonService = new CommonService();
  }

  componentDidMount(){
    this.getPeopleData();
    this.getMessageTemplates();
  }

  getPeopleData = () =>{
    const { browserLanguage, selectedCostCenter,selectedPeoples } = this.props
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.getPeopleScreenData, jsonBody)
      .then((res) => {
        this.setState({
          originalPeopleList: res.data,
          copyPeopleList: res.data,
          loading:false,
          selectedPeopleListID:selectedPeoples
        });
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  }

  getMessageTemplates = () =>{
    const { selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.userSourceSystemId = "";
    this.commonService
      .postMethod(apiUrl.getMessageTemplates, jsonBody)
      .then((res) => {
        this.setState({
          templateData:res.data
        })
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  }

  saveMessageTemplates = () =>{
    const { selectedCostCenter } = this.props;
    const { inputText,inputTextArea } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.basicParameters={};
    jsonBody.basicParameters.applicationId = jsonBody.applicationId;
    jsonBody.basicParameters.userSourceSystemId = "";
    jsonBody.basicParameters.masterGroupSourceSystemId = jsonBody.masterGroupSourceSystemId;
    jsonBody.basicParameters.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.messageTitle = inputText;
    jsonBody.messageBody = inputTextArea;

    this.commonService
      .postMethod(apiUrl.saveMessageTemplates, jsonBody)
      .then(() => {
        this.getMessageTemplates();
        Toastr.showSuccessMessage(translator.msgSUPToDrivers.saveTemplate);
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  }

  deleteMessageTemplates = () =>{
    const { selectedCostCenter } = this.props;
    const { templateSelectedRadioButton } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.basicParameters={};
    jsonBody.basicParameters={};
    jsonBody.basicParameters.applicationId = jsonBody.applicationId;
    jsonBody.basicParameters.userSourceSystemId = "";
    jsonBody.basicParameters.masterGroupSourceSystemId = jsonBody.masterGroupSourceSystemId;
    jsonBody.basicParameters.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.id = templateSelectedRadioButton.id;
    this.commonService
      .postMethod(apiUrl.deleteMessageTemplate, jsonBody)
      .then(() => {
        this.getMessageTemplates();
        Toastr.showSuccessMessage(translator.msgSUPToDrivers.delTemplate);
        this.setState({
          templateSelectedRadioButton:null
        })
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  }

  sendMessage = () =>{
    const { selectedCostCenter,parentCallback } = this.props;
    const { inputText,inputTextArea,selectedPeopleListID,selectedGraphicImage } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.messageTitle = inputText;
    jsonBody.messageBody = inputTextArea;
    jsonBody.graphicsUrl = selectedGraphicImage;
    jsonBody.userSystemIds = selectedPeopleListID;
    this.commonService
      .postMethod(apiUrl.sendMessage, jsonBody)
      .then(() => {
        parentCallback();
        Toastr.showSuccessMessage(translator.msgSUPToDrivers.msgSent);
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
    
  }

  peopleSearch = (e) =>{
    const { originalPeopleList } = this.state;
    if (e) {
      const filtered = originalPeopleList.filter((value) => value.firstName.toLowerCase().includes(e.toLowerCase()));
      this.setState({
        copyPeopleList: filtered,
      });
    } else {
      this.setState({
        copyPeopleList: originalPeopleList,
      });
    }
  }

  onPeopleCheckBoxCliked = (e,data) =>{
    const { selectedPeopleList,selectedPeopleListID } = this.state;
    const array = [ ...selectedPeopleList ];
    const arrayID = [ ...selectedPeopleListID ];
    if (e.target.checked) {
      array.push(data);
      arrayID.push(data.userSourceSystemId);
    } else {
      array.splice(array.indexOf(data), 1);
      arrayID.splice(arrayID.indexOf(data.userSourceSystemId), 1);
    }
    this.setState({
      selectedPeopleList: array,
      selectedPeopleListID:arrayID,
      selectedRadioButton: "Individual"
    });
  }

  radioButtonClicked = (data) =>{
    const { originalPeopleList } = this.state;

    if(data === "All")
    {
      this.setState({
        selectedPeopleList: originalPeopleList,
        copyPeopleList: originalPeopleList,
        selectedPeopleListID:originalPeopleList.map((element)=>element.userSourceSystemId)
      })
    }
    this.setState({
      selectedRadioButton: data,
    })
  }

  arrowIconPressed = (data) =>{
    const { expandedTemplatesList } = this.state;
    if( expandedTemplatesList.includes(data))
    {
      this.setState({
        expandedTemplatesList:expandedTemplatesList.filter((item)=> item!== data)
      })
    }
    else{
      const temp = expandedTemplatesList;
      temp.push(data);
      this.setState({
        expandedTemplatesList:temp
      })
    }
  }

  recipientsScreen = () =>{
    const { radioButtonOptions,copyPeopleList,searchText ,selectedRadioButton, loading, selectedPeopleListID } = this.state;
    const { selectedCostCenter } = this.props;
    return (
      <>
        <div className = "recipientsScreenHeader">
          { translator.msgSUPToDrivers.recipient }
        </div>
        <div className = "recipientsScreenBody">
          { radioButtonOptions.map((data) => 
            <div className = "recipientsScreenWrapper">
              <Radio 
                size = "small"
                labelPosition = "right"
                label = { data === "All"?`${ translator.msgSUPToDrivers.allEmp } ${ selectedCostCenter.costCenterName }`: `${ translator.msgSUPToDrivers.selected } ${ selectedPeopleListID.length } ${ translator.msgSUPToDrivers.emps }` }
                value = { data }
                onChange = { (e) => this.radioButtonClicked(e.target.value)
                }
                checked = {
                  data === selectedRadioButton
                }
              />
            </div>)}
        </div>
        <div className = "recipientsScreenSearchBody">
          <Search 
            className = "recipientsSearch" 
            aria-label = { translator.msgSUPToDrivers.searchText }
            label = { translator.msgSUPToDrivers.searchText }
            value = { searchText }
            onChange = { (e) => {
              this.peopleSearch(e.target.value);
              this.setState({
                searchText: e.target.value,
              });
            } }
          />
          <div className = "check">
            { loading ? <Loading preset = "brandPink" size = "xl" className = "recipientsLoader" />: 
              copyPeopleList && (
                copyPeopleList.map((data)=> (
                  <div className = "msgCheckAvatarNameContainer">
                    <Checkbox 
                      onChange = { (e) => this.onPeopleCheckBoxCliked(e, data) }
                      checked = { selectedPeopleListID.includes(data.userSourceSystemId) }
                      value = { data.userSourceSystemId }
                      className = "msgSuptoDriverCheckbox"
                    />
                    <Avatars 
                      imageSource = { data.userImagePath }
                      name = { data.firstName }
                      avatarColor = "#FFA894"
                      size = { 44 }
                      fontSize = "15px"
                    />
                    { data.firstName }
                  </div>
                ))
              ) }
            { }
          </div>
        </div>
        <div className = "recipientsScreenFooter">
          <div className = "recipientsFooterLine" />
        </div>
      </>
    )
  }

  templatesScreen = () =>{
    const { templateData,expandedTemplatesList,templateSelectedRadioButton } = this.state;
    return(
      <>
        <div className = "templatesScreenHeader"> 
          { translator.msgSUPToDrivers.msgTemplates }
        </div>
        <div className = "templatesScreenBody">
          { templateData && (
            templateData.map((data)=> (
              <>
                <div className = "templatesScreenBodyHeader">
                  <input
                    type = "radio"
                    value = "green"
                    checked = { data === templateSelectedRadioButton }
                    onChange = { () => this.setState({
                      templateSelectedRadioButton:data
                    })
                    }
                  />
                  <div className = "templateMessageHeading" style = { { width:"300px",marginLeft:"5px",marginTop:"5px", } }>{data.messageTitle}</div>
                  <img
                    src = { expandedTemplatesList.includes(data)?require('../../images/upArrow.png') :require('../../images/downArrow.png')
                    }
                    alt = "noImage"
                    className = "templatesDownArrow"
                    aria-hidden = "true"
                    onClick = { ()=>this.arrowIconPressed(data) }
                  />
                </div>
                { expandedTemplatesList.includes(data)? <div className = "templatesScreenBodyBody">{data.messageBody}</div> :null }
              </>)
            )
          ) }
          
        </div>
        <div className = "templatesScreenFooter">
          <div className = "templatesFooterLine" />
          <div className = "templatesScreenFooterButtonWrapper">
            <button type = "button" 
              className = { templateSelectedRadioButton === null?"templatesDeleteDisableButton":"templatesDeleteEnableButton1" } 
              onClick = { () => templateSelectedRadioButton.type === "C" ? this.deleteMessageTemplates() : Toastr.showErrorMessage(translator.msgSUPToDrivers.deleteAPIError) }
            >{ translator.msgSUPToDrivers.msgDeleteButton }
            </button>
            <Button className = "templatesUseButton" disabled = { templateSelectedRadioButton === null } onClick = { ()=>this.setState({
              inputText:templateSelectedRadioButton.messageTitle,
              inputTextArea:templateSelectedRadioButton.messageBody,
              selectedTabID: 'tab1',
            }) }
            >{ translator.msgSUPToDrivers.msgUseButton }
            </Button>
          </div>
        </div>
      </>)
  }

  graphicScreen = () =>{
    const { graphicImageList,selectedGraphicImage } = this.state;
    return(
      <>
        <div className = "graphicScreenHeader"> 
          { translator.msgSUPToDrivers.msgGraphic }
        </div>
        <div className = "graphicScreenHeaderBody"> 
          { translator.msgSUPToDrivers.msgGraphicsContent }
        </div>
        <div className = "graphicScreenSubHeader">
          { translator.msgSUPToDrivers.defaultGraphics }
        </div>
        <div className = "defaultGraphicBody">
          <img
            src =  "https://gamifystore.blob.core.windows.net/routemaster-prod-messageicons/MailBox.png"
            alt = "noImage"
            aria-hidden = "true"
            onClick = { () => this.setState({
              selectedGraphicImage:"MailBox"
            }) }
          />
          { selectedGraphicImage === "MailBox"? <img
            src = { require('../../images/tick.png') }
            alt = "noImage"
            aria-hidden = "true"
            className = "graphicTick"
          />:null }
          
        </div>
        <div className = "graphicCollectionHeader">
          { translator.msgSUPToDrivers.altOptions }
        </div>
        <div className = "graphicCollectionBody">
          { graphicImageList.map((data) =>(
            <div className = "graphicCollectionInnerBody">
              <img
                src = { `https://gamifystore.blob.core.windows.net/routemaster-prod-messageicons/${ data }.png` }
                alt = "noImage"
                aria-hidden = "true"
                onClick = { () => this.setState({
                  selectedGraphicImage: data
                }) }
              />
              { selectedGraphicImage === data? 
                <img
                  src = { require('../../images/tick.png') }
                  alt = "noImage"
                  aria-hidden = "true"
                  className = "graphicTick"
                />:null}
            </div>
          ))}
          
        </div>
        
        <div className = "graphicScreenFooter">
          <div className = "graphicFooterLine" />
        </div>
      </>
    )
  }

  tabSelection = () =>{
    const { selectedTabID }= this.state;
    return (
      <div>
        <TabContext>
          <Tabs selectedTab = { selectedTabID } className = "msgTabSelection">
            <Tab value = "tab1" panelId = "id-tab1" className = "msgTab1" onClick = { ()=> this.setState({
              selectedTabID:"tab1"
            }) }
            id = { selectedTabID === 'tab1' ? 'selectBadgeTabActive' : 'selectBadgeTabInActive' }
            >{ translator.msgSUPToDrivers.recepients }
            </Tab>
            <Tab value = "tab2" panelId = "id-tab2" className = "msgTab2" onClick = { ()=> this.setState({
              selectedTabID:"tab2"
            }) }
            id = { selectedTabID === 'tab2' ? 'selectBadgeTabActive' : 'selectBadgeTabInActive' }
            >{ translator.msgSUPToDrivers.templates }
            </Tab>
            <Tab value = "tab3" panelId = "id-tab3" className = "msgTab3" onClick = { ()=> this.setState({
              selectedTabID:"tab3"
            }) }
            id = { selectedTabID === 'tab3' ? 'selectBadgeTabActive' : 'selectBadgeTabInActive' }
            >{ translator.msgSUPToDrivers.graphic }
            </Tab>
          </Tabs>
          <TabPanel id = "id-tab1" value = "tab1">
            {this.recipientsScreen()}
          </TabPanel>
          <TabPanel id = "id-tab2" value = "tab2">
            {this.templatesScreen()}
          </TabPanel>
          <TabPanel id = "id-tab3" value = "tab3">
            {this.graphicScreen()}
          </TabPanel>
        </TabContext>
      </div>
    )
  }

  render() {
    const { browserLanguage,parentCallback }= this.props;
    const { inputText,inputTextArea, selectedPeopleListID }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <Dialog
        noCloseButton
        className = "supToDriverDialog"
      >
        <div className = "messageDialogBody">
          <div className = "leftMsgDialogBody">
            <div className = "leftMsgDialogBodyHeader">{ translator.msgSUPToDrivers.newMsg }</div>
            <div className = "leftMsgDialogBodyData">
              <div className = "leftMsgDialogBodyTitle">{ translator.msgSUPToDrivers.msgTitle }</div>
              <Input className = "leftMsgDialogBodyInput"
                value = { inputText }
                onChange = { (e)=>this.setState({
                  inputText:e.target.value
                }) }
              />
              <div className = "leftMsgDialogBodyContent">{ translator.msgSUPToDrivers.msgContent }</div>
              <InputTextarea 
                className = "leftMsgDialogBodyInputTest"
                value = { inputTextArea } 
                onChange = { (e) => this.setState({ inputTextArea: e.target.value }) } 
                rows = { 20 } 
                cols = { 60 } 
                autoResize 
                maxlength = "1000"
              />
              <div className = "msgTextAreaCount">{`${ inputTextArea.length }/1000`}</div>
              {/* <div className = "msgCheckBoxAndLabelWrapper">
                <Checkbox checked = { templateFlag } onChange = { ()=> this.setState({
                  templateFlag : !templateFlag
                },() =>{
                  if(!templateFlag)
                  {
                    this.saveMessageTemplates();
                  }
                }) }
                />
                <div>{ translator.msgSUPToDrivers.templateCheckbox }</div>
              </div> */}
            </div>
            <div className = "leftMsgDialogBodyFooter">
              <div className = "leftMsgDialogBodyFooterLine" />
              <div className = "leftMsgDialogBodyFooterLineWrapper">
                <div>
                  <button type = "button" className = { inputText !== null &&  inputTextArea !== "" ? "useAsATemplate" : "useAsATemplateDisable" } onClick = { () => this.setState( { selectedTabID : "tab2" },() => this.saveMessageTemplates()) }>{translator.msgSUPToDrivers.templateCheckbox}</button>
                </div>
                <div style = { { display:"flex" } }>
                  <button type = "button" className = "cancelButtonMsg" onClick = { ()=> parentCallback() }>{ translator.msgSUPToDrivers.cancelButton }</button> 
                  <Button className = "sendButtonMsg" onClick = { () => this.sendMessage() } disabled = { selectedPeopleListID.length<1 }>{ translator.msgSUPToDrivers.msgSendButton } </Button>
                </div>
              </div>
            </div>
          </div>
          
          <div className = "rightMsgDialogBody">{ this.tabSelection() }</div>
        </div>
      </Dialog>)
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MsgSupToDrivers);
