import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Headline, Search, Loading, Tooltip } from '@postidigital/posti-components';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService.js';
import './LogCss.css';
import LogFilter from './LogFilter.js';
import Avatar from '../../components/Avatar/Avatar.js';
import { getJsonBody, isRolePEC } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';
import CustomApiErrorDialog from '../Configuration/CustomApiErrorDialog.js';

const translator = new LocalizedStrings(translation);

class Log extends Component {
  constructor(props) {
    super(props);
    // For datatable
    this.myRef = React.createRef();

    this.state = {
      apiflag: false,
      fullData: null,
      searchBarText: null,
      // It will store the search result data
      // eslint-disable-next-line react/no-unused-state
      searchResult: [],
      jsonCopy: null,
      // eslint-disable-next-line react/no-unused-state
      // resetButtonFlag: false,
      // To check if data is received after filter or not
      filteredDataFromFilters: null,

      loading: true,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getActionLogData();
    }, 2500);
  };

  componentDidUpdate(prevProps) {
    const { selectedCostCenter }= this.props;
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
        prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.getActionLogData();
    }
  }

  // Api call for action log response data
  getActionLogData = () => {
    const { browserLanguage , selectedCostCenter }= this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.getActionLogScreenData, jsonBody)
      .then((res) => {
        if (res.data.length <= 0) {
          Toastr.showErrorMessage(translator.ToastMessages.logDataEmpty);
          this.setState({
            loading: false,
          });
        }
        // To sort date to desecending order
        res.data = res.data
          .sort((a, b) => a.loggedDateTime.localeCompare(b.loggedDateTime))
          .reverse();
        this.setState({
          jsonCopy: res.data,
          fullData: res.data,
          loading: false,
        });
      })
      .catch(() => {
        // Toastr.showErrorMessage(translator.ToastMessages.fetchingLogScreen);
        this.setState({
          apiflag: true,
        });
      });
  };

  // For type column in datatable
  typeBodyTemplate = (rowData) => (
    <img src = { rowData.logActivityCategoryImageURL } alt = "Noimg" />
  );

  // For date column in datatable
  dateBodyTemplate = (rowData) => {
    const finalDate = moment(rowData.loggedDateTime).format('DD.MM.YYYY');
    const hourMinuteSecond = rowData.loggedDateTime.split('T')[ 1 ];
    const hour = hourMinuteSecond.split(':')[ 0 ];
    const minuteSecond = hourMinuteSecond.split(':')[ 1 ];
    const minutes = minuteSecond.split(':')[ 0 ];
    const hourMinute = `${ hour  }:${  minutes }`;
    return (
      <span>
        <span className = "dateData">{finalDate}</span>
        <span className = "hourSecondCss">{hourMinute}</span>
      </span>
    );
  };

  // For avatar column in datatable
  avatarBodyTemplate = (rowData) => (
    <div className = "avatarWrapper">
      <Avatar
        imageSource = { rowData.supervisorBadgeImageURL }
        name = { rowData.userName }
        avatarColor = "#FFA894"
        size = { 42 }
        fontSize = "17px"
      />
    </div>
  );

  // When ENTER is pressed on the search bar
  searchOnBaseOfInput = (e) => {
    const { filteredDataFromFilters , fullData }= this.state;
    const { browserLanguage }= this.props;
    if (filteredDataFromFilters === null) {
      if (e) {
        const filtered = fullData.filter((value) => {
          if (browserLanguage === 'en') {
            return value.logActivityDescription

              .toLowerCase()

              .includes(e.toLowerCase());
          } 
          return value.logActivityDescription_FI

            .toLowerCase()

            .includes(e.toLowerCase());
          
        });
        this.setState({ jsonCopy: filtered });
      } else {
        this.setState({
          jsonCopy: fullData,
        });
      }
    } else if (e) {
      const filteredNew = filteredDataFromFilters.filter((value) => {
        if (browserLanguage === 'en') {
          return value.logActivityDescription

            .toLowerCase()

            .includes(e.toLowerCase());
        } 
        return value.logActivityDescription_FI

          .toLowerCase()

          .includes(e.toLowerCase());
        
      });

      this.setState({ jsonCopy: filteredNew });
    } else {
      this.setState({
        jsonCopy: filteredDataFromFilters,
      });
    }
  };

  // When main reset button is clicked
  // mainResetButton = () => {
  //   this.setState({
  //     // eslint-disable-next-line react/no-unused-state
  //     searchResult: [],
  //     jsonCopy: this.state.fullData,
  //     searchBarText: null,
  //     // eslint-disable-next-line react/no-unused-state
  //     resetButtonFlag: false,
  //     filteredDataFromFilters: null,
  //   });

  //   // To reset the sorting in datatable
  //   this.myRef.current.state.sortField = '';
  // };

  // To convert string to Date
  processDate = (date) => {
    const parts = date.split('/');
    return new Date(parts[ 2 ], parts[ 1 ] - 1, parts[ 0 ]);
  };

  // Callback function from child
  handleCallback = (userSourceSystemId, filterByType, startDate, endDate) => {
    const { jsonCopy , fullData } = this.state;
    const startingDate = moment(startDate).format('DD/MM/YYYY');
    const endingDate = moment(endDate).format('DD/MM/YYYY');
    const processedStartingDate = this.processDate(startingDate);
    const processedEndingDate = this.processDate(endingDate);

    let peoplefilteredArray = [];
    if (userSourceSystemId.length === 0) {
      peoplefilteredArray = jsonCopy;
    } else {
      userSourceSystemId.forEach((value) => {
        const filterArray = jsonCopy.filter((v) => value === v.userId);

        peoplefilteredArray = [ ...peoplefilteredArray, ...filterArray ];
      });
    }

    let filteredArray = null;
    if (filterByType != null) {
      if (filterByType === 'All') {
        if (userSourceSystemId.length === 0 && startDate === '' && endDate === '') {
          filteredArray = fullData;
        } else {
          filteredArray = peoplefilteredArray.filter((value) => (
            value.logActivityCategory === 'Team' ||
              value.logActivityCategory === 'Rewards' ||
              value.logActivityCategory === 'Recruitment' ||
              value.logActivityCategory === 'Weather'
          ));
        }
      } else {
        filteredArray = peoplefilteredArray.filter((value) => value.logActivityCategory === filterByType);
      }
    } else {
      filteredArray = peoplefilteredArray;
    }

    if (startDate !== '' || endDate !== '') {
      filteredArray = filteredArray.filter((value) => {
        const DDMMYYYY = moment(value.loggedDateTime).format('DD/MM/YYYY');
        const processedDDMMYY = this.processDate(DDMMYYYY);
        return processedDDMMYY >= processedStartingDate && processedDDMMYY <= processedEndingDate;
      });
    }
    this.setState({
      jsonCopy: filteredArray,
      // resetButtonFlag: true,
      filteredDataFromFilters: filteredArray,
    });
  };

  tooltipDateFormatter = (date) =>{
    let frontPart=date.split("T")[ 0 ];
    const backPart = date.split("T")[ 1 ];
    frontPart= frontPart.split("-").reverse().join(".");
    return `${ frontPart } ${ backPart.split(":")[ 0 ] }:${ backPart.split(":")[ 1 ] }`;
  }

  toolTipView = (rowData) =>
    // console.log("Cccccccccccccccccccc",rowData)
    (
      <>
        <div className = "logTooltipHeader">
          Action done by
        </div>
        <div className = "logTooltipBody">
          {` ${ this.tooltipDateFormatter(rowData.loggedDateTime) } by ${ rowData.supervisorName }`}
        </div>
      </>)
  
  initialName = (rowData) =>{
    const name = rowData.supervisorName;
    // eslint-disable-next-line prefer-regex-literals
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = [ ...name.matchAll(rgx) ] || [];
    initials = (
      (initials.shift()?.[ 1 ] || '') + (initials.pop()?.[ 1 ] || '')
    ).toUpperCase();
    return (
      <Tooltip 
        title = { this.toolTipView(rowData) }
        placement = "bottom"
        id = "postiLogTooltip"
      >
        <div className = "logNameInitial" > { initials }
        </div>
      </Tooltip>
    )
  }

  resetCallBack = () => {
    const { fullData }= this.state;
    this.setState({
      jsonCopy: fullData,
      filteredDataFromFilters: null,
    });
    // To reset the sorting in datatable
    this.myRef.current.state.sortField = '';
  };

  render() {
    const { browserLanguage }= this.props;
    const { loading , searchBarText ,jsonCopy, apiflag }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        {apiflag === true ? <CustomApiErrorDialog /> : null}
        <div className = "wholeLogWrapper">
          <Dialog visible = { loading } id = "loader">
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
          <div className = "leftSideLog">
            <Headline className = "leftSideLogHeader">
              {translator.LogScreen.actionsLog}
              <Search
                className = "searchBarLog"
                placeholder = { translator.RewardsScreen.searchText1 }
                size = "sm"
                value = { searchBarText }
                onChange = { (e) => {
                  this.searchOnBaseOfInput(e.target.value);
                  this.setState({
                    searchBarText: e.target.value,
                  });
                } }
                onEnter = { this.searchOnBaseOfInput }
              />
            </Headline>
            <div className = "leftSideLogContainer">
              <div className = "datatableContainer">
                <DataTable value = { jsonCopy } ref = { this.myRef } emptyMessage = { translator.VIPContactScreen.emptyData } >
                  
                  <Column
                    field = { (e) => {
                      const alphaOnly = e.logActivityCategoryImageURL.replace(
                        /[^a-zA-Z0-9]/g,
                        '-'
                      );
                      return alphaOnly;
                    } }
                    header = { translator.LogScreen.typeText }
                    className = "columnType"
                    body = { this.typeBodyTemplate }
                    sortable
                    style = { { width: '0.5vh', whiteSpace: 'nowrap' } }
                  />
                  <Column
                    field = { (e) => {
                      const yearMonthDay = e.loggedDateTime.split('T')[ 0 ];
                      const hourMinuteSecond = e.loggedDateTime.split('T')[ 1 ];
                      const hour = hourMinuteSecond.split(':')[ 0 ];
                      const minuteSecond = hourMinuteSecond.split(':')[ 1 ];
                      const minute = minuteSecond.split(':')[ 0 ];
                      return `${ yearMonthDay  }-${  hour  }-${  minute }`;
                    } }
                    header = { translator.LogScreen.dateText }
                    className = "columnDate"
                    body = { this.dateBodyTemplate }
                    sortable
                    style = { { width: '.5vh' } }
                  />
                  <Column
                    field = {
                      browserLanguage === 'en'
                        ? 'logActivityDescription'
                        : 'logActivityDescription_FI'
                    }
                    header = { translator.LogScreen.actionText }
                    sortable
                    className = "columnAction"
                    style = { { width: '19vh' } }
                  />
                  {isRolePEC() && <Column
                    field = "supervisorName"
                    header = { translator.LogScreen.by }
                    style = { { width: '2vh',overflow: "hidden",whiteSpace: "nowrap" } }
                    sortable
                    body = { this.initialName }
                  />}
                  <Column
                    field = "supervisorBadgeImageURL"
                    header = { translator.LogScreen.peopleBadgeText }
                    className = "columnTechnique"
                    body = { this.avatarBodyTemplate }
                    style = { { width: '5vh' } }
                  />
                </DataTable>

                <br />
                <br />
                <br />
                <br />
                <br />

                <div className = "leftSideLogFooter">
                  <hr className = "leftSideLogFooterLine" />
                  {/* <button
                    // className={
                    //   this.state.resetButtonFlag
                    //     ? 'leftSideLogFooterResetButton'
                    //     : 'leftSideLogFooterResetButtonDisable'
                    // }
                    className="leftSideLogFooterResetButton"
                    onClick={this.mainResetButton}
                  >
                    {translator.LogScreen.resetText}
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className = "rightSideLog">
            <div className = "rightSideLogFilterHeader">{translator.LogFilter.filtersText}</div>
            <div className = "rightSideLogFilterContainer">
              <LogFilter
                parentCallback = { this.handleCallback }
                resetCallback = { this.resetCallBack }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Log);
