/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../../locales/translation.js';
import './PeopleQuadrant.css';
import Avatar from '../../../components/Avatar/Avatar.js';
import { isRolePEC } from '../../../utility/commonUtility';

const translator = new LocalizedStrings(translation);

class PeopleQuadrant extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { browserLanguage  }= this.props;
    translator.setLanguage(browserLanguage);
    // eslint-disable-next-line react/destructuring-assignment
    const peopleQuadrantData = this.props.peopleQuadrantData[ 0 ];
    return (
      <>
        <div className = "peopleQuadrantContainer">
          <Avatar
            imageSource = { require('../../../images/Bitmap.png') }
            // imageSource={null}
            name = "Mati Miekkonen"
            avatarColor = "#ffa894"
            fontSize = "35px"
            size = { 85 }
          />
          <div className = "peopleQuadrantMainText">
            {`${ peopleQuadrantData.totalPeopleCount } ${ translator.HomePage.employees }`}
            <br />
            {!isRolePEC() && `${ peopleQuadrantData.totalTeamCount } ${ translator.HomePage.teams }`}
          </div>
        </div>
        <div className = "peopleQuadrantContainer">
          <img alt = "error" src = { require('../../../images/newbie.png') } />
          <div className = "peopleQuadrantSubText">
            {Math.round(peopleQuadrantData.newbeePercentage)}%
            <span style = { { fontWeight: 400 } }>
              {`- ${ peopleQuadrantData.totalNewbeeCount } ${ translator.HomePage.newbie }`}
            </span>
          </div>
        </div>

        <div className = "peopleQuadrantSubContainer">
          <img alt = "error" src = { require('../../../images/qualified.png') } />
          <div className = "peopleQuadrantSubText">
            {Math.round(peopleQuadrantData.qualifiedPercentage)}%
            <span style = { { fontWeight: 400 } }>
              {`- ${ peopleQuadrantData.totalQualifiedCount } ${ translator.HomePage.qualified }`}
            </span>
          </div>
        </div>

        <div className = "peopleQuadrantSubContainer">
          <img alt = "error" src = { require('../../../images/expert.png') } />
          <div className = "peopleQuadrantSubText">
            {Math.round(peopleQuadrantData.expertPercentage)}%
            <span style = { { fontWeight: 400 } }>
              {`- ${ peopleQuadrantData.totalExpertCount } ${ translator.HomePage.experts }`}
            </span>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleQuadrant);
