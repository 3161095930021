/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Switch, Route, Redirect } from 'react-router-dom';
import { translation } from '../locales/translation.js';
import Homepage from '../screens/Homepage/Homepage.js';
import People from '../screens/People/People.js';
import Messages from '../screens/Messages/Messages.js';
import Data from '../screens/Data/Data.js';
import Log from '../screens/Log/Log.js';
import Weather from '../screens/Weather/Weather.js';
import Configuration from '../screens/Configuration/Configuration.js';
import { getRolesArrayFromToken } from '../utility/commonUtility.js';
import Contacts from '../screens/Contacts/Contacts.js';
import DailyPulse from '../screens/DailyPulse/DailyPulse.js'
import Badges from '../screens/Badges/Badges.js';

const translator = new LocalizedStrings(translation);

const PSRoutes = [
  { path: '/', component: Homepage },
  { path: '/home', component: Homepage },
  { path: '/people', component: People },
  { path: '/weather', component: Weather },
  { path: '/log', component: Log },
];

const PECRoutes = [
  { path: '/', component: Homepage },
  { path: '/home', component: Homepage },
  { path: '/people', component: People },
  { path: '/message', component: Messages },
  { path: '/data', component: Data },
  { path: '/weather', component: Weather },
  { path: '/log', component: Log },
  { path: '/contacts', component: Contacts },
  { path: '/badges', component: Badges },
  { path: '/dailyPulse', component: DailyPulse },
  // { path: '/configurations', component: Configuration },
];

const PSAdminRoutes = [
  { path: '/', component: Homepage },
  { path: '/home', component: Homepage },
  { path: '/people', component: People },
  { path: '/weather', component: Weather },
  { path: '/log', component: Log },
];

const PECAdminRoutes = [
  { path: '/', component: Homepage },
  { path: '/home', component: Homepage },
  { path: '/people', component: People },
  { path: '/message', component: Messages },
  { path: '/data', component: Data },
  { path: '/weather', component: Weather },
  { path: '/log', component: Log },
  { path: '/contacts', component: Contacts },
  { path: '/configurations', component: Configuration },
  { path: '/dailyPulse', component: DailyPulse },
  { path: '/badges', component: Badges },

];

class ScreenContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routesArray = this.screenArrayBasedOnRoles();
  }

  // eslint-disable-next-line class-methods-use-this
  screenArrayBasedOnRoles = () => {
    let routesArray = [];
    const rolesArray = getRolesArrayFromToken();
    if (rolesArray.includes('PEC.ADMIN.GAMIFICATION')) {
      routesArray = PECAdminRoutes;
    } else if (rolesArray.includes('PS.ADMIN.GAMIFICATION')) {
      routesArray = PSAdminRoutes;
    } else if (rolesArray.includes('PS.SV.GAMIFICATION')) {
      routesArray = PSRoutes;
    } else if (rolesArray.includes('PEC.SV.GAMIFICATION')) {
      routesArray = PECRoutes;
    }else if(rolesArray.includes('SG_ROLE_EMD_ADMIN_GAMIFICATION')){
      routesArray = PSAdminRoutes;
    } else if(rolesArray.includes('SG_ROLE_EMD_SV_GAMIFICATION')){
      routesArray = PSRoutes;
    }

    return routesArray;
  };

  render() {
    const { browserLanguage } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <Switch>
        {this.routesArray.map((route, index) => (
          <Route
            // eslint-disable-next-line react/no-array-index-key
            key = { index }
            path = { route.path }
            exact = { index === 0 }
            component = { route.component }
          />
        ))}
        <Route path = "*" render = { () => <Redirect to = "/" /> } />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenContainer);
