/* eslint-disable class-methods-use-this */
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import './Toastr.css';

class Toastr {
  constructor() {
    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      onclick: null,
      showDuration: '300',
      hideDuration: '10000',
      timeOut: '3000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };
  }

  // to display the error message in the growl
  showErrorMessage(message = '', title = '') {
    toastr.error(message, title.toUpperCase());
  }

  // to display the success message in the growl
  showSuccessMessage(message = '', title = '') {
    toastr.success(message, title.toUpperCase());
  }

  // to dislplay the warning messgae in the growl
  showWarningMessage(message = '', title = '') {
    toastr.warning(message, title.toUpperCase());
  }
}

export default new Toastr();
