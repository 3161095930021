import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { PostiTheme, XyzTheme } from '@postidigital/posti-theme';
import { ThemeProvider } from 'styled-components';
import  combinedReducer  from './reducers/index';
import App from './App';
import * as serviceWorker from './serviceWorker';

const combinedTheme = { ...PostiTheme, xyz: XyzTheme };

const store = createStore(combinedReducer);
// const store = createStore(
//   combinedReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

ReactDOM.render(
  <Provider store = { store }>
    <ThemeProvider theme = { combinedTheme }>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
