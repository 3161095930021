import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import LocalizedStrings from 'react-localization';
import { CommonService } from '../../service/CommonService';
import apiUrl from '../../urlConstants';
import { getAppId , getEmailIdFromToken } from '../../utility/commonUtility';
import Toastr from '../../shared/Toastr';
import './DeleteTeam.css';
import { translation } from '../../locales/translation';

const translator = new LocalizedStrings(translation);
export class DeleteTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.commonService = new CommonService();
  }

  TeamDelete = () => {
    const emailId = getEmailIdFromToken(localStorage.getItem('adal.idtoken'));
    const { browserLanguage , selectedCostCenter , TeamDetails, OnDelete }= this.props;
    const ApplicationId = getAppId();
    const jsonBody = {
      applicationId: ApplicationId,
      masterGroupSourceSystemId: emailId,
      entityCode: browserLanguage,
      costCenterId: selectedCostCenter.costCenterId,
      teamId: TeamDetails.teamId,
    };
    this.commonService
      .postMethod(apiUrl.deleteTeam, jsonBody)
      .then((res) => {
        if (res.data === true) {
          Toastr.showSuccessMessage(
            `${ TeamDetails.Name } ${ translator.DeleteDialog.deleted }`
          );
          OnDelete();
        } else {
          Toastr.showErrorMessage(translator.ToastMessages.failedToDelete);
          OnDelete();
        }
      })
      .catch(() => {
        Toastr.showErrorMessage('Delete api error');
        OnDelete();
      });
  };

  render() {
    const { browserLanguage, TeamDetails, OnCancel }= this.props;
    translator.setLanguage(browserLanguage);
    return (
      <div>
        <Dialog showHeader = { false } visible style = { { width: '30vw' } } id = "deleteDialog">
          <div className = "deleteTeamHeader">{`${ translator.DeleteDialog.delete } ${ TeamDetails.Name }`}</div>
          <div className = "deleteTeamBody">
            {`${ translator.DeleteDialog.confirmThat } ${ TeamDetails.Name } ${ translator.DeleteDialog.team }. ${ translator.DeleteDialog.noteThis }`}
          </div>
          <div style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } } />
          <div className = "deleteFooter">
            <div
              aria-hidden = "true"
              className = "deleteFooterText"
              onClick = { () => {
                OnCancel();
              } }
            >
              {translator.DeleteDialog.cancel}
            </div>
            <button
              type = "submit"
              className = "teamDeleteButton"
              onClick = { () => {
                this.TeamDelete();
              } }
            >
              {translator.DeleteDialog.delete}
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTeam);
