import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Location.css';
import { Search, Checkbox } from '@postidigital/posti-components';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation';

const translator = new LocalizedStrings(translation);
export class location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: null,
      selectedLocationList: [],
      originalLocationArray: [],
    };
  }

  componentDidMount() {
    const { fetchLocationData } = this.props;
    this.setState({
      originalLocationArray: fetchLocationData,
    });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    const { fetchLocationData } = this.props;
    if (prevProps !== this.props) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        originalLocationArray: fetchLocationData,
      });
    }
  }

  locationSearch = (e) => {
    const { originalLocationArray } = this.state;
    const { fetchLocationData } = this.props;
    if (e) {
      const filtered = originalLocationArray.filter((value) => value.location.toLowerCase().includes(e.toLowerCase()));
      this.setState({
        originalLocationArray: filtered,
      });
    } else {
      this.setState({
        originalLocationArray: fetchLocationData,
      });
    }
  };

  onBadgesCheckboxClick = (e, category) => {
    const { selectedLocationList } = this.state;
    const { handleLocationData } = this.props;
    const array = [ ...selectedLocationList ];

    if (e.target.checked) {
      array.push(category);
    } else {
      array.splice(array.indexOf(category), 1);
    }

    this.setState({
      selectedLocationList: array,
    });
    handleLocationData(array);
  };

  render() {
    const { browserLanguage } = this.props;
    
    const { searchText,originalLocationArray,selectedLocationList } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        <div className = "locationHeading">{translator.NewConfigLocation.heading}</div>
        <Search
          className = "locationSelectionSearchBar"
          aria-label = "Search"
          id = "badgeSelectionSearch"
          label = { translator.RewardsScreen.searchText1 }
          value = { searchText }
          onChange = { (e) => {
            this.locationSearch(e.target.value);
            this.setState({
              searchText: e.target.value,
            });
          } }
        />
        <div className = "check" style = { { paddingBottom: '8vh', paddingLeft: '29.99px' } }>
          {originalLocationArray.length > 0 &&
            originalLocationArray.map((category) => 
            // eslint-disable-next-line react/jsx-no-useless-fragment
              <div
                style = { {
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '20px',
                  alignItems: 'center',
                } }
                key = { category.index }
              >
                <Checkbox
                  onChange = { (e) => this.onBadgesCheckboxClick(e, category) }
                  checked = { selectedLocationList.includes(category) }
                  disabled = { category.hasCustomRule === true }
                />
                <div>
                  <div
                    className = { category.hasCustomRule === true ? 'disabledCss' : 'enabledCss' }
                  >
                    {category.location} - {category.costCenterId}
                  </div>
                </div>
              </div>
            )}
        </div>

        <div
          style = { {
            position: 'absolute',
            bottom: '70px',
            width: '364px',
          } }
        >
          <div
            style = { {
              border: '1px solid #C2C9D1',
              margin: '0px 20px 0px 20px',
              width: '361px',
            } }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => 
  ({
    browserLanguage: state.browserLanguage,
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(location);
