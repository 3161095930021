import React, { Component } from 'react';
import './Contacts.css';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from '@postidigital/posti-components';
import { CommonService } from '../../service/CommonService.js';
import { translation } from '../../locales/translation.js';
import { getJsonBody } from '../../utility/commonUtility';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';

const translator = new LocalizedStrings(translation);
class deleteContactDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.commonService = new CommonService();
  }

  deleteMembers = () => {
    const { peopleList,selectedCostCenter,setLoader,clearAllSelectedState,onConfirm } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.IsSupervisor = true;
    jsonBody.EmployeeId = jsonBody.masterGroupSourceSystemId;
    jsonBody.requestFor = 'Supervisor';
    jsonBody.AppId = jsonBody.applicationId;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    const names = peopleList.map((data) => data.name);
    jsonBody.name = names;
    setLoader();
    this.commonService
      .postMethod(apiUrl.deleteContact, jsonBody)
      .then(() => {
        Toastr.showSuccessMessage(
          peopleList.length === 1
            ? `${ peopleList[ 0 ].name } ${ translator.DeleteDialog.deleted }`
            : `${ peopleList.length } ${ translator.DeleteDialog.contacts } ${ translator.DeleteDialog.deleted }`
        );
        onConfirm();
        clearAllSelectedState();
      })
      .catch(() => {
        Toastr.showErrorMessage('Delete Contact API Error');
        onConfirm();
        clearAllSelectedState();
      });
  };

  render() {
    const { browserLanguage,peopleList,onCancel } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <Dialog
        showHeader = { false }
        visible
        style = { { width: '30vw' } }
        id = "logOutDialog"
      >
        <div className = "logOutHeader">
          {peopleList.length === 1
            ? `${ translator.DeleteDialog.delete } ${ peopleList[ 0 ].name }?`
            : `${ translator.DeleteDialog.delete } ${ peopleList.length } ${ translator.DeleteDialog.contacts }?`}
        </div>
        <div className = "logOutBody">
          {peopleList.length === 1
            ? `${ translator.DeleteDialog.confirmThat } ${ peopleList[ 0 ].name } ${ translator.DeleteDialog.contact }.  ${ translator.DeleteDialog.noteThis }`
            : `${ translator.DeleteDialog.confirmThat } ${ peopleList.length } ${ translator.DeleteDialog.contacts }.  ${ translator.DeleteDialog.noteThis }`}
        </div>
        <div style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } } />
        <div className = "logOutFooter">
          <div
            onClick = { () => onCancel() }
            className = "logOutFooterText"
            aria-hidden = "true"
          >
            {translator.DeleteDialog.cancel}
          </div>
          <Button
            size = "sm"
            id = "deletePeople"
            onClick = { () => {
              this.deleteMembers();
            } }
          >
            {translator.DeleteDialog.delete}
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => 
  ({
    browserLanguage: state.browserLanguage,
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = () => {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(deleteContactDialogue);
