import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { translation } from '../../../locales/translation';
import './DailyPulse.css';

const translator = new LocalizedStrings(translation);
class DailyPulse extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { browserLanguage , dailyPulseData }= this.props;
    translator.setLanguage(browserLanguage);
    return (
      <>
        <div className = "pulseQuadrantContainer">
          <div className = "pulseMainEmoticon">
            <img
              alt = "error"
              src = { dailyPulseData.medianImage }
              style = { { width: '82px', aspectRatio: 1 } }
            />
          </div>
          <div className = "pulseQuadrantMainText">
            {browserLanguage === 'en'
              ? dailyPulseData.medianType
              : dailyPulseData.medianType_FI}
          </div>
        </div>

        {dailyPulseData.moodModels.length > 0 &&
          dailyPulseData.moodModels.map((mood, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key = { index }>
              <div className = "pulseQuadrantSubContainer">
                <img alt = "error" src = { mood.moodImage } style = { { width: '27.5px', aspectRatio: 1 } } />
                <div className = "pulseQuadrantSubText">
                  {mood.moodPercentage}%
                  <span style = { { fontWeight: 400 } }>
                    {' '}
                    - {mood.noOfVotes} {translator.HomePage.votes}
                  </span>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DailyPulse);
