/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import './People.css';
import {
  Search,
  Loading,
  Checkbox,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Tooltip,
} from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../locales/translation.js';
import Avatars from '../../components/Avatar/Avatar.js';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody, isRolePEC } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';
import PeopleRightSection from './PeopleRightSection.js';
import PeopleDetailsScreen from '../PeopleDetails/PeopleDetails';
import PeopleDetailsPECScreen from '../PeopleDetails/PeopleDetailsPEC';
import RewardsScreen from '../Rewards/Rewards';
import AddToTeam from './AddToTeam/AddToTeam.js';
import DeletePeopleDialog from './DeletePeopleDialog.js';
import Messages from '../Messages/MsgSupToDrivers.js';

const translator = new LocalizedStrings(translation);
const noOfRows = 10;
class People extends Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleDetailsList: [],
      selectedPeopleList: [],
      descendingPeople: false,
      descendingLevel: false,
      descendingEmpNo: false,
      descendingTeam: false,
      selectedPeopleWholeDetails: [],
      originalDataList: [],
      searchText: '',
      currentPageNumber: 1,
      loading: true,
      selectedEmployeeData: {},
      peopleDetailsDialog: false,
      counter: 0,
      rewardsOverLay: false,
      addToTeamDialog: false,
      deletePeopleDialog: false,
      flagMessageScreen: false,
      selectedPeopleUserSourceSystemId: [],
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.getPeopleScreenData();
    }, 3500);
  }

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props;
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.setState( { loading : true } )
      this.getPeopleScreenData();
      this.state.selectedPeopleList = [];
      this.state.searchText = '';
    }
  }

  getPeopleScreenData = () => {
    const { browserLanguage, selectedCostCenter } = this.props;
    this.setState({ counter: 0, currentPageNumber : 1 });
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;

    this.commonService
      .postMethod(apiUrl.getPeopleScreenData, jsonBody)
      .then((res) => {
        this.setState({
          peopleDetailsList: res.data,
          originalDataList: res.data,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  };

  onCheckboxClick = (e, members) => {
    const { selectedPeopleList, selectedPeopleWholeDetails, selectedPeopleUserSourceSystemId } =
      this.state;
    const array = [ ...selectedPeopleList ];
    const arrayDetailOfMember = [ ...selectedPeopleWholeDetails ];
    let tempselectedPeopleUserSourceSystemId = selectedPeopleUserSourceSystemId;

    if (e.target.checked) {
      array.push(e.target.value);
      arrayDetailOfMember.push(members);
      tempselectedPeopleUserSourceSystemId = [
        ...tempselectedPeopleUserSourceSystemId,
        members.userSourceSystemId,
      ];
    } else {
      array.splice(array.indexOf(e.target.value), 1);
      arrayDetailOfMember.splice(arrayDetailOfMember.indexOf(members), 1);
      tempselectedPeopleUserSourceSystemId = tempselectedPeopleUserSourceSystemId.filter(
        (ele) => members.userSourceSystemId.indexOf(ele) < 0
      );
    }
    this.setState({
      selectedPeopleList: array,
      selectedPeopleWholeDetails: arrayDetailOfMember,
      selectedPeopleUserSourceSystemId: tempselectedPeopleUserSourceSystemId,
    });
  };

  sortPeople = () => {
    const { peopleDetailsList, descendingPeople } = this.state;
    const array = peopleDetailsList;
    const descending = descendingPeople;
    if (descending) {
      array.reverse();
    } else {
      array.sort((a, b) => (a.firstName > b.firstName ? 1 : -1));
    }
    this.setState({
      peopleDetailsList: array,
      descendingPeople: !descending,
      descendingType: false,
      descendingEmpNo: false,
      descendingLevel: false,
      descendingTeam: false,
      currentPageNumber: 1,
    });
  };

  sortEmpNo = () => {
    const { peopleDetailsList, descendingEmpNo } = this.state;
    const array = peopleDetailsList;
    const descending = descendingEmpNo;
    if (descending) {
      array.reverse();
    } else {
      array.sort((a, b) => (a.userSourceSystemId > b.userSourceSystemId ? 1 : -1));
    }
    this.setState({
      peopleDetailsList: array,
      descendingEmpNo: !descending,
      descendingType: false,
      descendingPeople: false,
      descendingLevel: false,
      descendingTeam: false,
      currentPageNumber: 1,
    });
  };

  sortLevel = () => {
    const { peopleDetailsList, descendingLevel } = this.state;
    const array = peopleDetailsList;
    const descending = descendingLevel;
    if (descending) {
      array.reverse();
    } else {
      array.sort((a, b) => (a.roleName > b.roleName ? 1 : -1));
    }
    this.setState({
      peopleDetailsList: array,
      descendingLevel: !descending,
      descendingEmpNo: false,
      descendingType: false,
      descendingPeople: false,
      descendingTeam: false,
      currentPageNumber: 1,
    });
  };

  sortTeam = () => {
    const { peopleDetailsList, descendingTeam } = this.state;
    const array = peopleDetailsList;
    const descending = descendingTeam;
    if (descending) {
      array.reverse();
    } else {
      array.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));
    }
    this.setState({
      peopleDetailsList: array,
      descendingTeam: !descending,
      descendingType: false,
      descendingPeople: false,
      descendingEmpNo: false,
      descendingLevel: false,
      currentPageNumber: 1,
    });
  };

  sortType = () => {
    const { peopleDetailsList, descendingType } = this.state;
    const array = peopleDetailsList;
    const descending = descendingType;
    if (descending) {
      array.reverse();
    } else {
      array.sort((a, b) => (a.type > b.type ? 1 : -1));
    }
    this.setState({
      peopleDetailsList: array,
      descendingType: !descending,
      descendingEmpNo: false,
      descendingTeam: false,
      descendingPeople: false,
      descendingLevel: false,
      currentPageNumber: 1,
    });
  };

  globalSearch = (search) => {
    const { originalDataList } = this.state;
    if (search) {
      const filtered = originalDataList.filter(
        (value) =>
          // value.firstName.toLowerCase().includes(search.toLowerCase()) ||
          // value.userSourceSystemId.includes(search) ||
          // (value.type !== null
          //   ? value.type.toLowerCase().includes(search.toLowerCase())
          //   : 'update pending'
          value.firstName.toLowerCase().includes(search.toLowerCase()) ||
          value.userSourceSystemId.includes(search)
      );

      this.setState({
        peopleDetailsList: filtered,
        searchText: search,
        currentPageNumber: 1,
      });
    } else {
      this.setState({
        peopleDetailsList: originalDataList,
        searchText: search,
        currentPageNumber: 1,
      });
    }
  };

  badgeNameModificationPS=(data)=>{
    console.log(data,"dddddddddddddd");
    let abc= data && data.badgeName.split('');
    console.log(abc[ 2 ]);

    //return abc[ 2 ];
  }


  toolTipView = (data) => {
    const { browserLanguage } = this.props;
    console.log("DATAAAAAAAAAA",data);
    // let namePS='';
    let m="How are you";
    if(data && data.badgeName)
    {
      const abc= data.badgeName.split(" ");
      console.log("AAAAAAAAAAAAAA",abc[2],abc.length);
    
    }
    // let namePS='';
    //  if(abc.length == 3 )
    //  {
    //    namePS=abc[2]+'';
    //  }
    //  console.log(namePS,"llllllllllll");
    return (
      <div>
        <div className = "toolTipHeaderPeople">
          { isRolePEC() ?
            browserLanguage === 'en' ?  data.badgeName  : data.badgeNameFi
            :
            browserLanguage === 'en' ?  data.badgeName  : data.badgeNameFi
          }
          
        </div>
        <div className = "toolTipBodyPeople">{data.badgeAwardedDates.join(`, `)}</div>
      </div>
    );
  };

  onMsgDialogClose = () => {
    this.setState({
      flagMessageScreen: false,
      selectedPeopleList: [],
      selectedPeopleUserSourceSystemId: [],
    });
  };

  render() {
    const { browserLanguage } = this.props;
    const { peopleDetailsList } = this.state;
    let { currentPageNumber } = this.state;
    translator.setLanguage(browserLanguage);
    let startIndex = 0;
    let lastIndex = 0;
    const totalPageNumber = Math.ceil(peopleDetailsList.length / noOfRows);
    const maxPageNumber = Math.floor(peopleDetailsList.length / noOfRows);
    startIndex = (currentPageNumber - 1) * noOfRows;
    if (currentPageNumber <= maxPageNumber) {
      lastIndex = startIndex + noOfRows;
    } else {
      lastIndex = peopleDetailsList.length;
    }
    const {
      flagMessageScreen,
      loading,
      deletePeopleDialog,
      selectedPeopleWholeDetails,
      counter,
      addToTeamDialog,
      selectedPeopleList,
      rewardsOverLay,
      peopleDetailsDialog,
      selectedEmployeeData,
      searchText,
    } = this.state;
    const { selectedCostCenter } = this.props;
    return (
      <div className = "peopleContainer">
        <Dialog visible = { loading } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        {flagMessageScreen && (
          <Messages parentCallback = { this.onMsgDialogClose } selectedPeoples = { selectedPeopleList } />
        )}
        {deletePeopleDialog && (
          <DeletePeopleDialog
            peopleDetailsList = { selectedPeopleWholeDetails }
            onCancel = { () => this.setState({ deletePeopleDialog: false }) }
            setLoader = { () => {
              this.setState({
                deletePeopleDialog: false,
                loading: true,
              });
            } }
            onConfirm = { () => {
              setTimeout(() => {
                this.getPeopleScreenData();
                this.setState({
                  loading: false,
                  selectedPeopleWholeDetails: [],
                  selectedPeopleList: [],
                  counter: counter + 1,
                });
              }, 100);
            } }
          />
        )}

        {addToTeamDialog && (
          <AddToTeam
            peopleList = { selectedPeopleWholeDetails }
            peopleEmployeeIdList = { selectedPeopleList }
            onCancel = { () => {
              this.setState({ addToTeamDialog: false });
            } }
            onConfirm = { () => {
              setTimeout(() => {
                this.getPeopleScreenData();
                this.setState({
                  addToTeamDialog: false,
                  selectedPeopleWholeDetails: [],
                  selectedPeopleList: [],
                  counter: counter + 1,
                });
              }, 200);
            } }
          />
        )}
        {rewardsOverLay && (
          <RewardsScreen
            fromPeoplescreen
            checkboxSelectedValues = { selectedPeopleList }
            checkboxSelectedData = { selectedPeopleWholeDetails }
            closeRewardsOverlay = { () => {
              this.setState({
                rewardsOverLay: false,
                selectedPeopleList: [],
                selectedPeopleWholeDetails: [],
              });
              this.getPeopleScreenData();
            } }
          />
        )}
        {peopleDetailsDialog &&
          (isRolePEC() === true ? (
            <PeopleDetailsPECScreen
              selectedEmployeeData = { selectedEmployeeData }
              Dialog1
              OnHide = { () => {
                this.setState({ peopleDetailsDialog: false });
              } }
              OnSave = { () => {
                this.setState({ counter: counter + 1 }, () => {});
                this.getPeopleScreenData();
              } }
            />
          ) : (
            <PeopleDetailsScreen
              selectedEmployeeData = { selectedEmployeeData }
              Dialog2
              OnHide = { () => {
                this.setState({ peopleDetailsDialog: false });
              } }
              OnSave = { () => {
                this.setState({ counter: counter + 1 }, () => {});
                this.getPeopleScreenData();
              } }
            />
          ))}
        <div className = { !isRolePEC() ? 'peopleLeftSection' : 'peopleLeftSectionRoles' }>
          <div className = "peopleScreenHeaderContainer">
            <div className = "peopleHeader">{selectedCostCenter.costCenterName}</div>
            <div style = { { width: '300px' } }>
              <Search
                aria-label = { translator.RewardsScreen.searchText }
                id = "peopleSearch"
                label = {
                  browserLanguage === 'en' ? (
                    <div style = { { color: '#B0B0B0' } }>Search</div>
                  ) : (
                    <div style = { { color: '#B0B0B0' } }>Hae</div>
                  )
                }
                message = ""
                value = { searchText }
                onChange = { (e) => {
                  this.globalSearch(e.target.value);
                } }
              />
            </div>
          </div>

          <div
            style = { {
              display: 'flex',
              alignItems: 'center',
              height: '56px',
            } }
          >
            <div className = { isRolePEC() ? 'peopleTableHeading' : 'peopleTableHeader' }>
              <div className = "peopleHeaderText">{translator.PeopleScreen.peopleText}</div>
              <img
                aria-hidden = "true"
                alt = "NoImage"
                src = { require('../../images/sort.png') }
                style = { { cursor: 'pointer' } }
                onClick = { () => this.sortPeople() }
              />
            </div>
            {isRolePEC() && (
              <div className = { isRolePEC() ? 'levelTableHeading' : 'peopleTableHeader' }>
                <div className = "peopleHeaderText">{translator.PeopleScreen.employeeNo}</div>
                <img
                  aria-hidden = "true"
                  alt = "NoImage"
                  src = { require('../../images/sort.png') }
                  style = { { cursor: 'pointer' } }
                  onClick = { () => this.sortEmpNo() }
                />
              </div>
            )}

            <div className = { isRolePEC() ? 'levelTableHeading' : 'peopleTableHeader' }>
              <div className = "peopleHeaderText">{translator.PeopleScreen.levelText}</div>
              <img
                aria-hidden = "true"
                alt = "NoImage"
                src = { require('../../images/sort.png') }
                style = { { cursor: 'pointer' } }
                onClick = { () => this.sortLevel() }
              />
            </div>
            {/* {isRolePEC() && (
              <div className = { isRolePEC() ? 'levelTableHeading' : 'peopleTableHeader' }>
                <div className = "peopleHeaderText">{translator.PeopleScreen.type}</div>
                <img
                  aria-hidden = "true"
                  alt = "NoImage"
                  src = { require('../../images/sort.png') }
                  style = { { cursor: 'pointer' } }
                  onClick = { () => this.sortType() }
                />
              </div>
            )} */}

            {!isRolePEC() && (
              <div className = "peopleTableHeader">
                <div className = "peopleHeaderText">{translator.PeopleScreen.teamText}</div>
                <img
                  aria-hidden = "true"
                  alt = "NoImage"
                  src = { require('../../images/sort.png') }
                  style = { { cursor: 'pointer' } }
                  onClick = { () => this.sortTeam() }
                />
              </div>
            )}
            {/* {isRolePEC() && ( */}
            <div className = { isRolePEC() ? "exceptionalRewardsTableHeading":"exceptionalRewardsTableHeadingPS" }>
              <div className = { isRolePEC() ? "peopleHeaderText" : "peopleHeaderTextPS" } >{translator.PeopleScreen.exceptionReward}</div>
            </div>
            {/* )} */}
          </div>

          <div style = { { border: '1px solid #000', margin: '0 40px 0 24px' } } />
          <div
            style = { {
              overflowY: 'scroll',
              height: 'calc(92vh - 115px)',
              paddingBottom: '8vh',
            } }
          >
            {peopleDetailsList &&
              peopleDetailsList.slice(startIndex, lastIndex).map((value, index) => (
                <div key = { value.index }>
                  <div
                    style = { {
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: isRolePEC() ? '10px' : null,
                    } }
                  >
                    <div
                      style = { {
                        display: 'flex',
                        alignItems: 'center',
                        margin: isRolePEC() ? '10px 0 0 24px' : '10px 24px',
                        width: isRolePEC() ? '30.5%' : '50%',
                      } }
                    >
                      <Checkbox
                        id = { `checkbox-${ index }` }
                        onChange = { (e) => this.onCheckboxClick(e, value) }
                        checked = { selectedPeopleList.includes(value.userSourceSystemId) }
                        value = { value.userSourceSystemId }
                      />
                      <div
                        aria-hidden = "true"
                        className = "avatarhover"
                        onClick = { () => {
                          this.setState({
                            selectedEmployeeData: value,
                            peopleDetailsDialog: true,
                          });
                        } }
                        style = { { margin: '0 24px' } }
                      >
                        <Avatars
                          imageSource = { value.userImagePath }
                          name = { value.firstName }
                          avatarColor = "#FFA894"
                          size = { 66 }
                          fontSize = "24px"
                        />
                      </div>
                      <div className = "peopleName">
                        {isRolePEC()
                          ? `${ value.firstName } `
                          : `${ value.firstName } (${ value.userSourceSystemId })`}
                        {/* {`${ value.firstName } (${ value.userSourceSystemId  })`} */}
                        {isRolePEC() ? null : (
                          <div className = "peopleDetails">
                            {browserLanguage === 'en' ? value.roleName : value.roleName_FI}
                            {', '}
                            {value.groupName ? value.groupName : translator.PeopleScreen.noTeam}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* {isRolePEC() ? ( */}
                    {isRolePEC() && 
                    <div className = "peopleDetailsPECEmployee">
                      {browserLanguage === 'en'
                        ? value.userSourceSystemId
                        : value.userSourceSystemId}
                    </div> }
                    {isRolePEC() && 
                    <div className = "peopleDetailsPEC">
                      {browserLanguage === 'en' ? value.roleName : value.roleName_FI}
                    </div>}
                    {/* {isRolePEC() && value.type !== null && (
                      <div className = "peopleDetailsPECLevel">
                        {browserLanguage === 'en'
                          ? value.type
                          : value.type === 'parcel'
                            ? 'kevyt'
                            : 'raskas'}
                      </div>
                    )} */}
                    {isRolePEC() && value.type === null && (
                      <div className = "peopleDetailsPECLevel">
                        {browserLanguage === 'en' ? "" : ""}
                      </div>
                    )}
                    <div className = "peopleBadgesPEC">
                      {value.userBadgeDetails.map((data) => (
                        <Tooltip
                          title = { this.toolTipView(data) }
                          placement = "bottom"
                          arrow = { false }
                          className = "peopleAvatarWrapper"
                          id = "peoplePostiTooltip"
                        >
                          <div>
                            <Avatar
                              image = { data.badgeImageIcon }
                              className = "p-overlay-badge"
                              style = { {
                                backgroundColor: '#4caf4f',
                                color: '#ffffff',
                                height: '44px',
                                width: '44px',
                                cursor: 'pointer',
                              } }
                              shape = "circle"
                            >
                              {Number(data.countOfBadge) > 1 ? (
                                <Badge
                                  value = { data.countOfBadge }
                                  style = { {
                                    backgroundColor: '#0051C2',
                                    color: '#ffffff',
                                  } }
                                />
                              ) : null}
                            </Avatar>
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                    {/* ) : null} */}
                  </div>
                  <div
                    style = { {
                      border: '1px solid #EAEDF1',
                      margin: '0 24px',
                    } }
                  />
                </div>
              ))}
          </div>

          <div
            className = { !isRolePEC() ? 'homepageFooterContainer' : 'homepageFooterContainerRoles' }
          >
            <div style = { { border: '1px solid #C2C9D1', marginRight: '4px' } } />
            <div
              style = { {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '8vh',
              } }
            >
              <div style = { { display: 'flex', alignItems: 'center' } }>
                <ChevronLeftIcon
                  style = { { cursor: 'pointer' } }
                  aria-hidden = "true"
                  height = "24px"
                  onClick = { () => {
                    if (currentPageNumber > 1) {
                      currentPageNumber -= 1;
                    }
                    this.setState({ currentPageNumber });
                  } }
                  color = { currentPageNumber === 1 ? 'rgba(115, 128, 140, 0.5)' : '#73808C' }
                  width = "24px"
                />
                <div className = "peopleScreenPagination">
                  {totalPageNumber === 0
                    ? `${ 0 } / ${ totalPageNumber }`
                    : `${ currentPageNumber } / ${ totalPageNumber }`}
                </div>
                <ChevronRightIcon
                  style = { { cursor: 'pointer' } }
                  aria-hidden = "true"
                  height = "24px"
                  color = {
                    currentPageNumber === totalPageNumber ? 'rgba(115, 128, 140, 0.5)' : '#73808C'
                  }
                  onClick = { () => {
                    if (currentPageNumber !== totalPageNumber) {
                      currentPageNumber += 1;
                    }
                    this.setState({ currentPageNumber });
                  } }
                  width = "24px"
                  className = { peopleDetailsList.length > 0 ? null : 'disablePreNextArrow' }
                />
                <div
                  className = {
                    selectedPeopleList.length > 0
                      ? 'peopleFooterTextEnable'
                      : 'peopleFooterTextDisable'
                  }
                  onClick = { () =>
                    selectedPeopleList.length > 0 ? this.setState({ deletePeopleDialog: true }) : null
                  }
                  aria-hidden = "true"
                >
                  {translator.PeopleScreen.delete}
                </div>
              </div>
              <div style = { { display: 'flex', alignItems: 'center' } }>
                {!isRolePEC() && (
                  <div
                    className = {
                      selectedPeopleList.length > 0
                        ? 'peopleFooterTextEnable'
                        : 'peopleFooterTextDisable'
                    }
                    onClick = { () => {
                      if (selectedPeopleList.length > 0) {
                        this.setState({ addToTeamDialog: true });
                      }
                    } }
                    aria-hidden = "true"
                  >
                    {translator.PeopleScreen.addToTeam}
                  </div>
                )}
                {isRolePEC() && (
                  <button
                    type = "button"
                    className = "msgPeopleScreenButton"
                    onClick = { () => {
                      this.setState({ flagMessageScreen: true });
                    } }
                  >
                    {translator.msgSUPToDrivers.newMsg}
                  </button>
                )}
                <Button
                  size = "sm"
                  id = "newRewardButton"
                  onClick = { () => {
                    this.setState({ rewardsOverLay: true });
                  } }
                >
                  {translator.PeopleScreen.newRewardText}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {!isRolePEC() && (
          <div className = "peopleRightSection">
            <PeopleRightSection
              counter = { counter }
              remount = { () => {
                this.getPeopleScreenData();
              } }
              parentProps = { this.props }
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(People);
