import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from '@postidigital/posti-components';
import { authContext } from '../adalConfig';
import apiUrl from '../urlConstants.js';
import { CommonService } from '../service/CommonService.js';
import './NoRolePopUp.css';

class NoRolePopUp extends Component {
  constructor(props) {
    super(props);
    this.commonService = new CommonService();
  }

  logOut = () => {
    const { selectedCostCenter } = this.props;
    const jsonBody = {};
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.logOutAction, jsonBody)
      .then(() => {})
      .catch(() => {});
  };

  render() {
    return (
      <Dialog showHeader = { false } visible style = { { width: '450px' } } id = "logOutDialog">
        <div className = "logOutHeader">No access rights</div>
        <div className = "logOutBodyNoRole">
          You have no access to Admin portal. Please contact your superior to be granted the rights
          needed.
        </div>
        <div style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } } />
        <div className = "logOutFooter">
          <Button
            size = "sm"
            id = "deletePeople"
            onClick = { () => {
              this.logOut();
              setTimeout(() => {
                authContext.logOut();
              }, 1500);
            } }
          >
            LogOut
          </Button>
        </div>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => 
  ({
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = () => {
};

export default connect(mapStateToProps, mapDispatchToProps)(NoRolePopUp);
