/* eslint-disable */
/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import './TwoWayMessages.css';
import LocalizedStrings from 'react-localization';
import {
  Button,
  Input,
  Tab,
  TabContext,
  TabPanel,
  Tabs,
  Loading
} from '@postidigital/posti-components';
import { InputTextarea } from 'primereact/inputtextarea';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { Avatar } from 'primereact/avatar';
import { translation } from '../../locales/translation.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants.js';
import { CommonService } from '../../service/CommonService.js';
import Toastr from '../../shared/Toastr';

const translator = new LocalizedStrings(translation);
export class TwoWayMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversatationData: {},
      loading: true,
      expand: false,
      closeState: true,
      inputText: null,
      inputTextArea: '',
      templateFlag: false,
      selectedTabID: 'tab1',
      templateSelectedRadioButton: null,
      templateData: [],
      expandedTemplatesList: [],
      graphicImageList: [
        'mark',
        'question',
        'money',
        'msg',
        'clap',
        'party',
        'wine',
        'snow',
        'flower',
        'flag',
      ],
      selectedGraphicImage: 'MailBox',
      displayMessage: [],
      conversationLength: 0,
    };
    this.commonService = new CommonService();
    this.endScroll = React.createRef()
  }

  componentDidMount() {
    this.getMessageTemplates();
    this.getConversatationMessages();
  }

  componentDidUpdate(){
    this.endScroll.current.scrollIntoView(false)
  }

  getConversatationMessages = () => {
    const { browserLanguage, selectedCostCenter, selectedPeopleUserId } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.userSourceSystemId = '';
    jsonBody.interval = 'string';
    jsonBody.startDate = '2022-07-14T13:51:52.687Z';
    jsonBody.endDate = '2022-07-14T13:51:52.687Z';
    jsonBody.tertile = 'string';
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      userId: selectedPeopleUserId[ 0 ],
    };
    this.commonService
      .postMethod(apiUrl.getConversatationMessage, data)
      .then((res) => {
        this.setState(
          {
            conversatationData: res.data,
            conversationLength: res.data.messageList.length,
            loading: false,
          },
        );
      })
      .catch(() => {
        this.setState({});
        Toastr.showErrorMessage('Fetching messages error');
      });
  };

  isMessageAcknowledged = () => {
    const { browserLanguage, selectedCostCenter, selectedPeopleUserId } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.userSourceSystemId = '';
    jsonBody.interval = 'string';
    jsonBody.startDate = '2022-07-14T13:51:52.687Z';
    jsonBody.endDate = '2022-07-14T13:51:52.687Z';
    jsonBody.tertile = 'string';
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      userId: selectedPeopleUserId[ 0 ],
      msgId: 0,
    };
    this.commonService
      .postMethod(apiUrl.getAckMessage, data)
      .then(( ) => {
        this.setState({});
      })
      .catch(() => {
        this.setState({});
        Toastr.showErrorMessage('Message Acknowledge Failed');
      });
  };

  replyToMessages = () => {
    const { browserLanguage, selectedCostCenter, selectedPeopleUserSourceSystemId } = this.props;
    const { inputText, inputTextArea, selectedGraphicImage } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.masterGroupSourceSystemId = getJsonBody().masterGroupSourceSystemId;
    jsonBody.userSourceSystemId = '';
    jsonBody.messageTitle = inputText;
    jsonBody.messageBody = inputTextArea;
    jsonBody.graphicsUrl = selectedGraphicImage;
    jsonBody.userSystemIds = [ String(selectedPeopleUserSourceSystemId) ];
    this.commonService
      .postMethod(apiUrl.sendMsgToDriver, jsonBody)
      .then(( ) => {
        this.getConversatationMessages();
        Toastr.showSuccessMessage('Message Sent');
        this.setState({
          inputText:"",
          inputTextArea:""
        });
      })
      .catch(() => {
        this.setState({});
        Toastr.showErrorMessage('Message Sending Failed');
      });
  };

  saveMessageTemplates = () => {
    const { selectedCostCenter } = this.props;
    const { inputText, inputTextArea } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.basicParameters = {};
    jsonBody.basicParameters.applicationId = jsonBody.applicationId;
    jsonBody.basicParameters.userSourceSystemId = '';
    jsonBody.basicParameters.masterGroupSourceSystemId = jsonBody.masterGroupSourceSystemId;
    jsonBody.basicParameters.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.messageTitle = inputText;
    jsonBody.messageBody = inputTextArea;
    this.commonService
      .postMethod(apiUrl.saveMessageTemplates, jsonBody)
      .then(() => {
        this.getMessageTemplates();
        Toastr.showSuccessMessage(translator.msgSUPToDrivers.saveTemplate);
        // this.setState({
        //   templateFlag: false,
        // });
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  };

  deleteMessageTemplates = () => {
    const { selectedCostCenter } = this.props;
    const { templateSelectedRadioButton } = this.state;
    const jsonBody = getJsonBody();
    jsonBody.basicParameters = {};
    jsonBody.basicParameters = {};
    jsonBody.basicParameters.applicationId = jsonBody.applicationId;
    jsonBody.basicParameters.userSourceSystemId = '';
    jsonBody.basicParameters.masterGroupSourceSystemId = jsonBody.masterGroupSourceSystemId;
    jsonBody.basicParameters.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.id = templateSelectedRadioButton.id;
    this.commonService
      .postMethod(apiUrl.deleteMessageTemplate, jsonBody)
      .then(() => {
        this.getMessageTemplates();
        Toastr.showSuccessMessage(translator.msgSUPToDrivers.delTemplate);
        this.setState({
          templateSelectedRadioButton:null
        })
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  };

  getMessageTemplates = () => {
    const { selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.userSourceSystemId = '';
    this.commonService
      .postMethod(apiUrl.getMessageTemplates, jsonBody)
      .then((res) => {
        this.setState({
          templateData: res.data,
        });
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.fetchPeopleFailed);
      });
  };

  sendMessage = () => {
    const { displayMessage, conversatationData } = this.state;
    const days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
    const current = new Date();
    const day = days[ current.getDay() ];
    const date = `${ day } ${ current.getDate() }.${
      current.getMonth() + 1
    }.${ current.getFullYear() } | ${ current.getHours() }:${ current.getMinutes() } `;
    const { selectedGraphicImage } = this.state;
    return (
      displayMessage &&
      displayMessage.map((message) => (
        <Box
          component = "div"
          sx = { {
            border: '2px solid #A4DEDF',
            borderRadius: '16px',
            width: '389px',
            alignSelf: 'flex-end',
            marginRight: '33px',
            marginTop: '20px',
          } }
        >
          <div className = "mainDiv">
            <div style = { { padding: '20px', width: '90.5%' } }>
              <div className = "dayDateTime">{date}</div>
              <div className = "messageContent" style = { { paddingTop: '10px' } }>
                <img
                  src = { `https://gamifystore.blob.core.windows.net/routemaster-prod-messageicons/${ selectedGraphicImage }.png` }
                  alt = "noImage"
                  aria-hidden = "true"
                  width = { 44 }
                  height = { 44 }
                />
              </div>
              <div className = "messageHeading" style = { { paddingTop: '14px' } }>{message.inputText}</div>
              <div className = "messagesBody">{message.inputTextArea}</div>
            </div>
            <div style = { { paddingTop: '3%', paddingLeft: '10%' } }>
              <Avatar
                label = {
                  conversatationData.userName &&
                  conversatationData.userName.charAt(0)
                }
                size = "large"
                shape = "circle"
                style = { { backgroundColor: '#A4DEDF', color: '#ffff' } }
                className = "primeFirstAvatar1"
              />
            </div>
          </div>
        </Box>
      ))
    );
  };

  arrowIconPressed = (data) => {
    const { expandedTemplatesList } = this.state;
    if (expandedTemplatesList.includes(data)) {
      this.setState({
        expandedTemplatesList: expandedTemplatesList.filter((item) => item !== data),
      });
    } else {
      const temp = expandedTemplatesList;
      temp.push(data);
      this.setState({
        expandedTemplatesList: temp,
      });
    }
  };

  leftSectionContainer = () => {
    const { browserLanguage } = this.props;
    const { conversatationData, conversationLength } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <div className = "twoWayMessageLeftSection">
        <div className = "twoWayMessageLeftContainer">
          <div className = "twoWayMessageLeftHeading">
            { browserLanguage === 'en' ? `Messaging with ${ conversatationData && conversatationData.userName && conversatationData.userName.split(' ').shift() }` : `${ conversatationData && conversatationData.userName && conversatationData.userName.split(' ').shift() } viestit` }
          </div>
          <div className = "twoWayAvatarContainer">
            <Avatar
              image = { conversatationData.userProfileImage !== null && conversatationData.userProfileImage }
              label = {
                conversatationData.userProfileImage === null && 
                conversatationData.userName &&
                conversatationData.userName.charAt(0)
              }
              size = "xlarge"
              shape = "circle"
              style = { { backgroundColor: '#FFA894', color: '#ffff' } }
              className = "primeFirstAvatar"
            />
          </div>
        </div>
        <div
          style = { {
            height: '750px',
            paddingTop: '85px',
          } }
        >
          <div
            id = "twoWayMainContainer"
            style = { {
              display: 'flex',
              flexDirection: 'column',
              height: '673px',
              overflowY: 'scroll',
              paddingLeft: '42px',
              paddingRight: '10px',
              paddingTop: '20px',
              marginTop:'-8px'
            } }
          >
            {conversatationData.hasDeletedMessages ? (
              <div style = { { display:'flex', marginTop : '27px' } }>
                <div className = "linesCSS" />
                <div className = "deleteMessageNotify">
                  {translator.twoWayMsg.deleteInfo}
                </div>
                <div className = "linesCSS" />
              </div>
            ) : null}
            
            {conversationLength && conversationLength > 0 ? (
              conversatationData &&
              conversatationData.messageList &&
              conversatationData.messageList.map((messages) =>
                messages.messageType === 'I' ? (
                  <Box
                    component = "div"
                    sx = { {
                      border: '2px solid #FFA894',
                      borderRadius: '16px',
                      width: '389px',
                      alignSelf: 'flex-start',
                      marginTop: '20px',
                    } }
                  >
                    <div className = "mainDivRecieved">
                      <div style = { { padding: '20px', width: '90.5%' } }>
                        <div className = "dayDateTime" style = { { display: 'flex' } }>
                          <div>
                            {moment(messages.messageSentDateTime).format('DD.MM.YYYY | HH:MM')}
                          </div>
                          <div className = "newIndication">
                            {messages.isSupervisorAcknowledged === false ? '(new)' : null}
                          </div>
                        </div>
                        <div className = "messageHeading">{messages.messageTitle}</div>
                        <div className = "messageContent" style = { { paddingTop: '8px' } }>
                          {messages.messageBody}
                        </div>
                      </div>
                      <div style = { { paddingTop: '4%' } }>
                        <Avatar
                          image = { messages.userProfileImage !== null && messages.userProfileImage }
                          label = {
                            messages.userProfileImage === null && 
                            conversatationData.userName &&
                            conversatationData.userName.charAt(0)
                          }
                          size = "large"
                          shape = "circle"
                          style = { { backgroundColor: '#FFA894', color: '#ffff' } }
                          className = "primeFirstAvatar"
                        />
                      </div>
                    </div>
                  </Box>
                ) : (
                  <Box
                    component = "div"
                    sx = { {
                      border: '2px solid #A4DEDF',
                      borderRadius: '16px',
                      width: '389px',
                      alignSelf: 'flex-end',
                      marginRight: '33px',
                      marginTop: '20px',
                    } }
                  >
                    <div className = "mainDiv">
                      <div style = { { padding: '20px', width: '90.5%' } }>
                        <div className = "dayDateTime">{moment(messages.messageSentDateTime).format('DD.MM.YYYY | HH:MM')}</div>
                        <div className = "messageContent" style = { { paddingTop: '10px' } }>
                          <img
                            src = { messages.graphicsUrl }
                            alt = "noImage"
                            aria-hidden = "true"
                            height = { 44 }
                            width = { 44 }
                          />
                        </div>
                        <div className = "messageHeading" style = { { paddingTop: '14px' } }>
                          {messages.messageTitle}
                        </div>
                        <div className = "messagesBody">{messages.messageBody}</div>
                      </div>
                      <div style = { { paddingTop: '3%', paddingLeft: '10%' } }>
                        <Avatar
                          label = { messages.messageOwner.charAt(0) }
                          size = "large"
                          shape = "circle"
                          style = { { backgroundColor: '#A4DEDF', color: '#ffff' } }
                          className = "primeFirstAvatar1"
                        />
                      </div>
                    </div>
                  </Box>
                )
              )
            ) : null
            }
            {this.sendMessage()}
            <div
              style = { {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginTop: '18px',
              } }
            />
            <div ref = { this.endScroll } />
          </div>
          <div
            style = { {
              border: '1px solid #c2c9d1',
              marginLeft: '32px',
              marginRight: '32px',
              marginTop: '6px',
              position: 'absolute',
              width: '498.82px',
            } }
          />
          {this.buttonSection()}
        </div>
      </div>
    );
  };

  buttonSection = () => {
    const { expand, conversatationData } = this.state;
    const { parentCallback } = this.props;
    return (
      <div className = "twoWayButtonContainer">
        <button
          type = "button"
          className = "editButtonTwoWay"
          id = "twoWayEditButton"
          onClick = { () => {
            this.isMessageAcknowledged();
            parentCallback();
          } }
          style = { {
            backgroundColor: '#FFFFFF',
            color: expand ? '#0051C2' : '#0051C2',
          } }
        >
          {translator.PeopleDetails.close}
        </button>
        <Button
          className = "sendButtonMsgTW"
          id = "twoWay-nextButton"
          disabled = { expand }
          size = "sm"
          onClick = { () => {
            this.isMessageAcknowledged();
            this.setState({ expand: true });
          } }
        >
          {conversatationData.isNotInitiated === true ? "New message" : "Reply"}
        </Button>
      </div>
    );
  };

  recipientsScreen = () => {
    const { inputText, inputTextArea, templateFlag } = this.state;
    return (
      <>
        <>
          <div className = "twoWayRecipientsScreenHeaderRight">{translator.msgSUPToDrivers.msgTitle}</div>
          <div className = "twoWayRecipientsScreenBody">
            <Input
              className = "rightMsgDialogBodyInputTW"
              // label = { translator.msgSUPToDrivers.enterText }
              value = { inputText }
              onChange = { (e) =>
                this.setState({
                  inputText: e.target.value,
                  templateFlag: false,
                })
              }
            />
          </div>
          <div className = "rightMsgDialogBodyContentTW">{translator.msgSUPToDrivers.msgContent}</div>
          <InputTextarea
            className = "rightMsgDialogBodyInputTextArea"
            value = { inputTextArea }
            onChange = { (e) => this.setState({ inputTextArea: e.target.value, templateFlag: false, }) }
            rows = { 20 }
            cols = { 60 }
            autoResize
            maxlength = "1000"
          />
          <div className = "TWmsgTextAreaCount">{`${ inputTextArea.length }/1000`}</div>
          {/* <div className = "TWmsgCheckBoxAndLabelWrapper">
            <Checkbox
              checked = { templateFlag }
              onChange = { () =>
                this.setState(
                  {
                    templateFlag: !templateFlag,
                  },
                  () => {
                    if (!templateFlag) {
                      this.saveMessageTemplates();
                    }
                  }
                )
              }
            />
            <div>{translator.msgSUPToDrivers.templateCheckbox}</div>
          </div> */}
        </>
        <div className = "recipientsScreenFooterTwoWay">
          <div className = "recipientsFooterLineTW" />
          <div className = "leftMsgDialogBodyFooterLineWrapperTW">
          <div>
            <button type = "button" className = { inputText !== null &&  inputTextArea !== "" ? "useAsATemplate1to1" : "useAsATemplateDisable1to1" } onClick = { () => this.setState( { selectedTabID : "tab2" },() => this.saveMessageTemplates()) }>{translator.msgSUPToDrivers.templateCheckbox}</button>  
          </div>
          <div style = { { display:"flex" } }>
          <button type = "button" className = "cancelButtonMsg" 
              onClick = { () => 
                this.setState({ expand:false })
              }
            >
              {translator.msgSUPToDrivers.cancelButton}
          </button>
          <Button
              className = "sendButtonMsgTW"
              onClick = { () => {
                this.replyToMessages();
                setTimeout(() => {
                  this.setState({ expand:false })
                }, 1500);
              } }
              disabled = { inputTextArea.length < 1 }
            >
              {translator.msgSUPToDrivers.msgSendButton}{' '}
          </Button>
          </div>
          </div>
        </div>
      </>
    );
  };

  templatesScreen = () => {
    const { templateData, expandedTemplatesList, templateSelectedRadioButton } = this.state;
    return (
      <>
        <div className = "templatesScreenHeaderTW">{translator.msgSUPToDrivers.msgTemplates}</div>
        <div className = "twoWaytemplatesScreenBody">
          {templateData &&
            templateData.map((data) => (
              <>
                <div className = "templatesScreenBodyHeaderTW">
                  <input
                    type = "radio"
                    value = "green"
                    checked = { data === templateSelectedRadioButton }
                    onChange = { () =>
                      this.setState({
                        templateSelectedRadioButton: data,
                      })
                    }
                  />
                  <div style = { { width: '300px', marginLeft: '5px', marginTop: '5px' } }>
                    {data.messageTitle}
                  </div>
                  <img
                    src = {
                      expandedTemplatesList.includes(data)
                        ? require('../../images/upArrow.png')
                        : require('../../images/downArrow.png')
                    }
                    alt = "noImage"
                    className = "templatesDownArrow"
                    aria-hidden = "true"
                    onClick = { () => this.arrowIconPressed(data) }
                  />
                </div>
                {expandedTemplatesList.includes(data) ? (
                  <div className = "templatesScreenBodyTW">{data.messageBody}</div>
                ) : null}
              </>
            ))}
        </div>
        <div className = "templatesScreenFooterTwoWay">
          <div className = "templatesFooterLineTW" />
          <div className = "templatesScreenFooterButtonWrapper">
            <button
              type = "button"
              className = {
                templateSelectedRadioButton === null
                  ? 'templatesDeleteDisableButton'
                  : 'templatesDeleteEnableButton1'
              }
              onClick = { () =>
                templateSelectedRadioButton.type === 'C'
                  ? this.deleteMessageTemplates()
                  : Toastr.showErrorMessage(translator.msgSUPToDrivers.deleteAPIError)
              }
            >
              {translator.msgSUPToDrivers.msgDeleteButton}
            </button>
            <Button
              className = "templatesUseButton"
              disabled = { templateSelectedRadioButton === null }
              onClick = { () =>
                this.setState({
                  inputText: templateSelectedRadioButton.messageTitle,
                  inputTextArea: templateSelectedRadioButton.messageBody,
                  selectedTabID: 'tab1',
                })
              }
            >
              {translator.msgSUPToDrivers.msgUseButton}
            </Button>
          </div>
        </div>
      </>
    );
  };

  graphicScreen = () => {
    const { graphicImageList, selectedGraphicImage } = this.state;
    return (
      <>
        <div className = "graphicScreenHeaderTW">{translator.msgSUPToDrivers.msgGraphic}</div>
        <div className = "graphicScreenHeaderBodyTW">
          {translator.msgSUPToDrivers.msgGraphicsContent}
        </div>
        <div className = "twoWaygraphicScreenSubHeader">
          {translator.msgSUPToDrivers.defaultGraphics}
        </div>
        <div className = "defaultGraphicBodyTW">
          <img
            src = "https://gamifystore.blob.core.windows.net/routemaster-prod-messageicons/MailBox.png"
            alt = "noImage"
            aria-hidden = "true"
            onClick = { () =>
              this.setState({
                selectedGraphicImage: 'MailBox',
              })
            }
          />
          {selectedGraphicImage === 'MailBox' ? (
            <img
              src = { require('../../images/tick.png') }
              alt = "noImage"
              aria-hidden = "true"
              className = "graphicTickTW"
            />
          ) : null}
        </div>
        <div className = "graphicCollectionHeader">{translator.msgSUPToDrivers.altOptions}</div>
        <div className = "graphicCollectionBody">
          {graphicImageList.map((data) => (
            <div className = "graphicCollectionInnerBodyTW">
              <img
                src = { `https://gamifystore.blob.core.windows.net/routemaster-prod-messageicons/${ data }.png` }
                alt = "noImage"
                aria-hidden = "true"
                onClick = { () =>
                  this.setState({
                    selectedGraphicImage: data,
                  })
                }
              />
              {selectedGraphicImage === data ? (
                <img
                  src = { require('../../images/tick.png') }
                  alt = "noImage"
                  aria-hidden = "true"
                  className = "graphicTickTW"
                />
              ) : null}
            </div>
          ))}
        </div>

        <div className = "graphicScreenFooterTwoWay">
          <div className = "graphicFooterLineTW" />
        </div>
      </>
    );
  };

  tabSelection = () => {
    const { selectedTabID } = this.state;
    return (
      <div>
        <TabContext>
          <Tabs selectedTab = { selectedTabID } className = "msgTabSelection">
            <Tab
              value = "tab1"
              panelId = "id-tab1"
              className = "msgTab1"
              onClick = { () =>
                this.setState({
                  selectedTabID: 'tab1',
                })
              }
            >
              {translator.msgSUPToDrivers.newMsg}
            </Tab>
            <Tab
              value = "tab2"
              panelId = "id-tab2"
              className = "msgTab2"
              onClick = { () =>
                this.setState({
                  selectedTabID: 'tab2',
                })
              }
            >
              {translator.msgSUPToDrivers.templates}
            </Tab>
            <Tab
              value = "tab3"
              panelId = "id-tab3"
              className = "msgTab3"
              onClick = { () =>
                this.setState({
                  selectedTabID: 'tab3',
                })
              }
            >
              {translator.msgSUPToDrivers.graphic}
            </Tab>
          </Tabs>
          <TabPanel id = "id-tab1" value = "tab1">
            {this.recipientsScreen()}
          </TabPanel>
          <TabPanel id = "id-tab2" value = "tab2">
            {this.templatesScreen()}
          </TabPanel>
          <TabPanel id = "id-tab3" value = "tab3">
            {this.graphicScreen()}
          </TabPanel>
        </TabContext>
      </div>
    );
  };

  render() {
    console.log("conversatationData",this.state.conversatationData)
    const { browserLanguage, parentCallback } = this.props;
    const { expand, closeState, loading } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <Dialog
        id = "people-detail"
        visible
        style = { {
          width: 'auto',
          height: '820px',
        } }
        onHide = { () => {
          parentCallback();
        } }
      >
        <div className = "PeopleDetailContainerTW">
          <Dialog visible = { loading } id = "loader">
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
          {this.leftSectionContainer()}
          <div
            className = {
              expand
                ? 'PeopleDetailsRightSectionExpandTW'
                : closeState
                  ? 'PeopleDetailsRightSectionCloseTW'
                  : 'PeopleDetailsRightSectionTW'
            }
          >
            {this.tabSelection()}
          </div>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TwoWayMessages);
