/* eslint-disable */
import React from 'react';
import './EditDialog.css';
import { Dialog } from 'primereact/dialog';
import { Button } from '@postidigital/posti-components';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';

const translator = new LocalizedStrings(translation);
function EditDialog(props) {
  const { browserLanguage, onEditCancelButton } = props
  translator.setLanguage(browserLanguage);
  return (
    <Dialog showHeader = { false } visible>
      <div className = "editDialogWrapper">
        <div className = "editDialogHeader">
          <div className = "editDialogBody">
            {translator.editConfiguration.editconfigurationdesc}
          </div>
        </div>
        <div className = "editDialogText">
          <div>{translator.editConfiguration.dialogdesc}</div>
          <div>
            {browserLanguage === 'en' ? translator.editConfiguration.dialogdescLast : null}
          </div>
        </div>
        <div className = "editfooterline" />
        {/* <div className = "editDialogOkButton"> */}
          <Button
          className= "editDialogOkButton"
            onClick = { () => {
              onEditCancelButton();
            } }
          >
              Ok
          </Button>
        {/* </div> */}
      </div>
    </Dialog>
  );
}
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(EditDialog);
// export default EditDialog;
