/* eslint-disable eqeqeq */
/* eslint-disable max-lines */
/* eslint-disable global-require */
import React, { Component } from 'react';
import './Data.css';
import { connect } from 'react-redux';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import {  Loading, Tooltip as PostiTooltip } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  Tooltip as ReTooltip } from 'recharts';
import Avatars from '../../components/Avatar/Avatar.js';
import { translation } from '../../locales/translation';
import DataFilter from './DataFilter.js';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants';
import CustomApiErrorDialog from '../Configuration/CustomApiErrorDialog.js';

const eToday = new Date();
let eDate = eToday.getDate();
let eMonth = eToday.getMonth() + 1;
const eYear = eToday.getFullYear();
if (eDate < 10) {
  eDate = `0${  eDate }`;
}
if (eMonth < 10) {
  eMonth = `0${  eMonth }`;
}
const endDate = `${ eYear  }-${  eMonth  }-${  eDate }`;
const date= eToday.setDate(eToday.getDate() - 56);
const s1Date = new Date(date);
let sDate = s1Date.getDate();
let sMonth = s1Date.getMonth() + 1;
const sYear = s1Date.getFullYear();
if (sDate < 10) {
  sDate = `0${  sDate }`;
}
if (sMonth < 10) {
  sMonth = `0${  sMonth }`;
}
const startDate = `${ sYear  }-${  sMonth  }-${  sDate }`;

const translator = new LocalizedStrings(translation);
class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val1: 0,
      val2: 0,
      val3: 0,
      val4: 0,
      val5: 0,
      val6: 0,
      val7: '',
      descendingPeople: false,
      loading: true,
      originalData: null,
      badgeDataCount: [],
      showTooltip: false,
      peopleData: [],
      badgeData: [],
      engageData: [],
      earningData: [],
      childData: null,
      selectedTertileFromChild: 'All tertiles',
      weekNo:8,
      apiflag: false,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    setTimeout(() => {
      this.dataAPICall();
      this.closeLoader();
      this.setState({ descendingPeople: true })
    }, 3500);
  };

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props;
    if (
      prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== ''
    ) {
      this.setState({ loading: true, descendingPeople: true })
      this.dataAPICall();
      this.closeLoader();
    }
  }

  resetFromFilter = (weekNo) => {
    const { originalData } = this.state;
    this.setState({
      selectedTertileFromChild: 'All tertiles',
      peopleData: originalData.data.pointsByPeople.drivers,
      badgeData: originalData.data.pointsByBadges.badgePointsData,
      engageData: originalData.data.engagement.weekDataModel,
      earningData: originalData.data.earningPoints,
      weekNo,
    });
  };

  dataAPICall = () => {
    const { browserLanguage, selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = 4;
    jsonBody.userSourceSystemId = '';
    const data = { ...jsonBody, startDate, endDate, Tertile: 'All' };
    this.commonService
      .postMethod(apiUrl.dataScreenApi, data)
      .then((res) => {
        this.setState({
          originalData: JSON.parse(JSON.stringify(res)),
          peopleData: res.data.pointsByPeople.drivers,
          badgeData: res.data.pointsByBadges.badgePointsData,
          badgeDataCount: res.data.pointsByBadges.badgePointsData,
          engageData: res.data.engagement.weekDataModel,
          earningData: res.data.earningPoints,
          childData: res.data,
        },()=> {this.sortFunction(1); this.sortFunction(2)});
      })
      .catch(() => {
        this.setState({ loading: false, apiflag: true });
        // Toastr.showErrorMessage('Api Error');
      });
  };

  closeLoader= () => {
    setTimeout(() => {
      this.setState({
        loading :false
      });
    }, 2500);
  };

  parentLoader = (value) => {
    if(value === true)
    {
      this.setState({ loading: true })
    }
    this.closeLoader()
  }

  dataFromFilter = (filteredData, selectedTertile, weeks) => {
    this.setState({
      loading:true,
      selectedTertileFromChild: selectedTertile,
      peopleData: filteredData.data.pointsByPeople.drivers,
      badgeData: filteredData.data.pointsByBadges.badgePointsData,
      engageData: filteredData.data.engagement.weekDataModel,
      earningData: filteredData.data.earningPoints,
      weekNo: weeks,
    },()=>this.closeLoader());
  };

  sortFunction = (value) => {
    const { peopleData, descendingPeople, badgeData, badgeDataCount } = this.state;
    const array = value == 1 ? peopleData : value == 2 ? badgeData : badgeDataCount
    const descending = descendingPeople;
    if (!descending) {
      array.reverse();
    } else if (value == 1){
      array.sort((a, b) => (a.totalPoints < b.totalPoints ? 1 : -1));
    }
    else if(value == 2){
      array.sort((a, b) => (a.badgePoints < b.badgePoints ? 1 : -1));
    }
    else {
      array.sort((a, b) => (a.badgePcs > b.badgePcs ? 1 : -1));
    }
    this.setState(value == 1 ? {
      peopleData: array,
      descendingPeople: !descending,
    } : value == 3 ? { badgeData: array,
      descendingPeople: !descending } : { badgeDataCount: array,
      descendingPeople: !descending }
    );
  };

  toolTipContentEarningPoints = () => {
    const { val6, val7, showTooltip }=this.state;
    return(
      <div style = { { display :`${ showTooltip === false ? 'none' : 'block' }` } }>
        <div className = "mainWrapper">
          <div style = { { display: 'flex', paddingLeft: '13px', paddingBottom: '10px' } }>
            <div
              style = { {
                width: '15.07px',
                height: '15.27px',
                marginTop: '3.4px',
                marginLeft: '8px',
                backgroundColor: val7,
              } }
            />
            <div className = "legendDis3"> {val6} pts</div>
          </div>
        </div>
      </div>
    )
  };

  toolTipContentEngagement = () => {
    const { val1, val2, val3, val4, val5, showTooltip }=this.state;
    return(
      <div style = { { display :`${ showTooltip === false ? 'none' : 'block' }` } }>
        <div className = "toolTipWrapper">
          <div className = "toolTipHeader" />
          <div style = { { display: 'flex', paddingLeft: '20px', paddingBottom: '10px' } }>
            <img
              className = ""
              src = {
                val3 === 1
                  ? require('../../images/Passive1.png')
                  : require('../../images/Passive2.png')
              }
              alt = "noImage"
              height = { 15.07 }
              width = { 15.27 }
            />
            <div className = "legendDis1">{val1}%</div>
            <div className = "legendDis2">- {val4} members </div>
          </div>
          <div style = { { display: 'flex', paddingLeft: '20px' } }>
            <img
              className = ""
              src = {
                val3 === 1
                  ? require('../../images/Active1.png')
                  : require('../../images/Active2.png')
              }
              alt = "noImage"
              height = { 15.07 }
              width = { 15.27 }
            />
            <div className = "legendDis1">{val2}%</div>
            <div className = "legendDis2">- {val5} members </div>
          </div>
        </div>
      </div>
    )
  };

  engagementGraph = () => {
    const { engageData, showTooltip, weekNo } =this.state;
    return(
      <ResponsiveContainer height = { 300 }>
        <BarChart data = { engageData }>
          {/* <CartesianGrid verticalPoints = { [ '19.1%', '30.5%', '42%', '53.5%', '65%', '76.5%', '88%', '99.3%' ] } /> */}
          <CartesianGrid strokeLinecap = "3 3" />
          {/* <XAxis dataKey = "weekName" /> */}
          <XAxis dataKey = "weekName"  />
          <YAxis tickFormatter = { tick => `${ tick }%` } yAxisId = "left" orientation = "left" type = "number" domain = { [ 0, 100 ] } />
          <ReTooltip
            cursor = { false }
            isVisible = { showTooltip }
            content = { this.toolTipContentEngagement() }
            showChildInTooltip
            backgroundColor = "rgba(52, 52, 52, 0)"
            placement = "center"
          />
          <Bar
            barSize = { weekNo === 8 ? 8 : weekNo === 6 ? 14 : weekNo === 4 ? 24 : 30 }
            yAxisId = "left"
            dataKey = "pecActivePercentage"
            stackId = "a"
            fill = "#7D2332"
            onMouseOver = { (abc) => {
              this.setState({
                val1: abc.pecPassivePercentage,
                val2: abc.pecActivePercentage,
                val3: 1,
                val4: abc.pecPassivePeopleCount,
                val5: abc.pecActivePeopleCount,
                showTooltip:true
              });
            } }
            onMouseLeave = { ()=>{
              this.setState({
                showTooltip:false
              })
            } }
          />
          <Bar
            barSize = { weekNo === 8 ? 8 : weekNo === 6 ? 14 : weekNo === 4 ? 24 : 30 }
            yAxisId = "left"
            dataKey = "pecPassivePercentage"
            stackId = "a"
            fill = "#FFA894"
            onMouseOver = { (abc) => {
              this.setState({
                val1: abc.pecPassivePercentage,
                val2: abc.pecActivePercentage,
                val3: 1,
                val4: abc.pecPassivePeopleCount,
                val5: abc.pecActivePeopleCount,
                showTooltip:true
              });
            } }
            onMouseLeave = { ()=>{
              this.setState({
                showTooltip:false
              })
            } }
          />
          <Bar
            barSize = { weekNo === 8 ? 8 : weekNo === 6 ? 14 : weekNo === 4 ? 24 : 30 }
            yAxisId = "left"
            dataKey = "ccActivePercentage"
            stackId = "b"
            fill = "#29545C"
            onMouseOver = { (abc) => {
              this.setState({
                val1: abc.ccPassivePercentage,
                val2: abc.ccActivePercentage,
                val3: 2,
                val4: abc.ccPassivePeopleCount,
                val5: abc.ccActivePeopleCount,
                showTooltip:true
              });
            } }
            onMouseLeave = { ()=>{
              this.setState({
                showTooltip:false
              })
            } }
          />
          <Bar
            barSize = { weekNo === 8 ? 8 : weekNo === 6 ? 14 : weekNo === 4 ? 24 : 30 }
            yAxisId = "left"
            dataKey = "ccPassivePercentage"
            stackId = "b"
            fill = "#A4DEDF"
            onMouseOver = { (abc) => {
              this.setState({
                val1: abc.ccPassivePercentage,
                val2: abc.ccActivePercentage,
                val3: 2,
                val4: abc.ccPassivePeopleCount,
                val5: abc.ccActivePeopleCount,
                showTooltip:true
              });
            } }
            onMouseLeave = { ()=>{
              this.setState({
                showTooltip:false
              })
            } }
          />
        </BarChart>
      </ResponsiveContainer>
    )
  };

  earningPointsGraph = () => {
    const { earningData, showTooltip } =this.state;
    return(
      <ResponsiveContainer height = { 300 }>
        <LineChart data = { earningData }>
          {/* <CartesianGrid verticalPoints = { [ '19.1%', '30.5%', '42%', '53.5%', '65%', '76.5%', '88%', '99.3%' ] } /> */}
          <CartesianGrid strokeLinecap = "3 3" />
          {/* <XAxis dataKey = "weekName" /> */}
          {/* <XAxis dataKey = "weekName" padding = { { left: 50, right: 50 } } /> */}
          <XAxis dataKey = "weekName"  />
          <YAxis type = "number" domain = { [ 0, 200 ] } />
          <ReTooltip
            cursor = { false }
            isVisible = { showTooltip }
            content = { this.toolTipContentEarningPoints() }
            showChildInTooltip
            backgroundColor = "rgba(52, 52, 52, 0)"
            placement = "center"
          />
          <Line
            dataKey = "pecPoints.high"
            stroke = "#BE9198"
            strokeWidth = { 3 }
            activeDot = { {
              onMouseOver: (event, payload) =>{
                this.setState({ val6: payload.payload.pecPoints.high, val7: payload.fill, showTooltip:true })},
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              },
            } }
            dot
          />
          <Line
            dataKey = "pecPoints.low"
            stroke = "#FFD3C9"
            strokeWidth = { 3 }
            activeDot = { {
              onMouseOver: (event, payload) =>{
                this.setState({ val6: payload.payload.pecPoints.low, val7: payload.fill, showTooltip:true  })},
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              }
            } }
            dot
          />
          <Line
            dataKey = "pecPoints.average"
            stroke = "#DE94A0"
            strokeDasharray = "10 10"
            strokeWidth = { 3 }
            activeDot = { {
              onMouseOver: (event, payload) =>{
                this.setState({ val6: payload.payload.pecPoints.average, val7: payload.fill, showTooltip:true  })},
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              }
            } }
            dot
          />
          <Line
            dataKey = "ccPoints.average"
            stroke = "#1E3264"
            strokeDasharray = "10 10"
            strokeWidth = { 3 }
            activeDot = { {
              onMouseOver: (event, payload) =>{
                this.setState({ val6: payload.payload.ccPoints.average, val7: payload.fill, showTooltip:true })},
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              }
            } }
            dot
          />
          <Line
            dataKey = "ccPoints.low"
            stroke = "#A4DEDF"
            strokeWidth = { 3 }
            activeDot = { {
              onMouseOver: (event, payload) =>{
                this.setState({ val6: payload.payload.ccPoints.low, val7: payload.fill, showTooltip:true })},
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              }
            } }
            dot
          />
          <Line
            dataKey = "ccPoints.high"
            stroke = "#29545C"
            strokeWidth = { 3 }
            activeDot = { {
              onMouseOver: (event, payload) =>{
                this.setState({ val6: payload.payload.ccPoints.high, val7: payload.fill, showTooltip:true })},
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              }
            } }
            dot
          />
        </LineChart>
      </ResponsiveContainer>
    )
  };

  toolTipView1 = () => (
    <>
      <div className = "dataTooltipHeader1">{translator.DataScreen.pointsRewards1}</div>
      <div className = "dataTooltip1stBody1">{translator.DataScreen.prDesc1}
        <br />
        <b>{translator.DataScreen.high}</b> {translator.DataScreen.prDesc2}
        <br />
        <b>{translator.DataScreen.average1}</b> {translator.DataScreen.prDesc3}
        <br />
        <b>{translator.DataScreen.low}</b> {translator.DataScreen.prDesc4}
        <br />
        <b>{translator.DataScreen.prDesc5}</b> {translator.DataScreen.prDesc6}
      </div>
    </>
  );

  toolTipView2 = () => (
    <>
      <div className = "dataTooltipHeader1">{translator.DataScreen.engagement} </div>
      <div className = "dataTooltip1stBody1">{translator.DataScreen.toolTipDes1}
        <br />
        <b>{translator.DataScreen.toolTipDes2}</b> {translator.DataScreen.toolTipDes3}
        <br />
        <b>{translator.DataScreen.toolTipDes4}</b> {translator.DataScreen.toolTipDes5}
      </div>
    </>
  );

  ptsByPeopleTooltip = (value) => {
    const { browserLanguage }=this.props
    return(
      <div>
        <div className = "ptsByPeopleTooltipHeader">
          <div>
            { value.driverName }
          </div>
          <div>
            { `${ value.totalPoints } pts` }
          </div>
        </div>
        {value.totalPoints !== 0 ? 
          value.badges.map((rowData) =>
            <div className = "ptsByPeopleTooltipBody">
              <div className = "ptsByPeopleTooltipNameImg">
                <div>
                  <img
                    src = { rowData.badgeImg }
                    alt = "noImage"
                    height = { 44 }
                    width = { 44 }
                  />
                </div>
                <div>
                  { browserLanguage == 'en' ? rowData.badgeName : rowData.badgeNameFi }
                </div>
              </div>
              <div className = "ptsByPeopleTooltipCountPts">
                <div className = "checking3">
                  { `${ moment(rowData.latestBadgeWinningDate).format('DD.MM.YY') }` }
                </div>
                <div className = "checking1">
                  { `${ rowData.badgeCount } pcs` }
                </div>
                <div className = "checking2">
                  { `${ rowData.badgePoints } pts` }
                </div>
              </div>
            </div>
          ) : <div className = "noPointsCSS">{translator.DataScreen.nopointswon}</div>}
      </div>
    )
  };

  getPointsByPeople = () => {
    const { peopleData }=this.state;
    return(
      peopleData &&
      peopleData.map((value, index, totalEle) => (
        <div
          style = { {
            display: 'flex',
            alignItems: 'center',
            margin: '10px 0 5px -20px',
            width: '98%',
          } }
        >
          <div aria-hidden = "true" className = "avatarhover" style = { { margin: '0 24px' } }>
            <Avatars
              imageSource = { value.driverimage }
              name = { value.driverName }
              avatarColor = "#FFA894"
              size = { 55 }
              fontSize = "15px"
            />
          </div>
          <div className = "tooltip tooltip-scroll">
            <div className = "wrapper" style = { index <= totalEle.length/2 
              ? { marginTop: `calc(-70px * ${ index } )` } 
              : { marginTop: `calc(-70px * ${ index*0.9 })` } } 
            >
              <div className = "tooltip-text">{this.ptsByPeopleTooltip(value)}</div>
            </div>
            {value.driverName}
          </div>
          <div className = "pointsColumn">
            <div >{ value.totalPoints }&nbsp;</div>
            <div className = "points">{ translator.DataScreen.pts }</div>
          </div>
        </div>
      ))
    )
  };

  getPointsByBadges = () => {
    const { badgeData }=this.state;
    const { browserLanguage } =  this.props;
    return(
      badgeData &&
      badgeData.map((value) => (
        value.badgePcs !== 0 && value.badgePoints !== 0 ? 
          <div
            style = { {
              display: 'flex',
              alignItems: 'center',
              margin: '10px 0 5px -20px',
              width: '100%',
              justifyContent : 'space-between'
            } }
          >
            <div aria-hidden = "true" className = "avatarhover" style = { { margin: '0 24px' } }>
              <img className = "" src = { value.badgeImage } alt = "noImage" height = { 44 } width = { 44 } />
            </div>
            <div className = "peopleName1">{browserLanguage == 'en' ? value.badgeName : value.badgeNameFi}</div>
            <div className = "pcsColumn">
              <div>{value.badgePcs}&nbsp;</div>
              <div className = "points1"> {translator.DataScreen.pcs}</div>
            </div>
            <div className = "pointsColumn">
              <div>{value.badgePoints}&nbsp;</div>
              <div className = "points"> {translator.DataScreen.pts}</div>
            </div>
          </div> : null
      ))
    )
  };

  render() {
    const { browserLanguage, selectedCostCenter } = this.props;
    const { apiflag, childData, loading, selectedTertileFromChild, engageData, earningData } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <div className = "wholeConfigurationContainer">
        {apiflag === true ? <CustomApiErrorDialog /> : null}
        <Dialog visible = { loading } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        <div className = "configurationLeftSection">
          <div className = "configurationLeftSectionHeaderContainer">
            <div className = "configurationHeader">Data</div>
          </div>
          <div className = "configurationFilter">
            <div className = "dataFilterHeadingQ" style = { { paddingRight:'22px' } }>
              <div className = "mapHeadingHolder">
                {translator.DataScreen.engagement}{' '}
                {selectedTertileFromChild.includes('1st')
                  ? translator.DataScreen.firstTertile
                  : selectedTertileFromChild.includes('2nd')
                    ? translator.DataScreen.secondTertile
                    : selectedTertileFromChild.includes('3rd')
                      ? translator.DataScreen.thirdTertile
                      : null}
              </div>
              <div className = "subHolder">
                <PostiTooltip
                  title = { this.toolTipView2() }
                  placement = "bottom-end"
                  id = "postiMainDataTooltip1"
                >
                  <img
                    className = ""
                    src = { require('../../images/Subtract.png') }
                    alt = "noImage"
                    height = { 18 }
                    width = { 18 }
                  />
                </PostiTooltip>
              </div>
            </div>
            <div style = { { display: 'flex' } }>
              <div style = { { width:'80%' } }>
                <div className = "memberHeading">Members</div>
                {engageData && this.engagementGraph()}
              </div>
              <div className = "legendsHeadingG1">
                <div className = "legendDesc1">{translator.DataScreen.allterminal}</div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Passive1.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.passive}</div>
                </div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Active1.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.active}</div>
                </div>
                <div className = "legendDesc2">{selectedCostCenter.costCenterName}</div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Passive2.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.passive}</div>
                </div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Active2.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.active}</div>
                </div>
                <div className = "footerLegend1">{translator.DataScreen.week}</div>
              </div>
            </div>
            <div className = "dataFilterHeadingQ" style = { { paddingRight:'27px' } }>
              <div className = "mapHeadingHolder">
                {translator.DataScreen.pointsRewards}{' '}
                {selectedTertileFromChild.includes('1st')
                  ? translator.DataScreen.firstTertile
                  : selectedTertileFromChild.includes('2nd')
                    ? translator.DataScreen.secondTertile
                    : selectedTertileFromChild.includes('3rd')
                      ? translator.DataScreen.thirdTertile
                      : null}
              </div>
              <div className = "subHolder1">
                <PostiTooltip
                  title = { this.toolTipView1() }
                  placement = "bottom-end"
                  id = "postiMainDataTooltip"
                >
                  <img
                    className = ""
                    src = { require('../../images/Subtract.png') }
                    alt = "noImage"
                    height = { 18 }
                    width = { 18 }
                  />
                </PostiTooltip>
              </div>
            </div>
            <div style = { { display: 'flex', paddingBottom: '1.5%' } }>
              <div style = { { width:'80%' } }>
                <div className = "memberHeading">{translator.DataScreen.points}</div>
                {earningData && this.earningPointsGraph()}
              </div>
              <div className = "legendsHeadingG1">
                <div className = "legendDesc1">{translator.DataScreen.allterminal}</div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/High1.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.high}</div>
                </div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Average1.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.average}</div>
                </div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Low1.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.low}</div>
                </div>
                <div className = "legendDesc2">{selectedCostCenter.costCenterName}</div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/High2.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.high}</div>
                </div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Average2.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.average}</div>
                </div>
                <div style = { { display: 'flex' } }>
                  <img
                    className = ""
                    src = { require('../../images/Low2.png') }
                    alt = "noImage"
                    height = { 10 }
                    width = { 10 }
                  />
                  <div className = "legendDis">{translator.DataScreen.low}</div>
                </div>
                <div className = "footerLegend2">
                  <div>{translator.DataScreen.week}</div>
                  { earningData.length>0?
                    <div className = "rewardsCirleContainerHeading">
                      {translator.DataScreen.rewards}
                    </div>:null}
                </div>
              </div>
            </div>
            { earningData && (
              <div className = { earningData.length>1?"rewardsCirleContainer":"rewardsCirleContainerSingle" }>
                { earningData.map((ele) => <div className = "ovalHolder1" >{ele.weeklyRewards}</div>) }
              </div>
            )}
            <div style = { { display: 'flex' } }>
              <div style = { { display: 'flex', width:'45%' } }>
                <div className = "dataFilterHeading1">{translator.DataScreen.pointsPeople}{' '}
                  {selectedTertileFromChild.includes('1st')
                    ? translator.DataScreen.firstTertile
                    : selectedTertileFromChild.includes('2nd')
                      ? translator.DataScreen.secondTertile
                      : selectedTertileFromChild.includes('3rd')
                        ? translator.DataScreen.thirdTertile
                        : null}
                </div>
                <div><img
                  aria-hidden = "true"
                  src = { require('../../images/Sorting.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 24 }
                  style = { { cursor: 'pointer' } }
                  onClick = { () => this.sortFunction(1) }
                />
                </div>
              </div>
              <div style = { { display: 'flex', width:'55%' } }>
                <div className = "dataFilterHeading2">{translator.DataScreen.pointsBadge}{' '}
                  {selectedTertileFromChild.includes('1st')
                    ? translator.DataScreen.firstTertile
                    : selectedTertileFromChild.includes('2nd')
                      ? translator.DataScreen.secondTertile
                      : selectedTertileFromChild.includes('3rd')
                        ? translator.DataScreen.thirdTertile
                        : null}
                </div>
                <div><img
                  aria-hidden = "true"
                  src = { require('../../images/Sorting.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 24 }
                  style = { { cursor: 'pointer' } }
                  onClick = { () => this.sortFunction(3) }
                />
                </div>
                <div style = { { paddingLeft:'15%' } }><img
                  aria-hidden = "true"
                  src = { require('../../images/Sorting.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 24 }
                  style = { { cursor: 'pointer' } }
                  onClick = { () => this.sortFunction(2) }
                />
                </div>
              </div>
            </div>
            <div style = { { display:'flex' } }>
              <div className = "peopleContainer2" style = { { width:'42%' , marginRight:'2%' } }>
                {this.getPointsByPeople()}
              </div>
              <div className = "peopleContainer2" style = { { width:'52.5%' } } > 
                {this.getPointsByBadges()}
              </div>
            </div>
          </div>
          <div style = { { marginTop:'8%' } }>
            <div className = "configurationFooter" >
              <hr className = "configurationFooterLine" />
              <div className = "testt1">
                <div className = "testt2" />
              </div>
            </div>
          </div>
        </div>
        <div className = "configurationRightSection">
          <div
            className = "configurationRightSectionHeaderContainer"
            style = { { position: 'fixed', width: '100%', zIndex: '3' } }
          >
            <div className = "configurationFilterHeader">
              {translator.ConfigurationFilter.filters}
            </div>
          </div>
          {childData !== null ? (
            <DataFilter
              parentData = { childData }
              parentCallback = { this.dataFromFilter }
              parentReset = { this.resetFromFilter }
              parentLoader = { this.parentLoader }
            />
          ) : null}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Data);
