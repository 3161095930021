import React, { Component } from 'react';
import './ConfigurationFilter.css';
import { Checkbox, Radio, Search, Button } from '@postidigital/posti-components';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation';
import Toastr from '../../shared/Toastr';
import { getJsonBody } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants';
import { CommonService } from '../../service/CommonService.js';

const translator = new LocalizedStrings(translation);
class ConfigurationFilter extends Component {
  constructor(props) {
    super(props);
    const { currentPage } = this.props;
    this.state = {
      // appliedLocallyCheckFlag: false,
      badgeCategoryArray: [],
      locationArray: [],
      originalLocationArray: [],
      selectedBadgesList: [],
      selectedLocationList: [],
      searchText: null,
      configurationsRadioButtonOptions: [ 'All', 'Default rules', 'Locally applied rules' ],
      locationRadioButtonOptions: [ 'All', 'Local' ],
      selectedRadioButtonForConfigurations: 'All',
      selectedRadioButton: null,
      // To check which page are we currenlty on i.e. Badges or Locations
      currentPageStatus: currentPage,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    const { browserLanguage,data,locationData } = this.props;
    this.setState({
      // For Badges category in filter
      badgeCategoryArray: data.map((dataMap) =>
        browserLanguage === 'en' ? dataMap.badgeCategory : dataMap.badgeCategoryFi
      ),
      // For location search in filter except for "All"
      locationArray: locationData,
      originalLocationArray: locationData,
    });
  }

  componentDidUpdate(prevProps) {
    const { browserLanguage,data,currentPage } = this.props;
    if (prevProps.data !== data) {
      this.setState({
        currentPageStatus: currentPage,
        badgeCategoryArray: data.map((dataMap) =>
          browserLanguage === 'en' ? dataMap.badgeCategory : dataMap.badgeCategoryFi
        ),
      });
    }
    if (prevProps.browserLanguage !== browserLanguage) {
      this.setState({
        selectedBadgesList: [],
      });
    }
  }

  onBadgesCheckboxClick = (e, category) => {
    const { selectedBadgesList } = this.state;
    const array = [ ...selectedBadgesList ];
    if (e.target.checked) {
      array.push(category);
    } else {
      array.splice(array.indexOf(category), 1);
    }
    this.setState({
      selectedBadgesList: array,
    });
  };

  onLocationCheckboxClick = (e, data) => {
    const { selectedLocationList } = this.state;
    const array = [ ...selectedLocationList ];
    if (e.target.checked) {
      array.push(data.costCenterId);
    } else {
      array.splice(array.indexOf(data.costCenterId), 1);
    }
    this.setState({
      selectedLocationList: array,
      selectedRadioButton: 'Local',
    });
  };

  locationSearch = (e) => {
    const { originalLocationArray } = this.state;
    if (e) {
      const filtered = originalLocationArray.filter((value) => value.location.toLowerCase().includes(e.toLowerCase()));
      this.setState({
        locationArray: filtered,
      });
    } else {
      this.setState({
        locationArray: originalLocationArray,
      });
    }
  };

  // Function call when reset button is clicked
  filterResetClicked = () => {
    const { originalLocationArray,currentPageStatus } = this.state;
    const { parentCallbackForBadges,parentCallbackForLocations } = this.props;
    this.setState({
      selectedRadioButtonForConfigurations: null,
      selectedBadgesList: [],
      selectedLocationList: [],
      searchText: null,
      locationArray: originalLocationArray,
      selectedRadioButton: null,
    });
    if (currentPageStatus === 'Badges' || currentPageStatus === 'Merkit') {
      parentCallbackForBadges('Badges', 'Reset');
    } else {
      parentCallbackForLocations('Location', 'Reset');
    }
  };

  // To get the badges screen data
  filterApplyClickedForBadges = () => {
    const { selectedRadioButtonForConfigurations,selectedBadgesList,selectedLocationList } = this.state;
    const { browserLanguage,selectedCostCenter,parentCallbackForBadges } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.filter = {};
    jsonBody.filter.configurations = selectedRadioButtonForConfigurations;
    jsonBody.filter.badgeCategories = selectedBadgesList;
    jsonBody.filter.locations = selectedLocationList;
    this.commonService
      .postMethod(apiUrl.getBadgeData, jsonBody)
      .then((res) => {
        parentCallbackForBadges(res.data, 'Filter');
      })
      .catch(() => {
        Toastr.showErrorMessage('Badges screen filter API error');
      });
  };

  // To get the badges screen data
  filterApplyClickedForLocation = () => {
    const { selectedRadioButtonForConfigurations,selectedBadgesList,selectedLocationList } = this.state;
    const { browserLanguage,selectedCostCenter,parentCallbackForLocations } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.filter = {};
    jsonBody.filter.configurations = selectedRadioButtonForConfigurations;
    jsonBody.filter.badgeCategories = selectedBadgesList;
    jsonBody.filter.locations = selectedLocationList;
    this.commonService
      .postMethod(apiUrl.getLocationData, jsonBody)
      .then((res) => {
        parentCallbackForLocations(res.data, 'Filter');
      })
      .catch(() => {
        Toastr.showErrorMessage('Location screen filter API error ');
      });
  };

  render() {
    const { configurationsRadioButtonOptions,currentPageStatus,selectedLocationList,locationArray,searchText,selectedRadioButton,selectedRadioButtonForConfigurations,badgeCategoryArray,selectedBadgesList,locationRadioButtonOptions,originalLocationArray } = this.state;
    const { browserLanguage } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <>
        <div className = "configurationFilter">
          <div className = "configurationFilterHeading">
            {translator.ConfigurationFilter.configurations}
          </div>
          <div className = "configurationFilterBody">
            {configurationsRadioButtonOptions.map((rowData) => (
              <div style = { { marginBottom: '8px' } } key = { rowData.index }>
                <Radio
                  name = "configRadio"
                  size = "small"
                  labelPosition = "right"
                  label = {
                    rowData === 'All'
                      ? translator.ConfigurationFilter.all
                      : rowData === 'Default rules'
                        ? translator.ConfigurationFilter.defaultRules
                        : translator.ConfigurationFilter.locallyAppliedRules
                  }
                  value = { rowData }
                  onChange = { (e) => this.setState({
                    selectedRadioButtonForConfigurations: e.target.value,
                  })
                  }
                  checked = {
                    rowData === selectedRadioButtonForConfigurations
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className = "badgesFilter">
          <div className = "badgesFilterHeading">{translator.ConfigurationFilter.badges}</div>

          {badgeCategoryArray.length !== 0 ? (
            <div className = "badgesFilterBody">
              {badgeCategoryArray.map((category) => (
                (category != "Freight points" && category != "Raskaan kuljetuksen pisteet") && 
                <div className = "checkboxAndBadgeNameContainer" key = { category.index }>
                  <Checkbox
                    onChange = { (e) => this.onBadgesCheckboxClick(e, category) }
                    checked = { selectedBadgesList.includes(category) }
                  />
                  {category}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className = "locationFilter">
          <div className = "locationFilterHeading">{translator.ConfigurationFilter.location}</div>
          <div className = "locationFilterBody">
            {locationRadioButtonOptions.map((data) => (
              <div style = { { marginBottom: '8px' } } key = { data.index }>
                <Radio
                  name = "configLocationRadio"
                  size = "small"
                  labelPosition = "right"
                  // for translation
                  label = {
                    data === 'All'
                      ? translator.ConfigurationFilter.all
                      : translator.ConfigurationFilter.local
                  }
                  value = { data }
                  onChange = { (e) => {
                    if (e.target.value === 'All') {
                      this.setState({
                        selectedLocationList: originalLocationArray.map(
                          (data1) => data1.costCenterId
                        ),
                      });
                    } else {
                      this.setState({
                        selectedLocationList: [],
                      });
                    }
                    return this.setState({
                      selectedRadioButton: e.target.value,
                    });
                  } }
                  checked = { data === selectedRadioButton }
                />
              </div>
            ))}
          </div>
        </div>
        <div className = "searchFilter">
          <Search
            className = "locationSearchBar"
            aria-label = "Search"
            id = "locationSearch"
            label = { translator.RewardsScreen.searchText1 }
            value = { searchText }
            onChange = { (e) => {
              this.locationSearch(e.target.value);
              this.setState({
                searchText: e.target.value,
              });
            } }
          />
          {locationArray.length !== 0 ? (
            <div className = "searchFilterBody">
              {locationArray.map((data) => (
                <div className = "searchCheckboxAndBadgeNameContainer" key = { data.index }>
                  <Checkbox
                    onChange = { (e) => this.onLocationCheckboxClick(e, data) }
                    checked = { selectedLocationList.includes(data.costCenterId) }
                  />
                  {data.location} - {data.costCenterId}
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <div className = "filterFooter">
          <div
            style = { {
              border: '1px solid #C2C9D1',
              backgroundColor: '#C2C9D1',
            } }
          />
          <div className = "filterFooterBody">
            <button className = "configFilterResetButton" onClick = { () => this.filterResetClicked() } type = "button">
              {translator.ConfigurationFilter.reset}
            </button>

            <Button
              className = "configFilterApplyButton"
              onClick = { () => currentPageStatus === 'Badges' || currentPageStatus === 'Merkit'? this.filterApplyClickedForBadges(): this.filterApplyClickedForLocation()
              }
            >
              {translator.ConfigurationFilter.apply}
            </Button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationFilter);
