/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import moment from 'moment';
import { ChevronLeftIcon, ChevronRightIcon } from '@postidigital/posti-components';
import { translation } from '../../../locales/translation.js';
import './WeatherNotificationHistory.css';

const translator = new LocalizedStrings(translation);

class WeatherNotificationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  componentDidUpdate(prevProps) {
    const { reset,resetMethod } = this.props;
    if (reset === 0 && prevProps.reset === null) {
      this.setState({ currentIndex: 0 });
      resetMethod();
    }
  };

  manualPlaceHolder = () => (
    <img
      alt = ""
      src = { require('../../../images/manul_image.png') }
      style = { {
        position: 'absolute',
        top: '10px',
        left: '18px',
      } }
    />
  );

  render() {
    const { browserLanguage,selectedCostCenter,data,changeIndex } = this.props;
    const { currentIndex } = this.state;
    translator.setLanguage(browserLanguage);
    const weatherHistoryData = data.weatherNotificationHistoryWeekDetailsModel;
    return (
      <>
        <div className = "weatherHistoryMainContainer">
          <div className = "weatherNotificationHeading">{`${ selectedCostCenter.costCenterName } ${ translator.WeatherScreen.weeklyWeather }`}</div>
          <div style = { { display: 'flex', alignItems: 'center' } }>
            <ChevronLeftIcon
              style = { { cursor: 'pointer' } }
              aria-hidden = "true"
              height = "24px"
              color = {
                currentIndex === weatherHistoryData.length - 1
                  ? 'rgba(115, 128, 140, 0.5)'
                  : '#73808C'
              }
              width = "24px"
              onClick = { () => {
                // const currentIndex = this.state.currentIndex;
                if (currentIndex < weatherHistoryData.length - 1) {
                  this.setState({
                    currentIndex: currentIndex + 1,
                  });
                  changeIndex(currentIndex + 1);
                }
              } }
            />
            <div className = "weekNumberText">
              {`${ translator.WeatherScreen.weekText } ${
                weatherHistoryData[ currentIndex ].weekNumber
              } (
                ${ moment(weatherHistoryData[ currentIndex ].weekStartDate).format(
        'DD.M.'
      ) }-
                ${ moment(weatherHistoryData[ currentIndex ].weekEndDate).format(
        'DD.M.YYYY'
      ) }
              )`}
            </div>
            <ChevronRightIcon
              style = { { cursor: 'pointer' } }
              aria-hidden = "true"
              height = "24px"
              color = { currentIndex === 0 ? 'rgba(115, 128, 140, 0.5)' : '#73808C' }
              width = "24px"
              onClick = { () => {
                // const currentIndex = this.state.currentIndex;
                if (currentIndex !== 0) {
                  this.setState({
                    currentIndex: currentIndex - 1,
                  });
                  changeIndex(currentIndex - 1);
                }
              } }
            />
          </div>
        </div>
        <div style = { { display: 'flex', alignItems: 'center', paddingTop: '32px' } }>
          <div className = "weatherNotificationTable">
            <div
              style = { { height: '33.3%', display: 'flex', alignItems: 'center' } }
              className = "weatherNotificationHistoryText"
            >
              {translator.WeatherScreen.bravestBadge}
            </div>
            <div
              className = "weatherNotificationHistoryText"
              style = { { height: '33.3%', display: 'flex', alignItems: 'center' } }
            >
              {translator.WeatherScreen.braverBadge}
            </div>
            <div
              className = "weatherNotificationHistoryText"
              style = { { height: '33.3%', display: 'flex', alignItems: 'center' } }
            >
              {translator.WeatherScreen.braveWarriorText}
            </div>
          </div>
          {weatherHistoryData[
            currentIndex
          ].weatherNotificationHistoryWeatherDataModel.map((value, index) => (
            <div
              key = { value.index }
              className = "weatherNotificationTable"
              style = { {
                borderRight: '0.5px solid #C2C9D1',
                borderTop: '0.5px solid #C2C9D1',
                borderLeft: index === 0 ? '0.25px solid #C2C9D1' : 'none',
                borderBottom: '1px solid #3B4A57',
              } }
            >
              <div className = "weatherNotificationTableContent" style = { { position: 'relative' } }>
                <img
                  src = {
                    value.theBravestBadgeFlag
                      ? require('../../../images/bravest.png')
                      : require('../../../images/bravest_disabled.png')
                  }
                  alt = ""
                  style = { {
                    width: '30px',
                    height: '30px',
                  } }
                />
                {value.manualAwardIndicator &&
                    value.theBravestBadgeFlag &&
                    value.evenBraverBadgeFlag &&
                    value.braveWarriorBadgeFlag &&
                    this.manualPlaceHolder()}
              </div>
              <div className = "weatherNotificationTableContent" style = { { position: 'relative' } }>
                <img
                  src = {
                    value.evenBraverBadgeFlag
                      ? require('../../../images/braver.png')
                      : require('../../../images/braver_disabled.png')
                  }
                  alt = ""
                  style = { {
                    width: '30px',
                    height: '30px',
                  } }
                />
                {value.manualAwardIndicator &&
                    !value.theBravestBadgeFlag &&
                    value.evenBraverBadgeFlag &&
                    value.braveWarriorBadgeFlag &&
                    this.manualPlaceHolder()}
              </div>
              <div className = "weatherNotificationTableContent" style = { { position: 'relative' } }>
                <img
                  src = {
                    value.braveWarriorBadgeFlag
                      ? require('../../../images/brave_warrior.png')
                      : require('../../../images/brave_warrior_disabled.png')
                  }
                  alt = ""
                  style = { {
                    width: '30px',
                    height: '30px',
                  } }
                />
                {value.manualAwardIndicator &&
                    !value.theBravestBadgeFlag &&
                    !value.evenBraverBadgeFlag &&
                    value.braveWarriorBadgeFlag &&
                    this.manualPlaceHolder()}
              </div>
              <div
                style = { {
                  height: '5px',
                  borderLeft: index === 0 ? '1px solid #3B4A57' : 'none',
                  borderRight: '1px solid #3B4A57',
                  width: '100%',
                } }
              />
            </div>
          ))}
        </div>

        <div style = { { display: 'flex', alignItems: 'center', paddingTop: '10px' } }>
          {[
            {},
            ...weatherHistoryData[ currentIndex ]
              .weatherNotificationHistoryWeatherDataModel,
          ].map((value, index) => (
            <div
              key = { value.index }
              className = "weatherHistoryDayText"
              style = { {
                width: '12.5%',
                textAlign: 'center',
                visibility: index === 0 ? 'hidden' : 'visible',
              } }
            >
              {index > 0 &&
                  `${ moment(value.weatherDate)
                    .locale(browserLanguage)
                    .format('ddd DD.M.') }`}
            </div>
          ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WeatherNotificationHistory);