/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import React, { Component } from 'react';
import { Radio,Search,Button } from '@postidigital/posti-components';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { translation } from '../../locales/translation.js';
import './DailyPulseFilter.css'
import { CommonService } from '../../service/CommonService.js';
import apiUrl from '../../urlConstants';
import { getJsonBody } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';

const translator = new LocalizedStrings(translation);

class DailyPulseFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioLocationOptn:[],
      radioIntervalOptn:[ "Weekly","Monthly","Quarterly" ],
      selectedLocationOptn:null,
      selectedIntervalOptn:null,
      selectedCCOptn:null,
      searchText:null,
      locationList:[],
      originalLocationArray:[],
      selectedCCWholeValue:null
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    this.getLocationsList();
    // const { selectedCostCenter }=this.props
    setTimeout(() => {
      this.setState({
        radioLocationOptn:[ this.props.selectedCostCenter.costCenterName,"Other" ],
        selectedLocationOptn: this.props.selectedCostCenter.costCenterName,
        selectedIntervalOptn:"Weekly",
      })
    }, 3500);
    
  }

  componentDidUpdate(prevProps) {
    const { selectedCostCenter } = this.props;
    if (prevProps.selectedCostCenter !== selectedCostCenter &&
      prevProps.selectedCostCenter.costCenterName !== '') {
      this.setState({
        radioLocationOptn:[ selectedCostCenter.costCenterName,`${ selectedCostCenter.costCenterName } surrounding area`,"Other"  ],
        selectedLocationOptn: selectedCostCenter.costCenterName,
        selectedIntervalOptn:"Weekly"
      });
      this.dailyPulseResetClicked();
    }
  }
  
  getLocationsList = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.badges = [];
    this.commonService
      .postMethod(apiUrl.getlocationList, jsonBody)
      .then((res) => {
        this.setState({
          locationList:res.data,
          originalLocationArray:res.data
        })
      })
      .catch(() => {
        Toastr.showErrorMessage('Location List API Error');
      });
  };

  locationSearch = (e) => {
    const { originalLocationArray } = this.state;
    if (e) {
      const filtered = originalLocationArray.filter((value) => value.location.toLowerCase().includes(e.toLowerCase()));
      this.setState({
        locationList: filtered,
      });
    } else {
      this.setState({
        locationList: originalLocationArray,
      });
    }
  };

  dailyPulseResetClicked = () =>{
    const { onRestClick } = this.props
    this.setState({
      // eslint-disable-next-line react/destructuring-assignment
      selectedLocationOptn:this.props.selectedCostCenter.costCenterName,
      selectedIntervalOptn:"Weekly",
      selectedCCOptn:null,
      selectedCCWholeValue:null
    })
    onRestClick()
  }

  getLatLong=()=>{
    const { locationList, selectedCCOptn } = this.state
    let coordinates
    for(let i=0; i<locationList.length; i+=1){
      if(locationList[ i ].location === selectedCCOptn ){
        coordinates = { lat : locationList[ i ].latitude, lng : locationList[ i ].longitude }  
      }
    }
    return(coordinates)
  }
  
  render() {
    const { radioLocationOptn, selectedCCWholeValue,radioIntervalOptn,selectedLocationOptn,selectedIntervalOptn, searchText, locationList, selectedCCOptn } = this.state;
    const { browserLanguage, selectedCostCenter, onlocationChange } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <div className = "pulseFilterWholeWrapper">
        
        <div className = "pulseFilterIntervalHeading">
          {translator.DailyPulse.filterInterval}
        </div>
        <div className = "pulseFilterIntervalBody">
          { radioIntervalOptn.map((rowData) =>
            <div style = { { marginBottom:"8px" } }>
              <Radio
                name = "pulseIntervalRadio"
                size = "small"
                labelPosition = "right"
                label = { rowData === "Weekly" ? translator.DailyPulse.weekly : rowData === "Monthly" ? translator.DailyPulse.monthly : translator.DailyPulse.quaterly }
                value = { rowData }
                onChange = { (e) => this.setState({
                  selectedIntervalOptn: e.target.value,
                },
                )
                }
                checked = {
                  rowData === selectedIntervalOptn
                }
              />
            </div>)}
        </div>
        <div className = "pulseFilterLocationHeading">
          {translator.DailyPulse.locationFilter}
        </div>
        <div className = "pulseFilterLocationBody">
          { radioLocationOptn.map((rowData) =>
            <div style = { { marginBottom:"8px" } }>
              <Radio
                name = "pulseLocationRadio"
                size = "small"
                labelPosition = "right"
                label = { rowData.includes("surrounding")?`${ selectedCostCenter.costCenterName } ${ translator.DailyPulse.surrArea }`: rowData.includes("Other")?translator.DailyPulse.other:rowData }
                value = { rowData }
                onChange = { (e) => {
                  this.setState({
                    selectedLocationOptn: e.target.value,
                  },
                  )
                  if(e.target.value !== "Other")
                  {
                    this.setState({
                      selectedCCOptn:null
                    })
                  }
                }
                }
                checked = {
                  rowData === selectedLocationOptn
                }
              />
            </div>)}
        </div>
        <Search
          className = "pulseLocationSearchBar"
          aria-label = { translator.DailyPulse.searchText }
          id = { translator.DailyPulse.searchText }
          label = { translator.DailyPulse.searchText }
          value = { searchText }
          onChange = { (e) => {
            this.setState({
              searchText: e.target.value,
            
            },() => this.locationSearch(e.target.value) );
          } }
        />
        <div className = "locationlistContainer">
          {locationList && (
            locationList.map((locData) =>
              <div style = { { marginBottom:"8px" } }>
                <Radio
                  name = "pulseCCRadio"
                  size = "small"
                  labelPosition = "right"
                  label = { locData.location }
                  value = { locData.location }
                  onChange = { (e) => this.setState({
                    selectedCCOptn: e.target.value,
                    selectedLocationOptn:"Other",
                    selectedCCWholeValue:locData.location === e.target.value?locData:null
                  },
                  )
                  }
                  checked = {
                    locData.location === selectedCCOptn
                  }
                />
              </div>)
          )}
        </div>
        <div className = "dailyPulseScreenRightSectionFooter">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div
            style = { {
              height: '8vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            } }
          >
            <div
              className = "resetButtonText"
              onClick = { () => {this.dailyPulseResetClicked() } }
              aria-hidden = "true"
            >
              {translator.DailyPulse.resetText}
            </div>
            <Button
              size = "sm"
              id = "dailyPulseNotifyButton"
              onClick = { ()=>{
                const latLong= selectedCCOptn!==null && this.getLatLong()
                onlocationChange(selectedLocationOptn,selectedCCOptn,selectedIntervalOptn,latLong,selectedCCWholeValue)} }
            >
              {translator.DailyPulse.applyText}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DailyPulseFilter);
