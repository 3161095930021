/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import LocalizedStrings from 'react-localization';
import { Dialog } from 'primereact/dialog';
import { bindActionCreators } from 'redux';
import {
  Dropdown,
  Headline,
  DropdownMenuItem,
  LanguageSelection,
  Loading,
} from '@postidigital/posti-components';
import { translation } from "../locales/translation.js";
import  changeLanguageAction  from '../actions/changeLanguageAction';
import './Header.css';
import {
  getEmailIdFromToken,
  getJsonBody,
  getMultiappid,
  isPECAdmin,
} from '../utility/commonUtility';
import  selectedCostCenterAction  from '../actions/selectedCostCenterAction.js';
import { CommonService } from '../service/CommonService.js';
import Toastr from '../shared/Toastr';
import apiUrl from '../urlConstants.js';

const translator = new LocalizedStrings(translation);
let emailId = '';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOpen1: false,
      costCenterList: [],
      loading: true,
      a: false,
      imgclicked:false,
      imgclickedEMD:false,
      costCenterListEMD: [],
      isPS: true,
      isPEC : false,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    emailId = getEmailIdFromToken(localStorage.getItem('adal.idtoken'));
    const jsonBody = getJsonBody();
    const roles = getMultiappid();
    if(roles.length === 2)
    {
      // jsonBody.applicationId = roles[0];
      this.getSupervisorsCostCenter({ applicationId:roles[ 0 ],masterGroupSourceSystemId:jsonBody.masterGroupSourceSystemId });
      // jsonBody.applicationId = roles[1];
      if(roles[ 1 ] === 4)
      {
        this.setState({ isPEC : true })
      }
      this.getSupervisorsCostCenterEMD({ applicationId:roles[ 1 ],masterGroupSourceSystemId:jsonBody.masterGroupSourceSystemId });
      // this.getSupervisorsCostCenterEMD(jsonBody);
    }
    else
    {
      this.getSupervisorsCostCenter(jsonBody);
    }
    
  };

  // to fetch the Supervisors cost centers
  getSupervisorsCostCenter = (body) => {
    this.commonService
      .postMethod(apiUrl.getAllCostCenterName, body)
      .then((res) => {
        if (res && res.data) {
          res.data.sort((a, b) =>
            a.costCenterName > b.costCenterName ? 1 : -1
          );
          // eslint-disable-next-line react/destructuring-assignment
          this.props.changeCostCenterDispatcher(res.data[ 0 ]);
          this.setState({ costCenterList: res.data, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.ToastMessages.fetchCostCenterError);
      });
  };

  getSupervisorsCostCenterEMD = (body) => {
    this.commonService
      .postMethod(apiUrl.getAllCostCenterName, body)
      .then((res) => {
        if (res && res.data) {
          res.data.sort((a, b) =>
            a.costCenterName > b.costCenterName ? 1 : -1
          );
          // this.props.changeCostCenterDispatcher(res.data[0]);
          this.setState({ costCenterListEMD: res.data, loading: false });
        }
      })
      .catch(() => {
        this.setState({ loading: false });
        Toastr.showErrorMessage(translator.ToastMessages.fetchCostCenterError);
      });
  };

  render() {
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage,drpshow,selectedCostCenter,changeCostCenterDispatcher,oncc,changeLanguageActionDispatcher }= this.props;
    const { loading , isOpen,b ,costCenterList ,imgclickedEMD, costCenterListEMD,imgclicked , a , isOpen1 ,isPS,isPEC }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <div className = "headerContainer">
        <Dialog visible = { loading } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        <div className = "headerFlexAlignment">
          <img
            src = { require('../images/logo.png') }
            alt = "error"
            className = "headerLogo"
          />
          <div>
            <Headline id = "heading" color = "#3B4A57" size = "Three">
              {isPECAdmin()
                ? `${ translator.Header.headingSuperAdmin }`
                : `${ translator.Header.heading }`}
            </Headline>
          </div>

          {!drpshow && 
          <div>
            <Dropdown
              disabled = { window.location.href.includes("dailyPulse") || (decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? window.location.href.includes("message") : "") }
              style = { {
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
              } }
              id = "headerDropdown"
              iconColor = "#73808C"
              isOpen = { isOpen }
              menuAriaLabel = "Example "
              title = { selectedCostCenter.costCenterName }
              onSelect = { (costCenterIndex) => {
                changeCostCenterDispatcher(
                  costCenterList[ costCenterIndex ]
                );
              } }
              onToggle = { () => this.setState({ isOpen: !isOpen }) }
              overflowMenuContainerDirection = "right"
            >
              {costCenterList.map((item, index) => (
                <DropdownMenuItem
                  // eslint-disable-next-line react/no-array-index-key
                  key = { index }
                  allowWrap
                  aria-label = "Menu item"
                  tabIndex = { -1 }
                  value = { index }
                  selected = {
                    selectedCostCenter.costCenterId ===
                      item.costCenterId
                  }
                >
                  {item.costCenterName}-{item.costCenterId}
                </DropdownMenuItem>
              ))}
            </Dropdown>
          </div> 
          } 
          {drpshow && (
            
            <Dropdown
              style = { {
                border: 'none',
                boxShadow: 'none',
                background: 'transparent',
                height: '100%',
                marginBottom: '15px'
              } }
              id = "headerDropdown1"
              iconColor = "#73808C"
              isOpen = { isOpen1 }
              menuAriaLabel = "Example "
              title = { isPS ? `BD- ${ selectedCostCenter.costCenterName }` : isPEC ? `PEC- ${ selectedCostCenter.costCenterName }` : `EMD- ${ selectedCostCenter.costCenterName }` }
              overflowMenuContainer   
              // onSelect={(e) => {
              //   this.props.oncc(e);
              //   console.log(e);
              //   //this.componentDidMount();
              // }}
              onToggle = { () => this.setState({ isOpen1: !isOpen1 }) }
              // overflowMenuContainerDirection="right"
            >
              <div>
                <ul>
                  <li aria-hidden = "true" className = "mainhead" onClick = { ()=>{this.setState({ a:!a,imgclicked:!imgclicked })} }>
                    <div><span href = "#" className = "nodecor" >BD</span> 
                      <img aria-hidden = "true" alt = "no-img" src = { imgclicked ? require("../images/upArrow.png") :require("../images/downArrow.png") } className = "imgPos"  />
                    </div>
                  </li>  
                  <ul className = { a ?'dropdowna' :'dropdown' }>
                    {costCenterList.map((item, index) => 
                      <li aria-hidden = "true" key = { item.index } value = { index } onClick = { (e) => {
                        changeCostCenterDispatcher(
                          costCenterList[ e.target.value ]
                        );
                        oncc(2);
                        this.setState({ isOpen1:false,isPS:true });
                      }
                      } className = "internalli"
                      >{item.costCenterName}-{item.costCenterId}
                      </li>)}
            
                    {/* <li className='internalli'>PS CC2</li> */}
                  </ul> 
                </ul>
                <ul>
                  <div >
                    <li aria-hidden = "true" className = "mainhead" onClick = { ()=>{this.setState({ b:!b,imgclickedEMD:!imgclickedEMD })} }>
                      <span href = "#" className = "nodecor">{isPEC ? 'PEC' : 'EMD'}</span> 
                      <img alt = "no-img" src = { imgclickedEMD ? require("../images/upArrow.png") :require("../images/downArrow.png") } className = "imgPos"  />
                    </li>  
                  </div>
                  <ul className = { b ?'dropdowna' :'dropdown' }>
                    {costCenterListEMD.map((item, index) => 
                      <li aria-hidden = "true" key = { item.index } value = { index } onClick = { (e) => {
                        changeCostCenterDispatcher(
                          costCenterListEMD[ e.target.value ]
                        // this.state.costCenterList[2]
                        );
                        const val = isPEC ? 4 : 5
                        oncc(val);
                        this.setState({ isOpen1:false,isPS:false });
                      }
                      } className = "internalli"
                      >{item.costCenterName}-{item.costCenterId}
                      </li>)}
                  </ul> 
                </ul>
              </div>
            </Dropdown>
          )}
        </div>
        <div className = "headerFlexAlignment">
          <div className = "email">{emailId}</div>
          <div>
            <LanguageSelection
              id = "language"
              aria-label = ""
              language = { browserLanguage.toUpperCase() }
              languageLabel = ""
              onLanguageToggle = { (lang) => {
                changeLanguageActionDispatcher(lang.toLowerCase());
              } }
              supportedLanguages = { [ 'EN', 'FI' ] }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguageActionDispatcher: bindActionCreators(
    changeLanguageAction,
    dispatch
  ),
  changeCostCenterDispatcher: bindActionCreators(
    selectedCostCenterAction,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
