import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loading, Button } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import { getJsonBody } from '../../utility/commonUtility.js';
import { CommonService } from '../../service/CommonService.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';
import Avatar from '../../components/Avatar/Avatar';
import EditTeamTabSectionScreen from './EditTeamTabSection/EditTeamTabSection';
import './EditTeam.css';
import { translation } from '../../locales/translation';

const translator = new LocalizedStrings(translation);
let availablePeopleList = [];

export class EditTeam extends Component {
  constructor(props) {
    super(props);
    // const { TeamDetails } = this.props
    this.state = {
      isLoading: true,
      imageFile: [],
      imageBase64: '',
      availablePeopleList: [],
      // checkboxSelectedMembers: TeamDetails.selectedMembers,
      // checkboxSelectedData: TeamDetails.Details,
    };

    this.commonService = new CommonService();
  }

  componentDidMount () {
    this.getPeopleScreenData();
  };

  getPeopleScreenData = () => {
    const { browserLanguage,TeamDetails, selectedCostCenter }= this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;

    this.commonService
      .postMethod(apiUrl.getPeopleScreenData, jsonBody)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          availablePeopleList = res.data.filter((value) => value.groupId === null);
          const finalAvailableList = [ ...TeamDetails.Details, ...availablePeopleList ];
          this.setState({
            availablePeopleList: finalAvailableList,
            isLoading: false,
          });
        }
      })
      .catch(() => {
        Toastr.showErrorMessage('Home screen People fetch API Error');
      });
  };

  render() {
    const { browserLanguage , TeamDetails, OnHide, ResetTeamDetails }= this.props;
    // eslint-disable-next-line no-shadow
    const { imageFile , imageBase64 , availablePeopleList , isLoading }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        <Dialog
          id = "edit-team"
          visible = { Dialog }
          style = { { width: '80vw', height: '80vh' } }
          onHide = { (e) => {
            OnHide(e);
          } }
        >
          <div className = "editTeamContainer">
            {/* Left section */}
            <div className = "editTeamLeftSection" style = { { overflow: 'hidden' } }>
              <div className = "editTeamHeaderLeftContainer">
                <div className = "editTeam-heading">{TeamDetails.Name}</div>

                {(imageFile && imageFile.length !== 0) ||
                TeamDetails.TeamImage !== null ? (
                    <img
                      alt = ""
                      id = "editTeam-avatar"
                      src = {
                        imageFile.length !== 0
                          ? imageBase64
                          : TeamDetails.TeamImage
                      }
                    />
                  ) : (
                    <div id = "newteam-avatar" />
                  )}
              </div>
              <div
                style = { {
                  height: 'calc(70vh - 74px)',
                  overflowY: 'auto',
                  marginLeft: '32px',
                } }
              >
                <div className = "edit_info_text">{translator.ChampsScreen.information}</div>
                <table style = { { width: '80%', marginTop: '19px' } }>
                  <tbody>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.teamName}</td>
                      <td className = "table-info">{TeamDetails.Name}</td>
                    </tr>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.formed}</td>
                      <td className = "table-info">{moment().format('D.MM.YYYY')}</td>
                    </tr>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.members}</td>
                      <td className = "table-info">{TeamDetails.Details.length}</td>
                    </tr>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.pointsTotal}</td>
                      <td className = "table-info">{TeamDetails.PointsTotal} pts</td>
                    </tr>
                  </tbody>
                </table>
                <div className = "teamMembers">{translator.ChampsScreen.teamMembers}</div>
                {TeamDetails &&
                  TeamDetails.Details.map((data) => (
                    <div key = { data.index } style = { { marginTop: '18px', overflowY: 'auto' } }>
                      <div
                        style = { {
                          display: 'flex',
                          alignItems: 'center',
                        } }
                      >
                        <Avatar
                          imageSource = { data.userImagePath }
                          name = { data.firstName }
                          avatarColor = "#FFA894"
                          size = { 46 }
                          fontSize = "15px"
                        />
                        <div className = "memberName">{data.firstName}</div>
                      </div>
                    </div>
                  ))}
              </div>
              <div
                style = { {
                  border: '1px solid #C2C9D1',
                  marginLeft: '32px',
                  marginRight: '32px',
                } }
              />
              <div className = "editTeamButton">
                <button type = "submit" className = "cancel-button" disabled style = { { color: '#A7A7A7' } }>
                  {translator.ChampsScreen.delete}
                </button>
                <div style = { { display: 'flex' } }>
                  <button type = "submit" disabled className = "cancel-button" style = { { color: '#A7A7A7' } }>
                    {translator.ChampsScreen.editDetails}
                  </button>
                  <Button id = "team-nextButton" size = "sm" disabled>
                    {translator.ChampsScreen.close}
                  </Button>
                </div>
              </div>
            </div>
            {/* Right section */}
            <div className = "editTeamRightSection">
              <EditTeamTabSectionScreen
                teamId = { TeamDetails.teamId }
                Name = { TeamDetails.Name }
                checkboxSelectedMembers = { TeamDetails.selectedMembers }
                checkboxSelectedData = { TeamDetails.Details }
                availablePeopleList = { availablePeopleList }
                selectedFiles = { () => {} }
                OnHide = { (e) => OnHide(e) }
                ResetTeamDetails = { (e) => {
                  ResetTeamDetails(e);
                } }
              />
            </div>
          </div>
        </Dialog>
        {isLoading && (
          <Dialog
            id = "load-screen"
            visible
            style = { { width: '80vw', height: '80vh' } }
            onHide = { (e) => {
              OnHide(e);
            } }
          >
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EditTeam);
