import { combineReducers } from 'redux';
import changeLanguageReducer  from './changeLanguageReducer';
import changePathReducer from './changePathReducer ';
import selectedCostCenterReducer from './selectedCostCenterReducer';
import selectedTargetDataReducer from './targetDataReducer';

const combinedReducer = combineReducers({
  browserLanguage: changeLanguageReducer,
  changePath: changePathReducer,
  selectedCostCenter: selectedCostCenterReducer,
  selectedTargetData: selectedTargetDataReducer,
});
export default combinedReducer;
