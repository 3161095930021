/* eslint-disable max-lines */
/* eslint-disable global-require */
import React, { Component } from 'react';
import './AddNewContact.css';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import {
  Button,
  Tab,
  Tabs,
  TabContext,
  TabPanel,
  Checkbox,
  Input,
  Loading,
} from '@postidigital/posti-components';
import LocalizedStrings from 'react-localization';
import { CommonService } from '../../service/CommonService';
import { getJsonBody } from '../../utility/commonUtility';
import apiUrl from '../../urlConstants';
import Toastr from '../../shared/Toastr';
import { translation } from '../../locales/translation';

const translator = new LocalizedStrings(translation);
class AddNewContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'tab1',
      contactName: '',
      number: '',
      role: '',
      contactDataList: [],
      loading1: true,
      inputColor: '#C2C9D1',
      totalColors: [
        '#FFC226',
        '#EDBDBD',
        '#FFA894',
        '#A4DEDF',
        '#0051C2',
        '#008A00',
        '#29545C',
        '#C2C9D1',
      ],
      selectedContactsList: [],
      unselectedContactsList: [],
      dragId: null,
    };
    this.commonService = new CommonService();
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({
        loading1: false,
      });
    }, 500);
  };

  saveContact = () => {
    const { onConfirm,selectedCostCenter,setLoader } = this.props;
    const { selectedContactsList,unselectedContactsList } = this.state;
    let jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody = [ ...selectedContactsList, ...unselectedContactsList ];
    setLoader();
    this.commonService
      .postMethod(apiUrl.saveContact, jsonBody)
      .then(() => {
        Toastr.showSuccessMessage(
          jsonBody.length === 1
            ? `${ jsonBody[ 0 ].name } created`
            : `${ jsonBody.length } contacts created`
        );
        onConfirm();
      })
      .catch(() => {
        Toastr.showErrorMessage('Save Contact API Error');
      });
  };

  onDeselectAllClick = () => {
    let { selectedContactsList,unselectedContactsList } = this.state;
    // let selectedContactsList = [];
    // let unselectedContactsList = [];
    selectedContactsList = [ ...selectedContactsList ];
    unselectedContactsList = [ ...unselectedContactsList ];
    unselectedContactsList = [ ...unselectedContactsList, ...selectedContactsList ];
    selectedContactsList = [];
    this.setState({
      selectedContactsList,
      unselectedContactsList,
    });
  };

  contactsContent = () =>{
    const { onCancel } = this.props;
    const { contactName,number,role,contactDataList } = this.state;
    return(
      <>
        <div className = "rightSideAddNewContactContainer">
          <div className = "AddNewContactName">
            <div type = "text" className = "nameText">
              {translator.AddNewContactDialog.nameText}
            </div>
            <Input
              type = "text"
              name = "contactName"
              value = { contactName }
              onChange = { this.handleChange }
              className = "enterBlock"
              placeholder = { translator.AddNewContactDialog.enterNameText }
            />

            <div className = "nameText">{translator.AddNewContactDialog.numberText}</div>
            <Input
              type = "number"
              name = "number"
              value = { number }
              onChange = { this.handleChange }
              className = "enterBlock"
              placeholder = { translator.AddNewContactDialog.enterNumberText }
            />

            <div className = "nameText">{translator.AddNewContactDialog.roleText}</div>
            <Input
              type = "text"
              name = "role"
              value = { role }
              onChange = { this.handleChange }
              className = "enterBlock"
              placeholder = { translator.AddNewContactDialog.enterRoleText }
            />

            <div className = "rightSideButton">
              <Button
                size = "sm"
                style = { {
                  marginLeft: '2vw',
                  marginTop: '2vh',
                  marginBottom: '1vh',
                } }
                onClick = { this.onAddButtonClicked }
              >
                {translator.AddNewContactDialog.addButton}
              </Button>
            </div>
            {contactDataList.length > 0 ? (
              <>
                {contactDataList.map((data) => 
                  <div className = "listOfAddedContacts">
                    <div
                      style = { {
                        background: data.color,
                        height: '29px',
                        width: '29px',
                        borderRadius: '50%',
                        marginRight: '13px',
                      } }
                    />
                    {data.name}
                  </div>
                
                )}
              </>
            ) : (
              <p className = "listText">{translator.AddNewContactDialog.listText}</p>
            )}
          </div>
        </div>
        <div style = { { position: 'absolute', bottom: '8vh', width: '25vw' } }>
          <div
            style = { {
              border: '1px solid #C2C9D1',
              margin: '0px -3px 0px 30px',
            } }
          />
          <div className = "rightSideAddNewContactFooter">
            <div className = "rightSideAddNewContactFooterButton">
              <div className = "rightSideAddNewContactButton" onClick = { () => onCancel() } aria-hidden = "true" >
                {translator.RewardsScreen.cancelText}
              </div>
              <div className = "rightSideNextButton">
                <Button size = "sm" onClick = { () => this.setState({ tabValue: 'tab2' }) }>
                  {translator.AddNewContactDialog.next}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>)
  }

  handleDrag = (ev) => {
    this.setState({ dragId: ev.currentTarget.id });
  };

  handleDrop = (ev) => {
    const { unselectedContactsList,dragId } = this.state;
    const dragBox = unselectedContactsList.find((box) => box.name === dragId);
    const dropBox = unselectedContactsList.find(
      (box) => box.name === ev.currentTarget.id
    );
    const dragBoxOrder = dragBox.orderNumber;
    const dropBoxOrder = dropBox.orderNumber;
    const newBoxState = unselectedContactsList.map((box) => {
      const test = box;
      if (box.name === dragId) {
        test.orderNumber = dropBoxOrder;
      }
      if (box.name === ev.currentTarget.id) {
        test.orderNumber = dragBoxOrder;
      }
      return test;
    });
    this.setState({
      unselectedContactsList: newBoxState,
    });
  };

  orderContent = () => {
    const { unselectedContactsList } = this.state;
    return(
      <>
        <div className = "orderContentContainer">
          <div className = "colourHeader">{translator.AddNewContactDialog.vipContact}</div>
          <div className = "contactsOrder">
            {unselectedContactsList.length > 0 ? (
              <>
                {unselectedContactsList
                  .sort((a, b) => a.orderNumber - b.orderNumber)
                  .map((data) => 
                    <div
                      className = "orderContentContacts"
                      draggable
                      id = { data.name }
                      onDragOver = { (event) => event.preventDefault() }
                      onDragStart = { this.handleDrag }
                      onDrop = { this.handleDrop }
                    >
                      <div
                        style = { {
                          background: data.color,
                          height: '29px',
                          width: '29px',
                          borderRadius: '50%',
                          marginRight: '13px',
                        } }
                      />
                      {data.name}
                      <img
                        style = { {
                          alignItems: 'flex-end',
                          marginInlineStart: 'auto',
                        } }
                        alt = "error"
                        src = { require('../../images/Vector.png') }
                      />
                    </div>
                  
                  )}
              </>
            ) : (
              <p
                style = { {
                  paddingLeft: '2.5vw',
                  width: '365.31px',
                  height: '20px',
                  fontFamily: 'Montserrat',
                  fontSize: '13px',
                  lineHeight: '154%',
                  color: '#3B4A57',
                } }
              >
                {translator.AddNewContactDialog.listText}
              </p>
            )}
          </div>
        </div>
        <div style = { { position: 'absolute', bottom: '3.1%', width: '25vw' } }>
          <div
            style = { {
              border: '1px solid #C2C9D1',
              margin: '0px -3px 0px 30px',
            } }
          />
          <div className = "rightSideAddNewContactFooterButton">
            <div
              className = "rightSideAddNewContactButton"
              onClick = { () => this.setState({ tabValue: 'tab1' }) }
              aria-hidden = "true"
            >
              {translator.AddNewContactDialog.back}
            </div>
            <div className = "rightSideButton">
              <Button size = "sm" onClick = { () => this.setState({ tabValue: 'tab3' }) }>
                {translator.AddNewContactDialog.next}
              </Button>
            </div>
          </div>
        </div>
      </>)
  }

  setSelectedColor = (data) => {
    const { selectedContactsList,unselectedContactsList,inputColor,contactDataList } = this.state;
    const matchFound = selectedContactsList.find((contact) => contact.name === data.name);
    const unSelectedMatchFound =
      unselectedContactsList &&
      unselectedContactsList.find((contact) => contact.name === data.name);
    let { color } = contactDataList[ 0 ];
    if (matchFound) {
      color = inputColor;
    } else {
      color = unSelectedMatchFound
        ? unSelectedMatchFound.color
        : contactDataList[ 0 ].color;
    }
    return color;
  };

  colourContent = () => {
    const { unselectedContactsList,selectedContactsList,totalColors,inputColor } = this.state;
    const combinedList = [
      ...unselectedContactsList,
      ...selectedContactsList,
    ].sort((a, b) => a.orderNumber - b.orderNumber);
    return (
      <div className = "colorContentContainer">
        <div className = "colourHeader">{translator.AddNewContactDialog.vipContact}</div>
        {combinedList.length > 0 ? (
          <>
            {combinedList.map((data) => 
              <div className = "colourContentContacts">
                <Checkbox
                  onChange = { (e) => this.onCheckboxClick(e, data) }
                  checked = {
                    selectedContactsList &&
                        selectedContactsList.find(
                          (contact) => contact.name === data.name
                        )
                  }
                />
                <div
                  style = { {
                    background: this.setSelectedColor(data),
                    height: '29px',
                    width: '29px',
                    borderRadius: '50%',
                    marginRight: '13px',
                  } }
                />
                {data.name}
              </div>
            )}
          </>
        ) : (
          <p
            style = { {
              paddingLeft: '2.5vw',
              width: '365.31px',
              height: '20px',
              fontFamily: 'Montserrat',
              fontSize: '13px',
              lineHeight: '154%',
              color: '#3B4A57',
            } }
          >
            {translator.AddNewContactDialog.listText}
          </p>
        )}
        <div className = "colourContentFooter">
          <div className = "colorIndicationText">
            {translator.AddNewContactDialog.colourIndicationText}
          </div>
          <div className = "colorIndication">
            {totalColors.map((color) => 
              <div
                key = { color.index }
                aria-hidden = "true"
                onClick = { () => {
                  let selectedList = [ ...selectedContactsList ];

                  selectedList = selectedList.map((contact) => 
                    ({
                      ...contact,
                      color,
                    })
                  );
                  this.setState({
                    inputColor: color,
                    selectedContactsList: selectedList,
                  });
                } }
              >
                <div
                  style = { {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '36.73px',
                    height: '36.73px',
                    borderRadius: '50%',
                    backgroundColor: inputColor === color ? `#0051C2` : 'white',
                  } }
                >
                  <div
                    style = { {
                      width: '29.14px',
                      height: '29.14px',
                      borderRadius: '50%',
                      backgroundColor: `${ color }`,
                      border: '1px solid white',
                    } }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div style = { { position: 'absolute', bottom: '3.1%', width: '25vw' } }>
          <div
            style = { {
              border: '1px solid #C2C9D1',
              margin: '0px -3px 0px 30px',
            } }
          />
          <div className = "rightSideAddNewContactFooterButton">
            <div
              aria-hidden = "true"
              className = {
                selectedContactsList.length > 0
                  ? translator.AddNewContactDialog.deselectAll === 'Deselect all'
                    ? 'deselectButtonTextEnable1'
                    : 'deselectButtonTextEnable'
                  : translator.AddNewContactDialog.deselectAll === 'Deselect all'
                    ? 'deselectButtonTextDisable1'
                    : 'deselectButtonTextDisable'
              }
              onClick = { this.onDeselectAllClick }
            >
              {translator.AddNewContactDialog.deselectAll}
            </div>
            <div
              className = "rightSideAddNewContactButton"
              aria-hidden = "true"
              onClick = { () => {
                this.setState({ tabValue: 'tab2' });
                this.onDeselectAllClick();
              } }
            >
              {translator.AddNewContactDialog.back}
            </div>
            <div className = "rightSideButton">
              <Button
                size = "sm"
                onClick = { () => {
                  this.saveContact();
                } }
              >
                {translator.AddNewContactDialog.save}
              </Button>
            </div>
          </div>
        </div>
      </div>
      
    );
  };

  handleChange = ({ target }) => {
    this.setState({
      [ target.name ]: target.value,
    });
  };

  onCheckboxClick = (e, member) => {
    let { selectedContactsList,unselectedContactsList } = this.state;
    // let selectedContactsList = [];
    // let unselectedContactsList = [];
    selectedContactsList = [ ...selectedContactsList ];
    unselectedContactsList = [ ...unselectedContactsList ];
    if (e.target.checked) {
      selectedContactsList.push(member);
      unselectedContactsList = [ ...unselectedContactsList ].filter(
        (contact) => contact.name !== member.name
      );
    } else {
      const uncheckedItem = selectedContactsList.find(
        (selectedContact) => selectedContact.name === member.name
      );
      selectedContactsList = [ ...selectedContactsList ].filter(
        (contact) => contact.name !== member.name
      );
      unselectedContactsList.push(uncheckedItem);
    }
    selectedContactsList = selectedContactsList.map((contact) => {
      const { inputColor } = this.state;
      return ({ ...contact, color: inputColor })});
    this.setState({
      selectedContactsList,
      unselectedContactsList,
    });
  };

  onAddButtonClicked = () => {
    const { selectedCostCenter } = this.props;
    const { contactName,number,role,contactDataList,unselectedContactsList } = this.state;
    if (!(contactName === '' || number === '' || role === '')) {
      this.setState({
        contactDataList: [
          ...contactDataList,
          {
            costCenterId: selectedCostCenter.costCenterId,
            AppId: getJsonBody().applicationId,
            EmployeeId: getJsonBody().masterGroupSourceSystemId,
            id: 0,
            IsSupervisor: true,
            name: contactName,
            contactNumber: number,
            isHidden: false,
            isCustomContact: false,
            role,
            color: '#C2C9D1',
            orderNumber: contactDataList.length + 1,
          },
        ],
        unselectedContactsList: [
          ...unselectedContactsList,
          {
            costCenterId: selectedCostCenter.costCenterId,
            AppId: getJsonBody().applicationId,
            EmployeeId: getJsonBody().masterGroupSourceSystemId,
            id: 0,
            IsSupervisor: true,
            name: contactName,
            contactNumber: number,
            isHidden: false,
            isCustomContact: false,
            role,
            color: '#C2C9D1',
            orderNumber: contactDataList.length + 1,
          },
        ],
        contactName: '',
        number: '',
        role: '',
      });
    }
  };

  render() {
    const { browserLanguage } = this.props;
    const { loading1,tabValue,teamName } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <Dialog
        className = "addNewContactDialogue"
        showHeader = { false }
        visible
        style = { { width: '63vw', height: '90vh' } }
        id = "addNewContactDialog"
      >
        <Dialog visible = { loading1 } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        <div className = "addNewContactWrapper">
          <div className = "leftSideAddNewContact">
            <div className = "leftSideAddNewContactHeader">
              {translator.AddNewContactDialog.vipContact}
            </div>
            <div className = "leftSideAddNewContactContainer">
              <p className = "vipContactsText">{translator.AddNewContactDialog.paraHeader}</p>
              <p className = "para">
                {translator.AddNewContactDialog.paraText}
                <br />
                <br />
                {translator.AddNewContactDialog.paraText1}
                <br />
                <br />
                {translator.AddNewContactDialog.paraSteps}
                <br />
                {translator.AddNewContactDialog.paraStep1}
                <br />
                {translator.AddNewContactDialog.paraStep2}
                <br />
                {translator.AddNewContactDialog.paraStep3}
                <br />
                <br />
                {translator.AddNewContactDialog.paraText2}
              </p>
              <div
                style = { {
                  position: 'absolute',
                  bottom: '8vh',
                  width: '34vw',
                } }
              >
                <div
                  style = { {
                    border: '1px solid #C2C9D1',
                    margin: '0px 9px 0px 30px',
                  } }
                />
                <div className = "leftSideAddNewContactFooter" />
              </div>
            </div>
          </div>
          <div className = "centerAddNewContact" />
          <div className = "rightSideAddNewContact">
            <TabContext>
              <div className = "rightSideAddNewContactHeader">
                <Tabs
                  id = "tabStyle1"
                  selectedTab = { tabValue }
                  onChange = { (e) => {
                    if (teamName) this.setState({ tabValue: e });
                  } }
                >
                  <Tab
                    id = {
                      tabValue === 'tab1' ? 'nameTabActiveNew' : 'nameTabInActiveNew'
                    }
                    value = "tab1"
                    panelId = "id-tab1"
                    className = "addNew-tabStyle"
                    onClick = { () => this.setState({ tabValue: 'tab1' }) }
                  >
                      1. {translator.AddNewContactDialog.contactsTab}
                  </Tab>
                  <Tab
                    id = {
                      tabValue === 'tab2' ? 'nameTabActiveNew' : 'nameTabInActiveNew'
                    }
                    value = "tab2"
                    panelId = "id-tab2"
                    className = "addNew-tabStyle"
                    onClick = { () => this.setState({ tabValue: 'tab2' }) }
                  >
                      2. {translator.AddNewContactDialog.orderTab}
                  </Tab>
                  <Tab
                    id = {
                      tabValue === 'tab3' ? 'nameTabActiveNew' : 'nameTabInActiveNew'
                    }
                    value = "tab3"
                    panelId = "id-tab3"
                    className = "addNew-tabStyle"
                    onClick = { () => this.setState({ tabValue: 'tab3' }) }
                  >
                      3. {translator.AddNewContactDialog.coloursTab}
                  </Tab>
                </Tabs>
              </div>
              <div>
                <TabPanel id = "id-tab1" value = "tab1">
                  {this.contactsContent()}
                </TabPanel>
                <TabPanel id = "id-tab2" value = "tab2">
                  {this.orderContent()}
                </TabPanel>
                <TabPanel id = "id-tab3" value = "tab3">
                  {this.colourContent()}
                </TabPanel>
              </div>
            </TabContext>
          </div>
        </div>
      </Dialog>
     
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
})

const mapDispatchToProps = () => {
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewContact);
