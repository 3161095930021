/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ReTooltip,
} from 'recharts';
import { translation } from '../../locales/translation.js';
import './DailyPulse.css';

const translator = new LocalizedStrings(translation);

class DailyPulseGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val6: 0,
      val7: '',
      val9: 0,
      showTooltip:false,
    };
  }

  toolTipContentDailyPulse = () => {
    const { val6, val7, val9, showTooltip } = this.state;
    return (
      <div style = { { display :`${ showTooltip === false ? 'none' : 'block' }` } }>
        <div className = "toolWrapper">
          <div style = { { display: 'flex', paddingLeft: '13px', paddingBottom: '10px' } }>
            <div style = { { paddingRight: '12px', marginTop: '10.02px' } }>
              {val7 === '#FFC226' && (
                <img
                  className = ""
                  src = { require('../../images/Happy1.png') }
                  alt = "noImage"
                  height = { 23.51 }
                  width = { 27.5 }
                />
              )}
              {val7 === '#FFBA04' && (
                <img
                  className = ""
                  src = { require('../../images/Happy2.png') }
                  alt = "noImage"
                  height = { 23.51 }
                  width = { 27.5 }
                />
              )}
              {val7 === '#F7CC7A' && (
                <img
                  className = ""
                  src = { require('../../images/Happy3.png') }
                  alt = "noImage"
                  height = { 23.51 }
                  width = { 27.5 }
                />
              )}
              {val7 === '#EDBDBD' && (
                <img
                  className = ""
                  src = { require('../../images/Sad1.png') }
                  alt = "noImage"
                  height = { 23.51 }
                  width = { 27.5 }
                />
              )}
              {val7 === '#FFA894' && (
                <img
                  className = ""
                  src = { require('../../images/Sad2.png') }
                  alt = "noImage"
                  height = { 23.51 }
                  width = { 27.5 }
                />
              )}
            </div>
            <div className = "percentBlock">
              {val6}%
            </div>
            <div className = "voteBlock">
            - {val9} votes
            </div>
          </div>
        </div>
      </div>
    );
  };

  dailyPulseGraph = () => {
    const { respData, respGrid, browserLanguage, selectedInterval } =this.props;
    return (
      <ResponsiveContainer height = { 250 }>
        <LineChart data = { respData }>
          <CartesianGrid
            verticalPoints = { respGrid } 
          />
          <XAxis dataKey = { browserLanguage === "en"?"weekNumber":"weekNumberFi" } padding = { selectedInterval ==="Weekly" ?  { left: 50, right: 50 } : selectedInterval ==="Monthly" ?  { left: 35, right: 35 } :  { left: 95, right: 95 } } />
          <YAxis tickFormatter = { tick => `${ tick }%` } type = "number" domain = { [ 0, 100 ] } />
          <ReTooltip
            cursor = { false }
            onClose = { () => {} }
            isVisible = { false }
            content = { this.toolTipContentDailyPulse() }
            backgroundColor = "rgba(52, 52, 52, 0)"
            placement = "center"
            filterNull 
          />
          <Line
            dataKey = "veryGoodPercent"
            stroke = "#FFC226"
            strokeWidth = { 4.5 }
            activeDot = { {
              onMouseOver: (event, payload) => {
                this.setState({
                  val6: payload.payload.veryGoodPercent,
                  val7: payload.fill,
                  val9: payload.payload.veryGoodVotes,
                  showTooltip:true
                });
              },
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              },
            } }
            dot
          />
          <Line
            dataKey = "goodPercent"
            stroke = "#FFBA04"
            strokeWidth = { 4.5 }
            activeDot = { {
              onMouseOver: (event, payload) => {
                this.setState({
                  val6: payload.payload.goodPercent,
                  val7: payload.fill,
                  val9: payload.payload.goodVotes,
                  showTooltip:true
                });
              },
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              },
            } }
            dot
          />
          <Line
            dataKey = "normalPercent"
            stroke = "#F7CC7A"
            strokeWidth = { 4.5 }
            activeDot = { {
              onMouseOver: (event, payload) => {
                this.setState({
                  val6: payload.payload.normalPercent,
                  val7: payload.fill,
                  val9: payload.payload.normalVotes,
                  showTooltip:true
                });
              },
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              },
            } }
            dot
          />
          <Line
            dataKey = "badPercent"
            stroke = "#EDBDBD"
            strokeWidth = { 4.5 }
            activeDot = { {
              onMouseOver: (event, payload) => {
                this.setState({
                  val6: payload.payload.badPercent,
                  val7: payload.fill,
                  val9: payload.payload.badVotes,
                  showTooltip:true
                });
              },
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              },
            } }
            dot
          />
          <Line
            dataKey = "veryBadPercent"
            stroke = "#FFA894"
            strokeWidth = { 4.5 }
            activeDot = { {
              onMouseOver: (event, payload) => {
                this.setState({
                  val6: payload.payload.veryBadPercent,
                  val7: payload.fill,
                  val9: payload.payload.veryBadVotes,
                  showTooltip:true
                });
              },
              onMouseLeave: () => {
                this.setState({ showTooltip: false });
              },
            } }
            dot
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  render() {
    const { browserLanguage, selectedInterval, counter, selectedCostCenter, counter1, filterCCName } = this.props;
    // const { val8, val11, val12, val13, val14 } = this.state
    translator.setLanguage(browserLanguage);
    return (
     
      <div className = "pulseGraphContainer" >
        <div className = "graphHeading">
          <div className = "header">{ selectedInterval==="Weekly"?translator.DailyPulse.weeklyProgress:selectedInterval==="Monthly"?translator.DailyPulse.monthlyProgress:translator.DailyPulse.wuarterlyProgress } - { filterCCName === null ? selectedCostCenter.costCenterName : filterCCName }</div>
        </div>
        <div style = { { display: 'flex',padding:'10px' , paddingBottom: '40px' } }>
          <div style = { { width: '84%' } }>
            <div className = "displayGraph">{this.dailyPulseGraph()}</div>
            <div
              className = {
                selectedInterval === 'Weekly' ? 'displaySquare1' : selectedInterval === 'Monthly' ? 'displaySquare2' : 'displaySquare3'
              }
              style = { selectedInterval === 'Weekly' ?
                counter === 0 || counter1 >= 7
                  ? { marginLeft: '88%' }
                  : counter === 1 || counter1 === 6
                    ? { marginLeft: '76.6%' }
                    : counter === 2 || counter1 === 5
                      ? { marginLeft: '65%' }
                      : counter === 3 || counter1 === 4
                        ? { marginLeft: '53.5%' }
                        : counter === 4 || counter1 === 3
                          ? { marginLeft: '42%' }
                          : counter === 5 || counter1 === 2
                            ? { marginLeft: '30.6%' }
                            : counter === 6 || counter1 === 1
                              ? { marginLeft: '19%' }
                              : { marginLeft: '7.45%' } : selectedInterval==='Monthly' ? counter === 0
                  ? { marginLeft: '91.5%' }
                  : counter === 1
                    ? { marginLeft: '83.6%' }
                    : counter === 2
                      ? { marginLeft: '76.2%' }
                      : counter === 3
                        ? { marginLeft: '68.5%' }
                        : counter === 4
                          ? { marginLeft: '60.8%' }
                          : counter === 5
                            ? { marginLeft: '53.2%' }
                            : counter === 6
                              ? { marginLeft: '45.8%' }
                              : counter === 7
                                ? { marginLeft: '37.67%' }
                                : counter === 8
                                  ? { marginLeft: '30%' }
                                  : counter === 9
                                    ? { marginLeft: '22.8%' }
                                    : counter === 10
                                      ? { marginLeft: '14.9%' }
                                      : { marginLeft: '7%' } : selectedInterval==='Quarterly' ? counter === 0
                  ? { marginLeft: '76.5%' }
                  : counter === 1
                    ? { marginLeft: '53.6%' }
                    : counter === 2
                      ? { marginLeft: '31%' }
                      : { marginLeft: '7.5%' }
                  : { marginLeft: '7.5%' }
                                                          
              }
            />
          </div>
          <div style = { { marginLeft : '42px' , marginTop : '53px' } }>
            <div style = { { display : 'flex' } } > 
              <div>
                <img
                  style = { { marginRight : '12px', marginTop : '18px' } }
                  className = ""
                  src = { require('../../images/Happy1_line.png') }
                  alt = "noImage"
                  height = { 5 }
                  width = { 15.68 }
                />
              </div>
              <div>
                <img
                  style = { { marginTop : '6px' } }
                  className = ""
                  src = { require('../../images/Happy1Face.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 27.5 }
                />
              </div>
            </div>
            <div style = { { display : 'flex' } } > 
              <div>
                <img
                  style = { { marginRight : '12px', marginTop : '18px' } }
                  className = ""
                  src = { require('../../images/Happy2_line.png') }
                  alt = "noImage"
                  height = { 5 }
                  width = { 15.68 }
                />
              </div>
              <div>
                <img
                  style = { { marginTop : '6px' } }
                  className = ""
                  src = { require('../../images/Happy2Face.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 27.5 }
                />
              </div>
            </div>
            <div style = { { display : 'flex' } } > 
              <div>
                <img
                  style = { { marginRight : '12px', marginTop : '18px' } }
                  className = ""
                  src = { require('../../images/NormalFace_line.png') }
                  alt = "noImage"
                  height = { 5 }
                  width = { 15.68 }
                />
              </div>
              <div>
                <img
                  style = { { marginTop : '6px' } }
                  className = ""
                  src = { require('../../images/NormalFace.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 27.5 }
                />
              </div>
            </div>
            <div style = { { display : 'flex' } } > 
              <div>
                <img
                  style = { { marginRight : '12px', marginTop : '18px' } }
                  className = ""
                  src = { require('../../images/SadFace1_line.png') }
                  alt = "noImage"
                  height = { 5 }
                  width = { 15.68 }
                />
              </div>
              <div>
                <img
                  style = { { marginTop : '6px' } }
                  className = ""
                  src = { require('../../images/SadFace1.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 27.5 }
                />
              </div>
            </div>
            <div style = { { display : 'flex' } } > 
              <div>
                <img
                  style = { { marginRight : '12px', marginTop : '18px' } }
                  className = ""
                  src = { require('../../images/SadFace2_line.png') }
                  alt = "noImage"
                  height = { 5 }
                  width = { 15.68 }
                />
              </div>
              <div>
                <img
                  style = { { marginTop : '6px' } }
                  className = ""
                  src = { require('../../images/SadFace2.png') }
                  alt = "noImage"
                  height = { 24 }
                  width = { 27.5 }
                />
              </div>
            </div>
            <div className = "smilyFooter">
              { selectedInterval === "Weekly" ? translator.DailyPulse.weeks : selectedInterval === "Monthly" ? translator.DailyPulse.months : translator.DailyPulse.quarters }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = () => {};
export default connect(mapStateToProps, mapDispatchToProps)(DailyPulseGraph);