/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import './NewConfiguration.css';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Button } from '@postidigital/posti-components';
import LocalizedStrings from 'react-localization';
import EditConfiguration from './EditConfiguration';
import { translation } from '../../locales/translation.js';

const translator = new LocalizedStrings(translation);
class NewConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.state.newConifFlag1 = false;
    this.state.locationString = '';
  }

  componentDidMount() {
    const { allTargetValue,locationData } = this.props;
    allTargetValue.splice(allTargetValue.length - 1, 1);
    let locationString = '';
    locationData.map((item) => {
      locationString += `${ item.location  } `;
      return item
    });
    this.setState({ locationString });
  }

  render() {
    const { browserLanguage,allTargetValue,selectedBadgeData,cancelButtonPass,oldValue,datefin,datefin2,handleAPI } = this.props;
    const { newConifFlag1,locationString } = this.state;

    translator.setLanguage(browserLanguage);
    return (
      newConifFlag1 ? (
        <EditConfiguration
          allTargetValue = { allTargetValue }
          selectedBadgeData = { selectedBadgeData }
          onClickCancel = { cancelButtonPass }
        />
      ) : (
        <Dialog
          resizable = { false }
          showHeader = { false }
          visible 
          id = "newConfigurationDialog"
        >
          <div className = "newConfigurationHeader">{translator.NewConfiguration.header}</div>
          <div className = "newConfigurationContainer">
            <div className = "newConfigurationSubHeading">
              {translator.NewConfiguration.subheading}
            </div>
            <div className = "newConfigCategoryContainer">
              <div className = "newConfigCategory">
                <view className = "descContainer">
                  <span className = "span">{translator.NewConfiguration.badgelevel}</span>

                  {selectedBadgeData.map((item) => (
                    <span className = "newConfigDesc">
                      {selectedBadgeData[ 0 ]
                        ? browserLanguage === 'en'
                          ? item.heading
                          : item.headingFi
                        : '0'}
                    </span>
                  ))}
                </view>
              </div>
              <div className = "newConfigCategory">
                <view className = "descContainer">
                  <span className = "span">{translator.NewConfiguration.days}</span>
                  {allTargetValue.map((item, key) => {
                    if (key === 0) return null;
                    return (
                      <span className = "newConfigDescBrackets">
                        {oldValue[ key ].targetTime === item.targetTime ? (
                          <span className = "oldValue1">{item.targetTime}</span>
                        ) : (
                          <>
                            <span className = "check1">
                              {item.targetTime ? item.targetTime : '0'}
                            </span>
                            <span className = "oldValue">
                                ({oldValue[ key ].targetTime})
                            </span>
                          </>
                        )}
                      </span>
                    );
                  })}
                </view>
              </div>
              <div className = "newConfigCategory">
                <view className = "descContainer">
                  <span className = "span">{translator.NewConfiguration.slack}</span>
                  {allTargetValue.map((item, key) => {
                    if (key === 0) return null;
                    return (
                      <span className = "newConfigDescBrackets">
                        {oldValue[ key ].targetSlack === item.targetSlack ? (
                          <span className = "oldValue1">{item.targetSlack}</span>
                        ) : (
                          <>
                            <span className = "check1">
                              {item.targetSlack ? item.targetSlack : '0'}
                            </span>
                            <span className = "oldValue">
                                ({oldValue[ key ].targetSlack})
                            </span>
                          </>
                        )}
                      </span>
                    );
                  })}
                </view>
              </div>
              <div className = "newConfigCategory">
                <view className = "descContainer">
                  <span className = "span">{translator.NewConfiguration.targets}</span>
                  {allTargetValue.map((item, key) => {
                    if (key === 0) return null;
                    return (
                      <span className = "newConfigDescBrackets">
                        {oldValue[ key ].targetValue === item.targetValue ? (
                          <span className = "oldValue1">{item.targetValue}</span>
                        ) : (
                          <>
                            <span className = "check1">
                              {item.targetValue ? item.targetValue : '0'}
                            </span>
                            <span className = "oldValue">
                                ({oldValue[ key ].targetValue})
                            </span>
                          </>
                        )}
                      </span>
                    );
                  })}
                </view>
              </div>
              <div className = "newConfigCategory">
                <view className = "descContainer">
                  <span className = "span">{translator.NewConfiguration.points}</span>
                  {allTargetValue.map((item, key) => {
                    if (key === 0) return null;
                    return (
                      <span className = "newConfigDescBrackets">
                        {oldValue[ key ].targetPoints === item.targetPoints ? (
                          <span className = "oldValue1">{item.targetPoints}</span>
                        ) : (
                          <>
                            <span className = "check1">
                              {item.targetPoints ? item.targetPoints : '0'}
                            </span>
                            <span className = "oldValue">
                                ({oldValue[ key ].targetPoints})
                            </span>
                          </>
                        )}
                      </span>
                    );
                  })}
                </view>
              </div>
            </div>
            <div className = "newConfigCategory">
              <view className = "descContainer2">
                <span className = "startPublishApplyDateContainer">{translator.NewConfiguration.startingdate}</span>
                <span className = "newConfigDesc">{datefin}</span>
              </view>
            </div>
            <div className = "newConfigCategory">
              <view className = "descContainer2">
                <span className = "startPublishApplyDateContainer">{translator.NewConfiguration.publishingdate}</span>
                <span className = "newConfigDesc">{datefin2}</span>
              </view>
            </div>
            <div className = "newConfigCategory">
              <view className = "descContainer2">
                <span className = "startPublishApplyDateContainer">{translator.NewConfiguration.applicability}</span>
                <span className = "newConfigDesc">
                  {'\n'}
                  {locationString}
                </span>
              </view>
            </div>
          </div>
          <hr className = "newConfigurationFooterLine" />
          <view className = "footerNewConfig">
            <button 
              type = "button"
              className = "footerText" 
            >
              {/* Edit */}
            </button>
            <button
              type = "button"
              className = "footercancelButton"
              onClick = { () => cancelButtonPass() }
            >
              {translator.logOut.cancel}
            </button>
            <Button className = "footerTextCreateButton" onClick = { handleAPI }>
              {translator.NewConfiguration.create}
            </Button>
          </view>
        </Dialog>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewConfiguration);
