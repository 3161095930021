/* eslint-disable default-param-last */
// language reducer to transfer changed language to screen
const changePathReducer = (state = '', action) => {
  switch (action.type) {
  case 'ChangePath':
    return action.payload;
  default:
    return state;
  }
};
export default changePathReducer;
