import React from 'react';
import './CustomApiErrorDialog.css';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../locales/translation.js';

const translator = new LocalizedStrings(translation);
function CustomApiErrorDialog(props) {
  const { browserLanguage } = props;
  translator.setLanguage(browserLanguage);
  return (
    <Dialog showHeader = { false } visible>
      <div className = "editDialogWrapper">
        <div className = "apiDialogHeader">
          <div className = "apiDialogBody">{translator.commonApiError.oops}</div>
        </div>
        <div className = "editDialogText">
          <div>
            {translator.commonApiError.discription} 
          </div>
          <div>
            <b>routemaster_ecd@posti.com</b>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
// eslint-disable-next-line no-unused-vars
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CustomApiErrorDialog);
// export default EditDialog;
