/* eslint-disable default-param-last */
// language reducer to transfer changed language to screen
const changeLanguageReducer = (state = '', action) => {
  switch (action.type) {
  case 'ChangeLanguage':
    return action.payload;
  default:
    return state;
  }
};
export default changeLanguageReducer;
