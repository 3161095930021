/* eslint-disable */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fi';
import LocalizedStrings from 'react-localization';
import { Dialog, Loading, Button } from '@postidigital/posti-components';
import Toastr from '../../shared/Toastr';
import Avatar from '../../components/Avatar/Avatar';
import { translation } from '../../locales/translation';
import './Rewards.css';
import { CommonService } from '../../service/CommonService';
import apiUrl from '../../urlConstants';
import { getAppId, getEmailIdFromToken, isRolePEC } from '../../utility/commonUtility';

const translator = new LocalizedStrings(translation);
export class AwardBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.commonService = new CommonService();
  }

  onBadgeAward = () => {
    const appid=getAppId();
    const { selectedCostCenter, selectedBadgeAchievementCode, checkboxSelectedValues, closeAwardBadge, closeRewardsOverlay } = this.props
    const emailId = getEmailIdFromToken(localStorage.getItem('adal.idtoken'));
    const jsonBody = {
      ApplicationId: isRolePEC() ? 4 : appid,
      MasterGroupSourceSystemId: emailId,
      CostCenterId: selectedCostCenter.costCenterId,
      BadgeAchievementCode: selectedBadgeAchievementCode,
      UserIdList: checkboxSelectedValues,
    };
    this.setState({ isLoading: true });
    this.commonService
      .postMethod(apiUrl.AwardSupervisorBadges, jsonBody)
      .then((res) => {
        if (res.data.badgeAwardStatus === true) {
          this.setState({ isLoading: false });
          closeAwardBadge();
          closeRewardsOverlay();
          Toastr.showSuccessMessage(translator.RewardsScreen.rewardSaved);
        }
      })
      .catch(() => {
        closeRewardsOverlay();
        Toastr.showErrorMessage(translator.ToastMessages.awardBadgeFailed);
      });
  };

  onBadgeAwardMessage = () => {
    const appid=getAppId();
    const { selectedCostCenter, selectedBadgeAchievementCode, checkboxSelectedValues, closeAwardBadge, closeRewardsOverlay, inputText, inputTextArea, selectedBadgeName, selectedBadgeMsg } = this.props
    const emailId = getEmailIdFromToken(localStorage.getItem('adal.idtoken'));
    const jsonBody1 = {
      applicationId: isRolePEC() ? 4 : appid,
      masterGroupSourceSystemId: emailId,
      costcenterId: selectedCostCenter.costCenterId,
      badgeName: selectedBadgeAchievementCode === "Extra_work" ? "Extra work" : selectedBadgeAchievementCode,
      recipientsSystemIDs: checkboxSelectedValues,
      messageTitle: (inputText === null || inputText === '') ?  selectedBadgeName : inputText,
      messageBody : inputTextArea === "" ? selectedBadgeMsg : inputTextArea
    };
    this.setState({ isLoading: true });
    this.commonService
      .postMethod(apiUrl.saveCustomMessagesBadges, jsonBody1)
      .then((res) => {
        this.onBadgeAward();
        // if (res.data.badgeAwardStatus === true) {
        //   this.setState({ isLoading: false });
        //   closeAwardBadge();
        //   closeRewardsOverlay();
        //   Toastr.showSuccessMessage(translator.RewardsScreen.rewardSaved);
        // }
      })
      .catch(() => {
        closeRewardsOverlay();
        Toastr.showErrorMessage(translator.ToastMessages.awardBadgeFailed);
      });
  };

  finalRecipients = () =>  {
    const { checkboxSelectedData } = this.props
    return checkboxSelectedData.map((data) => (
      <div
        className = "flexBox"
        key = { data.userSourceSystemId }
        style = { { alignItems: 'center' } }
      >
        <div className = "avatarContainer">
          <Avatar
            imageSource = { data.userImagePath }
            name = { data.firstName }
            avatarColor = "#FFA894"
            size = { 46 }
            fontSize = "15px"
          />
        </div>
        <div className = "userNameText">{data.firstName}</div>
      </div>
    ))}

  // Final overlay to award Badge
  giveReward = () => {
    const { browserLanguage, badgeValue, selectedImg, selectedBadgeName, selectedBadgePoints, selectedBadgeMsg, closeRewardsOverlay, closeAwardBadge, inputText, inputTextArea } = this.props
    translator.setLanguage(browserLanguage);
    return (
      <div>
        <div className = "newRewardBanner">
          <div className = "newRewardText">
            {translator.RewardsScreen.newReward}
          </div>
          {badgeValue === '' ? (
            <div
              className = "badgeOnBanner"
              style = { {
                borderRadius: '100px',
                backgroundColor: '#E6EBF1',
              } }
            />
          ) : (
            <div className = "badgeOnBanner">
              <img
                alt = ""
                src = {
                  (isRolePEC() || getAppId() === 2)
                    ? selectedImg
                    : require(`../../images/${ selectedImg }`)
                }
                style = { { height: '88px', width: '88px' } }
              />
            </div>
          )}
        </div>
        <div style = { { height: '62vh', overflowY: 'auto', marginLeft: '32px' } }>
          <div>
            <div className = "rewards-Text">
              {translator.RewardsScreen.rewardText}
            </div>
            <div
              className = "flexBox"
              style = { { color: '#3B4A57', alignItems: 'center' } }
            >
              <div className = "typePointsText">
                {translator.RewardsScreen.typeText}
              </div>
              <div className = "badgeNamePointsText">
                {(isRolePEC() || getAppId() === 2)
                  ? selectedBadgeName
                  : translator.BadgesText[ selectedBadgeName ]}
              </div>
            </div>
            <div
              className = "flexBox"
              style = { { color: '#3B4A57', alignItems: 'center' } }
            >
              <div className = "typePointsText">
                {translator.RewardsScreen.pointsText}
              </div>
              <div
                className = "badgeNamePointsText"
                style = { { marginLeft: '0px' } }
              >
                {`${ selectedBadgePoints } pts`}
              </div>
            </div>
          </div>
          <div className = "recipient_Text">
            {translator.RewardsScreen.recipientsText}
          </div>
          {this.finalRecipients()}
          <div style = { { direction: 'ltr', marginLeft: '2.5px',marginTop:'34.59px',fontFamily:'Montserrat',fontWeight:'600',fontSize:'16px',lineHeight:'125%',marginBottom:'18px' } }>
            {translator.RewardsScreen.Preview}
          </div>
          <div className = "messageBox">
            <div className = "momentText">
              {moment().locale(browserLanguage).format('llll')}
            </div>
            <div>
              <img
                alt = ""
                src = {
                  (isRolePEC() || getAppId() === 2)
                    ? selectedImg
                    : require(`../../images/${ selectedImg }`)
                }
                className = "img_MsgContainer"
              />
            </div>
            <div className = "badgeTitleText">
              {(isRolePEC() || getAppId() === 2 || getAppId() === 5)
                ? (inputText === null || inputText === "") ? selectedBadgeName : inputText
                : translator.BadgesText[ selectedBadgeName ]}
            </div>
            <div className = "final-BadgeAwardMessage">
              {(isRolePEC() || getAppId() === 2 || getAppId() === 5)
                ? inputTextArea === "" ? selectedBadgeMsg : inputTextArea
                : translator.BadgesText[ selectedBadgeMsg ]}
            </div>
          </div>
        </div>
        <div className = "dividerLine" style = { { marginLeft: '32px' } } />
        <div className = "buttonRow" style = { { marginBottom: '10px' } }>
          <button
            type = "button"
            className = "cancelButton"
            onClick = { closeRewardsOverlay }
          >
            {translator.RewardsScreen.cancelText}
          </button>
          <button type = "button" className = "cancelButton" onClick = { ()=>{closeAwardBadge(inputText,inputTextArea)} }>
            {translator.RewardsScreen.editText}
          </button>
          <Button
            id = "reward-nextButton"
            size = "sm"
            disabled = { badgeValue === '' }
            onClick = { () => {
              if(isRolePEC() || getAppId () === 2 || getAppId() === 5) {
                this.onBadgeAwardMessage();
              } 
              else
              {
                   this.onBadgeAward();
              }
              
            } }
          >
            {translator.RewardsScreen.reward}
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { isLoading } =this.state
    const { closeRewardsOverlay } = this.props
    return (
      <div>
        {this.giveReward()}
        {isLoading && (
          <Dialog
            id = "badgeAwardLoader"
            noCloseButton
            onClose = { closeRewardsOverlay }
          >
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
        )}
      </div>
    );
  }
}

// const mapStateToProps = (state) => ({});
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AwardBadge);
