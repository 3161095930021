import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import './TeamDesc.css';
import {
  Tab,
  TabPanel,
  Tabs,
  TabContext,
  Input,
  Button,
  FileUpload,
  DocumentIcon,
  BinIcon,
  Search,
  Checkbox,
  Dialog,
  Loading,
} from '@postidigital/posti-components';
import moment from 'moment';
import { translation } from '../../../locales/translation.js';
import Avatar from '../../../components/Avatar/Avatar.js';
import { CommonService } from '../../../service/CommonService';
import apiUrl from '../../../urlConstants';
import { getAppId , getEmailIdFromToken } from '../../../utility/commonUtility';
import Toastr from '../../../shared/Toastr';

const translator = new LocalizedStrings(translation);

class TeamDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 'tab1',
      selectedFiles: [],
      errorMessage: [],
      selectedMembers: [],
      selectedMembersWholeDetails: [],
      imageBase64: null,
      searchText: '',
      availablePeople: [],
      isLoading: false,
    };
    this.commonService = new CommonService();
  }

  // To declare Name of Team
  nameContent = () => {
    const { teamName } = this.state;
    const { availablePeopleList,OnHide } = this.props;

    return (
      <>
        <div
          style = { {
            padding: '0px 32px 2px',
            overflowY: 'auto',
            marginBottom: '15vh',
          } }
        >
          <div className = "nameHeading">{translator.NewTeamScreen.teamName}</div>
          <Input
            id = "newTeamName"
            label = ""
            value = { teamName }
            onChange = { (e) => {
              this.setState({
                teamName: e.target.value,
                availablePeople: availablePeopleList,
              });
            } }
            lightBackground
          />
        </div>

        {/* Footer Section */}
        <div className = "teamDescFooterContainer" id = "name-buttons">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div className = "teamDescButtonContainer">
            <button type = "submit" className = "cancel-button" onClick = { () => OnHide('cancel') }>
              {translator.NewTeamScreen.cancel}
            </button>
            <Button
              id = "team-nextButton"
              disabled = {
                teamName === '' ||
                teamName === undefined ||
                teamName === null
              }
              size = "sm"
              onClick = { () => this.setState({ tabValue: 'tab2' }) }
            >
              {translator.NewTeamScreen.nextText}
            </Button>
          </div>
        </div>
      </>
    )}

  // To define image of Team
  avatarContent = () => {
    const { errorMessage,selectedFiles } = this.state;
    return (
      <>
        <div
          style = { {
            padding: '0px 32px',
            overflowY: 'auto',
            marginBottom: '15vh',
          } }
        >
          <div className = "nameHeading">{translator.NewTeamScreen.image}</div>

          <FileUpload
            accept = "image/jpeg, image/png"
            errorMessages = { errorMessage }
            disabled = { selectedFiles.length === 1 }
            files = { selectedFiles }
            multiple = { false }
            filesDisplay = {
              <div>
                {selectedFiles.map((data) => (
                  <div key = { data.index } style = { { marginTop: '16px' } }>
                    <div
                      style = { {
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      } }
                    >
                      <div style = { { display: 'flex' } }>
                        <DocumentIcon
                          style = { { alignSelf: 'center' } }
                          aria-hidden = "true"
                          color = "#FFA894"
                          height = "20px"
                          width = "20px"
                        />
                        <div className = "image-name">{data.name}</div>
                      </div>

                      <BinIcon
                        style = { { cursor: 'pointer' } }
                        aria-hidden = "true"
                        color = "#73808C"
                        height = "24px"
                        width = "24px"
                        onClick = { () => {
                          this.setState({ selectedFiles: [] });
                        // this.props.selectedFiles([]);
                        } }
                      />
                    </div>
                  </div>
                ))}
              </div>
            }
            inputProps = { { id: '1234' } }
            maxSize = { 200000 }
            minSize = { 1 }
            placeholderText = {
              <span className = "placeholder">
                {translator.NewTeamScreen.dragYourFiles}
                <br />
                <span
                  style = { {
                    color: selectedFiles.length !== 0 ? '#E6EBF1' : 'blue',
                  } }
                >
                  {translator.NewTeamScreen.uplOadIt}
                </span>
              </span>
            }
            resetErrorMessages = { this.selectedFiles }
            setErrorMessages = { this.errorMessage }
            setFiles = { (e) => this.selectedFiles(e) }
          />
        </div>

        {/* Footer Section */}
        <div className = "teamDescFooterContainer" id = "name-buttons">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div className = "teamDescButtonContainer">
            <button type = "submit" className = "cancel-button" onClick = { () => this.setState({ tabValue: 'tab1' }) }>
              {translator.NewTeamScreen.backText}
            </button>
            <Button
              id = "team-nextButton"
              size = "sm"
              onClick = { () => this.setState({ tabValue: 'tab3' }) }
            >
              {translator.NewTeamScreen.nextText}
            </Button>
          </div>
        </div>
      </>
    )}

  selectedFiles = (e) => {
    const { selectedFiles } = this.state;
    const array = selectedFiles;
    if (e) {
      e.map((data) => {
        array.push(data);
        return null;
      });
      if (e && e.length !== 0 && e[ 0 ] != null) {
        const reader = new FileReader();
        const file = e[ 0 ];
        reader.readAsDataURL(file);

        // to set image
        reader.onloadend = () => {
          this.setState({
            selectedFiles: array,
            errorMessage: [],
            imageBase64: reader.result,
          });
        };
      }
    }
  };

  errorMessage = (e) => {
    const error = [];
    if (e) {
      e.map((data) => {
        data.errors.map((data1) => {
          error.push(data1.message);
          return null;
        });
        return null;
      });
    }
    this.setState({ errorMessage: error });
    // this.props.selectedFiles([]);
  };

  // To align members to Team
  membersContent = () => {
    const { searchText,availablePeople,selectedMembers,teamName } = this.state;
    return (
      <>
        <div
          style = { {
            padding: '32px 32px 2px',
            overflowY: 'auto',
            // marginBottom: '15vh',
            height: 'calc(70vh - 74px)',
          } }
        >
          <Search
            aria-label = { translator.RewardsScreen.searchText }
            clearButtonProps = { {
              'aria-label': 'Clear',
            } }
            debounceTime = { 300 }
            id = "search"
            label = { translator.RewardsScreen.searchText }
            message = ""
            onChange = { (e) => this.searchPeople(e.target.value) }
            value = { searchText }
          />
          {availablePeople.map((members) => (
            <div className = "peopleDetailsContainer" key = { members.index }>
              <Checkbox
                id = "checkbox-1"
                onChange = { (e) => this.onCheckboxClick(e, members) }
                checked = { selectedMembers.includes(members.userSourceSystemId) }
                value = { members.userSourceSystemId }
              />
              <Avatar
                imageSource = { members.userImagePath }
                name = { members.firstName }
                avatarColor = "#FFA894"
                size = { 44 }
                fontSize = "15px"
              />
              <div className = "member-name">{members.firstName}</div>
            </div>
          ))}
        </div>

        {/* Footer Section */}
        <div className = "teamDescFooterContainer" id = "name-buttons">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div className = "teamDescButtonContainer">
            <button type = "submit" className = "cancel-button" onClick = { () => this.setState({ tabValue: 'tab2' }) }>
              {translator.NewTeamScreen.backText}
            </button>
            <Button
              id = "team-nextButton"
              disabled = {
                teamName === '' ||
                teamName === undefined ||
                teamName === null ||
                selectedMembers.length < 5
              }
              size = "sm"
              onClick = { () => {
                this.createTeam();
              } }
            >
              {translator.NewTeamScreen.saveText}
            </Button>
          </div>
        </div>
      </>
    )}

  searchPeople = (search) => {
    const { availablePeopleList } = this.props;
    if (search) {
      const filtered = availablePeopleList.filter((value) => value.firstName.toLowerCase().includes(search.toLowerCase()));
      this.setState({ searchText: search, availablePeople: filtered });
    } else {
      this.setState({
        availablePeople: availablePeopleList,
        searchText: search,
      });
    }
  };

  onCheckboxClick = (e, members) => {
    const { selectedMembers,selectedMembersWholeDetails } = this.state;

    const array = [ ...selectedMembers ];
    const arrayDetailOfMember = [ ...selectedMembersWholeDetails ];

    if (e.target.checked) {
      array.push(e.target.value);
      arrayDetailOfMember.push(members);
    } else {
      array.splice(array.indexOf(e.target.value), 1);
      arrayDetailOfMember.splice(arrayDetailOfMember.indexOf(members), 1);
    }
    this.setState({
      selectedMembers: array,
      selectedMembersWholeDetails: arrayDetailOfMember,
    });
  };

  createTeam = () => {
    const { imageBase64,teamName,selectedMembers,selectedMembersWholeDetails } = this.state;
    const { browserLanguage,selectedCostCenter,TeamDetails,OnHide  } = this.props;

    this.setState({ isLoading: true });
    const emailId = getEmailIdFromToken(localStorage.getItem('adal.idtoken'));
    const imageBase64Value = String(imageBase64).split(';base64,');
    const imageBase64StringValue = imageBase64Value[ 1 ];
    const ApplicationId = getAppId();
    const jsonBody = {
      applicationId: ApplicationId,
      MasterGroupSourceSystemId: emailId,
      entityCode: browserLanguage,
      costCenterId: selectedCostCenter.costCenterId,
      groupName: teamName,
      groupImage: imageBase64StringValue,
      userSystemIds: selectedMembers,
    };
    this.commonService
      .postMethod(apiUrl.createNewTeam, jsonBody)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.status === true) {
          const responseData = {
            Name: teamName,
            Details: selectedMembersWholeDetails,
            TeamImage: res.data.teamImage,
            imageBase64,
            EngagementLevel: '100%',
            PointsTotal: res.data.totalGroupPoints,
            selectedMembers,
            joiningDate: moment(res.data.teamFormedDate).format('DD.MM.YYYY'),
            teamId: res.data.teamId,
          };
          Toastr.showSuccessMessage(translator.NewTeamScreen.newTeamCreated);
          TeamDetails(responseData);
          OnHide('save');
        } else if (res.data.status === false) {
          if (res.data.statusMessage !== null) {
            Toastr.showErrorMessage(translator.NewTeamScreen.teamAlreadyExists);
          } else {
            Toastr.showErrorMessage(translator.ToastMessages.createTeamFailed);
          }
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
        Toastr.showErrorMessage(translator.ToastMessages.createTeamFailed);
      });
  };

  render() {
    const { isLoading,tabValue,teamName } = this.state;
    const { browserLanguage,OnHide  } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <>
        {isLoading && (
          <Dialog
            id = "spinner"
            onClose = { (e) => {
              OnHide(e);
            } }
            noCloseButton
          >
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
        )}
        <div id = "team-desc">
          <TabContext>
            {/* Tabs name */}
            <div className = "teamDescHeaderRightContainer">
              <Tabs
                selectedTab = { tabValue }
                onChange = { (e) => {
                  if (teamName) this.setState({ tabValue: e });
                } }
              >
                <Tab
                  id = { tabValue === 'tab1' ? 'nameTabActive' : 'nameTabInActive' }
                  value = "tab1"
                  panelId = "id-tab1"
                  className = "newTeam-tabStyle"
                >
                  {`1.  ${ translator.NewTeamScreen.name }`}
                </Tab>
                <Tab
                  id = { tabValue === 'tab2' ? 'nameTabActive' : 'nameTabInActive' }
                  value = "tab2"
                  panelId = "id-tab2"
                  className = "newTeam-tabStyle"
                >
                  {`2.  ${ translator.NewTeamScreen.avatar }`}
                </Tab>
                <Tab
                  id = { tabValue === 'tab3' ? 'nameTabActive' : 'nameTabInActive' }
                  value = "tab3"
                  panelId = "id-tab3"
                  className = "newTeam-tabStyle"
                >
                  {`3.  ${ translator.NewTeamScreen.members }`}
                </Tab>
              </Tabs>
            </div>
            {/* Tabs content */}
            <div>
              <TabPanel id = "id-tab1" value = "tab1">
                {this.nameContent()}
              </TabPanel>
              <TabPanel id = "id-tab2" value = "tab2">
                {this.avatarContent()}
              </TabPanel>
              <TabPanel id = "id-tab3" value = "tab3">
                {this.membersContent()}
              </TabPanel>
            </div>
          </TabContext>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TeamDesc);
