import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { translation } from '../../../locales/translation.js';
import './TodaysLog.css';

const translator = new LocalizedStrings(translation);

class TodaysLog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  todaysLogInfo = (todaysLogData, index) =>{
    const { browserLanguage } = this.props
    return(
      <div className = "mainREC" key = { index }>
        {todaysLogData.imageUrl ? (
          <img
            alt = ""
            style = { {
              marginRight: '19px',
              width: '32px',
              aspectRatio: 1,
            } }
            src = { todaysLogData.imageUrl }
          />
        ) : (
          <div className = "no-avatar" />
        )}
  
        <div className = "notifications">
          {todaysLogData.count}
          <span style = { { color: 'green' } }> {translator.HomePage.new}</span>
          <span style = { { color: '#73808C' } }>
            {' '}
            {browserLanguage === 'en'
              ? todaysLogData.logCategoryName
              : todaysLogData.logCategoryName_FI}
          </span>
        </div>
      </div>
    );
  } 

  render() {
    const { browserLanguage, todaysLogData } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <div className = "todaysLog" style = { { overflowY: 'auto', height: '350px' } }>
        {this.props &&
          todaysLogData &&
          // eslint-disable-next-line no-shadow
          todaysLogData.map((todaysLogData, index) =>
            todaysLogData.count > 0 ? this.todaysLogInfo(todaysLogData, index) : null
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TodaysLog);
