/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import {
  Button,
  InboxIcon,
  // FeedbackIcon,
  KnobsIcon,
  AsteriskIcon,
  CogIcon,
  HomeIcon,
  ListIcon,
  LogoutIcon,
  MenuItem,
  MultipleUsersIcon,
  SideMenu,
  GraphIcon,
  FeedbackIcon,
  ServicesIcon
} from '@postidigital/posti-components';
import './SideMenuCustom.css';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { translation } from '../locales/translation.js';
import  changePathAction  from '../actions/changePathAction.js';
import { authContext } from '../adalConfig';
import { CommonService } from '../service/CommonService.js';
import apiUrl from '../urlConstants.js';
import {
  getJsonBody,
  getRolesArrayFromToken,
} from '../utility/commonUtility.js';

const translator = new LocalizedStrings(translation);
const postalServicesScreens = [
  { screenName: 'home', icon: HomeIcon },
  { screenName: 'people', icon: MultipleUsersIcon },
  { screenName: 'weather', icon: CogIcon },
  { screenName: 'log', icon: ListIcon },
];

const PECScreens = [
  { screenName: 'home', icon: HomeIcon },
  { screenName: 'people', icon: MultipleUsersIcon },
  { screenName: 'weather', icon: CogIcon },
  { screenName: 'log', icon: ListIcon },
  { screenName: 'message', icon: InboxIcon },
  { screenName: 'badges', icon: ServicesIcon },
  { screenName: 'dailyPulse', icon: FeedbackIcon },
  { screenName: 'data', icon: GraphIcon },
  { screenName: 'contacts', icon: AsteriskIcon },
  // { screenName: 'configurations', icon: CogIcon },
];

const PSAdminScreens = [
  { screenName: 'home', icon: HomeIcon },
  { screenName: 'people', icon: MultipleUsersIcon },
  { screenName: 'weather', icon: CogIcon },
  { screenName: 'log', icon: ListIcon },
];
const PECAdminScreens = [
  { screenName: 'home', icon: HomeIcon },
  { screenName: 'people', icon: MultipleUsersIcon },
  { screenName: 'badges', icon: ServicesIcon },
  { screenName: 'message', icon: InboxIcon },
  { screenName: 'dailyPulse', icon: FeedbackIcon },
  { screenName: 'data', icon: GraphIcon },
  { screenName: 'weather', icon: CogIcon },
  { screenName: 'log', icon: ListIcon },
  { screenName: 'contacts', icon: AsteriskIcon },
  { screenName: 'configurations', icon: KnobsIcon },
];

class SideMenuCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
    };
    this.screenArray = this.screenArrayBasedOnRoles();
    this.commonService = new CommonService();
  }

  screenArrayBasedOnRoles = () => {
    let screenArray = [];
    const rolesArray = getRolesArrayFromToken();
    if (rolesArray.includes('PEC.ADMIN.GAMIFICATION')) {
      screenArray = PECAdminScreens;
    } else if (rolesArray.includes('PS.ADMIN.GAMIFICATION')) {
      screenArray = PSAdminScreens;
    } else if (rolesArray.includes('PS.SV.GAMIFICATION')) {
      screenArray = postalServicesScreens;
    } else if (rolesArray.includes('PEC.SV.GAMIFICATION')) {
      screenArray = PECScreens;
    } else if (rolesArray.includes('SG_ROLE_EMD_ADMIN_GAMIFICATION')){
      screenArray = PSAdminScreens;
    }else if(rolesArray.includes('SG_ROLE_EMD_SV_GAMIFICATION')){
      screenArray = postalServicesScreens;
    }
    return screenArray;
  };

  logOut = () => {
    const { selectedCostCenter }=this.props;
    const jsonBody = getJsonBody();
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    this.commonService
      .postMethod(apiUrl.logOutAction, jsonBody)
      .then(() => {})
      .catch(() => {});
  };

  render() {
    const { browserLanguage,history,changePathDispatcher, changePath, callBack }= this.props;
    const { openDialog }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <div id = "sideMenu">
        <Dialog
          showHeader = { false }
          visible = { openDialog }
          style = { { width: '30vw' } }
          id = "logOutDialog"
        >
          <div className = "logOutHeader">{translator.logOut.header}</div>
          <div className = "logOutBody">{translator.logOut.body}</div>
          <div
            style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } }
          />
          <div className = "logOutFooter">
            <div
              aria-hidden = "true"
              className = "logOutFooterText"
              onClick = { () => this.setState({ openDialog: false }) }
            >
              {translator.logOut.cancel}
            </div>
            <Button
              size = "sm"
              id = "logOutButton"
              onClick = { () => {
                this.logOut();
                setTimeout(() => {
                  authContext.logOut();
                }, 1500);
              } }
            >
              {translator.logOut.logOut}
            </Button>
          </div>
        </Dialog>
        <SideMenu role = "menu" id = "leftSideMenu" width = "inherit">
          {this.screenArray.map((screenDetails, index) => (
            <React.Fragment key = { `.$${ screenDetails.screenName }` }>
              <MenuItem
                aria-label = {
                  index !== 0
                    ? changePath ===
                          `/${ screenDetails.screenName }` ||
                        window.location.pathname ===
                          `/${ screenDetails.screenName }`
                    : changePath === '/' ||
                        changePath ===
                          `/${ screenDetails.screenName }` ||
                        window.location.pathname === '/' ||
                        window.location.pathname ===
                          `/${ screenDetails.screenName }`
                }
                chevronAriaLabel = "Button"
                icon = { screenDetails.icon }
                label = { translator.sideMenu[ screenDetails.screenName ] }
                selected = {
                  index !== 0
                    ? changePath ===
                          `/${ screenDetails.screenName }` ||
                        window.location.pathname ===
                          `/${ screenDetails.screenName }`
                    : changePath === '/' ||
                        changePath ===
                          `/${ screenDetails.screenName }` ||
                        window.location.pathname === '/' ||
                        window.location.pathname ===
                          `/${ screenDetails.screenName }`
                }
                onSelect = { (path) => {
                  history.push(path);
                  changePathDispatcher(path);
                  callBack(path);
                } }
                value = { screenDetails.screenName }
              />
            </React.Fragment>
          ))}
        </SideMenu>
        <div className = "sideMenuFooterText">
          <div style = { { textAlign: 'left', margin: '0 28px', display: 'none' } }>
            {`${ translator.sideMenu.version } 1.11.1`}
            <br /> {translator.sideMenu.terms} <br />
            {translator.sideMenu.accessability}
          </div>
          <div
            style = { { border: '1px solid #C2C9D1', margin: '24px 15px 0 15px' } }
          />
          <div
            aria-hidden = "true"
            className = "logOutStyle"
            onClick = { () => this.setState({ openDialog: true }) }
          >
            <LogoutIcon
              aria-hidden = "true"
              height = "18px"
              width = "18px"
              color = "#3B4A57"
              style = { { marginRight: '5%' } }
            />
            {translator.logOut.logOut}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  changePath: state.changePath,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = (dispatch) => ({
  changePathDispatcher: bindActionCreators(changePathAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SideMenuCustom));
