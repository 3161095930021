/* eslint-disable */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import React, { Component } from 'react';
import './DataFilter.css';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';
import { DateInput } from '@postidigital/posti-components/build/datepicker';
import {  Radio, Button, Tooltip } from '@postidigital/posti-components';
import { translation } from '../../locales/translation';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody } from '../../utility/commonUtility.js';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants';

const translator = new LocalizedStrings(translation);

const eToday = new Date();
let eDate = eToday.getDate();
let eMonth = eToday.getMonth() + 1;
const eYear = eToday.getFullYear();
if (eDate < 10) {
  eDate = `0${  eDate }`;
}
if (eMonth < 10) {
  eMonth = `0${  eMonth }`;
}
const endDate = `${ eYear  }-${  eMonth  }-${  eDate }`;

const date= eToday.setDate(eToday.getDate() - 56);
const s1Date = new Date(date);
let sDate = s1Date.getDate();
let sMonth = s1Date.getMonth() + 1;
const sYear = s1Date.getFullYear();
if (sDate < 10) {
  sDate = `0${  sDate }`;
}
if (sMonth < 10) {
  sMonth = `0${  sMonth }`;
}
const startDate = `${ sYear  }-${  sMonth  }-${  sDate }`;

class DataFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startingDateInput: '',
      endingDateInput: '',
      radioButtonOptions:[ 
        "All tertiles",
        "1st tertile - the lowest points earning group",
        "2nd tertile - the middle points earning group",
        "3rd tertile - the highest points earning group" 
      ],
      radioButtonFinnishOptions:[ 
        "Kaikki",
        "1. kolmannes - vähiten pisteitä ansainnut ryhmä",
        "2. kolmannes - keskimmäinen pisteitä ansainnut ryhmä",
        "3. kolmannes - eniten pisteitä ansainnut ryhmä" 
      ],
      selectedRadioButton:"All tertiles",
      months:[ "Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec" ],
      formatedStartingDate:null,
      formatedEndingDate:null
    };
    this.commonService = new CommonService();

  }

  dataFilterReset = () =>{
    const { parentReset } = this.props;
    const weekNo=8;
    this.setState({
      startingDateInput: '',
      endingDateInput: '',
      formatedStartingDate:null,
      formatedEndingDate:null,
      selectedRadioButton:"All tertiles"
    })
    parentReset(weekNo);
  }

  between8Weeks = () =>{
    const { formatedStartingDate,formatedEndingDate } = this.state;
    const starting = moment(formatedStartingDate, 'YYYY-MM-DD');
    const ending = moment(formatedEndingDate, 'YYYY-MM-DD');
    const diffInDays=ending.diff(starting, 'day');
    if( diffInDays>56 )
    {
      return false;
    }
    return true;
  }

  noOfWeeks=()=>{
    const { formatedStartingDate,formatedEndingDate } = this.state;
    let noOfWeek=0;
    const starting = moment(formatedStartingDate, 'YYYY-MM-DD');
    const ending = moment(formatedEndingDate, 'YYYY-MM-DD');
    const diffInDays=ending.diff(starting, 'day');
    if( diffInDays<=14 )
    {
      noOfWeek=2;
    }
    if( diffInDays>14 && diffInDays<=28 )
    {
      noOfWeek=4;
    }
    if( diffInDays>28 && diffInDays<=42 )
    {
      noOfWeek=6;
    }
    if( diffInDays>42 )
    {
      noOfWeek=8;
    }
    return noOfWeek;
  }

  toolTipView = () =>
    <>
      <div className = "dataTooltipHeader">
        {translator.DataScreen.tertileFilter}
      </div>
      <div className = "dataTooltip1stBody">
        {translator.DataScreen.dataFilterPara1} 
        <br />
        <b>{translator.DataScreen.dataFilterPara2Bold}</b> {translator.DataScreen.dataFilterPara2}
        <br />
        <b>{translator.DataScreen.dataFilterPara3Bold}</b> {translator.DataScreen.dataFilterPara3}
        <br />
        <b>{translator.DataScreen.dataFilterPara4Bold}</b> {translator.DataScreen.dataFilterPara4}
        <br />
        <b>{translator.DataScreen.dataFilterPara5Bold}</b> {translator.DataScreen.dataFilterPara5}
      </div>
    </>

  dataFilterApplyClicked = () =>{
    const { browserLanguage, selectedCostCenter, parentCallback } = this.props;
    const { formatedStartingDate,formatedEndingDate,selectedRadioButton, startingDateInput, endingDateInput } = this.state;
    if( startingDateInput === '' && endingDateInput !== "" || endingDateInput === '' && startingDateInput !== '')
    {
      Toastr.showErrorMessage('Please insert the date');
    }
    else if( startingDateInput > endingDateInput )
    {
      Toastr.showErrorMessage('Starting Date is greater than Ending Date');
    }
    else if(!this.between8Weeks())
    {
      Toastr.showErrorMessage('Starting Date and Ending Date should be between 8 weeks');
    }
    else
    {
      const jsonBody = getJsonBody();
      jsonBody.entityCode = browserLanguage;
      jsonBody.costCenterId = selectedCostCenter.costCenterId;
      jsonBody.userSourceSystemId = '';
      jsonBody.startDate = startingDateInput === '' && endingDateInput ===''? startDate:formatedStartingDate;
      jsonBody.endDate = startingDateInput === '' && endingDateInput ===''? endDate:formatedEndingDate;
      jsonBody.Tertile = selectedRadioButton.includes("All")?"All":selectedRadioButton.includes("1st")?"1":selectedRadioButton.includes("2nd")?"2":"3";
      this.commonService
        .postMethod(apiUrl.dataScreenApi, jsonBody)
        .then((res) => {
          const weeks=this.noOfWeeks();
          if(weeks === 0)
          {
            parentCallback(res,selectedRadioButton, 8);
          }
          else{
            parentCallback(res,selectedRadioButton, weeks);
          }
        })
        .catch(() => {
          Toastr.showErrorMessage('Filter Error');
        });
    }
  }

  // eslint-disable-next-line no-shadow
  dataFormatter = (date,flag) =>{
    const { months } = this.state;
    const temp = String(date._d);
    const day = temp.split(" ")[ 2 ];
    const monthString = temp.split(" ")[ 1 ];
    const year = temp.split(" ")[ 3 ];
    let month = String(months.indexOf(monthString)+1)
    if(Number(month)<10)
    {
      month = `0${ month }`
    }
    if(flag === "Starting")
    {
      this.setState({
        formatedStartingDate:`${ year }-${ month }-${ day }`
      })
    }
    else{
      this.setState({
        formatedEndingDate:`${ year }-${ month }-${ day }`
      })
    }
  }

  render() {
    const {  startingDateInput, endingDateInput,radioButtonOptions,selectedRadioButton, radioButtonFinnishOptions } = this.state;
    const { browserLanguage } = this.props;
    translator.setLanguage(browserLanguage);
    return (
      <>
        <div className = "dataFilter">
          <div className = "dataFilterHeading">{translator.DataScreen.filterByDates}</div>
        </div>
        <div className = "dataCalendarWarpper">
          <DateInput
            id = "startingDateInput"
            isOutsideRange = { (day) => !isInclusivelyBeforeDay(day, moment()) }
            locale = { browserLanguage }
            firstDayOfWeek = { 0 }
            className = "dataStartingCalender"
            label = { translator.LogFilter.startingDate }
            message = ""
            date = {
              startingDateInput === ''
                ? ''
                : moment(startingDateInput).locale(browserLanguage)
            }
            nextMonthButtonAriaLabel = "Move to next month"
            prevMonthButtonAriaLabel = "Move to previous month"
            daySize = { 46.5 }
            onDateChange = { (ele1) => {
              if (ele1 !== null && ele1 !== '') {
                this.setState({ startingDateInput: ele1 });
                this.dataFormatter(ele1,"Starting");
              } else {
                this.setState({});
              }
            } }
          />
          <DateInput
            id = "endingDateInput"
            isOutsideRange = { (day) => !isInclusivelyBeforeDay(day, moment()) }
            firstDayOfWeek = { 0 }
            className = "dataEndingCalender"
            label = { translator.LogFilter.endingDate }
            locale = { browserLanguage }
            message = ""
            date = {
              endingDateInput === ''
                ? ''
                : moment(endingDateInput).locale(browserLanguage)
            }
            nextMonthButtonAriaLabel = "Move to next month"
            prevMonthButtonAriaLabel = "Move to previous month"
            daySize = { 46.5 }
            onDateChange = { (ele) => {
              if (ele !== null && ele !== '') {
                this.setState({
                  endingDateInput: ele,
                });
                this.dataFormatter(ele,"Ending");
              } else {
                this.setState({});
              }
            } }
          />
        </div>
        <div className = "dataFilterPointsHeading">
          <div>{translator.DataScreen.filterByTertiles}</div>
          <div>
            <Tooltip 
              title = { this.toolTipView() }
              placement = "bottom-end"
              id = "postiDataTooltip"
            >
              <img src = { require("../../images/Subtract.png") } alt = "NoImg" style = { {
                cursor:"pointer"
              } }
              />
            </Tooltip >
          </div>
        </div>
        <div className = "dataFilterPointsBody">
          { radioButtonOptions.map((ele,index) =>
            <div className = "dataFilterPointsRadioContainer">
              <Radio
                size = "small"
                labelPosition = "right"
                label = { browserLanguage === "en"?ele: radioButtonFinnishOptions[ index ] }
                value = { ele }
                onChange = { (e) => this.setState({
                  selectedRadioButton: e.target.value,
                })
                }
                checked = {
                  ele === selectedRadioButton
                }
              /> 
            </div>
          )}
        </div>
        <div className = "parabolaImageWrapper">
          <img src = { selectedRadioButton.includes("All")?require("../../images/ParabolaFull.png"):
            selectedRadioButton.includes("1st")?require("../../images/ParabolaFirst.png"):
              selectedRadioButton.includes("2nd")?require("../../images/ParabolaMiddle.png"):
                require("../../images/ParabolaLast.png")
          } alt = "Notfound" className = "parabolaCss" 
          useMap = "#gfg_map"
          />
          <map name = "gfg_map">
            <area shape = "rect" coords = "0,0,195,250" alt = "GFG1"
              aria-hidden = "true"
              onClick = { ()=> this.setState({
                selectedRadioButton:"1st tertile - the lowest points earning group"
              }) }
              className = "mapAreaCss"
            />
            <area shape = "rect" coords = "196,0,315,250" alt = "GFG2"
              aria-hidden = "true"
              onClick = { ()=> this.setState({
                selectedRadioButton:"2nd tertile - the middle points earning group"
              }) }
              className = "mapAreaCss"
            />
            <area shape = "rect" coords = "315,0,500,250" alt = "GFG3"
              aria-hidden = "true"
              onClick = { ()=> this.setState({
                selectedRadioButton:"3rd tertile - the highest points earning group"
              }) }
              className = "mapAreaCss"
            />
          </map>
          < div className = "parabolaImageHeading">
            <div>
              {translator.DataScreen.lowPts}
            </div>
            <div>
              {translator.DataScreen.highPts}
            </div>
          </div>
        </div>
        <div className = "dataFilterFooter">
          <div
            style = { {
              border: '1px solid #C2C9D1',
              backgroundColor: '#C2C9D1',
            } }
          />
          <div className = "dataFilterFooterBody">
            <button className = "dataFilterResetButton" type = "button" onClick = { this.dataFilterReset }>
              {translator.DataScreen.reset}
            </button>
            <Button
              className = "dataFilterApplyButton"
              style = { {
                height: '39px',
              } }
              onClick = {()=>{this.dataFilterApplyClicked(), this.props.parentLoader(true)} }
            >
              <div
                style = { {
                  fontSize: '16px',
                } }
              >
                {translator.DataScreen.apply}
              </div>
            </Button>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(DataFilter);
