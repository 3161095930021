/* eslint-disable react/jsx-no-useless-fragment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import { Button } from '@postidigital/posti-components';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../../locales/translation.js';
import './Champs.css';
import Avatar from '../../../components/Avatar/Avatar.js';
// import { CommonService } from '../../../service/CommonService';

const translator = new LocalizedStrings(translation);

class Champs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // this.commonService = new CommonService();
  }

  componentDidMount() {};
  
  DeleteTeam = () => {
    const { OnDelete } = this.props
    OnDelete();
  };

  render() {
    const { browserLanguage ,informationTextFlag, TeamDetails, OnHide, OnEdit }= this.props;
    translator.setLanguage(browserLanguage);
    return (
      <>
        {TeamDetails && (
          <Dialog
            id = "champs-dialog"
            visible
            style = { { width: '40vw', height: '90vh' } }
            onHide = { () => OnHide() }
          >
            <div style = { { marginBottom: '15vh' } }>
              <div className = "teamNameContainer">
                <div className = "champs-heading">{TeamDetails.Name}</div>

                {TeamDetails.TeamImage && TeamDetails.TeamImage !== null ? (
                  <img alt = "" id = "newteam-avatar" src = { TeamDetails.TeamImage } />
                ) : (
                  <div id = "newteam-avatar" />
                )}
              </div>
              <div style = { { padding: '32px 0px 0px 32px' } }>
                <div className = "teamInfo">
                  {informationTextFlag
                    ? translator.ChampsScreen.teamInformation
                    : translator.ChampsScreen.information}
                </div>
                <table style = { { width: '80%', marginTop: '19px' } }>
                  <tbody>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.teamName}</td>
                      <td className = "table-info">{TeamDetails.Name}</td>
                    </tr>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.formed}</td>
                      <td className = "table-info">{TeamDetails.joiningDate}</td>
                    </tr>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.members}</td>
                      <td className = "table-info">{TeamDetails.Details.length}</td>
                    </tr>
                    <tr>
                      <td className = "table-header">{translator.ChampsScreen.pointsTotal}</td>
                      <td className = "table-info">{TeamDetails.PointsTotal} pts</td>
                    </tr>
                  </tbody>
                </table>
                <div className = "teamMembers">{translator.ChampsScreen.teamMembers}</div>
                {TeamDetails &&
                  TeamDetails.Details.map((data) => (
                    <div key = { data.index } style = { { marginTop: '18px', overflowY: 'auto' } }>
                      <div
                        style = { {
                          display: 'flex',
                          alignItems: 'center',
                        } }
                      >
                        <Avatar
                          imageSource = { data.userImagePath }
                          name = { data.firstName }
                          avatarColor = "#FFA894"
                          size = { 46 }
                          fontSize = "15px"
                        />
                        <div className = "memberName">{data.firstName}</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {/* Footer Section */}
            <div className = "teamDescFooterContainer" id = "champs-buttons">
              <div style = { { border: '1px solid #C2C9D1' } } />
              <div
                style = { {
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '10vh',
                } }
              >
                <button
                  type = "submit"
                  className = "cancel-button"
                  style = { { color: '#BD2841' } }
                  onClick = { () => {
                    this.DeleteTeam();
                  } }
                >
                  {translator.ChampsScreen.delete}
                </button>
                <div className = "teamDescButtonContainer">
                  <button type = "submit" onClick = { OnEdit } className = "cancel-button">
                    {translator.ChampsScreen.editDetails}
                  </button>
                  <Button
                    id = "team-nextButton"
                    size = "sm"
                    onClick = { () => {
                      OnHide();
                    } }
                  >
                    {translator.ChampsScreen.close}
                  </Button>
                </div>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Champs);
