import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { translation } from '../../locales/translation.js';
import Avatar from '../../components/Avatar/Avatar.js';
import './Greetings.css';
import Message from './Message.js';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody, isRolePEC } from '../../utility/commonUtility.js';
import apiUrl from '../../urlConstants.js';

const translator = new LocalizedStrings(translation);
class Greetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgData:null
    };
    this.commonService = new CommonService();
  }

  componentDidMount(){
    setTimeout(() => {
      this.getMessages()
    }, 3500);
  }

  getMessages = () =>{
    const token = localStorage.getItem('adal.idtoken');
    const decoded = jwtDecode(token);
    const { browserLanguage, selectedCostCenter } = this.props;
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.applicationId = getJsonBody().applicationId;
    jsonBody.userSourceSystemId = '';
    jsonBody.isSuperAdmin = decoded.roles[ 0 ] == "PEC.ADMIN.GAMIFICATION" ? "Y" : "N";
    const data = {
      basicParameters: {
        ...jsonBody,
      },
      category: 'I',
    };
    this.commonService
      .postMethod(apiUrl.getSentArchiveMessages, data)
      .then((res) => {
        this.setState({
          msgData:res.data
        })
      })
      .catch(() => {
      });
  }

  render() {
    const { browserLanguage  }= this.props;
    translator.setLanguage(browserLanguage);
    const { greetingData } = this.props;
    const { msgData } = this.state;
    let bodyText = '';
    if (greetingData) {
      // eslint-disable-next-line no-unused-expressions
      browserLanguage !== 'fi'
        ? (bodyText = greetingData.messageBody)
        : (bodyText = greetingData.messageBody_Fi);
    }
    return (
      <>
        <div className = "recruitmentHintsHeading" style = { { position: 'fixed', width: '27.5%' } }>
          {translator.HomePage.greetingHeader}
          {greetingData && (
            <div
              style = { {
                position: 'relative',
                top: '-33px',
                left: 'calc(100% - 96px)',
              } }
            >
              <Avatar
                imageSource = { greetingData.userImage }
                name = ""
                avatarColor = "#FFA894"
                size = { 66 }
                fontSize = "24px"
              />
            </div>
          )}
        </div>
        { isRolePEC() && 
        <div className = "msgHeadLine">
          {translator.messageScreen.messages}
        </div>
        }
        { msgData!==null? isRolePEC() && <Message dataFromParent = { msgData } />:null}
        {greetingData && (
          <>
            <div className = "greetingHeadLine">
              {translator.HomePage.greetingHeader}
            </div>
            <div
              style = { {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: isRolePEC() ? '25px 24px 18px 24px' : '80px 24px 18px 24px',
              } }
            >
              <div className = "greetingHeader">
                {browserLanguage !== 'fi'
                  ? greetingData.messageTitle
                  : greetingData.messageTitle_Fi}
              </div>
              <div className = "greetingDate">
                {moment(greetingData.userDateMessage)
                  .locale(browserLanguage)
                  .format('ddd DD.M.YYYY')}
              </div>
            </div>
            <div className = "greetingsText">{ReactHtmlParser(bodyText)}</div>
          </>
        )}

        <div className = "recruitmentHintsFooter">
          <div style = { { border: '1px solid #C2C9D1' } } />
          <div
            style = { {
              height: '8vh',
            } }
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Greetings);
