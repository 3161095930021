import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
// import { Button } from '@postidigital/posti-components';
import { translation } from '../../locales/translation';
import './OrganisedBadge.css';

const translator = new LocalizedStrings(translation);
class OrganisedBadge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      variables:[ "days","slack","daysPlusSlack","target","points" ],
      variablesLocal:[ "daysLocal","slackLocal","daysPlusSlackLocal","targetLocal","pointsLocal" ],
      stats:[ "target","passingRate","winnings","pointsEarned" ],
      information:[ "type","level","consisting","notes", "volumeRange", "dataSource" ],
      informationFi:[ "typeFi","levelFi","consistingFi","notesFi", "volumeRange", "dataSource" ],
      informationArray:[ "typeFi","levelFi","consistingFi","notesFi", "volumeRange", "dataSource" ]
    }
  }

  componentDidMount(){
    const { browserLanguage } = this.props;
    const { information,informationFi } = this.state;
    if( browserLanguage === "en")
    {
      this.setState({
        informationArray:information
      })
    }
    else{
      this.setState({
        informationArray:informationFi
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { browserLanguage } = this.props;
    const { information,informationFi } = this.state;

    if( prevProps.browserLanguage !==  browserLanguage )
    {
      if( browserLanguage === "en")
      {
        this.setState({
          informationArray:information
        })
      }
      else{
        this.setState({
          informationArray:informationFi
        })
      }
    }
  }

  formatRule = ( rowData ) =>{
    const { data } = this.props;
    let temp = rowData.replace("{target}",data.target);
    temp =  temp.replace("{day}",data.days);
    temp =  temp.replace("{day+slack}",Number(data.days)+Number(data.slack));
    return temp;
  }

  // checkChangeInVariableData = () =>{
  //   const { data } = this.props;
  //   if(data.badgeDetails.variables.daysLocal === null && data.badgeDetails.variables.slackLocal === null && data.badgeDetails.variables.daysPlusSlackLocal === null && data.badgeDetails.variables.targetLocal === null && data.badgeDetails.variables.pointsLocal === null)
  //   {
  //     return false;
  //   }
  //   return true;
  // }

  textTransformation = (data) => {
    let finalString;
    finalString = data.replace("or above", "tai enemmän");
    finalString = finalString.replace(/pts/g, "p.");
    if(finalString.split(" p.").length > 1)
    {
      if(finalString.split(" p.").length === 2)
      {
        return( ` ${ finalString.split(" p.")[ 0 ] }.p`)
      }
      return ( ` ${ finalString.split(" p.")[ 0 ] }.p${ finalString.split(" p.")[ 1 ] }.p` )
    }
    return finalString;
  }
  
  render() {
    const { data,browserLanguage, selectedCostCenter } = this.props;
    const { variables, variablesLocal, stats, informationArray } = this.state;
    translator.setLanguage(browserLanguage);
    return (
      <div className = "organizedBadgeWrapper">
        <div className = "organizedBadgeRuleHeading">
          { translator.BadgesScreen.rule }
        </div>
        <div className = "organizedBadgeRuleBody">
          { this.formatRule(browserLanguage==="en"?data.badgeDetails.rule:data.badgeDetails.ruleFi) } 
        </div>
        <div className = "organizedBadgeInformationHeading">
          { translator.BadgesScreen.information }
        </div>
        <div className = "organizedBadgeInformationBody">
          { informationArray.map((rowData) =>
          { if( data.badgeDetails.information[ rowData ] === "" || data.badgeDetails.information[ rowData ] === null)
          {
            return null;
          }
          if(rowData!=="consisting" && rowData !=="consistingFi" && rowData!=="notes" && rowData !=="notesFi")
          {
            return(
              <div className = "organizedBadgeInformationDayBody">
                <div className = "organizedBadgeInformationDay1stColumn">
                  {  translator.BadgesScreenInformation[ rowData ] }
                </div>
                <div className = "organizedBadgeInformationDay2ndColumn">
                  { data.badgeDetails.information[ rowData ] }
                </div>
              </div>
            )
          }
          return (data.badgeDetails.information[ rowData ] && data.badgeDetails.information[ rowData ].map((ele,index)=>
          {
            if(ele === "" || ele === null)
            {
              return null;
            }
            return(
              <div className = "organizedBadgeInformationDayBody">
                <div className = "organizedBadgeInformationDay1stColumn">
                  {  index===0?translator.BadgesScreenInformation[ rowData ] :null }
                </div>
                <div className = "organizedBadgeInformationDay2ndColumn">
                  { browserLanguage==="en"? ele : data.badgeDetails.information[ rowData ][ index ] }
                </div>
              </div>
            )
          }
          ))
          }
          )}
        </div>
        <div className = "organizedBadgeVariablesHeading">
          { translator.BadgesScreen.variables }
        </div>
        <div className = "organizedBadgeVariablesBody">
          { variables.map((rowData,index) =>
            <div className = "organizedBadgeVariablesDayBody">
              <div className = "organizedBadgeVariablesDay1stColumn">
                {  translator.BadgesScreen[ rowData ] }
              </div>
              <div className = "organizedBadgeVariablesDay2ndColumn">
                { data.badgeDetails.variables[ variablesLocal[ index ] ] === null?browserLanguage==="en"?data.badgeDetails.variables[ rowData ]:this.textTransformation(data.badgeDetails.variables[ rowData ]):
                  <div style = { { display:"flex" } }>
                    <div>
                      { browserLanguage==="en"?data.badgeDetails.variables[ variablesLocal[ index ] ]:this.textTransformation(data.badgeDetails.variables[ variablesLocal[ index ] ]) }
                    </div>
                    <div className = "lineAboveTextVariable">
                      { browserLanguage==="en"?data.badgeDetails.variables[ rowData ]:this.textTransformation(data.badgeDetails.variables[ rowData ]) }
                    </div>
                  </div>}
              </div>
            </div>
          )}
          {/* { this.checkChangeInVariableData() ? <div className = "astrikStyle">*: { translator.BadgesScreen.localValue } (<div className = "lineAboveText">{ translator.BadgesScreen.defaultValue }</div>)</div>
            : <div className = "astrikStyle">*: { translator.BadgesScreen.defaultValueC }</div>} */}
        </div>
        <div className = "organizedBadgeStatisticHeading">
          { `${ translator.BadgesScreen.statistics } - ${ translator.BadgesScreen.allTerminals } / ${ selectedCostCenter.costCenterName }` }
        </div>
        <div className = "organizedBadgeStatsBody">
          { stats.map((rowData) =>
            <div className = "organizedBadgeStatsDayBody">
              <div className = "organizedBadgeStatsDay1stColumn">
                {  translator.BadgesScreenStats[ rowData ] }
              </div>
              <div className = "organizedBadgeStatsDay2ndColumn">
                { browserLanguage==="en"? data.badgeDetails.statistics[ rowData ] : this.textTransformation(data.badgeDetails.statistics[ rowData ].replace(/pcs/g,"kpl")) }
              </div>
            </div>
          )}
        </div>
        <div className = "filterFooterBadges">
          <div
            style = { {
              border: '1px solid #C2C9D1',
              backgroundColor: '#C2C9D1',
              marginLeft:'-30px',
              marginRight:'20px'
            } }
          />
          <div className = "filterFooterBodyBadges">
            {/* <Button
              className = "badgesFilterApplyButton"
            >
              Print
            </Button> */}
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => 
  ({
    browserLanguage: state.browserLanguage,
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisedBadge);
