import React, { Component } from 'react';
import { connect } from 'react-redux';
import LocalizedStrings from 'react-localization';
import moment from 'moment';
import { isInclusivelyBeforeDay } from 'react-dates';
import { DateInput } from '@postidigital/posti-components/build/datepicker';
import { Dropdown, DropdownMenuItem, Button, Dialog, Loading } from '@postidigital/posti-components';
import apiUrl from '../../urlConstants';
import { CommonService } from '../../service/CommonService';
import Toastr from '../../shared/Toastr';
import { getAppId , getEmailIdFromToken } from '../../utility/commonUtility';
import { translation } from '../../locales/translation.js';

const translator = new LocalizedStrings(translation);

export class PeopleEdit extends Component {
  constructor(props) {
    super(props);
    const { selectedEmployeeData } = this.props;
    this.state = {
      date: moment(selectedEmployeeData.consentDate).format('YYYY-MM-DD'),
      error: false,
      TeamsInDepo: [],
      isOpen: false,
      selectedTeam: selectedEmployeeData.groupName,
      selectedTeamImage: null,
      isLoading: false,
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    const { selectedCostCenter } = this.props;
    const applicationId = getAppId();
    const Tokenno = applicationId === 5 ? '19NBCNWHRE' : 'HHF1Y4UJ5N'; 
    const jsonBody = {
      ApplicationId: applicationId,
      TokenNo: Tokenno,
      CostCenterId: selectedCostCenter.costCenterId,
    };
    this.getTeamsInACostCenter(jsonBody);
  }

  getTeamsInACostCenter = (body) => {
    this.setState({ isLoading: true });
    this.commonService
      .postMethod(apiUrl.getTeamsInACostCenter, body)
      .then((res) => {
        this.setState({ TeamsInDepo: res.data, isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        Toastr.showErrorMessage(translator.ToastMessages.fetchTeamsFailed);
      });
  };

  editableSection = () => {
    const { browserLanguage }= this.props;
    const { date , error }= this.state;
    translator.setLanguage(browserLanguage);

    return (
      <div>
        <div className = "PeopleDetailHeaderRightContainer" style = { { position: 'relative' } }>
          <div className = "team-heading" style = { { paddingTop: '30.49px', marginBottom: '7px' } }>
            {translator.PeopleDetails.informationText}
          </div>
          <div className = "tabMarking" />
        </div>
        <div
          style = { {
            height: '62vh',
            overflowY: 'auto',
            marginLeft: '32px',
          } }
        >
          <div className = "informationText" style = { { marginBottom: '18px' } }>
            {translator.PeopleDetails.postiStartDate}
          </div>
          <div style = { { marginRight: '32px' } }>
            <DateInput
              prevMonthButtonAriaLabel = "previous Month"
              nextMonthButtonAriaLabel = "nextMonth"
              locale = { browserLanguage }
              date = { moment(date).locale(browserLanguage) }
              displayFormat = "D.M.YYYY"
              isOutsideRange = { (day) => !isInclusivelyBeforeDay(day, moment()) }
              onChange = { () => {} }
              isInvalid = { error }
              onDateChange = { (data) => {
                if (data !== null && data !== '') {
                  this.setState({ date: data, error: false });
                } else {
                  this.setState({ error: true });
                }
              } }
            />
          </div>

          <div>
            <div className = "informationText" style = { { marginBottom: '18px' } }>
              {translator.PeopleDetails.team}
            </div>
            {this.dropDownComponent()}
          </div>
        </div>
        <div
          style = { {
            border: '1px solid #c2c9d1',
            marginLeft: '32px',
            marginRight: '32px',
          } }
        />
        <div>{this.buttonSection()}</div>
      </div>
    );
  };

  dropDownComponent = () => {
    const { TeamsInDepo , isOpen , selectedTeam } = this.state;
    return (
      <div style = { { marginRight: '32px' } }>
        <Dropdown
          id = "TeamDropdown"
          menuAriaLabel = "Team_Label "
          disabled = { !(TeamsInDepo.length > 0) }
          iconColor = "#73808C"
          isOpen = { isOpen }
          onToggle = { () => this.setState({ isOpen: !isOpen }) }
          onSelect = { (e) => {
            this.setState({
              selectedTeam: e.teamName,
              selectedTeamImage: e.teamImage,
            });
          } }
          title = { selectedTeam === '' ? 'Select a Team' : selectedTeam }
          label = { TeamsInDepo.length > 0 ? '' : 'No Teams available' }
        >
          {TeamsInDepo.map((res) => (
            <DropdownMenuItem
              id = "teamdropdownmenu"
              aria-label = "Team Dropdown item"
              key = { res.index }
              allowWrap
              tabIndex = { -1 }
              value = { res }
              selected = { selectedTeam === res.name }
            >
              {res.teamName}
            </DropdownMenuItem>
          ))}
          {/* </div> */}
        </Dropdown>
      </div>
    );
  };

  buttonSection = () => {
    const { error }= this.state;
    return (
      <div
        style = { {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginRight: '32px',
          marginTop: '10px',
          marginBottom: '10px',
        } }
      >
        <button
          type = "submit"
          id = "peopleEditButton"
          onClick = { () => {
            this.resetValues();
          } }
        >
          {translator.PeopleDetails.cancel}
        </button>
        <Button
          id = "peopleEdit-nextButton"
          disabled = { error }
          size = "sm"
          onClick = { () => {
            this.onEmployeeeEdit();
          } }
        >
          {translator.NewTeamScreen.saveText}
        </Button>
      </div>
    );
  };

  onEmployeeeEdit = () => {
    const { selectedCostCenter , selectedEmployeeData, OnSave, closeTransition }= this.props;
    const { selectedTeam , selectedTeamImage, date }= this.state;
    const emailId = getEmailIdFromToken(localStorage.getItem('adal.idtoken'));
    const applicationId = getAppId(); 
    const jsonBody = {
      ApplicationId: applicationId,
      masterGroupSourceSystemId: emailId,
      costCenterId: selectedCostCenter.costCenterId,
      userSourceSystemId: selectedEmployeeData.userSourceSystemId,
      teamName: selectedTeam,
      startDate: `${ moment(date).format('YYYY-MM-DD') }`,
    };
    this.commonService
      .postMethod(apiUrl.UpdateUserDetails, jsonBody)
      .then((res) => {
        if (res.data === true) {
          const data = {
            teamName: selectedTeam,
            startDate: moment(date).format('DD.MM.YYYY'),
            teamImage: selectedTeamImage,
          };
          Toastr.showSuccessMessage(translator.PeopleDetails.changesSaved);
          OnSave(data);
          closeTransition();
        }
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.ToastMessages.updateDetailsFailed);
      });
  };

  resetValues = () => {
    const { closeTransition } = this.props;
    closeTransition();
  };

  render() {
    const { browserLanguage }= this.props;
    const { isLoading }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      <>
        {isLoading && (
          <Dialog id = "spinner" onClose = { () => {} } noCloseButton>
            <Loading preset = "brandPink" size = "xl" />
          </Dialog>
        )}
        <div style = { { display: 'flex', FlexDirection: 'row', height: '100%' } }>
          <div style = { { width: '3.4%', backgroundColor: '#F6F7F9' } } />
          <div style = { { width: '96.6%' } }>{this.editableSection()}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
  selectedCostCenter: state.selectedCostCenter,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleEdit);
