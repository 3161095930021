/* eslint-disable max-lines */
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import LocalizedStrings from 'react-localization';
import React, { Component } from 'react';import {
  Button,
  Tab,
  Tabs,
  TabContext,
  TabPanel,
  Checkbox,
  Search,
  Loading,
} from '@postidigital/posti-components';
import Variable from "./Variable";
import './BadgeSelection.css';
import NewConfiguration from './NewConfiguration';
import DateScreen from './Date';
import Toastr from '../../shared/Toastr';
import { getJsonBody } from '../../utility/commonUtility.js';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService';
import apiUrl from '../../urlConstants';
import Location from './Location';

const translator = new LocalizedStrings(translation);
class BadgeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading2: true,
      loading1: null,
      oldValue: [],
      tabValue: 1,
      searchText: null,
      originalBadges: null,
      copyBadges: null,
      newConifFlag: false,
      appliedLocallyCheckFlag: false,
      name: [],
      locationData: [],
      selectedBadgeData: [],
      datefin: '',
      datefin2: '',
      fetchLocationData: [],
      allTarget: {
        heading: '',
        targetTime: '',
        targetSlack: '',
        targetValue: '',
        targetPoints: '',
      },
      allTargetList: [
        {
          heading: '',
          targetTime: '',
          targetSlack: '',
          targetValue: '',
          targetPoints: '',
        },
        {
          heading: '',
          targetTime: '',
          targetSlack: '',
          targetValue: '',
          targetPoints: '',
        },
      ],
    };
    this.commonService = new CommonService();
  }

  componentDidMount() {
    const { browserLanguage,frontApiData } = this.props;
    translator.setLanguage(browserLanguage);
    this.setState({
      originalBadges: frontApiData,
      copyBadges: frontApiData,
    });
    setTimeout(() => {
      this.setState({
        loading2: false,
      });
    }, 500);
  }

  handleLocationData = (data) => {
    this.setState({ locationData: [ ...data ] });
  };
  
  handleAPI = () => {
    const { getLocationsData,headerButton,getBadgesData,onCancel } = this.props;
    const { allTargetList,selectedBadgeData,locationData } = this.state;
    allTargetList.pop();
    const badgeapi = allTargetList.map((item, key) => {
      if (key === 0) return null;
      return {
        id: 0,
        targetTimeVariable: Number(item.targetTime),
        slackVariable: Number(item.targetSlack),
        targetValueVariable: item.targetValue,
        pointsVariable: Number(item.targetPoints),
        badgeName: selectedBadgeData[ key - 1 ].heading,
      };
    });
    badgeapi.splice(0, 1);
    const varapi = {
      requestType: 'Create',
      startingDate: moment(this.datefin).format('YYYY-MM-DD'),
      publishingDate: moment(this.datefin2).format('YYYY-MM-DD'),
      applicability: locationData.map((item) => item.costCenterId),
      applicationId: getJsonBody().applicationId,
      badges: [
        ...badgeapi,
      ],
    };
    this.commonService
      .postMethod(apiUrl.newconfiguration, varapi)
      .then(() => {
        Toastr.showSuccessMessage(`${ translator.toasterNotification.configCreated }`);
        if (headerButton === 'Badges' || headerButton === 'Merkit') {
          getBadgesData();
        } else {
          getLocationsData();
        }
        onCancel();
      })
      .catch(() => {
        Toastr.showErrorMessage('Creating error');
      });
  };

  handleVariableChange = (data, inputname, currentPageNumber) => {
    const { allTargetList } = this.state;
    allTargetList[ currentPageNumber ][ inputname ] = data.target.value;
    this.setState({ allTargetList });
    this.handleVariable(allTargetList);
  };

  handleBadgeSelection(data) {
    const { selectedBadgeData } = this.state;
    const currentData = {
      heading: data.badge,
      headingFi: data.badgeFi,
      name: data.badgeCategory,
      url: data.iconURL,
      slack: data.slack,
      target: data.target,
      points: data.points,
      days: data.days,
    };
    const checkSelectedBadge = selectedBadgeData.findIndex(
      (data1) => data1.heading === data.badge
    );

    let { allTargetList, oldValue } = this.state;
    const { allTarget } = this.state;

    if (checkSelectedBadge < 0) {
      const cloneAllTarget = { ...allTarget };
      cloneAllTarget.heading = currentData.heading;
      cloneAllTarget.targetPoints = currentData.points;
      cloneAllTarget.targetSlack = currentData.slack;
      cloneAllTarget.targetTime = currentData.days;
      cloneAllTarget.targetValue = currentData.target;
      selectedBadgeData.push(currentData);
      allTargetList.splice(allTargetList.length - 1, 0, { ...cloneAllTarget });
      allTargetList.splice(allTargetList.length, 0, { ...allTarget });
      allTargetList.splice(selectedBadgeData.length + 1, 1);
      this.handleVariable(allTargetList);
    } else {
      selectedBadgeData.splice(checkSelectedBadge, 1);
      allTargetList = allTargetList.filter((item) => item.heading !== data.badge);
    }
    oldValue = JSON.parse(JSON.stringify(allTargetList));
    this.setState({
      selectedBadgeData: [ ...selectedBadgeData ],
      allTargetList,
      oldValue,
    });
  }

  onHandle = () => {
    this.setState({ newConifFlag: true });
  };

  nextButton = () => {
    const { tabValue } = this.state;
    this.setState({ tabValue: tabValue + 1 });
  };

  backButton = () => {
    const { tabValue } = this.state;
    this.setState({ tabValue: tabValue - 1 });
  };

  badgeSelectionSearch = (e) => {
    const { originalBadges } = this.state;
    if (e) {
      const badgeFilter = originalBadges.filter((value) => value.badge.toLowerCase().includes(e.toLowerCase()));
      this.setState({
        copyBadges: badgeFilter,
      });
    } else {
      this.setState({
        copyBadges: originalBadges,
      });
    }
  };

  fetchLocation = () => {
    const { browserLanguage,selectedCostCenter } = this.props;
    const { selectedBadgeData } = this.state;
    this.setState({ loading1: true });
    const jsonBody = getJsonBody();
    jsonBody.entityCode = browserLanguage;
    jsonBody.costCenterId = selectedCostCenter.costCenterId;
    jsonBody.badges = selectedBadgeData.map((data) => data.heading);
    this.commonService
      .postMethod(apiUrl.fetchLocation, jsonBody)
      .then((res) => {
        this.setState({
          fetchLocationData: res.data,
          loading1: false,
        });
      })
      .catch(() => {
        this.setState({
          loading1: false,
        });
        Toastr.showErrorMessage('Location API Error');
      });
  };

  tab1 = () => {
    const { browserLanguage,onCancel } = this.props;
    const { searchText,copyBadges,appliedLocallyCheckFlag,selectedBadgeData } = this.state;
    return(
      <>
        <div style = { { paddingTop: '33.5px', paddingLeft: '30.5px' } }>
          <div className = "tabHeading">{translator.NewConfiguration.header1}</div>
          <div>
            <Search
              className = "badgeSelectionSearchBar"
              aria-label = "Search"
              id = "badgeSelectionSearch"
              label = { translator.RewardsScreen.searchText1 }
              value = { searchText }
              onChange = { (e) => {
                this.badgeSelectionSearch(e.target.value);
                this.setState({
                  searchText: e.target.value,
                });
              } }
            />
          </div>
          <div className = "check" style = { { paddingBottom: '8vh' } }>
            {copyBadges !== null
              ? copyBadges.map((data) =>
                <div
                  style = { {
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '20px',
                    alignItems: 'center',
                  } }
                >
                  {data.badge == "Freight" || data.badge == "Line-haul" || data.badgeFi == "Nouto- ja jakelukuljetus" || data.badgeFi == "Runkokuljetus" ? null : 
                    <>
                      <Checkbox
                        size = "small"
                        onChange = { () => {
                          this.handleBadgeSelection(data);
                          this.setState({
                            appliedLocallyCheckFlag: !appliedLocallyCheckFlag,
                          });
                        } }
                        checked = { selectedBadgeData.find(
                          (datas) => datas.heading === data.badge
                        ) }
                      />
                      <img className = "badgeImage" src = { data.iconURL } alt = "" />
                      <div>
                        {browserLanguage === 'en' ? (
                          <>
                            <div className = "badgeName">{data.badge}</div>
                            <div className = "badgeDesc">{data.badgeCategory}</div>{' '}
                          </>
                        ) : (
                          <>
                            <div className = "badgeName">{data.badgeFi}</div>
                            <div className = "badgeDesc">{data.badgeCategoryFi}</div>
                          </>
                        )}
                      </div>
                    </>
                  }
                </div>
              )
              : null}
          </div>
        </div>

        <div
          style = { {
            position: 'absolute',
            bottom: '70px',
            width: '364px',
          } }
        >
          <div
            style = { {
              border: '1px solid #C2C9D1',
              margin: '0px 20px 0px 20px',
              width: '361px',
            } }
          />
        </div>

        <div className = "badgeSelectionFooterBody">
          <button className = "badgeSelectionCancelButton" onClick = { () => onCancel() } type = "button" >
            {translator.logOut.cancel}
          </button>
          <Button
            className = "badgeSelectionNextButton"
            onClick = { () => {
              this.nextButton();
              this.fetchLocation();
            } }
          >
            {translator.EditDialog.next}
          </Button>
        </div>
      </>
    )
  }

  handleVariable = (childData) => {
    this.setState({ name: [ ...childData ] });
  };

  getDatefunc = (data) => {
    this.setState({ datefin: moment(data).format('DD.MM.YYYY') });
  };

  getDatefunc2 = (data) => {
    this.setState({ datefin2: moment(data).format('DD.MM.YYYY') });
  };

  render() {
    const { onCancel,frontApiData } = this.props;
    const { loading1,allTargetList,newConifFlag,name,selectedBadgeData,fetchLocationData,locationData,datefin,tabValue,datefin2,oldValue,loading2 } = this.state;
    return (
      <>
        <Dialog visible = { loading1 } id = "loader">
          <Loading preset = "brandPink" size = "xl" />
        </Dialog>
        {newConifFlag ? (
          <NewConfiguration
            allTargetValue = { name }
            selectedBadgeData = { selectedBadgeData }
            cancelButtonPass = { onCancel }
            locationData = { locationData }
            handleAPI = { this.handleAPI }
            datefin = { datefin }
            datefin2 = { datefin2 }
            oldValue = { oldValue }
          />
        ) : (
          <Dialog
            resizable = { false }
            showHeader = { false }
            visible
            id = "badgeSelectionDialog"
            style = { { width: '1005px', height: '820px' } }
          >
            <Dialog visible = { loading2 } id = "loader">
              <Loading preset = "brandPink" size = "xl" />
            </Dialog>
            <div className = "badgeSelectionWrapper">
              <div className = "leftSideBadgeSelection">
                <div className = "leftSideBadgeSelectionHeader">
                  {translator.NewConfiguration.header}
                </div>
                <div className = "leftSideBadgeSelectionContainer">
                  <p className = "badgeSelectionText">
                    {translator.NewConfiguration.createnewconfig}
                  </p>
                  <p className = "badgeSelectionPara">
                    {translator.NewConfiguration.ncdesc1}
                    <br />
                    <br />
                    {translator.NewConfiguration.ncdesc2}
                    <br />
                    {translator.NewConfiguration.ncdesc3}
                    <br />
                    {translator.NewConfiguration.ncdesc4}
                    <br />
                    {translator.NewConfiguration.ncdesc5}
                    <br />
                    {translator.NewConfiguration.ncdesc6}
                    <br />
                    <br />
                    {translator.NewConfiguration.ncdesc7}
                  </p>
                  <div
                    style = { {
                      position: 'absolute',
                      bottom: '69px',
                      width: '500px',
                    } }
                  >
                    <div
                      style = { {
                        border: '1px solid #C2C9D1',
                        margin: '0px 32px 0px 32px',
                        width: '498.82px',
                      } }
                    />
                  </div>
                </div>
              </div>
              <div className = "centerBadgeSelection" />
              <div className = "rightSideBadgeSelection" id = "hello">
                <TabContext>
                  <div className = "rightSideBadgeSelectionHeader">
                    <Tabs
                      id = "tabStyle1"
                      selectedTab = { tabValue }
                      onChange = { (e) => {
                        this.setState({ tabValue: e });
                      } }
                    >
                      <Tab
                        id = {
                          tabValue === 1
                            ? 'badgeSelectionTabActive'
                            : 'badgeSelectionTabInActive'
                        }
                        value = { 1 }
                        panelId = "badgeId-tab1"
                        className = "badgeSelection-tabStyle"
                        onClick = { () => this.setState({ tabValue: 1 }) }
                      >
                        1.{translator.NewConfiguration.badge}
                      </Tab>
                      <Tab
                        id = {
                          tabValue === 2
                            ? 'badgeSelectionTabActive'
                            : 'badgeSelectionTabInActive'
                        }
                        value = { 2 }
                        panelId = "badgeId-tab2"
                        className = "badgeSelection-tabStyle"
                        onClick = { () => this.setState({ tabValue: 2 }) }
                      >
                        2.{translator.NewConfiguration.variable}
                      </Tab>
                      <Tab
                        id = {
                          tabValue === 3
                            ? 'badgeSelectionTabActive'
                            : 'badgeSelectionTabInActive'
                        }
                        value = { 3 }
                        panelId = "badgeId-tab3"
                        className = "badgeSelection-tabStyle"
                        onClick = { () => this.setState({ tabValue: 3 }) }
                      >
                        3.{translator.NewConfiguration.location}
                      </Tab>
                      <Tab
                        id = {
                          tabValue === 4
                            ? 'badgeSelectionTabActive'
                            : 'badgeSelectionTabInActive'
                        }
                        value = { 4 }
                        panelId = "badgeId-tab4"
                        className = "badgeSelection-tabStyle"
                        onClick = { () => this.setState({ tabValue: 4 }) }
                      >
                        4.{translator.NewConfiguration.dates}
                      </Tab>
                    </Tabs>
                  </div>

                  <TabPanel id = "badgeId-tab1" value = { 1 }>
                    {this.tab1()}
                  </TabPanel>

                  <TabPanel id = "badgeId-tab2" value = { 2 }>
                    <Variable
                      prentBackClick = { this.backButton }
                      parentNextClick = { this.nextButton }
                      parentCallback = { this.handleVariable }
                      selectedBadgeData = { selectedBadgeData }
                      allTargetList = { allTargetList }
                      handleChange = { this.handleVariableChange }
                      translation = { translation }
                      frontApi = { frontApiData }
                    />
                  </TabPanel>
                  <TabPanel id = "badgeId-tab3" value = { 3 }>
                    <>
                      <Location
                        selectedBadgeData = { selectedBadgeData }
                        handleLocationData = { this.handleLocationData }
                        fetchLocation = { this.fetchLocation }
                        fetchLocationData = { fetchLocationData }
                      />
                      <div className = "badgeSelectionFooterBody" style = { { position: 'absolute' } }>
                        <button
                          className = "badgeSelectionCancelButton"
                          onClick = { () => {
                            this.backButton();
                            const element = document.getElementById('hello');
                            element.scrollBy(-200, 0);
                          } }
                          type = "button"
                        >
                          {translator.EditDialog.back}
                        </button>
                        <Button
                          className = "badgeSelectionNextButton"
                          onClick = { () => {
                            this.nextButton();
                            const element = document.getElementById('hello');
                            element.scrollBy(200, 0);
                          } }
                        >
                          {translator.EditDialog.next}
                        </Button>
                      </div>
                    </>
                  </TabPanel>
                  <TabPanel id = "badgeId-tab4" value = { 4 }>
                    <>
                      <DateScreen dateProp = { this.getDatefunc } dateProp2 = { this.getDatefunc2 } />
                      <div className = "badgeSelectionFooterBody" style = { { position: 'absolute' } }>
                        <button
                          className = "badgeSelectionCancelButton"
                          onClick = { () => {
                            this.backButton();
                            const element = document.getElementById('hello');
                            element.scrollBy(-200, 0);
                          } }
                          type = "button"
                        >
                          {translator.EditDialog.back}
                        </button>
                        <Button
                          className = "badgeSelectionNextButton"
                          onClick = { () => this.onHandle() }
                        >
                          {translator.EditDialog.save}
                        </Button>
                      </div>
                    </>
                  </TabPanel>
                </TabContext>
                <div className = "rightSideBadgeSelectionContainer" />
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => 
  ({
    browserLanguage: state.browserLanguage,
    selectedCostCenter: state.selectedCostCenter,
  })

const mapDispatchToProps = () => {
};

export default connect(mapStateToProps, mapDispatchToProps)(BadgeSelection);
