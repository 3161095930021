import jwtDecode from 'jwt-decode';
// import Resizer from 'react-image-file-resizer';
import LocalizedStrings from 'react-localization';
import { translation } from '../locales/translation.js';

let glo;
const translator = new LocalizedStrings(translation);

// to fetch the role array from token
export const getRolesArrayFromToken = () => {
  const token = localStorage.getItem('adal.idtoken');
  try {
    const decoded = jwtDecode(token);
    return decoded.roles;
    // return ['PEC.SV.GAMIFICATION'];
  } catch (error) {
    // console.log(`Error: ${error}`);
    return error;
  }
};

// to fetch the email id from token
export const getEmailIdFromToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.unique_name;
  } catch (error) {
    // console.log(`Error: ${error}`);
    return error;
  }
};
export const getAppId =()=>{
  const applicationId = glo;
  // rolesArray.includes('PEC.ADMIN.GAMIFICATION') ||
  // rolesArray.includes('PEC.SV.GAMIFICATION')
  //   ? 4
  //   : rolesArray.includes('SG_ROLE_EMD_ADMIN_GAMIFICATION') || rolesArray.includes('SG_ROLE_EMD_SV_GAMIFICATION')
  //   ? 5 
  //   : rolesArray.includes("PS.ADMIN.GAMIFICATION") || rolesArray.includes('PS.SV.GAMIFICATION')
  //   ? 2 
  //   :"" ;
  return applicationId;
}
// get basic parameters of request body for calling a service
export const getJsonBody = () => {
  const token = localStorage.getItem('adal.idtoken');
  // const applicationId =
  //   rolesArray.includes('PEC.ADMIN.GAMIFICATION') ||
  //   rolesArray.includes('PEC.SV.GAMIFICATION')
  //     ? 4
  //     : 2;
  const applicationId = getAppId();
  // rolesArray.includes('PEC.ADMIN.GAMIFICATION') ||
  // rolesArray.includes('PEC.SV.GAMIFICATION')
  //   ? 4
  //   :  rolesArray.includes('SG_ROLE_EMD_ADMIN_GAMIFICATION') ? 5 : 2;
  const config = {
    applicationId,
    masterGroupSourceSystemId: getEmailIdFromToken(token),
  };
  return config;
};

export const setAppid=(val)=>{
  glo=val;
}
export const getTokenExpiry = ()=>{
  const token = localStorage.getItem('adal.idtoken');
  const decoded = jwtDecode(token);
  return decoded;
}
export const isValidRole = () => {
  const token = localStorage.getItem('adal.idtoken');
  const decoded = jwtDecode(token);
  const rolesArray = decoded.roles;
  if (rolesArray) {
    if (
      rolesArray.includes('PEC.ADMIN.GAMIFICATION') ||
      rolesArray.includes('PS.ADMIN.GAMIFICATION') ||
      rolesArray.includes('PS.SV.GAMIFICATION') ||
      rolesArray.includes('PEC.SV.GAMIFICATION')||
      rolesArray.includes('SG_ROLE_EMD_ADMIN_GAMIFICATION')||
      rolesArray.includes('SG_ROLE_EMD_SV_GAMIFICATION')
    ) {
      return true;
    } 
    return false;
    
  }
  return false;
};
export const getMultiappid =() =>{
  const rolesArray = getRolesArrayFromToken();
  // rolesArray= '';
  // rolesArray=['SG_ROLE_EMD_ADMIN_GAMIFICATION','PS.ADMIN.GAMIFICATION']
  // rolesArray=['SG_ROLE_EMD_ADMIN_GAMIFICATION','SG_ROLE_EMD_SV_GAMIFICATION'];
  // rolesArray=["PS.ADMIN.GAMIFICATION","PS.SV.GAMIFICATION"];
  const appidarr=[];
  const validrole = isValidRole();
  if(validrole)
  {
    if(rolesArray.includes("PS.ADMIN.GAMIFICATION") || rolesArray.includes('PS.SV.GAMIFICATION')){
      appidarr.push(2);
    }
    if(rolesArray.includes('SG_ROLE_EMD_ADMIN_GAMIFICATION') || rolesArray.includes('SG_ROLE_EMD_SV_GAMIFICATION'))
    {
      appidarr.push(5);
    }
    if(rolesArray.includes("PEC.ADMIN.GAMIFICATION") || rolesArray.includes('PEC.SV.GAMIFICATION')){
      appidarr.push(4);
    }
  }
  return appidarr;
}

export const getJsonBody2 = () => {
  const token = localStorage.getItem('adal.idtoken');
  const config = {
    AppId: 4,
    EmployeeId: getEmailIdFromToken(token),
  };
  return config;
};

// to fetch the first and lastname
export const getNameInitials = (name) => {
  const nameInitials = {
    firstNameInitial: '',
    lastNameInitial: '',
  };
  const separatedName = name && name.split(' ');
  if (separatedName && separatedName.length > 0) {
    if (separatedName[ 0 ] && separatedName[ 0 ].length > 0) {
      nameInitials.firstNameInitial = separatedName[ 0 ].charAt(0);
    }
    if (
      separatedName[ separatedName.length - 1 ] &&
      separatedName[ separatedName.length - 1 ].length > 0
    ) {
      nameInitials.lastNameInitial =
        separatedName[ separatedName.length - 1 ].charAt(0);
    }
  }
  return `${ nameInitials.firstNameInitial }${ nameInitials.lastNameInitial }`;
};

export const getFirstAndLastName = (name) => {
  const nameInitials = {
    firstNameInitial: '',
    lastNameInitial: '',
  };
  const separatedName = name && name.split(' ');
  if (separatedName && separatedName.length > 0) {
    if (separatedName[ 0 ] && separatedName[ 0 ].length > 0) {
      [ nameInitials.firstNameInitial, ] = separatedName;
    }
    if (
      separatedName[ separatedName.length - 1 ] &&
      separatedName[ separatedName.length - 1 ].length > 0
    ) {
      nameInitials.lastNameInitial = separatedName[ separatedName.length - 1 ];
    }
  }
  return nameInitials;
};

// to convert the date in yy-mm-dd format
export function formatDate(date) {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 101).toString().substring(1);
  const day = (date.getDate() + 100).toString().substring(1);
  return `${ year  }-${  month  }-${  day }`;
}

// to compress the size of image
// export function resizer(reader, file) {
//   Resizer.imageFileResizer(
//     file,
//     500,
//     500,
//     'JPEG',
//     100,
//     0,
//     (uri) => {
//       reader.readAsDataURL(uri);
//     },
//     'blob',
//     0,
//     0
//   );
// }

// to convert the experience name in finnish
export const roles = {
  RolesEnglish: {
    Newcomer: 'Newcomer',
    Qualified: 'Qualified',
    Expert: 'Expert',
  },
  RolesFinnish: {
    Newcomer: 'Tulokas',
    Qualified: 'Pätevä',
    Expert: 'Ekspertti',
  },
};

// to check whether role is present or not
export function updateFinnishRoleInEmpList(employeeList, language) {
  translator.setLanguage(language);
  const updatedEmployeeList =
    employeeList &&
    employeeList.map((employee) => {
      const temp = employee;
      // employee.RoleName = employee.RoleName
      temp.RoleName = temp.RoleName
        ? translator.roles[ employee.RoleName ]
        : 'No Role';
      return employee;
    });
  return updatedEmployeeList;
}

export const isRolePEC = () => {
  const rolesArray = getRolesArrayFromToken();
  return rolesArray.includes('PEC.SV.GAMIFICATION') ||
  rolesArray.includes('PEC.ADMIN.GAMIFICATION')
};
export const isPECAdmin = () => {
  const rolesArray = getRolesArrayFromToken();
  return rolesArray.includes('PEC.ADMIN.GAMIFICATION')
};
