const PEC_BASE_URL =
  process.env.REACT_APP_ENV !== 'LOCAL'
    ? process.env.REACT_APP_PEC_API_URL
    : '';

const API_BASE_URL =
  process.env.REACT_APP_ENV !== 'LOCAL'
    ? process.env.REACT_APP_API_BASE_URL
    : '';

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// defined api to fetch data from backend
const urls = {
  getAllCostCenterName: `${ API_BASE_URL }/HomeScreen/FetchSupervisorCostCenters`,
  getWeatherInfo: `https://api.openweathermap.org/data/2.5/forecast?lat=`,
  getHourlyWeather: 'https://api.openweathermap.org/data/3.0/onecall?lat=',
  getHomeScreenData: `${ API_BASE_URL }/HomeScreen/FetchDepoData`,
  getPeopleScreenData: `${ API_BASE_URL }/PeopleScreen/FetchUsersAtCostCenterLevel`,
  AwardSupervisorBadges: `${ API_BASE_URL }/HomeScreen/AwardSupervisorBadges`,
  getTeamsInACostCenter: `${ API_BASE_URL }/PeopleScreen/FetchTeamsInACostCenter`,
  UpdateUserDetails: `${ API_BASE_URL }/PeopleScreen/UpdateUserDetails`,
  createNewTeam: `${ API_BASE_URL }/HomeScreen/AddNewTeam`,
  deleteTeam: `${ API_BASE_URL }/PeopleScreen/DeleteTeam`,
  updateTeamDetails: `${ API_BASE_URL }/PeopleScreen/UpdateTeamDetails`,
  informBadWeather: `${ API_BASE_URL }/WeatherScreen/InformBadWeather`,
  getWeatherLog: `${ API_BASE_URL }/WeatherScreen/FetchWeatherNotificationLog`,
  getWeatherNotificationHistory: `${ API_BASE_URL }/WeatherScreen/FetchWeatherNotificationWeeklyHistory`,
  getActionLogScreenData: `${ API_BASE_URL }/ActionLogScreen/FetchActionLogs`,
  addToTeam: `${ API_BASE_URL }/PeopleScreen/AddUserToTeam`,
  logOutAction: `${ API_BASE_URL }/ActionLogScreen/LogOutEventLogging`,
  greetingData: `${ API_BASE_URL }/HomeScreen/DisplayGreetingMessage`,
  deletePeople: `${ API_BASE_URL }/PeopleScreen/DeleteUser`,
  contactData: `${ API_BASE_URL }/VIPContactList/FetchVIPContactList`,
  getChangeHistory: `${ API_BASE_URL }/VIPContactList/FetchVIPContactHistory`,
  saveContact: `${ API_BASE_URL }/VIPContactList/SaveVIPContacts`,
  deleteContact: `${ API_BASE_URL }/VIPContactList/DeleteVIPContact`,
  getBadgeData: `${ API_BASE_URL }/RuleConfigurationList/FetchRuleConfigurationBadgeList`,
  getLocationData: `${ API_BASE_URL }/RuleConfigurationList/FetchRuleConfigurationLocationList`,
  getlocationList: `${ API_BASE_URL }/RuleConfigurationList/FetchLocations`,
  deleteConfigurations: `${ API_BASE_URL }/RuleConfigurationList/DeleteRuleConfigurations`,
  newconfiguration: `${ API_BASE_URL }/RuleConfigurationList/SaveRuleConfiguration`,
  fetchLocation: `${ API_BASE_URL }/RuleConfigurationList/FetchLocations`,
  peopleDetails:`${ PEC_BASE_URL }/GetAdditionalScreenAllDetails`,
  getBadgeDataPEC: `${ API_BASE_URL }/PeopleScreen/GetExceptionalData`,
  getUserPoints:`${ API_BASE_URL }/PeopleScreen/FetchPointsForUser`,
  getMessageTemplates:`${ API_BASE_URL }/MessageScreen/getSupervisorMessageTemplates`,
  saveMessageTemplates:`${ API_BASE_URL }/MessageScreen/addSupervisorMessageTemplate`,
  deleteMessageTemplate:`${ API_BASE_URL }/MessageScreen/deleteSupervisorMessageTemplate`,
  sendMessage:`${ API_BASE_URL }/MessageScreen/sendMsgToDrivers`,
  getSentArchiveMessages:`${ API_BASE_URL }/MessageScreen/getSupervisorMessages`,
  messageActions:`${ API_BASE_URL }/MessageScreen/messageActions`,
  dataScreenApi:`${ API_BASE_URL }/DataScreen/getPointsForDrivers`,
  weeklyPulseData:`${ API_BASE_URL }/DailyPulse/GetPulseData`,
  monthlyPulseData:`${ API_BASE_URL }/DailyPulse/FetchMonthlyPulseData`,
  quartelryPulseData:`${ API_BASE_URL }/DailyPulse/FetchQuaterlyPulseData`,
  weeklyPulseDataByTerminals:`${ API_BASE_URL }/DailyPulse/GetPulseDataRegionwise`,
  monthlyPulseDataByTerminals:`${ API_BASE_URL }/DailyPulse/FetchMonthlyPulseDataRegionwise`,
  quarterlyPulseDataByTerminals:`${ API_BASE_URL }/DailyPulse/FetchQuaterlyPulseDataRegionwise`,
  dailyPulseLineGraph : `${ API_BASE_URL }/DailyPulse/GetPulseDataForLineGraph`,
  getDataForBadgeScreen : `${ API_BASE_URL }/BadgeScreen/FetchSupervisorBadges`,
  acknowledgeMessage: `${ API_BASE_URL }/MessageScreen/AcknowledgeUserMessages`,
  saveCustomMessagesBadges: `${ API_BASE_URL }/HomeScreen/SaveExceptionalBadgeMessageForTheDay`,
  getConversatationMessage: `${ API_BASE_URL }/MessageScreen/GetConversationMessages`,
  getAckMessage: `${ API_BASE_URL }/MessageScreen/AcknowledgeUserMessages`,
  sendMsgToDriver: `${ API_BASE_URL }/MessageScreen/sendMsgToDrivers`
};
module.exports = urls;
