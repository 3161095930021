/* eslint-disable */

import React, { Component } from 'react';
import './App.css';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { translation } from './locales/translation.js';
import browserDetect from '../node_modules/browser-detect';
import changeLanguageAction from './actions/changeLanguageAction';
import Header from './components/Header';
import SideMenuCustom from './components/SideMenuCustom';
import ScreenContainer from './components/ScreenContainer';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { getMultiappid, getTokenExpiry, setAppid } from './utility/commonUtility';
import NoRolePopUp from './components/NoRolePopUp';
import TokenExpiryDialog from './components/TokenExpiryDialog';

const translator = new LocalizedStrings(translation);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appidgr1 : false,
      // buttonclicked: false,
      noAccessShow : false,
      tokenexpsec : false,
      urlPath:null
    };
  }

  // Initial Language detected from Browser Language
  componentDidMount() {
    let defaultLanguage = '';
    const objLang = navigator.language;
    if (browserDetect().name === 'ie') {
      if (navigator.browserLanguage === 'en' || navigator.browserLanguage === 'en-US') {
        defaultLanguage = 'en';
      } else if (navigator.browserLanguage === 'fi' || navigator.browserLanguage === 'FI') {
        defaultLanguage = 'fi';
      }
    } else if (objLang.includes('fi')) {
      defaultLanguage = 'fi';
    } else if (objLang.includes('en') || objLang.includes('en-US')) {
      defaultLanguage = 'en';
    } else {
      defaultLanguage = 'fi';
    }
    defaultLanguage = 'fi';
    // eslint-disable-next-line react/destructuring-assignment
    this.props.changeLanguageActionDispatcher(defaultLanguage);
    const appids=getMultiappid();
    // const decodedToken = getTokenExpiry();
    
    // setInterval(()=>{
    //   let seconds = new Date().getTime() / 1000;
    //   seconds = Math.round(seconds);
    //   if(decodedToken.exp < seconds)
    //   {
    //     this.setState({ tokenexpsec : true })
    //   }
    //   // console.log("ddddddddddddd",decodedToken.exp , seconds , decodedToken.exp - seconds);
    // },10000)
    
    // const validrole = isValidRole();
    // if(!validrole)
    // {
    //   this.setState({ noAccessShow:true });
    // }
    // console.log("Dd")
    if(appids.length === 0)
    {
      this.setState({ noAccessShow:true });
    }
    if(appids.length > 1)
    {
      this.setState({ appidgr1: true })
      setAppid(appids[ 0 ]);
    }
    if(appids.length === 1)
    {
      setAppid(appids[ 0 ]);
    }
  }

  showin = (val) => {
    // this.setState({ appid : val });
    setAppid(val);
  };

  // unshowdialog =(val1) =>{
  //   this.setState({ tokenexpsec : val1 })
  //   window.location.reload();
  // }

  retrievePath = (data) => this.setState({
    urlPath:data
  })

  render() {
    const { browserLanguage } = this.props;
    const { appidgr1,noAccessShow, tokenexpsec, urlPath }= this.state;
    translator.setLanguage(browserLanguage);
    return (
      browserLanguage === '' ? null : !noAccessShow ? (
        <Router>
          <Header oncc = { this.showin } drpshow = { appidgr1 } selectedPath = { urlPath }/>
          <div className = "mainContainer">
            <div style = { { width: '15%' } }>
              <SideMenuCustom callBack = { this.retrievePath }/>
            </div>
            <div style = { { width: '85%' } }>
              <ScreenContainer />
            </div>
            {/* {tokenexpsec && <TokenExpiryDialog oncc = { this.unshowdialog } /> } */}
          </div>
        </Router>
      ) : (
        <NoRolePopUp />
      )
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  changeLanguageActionDispatcher: bindActionCreators(changeLanguageAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
