import React, { Component } from 'react';
import './PeopleRightSection.css';
import LocalizedStrings from 'react-localization';
import { Button } from '@postidigital/posti-components';
import { connect } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { translation } from '../../locales/translation.js';
import { CommonService } from '../../service/CommonService.js';
import { getJsonBody } from '../../utility/commonUtility';
import Toastr from '../../shared/Toastr';
import apiUrl from '../../urlConstants.js';

const translator = new LocalizedStrings(translation);
class DeletePeopleDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.commonService = new CommonService();
  }

  deleteMembers = () => {
    const jsonBody = getJsonBody();
    const { peopleDetailsList,setLoader,onConfirm } = this.props;
    jsonBody.userSourceSystemIds = peopleDetailsList.map((v) => v.userSourceSystemId);
    setLoader();
    this.commonService
      .postMethod(apiUrl.deletePeople, jsonBody)
      .then((res) => {
        if (res.data === 1) {
          Toastr.showSuccessMessage(
            peopleDetailsList.length === 1
              ? `${ peopleDetailsList[ 0 ].firstName } ${ translator.PeopleScreen.deleted }`
              : `${ peopleDetailsList.length } ${ translator.PeopleScreen.peopleDeleted }`
          );
          onConfirm();
        } else {
          Toastr.showErrorMessage(translator.PeopleScreen.errorDeleting);
          onConfirm();
        }
      })
      .catch(() => {
        Toastr.showErrorMessage(translator.PeopleScreen.deleteAPIError);
        onConfirm();
      });
  };

  render() {
    const { browserLanguage , peopleDetailsList,onCancel }= this.props;
    translator.setLanguage(browserLanguage);
    return (
      <Dialog showHeader = { false } visible style = { { width: '30vw' } } id = "logOutDialog">
        <div className = "logOutHeader">
          {peopleDetailsList.length === 1
            ? `${ translator.PeopleScreen.deleteMemberText } ${ peopleDetailsList[ 0 ].firstName }?`
            : `${ translator.PeopleScreen.deleteMemberText } ${ peopleDetailsList.length } ${ translator.PeopleScreen.deletePeopleText }?`}
        </div>
        <div className = "logOutBody">
          {peopleDetailsList.length === 1
            ? `${ translator.PeopleScreen.singleMemberDeleteConfirmationText } ${ peopleDetailsList[ 0 ].firstName }. ${ translator.PeopleScreen.deleteWarningText }`
            : `${ translator.PeopleScreen.multipleDeleteConfirmationText } ${ peopleDetailsList.length } ${ translator.PeopleScreen.deletePeopleText }. ${ translator.PeopleScreen.deleteWarningText }`}
        </div>
        <div style = { { border: '1px solid #C2C9D1', margin: '0px 32px' } } />
        <div className = "logOutFooter">
          <div className = "logOutFooterText" aria-hidden = "true" onClick = { () => onCancel() }>
            {translator.PeopleScreen.cancel}
          </div>
          <Button size = "sm" id = "deletePeople" onClick = { () => this.deleteMembers() }>
            {translator.PeopleScreen.delete}
          </Button>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  browserLanguage: state.browserLanguage,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DeletePeopleDialog);
